import React, { useState } from "react";
import Layout from "../../Layout/Layout";
import Button from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import UploadModal from "../../../components/Modal/UploadModal";
import { DeleteIcon, EditIcon } from "../../../assets";

const albums = [
  {
    title: "Profile pictures",
    items: 11,
    imgSrc: "path_to_profile_picture.jpg",
  },
  { title: "Cover photos", items: 7, imgSrc: "path_to_cover_photo.jpg" },
  { title: "Untitled album", items: null, imgSrc: "zgbg.png" },
  { title: "Featured Photos", items: 15, imgSrc: "path_to_featured_photo.jpg" },
];

const AlbumPhotos = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const openUploadModal = () => {
    setIsUploadModalOpen(true);
  };

  const closeUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack
            title="Album Name >> Photos"
            onClick={handleNavigation(-1)}
          >
            <Button label="Add More Photos" onClick={() => openUploadModal()} />
          </ListHeaderBack>
        </div>

        <div className="p-4 grid grid-cols-3 sm:grid-cols-3 lg:grid-cols-4 gap-4">
          {albums.map((album, index) => (
            <div key={index} className="border rounded-lg overflow-hidden">
              <div className="bg-[#CCC] h-36 flex items-center justify-center cursor-pointer">
                {album.imgSrc ? (
                  <img
                    src={album.imgSrc}
                    alt={album.title}
                    className="h-full w-full object-fill"
                  />
                ) : (
                  <span className="text-4xl text-gray-500">+</span>
                )}
              </div>
              <div className="p-4 flex justify-end gap-3 cursor-pointer">
                <h3 className="text-lg font-semibold">
                  <EditIcon />
                </h3>
                <h3 className="text-lg font-semibold">
                  <DeleteIcon />
                </h3>
              </div>
            </div>
          ))}
        </div>
      </div>
      <UploadModal isOpen={isUploadModalOpen} onClose={closeUploadModal} />
    </Layout>
  );
};

export default AlbumPhotos;
