import * as Yup from "yup";
import "yup-phone-lite";

Yup.addMethod(Yup.array, "checkUnique", function (propertyName, message) {
  return this.test("unique", message, function (list) {
    const uniqueSet = new Set();
    const { path } = this;
    list.forEach((item, index) => {
      if (uniqueSet.has(item[`${propertyName}`])) {
        throw this.createError({
          path: `${path}[${index}].${propertyName}`,
          message,
        });
        // return false;
      } else {
        uniqueSet.add(item[`${propertyName}`]);
      }
    })
    return true;
  });
}
);

export const AddUpdateMaintenanceStaffValidation = () => {
  return Yup.object({
    prefix: Yup.string().required('*Required'),
    firstName: Yup.string().required('*Required').matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff]*)$/gi, "*Please enter letters only").min(3, '*Must contain min 3 letters'),
    lastName: Yup.string().matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff]*)$/gi, "*Please enter letters only").min(3, '*Must contain min 3 letters'),
    responsibility: Yup.string().trim().required('*Required'),
    title: Yup.string().trim().required('*Required'),
    agencyId: Yup.string().required('*Required'),
    mobileCountryCode: Yup.string().default('').required('*Required'),
    countryISO: Yup.string().default('').required('*Required'),
    mobileNo: Yup.string()
      .when(['countryISO'], (countryISO, schema) => {
        return countryISO[0] === '' ?
          schema.test('country-code-check', '*Please select country first', value => {
            return /.[]/.test(value);
          })
          :
          schema.phone(countryISO[0], '*Please enter valid number').test('not-only-zeros', '*Please enter valid number', value => {
            return !/^0/.test(value);
          })
      })
      .required("Mobile number is required"),
    email: Yup.string().matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/, "*Please enter valid email address"),
    otherDetails: Yup.string().trim(),
    idCard: Yup.string(),
    profilePic: Yup.string()
  });
};


export const AddUpdateAgencyValidation = () => {
  return Yup.object({
    categoryId: Yup.string().required('*Required'),
    agencyName: Yup.string().trim().required('*Required').min(3, '*Must contain min 3 letters'),
    officeAddress: Yup.string().trim().required('*Required'),
    panCardNo: Yup.string().required('*Required').min(10, '*PAN No must be of 10 digits')
      .test('not-only-zeros', '*Invalid PAN number', value => {
        return /[a-zA-Z]{5}\d{4}[a-zA-Z]{1}/.test(value);
      }),
    tanNo: Yup.string()
      .min(10, '*TAN No must be of 10 digits')
      .required("*TAN number is required"),
    concernedPersonName: Yup.string().trim().required('*Required').matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi, "*Please enter letters only").min(3, '*Must contain min 3 letters'),
    otherDetails: Yup.string().trim(),
    mobileCountryCode: Yup.string().default('').required('*Required'),
    countryISO: Yup.string().default('').required('*Required'),
    mobileNo: Yup.string()
      .when(['countryISO'], (countryISO, schema) => {
        return countryISO[0] === '' ?
          schema.test('country-code-check', '*Please select country first', value => {
            return /.[]/.test(value);
          })
          :
          schema.phone(countryISO[0], '*Please enter valid number').test('not-only-zeros', '*Please enter valid number', value => {
            return !/^0/.test(value);
          })
      })
      .required("Mobile number is required"),
  })
};

export const AddEmployeeValidation = () => {
  return Yup.object({
    employeeTypeId: Yup.string().required('*Required'),
    fullName: Yup.string().trim().required('*Required').matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi, "*Please enter letters only").min(3, '*Must contain min 3 letters'),
    userName: Yup.string().trim().required('*Required').matches(/[A-Za-z0-9_.]$/gi, "*only use [A-z], [a-z], [0-9], _ and .").min(3, '*Must contain min 3 letters'),
    mobileCountryCode: Yup.string().default('').required('*Required'),
    countryISO: Yup.string().default('').required('*Required'),
    mobileNo: Yup.string()
      .when(['countryISO'], (countryISO, schema) => {
        return countryISO[0] === '' ?
          schema.test('country-code-check', '*Please select country first', value => {
            return /.[]/.test(value);
          })
          :
          schema.phone(countryISO[0], '*Please enter valid number').test('not-only-zeros', '*Please enter valid number', value => {
            return !/^0/.test(value);
          })
      })
      .required("Mobile number is required"),
    email: Yup.string().required('*Required').matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/, "*Please enter valid email address"),
    address: Yup.string().trim().required('*Required'),
    password: Yup.string()
      .required("*New password is required")
      .matches(/^[\S]+$/, "*Password cannot contain spaces")
      .matches(/^[^_]+$/, "*Password cannot contain underscores")
      .min(8, "*Should contain at least 8 characters")
      .max(255, "*Should not more than 255 characters")
      .matches(/[a-z]/, "*Should contain at least 1 lowercase")
      .matches(/[A-Z]/, "*Should contain at least 1 uppercase")
      .matches(/[0-9]/, "*Should contain altleast 1 digit")
      .matches(/[!@#$%^&*]/, "*Should have atleast 1 special char")
    ,
    confirmPassword: Yup.string()
      .required("*Confirm password is required")
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
  })
};

export const UpdateEmployeeValidation = () => {
  return Yup.object({
    employeeTypeId: Yup.string().required('*Required'),
    fullName: Yup.string().trim().required('*Required').matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi, "*Please enter letters only").min(3, '*Must contain min 3 letters'),
    userName: Yup.string().trim().required('*Required').matches(/[A-Za-z0-9_.]$/gi, "*only use [A-z], [a-z], [0-9], _ and .").min(3, '*Must contain min 3 letters'),
    mobileCountryCode: Yup.string().default('').required('*Required'),
    countryISO: Yup.string().default('').required('*Required'),
    mobileNo: Yup.string()
      .when(['countryISO'], (countryISO, schema) => {
        return countryISO[0] === '' ?
          schema.test('country-code-check', '*Please select country first', value => {
            return /.[]/.test(value);
          })
          :
          schema.phone(countryISO[0], '*Please enter valid number').test('not-only-zeros', '*Please enter valid number', value => {
            return !/^0/.test(value);
          })
      })
      .required("Mobile number is required"),
    email: Yup.string().required('*Required').matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/, "*Please enter valid email address"),
    address: Yup.string().trim().required('*Required'),
  })
};

export const AddFromExistingEmployeeValidation = () => {
  return Yup.object({
    employeeTypeId: Yup.string().required('*Required'),
  })
};

export const AddManangingCommitteeValidation = () => {
  return Yup.object({
    name: Yup.string().trim().required('*Required').min(3, '*Must contain min 3 letters'),
    formationDate: Yup.date().required('*Required'),
    resolutionDoc: Yup.string(),
    selectedOccupants: Yup.array()
      .of(
        Yup.object().shape({
          memberId: Yup.string(),
          selected: Yup.string(),
          roles: Yup.string()
            .when(['selected'], (selected, schema) => {
              return selected[0] === 'true' ?
                schema.required("*Required")
                :
                schema
            }),
          title: Yup.string(),
          joinDate: Yup.date()
            .when(['selected'], (selected, schema) => {
              return selected[0] === 'true' ?
                schema.required("*Required")
                :
                schema
            }),
          leaveDate: Yup.date()
            .when(['selected'], (selected, schema) => {
              return selected[0] === 'true' ?
                schema.required("*Required")
                :
                schema
            }),
        })
      )
  })
};


export const ResignLetterValidation = () => {
  return Yup.object({
    dateOfResign: Yup.string().required('*Required'),
    reason: Yup.string().trim().required('*Required'),
    authority: Yup.string().required('*Required'),
    sendNotification: Yup.string(),
    approvalReqired: Yup.string(),
  })
};

export const RetireLetterValidation = () => {
  return Yup.object({
    dateOfRetire: Yup.string().required('*Required'),
    reason: Yup.string().trim().required('*Required'),
    sendNotification: Yup.string(),
    approvalReqired: Yup.string(),
  })
};


export const ApproveResignValidation = () => {
  return Yup.object({
    effectiveDate: Yup.date().required('*Required'),
    remarks: Yup.string().trim(),
    approveDoc: Yup.string(),
    sendEmail: Yup.string(),
  })
};


export const ReappointMemberValidation = () => {
  return Yup.object({
    fromDate: Yup.date().required('*Required'),
    toDate: Yup.date().required('*Required'),
    role: Yup.string().trim().required('*Required'),
    requiredDoc: Yup.string(),
    notifyOthers: Yup.string(),
  })
};


export const AddSocietyStaffValidation = () => {
  return Yup.object({
    prefix: Yup.string().required('*Required'),
    firstName: Yup.string().required('*Required').matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff]*)$/gi, "*Please enter letters only"),
    lastName: Yup.string().required('*Required').matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff]*)$/gi, "*Please enter letters only"),
    profilePic: Yup.string(),
    staffType: Yup.string().required('*Required'),
    staffProfileId: Yup.string().required('*Required'),
    startValidity: Yup.date().required('*Required'),
    endValidity: Yup.date().required('*Required'),
    workPerDay: Yup.string().required('*Required'),
    salary: Yup.string().required('*Required'),
    mobileCountryCode: Yup.string().default('').required('*Required'),
    countryISO: Yup.string().default('').required('*Required'),
    contact: Yup.string()
      .when(['countryISO'], (countryISO, schema) => {
        return countryISO[0] === '' ?
          schema.test('country-code-check', '*Please select country first', value => {
            return /.[]/.test(value);
          })
          :
          schema.phone(countryISO[0], '*Please enter valid number').test('not-only-zeros', '*Please enter valid number', value => {
            return !/^0/.test(value);
          })
      })
      .required("Mobile number is required"),
    email: Yup.string().matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/, "*Please enter valid email address").required('*Required'),
    gender: Yup.string().required('*Required'),
    salary: Yup.string().required('*Required'),
    address: Yup.string().trim().required('*Required'),
    dob: Yup.date().required('*Required'),
    vehicleType: Yup.string(),
    vehicleNumber: Yup.string().test('not-only-zeros', '*Invalid vehicle number', value => {
      return /^(?!0).*/.test(value);
    }),
    fingerPrintImg: Yup.string(),
    adharCardNo: Yup.string().test('not-only-zeros', '*Invalid identity proof number', value => {
      return !/^0/.test(value);
    }),
    adharDoc: Yup.string(),
    panCardNo: Yup.string().min(10, '*PAN No must be of 10 digits')
      .test('not-only-zeros', '*Invalid PAN number', value => {
        if (value)
          return /[a-zA-Z]{5}\d{4}[a-zA-Z]{1}/.test(value);
        return true;
      }),
    panDoc: Yup.string(),
    drivingLicenceNo: Yup.string().test('not-only-zeros', '*Invalid Licence Number', value => {
      return /^(?!0).*/.test(value);
    }),
    drivingLicenceDoc: Yup.string(),
    rationNo: Yup.string(),
    rationDoc: Yup.string(),
    flatData: Yup.array()
      .of(
        Yup.object().shape({
          flatId: Yup.string().required("*Required"),
          joinDate: Yup.date().required("*Required"),
          status: Yup.string().required("*Required")
        })
      ).checkUnique("flatId", "*Flat must be unique"),

  });
};

export const EditSocietyStaffValidation = () => {
  return Yup.object({
    prefix: Yup.string().required('*Required'),
    firstName: Yup.string().required('*Required').matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff]*)$/gi, "*Please enter letters only"),
    lastName: Yup.string().required('*Required').matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff]*)$/gi, "*Please enter letters only"),
    profilePic: Yup.string(),
    staffType: Yup.string().required('*Required'),
    staffProfileId: Yup.string().required('*Required'),
    startValidity: Yup.date().required('*Required'),
    endValidity: Yup.date().required('*Required'),
    workPerDay: Yup.string().required('*Required'),
    salary: Yup.string().required('*Required'),
    mobileCountryCode: Yup.string().default('').required('*Required'),
    countryISO: Yup.string().default('').required('*Required'),
    contact: Yup.string()
      .when(['countryISO'], (countryISO, schema) => {
        return countryISO[0] === '' ?
          schema.test('country-code-check', '*Please select country first', value => {
            return /.[]/.test(value);
          })
          :
          schema.phone(countryISO[0], '*Please enter valid number').test('not-only-zeros', '*Please enter valid number', value => {
            return !/^0/.test(value);
          })
      })
      .required("Mobile number is required"),
    email: Yup.string().matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/, "*Please enter valid email address").required('*Required'),
    gender: Yup.string().required('*Required'),
    salary: Yup.string().required('*Required'),
    address: Yup.string().trim().required('*Required'),
    dob: Yup.date().required('*Required'),
    vehicleType: Yup.string(),
    vehicleNumber: Yup.string().test('not-only-zeros', '*Invalid vehicle number', value => {
      return /^(?!0).*/.test(value);
    }),
    fingerPrintImg: Yup.string(),
    adharCardNo: Yup.string().test('not-only-zeros', '*Invalid identity proof number', value => {
      return !/^0/.test(value);
    }),
    adharDoc: Yup.string(),
    panCardNo: Yup.string().min(10, '*PAN No must be of 10 digits')
      .test('not-only-zeros', '*Invalid PAN number', value => {
        if (value)
          return /[a-zA-Z]{5}\d{4}[a-zA-Z]{1}/.test(value);
        return true;
      }),
    panDoc: Yup.string(),
    drivingLicenceNo: Yup.string().test('not-only-zeros', '*Invalid Licence Number', value => {
      return /^(?!0).*/.test(value);
    }),
    drivingLicenceDoc: Yup.string(),
    rationNo: Yup.string(),
    rationDoc: Yup.string(),
    flatData: Yup.array()
      .of(
        Yup.object().shape({
          flatId: Yup.string().required("*Required"),
          joinDate: Yup.date().required("*Required"),
          status: Yup.string().required("*Required"),
          dateOfResign: Yup.string(),
          reason: Yup.string()
            .when(['dateOfResign'], (dateOfResign, schema) => {
              return dateOfResign[0] && dateOfResign[0] !== '0000-00-00' ?
                schema.required("*Required")
                :
                schema
            })
        })
      ).checkUnique("flatId", "*Flat must be unique"),

  });
};

export const ApprovalSettingValidation = () => {
  return Yup.object({
    committeeApproval: Yup.string(),
    employeeApproval: Yup.string(),
    committeeId: Yup.string().required('*At Least One Field Is Required'),
    EmployeeId: Yup.string().required('*At Least One Field Is Required'),
  })
};