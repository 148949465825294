import React, { useState} from "react";
import Modal from "react-modal";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import closeIcon from "../../assets/icons/closeIcon.svg";
import "./sendEmailModal.css";
import { TextInputFM } from "../InputFields/TextInput";
import Button from "../Button/Button";
import { SendEmailValidation } from "../../validationSchema/setupRecordsSchema/occupants&TenantsSchema";
import { ErrorMessage, Form, Formik } from "formik";
import { CircularProgress } from "@mui/material";
import draftToHtml from "draftjs-to-html";

const customStyles = {
  content: {
    width: "680px",
    height: "fit-content",
    minHeight: "fit-content",
    // maxHeight: "80vh",
    background: "#fff",
    border: "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
};

Modal.setAppElement("#root");

const SendEmailModal = ({ isOpen, closeModal, loading, onSubmit }) => {
  const [subject, setSubject] = useState("");

  // For the draft-wysiwyg editor
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Email Modal"
      style={customStyles}
      className="email-modal bg-white border rounded p-4 absolute max-w-2xl "
      overlayClassName="email-modal-overlay fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50"
    >
      <div className="text-center relative items-center flex justify-center">
        <h1 className="text-lg font-bold">Send Email</h1>
        <button
          onClick={closeModal}
          className="absolute top-0 right-0 bg-[#EAEAEA] rounded-full p-3 items-center"
          type="button"
        >
          <img src={closeIcon} alt="Close" />
        </button>
      </div>
      <div className="border-b border-[#CCCCCC] mt-3"></div>
      <Formik
        enableReinitialize
        initialValues={{
          subject: "",
          message: "",
        }}
        onSubmit={(values, { resetForm }) => {
          onSubmit(values);
        }}
        validationSchema={SendEmailValidation}
      >
        {(formik) => {
          return (
            <Form>
              <div
                className="modal-body mt-4"
                style={
                  {
                    // height:"calc(100% - 120px)",
                  }
                }
              >
                <div className="form-group mb-2 flex gap-5 items-center">
                  <label
                    htmlFor="subject"
                    className="text-sm text-gray-600 mb-3"
                  >
                    Subject
                  </label>
                  <TextInputFM
                    label=""
                    placeholder="Enter Subject"
                    id="subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    className="w-[570px]"
                    name="subject"
                    maxLength={80}
                  />
                </div>
                <div className="form-group flex gap-2 ">
                  <label htmlFor="message" className="text-sm text-gray-600">
                    Message
                  </label>
                  {/* Integrate the Editor component */}
                  <div>
                    <div className="occupant-info-editor border border-[#CCC] rounded-md w-full">
                      <Editor
                        editorState={editorState}
                        toolbar={{
                          options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history', 'colorPicker'],
                          inline: { inDropdown: false, options: ["bold", "italic", "underline"] },
                          list: { inDropdown: true },
                          textAlign: { inDropdown: true },
                          link: { inDropdown: true },
                          history: { inDropdown: false, options: ["redo", "undo"] },
                          colorPicker: {
                            className: undefined,
                            component: undefined,
                            popupClassName: undefined,
                          },
                      }}
                        onEditorStateChange={(neweditorState) => {
                          onEditorStateChange(neweditorState);
                          formik.setFieldValue(
                            "message",
                            draftToHtml(
                              convertToRaw(neweditorState.getCurrentContent())
                            )
                          );
                        }}
                      />
                    </div>
                    <div
                      className={`text-red-500 text-xs italic h-4 text-wrap break-words`}
                    >
                      <ErrorMessage name="message" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-b border-[#CCCCCC] mt-3"></div>
              <div className="modal-footer mt-4 flex justify-center">
                <Button
                  label={
                    <span className="w-8 inline-block">
                      {loading ? (
                        <CircularProgress sx={{ color: "white" }} size={17} />
                      ) : (
                        "Send"
                      )}
                    </span>
                  }
                  className="px-5"
                  type={"submit"}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default SendEmailModal;
