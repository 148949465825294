import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Arrow } from "../../../assets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import Layout from "../../Layout/Layout";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import { getCreditDebitTariffHeaderAllAudit } from "../../../redux/actions/FinanceActions/SettingActions/CreditDebitTariffHeaderAction";
import moment from "moment";


export const AuditData = [
    {
        label: "",
        values: [
            { key: "Order No", label: "Order No", value: "D-Block" },
            { key: "Cause", label: "Cause", value: "" },
            { key: "Amount", label: "Amount", value: "8" },
            { key: "hsn_code", label: "HSN code	", value: "8" },
        ],
    },
];


const Accordion = React.memo(
  ({ data, isOpen, toggleAccordion, label, descr, desc }) => (
    <div className="border rounded-lg border-[#CCCCCC] my-2">
      <div
        className="flex justify-between cursor-pointer items-center bg-[#FDF2F3] rounded-lg p-3 border-b"
        onClick={toggleAccordion}
      >
        <div className="flex items-center">
          <button className="text-white text-base font-semibold mr-3 w-5 h-5 bg-red-650 border rounded flex justify-center items-center">
            {isOpen ? (
              <FontAwesomeIcon icon={faChevronUp} size="xs" />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} size="xs" />
            )}
          </button>
          <div className="text-[#000000] text-sm font-semibold">{label}</div>
        </div>
        <div>
          <div className="text-xs text-right text-[#888]">{desc}</div>
          <div className="text-xs text-right text-[#888]">{descr}</div>
        </div>
      </div>
      {isOpen && (
        <div className="p-3">
          {AuditData.map((item, i0) => (
            <React.Fragment key={i0}>
              {item.label ? (
                <div className="acord-section font-semibold text-[#222222] p-2">
                  {item.label}
                </div>
              ) : null}
              {item.values.map((rowData, i1) => (
                <>
                  <div className="flex justify-between p-1.5 text-sm">
                      <div className="flex justify-between w-1/2">
                        <div className="w-1/2 font-semibold">{rowData.label}</div>
                        <div className="w-1/2">{data?.new_tenant_details?.[rowData.key]?.toString() || '-'}</div>
                      </div>
                    
                  </div>
                  {i0 === 0 && i1 === 5 ? <hr /> : null}
                </>
              ))}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  )
);

const CreditDebitAllAuditLog = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {loading, tariffHeaderAllAudit} = useSelector(state=>state.creditDebitTariffHeaderReducer)
  const {loginData} = useSelector(state=>state.loginReducer)

  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const toggleAccordion = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(()=>{
    dispatch(getCreditDebitTariffHeaderAllAudit({societyId: loginData?.default_community?.community_id}))
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="flex gap-5 pb-2">
          <div className="flex w-1/2">
            <button
              onClick={handleNavigation(-1)}
              className="text-sm p-2 text-red-650 font-semibold flex gap-2 justify-center items-center"
            >
              <span>
                <Arrow />
              </span>
              Back
            </button>
            <span className="border-r border-[#CCCCCC]"></span>
            <h1 className="text-sm font-semibold p-2">Credit/Debit Note Cause Audit Logs</h1>
          </div>
        </div>
        <div className="border-b border-[#CCCCCC]"></div>
        {
          loading ?
          <Loader/>
          :
            <div>
              {tariffHeaderAllAudit?.length > 0 ? (
                <>
                {
                    tariffHeaderAllAudit?.map((item, index)=>(
                        <Accordion
                          label={`Credit Debit Audit ${(index+1)?.toString()?.padStart(2, '0')}`}
                          key={index}
                          data={item}
                          isOpen={index === openAccordionIndex}
                          toggleAccordion={() => toggleAccordion(index)}
                          desc={`Modified By ${item?.modified_by_name} ${moment(item?.modified_dateTime, 'YYYY-MM-DD hh:mm:ss').format('MMM DD, YYYY hh:mm A')}`}
                          descr={`Ip Address: ${item?.IpAddress} Session id - ${item?.modified_sessionId}`}
                        />
                    ))
                }
                </>
              ) : (
                <div className="text-center py-4 text-[#999999] flex justify-center">No Audits</div>
              )}
            </div>
        }
      </div>
    </Layout>
  );
};

export default CreditDebitAllAuditLog;
