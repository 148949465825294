import { notify } from '../../../../utils/notification';
import apiEndpoints from '../../../api';
import axiosInstance2 from '../../../axiosInstance2'
import {logOut} from '../../../../utils/helpers/logOut'
import { errorMsg } from '../../../../consts/responseMessages';

export async function getDueReminderDetailsReq(action) {
    const BODY={
        'id': action.data?.societyId,
    }

    return axiosInstance2.post(apiEndpoints.GET_DUE_REMINDER_DETAILS_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function updateDueReminderDetailsReq(action) {
    const BODY ={
        "pageid": action.data.societyId,
        "beforeid": action.data.noOfDaysBeforeDue?.filter(item=>item.id)?.map(item=>item.id)?.join(','),
        "afterid": action.data.noOfDaysAfterDue?.filter(item=>item.id)?.map(item=>item.id)?.join(','),
        "before_duedate": action.data.noOfDaysBeforeDue?.filter(item=>!item.id)?.map(item=>item.noOfDay),
        "after_duedate": action.data.noOfDaysAfterDue?.filter(item=>!item.id)?.map(item=>item.noOfDay),
    }

    action.data.noOfDaysBeforeDue?.forEach((item)=>{
        if(item.id){
            BODY[`before_duedateedit${item.id}`]= item.noOfDay
        }
    })
    action.data.noOfDaysAfterDue?.forEach((item)=>{
        if(item.id){
            BODY[`after_duedateedit${item.id}`]= item.noOfDay
        }
    })


    return axiosInstance2.post(apiEndpoints.UPDATE_DUE_REMINDER_DETAILS_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function deleteDueReminderDetailsReq(action) {
    const BODY={
        'remid': action.data?.id,
        'pageid': action.data?.societyId,
    }
    return axiosInstance2.post(apiEndpoints.DELETE_DUE_REMINDER_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getDebitNoteDueReminderDetailsReq(action) {
    const BODY={
        'id': action.data?.societyId,
    }

    return axiosInstance2.post(apiEndpoints.GET_DEBIT_NOTE_DUE_REMINDER_DETAILS_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function updateDebitNoteDueReminderDetailsReq(action) {
    const BODY ={
        "pageid": action.data.societyId,
        "before_edit": action.data.beforeId,
        [action.data.beforeId ? "before_duedate_debit_note_edit" : "before_debitnote_duedate"]: action.data.noOfDaysBeforeDue,
        "after_edit": action.data.afterId,
        [action.data.afterId ? "after_duedate_debit_note_edit" : "after_debitnote_duedate"]: action.data.noOfDaysAfterDue,
    }

    return axiosInstance2.post(apiEndpoints.UPDATE_DEBIT_NOTE_DUE_REMINDER_DETAILS_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getFDMaturityDueReminderDetailsReq(action) {
    const BODY={
        'id': action.data?.societyId,
    }

    return axiosInstance2.post(apiEndpoints.GET_FD_MATURITY_DUE_REMINDER_DETAILS_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function updateFDMaturityDueReminderDetailsReq(action) {
    const BODY ={
        "pageid": action.data.societyId,
        "day_prior": action.data.noOfDaysBeforeDue,
        "member_type": action.data.memberTypeId,
        "member": action.data.memberIds,
        "employee_type": action.data.employeeTypeId,
        "employee": action.data.employeeIds,
        "managing_committees": action.data.managingCommitteeId,
        "mc_member": action.data.managingCommitteeMemberIds,
    }

    return axiosInstance2.post(apiEndpoints.UPDATE_FD_MATURITY_DUE_REMINDER_DETAILS_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getMemberDueReminderDetailsReq(action) {
    const BODY={
        'id': action.data?.societyId,
        'drcrid': action.data?.creditNoteId
    }

    return axiosInstance2.post(apiEndpoints.GET_MEMBER_DUE_REMINDER_DETAILS_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function updateMemberDueReminderDetailsReq(action) {
    const BODY ={
        "pageid": action.data.societyId,
        "beforedate": [action.data.noOfDaysBeforeDue],
        "afterdate": [action.data.noOfDaysAfterDue],
        "employee_type": action.data.employeeRoleId,
        "employee": action.data.employeeId,
        "managing_committees": action.data.managingCommitteId,
        "mc_member": action.data.managingCommitteMemberId,
    }
    return axiosInstance2.post(apiEndpoints.UPDATE_MEMBER_DUE_REMINDER_DETAILS_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getNomineeDueReminderDetailsReq(action) {
    const BODY={
        'id': action.data?.societyId,
    }

    return axiosInstance2.post(apiEndpoints.GET_NOMINEE_DUE_REMINDER_DETAILS_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function updateNomineeDueReminderDetailsReq(action) {
    const BODY ={
        "pageid": action.data.societyId,
        "schedule": action.data.schedule,
        "schedule_day": action.data.day,
        "schedule_date": action.data.date,
    }

    return axiosInstance2.post(apiEndpoints.UPDATE_NOMINEE_DUE_REMINDER_DETAILS_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getMemberListAccordingToRoleReq(action) {
    const BODY={
        'pageid': action.data?.societyId,
        'type': action.data?.roleId
    }

    return axiosInstance2.post(apiEndpoints.GET_MEMBER_LIST_ACCORDING_TO_ROLE_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}
