import React from "react";
import Layout from "../../Layout/Layout";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";
import { Form, Formik } from "formik";
import { TextInputFM } from "../../../components/InputFields/TextInput";
import {BankNameValidation} from '../../../validationSchema/setupRecordsSchema/buildingSchema'
import { useDispatch, useSelector } from "react-redux";
import { addBankName } from "../../../redux/actions/SetupRecordsActions/BuildingActions/BankDetailsAction";
import { CircularProgress } from "@mui/material";
 
const AddBankAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {addLoader} = useSelector(state=>state.bankNameReducer)

  const handleNavigation = (path) => () => {
    navigate(path);
  };
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 ">
        <Formik
          initialValues={{ 
            bankName: ''
          }}
          onSubmit={(values, {resetForm}) =>
            dispatch(addBankName({...values, societyId: loginData?.default_community?.community_id, onSuccesCallback: ()=>{resetForm()}}))
          }
          validationSchema={BankNameValidation}
          >    
            {
              (formik)=>{
                return (
                  <Form>
                    <div className="border-b border-[#CCC]">
                      <ListHeaderBack
                        onClick={handleNavigation(-1)}
                        title="Add Bank Account"
                      >
                        <span className="border-r border-[#CCCCCC]"></span>
                        <div className="flex">
                          <Button
                            label={
                              <span className="w-8 inline-block">
                                {
                                  addLoader ?
                                    <CircularProgress sx={{color: "white"}} size={17}/>
                                    :
                                    'Add'
                                  }
                              </span>
                            }
                            className="font-semibold h-8"
                            type={'submit'}
                          />
                        </div>
                      </ListHeaderBack>
                    </div>

                    <div className="px-2">
                      <div className="w-1/2 mt-3">
                          <TextInputFM
                            label="Bank Name:"
                            className="w-56 text-xs h-9"
                            placeholder="Enter"
                            name='bankName'
                            maxLength={30}
                          />
                      </div>
                    </div>
                  </Form>
                  )
                }
              }
          </Formik>
      </div>
    </Layout>
  );
};

export default AddBankAccount;
