import React, { useEffect,useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import ListHeader from "../ListHeader/ListHeader";
import Button from "../Button/Button";
import ButtonG from "../Button/ButtonG";
import Dropdown2 from "../Dropdown/dropdown2";
import Box from "@mui/material/Box";
import Modal from "react-modal";

const customStyles = {
    content: {
      width: "500px",
      height: "200px",
      minHeight: "fit-content",
      background: "#fff",
      border: "1px solid #ccc",
      borderRadius: "8px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      overflow : "visible",
    },
  };
const SelectModel = ({isOpen,onClose,selectList,setSelectId,onSubmit,headTitle,loading}) => {
  //const dispatch = useDispatch();
    return (
        <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
            <div className="bg-white rounded-lg">
              <ListHeader leftContent={headTitle}>
                <span className="border-r border-[#CCC]"></span>
                <ButtonG label="Cancel" type={"button"} onClick={onClose} />
                <Button label="Submit" onClick={onSubmit} disabled={loading} />
              </ListHeader>
            </div>
            <Box sx={{ width: "100%", height: "67%", fontSize: "13px" }}>
              <div className="flex flex-wrap items-center justify-center gap-4 p-2 mt-3">
                  <Dropdown2
                    options={selectList}
                    placeholder="Choose Action"
                    className="text-[11px] ms-4"
                    width="250px"
                    height="32px"
                    onSelect={(selectedItem) => {
                      setSelectId(selectedItem.value)
                      //setAction(selectedItem.value);
                    }}
                  />
                </div>
              
            </Box>
        </Modal>
    )
}
export default SelectModel;