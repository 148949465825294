import * as type from '../../../actions/index.types'

export function staffPersonnelReducer(state = {
  loading: false,
  updateLoader: false,
  ratingDataLoader: false,
  approvalSettingLoader: false,
  staffPersonnelList: null,
  staffProfileList: null,
  staffDetails: null,
  ratingData: null,
  ratingParameters: null,
  wingsFlatData: null,
  printCardData: null,
  idCardFieldsData: null,
  approvalSetting: null,
  error: null
}, action) {

  switch (action.type) {
    case type.GET_SOCIETY_STAFF_LIST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case type.GET_SOCIETY_STAFF_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        staffPersonnelList: action.payload
      }
    case type.GET_SOCIETY_STAFF_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        loading: false,
        staffPersonnelList: null,
      }

    case type.GET_SOCIETY_STAFF_PROFILE_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        staffProfileList: action.payload,
      };
    case type.GET_SOCIETY_STAFF_PROFILE_LIST_FAILURE:
      return {
        ...state,
        staffProfileList: null,
      };


    case type.GET_SOCIETY_STAFF_DETAILS:
      return {
        ...state,
        loading: true,
        error: null
      }
    case type.GET_SOCIETY_STAFF_DETAILS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        staffDetails: action.payload
      }
    case type.GET_SOCIETY_STAFF_DETAILS_FAILURE:
      return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        loading: false,
        staffDetails: null,
      }


    case type.ADD_SOCIETY_STAFF:
      return {
        ...state,
        updateLoader: true,
      }
    case type.ADD_SOCIETY_STAFF_SUCCESS:
      return {
        ...state,
        updateLoader: false,

      }
    case type.ADD_SOCIETY_STAFF_FAILURE:
      return {
        ...state,
        updateLoader: false,
      }


    case type.UPDATE_SOCIETY_STAFF:
      return {
        ...state,
        updateLoader: true,
      }
    case type.UPDATE_SOCIETY_STAFF_SUCCESS:
      return {
        ...state,
        updateLoader: false,

      }
    case type.UPDATE_SOCIETY_STAFF_FAILURE:
      return {
        ...state,
        updateLoader: false,
      }


    case type.DELETE_SOCIETY_STAFF:
      return {
        ...state,
        updateLoader: true
      }
    case type.DELETE_SOCIETY_STAFF_SUCCESS:
      return {
        ...state,
        updateLoader: false,
        staffPersonnelList: {
          ...state.staffPersonnelList,
          staff_list: state.staffPersonnelList?.staff_list?.filter(item => !(action.payload?.staffId === item?.id && action.payload.staffLevelId === item.staff_level_id))
        }
      }
    case type.DELETE_SOCIETY_STAFF_FAILURE:
      return {
        ...state,
        updateLoader: false
      }

    case type.GET_SOCIETY_WING_FLAT_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        wingsFlatData: action.payload
      }
    case type.GET_SOCIETY_WING_FLAT_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        wingsFlatData: null,
      }


    case type.PRINT_SOCIETY_STAFF_CARD:
      return {
        ...state,
        loading: true,
        error: null
      }
    case type.PRINT_SOCIETY_STAFF_CARD_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        printCardData: action.payload
      }
    case type.PRINT_SOCIETY_STAFF_CARD_FAILURE:
      return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        loading: false,
        printCardData: null,
      }

    case type.GET_SOCIET_STAFF_RATING_DATA:
      return {
        ...state,
        ratingDataLoader: true,
        error: null
      }
    case type.GET_SOCIET_STAFF_RATING_DATA_SUCCESS:
      return {
        ...state,
        error: null,
        ratingDataLoader: false,
        ratingData: action.payload
      }
    case type.GET_SOCIET_STAFF_RATING_DATA_FAILURE:
      return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        ratingDataLoader: false,
        ratingData: null,
      }

    case type.GET_SOCIET_STAFF_RATING_PARAMETERS:
      return {
        ...state,
        error: null
      }
    case type.GET_SOCIET_STAFF_RATING_PARAMETERS_SUCCESS:
      return {
        ...state,
        error: null,
        ratingParameters: action.payload
      }
    case type.GET_SOCIET_STAFF_RATING_PARAMETERS_FAILURE:
      return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        ratingParameters: null,
      }



    case type.UPDATE_SOCIET_STAFF_RATING_DATA:
      return {
        ...state,
        updateLoader: true,
      }
    case type.UPDATE_SOCIET_STAFF_RATING_DATA_SUCCESS:
      const list = state.staffPersonnelList?.staff_list
      let objIndex = list?.findIndex(obj => obj.id === action.payload?.staffId);

      let ratingTotal = 0, length = 0;
      if (action.payload?.ratingData) {
        ratingTotal = action.payload?.ratingData?.rating?.reduce((total, next) => {
          if (next.rating_id !== action.payload.ratingId) {
            length++;
            return total + next.avg_rating
          }
          return total;
        }, 0)
      }
      list[objIndex].rating = (ratingTotal + action.payload.avgRating) / (length + 1)
      return {
        ...state,
        updateLoader: false,
        staffPersonnelList: {
          ...state.staffPersonnelList,
          staff_list: list
        }

      }
    case type.UPDATE_SOCIET_STAFF_RATING_DATA_FAILURE:
      return {
        ...state,
        updateLoader: false,
      }

    case type.SEND_STAFF_CODE_SMS_TO_STAFF:
      return {
        ...state,
        updateLoader: true,
      }
    case type.SEND_STAFF_CODE_SMS_TO_STAFF_SUCCESS:
      return {
        ...state,
        updateLoader: false,

      }
    case type.SEND_STAFF_CODE_SMS_TO_STAFF_FAILURE:
      return {
        ...state,
        updateLoader: false,
      }

    case type.GET_ID_CARD_FIELDS_DATA_SUCCESS:
      return {
        ...state,
        error: null,
        idCardFieldsData: action.payload
      }
    case type.GET_ID_CARD_FIELDS_DATA_FAILURE:
      return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        idCardFieldsData: null,
      }

    case type.UPDATE_ID_CARD_FIELDS_DATA:
      return {
        ...state,
        updateLoader: true,
      }
    case type.UPDATE_ID_CARD_FIELDS_DATA_SUCCESS:
      return {
        ...state,
        updateLoader: false,
        idCardFieldsData: { ...action.payload.idData }
      }
    case type.UPDATE_ID_CARD_FIELDS_DATA_FAILURE:
      return {
        ...state,
        updateLoader: false,
      }


    case type.GET_APPROVAL_SETTINGS_DATA:
      return {
        ...state,
        approvalSettingLoader: true,
        error: null
      }
    case type.GET_APPROVAL_SETTINGS_DATA_SUCCESS:
      return {
        ...state,
        error: null,
        approvalSettingLoader: false,
        approvalSetting: action.payload
      }
    case type.GET_APPROVAL_SETTINGS_DATA_FAILURE:
      return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        approvalSettingLoader: false,
        approvalSetting: null,
      }



    case type.UPDATE_APPROVAL_SETTINGS_DATA:
      return {
        ...state,
        updateLoader: true,
      }
    case type.UPDATE_APPROVAL_SETTINGS_DATA_SUCCESS:
      return {
        ...state,
        updateLoader: false,
      }
    case type.UPDATE_APPROVAL_SETTINGS_DATA_FAILURE:
      return {
        ...state,
        updateLoader: false,
      }

    case type.UPDATE_APPROVAL_STATUS_DATA:
      return {
        ...state,
        updateLoader: true,
      }
    case type.UPDATE_APPROVAL_STATUS_DATA_SUCCESS:
      return {
        ...state,
        updateLoader: false,
        staffPersonnelList: {
          ...state.staffPersonnelList,
          staff_list: state.staffPersonnelList?.staff_list?.map((item) => action.payload.staff_id == item.id
            ? { ...item, approval_status: action.payload.approval_status, approval_comments: action.payload.approval_comments }
            : item
          )
        }
      }
    case type.UPDATE_APPROVAL_STATUS_DATA_FAILURE:
      return {
        ...state,
        updateLoader: false,
      }



    default:
      return state
  }
}