import { call, put, takeLatest } from 'redux-saga/effects'
import * as types from '../../actions/index.types'
import * as req from '../../../axios/request/FinanceReqs/MyInvoiceReqs'


function* getMyInvoiceList(action) {
    try {
        const result = yield call(req.getMyInvoiceListReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_MY_INVOICE_LIST_SUCCESS, payload: result});  
        } else {
            yield put({ type: types.GET_MY_INVOICE_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
  }

  function* getInvoiceStatusList(action) {
    try {
        const result = yield call(req.getInvoiceStatusListReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_MY_INVOICE_STATUS_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_MY_INVOICE_STATUS_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getInvoicePaymentList(action) {
    try {
        const result = yield call(req.getMyInvoicePaymentListReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_MY_INVOICE_PAYMENT_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_MY_INVOICE_PAYMENT_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getSingleInvoiceList(action) {
    try {
        const result = yield call(req.getSingleInvoiceReq, action);
        if (result.success === true) {
            yield put({ type: types.SINGLE_INVOICE_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.SINGLE_INVOICE_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* printMyInvoiceReceipt(action) {
    try {
        const result = yield call(req.getReceiptReq, action);
        yield put({ type: types.GET_INVOICE_RECEIPT_SUCCESS, payload: result}); 
        action.data?.onSuccessCallback() 
    } catch (e) {
        yield put({ type: types.GET_INVOICE_RECEIPT_FAILURE, message: e });
        console.log(e)
    }
}

function* printMyInvoiceBill(action) {
    try {
        const result = yield call(req.getInvoiceReq, action);
        yield put({ type: types.GET_MY_INVOICE_PRINT_INVOICE_SUCCESS, payload: result});  
        action.data?.onSuccessCallback()
    } catch (e) {
        yield put({ type: types.GET_MY_INVOICE_PRINT_INVOICE_FAILURE, message: e });
        console.log(e)
    }
}

  export default function* myInvoiceSaga() {
    yield takeLatest(types.GET_MY_INVOICE_LIST, getMyInvoiceList);
    yield takeLatest(types.GET_MY_INVOICE_STATUS_LIST, getInvoiceStatusList);
    yield takeLatest(types.GET_MY_INVOICE_PAYMENT_LIST, getInvoicePaymentList);
    yield takeLatest(types.SINGLE_INVOICE_LIST, getSingleInvoiceList);
    yield takeLatest(types.GET_INVOICE_RECEIPT, printMyInvoiceReceipt);
    yield takeLatest(types.GET_MY_INVOICE_PRINT_INVOICE, printMyInvoiceBill);
}