import * as type from "../../../actions/index.types";

export function generateInvoiceReducer(
  state = {
    loading: false,
    printLoader: false,
    updateLoader: false,
    uploadLoader : false,
    billPeriod: null,
    invoiceGenerator: null,
    creditNoteAdjustment: null,
    debitNoteAdjustment: null,
    excelGenerateInvoiceData: null,
    error: null,
  },
  action
) {
  switch (action.type) {
    case type.GET_BILL_PERIOD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_BILL_PERIOD_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        billPeriod: action.payload,
      };
    case type.GET_BILL_PERIOD_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        billPeriod: null,
      };
    
    case type.INVOICE_GENERATOR_LIST:
    return {
        ...state,
        loading: true,
        error: null,
        updateLoader: true
    };
    case type.INVOICE_GENERATOR_LIST_SUCCESS:
    return {
        ...state,
        error: null,
        loading: false,
        updateLoader: false,
        invoiceGenerator: action.payload,
    };
    case type.INVOICE_GENERATOR_LIST_FAILURE:
    return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        updateLoader: false,
        invoiceGenerator: null,
    };
        
    case type.GENERATE_INVOICE:
    return {
        ...state,
        updateLoader: true
    }
    case type.GENERATE_INVOICE_SUCCESS:
    return {
        ...state,
        updateLoader: false
        }

    case type.GENERATE_INVOICE_FAILURE:
    return {
        ...state,
        updateLoader: false
        }

    case type.GET_CREDIT_NOTE_ADJUSTMENT:
      return {
        ...state,
        error: null,
        creditnoteLoader : true,
        creditNoteAdjustment: null
      };
    case type.GET_CREDIT_NOTE_ADJUSTMENT_SUCCESS:
      return {
        ...state,
        error: null,
        creditnoteLoader : false,
        creditNoteAdjustment: action.payload,
      };
    case type.GET_CREDIT_NOTE_ADJUSTMENT_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        creditnoteLoader : false,
        creditNoteAdjustment: null,
      };

      case type.GET_DEBIT_NOTE_ADJUSTMENT:
      return {
        ...state,
        error: null,
        debitNoteAdjustment: null
      };
    case type.GET_DEBIT_NOTE_ADJUSTMENT_SUCCESS:
      return {
        ...state,
        error: null,
        debitNoteAdjustment: action.payload,
      };
    case type.GET_DEBIT_NOTE_ADJUSTMENT_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        debitNoteAdjustment: null,
      };

      case type.UPLOAD_GENERATE_INVOICE_EXCEL:
      return {
        ...state,
        uploadLoader: true,
        error: null,
      };
    case type.UPLOAD_GENERATE_INVOICE_EXCEL_SUCCESS:
      return {
        ...state,
        error: null,
        uploadLoader: false,
        invoiceGenerator: action.payload,
      };
    case type.UPLOAD_GENERATE_INVOICE_EXCEL_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        uploadLoader: false,
        invoiceGenerator: null,
      };

    case type.SAVE_GENERATE_INVOICE_EXCEL:
      return {
        ...state,
        uploadLoader: true,
      };
    case type.SAVE_GENERATE_INVOICE_EXCEL_SUCCESS:
      return {
        ...state,
        uploadLoader: false,
      };
    case type.SAVE_GENERATE_INVOICE_EXCEL_FAILURE:
      return {
        ...state,
        uploadLoader: false,
      };
    default:
      return state;
  }
}
