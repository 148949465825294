import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getBookingDetails, getBookingInvoice } from "../../../redux/actions/ClubActions/ViewBookingsAction";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import moment from "moment";
import { toWords } from 'number-to-words'
import { validateDate } from "../../../utils/helpers/universalFunctions";



function PrintBookingInvoice() {
  const {id} = useParams()
  const dispatch = useDispatch()
  const {loading, invoiceDetails} = useSelector(state=>state.viewBookingReducer)
  const {loginData} = useSelector(state=>state.loginReducer)

  useEffect(()=>{
    dispatch(getBookingInvoice({invoiceId: id, societyId: loginData?.default_community?.community_id}))
  }, [])


  useEffect(() => {
    if(!loading && invoiceDetails?.data){
    //   window.print()
    }
  }, [loading]);

  return (
    <div className="mx-5 my-8">
        {
            loading?
            <Loader/>
            :
            <>
                <div className="text-center text-sm">
                    <div className="underline text-xl font-bold">{loginData?.default_community?.community_name}</div>
                    <div>(REGN. NO. {loginData?.default_community?.regn_no}   Dated -{moment(loginData?.default_community?.date_of_regn)?.format('DD-MM-YYYY')})</div>
                    <div>{loginData?.default_community?.office_address}</div>
                    <div>GST Registration No. : {loginData?.default_community?.gst_registration_no}</div>
                </div>
                <div>
                    <div className="font-bold text-center mt-8">{invoiceDetails?.data?.facilities_name}</div>
                    <div className="flex justify-between">
                        <div><span className="font-bold">Member Name: </span>{invoiceDetails?.data?.membername}</div>
                        <div><span className="font-bold">Invoice Number: </span>{invoiceDetails?.data?.invoice_no}</div>
                    </div>
                    <div className="flex justify-between">
                        <div><span className="font-bold">Wing & Flat No: </span>{invoiceDetails?.data?.wing_no} - {invoiceDetails?.data?.flat_no}</div>
                        <div><span className="font-bold">Invoice Date: </span>{moment(invoiceDetails?.data?.invoice_date).format('DD-MM-YYYY') || "-"}</div>
                    </div>
                    <table className="w-full mt-3 text-sm">
						<tbody className='border border-black'>
                            <tr>
                                <td colspan="2">
                                    <table width="100%" border="1" cellspacing="0" class="dtlstb">
                                        <tbody><tr height="30" valign="top">
                                            <th className='px-2 border border-black' width="45%">Item Description</th>
                                            <th className='px-2 border border-black' align="center" width="15%">Unit Price(Rs.)</th>
                                            <th className='px-2 border border-black' align="center" width="15%">No. of days</th>
                                            <th className='px-2 border border-black' align="center" width="15%">Amount (Rs.)</th>
                                        </tr>
                                        <tr height="30" valign="top">
                                            <td align="left">
                                                <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                                    <tbody>
                                                        <tr class="tbrow">
                                                            <td className='px-2 border border-black'>Booking Frequency</td>
                                                            <td className='px-2 border border-black'> {invoiceDetails?.data?.booking_frequency}</td>
                                                        </tr>
                                                        {
                                                            invoiceDetails?.data?.booking_frequency === 'Weekly' &&
                                                            <tr class="tbrow">
                                                                <td width="30%" className='px-2 border border-black'>Book Every</td>
                                                                <td className='px-2 border border-black'> {invoiceDetails?.data?.booking_day?.replaceAll(',', ', ') || "-"}</td>
                                                            </tr>
                                                        }
                                                        {
                                                            invoiceDetails?.data?.booking_frequency === 'One Time' ?
                                                            <tr class="tbrow">
                                                                <td width="30%" className='px-2 border border-black'>Date Of Booking</td>
                                                                <td className='px-2 border border-black'> {moment(invoiceDetails?.data?.from_date).format('DD-MM-YYYY') || "-"}</td>
                                                            </tr>
                                                            :
                                                            <tr class="tbrow">
                                                                <td width="30%" className='px-2 border border-black'>Date Range</td>
                                                                <td className='px-2 border border-black'> 
                                                                    {
                                                                        `From: ${moment(invoiceDetails?.data?.from_date).format('DD-MM-YYYY')} - To: ${moment(invoiceDetails?.data?.to_date).format('DD-MM-YYYY')}` || "-"
                                                                    }
                                                                </td>
                                                            </tr>
                                                        }
                                                        <tr class="tbrow">
                                                            <td className='px-2 border border-black'>Time-slot</td>
                                                            <td className='px-2 border border-black'> 
                                                            {
                                                                invoiceDetails?.data?.slot_type === 'hours' ?
                                                                invoiceDetails?.data?.time_slot
                                                                :
                                                                `${moment(invoiceDetails?.data?.time_slot?.slice(0, 9), 'hh:mm:ss').format('hh:mm A')} - ${moment(invoiceDetails?.data?.time_slot?.slice(12), 'hh:mm:ss').format('hh:mm A')} (${invoiceDetails?.data?.facilities_type})`
                                                            }
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>				
                                            <td className='px-2 border border-black' align="center" valign="top">{invoiceDetails?.data?.unit_price && (invoiceDetails?.data?.unit_price !=='Not Chargable') ?parseFloat(invoiceDetails?.data?.unit_price)?.toFixed(2)  : '0.00'}</td>	
                                            <td className='px-2 border border-black' align="center" valign="top">
                                                {(validateDate(invoiceDetails?.data?.from_date) && validateDate(invoiceDetails?.data?.to_date)) ? Math.ceil(moment(invoiceDetails?.data?.to_date)?.diff(invoiceDetails?.data?.from_date, 'days', true)) : '-'}
                                            </td>
                                            <td className='px-2 border border-black' align="center" valign="top">{invoiceDetails?.data?.booking_amount?.toFixed(2)}</td>			
                                        </tr>
                                        <tr>
                                            <td rowspan="10" className='px-2 border border-black' align="center" valign="top"></td>
                                            <td className='px-2 border border-black' colspan="2" align="right" valign="top">Sub Total</td>
                                            <td className='px-2 border border-black' align="center" valign="top">{parseFloat(invoiceDetails?.data?.booking_amount)?.toFixed(2)}</td>
                                        </tr>
                                                                        
                                        <tr>
                                            <td className='px-2 border border-black' colspan="2" align="right" valign="top">Security Deposit Amount</td>
                                            <td className='px-2 border border-black' align="center" valign="top">{invoiceDetails?.data?.security_deposit ? parseFloat(invoiceDetails?.data?.security_deposit)?.toFixed(2) : 0.00}</td>
                                        </tr>
                                        <tr>
                                            <td className='px-2 border border-black' colspan="2" align="right" valign="top">Grand Total </td>
                                            <td className='px-2 border border-black' align="center" valign="top">{parseFloat(invoiceDetails?.data?.total_amount)?.toFixed(2)}</td>
                                        </tr>
                                    </tbody>
                                </table>
							</td>
						</tr>
					</tbody>
                    </table>
                </div>
            </>
        }
    </div>
  );
}

export default PrintBookingInvoice;
