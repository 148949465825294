import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Layout from "../../../Layout/Layout";
import { Arrow } from "../../../../assets";
import { OCCUPANTS_INFO_ENDPOINT } from "../../../../Routing/routes";
import Button from "../../../../components/Button/Button";
import ButtonG from "../../../../components/Button/ButtonG";
import Search from "../../../../components/SearchBox/Search";
import MembersPrivacyTable from "./MembersPrivacyTable";
import { useDispatch, useSelector } from "react-redux";
import { getMemberPrivacy } from "../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/OccupantsInfoAction";
import { CircularProgress } from "@mui/material";

const MembersPrivacy = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [resetClick, setResetClick] = useState(undefined)
  const [saveClick, setSaveClick] = useState(undefined)
  const [search, setSearch] = useState('')
  const {updateLoader, memberPrivacyData} = useSelector(state=>state.occupantInfoReducer)

  const [searchParams, setSearchParams] = useSearchParams()
  const subscribeIds = [...searchParams.entries()]?.map((item)=>{
    if(item[0]==='subId')
      return item[1]
  })

  const handleSearch=(value)=>{
    setSearch(value)
  }


  const handleBackClick = () => {
    navigate(OCCUPANTS_INFO_ENDPOINT);
  };

  useEffect(()=>{
    dispatch(getMemberPrivacy({subscribeIds}))
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="flex justify-between gap-5 pb-2">
          <div className="flex w-1/2">
            <button
              onClick={handleBackClick}
              className="text-sm p-2 text-red-650 font-semibold flex gap-2 justify-center items-center"
            >
              <span>
                <Arrow />
              </span>
              Back
            </button>
            <span className="border-r border-[#CCCCCC]"></span>
            <h1 className="text-sm font-semibold p-2">Members Privacy</h1>
          </div>
          <div className="flex gap-2">
            <div className="items-center flex">
              <Search onChange={handleSearch} />
            </div>
            <div className="border-r"></div>
            <div className="gap-2 flex items-center">
              <ButtonG label="Reset" className="px-4 py-2" type='button' onClick={resetClick} />
              <Button label={
                  <span className="w-8 inline-block">
                    {
                      updateLoader ?
                        <CircularProgress sx={{color: "white"}} size={17}/>
                        :
                        'Save'
                      }
                  </span>
                } 
                disabled={!memberPrivacyData?.length}
                className="px-4 py-2" type='button' onClick={saveClick}
                />
            </div>
          </div>
        </div>
        <div className="border-b border-[#CCCCCC]"></div>
        <MembersPrivacyTable 
          setResetClick={setResetClick}
          setSaveClick={setSaveClick}
          searchText={search}
        />
      </div>
    </Layout>
  );
};

export default MembersPrivacy;
