import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {notify} from '../../../../utils/notification'
import * as types from '../../../actions/index.types'
import * as req from '../../../../axios/request/SetupRecordsReqs/BuildingReqs/BankDetailsReqs'

function* getBankDetailsTypeAndNameList(action) {
    try {
        const result = yield call(req.getBankDetailsAndTypeListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_BANK_DETAIL_AND_TYPE_LIST_SUCCESS, payload: {flag: action.data.flag, list: result.data}});  
        } else {
            yield put({ type: types.GET_BANK_DETAIL_AND_TYPE_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getBankDetails(action) {
    try {
        const result = yield call(req.getBankDetailsReq);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_BANK_DETAILS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_BANK_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* addBankDetails(action) {
    try {
        const result = yield call(req.addBankDetailsReq, action);
        if (result.statusCode === 200) {
            notify('success', result.message);
            action.data?.onSucessCallback()
            yield put({ type: types.ADD_BANK_DETAILS_SUCCESS});  
        } else {
            yield put({ type: types.ADD_BANK_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getBankDetailsAuditLog(action) {
    try {
        const result = yield call(req.getBankDetailsAuditLogReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_BANK_DETAILS_AUDIT_LOGS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_BANK_DETAILS_AUDIT_LOGS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}


function* deleteBankData(action) {
    try {
        const result = yield call(req.deleteBankDataReq, action);
        if (result.statusCode === 200) {
            notify('success', result.message);
            action.data?.onSuccessCallback?.()
        }
    } catch (e) {
        console.log(e)
    }
}

function* getBankNameList(action) {
    try {
        const result = yield call(req.getBankNameReq);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_BANK_NAME_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_BANK_NAME_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* addBankName(action) {
    try {
        const result = yield call(req.addBankNameReq, action);
        if (result.statusCode === 200) {
            notify('success', result.message);
            action.data?.onSuccesCallback()
            yield put({ type: types.ADD_BANK_NAME_SUCCESS});  
        } else {
            yield put({ type: types.ADD_BANK_NAME_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

export default function* bankDetailsSaga() {
    yield takeEvery(types.GET_BANK_DETAIL_AND_TYPE_LIST, getBankDetailsTypeAndNameList);
    yield takeLatest(types.GET_BANK_DETAILS, getBankDetails);
    yield takeLatest(types.ADD_BANK_DETAILS, addBankDetails);
    yield takeLatest(types.DELETE_BANK_DATA, deleteBankData);
    yield takeLatest(types.GET_BANK_NAME_LIST, getBankNameList);
    yield takeLatest(types.ADD_BANK_NAME, addBankName);
    yield takeLatest(types.GET_BANK_DETAILS_AUDIT_LOGS, getBankDetailsAuditLog);
}