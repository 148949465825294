import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import { TextInputFM } from "../../../components/InputFields/TextInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { BANK_DETAILS_AUDIT_LOG_ENDPOINT, CREATE_BANK_ACCOUNT_ENDPOINT } from "../../../Routing/routes";
import ListHeader from "../../../components/ListHeader/ListHeader";
import { DropdownFM2 } from "../../../components/Dropdown/dropdown2";
import { FieldArray, Form, Formik } from "formik";
import { BankDetailsValidation } from "../../../validationSchema/setupRecordsSchema/buildingSchema";
import { useDispatch, useSelector } from "react-redux";
import { addBankDetails, deleteBankData, getBankDetailSAndTypeList, getBankDetails, getBankNameList } from "../../../redux/actions/SetupRecordsActions/BuildingActions/BankDetailsAction";
import Loader from "../../../components/Loader/Loader";
import { CircularProgress } from "@mui/material";
import * as permissions from '../../../Routing/permissions'
import { getPermission } from "../../../utils/helpers/getPermissions";
import DeleteModal from "../../../components/Modal/DeleteModal";

const BankDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { loading, updateLoader, bankDetailsData } = useSelector(state => state.bankDetailsReducer)
  const { ipAddress } = useSelector(state => state.ipAddressReducer)
  const { loginData } = useSelector(state => state.loginReducer)

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleAddBankClick = (arrayHelpers) => {
    arrayHelpers.push({
      details: '',
      detailsId: '',
      bankName: '',
      bankNameId: '',
      accountType: '',
      accountTypeId: '',
      accountNumber: '',
      ifscCode: '',
      micrCode: '',
      chequeReturnCharges: '',
    })
  };

  const handleDelete = (arrayHelpers, index, bank) => {
    if (bank.societyBankDetailId) {
      dispatch(deleteBankData({
        id: bank.societyBankDetailId,
        flag: 'bank',
        ipAddress,
        sessionId: loginData?.session_id,
        onSuccessCallback: () => {
          arrayHelpers.remove(index);
        }
      }))
    } else {
      arrayHelpers.remove(index);
    }
  }

  useEffect(() => {
    dispatch(getBankDetails())
    dispatch(getBankNameList())
    dispatch(getBankDetailSAndTypeList({ flag: 'detail' }))
    dispatch(getBankDetailSAndTypeList({ flag: 'type' }))
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          enableReinitialize
          initialValues={{
            bankData: bankDetailsData ? bankDetailsData?.map((item) => ({
              societyBankDetailId: item.society_bank_details_id,
              details: item.details || '',
              detailsId: item.detail_id,
              bankName: item.bank_name || '',
              bankNameId: item.bank_name_id,
              accountType: item.type || '',
              accountTypeId: item.type_id,
              accountNumber: item.account_no || '',
              ifscCode: item.ifsc_code || '',
              micrCode: item.micr_code || '',
              chequeReturnCharges: item.cheque_return_charges,
            })) : []
          }}
          onSubmit={values =>
            dispatch(addBankDetails({ ...values, ipAddress, sessionId: loginData?.session_id, onSucessCallback: () => { dispatch(getBankDetails()) } }))
          }
          validationSchema={BankDetailsValidation}
        >
          {
            ({ values, setFieldValue, dirty }) => {
              return (
                <Form>
                  <div className="border-b border-[#CCC]">
                    <ListHeader leftContent="Bank Details">
                      <div className="flex gap-2">
                        {
                          getPermission(loginData, [permissions.VIEW_BANK_DETAILS_AUDIT_LOG_PERMISSION])
                          &&
                          <Button
                            label="Audit Log"
                            onClick={handleNavigation(BANK_DETAILS_AUDIT_LOG_ENDPOINT)}
                            className="h-8 px-4 font-semibold"
                            type='button'
                          />
                        }
                        {getPermission(loginData, [permissions.VIEW_BANK_ACCOUNTS_PERMISSION]) && <Button label="Create Bank Account" onClick={handleNavigation(CREATE_BANK_ACCOUNT_ENDPOINT)} type='button' />}
                        {
                          getPermission(loginData, [permissions.ADD_BANK_DETAILS_PERMISSION])
                          &&
                          <>
                            <div className="border-r"></div>
                            <Button
                              label={
                                <span className="inline-block w-8">
                                  {
                                    updateLoader ?
                                      <CircularProgress sx={{ color: "white" }} size={17} />
                                      :
                                      'Save'
                                  }
                                </span>
                              }
                              className="h-8 px-4 font-semibold"
                              type='submit'
                              disabled={loading || updateLoader || !dirty}
                            />
                          </>
                        }
                      </div>
                    </ListHeader>
                  </div>
                  <div className="flex flex-wrap items-center justify-center gap-4 p-2 mt-3">
                    {
                      loading && !bankDetailsData
                        ?
                        <Loader />
                        :
                        <FieldArray
                          name="bankData"
                          render={arrayHelpers => (
                            <>
                              {
                                // values.bankData && values.bankData.length > 0 ? 

                                (
                                  values.bankData.map((bank, index) => (
                                    <BankBox
                                      values={values}
                                      setFieldValue={setFieldValue}
                                      key={index}
                                      index={index}
                                      onDelete={() => { handleDelete(arrayHelpers, index, bank) }}
                                    />
                                  )))
                              }
                              {
                                getPermission(loginData, [permissions.ADD_BANK_DETAILS_PERMISSION])
                                &&
                                <div class="overflow-hidden rounded-lg w-[49%] h-[430px] flex items-center justify-center">
                                  <button
                                    className="text-red-650 font-semibold border-2 border-dashed border-red-650 w-[100%] h-[155px] p-2 bg-[#FDF6F6] flex justify-center rounded-md items-center"
                                    onClick={() => { handleAddBankClick(arrayHelpers) }}
                                    type='button'
                                  >
                                    Add More Bank
                                  </button>
                                </div>
                              }
                            </>
                          )
                          }
                        >
                        </FieldArray>
                    }
                  </div>
                </Form>
              )
            }
          }
        </Formik>


      </div>
    </Layout>
  );
};

const BankBox = ({ values, index, setFieldValue, onDelete }) => {
  const { bankDetailList, bankTypeList } = useSelector(state => state.bankDetailsReducer)
  const { bankNameList } = useSelector(state => state.bankNameReducer)
  const { loginData } = useSelector(state => state.loginReducer)
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <div className="border border-[#CCC] overflow-hidden rounded-lg w-[49%] h-[430px]">
      <h2 className="border-b p-3 text-sm font-semibold bg-[#FDF2F3] flex justify-between items-center">
        <span>S No.: {index + 1}</span>
        {
          getPermission(loginData, [permissions.DELETE_BANK_DETAILS_PERMISSION])
          &&
          <button
            className="font-semibold text-red-650"
            onClick={() => setShowDeleteModal(true)}
            type='button'
          >
            <span className="mr-2">
              <FontAwesomeIcon icon={faTrashCan} />
            </span>
            Delete
          </button>
        }
      </h2>
      <div className="items-center px-2">
        <div className="flex justify-between mt-3">
          <span className="mt-2 text-sm">Details</span>
          <DropdownFM2
            options={bankDetailList?.map((item) => (
              {
                label: item.details,
                value: item.id
              }
            )) || []}
            width="224px"
            height="36px"
            placeholder="Select Details"
            onSelect={(selectedItem) => {
              setFieldValue(`bankData.${index}.details`, selectedItem.label)
              setFieldValue(`bankData.${index}.detailsId`, selectedItem.value)
            }}
            value={values.bankData[index].details && { label: values.bankData[index].details, value: values.bankData[index].detailsId }}
            name={`bankData.${index}.details`}
          />
        </div>
        <div className="flex">
          <span className="text-sm w-[227px] mt-2">Bank Name</span>
          {
            values.bankData[index].societyBankDetailId ?
              <div className="h-[44px] mt-2 text-xs font-semibold">{values.bankData[index].bankName}</div>
              :
              <DropdownFM2
                options={bankNameList?.map((item) => (
                  {
                    label: item.account,
                    value: item.bank_name_id
                  }
                )) || []}
                width="224px"
                height="36px"
                placeholder="Select Bank name"
                onSelect={(selectedItem) => {
                  setFieldValue(`bankData.${index}.bankName`, selectedItem.label)
                  setFieldValue(`bankData.${index}.bankNameId`, selectedItem.value)
                }}
                value={values.bankData[index].bankName && { label: values.bankData[index].bankName, value: values.bankData[index].bankNameId }}
                name={`bankData.${index}.bankName`}
              />
          }
        </div>
        <div className="flex">
          <span className="text-sm w-[227px] mt-2">Account Type</span>
          {
            values.bankData[index].societyBankDetailId ?
              <div className="h-[44px] mt-2 text-xs font-semibold">{values.bankData[index].accountType}</div>
              :
              <DropdownFM2
                options={bankTypeList?.map((item) => (
                  {
                    label: item.type,
                    value: item.id
                  }
                )) || []}
                width="224px"
                height="36px"
                placeholder="Select Type"
                onSelect={(selectedItem) => {
                  setFieldValue(`bankData.${index}.accountType`, selectedItem.label)
                  setFieldValue(`bankData.${index}.accountTypeId`, selectedItem.value)
                }}
                value={values.bankData[index].accountType && { label: values.bankData[index].accountType, value: values.bankData[index].accountTypeId }}
                name={`bankData.${index}.accountType`}
              />
          }
        </div>
        <TextInputFM
          label="Bank Account Number:"
          placeholder="Enter Bank Account Number"
          className="w-56 font-semibold h-9"
          name={`bankData.${index}.accountNumber`}
          type='number'
          maxLength={14}
        />
        <TextInputFM
          label="IFSC Code:"
          placeholder="Enter IFSC Code"
          className="w-56 font-semibold h-9"
          name={`bankData.${index}.ifscCode`}
          type='alphaNumber'
          maxLength={11}
        />
        <TextInputFM
          label="MICR Code:"
          placeholder="Enter MICR Code"
          className="w-56 h-9"
          name={`bankData.${index}.micrCode`}
          type={'number'}
          maxLength={9}
        />
        <TextInputFM
          label="Cheque Return Charges (Rs.)"
          placeholder="Enter Amount"
          className="w-56 h-9"
          maxLength={15}
          name={`bankData.${index}.chequeReturnCharges`}
          type='decimal'
        />
      </div>
      <DeleteModal
        isOpen={showDeleteModal}
        title={'Delete Bank Detail'}
        text={'Do you really want to delete this bank detail?'}
        onCancel={() => setShowDeleteModal(false)}
        onDelete={onDelete}
      />
    </div>
  );
};

export default BankDetails;
