import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import parse from 'html-react-parser';
import Loader from '../../../../components/Loader/Loader'
import { printMyInvoiceBill } from '../../../../redux/actions/FinanceActions/MyInvoiceActions';

export function PrintInvoiceBillMulti() {
    const data = useLocation().state;
    const dispatch = useDispatch()
    const {loginData} = useSelector(state=>state.loginReducer)
    const { printInvoice, printLoader } = useSelector(
        (state) => state.myInvoiceReducer
      );
  
    useEffect(()=>{
        dispatch(printMyInvoiceBill({
            societyId: loginData?.default_community?.community_id,
            invid: data.invid,
            onSuccessCallback: ()=>{
                setTimeout(function() {
                    window.print()
                }, 5000); 
            }
        }))
    }, [])
   

    return (
    <div>
        {
            printLoader || !printInvoice ?
            <Loader/>
            :
            <>
                {parse(printInvoice)}
            </>
        }
    </div>
  )
}

function PrintInvoiceBill() {
    const {id} = useParams()
    const dispatch = useDispatch()
    const {loginData} = useSelector(state=>state.loginReducer)
    const { printInvoice, printLoader } = useSelector(
        (state) => state.myInvoiceReducer
      );
  
    useEffect(()=>{
        dispatch(printMyInvoiceBill({
            societyId: loginData?.default_community?.community_id,
            invid: [parseInt(id,)],
            onSuccessCallback: ()=>{
                setTimeout(function() {
                    window.print()
                }, 5000); 
            }
        }))
    }, [])
   
    return (
    <div>
        {
            printLoader || !printInvoice ?
            <Loader/>
            :
            <>
                {parse(printInvoice)}
            </>
        }
    </div>
  )
}

export default PrintInvoiceBill