import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OCCUPANTS_INFO_ENDPOINT } from "../../../../Routing/routes";
import Layout from "../../../Layout/Layout";
import Button from "../../../../components/Button/Button";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import {TextInputFM } from "../../../../components/InputFields/TextInput";
import TextRadio from "../../../../components/InputFields/TextRadio";
import ButtonG from "../../../../components/Button/ButtonG";
import ProfilePicture from "../../../../components/ProfilePicture/ProfilePicture";
import {UploadButtonFM} from "../../../../components/Button/UploadButton";
import countries from "../../../../consts/countries";
import { genderOptions, namePrefixOptions, registerAsOptions } from "../../../../consts/DropDownOptions";
import { Form, Formik } from "formik";
import { AddOccupantsValidation } from "../../../../validationSchema/setupRecordsSchema/occupants&TenantsSchema";
import { useDispatch, useSelector } from "react-redux";
import { DropdownFM2 } from "../../../../components/Dropdown/dropdown2";
import { CircularProgress } from "@mui/material";
import {addOccupant, getTitleList} from '../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/OccupantsInfoAction'
import { getFlatDetails, getWingsAndFlatDetails } from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";


const AddOccupants = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {wingsData,flatsData} = useSelector(state=>state.wingsReducer)
  const {loginData} = useSelector(state=>state.loginReducer)
  const {ipAddress} = useSelector(state=>state.ipAddressReducer)
  const {updateLoader, titleList} = useSelector(state=>state.occupantInfoReducer)
  const [buttonType, setButtonType] = useState('')
  const handleCancelClick = () => {
    navigate(OCCUPANTS_INFO_ENDPOINT);
  };

  const handleSaveClick = async(values, {resetForm, initialValues}) => {
    dispatch(addOccupant({
      ...values, 
      ipAddress,
      societyId: loginData?.default_community?.community_id,
      sessionId: loginData?.session_id,
      onSuccessCallback: buttonType==='Save' ? ()=>{
        navigate(OCCUPANTS_INFO_ENDPOINT)
      }
      :
      ()=>{
        resetForm()
      }
    }))
  };

  useEffect(()=>{
    const societyId= loginData?.default_community?.community_id
    dispatch(getTitleList({societyId}))
    dispatch(getWingsAndFlatDetails({societyId}))
  }, [])

  return (
    <Layout>
      <div className="flex flex-col gap-2 w-full">
        <Formik
          initialValues={{
            titleId: '',
            prefix: '',
            firstName: '',
            middleName: '',
            lastName: '',
            gender: '',
            registerAs: 'main',
            countryId: '',
            mobileCountryCode: '',
            countryISO: '',
            mobileNo: '',
            secondaryMobileNo: '',
            mobileVisible:  'No',
            sendeSMSOnAlternateNo:  'No',
            primaryEmail:   '',
            secondaryEmail:  '',
            emailVisible:  'No',
            loginAccess:  false,
            sendeSMSOnAlternateEmail:  'No',
            adharCardNo: '',
            adharFile: '',
            wingId: '',
            flatId: '',
            profilePic: ''
          }}
          onSubmit={handleSaveClick}
          validationSchema={AddOccupantsValidation}
          >    
          {
            ({values, setFieldValue})=>{
              return (
                <Form>
                  <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg p-2">
                    <div className="flex justify-between border-b border-[#CCCCCC] pb-2">
                      <h1 className="text-base font-semibold p-1">Add Occupants</h1>
                      <div className="flex gap-2">
                        <ButtonG
                          label="Cancel"
                          onClick={handleCancelClick}
                          className="h-8"
                          type={'button'}
                        />
                        <Button
                          label={
                            <span className="w-12 inline-block">
                              {
                                updateLoader && buttonType==='Save' ?
                                  <CircularProgress sx={{color: "white"}} size={17}/>
                                  :
                                  'Save'
                                }
                            </span>
                            }
                          className="px-5 h-8"
                          type='submit'
                          onClick={()=>setButtonType('Save')}
                        />
                        <Button label={
                            <span className="w-28 inline-block">
                              {
                                updateLoader && buttonType==='Save & Add More' ?
                                  <CircularProgress sx={{color: "white"}} size={17}/>
                                  :
                                  'Save & Add More'
                                }
                            </span>
                            }
                            className="px-5 h-8" type='submit' onClick={()=>setButtonType('Save & Add More')} />
                      </div>
                    </div>
                    <>
                      <div className="flex gap-4 p-2">
                        <div className="flex flex-col justify-between w-full gap-3">
                          <div className="flex justify-between w-full gap-3">
                            <div className="w-1/2 gap-2 pr-5">
                              <div className="flex  justify-between text-sm">
                                <span className="mt-2">Role</span>
                                <DropdownFM2
                                  options={titleList?.map(item=>({
                                    label: item.title,
                                    value: item?.title_id
                                  })) || []}
                                  width={"224px"}
                                  className="text-xs"
                                  placeholder={"Select Role"}
                                  onSelect={(selectedItem)=>{
                                    setFieldValue(`titleId`, selectedItem.value)
                                  }}
                                  height='38px'
                                  name={`titleId`}
                                  {...(values.titleId==='' && {value: null})}
                                />
                              </div>
                            
                                <div className="flex justify-between text-sm">
                                <span>First Name</span>
                                <div className="flex gap-2">
                                  <div className="flex  justify-between text-sm">
                                    <DropdownFM2
                                      options={namePrefixOptions}
                                      width={"73px"}
                                      className="text-xs"
                                      placeholder={"Select"}
                                      onSelect={(selectedItem)=>{
                                        setFieldValue(`prefix`, selectedItem.value)
                                      }}
                                      height='38px'
                                      name={`prefix`}
                                      {...(values.prefix ==='' && {value: null})}
                                    />
                                  </div>
                                  <TextInputFM
                                    label=""
                                    placeholder="Enter First Name"
                                    className="w-36"
                                    name={`firstName`}
                                    maxLength={20}
                                    />
                                </div>
                              </div>
                              <TextInputFM
                                label="Last Name"
                                placeholder="Enter Last Name"
                                className="w-56"
                                name={`lastName`}
                                type='alphaNumber'
                                maxLength={20}
                              />
                              <div className="flex  justify-between text-sm">
                                <span className="mt-2">Gender</span>
                                <DropdownFM2
                                  options={genderOptions}
                                  width={"224px"}
                                  className="text-xs"
                                  placeholder="Select Gender"
                                  onSelect={(selectedItem)=>{
                                    setFieldValue(`gender`, selectedItem.value)
                                  }}
                                  height='38px'
                                  name={`gender`}
                                  {...(values.gender==='' && {value: null})}
                                />
                              </div>
                              <TextInputFM
                                label="Mobile Number"
                                placeholder="Enter Number"
                                className="w-56"
                                name={`mobileNo`}
                                type='number'
                                maxLength='15'
                              />
                              <div className="w-full flex -mt-1 ms-1 mb-2">
                                <div className="w-1/2"></div>
                                <div className="w-1/2 ">
                                  <Checkbox text="Public" className="text-xs"
                                    initialchecked={values?.mobileVisible === 'Yes'} 
                                    onChange={(e)=>{setFieldValue(`mobileVisible`, e.target.checked ? 'Yes' : 'No')}}
                                  />
                                </div>
                              </div>
                              <TextInputFM
                                label="Primary Email Id"
                                placeholder="Enter Email ID"
                                className="w-56"
                                name={`primaryEmail`}
                              />
                              <div className="w-full flex -mt-2 mb-3">
                                <div className="w-1/2"></div>
                                <div className="w-1/2">
                                  <div className="flex justify-between ms-1.5 mt-2">
                                    <Checkbox text="Public" className="text-xs" 
                                      initialchecked={values?.emailVisible === 'Yes'} 
                                      onChange={(e)=>{setFieldValue(`emailVisible`, e.target.checked ? 'Yes' : 'No')}}
                                    />
                                    <Checkbox text="Login Access" className="text-xs" 
                                      initialchecked={values?.loginAccess} 
                                      onChange={(e)=>{setFieldValue(`loginAccess`, e.target.checked)}}
                                    />
                                  </div>
                                </div>
                              </div>
                              <TextInputFM
                                label="Identity Proof Number"
                                placeholder="Enter"
                                className="w-56"
                                type='alphaNumber'
                                name={`adharCardNo`}
                                maxLength='12'
                              />

                              <div className="mt-[27px]">
                                <div className="flex flex-row justify-between">
                                  <span className="text-sm mt-2">Wing</span>
                                  <div className="text-xs">
                                    <DropdownFM2
                                      options={wingsData?.map((item)=>({
                                        value: item?.wing_id,
                                        label: item?.number
                                      })) || []}
                                      width={"224px"}
                                      className="text-xs"
                                      placeholder={"Select Wing"}
                                      onSelect={(selectedItem)=>{
                                        setFieldValue(`wingId`, selectedItem.value)
                                        setFieldValue(`flatId`, '')
                                        dispatch(getFlatDetails({societyId: loginData?.default_community?.community_id, wingId: selectedItem?.value}))
                                      }}
                                      height='38px'
                                      name={`wingId`}
                                      {...(values.wingId==='' && {value: null})}
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-row justify-between mb-4">
                                  <span className="text-sm mt-2">Flat</span>
                                  <div className="text-xs">
                                    <DropdownFM2
                                      options={values.wingId && flatsData?.manage_flat_details?.map((item)=>({
                                        value: item?.flat_id,
                                        label: item?.flat_no,
                                        color: item.flat_allotted ? '#00D94B' : '#000000' 
                                      })) || []}
                                      width={"224px"}
                                      className="text-xs"
                                      placeholder={"Select Flat/Unit"}
                                      onSelect={(selectedItem)=>{
                                        setFieldValue(`flatId`, selectedItem.value)
                                      }}
                                      height='38px'
                                      name={`flatId`}
                                      {...(values.flatId==='' && {value: null})}
                                    />
                                  </div>
                                </div>
                              </div> 
                            </div>
                            <div className="w-1/2 gap-2 pr-5">
                              <div className="my-2 mb-6">
                                <TextRadio
                                  label="Register As (Member)"
                                  options={registerAsOptions?.map((item)=>({
                                    name: `registerAs`,
                                    value: item.value,
                                    label: item.label
                                  }))}
                                  defaultSelected="main"
                                  onChange={(selectemItem)=>setFieldValue(`registerAs`, selectemItem)}
                                  className={'w-56'}
                                />
                              </div>
                              <div className="mt-3">
                                <TextInputFM
                                  label="Middle Name"
                                  placeholder="Enter Middle Name"
                                  className="w-56"
                                  name={`middleName`}
                                  maxLength={20}
                                />
                              </div>
                              <div className="flex flex-row justify-between text-sm mt-5 mb-10"></div>
                              <div className="flex  justify-between text-sm">
                                <span className="mt-2">Country</span>
                                <DropdownFM2
                                  options={ countries?.map((item)=>({
                                    label: `${item?.name?.toUpperCase()} (${item?.phonecode})`,
                                    value: item?.phonecode?.replace("+",""),
                                    iso: item.iso,
                                    id: item?.id
                                  })) || []}
                                  width={"224px"}
                                  className="text-xs"
                                  placeholder={"Select Country"}
                                  onSelect={(selectedItem)=>{
                                    setFieldValue(`mobileCountryCode`, selectedItem.value)
                                    setFieldValue(`countryId`, selectedItem.id)
                                    setFieldValue(`countryISO`, selectedItem.iso)
                                  }}
                                  height='38px'
                                  name={`mobileCountryCode`}
                                  {...(values.mobileCountryCode==='' && {value: null})}
                                />
                              </div>
                              <TextInputFM
                                label="Secondary Mobile Number"
                                placeholder="Enter Number"
                                className="w-56"
                                type='number'
                                name={`secondaryMobileNo`}
                                maxLength={15}
                              />
                              <div className="w-full flex -mt-1 mb-2">
                                <div className="w-1/2"></div>
                                <div className="w-1/2">
                                  <Checkbox
                                    text="Send SMS on Alternate Number"
                                    className="text-xs whitespace-nowrap"
                                    initialchecked={values?.sendeSMSOnAlternateNo === 'Yes'} 
                                    onChange={(e)=>{setFieldValue(`sendeSMSOnAlternateNo`, e.target.checked ? 'Yes' : 'No')}}
                                  />
                                </div>
                              </div>
                              <TextInputFM
                                label="Secondary Email Id"
                                placeholder="Enter Email"
                                className="w-56"
                                name={`secondaryEmail`}
                              />
                              <div className="w-full flex -mt-1 mb-4">
                                <div className="w-1/2"></div>
                                <div className="w-1/2">
                                  <Checkbox
                                    text="Send SMS on Alternate Email"
                                    className="text-xs"
                                    initialchecked={values?.sendeSMSOnAlternateEmail === 'Yes'} 
                                    onChange={(e)=>{setFieldValue(`sendeSMSOnAlternateEmail`, e.target.checked ? 'Yes' : 'No')}}
                                  />
                                </div>
                              </div>
                              <div className="text-sm flex justify-between mb-4">
                                <span>Upload Identity Proof</span>
                                <div className="w-1/2">
                                  <UploadButtonFM showFileName={true} 
                                    name={`adharFile`}
                                    accept={"image/jpeg,image/gif,image/png,application/pdf,image/x-eps"}
                                    onSelect={(e)=>setFieldValue(`adharFile`, e)} 
                                    fileName={values.adharFile?.name}
                                  />
                                </div>
                              </div>
                              <div className="mt-7">
                                <ProfilePicture
                                  onSelect={(e)=>setFieldValue(`profilePic`, e)} 
                                  onDelete={(e)=>setFieldValue(`profilePic`, '')} 
                                />
                              </div>
                            </div> 
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                </Form>
              )
            }
          }
        </Formik>
      </div>
    </Layout>
  );
};

export default AddOccupants;
