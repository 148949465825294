import * as types from '../../index.types';

export function downloadExcelFormat(data) {
    return {
        type: types.DOWNLOAD_EXCEL_FORMAT,
        data
    };
}

export function uploadSocietyStaffList(data) {
    return {
        type: types.UPLOAD_STAFF_EXCEL_SHEET,
        data
    };
}

export function uploadToUpdateOlddOccupantsList(data) {
    return {
        type: types.UPLOAD_TO_UPDATE_OLD_OCCUPANT_EXCEL_SHEET,
        data
    };
}

export function uploadNewOccupantsList(data) {
    return {
        type: types.UPLOAD_NEW_OCCUPANT_EXCEL_SHEET,
        data
    };
}

export function uploadOccupantsOpeningbalanceSheet(data) {
    return {
        type: types.UPLOAD_OCCUPANT_OPENING_BALANCE_EXCEL_SHEET,
        data
    };
}

export function downloadOpeningBalanceExcelSample(data) {
    return {
        type: types.DOWNLOAD_OCCUPANT_OPENING_BALANCE_SHEET_SAMPLE,
        data
    };
}

export function clearBulkUploadError(data) {
    return {
        type: types.UPLOAD_CLEAR_ERROR,
        data
    };
}