import React, { useEffect } from "react";
import Layout from "../../Layout/Layout";
import {
  Link,
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import Search from "../../../components/SearchBox/Search";
import NoticeAndCircularArchiveTable from "./NoticeAndCircularArchiveTable";
import { useSelector, useDispatch } from "react-redux";
import { getNoticeCircularArchiveList } from "../../../redux/actions/SocialAndCommunicationActions/NoticeAndCircularAction";
import moment from "moment";
import {
  ARCHIVE_NOTICE_AND_CIRCULARS_ENDPOINT,
  NOTICE_AND_CIRCULARS_ENDPOINT,
} from "../../../Routing/routes";

export const headCells = [
  { id: "sNo", label: "S.No", sort: true, width: "50px" },
  { id: "type", label: "Type", sort: true, width: "90px" },
  {
    id: "subject",
    label: "Subject",
    sort: false,
    width: "100px",
  },
  { id: "description", label: "Description", sort: true, width: "190px" },
  { id: "notice_on", label: "Date Of Notice", sort: true, width: "100px" },
  { id: "action", label: "Action", sort: false, width: "70px" },
];

export const rows = [
  {
    id: 1,
    type: "notice",
    subject: "test 435234",
    expiredOn: "2023-03-02",
  },
];

const NoticeAndCircularArchive = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { noticeCircularArchiveList, loading } = useSelector(
    (state) => state.noticeAndCircularReducer
  );

  const goback = () => {
    navigate(NOTICE_AND_CIRCULARS_ENDPOINT);
  };

  const handleSearch = (text) => {
    if (text && text !== searchParams.get("filter_text")) {
      searchParams.delete("page");
      searchParams.delete("limit");
      searchParams.delete("filter_text");
      navigate({
        pathname: `${ARCHIVE_NOTICE_AND_CIRCULARS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["filter_text", text?.trim()],
        ])}`,
      });
    } else {
      searchParams.delete("filter_text");
      navigate({
        pathname: `${ARCHIVE_NOTICE_AND_CIRCULARS_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries()])}`,
      });
    }
  };
  const getTableData = () => {
    const data = {
      societyId: loginData?.default_community?.community_id,
      filter_type: "archive",
      page: searchParams.get("page") || 1,
      filter_text: searchParams.get("filter_text") || "",
      limit: searchParams.get("limit") || 10,
    };
    dispatch(getNoticeCircularArchiveList(data));
  };

  useEffect(() => {
    getTableData();
  }, [searchParams]);

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <ListHeaderBack title="Archive - Notice and Circulars" onClick={goback}>
          {/* <span className="border-r border-[#CCC]"></span> */}
          <Search
            width={36}
            height="8"
            onclick={handleSearch}
            value={searchParams.get("filter_text")}
          />
        </ListHeaderBack>

        <div>
          <NoticeAndCircularArchiveTable
            loading={loading}
            data={noticeCircularArchiveList?.list?.map((item, index) => ({
              sNo: (
                (noticeCircularArchiveList?.current_page - 1) *
                  noticeCircularArchiveList?.limit +
                1 +
                index
              )
                ?.toString()
                ?.padStart(2, "0"),
              id: item?.id,
              subject: item?.title,
              description: item?.description.replace(/(<([^>]+)>)/gi, ""),
              type: item?.type,
              notice_on: item?.notice_date
                ? moment(new Date(item?.notice_date))?.format("MMM DD, YYYY")
                : "-",
              comment_count: item?.comment_count,
            }))}
            columns={headCells}
            checkbox={false}
            pagination={noticeCircularArchiveList?.list?.length}
            totalCount={noticeCircularArchiveList?.total_count}
            totalPages={noticeCircularArchiveList?.total_pages}
            start={
              (noticeCircularArchiveList?.current_page - 1) *
                noticeCircularArchiveList?.limit +
              1
            }
            end={
              (noticeCircularArchiveList?.current_page - 1) *
                noticeCircularArchiveList?.limit +
              noticeCircularArchiveList?.list?.length
            }
            currentPage={noticeCircularArchiveList?.current_page}
            rowsPerPage={searchParams.get("limit") || 10}
            getTableData={getTableData}
          ></NoticeAndCircularArchiveTable>
        </div>
        {!noticeCircularArchiveList?.list?.length && !loading && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
    </Layout>
  );
};

export default NoticeAndCircularArchive;
