import React, { useEffect, useState } from "react";
import Layout from "../../../Layout/Layout";
import Button from "../../../../components/Button/Button";
import GenerateInvoiceAccordion from "./GenerateInvoiceAccordion";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { generateInvoice } from "../../../../redux/actions/FinanceActions/BillingManagementActions/GenerateInvoiceAction";
import ButtonG from "../../../../components/Button/ButtonG";
import { useNavigate } from "react-router-dom";
import { MANAGE_INVOICE_CURRENT_ENDPOINT,MANAGE_INVOICE_DRAFTS_ENDPOINT } from "../../../../Routing/routes";
import { CircularProgress } from "@mui/material";
import Calculation from "../../../../utils/helpers/invoiceCalculation"

const GenerateCurrentInvoice = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [invoiceData, setInvoiceData] = useState();
  const [generateLoader, setgenerateLoader] = useState(false);
  const [draftLoader, setdraftLoader] = useState(false);
  const [headData, setHeadData] = useState([]);
  const { loginData } = useSelector((state) => state.loginReducer);
  const { invoiceGenerator, updateLoader } = useSelector(
    (state) => state.generateInvoiceReducer
  );

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handlePost = () => {
    setgenerateLoader(true);
    dispatch(
      generateInvoice({
        societyId: loginData?.default_community?.community_id,
        is_draft: 0,
        status: "generate",
        invoiceData,
        onSuccessCallback: () => {
          navigate(MANAGE_INVOICE_CURRENT_ENDPOINT);
        },
        onRequestEndCallback: () => {
          setgenerateLoader(false);
        },
      })
    );
  };
  const handleSaveAsDraft = () => {
    setdraftLoader(true);
    dispatch(
      generateInvoice({
        societyId: loginData?.default_community?.community_id,
        is_draft: 1,
        status: "generate",
        invoiceData,
        onSuccessCallback: () => {
          navigate(MANAGE_INVOICE_DRAFTS_ENDPOINT);
        },
        onRequestEndCallback: () => {
          setdraftLoader(false);
        },
      })
    );
  };

  useEffect(() => {
    if (invoiceGenerator.flat_no) {
      let flatid = invoiceGenerator["flat_no"];
      flatid.forEach((id) => {
        var data = Calculation(invoiceGenerator, id);
        Object.assign(invoiceGenerator, data);
      });
      setInvoiceData({ ...invoiceGenerator });
    }
  }, [invoiceGenerator]);

  return (
    <Layout>
      <div className="flex flex-col w-full h-full gap-2">
        <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg p-2">
          <div className="flex justify-between gap-5 pb-2 border-b border-[#CCCC]">
            <div className="flex items-center w-1/2">
              <h1 className="p-1 text-sm font-semibold">Generate Invoices</h1>
            </div>
            <div className="flex items-center gap-2">
              <div className="border-r border-[#CCC] w-full h-full"></div>
              <ButtonG
                label="Cancel"
                className="h-8"
                onClick={handleNavigation(-1)}
                type="button"
                disabled={updateLoader}
              />
              {/* {
                invoiceData?.flat_no?.map((item, index) => (
                  {
                    invoiceGenerator[item]?.memberdata?.error
                  }
                ))
              } */}
              <Button
                label={
                  generateLoader ? (
                    <CircularProgress sx={{ color: "white" }} size={17} />
                  ) : (
                    "Generate"
                  )
                }
                className="h-8 px-4"
                type="Submit"
                onClick={handlePost}
                disabled={updateLoader}
              />
              <div className="border-r border-[#CCC] h-full"></div>
              <Button
                label={
                  draftLoader ? (
                    <CircularProgress sx={{ color: "white" }} size={17} />
                  ) : (
                    "Save As Draft"
                  )
                }
                className="px-4 min-w-32"
                type="Submit"
                onClick={handleSaveAsDraft}
                disabled={updateLoader}
              />
            </div>
          </div>
          <div>
            <GenerateInvoiceAccordion
              invoiceData={invoiceData}
              Calculation={Calculation}
              setInvoiceData={setInvoiceData}
              headData={headData}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default GenerateCurrentInvoice;
