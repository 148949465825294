import React, { useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/closeIcon.svg";
import Button from "../../../../components/Button/Button";
import Dropdown from "../../../../components/Dropdown/Dropdown";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getSocietyStaffApprovalSetting,
  updateSocietyStaffApprovalSetting,
  updateSocietyStaffApprovalStatus,
} from "../../../../redux/actions/SetupRecordsActions/StaffManagementActions/StaffPersonnelAction";
import Loader from "../../../../components/Loader/Loader";
import { Form, Formik } from "formik";
import { ApprovalSettingValidation } from "../../../../validationSchema/setupRecordsSchema/staffManagementSchema";
import {
  getSocietyEmployeeTypes,
  getSocietyManagingCommitteeTypes,
} from "../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/TenantsInfoAction";
import { DropdownFM2 } from "../../../../components/Dropdown/dropdown2";
import { CircularProgress } from "@mui/material";
import { TextAreaFM } from "../../../../components/InputFields/TextArea";
import * as permissions from "../../../../Routing/permissions";
import { getPermission } from "../../../../utils/helpers/getPermissions";
import { TextInputFM } from "../../../../components/InputFields/TextInput";

const ApprovalStatus = ({ isOpen, onClose, onDelete, row = "" }) => {
  const [visibility, setVisibility] = useState(false);

  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const approvalSetting = useSelector(
    (state) => state.staffPersonnelReducer.approvalSetting
  );
  const updateLoader = useSelector(
    (state) => state.staffPersonnelReducer.updateLoader
  );

  const handleCheckbox = (e, key, setFieldValue) => {
    setFieldValue(key, e.target.checked ? 1 : 0);
  };

  const handleSaveClick = (values, { resetForm, initialValues }) => {
    dispatch(
      updateSocietyStaffApprovalStatus({
        ...values,
        staff_id: row?.staffId,
        staff_level_id: row?.staffLevelId,
        societyId: loginData?.default_community?.community_id,
        onSuccessCallback: () => {
          onClose();
        },
      })
    );
  };

  useEffect(() => {
    let data = { societyId: loginData?.default_community?.community_id };
    dispatch(getSocietyStaffApprovalSetting(data));
  }, []);
  useEffect(() => {
    if (
      getPermission(loginData, [permissions.APPROVE_STAFF_PERMISSION]) &&
      loginData?.user_type != "Society" &&
      approvalSetting
    ) {
      if (
        loginData?.user_type == "Employee" &&
        loginData?.default_community?.blocks
      ) {
        if (
          loginData?.default_community?.blocks?.member_title_id ==
          approvalSetting.employee_type_id
        ) {
          setVisibility(true);
        } else {
          setVisibility(false);
        }
      } else if (loginData?.default_community?.committees) {
        if (loginData?.default_community?.committees.length > 0) {
          if (
            loginData?.default_community?.committees.some(
              (item) => item.committeeid == approvalSetting.committee_id
            )
          ) {
            setVisibility(true);
          } else {
            setVisibility(false);
          }
        }
      } else {
        setVisibility(false);
      }
    } else if (loginData?.user_type == "Society") {
      setVisibility(true);
    } else {
      setVisibility(false);
    }
    // setVisibility()
  }, [approvalSetting]);

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-60 z-20 ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="bg-white rounded-lg w-[420px] ">
        <div className="flex justify-between p-2 gap-10 items-center -mb-2">
          <h2 className="p-2 text-lg font-semibold mb-2  justify-between  ">
            Change Status
          </h2>
          <button
            onClick={onClose}
            className="bg-[#EAEAEA] rounded-full p-2 items-center mb-2 w-9 h-9 "
          >
            <CloseIcon className="fill-current text-gray-600 hover:text-gray-800 ml-1 w-3 h-3 cursor-pointer" />
          </button>
        </div>
        <div className="border-b border-[#CCCCCC]"></div>
        {/* {approvalSettingLoader ? (
          <Loader />
        ) : ( */}
        <div className="p-4">
          <Formik
            initialValues={{
              approval_status: row?.approval_status || "",
              approval_comments: row?.approval_comments || "",
            }}
            enableReinitialize
            onSubmit={handleSaveClick}
            //   validationSchema={ApprovalSettingValidation}
          >
            {({ values, dirty, setFieldValue }) => {
              return (
                <Form>
                  {/* <div className="mb-[15px] px-2 items-center flex">
                      <Checkbox initialchecked={values.committeeApproval === 1} onChange={(e)=>{handleCheckbox(e, 'committeeApproval', setFieldValue)}} />
                      <span className="text-[13px]">Committee Autoapproval</span>
                    </div> */}
                  {/* <div className="mb-[15px] px-2 items-center flex">
                      <Checkbox initialchecked={values.employeeApproval === 1} onChange={(e)=>{handleCheckbox(e, 'employeeApproval', setFieldValue)}} />
                      <span className="text-[13px]">Employee Type Autoapproval</span>
                    </div> */}
                  <div
                    className={`flex p-2 pb-0 justify-between  ${
                      !visibility ? " pointer-events-none" : ""
                    }`}
                  >
                    <span className="text-sm mt-2">Status</span>
                    {visibility ? (
                      <DropdownFM2
                        options={
                          [
                            { label: "Approve", value: "Approval" },
                            { label: "Unapprove", value: "Pending" },
                            { label: "Reject", value: "Reject" },
                          ] || []
                        }
                        width="224px"
                        height="32px"
                        placeholder="Select Status"
                        className="text-xs"
                        onSelect={(selectedItem) => {
                          setFieldValue("approval_status", selectedItem.value);
                        }}
                        value={{
                          value: values?.approval_status,
                          label: [
                            { label: "Approve", value: "Approval" },
                            { label: "Unapprove", value: "Pending" },
                            { label: "Reject", value: "Reject" },
                          ]?.find(
                            (item) => item.value == values.approval_status
                          )?.label,
                        }}
                        name="approval_status"
                      />
                    ) : (
                      <div className="w-56 mt-2 break-words mb-2 font-bold">
                        {row?.approval_status == "Approval"
                          ? "Approved"
                          : row?.approval_status == "Reject"
                          ? "Rejected"
                          : "Unapproved"}
                      </div>
                    )}
                  </div>

                  <div
                    className={`flex flex-col p-2 pb-0 justify-center items-center ${
                      !visibility ? " pointer-events-none" : ""
                    }`}
                  >
                    <TextAreaFM
                      label={"Comment"}
                      placeholder="Enter Comment"
                      className={"w-56 h-20"}
                      name="approval_comments"
                      maxLength={200}
                    />
                  </div>

                  {/* <div className="border-b"></div> */}

                  <div className="flex justify-center mt-1">
                    {visibility && (
                      <Button
                        label={
                          <span className="w-12 inline-block">
                            {updateLoader ? (
                              <CircularProgress
                                sx={{ color: "white" }}
                                size={17}
                              />
                            ) : (
                              "Save"
                            )}
                          </span>
                        }
                        className="h-5 px-5 font-semibold"
                        disabled={false}
                        type={"submit"}
                      />
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        {/* )} */}
      </div>
    </div>
  );
};

export default ApprovalStatus;
