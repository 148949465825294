import * as type from "../../../actions/index.types";

export function dueReminderReducer(
  state = {
    loading: false,
    updateLoader: false,
    dueReminderData: null,
    debitDueReminderData: null,
    fdMaturityDueReminderData: null,
    memberDueReminderData: null,
    nomineeDueReminderData: null,

    loadingMemberList: false,
    memberListAccRole: null,
    error: null,
  },
  action
) {
  switch (action.type) {
    case type.GET_DUE_REMINDER_DETAILS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_DUE_REMINDER_DETAILS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        dueReminderData: action.payload,
      };
    case type.GET_DUE_REMINDER_DETAILS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        dueReminderData: null,
      };
         
    
    case type.UPDATE_DUE_REMINDER_DETAILS:
    return {
      ...state,
      updateLoader: true
    }
    case type.UPDATE_DUE_REMINDER_DETAILS_SUCCESS:
    return {
        ...state,
        updateLoader: false
    }
    case type.UPDATE_DUE_REMINDER_DETAILS_FAILURE:
    return {
        ...state,
        updateLoader: false
    }

    case type.GET_DEBIT_NOTE_DUE_REMINDER_DETAILS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_DEBIT_NOTE_DUE_REMINDER_DETAILS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        debitDueReminderData: action.payload,
      };
    case type.GET_DEBIT_NOTE_DUE_REMINDER_DETAILS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        debitDueReminderData: null,
      };
         
    
    case type.UPDATE_DEBIT_NOTE_DUE_REMINDER_DETAILS:
    return {
      ...state,
      updateLoader: true
    }
    case type.UPDATE_DEBIT_NOTE_DUE_REMINDER_DETAILS_SUCCESS:
    return {
        ...state,
        updateLoader: false
    }
    case type.UPDATE_DEBIT_NOTE_DUE_REMINDER_DETAILS_FAILURE:
    return {
        ...state,
        updateLoader: false
    }

    case type.GET_FD_MATURITY_DUE_REMINDER_DETAILS:
        return {
          ...state,
          loading: true,
          error: null,
        };
    case type.GET_FD_MATURITY_DUE_REMINDER_DETAILS_SUCCESS:
    return {
        ...state,
        error: null,
        loading: false,
        fdMaturityDueReminderData: action.payload,
    };
    case type.GET_FD_MATURITY_DUE_REMINDER_DETAILS_FAILURE:
    return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        fdMaturityDueReminderData: null,
    };
           
      
    case type.UPDATE_FD_MATURITY_DUE_REMINDER_DETAILS:
    return {
    ...state,
    updateLoader: true
    }
    case type.UPDATE_FD_MATURITY_DUE_REMINDER_DETAILS_SUCCESS:
    return {
        ...state,
        updateLoader: false
    }
    case type.UPDATE_FD_MATURITY_DUE_REMINDER_DETAILS_FAILURE:
    return {
        ...state,
        updateLoader: false
    }

      case type.GET_MEMBER_DUE_REMINDER_DETAILS:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case type.GET_MEMBER_DUE_REMINDER_DETAILS_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          memberDueReminderData: action.payload,
        };
      case type.GET_MEMBER_DUE_REMINDER_DETAILS_FAILURE:
        return {
          ...state,
          error: action?.message || "Somthing went wrong.",
          loading: false,
          memberDueReminderData: null,
        };
           
      
      case type.UPDATE_MEMBER_DUE_REMINDER_DETAILS:
      return {
        ...state,
        updateLoader: true
      }
      case type.UPDATE_MEMBER_DUE_REMINDER_DETAILS_SUCCESS:
      return {
          ...state,
          updateLoader: false
      }
      case type.UPDATE_MEMBER_DUE_REMINDER_DETAILS_FAILURE:
      return {
          ...state,
          updateLoader: false
      }

      case type.GET_NOMINEE_DUE_REMINDER_DETAILS:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case type.GET_NOMINEE_DUE_REMINDER_DETAILS_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          nomineeDueReminderData: action.payload,
        };
      case type.GET_NOMINEE_DUE_REMINDER_DETAILS_FAILURE:
        return {
          ...state,
          error: action?.message || "Somthing went wrong.",
          loading: false,
          nomineeDueReminderData: null,
        };
           
      
      case type.UPDATE_NOMINEE_DUE_REMINDER_DETAILS:
      return {
        ...state,
        updateLoader: true
      }
      case type.UPDATE_NOMINEE_DUE_REMINDER_DETAILS_SUCCESS:
      return {
          ...state,
          updateLoader: false
      }
      case type.UPDATE_NOMINEE_DUE_REMINDER_DETAILS_FAILURE:
      return {
          ...state,
          updateLoader: false
      }

    case type.GET_MEMBER_LIST_ACCORDING_TO_ROLE:
      return {
        ...state,
        loadingMemberList: true,
        error: null,
      };
    case type.GET_MEMBER_LIST_ACCORDING_TO_ROLE_SUCCESS:
      return {
        ...state,
        error: null,
        loadingMemberList: false,
        memberListAccRole: action.payload,
      };
    case type.GET_MEMBER_LIST_ACCORDING_TO_ROLE_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loadingMemberList: false,
        memberListAccRole: null,
      };
    default:
      return state;
  }
}
