import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ListHeader from "../ListHeader/ListHeader";
import Button from "../Button/Button";
import ButtonG from "../Button/ButtonG";
import Dropdown2 from "../Dropdown/dropdown2";
import Box from "@mui/material/Box";
import Modal from "react-modal";
import { TextInputFM } from "../InputFields/TextInput";
import { DropdownFM2 } from "../Dropdown/dropdown2";
import {
  getMoreHeadDetails,
  addUtilitySlab,
} from "../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";
import { notify } from "../../utils/notification";
import { Form, Formik } from "formik";
const customStyles = {
  content: {
    width: "500px",
    height: "350px",
    minHeight: "fit-content",
    background: "#fff",
    border: "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
};
const AddUtilitySlabModel = ({ isOpen, onClose, headId }) => {
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer)
  const { loading, updateLoader, bankDetailsData } = useSelector(state => state.bankDetailsReducer)
  let RangeType = [
    {
      value: "fixed",
      label: "Fixed",
    },
    {
      value: "less",
      label: "Less Then",
    },
    {
      value: "greater",
      label: "Greater Then",
    },
    {
      value: "range",
      label: "Range",
    },
  ]
  const onSubmit = (values) => {
      dispatch(addUtilitySlab({
        societyId: loginData?.default_community?.community_id,
        rangeType: values.rangeType,
        headId: headId,
        range1: values.range1, 
        range2: values.range2,
        charge: values.charge,
        onSucessCallback: () => {
          onClose()
          dispatch(getMoreHeadDetails({ societyId: loginData?.default_community?.community_id, headId: headId }))
          //navigate(-1);
      },
      }))
      
    
  }
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
      <Formik
        initialValues={{
          rangeType: 'fixed',
          range1: 0,
          range2: 0,
          charge: 0,
        }}
        enableReinitialize
      //validationSchema={addMoreHeadValidation}
        onSubmit={onSubmit}
      >
        {(formik) => {
            return (
        <Form>
          <div className="bg-white border-b rounded-lg">
            <ListHeader leftContent="Add Utility Slab">
              <span className="border-r border-[#CCC]"></span>
              <ButtonG label="Cancel" type={"button"} onClick={onClose} />
              <Button label="Submit" type={"submit"} disabled={loading} />
            </ListHeader>
          </div>
          <Box sx={{ width: "100%", height: "67%", fontSize: "13px" }}>
            <div className="flex flex-wrap w-full gap-4 p-2 mt-3">
              <div className="flex justify-between w-full ">
                <span className="">Range Type</span>
                <DropdownFM2
                  options={RangeType.map((detail) => {
                    return {
                      value: detail.value,
                      label: detail.label
                    }
                  })}
                  value={formik.values.rangeType ? {
                    label: RangeType?.find((detail) => detail.value === formik.values.rangeType).label,
                    value: formik.values.rangeType
                  } : null}
                  onSelect={(selectedItem) => {
                    formik.setFieldValue('rangeType', selectedItem.value)
                  }}
                  name="rangeType"
                  placeholder="Select Range Type"
                  width="14rem"
                  height="36px"
                />
              </div>
              <div className="w-full">
              {formik.values.rangeType !== 'fixed' ?<TextInputFM
                  label={
                    formik.values.rangeType === 'range' ? "Range 1" : "Range"
                  }
                  placeholder="Enter Head Name"
                  className="w-56 h-9"
                  name='range1'
                  type="decimal"
                  maxLength={20}
                /> 

                : <></>}
              </div>
              <div className="w-full">
              {formik.values.rangeType === 'range' ?<TextInputFM
                  label="Range 2"
                  placeholder="Enter Head Name"
                  className="w-56 h-9"
                  name='range2'
                  type="decimal"
                  maxLength={20}
                /> 
                : <></>}
              </div>
              <div className="w-full">
                <TextInputFM
                  label="Per Unit Charge"
                  placeholder="Enter Per Unit Charge"
                  className="w-56 h-9"
                  name='charge'
                  type="decimal"
                  maxLength={20}
                />
              </div>



            </div>

          </Box>
        </Form> 
        )}}
      </Formik>
    </Modal>
  )
}
export default AddUtilitySlabModel;