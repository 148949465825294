import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ButtonG from "../../../../components/Button/ButtonG";
import Button from "../../../../components/Button/Button";
import { TextInputFM } from "../../../../components/InputFields/TextInput";
import { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import Layout from "../../../Layout/Layout";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import { DropdownFM2 } from "../../../../components/Dropdown/dropdown2";
import { DeleteIcon } from "../../../../assets";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteVehicleDetails,
  getOccupantDetails,
  getVehicleDetails,
  updateVehicleDetails,
} from "../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/OccupantsInfoAction";
import { CircularProgress } from "@mui/material";
import { FieldArray, Form, Formik } from "formik";
import Loader from "../../../../components/Loader/Loader";
import { getParkingTypeList } from "../../../../redux/actions/GateManagementActions/ParkingManagementAction";
import { VehicleValidation } from "../../../../validationSchema/setupRecordsSchema/occupants&TenantsSchema";
import { VehicleTypeOptions } from "../../../../consts/DropDownOptions";
import moment from "moment";

const VehiclesDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, userId, flatId } = useParams();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, updateLoader, occupantDetail, vehicleDetailsList } = useSelector(
    (state) => state.occupantInfoReducer
  );
  const {parkingTypeList} = useSelector(state=>state.parkingManagementReducer)

  useEffect(() => {
    dispatch(
      getOccupantDetails({
        flatId,
        subscribeId: id,
        societyId: loginData?.default_community?.community_id,
        userType: loginData?.user_type,
        userId: userId,
      })
    );

    dispatch(getVehicleDetails({ id: flatId }));
    dispatch(getParkingTypeList({societyId: loginData?.default_community?.community_id}))

  }, []);

  const handleCancelClick = () => {
    navigate(-1);
  };

  const handleSaveClick = (values, {resetForm}) => {
    dispatch(updateVehicleDetails({
      societyId: loginData?.default_community?.community_id,
      flatId,
      ...values,
      onSuccessCallback: ()=>{
        navigate(-1)
      }
    }))
  };

  const handleAddMoreClick = (arrayHelpers) => {
    arrayHelpers.push({
      id:0,
      stickerNo: '',
      parkingType: '',
      parkingNo: '',
      location: '',
      vehicleType: '',
      vehicleNo: '',
      vehicleBrand: '',
      modal: '',
      year: '',
      rfid: '',
    });
  };

  const handleDelete=({arrayHelpers, values, index})=>{
    if(!values.vehicles[index].id){
      arrayHelpers.remove(index)
    }else{
      dispatch(deleteVehicleDetails({
        societyId: loginData?.default_community?.community_id,
        flatId,
        id: values.vehicles[index].id,
        onSuccessCallback: ()=>{
          arrayHelpers.remove(index)
        }
      }))
    }
  }

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg p-2">
        <Formik
          initialValues={{
            vehicles: vehicleDetailsList?.map(item=> ({
              id: item.id || 0,
              stickerNo: item?.parking_lot || '',
              parkingNo: item?.parking_no || '',
              parkingType: item?.parking_type_id || '',
              location: item?.location || '',
              vehicleType: item?.vehicle_type || '',
              vehicleNo: item?.vehicle_no || '',
              vehicleBrand: item?.vehicle_brand || '',
              modal: item?.vehicle_model || '',
              year: item?.vehicle_reg_year?.slice(0,4) || '',
              rfid: item?.rfid || '',
            }))
          }}
          enableReinitialize
          onSubmit={handleSaveClick}
          validationSchema={VehicleValidation}
        >
          {(formik) => {
            return (
              <Form>
                <div className="border-b border-[#CCC]">
                  <ListHeader
                    leftContent={occupantDetail &&`${occupantDetail?.user_detail?.prefix ? occupantDetail?.user_detail?.prefix :''} ${occupantDetail?.user_detail?.f_name} ${occupantDetail?.user_detail?.m_name ?occupantDetail?.user_detail?.m_name : ''} ${occupantDetail?.user_detail?.l_name} (${occupantDetail?.flat_data?.find((item) => item.flat_id?.toString() === flatId)?.flat_no}) >> Vehicle Details`
                    }
                  >
                    <ButtonG
                      type='button'
                      label="Cancel"
                      onClick={handleCancelClick}
                      className="h-8 w-16"
                    />
                    <Button
                      label={
                        <span className="w-8 inline-block">
                          {updateLoader ? (
                            <CircularProgress
                              sx={{ color: "white" }}
                              size={17}
                            />
                          ) : (
                            "Save"
                          )}
                        </span>
                      }
                      type="submit"
                      className="h-8 w-16"
                      disabled={updateLoader || !formik.values.vehicles?.length}
                    />
                  </ListHeader>
                </div>
                {loading ? (
                  <Loader />
                ) : (
                  <div className="flex-wrap flex gap-2 w-full">
                    <FieldArray
                      name="vehicles"
                      render={(arrayHelpers) => (
                        <>
                          {formik.values.vehicles &&
                            formik.values.vehicles.length > 0 &&
                            formik.values.vehicles.map((item, index) => (
                              <div className="border rounded-lg mt-2 w-[49%]">
                                <div className="flex justify-between items-center bg-[#FDF2F3] border-b border-[#CCC] py-2 px-2">
                                  <div className="">
                                    Vehicle No.: {(index + 1)?.toString()?.padStart(2, '0')}
                                  </div>
                                  <DeleteIcon className="fill-red-650 cursor-pointer" onClick={()=> handleDelete({arrayHelpers, values: formik.values, index})} />
                                </div>
                                <div key={item.id} className="flex gap-4 p-2">
                                  <div className="flex flex-col justify-between w-1/2 gap-3">
                                    {/* Role Dropdown */}
                                    <div className="flex justify-between w-full gap-3">
                                      <div className=" gap-2 pr-5 mt-3">
                                        <TextInputFM
                                          label="Parking Sticker No."
                                          placeholder="Enter No."
                                          type='number'
                                          name={`vehicles.${index}.stickerNo`}
                                          className="w-56"
                                        />
                                        <div className="flex justify-between">
                                          <span className="text-sm mt-2">
                                            Parking Type
                                          </span>
                                          <DropdownFM2
                                            options={parkingTypeList?.map((item)=>({
                                              value: item.parking_type_id,
                                              label: item.title
                                            })) || []}
                                            width={"224px"}
                                            className="text-xs"
                                            placeholder="Select Parking Type"
                                            onSelect={(selectedItem) => {
                                              formik.setFieldValue(
                                                `vehicles.${index}.parkingType`,
                                                selectedItem.value
                                              );
                                            }}
                                            height="38px"
                                            name={`vehicles.${index}.parkingType`}
                                            value={
                                              formik.values.vehicles[index].parkingType ?
                                              {
                                                label: parkingTypeList?.find(item=> item.parking_type_id === formik.values.vehicles[index].parkingType)?.title,
                                                value: formik.values.vehicles[index].parkingType,
                                              }
                                              :null
                                            }
                                          />
                                          </div>
                                        <TextInputFM
                                          label="Parking No."
                                          placeholder="Enter"
                                          name={`vehicles.${index}.parkingNo`}
                                          className="w-56"
                                        />
                                        <TextInputFM
                                          label="Location"
                                          placeholder="Enter"
                                          name={`vehicles.${index}.location`}
                                          className="w-56"
                                        />
                                        <div className="flex justify-between">
                                          <span className="text-sm w-56 mt-2 align-top">
                                            Vehicle Type
                                          </span>
                                          <div className="w-56">
                                          <DropdownFM2
                                            options={VehicleTypeOptions}
                                            width={"224px"}
                                            className="text-xs"
                                            placeholder="Select Vehicle Type"
                                            onSelect={(selectedItem) => {
                                              formik.setFieldValue(
                                                `vehicles.${index}.vehicleType`,
                                                selectedItem.value
                                              );
                                            }}
                                            height="38px"
                                            name={`vehicles.${index}.gender`}
                                            value={
                                              formik.values.vehicles[index].vehicleType ?
                                              {
                                                label: VehicleTypeOptions?.find(item=> item.value === formik.values.vehicles[index].vehicleType)?.label,
                                                value: formik.values.vehicles[index].vehicleType,
                                              }
                                              :null
                                            }
                                          />
                                          </div>
                                        </div>

                                        <TextInputFM
                                          label="Vehicle Number"
                                          placeholder="Enter"
                                          name={`vehicles.${index}.vehicleNo`}
                                          className="w-56"
                                          type='alphaNumber'
                                        />
                                        <TextInputFM
                                          label="Vehicle Brand"
                                          placeholder="Enter"
                                          name={`vehicles.${index}.vehicleBrand`}
                                          className="w-56"
                                        />
                                        <TextInputFM
                                          label="Vehicle Model"
                                          placeholder="Enter"
                                          className="w-56"
                                          name={`vehicles.${index}.modal`}
                                        />
                                        <div className="flex justify-between text-sm">
                                          <span className="mt-2">Year</span>
                                          <DatePickerComponentFM
                                            dateFormat={'YYYY'}
                                            className="w-56 h-9 justify-between"
                                            onDateChange={(selectedDate) =>
                                              formik.setFieldValue(
                                                `vehicles.${index}.year`,
                                                moment(new Date(selectedDate)).format("YYYY")
                                              )
                                            }
                                            name={`vehicles.${index}.year`}
                                            defaultValue={
                                              formik.values?.vehicles[index]?.year
                                                ? new Date(`${formik.values?.vehicles[index]?.year}-01-01`)
                                                : null
                                            }
                                            showYearPicker={true}
                                            maxDate={new Date()}
                                          />
                                        </div>
                                        <TextInputFM
                                          label="RFID"
                                          placeholder="Enter "
                                          className="w-56"
                                          name={`vehicles.${index}.rfid`}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                            <div className="flex w-[49%] mt-4">
                              <button
                                type="button"
                                label="Add More"
                                onClick={()=>{handleAddMoreClick(arrayHelpers)}}
                                className="text-red-650 font-semibold border-2 border-dashed border-red-650 w-full h-[155px] p-2 bg-[#FDF6F6] flex justify-center rounded-md items-center"
                              >
                                Add More
                              </button>
                            </div>
                        </>
                      )}
                    ></FieldArray>
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    </Layout>
  );
};

export default VehiclesDetails;
