import { call, put, takeLatest } from 'redux-saga/effects'
import {notify} from '../../../utils/notification'
import * as types from '../../actions/index.types'
import { societyForgotPasswordReq } from '../../../axios/request/AuthReqs/authReq';

function* societyForgotPassword(action) {
    try {
        const result = yield call(societyForgotPasswordReq, action);
        if (result.statusCode === 200) {
            notify('success', result.message);
            action.data.onSuccessCallback();
            yield put({ type: types.SOCIETY_FORGOT_PASSWORD_SUCCESS, payload: result});  
        } else {
            yield put({ type: types.SOCIETY_FORGOT_PASSWORD_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}


export default function* societyForgotPasswordSaga() {
    yield takeLatest(types.SOCIETY_FORGOT_PASSWORD, societyForgotPassword);
}