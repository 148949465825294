import * as types from '../index.types';

export function getComplaintCategoryList(data) {
    return {
        type: types.GET_COMPLAINT_CATEGORY_LIST,
        data,
    };
}

export function getComplaintCategoryDetailsList(data) {
    return {
        type: types.GET_COMPLAINT_CATEGORY_DETAILS_LIST,
        data,
    };
}

export function editComplaintCategory(data) {
    return {
        type: types.EDIT_COMPLAINT_CATEGORY_LIST,
        data,
    }
}

export function addComplaintCategory(data) {
    return {
        type: types.ADD_COMPLAINT_CATEGORY_LIST,
        data
    };
}

export function deleteComplaintCategory(data) {
    return {
        type: types.DELETE_CATEGORY,
        data
    };
}

export function getComplaintSetting(data) {
    return {
        type: types.GET_COMPLAINT_SETTING_LIST,
        data,
    };
}

export function updateComplaintSetting(data) {
    return {
        type: types.UPDATE_COMPLAINT_SETTING_LIST,
        data,
    };
}

export function getComplaintTypeSetting(data) {
    return {
        type: types.GET_COMPLAINT_TYPE_SETTING,
        data,
    };
}

export function updateComplaintTypeSetting(data) {
    return {
        type: types.UPDATE_COMPLAINT_TYPE_SETTING,
        data
    };
}