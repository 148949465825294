import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ButtonG from "../../../../components/Button/ButtonG";
import Button from "../../../../components/Button/Button";
import { TextInputFM } from "../../../../components/InputFields/TextInput";
import { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import Layout from "../../../Layout/Layout";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import { DeleteIcon } from "../../../../assets";
import { TextAreaFM } from "../../../../components/InputFields/TextArea";
import RevokeDetailsModal from "../../../../components/Modal/RevokeDetailsModal";
import { REVOKE_DETAILS_LIST_ENDPOINT } from "../../../../Routing/routes";
import { useDispatch } from "react-redux";
import { addUpdateNominee, deleteNominee, getNomineeList, getOccupantDetails } from "../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/OccupantsInfoAction";
import { FieldArray, Form, Formik } from "formik";
import { NomineeValidation } from "../../../../validationSchema/setupRecordsSchema/occupants&TenantsSchema";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import Loader from "../../../../components/Loader/Loader";
import moment from "moment";
import { notify } from "../../../../utils/notification";

const NomineesDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { subscribeId, userId, flatId } = useParams();
  const [revokeModal, setRevokeModal] = useState(false);
  const { loading, updateLoader, nomineeDetails, occupantDetail } = useSelector((state) => state.occupantInfoReducer);
  const { loginData } = useSelector((state) => state.loginReducer);
  const {ipAddress} = useSelector(state=>state.ipAddressReducer)

  const handleCancelClick = () => {
    navigate(-1);
  };

  const handleSaveClick = (values, {resetForm}) => {
    let sumOfShare = values.nominees?.reduce((total, next) => total + parseFloat(next.share), 0)
    if(sumOfShare > 100 || sumOfShare < 100){
      notify('warning', 'Sum of share of nominee should be 100')
    }else{
      dispatch(addUpdateNominee({
        societyId: loginData?.default_community?.community_id,
        flatId,
        ...values,
        onSuccessCallback: ()=>{
          navigate(-1)
        }
      }))
    }
  };


  const handleAddMoreClick = (arrayHelpers) => {
    arrayHelpers.push({
      id:  0,
      name:  '',
      address: '',
      relationship: '',
      share: '',
      dob: '',
      dateOfNomination: '',
      dateOfMC: '',
      remarks: ''
    });
  };

  const handleDelete=({arrayHelpers, values, index})=>{
    if(!values.nominees[index].id){
      arrayHelpers.remove(index)
    }else{
      dispatch(deleteNominee({
        societyId: loginData?.default_community?.community_id,
        flatId,
        id: values.nominees[index].id,
        ipAddress,
        onSuccessCallback: ()=>{
          arrayHelpers.remove(index)
          // navigate(-1)
          // window.location.reload(); 
          
        }
      }))
    }
  }

  const handleRevokeModal = () => {
    setRevokeModal(true);
  }

  const handleRevokeListClick = () => {
    navigate(REVOKE_DETAILS_LIST_ENDPOINT)
  };

  useEffect(()=>{
    dispatch(getNomineeList({flatId}))
    dispatch(
      getOccupantDetails({
        subscribeId: subscribeId,
        societyId: loginData?.default_community?.community_id,
        userType: loginData?.user_type,
        userId: userId,
      })
    );
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg p-2">
        <Formik
          initialValues={{
            nominees: nomineeDetails?.map((item)=>({
              id: item?.id || 0,
              name: item?.full_name || '',
              address: item?.address || '',
              relationship: item?.relationship || '',
              share: item.share || '',
              dob: item?.dob || '',
              dateOfNomination: item?.date_of_nomination || '',
              dateOfMC: item?.mc_meeting_date || '',
              remarks: item?.remarks || ''
            }))  || []
          }}
          enableReinitialize
          validateOnChange={true}
          onSubmit={handleSaveClick}
          validationSchema={NomineeValidation}
        >
          {({values, setFieldValue, setFieldTouched}) => {
            return (
              <Form>
                <div className="border-b border-[#CCC]">
                  <ListHeader leftContent={occupantDetail &&`${occupantDetail?.user_detail?.prefix ? occupantDetail?.user_detail?.prefix :''} ${occupantDetail?.user_detail?.f_name} ${occupantDetail?.user_detail?.m_name ?occupantDetail?.user_detail?.m_name : ''} ${occupantDetail?.user_detail?.l_name} (${occupantDetail?.flat_data?.find((item) => item.flat_id?.toString() === flatId)?.flat_no}) >> Nominees Details`}>
                    <ButtonG
                      type='button'
                      label="Cancel"
                      onClick={handleCancelClick}
                      className="h-8 w-16"
                    />
                    <Button
                      label={
                        <span className="w-12 inline-block">
                          {updateLoader ? (
                            <CircularProgress
                              sx={{ color: "white" }}
                              size={17}
                            />
                          ) : (
                            "Save"
                          )}
                        </span>
                      }
                      disabled={updateLoader || !values.nominees?.length}
                      className="px-3 h-8"
                      type="submit"
                    />
                  </ListHeader>
                </div>
                {/* <div className="border rounded-lg mt-2">
                  <div className="flex justify-between items-center">
                    <div className="flex justify-between w-1/2 gap-6 items-center my-3">
                      <span className="text-sm ml-2">Nomination Serial No.</span>
                      <div
                        className={`text-sm border border-[#CCCCCC] rounded-md p-2 py-2 bg-[#F9FAFC] `}
                      >
                        <input
                          type="text"
                          placeholder="Enter..."
                          className="bg-[#F9FAFC] w-56 outline-none border-none placeholder-italic text-black not-italic text-xs font-semibold"
                        />
                      </div>
                    </div>
                    <div className="flex gap-2 mr-2">
                      <Button label="Revoke" onClick={handleRevokeModal}/>
                      <Button label="Revoke List" onClick={handleRevokeListClick} />
                    </div>
                  </div>
                </div> */}
                
                {
                  loading ?
                  <Loader/>
                  :
                  <div className="flex-wrap flex gap-2 w-full justify-center">
                  <FieldArray
                    name="nominees"
                    render={(arrayHelpers) => (
                      <>
                      {values.nominees &&
                        values.nominees.length > 0 &&
                        values.nominees.map((familyMember, index) => (
                          <div className="border rounded-lg mt-2 w-[49%]">
                            <div className="flex justify-between items-center bg-[#FDF2F3] border-b border-[#CCC] py-2 px-2">
                              <div className="">Nominee No.: {(index + 1)?.toString()?.padStart(2, '0')}</div>
                              <DeleteIcon className="fill-red-650 cursor-pointer" onClick={()=> handleDelete({arrayHelpers, values, index})} />
                            </div>
                            <div key={index} className="flex w-full gap-4 p-2">
                              <div className="flex flex-col w-full justify-between gap-3">
                                {/* Role Dropdown */}
                                <div className="">
                                  <div className="mt-3">
                                    <TextInputFM
                                      label="Nominee Name"
                                      placeholder="Enter Name"
                                      name={`nominees.${index}.name`}
                                      className="w-56"
                                    />
                                    <div>
                                      <TextAreaFM
                                        label="Nominee Address"
                                        placeholder="Enter Address"
                                        className="w-56 h-20"
                                        name={`nominees.${index}.address`}
                                      />
                                    </div>
                                    <TextInputFM
                                      label="Relationship"
                                      placeholder="Enter "
                                      name={`nominees.${index}.relationship`}
                                      className="w-56"
                                    />
                                    <TextInputFM
                                      label="Share of Nominee(in %)"
                                      placeholder="Enter share"
                                      name={`nominees.${index}.share`}
                                      className="w-56"
                                      type='decimal'
                                    />
                                    <div className="flex justify-between text-sm w-full">
                                      <span className="mt-2">Date of Birth (if Minor)</span>
                                      <DatePickerComponentFM
                                        className="w-56 h-9 justify-between"
                                        onDateChange={(selectedDate) => {
                                          setFieldValue(`nominees.${index}.dob`, moment(selectedDate).format('YYYY-MM-DD'))
                                        }}
                                        name={`nominees.${index}.dob`}
                                        defaultValue={values.nominees[index].dob ? new Date(values.nominees[index].dob) : null}
                                        maxDate={new Date()}
                                      />
                                    </div>
                                    <div className="flex justify-between text-sm">
                                      <span className="mt-2">Date of Nomination</span>
                                      <DatePickerComponentFM
                                        className="w-56 h-9 justify-between"
                                        onDateChange={(selectedDate) => {
                                          setFieldValue(`nominees.${index}.dateOfNomination`, moment(selectedDate).format('YYYY-MM-DD'))
                                        }}
                                        name={`nominees.${index}.dateOfNomination`}
                                        defaultValue={values.nominees[index].dateOfNomination ? new Date(values.nominees[index].dateOfNomination) : null}
                                        maxDate={new Date()}
                                      />
                                    </div>
                                    {/* <div className="flex justify-between text-sm">
                                      <span className="mt-2">
                                        Managing Committee Meeting Date
                                      </span>
                                      <DatePickerComponentFM
                                        className="w-56 h-9 justify-between"
                                        onDateChange={(selectedDate) => {
                                          setFieldValue(`nominees.${index}.dateOfMC`, moment(selectedDate).format('YYYY-MM-DD'))
                                        }}
                                        name={`nominees.${index}.dateOfMC`}
                                        defaultValue={values.nominees[index].dateOfMC ? new Date(values.nominees[index].dateOfMC) : null}
                                        maxDate={new Date()}
                                      />
                                    </div> */}
                                    <div>
                                      <TextAreaFM
                                        label="Remarks"
                                        placeholder="Enter Remarks"
                                        className="w-56 h-20"
                                        name={`nominees.${index}.remarks`}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="flex w-[49%] h-[430px]  items-center justify-center mt-4">
                          <button
                            type="button"
                            label="Add More"
                            onClick={()=>{handleAddMoreClick(arrayHelpers)}}
                            className="text-red-650 font-semibold border-2 border-dashed border-red-650 w-full h-[155px] p-2 bg-[#FDF6F6] flex justify-center rounded-md items-center"
                          >
                            Add More
                          </button>
                        </div>
                      </>)}
                      ></FieldArray>
                  </div>
                }
              </Form>
            )}}
            </Formik>
      </div>
      <RevokeDetailsModal
        isOpen={revokeModal}
        onCancel={() => setRevokeModal(false)}
        onConfirm={() => {
            setRevokeModal(false);
        }}
      />
    </Layout>
  );
};

export default NomineesDetails;
