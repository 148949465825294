import React from "react";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import Layout from "../../Layout/Layout";
import { useNavigate } from "react-router-dom";
import ButtonIco from "../../../components/Button/ButtonPrint";
import { faPrint } from "@fortawesome/free-solid-svg-icons";

function ViewDocIssued() {
  const navigate = useNavigate();

  const handlePrint = () => {
    // window.open(`${""}?${createSearchParams([...searchParams.entries()])}`)
  };

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack
            title="NOC For Transfer"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ButtonIco icon={faPrint} children="Print" onClick={handlePrint} />
          </ListHeaderBack>
        </div>
        <div className="p-5 gap-2 my-1">
          <div className="text-base flex justify-between">
            <span className="text-left">TelePhone No.: 9876-543210</span>
            <span className="text-right">Date: 11-04-2023</span>
          </div>
          <div className="mt-6">
            <p className="text-[24px] font-bold text-red-650 text-center">
              Treasure Town Society
            </p>
            <p className="text-center text-base font-semibold">
              Society Address
            </p>
          </div>
          <div className="border-[#222] border-b my-6" />
          <div className="text-base font-bold text-center">
            TO WHOMESOEVER IT MAY CONCERN
          </div>
          <div className="mt-5 text-left text-[13px]">
            <p className="font-semibold mb-4">To,</p>
            <p className="font-semibold">Mr. Dheeraj Karda</p>
            <p className="font-semibold">P-Block-TPP01 Synapse</p>
            <p className="font-semibold">Mumbai, Kurla</p>

            <p className="mt-8 mb-3">Dear Sir/Ma'am</p>

            <p className="mb-3">
              With reference to your application dated —. This Society has no
              objection to transfer the flat in occupation with you too .
              Provided you will abide by the provision of Co-op. Act .and Rules,
              and the provision of State Government as well as the Provision of
              Society Bye Law.
            </p>

            <p className="mb-6">
              The said building has ground Plus Three storey without the
              facility of lift. The area of the Block No. P-Block-TPP001 is
              admeasuring 1210 Sq. Ft carpet/Builtup.
            </p>

            <p className="">Your's Truly</p>
            <p className="font-semibold">Synapse</p>
          </div>
          <div className="text-right font-semibold text-[13px] mt-8">
            Honorary Secretary
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ViewDocIssued;
