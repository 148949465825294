export const ViewStaffDetailsData = [
  {
    label: "",
    values: [
      [
        {
          key: "staff_fname",
          label: "Staff Name:",
          value: "Rakesh",
        },
      ],
      [
        {
          key: "emp_code",
          label: "EMP Id:",
          value: "EMP-0002",
        },
        {
          key: "staff_code",
          label: "Code",
          value: "9755",
        },
      ],
      [
        { key: "staff_gender", label: "Gender", value: "Male" },
        { key: "dob", label: "DOB", value: "-" },
      ],
      [{ key: "staff_email", label: "Email", value: "9776543210" }],
      [{ key: "phone_number", label: "Contact", value: "9776543210" }],
      [{ key: "staff_role", label: "Profile", value: "Office Staff" }],
      [
        {
          key: "vehicle_type",
          label: "Vehicle Type",
          value: "2 Wheeler",
        },
        {
          key: "staff_vehicle_number",
          label: "Vehicle Number:",
          value: "MP09AAXXXX",
        },
      ],
      [
        {
          key: "staff_address",
          label: "Address",
          value: "-",
        },
      ],
    ],
  },

  {
    label: "Other Details",
    values: [
      [{ key: "staff_type", label: "Type", value: "Society" }],
      [
        {
          key: "staff_salary",
          label: "Salary",
          value: "INR 50000",
        },
      ],
      [
        {
          key: "id_validity",
          label: "ID Validity:",
          value: "Mar 1, 2023 - Mar 1, 2024",
        },
      ],
    ],
  },

  {
    label: "Approval Details",
    values: [
      [
        {
          key: "approval_status",
          label: "Status: ",
          value: {
            Approval: "Approved",
            Reject: "Rejected",
            Pending: "Unapproved (Pending)",
          },
        },
      ],
      [
        {
          key: "approval_comments",
          label: "Comments:",
          value: "Auto-appproval Process",
        },
      ],
      [
        {
          key: "approved_by",
          label: " Approved  By:",
          value: "Auto-appproval Process",
        },
      ],
    ],
  },

  {
    label: "Document Details",
    values: [
      [
        {
          key: "pan_card_no",
          key2: "pan_image_url",
          label: "Pan No.",
          value: "N/A",
        },
      ],
      [
        {
          key: "uid_no",
          key2: "uid_image_url",
          label: "Identity Proof No.",
          value: "N/A",
        },
      ],
      [
        {
          key: "driving_licence_no",
          key2: "driving_licence_image_url",
          label: "Driving Licence No.",
          value: "N/A",
        },
      ],
      [
        {
          key: "ration_no",
          key2: "ration_image_url",
          label: "Ration No.",
          value: "N/A",
        },
      ],
    ],
  },
];
