import { call, put, takeLatest } from 'redux-saga/effects'
import {notify} from '../../../../utils/notification'
import * as types from '../../../actions/index.types'
import * as req from '../../../../axios/request/FinanceReqs/SettingReqs/DueRemindersReqs'

function* getDueReminderDetails(action) {
    try {
        const result = yield call(req.getDueReminderDetailsReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_DUE_REMINDER_DETAILS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_DUE_REMINDER_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateDueReminder(action) {
    try {
        const result = yield call(req.updateDueReminderDetailsReq, action);
        if (result.success === true) {
            notify('success', result.message)
            action.data?.onSuccessCallback?.()
            yield put({ type: types.UPDATE_DUE_REMINDER_DETAILS_SUCCESS, payload: result.data});  
            const newList = yield call(req.getDueReminderDetailsReq, action)
            if (newList.success === true)
                yield put({ type: types.GET_DUE_REMINDER_DETAILS_SUCCESS, payload: newList.data});  
        } else {
            yield put({ type: types.UPDATE_DUE_REMINDER_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* deleteDueReminder(action) {
    try {
        const result = yield call(req.deleteDueReminderDetailsReq, action);
        if (result.success === true) {
            notify('success', result.message)
            action.data?.onSuccessCallback?.()
            yield put({ type: types.DELETE_DUE_REMINDER_SUCCESS, payload: action.data});  
            const newList = yield call(req.getDueReminderDetailsReq, action)
            if (newList.success === true)
                yield put({ type: types.GET_DUE_REMINDER_DETAILS_SUCCESS, payload: newList.data});  
        } else {
            yield put({ type: types.DELETE_DUE_REMINDER_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getDebitNoteDueReminderDetails(action) {
    try {
        const result = yield call(req.getDebitNoteDueReminderDetailsReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_DEBIT_NOTE_DUE_REMINDER_DETAILS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_DEBIT_NOTE_DUE_REMINDER_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateDebitNoteDueReminderDetails(action) {
    try {
        const result = yield call(req.updateDebitNoteDueReminderDetailsReq, action);
        if (result.success === true) {
            notify('success', result.message)
            action.data?.onSuccessCallback?.()
            yield put({ type: types.UPDATE_DEBIT_NOTE_DUE_REMINDER_DETAILS_SUCCESS, payload: result.data});  
            const newList = yield call(req.getDebitNoteDueReminderDetailsReq, action)
            if (newList.success === true)
                yield put({ type: types.GET_DEBIT_NOTE_DUE_REMINDER_DETAILS_SUCCESS, payload: newList.data});  
        } else {
            yield put({ type: types.UPDATE_DEBIT_NOTE_DUE_REMINDER_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getFDMaturityDueReminderDetails(action) {
    try {
        const result = yield call(req.getFDMaturityDueReminderDetailsReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_FD_MATURITY_DUE_REMINDER_DETAILS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_FD_MATURITY_DUE_REMINDER_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateFDMaturityDueReminderDetails(action) {
    try {
        const result = yield call(req.updateFDMaturityDueReminderDetailsReq, action);
        if (result.success === true) {
            notify('success', result.message)
            action.data?.onSuccessCallback?.()
            yield put({ type: types.UPDATE_FD_MATURITY_DUE_REMINDER_DETAILS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.UPDATE_FD_MATURITY_DUE_REMINDER_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getMemberDueReminderDetails(action) {
    try {
        const result = yield call(req.getMemberDueReminderDetailsReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_MEMBER_DUE_REMINDER_DETAILS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_MEMBER_DUE_REMINDER_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateMemberDueReminderDetails(action) {
    try {
        const result = yield call(req.updateMemberDueReminderDetailsReq, action);
        if (result.success === true) {
            notify('success', result.message)
            action.data?.onSuccessCallback?.()
            yield put({ type: types.UPDATE_MEMBER_DUE_REMINDER_DETAILS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.UPDATE_MEMBER_DUE_REMINDER_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getNomineeDueReminderDetails(action) {
    try {
        const result = yield call(req.getNomineeDueReminderDetailsReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_NOMINEE_DUE_REMINDER_DETAILS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_NOMINEE_DUE_REMINDER_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateNomineeDueReminderDetails(action) {
    try {
        const result = yield call(req.updateNomineeDueReminderDetailsReq, action);
        if (result.success === true) {
            notify('success', result.message)
            action.data?.onSuccessCallback?.()
            yield put({ type: types.UPDATE_NOMINEE_DUE_REMINDER_DETAILS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.UPDATE_NOMINEE_DUE_REMINDER_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getMemberListAccordingToRole(action) {
    try {
        const result = yield call(req.getMemberListAccordingToRoleReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_MEMBER_LIST_ACCORDING_TO_ROLE_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_MEMBER_LIST_ACCORDING_TO_ROLE_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

export default function* dueReminderSaga() {
    yield takeLatest(types.GET_DUE_REMINDER_DETAILS, getDueReminderDetails);
    yield takeLatest(types.UPDATE_DUE_REMINDER_DETAILS, updateDueReminder);
    yield takeLatest(types.DELETE_DUE_REMINDER, deleteDueReminder);
    yield takeLatest(types.GET_DEBIT_NOTE_DUE_REMINDER_DETAILS, getDebitNoteDueReminderDetails);
    yield takeLatest(types.UPDATE_DEBIT_NOTE_DUE_REMINDER_DETAILS, updateDebitNoteDueReminderDetails);
    yield takeLatest(types.GET_FD_MATURITY_DUE_REMINDER_DETAILS, getFDMaturityDueReminderDetails);
    yield takeLatest(types.UPDATE_FD_MATURITY_DUE_REMINDER_DETAILS, updateFDMaturityDueReminderDetails);
    yield takeLatest(types.GET_MEMBER_DUE_REMINDER_DETAILS, getMemberDueReminderDetails);
    yield takeLatest(types.UPDATE_MEMBER_DUE_REMINDER_DETAILS, updateMemberDueReminderDetails);
    yield takeLatest(types.GET_NOMINEE_DUE_REMINDER_DETAILS, getNomineeDueReminderDetails);
    yield takeLatest(types.UPDATE_NOMINEE_DUE_REMINDER_DETAILS, updateNomineeDueReminderDetails);
    yield takeLatest(types.GET_MEMBER_LIST_ACCORDING_TO_ROLE, getMemberListAccordingToRole);
}