import * as type from '../../../actions/index.types'

export function leaveAndLicenseReducer(state = {
  loading: false,
  updateLoader: false,
  leaveAndLicenseList: null,
  error:null
}, action) {

switch (action.type) {
  case type.GET_LEAVE_AND_LICENSE_LIST:
    return {
      ...state,
      loading: true,
      error: null
    }
  case type.GET_LEAVE_AND_LICENSE_LIST_SUCCESS:
    return {
        ...state,
        error: null,
        loading: false,
        leaveAndLicenseList: action.payload
    }

  case type.GET_LEAVE_AND_LICENSE_LIST_FAILURE:
    return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        loading: false,
        leaveAndLicenseList: null,
    }

default:
  return state
}
}