import React from "react";
import { Arrow } from "../../assets";

const ListHeaderBack = ({ onClick, title, children , className }) => {

  return (
    <div className={`${className} flex justify-between border-[#CCC] pb-1`}>
      <div className="text-base font-semibold p-1">
        <div className="flex gap-1">
          <button
            onClick={onClick}
            className="text-sm p-1 text-red-650 font-semibold flex gap-2 justify-center items-center"
            type='button'
          >
            <span>
              <Arrow className="fill-red-650" />
            </span>
            {"Back"}
          </button>
          <span className="border-r border-[#CCCCCC]"></span>
          <h1 className="text-sm font-semibold p-1">
            {title}
          </h1>
        </div>
      </div>
      <div className="flex gap-2">{children}</div>
    </div>
  );
};

export default ListHeaderBack;