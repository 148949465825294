import { call, put, takeLatest } from 'redux-saga/effects'
import {notify} from '../../../../utils/notification'
import * as types from '../../../actions/index.types'
import * as req from '../../../../axios/request/SetupRecordsReqs/BuildingReqs/WingsAndFlatsReqs'


function* getWingsAndFlatsDetails(action) {
    try {
        const result = yield call(req.getWingsAndFlatsDetailsReq);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_WINGS_AND_FLATS_DETAILS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_WINGS_AND_FLATS_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* addWings(action) {
    try {
        const result = yield call(req.addWingsReq, action);
        if (result.statusCode === 200) {
            notify('success', result.message);
            action.data?.onSucessCallback()
            yield put({ type: types.ADD_WINGS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.ADD_WINGS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* deleteWing(action) {
    try {
        const result = yield call(req.deleteWingsReq, action);
        if (result.statusCode === 200) {
            notify('success', result.message);
            action.data?.onSuccessCallback()
        } else {
        }
    } catch (e) {
        console.log(e)
    }
}

function* getWingsAuditlogs(action) {
    try {
        const result = yield call(req.getWingsAuditLogReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_WINGS_AUDIT_LOGS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_WINGS_AUDIT_LOGS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* downloadWingsExcelSheet(action) {
    try {
        const result = yield call(req.downloadWingsExcelSheetReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
        }else{
            notify('Unable to download sheet.')
        }
    } catch (e) {
        console.log(e)
    }
}

function* getFlatsDetails(action) {
    try {
        const result = yield call(req.getFlatsDetailsReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_FLATS_DETAILS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_FLATS_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* addFlats(action) {
    try {
        const result = yield call(req.addFlatsReq, action);
        if (result.statusCode === 200) {
            notify('success', result.message);
            action.data?.onSucessCallback()
            yield put({ type: types.ADD_FLATS_DETAILS_SUCCESS});  
        } else {
            yield put({ type: types.ADD_FLATS_DETAILS_FAILURE});
        }
    } catch (e) {
        console.log(e)
    }
}

function* deleteFlat(action) {
    try {
        const result = yield call(req.deleteFlatsReq, action);
        if (result.statusCode === 200) {
            notify('success', result.message);
            action.data?.onSuccessCallback()
        } else {
        }
    } catch (e) {
        console.log(e)
    }
}

function* downloadFlatsExcelSheet(action) {
    try {
        const result = yield call(req.downloadFlatsExcelSheetReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
        }else{
            notify('Unable to download sheet.')
        }
    } catch (e) {
    }
}

function* uploadFlatsExcelSheet(action) {
    try {
        const result = yield call(req.uploadFlatsReq, action);
        if (result.statusCode === 200) {
            notify('success', 'File uploaded successfully')
            yield put({ type: types.UPLOAD_FLATS_EXCEL_SHEET_SUCCESS, payload: result.data});  
            action.data?.onSuccessCallback()
        }else{
            yield put({ type: types.UPLOAD_FLATS_EXCEL_SHEET_FAILURE, payload: result.data});  
        }
        action.data?.onRequestEndCallback(result)
    } catch (e) {
        console.log(e)
    }
}

function* getBillTariffDetails(action) {
    try {
        const result = yield call(req.getBillTariffDetailsReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_BILL_TARIFF_DETAILS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_BILL_TARIFF_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* downloadBillTariffExcelSheet(action) {
    try {
        const result = yield call(req.downloadBillTariffExcelSheetReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
        }else{
            notify('Unable to download sheet.')
        }
    } catch (e) {
        console.log(e)
    }
}

function* uploadBillTariffExcelSheet(action) {
    try {
        const result = yield call(req.uploadBillTariffReq, action);
        if (result.statusCode === 200) {
            notify('success', 'File uploaded successfully')
            yield put({ type: types.UPLOAD_BILL_TARIFF_EXCEL_SHEET_SUCCESS, payload: result.data});  
            action.data?.onSuccessCallback()
        }else{
            yield put({ type: types.UPLOAD_BILL_TARIFF_EXCEL_SHEET_FAILURE, payload: result.data});  
        }
        action.data?.onRequestEndCallback(result)
    } catch (e) {
        console.log(e)
    }
}

function* updateBillTariff(action) {
    try {
        const result = yield call(req.updateBillTariffReq, action);
        if (result.statusCode === 200) {
            notify('success', result.message);
            yield put({ type: types.UPDATE_BILL_TARIFF_DETAILS_SUCCESS});  
        } else {
            yield put({ type: types.UPDATE_BILL_TARIFF_DETAILS_FAILURE});
        }
    } catch (e) {
        console.log(e)
    }
}

function* getHeadNameList(action) {
    try {
        const result = yield call(req.getHeadNameListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_HEAD_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_HEAD_LIST_FAILURE, payload: result.data});  
        }
    } catch (e) {
        console.log(e)
    }
}


function* getUtilityReading(action) {
    try {
        const result = yield call(req.getUtilityReadingReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_UTILITY_READING_DETAILS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_UTILITY_READING_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateUtilityReading(action) {
    try {
        const result = yield call(req.updateUtilityReadingReq, action);
        if (result.statusCode === 200) {
            notify('success', result.message);
            yield put({ type: types.UPDATE_UTILITY_READING_DETAILS_SUCCESS});  
        } else {
            yield put({ type: types.UPDATE_UTILITY_READING_DETAILS_FAILURE});
        }
    } catch (e) {
        console.log(e)
    }
}

function* uploadUtilityReadingExcelSheet(action) {
    try {
        const result = yield call(req.uploadUtilityReadingReq, action);
        if (result.statusCode === 200) {
            notify('success', 'File uploaded successfully')
            yield put({ type: types.UPLOAD_UTILITY_READING_EXCEL_SHEET_SUCCESS, payload: result.data});  
            action.data?.onSuccessCallback()
        }else{
            yield put({ type: types.UPLOAD_UTILITY_READING_EXCEL_SHEET_FAILURE, payload: result.data});  
        }
        action.data?.onRequestEndCallback(result)
    } catch (e) {
        console.log(e)
    }
}

function* getFlatDetailsWithUserName(action) {
    try {
        const result = yield call(req.getFlatAndOccupantsDetailsReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_WINGS_AND_FLATS_DETAILS_WITH_USERNAME_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_WINGS_AND_FLATS_DETAILS_WITH_USERNAME_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}


export default function* wingsAndFlatsSaga() {
    yield takeLatest(types.GET_WINGS_AND_FLATS_DETAILS, getWingsAndFlatsDetails);
    yield takeLatest(types.ADD_WINGS, addWings);
    yield takeLatest(types.DELETE_WING, deleteWing);
    yield takeLatest(types.GET_WINGS_AUDIT_LOGS, getWingsAuditlogs);
    yield takeLatest(types.DOWNLOAD_WINGS_EXCEL_SHEET, downloadWingsExcelSheet);
    yield takeLatest(types.GET_FLATS_DETAILS, getFlatsDetails);
    yield takeLatest(types.ADD_FLATS_DETAILS, addFlats);
    yield takeLatest(types.DELETE_FLAT, deleteFlat);
    yield takeLatest(types.DOWNLOAD_FLATS_EXCEL_SHEET, downloadFlatsExcelSheet);
    yield takeLatest(types.UPLOAD_FLATS_EXCEL_SHEET, uploadFlatsExcelSheet);
    yield takeLatest(types.GET_BILL_TARIFF_DETAILS, getBillTariffDetails);
    yield takeLatest(types.DOWNLOAD_BILL_TARIFF_EXCEL_SHEET, downloadBillTariffExcelSheet);
    yield takeLatest(types.UPLOAD_BILL_TARIFF_EXCEL_SHEET, uploadBillTariffExcelSheet);
    yield takeLatest(types.UPDATE_BILL_TARIFF_DETAILS, updateBillTariff);
    yield takeLatest(types.GET_HEAD_LIST, getHeadNameList);
    yield takeLatest(types.GET_UTILITY_READING_DETAILS, getUtilityReading);
    yield takeLatest(types.UPDATE_UTILITY_READING_DETAILS, updateUtilityReading);
    yield takeLatest(types.UPLOAD_UTILITY_READING_EXCEL_SHEET, uploadUtilityReadingExcelSheet);
    yield takeLatest(types.GET_WINGS_AND_FLATS_DETAILS_WITH_USERNAME, getFlatDetailsWithUserName)
}