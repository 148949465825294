import React, { memo, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import "./Table.css";
import { SortIcon } from "../../assets";
import styled from "@emotion/styled";
import ItemsPerPage from "../ItemsPerPage/ItemsPerPage";
import Pagination from "../Pagination/Pagination";
import MultiSelect from "../MultiSelect/MultiSelect";
import { updateComplaintSetting } from "../../redux/actions/DeskActions/ComplaintCategoryAction";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const CategoryLevelDropdown = ({
  data,
  setFormData,
  index,
  formKey,
  placeholder,
  subIndex,
}) => {
  const { loginData } = useSelector((state) => state.loginReducer);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(
    data?.filter((item) => item?.is_selected)?.map((item) => ({
        label: item?.title,
        value: item?.id,
      }))
  );

  const [isEdited, setEdited] = useState(false);

  useEffect(() => {
    setFormData((prev) => {
      let newFormData = prev;
      let levelIds = selected?.map((item) => item.value) || [];
      newFormData.cat_setting[index]['sub_category'][subIndex][formKey] = levelIds;
      return newFormData;
    });
  }, []);

  useEffect(() => {
    setFormData((prev) => {
      let newFormData = prev;
      let levelIds = selected?.map((item) => item.value) || [];
      newFormData.cat_setting[index]['sub_category'][subIndex][formKey] = levelIds;
      if (isEdited) {
        dispatch(
          updateComplaintSetting({
            societyId: loginData?.default_community?.community_id,
            cat_setting: newFormData.cat_setting[index].sub_category,
          })
        );
      }
      return newFormData;
    });
  }, [selected]);

  return (
    <div className="mb-3">
      <MultiSelect
        options={data?.map((item, index) => ({
          label: item?.title,
          value: item.id,
        }))}
        width="192px"
        height={"fit-content"}
        placeholder={placeholder}
        className="text-xs"
        isMulti={true}
        value={selected}
        onSelect={(selectedItem) => {
          setSelected(selectedItem);
          setEdited(true);
        }}
      />
    </div>
  );
};

const CategoryMultiSelectContainer = ({
  data,
  setFormData,
  index,
  placeholder,
  subIndex,
}) => {
  return (
    <>
      <TableCell
        size="small"
        align="left"
        sx={{
          // borderBottom: "1px solid #CCC",
          fontSize: "13px",

          pl: 0,
          pr: 1,
          wordBreak: "break-word",
          width: "150px",
        }}
      >
        <div className="col-span-2">
          <CategoryLevelDropdown
            data={data?.level_1_committee}
            setFormData={setFormData}
            index={index}
            subIndex={subIndex}
            formKey={"level_1_committee"}
            placeholder="Search Committee"
          />
          <CategoryLevelDropdown
            data={data?.level_1_employee}
            setFormData={setFormData}
            index={index}
            subIndex={subIndex}
            formKey={"level_1_employee"}
            placeholder="Search Employee"
          />
          <CategoryLevelDropdown
            data={data?.level_1_agency}
            setFormData={setFormData}
            index={index}
            subIndex={subIndex}
            formKey={"level_1_agency"}
            placeholder="Search Agency"
          />
        </div>
      </TableCell>
      <TableCell
        size="small"
        align="left"
        sx={{
          // borderBottom: "1px solid #CCC",
          fontSize: "13px",

          pl: 0,
          pr: 1,
          wordBreak: "break-word",
          width: "150px",
        }}
      >
        <div className="col-span-2">
          <CategoryLevelDropdown
            data={data?.level_2_committee}
            setFormData={setFormData}
            index={index}
            subIndex={subIndex}
            formKey={"level_2_committee"}
            placeholder="Search Committee"
          />
          <CategoryLevelDropdown
            data={data?.level_2_employee}
            setFormData={setFormData}
            index={index}
            subIndex={subIndex}
            formKey={"level_2_employee"}
            placeholder="Search Employee"
          />
          <CategoryLevelDropdown
            data={data?.level_2_agency}
            setFormData={setFormData}
            index={index}
            subIndex={subIndex}
            formKey={"level_2_agency"}
            placeholder="Search Agency"
          />
        </div>
      </TableCell>
      <TableCell
        size="small"
        align="left"
        sx={{
          // borderBottom: "1px solid #CCC",
          fontSize: "13px",

          pl: 0,
          pr: 1,
          wordBreak: "break-word",
          width: "150px",
        }}
      >
        <div className="col-span-2">
          <CategoryLevelDropdown
            data={data?.level_3_committee}
            setFormData={setFormData}
            index={index}
            subIndex={subIndex}
            formKey={"level_3_committee"}
            placeholder="Search Committee"
          />
          <CategoryLevelDropdown
            data={data?.level_3_employee}
            setFormData={setFormData}
            index={index}
            subIndex={subIndex}
            formKey={"level_3_employee"}
            placeholder="Search Employee"
          />
          <CategoryLevelDropdown
            data={data?.level_3_agency}
            setFormData={setFormData}
            index={index}
            subIndex={subIndex}
            formKey={"level_3_agency"}
            placeholder="Search Agency"
          />
        </div>
      </TableCell>
    </>
  );
};

const RowCategory = memo(({ data, setFormData, index }) => {
  const TableRowStyled = styled(TableRow)`
    &:nth-of-type(odd) {
      background-color: #ffffff;
    }
    // &:nth-of-type(even) {
    //   background-color: #f4f5f8;
    // }
    &:nth-of-type(odd):hover {
      background-color: #ffffff;
    }
    // &:nth-of-type(even):hover {
    //   background-color: #f4f5f8;
    // }
  `;
  return (
    <>
      <TableRowStyled
        key={data.id}
        role="checkbox"
        // aria-checked={isRowSelected}
        tabIndex={-1}
        // selected={isRowSelected}
        sx={{ cursor: "pointer" }}
      >
           <TableCell
            size="small"
            align="left"
            colSpan={4}
            sx={{
              border: "1px solid #CCC",
              fontSize: "15px",
              wordBreak: "break-word",
              width: "150px",
              backgroundColor: "#FDF2F3",
              // borderRadius: "8px"
            }}
          >
        {data?.title}
          </TableCell>
      </TableRowStyled>

      {data.sub_category?.map((subItem, subIndex) => (
        <TableRowStyled
          key={subItem.id}
          role="checkbox"
          // aria-checked={isRowSelected}
          tabIndex={-1}
          // selected={isRowSelected}
          sx={{ cursor: "pointer" }}
        >
          <TableCell
            size="small"
            align="left"
            sx={{
              // borderBottom: "1px solid #CCC",
              fontSize: "15px",
              pl: 5,
              pr: 1,
              wordBreak: "break-word",
              width: "150px",
            }}
          >
            {subItem?.name}
          </TableCell>
          <CategoryMultiSelectContainer
            data={subItem}
            setFormData={setFormData}
            index={index}
            subIndex={subIndex}
          />
        </TableRowStyled>
      ))}
    </>
  );
});

const ComplaintSettingTable = ({
  children,
  data,
  columns,
  checkbox = true,
  pagination = true,
  setAnyChecked,
  //props are required if pagination is true
  totalCount,
  totalPages,
  start,
  end,
  currentPage,
  getTableData,
}) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(currentPage || 1);
  const [rowsPerPage, setRowsPerPage] = useState(
    !pagination ? data.length : 10
  );
  const initializeFormData = () => {
    return data?.setting?.map((item, index) => ({
      id: item.id,
      sub_category: item?.sub_category?.map((subItem, subIndex) => ({
        id: subItem.id,
        level_1_agency: [],
        level_2_agency: [],
        level_3_agency: [],

        level_1_committee: [],
        level_2_committee: [],
        level_3_committee: [],

        level_1_employee: [],
        level_2_employee: [],
        level_3_employee: [],
      })),
    }));
  };
  const [formData, setFormData] = useState({
    cat_setting: initializeFormData(),
  });

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    const newSelected = event.target.checked ? data.map((item) => item.id) : [];
    setSelected(newSelected);
  };

  const handleClick = (event, id) => {
    const newSelected = selected.includes(id)
      ? selected.filter((selectedId) => selectedId !== id)
      : [...selected, id];
    setSelected(newSelected);
  };

  const handleChangePage = (newPage) => {
    if (page !== newPage) {
      setPage(newPage);
      getTableData(rowsPerPage, newPage);
    }
  };

  const handleChangeRowsPerPage = (value) => {
    if (rowsPerPage !== value) {
      getTableData(value, page);
      setRowsPerPage(value);
      setPage(0);
    }
  };

  const isSelected = (id) => selected.includes(id);

  const [checkedData, setCheckedData] = useState({});

  const checkedInputData = (key) => {
    if (checkedData.hasOwnProperty(key)) return checkedData[key];
    else return false;
  };

  const handleCheckedData = (key) => (isChecked) => {
    setCheckedData((prev) => ({ ...prev, [key]: isChecked }));
  };

  // const checkData = (values, setFieldValue, key, index, selectedItem) => {
  //   let object = values?.categories[index]?.[key];
  //   object.forEach((e, index) => {
  //     if (selectedItem?.filter((item) => item.itemIndex === index)?.length) {
  //       object[index].isSelected = 1;
  //     } else {
  //       object[index].isSelected = 0;
  //     }
  //   });
  //   setFieldValue(`categories.${index}.${key}`, object);
  // };

  return (
    <Box
      sx={{ width: "100%", height: "67%", fontSize: "13px" }}
      className="table-component"
    >
      <TableContainer
        className=""
        sx={{ minHeight: "500px" }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {!!checkbox && (
                <TableCell
                  size="small"
                  sx={{ py: 1.5, pl: 0, pr: 2, zIndex: 0, width: 36 }}
                >
                  <Checkbox
                    size="small"
                    indeterminate={false}
                    checked={data.length > 0 && selected.length === data.length}
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all",
                    }}
                    sx={{
                      p: 0,
                      color: "#AAAAA",
                      "&.Mui-checked": {
                        color: "#CF001C",
                      },
                    }}
                  />
                </TableCell>
              )}
              {columns.map((column) => (
                <TableCell
                  size="small"
                  key={column.id}
                  align={column.numeric ? "right" : "left"}
                  sortDirection={orderBy === column.id ? order : false}
                  sx={{
                    py: 1.5,
                    pl: 0,
                    pr: 1,
                    fontWeight: 600,
                    zIndex: 0,
                    color: "#AAAAAA",
                    wordWrap: "break-word",
                    fontSize: "13px",
                    width: column.width || "fit-content",
                    minWidth: column.width || "fit-content",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={() => {
                      if (column.sort) {
                        handleRequestSort(column.id);
                      }
                    }}
                    IconComponent={SortIcon}
                    sx={{
                      ".MuiTableSortLabel-icon": {
                        opacity: "1 !important",
                      },
                      svg: {
                        flexShrink: 0,
                      },
                    }}
                    hideSortIcon={!column.sort}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.setting?.map((row, index) => (
              <RowCategory
                data={row}
                key={row.id}
                setFormData={setFormData}
                index={index}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {!!pagination && (
        <div className="flex flex-row justify-between mt-3 pt-1 border-t border-[#ccc] text-[14px]">
          <ItemsPerPage
            rowsPerPage={rowsPerPage}
            onChange={handleChangeRowsPerPage}
          />
          <Pagination
            totalCount={totalCount}
            totalPages={totalPages}
            start={start}
            end={end}
            currentPage={page}
            onChange={handleChangePage}
          />
        </div>
      )}
    </Box>
  );
};

// ComplaintSettingTable.propTypes = {
//   data: PropTypes.array.isRequired,
//   columns: PropTypes.array.isRequired,
//   defaultSortField: PropTypes.string,
//   rowsPerPageOptions: PropTypes.array,
// };

export default ComplaintSettingTable;
