import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Arrow } from "../../../assets";
import { LOGIN_ENDPOINT } from "../../../Routing/routes";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { forgotPasswordValidation } from "../../../validationSchema/authSchema";
import { societyForgotPassword } from '../../../redux/actions/AuthActions/SocietyForgotPasswordAction'
import { CircularProgress } from "@mui/material";

const ResetPasswordForm = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { loading } = useSelector(state => state.forgotPasswordReducer)

  const handleForgotPassword = (values, { resetForm }) => {
    const sendRequest = {
      ...values,
      onSuccessCallback: () => {
        navigate(LOGIN_ENDPOINT);
      }
    };
    dispatch(societyForgotPassword(sendRequest))
    resetForm()
  }

  return (
    <div className="flex justify-center items-center h-full w-auto bgClasGrad">
      <div className="rounded p-8 mb-4">
        <div>
          <h3 className="flex justify-center">Reset Password For</h3>
          <h1 className="tracking-normal text-red-600 font-bold text-3xl">
            Zipgrid Neo Console
          </h1>
          <div className="border border-1 border-black-700 m-5 opacity-1"></div>
        </div>

        <Formik
          initialValues={{
            email: ''
          }}
          validationSchema={forgotPasswordValidation}
          onSubmit={handleForgotPassword}
        >
          {
            (formik) => {
              return (
                <Form>
                  <div className="mb-4">
                    <Field
                      className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight italic
                      focus:outline-none focus:shadow-outline ${
                        formik.touched.email && formik.errors.email ? "border-red-500" : ""
                      }`}
                      id="username"
                      type="text"
                      name="email"
                      placeholder="Email Address"
                      autoComplete="off"
                    />
                    <div className="text-red-500 text-xs italic h-2">
                      <ErrorMessage name='email' />
                    </div>
                  </div>
                  <button
                    className={`bg-red-300  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full border-solid border-[#941A25] ${
                      !loading && (formik.dirty && formik.isValid) ? "bg-red-650 cursor-pointer" : "cursor-not-allowed"
                    }`}
                    type="submit"
                    disabled={loading || !(formik.dirty && formik.isValid)}
                  >
                    {
                      loading ?
                        <CircularProgress sx={{ color: "white" }} size={17} />
                        :
                        'Send Reset Link'
                    }
                  </button>

                  <div className="mt-4 text-gray-400 flex justify-center items-center hover:text-red-600">
                    <Link to={LOGIN_ENDPOINT} className="hover-arrow">
                      <h3 className="relative flex items-center hover-arrow gap-2">
                        <span className="arrow-icon mr-4 -ml-1 animate-pulse">
                          <Arrow />
                        </span>
                        Back to Login
                      </h3>
                    </Link>
                  </div>
                </Form>
              )
            }
          }
        </Formik>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
