import React, { useRef } from "react";
import { TextInputFM } from "../../../components/InputFields/TextInput";
import Modal from "../../../components/Modal/Modal";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { addUpdateParkingTypeDetails } from "../../../redux/actions/GateManagementActions/ParkingManagementAction";
import { useSelector } from "react-redux";
import { AddParkingTypeValidation } from "../../../validationSchema/gateManagementSchema/visitorAndVehicleInAndOutSchema";

const EditParkingTypeModal = ({ isOpen, data, onCancel, loading }) => {
  const formRef = useRef();
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {updateLoader} = useSelector(state=>state.parkingManagementReducer)

  const onConfirm = () =>{
    formRef.current?.submitForm();
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      title="Add Parking Type"
      onCancel={onCancel}
      onConfirm={onConfirm}
      loading={updateLoader}
    >
      <Formik
        initialValues={{
          parkingType: data.parkingType || '',
          parkingTypeId: data.parkingTypeId || 0
        }}
        innerRef={(f) => (formRef.current = f)}
        validationSchema={AddParkingTypeValidation}
        onSubmit={(values, {resetForm})=>dispatch(addUpdateParkingTypeDetails({...values, societyId: loginData?.default_community?.community_id, onSuccessCallback: ()=>{onCancel()}}))}
        >
          {
            ({values})=>(
            <Form>
              <TextInputFM label="Add Parking Type" placeholder="Enter Parking Type" name='parkingType' />
            </Form>
            )
        }
      </Formik>
    </Modal>
  );
};

export default EditParkingTypeModal;
