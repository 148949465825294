import React, { useEffect } from "react";
import Layout from "../../Layout/Layout";
import SocietyFormsandBylawsAccordion from "./SocietyFormsandBylawsAccordion";
import ListHeader from "../../../components/ListHeader/ListHeader";
import { useDispatch } from "react-redux";
import { getSocietyFormsAndByeLaws } from "../../../redux/actions/SetupRecordsActions/SocietyFormsAndByeLawsActions/SocietyFormsAndByeLawsAction";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";

function SocietyFormsandBylaws({ action }) {

  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading, societyFormsAndByeLawsData} = useSelector(state=>state.SocietyFormsAndByeLawsReducer)

  useEffect(()=>{
    dispatch(getSocietyFormsAndByeLaws({stateId: loginData?.default_community?.state}))
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader
            leftContent={"Society Forms and Bylaws"}
          >
          </ListHeader>
        </div>
          <div>
            {
              loading ?
              <Loader/>:
              <>
              {
                societyFormsAndByeLawsData &&
                <SocietyFormsandBylawsAccordion data={societyFormsAndByeLawsData} />
              }
              </>
            }
          </div>

      </div>
    </Layout>
  );
}

export default SocietyFormsandBylaws;
