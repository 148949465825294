import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const AccordionTable = React.memo(({ isOpen, toggleAccordion, label, children }) => (
  <div className="border rounded-lg border-[#CCCCCC] my-2 overflow-hidden">
    <div
      className="flex justify-between cursor-pointer items-center bg-[#FDF2F3] rounded-t-lg p-3 border-b"
      onClick={toggleAccordion}
    >
      <div className="flex items-center">
        <button className="text-white text-sm font-semibold mr-3 w-5 h-5 bg-red-650 border rounded flex justify-center items-center">
          {isOpen ? (
            <FontAwesomeIcon icon={faChevronUp} size="xs" />
          ) : (
            <FontAwesomeIcon icon={faChevronDown} size="xs" />
          )}
        </button>
        <div className="text-[#000000] text-sm font-semibold">{label}</div>
      </div>
    </div>
    {isOpen && <div className="p-3 flex justify-between">{children}</div>}
  </div>
));

export default AccordionTable;
