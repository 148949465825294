import * as types from '../../index.types';

export function getImportantContactList(data) {
    return {
        type: types.GET_IMPORTANT_CONTACTS_LIST,
        data
    };
}

export function getContactCategoryList(data) {
    return {
        type: types.GET_CONTACTS_CATEGORY_LIST,
        data
    };
}
