import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import parse from "html-react-parser";
import Loader from "../../../../components/Loader/Loader";
import { getMyInvoiceReceipt } from "../../../../redux/actions/FinanceActions/MyInvoiceActions";
import { getDebitNoteReceiptPrint } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageDebitNoteAction";

function PrintDebitReceipt() {
  const data = useLocation().state;
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { printDebitReceipt, printLoader } = useSelector(
    (state) => state.manageDebitNoteReducer
  );

  useEffect(() => {
    if (data)
      dispatch(
        getDebitNoteReceiptPrint({
          societyId: loginData?.default_community?.community_id,
          invid: data?.invid,
        })
      );
    else
      dispatch(
        getDebitNoteReceiptPrint({
          societyId: loginData?.default_community?.community_id,
          invid: [id].map(Number),
        })
      );
  }, []);

  return (
    <div>
      {printLoader || !printDebitReceipt ? (
        <Loader />
      ) : (
        <>{parse(printDebitReceipt)}</>
      )}
    </div>
  );
}

export default PrintDebitReceipt;
