import {
    LOGIN, RELOGIN
} from '../index.types';


export function login(data) {
    return {
        type: LOGIN,
        data,
    };
}

export function relogin(data) {
    return {
        type: RELOGIN,
        data,
    };
}

