import React, { useEffect, useState } from "react";

function TextRadio({ options, label, defaultSelected, onChange, className, name, disabled=false }) {
  const [selectedValue, setSelectedValue] = useState(defaultSelected);

  const handleRadioChange = (value) => {
    setSelectedValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  useEffect(()=>{
    if(defaultSelected){
      setSelectedValue(defaultSelected)
    }
  }, [defaultSelected])

  return (
    <div className="flex items-center justify-between">
      {label && <span className="text-sm">{label}</span>}
  
      <div className={`text-sm flex  items-center ${className}`}>
        {options.map((option) => (
          <label
            key={option.value}
            className={`mr-4 flex items-center ${selectedValue === option.value ? "" : ""}`}
          >
            <input
              type="radio"
              name={name || option.name}
              value={option.value}
              checked={selectedValue === option.value}
              onChange={() => handleRadioChange(option.value)}
              className="mr-2"
              style={{
                accentColor: "#CF001C",
              }}
              disabled={disabled}
            />
            {option.label}
          </label>
        ))}
      </div>
    </div>
  );
}

export default TextRadio;
