import React, { useState } from "react";
import Modal from "react-modal";
import "./UploadModal.css";
import closeIcon from "../../assets/icons/closeIcon.svg";
import UploadIcon from "../../assets/icons/uploadIcon.svg";
import { TextArea } from "../InputFields/TextArea";

const UploadModal = ({ isOpen, onClose }) => {
  const [sections, setSections] = useState([1, 2, 3]);
  const [file, setFile] = useState(null);

  const handleAddMore = () => {
    setSections([...sections, sections.length + 1]);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      setFile(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} contentLabel="Upload Modal">
      <div className="flex flex-col h-full">
        <div className="flex">
          <div className="w-1/4"></div>
          <div className="flex justify-between items-center w-3/4 ml-32">
            <h1 className="text-lg font-bold text-center">Share Images</h1>
            <button
              onClick={onClose}
              className="cursor-pointer p-2 border rounded-full bg-gray-200 "
            >
              <img src={closeIcon} alt="Close" />
            </button>
          </div>
        </div>
        <div className="border-b-2 p-2"></div>

        <div className="overflow-y-auto">
          {sections.map((section) => (
            <div key={section} className="flex mt-5">
              <div
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                className="flex flex-col justify-center items-center border border-dashed rounded-lg h-[120px] w-1/4"
              >
                <label
                  htmlFor={`fileInput${section}`}
                  className="bg-gray-200 border rounded-full border-[#E6EFFF] opacity-1 cursor-pointer"
                >
                  <img src={UploadIcon} alt="Upload" className="p-2" />
                </label>
                <h3 className="font-bold text-sm text-center mt-1">
                  Add Photo
                </h3>
                <p className="text-gray-400 opacity-1 text-center">
                  or drag and drop
                </p>
                <input
                  id={`fileInput${section}`}
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                />
              </div>
              <div className="w-3/4 h-3 ml-2">
                <TextArea
                  label=""
                  row={6}
                  placeholder="Enter Something Here"
                  className="w-[400px] ml-3"
                />
              </div>
            </div>
          ))}
        </div>

        <div className="flex flex-col justify-end items-end mt-10">
          <button
            onClick={handleAddMore}
            className="text-red-650 font-semibold mt-2"
          >
            + Add More
          </button>
        </div>

        <div className="border-b-2 p-2"></div>

        <div className="flex justify-center items-center mt-4">
          <button className="decoration-none bg-red-650 text-white px-4 py-1 rounded-lg mt-2">
            Post
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default UploadModal;
