import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import "./Login.css";
import LoginForm from "./LoginForm";
import AuthContainer from "../AuthContainer"; 
import { HOME_ENDPOINT, VISITOR_DASHBOARD_ENDPOINT } from "../../../Routing/routes";
import webTokenStorer from "../../../utils/webStorer";

const Login = () => {
  const navigate = useNavigate();

    if(webTokenStorer.getToken()){
      return <Navigate to={HOME_ENDPOINT}/>
      // return <Navigate to={VISITOR_DASHBOARD_ENDPOINT}/>
    }

  return (
    <AuthContainer>
      <LoginForm navigate={navigate} />
    </AuthContainer>
  );
};

export default Login;
