import * as type from "../../actions/index.types";

export function oldFinancialReportsReducers(
  state = {
    loading: false,
    loadingCategories: false,
    updateLoader: false,
    billTypeList: null,
    financeMembersList: null,
    billGenerationReports: null,
    memberBillReports: null,
    societyBillReports: null,
    interestStatementReports: null,
    eInvoiceReports: null,
    outStandingReports: null,
    groupTrailBalance: null,
    openingBalance: null,
    receiptsPayments: null,
    journals: null,
    cashBankBook: null,
    ledgers: null,
    allMembersLedgers: null,
    chartOfAccountBookData: null,
    error: null,
  },
  action
) {
  switch (action.type) {
    case type.GET_BILL_TYPE_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_BILL_TYPE_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        billTypeList: action.payload,
      };
    case type.GET_BILL_TYPE_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        billTypeList: null,
      };

    case type.GET_FINANCE_MEMBER_LIST:
      return {
        ...state,
        loadingCategories: true,
        error: null,
      };
    case type.GET_FINANCE_MEMBER_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loadingCategories: false,
        financeMembersList: action.payload,
      };
    case type.GET_FINANCE_MEMBER_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loadingCategories: false,
        financeMembersList: null,
      };

    case type.GET_BILL_GENERATION_VIEW_REPORTS:
      return {
        ...state,
        loadingCategories: true,
        error: null,
      };
    case type.GET_BILL_GENERATION_VIEW_REPORTS_SUCCESS:
      return {
        ...state,
        error: null,
        loadingCategories: false,
        billGenerationReports: action.payload,
      };
    case type.GET_BILL_GENERATION_VIEW_REPORTS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loadingCategories: false,
        billGenerationReports: null,
      };

    case type.GET_MEMBERS_BILL_REPORTS:
      return {
        ...state,
        loadingCategories: true,
        error: null,
      };
    case type.GET_MEMBERS_BILL_REPORTS_SUCCESS:
      return {
        ...state,
        error: null,
        loadingCategories: false,
        memberBillReports: action.payload,
      };
    case type.GET_MEMBERS_BILL_REPORTS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loadingCategories: false,
        memberBillReports: null,
      };

    case type.GET_SOCIETY_BILL_REPORTS:
      return {
        ...state,
        loadingCategories: true,
        error: null,
      };
    case type.GET_SOCIETY_BILL_REPORTS_SUCCESS:
      return {
        ...state,
        error: null,
        loadingCategories: false,
        societyBillReports: action.payload,
      };
    case type.GET_SOCIETY_BILL_REPORTS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loadingCategories: false,
        societyBillReports: null,
      };

    case type.GET_INTEREST_STATEMENT_REPORTS:
      return {
        ...state,
        loadingCategories: true,
        error: null,
      };
    case type.GET_INTEREST_STATEMENT_REPORTS_SUCCESS:
      return {
        ...state,
        error: null,
        loadingCategories: false,
        interestStatementReports: action.payload,
      };
    case type.GET_INTEREST_STATEMENT_REPORTS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loadingCategories: false,
        interestStatementReports: null,
      };

    case type.GET_EINVOICE_REPORTS:
      return {
        ...state,
        loadingCategories: true,
        error: null,
      };
    case type.GET_EINVOICE_REPORTS_SUCCESS:
      return {
        ...state,
        error: null,
        loadingCategories: false,
        eInvoiceReports: action.payload,
      };
    case type.GET_EINVOICE_REPORTS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loadingCategories: false,
        eInvoiceReports: null,
      };

    // finance reports

    case type.GET_OUTSTANDING_REPORTS:
      return {
        ...state,
        loadingCategories: true,
        error: null,
      };
    case type.GET_OUTSTANDING_REPORTS_SUCCESS:
      return {
        ...state,
        error: null,
        loadingCategories: false,
        outStandingReports: action.payload,
      };
    case type.GET_OUTSTANDING_REPORTS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loadingCategories: false,
        outStandingReports: null,
      };

    // accounts reports

    case type.GET_GROUP_TRAIL_BALANCE:
      return {
        ...state,
        loading: true,
        loadingCategories: true,
        error: null,
      };
    case type.GET_GROUP_TRAIL_BALANCE_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        loadingCategories: false,
        groupTrailBalance: action.payload,
      };
    case type.GET_GROUP_TRAIL_BALANCE_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        loadingCategories: false,
        groupTrailBalance: null,
      };

    case type.GET_OPENING_BALANCE_REPORTS:
      return {
        ...state,
        loadingCategories: true,
        loading: true,
        error: null,
      };
    case type.GET_OPENING_BALANCE_REPORTS_SUCCESS:
      return {
        ...state,
        error: null,
        loadingCategories: false,
        loading: false,
        openingBalance: action.payload,
      };
    case type.GET_OPENING_BALANCE_REPORTS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loadingCategories: false,
        loading: false,
        openingBalance: null,
      };

    case type.GET_RECEIPTS_PAYMENTS:
      return {
        ...state,
        loadingCategories: true,
        loading: true,
        error: null,
      };
    case type.GET_RECEIPTS_PAYMENTS_SUCCESS:
      return {
        ...state,
        error: null,
        loadingCategories: false,
        loading: false,
        receiptsPayments: action.payload,
      };
    case type.GET_RECEIPTS_PAYMENTS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loadingCategories: false,
        loading: false,
        receiptsPayments: null,
      };

    case type.GET_CASH_BANK_BOOK:
      return {
        ...state,
        loadingCategories: true,
        error: null,
      };
    case type.GET_CASH_BANK_BOOK_SUCCESS:
      return {
        ...state,
        error: null,
        loadingCategories: false,
        cashBankBook: action.payload,
      };
    case type.GET_CASH_BANK_BOOK_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loadingCategories: false,
        cashBankBook: null,
      };

    case type.GET_JOURNALS:
      return {
        ...state,
        loadingCategories: true,
        error: null,
      };
    case type.GET_JOURNALS_SUCCESS:
      return {
        ...state,
        error: null,
        loadingCategories: false,
        journals: action.payload,
      };
    case type.GET_JOURNALS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loadingCategories: false,
        journals: null,
      };

    case type.GET_LEDGERS:
      return {
        ...state,
        loadingCategories: true,
        error: null,
      };
    case type.GET_LEDGERS_SUCCESS:
      return {
        ...state,
        error: null,
        loadingCategories: false,
        ledgers: action.payload,
      };
    case type.GET_LEDGERS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loadingCategories: false,
        ledgers: null,
      };

    // case type.GET_ALL_MEMBER_LEDGERS:
    //   return {
    //     ...state,
    //     loadingCategories: true,
    //     error: null,
    //   };
    // case type.GET_ALL_MEMBER_LEDGERS_SUCCESS:
    //   return {
    //     ...state,
    //     error: null,
    //     loadingCategories: false,
    //     allMembersLedgers: action.payload,
    //   };
    // case type.GET_ALL_MEMBER_LEDGERS_FAILURE:
    //   return {
    //     ...state,
    //     error: action?.message || "Somthing went wrong.",
    //     loadingCategories: false,
    //     allMembersLedgers: null,
    //   };

    case type.GET_CHART_OF_ACCOUNT_BOOK_DATA:
      return {
        ...state,
        loadingCategories: true,
        error: null,
      };
    case type.GET_CHART_OF_ACCOUNT_BOOK_DATA_SUCCESS:
      return {
        ...state,
        error: null,
        loadingCategories: false,
        chartOfAccountBookData: action.payload,
      };
    case type.GET_CHART_OF_ACCOUNT_BOOK_DATA_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loadingCategories: false,
        chartOfAccountBookData: null,
      };

    default:
      return state;
  }
}
