import React, { useEffect } from "react";
import { Link, createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPrint } from "@fortawesome/free-solid-svg-icons";
import ContactTable from "./ContactTable";
import Layout from "../../pages/Layout/Layout";
import {
  ADD_CONTACT_ENDPOINT,
  CONTACT_ENDPOINT,
  MANAGE_CATEGORY_ENDPOINT,
  RESIDENT_VENDORS_ENDPOINT,
  SOCIETY_VENDORS_ENDPOINT,
} from "../../Routing/routes";
import Search from "../SearchBox/Search";
import ListHeader from "../ListHeader/ListHeader";
import { useDispatch } from "react-redux";
import { getContactCategoryList, getImportantContactList } from "../../redux/actions/SocialAndCommunicationActions/LobbyActions/ImportantContactsAction";
import { useSelector } from "react-redux";
import Dropdown2 from "../Dropdown/dropdown2";
import ButtonG from "../Button/ButtonG";


export const headingsContacts = [
  { id: 'sNo', label: 'SNo', sort: false, width: '35px' },
  { id: 'category', label: 'category', sort: true,width: '120px' },
  { id: 'name', label: 'Name', sort: true,width: '130px' },
  { id: 'address', label: 'Address', sort: true, width:'130px'  },
  { id: 'concernedPerson', label: 'Concerned Person', sort: true, width:'160px' },
  { id: 'contact', label: 'Contact No.', sort: true, width: '110px' },
  { id: 'email', label: 'Email ID', sort: true, width:'140px' },
  { id: 'status', label: 'Status', sort: true, width: '80px' },
  {
    id: "action",
    label: "Action",
    sort: false,
  },
];

const ContactsList = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading, importantContactList, categoryList} = useSelector(state=>state.importantContactsReducer)

  const handleSearch = (text)=>{
    if(text && text !== searchParams.get("search")){
      searchParams.delete("search")
      searchParams.delete('page')
      searchParams.delete('pageLimit')
      navigate({
        pathname: `${CONTACT_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["search", text]])}`,
      })
    }
  }

  const handelSelectCategory = (selectedItem)=>{
    if(selectedItem.value?.toString() !== searchParams.get("catId")){
      searchParams.delete('page')
      searchParams.delete('pageLimit')
      searchParams.delete("catId")
      navigate({
        pathname: `${CONTACT_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["catId", selectedItem.value]])}`,
      })
    }
  }

  const handlePrint = () => {
    window.print(); // Open the print dialog
  };

  const handleClear = ()=>{
    navigate(CONTACT_ENDPOINT)
  }

  const getTableData =() =>{
    const data={
      societyId: loginData?.default_community?.community_id,
      page: searchParams.get("page") || 1,
      searchText: searchParams.get("search") || '',
      pageLimit: searchParams.get("pageLimit") || 10,
      catId: searchParams.get("catId") || '',
    }

    dispatch(getImportantContactList(data))
  }

  useEffect(()=>{
    dispatch(getContactCategoryList({societyId: loginData?.default_community?.community_id}))
  }, [])

  useEffect(()=>{      
    getTableData()
  }, [searchParams])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <ListHeader leftContent="Important Contacts">
          <div className="flex text-sm gap-3 font-semibold">
            <Link to={SOCIETY_VENDORS_ENDPOINT} className="text-white">
              <button className="bg-red-650 text-white text-xs h-8 px-4 rounded-lg">
                Society Vendors
              </button>
            </Link>
            <Link to={RESIDENT_VENDORS_ENDPOINT} className="text-white">
              <button className="bg-red-650 text-white text-xs h-8 px-4 rounded-lg">
                Resident Vendors
              </button>
            </Link>
            <div className="border-r border-[#CCC]"></div>
            <Link to={ADD_CONTACT_ENDPOINT} className="text-white">
              <button className="bg-red-650 text-white text-xs h-8 px-4 rounded-lg">
                Add Contact
              </button>
            </Link>
            <Link to={MANAGE_CATEGORY_ENDPOINT} className="text-white">
              <button className="bg-red-650 text-white text-xs h-8 px-4 rounded-lg">
                Manage Category
              </button>
            </Link>
          </div>
        </ListHeader>
        <div className="p-2 flex flex-row justify-between gap-3 border-t border-[#CCC]">
        <Search height="8" onclick={handleSearch} value={searchParams.get("search")} />
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <p>Filter</p>
              <Dropdown2
                options={categoryList?.map((item)=>({
                  value: item?.cat_id,
                  label: item?.category
                })) || []}
                placeholder="Select Category"
                className="text-[11px] ms-4"
                width="180px"
                height="32px"
                onSelect={(selectedItem) => {
                  handelSelectCategory(selectedItem)
                }}
                value={
                  searchParams.get("catId") && {
                    value: searchParams.get("catId"),
                    label: categoryList?.find((item) => item.cat_id?.toString() === searchParams.get("catId"))?.category
                  }
                }
              />
              <ButtonG label='Clear' className='h-8' onClick={handleClear} />

            </div>
            <div className="border-r border-[#CCC] mx-4 h-full"></div>
            <div className="flex gap-2">
              <button
                onClick={handlePrint}
                className="flex text-red-650 font-semibold border border-red-650 bg-white text-xs h-8 px-3 items-center rounded-lg"
              >
                <FontAwesomeIcon icon={faPrint} className="mr-2" /> Print
              </button>
              <button className="flex text-red-650 font-semibold border border-red-650 -mr-2 bg-white text-xs h-8 px-3 items-center rounded-lg">
                <FontAwesomeIcon icon={faDownload} className="mr-2" /> Download
                Excel
              </button>
            </div>
          </div>
        </div>
          <ContactTable
            loading={loading}
            data={importantContactList?.list?.map((item, index)=>({
              sNo: (((importantContactList?.current_page_no - 1) * importantContactList?.page_limit + 1)+index)?.toString()?.padStart(2, "0"),
              contactId: item?.imp_contact_id || '-',
              category: item?.category || '-',
              name: item.name || '-',
              address: item?.address || '-',
              concernedPerson: item?.concerned_person || '-',
              contact: item?.contact || '-',
              email: item?.email1,
              status:  item?.status === 'A' ?  'Active': (item?.status === 'I' ? 'Inactive': 'Unapprove'),
            }))}
            columns={headingsContacts}
            checkbox={false}
            pagination={importantContactList?.list?.length}
            totalCount={importantContactList?.total_record}
            totalPages={importantContactList?.total_pages}
            start={(importantContactList?.current_page_no - 1) * importantContactList?.page_limit + 1}
            end={(importantContactList?.current_page_no - 1) * importantContactList?.page_limit + importantContactList?.list?.length}
            currentPage={importantContactList?.current_page_no}
            rowsPerPage={searchParams.get("pageLimit") || 10}
            getTableData={getTableData}

          ></ContactTable>

          {!importantContactList?.list?.length && !loading && (
            <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
              No Records
            </div>
          )}
      </div>
    </Layout>
  );
};

export default ContactsList;

