
import * as Yup from "yup";

export const AddTariffHeaderValidation = () => {
    return Yup.object({
      orderNo: Yup.string().required("*Required"),
      cause: Yup.string().required("*Required"),
      glMappingId: Yup.string().required("*Required"),
      amount: Yup.string().required("*Required"),
      hsnCode: Yup.string(),
      type:  Yup.string(),
      gst:  Yup.string(),
      service:  Yup.string(),
    });
    };
    

export const InvoiceDetailsValidation = () => {
    return Yup.object({
        duration: Yup.string().required("*Required"),
    });
};
        
export const DueReminderValidation = () => {
    return Yup.object({
        noOfDaysBeforeDue: Yup.array().of(
            Yup.object().shape({
                noOfDay: Yup.string().trim().required("*Required")
            })
        ),
        noOfDaysAfterDue: Yup.array().of(
            Yup.object().shape({
                noOfDay: Yup.string().trim().required("*Required")
            })
        ),
    });
};
        
export const NomineeDueReminderValidation = () => {
    return Yup.object({
        schedule: Yup.string().required("*Required"),
        date: Yup.string().required("*Required"),
        day: Yup.string().required("*Required"),
    });
};

export const MemberDueReminderValidation = () => {
    return Yup.object({
        noOfDaysBeforeDue: Yup.string(),
        noOfDaysAfterDue: Yup.string(),
        employeeRoleId: Yup.string().required("*Required"),
        employeeId: Yup.array().of(
            Yup.string()
        ).min(1, "*Required"),
        managingCommitteId: Yup.string().required("*Required"),
        managingCommitteMemberId: Yup.array().of(
            Yup.string()
        ).min(1, "*Required"),
    });
};
        
export const DepositDueReminderValidation = () => {
    return Yup.object({
        noOfDaysBeforeDue: Yup.string().trim(),
        noOfDaysAfterDue: Yup.string().trim(),
    });
};
        
export const FDMaturityDueReminderValidation = () => {
    return Yup.object({
        noOfDaysBeforeDue: Yup.string().trim(),
        employeeTypeId: Yup.string().required("*Required"),
        employeeIds: Yup.array().of(
            Yup.string()
        ).min(1, "*Required"),
        memberTypeId: Yup.string().required("*Required"),
        memberIds: Yup.array().of(
            Yup.string()
        ).min(1, "*Required"),
        managingCommitteeId: Yup.string().required("*Required"),
        managingCommitteeMemberIds: Yup.array().of(
            Yup.string()
        ).min(1, "*Required"),
    });
};