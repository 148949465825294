import React from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import { TextAreaFM } from "../../components/InputFields/TextArea";
import Button from "../../components/Button/Button";
import { Form, Formik } from "formik";
import { CircularProgress } from "@mui/material";
import { SendMessageValidation } from "../../validationSchema/ClubSchema/BookAFacilitySchema";
import { TextInputFM } from "../InputFields/TextInput";
import { useSelector } from "react-redux";

const SendMessageModal = ({ isOpen, onClose, onConfirm, loading}) => {
    const {loginData} = useSelector(state=>state.loginReducer)

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-60 z-20 ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="bg-white rounded-lg w-[600px] px-2 min-h-[300px] ">
        <div className="flex justify-between p-2 gap-8 items-center -mb-2">
          <h2 className="p-2 text-lg font-semibold mb-2 items-center text-center w-[180%] flex justify-end">
            Send message to the administrator
          </h2>
          <div className="w-1/2 flex justify-end">
            <button
              onClick={onClose}
              className="bg-[#EAEAEA] rounded-full p-2 items-center mb-2 w-9 h-9 "
            >
              <CloseIcon className="fill-current text-gray-600 hover:text-gray-800 ml-1 w-3 h-3 cursor-pointer" />
            </button>
          </div>
        </div>
        <div className="border-b border-[#CCCCCC]"></div>
        <Formik
        initialValues={{
            subject: '',
          message: ''
        }}
          validationSchema={SendMessageValidation}
          onSubmit={(values, {resetForm})=>{onConfirm(values)}}
        >
          {
            (values)=>(
              <Form>
                <div className="mt-2 px-2">
                    <div className="pt-2 flex text-sm">
                        <div className="pt-2 text=[#AAAAAA] w-40 text-sm">To</div>
                        Administrator of {loginData?.default_community?.community_name}
                    </div>
                    <div className="pt-2 flex">
                        <div className="pt-2 text=[#AAAAAA] w-40 text-sm">Subject</div>
                        <TextInputFM
                            className='w-[420px]'
                            placeholder='Enter'
                            label=''
                            value={values.noOfBooking}
                            name={'subject'}
                        />
                    </div>
                    <div className=" flex">
                        <div className="pt-2 text=[#AAAAAA] w-52 text-sm">Message</div>
                        <TextAreaFM name='message' maxLength={300} label="" placeholder="Enter Reason" row="5" className="w-[420px]" />
                    </div>
                </div>
                <div className="border-b border-[#CCC]"></div>
                <div className="flex justify-center my-4">
                    <Button label={
                      <span className="w-24 inline-block">
                      {
                      loading ?
                        <CircularProgress sx={{color: "white"}} size={17}/>
                        :
                        <>Send Message</>
                      }
                    </span>
                  } 
                  type='submit' />
                </div>
              </Form>
            )
          }
        </Formik>
      </div>
    </div>
  );
};

export default SendMessageModal;
