import React, { useState } from "react";
import { TextInputFM } from "../../../components/InputFields/TextInput";
import { DropdownFM2 } from "../../../components/Dropdown/dropdown2";
import { useSelector } from "react-redux";
import { ErrorMessage, FieldArray } from "formik";
import {
  TimeSlotFrequencyOptions,
  TimeSlotSelectedDaysOptions,
} from "../../../consts/DropDownOptions";
import { DeleteIcon } from "../../../assets";
import DeleteModal from "../../../components/Modal/DeleteModal";
import { deleteTimeSlot } from "../../../redux/actions/ClubActions/BookAFacilityAction";
import { useDispatch } from "react-redux";
import { TimeSelectorFM2 } from "../../../components/TimeSelector/TimeSelector";
import { getDateObjectFromTimeString } from "../../../utils/helpers/universalFunctions";
import moment from "moment";
import { getPermission } from "../../../utils/helpers/getPermissions";
import * as permissions from "../../../Routing/permissions";

export const headCells = [
  {
    id: "type",
    label: "Type",
    width: "100px",
  },
  { id: "ticket", label: "Ticket/Passes", width: "100px" },
  {
    id: "startTime",
    label: "Start-Time",
    width: "90px",
  },
  {
    id: "endTime",
    label: "End-Time",
    width: "80px",
  },
  {
    id: "frequency",
    label: "Frequency",
    width: "150px",
  },
  {
    id: "days",
    label: "",
    width: "250px",
  },
];

function EditTimeSlotTable({ values, setFieldValue }) {
  const dispatch = useDispatch();
  const { typeList } = useSelector((state) => state.addTimeSlotReducer);
  const { updateLoader } = useSelector((state) => state.bookAFacilityReducer);
  const [deleteModal, setDeleteModal] = useState({ id: "", open: false });
  const { loginData } = useSelector((state) => state.loginReducer);

  const handleDelete = (id, arrayHelpers, index) => {
    if (id) {
      dispatch(
        deleteTimeSlot({
          ...values,
          timeSlotId: id,
          onSuccessCallback: () => {
            arrayHelpers.remove(index);
          },
        })
      );
    } else {
      arrayHelpers.remove(index);
    }
  };

  return (
    <table className="w-full">
      <thead className="font-semibold text-sm text-[#AAA]">
        <tr>
          {headCells?.map((item, index) => (
            <td className="p-1 w-28 py-2 mb-2 border-b border-[#DDD]  ">
              {item.label}
            </td>
          ))}
          <td className="p-1  pb-2 mb-2 border-b border-[#DDD]  "></td>
        </tr>
      </thead>
      <tbody>
        <tr className="h-2"></tr>
        <FieldArray
          name="timeSlot"
          render={(arrayHelpers) => (
            <>
              {values.timeSlot &&
                values.timeSlot.length > 0 &&
                values.timeSlot.map((slot, index) => (
                  <tr className="align-top">
                    <td className="px-1">
                      <DropdownFM2
                        options={
                          typeList?.map((item) => ({
                            label: item.title,
                            value: item.id,
                          })) || []
                        }
                        placeholder="Select Type"
                        className="text-[11px] ms-4"
                        width="120px"
                        height="36px"
                        onSelect={(selectedItem) => {
                          setFieldValue(
                            `timeSlot.${index}.type`,
                            selectedItem.label
                          );
                        }}
                        value={
                          values.timeSlot[index]?.type
                            ? {
                                label: values.timeSlot[index]?.type,
                                value: typeList?.find(
                                  (item) =>
                                    item.title === values.timeSlot[index]?.type
                                )?.id,
                              }
                            : null
                        }
                        name={`timeSlot.${index}.type`}
                      />
                    </td>
                    <td className="px-1">
                      <TextInputFM
                        label=""
                        placeholder="Enter"
                        className="w-28 h-9"
                        type="number"
                        name={`timeSlot.${index}.ticket`}
                      />
                    </td>
                    <td className="px-1">
                      <TimeSelectorFM2
                        width={"110px"}
                        height={"37px"}
                        setTime={(value) => {
                          setFieldValue(`timeSlot.${index}.endTime`, "");
                          setFieldValue(
                            `timeSlot.${index}.startTime`,
                            moment(new Date(value)).format("HH:mm")
                          );
                        }}
                        name={`timeSlot.${index}.startTime`}
                        minutesStep={15}
                        time={getDateObjectFromTimeString(
                          values.timeSlot[index].startTime
                        )}
                      />
                    </td>
                    <td className="px-1">
                      <TimeSelectorFM2
                        width={"110px"}
                        height={"37px"}
                        setTime={(value) => {
                          // if (
                          //   values.timeSlot[index].startTime?.split(
                          //     ":"
                          //   )?.[1] === "00"
                          // )
                          //   setFieldValue(
                          //     `timeSlot.${index}.endTime`,
                          //     moment(new Date(value)).format("HH:15")
                          //   );
                          // else
                          setFieldValue(
                            `timeSlot.${index}.endTime`,
                            moment(new Date(value)).format("HH:mm")
                          );
                        }}
                        name={`timeSlot.${index}.endTime`}
                        minutesStep={15}
                        time={getDateObjectFromTimeString(
                          values.timeSlot[index].endTime
                        )}
                        maxTime={
                          values.timeSlot[index].startTime !== "00:00"
                            ? getDateObjectFromTimeString("23:59")
                            : ""
                        }
                        minTime={
                          values.timeSlot[index].startTime === "00:00"
                            ? getDateObjectFromTimeString("00:15")
                            : getDateObjectFromTimeString(
                                `${
                                  parseInt(
                                    values.timeSlot[index].startTime?.split(
                                      ":"
                                    )?.[1]
                                  ) >= 45
                                    ? `${
                                        parseInt(
                                          values.timeSlot[
                                            index
                                          ].startTime?.split(":")?.[0]
                                        ) + 1
                                      }:00`
                                    : `${parseInt(
                                        values.timeSlot[index].startTime?.split(
                                          ":"
                                        )?.[0]
                                      )}:${
                                        parseInt(
                                          values.timeSlot[
                                            index
                                          ].startTime?.split(":")?.[1]
                                        ) + 15
                                      }`
                                }`
                              )
                        }
                        isDisabled={!values.timeSlot[index].startTime}
                      />
                    </td>
                    <td className="px-1">
                      <DropdownFM2
                        options={TimeSlotFrequencyOptions}
                        placeholder="Select Type"
                        className="text-[11px] ms-4"
                        width="120px"
                        height="36px"
                        onSelect={(selectedItem) => {
                          setFieldValue(
                            `timeSlot.${index}.frequency`,
                            selectedItem.value
                          );
                        }}
                        value={
                          values.timeSlot[index]?.frequency
                            ? {
                                label: TimeSlotFrequencyOptions?.find(
                                  (item) =>
                                    item.value ===
                                    values.timeSlot[index]?.frequency
                                )?.label,
                                value: values.timeSlot[index]?.frequency,
                              }
                            : null
                        }
                        name={`timeSlot.${index}.frequency`}
                      />
                    </td>
                    <td className="px-1 w-[260px] text-sm">
                      <div className="flex items-center h-9">
                        {values.timeSlot[index].frequency === "Selected" &&
                          TimeSlotSelectedDaysOptions.map((day) => (
                            <button
                              type="button"
                              key={day.value}
                              className={`px-1 py-1 rounded-md ${
                                values.timeSlot[index].selectedDays.includes(
                                  day.value
                                )
                                  ? "bg-red-650 text-white"
                                  : "bg-gray-200 text-gray-700"
                              } mx-1`}
                              onClick={() => {
                                if (
                                  values.timeSlot[index].selectedDays?.includes(
                                    day.value
                                  )
                                )
                                  setFieldValue(
                                    `timeSlot.${index}.selectedDays`,
                                    values.timeSlot[index].selectedDays?.filter(
                                      (item) => item != day.value
                                    )
                                  );
                                else
                                  setFieldValue(
                                    `timeSlot.${index}.selectedDays`,
                                    [
                                      ...values.timeSlot[index].selectedDays,
                                      day.value,
                                    ]
                                  );
                              }}
                            >
                              {day.label}
                            </button>
                          ))}
                      </div>
                      <div
                        className={`text-red-500 text-xs italic h-4 text-wrap break-words`}
                      >
                        <ErrorMessage name={`timeSlot.${index}.selectedDays`} />
                      </div>
                    </td>
                    <td>
                      {getPermission(
                        loginData,
                        permissions.DELETE_TIME_SLOT_PERMISSION
                      ) && (
                        <DeleteIcon
                          onClick={
                            slot.id
                              ? () =>
                                  setDeleteModal({ id: slot.id, open: true })
                              : () => {
                                  handleDelete(slot.id, arrayHelpers, index);
                                }
                          }
                          className="fill-red-650 mt-3 cursor-pointer"
                        />
                      )}
                      <DeleteModal
                        title="Delete Time Slot"
                        loading={updateLoader}
                        isOpen={deleteModal.open && slot.id === deleteModal.id}
                        onCancel={() => setDeleteModal(false)}
                        onDelete={() => {
                          handleDelete(slot.id, arrayHelpers, index);
                        }}
                        text="Do you really want to delete this time slot?"
                      />
                    </td>
                  </tr>
                ))}
              <tr>
                <td colSpan={7}>
                  <div className="h-8 my-2 flex justify-center">
                    <button
                      type="button"
                      label="Add More"
                      onClick={() =>
                        arrayHelpers.push({
                          type: "",
                          ticket: "",
                          startTime: "",
                          endTime: "",
                          frequency: "",
                          selectedDays: [],
                        })
                      }
                      className="text-red-650 font-semibold border-2 border-dashed border-red-650 p-2 bg-[#FDF6F6] flex justify-center rounded-md items-center"
                    >
                      Add More
                    </button>
                  </div>
                </td>
              </tr>
            </>
          )}
        ></FieldArray>
      </tbody>
    </table>
  );
}

export default EditTimeSlotTable;
