import React, { useEffect } from "react";
import Layout from "../../../Layout/Layout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import InvoiceStructure from "./InvoiceStructure";
import PaymentOptions from "./PaymentOptions";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getBookingInvoice } from "../../../../redux/actions/ClubActions/ViewBookingsAction";

function MyInvoicePayment() {
const data = useLocation().state;
  const paymentLink = useLocation().state;
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, updateLoader, invoiceDetails } = useSelector(
    (state) => state.viewBookingReducer
  );

  const { loginData } = useSelector((state) => state.loginReducer);

  useEffect(() => {
    dispatch(
      getBookingInvoice({
        invoiceId: id,
        societyId: loginData?.default_community?.community_id,
      })
    );
  }, [dispatch, id, loginData?.default_community?.community_id]);
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack
            title="Make Payment"
            onClick={() => {
              navigate(-1);
            }}
          ></ListHeaderBack>
        </div>
        <div className="gap-2 py-1 my-1">
          <InvoiceStructure
            invoiceDetails={invoiceDetails}
            loginData={loginData}
            loading={loading}
          />
          <div>
            <PaymentOptions
              invid={id}
              invoiceDetails={invoiceDetails}
              loginData={loginData}
              loading={loading}
              updateLoader={updateLoader}
              paymentLink={paymentLink}
              data={data}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default MyInvoicePayment;
