export const getPermission = (loginData, keys) => {

    if(loginData?.user_type === 'Society')
      return true;
  
    let permissionsData = loginData?.permissions
    let result = false;
    for(let i=0; i<keys.length; i++){
      let key = keys[i]
      const keysArray = key?.split('.');
      
      result = keysArray?.reduce((accumulator, currentKey) => {
        return accumulator ? accumulator[currentKey] : undefined;
      }, permissionsData);
      
      if((result===1) || (Array.isArray(result)))
        break;
    }
    return ((result===1) || (Array.isArray(result)));
}

  