import * as types from '../index.types';

export function getBillTypeList(data) {
    return {
        type: types.GET_BILL_TYPE_LIST,
        data
    };
}

export function getFinanceMembersList(data) {
    return {
        type: types.GET_FINANCE_MEMBER_LIST,
        data
    };
}

export function getBillGenerationViewReports(data) {
    return {
        type: types.GET_BILL_GENERATION_VIEW_REPORTS,
        data
    };
}

export function getDownloadBillGenerationViewReports(data) {
    return {
        type: types.DOWNLOAD_BILL_GENERATION_VIEW_REPORTS,
        data
    };
}

export function getMembersBillReports(data) {
    return {
        type: types.GET_MEMBERS_BILL_REPORTS,
        data
    };
}

export function getSocietyBillReports(data) {
    return {
        type: types.GET_SOCIETY_BILL_REPORTS,
        data
    };
}

export function getInterestStatementReports(data) {
    return {
        type: types.GET_INTEREST_STATEMENT_REPORTS,
        data
    };
}

export function getEInvocieReports(data) {
    return {
        type: types.GET_EINVOICE_REPORTS,
        data
    };
}

// finance reports

export function getOutstandingReports(data) {
    return {
        type: types.GET_OUTSTANDING_REPORTS,
        data
    };
}

export function downloadOutstandingReports(data) {
    return {
        type: types.DOWNLOAD_OUTSTANDING_REPORTS,
        data
    };
}

// account reports 

export function getGroupTrailBalance(data) {
    return {
        type: types.GET_GROUP_TRAIL_BALANCE,
        data
    };
}

export function downloadCSVGroupTrailBalance(data) {
    return {
        type: types.DOWNLOAD_CSV_GROUP_TRAIL_BALANCE,
        data
    };
}

export function downloadPDFGroupTrailBalance(data) {
    return {
        type: types.DOWNLOAD_PDF_GROUP_TRAIL_BALANCE,
        data
    };
}

export function getOpeningBalanceReports(data) {
    return {
        type: types.GET_OPENING_BALANCE_REPORTS,
        data
    };
}

export function downloadCSVOpeningBalanceReports(data) {
    return {
        type: types.DOWNLOAD_CSV_OPENING_BALANCE_REPORTS,
        data
    };
}

export function downloadPDFOpeningBalanceReports(data) {
    return {
        type: types.DOWNLOAD_PDF_OPENING_BALANCE_REPORTS,
        data
    };
}

export function getReceiptsPayments(data) {
    return {
        type: types.GET_RECEIPTS_PAYMENTS,
        data
    };
}

export function downloadCSVReceiptsPayments(data) {
    return {
        type: types.DOWNLOAD_CSV_RECEIPTS_PAYMENTS,
        data
    };
}

export function downloadPDFReceiptsPayments(data) {
    return {
        type: types.DOWNLOAD_PDF_RECEIPTS_PAYMENTS,
        data
    };
}

export function getCashBankBook(data) {
    return {
        type: types.GET_CASH_BANK_BOOK,
        data
    };
}

export function downloadCSVCashBankBook(data) {
    return {
        type: types.DOWNLOAD_CSV_CASH_BANK_BOOK,
        data
    };
}

export function downloadPDFCashBankBook(data) {
    return {
        type: types.DOWNLOAD_PDF_CASH_BANK_BOOK,
        data
    };
}

export function getJournal(data) {
    return {
        type: types.GET_JOURNALS,
        data
    };
}

export function downloadCSVJournals(data) {
    return {
        type: types.DOWNLOAD_CSV_JOURNALS,
        data
    };
}

export function downloadPDFJournals(data) {
    return {
        type: types.DOWNLOAD_PDF_JOURNALS,
        data
    };
}

export function getLedgers(data) {
    return {
        type: types.GET_LEDGERS,
        data
    };
}

export function downloadCSVLedgers(data) {
    return {
        type: types.DOWNLOAD_CSV_LEDGERS,
        data
    };
}

export function downloadPDFLedgers(data) {
    return {
        type: types.DOWNLOAD_PDF_LEDGERS,
        data
    };
}

// export function getAllMemberLedgers(data) {
//     return {
//         type: types.GET_ALL_MEMBER_LEDGERS,
//         data
//     };
// }

export function downloadCSVAllMemberReport(data) {
    return {
        type: types.DOWNLOAD_CSV_ALL_MEMBERS_REPORT,
        data
    };
}

export function chartOfAccountBookData(data) {
    return {
        type: types.GET_CHART_OF_ACCOUNT_BOOK_DATA,
        data
    };
}
