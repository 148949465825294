import * as types from '../../index.types';

export function getLeaveAndLicenseList(data) {
    return {
        type: types.GET_LEAVE_AND_LICENSE_LIST,
        data
    };
}

export function downloadLeaveAndLicenseList(data) {
    return {
        type: types.DOWNLOAD_LEAVE_AND_LICENSE,
        data
    };
}