import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import "./Table.css";
import {
  DeleteIcon,
  EditIcon,
  PrintIdCardIcon,
  SendSMSIcon,
  SortIcon,
  ViewIcon,
} from "../../assets";
import styled from "@emotion/styled";
import ItemsPerPage from "../ItemsPerPage/ItemsPerPage";
import Pagination from "../Pagination/Pagination";
import ImageComponent from "../ImageComponent/ImageComponent";
import {
  stableSort,
  getComparator,
} from "../../utils/helpers/universalFunctions";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { CircularProgress, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import {
  EDIT_SOCIETY_STAFF_ENDPOINT,
  SOCIETY_STAFF_ID_CARD_ENDPOINT,
  VIEW_SOCIETY_STAFF_DETAILS_ENDPOINT,
} from "../../Routing/routes";
import RatingComp from "../Rating/Rating";
import Dropdown2 from "../Dropdown/dropdown2";
import Button from "../Button/Button";
import DeleteModal from "../Modal/DeleteModal";
import {
  deleteSocietyStaff,
  sendStaffCodeSmsToStaff,
} from "../../redux/actions/SetupRecordsActions/StaffManagementActions/StaffPersonnelAction";
import SendSmsModal from "../Modal/SendSmsModal";
import * as permissions from "../../Routing/permissions";
import { getPermission } from "../../utils/helpers/getPermissions";
import ApprovalStatus from "../../pages/SetupRecords/StaffManagement/StaffPersonnel/ApprovalStatus";

function ActionComponent({ row, editPath }) {
  const { staffId, staffLevelId, memberName, staffCode, mobileNo } = row;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSendSMSModalModal, setShowSendSMSModalModal] = useState(false);
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);

  const handleSendSMSModalClick = () => {
    setShowSendSMSModalModal(true);
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleDelete = () => {
    let data = {
      staffId,
      staffLevelId,
      societyId: loginData?.default_community?.community_id,
      page: searchParams.get("page") || 1,
      searchText: searchParams.get("search") || "",
      pageLimit: searchParams.get("pageLimit") || 10,
      wingId: searchParams.get("wingId") || "",
      flatId: searchParams.get("flatId") || "",
      status: searchParams.get("status") || "",
      societyType: searchParams.get("societyType") || "",
      societyProfile: searchParams.get("societyProfile") || "",
      onSuccessCallback: () => {
        setShowDeleteModal(false);
      },
    };
    dispatch(deleteSocietyStaff(data));
  };

  const handleSendSMS = () => {
    const data = {
      staffData: [
        {
          memberName,
          staffCode,
          staffId,
          mobileNo: mobileNo?.includes("+")
            ? mobileNo?.replace("+", "")
            : `91-${mobileNo}`,
        },
      ],
    };
    dispatch(sendStaffCodeSmsToStaff(data));
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {getPermission(loginData, [permissions.VIEW_STAFF_PERMISSION]) && (
        <Tooltip title="View" arrow>
          <ViewIcon
            className="fill-[#555] hover:fill-red-650 me-1.5"
            onClick={() => {
              navigate(`${VIEW_SOCIETY_STAFF_DETAILS_ENDPOINT}/${staffId}`);
            }}
          />
        </Tooltip>
      )}
      {getPermission(loginData, [permissions.EDIT_STAFF_PERMISSION]) && (
        <Tooltip title="Edit" arrow>
          <EditIcon
            className="fill-[#555] hover:fill-red-650 me-1.5"
            onClick={() => {
              navigate(`${editPath}/${staffId}`);
            }}
          />
        </Tooltip>
      )}
      {getPermission(loginData, [
        permissions.SEND_STAFF_SMS_CODE_PERMISSION,
      ]) && (
        <Tooltip title="Send SMS" arrow>
          <SendSMSIcon
            className="fill-[#555] hover:fill-red-650 me-1.5"
            onClick={() => handleSendSMSModalClick()}
          />
        </Tooltip>
      )}
      {getPermission(loginData, [permissions.PRINT_STAFF_ID_CARD_PERMISSION]) &&
        row?.approval_status == "Approval" && (
          <Tooltip title="Id Card" arrow>
            <PrintIdCardIcon
              className="fill-[#555] hover:fill-red-650 me-1.5"
              onClick={() => {
                window.open(
                  `${SOCIETY_STAFF_ID_CARD_ENDPOINT}/${loginData?.default_community?.community_id}?society_type=${loginData?.default_community?.society_type}&staff_id=${staffId}`
                );
              }}
            />
          </Tooltip>
        )}

      {getPermission(loginData, [permissions.DELETE_STAFF_PERMISSION]) && (
        <Tooltip title="Delete" arrow>
          <DeleteIcon
            className="fill-[#555] hover:fill-red-650 me-1.5"
            onClick={() => handleDeleteClick()}
          />
        </Tooltip>
      )}
      <DeleteModal
        isOpen={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onDelete={handleDelete}
      />
      <SendSmsModal
        isOpen={showSendSMSModalModal}
        onCancel={() => setShowSendSMSModalModal(false)}
        onConfirm={handleSendSMS}
      />
    </Box>
  );
}

const TableRowStyled = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #ffffff;
  }
  &:nth-of-type(even) {
    background-color: #f4f5f8;
  }
  &:nth-of-type(odd):hover {
    background-color: #ffffff;
  }
  &:nth-of-type(even):hover {
    background-color: #f4f5f8;
  }
`;

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    zIndex: 0,
    color: "#AAAAAA",
    fontSize: "13px",
    padding: "12px 8px 12px 0",
    wordBreak: "break-word",
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
    fontSize: "13px",
    padding: "12px 8px 12px 0",
    wordBreak: "break-word",
    cursor: "pointer",
  },
}));

const SocietyStaffListTable = ({
  loading,
  data,
  columns,
  checkbox = true,
  pagination = true,
  navigationPath,
  editPath,
  //props are required if pagination is true
  totalCount,
  totalPages,
  start,
  end,
  currentPage,
  rowsPerPage,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const { loginData } = useSelector((state) => state.loginReducer);

  const dispatch = useDispatch();
  const updateLoader = useSelector(
    (state) => state.staffPersonnelReducer.updateLoader
  );
  const [selected, setSelected] = useState([]);
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
  const [action, setAction] = useState("");

  const bulkActionOptions = [
    {
      value: "Print Staff ID Card",
      label: "Print Staff ID Card",
      permission: [permissions.PRINT_STAFF_ID_CARD_PERMISSION],
    },
    {
      value: "Send staff code SMS",
      label: "Send staff code SMS",
      permission: [permissions.SEND_STAFF_SMS_CODE_PERMISSION],
    },
  ];

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    const newSelected = event.target.checked
      ? data.map((item, index) => index)
      : [];
    setSelected(newSelected);
  };

  const handleClick = (event, index) => {
    const newSelected = selected.includes(index)
      ? selected.filter((selectedIndex) => selectedIndex !== index)
      : [...selected, index];
    setSelected(newSelected);
  };

  const handleChangePage = (newPage) => {
    searchParams.delete("page");
    navigate({
      pathname: `${navigationPath}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["page", newPage],
      ])}`,
    });
  };

  const handleChangeRowsPerPage = (value) => {
    if (rowsPerPage !== value) {
      searchParams.delete("pageLimit");
      navigate({
        pathname: `${navigationPath}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["pageLimit", value],
        ])}`,
      });
    }
  };

  const handleApply = () => {
    if (action === "Print Staff ID Card") {
      window.open(
        `${SOCIETY_STAFF_ID_CARD_ENDPOINT}/${
          loginData?.default_community?.community_id
        }?society_type=${
          loginData?.default_community?.society_type
        }&staff_id=${selected?.map((i) => data[i].staffId)?.join(",")}`
      );
      setSelected([]);
    } else {
      const sendRequest = {
        staffData: selected?.map((i) => ({
          memberName: data[i]?.memberName,
          staffCode: data[i]?.staffCode,
          staffId: data[i]?.staffId,
          mobileNo: data[i]?.mobileNo?.includes("+")
            ? data[i]?.mobileNo?.replace("+", "")
            : `91-${data[i]?.mobileNo}`,
        })),
        onSuccessCallback: () => {
          setSelected([]);
        },
      };
      dispatch(sendStaffCodeSmsToStaff(sendRequest));
    }
  };

  const isSelected = (id) => selected.includes(id);

  useEffect(() => {
    setSelected([]);
  }, [searchParams]);

  return (
    <Box sx={{ width: "100%", fontSize: "13px" }} className="table-component">
      {!!selected?.length && (
        <div className="flex justify-end items-center border-t border-[#CCC] gap-2 p-2">
          <div className="flex items-center gap-2">
            <Dropdown2
              options={bulkActionOptions.filter((item) =>
                getPermission(loginData, item.permission)
              )}
              placeholder="Choose Action"
              className="text-[11px] ms-4"
              width="160px"
              height="32px"
              onSelect={(selectedItem) => {
                setAction(selectedItem.value);
              }}
            />
            <Button
              label={
                <span className="w-8 inline-block">
                  {updateLoader ? (
                    <CircularProgress sx={{ color: "white" }} size={17} />
                  ) : (
                    "Apply"
                  )}
                </span>
              }
              className="font-semibold px-4 h-8"
              type="button"
              onClick={handleApply}
            />
          </div>
        </div>
      )}
      <TableContainer
        className="border-t border-[#CCC]"
        sx={{ minHeight: data?.length ? "500px" : "none" }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {!!checkbox && (
                <TableCellStyled
                  size="small"
                  sx={{ pl: "0 !important", pr: 2, width: 36 }}
                >
                  <Checkbox
                    size="small"
                    indeterminate={false}
                    checked={
                      data?.length > 0 && selected?.length === data?.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all",
                    }}
                    sx={{
                      p: 0,
                      color: "#AAAAA",
                      "&.Mui-checked": {
                        color: "#CF001C",
                      },
                    }}
                    disabled={loading || !data}
                  />
                </TableCellStyled>
              )}
              {columns?.map((column) => (
                <TableCellStyled
                  size="small"
                  key={column.id + "heading"}
                  align={column.numeric ? "right" : "left"}
                  sortDirection={orderBy === column.id ? order : false}
                  sx={{
                    width: column.width || "fit-content",
                    minWidth: column.width || "fit-content",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={() => {
                      if (column.sort) {
                        handleRequestSort(column.id);
                      }
                    }}
                    IconComponent={SortIcon}
                    sx={{
                      ".MuiTableSortLabel-icon": {
                        opacity: "1 !important",
                      },
                      svg: {
                        flexShrink: 0,
                      },
                    }}
                    hideSortIcon={!column.sort}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCellStyled>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCellStyled
                  sx={{ border: "none" }}
                  colSpan={columns?.length + 1}
                >
                  <Loader />
                </TableCellStyled>
              </TableRow>
            ) : (
              <>
                {!!data?.length &&
                  stableSort(data, getComparator(order, orderBy))?.map(
                    (row, rowIndex) => {
                      const isRowSelected = isSelected(row?.id);

                      return (
                        <TableRowStyled
                          key={row?.staffId}
                          role="checkbox"
                          aria-checked={isRowSelected}
                          tabIndex={-1}
                          selected={isRowSelected}
                        >
                          {!!checkbox && (
                            <TableCellStyled
                              size="small"
                              sx={{ pl: "0 !important", zIndex: 0 }}
                              onClick={(event) => handleClick(event, rowIndex)}
                            >
                              <Checkbox
                                checked={selected?.includes(rowIndex)}
                                onChange={(e) => handleClick(e, rowIndex)}
                                size="small"
                                color="primary"
                                inputProps={{
                                  "aria-labelledby": `generic-table-checkbox-${row?.id}`,
                                }}
                                sx={{
                                  p: 0,
                                  color: "#AAAAA",
                                  "&.Mui-checked": {
                                    color: "#CF001C",
                                  },
                                }}
                              />
                            </TableCellStyled>
                          )}

                          {/* Serial Number */}
                          <TableCellStyled
                            size="small"
                            align="left"
                            onClick={(event) => handleClick(event, rowIndex)}
                          >
                            {row?.sNo}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            <ImageComponent
                              width={20}
                              height={20}
                              defaultUrl={row?.imageURL}
                            />
                          </TableCellStyled>

                          <TableCellStyled
                            size="small"
                            align="left"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {row.memberName}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            <div>
                              <b>Staff Code: </b> {row.staffCode}
                            </div>
                            <div>
                              <b>Profile: </b> {row.profile}
                            </div>
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row.mobileNo}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row?.flatNo}
                          </TableCellStyled>
                          <TableCellStyled size="small" align="left">
                            <div
                              onClick={() => {
                                setShowChangeStatusModal({
                                  row,
                                  open: true,
                                });
                              }}
                              className={`underline font-semibold ${
                                row.approval_status === "Approval"
                                  ? "text-green-600"
                                  : row.approval_status === "Reject" ||
                                    row.approval_status === "Pending"
                                  ? "text-red-500"
                                  : "text-yellow-500"
                              }`}
                            >
                              {row?.approval_status == "Approval"
                                ? "Approved"
                                : row?.approval_status == "Reject"
                                ? "Rejected"
                                : "Unapproved"}
                            </div>
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            <RatingComp row={row} />
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            <ActionComponent row={row} editPath={editPath} />
                          </TableCellStyled>
                        </TableRowStyled>
                      );
                    }
                  )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <ApprovalStatus
        isOpen={showChangeStatusModal?.open}
        onClose={() => setShowChangeStatusModal(false)}
        row={showChangeStatusModal?.row}
      />

      {!!pagination && data && (
        <div className="flex flex-row justify-between mt-3 pt-1 border-t border-[#ccc] text-[14px]">
          <ItemsPerPage
            rowsPerPage={rowsPerPage}
            onChange={handleChangeRowsPerPage}
          />
          <Pagination
            totalCount={totalCount}
            totalPages={totalPages}
            start={start}
            end={end}
            currentPage={currentPage}
            onChange={handleChangePage}
          />
        </div>
      )}
    </Box>
  );
};

SocietyStaffListTable.propTypes = {
  columns: PropTypes.array.isRequired,
  defaultSortField: PropTypes.string,
  rowsPerPageOptions: PropTypes.array,
};

export default SocietyStaffListTable;
