import { call, put, takeLatest } from 'redux-saga/effects'
import {notify} from '../../../../utils/notification'
import * as types from '../../../actions/index.types'
import * as req from '../../../../axios/request/SetupRecordsReqs/BillingAndInvoiceSetup/GSTAndNOCSettingReqs'

function* getGSTAndNOCSetting(action) {
    try {
        const result = yield call(req.getGSTAndNOCSettingReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_GST_NOC_SETTING_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_GST_NOC_SETTING_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateGSTAndNOCSetting(action) {
    try {
        const result = yield call(req.updateGSTAndNOCSettingReq, action);
        if (result.success === true) {
            notify('success', result.message)
            yield put({ type: types.UPDATE_GST_NOC_SETTING_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.UPDATE_GST_NOC_SETTING_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}


export default function* gstAndNOCSettingSaga() {
    yield takeLatest(types.GET_GST_NOC_SETTING, getGSTAndNOCSetting);
    yield takeLatest(types.UPDATE_GST_NOC_SETTING, updateGSTAndNOCSetting);
}