import React, { useEffect } from "react";
import { DropdownFM2 } from "../../../../../components/Dropdown/dropdown2";
import { NomineeDueReminderScheduleOptions, TimeSlotSelectedDaysOptions, dateOptions } from "../../../../../consts/DropDownOptions";
import { ErrorMessage, Form, Formik } from "formik";
import { NomineeDueReminderValidation } from "../../../../../validationSchema/financeSchema/settingSchema";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getNomineeDueReminderDetails, updateNomineeDueReminderDetails } from "../../../../../redux/actions/FinanceActions/SettingActions/DueRemindersAction";
import Loader from "../../../../../components/Loader/Loader";
import { CircularProgress } from "@mui/material";
import Button from "../../../../../components/Button/Button";

const NomineesUpdateReminder = () => {
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading, updateLoader, nomineeDueReminderData} = useSelector(state=>state.dueReminderReducer)

  const handleSubmit = (values, {resetForm})=>{
    dispatch(updateNomineeDueReminderDetails({...values, societyId: loginData?.default_community?.community_id}))
  }
  
  useEffect(()=>{
    dispatch(getNomineeDueReminderDetails({societyId: loginData?.default_community?.community_id}))
  }, [])

  return (
    <div>
        <Formik
          initialValues={{  
            schedule: nomineeDueReminderData?.schedule || '',
            date: nomineeDueReminderData?.schedule!=='Weekly' ? nomineeDueReminderData?.schedule_day : '01',
            day: nomineeDueReminderData?.schedule=='Weekly' ? nomineeDueReminderData?.schedule_day : 'Sun'
          }}
          enableReinitialize
          validationSchema={NomineeDueReminderValidation}
          onSubmit={handleSubmit}
        >
          {
            ({values, errors, setFieldValue})=>(
              <Form>
                <div className="flex py-1.5 justify-end border-b border-[#CCC]">
                  <Button
                    type='submit'
                    label={
                      <span className="w-16 inline-block">
                        {
                        updateLoader ?
                          <CircularProgress sx={{color: "white"}} size={17}/>
                          :
                          <>Save</>
                        }
                      </span>
                      }
                      disabled={loading || updateLoader}
                  />
                </div>
                <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg  mt-3">
                  <div className=" border-b border-[#CCC] pb-2 bg-[#FDF2F3]">
                    <h1 className="text-base items-center p-2 -mb-2 ml-1">Update Reminder</h1>
                  </div>
                  {
                    loading ?
                    <Loader/>
                    :
                    <div className=" p-2">
                      <div className="flex flex-col"> 
                          <div className="flex justify-between w-1/2 mt-4 z-10">
                            <span className="text-sm mt-2">Schedule</span>
                            <DropdownFM2
                              options={NomineeDueReminderScheduleOptions}
                              height="36px"
                              width="242px"
                              name='schedule'
                              onSelect={(selectedItem)=>{
                                setFieldValue('schedule', selectedItem.value)
                              }}
                              value={
                                values.schedule ? {
                                  label: values.schedule,
                                  value: values.schedule
                                }
                                :null
                              }
                            />
                          </div>
                          <div className="flex justify-between w-1/2">
                            <span className="text-sm mt-2">Day</span>
                            {
                              values.schedule === 'Weekly' ?
                              <div>
                                <div className='flex items-center w-[242px] text-[13px] h-9'>
                                  {
                                    TimeSlotSelectedDaysOptions.map((day) => (
                                      <button
                                      type='button'
                                        className={`px-1.5 py-1.5 rounded-md ${
                                          values.day===day.value
                                            ? "bg-red-650 text-white"
                                            : "bg-gray-200 text-gray-700"
                                        } mx-1`}
                                        onClick={() => {
                                            setFieldValue(`day`, day.value)
                                        }}
                                      >
                                        {day.label}
                                      </button>
                                    ))
                                  }
                                </div>
                                <div className={`text-red-500 text-xs italic h-[16px]`}>
                                  <ErrorMessage name={'day'}/>
                                </div>
                              </div>
                            :
                              <DropdownFM2
                                options={dateOptions}
                                height="36px"
                                width="242px"
                                name='date'
                                onSelect={(selectedItem)=>{
                                  setFieldValue('date', selectedItem.value)
                                }}
                                value={
                                  values.date ? {
                                    label: values.date,
                                    value: values.date
                                  }
                                  :null
                                }
                              />
                            }
                          </div>
                          
                      </div>
                    </div>
                  }
                </div>
              </Form>
            )
          }
        </Formik>
    </div>
  );
};

export default NomineesUpdateReminder;
