import React, { useEffect,useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import ListHeader from "../ListHeader/ListHeader";
import Button from "../Button/Button";
import ButtonG from "../Button/ButtonG";
import Dropdown2 from "../Dropdown/dropdown2";
import Box from "@mui/material/Box";
import Modal from "react-modal";
import { getBankDetails} from "../../redux/actions/SetupRecordsActions/BuildingActions/BankDetailsAction";
import {bulkVerifyPostPayment} from "../../redux/actions/FinanceActions/BillingManagementActions/ManageInvoiceActions";
import { notify } from "../../utils/notification";
const customStyles = {
    content: {
      width: "500px",
      height: "200px",
      minHeight: "fit-content",
      background: "#fff",
      border: "1px solid #ccc",
      borderRadius: "8px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    },
  };
const BankSelectionModel = ({isOpen,onClose,receiptId}) => {
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer)
  const [bankId, setBankId] = useState("")
  const { loading, updateLoader, bankDetailsData } = useSelector(state => state.bankDetailsReducer)
  const onSubmit = () => {
    if(bankId){
      // console.log(bankId);
      // console.log(receiptId);
      // console.log(loginData?.default_community?.community_id);
      dispatch(bulkVerifyPostPayment({
        bank_id: bankId,
        receipt_id: receiptId,
        society_id: loginData?.default_community?.community_id,
      }))
      handelClose()
    }else{
      notify("warning", "Please select a Bank");
    }
  }
  const handelClose = () => {
    setBankId("")
    onClose()
  }
  useEffect(() => {
    dispatch(getBankDetails())
  }, [])
    return (
        <Modal isOpen={isOpen} onRequestClose={handelClose} style={customStyles}>
            <div className="bg-white rounded-lg">
              <ListHeader leftContent="Select Bank">
                <span className="border-r border-[#CCC]"></span>
                <ButtonG label="Cancel" type={"button"} onClick={handelClose} />
                <Button label="Submit" onClick={onSubmit} disabled={loading} />
              </ListHeader>
            </div>
            <Box sx={{ width: "100%", height: "67%", fontSize: "13px" }}>
              <div className="flex flex-wrap items-center justify-center gap-4 p-2 mt-3">
                  <Dropdown2
                    options={
                      bankDetailsData
                        ? bankDetailsData?.map((item) => ({
                            value: item.bank_name_id,
                            label: item.bank_name,
                          }))
                        : []
                    }
                    placeholder="Choose Action"
                    className="text-[11px] ms-4"
                    width="250px"
                    height="32px"
                    onSelect={(selectedItem) => {
                      setBankId(selectedItem.value)
                      //setAction(selectedItem.value);
                    }}
                  />
                </div>
              
            </Box>
        </Modal>
    )
}
export default BankSelectionModel;