import React from "react";
import ListHeader from "../../../components/ListHeader/ListHeader";
import Layout from "../../Layout/Layout";
import Dropdown2 from "../../../components/Dropdown/dropdown2";
import { jFormYearsOptions } from "../../../consts/DropDownOptions";
import { PRINT_J_FORM_ENDPOINT } from "../../../Routing/routes";

const JForm = () => {
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
        <ListHeader leftContent="Member List Format 'J'">
          <Dropdown2
            options={jFormYearsOptions}
            placeholder="Select Year"
            className=""
            width="208px"
            height="36px"
            onSelect={(selectedItem) => {
              window.open(`${PRINT_J_FORM_ENDPOINT}?from=${selectedItem.fromYear}&to=${selectedItem.toYear}`)
            }}
          />
        </ListHeader>
        </div>
      </div>
    </Layout>
  );
};

export default JForm;
