import React from "react";
import Modal from "react-modal";
import { TextInput } from "../InputFields/TextInput";
import {DatePickerComponent} from "../DatePicker/DatePicker";
import Button from "../Button/Button";
import ButtonG from "../Button/ButtonG";

const customStyles = {
  content: {
    width: "550px",
    height: "250px",
    background: "#fff",
    border: "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
};

const RevokeDetailsModal = ({ isOpen, onCancel, onConfirm }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onCancel} style={customStyles}>
      <div className="w-full">
        <div className="text-center border-b border-[#CCC] font-semibold py-1 flex items-center justify-center">
          <h2>Revoke Details</h2>
        </div>
        <div className="py-2">
          <TextInput
            label="Nomination Serial No."
            placeholder="Enter No."
            className=" w-52"
          />
          <div className="flex items-center justify-between w-full text-sm">
            <span>Date Of Any Subsequent Revocation Of Nomination</span>
            <DatePickerComponent isRange={false} className="w-[228px] h-9" />
          </div>
        </div>
        <div className="flex gap-2 items-center justify-center mt-7 border-t pt-2.5">
          <ButtonG onClick={onCancel} label="Cancel" className="h-9"></ButtonG>
          <Button onClick={onConfirm} label="Confirm" className="h-9"></Button>
        </div>
      </div>
    </Modal>
  );
};

export default RevokeDetailsModal;
