import React, { useEffect } from "react";
import Layout from "../../Layout/Layout";
import ListHeader from "../../../components/ListHeader/ListHeader";
import Search from "../../../components/SearchBox/Search";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getInvoiceStatusList,
  getMyInvoiceList,
} from "../../../redux/actions/FinanceActions/MyInvoiceActions";
import moment from "moment";
import RangeDatePicker from "../../../components/DatePicker/RangeDatePicker";
import MyInvoiceTable from "./MyInvoiceTable";
import Dropdown2 from "../../../components/Dropdown/dropdown2";
import ButtonG from "../../../components/Button/ButtonG";
import { MY_INVOICE_ENDPOINT } from "../../../Routing/routes";
import {
  getInvoiceTypeList,
} from "../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";

const headCells = [
  { id: "sNo", label: "S No.", sort: false },
  { id: "flatNo", label: "Flat No.", sort: true },
  { id: "invoiceNo", label: "Invoice No.", sort: true },
  { id: "invoiceDate", label: "Invoice Date", sort: true },
  { id: "amount", label: "Amount", sort: true },
  { id: "status", label: "Status", sort: true },
  {
    id: "action",
    label: "Action",
    sort: false,
    actionType: [
      "edit",
      "view",
      "audit logs",
      "view access",
      "access setting",
      "delete",
    ],
  },
];

const MyInvoice = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { myInvoiceList, invoiceStatusList, loading } = useSelector(
    (state) => state.myInvoiceReducer
  );
  const { typeLists } = useSelector(
    (state) => state.invoiceSettingReducer
  );
  const handleSearch = (text) => {
    if (text && text !== searchParams.get("search")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("search");
      navigate({
        pathname: `${MY_INVOICE_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["search", text],
        ])}`,
      });
    }
  };
  const handelSelectInvoiceType = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("typeid");
    navigate({
      pathname: `${MY_INVOICE_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["typeid", selectedItem.value],
      ])}`,
    });
  };
  const handelSelectStatus = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("status");
    navigate({
      pathname: `${MY_INVOICE_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["status", selectedItem.value],
      ])}`,
    });
  };

  const handleStartDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !==
      searchParams.get("startDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("startDate");
      navigate({
        pathname: `${MY_INVOICE_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["startDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleEndDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !== searchParams.get("endDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("endDate");
      navigate({
        pathname: `${MY_INVOICE_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["endDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleClear = () => {
    navigate(MY_INVOICE_ENDPOINT);
  };

  useEffect(() => {
    dispatch(
      getMyInvoiceList({
        societyId: loginData?.default_community?.community_id,
        page: searchParams.get("page") || 1,
        pageLimit: searchParams.get("pageLimit") || 10,
        invoiceno: searchParams.get("invoiceno") || "",
        typeid: searchParams.get("typeid") || "",
        status: searchParams.get("status") || "",
        from_date: searchParams.get("startDate") || "",
        to_date: searchParams.get("endDate") || "",
        searchBy: searchParams.get("search") || "",
      })
    );
   
  }, [searchParams]);
  useEffect(() => {
    dispatch(getInvoiceTypeList({
      societyId: loginData?.default_community?.community_id,
    }))
    dispatch(
      getInvoiceStatusList({
        act: "status",
      })
    );
  },[])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="My Invoices">
            <div className="flex items-center gap-3 p-2">
              <span className="text-xs">Invoice Status</span>
              <Dropdown2
                options={
                  invoiceStatusList?.invoice_status_list
                    ? Object.entries(
                        invoiceStatusList?.invoice_status_list
                      )?.map((item) => ({
                        label: item?.[1],
                        value: item?.[0],
                      }))
                    : []
                }
                placeholder="Invoice Status"
                className="text-[11px] ms-4"
                width="200px"
                height="32px"
                onSelect={(selectedItem) => {
                  handelSelectStatus(selectedItem);
                }}
                value={
                  searchParams.get("status") && {
                    value: searchParams.get("status"),
                    label: invoiceStatusList?.invoice_status_list
                      ? Object.entries(
                          invoiceStatusList?.invoice_status_list
                        )?.find(
                          (item) => item?.[0] === searchParams.get("status")
                        )?.[1]
                      : [],
                  }
                }
              />
            </div>
          </ListHeader>
        </div>

        <div className="flex justify-between p-2">
          <Search
            height="8"
            onclick={handleSearch}
            value={searchParams.get("search")}
            className="-ml-2"
            placeholder="Search By Invoice No."
          />

          <div className="flex gap-2">
          <Dropdown2
              options={
                typeLists
                  ? typeLists?.map(
                      (item) => ({
                        label: item?.invoice_title,
                        value: item?.id,
                      })
                    )
                  : []
              }
              placeholder="Invoice type"
              className="text-[11px] ms-4"
              width="172px"
              height="32px"
              onSelect={(selectedItem) => {
                handelSelectInvoiceType(selectedItem);
              }}
              value={
                searchParams.get("typeid") && {
                  value: searchParams.get("typeid"),
                  label: typeLists ? typeLists?.find((item) => item?.id === parseInt(searchParams.get("typeid")))?.invoice_title : "",
                }
              }
            />
            <RangeDatePicker
              className={"w-[200px] h-[32px]"}
              defaultStartValue={
                searchParams.get("startDate")
                  ? new Date(searchParams.get("startDate"))
                  : ""
              }
              defaultEndValue={
                searchParams.get("endDate")
                  ? new Date(searchParams.get("endDate"))
                  : ""
              }
              onStartDateChange={handleStartDate}
              onEndDateChange={handleEndDate}
            />
            <div className="border-r border-[#CCC]"></div>
            <ButtonG label="Clear" className="h-8" onClick={handleClear} />
          </div>
        </div>

        <div className="">
          <MyInvoiceTable
            loading={loading}
            data={myInvoiceList?.data?.map((item, index) => ({
              sNo:
                myInvoiceList?.paginate?.current *
                  myInvoiceList?.paginate?.limit +
                1 +
                index,
              id: item.id,
              wingno: item.wingno,
              flatNo: item.flat_no,
              invoiceno: item.invoice_no,
              invoiceDate: item.invoice_date
                ? moment(new Date(item?.invoice_date))?.format("DD-MM-YYYY")
                : "-",
              amount: item.grandtotal,
              amountPaid: item.amount_paid,
              amountDue: item.dueAmount,
              status: item.row_status,
              remarks: item.remarks,
              ismakePayment: item.ismakePayment,
              paymentLink: item.paymentLink,
            }))}
            columns={headCells}
            checkbox={false}
            pagination={myInvoiceList?.data?.length}
            totalCount={myInvoiceList?.paginate?.total_count}
            totalPages={myInvoiceList?.paginate?.total_page}
            start={
              myInvoiceList?.paginate?.current *
                myInvoiceList?.paginate?.limit +
              1
            }
            end={
              myInvoiceList?.paginate?.current *
                myInvoiceList?.paginate?.limit +
              myInvoiceList?.data?.length
            }
            currentPage={parseInt(myInvoiceList?.paginate?.current) + 1}
            rowsPerPage={searchParams.get("pageLimit") || 10}
          ></MyInvoiceTable>
        </div>

        {!myInvoiceList?.data?.length && !loading && (
          <div className="text-lg text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
    </Layout>
  );
};

export default MyInvoice;
