import { notify } from "../../../../utils/notification";
import apiEndpoints from "../../../api";
import axiosInstance from "../../../axiosInstance";
import { logOut } from "../../../../utils/helpers/logOut";
import { errorMsg } from "../../../../consts/responseMessages";

// bill generation
export async function getBillGenerationViewReportsReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.VIEW_FINANCE_BILL_API}?society_registration=${action.data?.society_registration}&page=${action.data?.page}&limit=${action.data?.limit}&bill_type=${action.data?.typeOfReport}&blob_bill_id=${action.data?.billType}&bill_month=${action.data?.month}&bill_year=${action.data?.year}&member_id=${action.data?.financeMemberList}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data?.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
      ) {
        logOut();
      } else if (errors.response?.data?.statusCode === 501) {
        notify("warning", errors.response.data?.message);
      }
      return errors;
    });
}

export async function downloadBillGenerationViewReportsReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.DOWNLOAD_FINANCE_BILL_API}?society_registration=${action.data?.society_registration}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data?.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
      ) {
        logOut();
      } else if (errors.response?.data?.statusCode === 501) {
        notify("warning", errors.response.data?.message);
      }
      return errors;
    });
}

export async function getFinanceMembersListReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.FINANCE_MEMBER_LIST_API}?society_registration=${action.data?.society_registration}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data?.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
      ) {
        logOut();
      } else if (errors.response?.data?.statusCode === 501) {
        notify("warning", errors.response.data?.message);
      }
      return errors;
    });
}

export async function getBillTypeListReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.BILL_TYPE_LIST_API}?society_id=${action.data?.society_id}&daybook_filter=${action.data?.daybook_filter}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data?.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
      ) {
        logOut();
      } else if (errors.response?.data?.statusCode === 501) {
        notify("warning", errors.response.data?.message);
      }
      return errors;
    });
}

// bill reports

// members bills
export async function getMembersBillReportsReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.MEMBER_BILL_REPORTS_API}?society_registration=${action.data?.society_registration}&page=${action.data?.page}&limit=${action.data?.limit}&blob_bill_id=${action.data?.billType}&bill_month=${action.data?.month}&bill_year=${action.data?.year}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data?.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
      ) {
        logOut();
      } else if (errors.response?.data?.statusCode === 501) {
        notify("warning", errors.response.data?.message);
      }
      return errors;
    });
}

// society bills
export async function getSocietyBillReportsReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.SOCIETY_BILL_REPORTS_API}?society_registration=${action.data?.society_registration}&page=${action.data?.page}&limit=${action.data?.limit}&start_year=${action.data?.start_year}&end_year=${action.data?.end_year}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data?.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
      ) {
        logOut();
      } else if (errors.response?.data?.statusCode === 501) {
        notify("warning", errors.response.data?.message);
      }
      return errors;
    });
}

// interest statement

export async function getInterestStatementReportsReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.INTEREST_STATEMENT_REPORTS_API}?society_id=${action.data?.society_id}&page=${action.data?.page}&limit=${action.data?.limit}&blob_bill_id=${action.data?.billType}&bill_month=${action.data?.month}&bill_year=${action.data?.year}&member_id=${action.data?.financeMemberList}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data?.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
      ) {
        logOut();
      } else if (errors.response?.data?.statusCode === 501) {
        notify("warning", errors.response.data?.message);
      }
      return errors;
    });
}

// e invoice reports

export async function geteInvoiceReportsReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.E_INVOICE_REPORTS_API}?society_id=${action.data?.society_id}&page=${action.data?.page}&limit=${action.data?.limit}&blob_bill_id=${action.data?.billType}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data?.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
      ) {
        logOut();
      } else if (errors.response?.data?.statusCode === 501) {
        notify("warning", errors.response.data?.message);
      }
      return errors;
    });
}

// Finance Reports

export async function getOutstandingReportsReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.OUTSTANDING_STATEMENT_API}?society_id=${action.data?.society_id}&page=${action.data?.page}&limit=${action.data?.limit}&fin_year=${action.data?.fin_year}&user_type=${action.data?.user_type}&day_book_id=${action.data?.day_book_id}&as_on_date=${action.data?.as_on_date}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data?.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
      ) {
        logOut();
      } else if (errors.response?.data?.statusCode === 501) {
        notify("warning", errors.response.data?.message);
      }
      return errors;
    });
}

export async function downloadOutstandingReportsReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.OUTSTANDING_STATEMENT_CSV_DOWNLOAD_API}?society_id=${action.data?.society_id}&page=${action.data?.page}&limit=${action.data?.limit}&fin_year=${action.data?.fin_year}&user_type=${action.data?.user_type}&day_book_id=${action.data?.day_book_id}&as_on_date=${action.data?.as_on_date}`
      , {
        responseType: "arraybuffer",
      }
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data?.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
      ) {
        logOut();
      } else if (errors.response?.data?.statusCode === 501) {
        notify("warning", errors.response.data?.message);
      }
      return errors;
    });
}

// account reports

export async function getGroupTrialBalanceReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.GROUP_TRAIL_BALANCE_API}?society_id=${action.data?.society_id}&page=${action.data?.page}&limit=${action.data?.limit}&fin_year=${action.data?.fin_year}&as_on_date=${action.data?.as_on_date}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data?.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
      ) {
        logOut();
      } else if (errors.response?.data?.statusCode === 501) {
        notify("warning", errors.response.data?.message);
      }
      return errors;
    });
}

export async function downloadCSVGroupTrialBalanceReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.DOWNLOAD_CSV_GROUP_TRAIL_BALANCE_API}?society_id=${action.data?.society_id}&page=${action.data?.page}&limit=${action.data?.limit}&fin_year=${action.data?.fin_year}&as_on_date=${action.data?.as_on_date}&email=${action.data?.email}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data?.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
      ) {
        logOut();
      } else if (errors.response?.data?.statusCode === 501) {
        notify("warning", errors.response.data?.message);
      }
      return errors;
    });
}

export async function downloadPDFGroupTrialBalanceReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.DOWNLOAD_PDF_GROUP_TRAIL_BALANCE_API}?society_id=${action.data?.society_id}&page=${action.data?.page}&limit=${action.data?.limit}&fin_year=${action.data?.fin_year}&as_on_date=${action.data?.as_on_date}&email=${action.data?.email}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data?.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
      ) {
        logOut();
      } else if (errors.response?.data?.statusCode === 501) {
        notify("warning", errors.response.data?.message);
      }
      return errors;
    });
}

export async function getOpeningBalanceReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.OPENING_BALANCE_API}?society_id=${action.data?.society_id}&page=${action.data?.page}&limit=${action.data?.limit}&fin_year=${action.data?.fin_year}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data?.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
      ) {
        logOut();
      } else if (errors.response?.data?.statusCode === 501) {
        notify("warning", errors.response.data?.message);
      }
      return errors;
    });
}

export async function downloadCSVOpeningBalanceReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.DOWNLOAD_CSV_OPENING_BALANCE_API}?society_id=${action.data?.society_id}&page=${action.data?.page}&limit=${action.data?.limit}&fin_year=${action.data?.fin_year}`
      , {
        responseType: "arraybuffer",
      }
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data?.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
      ) {
        logOut();
      } else if (errors.response?.data?.statusCode === 501) {
        notify("warning", errors.response.data?.message);
      }
      return errors;
    });
}

export async function downloadPDFOpeningBalanceReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.DOWNLOAD_PDF_OPENING_BALANCE_API}?society_id=${action.data?.society_id}&page=${action.data?.page}&limit=${action.data?.limit}&fin_year=${action.data?.fin_year}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data?.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
      ) {
        logOut();
      } else if (errors.response?.data?.statusCode === 501) {
        notify("warning", errors.response.data?.message);
      }
      return errors;
    });
}

export async function getReceiptPaymentReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.RECEIPT_PAYMENT_API}?society_id=${action.data?.society_id}&page=${action.data?.page}&limit=${action.data?.limit}&voucher_filter_type=${action.data?.voucher_filter_type}&ledger_status=${action.data?.ledger_status}&from_date=${action.data?.from_date}&to_date=${action.data?.to_date}&daybook_short_form=${action.data?.daybook_short_form} `
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data?.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
      ) {
        logOut();
      } else if (errors.response?.data?.statusCode === 501) {
        notify("warning", errors.response.data?.message);
      }
      return errors;
    });
}

export async function downloadCSVReceiptPaymentReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.DOWNLOAD_CSV_RECEIPT_PAYMENT_API}?society_id=${action.data?.society_id}&page=${action.data?.page}&limit=${action.data?.limit}&voucher_filter_type=${action.data?.voucher_filter_type}&ledger_status=${action.data?.ledger_status}&daybook_short_form=${action.data?.daybook_short_form}&from_date=${action.data?.from_date}&to_date=${action.data?.to_date}`
      , {
        responseType: "arraybuffer",
      }
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data?.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
      ) {
        logOut();
      } else if (errors.response?.data?.statusCode === 501) {
        notify("warning", errors.response.data?.message);
      }
      return errors;
    });
}

export async function downloadPDFReceiptPaymentReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.DOWNLOAD_PDF_RECEIPT_PAYMENT_API}?society_id=${action.data?.society_id}&page=${action.data?.page}&limit=${action.data?.limit}&voucher_filter_type=${action.data?.voucher_filter_type}&ledger_status=${action.data?.ledger_status}&from_date=${action.data?.from_date}&to_date=${action.data?.to_date}&daybook_short_form=${action.data?.daybook_short_form}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data?.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
      ) {
        logOut();
      } else if (errors.response?.data?.statusCode === 501) {
        notify("warning", errors.response.data?.message);
      }
      return errors;
    });
}

export async function getCashBankBookReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.CASH_BANK_BOOK_API}?society_id=${action.data?.society_id}&page=${action.data?.page}&limit=${action.data?.limit}&daybook_short_form=${action.data?.daybook_short_form}&from_date=${action.data?.from_date}&to_date=${action.data?.to_date}&fin_year=${action.data?.fin_year}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data?.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
      ) {
        logOut();
      } else if (errors.response?.data?.statusCode === 501) {
        notify("warning", errors.response.data?.message);
      }
      return errors;
    });
}

export async function downloadCSVCashBankBookReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.DOWNLOAD_CSV_CASH_BANK_BOOK_API}?society_id=${action.data?.society_id}&page=${action.data?.page}&limit=${action.data?.limit}&daybook_short_form=${action.data?.daybook_short_form}&from_date=${action.data?.from_date}&to_date=${action.data?.to_date}&fin_year=${action.data?.fin_year}`
      , {
        responseType: "arraybuffer",
      }
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data?.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
      ) {
        logOut();
      } else if (errors.response?.data?.statusCode === 501) {
        notify("warning", errors.response.data?.message);
      }
      return errors;
    });
}

export async function downloadPDFCashBankBookReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.DOWNLOAD_PDF_CASH_BANK_BOOK_API}?society_id=${action.data?.society_id}&page=${action.data?.page}&limit=${action.data?.limit}&daybook_short_form=${action.data?.daybook_short_form}&from_date=${action.data?.from_date}&to_date=${action.data?.to_date}&fin_year=${action.data?.fin_year}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data?.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
      ) {
        logOut();
      } else if (errors.response?.data?.statusCode === 501) {
        notify("warning", errors.response.data?.message);
      }
      return errors;
    });
}

export async function getJournalsReportReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.JOURNALS_API}?society_id=${action.data?.society_id}&page=${action.data?.page}&limit=${action.data?.limit}&daybook_short_form=${action.data?.daybook_short_form}&from_date=${action.data?.from_date}&to_date=${action.data?.to_date}&fin_year=${action.data?.fin_year}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data?.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
      ) {
        logOut();
      } else if (errors.response?.data?.statusCode === 501) {
        notify("warning", errors.response.data?.message);
      }
      return errors;
    });
}

export async function downloadCSVJournalsReportsReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.DOWNLOAD_CSV_JOURNALS_API}?society_id=${action.data?.society_id}&page=${action.data?.page}&limit=${action.data?.limit}&daybook_short_form=${action.data?.daybook_short_form}&from_date=${action.data?.from_date}&to_date=${action.data?.to_date}&fin_year=${action.data?.fin_year}`
      , {
        responseType: "arraybuffer",
      }
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data?.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
      ) {
        logOut();
      } else if (errors.response?.data?.statusCode === 501) {
        notify("warning", errors.response.data?.message);
      }
      return errors;
    });
}

export async function downloadPDFJournalsReportsReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.DOWNLOAD_PDF_JOURNALS_API}?society_id=${action.data?.society_id}&page=${action.data?.page}&limit=${action.data?.limit}&daybook_short_form=${action.data?.daybook_short_form}&from_date=${action.data?.from_date}&to_date=${action.data?.to_date}&fin_year=${action.data?.fin_year}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data?.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
      ) {
        logOut();
      } else if (errors.response?.data?.statusCode === 501) {
        notify("warning", errors.response.data?.message);
      }
      return errors;
    });
}

export async function getLedgersReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.LEDGERS_API}?society_id=${action.data?.society_id}&page=${action.data?.page}&limit=${action.data?.limit}&account_head=${action.data?.account_head}&fin_year=${action.data?.fin_year}&from_date=${action.data?.from_date}&to_date=${action.data?.to_date}&day_book_id=${action.data?.day_book_id}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data?.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
      ) {
        logOut();
      } else if (errors.response?.data?.statusCode === 501) {
        notify("warning", errors.response.data?.message);
      }
      return errors;
    });
}

export async function downloadCSVLedgersReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.DOWNLOAD_CSV_LEDGERS_API}?society_id=${action.data?.society_id}&page=${action.data?.page}&limit=${action.data?.limit}&account_head=${action.data?.account_head}&fin_year=${action.data?.fin_year}&from_date=${action.data?.from_date}&to_date=${action.data?.to_date}&day_book_id=${action.data?.day_book_id}`
      , {
        responseType: "arraybuffer",
      }
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data?.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
      ) {
        logOut();
      } else if (errors.response?.data?.statusCode === 501) {
        notify("warning", errors.response.data?.message);
      }
      return errors;
    });
}

export async function downloadPDFLedgersReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.DOWNLOAD_PDF_LEDGERS_API}?society_id=${action.data?.society_id}&page=${action.data?.page}&limit=${action.data?.limit}&account_head=${action.data?.account_head}&fin_year=${action.data?.fin_year}&from_date=${action.data?.from_date}&to_date=${action.data?.to_date}&day_book_id=${action.data?.day_book_id}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data?.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
      ) {
        logOut();
      } else if (errors.response?.data?.statusCode === 501) {
        notify("warning", errors.response.data?.message);
      }
      return errors;
    });
}

// export async function getAllMemberLedgereReq(action) {
//   return axiosInstance
//     .get(
//       `${apiEndpoints.ALL_MEMBER_LEDGER_API}?society_id=${action.data?.society_id}&page=${action.data?.page}&limit=${action.data?.limit}&blob_bill_id=${action.data?.billType}`
//     )
//     .then((response) => {
//       return response;
//     })
//     .catch((errors) => {
//       console.log("errors", errors);
//       if (
//         errors.response?.data?.statusCode === 400 &&
//         errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
//       ) {
//         logOut();
//       } else if (errors.response?.data?.statusCode === 501) {
//         notify("warning", errors.response.data?.message);
//       }
//       return errors;
//     });
// }

export async function downloadCSVAllMembersReportReq(action) {
  const BODY = {
    society_id: action.data?.society_id,
    fin_year: action.data?.fin_year,
    from_date: action.data?.start_date,
    to_date: action.data?.end_date,
    members_acc_head: [
      ...action.data?.selected_member?.map(item => "MYA" + action.data?.society_id + item)
    ]
  }
  return axiosInstance
    .post(
      `${apiEndpoints.DOWNLOAD_CSV_ALL_MEMBERS_REPORT_API}`, { ...BODY }, {
      responseType: "arraybuffer",
    }
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data?.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
      ) {
        logOut();
      } else if (errors.response?.data?.statusCode === 501) {
        notify("warning", errors.response.data?.message);
      }
      return errors;
    });
}

export async function getChartOfAccountBookDataReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.CHART_OF_ACCOUNT_BOOK_DATA_API}?society_id=${action.data?.society_id}&page=${action.data?.page}&limit=${action.data?.limit}&blob_bill_id=${action.data?.billType}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data?.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
      ) {
        logOut();
      } else if (errors.response?.data?.statusCode === 501) {
        notify("warning", errors.response.data?.message);
      }
      return errors;
    });
}
