import * as type from '../../../actions/index.types'

export function maintenanceStaffReducer(state = {
    loading: false,
    updateLoader: false,
    maintenanceStaffList: null,
    maintenanceStaffDetails: null,
    error:null
}, action) {

switch (action.type) {
    case type.GET_MAINTENANCE_STAFF_LIST:
        return {
          ...state,
          loading: true,
          error: null
        }
    case type.GET_MAINTENANCE_STAFF_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        maintenanceStaffList: action.payload
      }
    case type.GET_MAINTENANCE_STAFF_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        loading: false,
        maintenanceStaffList: null,
      }

      case type.ADD_UPDATE_MAINTENANCE_STAFF:
        return {
          ...state,
          updateLoader: true,
        }
      case type.ADD_UPDATE_MAINTENANCE_STAFF_SUCCESS:
        return {
          ...state,
          updateLoader: false,
    
        }
      case type.ADD_UPDATE_MAINTENANCE_STAFF_FAILURE:
        return {
          ...state,
          updateLoader: false,
        }
    

      case type.GET_MAINTENANCE_STAFF_DETAILS:
          return {
            ...state,
            loading: true,
            error: null
          }
      case type.GET_MAINTENANCE_STAFF_DETAILS_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          maintenanceStaffDetails: action.payload
        }
      case type.GET_MAINTENANCE_STAFF_DETAILS_FAILURE:
        return {
          ...state,
          error: action?.message || 'Somthing went wrong.',
          loading: false,
          maintenanceStaffDetails: null,
        }


    case type.DELETE_MAINTENANCE_STAFF_OR_AGENCY:
      return {
        ...state,
        updateLoader: true
      }
    case type.DELETE_MAINTENANCE_STAFF_OR_AGENCY_SUCCESS:
      if(action.payload.flag=== 'staff')
        return {
          ...state,
          updateLoader: false,
          maintenanceStaffList: {
            ...state.maintenanceStaffList,
            list: state.maintenanceStaffList?.list?.filter(item=>!(action.payload?.ids?.includes(item.agency_staff_id)))
          }
        }
      else 
        return {
          ...state,
          updateLoader: false
        }
      
    case type.DELETE_MAINTENANCE_STAFF_OR_AGENCY_FAILURE:
      return {
        ...state,
        updateLoader: false
      }
default:
  return state
}
}

export function agencyReducer(state = {
    loading: false,
    updateLoader: false,
    agencyList: null,
    agencyCategoryList: null,
    agencyDetails: null,
    error:null
}, action) {

switch (action.type) {
    case type.GET_AGENCY_LIST:
        return {
          ...state,
          loading: true,
          error: null
        }
    case type.GET_AGENCY_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        agencyList: action.payload
      }
    case type.GET_AGENCY_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        loading: false,
        agencyList: null,
      }


    case type.GET_AGENCY_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        agencyCategoryList: action.payload
      }
  
      
    case type.GET_AGENCY_DETAILS:
        return {
          ...state,
          loading: true,
          error: null
        }
    case type.GET_AGENCY_DETAILS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        agencyDetails: action.payload
      }
    case type.GET_AGENCY_DETAILS_FAILURE:
      return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        loading: false,
        agencyDetails: null,
      }



    case type.ADD_UPDATE_AGENCY:
      return {
        ...state,
        updateLoader: true,
      }
    case type.ADD_UPDATE_AGENCY_SUCCESS:
      return {
        ...state,
        updateLoader: false,
  
      }
    case type.ADD_UPDATE_AGENCY_FAILURE:
      return {
        ...state,
        updateLoader: false,
      }

    case type.DELETE_MAINTENANCE_STAFF_OR_AGENCY:
      return {
        ...state,
        updateLoader: true
      }
    case type.DELETE_MAINTENANCE_STAFF_OR_AGENCY_SUCCESS:
      if(action.payload.flag=== 'agency')
        return {
          ...state,
          updateLoader: false,
          agencyList: {
            ...state.agencyList,
            list: state.agencyList?.list?.filter(item=>!(action.payload?.ids?.includes(item.agency_id)))
          }
        }
      else 
        return {
          ...state,
          updateLoader: false
        }
      
    case type.DELETE_MAINTENANCE_STAFF_OR_AGENCY_FAILURE:
      return {
        ...state,
        updateLoader: false
      }
default:
  return state
}
}