import * as types from '../index.types';

export function getNoticeList(data) {
    return {
        type: types.GET_NOTICE_LIST,
        data
    };
}

export function getSavedNoticeDetails(data) {
    return {
        type: types.GET_SAVED_NOTICE_DETAILS,
        data
    };
}

export function updateSavedNotice(data) {
    return {
        type: types.UPDATE_SAVED_NOTICE,
        data
    };
}

export function deleteSavedNotic(data) {
    return {
        type: types.DELETE_SAVED_NOTICE,
        data
    };
}
