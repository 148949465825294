import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {DatePickerComponent} from "../../../../components/DatePicker/DatePicker";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import EditInvoiceDraftTable from "./EditInvoiceDraftTable";
import moment from "moment";

const Accordion = ({
  label,
  isOpen,
  toggleAccordion,
  children,
  totalAmount,
}) => {
  return (
    <div className="border rounded-lg border-[#CCCCCC] my-2">
      <div
        className="cursor-pointer items-center bg-[#FDF2F3] rounded-lg p-3 border-b"
        onClick={toggleAccordion}
      >
        <div className="flex items-center w-full">
          <div>
            <button
              className="flex items-center justify-center w-5 h-5 mr-3 text-base font-semibold text-white border rounded bg-red-650"
            >
              {isOpen ? (
                <FontAwesomeIcon icon={faChevronUp} size="xs" />
              ) : (
                <FontAwesomeIcon icon={faChevronDown} size="xs" />
              )}
            </button>
          </div>
          <div className="text-[#000000] grow w-full text-sm font-semibold justify-between flex">
            <span className="">{label}</span>
            <span className="">Total Amount: {totalAmount}</span>
          </div>
        </div>
      </div>
      {isOpen && <>{children}</>}
    </div>
  );
};

const EditInvoiceDraftAccordion = ({ invoiceData, setInvoiceData,headData,editCalculation }) => {
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const [checkboxStates, setCheckboxStates] = useState({
    showUnit: true,
    showFlatArea: true,
  });

  const toggleAccordion = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleChecked = (checkboxName) => {
    setCheckboxStates((prevState) => ({
      ...prevState,
      [checkboxName]: !prevState[checkboxName],
    }));
  };

  return (
    <div>
      {invoiceData?.invid?.map((item, index) => (
        <Accordion
          key={index}
          label={invoiceData[item]?.memberdata?.membername}
          totalAmount= {invoiceData[item]?.invoicedetail?.grandtotal}
          isOpen={index === openAccordionIndex}
          toggleAccordion={() => toggleAccordion(index)}
        >
          <div className="p-3 text-[#222222] text-sm flex">
            <div className="w-1/2">
              <div className="flex gap-5 mb-4">
                <span className="w-[30%]">Invoice Number:</span>
                <span>{invoiceData[item]?.invoicedetail?.invoice_no}</span>
              </div>
              <div className="flex gap-5 mb-4">
                <span className="w-[30%]">Member Name:</span>
                <span>{invoiceData[item]?.memberdata?.membername}</span>
              </div>
              <div className="flex gap-5 mb-4">
                <span className="w-[30%]">Unit:</span>
                <span>{invoiceData[item]?.memberdata?.unit}</span>
              </div>
              <div className="flex gap-5 mb-4">
                <span className="w-[30%]">Area:</span>
                <span>{invoiceData[item]?.memberdata?.flatarea}</span>
              </div>
              <div className="flex gap-5 mb-4">
                <span className="w-[30%]">GST Registration No.:</span>
                <span>{invoiceData[item]?.memberdata?.gst_number}</span>
              </div>
            </div>
            <div className="w-1/2">
              <div className="flex items-center justify-between mb-4">
                <span>Invoice Date:</span>
                <span className="mr-12">
                  <DatePickerComponent
                    defaultValue={
                      invoiceData[item]?.invoicedetail?.invoice_date ? new Date(invoiceData[item]?.invoicedetail?.invoice_date) : null
                    }
                    className="w-52 h-9"
                    onDateChange={(selectedDate) => {
                      setInvoiceData((prev) => {
                        let newData = {...prev};
                        newData[item].invoicedetail.invoice_date =
                          moment(selectedDate).format("YYYY-MM-DD");
                        return newData;
                      });
                    }}
                  />
                </span>
              </div>
              <div className="flex items-center justify-between mb-4">
                <span>Bill Period:</span>
                <span className="mr-12">
                {invoiceData[item]?.invoicedetail?.bill_period}
                </span>
              </div>
              <div className="flex items-center justify-between mb-4">
                <span>Payment Due Date:</span>
                <span className="mr-12">
                <DatePickerComponent
                    defaultValue={
                      invoiceData[item]?.invoicedetail?.payment_duedate ? new Date(invoiceData[item]?.invoicedetail?.payment_duedate) : null
                    }
                    className="w-52 h-9"
                    onDateChange={(selectedDate) => {
                      setInvoiceData((prev) => {
                        let newData = {...prev};
                        newData[item].invoicedetail.payment_duedate =
                          moment(selectedDate).format("YYYY-MM-DD");
                        return newData;
                      });
                    }}
                  />
                </span>
              </div>
              <div className="flex items-center justify-end mb-4">
                {/* <span className="text-sm">Show Unit As “Unit: Wing No.</span> */}
                <span className="w-64">
                  <Checkbox
                    text=" Send Email Notification To User"
                    className="text-xs"
                    initialchecked={invoiceData[item]?.sendemailnotification === "Yes" ? true: false}
                    onChange={(e) => {
                      setInvoiceData((prev) => {
                        let newData = {...prev};
                        newData[item].sendemailnotification = (e.target.checked) ? "Yes" : "No"
                        return newData;
                      });
                    }}
                  />
                </span>
              </div>

              <div className="flex items-center justify-end">
                {/* <span className="text-sm">Show Flat Area</span> */}
                <div className="flex items-center justify-between w-64 gap-3">
                  <Checkbox
                    text=" Send SMS Notification To User"
                    className="-mt-3 text-xs"
                    initialchecked={invoiceData[item]?.sendsmsnotification === "Yes" ? true: false}
                    onChange={(e) => {
                      setInvoiceData((prev) => {
                        let newData = {...prev};
                        newData[item].sendsmsnotification = (e.target.checked) ? "Yes" : "No"
                        console.log(newData, "test456")
                        return newData;
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="p-3">
            <Table 
            invoiceData = {invoiceData} 
            index={item} 
            setInvoiceData={setInvoiceData} 
            headData={headData[index]} 
            editCalculation={editCalculation} 
            />
          </div>
        </Accordion>
      ))}
    </div>
  );
};

export default EditInvoiceDraftAccordion;

const Table = ({setInvoiceData, invoiceData, headData, index,editCalculation}) => {
  return (
    <>
      <EditInvoiceDraftTable
        data={invoiceData}
        headData={headData}
        index={index}
        setInvoiceData={setInvoiceData}
        columns={headCells}
        checkbox={false}
        pagination={false}
        editCalculation={editCalculation}
      ></EditInvoiceDraftTable>
    </>
  );
};


const headCells = [
  {
    id: "item",
    label: "Item",
    sort: false,
    width: "600px",
  },
  { id: "amount", label: "Amount(Rs.)", sort: false },
];
