import React, { useEffect, useRef } from "react";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import Search from "../../../../components/SearchBox/Search";
import { ADD_FROM_EXISITING_TENANT_ENDPOINT, TENANTS_INFO_ENDPOINT } from "../../../../Routing/routes";
import Layout from "../../../Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getFlatDetails, getWingsAndFlatDetails } from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import { Formik, Form } from "formik";
import { CircularProgress } from "@mui/material";
import Loader from "../../../../components/Loader/Loader";
import { DropdownFM2 } from "../../../../components/Dropdown/dropdown2";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import Button from "../../../../components/Button/Button";
import {addExistingTenants, getExistingTenants} from '../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/TenantsInfoAction'
import { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import moment from "moment";
import{ UploadButtonFM } from "../../../../components/Button/UploadButton";
import {AddExistingTenantsValidation} from '../../../../validationSchema/setupRecordsSchema/occupants&TenantsSchema'
import { uploadImageOnFirebase } from "../../../../firebase/firebaseFunction";

const AddFromExistingTenant = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const formRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams()
  const {loading, updateLoader, existingTenantList} = useSelector(state=>state.tenantInfoReducer)
  const {wingsData,flatsData} = useSelector(state=>state.wingsReducer)
  const {loginData} = useSelector(state=>state.loginReducer)
  const {ipAddress} = useSelector(state=>state.ipAddressReducer)

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleSearch = (text)=>{
    if(text){
      navigate({
        pathname: `${ADD_FROM_EXISITING_TENANT_ENDPOINT}`,
        search: `?${createSearchParams([["search", text?.trim()]])}`,
      }, { replace: true })
    }
  }

  const handleSubmit = async(values, {resetForm})=>{
    if(values?.rentAgreementFile){
      await uploadImageOnFirebase(values.rentAgreementFile, `society/${loginData?.default_community?.community_id}/flats/${values.flatId}/docs/${values.rentAgreementFile?.name}`)
    }
    dispatch(addExistingTenants({
      ...values, 
      rentAgreementFile: values?.rentAgreementFile ? `society/${loginData?.default_community?.community_id}/flats/${values.flatId}/docs/${values.rentAgreementFile?.name}` : '',
      ipAddress, 
      societyId: loginData?.default_community?.community_id, 
      sessionId: loginData?.session_id,
      onSuccessCallback: ()=>{
        resetForm()
      }
    }))
  }

  const getTableData =() =>{
    const data={
      searchText: searchParams.get("search") || ''
    }
    dispatch(getExistingTenants(data))
  }

  useEffect(()=>{      
    getTableData()
  }, [searchParams])

  useEffect(()=>{
    dispatch(getWingsAndFlatDetails({societyId: loginData?.default_community?.community_id}))
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          enableReinitialize
          initialValues={{ 
              userId: existingTenantList?.user_acc_id,
              firstName: existingTenantList?.first_name,
              lastName: existingTenantList?.last_name,
              middleName: existingTenantList?.middle_name,
              email: existingTenantList?.email,
              mobileNo: existingTenantList?.contact_no,
              countryID: existingTenantList?.tenant_country_id,
              mobileCountryCode: existingTenantList?.mobile_country_code,

              wingId: '',
              flatId: '',
              rentDeedStartDate: null,
              rentDeedEndDate: null,
              rentAgreementFile:  ''
            }}

          innerRef={(f) => (formRef.current = f)}
          onSubmit={handleSubmit}
          validationSchema={AddExistingTenantsValidation}
          >    
          {
            ({values, setFieldValue, errors})=>{
              return (
                <Form>
                    <div className="border-b border-[#CCC] mb-3">
                      <ListHeaderBack
                        onClick={handleNavigation(TENANTS_INFO_ENDPOINT)}
                        title="Invite Existing Member"
                      >
                        <Search placeholder='Search by email or mobile number' onclick={handleSearch} value={searchParams.get("search")}/>
                        <span className="border-r border-[#ccc]"></span>
                        <Button label={
                            <span className="w-12 inline-block">
                              {
                                updateLoader ?
                                  <CircularProgress sx={{color: "white"}} size={17}/>
                                  :
                                  'Send'
                                }
                            </span>
                            } type='button'
                            onClick={()=>formRef.current?.submitForm()}/>
                      </ListHeaderBack>
                    </div>
                    <div>
                      <div>
                        <div>
                          {
                            (loading && searchParams.get("search")) ?
                            <Loader/>
                            :
                            <>
                              {
                                (existingTenantList && searchParams.get("search")) ?
                                <div className="ms-5 w-[400px]">
                                  <div className="flex text-sm mb-5">
                                    <div className="w-44">Name</div>
                                    <div>{ values.firstName + ' ' + values.middleName + ' ' + values.lastName}</div>
                                  </div>
                                  <div className="flex  justify-between text-sm mb-1">
                                    <div className="mt-2 w-48">Wing No.</div>
                                    <DropdownFM2
                                      options={wingsData?.map(item=>({
                                        label: item.number,
                                        value: item?.wing_id
                                      })) || []}
                                      width={"224px"}
                                      className="text-xs"
                                      placeholder={"Select Wing"}
                                      onSelect={(selectedItem)=>{
                                        setFieldValue(`wingId`, selectedItem.value)
                                        setFieldValue(`flatId`, '')
                                        dispatch(getFlatDetails({societyId: loginData?.default_community?.community_id, wingId: selectedItem?.value}))
                                      }}
                                      height='38px'
                                      name='wingId'
                                      {...(values.wingId ==='' && {value: null})}
                                    />
                                  </div>
                                  <div className="flex  justify-between text-sm mb-1">
                                    <div className="mt-2 w-48">Flat No.</div>
                                    <DropdownFM2
                                      options={(values.wingId && flatsData?.manage_flat_details?.map(item=>({
                                        label: item.flat_no,
                                        value: item?.flat_id
                                      }))) || []}
                                      width={"224px"}
                                      className="text-xs"
                                      placeholder={"Select Flat"}
                                      onSelect={(selectedItem)=>{
                                        setFieldValue(`flatId`, selectedItem.value)
                                      }}
                                      height='38px'
                                      name='flatId'
                                      {...(values.flatId==='' && {value: null})}
                                    />
                                  </div>
                                  <div className="flex flex-row justify-between mb-1">
                                    <span className="text-sm mt-2">Rent Deed Start Date</span>
                                    <div>
                                      <DatePickerComponentFM
                                        className="w-56 h-9 ml-4 justify-between"
                                        onDateChange={(selectedDate) => {
                                          setFieldValue('rentDeedStartDate', selectedDate)
                                        }}
                                        name='rentDeedStartDate'
                                        defaultValue={values.rentDeedStartDate ? new Date(values.rentDeedStartDate) : null}
                                        maxDate={new Date()}
                                      />
                                    </div>
                                  </div>
                                  <div className="flex flex-row justify-between mb-1">
                                    <span className="text-sm mt-2">Rent Deed End Date</span>
                                    <div>
                                    <DatePickerComponentFM
                                      className="w-56 h-9 ml-4 justify-between"
                                      onDateChange={(selectedDate) => {
                                        setFieldValue('rentDeedEndDate', selectedDate)
                                      }}
                                      name='rentDeedEndDate'
                                      defaultValue={values.rentDeedEndDate ? new Date(values.rentDeedEndDate) : null}
                                      readOnly={!values.rentDeedStartDate}
                                      {...(values.rentDeedStartDate && {minDate: new Date(moment(values.rentDeedStartDate).add(1, 'days'))})}
                                    />
                                    </div>
                                  </div>
                                  <div className="flex justify-between">
                                    <span className="text-sm">Rent Agreement Upload</span>
                                    <div className="w-56">
                                      <UploadButtonFM showFileName={true} 
                                        name={`rentAgreementFile`}
                                        accept={"image/jpeg,image/gif,image/png,application/pdf,image/x-eps"}
                                        onSelect={(e)=>setFieldValue(`rentAgreementFile`, e)} 
                                        fileName={values?.rentAgreementFile?.name}
                                        />
                                    </div>
                                  </div>
                                </div>
                                :
                                <div className="text-center py-4 text-[#999999] flex justify-center">
                                  No Records
                                </div>
                              }

                            </>
                          }
                        </div>
                      </div>
                    </div>
                </Form>
              )}
            }
        </Formik>
      </div>
    </Layout>
  );
};

export default AddFromExistingTenant;
