import React, { useState } from "react";
import Checkbox from "../../../../../components/Checkbox/Checkbox";

const CreditNote = ({
  setInvoiceSettingList,
  invoiceSettingList
}) => {
  const [checked, setChecked] = useState(false);

  const handleChecked = (event, setFieldValue) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      setFieldValue("isUrgent", "Yes");
    } else {
      setFieldValue("isUrgent", "No");
    }
  };
  return (
    <>
      <div className="p-3">
        <table className="border-separate w-[60%]">
          <tbody>
            <tr className="items-center text-sm">
              <td className="pb-4">Show Flat Address in the Invoice</td>
              <td className="pb-4">
                <Checkbox
                  text="Yes"
                  className="text-sm"
                  initialchecked={invoiceSettingList.showCreditAddress===1?true:false}
                  onChange={(e) => {
                    setInvoiceSettingList({...invoiceSettingList, showCreditAddress: e.target.checked ? 1 : 0 });
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CreditNote;
