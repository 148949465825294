import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import moment from "moment";
import ImageComponent from "../../../components/ImageComponent/ImageComponent";
import { getVisitorPassData } from "../../../redux/actions/GateManagementActions/VisitorAndVehicleInAndOutAction";
import { validateDate } from "../../../utils/helpers/universalFunctions";


const IdCard = ({
  societyName,
  jobName,
  imageURL,
  name,
  visitorNo,
  contact,
  type,
  purpose,
  count,
  date,
  qrCode,
  flat,
  belongings,
  from,
}) => {
  return (
    <div className="w-[350px] mx-auto bg-white shadow-lg overflow-hidden border border-[#FAFAFA] text-[#222]">
      <div className="px-6 py-3 bg-[#2e7db1]">
        <h1 className="font-bold text-[22px] text-center text-white mb-2 py-2">
          {societyName}
        </h1>
        <div className="border-b border-white w-full h-full"></div>
        <div className="flex justify-between items-center">
        <p className=" text-center text-white text-[16px] mt-2 font-semibold">
          {jobName}
        </p>
        <p className=" text-center text-white text-[16px] mt-2 font-semibold">
          {visitorNo}
        </p>
        </div>
      </div>

      <div className="flex px-3">
        <div className="w-[45%] mt-3">
          <ImageComponent defaultUrl={imageURL} width={32} height={32} />
        </div>
        <div className="w-[55%] mt-2">
          <p className="font-extrabold text-lg leading-6 mt-2 mb-2.5 capitalize">
            {name}
          </p>
          <div className="flex gap-1">
            <table>
              <tbody className="align-top text-xs">
                {/* <tr>
                  <td className="text-[#222] w-[80px]">Type: </td>
                  <td className="font-semibold">{type}</td>
                </tr> */}
                <tr>
                  <td className="text-[#222] w-[80px]">Contact: </td>
                  <td className="font-semibold">{contact}</td>
                </tr>
                <tr>
                  <td className="text-[#222] w-[80px]">Purpose: </td>
                  <td className="font-semibold">{purpose}</td>
                </tr>
                <tr>
                  <td className="text-[#222] w-[80px]">Visitor Count: </td>
                  <td className="font-semibold">{count}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="mx-2 grid grid-cols-1">
        <div className="mt-2 text-[13px]">
          <span className="text-[#222] w-20">Unit/Flat No.: &nbsp;</span>
          <span className="font-semibold capitalize">{flat}</span>
        </div>
        <div className="mt-2 text-[13px]">
          <span className="text-[#222] w-20">Belongings: &nbsp;</span>
          <span className="font-semibold capitalize">{belongings}</span>
        </div>
        <div className="mt-2 text-[13px]">
          <span className="text-[#222] w-20">From: &nbsp;</span>
          <span className="font-semibold capitalize">{from}</span>
        </div>
      </div>
      <hr className="m-2 mt-4 border-t-[1px]" />

      <div className="flex justify-center">
        {/* QR Code component or image goes here */}
        {qrCode && (
          <ImageComponent defaultUrl={qrCode} width={32} height={32} />
        )}
      </div>

      <div className="px-6 py-2 bg-[#2e7db1]">
        <div className="flex justify-between items-center">
          <p className="text-white ">Date & Time </p>
          <h1 className="font-bold text-[14px] text-center text-white">
            {date}
          </h1>
        </div>
        <div className="border-b border-white w-full h-full"></div>
        <div className="text-center">
          <Link
            to="https://zipgrid.com/"
            target="_blank"
            className=" text-white text-[13px] mt-1 font-semibold"
          >
            www.zipgrid.com
          </Link>
        </div>
      </div>
    </div>
  );
};

const PrintVisitorPass = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, visitorPassData } = useSelector(
    (state) => state.visitorAndVehicleInAndOutReducer
  );

  useEffect(() => {
    dispatch(getVisitorPassData({ visitorLogId: id }));
  }, []);

  return (
    <div className="flex items-center mt-2 flex-wrap justify-center bg-gray-100 mb-10">
      {loading ? (
        <Loader />
      ) : (
        <>
          {visitorPassData && (
            <IdCard
              societyName={visitorPassData?.society_name}
              jobName={"Visitor Pass"}
              imageURL={visitorPassData?.data?.vphoto}
              name={
                `${visitorPassData?.data?.vname} ${
                  visitorPassData?.data?.lname
                    ? visitorPassData?.data?.lname
                    : ""
                }` || "-"
              }
              visitorNo={visitorPassData?.data?.visitors_no || "-"}
              type={visitorPassData?.data?.type || "-"}
              contact={
                `${
                  visitorPassData?.data?.VCountryCode
                    ? `+${visitorPassData?.data?.VCountryCode?.replace(
                        "+",
                        ""
                      )}-`
                    : ""
                }${visitorPassData?.data.vphone}` || "-"
              }
              purpose={visitorPassData?.data?.purpose}
              count={visitorPassData?.data.vcount}
              date={visitorPassData?.data?.checkin  || '-'}
              flat={visitorPassData?.data?.flats_details || "-"}
              from={visitorPassData?.data?.vfrom || "-"}
              belongings={visitorPassData?.data?.vbelongings || "-"}
              qrCode={visitorPassData?.data?.qr}
            />
          )}
        </>
      )}
    </div>
  );
};

export default PrintVisitorPass;
