import React from 'react';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

export function downloadExcel({ data, fileName }) {
  try {
    var url = window.URL.createObjectURL(new Blob([data]));
    var a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a); // append the element to the dom
    a.click();
    a.remove();
  } catch (e) {
    console.log(e)
  }
}


export function downloadFileFromURL({ url, fileName }) {
  try {
    var a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a); // append the element to the dom
    a.click();
    a.remove();

    // let imgExtentions = ['jpg', 'png', 'jfif', 'gif', 'jpeg', 'svg']
    // if(imgExtentions.some(sub => url.includes(sub))){
    //   const xhr = new XMLHttpRequest();
    //   xhr.responseType = 'blob';
    //   xhr.onload = (event) => {
    //     const blob = xhr.response;
    //   };
    //   xhr.open('GET', url);
    //   xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    //   xhr.send();
    // }else{
    //   var a = document.createElement('a');
    //   a.href = url;
    //   a.download = fileName;
    //   document.body.appendChild(a); // append the element to the dom
    //   a.click();
    //   a.remove();
    // }
  } catch (e) {
    console.log(e)
  }
}

export function exportToCSV(apiData, fileName) {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const ws = XLSX.utils.json_to_sheet(apiData);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};
