import React, { useEffect } from "react";
import { DeleteIcon } from "../../../../assets";
import { DropdownFM2 } from "../../../../components/Dropdown/dropdown2";
import {
  TextInput,
  TextInputFM,
} from "../../../../components/InputFields/TextInput";
import { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import TextRadio from "../../../../components/InputFields/TextRadio";
import moment from "moment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getBookingGLMappingList } from "../../../../redux/actions/ClubActions/ViewBookingsAction";
import { getCreditDebitTariffHeaderList } from "../../../../redux/actions/FinanceActions/SettingActions/CreditDebitTariffHeaderAction";
import { FieldArray, ErrorMessage } from "formik";

const DebitDetailBox = ({
  values,
  setFieldValue,
  manageDebitNoteTarrifList = null,
  columns = null,
}) => {
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);

  const { glMappingList } = useSelector((state) => state.viewBookingReducer);

  const handleRadio = (value, key, setFieldValue) => {
    setFieldValue(key, value);
  };

  const timeOfDayOptions = [
    { name: "timeOfDay", value: "Y", label: "Service" },
    { name: "timeOfDay", value: "N", label: "Goods" },
  ];

  const subTotal = (values, setFieldValue) => {
    const total = Number(
      values.details?.reduce((prevVal, item) => {
        return prevVal + Number(item?.amount);
      }, 0)
    );
    const total2 = Number(
      values.details?.reduce((prevVal, item) => {
        return item?.gstapplicable == "Y"
          ? prevVal + Number(item?.amount)
          : prevVal;
      }, 0)
    );
    const cgst = manageDebitNoteTarrifList?.debitsetting?.cgst_rate
      ? (
          (total2 *
            Number(manageDebitNoteTarrifList?.debitsetting?.cgst_rate)) /
          100
        ).toFixed(2)
      : 0;
    const sgst = manageDebitNoteTarrifList?.debitsetting?.sgst_rate
      ? (
          (total2 *
            Number(manageDebitNoteTarrifList?.debitsetting?.sgst_rate)) /
          100
        ).toFixed(2)
      : 0;
    const igst = manageDebitNoteTarrifList?.debitsetting?.igst_rate
      ? (
          (total2 *
            Number(manageDebitNoteTarrifList?.debitsetting?.igst_rate)) /
          100
        ).toFixed(2)
      : 0;
    // setFieldValue("interest", Number(cgst) + Number(sgst));
    setFieldValue("sgst_amount", cgst);
    setFieldValue("cgst_amount", sgst);
    setFieldValue("igst_amount", igst);

    setFieldValue(
      "subTotal",
      Number(total.toFixed(2)) + (Number(cgst) + Number(sgst) + Number(igst))
    );
    // if (
    //   manageDebitNoteTarrifList?.debitsetting?.cgst_rate != "" &&
    //   manageDebitNoteTarrifList?.debitsetting?.cgst_rate != ""
    // ) {
    // }

    // setFieldValue("interest", igst);
    // setFieldValue("subTotal", Number(total.toFixed(2)) + igst);
    //   if (manageDebitNoteTarrifList?.debitsetting?.igst_rate !== "") {
    // }
  };

  return (
    <div>
      <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg mt-3">
        <div className=" border-b border-[#CCC] pb-2 bg-[#FDF2F3]">
          <h1 className="items-center p-2 ml-1 -mb-2 text-base">
            Cause Details
          </h1>
        </div>
        <div className="p-2 ">
          <div className="flex flex-wrap gap-2 p-2">
            {
              <>
                <div className="flex items-start gap-4 text-sm">
                  <div className="flex items-start justify-between gap-4 ">
                    <div style={{ width: "121px" }} className="py-2 font-bold">
                      Tarrif Header
                    </div>
                    <div style={{ width: "121px" }} className="py-2 font-bold ">
                      Credit A/C
                    </div>
                    <div style={{ width: "129px" }} className="py-2 font-bold">
                      Amount(Rs.)
                    </div>
                    <div style={{ width: "158px" }} className="py-2 font-bold">
                      Description
                    </div>
                    <div style={{ width: "137px" }} className="py-2 font-bold">
                      From Date
                    </div>
                    <div style={{ width: "130px" }} className="py-2 font-bold">
                      To Date
                    </div>
                  </div>
                  <div className="py-2 font-bold ">
                    {values.details?.length > 1 ? "Action" : ""}
                  </div>
                </div>
                <FieldArray
                  name="details"
                  render={(arrayHelpers) => (
                    <>
                      {values.details &&
                        values.details.length > 0 &&
                        values.details.map((row, index) => {
                          return (
                            <React.Fragment key={index}>
                              <div className="flex items-start gap-4">
                                <div className="flex items-start justify-between gap-4">
                                  <div
                                    style={{ width: "150px" }}
                                    className="py-2 text-sm"
                                  >
                                    {row?.cause}
                                  </div>

                                  <div
                                    style={{ width: "150px" }}
                                    className="py-2 text-sm"
                                  >
                                    {row?.gl_name}
                                  </div>
                                  <div>
                                    <TextInput
                                      label=""
                                      placeholder="Enter Amount"
                                      className="w-30"
                                      name={`details.${index}.amount`}
                                      value={values.details[index]?.amount}
                                      onChange={(e) => {
                                        setFieldValue(
                                          `details.${index}.amount`,
                                          e.target.value
                                        );
                                        values.details[index].amount =
                                          e.target.value;
                                        subTotal(values, setFieldValue);
                                      }}
                                      type="decimal"
                                    />
                                    <div
                                      className={`text-red-500 text-xs italic h-4 text-wrap break-words `}
                                    >
                                      <ErrorMessage
                                        name={`details.${index}.amount`}
                                      />
                                    </div>
                                  </div>

                                  <TextInputFM
                                    label=""
                                    placeholder="Enter Description"
                                    className="w-40"
                                    name={`details.${index}.description`}
                                  />
                                  <div className="flex gap-3">
                                    <DatePickerComponentFM
                                      className="h-9 w-42"
                                      name={`details.${index}.fromDate`}
                                      placeholder="From Date"
                                      onDateChange={(selectedDate) => {
                                        if (
                                          new Date(
                                            values.details[index]?.toDate
                                          ).getTime() <
                                          new Date(selectedDate).getTime()
                                        ) {
                                          setFieldValue(
                                            `details.${index}.toDate`,
                                            null
                                          );
                                        }
                                        setFieldValue(
                                          `details.${index}.fromDate`,
                                          moment(selectedDate).format(
                                            "YYYY-MM-DD"
                                          )
                                        );
                                      }}
                                      // minDate={new Date()}
                                      defaultValue={
                                        values.details[index]?.fromDate
                                          ? new Date(
                                              values.details[index].fromDate
                                            )
                                          : null
                                      }
                                    />
                                  </div>
                                  <div className="flex gap-3">
                                    <DatePickerComponentFM
                                      className="h-9 w-42"
                                      name={`details.${index}.toDate`}
                                      onDateChange={(selectedDate) => {
                                        setFieldValue(
                                          `details.${index}.toDate`,
                                          moment(selectedDate).format(
                                            "YYYY-MM-DD"
                                          )
                                        );
                                      }}
                                      minDate={
                                        new Date(
                                          values.details[index]?.fromDate
                                        )
                                      }
                                      defaultValue={
                                        values.details[index]?.toDate
                                          ? new Date(
                                              values.details[index].toDate
                                            )
                                          : null
                                      }
                                    />
                                  </div>
                                  {/* <TextInputFM
                                  label=""
                                  placeholder="Enter Amount"
                                  className="w-48"
                                  name={`details.${index}.amount`}
                                  type="decimal"
                                /> */}
                                </div>
                                <div className="flex mt-1">
                                  <div className="w-[26px]">
                                    {values.details?.length > 1 && (
                                      <button
                                        className="p-2 fill-red-650"
                                        onClick={() => {
                                          arrayHelpers.remove(index);
                                          values.details.splice(index, 1);
                                          subTotal(values, setFieldValue);
                                        }}
                                        type="button"
                                      >
                                        <DeleteIcon />
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                      {/* <div>
                      <button
                        type="button"
                        className="text-red-650 font-semibold border-2 border-dashed border-red-650 w-28 text-xs whitespace-nowrap h-9 p-2 mb-4 bg-[#FDF6F6] flex justify-center rounded-md"
                        onClick={() =>
                          arrayHelpers.push({
                            tariffHeaderId: "",
                            debitAccountId: "",
                            hsnCode: "",
                            serviceGood: "Y",
                            amount: "",
                          })
                        }
                      >
                        Add More
                      </button>
                    </div> */}
                    </>
                  )}
                ></FieldArray>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default DebitDetailBox;
