import React, { useEffect } from "react";
import Layout from "../../../../Layout/Layout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import InvoiceStructure from "./InvoiceStructure";
import PaymentOptions from "./PaymentOptions";
import ListHeaderBack from "../../../../../components/ListHeader/ListHeaderBack";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getMakePaymentDetails } from "../../../../../redux/actions/FinanceActions/BillingManagementActions/ManageDebitNoteAction";

function MakePaymentS() {
  const data = useLocation().state;
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, updateLoader, makePaymentDetails } = useSelector(
    (state) => state.manageDebitNoteReducer
  );

  const { loginData } = useSelector((state) => state.loginReducer);

  useEffect(() => {
    dispatch(
      getMakePaymentDetails({
        societyId: loginData?.default_community?.community_id,
        invid: id,
      })
    );
  }, [dispatch, id, loginData?.default_community?.community_id]);
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack
            title="Make Payment"
            onClick={() => {
              navigate(-1);
            }}
          ></ListHeaderBack>
        </div>
        <div className="py-1 gap-2 my-1">
          <InvoiceStructure
            invoiceDetails={makePaymentDetails}
            loginData={loginData}
            loading={loading}
          />
          <div>
            <PaymentOptions
              invid={id}
              invoiceDetails={makePaymentDetails}
              loginData={loginData}
              loading={loading}
              updateLoader={updateLoader}
              data={data}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default MakePaymentS;
