import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ListHeader from "../../../components/ListHeader/ListHeader";
import Layout from "../../Layout/Layout";
import Button from "../../../components/Button/Button";
import ButtonG from "../../../components/Button/ButtonG";
import { UploadButtonFM } from "../../../components/Button/UploadButton";
import Checkbox from "../../../components/Checkbox/Checkbox";
import { DropdownFM2 } from "../../../components/Dropdown/dropdown2";
import { Form, Formik } from "formik";
import { editComplaintValidation } from "../../../validationSchema/deskSchema/logaTicketSchema";
import { useDispatch, useSelector } from "react-redux";
import {
  editComplaint,
  getComplaintNatureList,
  getComplaintTypeList,
  getFlatsNo,
  getWingsList,
} from "../../../redux/actions/DeskActions/LogaComplaintActions";
import { onBehalfOfOptions } from "../../../consts/DropDownOptions";
import { CircularProgress } from "@mui/material";
import {
  getComplaintCategoryDetailsList,
  getComplaintCategoryList,
} from "../../../redux/actions/DeskActions/ComplaintCategoryAction";
import {
  getLoggedComplaintTicketDetails,
  getSocietyDetailsList,
} from "../../../redux/actions/DeskActions/DeskAction";
import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

import Loader from "../../../components/Loader/Loader";

const EditComplaint = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const { loginData } = useSelector((state) => state.loginReducer);
  const dropdownData = useSelector((state) => state.logaComplaintReducer);
  const { wingsList, flatsList } = useSelector(
    (state) => state.logaComplaintReducer
  );
  const { updateLoader } = useSelector((state) => state.logaComplaintReducer);
  const { complaintCategoryList, complaintCategoryDetailsList } = useSelector(
    (state) => state.complaintCategoryReducer
  );
  const { loading, complaintLoggedTicket, societyDetailsList } = useSelector(
    (state) => state.deskReducers
  );
  const { id } = useParams();

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  const handleChecked = (event, setFieldValue) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      setFieldValue("isUrgent", "Yes");
    } else {
      setFieldValue("isUrgent", "No");
    }
  };

  useEffect(() => {
    if (complaintLoggedTicket?.description) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(complaintLoggedTicket?.description || "")
          )
        )
      );
    }
  }, [complaintLoggedTicket?.description]);

  useEffect(() => {
    if (complaintLoggedTicket) {
      dispatch(
        getComplaintCategoryDetailsList({
          categoryId: complaintLoggedTicket?.p_cat_id
            ? complaintLoggedTicket?.p_cat_id
            : complaintLoggedTicket?.cat_id,
        })
      );
      let data = {
        societyId: loginData?.default_community?.community_id,
        wingId: complaintLoggedTicket?.wing_no,
        userType:
          complaintLoggedTicket?.logged_type === "Member"
            ? "Member"
            : complaintLoggedTicket?.logged_type === "Tenant"
            ? "Tenant"
            : "",
      };
      dispatch(getFlatsNo(data));
    }
  }, [complaintLoggedTicket]);

  useEffect(() => {
    dispatch(getComplaintNatureList());
    dispatch(getComplaintCategoryList());
    const data = { societyId: loginData?.default_community?.community_id };
    dispatch(getComplaintTypeList(data));
    dispatch(getWingsList(data));
    const societyId =
      loginData?.default_community?.community_id === 4202
        ? 0
        : loginData?.default_community?.community_id;
    dispatch(getSocietyDetailsList({ societyId }));
    dispatch(getLoggedComplaintTicketDetails({ ticketId: id }));
  }, []);

  const handleCancelClick = () => {
    navigate(-1);
  };

  const handleSaveClick = async (values, { resetForm }) => {
    values.subscriber_id =
      dropdownData.flatsList?.find(
        (item) =>
          item.house_unique_id === parseInt(complaintLoggedTicket?.flatid)
      )?.subscriber_id || "";
    let sendRequest = {
      id: id,
      ...values,
      societyId: loginData?.default_community?.community_id,
    };
    dispatch(editComplaint(sendRequest));
  };

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 px-3">
        <Formik
          initialValues={{
            complaintNature: complaintLoggedTicket?.nature || "",
            complaintType: complaintLoggedTicket?.type || "",
            category: parseInt(complaintLoggedTicket?.p_cat_id),
            subCategory: parseInt(complaintLoggedTicket?.cat_id),
            isUrgent: "No",
            description: complaintLoggedTicket?.description || "",
            wingId: parseInt(complaintLoggedTicket?.wing_no) || "",
            flatsNo: parseInt(complaintLoggedTicket?.flatid) || "",
            attachments: "",
            societyName: parseInt(complaintLoggedTicket?.societyid) || "",
            subscriber_id: "",
            isTenant:
              complaintLoggedTicket?.logged_type === "Member"
                ? 0
                : complaintLoggedTicket?.logged_type === "Tenant"
                ? 1
                : "",
            tenantId: parseInt(complaintLoggedTicket?.logged_by) || "",
            societyId: parseInt(complaintLoggedTicket?.societyid) || "",
            emailAssignee: 0,
            emailOwner: 0,
          }}
          enableReinitialize
          validationSchema={editComplaintValidation}
          onSubmit={handleSaveClick}
        >
          {(formik) => {
            return (
              <Form>
                <div className="border-b border-[#CCC]">
                  <ListHeader leftContent="Edit Complaint">
                    <div className="mx-0.5 border-l-2"></div>
                    <ButtonG
                      label="Cancel"
                      onClick={handleCancelClick}
                      type={"button"}
                    />
                    <Button
                      label={
                        <span className="w-12 inline-block">
                          {updateLoader ? (
                            <CircularProgress
                              sx={{ color: "white" }}
                              size={17}
                            />
                          ) : (
                            "Save"
                          )}
                        </span>
                      }
                      className="px-3 h-8"
                      type="submit"
                    />
                  </ListHeader>
                </div>
                {loading && id !== complaintLoggedTicket?.id ? (
                  <Loader />
                ) : (
                  <div className="flex justify-between gap-4 mt-4">
                    <table className="border-separate">
                      <tbody>
                        <tr>
                          <td className="text-sm w-56 pt-2.5 align-top">
                            Ticket Nature
                          </td>
                          <td>
                            <DropdownFM2
                              options={dropdownData.complaintNatureList?.map(
                                (item) => ({
                                  label: item.title,
                                  value: item.title,
                                })
                              )}
                              placeholder="Select Ticket Nature"
                              className=""
                              width="208px"
                              height="36px"
                              onSelect={(selectedItem) => {
                                formik.setFieldValue(
                                  "complaintNature",
                                  selectedItem.value
                                );
                              }}
                              value={{
                                label: dropdownData.complaintNatureList?.find(
                                  (item) =>
                                    item.title === formik.values.complaintNature
                                )?.title,
                                value: formik.values.complaintNature,
                              }}
                              name={"complaintNature"}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="text-sm w-56 pt-2.5 align-top">
                            Ticket Type
                          </td>
                          <td>
                            <DropdownFM2
                              options={dropdownData.complaintType?.map(
                                (item) => ({
                                  label: item.title,
                                  value: item.title,
                                })
                              )}
                              placeholder="Select Type"
                              className=""
                              width="208px"
                              height="36px"
                              onSelect={(selectedItem) => {
                                formik.setFieldValue(
                                  "complaintType",
                                  selectedItem.value
                                );
                              }}
                              value={{
                                label: dropdownData.complaintType?.find(
                                  (item) =>
                                    item.title === formik.values.complaintType
                                )?.title,
                                value: formik.values.complaintType,
                              }}
                              name={"complaintType"}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="text-sm w-56 pt-2.5 align-top">
                            Category
                          </td>
                          <td>
                            <DropdownFM2
                              options={complaintCategoryList?.map((item) => ({
                                label: item.title,
                                value: item.id,
                              }))}
                              placeholder="Select Category"
                              className=""
                              width="208px"
                              height="36px"
                              onSelect={(selectedItem) => {
                                formik.setFieldValue(
                                  "category",
                                  selectedItem.value
                                );
                                if (
                                  formik.values.category !== selectedItem.value
                                ) {
                                  formik.setFieldValue("subCategory", "");
                                  dispatch(
                                    getComplaintCategoryDetailsList({
                                      categoryId: selectedItem.value,
                                    })
                                  );
                                }
                              }}
                              value={{
                                label: complaintCategoryList?.find(
                                  (item) => item.id === formik.values.category
                                )?.title,
                                value: formik.values.category,
                              }}
                              name={"category"}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="text-sm w-56 pt-2.5 align-top">
                            Sub Category
                          </td>
                          <td>
                            <DropdownFM2
                              options={complaintCategoryDetailsList?.[0]?.sub_category?.map(
                                (item) => ({
                                  label: item.sub_title,
                                  value: item.id,
                                })
                              )}
                              placeholder="Select SubCategory"
                              className=""
                              width="208px"
                              height="36px"
                              onSelect={(selectedItem) => {
                                formik.setFieldValue(
                                  "subCategory",
                                  selectedItem.value
                                );
                              }}
                              value={{
                                label:
                                  complaintCategoryDetailsList?.[0]?.sub_category?.find(
                                    (item) =>
                                      item.id === formik.values.subCategory
                                  )?.sub_title,
                                value: formik.values.subCategory,
                              }}
                              name={"subCategory"}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="text-sm w-56 pt-2.5 align-top">
                            Assign Society
                          </td>
                          <td>
                            <DropdownFM2
                              options={societyDetailsList?.map((item) => ({
                                label: item?.title,
                                value: item.id,
                              }))}
                              placeholder="Select Society"
                              className=""
                              width="208px"
                              height="36px"
                              onSelect={(selectedItem) => {
                                formik.setFieldValue(
                                  "societyName",
                                  selectedItem.value
                                );
                              }}
                              value={{
                                label: societyDetailsList?.find(
                                  (item) =>
                                    item.id === formik.values.societyName
                                )?.title,
                                value: formik.values.societyName,
                              }}
                              name={"societyName"}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="text-sm w-56 pt-2.5 align-top">
                            Is Urgent ?
                          </td>
                          <td>
                            <div className="w-52 mb-4">
                              <Checkbox
                                text="Yes"
                                className="text-sm"
                                initialchecked={checked}
                                onChange={(e) => {
                                  handleChecked(e, formik.setFieldValue);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-sm w-56 pt-2.5 align-top">
                            Description
                          </td>
                          <td>
                            <div className="occupant-info-editor border border-[#CCC] rounded-md w-full mb-4 -z-100">
                              <Editor
                                editorState={editorState}
                                toolbar={{
                                  options: [
                                    "inline",
                                    "blockType",
                                    "fontSize",
                                    "list",
                                    "textAlign",
                                    "history",
                                    "colorPicker",
                                    "image",
                                  ],
                                  inline: {
                                    inDropdown: false,
                                    options: ["bold", "italic", "underline"],
                                  },
                                  list: { inDropdown: true },
                                  textAlign: { inDropdown: true },
                                  link: { inDropdown: true },
                                  history: {
                                    inDropdown: false,
                                    options: ["redo", "undo"],
                                  },
                                  colorPicker: {
                                    className: undefined,
                                    component: undefined,
                                    popupClassName: undefined,
                                  },
                                  image: {},
                                }}
                                onEditorStateChange={(neweditorState) => {
                                  onEditorStateChange(neweditorState);
                                  formik.setFieldValue(
                                    "description",
                                    draftToHtml(
                                      convertToRaw(
                                        neweditorState.getCurrentContent()
                                      )
                                    )
                                  );
                                }}
                                // contentState={{}}
                                name="description"
                              />
                            </div>
                          </td>
                        </tr>
                        <tr className="text-[#222] text-sm">
                          <td className="text-sm w-56 pt-2.5 align-top">
                            View Attachment:
                          </td>
                          <td>
                            {complaintLoggedTicket?.attachments?.length > 0 ? (
                              <span>
                                <Link
                                  target="_blank"
                                  className="ms-1 underline text-xs text-blue-500"
                                  to={
                                    complaintLoggedTicket.attachments[0]
                                      .attachment_Url
                                  }
                                >
                                  View Uploaded Doc
                                </Link>
                              </span>
                            ) : (
                              <span>-</span>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-sm w-56 pt-2.5 align-top">
                            Attachments
                          </td>
                          <td>
                            <div className="w-52 overflow-hidden mb-5">
                              <UploadButtonFM
                                showFileName={true}
                                name={`attachments`}
                                accept={
                                  "image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                }
                                onSelect={(e) =>
                                  formik.setFieldValue(`attachments`, e)
                                }
                                fileName={formik.values?.attachments?.name}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-sm w-56 pt-2.5 align-top">
                            On Behalf Of
                          </td>
                          <td>
                            <DropdownFM2
                              options={onBehalfOfOptions}
                              width="208px"
                              height="36px"
                              name={"isTenant"}
                              placeholder="Select OnBehalf"
                              onSelect={(selectedItem) => {
                                // debugger;
                                // console.log("ONBehalf:" + selectedItem.value);
                                if (selectedItem.value === 0) {
                                  formik.setFieldValue(
                                    "tenantId",
                                    selectedItem.value
                                  );
                                }
                                formik.setFieldValue(
                                  "isTenant",
                                  selectedItem.value
                                );
                                if (formik.values.wingId) {
                                  let data = {
                                    societyId:
                                      loginData?.default_community
                                        ?.community_id,
                                    wingId: formik.values.wingId,
                                    userType: selectedItem.value
                                      ? "Tenant"
                                      : "Member",
                                  };
                                  dispatch(getFlatsNo(data));
                                }
                                formik.setFieldValue("flatsNo", "");
                              }}
                              value={
                                formik.values.isTenant === ""
                                  ? null
                                  : {
                                      label: onBehalfOfOptions?.find(
                                        (item) =>
                                          item.value === formik.values.isTenant
                                      )?.label,
                                      value: formik.values.isTenant,
                                    }
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="text-sm w-56 pt-2.5 align-top">
                            Member/Tenant Details
                          </td>
                          <td>
                            <div className="flex items-center">
                              <div className="w-52 flex gap-4 mr-4">
                                <DropdownFM2
                                  placeholder="Wing"
                                  name={"wingId"}
                                  className={"me-4"}
                                  options={wingsList.map((item) => ({
                                    label: item.number,
                                    value: item.id,
                                  }))}
                                  width="96px"
                                  height="36px"
                                  onSelect={(selectedItem) => {
                                    formik.setFieldValue(
                                      "wingId",
                                      selectedItem.value
                                    );
                                    let data = {
                                      societyId:
                                        loginData?.default_community
                                          ?.community_id,
                                      wingId: selectedItem.value,
                                      userType: formik.values.isTenant
                                        ? "Tenant"
                                        : "Member",
                                    };
                                    dispatch(getFlatsNo(data));
                                  }}
                                  value={{
                                    label: wingsList?.find(
                                      (item) => item.id === formik.values.wingId
                                    )?.number,
                                    value: formik.values.wingId,
                                  }}
                                />
                                <DropdownFM2
                                  options={flatsList?.map((item) => ({
                                    label: item.flat_no,
                                    value: item.house_unique_id,
                                    subscriber_id: item.subscriber_id,
                                    memberName: item.full_name,
                                    tenant_id: item.tenantid,
                                    tenantName: item.tenant_full_name,
                                  }))}
                                  name={"flatsNo"}
                                  width="96px"
                                  height="36px"
                                  placeholder="Flat/Unit"
                                  onSelect={(selectedItem) => {
                                    formik.setFieldValue(
                                      "flatsNo",
                                      selectedItem.value
                                    );
                                    formik.setFieldValue(
                                      "subscriber_id",
                                      selectedItem.subscriber_id
                                    );
                                    formik.setFieldValue(
                                      "tenantId",
                                      selectedItem.tenant_id
                                    );
                                    formik.setFieldValue(
                                      "memberName",
                                      selectedItem.memberName
                                    );
                                    formik.setFieldValue(
                                      "tenantName",
                                      selectedItem.tenantName
                                    );
                                  }}
                                  value={
                                    formik.values.flatsNo
                                      ? {
                                          label: flatsList?.find(
                                            (item) =>
                                              item.house_unique_id ===
                                              formik.values.flatsNo
                                          )?.flat_no,
                                          value: formik.values.flatsNo,
                                        }
                                      : null
                                  }
                                />
                              </div>
                              <div className="text-[#AAAAAA] font-bold text-xs -mt-3">
                                —{" "}
                                {formik.values.isTenant === "Tenant"
                                  ? dropdownData?.flatsList?.find(
                                      (item) =>
                                        item.house_unique_id ===
                                        formik.values.flatsNo
                                    )?.tenant_full_name
                                  : dropdownData?.flatsList?.find(
                                      (item) =>
                                        item.house_unique_id ===
                                        formik.values.flatsNo
                                    )?.full_name}
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="w-44 py-2 text-sm align-top">
                            Email to:
                          </td>
                          <td className="py-2">
                            <div className="text-sm">
                              <Checkbox
                                text={"Assignee"}
                                className={"w-28"}
                                initialchecked={
                                  formik.values.emailAssignee === 1
                                }
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    `emailAssignee`,
                                    e.target.checked ? 1 : 0
                                  );
                                }}
                              />
                              <Checkbox
                                text={"Owner"}
                                className={"w-28"}
                                initialchecked={formik.values.emailOwner === 1}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    `emailOwner`,
                                    e.target.checked ? 1 : 0
                                  );
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    </Layout>
  );
};

export default EditComplaint;
