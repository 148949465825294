import React from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../pages/Layout/Layout";
import { CONTACT_ENDPOINT } from "../../Routing/routes";
import { TextInput } from "../InputFields/TextInput";
import { TextArea } from "../InputFields/TextArea";
import TextRadio  from "../InputFields/TextRadio";
import Dropdown from "../Dropdown/Dropdown";
import ButtonG from "../Button/ButtonG";
import Button from "../Button/Button";

const AddContact = () => {
  const navigate = useNavigate();

  const handleCancelClick = () => {
    navigate(CONTACT_ENDPOINT);
  };

  const handleSaveClick = () => {
    console.log("Form data saved to the console.");
  };
  const handleTimeOfDayChange = (value) => {
  };

  const timeOfDayOptions = [
    { name: "timeOfDay", value: "day", label: "Day" },
    { name: "timeOfDay", value: "night", label: "Night" },
  ];

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 mb-5">
        <div className="flex justify-between border-b border-[#CCC]  pb-2">
          <h1 className="text-base font-semibold p-1">Add Contact</h1>
          <div className="flex gap-2">
            <ButtonG label="Cancel" onClick={handleCancelClick} />
            <Button label="Save" onClick={handleSaveClick} className="px-5" />
          </div>
        </div>
        <div className="flex justify-between gap-4 p-2">
          <div className="flex flex-col gap-2 w-1/2 pr-5">
            <div className="flex items-center justify-between mb-4 mt-3">
              <span className="text-sm">Contact Type</span>
              <Dropdown
                options={[
                  "Security",
                  "Emergency Contact",
                  "Society AMC Category",
                  "Society Vendor Category",
                ]}
                width="52"
                placeholder="Select Category"
                className="text-xs"
                onSelect={(selectedItem) => {
                  // Handle the selected item here
                }}
              />
            </div>
            <TextInput
              label="Full Name"
              placeholder="Enter Full Name"
              className="w-52"
            />
            <TextInput
              label="Phone Number"
              placeholder="Enter Phone Number"
              className="w-52"
            />
            <TextInput
              label="Email Address"
              placeholder="Enter Email Address"
              className="w-52"
            />
            <div className="w-full">
              <TextArea
                label="Address"
                placeholder="Enter Address"
                className={"w-52"}
              />
            </div>
            
            <div className="my-1"></div>
              <TextRadio
                label="Time of Day"
                options={timeOfDayOptions}
                defaultSelected="day"
                onChange={handleTimeOfDayChange}
                className={'w-52'}
              />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddContact;
