import React, { useEffect, useState } from "react";
import Layout from "../../../Layout/Layout";
import RangeDatePicker from "../../../../components/DatePicker/RangeDatePicker";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import Search from "../../../../components/SearchBox/Search";
import Button from "../../../../components/Button/Button";
import ButtonIco from "../../../../components/Button/ButtonPrint";
import { DownloadExt, UploadEx } from "../../../../assets";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import ManageInvoiceCurrentTable from "../../../../components/TableComponent/MangeInvoiceCurrentTable";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  GENERATE_INVOICE_ENDPOINT,
  MANAGE_INVOICE_CURRENT_ENDPOINT,
  MANAGE_INVOICE_DRAFTS_ENDPOINT,
  MANAGE_INVOICE_PREVIOUS_ENDPOINT,
  UPLOAD_PAYMENT_EXCEL_ENDPOINT,
} from "../../../../Routing/routes";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  downloadManageInvoicePaymentExcelSample,
  getManageInvoiceCurrent,
} from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageInvoiceActions";
import moment from "moment";
import { getInvoiceStatusList } from "../../../../redux/actions/FinanceActions/MyInvoiceActions";
import {
  getFlatDetails,
  getWingsAndFlatDetails,
} from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import {
  getInvoiceTypeList,
} from "../../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";
import ButtonG from "../../../../components/Button/ButtonG";
import { downloadExcel } from "../../../../utils/helpers/downloadMedia";

export const headingsInvoice = [
  { id: "sNo", label: "SNo.", sort: false, width: "40px" },
  { id: "invoiceNo", label: "Invoice No.", sort: true, width: "100px" },
  {
    id: "memberDetails",
    label: "Member Details",
    sort: true,
    width: "140px",
  },
  { id: "invoiceDate", label: "Invoice Date", sort: true, width: "100px" },
  {
    id: "generationDate",
    label: "Generation Date",
    sort: true,
    width: "100px",
  },
  { id: "amount", label: "Amount", sort: true, width: "120px" },
  { id: "status", label: "Status", sort: true, width: "90px" },
  { id: "remarks", label: "Remarks", sort: true, width: "100px" },
  {
    id: "action",
    label: "Action",
    sort: false,
  },
];

const ManageInvoiceCurrent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { manageInvoiceCurrent, loading } = useSelector(
    (state) => state.manageInvoiceReducer
  );
  const { typeLists } = useSelector(
    (state) => state.invoiceSettingReducer
  );
  const { invoiceStatusList } = useSelector((state) => state.myInvoiceReducer);
  const { wingsData, flatsData } = useSelector((state) => state.wingsReducer);
  const [manageInvoiceList, setManageInvoiceList] = useState();
  const [invoiceTypeList, setInvoiceTypeList] = useState([]);
  const handleNavigation = (path) => () => {
    navigate(path);
  };
  const handleSearch = (text) => {
    if (text && text !== searchParams.get("search")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("search");
      navigate({
        pathname: `${MANAGE_INVOICE_CURRENT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["search", text],
        ])}`,
      });
    }
  };

  const handelSelectStatus = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("status");
    navigate({
      pathname: `${MANAGE_INVOICE_CURRENT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["status", selectedItem.value],
      ])}`,
    });
  };
  const handelSelectStatusBy = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("statusby");
    navigate({
      pathname: `${MANAGE_INVOICE_CURRENT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["statusby", selectedItem.value],
      ])}`,
    });
  };
  const handelSelectInvoiceType = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("typeid");
    navigate({
      pathname: `${MANAGE_INVOICE_CURRENT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["typeid", selectedItem.value],
      ])}`,
    });
  };
  const handelSelectAccountStatus = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("accountEntryStatus");
    navigate({
      pathname: `${MANAGE_INVOICE_CURRENT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["accountEntryStatus", selectedItem.value],
      ])}`,
    });
  };

  const handleSelectWing = (selectedItem) => {
    if (selectedItem.value?.toString() !== searchParams.get("wingId")) {
      dispatch(
        getFlatDetails({
          societyId: loginData?.default_community?.community_id,
          wingId: selectedItem?.value,
        })
      );
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("wingId");
      searchParams.delete("flatId");
      navigate({
        pathname: `${MANAGE_INVOICE_CURRENT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["wingId", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleSelectFlat = (selectedItem) => {
    if (selectedItem.value?.toString() !== searchParams.get("flatId")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("flatId");
      navigate({
        pathname: `${MANAGE_INVOICE_CURRENT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["flatId", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleStartDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !==
      searchParams.get("startDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("startDate");
      navigate({
        pathname: `${MANAGE_INVOICE_CURRENT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["startDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleEndDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !== searchParams.get("endDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("endDate");
      navigate({
        pathname: `${MANAGE_INVOICE_CURRENT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["endDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleClear = () => {
    navigate(MANAGE_INVOICE_CURRENT_ENDPOINT);
  };

  const handleDownloadSampleAndFormatExcel = (flag) => {
    const onSucessCallback = (data) => {
      downloadExcel({ data, fileName: `SocietyPayment ${flag}.xlsx` });
    };
    const data = {
      flag,
      onSucessCallback,
    };
    dispatch(downloadManageInvoicePaymentExcelSample(data));
  };

  useEffect(() => {
    let societyId = loginData?.default_community?.community_id;
    dispatch(getWingsAndFlatDetails({ societyId }));
    dispatch(getInvoiceTypeList({
      societyId: loginData?.default_community?.community_id,
    }))
    if (searchParams.get("wingId")) {
      dispatch(
        getFlatDetails({ societyId, wingId: searchParams.get("wingId") })
      );
    }
    dispatch(
      getInvoiceStatusList({
        act: "status",
      })
    );
  }, []);
  const reloadManage = () => {
    dispatch(
      getManageInvoiceCurrent({
        societyId: loginData?.default_community?.community_id,
        page: searchParams.get("page") || 1,
        pageLimit: searchParams.get("pageLimit") || 10,
        invoiceno: searchParams.get("invoiceno") || "",
        status: searchParams.get("status") || "",
        statusby: searchParams.get("statusby") || "",
        from_date: searchParams.get("startDate") || "",
        to_date: searchParams.get("endDate") || "",
        searchBy: searchParams.get("search") || "",
        wingId: searchParams.get("wingId") || "",
        flatId: searchParams.get("flatId") || "",
        member_name: searchParams.get("searchBy") || "",
        from_amount: searchParams.get("from_amount") || "",
        to_amount: searchParams.get("to_amount") || "",
        InvoiceType: searchParams.get("typeid") || 1,
        AccountEntryStatus: searchParams.get("accountEntryStatus") || "",
        is_web: 1,
      }));
  }
  useEffect(() => {
    dispatch(
      getManageInvoiceCurrent({
        societyId: loginData?.default_community?.community_id,
        page: searchParams.get("page") || 1,
        pageLimit: searchParams.get("pageLimit") || 10,
        invoiceno: searchParams.get("invoiceno") || "",
        status: searchParams.get("status") || "",
        statusby: searchParams.get("statusby") || "",
        from_date: searchParams.get("startDate") || "",
        to_date: searchParams.get("endDate") || "",
        searchBy: searchParams.get("search") || "",
        wingId: searchParams.get("wingId") || "",
        flatId: searchParams.get("flatId") || "",
        member_name: searchParams.get("searchBy") || "",
        from_amount: searchParams.get("from_amount") || "",
        to_amount: searchParams.get("to_amount") || "",
        AccountEntryStatus: searchParams.get("accountEntryStatus") || "",
        InvoiceType: searchParams.get("typeid") || 1,
        is_web: 1,
      })
    );
  
  }, [searchParams]);
  useEffect(()=>{
    if(manageInvoiceCurrent){
      setManageInvoiceList(manageInvoiceCurrent)
    }
  },[manageInvoiceCurrent])
  useEffect(()=>{
    if(typeLists){
      setInvoiceTypeList(typeLists)
    }
  },[typeLists])
 
  
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Invoice - Current">
            <div className="flex gap-3 text-sm">
              <button
                onClick={handleNavigation(UPLOAD_PAYMENT_EXCEL_ENDPOINT)}
                className="flex items-center px-2 text-xs font-semibold bg-white border rounded-lg text-red-650 border-red-650 whitespace-nowrap"
              >
                <UploadEx className="mr-2 fill-red-650" /> Upload Payment Excel
              </button>
              {/* <ButtonIco
                icon={DownloadExt}
                children="Download payment Excel"
                onClick={() => {
                  handleDownloadSampleAndFormatExcel("sample");
                }}
              /> */}
              <div className="border-r border-[#CCC]"></div>
              <Button
                label="Previous"
                className="h-8"
                onClick={handleNavigation(MANAGE_INVOICE_PREVIOUS_ENDPOINT)}
              />
              <Button
                label="Draft"
                className="h-8 px-4"
                onClick={handleNavigation(MANAGE_INVOICE_DRAFTS_ENDPOINT)}
              />
              <div className="border-r border-[#CCC]"></div>
              <Button
                label="Generate Invoice"
                onClick={handleNavigation(GENERATE_INVOICE_ENDPOINT)}
              />
            </div>
          </ListHeader>
        </div>

        <div className="flex flex-row justify-between gap-4 p-1 mt-1">
          <Search
            height="8"
            onclick={handleSearch}
            value={searchParams.get("search")}
            placeholder="Search..."
          />
          <div className="flex items-center justify-between gap-3">
            <div className="text-sm">Invoice type</div>
            <Dropdown2
              options={
                invoiceTypeList
                  ? invoiceTypeList?.map(
                      (item) => ({
                        label: item?.invoice_title,
                        value: item?.id,
                      })
                    )
                  : []
              }
              placeholder="Invoice type"
              className="text-[11px] ms-4"
              width="172px"
              height="32px"
              onSelect={(selectedItem) => {
                handelSelectInvoiceType(selectedItem);
              }}
              value={
                searchParams.get("typeid") && {
                  value: searchParams.get("typeid"),
                  label: invoiceTypeList ? invoiceTypeList?.find((item) => item?.id === parseInt(searchParams.get("typeid")))?.invoice_title : "",
                }
              }
            />
          </div>
          <div className="flex items-center justify-between gap-3">
            <div className="text-sm">Search By</div>
            <Dropdown2
              options={
                manageInvoiceCurrent?.searchBy
                  ? Object.entries(manageInvoiceCurrent?.searchBy)?.map(
                      (item) => ({
                        label: item?.[1],
                        value: item?.[0],
                      })
                    )
                  : []
              }
              placeholder="Invoice Date"
              className="text-[11px] ms-4"
              width="172px"
              height="32px"
              onSelect={(selectedItem) => {
                handelSelectStatusBy(selectedItem);
              }}
              value={
                searchParams.get("statusby") && {
                  value: searchParams.get("statusby"),
                  label: manageInvoiceCurrent?.searchBy
                    ? Object.entries(manageInvoiceCurrent?.searchBy)?.find(
                        (item) => item[0] === searchParams.get("statusby"))[1]
                
                    : "",
                }
              }
            />
          </div>
        </div>
        <div className="flex items-center gap-2 p-2">
          <Dropdown2
            options={
              wingsData?.map((item) => ({
                value: item?.wing_id,
                label: item?.number,
              })) || []
            }
            placeholder="Select Wing"
            className="text-[11px] ms-4"
            width="162px"
            height="32px"
            onSelect={(selectedItem) => {
              handleSelectWing(selectedItem);
            }}
            value={
              searchParams.get("wingId") && {
                value: searchParams.get("wingId"),
                label: wingsData?.find(
                  (item) =>
                    item.wing_id?.toString() === searchParams.get("wingId")
                )?.number,
              }
            }
          />
          <Dropdown2
            options={
              (searchParams.get("wingId") &&
                flatsData?.manage_flat_details?.map((item) => ({
                  value: item?.flat_id,
                  label: item?.flat_no,
                }))) ||
              []
            }
            placeholder="Select Flat"
            className="text-[11px] ms-4"
            width="162px"
            height="32px"
            onSelect={(selectedItem) => {
              handleSelectFlat(selectedItem);
            }}
            value={
              searchParams.get("flatId") && {
                value: searchParams.get("flatId"),
                label: flatsData?.manage_flat_details?.find(
                  (item) =>
                    item.flat_id?.toString() === searchParams.get("flatId")
                )?.flat_no,
              }
            }
          />
          <Dropdown2
            options={
              invoiceStatusList?.invoice_status_list
                ? Object.entries(invoiceStatusList.invoice_status_list).map(
                    (item) => ({
                      label: item[1],
                      value: item[0],
                    })
                  )
                : []
            }
            placeholder="Select Status"
            className="text-[11px] ms-4"
            width="162px"
            height="32px"
            onSelect={(selectedItem) => {
              handelSelectStatus(selectedItem);
            }}
            value={
              searchParams.get("status")
                ? {
                    value: searchParams.get("status"),
                    label: invoiceStatusList?.invoice_status_list
                      ? Object.entries(
                          invoiceStatusList.invoice_status_list
                        ).find(
                          (item) => item[0] === searchParams.get("status")
                        )[1]
                      : "",
                  }
                : []
            }
          />

          <Dropdown2
            options={
              manageInvoiceCurrent?.AccountEntryStatusList
                ? Object.entries(
                    manageInvoiceCurrent?.AccountEntryStatusList
                  )?.map((item) => ({
                    label: item?.[1],
                    value: item?.[0],
                  }))
                : []
            }
            placeholder="Account Entry Status"
            className="text-[10px] ms-3"
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              handelSelectAccountStatus(selectedItem);
            }}
            value={
              searchParams.get("accountEntryStatus") && {
                value: searchParams.get("accountEntryStatus"),
                label: manageInvoiceCurrent?.AccountEntryStatusList
                  ? Object.entries(
                      manageInvoiceCurrent?.AccountEntryStatusList
                    )?.find(
                      (item) => item[0] === searchParams.get("accountEntryStatus") 
                    )[1]
                  : [],
              }
            }
          />
          <RangeDatePicker
            className={"w-[190px] h-[32px]"}
            defaultStartValue={
              searchParams.get("startDate")
                ? new Date(searchParams.get("startDate"))
                : ""
            }
            defaultEndValue={
              searchParams.get("endDate")
                ? new Date(searchParams.get("endDate"))
                : ""
            }
            onStartDateChange={handleStartDate}
            onEndDateChange={handleEndDate}
          />
          <div className="border-r border-[#CCC]"></div>
          <ButtonG label="Clear" className="h-8" onClick={handleClear} />
        </div>
        <div>
          <ManageInvoiceCurrentTable
            loading={loading}
            data={manageInvoiceList?.data?.map((item, index) => ({
              sNo:
              manageInvoiceList?.paginate?.current *
              manageInvoiceList?.paginate?.limit +
                1 +
                index,
              id: item.id,
              index: index,
              memberDetails: item?.member?.subscriber_name,
              wingNo: item?.flat?.wing?.number,
              flatNo: item?.flat?.flat_no,
              invoiceNo: item.invoice_no,
              invoiceDate: item.invoice_date
                ? moment(new Date(item?.invoice_date))?.format("DD-MM-YYYY")
                : "-",
              generationDate: item.date
                ? moment(new Date(item?.date))?.format("DD-MM-YYYY")
                : "-",
              amount: item.grandtotal,
              amountPaid: item.amountPaid,
              amountDue: item.dueAmount,
              status: item.row_status,
              remarks: item.remarks,
              ismakePayment: item.ismakePayment,
              paymentLink: item.paymentLink,
              payid : item.payments ? item.payments.map((payment)=> payment.id) : []
            }))}
            columns={headingsInvoice}
            checkbox={true}
            pagination={manageInvoiceList?.data?.length}
            totalCount={manageInvoiceList?.paginate?.total_count}
            totalPages={manageInvoiceList?.paginate?.total_page}
            start={
              manageInvoiceList?.paginate?.current *
              manageInvoiceList?.paginate?.limit +
              1
            }
            end={
              manageInvoiceList?.paginate?.current *
              manageInvoiceList?.paginate?.limit +
              manageInvoiceList?.data?.length
            }
            currentPage={parseInt(manageInvoiceList?.paginate?.current) + 1}
            rowsPerPage={searchParams.get("pageLimit") || 10}
            reloadManage={reloadManage}
          ></ManageInvoiceCurrentTable>

          {!manageInvoiceList?.data?.length && !loading && (
            <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
              No Records
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ManageInvoiceCurrent;
