import React, { useEffect, useState } from "react";
import {
  SocietyProfileData,
  SpecificDetailsData,
} from "../../consts/SocietyProfileData";
import { useDispatch, useSelector } from "react-redux";
import { getSocietyProfile } from "../../redux/actions/SetupRecordsActions/BuildingActions/SocietyProfileAction";
import AccordionTable from "./AccordionTable";
import WingsAccordionTable from "./WingsAccordionTable";
import Loader from "../Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import ProfilePicture from "../ProfilePicture/ProfilePicture";
import convertTime from "../../utils/helpers/convertTime";
import moment from "moment";
import { Link } from "react-router-dom";
import { EDITSOCIETY_PROFILE_ENDPOINT } from "../../Routing/routes";
import * as permissions from '../../Routing/permissions'
import {getPermission} from '../../utils/helpers/getPermissions'

const Accordion = React.memo(
  ({ data, isOpen, toggleAccordion, label, desc, showProfileImg = false, changeProfileImg=true}) => {
    const { societyProfileData } = useSelector((state) => state.societyProfileReducer);
    let apiData = label=== "Profile Information" ? societyProfileData?.profile_data : societyProfileData?.specifiv_details

    return (
      <div className="border rounded-lg border-[#CCCCCC] my-2 overflow-hidden relative">
        {showProfileImg && (
          <div className="absolute top-[50px] text-center right-[55px]">
            <div className="text-sm text-gray-400 font-semibold mb-3">Change Profile Image</div>
            <ProfilePicture direction="col" placeHolder="Change" edit={false} defaultURL={apiData?.logo}/>
            {changeProfileImg && <Link className="mt-2 font-bold text-[13px] text-[#CF001C]" to={EDITSOCIETY_PROFILE_ENDPOINT}>Change</Link>}
          </div>
        )}
        <div
          className="flex justify-between cursor-pointer items-center bg-[#FDF2F3] rounded-t-lg p-3 border-b"
          onClick={toggleAccordion}
        >
          <div className="flex items-center">
            <button className="text-white text-sm font-semibold mr-3 w-5 h-5 bg-red-650 border rounded flex justify-center items-center">
              {isOpen ? (
                <FontAwesomeIcon icon={faChevronUp} size="xs" />
              ) : (
                <FontAwesomeIcon icon={faChevronDown} size="xs" />
              )}
            </button>
            <div className="text-[#000000] text-sm font-semibold">{label}</div>
          </div>
        </div>
        {isOpen && (
          <div className="p-3">
            {data.map((item, i0) => (
              <React.Fragment key={i0}>
                {item.label ? (
                  <div className="acord-section text-sm font-semibold text-[#222222] p-2">
                    {item.label}
                  </div>
                ) : null}
                {item.values.map((rowData, i1) => (
                  <React.Fragment key={i0+i1}>
                    <div className="flex p-2">
                      {rowData.map((fieldData, index) => (
                        <div
                          className="flex w-[45%] text-xs"
                          key={index}
                        >
                          <div className="w-[40%] text-[#AAAAAA] text-[13px]">
                            {(fieldData.key ==='built_up_area' && (apiData['builtup_option'] === 2 ? 'Total Carpet Area': 'Total Built Up Area')) || fieldData.label }:
                          </div>
                          <div className="w-[65%] text-[13px]">
                            <div className="text-justify w-[90%]">
                              {
                                label=== "Profile Information"
                                ?
                                <>
                                {
                                  apiData[fieldData.key]
                                    ? fieldData.label === "Phone" 
                                      ? apiData["mobile_country_code"] + "-" + apiData[fieldData.key]
                                      : fieldData.label === "Club Timing"
                                        ? convertTime( apiData[fieldData.key])
                                        : apiData[fieldData.key]
                                    : "-"
                                }
                                </>
                                :
                                <>
                                {
                                  apiData[fieldData.key] 
                                    ? fieldData.label === "Date of Registration" 
                                      ?  moment(new Date(apiData[fieldData.key])).format('DD MMM, YYYY')
                                      : fieldData.label === "Total Built Up Area" || fieldData.label === "Area of Plot" 
                                        ? apiData[fieldData.key] + ' ' + apiData['const_unit']
                                        : fieldData.label === "Construction Cost Per Unit" 
                                          ? 'Rs. ' +  apiData[fieldData.key] + '/' + apiData['const_unit']
                                          : fieldData.label === "Value Per Share" 
                                            ? 'Rs. ' + apiData[fieldData.key]
                                            : apiData[fieldData.key]
                                    : "-"
                                }
                                </>
                              }
                            </div>
                            <div className="w-1/2"></div>
                          </div>
                        </div>
                      ))}
                    </div>
                    {i0 === 0 && i1 === 6 ? <hr key={`${i0}-${i1}`} /> : null}
                    {i0 === 1 && i1 === 0 ? <hr key={`${i0}-${i1}`} /> : null}
                    {i0 === 2 && i1 === 3 ? <hr key={`${i0}-${i1}`} /> : null}
                    {i0 === 3 && i1 === 3 ? <hr key={`${i0}-${i1}`} /> : null}
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    );
  }
);


const SocietyProfileInfo = () => {
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const dispatch = useDispatch();
  const {loginData } = useSelector((state) => state.loginReducer);
  const {loading, societyProfileData } = useSelector((state) => state.societyProfileReducer);

  const toggleAccordion = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };


  useEffect(() => {
    dispatch(getSocietyProfile());
  }, []);

  return (
    <>
      <div>
        {
          loading && !societyProfileData ?
          <Loader/>
          :
          <>
            {societyProfileData && (
              <>
                <Accordion
                  label={"Profile Information"}
                  key={0}
                  data={SocietyProfileData}
                  isOpen={0 === openAccordionIndex}
                  toggleAccordion={() => toggleAccordion(0)}
                  desc={"Modified By Synapse On Dec 21,2023 06:26 PM"}
                  showProfileImg={getPermission(loginData, [permissions.VIEW_PROFILE_PICTURE_PERMISSION])}
                  changeProfileImg={getPermission(loginData, [permissions.EDIT_PROFILE_PICTURE_PERMISSION])}
                />
                <AccordionTable
                  label="Wings"
                  key={1}
                  isOpen={1 === openAccordionIndex}
                  toggleAccordion={() => toggleAccordion(1)}
                >
                  <WingsAccordionTable additionalProp="value" data={societyProfileData?.wing_data} />
                </AccordionTable>

                <Accordion
                  label={"Specific Details"}
                  key={2}
                  data={SpecificDetailsData}
                  isOpen={2 === openAccordionIndex}
                  toggleAccordion={() => toggleAccordion(2)}
                  desc={"Modified By Synapse On Jun 27,2023 06:26 PM"}
                />
              </>
            )}
          </>
        }
      </div>
    </>
  );
};

export default SocietyProfileInfo;
