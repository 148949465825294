import React, { useEffect } from "react";
import Layout from "../../../Layout/Layout";
import {leaveAndLicenseStatusOptions} from '../../../../consts/DropDownOptions';
import Search from "../../../../components/SearchBox/Search";
import LeaveandLicenseTable from "./LeaveandLicenseTable";
import { DownloadEx } from "../../../../assets";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import RangeDatePicker from "../../../../components/DatePicker/RangeDatePicker";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LEAVE_AND_LICENSE_ENDPOINT } from "../../../../Routing/routes";
import { getFlatDetails, getWingsAndFlatDetails } from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import {downloadLeaveAndLicenseList, getLeaveAndLicenseList} from '../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/LeaveAndLicenseAction'
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import moment from "moment";
import { downloadExcel } from "../../../../utils/helpers/downloadMedia";


export const headingsLeaveandLicense = [
  { id: 'sNo', label: 'S No.', sort: false, width: '44px' },
  { id: 'ticketNo', label: 'Ticket No.', sort: true,width: '61px' },
  { id: 'flatNo', label: 'Flat No.', sort: true, width:'46px'  },
  { id: 'description', label: 'Description', sort: true, width:'100px' },
  { id: 'category', label: 'Category', sort: true, width: '80px' },
  { id: 'loggedBy', label: 'Logged By', sort: true, width:'72px' },
  { id: 'assignedTo', label: 'Assigned To', sort: true, width:'80px'},
  { id: 'servicedBy', label: 'Serviced By', sort: true,width:'80px' },
  { id: 'loggedOn', label: 'Logged On', sort: true, width: '76px' },
  { id: 'lastUpdatedOn', label: 'Last Upated On', sort: true, width: '76px' },
  { id: 'status', label: 'Status', sort: true, width: '65px' },
  { id: 'aging', label: 'Aging', sort: true, width: '65px' },

  {
    id: "action",
    label: "Action",
    sort: false,
  },
];

const LeaveandLicense = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {wingsData,flatsData} = useSelector(state=>state.wingsReducer)
  const {loading, leaveAndLicenseList} = useSelector(state=>state.leaveAndLicenseReducer)
  const {loginData} = useSelector(state=>state.loginReducer)

  const handleSearch = (text)=>{
    if(text && text !== searchParams.get("search")){
      searchParams.delete('page')
      searchParams.delete('pageLimit')
      searchParams.delete("search")
      navigate({
        pathname: `${LEAVE_AND_LICENSE_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["search", text]])}`,
      })
    }
  }

  const handleSelectWing = (selectedItem)=>{
    if(selectedItem.value?.toString() !== searchParams.get("wingId")){
      dispatch(getFlatDetails({societyId: loginData?.default_community?.community_id, wingId: selectedItem?.value}))
      searchParams.delete('page')
      searchParams.delete('pageLimit')
      searchParams.delete("wingId")
      searchParams.delete("flatId")
      navigate({
        pathname: `${LEAVE_AND_LICENSE_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["wingId", selectedItem.value]])}`,
      })
    }
  }
  
  const handleSelectFlat = (selectedItem)=>{
    if(selectedItem.value?.toString() !== searchParams.get("flatId")){
      searchParams.delete('page')
      searchParams.delete('pageLimit')
      searchParams.delete("flatId")
      navigate({
        pathname: `${LEAVE_AND_LICENSE_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["flatId", selectedItem.value]])}`,
      })
    }
  }
  
    const handleStartDate = (selectedDate)=>{
      if(moment(selectedDate).format('YYYY-MM-DD') !== searchParams.get("startDate")){
        searchParams.delete('page')
        searchParams.delete('pageLimit')
        searchParams.delete("startDate")
        navigate({
          pathname: `${LEAVE_AND_LICENSE_ENDPOINT}`,
          search: `?${createSearchParams([...searchParams.entries(), ["startDate", moment(selectedDate).format('YYYY-MM-DD')]])}`,
        })
      }
    }

    const handleEndDate = (selectedDate)=>{
      if(moment(selectedDate).format('YYYY-MM-DD') !== searchParams.get("endDate")){
        searchParams.delete('page')
        searchParams.delete('pageLimit')
        searchParams.delete("endDate")
        navigate({
          pathname: `${LEAVE_AND_LICENSE_ENDPOINT}`,
          search: `?${createSearchParams([...searchParams.entries(), ["endDate", moment(selectedDate).format('YYYY-MM-DD')]])}`,
        })
      }
    }

    const handleStatus = (selectedItem)=>{
      if(selectedItem.value !== searchParams.get("status")){
        searchParams.delete('page')
        searchParams.delete('pageLimit')
        searchParams.delete("status")
        navigate({
          pathname: `${LEAVE_AND_LICENSE_ENDPOINT}`,
          search: `?${createSearchParams([...searchParams.entries(), ["status", selectedItem.value]])}`,
        })
      }
    }

    
  const handleDownloadExcel = ()=>{
    const onSucessCallback = (data)=>{
      downloadExcel({data, fileName: 'leave_and_license_sheet.xlsx'})
    }
    const data = {
      societyId: loginData?.default_community?.community_id,
      searchText: searchParams.get("search") || '',
      status: searchParams.get("status") || '',
      wingId: searchParams.get("wingId") || '',
      flatId: searchParams.get("flatId") || '',
      startDate: searchParams.get("startDate") || '',
      endDate: searchParams.get("endDate") || '',
      onSucessCallback
    }
    dispatch(downloadLeaveAndLicenseList(data))
  }

  const getTableData =() =>{
    const data={
      societyId: loginData?.default_community?.community_id,
      page: searchParams.get("page") || 1,
      searchText: searchParams.get("search") || '',
      pageLimit: searchParams.get("pageLimit") || 10,
      status: searchParams.get("status") || '',
      wingId: searchParams.get("wingId") || '',
      flatId: searchParams.get("flatId") || '',
      startDate: searchParams.get("startDate") || '',
      endDate: searchParams.get("endDate") || '',
    }

    dispatch(getLeaveAndLicenseList(data))
  }

  useEffect(()=>{      
    getTableData()
  }, [searchParams])

  useEffect(()=>{
    dispatch(getWingsAndFlatDetails({societyId: loginData?.default_community?.community_id}))

    if(searchParams.get("wingId")){
      dispatch(getFlatDetails({societyId: loginData?.default_community?.community_id, wingId: searchParams.get("wingId")}))
    }
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 ">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Leave and License">
            <div className="items-center">
              <div className="flex items-center gap-2">
                <p className="text-sm">Status:</p>
                <Dropdown2
                  options={leaveAndLicenseStatusOptions}
                  placeholder="Select status"
                  className="text-[11px] ms-4"
                  width="180px"
                  height="32px"
                  onSelect={(selectedItem) => {
                    handleStatus(selectedItem)
                  }}
                  value={
                    searchParams.get("status") && {
                      value: searchParams.get("status"),
                      label: leaveAndLicenseStatusOptions?.find((item) => item.value?.toString() === searchParams.get("status"))?.label
                    }
                  }
                />
              </div>
            </div>
          </ListHeader>
        </div>
        <div className="flex justify-between items-center p-2">
          <div className="-ml-1">
          <Search height="8" placeholder={'Search by ticket No.'} onclick={handleSearch} value={searchParams.get("search")} />
          </div>
          <div className="flex items-center gap-2">
            <span className="text-xs">Filter</span>
            <Dropdown2
                options={wingsData?.map((item)=>({
                  value: item?.wing_id,
                  label: item?.number
                })) || []}
                placeholder="Select Wing"
                className="text-[11px] ms-4"
                width="180px"
                height="32px"
                onSelect={(selectedItem) => {
                  handleSelectWing(selectedItem)
                }}
                value={
                  searchParams.get("wingId") && {
                    value: searchParams.get("wingId"),
                    label: wingsData?.find((item) => item.wing_id?.toString() === searchParams.get("wingId"))?.number
                  }
                }
              />
              <Dropdown2
                options={searchParams.get('wingId') && flatsData?.manage_flat_details?.map((item)=>({
                  value: item?.flat_id,
                  label: item?.flat_no
                })) || []}
                placeholder="Select Flat"
                className="text-[11px] ms-4"
                width="180px"
                height="32px"
                onSelect={(selectedItem) => {
                  handleSelectFlat(selectedItem)
                }}
                value={
                  searchParams.get("flatId") && {
                    value: searchParams.get("flatId"),
                    label: flatsData?.manage_flat_details?.find((item) => item.flat_id?.toString() === searchParams.get("flatId"))?.flat_no
                  }
                }
              />
            <div className="items-center">
              <RangeDatePicker className="w-44 h-8"
                defaultStartValue={searchParams.get("startDate") ? new Date(searchParams.get("startDate")) : ''}
                defaultEndValue={searchParams.get("endDate") ? new Date(searchParams.get("endDate")) : ''}
                onStartDateChange={handleStartDate}
                onEndDateChange={handleEndDate}
               />
            </div>
          </div>
          <div className="flex gap-3">
            <span className="border-l border-[#CCCCCC]"></span>
            <div className="flex -mr-2">
              <button className="flex text-red-650 border border-red-650 bg-white font-semibold text-xs h-8 px-3 items-center rounded-lg" onClick={handleDownloadExcel}>
                <DownloadEx className="mr-2" /> Download Excel
              </button>
            </div>
          </div>
        </div>
        <div>
        <LeaveandLicenseTable
          loading={loading}
          data={leaveAndLicenseList?.list?.map((item, index)=>({
            sNo: (((leaveAndLicenseList?.current_page_no - 1) * leaveAndLicenseList?.page_limit + 1)+index)?.toString()?.padStart(2, "0"),
            id: item?.id || '-',
            ticketNo: item?.ticket_no || '-',
            flatNo: item?.flat_no || '-',
            description: item?.description || '-',
            category: item?.category || '-',
            loggedBy: item?.logged_by_name || '-',
            assignedTo: item?.assigned_to_name || '-',
            servicedBy: item?.serviced_by || '-',
            loggedOn: item?.logged_on ? moment(new Date(item?.logged_on))?.format('DD-MM-YYYY hh:mm A') : '-',
            lastUpdatedOn: item?.last_updated_on ? moment(new Date(item?.last_updated_on))?.format('DD-MM-YYYY hh:mm A') : '-',
            lastTimeActive: item?.last_activity_time ? moment(new Date(item?.last_activity_time))?.format('DD-MM-YYYY hh:mm A') : '-',
            status: item?.ticket_status || '-',
            aging: `${item?.days ? `${item?.days} Days ` : ''}${item?.hours ? `${item?.hours} Hours ` : ''}${item?.minutes ? `${item?.minutes} Min` : ''}` || '-',
          }))}
          columns={headingsLeaveandLicense}
          checkbox={false}
          pagination={leaveAndLicenseList?.list?.length}
          totalCount={leaveAndLicenseList?.total_record}
          totalPages={leaveAndLicenseList?.total_pages}
          start={(leaveAndLicenseList?.current_page_no - 1) * leaveAndLicenseList?.page_limit + 1}
          end={(leaveAndLicenseList?.current_page_no - 1) * leaveAndLicenseList?.page_limit + leaveAndLicenseList?.list?.length}
          currentPage={leaveAndLicenseList?.current_page_no}
          rowsPerPage={searchParams.get("pageLimit") || 10}
          getTableData={getTableData}
        ></LeaveandLicenseTable>
        {!leaveAndLicenseList?.list?.length && !loading && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
        </div>
      </div>
    </Layout>
  );
};

export default LeaveandLicense;
