import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import "./sideNav.css";
import Profile from "../../assets/icons/Intersection 4@2x.png";

import {tabData, quickData} from "./sideNavData.jsx";
import { useSelector } from "react-redux";
import { getPermission } from "../../utils/helpers/getPermissions.js";

const SideNavbar = () => {
  const location = useLocation();
  const {loginData} = useSelector(state=>state.loginReducer)

  return (
    <div className="px-4 md:px-8 lg:px-0">
      <div className="flex">
        <div className="border rounded-lg border-[#CCC] outline-1 w-full">
          <div className=" bg-white rounded-lg">
            <div className="flex flex-col items-center p-4 pb-2 border-b-2 border-[#CCC]">
              <img src={loginData?.default_community?.profile_photo_url || Profile} alt="Profile" className="h-32 w-32 rounded object-cover" />
              <Link to={"/home"} className="font-semibold text-xl mt-2 text-center hover:text-red-650 hover:underline" >
                {loginData?.default_community?.community_name}
              </Link>

              <span className="border-b mt-2 border-black-900 opacity-1"></span>
            </div>

            <div className="mt-4 leading-9 pb-2 border-b-2 border-[#CCC]">
              {tabData.map((tab, index) => (
                (!tab.permissionPath || (tab.permissionPath && getPermission(loginData, tab.permissionPath))) ?
                  <NavLink
                    key={index}
                    to={tab.route}
                    className={`act ${
                      location.pathname === tab.route ? "active-link" : ""
                    }`}
                  >
                    <span className={`flex items-center px-4 cursor-pointer hover:bg-[#CF001C0A] ${tab.apiImplemented ? '' : 'text-gray-200'}`}>
                      <tab.Icon className="h-6 w-5 mr-4" />
                      {tab.text}
                    </span>
                  </NavLink>
                : <></>
              ))}
            </div>
            <span className="border-b-4 mt-4"></span>

            {/* Quick Links */}
            <div className="mt-4 leading-9 pb-2">
              <h5 className="text-[#AAAAAA] text-left px-5">Quick Links</h5>
              {quickData.map((tab, index) => (
                (!tab.permissionPath || (tab.permissionPath && getPermission(loginData, tab.permissionPath))) ?
                <NavLink
                  key={index}
                  to={tab.route}
                  className={`act ${
                    location.pathname === tab.route  ? "active-link" : ""
                  }`}
                >
                  <span className={`flex items-center px-4 cursor-pointer hover:bg-[#CF001C0A] ${tab.apiImplemented ? '' : 'text-gray-200'}`}>
                    <tab.Icon className="h-6 w-5 mr-3" />
                    {tab.text}
                  </span>
                </NavLink>
                :
                <></>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideNavbar;
