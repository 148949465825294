import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import "../../../components/TableComponent/Table.css";
import {
  AuditIcon,
  DeleteIcon,
  EditIcon,
  EditRateChartIcon,
  MakeBookingIcon,
  SortIcon,
  TimeSlotIcon,
  ViewIcon,
} from "../../../assets";
import styled from "@emotion/styled";
import {
  stableSort,
  getComparator,
} from "../../../utils/helpers/universalFunctions";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { Tooltip } from "@mui/material";
import Loader from "../../../components/Loader/Loader";
import ItemsPerPage from "../../../components/ItemsPerPage/ItemsPerPage";
import Pagination from "../../../components/Pagination/Pagination";
import DeleteModal from "../../../components/Modal/DeleteModal";
import {
  ALL_FACILITY_ENDPOINT,
  EDIT_FACILITY_ENDPOINT,
  EDIT_RATE_CHART_ENDPOINT,
  EDIT_TIME_SLOT_ENDPOINT,
  MAKE_BOOKING_ENDPOINT,
  VIEW_FACILTIY_AUDIT_LOGS_ENDPOINT,
  VIEW_FACILTIY_DETAILS_ENDPOINT,
} from "../../../Routing/routes";
import { changeFacilityStatus, deleteFacility } from "../../../redux/actions/ClubActions/BookAFacilityAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ChangeStatusModal from "../../../components/Modal/ChangeStatusModal";
import { getPermission } from "../../../utils/helpers/getPermissions";
import * as permissions from '../../../Routing/permissions'

function ActionComponent({data }) {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const {facilityId, booking, chargeable, status} = data
  const dispatch = useDispatch()
  const {updateLoader} = useSelector(state=>state.bookAFacilityReducer)
  const {loginData} = useSelector(state=>state.loginReducer)
  const [searchParams, setSearchParams] = useSearchParams()
  const {ipAddress} = useSelector(state=>state.ipAddressReducer)

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };
  const handleNavigation = (path) => () => {
    navigate(path);
}

const handleDelete = () => {
  dispatch(deleteFacility({
    facilityId,
    ipAddress,
    sessionId: loginData?.session_id,
    societyId: loginData?.default_community?.community_id,
    page: searchParams.get("page") || 1,
    searchText: searchParams.get("search") || '',
    pageLimit: searchParams.get("pageLimit") || 10,
    onSuccessCallback: ()=>{setShowDeleteModal(false)}
  }))
  ;
};

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
        {
          getPermission(loginData, [permissions.VIEW_FACILITY_PERMISSION]) &&
          <Tooltip title="View" arrow>
            <ViewIcon
              className="fill-[#555] hover:fill-red-650 me-2.5"
              onClick={handleNavigation(`${VIEW_FACILTIY_DETAILS_ENDPOINT}/${facilityId}`)}
            />
          </Tooltip>
        }
      {
        getPermission(loginData, [permissions.EDIT_FACILITY_PERMISSION]) &&
        <Tooltip title="Edit" arrow>
          <EditIcon
            className="fill-[#555] hover:fill-red-650 me-2.5"
            onClick={handleNavigation(`${EDIT_FACILITY_ENDPOINT}/${facilityId}`)}
          />
        </Tooltip>
      }
      {
        getPermission(loginData, [permissions.VIEW_FACILITY_PERMISSION]) &&
        <Tooltip title="Audit Icon" arrow>
          <AuditIcon
            className="fill-[#555] hover:fill-red-650 me-2.5"
            onClick={handleNavigation(`${VIEW_FACILTIY_AUDIT_LOGS_ENDPOINT}/${facilityId}`)}        />
        </Tooltip>
      }
      {(booking === 'Applicable' && status==='Active') && (
        <>
          {
            getPermission(loginData, [permissions.MAKE_BOOKING_PERMISSION]) &&
            <Tooltip title="Make Booking" arrow>
              <MakeBookingIcon
                className="fill-[#555] hover:fill-red-650 me-2.5"
                onClick={handleNavigation(`${MAKE_BOOKING_ENDPOINT}/${facilityId}`)}
              />
            </Tooltip>
          }
          {
            getPermission(loginData, [permissions.EDIT_TIME_SLOT_PERMISSION]) &&
            <Tooltip title="Edit Time Slot" arrow>
              <TimeSlotIcon
                className="fill-[#555] hover:fill-red-650 me-2.5"
                onClick={handleNavigation(`${EDIT_TIME_SLOT_ENDPOINT}/${facilityId}`)}
              />
            </Tooltip>
          }
        </>
      )}
      {
        getPermission(loginData, [permissions.EDIT_RATE_CHART_PERMISSION]) &&
        <>
          {(booking === 'Applicable' && chargeable==='Yes' && status==='Active') && (
            <Tooltip title="Edit Rate Chart" arrow>
              <EditRateChartIcon
                className="fill-[#555] hover:fill-red-650 me-3"
                onClick={handleNavigation(`${EDIT_RATE_CHART_ENDPOINT}/${facilityId}`)}
              />
            </Tooltip>
          )}
        </>
      }
      {
        getPermission(loginData, [permissions.DELETE_FACILITY_PERMISSION]) &&
        <Tooltip title="Delete" arrow>
          <DeleteIcon
            className="fill-[#555] hover:fill-red-650 me-2.5"
            onClick={handleDeleteClick}
          />
        </Tooltip>
      }
      <DeleteModal
      loading={updateLoader}
        isOpen={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onDelete={handleDelete}
      />
    </Box>
  );
}

const TableRowStyled = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #ffffff;
  }
  &:nth-of-type(even) {
    background-color: #f4f5f8;
  }
  &:nth-of-type(odd):hover {
    background-color: #ffffff;
  }
  &:nth-of-type(even):hover {
    background-color: #f4f5f8;
  }
`;

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    zIndex: 0,
    color: "#AAAAAA",
    fontSize: "13px",
    padding: "12px 8px 12px 0",
    wordBreak: "break-word",
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
    fontSize: "13px",
    padding: "12px 8px 12px 0",
    wordBreak: "break-word",
    cursor: "pointer",
  },
}));

const AllFacilityTable = ({
  loading,
  data,
  columns,
  checkbox = true,
  pagination = true,

  //props are required if pagination is true
  totalCount,
  totalPages,
  start,
  end,
  currentPage,
  rowsPerPage,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const [selected, setSelected] = useState([]);
  const [showChangeStatusModal, setShowChangeStatusModal] = useState({id: '', open: false});
  const {updateLoader} = useSelector(state=>state.bookAFacilityReducer)
  const {loginData} = useSelector(state=>state.loginReducer)

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    const newSelected = event.target.checked
      ? data.map((item) => item.facilityId)
      : [];
    setSelected(newSelected);
  };

  const handleClick = (event, id) => {
    const newSelected = selected.includes(id)
      ? selected.filter((selectedId) => selectedId !== id)
      : [...selected, id];
    setSelected(newSelected);
  };

  const handleChangePage = (newPage) => {
    searchParams.delete("page")
    navigate({
      pathname: `${ALL_FACILITY_ENDPOINT}`,
      search: `?${createSearchParams([...searchParams.entries(), ["page", newPage]])}`,
    });
  };

  const handleChangeRowsPerPage = (value) => {
    if (rowsPerPage !== value) {
      searchParams.delete("pageLimit")
      searchParams.delete("page")
      navigate({
        pathname: `${ALL_FACILITY_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["pageLimit", value]])}`,
      });
    }
  };

  const isSelected = (id) => selected.includes(id);

  const handleChangeStatus = (id, status)=>{
    let data={
      facilityId: id, 
      status: status==='Active' ? 'I' : 'A',
      onRequestEndCallback: ()=>{
        setShowChangeStatusModal(false)
      }
    };
    dispatch(changeFacilityStatus(data))
  }


  return (
    <Box sx={{ width: "100%", fontSize: "13px" }} className="table-component">
      <TableContainer
        className="border-t border-[#CCC]"
        sx={{ minHeight: data?.length ? "500px" : "none" }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {!!checkbox && (
                <TableCellStyled
                  size="small"
                  sx={{ pl: "0 !important", pr: 2, width: 36 }}
                >
                  <Checkbox
                    size="small"
                    indeterminate={false}
                    checked={
                      data?.length > 0 && selected?.length === data?.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all",
                    }}
                    sx={{
                      p: 0,
                      color: "#AAAAA",
                      "&.Mui-checked": {
                        color: "#CF001C",
                      },
                    }}
                    disabled={loading || !data}
                  />
                </TableCellStyled>
              )}
              {columns?.map((column) => (
                <TableCellStyled
                  size="small"
                  key={column.id + "heading"}
                  align={column.numeric ? "right" : "left"}
                  sortDirection={orderBy === column.id ? order : false}
                  sx={{
                    width: column.width || "fit-content",
                    minWidth: column.width || "fit-content",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={() => {
                      if (column.sort) {
                        handleRequestSort(column.id);
                      }
                    }}
                    IconComponent={SortIcon}
                    sx={{
                      ".MuiTableSortLabel-icon": {
                        opacity: "1 !important",
                      },
                      svg: {
                        flexShrink: 0,
                      },
                    }}
                    hideSortIcon={!column.sort}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCellStyled>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCellStyled
                  sx={{ border: "none" }}
                  colSpan={columns?.length + 1}
                >
                  <Loader />
                </TableCellStyled>
              </TableRow>
            ) : (
              <>
                {!!data?.length &&
                  stableSort(data, getComparator(order, orderBy))?.map(
                    (row, rowIndex) => {
                      const isRowSelected = isSelected(row?.id);

                      return (
                        <TableRowStyled
                          key={row?.facilityId}
                          role="checkbox"
                          aria-checked={isRowSelected}
                          tabIndex={-1}
                          selected={isRowSelected}
                        >
                          {!!checkbox && (
                            <TableCellStyled
                              size="small"
                              sx={{ pl: "0 !important", zIndex: 0 }}
                              onClick={(event) =>
                                handleClick(event, row?.facilityId)
                              }
                            >
                              <Checkbox
                                checked={selected?.includes(
                                  row?.facilityId
                                )}
                                onChange={(e) =>
                                  handleClick(e, row?.facilityId)
                                }
                                size="small"
                                color="primary"
                                inputProps={{
                                  "aria-labelledby": `generic-table-checkbox-${row?.id}`,
                                }}
                                sx={{
                                  p: 0,
                                  color: "#AAAAA",
                                  "&.Mui-checked": {
                                    color: "#CF001C",
                                  },
                                }}
                              />
                            </TableCellStyled>
                          )}

                          {/* Serial Number */}
                          <TableCellStyled
                            size="small"
                            align="left"
                            onClick={(event) => handleClick(event)}
                          >
                            {row?.sNo}
                          </TableCellStyled>
                          <TableCellStyled size="small" align="left">
                            {row?.facilityName}
                          </TableCellStyled>
                          <TableCellStyled size="small" align="left">
                            {row?.booking}
                          </TableCellStyled>
                          <TableCellStyled size="small" align="left">
                            {row?.chargeable}
                          </TableCellStyled>
                          <TableCellStyled size="small" align="left">
                            <div 
                              onClick={getPermission(loginData, [permissions.CHAGE_FACILITY_STATUS_PERMISSION]) ? ()=> setShowChangeStatusModal({id: row.facilityId, open: true}) : undefined} 
                              className={`underline font-semibold ${row.status==='Active' ? 'text-green-600' : (row.status === 'Inactive' ? 'text-red-500' : 'text-yellow-500')}`} >
                            {
                                row.status
                            }
                            </div>
                            <ChangeStatusModal
                                isOpen={showChangeStatusModal.open && row.facilityId === showChangeStatusModal.id}
                                onCancel={()=>setShowChangeStatusModal(false)}
                                onConfirm={()=>{handleChangeStatus(row.facilityId, row?.status)}}
                                text={`Do you want to change status to ${row?.status==='Active' ? 'Inactive' : 'Active'} ?`}
                                loading={updateLoader}
                              />
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            <ActionComponent data={row} />
                          </TableCellStyled>
                        </TableRowStyled>
                      );
                    }
                  )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!!pagination && data && (
        <div className="flex flex-row justify-between mt-3 pt-1 border-t border-[#ccc] text-[14px]">
          <ItemsPerPage
            rowsPerPage={rowsPerPage}
            onChange={handleChangeRowsPerPage}
          />
          <Pagination
            totalCount={totalCount}
            totalPages={totalPages}
            start={start}
            end={end}
            currentPage={currentPage}
            onChange={handleChangePage}
          />
        </div>
      )}
    </Box>
  );
};

AllFacilityTable.propTypes = {
  columns: PropTypes.array.isRequired,
  defaultSortField: PropTypes.string,
  rowsPerPageOptions: PropTypes.array,
};

export default AllFacilityTable;
