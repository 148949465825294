export const errorMsg = {
  INVALID_ACCESS_TOKEN: [
    "jwt malformed",
    "Invalid access token or may be not found.",
    "invalid token",
    "Expired token",
    "Token not provided",
    "Syntax error, malformed JSON",
    "Wrong number of segments",
    "Malformed UTF-8 characters",
  ],
};
