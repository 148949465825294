import moment from "moment";
import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const PaymentStructure = ({calulatedData}) => {
  const location = useLocation()
  const {state} = location;
  const {facilityDetails} = useSelector(state=>state.bookAFacilityReducer)

  return (
    <div>
      <table className="w-full border-collapse border-2 border-[#222]">
        <thead>
          <tr>
            <th
              colSpan="2"
              className="border-b-2 border-r-2 border-[#222] w-2/5 text-left"
            >
              Item Description
            </th>
            <th className="border-b-2 border-r-2 border-[#222]">
              Unit Price(Rs.)
            </th>
            <th className="border-b-2 border-r-2 border-[#222]">No. Of Bookings</th>
            <th className="border-b-2 border-[#222]">Amount(Rs.)</th>
          </tr>
          <tr>
            <td
              colSpan="2"
              className="border-b border-r-2 border-[#222] p-1 text-sm"
              height="30"
            >
              Booking Frequency : {state.frequency}
            </td>
            <td className="text-center border-r-2 border-[#222]">
              {
                facilityDetails?.chargeable==='Yes' ?
                calulatedData?.charge
                :
                '0.00'
              }
            </td>
            <td className="text-center border-r-2 border-[#222]">
              {state?.noOfBooking}
            </td>
            <td className="text-center border-r-2 border-[#222]">
              {
                facilityDetails?.chargeable==='Yes' ?
                (state?.noOfBooking * calulatedData?.charge)?.toFixed(2)
                :
                '0.00'              
              }
            </td>
          </tr>
          <tr>
            <td
              colSpan="2"
              className="border-b border-r-2 border-[#222] text-sm p-1"
            >
              {
                state.frequency  === 'One Time' ?
                <>
                  Date Of Booking: {moment(state.dateOfBooking)?.format('DD/MM/YYYY')}
                </>
                :
                <>
                  Date Range: From: {moment(state.fromDate)?.format('DD/MM/YYYY')} To: {moment(state.toDate)?.format('DD/MM/YYYY')}
                </>
              }

            </td>
            <td className="text-center border-r-2 border-[#222]"></td>
            <td className="text-center border-r-2 border-[#222]"></td>
            <td className="text-center border-r-2 border-[#222]"></td>
          </tr>
          <tr className="border-b-2 border-[#222]">
            <td
              colSpan="2"
              className="border-b border-r-2 border-[#222] p-1 text-sm"
              >
              Time Slot : {state.slotName}
            </td>
            <td className="text-center border-r-2 border-[#222] border-b-2"></td>
            <td className="text-center border-r-2 border-[#222] border-b-2"></td>
            <td className="text-center border-r-2 border-[#222] border-b-2"></td>
          </tr>
            {
              state.slotType  === 'hours' &&
              <tr className="border-b-2 border-[#222]">
                <td
                  colSpan="2"
                  className="border-b border-r-2 border-[#222] p-1 text-sm"
                  >
                Time Range: {`${moment(state?.fromTime, 'hh:mm').format('hh:mm A')} - ${moment(state?.toTime, 'hh:mm').format('hh:mm A')}`}
              </td>
              <td className="text-center border-r-2 border-[#222] border-b-2"></td>
              <td className="text-center border-r-2 border-[#222] border-b-2"></td>
              <td className="text-center border-r-2 border-[#222] border-b-2"></td>
            </tr>
            }
        </thead>
        <tbody>
          <tr>
            <td colSpan="2" className="border-r-2 border-[#222]">
              &nbsp;
            </td>
            <td className="text-right border-r-2 border-[#222] border-b-2 text-sm px-2" colSpan="2" >Sub Total</td>
            <td className="text-center border-r-2 border-[#222] border-b-2">
              {
                facilityDetails?.chargeable==='Yes' ?
                (state?.noOfBooking * calulatedData?.charge)?.toFixed(2)
                :
                '0.00'              
              }
            </td>
          </tr>
          <tr>
            <td colSpan="2" className="border-r-2 border-[#222]">
              &nbsp;
            </td>
            <td className="text-right border-r-2 border-[#222] border-b-2 text-sm px-2" colSpan="2" >Security Deposit Amount</td>
            <td className="text-center border-r-2 border-[#222] border-b-2">{calulatedData?.securityDeposit}</td>
          </tr>
          <tr>
            <td colSpan="2" className="border-r-2 border-[#222]">
              &nbsp;
            </td>
            <td className="text-right border-r-2 border-[#222] border-b-2 text-sm px-2" colSpan="2" >Grand Total</td>
            <td className="text-center border-r-2 border-[#222] border-b-2">
              {
                facilityDetails?.chargeable==='Yes' ?
                ((state?.noOfBooking * calulatedData?.charge)+ parseFloat(calulatedData?.securityDeposit))?.toFixed(2)
                :
                calulatedData?.securityDeposit          
              }
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PaymentStructure;
