import { notify } from "../../../../utils/notification";
import apiEndpoints from "../../../api";
import axiosInstance from "../../../axiosInstance";
import { logOut } from "../../../../utils/helpers/logOut";
import { errorMsg } from "../../../../consts/responseMessages";

export async function getComplaintEscalationSettingsReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.VIEW_ESCALATION_SETTINGS_API}?society_id=${action.data.societyId}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function updateSpecificDetailsReq(action) {
  const BODY = {
    action: action.data?.action,
    level: action.data?.escalationlevel,
    duration: action.data?.duration,
    id: action.data?.id,
  };

  return axiosInstance
    .put(`${apiEndpoints.EDIT_ESCALATION_SETTINGS_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 400) {
        notify("error", errors.response.data.message);
      } else if (errors.response.data.statusCode === 401) {
        notify("error", errors.response.data.message);
        logOut();
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function getComplaintEmailSmsReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.VIEW_EMAIL_AND_SMS_SETTINGS_API}?society_id=${action.data.societyId}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function updateComplaintEmailSmsReq(action) {
  console.log(action.data);
  const BODY = {
    society_id: action.data?.societyId,
    feature_type: action.data?.featureTypeName,
    status: action.data?.status,
  };

  return axiosInstance
    .post(`${apiEndpoints.EDIT_EMAIL_AND_SMS_SETTINGS_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 400) {
        notify("error", errors.response.data.message);
      } else if (errors.response.data.statusCode === 401) {
        notify("error", errors.response.data.message);
        logOut();
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function getComplaintDashboardContentReq(action) {
  const BODY = {
    society_id: action.data?.societyId,
    page: action.data.page,
    limit: action.data.pageLimit,
    filter_text: action.data.searchText,
    filter_status: action.data.status,
    filter_category: action.data.category,
    filter_level: action.data.level,
    from_date: action.data.startDate,
    to_date: action.data.endDate,
  };

  return axiosInstance
    .post(`${apiEndpoints.COMPLAINT_DASHBOARD_CONTENT_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function getComplaintListReq(action) {
  const BODY = {
    society_id: action.data?.societyId,
    page: action.data.page,
    limit: action.data.pageLimit,
    filter_text: action.data.searchText,
    filter_status: action.data.status,
    filter_category: action.data.category,
    filter_level: action.data.level,
    from_date: action.data.startDate,
    to_date: action.data.endDate,
  };

  return axiosInstance
    .post(`${apiEndpoints.COMPLAINT_DASHBOARD_LIST_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function getComplaintAgingListReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.COMPLAINT_AGING_LIST_API}?society_id=${action.data.societyId}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function getLoggedTicketDetailReqs(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.VIEW_COMPLAINT_LOGGED_DETAILS_API}?id=${action.data.ticketId}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

// assigned complaint

export async function getAssignedReqRolesReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.GET_REQ_ROLES_API}?society_id=${action.data.societyId}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function getAssignedSubReqRolesReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.GET_REQ_SUB_ROLES_API}?society_id=${action.data.societyId}&role_type=${action.data.roleType}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function getAssignedMembersListReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.ASSIGNED_USER_LIST_ACC_VENDORS_API}?society_id=${action.data.societyId}&sub_role_type_id=${action.data.reqSubRole}&role_type=${action.data.roleType}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function getAssignedComplaintDataReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.GET_ASSIGNED_COMPLAINT_DASHBOARD_API}?complaint_id=${action.data.complaintId}&society_id=${action.data.societyId}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function addAssignedComplaintDataReq(action) {
  const BODY =
    action.data.reAssign === "employee"
      ? {
        is_employee: 1,
        society_id: action.data?.societyId,
        complaint_id: action?.data?.complaintId,
        assigned_to: action?.data?.assignedMember,
        status: action?.data?.status,
        assigned_title: action?.data?.reqSubRole,
        assigned_type: action?.data?.reqRole,
        serviced_by: action?.data?.servicedBy,
        comment: action?.data?.writeComment,
        email_assignee: action?.data?.emailAssignee,
        email_owner: action?.data?.emailOwner,
      }
      : {
        is_employee: 0,
        society_id: action.data?.societyId,
        complaint_id: action?.data?.complaintId,
        selected_flat_id: action?.data?.flatsNo,
        status: action?.data?.status,
        selected_society_id: action?.data?.societyName,
        selected_subscriber_id: action?.data?.subscriber_id,
        serviced_by: action?.data?.memberName,
        comment: action?.data?.writeComment,
        email_assignee: action?.data?.emailAssignee,
        email_owner: action?.data?.emailOwner,
      };
  return axiosInstance
    .post(`${apiEndpoints.ADD_ASSIGNED_COMPLAINT_API}`, { ...BODY })

    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 400) {
        notify("error", errors.response.data.message);
      } else if (errors.response.data.statusCode === 401) {
        notify("error", errors.response.data.message);
        logOut();
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function changeComplaintStatusReq(action) {
  const BODY = {
    society_id: action.data?.societyId,
    complaint_id: action?.data?.complaintId,
    status: action.data?.status,
  };

  return axiosInstance
    .put(apiEndpoints.CHANGE_COMPLAINT_STATUS_API, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 400) {
        notify("error", errors.response.data.message);
      } else if (errors.response.data.statusCode === 401) {
        notify("error", errors.response.data.message);
        logOut();
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function downloadComplaintsExcelSheetReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.DOWNLOAD_COMPLAINT_EXCEL_API}?society_id=${action.data?.societyId}`,
      {
        responseType: "arraybuffer",
      }
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function getPostCommentReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.VIEW_POST_COMMENT_API}?complaint_id=${action.data.complaintId}&society_id=${action.data.societyId}`
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function addPostCommentReq(action) {
  const BODY = {
    society_id: action.data?.societyId,
    complaint_id: action?.data?.complaintId,
    comment: action?.data?.comment,
    email_assignee: action?.data?.emailAssignee,
    email_owner: action?.data?.emailOwner,
    status: action?.data?.status,
    attachments: [null],
  };
  return axiosInstance
    .post(`${apiEndpoints.ADD_POST_COMMENT_API}`, { ...BODY })

    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 400) {
        notify("error", errors.response.data.message);
      } else if (errors.response.data.statusCode === 401) {
        notify("error", errors.response.data.message);
        logOut();
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function getComplaintStatusReq(action) {
  return axiosInstance
    .get(`${apiEndpoints.GET_COMPLAINT_STATUS_API}`)
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}


export async function getSocietyListReq(action) {
  return axiosInstance
    .get(`${apiEndpoints.GET_SOCIETY_LIST_API}?society_id=${action.data.societyId}`)
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errors.response?.data.message === "invalid token"
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function addComplaintDashboardReviewReq(action) {
  const BODY = {
    complaint_id: action.data.complaint_id,
    review_comment: action.data.review_comment.replace(/\s{2,}/g, ' ').trim(),
    review_rating: action.data.review_rating,
  };

  return axiosInstance
    .post(`${apiEndpoints.ADD_COMPLAINT_DASHBOARD_REVIEW_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}