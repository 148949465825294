import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { getMemberRegisterDetails } from '../../../redux/actions/ComplianceActions/RegistersAction'
import Loader from '../../../components/Loader/Loader'
import moment from 'moment'
import { validateDate } from '../../../utils/helpers/universalFunctions'

function PrintIForm() {
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const {loading, memberRegisterDetails} = useSelector(state=>state.registersReducer)

    useEffect(()=>{
        dispatch(getMemberRegisterDetails({ids: searchParams.get("ids")}))
    }, [])

    
  useEffect(() => {
    if(!loading && memberRegisterDetails){
      window.print()
    }
  }, [loading]);

  return loading ? (
    <Loader />
  ) : (
    <div  className=" p-3 w-[990px] mx-auto">
      <table className="table-fixed w-full mx-auto">
        <caption className="caption-top mb-10 mt-4">
          <div className="underline font-extrabold text-lg uppercase mb-2">Form "I"</div>
          <div className="font-bold mb-5 text-sm">(See Rule 32 and 65[1])</div>
          <div className="font-bold text-lg text-red-500 mb-5 underline">REGISTER OF MEMBERS</div>
          <div className="font-bold mb-3 text-sm">(Section)</div>
        </caption>

        <tbody className='text-sm align-top'>
            <tr className=''>
                <td className='p-1.5 w-[50%] font-semibold'>Serial Number	</td>
                <td className='p-1.5 w-[50%]'>1</td>
            </tr>
            <tr className=''>
                <td className='p-1.5 w-[50%] font-semibold'> Date of admission	</td>
                <td className='p-1.5 w-[50%]'>{validateDate(memberRegisterDetails?.date_of_admission) ? moment(memberRegisterDetails?.date_of_admission)?.format('DD-MM-YYYY') : '-'}</td>
            </tr>
            <tr className=''>
                <td className='p-1.5 w-[50%] font-semibold'>Date of payment of entrance fee</td>
                <td className='p-1.5 w-[50%]'>{validateDate(memberRegisterDetails?.date_of_entrance_fee) ? moment(memberRegisterDetails?.date_of_entrance_fee)?.format('DD-MM-YYYY') : '-'}</td>
            </tr>
            <tr className=''>
                <td className='p-1.5 w-[50%] font-semibold'>Full name	</td>
                <td className='p-1.5 w-[50%]'>{memberRegisterDetails?.occupant_names || '-'}</td>
            </tr>
            <tr className=''>
                <td className='p-1.5 w-[50%] font-semibold'>Address	</td>
                <td className='p-1.5 w-[50%]'>{memberRegisterDetails?.flat_address || '-'}</td>
            </tr>
            <tr className=''>
                <td className='p-1.5 w-[50%] font-semibold'>Occupation	</td>
                <td className='p-1.5 w-[50%]'>{memberRegisterDetails?.profession || '-'}</td>
            </tr>
            <tr className=''>
                <td className='p-1.5 w-[50%] font-semibold'> Age on the date of admission	</td>
                <td className='p-1.5 w-[50%]'>{memberRegisterDetails?.age_on_date_of_admission || '-'}</td>
            </tr>
            <tr className=''>
                <td className='p-1.5 w-[50%] font-semibold'>Full Name and address of the person nominated by the Member under Section 30 (1)</td>
                <td className='p-1.5 w-[50%]'>
                    Name: {memberRegisterDetails?.nominee}
                    <br />
                    Address: {memberRegisterDetails?.nominee_address}
                </td>
            </tr>
            <tr className=''>
                <td className='p-1.5 w-[50%] font-semibold'> Date of nomination	</td>
                <td className='p-1.5 w-[50%]'>{validateDate(memberRegisterDetails?.date_of_nomination) ? moment(memberRegisterDetails?.date_of_nomination)?.format('DD-MM-YYYY') : '-'}</td>
            </tr>
            <tr className=''>
                <td className='p-1.5 w-[50%] font-semibold'> Date of cessation of membership	</td>
                <td className='p-1.5 w-[50%]'>{validateDate(memberRegisterDetails?.date_of_cessation) ? moment(memberRegisterDetails?.date_of_cessation)?.format('DD-MM-YYYY') : '-'}</td>
            </tr>
            <tr className=''>
                <td className='p-1.5 w-[50%] font-semibold'> Reasons for cessation </td>
                <td className='p-1.5 w-[50%]'>{memberRegisterDetails?.reason_for_cessation || '-'}</td>
            </tr>
            <tr className=''>
                <td className='p-1.5 w-[50%] font-semibold'> Remarks</td>
                <td className='p-1.5 w-[50%]'>{memberRegisterDetails?.remarks || '-'}</td>
            </tr>
        </tbody>
      </table>

      <table className="table-fixed text-sm mt-3 w-full mx-auto border border-black">
        <thead>
            <tr className=''>
                <th className='p-1.5 text-red-500 border border-black w-[50%] font-semibold' colSpan={8}>Particulars Of Shares Held</th>
            </tr>
            <tr className='bg-[#DDDDDD] font-semibold'>
                <th className='p-1.5 border border-black w-[10%]'>Date</th>
                <th className='p-1.5 border border-black w-[10%]'>Cash Book Folio	</th>
                <th className='p-1.5 border border-black w-[10%]'>Application	</th>
                <th className='p-1.5 border border-black w-[10%]'>Allotment	</th>
                <th className='p-1.5 border border-black w-[10%]'>
                    Amount Received On
                    <br />
                    1st call
                    <br />
                    2nd call	
                </th>
                <th className='p-1.5 border border-black w-[10%]'>Total Amount Received	</th>
                <th className='p-1.5 border border-black w-[10%]'>No. Of Shares Held	</th>
                <th className='p-1.5 border border-black w-[10%]'>Sr. No. Of Shares Certificates	</th>
            </tr>
        </thead>

        <tbody className='text-sm align-top'>
           {
            memberRegisterDetails?.share_held?.map((item, index)=>(
                <tr key={index}>
                    <td className='p-1.5  border border-black w-[10%]'>{validateDate(item?.date) ? moment(item?.date)?.format('DD-MM-YYYY') : '-'}</td>
                    <td className='p-1.5  border border-black w-[10%]'>{item?.share_held_cash_book_folio || '-'}</td>
                    <td className='p-1.5  border border-black w-[10%]'>{item?.share_held_application || '-'}</td>
                    <td className='p-1.5  border border-black w-[10%]'>{item?.share_held_allotment || '-'}</td>
                    <td className='p-1.5  border border-black w-[10%]'>
                        {validateDate(item?.share_held_date) ? moment(item?.share_held_date)?.format('DD-MM-YYYY') : '-'}
                        <br />
                        {item?.share_held_amount_received_on_2nd || '-'}
                    </td>
                    <td className='p-1.5  border border-black w-[10%]'>{item?.share_held_amount_received || '-'}</td>
                    <td className='p-1.5  border border-black w-[10%]'>{item?.sr_no_of_share_held || '-'}</td>
                </tr>
            ))
           }
        </tbody>
      </table>

      <table className="table-fixed text-sm mt-3 w-full mx-auto border border-black">
        <thead>
            <tr className=''>
                <th className='p-1.5 text-red-500 border border-black w-[50%] font-semibold' colSpan={10}>Particulars Of Shares Tranferred Or Surrendered</th>
            </tr>
            <tr className='bg-[#DDDDDD] font-semibold'>
                <th className='p-1.5 border border-black w-[10%]'>Date</th>
                <th className='p-1.5 border border-black w-[10%]'>Cash Book Folio	</th>
                <th className='p-1.5 border border-black w-[10%]'>Date	</th>
                <th className='p-1.5 border border-black w-[10%]'>Cash Book Folio Or Share Transfer Register No		</th>
                <th className='p-1.5 border border-black w-[10%]'>No. Of Shares Transferred</th>
                <th className='p-1.5 border border-black w-[10%]'>Sr. No. Of Shares Certificates</th>
                <th className='p-1.5 border border-black w-[10%]'>No. Of Shares Transferred Or Refunded	</th>
                <th className='p-1.5 border border-black w-[10%]' colSpan={3}>Balance</th>
            </tr>
            <tr className='bg-[#DDDDDD] font-semibold'>
                <th className='p-1.5 border border-black w-[10%]' colSpan={7}></th>
                <th className='p-1.5 border border-black w-[10%]'>No. Of Shares Held	</th>
                <th className='p-1.5 border border-black w-[10%]'>Sr. No. Of Shares Certificates	</th>
                <th className='p-1.5 border border-black w-[10%]'>Amount</th>
            </tr>
        </thead>

        <tbody className='text-sm align-top'>
           {
            memberRegisterDetails?.share_transferred?.map((item, index)=>(
                <tr key={index}>
                    <td className='p-1.5  border border-black w-[10%]'>{validateDate(item?.share_transferred_date) ? moment(item?.share_transferred_date)?.format('DD-MM-YYYY') : '-'}</td>
                    <td className='p-1.5  border border-black w-[10%]'>{item?.share_transferred_cash_book_folio || '-'}</td>
                    <td className='p-1.5  border border-black w-[10%]'>{validateDate(item?.share_transferred_date2) ? moment(item?.share_transferred_date2)?.format('DD-MM-YYYY') : '-'}</td>
                    <td className='p-1.5  border border-black w-[10%]'>{item?.cash_book_folio_transfer_no || '-'}</td>
                    <td className='p-1.5  border border-black w-[10%]'>{item?.no_of_share_transferred || '-'}</td>
                    <td className='p-1.5  border border-black w-[10%]'>{item?.sr_no_of_share_transferred || '-'}</td>
                    <td className='p-1.5  border border-black w-[10%]'>{item?.no_of_share_transferred || '-'}</td>
                    <td className='p-1.5  border border-black w-[10%]'>{item?.no_of_share_held_transferred || '-'}</td>
                    <td className='p-1.5  border border-black w-[10%]'>{item?.sr_no_of_share_held_transferred || '-'}</td>
                    <td className='p-1.5  border border-black w-[10%]'>{item?.share_transferred_amount || '-'}</td>
                </tr>
            ))
           }
        </tbody>
      </table>
    </div>
  );
}

export default PrintIForm