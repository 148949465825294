import React, { useEffect, useState } from "react";
import EmployeeReportRecordsTable from "./EmployeeReportRecordsTable";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { EMPLOYEE_LOGIN_REPORT_ENDPOINT, EMPLOYEE_RECORDS_ENDPOINT, PRINT_EMPLOYEE_RECORDS_ENDPOINT } from "../../../../Routing/routes";
import Layout from "../../../Layout/Layout";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import Button from "../../../../components/Button/Button";
import ButtonIco from "../../../../components/Button/ButtonPrint";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getEmployeeNameList, getEmployeeReportRecord } from "../../../../redux/actions/SetupRecordsActions/StaffManagementActions/EmployeeManagementAction";
import moment from "moment";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import RangeDatePicker from "../../../../components/DatePicker/RangeDatePicker";
import ButtonG from "../../../../components/Button/ButtonG";

export const headingsReport = [
  { id: 'sNo', label: 'S No.', sort: false, width: '60px' },
  { id: 'type', label: 'Type Of Report', sort: true,width: '120px' },
  { id: 'name', label: 'Name', sort: true, width: '120px'  },
  { id: 'reportDate', label: 'Report Date', sort: true, width:'100px' },
  { id: 'ipAddress', label: 'IP Address', sort: true, width: '100px' },
];

const EmployeeReportRecords = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {loading, employeeReportRecord, employeeNameList} = useSelector(state=>state.employeeManagementReducer)
  const {loginData} = useSelector(state=>state.loginReducer)
  const {ipAddress} = useSelector(state=>state.ipAddressReducer)
  const [filter, setFilter] = useState({
    empId: searchParams.get("empId") || "",
    startDate: searchParams.get("startDate") || "",
    endDate: searchParams.get("endDate") || "",
  })

  const handleBackClick = () => {
    navigate(EMPLOYEE_LOGIN_REPORT_ENDPOINT);
  };

  const handlePrint = () => {
    window.open(`${PRINT_EMPLOYEE_RECORDS_ENDPOINT}?${createSearchParams([...searchParams.entries()])}`)
  };


  const handleSelectName = (selectedItem)=>{
    setFilter(prev=> { return {...prev, empId: selectedItem.value}})
  }

  const handleStartDate = (selectedDate)=>{
    setFilter(prev=> { return {...prev, startDate: moment(selectedDate).format('YYYY-MM-DD')}})
  }

  const handleEndDate = (selectedDate)=>{
    setFilter(prev=> { return {...prev, endDate: moment(selectedDate).format('YYYY-MM-DD')}})
  }

  const onSubmit= ()=>{
    navigate({
      pathname: `${EMPLOYEE_RECORDS_ENDPOINT}`,
      search: `?${createSearchParams([...(Object.entries(filter))])}`,
  })
  }

  const handleClear = ()=>{
    navigate(EMPLOYEE_RECORDS_ENDPOINT)
  }

  const getTableData = ()=>{
    const data = {
      empId: searchParams.get("empId") || "",
      startDate: searchParams.get("startDate") || "",
      endDate: searchParams.get("endDate") || "",
      page: searchParams.get("page") || 1,
      pageLimit: searchParams.get("pageLimit") || 10,
      societyId: loginData?.default_community?.community_id,
      ipAddress,
      sessionId: loginData?.session_id
    }
    setFilter({
      empId: searchParams.get("empId") || "",
      startDate: searchParams.get("startDate") || "",
      endDate: searchParams.get("endDate") || "",
    })
    dispatch(getEmployeeReportRecord(data))
  }

  useEffect(()=>{
    dispatch(getEmployeeNameList({societyId: loginData?.default_community?.community_id}))
  }, [])



  useEffect(()=>{
    getTableData()
  }, [searchParams])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack
            onClick={handleBackClick}
            title="Employee Report Records"
          ></ListHeaderBack>
        </div>
        <div className="flex justify-between items-center p-2">
          <div className="flex items-center gap-3 ">
            <span className="text-sm">User Name</span>
              <Dropdown2
                options={employeeNameList?.map((item)=>({
                  value: item?.emp_id,
                  label: item?.emp_name
                })) || []}
                placeholder="Select User Name"
                className="text-[11px] ms-4"
                width="180px"
                height="32px"
                onSelect={(selectedItem) => {
                  handleSelectName(selectedItem)
                }}
                value={
                  filter.empId && {
                    value: filter.empId,
                    label: employeeNameList?.find((item) => item.emp_id?.toString() === filter.empId?.toString())?.emp_name
                  }
                }
              />
            <span className="text-sm">DateRange</span>
              <RangeDatePicker className="w-44 h-8"
                defaultStartValue={filter.startDate ? new Date(filter.startDate) : ''}
                defaultEndValue={filter.endDate ? new Date(filter.endDate) : ''}
                onStartDateChange={handleStartDate}
                onEndDateChange={handleEndDate}
               />
            <Button label="Search" onClick={onSubmit} className="h-8" />
            <ButtonG label='Clear' className='h-8' onClick={handleClear} />
          </div>
          <div className="flex gap-2">
            <span className="border-l border-[#CCCCCC]"></span>
            <div className="flex gap-2 font-semibold">
              <ButtonIco
                icon={faPrint}
                onClick={handlePrint}
                children="Print"
              />
            </div>
          </div>
        </div>
        <div>
        <EmployeeReportRecordsTable
          loading={loading}
          data={employeeReportRecord?.list?.map((item, index)=>({
            sNo: (((employeeReportRecord?.current_page_no - 1) * employeeReportRecord?.page_limit + 1)+index)?.toString()?.padStart(2, "0"),
            id: item?.emp_id || '-',
            type: item?.type || '-',
            name: item?.name || '-',
            reportDate: item?.date? moment(item?.date)?.format('DD-MM-YYYY hh:mm A') : '-',
            ipAddress: item?.ip ||  '-',
          }))}
          columns={headingsReport}
          checkbox={false}
          pagination={employeeReportRecord?.list?.length}
          totalCount={employeeReportRecord?.total_record}
          totalPages={employeeReportRecord?.total_pages}
          start={(employeeReportRecord?.current_page_no - 1) * employeeReportRecord?.page_limit + 1}
          end={(employeeReportRecord?.current_page_no - 1) * employeeReportRecord?.page_limit + employeeReportRecord?.list?.length}
          currentPage={employeeReportRecord?.current_page_no}
          rowsPerPage={searchParams.get("pageLimit") || 10}
          getTableData={getTableData}
        ></EmployeeReportRecordsTable>
        {!employeeReportRecord?.list?.length && !loading && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
        </div>
      </div>
    </Layout>
  );
};

export default EmployeeReportRecords;
