import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TENANTS_INFO_ENDPOINT } from "../../../../Routing/routes";
import Layout from "../../../Layout/Layout";
import Button from "../../../../components/Button/Button";
import { TextInputFM } from "../../../../components/InputFields/TextInput";
import ButtonG from "../../../../components/Button/ButtonG";
import { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import  { UploadButtonFM } from "../../../../components/Button/UploadButton";
import { Form, Formik } from "formik";
import { RenewFormValidation } from "../../../../validationSchema/setupRecordsSchema/occupants&TenantsSchema";
import { useDispatch, useSelector } from "react-redux";
import { DropdownFM2 } from "../../../../components/Dropdown/dropdown2";
import { getFlatDetails, getWingsAndFlatDetails } from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import moment from "moment";
import countries from "../../../../consts/countries";
import { getTenantsDetails, renewTenantDetail } from "../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/TenantsInfoAction";
import { CircularProgress } from "@mui/material";
import Loader from "../../../../components/Loader/Loader";
import { validateDate } from "../../../../utils/helpers/universalFunctions";

const RenewTenancy = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {id} = useParams();
  const {wingsData,flatsData} = useSelector(state=>state.wingsReducer)
  const {loginData} = useSelector(state=>state.loginReducer)
  const {ipAddress} = useSelector(state=>state.ipAddressReducer)
  const {loading, updateLoader, tenantDetails} = useSelector(state=>state.tenantInfoReducer)

  const handleCancelClick = () => {
    navigate(TENANTS_INFO_ENDPOINT);
  };

  const handleSaveClick = async(values, {resetForm}) => {
    dispatch(renewTenantDetail({
      ...values, 
      societyId: loginData?.default_community?.community_id, 
      sessionId: loginData?.session_id, 
      ipAddress
    }))
  };

  useEffect(()=>{
    dispatch(getTenantsDetails({tenantId: id}))
    const societyId= loginData?.default_community?.community_id
    dispatch(getWingsAndFlatDetails({societyId}))
  }, [])

  useEffect(()=>{
    if(tenantDetails?.wing_id){
      dispatch(getFlatDetails({societyId: loginData?.default_community?.community_id, wingId: tenantDetails?.wing_id}))
    }
  }, [tenantDetails])

  return (
    <Layout>
      <div className="flex flex-col gap-2 w-full">
        <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg p-2">
        <Formik
          initialValues={{
            tenantId: tenantDetails?.tenant_id,
            firstName: tenantDetails?.usr_fname || '',
            middleName: tenantDetails?.usr_mname || '',
            lastName: tenantDetails?.usr_lname || '',
            mobileCountryCode: tenantDetails?.mobile_country_code,
            countryISO: countries?.find(item=> item.phonecode?.replace('+','') === tenantDetails?.mobile_country_code)?.iso,
            countryId: countries?.find(item=> item.phonecode?.replace('+','') === tenantDetails?.mobile_country_code)?.id,
            mobileNo: tenantDetails?.contact_no|| '-',
            alternateMobileNo: tenantDetails?.alternate_contact_no || '',
            email: tenantDetails?.email ||  '',
            wingId: tenantDetails?.wing_id ||  '',
            flatId: tenantDetails?.flat_id ||  '',
            adharCardNo: tenantDetails?.uidcard_no || '',
            panCardNo: tenantDetails?.pancard_no || '',
            rentDeedStartDate: tenantDetails?.start_date || '',
            rentDeedEndDate: tenantDetails?.end_date || '',
            rentAgreementFile:  ''
          }}
        enableReinitialize
        onSubmit={handleSaveClick}
          validationSchema={RenewFormValidation}
          >    
            {
              ({values, dirty, setFieldValue})=>{
                return (
                  <Form>
                    <div className="flex justify-between border-b border-[#CCC] pb-2">
                      <h1 className="text-base font-semibold p-1">Renew Tenancy</h1>
                      <div className="flex gap-2">
                        <ButtonG
                          label="Back"
                          onClick={handleCancelClick}
                          className="px-5 h-8"
                          type={'button'}
                        />
                        <Button
                          label={
                            <span className="w-12 inline-block">
                              {
                                updateLoader ?
                                  <CircularProgress sx={{color: "white"}} size={17}/>
                                  :
                                  'Update'
                                }
                            </span>
                            } 

                          className="px-5 h-8"
                          type={'submit'}
                          disabled={loading || updateLoader || !dirty}
                        />
                      </div>
                    </div>
                    <div className="flex gap-4 p-2">
                      <div className="flex flex-col justify-between w-full gap-3">
                        <div className="flex justify-between w-full gap-3 min-h-[540px]">
                      {
                        loading && id !== tenantDetails?.tenant_id?.toString()?
                        <Loader/>
                        :
                          <div className="w-1/2 gap-2 pr-5">
                          <TextInputFM
                            label="First Name"
                            placeholder="Enter First Name"
                            className="w-56"
                            name='firstName'
                            maxLength={25}
                          />
                          <TextInputFM
                            label="Middle Name"
                            placeholder="Enter Middle Name"
                            className="w-56"
                            name='middleName'
                            maxLength={25}
                          />
                          <TextInputFM
                            label="Last Name"
                            placeholder="Enter Last Name"
                            className="w-56"
                            name='lastName'
                            maxLength={25}
                          />
                          <div className="flex justify-between mb-2">
                            <span className="text-sm mt-2">Wing Number</span>
                            <DropdownFM2
                              options={wingsData?.map((item)=>({
                                value: item?.wing_id,
                                label: item?.number
                              })) || []}
                              width={"224px"}
                              className="text-xs"
                              placeholder={"Select Wing"}
                              onSelect={(selectedItem)=>{
                                setFieldValue(`wingId`, selectedItem.value)
                                setFieldValue('flatId', '')
                                dispatch(getFlatDetails({societyId: loginData?.default_community?.community_id, wingId: selectedItem?.value}))
                              }}
                              height='38px'
                              name={`wingId`}
                              value={{
                                value: values.wingId,
                                label: wingsData?.find(item=> item.wing_id === values.wingId)?.number
                              }}
                            />
                          </div>
                          <div>
                            <div className="flex justify-between mb-1">
                              <span className="text-sm mt-2">Flat Number</span>
                              <DropdownFM2
                                options={values.wingId && flatsData?.manage_flat_details?.map((item)=>({
                                  value: item?.flat_id,
                                  label: item?.flat_no
                                })) || []}
                                width={"224px"}
                                className="text-xs"
                                placeholder={"Select Flat/Unit"}
                                onSelect={(selectedItem)=>{
                                  setFieldValue(`flatId`, selectedItem.value)
                                }}
                                height='38px'
                                name={`flatId`}
                                value={values.wingId && {
                                  value: values.flatId,
                                  label: flatsData?.manage_flat_details?.find(item=> item.flat_id === values.flatId)?.flat_no
                                }}
                              />
                            </div>
                          </div>
                          <div className="flex flex-row justify-between">
                            <span className="text-sm mt-2">Rent Deed Start Date</span>
                            <div>
                            <DatePickerComponentFM
                              className="w-56 h-9 ml-4 justify-between"
                              onDateChange={(selectedDate) => {
                                setFieldValue('rentDeedStartDate', selectedDate)
                                if(validateDate(values.rentDeedEndDate) && moment(values.rentDeedEndDate).isBefore(new Date(selectedDate)))
                                      setFieldValue(`rentDeedEndDate`, '')
                              }}
                              name='rentDeedStartDate'
                              defaultValue={values.rentDeedStartDate ? new Date(values.rentDeedStartDate) : null}
                            />
                            </div>
                          </div>
                          <div className="flex flex-row justify-between">
                            <span className="text-sm mt-2">Rent Deed End Date</span>
                            <div>
                            <DatePickerComponentFM  
                              className="w-56 h-9 ml-4 justify-between" 
                              name={'rentDeedEndDate'}
                              onDateChange={(selectedDate) => {
                                setFieldValue('rentDeedEndDate', selectedDate)
                              }}
                              defaultValue={values.rentDeedEndDate ? new Date(values.rentDeedEndDate) : null}
                              readOnly={!values.rentDeedStartDate}
                              {...(values.rentDeedStartDate && {minDate: new Date(moment(values.rentDeedStartDate).add(1, 'days'))})}
                            />
                            </div>
                          </div>
                          <div className="flex justify-between mb-1">
                            <span className="text-sm">Rent Agreement Upload</span>
                            <div className="w-1/2 -mr-1">
                              <UploadButtonFM showFileName={true} 
                                name={`rentAgreementFile`}
                                accept={"image/jpeg,image/gif,image/png,application/pdf,image/x-eps"}
                                onSelect={(e)=>setFieldValue(`rentAgreementFile`, e)} 
                                fileName={values?.rentAgreementFile?.name}
                                />
                            </div>
                          </div>
                          <TextInputFM
                            label="Email"
                            placeholder="Enter Email"
                            className="w-56"
                            name='email'
                          />

                          <div className="flex justify-between ">
                            <span className="text-sm mt-2">Country</span>
                            <DropdownFM2
                              options={ countries?.map((item)=>({
                                label: `${item?.name?.toUpperCase()} (${item?.phonecode})`,
                                value: item?.phonecode?.replace("+",""),
                                iso: item.iso,
                                id: item?.id
                              })) || []}
                              width={"224px"}
                              className="text-xs"
                              placeholder={"Select Country"}
                              onSelect={(selectedItem)=>{
                                setFieldValue(`mobileCountryCode`, selectedItem.value)
                                setFieldValue(`countryId`, selectedItem.id)
                                setFieldValue(`countryISO`, selectedItem.iso)
                              }}
                              height='38px'
                              name={`mobileCountryCode`}
                              value={{
                                label: `${countries?.find(item=> item.phonecode?.replace('+','') === tenantDetails?.mobile_country_code)?.name?.toUpperCase()} (+${values.mobileCountryCode})`,
                                value: values.mobileCountryCode,
                                iso: values.countryISO,
                                id: values.countryId
                              }}
                            />
                          </div>

                          <TextInputFM
                            label="Contact No."
                            placeholder="Enter Contact"
                            className="w-56"
                            name='mobileNo'
                            type='number'
                            maxLength={12}
                          />

                          <TextInputFM
                            label="Alternate Contact No."
                            placeholder="Enter Alternate Contact"
                            className="w-56"
                            name='alternateMobileNo'
                            type='number'
                            maxLength={12}
                          />    
                          <TextInputFM
                            label="UID Card No."
                            placeholder="Enter UID"
                            className="w-56"
                            name='adharCardNo'
                            type='number'
                            maxLength={12}
                          /> 
                            
                          <TextInputFM
                            label="PAN Card No."
                            placeholder="Enter PAN"
                            className="w-56"
                            name='panCardNo'
                            type='alphaNumber'
                            maxLength={10}
                          /> 
                          </div>
                      }
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              }
          </Formik>
        </div>
      </div>
    </Layout>
  );
};

export default RenewTenancy;
