import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import "./Table.css";
import { SortIcon } from "../../assets";
import styled from "@emotion/styled";
import { NumberInput } from "../InputFields/NumberInput";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";
import { TEST_ENDPOINT } from "../../Routing/routes";
import CreditNoteandAdvanceModal from "../../pages/Finance/BillingManagement/ManageInvoice/CreditNoteAndAdvance";

const TableRowStyled = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #ffffff;
  }
  // &:nth-of-type(even) {
  //   background-color: #f4f5f8;
  // }
  &:nth-of-type(odd):hover {
    background-color: #ffffff;
  }
  // &:nth-of-type(even):hover {
  //   background-color: #f4f5f8;
  // }
`;

const GenerateInvoiceTable = ({
  Calculation,
  children,
  data,
  headData,
  setInvoiceData,
  index,
  columns,
  checkbox = true,
  pagination = true,
  setAnyChecked,

  getTableData,
}) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);

  const [selected, setSelected] = useState([]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    const newSelected = event.target.checked ? data.map((item) => item.id) : [];
    setSelected(newSelected);
  };

  const handleClick = (event, id) => {
    const newSelected = selected.includes(id)
      ? selected.filter((selectedId) => selectedId !== id)
      : [...selected, id];
    setSelected(newSelected);
  };

  const isSelected = (id) => selected.includes(id);

  const [checkedData, setCheckedData] = useState({});

  const checkedInputData = (key) => {
    if (checkedData.hasOwnProperty(key)) return checkedData[key];
    else return false;
  };

  const handleCheckedData = (key) => (isChecked) => {
    setCheckedData((prev) => ({ ...prev, [key]: isChecked }));
  };

  const navigate = useNavigate();

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const [creditNoteAndAdvanceModal, setCreditNoteAndAdvanceModal] = useState(false);
  const [credAdvId, setCredAdvId] = useState(null);

  const openModal = (id) => {
    setCreditNoteAndAdvanceModal(true);
    setCredAdvId(id)
  };

  const closeModal = () => {
    setCreditNoteAndAdvanceModal(false);
  };
  
  const handleCalculate = (invvoicedata,index) => {
    var data1 = Calculation(invvoicedata,index)
    Object.assign(data, data1);
    setInvoiceData({...data})
  }

  return (
    <Box
      sx={{ width: "100%", height: "67%", fontSize: "13px" }}
      className="table-component"
    >
      <TableContainer
        className="border-t border-[#CCC]"
        sx={{ minHeight: "500px" }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {!!checkbox && (
                <TableCell
                  size="small"
                  sx={{ py: 1.5, pl: 0, pr: 2, zIndex: 0, width: 36 }}
                >
                  <Checkbox
                    size="small"
                    indeterminate={false}
                    checked={data.length > 0 && selected.length === data.length}
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all",
                    }}
                    sx={{
                      p: 0,
                      color: "#AAAAA",
                      "&.Mui-checked": {
                        color: "#CF001C",
                      },
                    }}
                  />
                </TableCell>
              )}
              {columns.map((column) => (
                <TableCell
                  size="small"
                  key={column.id}
                  align={column.numeric ? "right" : "left"}
                  sortDirection={orderBy === column.id ? order : false}
                  sx={{
                    pl: 0,
                    pr: 1,
                    fontWeight: 600,
                    zIndex: 0,
                    color: "#AAAAAA",
                    wordWrap: "break-word",
                    fontSize: "13px",
                    width: column.width || "fit-content",
                    minWidth: column.width || "fit-content",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={() => {
                      if (column.sort) {
                        handleRequestSort(column.id);
                      }
                    }}
                    IconComponent={SortIcon}
                    sx={{
                      ".MuiTableSortLabel-icon": {
                        opacity: "1 !important",
                      },
                      svg: {
                        flexShrink: 0,
                      },
                    }}
                    hideSortIcon={!column.sort}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data[index]?.invoicedetail?.mainhead ? Object.entries(data[index]?.invoicedetail?.mainhead).map((key) => {
              return (
                <TableRowStyled
                  key={key[1].id}
                  role="checkbox"
                  tabIndex={-1}
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell
                    size="small"
                    key="name"
                    align="left"
                    sx={{
                      borderBottom: "none",
                      fontSize: "13px",
                      pl: 0,
                      pr: 1,
                      wordBreak: "break-word",
                      width: "150px",
                    }}
                  >
                    {key[1]?.name}
                  </TableCell>

                  <TableCell
                    size="small"
                    key="amount"
                    align="left"
                    sx={{
                      borderBottom: "none",
                      fontSize: "13px",
                      pl: 0,
                      pr: 1,
                      wordBreak: "break-word",
                    }}
                  >
                    <NumberInput
                      placeholder="Enter Amount"
                      className="w-[200px]"
                      value={key[1]?.value || 0}
                      type="number"
                      onChange={ (e) => {
                        data[index].invoicedetail.mainhead[key[0]].value = !isNaN(e.target.value) ? parseFloat(e.target.value) : 0;
                        handleCalculate(data,index)
                      }}
                    />
                  </TableCell>
                </TableRowStyled>
              );
            }) : <></>}
            { data[index]?.invoicedetail?.morehead ? Object.entries(data[index]?.invoicedetail?.morehead).map((key) => {
              return (
                <TableRowStyled
                  key={key[1].id}
                  role="checkbox"
                  tabIndex={-1}
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell
                    size="small"
                    key="name"
                    align="left"
                    sx={{
                      borderBottom: "none",
                      fontSize: "13px",
                      pl: 0,
                      pr: 1,
                      wordBreak: "break-word",
                      width: "150px",
                    }}
                  >
                    {key[1]?.name}
                  </TableCell>

                  <TableCell
                    size="small"
                    key="amount"
                    align="left"
                    sx={{
                      borderBottom: "none",
                      fontSize: "13px",
                      pl: 0,
                      pr: 1,
                      wordBreak: "break-word",
                    }}
                  >
                    <NumberInput
                      placeholder="Enter Amount"
                      className="w-[200px]"
                      value={key[1]?.value || 0}
                      type="decimal"
                      onChange={(e) => {
                        data[index].invoicedetail.morehead[key[0]].value = !isNaN(e.target.value) ? parseFloat(e.target.value) : 0;
                        handleCalculate(data,index)
                      }}
                    />
                    {typeof key[1]?.utility_error !== 'undefined' ? <p>{key[1]?.utility_error.map(key => key)}</p> : <></>}
                    
                  </TableCell>
                </TableRowStyled>
              );
            }) : <></> }
            {data[index]?.invoicedetail?.roundoff ? 
            <TableRowStyled
              role="checkbox"
              tabIndex={-1}
              sx={{ cursor: "pointer" }}
            >
              <TableCell
                size="small"
                key="name"
                align="left"
                sx={{
                  borderBottom: "none",
                  fontSize: "13px",
                  pl: 0,
                  pr: 1,
                  wordBreak: "break-word",
                  width: "150px",
                }}
              >

                {data[index]?.invoicedetail?.roundoff?.name}
              </TableCell>

              <TableCell
                size="small"
                key="amount"
                align="left"
                sx={{
                  borderBottom: "none",
                  fontSize: "13px",
                  pl: 0,
                  pr: 1,
                  wordBreak: "break-word",
                }}
              >
                <NumberInput
                  placeholder="Enter Amount"
                  className="w-[200px]"
                  value={data[index]?.invoicedetail?.roundoff?.value}
                  type="decimal"
                  disabled="true"
                />
              </TableCell>
            </TableRowStyled> : <></>}
            <TableRowStyled
              role="checkbox"
              tabIndex={-1}
              sx={{ cursor: "pointer" }}
            >
              <TableCell
                size="small"
                key="name"
                align="left"
                sx={{
                  borderBottom: "none",
                  fontSize: "13px",
                  pl: 0,
                  pr: 1,
                  wordBreak: "break-word",
                  width: "150px",
                }}
              >
                Subtotal
              </TableCell>

              <TableCell
                size="small"
                key="amount"
                align="left"
                sx={{
                  borderBottom: "none",
                  fontSize: "13px",
                  pl: 0,
                  pr: 1,
                  wordBreak: "break-word",
                }}
              >
                <NumberInput
                  placeholder="Enter Amount"
                  className="w-[200px]"
                  value={data[index]?.invoicedetail?.subtotal}
                  type="decimal"
                  disabled="true"
                />
              </TableCell>
            </TableRowStyled>
            {data[index]?.invoicedetail?.arearhead ? Object.entries(data[index]?.invoicedetail?.arearhead).map(
              (key) => {
                return (
                  <TableRowStyled
                    key={key[1].id}
                    role="checkbox"
                    tabIndex={-1}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell
                      size="small"
                      key="name"
                      align="left"
                      sx={{
                        borderBottom: "none",
                        fontSize: "13px",
                        pl: 0,
                        pr: 1,
                        wordBreak: "break-word",
                        width: "150px",
                      }}
                    >
                      {key[1]?.name}
                    </TableCell>

                    <TableCell
                      size="small"
                      key="amount"
                      align="left"
                      sx={{
                        borderBottom: "none",
                        fontSize: "13px",
                        pl: 0,
                        pr: 1,
                        wordBreak: "break-word",
                      }}
                    >
                      <NumberInput
                        placeholder="Enter Amount"
                        className="w-[200px]"
                        value={key[1]?.value || 0}
                        type="decimal"
                        disabled={key[1]?.key === "Current Period Interest" ? false : true}
                        onChange={(e) => {
                          data[index].invoicedetail.arearhead[key[0]].value = !isNaN(e.target.value) ? parseFloat(e.target.value) : 0;
                          handleCalculate(data,index)
                        }}
                      />
                       {key[1]?.key === "Past Advances & Dues" && (
                        <div className="flex gap-3 mt-1">
                          <Button
                            label="Credit Note & Advances"
                            className=""
                            onClick={()=>openModal(key[0])}
                          >
                            Credit note & Advances
                          </Button>
                        </div>
                      )}
                    </TableCell>
                  </TableRowStyled>
                );
              }
            ) : <></>}

            <TableRowStyled
              role="checkbox"
              tabIndex={-1}
              sx={{ cursor: "pointer" }}
            >
              <TableCell
                size="small"
                key="name"
                align="left"
                sx={{
                  borderBottom: "none",
                  fontSize: "13px",
                  pl: 0,
                  pr: 1,
                  wordBreak: "break-word",
                  width: "150px",
                }}
              >
                GrandTotal
              </TableCell>

              <TableCell
                size="small"
                key="amount"
                align="left"
                sx={{
                  borderBottom: "none",
                  fontSize: "13px",
                  pl: 0,
                  pr: 1,
                  wordBreak: "break-word",
                }}
              >
                {data[index]?.invoicedetail?.grandtotal}
              </TableCell>
            </TableRowStyled>
          </TableBody>
        </Table>
      </TableContainer>
      <CreditNoteandAdvanceModal
        handleCalculate = {handleCalculate}
        editInvoiceDetails={data}
        credAdvId = {credAdvId}        
        isOpen={creditNoteAndAdvanceModal}
        onClose={() => {
          setCreditNoteAndAdvanceModal(false);
        }}
        index={index}
      />
    </Box>
  );
};

GenerateInvoiceTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  defaultSortField: PropTypes.string,
  rowsPerPageOptions: PropTypes.array,
};

export default GenerateInvoiceTable;
