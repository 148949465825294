import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import "./Table.css";
import {
    ArchiveIcon,
  DeleteIcon,
  EditIcon,
  RenewIcon,
  RentFormIcon,
  SendEmail,
  SortIcon, 
  ViewIcon,
} from "../../assets";
import styled from "@emotion/styled";
import ItemsPerPage from "../ItemsPerPage/ItemsPerPage";
import Pagination from "../Pagination/Pagination";
import {
  stableSort,
  getComparator,
} from "../../utils/helpers/universalFunctions";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { EDIT_TENANTS_DETAILS_ENDPOINT, ONLINE_REGISTRATION_FORM_FOR_RENT_PAYMENT_ENDPOINT, RENEW_TENANCY_ENDPOINT, TENANTS_INFO_ENDPOINT, VIEW_TENANTS_DETAILS_ENDPOINT } from "../../Routing/routes";
import DeleteModal from "../Modal/DeleteModal";
import ArchiveTenantModal from "../Modal/ArchiveTenantModal";
import SendEmailModal from "../Modal/SendEmailModal";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import { archiveTenant, changeTenantStatus, deleteTenant, sendEmailToTeanants } from "../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/TenantsInfoAction";
import ChangeStatusModal from "../Modal/ChangeStatusModal";
import moment from "moment";
import * as permissions from '../../Routing/permissions'
import { getPermission } from "../../utils/helpers/getPermissions";

function ActionComponent({ tenantId, email, tenantName, rentDeedStartDate}) {
    const navigate = useNavigate();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
    const [isSendEmailModalOpen, setIsSendEmailModalOpen] = useState(false);
    const {ipAddress} = useSelector(state=>state.ipAddressReducer)
    const {loginData} = useSelector(state=>state.loginReducer)
    const {updateLoader} = useSelector(state=>state.tenantInfoReducer)

    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()

    const handleDeleteClick = () => {
      setShowDeleteModal(true);
    };

    const handleDelete = ()=>{
      let data = {
        ipAddress, 
        deletetenantId: tenantId, 
        sessionId: loginData?.session_id,
        societyId: loginData?.default_community?.community_id,
        page: searchParams.get("page") || 1,
        searchText: searchParams.get("search") || '',
        pageLimit: searchParams.get("pageLimit") || 10,
        status: searchParams.get("status") || '',
        wingId: searchParams.get("wingId") || '',
        flatId: searchParams.get("flatId") || '',
        flag: 'Tenant',
        onSuccessCallback: ()=>{
          setShowDeleteModal(false);
        }
      }
      dispatch(deleteTenant(data))
    }
  
    const handleArchiveClick = () => {
      setIsArchiveModalOpen(true);
    };

    const handleSendEmailOnSubmit = (data) =>{
      const sendRequest = {
        subject: data.subject,
        message: data.message,
        emailIds: `${email}`,
        onRequestEndCallback: ()=>{
          setIsSendEmailModalOpen(false)
        }
      }
      dispatch(sendEmailToTeanants(sendRequest))
    }

    const handleArchiveTenant = (date)=>{
      dispatch(archiveTenant({
        ipAddress,
        sessionId: loginData?.session_id,
        tenantId,
        leavingDate: moment(date).format('YYYY-MM-DD hh:mm:ss'),
        onRequestEndCallback: ()=>{
          setIsArchiveModalOpen(false)
        },
        societyId: loginData?.default_community?.community_id,
        page: searchParams.get("page") || 1,
        searchText: searchParams.get("search") || '',
        pageLimit: searchParams.get("pageLimit") || 10,
        status: searchParams.get("status") || '',
        wingId: searchParams.get("wingId") || '',
        flatId: searchParams.get("flatId") || '',
      }))
    }
  
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {
          getPermission(loginData, [permissions.VIEW_TENANTS_PERMISSION]) &&
          <Tooltip title="View" arrow>
            <ViewIcon
              className="fill-[#555] hover:fill-red-650 me-[8px]"
              onClick={() => {
                navigate(`${VIEW_TENANTS_DETAILS_ENDPOINT}/${tenantId}`);
              }}
            />
          </Tooltip>
        }
        {
          getPermission(loginData, [permissions.EDIT_TENANTS_PERMISSION]) &&
          <Tooltip title="Edit" arrow>
            <EditIcon
              className="fill-[#555] hover:fill-red-650 me-[8px]"
              onClick={() => {
                navigate(`${EDIT_TENANTS_DETAILS_ENDPOINT}/${tenantId}`);
              }}
            />
          </Tooltip>
        }
        {
          getPermission(loginData, [permissions.RENEW_TENANT_PERMISSION]) &&
          <Tooltip title="Renew" arrow>
            <RenewIcon
              className="fill-[#555] hover:fill-red-650 me-[8px]"
              onClick={() => {
                navigate(`${RENEW_TENANCY_ENDPOINT}/${tenantId}`);
              }}
            />
          </Tooltip>
        }
        {
          getPermission(loginData, [permissions.SEND_INVITE_IN_TENANTS_PERMISSION]) &&
          <Tooltip title="Send Mail" arrow>
            <SendEmail
              className="fill-[#555] hover:fill-red-650 me-[8px]"
              onClick={() => setIsSendEmailModalOpen(true)}
            />
          </Tooltip>
        }
        <Tooltip title="Archive" arrow>
          <ArchiveIcon
            className="fill-[#555] hover:fill-red-650 me-[8px]"
            onClick={() => handleArchiveClick()}
          />
        </Tooltip>
        {
          getPermission(loginData, [permissions.DELETE_TENANTS_PERMISSION]) &&
          <Tooltip title="Delete" arrow>
            <DeleteIcon
              className="fill-[#555] hover:fill-red-650 me-[8px]"
              onClick={() => handleDeleteClick()}
            />
          </Tooltip>
        }
        {
          getPermission(loginData, [permissions.PAY_ONLINE_RENT_PERMISSION]) &&
          <Tooltip title="Rent Payment Form" arrow>
            <RentFormIcon
              className="fill-[#555] hover:fill-red-650 me-[8px]"
              onClick={() => {
                navigate(`${ONLINE_REGISTRATION_FORM_FOR_RENT_PAYMENT_ENDPOINT}/${tenantId}`);
              }}
            />
          </Tooltip>
        }
        {/* Delete Confirmation Modal */}
      <DeleteModal
        isOpen={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onDelete={handleDelete}
      />
      <ArchiveTenantModal
        isOpen={isArchiveModalOpen}
        onClose={() => setIsArchiveModalOpen(false)}
        tenantName={tenantName}
        minDate={moment(new Date(rentDeedStartDate)).add(1, 'days').toDate()}
        loading={updateLoader}
        onSubmit={handleArchiveTenant}
      />

      {/* Email Modal */}
      <SendEmailModal
        isOpen={isSendEmailModalOpen}
        closeModal={() => setIsSendEmailModalOpen(false)}
        loading={updateLoader}
        onSubmit={handleSendEmailOnSubmit}
      />
      </Box>
    );
  }
  

  const TableRowStyled = styled(TableRow)`
    &:nth-of-type(odd) {
      background-color: #ffffff;
    }
    &:nth-of-type(even) {
      background-color: #f4f5f8;
    }
    &:nth-of-type(odd):hover {
      background-color: #ffffff;
    }
    &:nth-of-type(even):hover {
      background-color: #f4f5f8;
    }
  `;

  const TableCellStyled = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontWeight: 600,
      zIndex: 0,
      color: "#AAAAAA",
      fontSize: "13px",
      padding: '12px 8px 12px 0',
      wordBreak: "break-word",
    },
    [`&.${tableCellClasses.body}`]: {
      borderBottom: "none",
      fontSize: "13px",
      padding: '12px 8px 12px 0',
      wordBreak: "break-word",
      cursor: "pointer"
    },
  }));

const TenantInfoListTable = ({
  loading,
  data,
  columns,
  checkbox = true,
  pagination = true,

  //props are required if pagination is true
  totalCount,
  totalPages,
  start,
  end,
  currentPage,
  rowsPerPage,

  selected,
  setSelected
  
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);

  const [showChangeStatusModal, setShowChangeStatusModal] = useState({id: '', open: false});
  const dispatch = useDispatch()
  const {updateLoader} = useSelector(state=>state.tenantInfoReducer)
  const {loginData} = useSelector(state=>state.loginReducer)

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    const newSelected = event.target.checked ? data.map((item) => item.tenantId) : [];
    setSelected(newSelected);
  };

  const handleClick = (event, id) => {
    const newSelected = selected.includes(id)
      ? selected.filter((selectedId) => selectedId !== id)
      : [...selected, id];
    setSelected(newSelected);
  };

  const handleChangePage = (newPage) => {
    searchParams.delete("page")
    navigate({
      pathname: `${TENANTS_INFO_ENDPOINT}`,
      search: `?${createSearchParams([...searchParams.entries(), ["page", newPage]])}`,
    })
  } ;

  const handleChangeRowsPerPage = (value) => {
    if (rowsPerPage !== value) {
      searchParams.delete("pageLimit")
      navigate({
        pathname: `${TENANTS_INFO_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["pageLimit", value]])}`,
      })
    }
  };

  
  const isSelected = (id) => selected.includes(id);


  const handleChangeStatus = (id, status)=>{
    let data={
      tenantId: id, 
      status: status==='Active' ? 'I' : 'A',
      onRequestEndCallback: ()=>{
        setShowChangeStatusModal(false)
      }
    };
    dispatch(changeTenantStatus(data))
  }

  useEffect(()=>{      
    setSelected([])
  }, [searchParams])

  return (
    <Box
      sx={{ width: "100%", fontSize: "13px" }}
      className="table-component"
    >
    <TableContainer
      className="border-t border-[#CCC]"
      sx={{minHeight: data?.length ? "500px" : 'none'}}
    >
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {!!checkbox && (
              <TableCellStyled size="small" sx={{pl:'0 !important', pr: 2, width: 36 }}>
                <Checkbox
                  size="small"
                  indeterminate={false}
                  checked={data?.length > 0 && selected?.length === data?.length}
                  onChange={handleSelectAllClick}
                  inputProps={{
                    "aria-label": "select all",
                  }}
                  sx={{
                    p: 0,
                    color: "#AAAAA",
                    "&.Mui-checked": {
                      color: "#CF001C",
                    },
                  }}
                  disabled={loading || !data}
                />
              </TableCellStyled>
            )}
            {columns?.map((column) => (
              <TableCellStyled
                size="small"
                key={column.id+ 'heading'}
                align={column.numeric ? "right" : "left"}
                sortDirection={orderBy === column.id ? order : false}
                sx={{

                  width: column.width || "fit-content",
                  minWidth: column.width || "fit-content",
                }}
              >
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={orderBy === column.id ? order : "asc"}
                  onClick={() => {
                    if (column.sort) {
                      handleRequestSort(column.id);
                    }
                  }}
                  IconComponent={SortIcon}
                  sx={{
                    ".MuiTableSortLabel-icon": {
                      opacity: "1 !important",
                    },
                    svg: {
                      flexShrink: 0,
                    },
                  }}
                  hideSortIcon={!column.sort}
                >
                  {column.label}
                  {orderBy === column.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCellStyled>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {
              loading ?
              <TableRow>
                <TableCellStyled sx={{border: 'none'}} colSpan={columns?.length+1}><Loader/></TableCellStyled>
              </TableRow>
              :
              <>
                {!!data?.length && stableSort(data, getComparator(order, orderBy))
                  ?.map((row, rowIndex) => {
                    const isRowSelected = isSelected(row?.id);

                    return (
                      <TableRowStyled
                        key={row?.tenantId}
                        role="checkbox"
                        aria-checked={isRowSelected}
                        tabIndex={-1}
                        selected={isRowSelected}
                      >
                        {!!checkbox && (
                          <TableCellStyled size="small" sx={{pl:'0 !important', zIndex: 0}} onClick={(event) => handleClick(event, row?.tenantId)}>
                            <Checkbox
                              checked={selected?.includes(row?.tenantId)}
                              onChange={(e) =>
                                handleClick(e, row?.tenantId)
                              }
                              size="small"
                              color="primary"
                              inputProps={{
                                "aria-labelledby": `generic-table-checkbox-${row?.id}`,
                              }}
                              sx={{
                                p: 0,
                                color: "#AAAAA",
                                "&.Mui-checked": {
                                  color: "#CF001C",
                                },
                              }}

                            />
                          </TableCellStyled>
                        )}

                        {/* Serial Number */}
                        <TableCellStyled size="small" align="left" onClick={(event) => handleClick(event, row?.tenantId)}>
                          {row?.sNo}
                        </TableCellStyled>
                        
                        <TableCellStyled size="small" align="left" sx={{textTransform: 'capitalize'}}>
                          {row?.tenantName}
                        </TableCellStyled>
      
                        <TableCellStyled size="small" align="left" sx={{textTransform: 'capitalize'}}>
                          {row.wingNo}
                        </TableCellStyled>

                        <TableCellStyled size="small" align="left">
                          {row.flatNo}
                        </TableCellStyled>

                        <TableCellStyled size="small" align="left">
                          {row.memberName}
                        </TableCellStyled>

                        <TableCellStyled size="small" align="left">
                          {row?.rentDeedStartDate} 
                        </TableCellStyled>

                        <TableCellStyled size="small" align="left">
                          {row.rentDeedEndDate}
                        </TableCellStyled>

                        <TableCellStyled size="small" align="left">
                          {row.lastTimeActive}
                        </TableCellStyled>

                        <TableCellStyled size="small" align="left" >
                          {
                            row.status === 'Expired' ?
                            <u className="text-red-650 font-semibold">{row.status}</u>:
                            <div>
                              <u 
                                onClick={()=> 
                                  getPermission(loginData, [permissions.APPROVE_TENANTS_PERMISSION]) ? setShowChangeStatusModal({id: row.tenantId, open: true}) : undefined
                                } 
                                className={`font-semibold ${row.status==='Active' ? 'text-green-600' : (row.status === 'Inactive' ? 'text-orange-500' : 'text-yellow-500')}`} >

                                {row?.status}

                              </u>
                              <ChangeStatusModal
                                isOpen={showChangeStatusModal.open && row.tenantId === showChangeStatusModal.id}
                                onCancel={()=>setShowChangeStatusModal(false)}
                                onConfirm={()=>{handleChangeStatus(row.tenantId, row?.status)}}
                                text={`Do you want to change status to ${row?.status==='Active' ? 'Inactive' : 'Active'} ?`}
                                loading={updateLoader}
                              />
                            </div>
                          }
                        </TableCellStyled>
                        
                        <TableCellStyled size="small" align="left">
                          <ActionComponent tenantId={row.tenantId} email={row.email} tenantName={row.tenantName} rentDeedStartDate={row.rentDeedStartDate} />
                        </TableCellStyled>
                      </TableRowStyled>
                    );
                  })}
              </>
            }
          </TableBody>
        </Table>
      </TableContainer>

      {!!pagination && data && (
        <div className="flex flex-row justify-between mt-3 pt-1 border-t border-[#ccc] text-[14px]">
          <ItemsPerPage
            rowsPerPage={rowsPerPage}
            onChange={handleChangeRowsPerPage}
          />
          <Pagination
            totalCount={totalCount}
            totalPages={totalPages}
            start={start}
            end={end}
            currentPage={currentPage}
            onChange={handleChangePage}
          />
        </div>
      )}
    </Box>
  );
};

TenantInfoListTable.propTypes = {
  columns: PropTypes.array.isRequired,
  defaultSortField: PropTypes.string,
  rowsPerPageOptions: PropTypes.array,
};

export default TenantInfoListTable;
