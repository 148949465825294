import { call, put, takeLatest } from 'redux-saga/effects'
import * as types from '../../actions/index.types'
import * as req from '../../../axios/request/ReportsReqs/OldFinancialReportsReqs/OldFinancialReportsReq'
import { notify } from '../../../utils/notification';

function* getBillTypeList(action) {
    try {
        const result = yield call(req.getBillTypeListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_BILL_TYPE_LIST_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_BILL_TYPE_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getFinanceMembersList(action) {
    try {
        const result = yield call(req.getFinanceMembersListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_FINANCE_MEMBER_LIST_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_FINANCE_MEMBER_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getBillGenerationViewReports(action) {
    try {
        const result = yield call(req.getBillGenerationViewReportsReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_BILL_GENERATION_VIEW_REPORTS_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_BILL_GENERATION_VIEW_REPORTS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* downloadBillGenerationViewReports(action) {
    try {
        const result = yield call(req.downloadBillGenerationViewReportsReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
        } else {
            notify('Unable to download sheet.')
        }
    } catch (e) {
    }
}

function* getMembersBillReports(action) {
    try {
        const result = yield call(req.getMembersBillReportsReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_MEMBERS_BILL_REPORTS_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_MEMBERS_BILL_REPORTS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getSocietyBillReports(action) {
    try {
        const result = yield call(req.getSocietyBillReportsReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_SOCIETY_BILL_REPORTS_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_SOCIETY_BILL_REPORTS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getInterestStatementReports(action) {
    try {
        const result = yield call(req.getInterestStatementReportsReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_INTEREST_STATEMENT_REPORTS_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_INTEREST_STATEMENT_REPORTS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getEInvocieReports(action) {
    try {
        const result = yield call(req.geteInvoiceReportsReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_EINVOICE_REPORTS_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_EINVOICE_REPORTS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

// finance reports 

function* getOutstandingReports(action) {
    try {
        const result = yield call(req.getOutstandingReportsReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_OUTSTANDING_REPORTS_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_OUTSTANDING_REPORTS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* downloadOutstandingReports(action) {
    try {
        const result = yield call(req.downloadOutstandingReportsReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
        } else {
            notify("error", result.message)
        }
    } catch (e) {
        console.log(e)
    }
}

// accounts reports 

function* getGroupTrailBalance(action) {
    try {
        const result = yield call(req.getGroupTrialBalanceReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_GROUP_TRAIL_BALANCE_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_GROUP_TRAIL_BALANCE_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* downloadCSVGroupTrailBalance(action) {
    try {
        const result = yield call(req.downloadCSVGroupTrialBalanceReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
        }
        else if (result?.statusCode == 200) {
            notify("success", result.message)
        }
        else {
            notify('Unable to download sheet.')
        }
    } catch (e) {
    }
}

function* downloadPDFGroupTrailBalance(action) {
    try {
        const result = yield call(req.downloadPDFGroupTrialBalanceReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
        }
        else if (result?.statusCode == 200) {
            notify("success", result.message)
        } else {
            notify('Unable to download sheet.')
        }
    } catch (e) {
    }
}

function* getOpeningBalanceReports(action) {
    try {
        const result = yield call(req.getOpeningBalanceReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_OPENING_BALANCE_REPORTS_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_OPENING_BALANCE_REPORTS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* downloadCSVOpeningBalanceReports(action) {
    try {
        const result = yield call(req.downloadCSVOpeningBalanceReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
        } else {
            notify('Unable to download sheet.')
        }
    } catch (e) {
        console.log(e)
    }
}

function* downloadPDFOpeningBalanceReports(action) {
    try {
        const result = yield call(req.downloadPDFOpeningBalanceReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
        } else {
            notify('Unable to download sheet.')
        }
    } catch (e) {
    }
}

function* getReceiptsPayments(action) {
    try {
        const result = yield call(req.getReceiptPaymentReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_RECEIPTS_PAYMENTS_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_RECEIPTS_PAYMENTS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* downloadCSVReceiptsPayments(action) {

    try {
        const result = yield call(req.downloadCSVReceiptPaymentReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
        }
        else {
            notify('error', result.message)
        }
    } catch (e) {
    }
}

function* downloadPDFReceiptsPayments(action) {
    try {
        const result = yield call(req.downloadPDFReceiptPaymentReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
        } else {
            notify('Unable to download sheet.')
        }
    } catch (e) {
    }
}

function* getCashBankBook(action) {
    try {
        const result = yield call(req.getCashBankBookReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_CASH_BANK_BOOK_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_CASH_BANK_BOOK_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* downloadCSVCashBankBook(action) {
    try {
        const result = yield call(req.downloadCSVCashBankBookReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
        } else {
            notify('Unable to download sheet.')
        }
    } catch (e) {
    }
}

function* downloadPDFCashBankBook(action) {
    try {
        const result = yield call(req.downloadPDFCashBankBookReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
        } else {
            notify('Unable to download sheet.')
        }
    } catch (e) {
    }
}

function* getJournal(action) {
    try {
        const result = yield call(req.getJournalsReportReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_JOURNALS_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_JOURNALS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* downloadCSVJournals(action) {
    try {
        const result = yield call(req.downloadCSVJournalsReportsReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
        } else {
            notify('error', result.message)
        }
    } catch (e) {
    }
}

function* downloadPDFJournals(action) {
    try {
        const result = yield call(req.downloadPDFJournalsReportsReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
        } else {
            notify('Unable to download sheet.')
        }
    } catch (e) {
    }
}

function* getLedgers(action) {
    try {
        const result = yield call(req.getLedgersReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_LEDGERS_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_LEDGERS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* downloadCSVLedgers(action) {
    try {
        const result = yield call(req.downloadCSVLedgersReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
        } else {
            notify('error', result.message)
        }
    } catch (e) {
    }
}

function* downloadPDFLedgers(action) {
    try {
        const result = yield call(req.downloadPDFLedgersReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
        } else {
            notify('Unable to download sheet.')
        }
    } catch (e) {
    }
}

// function* getAllMemberLedgers(action) {
//     try {
//         const result = yield call(req.getAllMemberLedgereReq, action);
//         if (result.statusCode === 200) {
//             yield put({ type: types.GET_ALL_MEMBER_LEDGERS_SUCCESS, payload: result.data });
//         } else {
//             yield put({ type: types.GET_ALL_MEMBER_LEDGERS_FAILURE, message: result?.response?.data?.message });
//         }
//     } catch (e) {
//         console.log(e)
//     }
// }

function* downloadCSVAllMembersReport(action) {
    try {
        const result = yield call(req.downloadCSVAllMembersReportReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSuccessCallback(result)
        } else {
            notify('error', result.message)
        }
    } catch (e) {
        console.log(e)
    }
}

function* chartOfAccountBookData(action) {
    try {
        const result = yield call(req.getChartOfAccountBookDataReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_CHART_OF_ACCOUNT_BOOK_DATA_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_CHART_OF_ACCOUNT_BOOK_DATA_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}


export default function* oldFinancialReportsSaga() {
    yield takeLatest(types.GET_BILL_GENERATION_VIEW_REPORTS, getBillGenerationViewReports);
    yield takeLatest(types.GET_FINANCE_MEMBER_LIST, getFinanceMembersList);
    yield takeLatest(types.GET_BILL_TYPE_LIST, getBillTypeList);
    yield takeLatest(types.GET_MEMBERS_BILL_REPORTS, getMembersBillReports);
    yield takeLatest(types.GET_SOCIETY_BILL_REPORTS, getSocietyBillReports);
    yield takeLatest(types.GET_INTEREST_STATEMENT_REPORTS, getInterestStatementReports);
    yield takeLatest(types.GET_EINVOICE_REPORTS, getEInvocieReports);
    yield takeLatest(types.DOWNLOAD_BILL_GENERATION_VIEW_REPORTS, downloadBillGenerationViewReports);
    yield takeLatest(types.GET_OUTSTANDING_REPORTS, getOutstandingReports);
    yield takeLatest(types.DOWNLOAD_OUTSTANDING_REPORTS, downloadOutstandingReports);

    yield takeLatest(types.GET_GROUP_TRAIL_BALANCE, getGroupTrailBalance);
    yield takeLatest(types.DOWNLOAD_CSV_GROUP_TRAIL_BALANCE, downloadCSVGroupTrailBalance);
    yield takeLatest(types.DOWNLOAD_PDF_GROUP_TRAIL_BALANCE, downloadPDFGroupTrailBalance);
    yield takeLatest(types.GET_OPENING_BALANCE_REPORTS, getOpeningBalanceReports);
    yield takeLatest(types.DOWNLOAD_CSV_OPENING_BALANCE_REPORTS, downloadCSVOpeningBalanceReports);
    yield takeLatest(types.DOWNLOAD_PDF_OPENING_BALANCE_REPORTS, downloadPDFOpeningBalanceReports);
    yield takeLatest(types.GET_RECEIPTS_PAYMENTS, getReceiptsPayments);
    yield takeLatest(types.DOWNLOAD_CSV_RECEIPTS_PAYMENTS, downloadCSVReceiptsPayments);
    yield takeLatest(types.DOWNLOAD_PDF_RECEIPTS_PAYMENTS, downloadPDFReceiptsPayments);
    yield takeLatest(types.GET_CASH_BANK_BOOK, getCashBankBook);
    yield takeLatest(types.DOWNLOAD_CSV_CASH_BANK_BOOK, downloadCSVCashBankBook);
    yield takeLatest(types.DOWNLOAD_PDF_CASH_BANK_BOOK, downloadPDFCashBankBook);
    yield takeLatest(types.GET_JOURNALS, getJournal);
    yield takeLatest(types.DOWNLOAD_CSV_JOURNALS, downloadCSVJournals);
    yield takeLatest(types.DOWNLOAD_PDF_JOURNALS, downloadPDFJournals);
    yield takeLatest(types.GET_LEDGERS, getLedgers);
    yield takeLatest(types.DOWNLOAD_CSV_LEDGERS, downloadCSVLedgers);
    yield takeLatest(types.DOWNLOAD_PDF_LEDGERS, downloadPDFLedgers);
    // yield takeLatest(types.GET_ALL_MEMBER_LEDGERS, getAllMemberLedgers);
    yield takeLatest(types.DOWNLOAD_CSV_ALL_MEMBERS_REPORT, downloadCSVAllMembersReport);
    yield takeLatest(types.GET_CHART_OF_ACCOUNT_BOOK_DATA, chartOfAccountBookData);
}