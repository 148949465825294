import { notify } from '../../../../utils/notification';
import apiEndpoints from '../../../api';
import axiosInstance from '../../../axiosInstance'
import {logOut} from '../../../../utils/helpers/logOut'
import { errorMsg } from '../../../../consts/responseMessages';

export async function getLeaveAndLicenseListReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_LEAVE_AND_LICENSE_LIST_API}?society_id=${action.data.societyId}&page_no=${action.data.page}&page_limit=${action.data.pageLimit}${action.data.searchText ? `&search_text=${encodeURIComponent(action.data.searchText)}` : ""}${action.data.wingId ? `&wing_id=${action.data.wingId}` : ""}${action.data.flatId ? `&flat_id=${action.data.flatId}` : ""}${action.data.startDate ? `&from_date=${action.data.startDate}` : ""}${action.data.endDate ? `&to_date=${action.data.endDate}` : ""}${action.data.status ? `&status=${action.data.status}` : ""}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function downloadLeaveAndLicenseSheetReq(action) {

    return axiosInstance.get(`${apiEndpoints.DOWNLOAD_LEAVE_AND_LICENSE_API}?society_id=${action.data.societyId}${action.data.searchText ? `&search_text=${encodeURIComponent(action.data.searchText)}` : ""}${action.data.wingId ? `&wing_id=${action.data.wingId}` : ""}${action.data.flatId ? `&flat_id=${action.data.flatId}` : ""}${action.data.startDate ? `&from_date=${action.data.startDate}` : ""}${action.data.endDate ? `&to_date=${action.data.endDate}` : ""}${action.data.status ? `&status=${action.data.status}` : ""}`, {
        responseType: 'arraybuffer',
    })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}