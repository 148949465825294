import { LOGOUT } from '../index.types';


export function logout(data) {
    return {
        type: LOGOUT,
        data
    };
}

