import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { Arrow } from "../../../../assets";
import Layout from "../../../Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getAllManagingCommitteeAuditLog } from "../../../../redux/actions/SetupRecordsActions/StaffManagementActions/ManangingCommitteeAction";
import moment from "moment";
import Loader from "../../../../components/Loader/Loader";
import { validateDate } from "../../../../utils/helpers/universalFunctions";

const AuditData = [];

const Accordion = React.memo(
  ({ data, isOpen, toggleAccordion, label, descr, desc }) => (
    <div className="border rounded-lg border-[#CCCCCC] my-2">
      <div
        className="flex justify-between cursor-pointer items-center bg-[#FDF2F3] rounded-lg p-3 border-b"
        onClick={toggleAccordion}
      >
        <div className="flex items-center">
          <button className="text-white text-base font-semibold mr-3 w-5 h-5 bg-red-650 border rounded flex justify-center items-center">
            {isOpen ? (
              <FontAwesomeIcon icon={faChevronUp} size="xs" />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} size="xs" />
            )}
          </button>
          <div className="text-[#000000] text-sm font-semibold">{label}</div>
        </div>
        <div>
          <div className="text-xs text-right text-[#888]">{desc}</div>
          <div className="text-xs text-right text-[#888]">{descr}</div>
        </div>
      </div>
      {isOpen && (
        <div className="p-3">
          <div className="grid grid-cols-5 gap-1 w-full text-sm p-2 mb-4">
            <div className="font-bold">Committee Name</div>
            <div className="capitalize col-span-4">{data?.new_data?.title}</div>
          </div>
          <div className="grid grid-cols-5 gap-1 w-full text-sm p-2 font-bold">
            <div>Members</div>
            <div>Member Name</div>
            <div>Roles and responsibilty</div>
            <div>Join Date</div>
            <div>Leave Date</div>
          </div>
          {
            Object.keys(data?.new_data?.members).map((key) => data?.new_data?.members[key])?.length && Object.keys(data?.new_data?.members).map((key) => data?.new_data?.members[key]).map(member=>(
              <div className="grid grid-cols-5 gap-1 w-full text-sm p-2">
                <div></div>
                <div>{data?.new_data?.name?.[member] || '-'}</div>
                <div>{data?.new_data?.roles?.[member] || '-'}</div>
                <div>{validateDate(data?.new_data?.join_date?.[member]) ? moment(new Date(data?.new_data?.join_date?.[member]))?.format('DD-MM-YYYY') : '-'}</div>
                <div>{validateDate(data?.new_data?.leave_date?.[member]) ? moment(new Date(data?.new_data?.leave_date?.[member]))?.format('DD-MM-YYYY') :  '-'}</div>
              </div>
            ))
          }
          {AuditData.map((item, i0) => (
            <>
              {item.label ? (
                <div className="acord-section font-semibold text-[#222222] p-2">
                  {item.label}
                </div>
              ) : null}
              {item.values.map((rowData, i1) => (
                <>
                  <div className="flex justify-between p-2">
                    {rowData.map((fieldData, index) => (
                      <div className="flex justify-between w-1/2">
                        <div className="w-1/2">{fieldData.label}</div>
                        <div className="w-1/2">{fieldData.value}</div>
                      </div>
                    ))}
                  </div>
                  {i0 === 0 && i1 === 5 ? <hr /> : null}
                </>
              ))}
            </>
          ))}
        </div>
      )}
    </div>
  )
);

const CommitteeDetailsAudit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const {loading, allCommitteeAuditLog} = useSelector(state=> state.managingCommitteeReducer)
  const {loginData} = useSelector(state=>state.loginReducer)
  
  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const toggleAccordion = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(()=>{
    dispatch(getAllManagingCommitteeAuditLog({societyId: loginData?.default_community?.community_id}))
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="flex gap-5 pb-2">
          <div className="flex w-1/2">
            <button
              onClick={handleNavigation(-1)}
              className="text-sm p-2 text-red-650 font-semibold flex gap-2 justify-center items-center"
            >
              <span>
                <Arrow />
              </span>
              Back
            </button>
            <span className="border-r border-[#CCCCCC]"></span>
            <h1 className="text-sm font-semibold p-2">
              Managing Committee Audit Logs
            </h1>
          </div>
        </div>
        <div className="border-b border-[#CCCCCC]"></div>
        <div>
        {
          loading ?
          <Loader/>:
          <>
          {
            !!allCommitteeAuditLog?.length ?
            <div>
              {
                allCommitteeAuditLog?.map((item, index)=>(
                  <Accordion
                    label={`Audit ${(index+1)?.toString()?.padStart(2, '0')}`}
                    key={index}
                    data={item}
                    isOpen={index === openAccordionIndex}
                    toggleAccordion={() => toggleAccordion(index)}
                    desc={`Modified By ${item?.modified_by} On ${moment(item?.modified_dateTime)?.format('MMM DD, YYYY hh:mm A')}`}
                    descr={`Ip Address: ${item?.IpAddress}. Session id - ${item?.modified_sessionId}`}
                  />
                ))
              }
            </div>
            :
            <div className="text-sm text-[#AAAAAA] my-4 text-center">
              No Records
            </div>
          }
          </>
        }
        </div>
      </div>
    </Layout>
  );
};

export default CommitteeDetailsAudit;
