import { call, put, takeLatest } from 'redux-saga/effects'
import {notify} from '../../../../utils/notification'
import * as types from '../../../actions/index.types'
import * as req from '../../../../axios/request/FinanceReqs/SettingReqs/CreditDebitTariffHeadersReqs'

function* getCreditDebitTariffHeaderList(action) {
    try {
        const result = yield call(req.getCreditDebitTariffHeaderListReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_TARIFF_HEADER_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_TARIFF_HEADER_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getCreditDebitTariffHeaderDetails(action) {
    try {
        const result = yield call(req.getCreditDebitTariffHeaderDetailsReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_TARIFF_HEADER_DETAILS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_TARIFF_HEADER_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getCreditDebitTariffHeaderAudit(action) {
    try {
        const result = yield call(req.getCreditDebitTariffHeaderAuditReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_TARIFF_HEADER_AUDIT_LOGS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_TARIFF_HEADER_AUDIT_LOGS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getCreditDebitTariffHeaderAllAudit(action) {
    try {
        const result = yield call(req.getCreditDebitTariffHeaderAllAuditReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_TARIFF_HEADER_ALL_AUDIT_LOGS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_TARIFF_HEADER_ALL_AUDIT_LOGS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateCreditDebitTariffHeaders(action) {
    try {
        const result = yield call(req.updateCreditDebitTariffHeadersReq, action);
        if (result.success === true) {
            notify('success', result.message)
            yield put({ type: types.UPDATE_TARIFF_HEADER_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.UPDATE_TARIFF_HEADER_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* addCreditDebitTariffHeaders(action) {
    try {
        const result = yield call(req.addCreditDebitTariffHeadersReq, action);
        if (result.success === true) {
            action.data?.onSuccessCallback?.()
            notify('success', result.message)
            yield put({ type: types.ADD_TARIFF_HEADER_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.ADD_TARIFF_HEADER_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getGLMappingList(action) {
    try {
        const result = yield call(req.getGLMappingListReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_GL_MAPPING_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_GL_MAPPING_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getAccountLedgerHeaderList(action) {
    try {
        const result = yield call(req.getAccountLedgerHeaderListReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_ACCOUNT_LEDGER_HEADER_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_ACCOUNT_LEDGER_HEADER_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}


function* getDebitSetting(action) {
    try {
        const result = yield call(req.getDebitSettingReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_DEBIT_NOTE_SETTINGS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_DEBIT_NOTE_SETTINGS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateDebitSettings(action) {
    try {
        const result = yield call(req.updateDebitSettingsReq, action);
        if (result.success === true) {
            notify('success', result.message)
            yield put({ type: types.UPDATE_DEBIT_NOTE_SETTINGS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.UPDATE_DEBIT_NOTE_SETTINGS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}


export default function* creditDebitTariffHeaderSaga() {
    yield takeLatest(types.GET_TARIFF_HEADER_LIST, getCreditDebitTariffHeaderList);
    yield takeLatest(types.GET_TARIFF_HEADER_DETAILS, getCreditDebitTariffHeaderDetails);
    yield takeLatest(types.GET_TARIFF_HEADER_AUDIT_LOGS, getCreditDebitTariffHeaderAudit);
    yield takeLatest(types.GET_TARIFF_HEADER_ALL_AUDIT_LOGS, getCreditDebitTariffHeaderAllAudit);
    yield takeLatest(types.UPDATE_TARIFF_HEADER, updateCreditDebitTariffHeaders);
    yield takeLatest(types.ADD_TARIFF_HEADER, addCreditDebitTariffHeaders);
    yield takeLatest(types.GET_GL_MAPPING_LIST, getGLMappingList);
    yield takeLatest(types.GET_ACCOUNT_LEDGER_HEADER_LIST, getAccountLedgerHeaderList);
    yield takeLatest(types.GET_DEBIT_NOTE_SETTINGS, getDebitSetting);
    yield takeLatest(types.UPDATE_DEBIT_NOTE_SETTINGS, updateDebitSettings);
}