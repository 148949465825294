import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const ViewCommitteeAccordionAccessSettings = React.memo(
  ({ data, isOpen, toggleAccordion, label, accessSetting }) => (
    <div className="border rounded-lg border-[#CCCCCC] my-2 overflow-hidden">
      <div
        className="flex justify-between cursor-pointer items-center bg-[#FDF2F3] rounded-t-lg p-3 border-b"
        onClick={toggleAccordion}
      >
        <div className="flex items-center">
          <button className="text-white text-sm font-semibold mr-3 w-5 h-5 bg-red-650 border rounded flex justify-center items-center">
            {isOpen ? (
              <FontAwesomeIcon icon={faChevronUp} size="xs" />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} size="xs" />
            )}
          </button>
          <div className="text-[#000000] text-sm font-semibold">{label}</div>
        </div>
      </div>
      {isOpen && (
        <div className="p-3 grid grid-cols-3">
          {/* <div> */}
          {data.map((item, i0) => (
            <>
            {
              (Array.isArray(item?.[1]) || !!Object.values(item?.[1])?.reduce((subTotal, value) => subTotal + value, 0)) &&
              <div className="mb-10" key={item?.[0]+i0}>
                {(Array.isArray(item?.[1]) || !!Object.values(item?.[1])?.reduce((subTotal, value) => subTotal + value, 0)) ? (
                  <div className="acord-section text-sm font-semibold text-[#222222] p-2">
                    <div className="inline-flex items-center">
                      {item?.[0]}
                    </div>
                  </div>
                ) : null}
                {Object.entries(item?.[1])?.map((rowData, i1) => (
                  <>
                    {
                      !!rowData?.[1] &&
                      <div className="ml-6" key={rowData?.[0] + i1}>
                        <div className="flex justify-between py-1">
                            <div className="flex flex-row items-center text-xs">
                              <div>{rowData?.[0]}</div>
                            </div>
                        </div>
                      </div>
                    }
                  </>
                ))}
              </div>
            }
            </>
          ))}
        </div>
      )}
    </div>
  )
);

export default ViewCommitteeAccordionAccessSettings;
