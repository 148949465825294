import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { faDownload, faPrint } from "@fortawesome/free-solid-svg-icons";
import { SocietyVendorsData as initialCategoryData } from "./ContactData";
import SocietyTable from "./SocietyTable";
import AMCContractReportModal from "./AMCContractModal";
import {
  SOCIETY_VENDORS_ENDPOINT,
  VENDOR_AUDIT_LOG_ENDPOINT,
} from "../../Routing/routes";
import Layout from "../../pages/Layout/Layout";
import Dropdown from "../Dropdown/Dropdown";
import Search from "../SearchBox/Search";
import ItemsPerPage from "../ItemsPerPage/ItemsPerPage";
import Pagination from "../Pagination/Pagination";
import Button from "../Button/Button";
import ButtonIco from "../Button/ButtonPrint";
import ListHeaderBack from "../ListHeader/ListHeaderBack";

const ArchivedAMC = () => {
  const navigate = useNavigate();
  const [selectedNumber, setSelectedNumber] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [isAddCategoryPopupOpen, setIsAddCategoryPopupOpen] = useState(false);
  const [isEditingCategory, setIsEditingCategory] = useState(false);
  const [categoryToEdit, setCategoryToEdit] = useState(null);
  const [SocietyVendorsData, setCategoryData] = useState(initialCategoryData);

  const itemsPerPage = parseInt(selectedNumber) || 10;
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = SocietyVendorsData.slice(startIndex, endIndex);

  const totalPages = Math.ceil(SocietyVendorsData.length / itemsPerPage);
  const startEntry = currentPage * itemsPerPage + 1;
  const endEntry = Math.min(
    (currentPage + 1) * itemsPerPage,
    SocietyVendorsData.length
  );

  const handleNumberClick = (number) => {
    setSelectedNumber(number);
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleAMCContractModalClick = () => {
    setIsAddCategoryPopupOpen(true);
  };
  const handleAuditLogClick = () => {
    navigate(VENDOR_AUDIT_LOG_ENDPOINT);
  };

  const handleAMCContractModal = (categoryName) => {
    const newCategory = {
      id: SocietyVendorsData.length + 1,
      name: categoryName,
    };
    setCategoryData([...SocietyVendorsData, newCategory]);
  };

  const handleEditCategoryClick = (category) => {
    setCategoryToEdit(category);
    setIsEditingCategory(true);
  };

  const handleEditCategory = (categoryId, categoryName, isSpecialCategory) => {
    const updatedCategories = SocietyVendorsData.map((c) => {
      if (c.id === categoryId) {
        return {
          ...c,
          name: categoryName,
          isSpecial: isSpecialCategory,
        };
      }
      return c;
    });

    setCategoryData(updatedCategories);
    setIsEditingCategory(false);
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <ListHeaderBack
          onClick={handleNavigation(SOCIETY_VENDORS_ENDPOINT)}
          title="Archived AMC Contract"
        >
          <Button
            label="AMC Contract Report"
            className="h-8"
            onClick={handleAMCContractModalClick}
          />
          <span className="border-r border-[#CCCCCC] h-full"></span>
            <Button
              label="Audit Logs"
              className="h-8"
              onClick={handleAuditLogClick}
            />
        </ListHeaderBack>

        <div className="border-b border-[#CCCCCC]"></div>
        <div className="p-2 flex flex-row justify-between gap-2">
          <div>
            <Search width="full" height="8" />
          </div>
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <p>Filter</p>
              <Dropdown
                options={[
                  "Carpenters",
                  "Electrician",
                  "Ac Repair",
                  "Food & Snacks Supplier",
                ]}
                width="48"
                height="8"
                className="text-xs"
                placeholder="Select Category"
                onSelect={(selectedItem) => {
                  // Handle the selected item here
                }}
              />
            </div>
            <div className="border-r border-[#CCC] mx-2 h-full"></div>
            <div className="flex gap-2">
              <ButtonIco
                icon={faPrint}
                onClick={handlePrint}
                children="Print"
              />
              <ButtonIco icon={faDownload} children="Download Excel" />
            </div>
          </div>
        </div>

        <SocietyTable
          data={paginatedData}
          onEditCategory={handleEditCategoryClick}
        />

        <div className="border-b-2"></div>
        <div className="flex flex-row justify-between">
          <ItemsPerPage
            selectedNumber={selectedNumber}
            onSelect={handleNumberClick}
          />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            handlePageClick={handlePageClick}
            startEntry={startEntry}
            endEntry={endEntry}
            dataLength={SocietyVendorsData.length}
          />
        </div>
        <AMCContractReportModal
          isOpen={isAddCategoryPopupOpen || isEditingCategory}
          onClose={() => {
            setIsAddCategoryPopupOpen(false);
            setIsEditingCategory(false);
            setCategoryToEdit(null);
          }}
          onAddCategory={handleAMCContractModal}
          onEditCategory={handleEditCategory}
          categoryToEdit={categoryToEdit}
        />
      </div>
    </Layout>
  );
};

export default ArchivedAMC;
