import { notify } from '../../../utils/notification';
import apiEndpoints from '../../api';
import axiosInstance from '../../axiosInstance'
import {logOut} from '../../../utils/helpers/logOut'
import { errorMsg } from '../../../consts/responseMessages';
import getBrowserDetails from '../../../utils/helpers/getBrowserDetails';

export async function getParkingTypeListReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_PARKING_TYPE_LIST_API}?society_id=${action.data.societyId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getParkingTypeDetailsReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_PARKING_TYPE_DETAILS_API}?parking_type_id=${action.data.parkingTypeId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function addUpdateParkingTypeDetailsReq(action) {

    const BODY= {
        "parking_type_id": action.data.parkingTypeId || 0,
        "society_id": action.data.societyId,
        "title": action.data?.parkingType
      }

    return axiosInstance.post(apiEndpoints.ADD_UPDATE_PARKING_TYPE_DETAILS_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function deleteParkingTypeDetailsReq(action) {
    const BODY= {
        "parking_type_id": action.data.parkingTypeId
      }

    return axiosInstance.delete(apiEndpoints.DELETE_PARKING_TYPE_DETAILS_API, {data: BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getParkingChargesListReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_PARKING_CHARGE_LIST_API}?society_id=${action.data.societyId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function addUpdateParkingChargeReq(action) {

    const BODY= {
        "parking_charges_id": action.data?.parkingChargeId || 0,
        "society_id": action.data?.societyId,
        "w4_1parking": action.data?.data[0]?.description?.[0]?.value || 0,
        "w4_2parking": action.data?.data[0]?.description?.[1]?.value || 0,
        "w4_3parking": action.data?.data[0]?.description?.[2]?.value || 0,
        "w4_4parking": action.data?.data[0]?.description?.[3]?.value || 0,
        "w4_5parking": action.data?.data[0]?.description?.[4]?.value || 0,
        "w4_6parking": action.data?.data[0]?.description?.[5]?.value || 0,
        "w4_7parking": action.data?.data[0]?.description?.[6]?.value || 0,
        "w4_8parking": action.data?.data[0]?.description?.[7]?.value || 0,
        "w4_9parking": action.data?.data[0]?.description?.[8]?.value || 0,
        "w4_10parking": action.data?.data[0]?.description?.[9]?.value || 0,
        
        "w2_1parking": action.data?.data[1]?.description?.[0]?.value || 0,
        "w2_2parking": action.data?.data[1]?.description?.[1]?.value || 0,
        "w2_3parking": action.data?.data[1]?.description?.[2]?.value || 0,
        "w2_4parking": action.data?.data[1]?.description?.[3]?.value || 0,
        "w2_5parking": action.data?.data[1]?.description?.[4]?.value || 0,
        "w2_6parking": action.data?.data[1]?.description?.[5]?.value || 0,
        "w2_7parking": action.data?.data[1]?.description?.[6]?.value || 0,
        "w2_8parking": action.data?.data[1]?.description?.[7]?.value || 0,
        "w2_9parking": action.data?.data[1]?.description?.[8]?.value || 0,
        "w2_10parking": action.data?.data[1]?.description?.[9]?.value || 0,

        "otherw_1parking": action.data?.data[2]?.description?.[0]?.value || 0,
        "otherw_2parking": action.data?.data[2]?.description?.[1]?.value || 0,
        "otherw_3parking": action.data?.data[2]?.description?.[2]?.value || 0,
        "otherw_4parking": action.data?.data[2]?.description?.[3]?.value || 0,
        "otherw_5parking": action.data?.data[2]?.description?.[4]?.value || 0,
        "otherw_6parking": action.data?.data[2]?.description?.[5]?.value || 0,
        "otherw_7parking": action.data?.data[2]?.description?.[6]?.value || 0,
        "otherw_8parking": action.data?.data[2]?.description?.[7]?.value || 0,
        "otherw_9parking": action.data?.data[2]?.description?.[8]?.value || 0,
        "otherw_10parking": action.data?.data[2]?.description?.[9]?.value || 0,
        "browser_details": getBrowserDetails(),
        "session_id": action.data?.sessionId,
        "ip": action.data?.ipAddress
      }

    return axiosInstance.post(apiEndpoints.ADD_UPDATE_PARKING_CHARGE_LIST_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getParkingChargeAuditReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_PARKING_CHARGE_AUDIT_LOGS_API}?society_id=${action.data.societyId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}
