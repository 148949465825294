import * as type from "../../actions/index.types";

export function bookAFacilityReducer(
  state = {
    loading: false,
    loadingTimeSlot: false,
    loadingRateChart: false,
    updateLoader: false,
    facilityList: null,
    timeSlotList: null,
    frequencyList: null,
    facilityDetails: null,
    facilityAudit: null,
    rateChart: null,
    availabilityData: null,
    onlinepaymentLinkData: null,
    error: null,
    availabilityError: null,
  },
  action
) {
  switch (action.type) {
    case type.GET_FACILITY_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_FACILITY_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        facilityList: action.payload,
      };
    case type.GET_FACILITY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action?.message || "Somthing went wrong.",
        facilityList: null,
      };

    case type.GET_TIME_SLOT_LIST:
      return {
        ...state,
        error: null,
        loadingTimeSlot: true,
      };
    case type.GET_TIME_SLOT_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loadingTimeSlot: false,
        timeSlotList: action.payload,
      };
    case type.GET_TIME_SLOT_LIST_FAILURE:
      return {
        ...state,
        loadingTimeSlot: false,
        error: action?.message || "Somthing went wrong.",
        timeSlotList: null,
      };

    case type.GET_BOOKING_FREQUENCY_LIST:
      return {
        ...state,
        error: null,
      };
    case type.GET_BOOKING_FREQUENCY_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        frequencyList: action.payload,
      };
    case type.GET_BOOKING_FREQUENCY_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        frequencyList: null,
      };

    case type.GET_FACILITY_DETAILS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_FACILITY_DETAILS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        facilityDetails: action.payload,
      };
    case type.GET_FACILITY_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action?.message || "Somthing went wrong.",
        facilityDetails: null,
      };
  
    case type.UPDATE_FACILITY:
      return {
          ...state,
          updateLoader: true,
      };
    case type.UPDATE_FACILITY_SUCCESS:
    return {
        ...state,
        updateLoader: false,
    };
    case type.UPDATE_FACILITY_FAILURE:
      return {
          ...state,
          updateLoader: false,
      };
  
    case type.GET_FACILITY_AUDIT_LOGS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_FACILITY_AUDIT_LOGS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        facilityAudit: action.payload,
      };
    case type.GET_FACILITY_AUDIT_LOGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action?.message || "Somthing went wrong.",
        facilityAudit: null,
      };
          
    case type.DELETE_FACILITY:
      return {
          ...state,
          updateLoader: true,
      };
    case type.DELETE_FACILITY_SUCCESS:
    return {
        ...state,
        updateLoader: false,
    };
    case type.DELETE_FACILITY_FAILURE:
      return {
          ...state,
          updateLoader: false,
      };
  
    case type.ADD_UPDATE_TIME_SLOT:
      return {
          ...state,
          updateLoader: true,
      };
    case type.ADD_UPDATE_TIME_SLOT_SUCCESS:
    return {
        ...state,
        updateLoader: false,
    };
    case type.ADD_UPDATE_TIME_SLOT_FAILURE:
      return {
          ...state,
          updateLoader: false,
      };
  
    case type.GET_RATE_CHART:
      return {
        ...state,
        loadingRateChart: true,
        error: null,
      };
    case type.GET_RATE_CHART_SUCCESS:
      return {
        ...state,
        error: null,
        loadingRateChart: false,
        rateChart: action.payload,
      };
    case type.GET_RATE_CHART_FAILURE:
      return {
        ...state,
        loadingRateChart: false,
        error: action?.message || "Somthing went wrong.",
        rateChart: null,
      };

    case type.ADD_UPDATE_RATE_CHART:
      return {
          ...state,
          updateLoader: true,
      };
    case type.ADD_UPDATE_RATE_CHART_SUCCESS:
    return {
        ...state,
        updateLoader: false,
    };
    case type.ADD_UPDATE_RATE_CHART_FAILURE:
      return {
          ...state,
          updateLoader: false,
      };
      
    case type.CHECK_BOOKING_AVAILIBILITY:
      return {
        ...state,
        availabilityError: null,
      };
    case type.CHECK_BOOKING_AVAILIBILITY_SUCCESS:
      return {
        ...state,
        availabilityError: null,
        availabilityData: action.payload.available,
      };
    case type.CHECK_BOOKING_AVAILIBILITY_FAILURE:
      return {
        ...state,
        availabilityError: action?.payload?.error,
        availabilityData: action.payload.available,
      };
    case type.CLEAR_CHECK_BOOKING_AVAILIBILITY_DATA:
      return {
        ...state,
        availabilityError: null,
        availabilityData: null,
      };
  
          
    case type.ADD_BOOKING:
      return {
          ...state,
          updateLoader: true,
          
      };
    case type.ADD_BOOKING_SUCCESS:
    return {
        ...state,
        updateLoader: false,
    };
    case type.ADD_BOOKING_FAILURE:
      return {
          ...state,
          updateLoader: false,
      };
    
    case type.CHANGE_FACILITY_STATUS:
      return {
          ...state,
          updateLoader: true,
      };
    case type.CHANGE_FACILITY_STATUS_SUCCESS:
    return {
        ...state,
        updateLoader: false,
        facilityList: {
          ...state.facilityList,
          facilty_list: state.facilityList?.facilty_list?.map( (facility) => action.payload.facilityId === facility.id
          ? {...facility, status: action.payload.status === 'I' ? 'Inactive' : 'Active'}
          : facility
        )
      } 
    }
    case type.CHANGE_FACILITY_STATUS_FAILURE:
      return {
          ...state,
          updateLoader: false,
      };
  
    
    case type.DELETE_TIME_SLOT:
      return {
          ...state,
          updateLoader: true,
      };
    case type.DELETE_TIME_SLOT_SUCCESS:
    return {
        ...state,
        updateLoader: false,
    };
    case type.DELETE_TIME_SLOT_FAILURE:
      return {
          ...state,
          updateLoader: false,
      };

      case type.DELETE_RATE_CHART:
        return {
            ...state,
            updateLoader: true,
        };
      case type.DELETE_RATE_CHART_SUCCESS:
      return {
          ...state,
          updateLoader: false,
      };
      case type.DELETE_RATE_CHART_FAILURE:
        return {
            ...state,
            updateLoader: false,
        };

    
    case type.SEND_MESSAGE_TO_ADMINISTRATOR:
      return {
          ...state,
          updateLoader: true,
      };
    case type.SEND_MESSAGE_TO_ADMINISTRATOR_SUCCESS:
    return {
        ...state,
        updateLoader: false,
    };
    case type.SEND_MESSAGE_TO_ADMINISTRATOR_FAILURE:
      return {
          ...state,
          updateLoader: false,
      };

      case type.GET_FACILTIY_ONLINE_PAYMENT_LINK:
        return {
            ...state,
            loading: true,
            error: null,
            onlinepaymentLinkData: null
        };
      case type.GET_FACILTIY_ONLINE_PAYMENT_LINK_SUCCESS:
        return {
            ...state,
            error: null,
            loading: false,
            onlinepaymentLinkData: action.payload,
        };
      case type.GET_FACILTIY_ONLINE_PAYMENT_LINK_FAILURE:
        return {
            ...state,
            loading: false,
            error: action?.message || "Somthing went wrong.",
            onlinepaymentLinkData: null,
        };

    default:
      return state;
    }
}
