import { notify } from '../../../../utils/notification';
import apiEndpoints from '../../../api';
import axiosInstance from '../../../axiosInstance'
import {logOut} from '../../../../utils/helpers/logOut'
import getBrowserDetails from '../../../../utils/helpers/getBrowserDetails'
import moment from 'moment';
import { errorMsg } from '../../../../consts/responseMessages';

export async function getManagingCommitteeDetailedListReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_MANAGING_COMMITTIEE_DETAILED_LIST_API}?society_id=${action.data.societyId}&page_no=${action.data.page}&page_limit=${action.data.pageLimit}${action.data.searchText ? `&search_text=${encodeURIComponent(action.data.searchText)}` : '' }${action.data.formationDate ? `&fomation_date=${encodeURIComponent(action.data.formationDate)}` : '' }`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function addManagingCommitteeReq(action) {
    const BODY= {
        "society_id": action.data?.societyId,
        "committee_name": action.data?.name,
        "formation_date": action.data?.formationDate,
        "resolution_doc": action.data?.resolutionDoc || '',
        "browser_details": getBrowserDetails(),
        "session_id": action.data?.sessionId,
        "ip": action.data?.ipAddress,
        "members_list": action.data?.selectedOccupants?.filter((occupant)=> occupant.selected)?.map((occupant)=>({
            "member_id": occupant?.memberId,
            "join_date": occupant?.joinDate || action.data?.formationDate,
            "leave_date": occupant?.leaveDate || "0000-00-00",
            "roles": occupant?.roles,
            "title": occupant?.title
        }))
      }

    return axiosInstance.post(apiEndpoints.ADD_MANAGING_COMMITTIEE_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}


export async function getOccupantsForManagingCommitteeReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_OCCUPANTS_FOR_MANAGING_COMMITTIEE_API}?society_id=${action.data.societyId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getAllManagingCommitteeAuditLogReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_ALL_COMMITTEE_AUDIT_LOG_API}?society_id=${action.data.societyId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getManagingCommitteeAuditLogReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_MANAGING_COMMITTIEE_AUDIT_LOGS_API}?committee_id=${action.data.committeeId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getManagingCommitteeDetailsReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_MANAGING_COMMITTIEE_DETAILS_API}?committee_id=${action.data.committeeId}${action.data.searchText ? `&search_text=${encodeURIComponent(action.data.searchText)}` : '' }${action.data.joinDate ? `&join_date=${encodeURIComponent(action.data.joinDate)}` : '' }${action.data.leaveDate ? `&leave_date=${encodeURIComponent(action.data.leaveDate)}` : '' }`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}


export async function getManagingCommitteeArhiveMemberReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_ARCHIVE_MANAGING_COMMITTIEE_MEMBER_API}?society_id=${action.data.societyId}${action.data.searchText ? `&search_text=${encodeURIComponent(action.data.searchText)}` : '' }${action.data.joinDate ? `&join_date=${encodeURIComponent(action.data.joinDate)}` : '' }${action.data.leaveDate ? `&leave_date=${encodeURIComponent(action.data.leaveDate)}` : '' }`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function updateManagingCommitteeReq(action) {
    const BODY= {
        "society_id": action.data?.societyId,
        "committee_id": parseInt(action.data?.committeeId),
        "committee_name": action.data?.name,
        "formation_date": action.data?.formationDate,
        "resolution_doc": action.data?.resolutionDoc || '',
        "browser_details": getBrowserDetails(),
        "session_id": action.data?.sessionId,
        "ip": action.data?.ipAddress,
        "members_list": action.data.selectedOccupants?.filter(item=>item.selected || item.status !=='')?.map(item=> ({
            "role_data_id": item?.roleId || 0,
            "member_id": item?.memberId,
            "join_date": item?.joinDate || moment(action.data?.formationDate)?.format('YYYY-MM-DD'),
            "leave_date": item?.leaveDate || '0000-00-00',
            "roles": item?.roles,
            "title": item?.title,
            "status": item?.status || 'A'
        }))
      }

    return axiosInstance.put(apiEndpoints.UPDATE_MANAGING_COMMITTIEE_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getManagingCommitteeAccessSettingReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_MANAGING_COMMITTIEE_ACCESS_SETTING_API}?committee_id=${action.data.committeeId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function updateManagingCommitteeAccessSettingReq(action) {
    const BODY= {
        "committee_id": action.data?.committeeId,
        "access": action.data?.accessSetting
      }

    return axiosInstance.put(apiEndpoints.UPDATE_MANAGING_COMMITTIEE_ACCESS_SETTING_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function deleteManagingCommitteeReq(action) {
    const BODY= {
        "committee_ids": action.data?.committeeIds
      }
      
      return axiosInstance.delete(apiEndpoints.DELETE_MANAGING_COMMITTIEE_API, {data: BODY})
      .then(response => {
          return response;
      }).catch((errors) => {
          console.log("errors", errors);
          if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
              logOut()
          }else {
              notify('warning', "Something went wrong");
          }
          return errors

      })
}

export async function resignFromManagingCommitteeReq(action) {
    const BODY= {
        "role_data_id": action.data?.roleId,
        "committee_id": action.data?.committeeId,
        "resign_date": action.data?.dateOfResign,
        "current_date": moment().format('YYYY-MM-DD'),
        "resign_letter": action.data?.reason,
        "resign_letter_html": action?.data?.reasonHTML,
        "approving_auth": action.data?.authority,
        "send_noti_other_cm": action.data?.sendNotification,
        "is_aproval_required": action.data?.approvalReqired,
        "browser_details": getBrowserDetails(),
        "session_id": action.data?.sessionId,
        "ip": action.data?.ipAddress,
        "member_email": "string"
      }


    return axiosInstance.post(apiEndpoints.RESIGN_MANAGING_COMMITTIEE_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function retireFromManagingCommitteeReq(action) {
    const BODY= {
        "role_data_id": action.data?.roleId,
        "committee_id": action.data?.committeeId,
        "retire_date": action.data?.dateOfRetire,
        "current_date": moment().format('YYYY-MM-DD'),
        "retire_reason": action.data.reason,
        "send_noti_other_cm": action.data?.sendNotification,
        "is_aproval_required": action.data?.approvalReqired,
        "member_name": action.data.memberName,
        "member_flats": action.data.memberFlat,
        "member_email": action.data.memberEmail
    }

    return axiosInstance.post(apiEndpoints.RETIRE_MANAGING_COMMITTIEE_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getArchivedCommitteeMemberDataReq(action) {
    return axiosInstance.get(`${apiEndpoints.VIEW_ARCHIVED_MANAGING_COMMITTEE_MEMBER_API}?committee_id=${action.data.committeeId}&role_data_id=${action.data.roleId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}


export async function deleteArchivedCommitteeMemberReq(action) {
    const BODY= {
        "role_data_id": action.data.roleId
      }
      
      return axiosInstance.delete(apiEndpoints.DELETE_ARCHIVED_MANAGING_COMMITTEE_MEMBER_API, {data: BODY})
      .then(response => {
          return response;
      }).catch((errors) => {
          console.log("errors", errors);
          if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
              logOut()
          }else {
              notify('warning', "Something went wrong");
          }
          return errors

      })
}


export async function approveAndRejectCommitteeMemberResignationReq(action) {
    const BODY= {
        "role_data_id": action.data?.roleId,
        "committee_id": action.data?.committeeId,
        "effective_date": action.data?.effectiveDate || moment().format('YYYY-MM-YY'),
        "remark": action.data?.remarks || 'r',
        "approval_doc": action.data?.approveDoc || '',
        "send_noti_other_cm": action.data?.sendEmail,
        "is_approve": action.data?.isApprove,
        "member_name": action.data?.memberName,
        "member_flats": action.data?.flat,
        "member_email": action.data?.email
      }

    return axiosInstance.post(apiEndpoints.APPROVE_AND_REJECT_MANAGING_COMMITTEE_MEMBER_RESIGNATION_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function approveCommitteeMemberRetirementReq(action) {
    console.log(action.data)
    const BODY= {
        "role_data_id": action.data?.roleId,
        "committee_id": action.data?.committeeId,
        "effective_date": moment().format('YYYY-MM-DD'),
        "member_name": action.data?.memberName,
        "member_flats": action.data?.memberFlat,
        "member_email": action.data?.memberEmail
      }

    return axiosInstance.post(apiEndpoints.APPROVE_MANAGING_COMMITTEE_MEMBER_RETIREMENT_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function reappointCommitteeMemberRetirementReq(action) {
    const BODY= {
        "role_data_id": action.data?.roleId,
        "committee_id": action.data?.committeeId,
        "join_date": action.data?.fromDate,
        "leave_date": action.data?.toDate,
        "req_doc": action.data?.requiredDoc,
        "send_noti_other_cm": action.data?.notifyOthers,
        "responsibility": action.data?.role,
        "member_name": action.data?.memberName,
        "member_flats": action.data?.flat,
        "member_email": action.data?.email
      }

    return axiosInstance.post(apiEndpoints.REAPPOINT_MANAGING_COMMITTEE_MEMBER_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}