import React from "react";
import {
  MAKE_PAYMENT_ENDPOINT,
  MANAGE_INVOICE_CURRENT_ENDPOINT,
} from "../../../Routing/routes";
import Layout from "../../Layout/Layout";
import { Navigate, useLocation } from "react-router-dom";

const PaymentSuccess = () => {
  const data = useLocation().state;
  if (data)
    return (
      <Layout>
        <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg mt-3">
        <div className="flex items-center justify-center min-h-screen">
          <div className="bg-white p-8 border w-full max-w-md">
            <h2 className="text-2xl font-bold text-green-600 mb-4">
              Payment Successful!
            </h2>
            <div className="mb-4">
              <span className="font-semibold">Invoice Number:</span>
              <span className="ml-2 text-gray-700">{data?.invoice_no}</span>
            </div>
            <div className="mb-4">
              <span className="font-semibold">Transaction ID:</span>
              <span className="ml-2 text-gray-700">{data?.trans_id}</span>
            </div>
            <div className="mb-4">
              <span className="font-semibold">Total Amount:</span>
              <span className="ml-2 text-gray-700">{data?.total_amount}</span>
            </div>
            <div className="mb-4">
              <span className="font-semibold">Total Paid:</span>
              <span className="ml-2 text-gray-700">{data?.total_paid}</span>
            </div>
            <div className="mb-4">
              <span className="font-semibold">Receipt Number:</span>
              <span className="ml-2 text-gray-700">{data?.reciept_no}</span>
            </div>
            <button
              onClick={() =>
                (window.location.href = MANAGE_INVOICE_CURRENT_ENDPOINT)
              }
              className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition duration-300"
            >
              Go to Homepage
            </button>
          </div>
        </div>
        </div>
      </Layout>
    );
  else return <Navigate to={MAKE_PAYMENT_ENDPOINT} />;
};

export default PaymentSuccess;
