import React, { useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/closeIcon.svg";
import Button from "../../../../components/Button/Button";
import RadioButtonsGroup from "../../../../components/InputFields/RadioButtonsGroup";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateIDCardFieldsData } from "../../../../redux/actions/SetupRecordsActions/StaffManagementActions/StaffPersonnelAction";
import { CircularProgress } from "@mui/material";

const options = [
    { value: 'Y', label: 'Yes' },
    { value: 'N', label: 'No' },
    // { value: 'disabled', label: 'Other', disabled: true },
  ];

const IdCardFieldsModal = ({ isOpen, onClose, tenantName, onDelete }) => {
  const dispatch = useDispatch()
  const {updateLoader, idCardFieldsData} = useSelector(state=>state.staffPersonnelReducer)
  const {loginData} = useSelector(state=>state.loginReducer)
  const [data, setData] = useState({
    "id": 0,
    "name": "",
    "mobile": "",
    "tenure": "",
    "flat_details": "",
    "vehicle_details": "",
    "profile": "",
    "dept": ""
})

  const handleCheck = (e, key) =>{
    setData(prev=> {
      return {...prev, [key]: e.target.value}
    })
  }

  const handleSubmit =()=>{
    dispatch(updateIDCardFieldsData({
      idData: data, 
      societyId: loginData?.default_community?.community_id,
      onSuccessCallback: ()=>{
        onClose()
      }
    }))
  }

  useEffect(()=>{
    if(!Array.isArray(idCardFieldsData))
      setData(idCardFieldsData)
  }, [idCardFieldsData])

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-60 z-20 ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="bg-white rounded-lg w-[420px] h-[350px]">
        <div className="w-full flex">
          <div className="w-[30%]"></div>
          <div className="flex justify-between p-2 items-center -mb-2 w-3/4">
            <h2 className="p-2 text-lg text-center font-semibold mb-2">
              ID card Fields
            </h2>
            <button
              onClick={()=>{onClose(); setData(idCardFieldsData)}}
              className="bg-[#EAEAEA] rounded-full p-2 items-center mb-2 w-9 h-9"
            >
              <CloseIcon className="fill-current text-gray-600 hover:text-gray-800 ml-1 w-3 h-3 cursor-pointer" />
            </button>
          </div>
        </div>
        <div className="border-b border-[#CCCCCC]"></div>
        {
          data &&
          <div className="p-2">
            <div className="px-4">
              <RadioButtonsGroup label="Staff Name" options={options} value={data?.name} onChange={(e)=>{handleCheck(e, 'name')}} />
              <RadioButtonsGroup label="Profile" options={options} value={data?.profile} onChange={(e)=>{handleCheck(e, 'profile')}} />
              <RadioButtonsGroup label="Department" options={options} value={data?.dept} onChange={(e)=>{handleCheck(e, 'dept')}} />
              <RadioButtonsGroup label="Staff Contact Number" options={options} value={data?.mobile} onChange={(e)=>{handleCheck(e, 'mobile')}} />
              <RadioButtonsGroup label="Tenure" options={options} value={data?.tenure} onChange={(e)=>{handleCheck(e, 'tenure')}} />
              <RadioButtonsGroup label="Flat Details" options={options} value={data?.flat_details} onChange={(e)=>{handleCheck(e, 'flat_details')}} />
              <RadioButtonsGroup label="Vehicle Details" options={options} value={data?.vehicle_details} onChange={(e)=>{handleCheck(e, 'vehicle_details')}} />
            </div>
            <div className="border-b border-[#CCCCCC] mt-5"></div>

            <div className="flex justify-center mt-4">
              <Button
                label={
                  <span className="w-8 inline-block">
                    {
                      updateLoader ?
                        <CircularProgress sx={{color: "white"}} size={17}/>
                        :
                        'Apply'
                      }
                  </span>
                }
                className="h-9 px-5 font-semibold "
                onClick={handleSubmit}
              />
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default IdCardFieldsModal;
