import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import ButtonG from "../../../../components/Button/ButtonG";
import Button from "../../../../components/Button/Button";
import { TextAreaFM } from "../../../../components/InputFields/TextArea";
import Layout from "../../../Layout/Layout";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getManagingCommitteeDetails, retireFromManagingCommittee } from "../../../../redux/actions/SetupRecordsActions/StaffManagementActions/ManangingCommitteeAction";
import { Form, Formik } from "formik";
import { RetireLetterValidation } from "../../../../validationSchema/setupRecordsSchema/staffManagementSchema";
import { CircularProgress } from "@mui/material";
import Loader from "../../../../components/Loader/Loader";

function RetireManagingCommitteeMember({ action }) {
  const navigate = useNavigate();
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading, updateLoader, managingCommitteeData} = useSelector(state=> state.managingCommitteeReducer)
  const {ipAddress} = useSelector(state=>state.ipAddressReducer)
  const dispatch = useDispatch()
  const {id, roleId} = useParams()
  const [data, setData]= useState(null)

  const handleSubmit = (values, {resetForm}) => {
    dispatch(retireFromManagingCommittee({
      ...values, 
      memberName:data?.subscriber_name,
      memberFlat: data?.flat_no,
      memberEmail: data?.member_email,
      roleId: parseInt(roleId),
      committeeId: parseInt(id),
      ipAddress,
      societyId: loginData?.default_community?.community_id,
      sessionId: loginData?.session_id,
      type: 'RET',
      onSuccessCallback: ()=>{
        navigate(-1)
      }
    }))
  };

  useEffect(()=>{      
    dispatch(getManagingCommitteeDetails({committeeId: id}))
  }, [])

  useEffect(()=>{      
    if(managingCommitteeData){
      setData(managingCommitteeData?.members?.find(item=>item.role_data_id?.toString() === roleId && item.resign_retire_status === 'No'))
    }
  }, [managingCommitteeData])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          initialValues={{
            dateOfRetire: '',
            reason: '',
            sendNotification: 0,
            approvalReqired: 'Yes',
          }}
          enableReinitialize
          onSubmit={handleSubmit}
          validationSchema={RetireLetterValidation}
          >    
            {
              ({values, errors, setFieldValue})=>{
                return (
                  <Form>
                    <div className="border-b border-[#CCC]">
                      <ListHeaderBack
                        title={"Retire Managing Committee Member"}
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        <ButtonG
                          label="Cancel"
                          className="h-8"
                          onClick={() => {
                            navigate(-1);
                          }}
                        />
                        <Button label={
                          <span className="w-12 inline-block">
                            {
                              updateLoader ?
                                <CircularProgress sx={{color: "white"}} size={17}/>
                                :
                                'Submit'
                              }
                          </span>
                        } className="h-8"
                        disabled={loading || updateLoader} />
                      </ListHeaderBack>
                    </div>
                    {
                      loading ?
                      <Loader/>
                      :
                      <>
                      {
                        data &&
                        <div className="p-1 py-3 gap-2 m-1">
                          <div className="w-1/2 text-sm">
                            <div className="flex justify-between">
                              <span  className="mt-2">Retire Date</span>
                              <DatePickerComponentFM
                                className="w-52 h-9 ml-4" 
                                name='dateOfRetire'
                                onDateChange={(selectedDate) => {
                                    setFieldValue('dateOfRetire', selectedDate)
                                  }}
                                  minDate={new Date()}
                                  defaultValue={values.dateOfRetire ? new Date(values.dateOfRetire) : null}
                                />
                            </div>
                            <TextAreaFM
                              label={"Retire Reason:"}
                              placeholder={"Enter"}
                              className={"w-52"}
                              row={5}
                              name='reason'
                            />
                            <div className="mb-3">
                              <Checkbox
                                text="Send notification to other Committee Members."
                                className="text-sm"
                                initialchecked={values.sendNotification === 1}
                                onChange={(e) => {setFieldValue('sendNotification', e.target.checked ? 1 : 0)}}
                              />
                            </div>
                            <div className="">
                              <Checkbox
                                text="Approval Required."
                                className="text-sm"
                                initialchecked={values.approvalReqired === 'Yes'}
                                onChange={(e) => {setFieldValue('approvalReqired', e.target.checked ? 'Yes' : 'No')}}
                              />
                            </div>
                          </div>
                        </div>
                      }
                      </>
                    }
                  </Form>
                )
              }
            }
          </Formik>
      </div>
    </Layout>
  );
}

export default RetireManagingCommitteeMember;
