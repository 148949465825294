import React, { memo, useCallback, useEffect, useState } from 'react'
import { getFirebaseURL } from '../../firebase/firebaseFunction';
import { Skeleton } from '@mui/material';
import { DefaultLogo } from '../../assets';

function ImageComponent({width, height, defaultUrl}) {
    const [url, setUrl] = useState('')
    const [loader, setLoader] = useState(true);

    const getURL = useCallback(async()=>{
        const url = await getFirebaseURL(defaultUrl);
        setLoader(prev=>{
          setUrl(url)
          return false;
        })
    }, [defaultUrl]) 

    useEffect(()=>{
        if(defaultUrl?.includes('https:')){
          setUrl(defaultUrl) 
          setLoader(false)
        }else if(defaultUrl){
          getURL()
        }else{
          setUrl(null)
          setLoader(false)
        }
      }, [defaultUrl])

    return (
      <div className={`w-${width} h-${height} rounded object-cover`}>
        {
          loader ?
          <Skeleton variant="rounded" width={`${width/4}rem`} height={`${height/4}rem`} />
          :
          <>
          {
            url ?
            <img
              src={url || DefaultLogo}
              alt={''}
              className={`w-${width} h-${height} rounded object-cover`}
            />
            :
            <DefaultLogo className={`w-${width} h-${height}`} />
          }
          </>
        }
      </div>
    )
}

export default memo(ImageComponent)