import React from "react";
import Layout from "../../../Layout/Layout";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Search from "../../../../components/SearchBox/Search";
import GenericTable from "../../../../components/TableComponent/Table";
// import { Box, Tooltip } from "@mui/material";


// function ActionComponent({ id }) {
//   const navigate = useNavigate();

//   return (
//     <Box sx={{ display: "flex", alignItems: "center" }}>
//       <Tooltip title="View">
//         <ViewIcon
//           className="fill-[#555] hover:fill-red-650 me-3"
//           onClick={() => {
//             navigate(`${VIEW_NOTE_CAUSE_ENDPOINT}/${id}`);
//           }}
//         />
//       </Tooltip>
//       <Tooltip title="Edit">
//         <EditIcon
//           className="fill-[#555] hover:fill-red-650 me-3"
//           onClick={() => {
//             navigate(`${EDIT_CAUSE_ENDPOINT}/${id}`); 
//           }}
//         />
//       </Tooltip>
//       <Tooltip title="Audit Logs">
//         <AuditIcon
//           className="fill-[#555] hover:fill-red-650 me-3"
//           onClick={() => {
//             navigate(`${CREDIT_DEBIT_NOTE_CAUSE_AUDIT_LOGS_ENDPOINT}`);
//           }}
//         />
//       </Tooltip>
//     </Box>
//   );
// }

const RevokeList = () => {

  const rows = [
    {
      id: 1,
      sNo: 1,
      orderNo: "034",
      type: "Debit Note",
      description: "N/A",
      status: "XXXX.XX",
    },
    {
      id: 2,
      sNo: 2,
      orderNo: "035",
      type: "Debit Note",
      description: "N/A",
      status: "XXXX.XX",
    },
    {
      id: 3,
      sNo: 3,
      orderNo: "036",
      type: "Debit Note",
      description: "N/A",
      status: "XXXX.XX",
    },
  ];

  const headCells = [
    { id: "sNo", label: "S No.", sort: false, width: '70px' },
    { id: "orderNo", label: "Order No.", sort: true, width: '600px' },
    { id: "type", label: "Type", sort: true, width: '200px'},
    {
      id: "action",
      label: "Action",
      sort: false,
    },
  ];

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
          <ListHeader leftContent="Revoke List">
          <Search height="8" className="-ml-2" />
          </ListHeader>

        <div className="ml-1">
          <GenericTable
            data={rows}
            columns={headCells}
            checkbox={false}
            pagination={rows.length}
            totalCount={rows.length}
            totalPages={2}
            start={1}
            end={10}
            currentPage={1}
            getTableData={(rowsPerPage, page) => {
              console.log(rowsPerPage, page);
            }}
          >
            {/* <ActionComponent /> */}
          </GenericTable>
        </div>

        {!rows?.length && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
    </Layout>
  );
};

export default RevokeList;
