import React from 'react'
import Checkbox from '../../../../components/Checkbox/Checkbox';
import { TextInput } from '../../../../components/InputFields/TextInput';
import {DatePickerComponent} from '../../../../components/DatePicker/DatePicker';
import moment from 'moment';

const headCells = [
    { id: "type", label: "Type", sort: false, width: "110px" },
    { id: "memberName", label: "Member Name", sort: false, width: "200px" },
    { id: "creditNoteDate", label: "Date", sort: false, width: "160px"  },
    { id: "hardCopy", label: "Debit A/C", sort: false, width: "160px"  },
    { id: "referenceInvoice", label: "Amount (Rs.)", sort: false, width: "140px"  },
    { id: "gstApplicable", label: "Hard copy voucher	", sort: false, width: "140px"  },
  ];

  const headCells2 = [
    { id: "type", label: "Remark", sort: false, width: "310px" },
    { id: "memberName", label: "Cheque Number", sort: false, width: "160px" },
    { id: "creditNoteDate", label: "Bank Name", sort: false, width: "160px"  },
    { id: "hardCopy", label: "Cheque Date	", sort: false, width: "140px"  },
    { id: "referenceInvoice", label: "Payment Date", sort: false, width: "140px"  },
  ];



function ExcelDataTable({uploadingExcelData, setUploadingExcelData}) {

  return (
    <div>
        <div className="m-2">
            <Checkbox 
                initialchecked={!uploadingExcelData?.find(item=> !item.selected)}
                onChange={(e)=>{
                    setUploadingExcelData(prev=>{
                        let newData= [...prev]
                        newData.forEach(item=>{
                            item.selected=e.target.checked;
                        })
                        return newData
                    })
                }}
                />
                <span className="ms-2 text-[#AAAAAA] text-[13px] font-semibold">Select All</span>
        </div>
        {
            uploadingExcelData?.map((item, index)=>(
                item.error ?
                <div className='mb-7'>
                    <table className='w-full'>
                        <tbody>
                            <tr>
                                <td className='py-3 px-2 w-[35px]'>
                                    <Checkbox
                                        initialchecked={false} 
                                        disabled={true}
                                    />
                                </td>
                                <td className="py-4 text-xs font-semibold text-justify break-all cursor-pointer text-red-650 text-wrap">
                                    {item.massage}
                                </td>
                            </tr>
                        </tbody>
                </table>
                </div>
                :
                <div className='mb-7'>
                <table className='w-full'>
                    <thead className="text-[#AAAAAA] font-semibold" >
                        <tr>
                            <th className={`border-y p-2 text-[13px] border-[#DDD] text-left w-[35px]`}>
                                
                                <Checkbox
                                    initialchecked={item.selected} 
                                    onChange={(e)=>{
                                        setUploadingExcelData(prev=>{
                                            let newData = [...prev]
                                            newData[index].selected = e.target.checked;
                                            return newData;
                                        })
                                    }}
                                />
                            </th>
                            {
                                headCells?.map((item)=>(
                                <th className={`border-y py-2 text-[13px] border-[#DDD] text-left w-[${item.width}]`} >{item.label}</th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='align-top bg-[#F4F5F8]'>
                            <td className='px-2 py-3'>
                                
                            </td>
                            <td className='py-3'>
                                <TextInput
                                    className='w-24'
                                    value={item.type}
                                    onChange={(e)=>{
                                        setUploadingExcelData(prev=>{
                                            let newData = [...prev]
                                            newData[index].type = e.target.value;
                                            return newData;
                                        })
                                    }}
                                />
                            </td>
                            <td className='py-3 text-[13px]'>
                                {item.member_glacc}
                            </td>
                            <td className='py-3 text-[13px]'>
                                <DatePickerComponent
                                    defaultValue={item?.date ? new Date(item.date) : null}
                                    className='w-36 h-9'
                                    onDateChange={(selectedDate)=>{
                                        setUploadingExcelData(prev=>{
                                            let newData = [...prev]
                                            newData[index].date = moment(selectedDate).format('YYYY-MM-DD');
                                            return newData;
                                        })
                                    }}
                                />
                            </td>
                            <td className='py-3 text-[13px]'>
                                {item.debit_ac_name}
                            </td>
                            <td className='py-3'>
                                <TextInput
                                        className='w-32'
                                        value={item.amount}
                                        type='decimal'
                                        onChange={(e)=>{
                                            setUploadingExcelData(prev=>{
                                                let newData = [...prev]
                                                newData[index].amount = e.target.value;
                                                return newData;
                                            })
                                        }}
                                    />
                            </td>
                            <td className='py-3 text-[13px]'>
                                <TextInput
                                        className='w-32'
                                        value={item.voucher_no}
                                        type='decimal'
                                        onChange={(e)=>{
                                            setUploadingExcelData(prev=>{
                                                let newData = [...prev]
                                                newData[index].voucher_no = e.target.value;
                                                return newData;
                                            })
                                        }}
                                    />
                            </td>

                        </tr>
                    </tbody>
                </table>
                <table className='w-full'>
                    <thead className="text-[#AAAAAA] font-semibold" >
                        <tr>
                            <th className={`border-y p-2 text-[13px] border-[#DDD] text-left w-[35px]`}>
                                
                            </th>
                            {
                                headCells2?.map((item)=>(
                                <th className={`border-y py-2 text-[13px] border-[#DDD] text-left w-[${item.width}]`} >{item.label}</th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='align-top bg-[#F4F5F8]'>
                            <td className='px-2 py-3'>
                                
                            </td>
                            <td className='py-3'>
                                <TextInput
                                    className='w-72'
                                    value={item.remark}
                                    onChange={(e)=>{
                                        setUploadingExcelData(prev=>{
                                            let newData = [...prev]
                                            newData[index].remark = e.target.value;
                                            return newData;
                                        })
                                    }}
                                />
                            </td>
                            <td className='py-3 text-[13px]'>
                                <TextInput
                                        className='w-36'
                                        value={item.cheque_number}
                                        onChange={(e)=>{
                                            setUploadingExcelData(prev=>{
                                                let newData = [...prev]
                                                newData[index].cheque_number = e.target.value;
                                                return newData;
                                            })
                                        }}
                                    />
                            </td>
                            <td className='py-3 text-[13px]'>
                                <TextInput
                                    className='w-36'
                                    value={item.bank_name}
                                    onChange={(e)=>{
                                        setUploadingExcelData(prev=>{
                                            let newData = [...prev]
                                            newData[index].bank_name = e.target.value;
                                            return newData;
                                        })
                                    }}
                                />
                            </td>
                            <td className='py-3 text-[13px]'>
                                <DatePickerComponent
                                    defaultValue={item.cheque_date ? new Date(item.cheque_date) : null}
                                    className='w-32 h-9'
                                    onDateChange={(selectedDate)=>{
                                        setUploadingExcelData(prev=>{
                                            let newData = [...prev]
                                            newData[index].cheque_date = moment(selectedDate).format('YYYY-MM-DD');
                                            return newData;
                                        })
                                    }}
                                />
                            </td>
                            <td className='py-3 text-[13px]'>
                                <DatePickerComponent
                                    defaultValue={item.payment_date ? new Date(item.payment_date) : null}
                                    className='w-32 h-9'
                                    onDateChange={(selectedDate)=>{
                                        setUploadingExcelData(prev=>{
                                            let newData = [...prev]
                                            newData[index].payment_date = moment(selectedDate).format('YYYY-MM-DD');
                                            return newData;
                                        })
                                    }}
                                />
                            </td>

                        </tr>
                    </tbody>
                </table>
                </div>
            ))
        }

    </div>
  )
}

export default ExcelDataTable