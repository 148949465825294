import { ErrorMessage } from "formik";
import React, { useState, useEffect, useRef } from "react";

const Dropdown = ({ options, width, onSelect, className, placeholder, height, defaultValue}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsOpen(false);
    if(onSelect){
      onSelect(item);
    }
  };

  const closeDropdown = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeDropdown);
    return () => {
      document.removeEventListener("click", closeDropdown);
    };
  }, []);
  
  // Determine the maximum height based on the number of options
  const maxHeight = options?.length > 4 ? 150 : "none";

  return (
    <div className="relative inline-block text-center" ref={dropdownRef}>
      <div>
        <button
          type="button"
          className={`${className} inline-flex justify-between items-center px-2 py-2 text-[11px] text-[] font-semibold
           bg-[#F9FAFC] border border-[#CCCCCC]
          rounded-md hover:bg-[#F4F5F8] focus:outline-none w-${width} h-${height} ${
            isOpen ? "border-b" : ""
          }`}
          onClick={toggleDropdown}
        >
          {selectedItem.label || defaultValue?.label || placeholder}
          <svg
            className={`h-5 w-5 transform ${
              isOpen ? "rotate-180" : ""
            } transition-transform duration-300`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div
          className={`origin-top-right z-20 absolute right-0 mt-2 ml-2 w-full overflow-y-auto rounded-md border bg-white ring-1 
          ring-black ring-opacity-5 focus:outline-none`}
          style={{ maxHeight: maxHeight }}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div role="none">
            {options.map((option, i) => (
              <button
              type='button'
                key={option.label}
                className={`${i === options?.label?.length - 1 ? "" : "border-b"} block px-2 py-2 text-xs font-semibold w-full text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900 `}
                role="menuitem"
                onClick={() => handleItemClick(option)}
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export const DropdownFM =({ options, width, onSelect, className, placeholder, height, defaultValue, name,}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsOpen(false);
    if(onSelect){
      onSelect(item);
    }
  }

  const closeDropdown = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeDropdown);
    return () => {
      document.removeEventListener("click", closeDropdown);
    };
  }, []);

  useEffect(() => {
    setSelectedItem(defaultValue)
  }, [defaultValue]);
  
  // Determine the maximum height based on the number of options
  const maxHeight = options?.length > 4 ? 150 : "none";

  return (
    <div className="mb-1">
      <div className="relative inline-block text-center" ref={dropdownRef}>
      <div>
        <button
          type="button"
          className={`${className} inline-flex justify-between items-center px-2 py-2 text-[11px] text-[] font-semibold
           bg-[#F9FAFC] border border-[#CCCCCC]
          rounded-md hover:bg-[#F4F5F8] focus:outline-none w-${width} h-${height} ${
            isOpen ? "border-b" : ""
          }`}
          onClick={toggleDropdown}
        >
          {selectedItem?.label || placeholder}
          <svg
            className={`h-5 w-5 transform ${
              isOpen ? "rotate-180" : ""
            } transition-transform duration-300`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div
          className={`origin-top-right z-20 absolute right-0 mt-2 ml-2 w-full overflow-y-auto rounded-md border bg-white ring-1 
          ring-black ring-opacity-5 focus:outline-none`}
          style={{ maxHeight: maxHeight }}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div role="none">
            {options?.map((option, i) => (
              <button
              type='button'
                key={option.label}
                className={`${i === options?.label?.length - 1 ? "" : "border-b"} block px-2 py-2 text-xs font-semibold w-full text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900 `}
                role="menuitem"
                onClick={() => handleItemClick(option)}
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
      )}
      </div>
      <div className="h-4 text-xs italic text-red-500 ">
        <ErrorMessage name={name}/>
      </div>
    </div>
  );
}


export default Dropdown;
