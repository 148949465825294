import React, { useEffect } from 'react';
import QRCode from "qrcode.react";
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getMaintenanceStaffDetails } from '../../redux/actions/SetupRecordsActions/StaffManagementActions/MaintenanceAgencyAction';
import Loader from '../Loader/Loader';
import ImageComponent from '../ImageComponent/ImageComponent'

const IdCard = ({ societyName, jobName, imageURL, name, staffType, mobileNo, employeeCode, qrCode }) => {
  return (
    <div className="w-[340px] mx-auto bg-white shadow-lg overflow-hidden border border-[#FAFAFA] text-[#222]">
      <div className="px-6 py-3 bg-[#2e7db1]">
        <h1 className="font-bold text-[22px] text-center text-white mb-2 py-2">
          {societyName}
        </h1>
        <div className="border-b border-white w-full h-full"></div>
        <p className=" text-center text-white text-[16px] mt-2 font-semibold">
          {jobName}
        </p>
      </div>

      <div className="flex px-3">
        <div className="w-[48%] mt-3">
          <ImageComponent defaultUrl={imageURL} width={32} height={32} /> 

        </div>
        <div className="w-[50=2%]">
          <p className="font-extrabold text-lg leading-6 mt-2 mb-2.5 capitalize">{name}</p>
          <div className="flex gap-1">
            <div className='w-16'>
              <p className="text-[#222] text-xs">Mobile No: </p>
              <p className="text-[#222] text-xs">Staff Type: </p>
            </div>
            <div className="text-xs font-semibold w-24">
              <p> {mobileNo}</p>
              <p> {staffType}</p>
            </div>
          </div>
        </div>
      </div>

      <hr className="m-2 mt-4 border-t-[1px]" />

      <div className="flex justify-center p-3">
        {/* QR Code component or image goes here */}
        {qrCode && <ImageComponent defaultUrl={qrCode} width={32} height={32} /> }
      </div>

      <div className="px-6 py-2 bg-[#2e7db1]">
        <div className="flex justify-between items-center">
          <p className="text-white ">Employee Code </p>
          <h1 className="font-bold text-[22px] text-center text-white mb-2 ">
            {employeeCode}
          </h1>
        </div>
        <div className="border-b border-white w-full h-full"></div>
        <div className="text-center">
          <Link to='https://zipgrid.com/' target='_blank' className=" text-white text-[13px] mt-1 font-semibold">
              www.zipgrid.com
          </Link>
        </div>
      </div>
    </div>
  );
};
const MaintenanceStaffIdCard = () => {
    const dispach =useDispatch()
    const {id} = useParams()
    const {loading, maintenanceStaffDetails} = useSelector(state=> state.maintenanceStaffReducer)
    const {loginData} = useSelector(state=>state.loginReducer)

    useEffect(()=>{
        dispach(getMaintenanceStaffDetails({staffId: id}))
      },[])

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
        {
            loading && maintenanceStaffDetails?.agency_staff_id?.toString() !== id ?
            <Loader/>
            :
            <>
              {
                maintenanceStaffDetails &&
                <IdCard
                  societyName={loginData?.user_name}
                  societyLogo={loginData?.default_community?.profile_photo_url}
                  staffType={maintenanceStaffDetails?.staff_type}
                  imageURL={maintenanceStaffDetails?.imageURL}
                  name={maintenanceStaffDetails?.name}
                  validity={maintenanceStaffDetails?.mobile}
                  mobileNo={`+${maintenanceStaffDetails?.mobile_country_code}-${maintenanceStaffDetails?.contact}`}
                  employeeCode={maintenanceStaffDetails?.staff_code}
                  qrCode={maintenanceStaffDetails?.qr_image}
                />
              }
            </>
            
        }
    </div>
  );
};

export default MaintenanceStaffIdCard;
