import React from 'react'
import Layout from '../../../Layout/Layout'
import ListHeader from '../../../../components/ListHeader/ListHeader'
import Button from '../../../../components/Button/Button'
import { useNavigate } from 'react-router-dom'
import { DEPOSIT_DUE_REMINDER_ENDPOINT, DUE_REMINDER_ENDPOINT, FIXED_DEPOSIT_MATURITY_REMINDER_ENDPOINT, MEMBER_DUE_REMINDER_ENDPOINT } from '../../../../Routing/routes'
import NomineesUpdateReminder from './NomineesReminderComp/NomineesUpdateReminder'

const NominationReminder = () => {
    const navigate = useNavigate();
  const handleNavigation = (path) => () => {
    navigate(path);
  };
  return (
    <Layout>
        <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg p-2">
           <div className='border-b border-[#CCC]'>
           <ListHeader leftContent="Nomination Reminder">
              <Button
                type='button'
                  label="Deposit Due Reminder"
                  onClick={handleNavigation(DEPOSIT_DUE_REMINDER_ENDPOINT)}
                />
                <Button label="Due Reminder" onClick={handleNavigation(DUE_REMINDER_ENDPOINT)} />
                <Button label="FD Maturity Reminder" onClick={handleNavigation(FIXED_DEPOSIT_MATURITY_REMINDER_ENDPOINT)} />
                <Button label="Member Due Reminder" onClick={handleNavigation(MEMBER_DUE_REMINDER_ENDPOINT)} />
            </ListHeader>
           </div>

            <div>
                <NomineesUpdateReminder />
            </div>
        </div>
    </Layout>
  )
}

export default NominationReminder