import React, { useEffect, useRef } from "react";
import { Link, createSearchParams, useNavigate } from "react-router-dom";
import ListHeader from "../../../components/ListHeader/ListHeader";
import Layout from "../../Layout/Layout";
import Button from "../../../components/Button/Button";
import ButtonG from "../../../components/Button/ButtonG";
import Dropdown2, { DropdownFM2 } from "../../../components/Dropdown/dropdown2";
import { DatePickerComponentFM } from "../../../components/DatePicker/DatePicker";
import TextRadio from "../../../components/InputFields/TextRadio";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getFlatDetails, getWingsAndFlatDetails } from "../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import { getBankDetails, getBankNameList } from "../../../redux/actions/SetupRecordsActions/BuildingActions/BankDetailsAction";
import { Form, Formik } from "formik";
import moment from "moment";
import { ChequeDepositSlipValidation } from "../../../validationSchema/financeSchema/billingReportSchema";
import { PRINT_CHEQUE_DEPOSIT_SLIP_ENDPOINT } from "../../../Routing/routes";
import { validateDate } from "../../../utils/helpers/universalFunctions";
import { TextInputFM } from "../../../components/InputFields/TextInput";


const ChequeDepositSlip = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {wingsData,flatsData} = useSelector(state=>state.wingsReducer)
  const {loginData} = useSelector(state=>state.loginReducer)
  const {bankNameList} = useSelector(state=>state.bankNameReducer)
  const {bankDetailsData} = useSelector(state=>state.bankDetailsReducer)

  const handleCancelClick = () => {
    navigate(-1);
  };

  const radioOptions = [
    { name: "dateType", value: "dated", label: "Payment Date" },
    { name: "dateType", value: "date", label: "System Date" },
  ];

  const handleSaveClick = (values, {resetForm})=>{
    let query = []
    values.bankId && query.push(["bankId", values.bankId])
    values.accountNo && query.push(["accountNo", values.accountNo])
    values.branchName && query.push(["branchName", values.branchName])
    values.wingId && query.push(["wingId", values.wingId])
    values.flatId && query.push(["flatId", values.flatId])
    values.memberName && query.push(["memberName", values.memberName])
    values.dateType && query.push(["dateType", values.dateType])
    values.fromDate && query.push(["fromDate", values.fromDate])
    values.toDate && query.push(["toDate", values.toDate])

    let searchParams = createSearchParams(query)
    window.open(`${PRINT_CHEQUE_DEPOSIT_SLIP_ENDPOINT}?${searchParams}`, "_blank")
    resetForm()
    }
  

  useEffect(()=>{
    const societyId= loginData?.default_community?.community_id
    dispatch(getWingsAndFlatDetails({societyId}))
    dispatch(getBankDetails())
    dispatch(getBankNameList())
  }, [])

  return (
    <Layout>
      <Formik
        initialValues={{
          bankId: '',
          wingId: '',
          flatId: '',
          accountNo: '',
          branchName: '',
          memberName: '',
          dateType: "dated",
          fromDate: '',
          toDate:  ''
        }}
        onSubmit={handleSaveClick}
        validationSchema={ChequeDepositSlipValidation}
        >    
          {
            ({values, setFieldValue})=>{
              return(
                <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 px-3">
                  <Form> 
                    <div className="border-b border-[#CCC]">
                      <ListHeader leftContent="Cheque Deposit Slip / Pay Slip">
                        <div className="mx-0.5 border-l-2"></div>
                        <ButtonG
                          label="Cancel"
                          onClick={handleCancelClick}
                          type={"button"}
                        />
                        <Button label="Get" className="px-6" type={"submit"} />
                      </ListHeader>
                    </div>
                    <div className="w-1/2 pt-3">
                      <div className="flex justify-between ">
                        <span className="text-sm mt-2">Bank Name</span>
                        <DropdownFM2
                          options={bankNameList?.map((item)=>(
                            {
                              label: item.account,
                              value: item.bank_name_id
                            }
                          )) || []}
                          width="224px"
                          height="36px"
                          placeholder="Select Bank name"
                          onSelect={(selectedItem) => {
                            setFieldValue(`bankId`, selectedItem.value)
                            setFieldValue(`accountNo`, '')
                          }}
                          value={values.bankId ? {label: bankNameList?.find(item=> item.bank_name_id === values.bankId)?.account, value: values.bankId } : null}
                          name={`bankId`}
                        />
                      </div>
                      <div className="flex justify-between">
                        <span className="text-sm mt-2">Account Number</span>
                        <DropdownFM2
                          options={bankDetailsData?.filter(item=> item.bank_name_id === values.bankId)?.map((item)=> ({
                                  label: item.account_no,
                                  value: item.account_no
                                })
                                )
                            || []}
                          width="224px"
                          height="36px"
                          placeholder="Select Account Number"
                          onSelect={(selectedItem) => {
                            setFieldValue(`accountNo`, selectedItem.value)
                          }}
                          value={values.accountNo ? {label: values.accountNo, value: values.accountNo } : null}
                          name={`accountNo`}
                        />
                      </div>
                      <TextInputFM
                        label='Branch Name'
                        placeholder="Enter"
                        className="w-56"
                        name='branchName' 
                      />
                      <TextInputFM
                        label='Member Name'
                        placeholder="Enter"
                        className="w-56"
                        name='memberName' 
                      />
                    </div>
                    <div className="text-sm flex">
                      <div className="flex justify-between w-1/2">
                        <span className="mt-2">Wing & Flat </span>
                        <div className="items-center flex flex-col">
                          <div className="flex gap-2">
                            <DropdownFM2
                                options={wingsData?.map((item)=>({
                                  value: item?.wing_id,
                                  label: item?.number
                                })) || []}
                                width={"108px"}
                                className="text-xs"
                                placeholder={"Wing"}
                                onSelect={(selectedItem)=>{
                                  setFieldValue(`wingId`, selectedItem.value)
                                  setFieldValue(`flatId`, '')
                                  dispatch(getFlatDetails({societyId: loginData?.default_community?.community_id, wingId: selectedItem?.value}))
                                }}
                                height='38px'
                                name={`wingId`}
                                {...(values.wingId==='' && {value: null})}
                              />
                            <DropdownFM2
                              options={values.wingId && flatsData?.manage_flat_details?.map((item)=>({
                                value: item?.flat_id,
                                label: item?.flat_no,
                                memberName: item?.subscriber_name
                              })) || []}
                              width={"108px"}
                              className="text-xs"
                              placeholder={"Flat/Unit"}
                              onSelect={(selectedItem)=>{
                                setFieldValue(`flatId`, selectedItem.value)
                                setFieldValue('memberName', selectedItem.memberName || '')
                              }}
                              height='38px'
                              name={`flatId`}
                              {...(values.flatId==='' && {value: null})}
                            />
                          </div>
                          <TextRadio
                            options={radioOptions}
                            defaultSelected={values.dateType}
                            onChange={(selectemItem)=>setFieldValue(`dateType`, selectemItem.value)}
                            className={"w-56 text-xs"}
                          />
                        </div>
                      </div>
                      <div className="text-[#AAAAAA] font-bold text-xs ms-20 mt-2">
                      {/* — {values.flatId ? values.memberName : 'Resident name show here , once wing and flat selected.'} */}
                      </div>
                    </div>
                    <div className="w-1/2 pt-3">
                      <div className="flex justify-between">
                        <span className="text-sm mt-2">From Date</span>
 
                        <DatePickerComponentFM
                          className="w-56 h-9 ml-4 justify-between" 
                          name={'fromDate'}
                          onDateChange={(selectedDate) => {
                            setFieldValue('fromDate', moment(selectedDate)?.format('YYYY-MM-DD'))
                            if(validateDate(values.toDate) && moment(values.toDate).isBefore(new Date(selectedDate)))
                              setFieldValue('toDate', '')
                          }}
                          defaultValue={values.fromDate ? new Date(values.fromDate) : null}
                        />                
                      </div>
                      <div className="flex justify-between">
                        <span className="text-sm mt-2">To Date</span>
                        <DatePickerComponentFM  
                          className="w-56 h-9 ml-4 justify-between" 
                          name={'toDate'}
                          onDateChange={(selectedDate) => {
                            setFieldValue('toDate', moment(selectedDate)?.format('YYYY-MM-DD'))
                          }}
                          defaultValue={values.toDate ? new Date(values.toDate) : null}
                          readOnly={!values.fromDate}
                          {...(values.fromDate && {minDate: new Date(moment(values.fromDate).add(1, 'days'))})}
                          />
                      </div>
                    </div>
                </Form>
              </div>
              )
            }
          }
        </Formik>
    </Layout>
  );
};

export default ChequeDepositSlip;
