import { notify } from "../../../utils/notification";
import apiEndpoints from "../../api";
import axiosInstance from "../../axiosInstance";
import { logOut } from "../../../utils/helpers/logOut";
import getBrowserDetails from "../../../utils/helpers/getBrowserDetails";
import { errorMsg } from "../../../consts/responseMessages";


export async function getNoticeCircularListReq(action) {
    return axiosInstance
        .get(
            `${apiEndpoints.GET_NOTICE_CIRCULAR_LIST_API}?society_id=${action.data.societyId
            }&filter_type=${action.data.filter_type}&for_console=1&page=${action.data.page}&limit=${action.data.limit}${action.data.filter_text
                ? `&filter_text=${encodeURIComponent(action.data.filter_text)}`
                : ""
            }`
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
            ) {
                logOut();
            } else if (errors.response?.data.statusCode === 501) {
                notify("warning", errors.response.data.message);
            }
            return errors;
        });
}

export async function resendNoticeCircularReq(action) {
    const BODY = {
        id: action.data?.id
    }
    return axiosInstance
        .post(
            apiEndpoints.RESEND_NOTICE_CIRCULAR_API, { ...BODY }
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
            ) {
                logOut();
            } else if (errors.response?.data.statusCode === 501) {
                notify("warning", errors.response.data.message);
            }
            return errors;
        });
}


export async function deleteNoticeCircularReq(action) {


    return axiosInstance
        .delete(apiEndpoints.DELETE_NOTICE_CIRCULAR_API + action.data?.id)
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
            ) {
                logOut();
            } else {
                notify("warning", "Something went wrong");
            }
            return errors;
        });
}

export async function getNoticeCircularDetailsReq(action) {
    return axiosInstance
        .get(
            `${apiEndpoints.GET_NOTICE_CIRCULAR_DETAILS_API}?id=${action.data.id
            }`
        ).then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
            ) {
                logOut();
            } else {
                notify("warning", "Something went wrong");
            }
            return errors;
        });
}
export async function printNoticeCircularReq(action) {
    return axiosInstance
        .get(
            `${apiEndpoints.PRINT_NOTICE_CIRCULAR}?id=${action.data.id
            }`
        ).then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
            ) {
                logOut();
            } else {
                notify("warning", "Something went wrong");
            }
            return errors;
        });
}

export async function postNoticeCircularCommentReq(action) {
    const BODY = {
        notice_id: action.data?.id,
        comment: action.data?.comment.trim(),
    };
    return axiosInstance
        .post(
            apiEndpoints.POST_NOTICE_CIRCULAR_COMMENT_API, { ...BODY }
        ).then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
            ) {
                logOut();
            } else {
                notify("warning", "Something went wrong");
            }
            return errors;
        });
}

export async function addNoticeCircularReq(action) {
    const BODY = {
        resident_live_members: action?.data?.resident_live_members,
        selected_wings_members: action?.data?.selected_wings_members,
        selected_wings_tenants: action?.data?.selected_wings_tenants,
        selected_flat_tenants: action?.data?.selected_flat_tenants,
        selected_flat_members: action?.data?.selected_flat_members,
        managing_committee: action?.data?.managing_committee,
        employees: action?.data?.employees,
        ref_no: action?.data?.ref_no,
        attachments: action?.data?.attachments,
        description: action?.data?.description,
        title: action?.data?.title,
        acknowledgment: action?.data?.acknowledgment,
        notice_date: action?.data?.notice_date,
        expiry_date: action?.data?.expiry_date,
        visiblity: "Members",
        type: action?.data?.types,
        by_order: action?.data?.by_order,
        is_publish: action?.data?.is_publish
    };
    return axiosInstance
        .post(
            apiEndpoints.Add_NOTICE_CIRCULAR_API, { ...BODY }
        ).then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
            ) {
                logOut();
            } else {
                notify("warning", "Something went wrong");
            }
            return errors;
        });
}

export async function updateNoticeCircularReq(action) {
    const BODY = {
        id: action?.data?.id,
        resident_live_members: action?.data?.resident_live_members,
        selected_wings_members: action?.data?.selected_wings_members,
        selected_wings_tenants: action?.data?.selected_wings_tenants,
        selected_flat_tenants: action?.data?.selected_flat_tenants,
        selected_flat_members: action?.data?.selected_flat_members,
        managing_committee: action?.data?.managing_committee,
        employees: action?.data?.employees,
        ref_no: action?.data?.ref_no,
        attachments: action?.data?.attachments,
        description: action?.data?.description,
        title: action?.data?.title,
        acknowledgment: action?.data?.acknowledgment,
        notice_date: action?.data?.notice_date,
        expiry_date: action?.data?.expiry_date,
        visiblity: "Members",
        type: action?.data?.types,
        by_order: action?.data?.by_order,
        is_publish: action?.data?.is_publish
    };
    return axiosInstance
        .put(
            apiEndpoints.UPDATE_NOTICE_CIRCULAR_API, { ...BODY }
        ).then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
            ) {
                logOut();
            } else {
                notify("warning", "Something went wrong");
            }
            return errors;
        });
}

export async function getNoticeCircularTypesReq(action) {

    return axiosInstance
        .get(
            apiEndpoints.GET_NOTICE_CIRCULAR_TYPES_API
        ).then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
            ) {
                logOut();
            } else {
                notify("warning", "Something went wrong");
            }
            return errors;
        });
}

export async function getNoticeCircularManagingCommitteeReq(action) {

    return axiosInstance
        .post(
            apiEndpoints.GET_NOTICE_CIRCULAR_MANAGING_COMMITTEE_API, { filter_type: ["Managing Committee"] }
        ).then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
            ) {
                logOut();
            } else {
                notify("warning", "Something went wrong");
            }
            return errors;
        });
}


export async function getNoticeCircularArvhiveListReq(action) {
    return axiosInstance
        .get(
            `${apiEndpoints.GET_NOTICE_CIRCULAR_LIST_API}?society_id=${action.data.societyId
            }&filter_type=${action.data.filter_type}&for_console=1&page=${action.data.page}&limit=${action.data.limit}${action.data.filter_text
                ? `&filter_text=${encodeURIComponent(action.data.filter_text)}`
                : ""
            }`
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
            ) {
                logOut();
            } else if (errors.response?.data.statusCode === 501) {
                notify("warning", errors.response.data.message);
            }
            return errors;
        });
}


export async function addAcknowledgementNoticeReq(action) {
    return axiosInstance
        .put(`${apiEndpoints.ADD_ACKNOWLEDGEMENT_NOTICE_API}/${action.data.notice_id}/${action.data.user_id}/${action.data.user_type}`)
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
            ) {
                logOut();
            } else if (errors.response?.data.statusCode === 501) {
                notify("warning", errors.response.data.message);
            }
            return errors;
        });
}




export async function getNoticeCircularAcknowledgementListReq(action) {
    return axiosInstance
        .get(
            `${apiEndpoints.GET_NOTICE_CIRCULAR_ACKNOWLEDGEMENT_API}?id=${action.data.id} `
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
            ) {
                logOut();
            } else if (errors.response?.data.statusCode === 501) {
                notify("warning", errors.response.data.message);
            }
            return errors;
        });
}