import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Accordion = ({
  label,
  isOpen,
  toggleAccordion,
  children,
  description, // added description prop
}) => {
  return (
    <div className="border rounded-lg border-[#CCCCCC] my-2">
      <div className="cursor-pointer items-center bg-[#FDF2F3] rounded-lg p-3 border-b">
        <div className="flex items-center justify-between w-full">
          <div
            className="flex items-center overflow-hidden text-ellipsis basis-full"
            onClick={toggleAccordion}
          >
            <div>
              <button
                className="text-white text-base font-semibold mr-3 w-5 h-5 bg-red-650 
                border rounded flex justify-center items-center"
              >
                {isOpen ? (
                  <FontAwesomeIcon icon={faChevronUp} size="xs" />
                ) : (
                  <FontAwesomeIcon icon={faChevronDown} size="xs" />
                )}
              </button>
            </div>
            <div className="text-[#000000] text-sm font-semibold justify-between flex w-full text-justify">
              <span className="w-full mr-10 capitalize">{label}</span>
            </div>
          </div>
        </div>
      </div>
      {isOpen && <>{children}</>}
    </div>
  );
};


const ParkingData = [
  {
    id: 1,
    title: "For 4 Wheeler",
    keyPart: '4w_',
    keys: {
      '4w_1parking':  {label: '1st Parking', audit: []},
      '4w_2parking':  {label: '2nd Parking', audit: []},
      '4w_3parking':  {label: '3rd Parking', audit: []},
      '4w_4parking':  {label: '4th Parking', audit: []},
      '4w_5parking':  {label: '5th Parking', audit: []},
      '4w_6parking':  {label: '6th Parking', audit: []},
      '4w_7parking':  {label: '7th Parking', audit: []},
      '4w_8parking':  {label: '8th Parking', audit: []},
      '4w_9parking':  {label: '9th Parking', audit: []},
      '4w_10parking':  {label: '9th Parking', audit: []},
    }
  },

  {
    id: 2,
    title: "For 2 Wheeler",
    keyPart: '2w_',
    keys: {
      '2w_1parking':  {label: '1st Parking', audit: []},
      '2w_2parking':  {label: '2nd Parking', audit: []},
      '2w_3parking':  {label: '3rd Parking', audit: []},
      '2w_4parking':  {label: '4th Parking', audit: []},
      '2w_5parking':  {label: '5th Parking', audit: []},
      '2w_6parking':  {label: '6th Parking', audit: []},
      '2w_7parking':  {label: '7th Parking', audit: []},
      '2w_8parking':  {label: '8th Parking', audit: []},
      '2w_9parking':  {label: '9th Parking', audit: []},
      '2w_10parking':  {label: '9th Parking', audit: []},
    }
  },

  {
    id: 3,
    title: "For Other Wheeler",
    keyPart: 'otherw_',
    keys:{
      'otherw_1parking':  {label: '1st Parking', audit: []},
      'otherw_2parking':  {label: '2nd Parking', audit: []},
      'otherw_3parking':  {label: '3rd Parking', audit: []},
      'otherw_4parking':  {label: '4th Parking', audit: []},
      'otherw_5parking':  {label: '5th Parking', audit: []},
      'otherw_6parking':  {label: '6th Parking', audit: []},
      'otherw_7parking':  {label: '7th Parking', audit: []},
      'otherw_8parking':  {label: '8th Parking', audit: []},
      'otherw_9parking':  {label: '9th Parking', audit: []},
      'otherw_10parking':  {label: '9th Parking', audit: []},
    }
  },
];


const SocietyParkingAccordions = ({ data }) => {
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const {parkingChargesAudit} = useSelector(state=>state.parkingManagementReducer)
  const [audits, settAudits] = useState(ParkingData)
  const toggleAccordion = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(()=>{
    settAudits((prevData)=>{
      let dummyData = JSON.parse(JSON.stringify(prevData))
      parkingChargesAudit?.forEach((item=>{
          item?.changedFields?.split(',')?.forEach(changedKey =>{
            dummyData?.forEach((AccData)=>{
              if(changedKey?.includes(AccData.keyPart)){
                AccData.keys[changedKey].audit = [
                  ... AccData?.keys?.[changedKey]?.audit, 
                  {
                    old_value: item?.old_data?.[changedKey], 
                    new_value: item?.new_data?.[changedKey],
                    modified_by: item.modified_by,
                    modified_sessionId: item?.modified_sessionId,
                    IpAddress: item?.IpAddress,
                    modified_dateTime: moment(item?.modified_dateTime)?.format('MMM DD,YYYY hh:mm A')
                  }
                ]
              }
            })
          })
      }))
      return dummyData;
    })
  },[parkingChargesAudit])

  return (
    <div>
      {audits?.map((item, index) => (
        <Accordion
          label={item?.title}
          key={index}
          isOpen={index === openAccordionIndex}
          toggleAccordion={() => toggleAccordion(index)}
          description={item?.description}
        >
          <div className="p-3 text-[#222222] text-sm">
            {
              Object.entries(item?.keys)?.map(([key, value], i)=>(
                value?.audit?.length ?
                  <div key={key}>
                    <div className="flex justify-between w-full text-sm p-2 font-bold">
                      <div className="w-1/2">{value.label}</div>
                      <div className="w-1/2 text-[#AAAAAA]">Old Value</div>
                      <div className="w-1/2 text-[#AAAAAA]">New Value</div>
                    </div>
                    {
                      value.audit?.map((audit)=>(
                        <div>
                          <div className="flex justify-between w-full text-sm p-2">
                            <div className="w-1/2"></div>
                            <div className="w-1/2">{audit.old_value}</div>
                            <div className="w-1/2">{audit.new_value}</div>
                          </div>
                          <div className="flex justify-end flex-col mb-4">
                            <div className="text-xs text-right text-[#888]">{`Modified By ${audit?.modified_by} On ${moment(new Date(audit?.modified_dateTime))?.format('MMM DD,YYYY hh:mm A')}`}</div>
                            <div className="text-xs text-right text-[#888]">{`Ip Address: ${audit?.IpAddress}. Session id - ${audit?.modified_sessionId}`}</div>
                          </div>
                          <hr className="mt-4"/>
                        </div>
                      ))
                    }
                  </div>
                  :
                  <></>
              ))
            }
          </div>
        </Accordion>
      ))}
    </div>
  );
};

export default SocietyParkingAccordions;
