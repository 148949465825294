import * as type from '../../actions/index.types'

export function passwordResetReducer(state = {
  loading: false,
  resetPasswordData: null,
  error:null
}, action) {

switch (action.type) {
  case type.PASSWORD_RESET:
    return {
      ...state,
      loading: true,
    }
  case type.PASSWORD_RESET_SUCCESS:
    return {
      ...state,
      error: null,
      loading: false,
      resetPasswordData: action.payload
    }

  case type.PASSWORD_RESET_FAILURE:
    return {
      ...state,
      error: action?.message || 'Somthing went wrong.',
      loading: false,
    }
  default:
    return state
}
}