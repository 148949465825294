import React from "react";
import ListHeader from "../../../components/ListHeader/ListHeader";
import Layout from "../../Layout/Layout";
import { useNavigate } from "react-router-dom";
import SmsHistoryTable from "../../../components/TableComponent/SmsHistoryList";
import Dropdown2 from "../../../components/Dropdown/dropdown2";
import RangeDatePicker from "../../../components/DatePicker/RangeDatePicker";

export const headCells = [
  { id: "id", label: "S No.", width: "55px" },
  { id: "module", label: "Module", sort: false, width: "80px" },
  { id: "title", label: "Title", sort: true, width: "140px" },
  { id: "date", label: "Date", sort: true, width: "100px" },
  { id: "moduleCount", label: "Module Count", sort: true, width: "130px" },
  {
    id: "smsCreditCount",
    label: "SMS Credit Count",
    sort: true,
    width: "140px",
  },
  { id: "totalSmsCount", label: "Total SMS Count", sort: true, width: "130px" },
  { id: "route", label: "Route", sort: true, width: "100px" },
  { id: "action", label: "Action", sort: true, },
];

const rows = [
  {
    id: `1`,
    module: "Complaint",
    title: "Assignment of Complaint",
    date: "11-02-2023",
    moduleCount: "324",
    smsCreditCount: "32",
    totalSmsCount: "234",
    route: "q2324",
  },
  {
    id: `2`,
    module: "2000",
    title: "broo",
    date: "31-03-2023",
    moduleCount: "322134",
    smsCreditCount: "31232",
    totalSmsCount: "234234",
    route: "q2322342344",
  },
];

const SmsHistoryList = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => () => {
    navigate(path);
  };
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="SMS History List"></ListHeader>
        </div>
        <div className="flex justify-between items-center my-2">
          <Dropdown2
            options={[
              { value: "apple", label: "Apple" },
              { value: "orange", label: "Orange" },
              { value: "banana", label: "Banana" },
            ]}
            name="fruit"
            onSelect={""}
            placeholder="Select"
            width="220px"
            height="32px"
          />
          <RangeDatePicker
            className={"w-[190px] h-8"}
            // defaultStartValue={
            //   searchParams.get("startDate")
            //     ? new Date(searchParams.get("startDate"))
            //     : ""
            // }
            // defaultEndValue={
            //   searchParams.get("endDate")
            //     ? new Date(searchParams.get("endDate"))
            //     : ""
            // }
            // onStartDateChange={handleStartDate}
            // onEndDateChange={handleEndDate}
          />
        </div>
        <div className="ml-1">
          <SmsHistoryTable
            data={rows}
            columns={headCells}
            checkbox={false}
            // pagination={staffPersonnelList?.staff_list?.length}
            // totalCount={staffPersonnelList?.total_count}
            // totalPages={staffPersonnelList?.total_pages}
            // start={(staffPersonnelList?.current_page - 1) * (searchParams.get("pageLimit") || 10) + 1}
            // end={(staffPersonnelList?.current_page - 1) * (searchParams.get("pageLimit") || 10) + staffPersonnelList?.staff_list?.length}
            // currentPage={parseInt(staffPersonnelList?.current_page)}
            // rowsPerPage={searchParams.get("pageLimit") || 10}
            // getTableData={getTableData}
          ></SmsHistoryTable>
        </div>
        {!rows?.length && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
    </Layout>
  );
};

export default SmsHistoryList;
