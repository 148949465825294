import React, { useEffect } from "react";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { ADD_EMPLOYEE_ENDPOINT, EMPLOYEE_ENDPOINT, EMPLOYEE_LOGIN_REPORT_ENDPOINT, EXISTING_EMPLOYEE_ENDPOINT, VIEW_EMPLOYEE_DETAILS_ENDPOINT } from "../../../../Routing/routes";
import Layout from "../../../Layout/Layout";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Button from "../../../../components/Button/Button";
import Search from "../../../../components/SearchBox/Search";
import { useSelector } from "react-redux";
import EmployeeListTable from "./EmployeeListTable";
import { useDispatch } from "react-redux";
import {getEmployeeList} from '../../../../redux/actions/SetupRecordsActions/StaffManagementActions/EmployeeManagementAction'
import ButtonG from "../../../../components/Button/ButtonG";
import { getPermission } from "../../../../utils/helpers/getPermissions";
import * as permissions from '../../../../Routing/permissions'

const headCells = [
  { id: "sNo", label: "S No.", sort: false },
  { id: "name", label: "Name", sort: true, width: "180px" },
  { id: "type", label: "Type", sort: true },
  { id: "phoneNumber", label: "Phone Number", sort: true },
  { id: "emailAddress", label: "Email Address", sort: true },
  { id: "status", label: "Status", sort: true },
  { id: "action", label: "Action", sort: false },
];


function EmployeeList() {
  const [searchParams, setSearchParams] = useSearchParams()

  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {loading, employeeList} = useSelector(state=> state.employeeManagementReducer)
  const {loginData} = useSelector(state=>state.loginReducer)

  const handleAddEmployeeClick = () => {
    navigate(ADD_EMPLOYEE_ENDPOINT);
  };
  const handleExistingEmployeeClick = () => {
    navigate(EXISTING_EMPLOYEE_ENDPOINT);
  };
  const handleEmployeeLoginClick = () => {
    navigate(EMPLOYEE_LOGIN_REPORT_ENDPOINT);
  };

  const handleSearch = (text)=>{
    if(text && text !== searchParams.get("search")){
      searchParams.delete('page')
      searchParams.delete('pageLimit')
      searchParams.delete("search")
      navigate({
        pathname: `${EMPLOYEE_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["search", text?.trim()]])}`,
      })
    }
  }

  const handleClear = ()=>{
    navigate(EMPLOYEE_ENDPOINT)
  }

  const getTableData =() =>{
    const data={
      societyId: loginData?.default_community?.community_id,
      page: searchParams.get("page") || 1,
      searchText: searchParams.get("search") || '',
      pageLimit: searchParams.get("pageLimit") || 10,
    }

    dispatch(getEmployeeList(data))
  }

  useEffect(()=>{      
    getTableData()
  }, [searchParams])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="">
          <ListHeader leftContent="Employee List">
            <div className="flex flex-row gap-3">
              <Search height="8" onclick={handleSearch} value={searchParams.get("search")} />
              {
                getPermission(loginData, [permissions.ADD_EMPLOYEE_PERMISSION]) &&
                <ButtonG label='Clear' className='h-8' onClick={handleClear} />
              }

            <div className="border-r border-[#CCC]"></div>
              {
                getPermission(loginData, [permissions.ADD_EMPLOYEE_PERMISSION]) &&
                <Button label="Add Employee" onClick={handleAddEmployeeClick} />
              }
              <Button
                label="Add from Existing"
                onClick={handleExistingEmployeeClick}
                className=""
              />
              {
                getPermission(loginData, [permissions.VIEW_EMPLOYEE_LOGIN_REPORT_PERMISSION]) &&
                <Button
                  label="Employee Login Report"
                  onClick={handleEmployeeLoginClick}
                  className=""
                />
              }
            </div>
          </ListHeader>
        </div>
        <div className="gap-2 pl-1">
          <EmployeeListTable
              loading={loading}
              data={employeeList?.list?.map((item, index)=>({
                sNo: (((employeeList?.current_page_no - 1) * employeeList?.page_limit + 1)+index)?.toString()?.padStart(2, "0"),
                employeeId: item?.emp_id,
                employeeDetailsId: item?.emp_detail_id,
                name: item?.emp_name || '-',
                type: item?.type  || '-',
                phoneNumber: `+${item?.mobile_country_code}-${item?.phone}` || '-',
                emailAddress: item?.email || '-',
                status: (item?.status === 'A' ?'Active' : 'Inactive') || '-'
              }))}
              columns={headCells}
              checkbox={false}
              pagination={employeeList?.list?.length}
              totalCount={employeeList?.total_record}
              totalPages={employeeList?.total_pages}
              start={(employeeList?.current_page_no - 1) * employeeList?.page_limit + 1}
              end={(employeeList?.current_page_no - 1) * employeeList?.page_limit + employeeList?.list?.length}
              currentPage={employeeList?.current_page_no}
              rowsPerPage={searchParams.get("pageLimit") || 10}
              getTableData={getTableData}

            ></EmployeeListTable>
          
          {!employeeList?.list?.length && !loading && (
            <div className="text-sm text-[#AAAAAA] my-4 text-center">
              No Records
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default EmployeeList;
