import { call, put, takeLatest } from 'redux-saga/effects'
import * as types from '../../actions/index.types'
import {notify} from '../../../utils/notification'
import { passwordResetReq } from '../../../axios/request/AuthReqs/authReq';



function* passwordReset(action) {
    try {
        const result = yield call(passwordResetReq, action);
        if (result.statusCode === 200) {
            notify('success', result.message);
            yield put({ type: types.PASSWORD_RESET_SUCCESS, payload: result.data});  
            action.data.onSuccessCallback()
        } else {
            yield put({ type: types.PASSWORD_RESET_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}


export default function* passwordResetSaga() {
    yield takeLatest(types.PASSWORD_RESET, passwordReset);
}