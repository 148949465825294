import * as types from '../../index.types';

export function getManagingCommitteeList(data) {
    return {
        type: types.GET_MANAGING_COMMITTIEE_DETAILED_LIST,
        data
    };
}

export function addManagingCommittee(data) {
    return {
        type: types.ADD_MANAGING_COMMITTIEE,
        data
    };
}

export function getOccupantsForManagingCommittee(data) {
    return {
        type: types.GET_OCCUPANTS_FOR_MANAGING_COMMITTIEE,
        data
    };
}

export function getAllManagingCommitteeAuditLog(data) {
    return {
        type: types.GET_ALL_COMMITTEE_AUDIT_LOG,
        data
    };
}

export function getManagingCommitteeAuditLog(data) {
    return {
        type: types.GET_MANAGING_COMMITTIEE_AUDIT_LOGS,
        data
    };
}

export function getManagingCommitteeDetails(data) {
    return {
        type: types.GET_MANAGING_COMMITTIEE_DETAILS,
        data
    };
}

export function getManagingCommitteeArhiveMember(data) {
    return {
        type: types.GET_ARCHIVE_MANAGING_COMMITTIEE_MEMBER,
        data
    };
}

export function updateManagingCommittee(data) {
    return {
        type: types.UPDATE_MANAGING_COMMITTIEE,
        data
    };
}

export function getManagingCommitteeAccessSetting(data) {
    return {
        type: types.GET_MANAGING_COMMITTIEE_ACCESS_SETTING,
        data
    };
}

export function updateManagingCommitteeAccessSetting(data) {
    return {
        type: types.UPDATE_MANAGING_COMMITTIEE_ACCESS_SETTING,
        data
    };
}

export function deleteManagingCommittee(data) {
    return {
        type: types.DELETE_MANAGING_COMMITTIEE,
        data
    };
}

export function resignFromManagingCommittee(data) {
    return {
        type: types.RESIGN_MANAGING_COMMITTIEE,
        data
    };
}

export function retireFromManagingCommittee(data) {
    return {
        type: types.RETIRE_MANAGING_COMMITTIEE,
        data
    };
}





export function getArchivedCommitteeMemberData(data) {
    return {
        type: types.VIEW_ARCHIVED_MANAGING_COMMITTEE_MEMBER,
        data
    };
}

export function deleteArchivedCommitteeMember(data) {
    return {
        type: types.DELETE_ARCHIVED_MANAGING_COMMITTEE_MEMBER,
        data
    };
}

export function approveAndRejectCommitteeMemberResignation(data) {
    return {
        type: types.APPROVE_AND_REJECT_MANAGING_COMMITTEE_MEMBER_RESIGNATION,
        data
    };
}

export function reappointCommitteeMemberRetirement(data) {
    return {
        type: types.REAPPOINT_MANAGING_COMMITTEE_MEMBER,
        data
    };
}

export function approveCommitteeMemberRetirement(data) {
    return {
        type: types.APPROVE_MANAGING_COMMITTEE_MEMBER_RETIREMENT,
        data
    };
}