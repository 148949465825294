import * as type from "../../actions/index.types";

export function addFacilityReducer(
  state = {
    loading: false,
    updateLoader: false,
    employeeRoleList: null,
    managingCommitteeRoleList: null,
    vendorRoleList: null,

    employeeList: null,
    managingCommitteeList: null,
    vendorList: null,

    vendorConcernedPersonList: null,

    error: null,
  },
  action
) {
  switch (action.type) {

    case type.GET_ROLE_LIST:
      return {
        ...state,
        error: null,
      };
    case type.GET_ROLE_LIST_SUCCESS:
      if(action.payload.roleType ==='Employees')
        return {
          ...state,
          error: null,
          employeeRoleList: action.payload.data,
        };
      else if(action.payload.roleType ==='Vendors')
        return {
          ...state,
          error: null,
          vendorRoleList: action.payload.data,
        };
      else if(action.payload.roleType ==='Managing Staff')
        return {
          ...state,
          error: null,
          managingCommitteeRoleList: action.payload.data,
        };
        
    case type.GET_ROLE_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
      };


    case type.GET_ROLE_WISE_LIST:
      return {
        ...state,
        error: null,
      };
    case type.GET_ROLE_WISE_LIST_SUCCESS:
      if(action.payload.roleType ==='Employees')
        return {
          ...state,
          error: null,
          employeeList: action.payload.data,
        };
      else if(action.payload.roleType ==='Vendors')
        return {
          ...state,
          error: null,
          vendorList: action.payload.data,
        };
      else if(action.payload.roleType ==='Managing Staff')
        return {
          ...state,
          error: null,
          managingCommitteeList: action.payload.data?.filter(item=> (item.assigned_title !=='Demo_user') && item.subscriber_id),
        };
        
    case type.GET_ROLE_WISE_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
      };



    case type.GET_VENDOR_CONCERNED_PERSONS_LIST:
      return {
        ...state,
        error: null,
      };
    case type.GET_VENDOR_CONCERNED_PERSONS_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        vendorConcernedPersonList: action.payload,
      };
    case type.GET_VENDOR_CONCERNED_PERSONS_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        vendorConcernedPersonList: null,
      };


    case type.ADD_FACILITY:
      return {
          ...state,
          updateLoader: true,
      };
    case type.ADD_FACILITY_SUCCESS:
    return {
        ...state,
        updateLoader: false,
    };
    case type.ADD_FACILITY_FAILURE:
      return {
          ...state,
          updateLoader: false,
      };


    default:
      return state;
    }
}
