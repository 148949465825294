import { notify } from '../../../../utils/notification';
import apiEndpoints from '../../../api';
import axiosInstance from '../../../axiosInstance'
import {logOut} from '../../../../utils/helpers/logOut'
import { errorMsg } from '../../../../consts/responseMessages';

export async function getInvitedOccupantsAndTenantsListReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_INVITED_OCCUPANTS_AND_TENANTS_LIST_API}?society_id=${action.data.societyId}&page_no=${action.data.page}&page_limit=${action.data.pageLimit}&flag=${action.data.flag}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}


export async function sendInviteToOccupantsAndTenantsReq(action) {

    const BODY= {
        "email_id": action.data?.email,
        "user_name": action.data?.name,
        "flag": action.data?.flag
      }

    return axiosInstance.post(apiEndpoints.SEND_INVITE_EMAIL_TO_OCCUPANTS_AND_TENANTS_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}