import { notify } from '../../../utils/notification';
import apiEndpoints from '../../api';
import axiosInstance from '../../axiosInstance'
import {logOut} from '../../../utils/helpers/logOut'
import moment from 'moment';
import { errorMsg } from '../../../consts/responseMessages';

export async function getFlatMemberListReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_FLAT_MEMBER_LIST_API}?house_unique_id=${action.data.flatId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getVisitorOrVehicleDashboardDataReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_VISITOR_OR_VEHICLE_MANAGEMENT_DASHBOARD_DATA_API}?society_id=${action.data.societyId}&flag=${action.data.flag}&from_date=${action.data.fromDate}&to_date=${action.data.toDate}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getVisitorVehicleLogReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_VISITOR_VEHICLE_LOG_API}?society_id=${action.data.societyId}&page_no=${action.data.page}&page_limit=${action.data.pageLimit}&from_date=${action.data.fromDate}&to_date=${action.data.toDate}${action.data.searchText ? `&search_text=${encodeURIComponent(action.data.searchText)}` : '' }${action.data.vehicleType ? `&vehicle_type=${action.data.vehicleType}` : '' }`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function downloadVisitorVehicleLogReq(action) {

    return axiosInstance.get(`${apiEndpoints.DOWNLOAD_VISITOR_VEHICLE_LOG_API}?society_id=${action.data.societyId}&from_date=${action.data.fromDate}&to_date=${action.data.toDate}${action.data.searchText ? `&search_text=${encodeURIComponent(action.data.searchText)}` : '' }${action.data.vehicleType ? `&vehicle_type=${action.data.vehicleType}` : '' }`, {
        responseType: 'arraybuffer',
    })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getSocietyEntryGateReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_SOCIETY_ENTRY_GATE_API}?society_id=${action.data.societyId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function addUpdateSocietyEntryGateReq(action) {

    const BODY= {
        "society_id": action.data.societyId,
        "gate_data": action.data.gates?.map(item=>({
            "gate_id": item.gateId,
            "gate_title": item.name
        }))
      }

    return axiosInstance.post(apiEndpoints.ADD_UPDATE_SOCIETY_ENTRY_GATE_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function deleteSocietyEntryGateReq(action) {
    const BODY= {
        "gate_id": action.data.gateId
      }

    return axiosInstance.delete(apiEndpoints.DELETE_SOCIETY_ENTRY_GATE_API, {data: BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getGateAccessDataReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_GATE_ACCESS_DATA_API}?society_id=${action.data.societyId}&session_id=${action.data.sessionId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function addGateAccessDataReq(action) {

    const BODY= {
        "id": action.data.id || 0,
        "society_id": action.data.societyId,
        "staff_in_gate": action.data.inGate?.toString(),
        "staff_out_gate": action.data.outGate?.toString(),
        "staff_access": action.data.gateAccess,
        "session_id": action.data.sessionId
    }

    return axiosInstance.post(apiEndpoints.ADD_UPDATE_GATE_ACCESS_DATA_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getVisitorLogReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_VISITOR_LOG_API}?society_id=${action.data.societyId}&page_no=${action.data.page}&page_limit=${action.data.pageLimit}&from_date=${action.data.fromDate}&to_date=${action.data.toDate}${action.data.searchText ? `&search_text=${encodeURIComponent(action.data.searchText)}` : '' }${action.data.type ? `&flag=${action.data.type}` : '' }`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function downloadVisitorLogReq(action) {

    return axiosInstance.get(`${apiEndpoints.DOWNLOAD_VISITOR_LOG_API}?society_id=${action.data.societyId}&from_date=${action.data.fromDate}&to_date=${action.data.toDate}${action.data.searchText ? `&search_text=${encodeURIComponent(action.data.searchText)}` : '' }${action.data.type ? `&flag=${action.data.type}` : '' }`, {
        responseType: 'arraybuffer',
    })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getFrequentVisitorListReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_FREQUENT_VISITOR_LIST_API}?society_id=${action.data.societyId}&page_no=${action.data.page}&page_limit=${action.data.pageLimit}&from_date=${action.data.fromDate}&to_date=${action.data.toDate}${action.data.searchText ? `&search_text=${encodeURIComponent(action.data.searchText)}` : '' }`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getExpectedVisitorListReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_EXPECTED_VISITOR_LIST_API}?society_id=${action.data.societyId}&page_no=${action.data.page}&page_limit=${action.data.pageLimit}&from_date=${action.data.fromDate}&to_date=${action.data.toDate}${action.data.searchText ? `&search_text=${encodeURIComponent(action.data.searchText)}` : '' }`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function updateVisitorStatusReq(action) {

    const BODY= {
        "visitor_log_id": action.data.visitorLogId,
        "status": action.data.status,
        "datetime": action.data.dateTime,
        "ip": action.data.ipAddress,
        "gate": action.data.gate,
        "otp": action.data.otp
      }

    return axiosInstance.put(apiEndpoints.UPDATE_VISITOR_STATUS_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getStaffDetailsByStaffCodeReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_STAFF_DETAILS_BY_STAFF_CODE_API}?society_id=${action.data.societyId}&staff_code=${action.data.staffCode}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 404 && errors.response?.data?.type === 'STAFF_DATA_NOT_FOUND') {
                notify('error', errors.response.data.message);
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getVistorPurposeListReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_VISITOR_PURPOSE_LIST_API}?society_id=${action.data.societyId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function deleteVistorPurposeReq(action) {
    const BODY= {
        "purpose_id": action.data?.purposeId
      }

    return axiosInstance.delete(apiEndpoints.DELETE_VISITOR_PURPOSE_LIST_API, {data: BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getVistorDataReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_VISITORS_DATA_API}?mobile_number=${action.data.mobileNo}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function sendOTPToVisitorReq(action) {

    const BODY= {
        "mobile_number": action.data.phoneNumber
      }

    return axiosInstance.post(apiEndpoints.SEND_OTP_TO_VISITOR_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function adNewVisitorReq(action) {

    const BODY= {
        "visitor_id": action.data.visitorId || 0,
        "profile_pic": action.data.photo,
        "first_name": action.data.firstName,
        "last_name": action.data.lastName,
        "mobile_number": action.data.phone,
        "coming_from": action.data.from,
        "visit_time": moment().format('HH:mm:ss'),
        "visit_date": moment().format('YYYY-MM-DD'),
        "vehicle_type": action.data.vehicleType,
        "vehicle_no": action.data.vehicleNumber,
        "id_proof": action.data.idProofType,
        "id_proof_no": action.data.idProofNo,
        "email": action.data.email,
        "no_of_visitors": action.data.count,
        "gate": '',
        "status": 'Checkedin',
        "is_web_user": 1,
        "visitor_type": action.data.visitorType,
        "purpose_id": action.data.purposeId,
        "purpose": action.data.purposeName,
        "belongings": action.data.belongings,
        "laptop": action.data.laptop,
        "description": action.data.comment,
        "add_as_frequent": action.data.addAsFrequentVisitor,
        "send_sms_to_member": action.data.sendSMS,
        "ip": action.data.ipAddress,
        "flats_data": action.data?.whereToVisit?.map((item)=>({
            "house_unique_id": item.flatId,
            "member_id": item.memberId,
            "member_mobile_no": item.memberMobileNo
          }
        ))
      }

    return axiosInstance.post(apiEndpoints.ADD_NEW_VISITOR_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response?.data?.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function staffCheckInReq(action) {

    const BODY= {
        "visitor_log_id": action.data?.visitorLogId || 0,
        "staff_id": action.data?.staffId,
        "profile_pic": action.data.profilePic,
        "first_name": action.data.fName,
        "last_name": action.data.lName,
        "mobile_number": action.data.mobile,
        "coming_from": action.data.from,
        "id_proof_no": action.data.idProofNo,
        "visit_time": moment().format('HH:mm:ss'),
        "visit_date": moment().format('YYYY-MM-DD'),
        "vehicle_type": action.data.vehicleType,
        "purpose_id": 1,
        "vehicle_no": action.data.vehicleNo,
        "email": action.data.email || '',
        "profile": action.data.profile,
        "house_unique_ids": action.data.flatIds,
        "member_ids": action.data.membersId || '',
        "status": action.data?.status,
        "ip": action.data.ipAddress
      }

    return axiosInstance.post(apiEndpoints.STAFF_CHECKIN_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getVisitorPassDataReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_VISITOR_PASS_DATA_API}?visitor_log_id=${action.data.visitorLogId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}