import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../Layout/Layout";
import { SOCIETY_PROFILE_ENDPOINT } from "../../../Routing/routes";
import { TextInputFM } from "../../../components/InputFields/TextInput";
import Button from "../../../components/Button/Button";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import { Form, Formik } from "formik";
import { societyChangePasswordValidation } from "../../../validationSchema/setupRecordsSchema/buildingSchema";
import { societyChangePassword } from "../../../redux/actions/SetupRecordsActions/BuildingActions/SocietyProfileAction";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";

const ChangePassword = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const { ipAddress } = useSelector((state) => state.ipAddressReducer);
  const { loginData } = useSelector((state) => state.loginReducer);
  const dispatch = useDispatch();

  const handleBackClick = () => {
    navigate(SOCIETY_PROFILE_ENDPOINT);
  };

  const handleSubmit = (values, { resetForm }) => {
    setLoader(true);
    const sendRequest = {
      ...values,
      ipAddress,
      sessionId: loginData?.session_id,
      onSuccessCallback: () => {
        setLoader(false);
        resetForm();
      },
    };
    dispatch(societyChangePassword(sendRequest));
  };

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          initialValues={{
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          }}
          validationSchema={societyChangePasswordValidation}
          onSubmit={handleSubmit}
        >
          {(formik) => {
            return (
              <Form>
                <ListHeaderBack
                  onClick={handleBackClick}
                  title="Change Password"
                >
                  <Button
                    label={
                      <span className="w-12 inline-block">
                        {loader ? (
                          <CircularProgress sx={{ color: "white" }} size={17} />
                        ) : (
                          "Update"
                        )}
                      </span>
                    }
                    className="h-8 px-5"
                    type="submit"
                    disabled={loader}
                  />
                  <Button
                    label={<span className="w-12 inline-block ">Clear</span>}
                    className="h-8 px-5 !bg-[#CCCCCC] !border-[#CCCCCC]"
                    type="button"
                    onClick={() => {
                      formik.resetForm();
                    }}
                  />{" "}
                </ListHeaderBack>
                <div className="border-b border-[#CCCCCC]"></div>
                <div className="flex p-2">
                  <div className="w-1/2 flex flex-col pr-5">
                    <TextInputFM
                      label="Old Password"
                      placeholder="Enter Old Password"
                      className="w-56"
                      name="oldPassword"
                      errorBoxClassname="w-56"
                    />
                  </div>
                </div>
                <div className="w-full flex pr-5 gap-5">
                  <div className="w-1/2 pl-2">
                    <TextInputFM
                      label="New Password"
                      placeholder="Enter New Password"
                      className="w-56"
                      name="newPassword"
                      errorBoxClassname="w-56"
                    />
                  </div>
                  <div className="w-1/2 pl-[14px]">
                    <TextInputFM
                      label="Confirm Password"
                      placeholder="Enter Confirm Password"
                      className="w-56"
                      name="confirmPassword"
                      errorBoxClassname="w-56"
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Layout>
  );
};

export default ChangePassword;
