import * as type from "../../actions/index.types";

export function registersReducer(
  state = {
    loading: false,
    printLoader: false,
    memberListFormatI: null,
    memberRegisterDetails: null,
    memberListFormatJ: null,
    shareRegisterList: null,
    memberMortgageRegisterList: null,
    memberMortgageRegisterDetails: null,
    nominationRegisterList: null,
    propertyRegisterData: null,
    error: null,
  },
  action
) {
  switch (action.type) {
    case type.GET_MEMBER_LIST_FORMAT_I:
        return {
        ...state,
        loading: true,
        error: null,
        };
    case type.GET_MEMBER_LIST_FORMAT_I_SUCCESS:
        return {
        ...state,
        error: null,
        loading: false,
        memberListFormatI: action.payload,
        };
    case type.GET_MEMBER_LIST_FORMAT_I_FAILURE:
        return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        memberListFormatI: null,
        };

    case type.GET_MEMBER_REGISTER_DETAILS:
        return {
            ...state,
            loading: true,
            error: null,
        };
    case type.GET_MEMBER_REGISTER_DETAILS_SUCCESS:
        return {
            ...state,
            error: null,
            loading: false,
            memberRegisterDetails: action.payload,
        };
    case type.GET_MEMBER_REGISTER_DETAILS_FAILURE:
        return {
            ...state,
            error: action?.message || "Somthing went wrong.",
            loading: false,
            memberRegisterDetails: null,
        };

    case type.GET_MEMBER_LIST_FORMAT_J:
        return {
            ...state,
            loading: true,
            error: null,
        };
    case type.GET_MEMBER_LIST_FORMAT_J_SUCCESS:
        return {
            ...state,
            error: null,
            loading: false,
            memberListFormatJ: action.payload,
        };
    case type.GET_MEMBER_LIST_FORMAT_J_FAILURE:
        return {
            ...state,
            error: action?.message || "Somthing went wrong.",
            loading: false,
            memberListFormatJ: null,
        };
    
    
    case type.GET_MEMBER_SHARE_REGISTER_LIST:
        return {
            ...state,
            loading: true,
            error: null,
        };
    case type.GET_MEMBER_SHARE_REGISTER_LIST_SUCCESS:
        return {
            ...state,
            error: null,
            loading: false,
            shareRegisterList: action.payload,
        };
    case type.GET_MEMBER_SHARE_REGISTER_LIST_FAILURE:
        return {
            ...state,
            error: action?.message || "Somthing went wrong.",
            loading: false,
            shareRegisterList: null,
        };
    
    case type.GET_MEMBER_MORTGAGE_REGISTER_LIST:
        return {
            ...state,
            loading: true,
            error: null,
        };
    case type.GET_MEMBER_MORTGAGE_REGISTER_LIST_SUCCESS:
        return {
            ...state,
            error: null,
            loading: false,
            memberMortgageRegisterList: action.payload,
        };
    case type.GET_MEMBER_MORTGAGE_REGISTER_LIST_FAILURE:
        return {
            ...state,
            error: action?.message || "Somthing went wrong.",
            loading: false,
            memberMortgageRegisterList: null,
        };
    
    case type.GET_MEMBER_MORTGAGE_REGISTER_DETAILS:
        return {
            ...state,
            loading: true,
            error: null,
        };
    case type.GET_MEMBER_MORTGAGE_REGISTER_DETAILS_SUCCESS:
        return {
            ...state,
            error: null,
            loading: false,
            memberMortgageRegisterDetails: action.payload,
        };
    case type.GET_MEMBER_MORTGAGE_REGISTER_DETAILS_FAILURE:
        return {
            ...state,
            error: action?.message || "Somthing went wrong.",
            loading: false,
            memberMortgageRegisterDetails: null,
        };
    
    case type.GET_NOMINATION_REGISTER_LIST:
        return {
            ...state,
            loading: true,
            error: null,
        };
    case type.GET_NOMINATION_REGISTER_LIST_SUCCESS:
        return {
            ...state,
            error: null,
            loading: false,
            nominationRegisterList: action.payload,
        };
    case type.GET_NOMINATION_REGISTER_LIST_FAILURE:
        return {
            ...state,
            error: action?.message || "Somthing went wrong.",
            loading: false,
            nominationRegisterList: null,
        };
          
    case type.GET_PROPERTY_REGISTER_DATA:
        return {
            ...state,
            loading: true,
            error: null,
        };
    case type.GET_PROPERTY_REGISTER_DATA_SUCCESS:
        return {
            ...state,
            error: null,
            loading: false,
            propertyRegisterData: action.payload,
        };
    case type.GET_PROPERTY_REGISTER_DATA_FAILURE:
        return {
            ...state,
            error: action?.message || "Somthing went wrong.",
            loading: false,
            propertyRegisterData: null,
        };
          
    default:
      return state;
  }
}
