import React from "react";
import Modal from "./Modal";
const DeleteModal = ({ isOpen, title, text, onCancel, onDelete, loading}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      title={title || "Delete Record"}
      onCancel={onCancel}
      loading={loading}
      onConfirm={onDelete}
    >
      <p className="flex justify-center font-semibold text-[16px]">Are you sure?</p>
      <p className=" mt-6 text-[16px] text-center">
        {text || 'Do you really want to delete this record?'}
      </p>
    </Modal>
  );
};

export default DeleteModal;
