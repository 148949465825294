import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { getMemberShareRegisterList } from '../../../redux/actions/ComplianceActions/RegistersAction'
import Loader from '../../../components/Loader/Loader'
import moment from 'moment'
import { validateDate } from '../../../utils/helpers/universalFunctions'
import { PrinterIcon } from '../../../assets'

export const headingsTenants = [
    { id: 'sNo', label: 'S No.', width: '45px' },
    { id: 'date', label: 'Date Of Allotment Of Share', sort: true,width: '6.6%' },
    { id: 'folioNo', label: 'Cash Book Folio No.', sort: true, width:'6.6%'  },
    { id: 'certificateNo', label: 'Share Certificate No.', sort: true, width:'6.6%' },
    { id: 'DistnoOfShare', label: 'Disti. No. Of Shares', sort: true, width: '6.6%' },
    { id: 'noOfShare', label: 'No. Of Shares', sort: true, width:'6.6%' },
    { id: 'valueOfShare', label: 'Value Of Shares Rs.', sort: true, width:'6.6%'},
    { id: 'nameOfMember', label: 'Name Of The Member To Whom Shares Alloted', sort: true,width:'6.6%' },
    { id: 'flat', label: 'Flat/Shop/Office & Garage No.', sort: true, width: '6.6%' },
    { id: 'dateOfTransfer', label: 'Date Of Transfer/Refund', sort: true, width: '6.6%' },
    { id: 'jounralFolioNo', label: 'Cash Book Journal Folio No.	', sort: true, width: '6.6%' },
    { id: 'noOfShareTransfered', label: 'No. Of Shares Transferred Or Refunded	.', sort: true, width: '6.6%' },
    { id: 'flat', label: 'Shares Certificate Nos. Transferred Or Refunded', sort: true, width: '6.6%' },
    { id: 'flat', label: 'Value Of Shares Transferred Or Refunded(Rs.)', sort: true, width: '6.6%' },
    { id: 'flat', label: 'Name Of Transferee Or The Person Receiving Refund', sort: true, width: '6.6%' },
    { id: 'flat', label: 'Authority For Transfer Or Refund	', sort: true, width: '6.6%' },
    { id: 'flat', label: 'Remarks', sort: true, width: '6.6%' },
  ];
  
function PrintShareRegister() {
    const dispatch = useDispatch()
    const {loading, shareRegisterList} = useSelector(state=>state.registersReducer)
    const {loginData} = useSelector(state=>state.loginReducer)

    useEffect(()=>{
        dispatch(getMemberShareRegisterList({societyId: loginData?.default_community?.community_id,}))
    }, [])

    
  useEffect(() => {
    if(!loading && shareRegisterList){
      window.print()
    }
  }, [loading]);

  return loading ? (
    <Loader />
  ) : (
    <div className="m-1">
      <table className="table-auto w-full mx-auto border-collapse border border-black">
        <caption className="caption-top mb-10 mt-4">
          <div className="underline font-extrabold text-lg uppercase mb-3">{loginData?.default_community?.community_name}</div>
          <div className="underline font-bold text-sm">(Reg No.: {loginData?.default_community?.regn_no} &nbsp; Dated: {moment(new Date(loginData?.default_community?.date_of_regn))?.format('DD-MM-YYYY')})</div>
          <div className="font-bold mb-5 text-sm">{loginData?.default_community?.office_address} </div>
          <div className="underline font-extrabold text-sm text-red-500 uppercase mt-3">Share Register</div>
        </caption>
        <thead>
          <tr>
            {
              headingsTenants?.map((item)=>(
                <td className="text-sm p-1 font-semibold bg-[#DDDDDD] border border-black" key={item?.id}>
                  {item?.label}
                </td>
              ))
            }
          </tr>
          <tr>
            <td className="text-sm p-1 font-semibold bg-[#DDDDDD] border border-black">1</td>
            <td className="text-sm p-1 font-semibold bg-[#DDDDDD] border border-black">2</td>
            <td className="text-sm p-1 font-semibold bg-[#DDDDDD] border border-black">3</td>
            <td className="text-sm p-1 font-semibold bg-[#DDDDDD] border border-black">4</td>
            <td className="text-sm p-1 font-semibold bg-[#DDDDDD] border border-black">4-A</td>
            <td className="text-sm p-1 font-semibold bg-[#DDDDDD] border border-black">5</td>
            <td className="text-sm p-1 font-semibold bg-[#DDDDDD] border border-black">6</td>
            <td className="text-sm p-1 font-semibold bg-[#DDDDDD] border border-black">7</td>
            <td className="text-sm p-1 font-semibold bg-[#DDDDDD] border border-black">7-A</td>
            <td className="text-sm p-1 font-semibold bg-[#DDDDDD] border border-black">8</td>
            <td className="text-sm p-1 font-semibold bg-[#DDDDDD] border border-black">9</td>
            <td className="text-sm p-1 font-semibold bg-[#DDDDDD] border border-black">10</td>
            <td className="text-sm p-1 font-semibold bg-[#DDDDDD] border border-black">11</td>
            <td className="text-sm p-1 font-semibold bg-[#DDDDDD] border border-black">12</td>
            <td className="text-sm p-1 font-semibold bg-[#DDDDDD] border border-black">13</td>
            <td className="text-sm p-1 font-semibold bg-[#DDDDDD] border border-black">14</td>
            <td className="text-sm p-1 font-semibold bg-[#DDDDDD] border border-black">15</td>
          </tr>
        </thead>
        <tbody>
            {
              shareRegisterList?.map((item, index)=>(
                <React.Fragment  key={index}>
                {
                  !!Object.keys(item)?.length &&
                  <tr className="text-[#222] text-sm">
                    <td className="p-1 border border-black align-top">
                      {(index+1)?.toString()?.padStart(2, 0)}
                    </td>
                    <td className="p-1 border border-black align-top capitalize">
                        {validateDate(item?.share_held_date) ? moment(new Date(item?.share_held_date))?.format('DD-MM-YYYY') : '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.share_held_cash_book_folio || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.sr_no_of_disti_held || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.sr_no_of_share_held || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.no_of_share_held || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.value_of_share || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.occupant_names || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {`${item?.wing_no} - ${item?.flat_no}` || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {validateDate(item?.share_transferred_date) ? moment(new Date(item?.share_transferred_date))?.format('DD-MM-YYYY') : '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.no_of_share_transferred || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.sr_no_of_share_transferred || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.shre_certificate_no || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.shre_certificate_no || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.shre_certificate_no || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.authority_for_transfer || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.remarks || '-'}
                    </td>
                  </tr>
                }
                </React.Fragment>
              ))
            }
          
        </tbody>
      </table>
      {!shareRegisterList?.length && !loading && (
          <div className="text-sm my-10 text-center italic">
            No Records
          </div>
        )}

        <div className="my-9 underline font-extrabold text-sm uppercase justify-center items-center cursor-pointer text-red-500 flex" onClick={()=>{window.print()}}> <PrinterIcon/>  &nbsp; PRINT</div>
    </div>
  );
}

export default PrintShareRegister