import { call, put, takeLatest } from 'redux-saga/effects'
import * as types from '../../../actions/index.types'
import * as req from '../../../../axios/request/Social&Communication/Lobby/ImportantContactsReqs'

function* getImportantContactList(action) {
    try {
        const result = yield call(req.getImportantContactListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_IMPORTANT_CONTACTS_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_IMPORTANT_CONTACTS_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getContactCategoryList(action) {
    try {
        const result = yield call(req.getContactCategoryListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_CONTACTS_CATEGORY_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_CONTACTS_CATEGORY_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}


export default function* importantContactSaga() {
    yield takeLatest(types.GET_IMPORTANT_CONTACTS_LIST, getImportantContactList);
    yield takeLatest(types.GET_CONTACTS_CATEGORY_LIST, getContactCategoryList);

}