import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
    RENT_REGISTRATION_LIST_ENDPOINT,

} from "../../../../Routing/routes";
import Layout from "../../../Layout/Layout";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import { useDispatch, useSelector } from "react-redux";
import { getRentPaymentOnlineRegistrationData } from "../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/TenantsInfoAction";
import moment from "moment";
import Loader from "../../../../components/Loader/Loader";

const ViewOnlineRegFormForRentPayment = ({type}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {loading, rentPaymentOnlineRegistrationData} = useSelector(state => state.tenantInfoReducer)
  const { loginData } = useSelector((state) => state.loginReducer);

  const {tenantId} = useParams()

  const handleBackClick = () => {
    navigate(RENT_REGISTRATION_LIST_ENDPOINT);
  };


  useEffect(()=>{
    dispatch(getRentPaymentOnlineRegistrationData({tenantId, societyId: loginData?.default_community?.community_id}))
  }, [])


  return (
    <Layout>
      <div className="flex flex-col gap-2 w-full">
        <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
            <ListHeaderBack
            onClick={handleBackClick}
            title="View Online Registration Form For Rent Payment"
            >
            </ListHeaderBack>
        </div>
        <div>
        <div className="flex gap-4 p-2 min-h-[40px]">
            <div className="flex flex-col w-full gap-3">
            <div className=" text-[#AAAAAA]">Tenant Details</div>
                {
                    loading && tenantId !== rentPaymentOnlineRegistrationData?.tenant_id?.toString() ?
                    <Loader />
                    :
                    <div className="flex justify-between w-full gap-3">
                        <div className="w-1/2 gap-1">
                            <div className="mb-4">
                                <div className="flex text-sm capitalize">
                                    <div className="font-medium w-52 text-[#aaa] shrink-0">Individual/Company</div>
                                    <span>{rentPaymentOnlineRegistrationData?.individual_or_company || '-'}</span>
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="flex text-sm capitalize">
                                    <div className="font-medium w-52 text-[#aaa] shrink-0">Name</div>
                                    <span>{rentPaymentOnlineRegistrationData?.first_name} {rentPaymentOnlineRegistrationData?.last_name}</span>
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="flex text-sm">
                                    <div className="font-medium w-52 text-[#aaa] shrink-0">Wing</div>
                                    <span>{rentPaymentOnlineRegistrationData?.wing_no || '-'}</span>
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="flex text-sm">
                                    <div className="font-medium w-52 text-[#aaa] shrink-0">Date of Birth</div>
                                    <span>{rentPaymentOnlineRegistrationData?.dob ? moment(rentPaymentOnlineRegistrationData?.dob)?.format('DD-MM-YYYY') : '-' }</span>
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="flex text-sm">
                                    <div className="font-medium w-52 text-[#aaa] shrink-0">Mobile Number</div>
                                    <span>{(rentPaymentOnlineRegistrationData?.mobile?.split('-')?.length>1 ? rentPaymentOnlineRegistrationData?.mobile?.split('-')?.[1]: rentPaymentOnlineRegistrationData?.mobile?.split('-')?.[0]) || '-'}</span>
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="flex text-sm">
                                    <div className="font-medium w-52 text-[#aaa] shrink-0">Tenancy Property Address</div>
                                    <span>{rentPaymentOnlineRegistrationData?.address || '-' }</span>
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="flex text-sm">
                                    <div className="font-medium w-52 text-[#aaa] shrink-0">City</div>
                                    <span>{rentPaymentOnlineRegistrationData?.city || '-' }</span>
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="flex text-sm">
                                    <div className="font-medium w-52 text-[#aaa] shrink-0">PAN Number</div>
                                    <span>{rentPaymentOnlineRegistrationData?.tenant_pan || '-' }</span>
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="flex text-sm">
                                    <div className="font-medium w-52 text-[#aaa] shrink-0">Rent Amount</div>
                                    <span>{rentPaymentOnlineRegistrationData?.rent_amount || '-' }</span>
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="flex text-sm">
                                    <div className="font-medium w-52 text-[#aaa] shrink-0">Monthly Due Date</div>
                                    <span>{rentPaymentOnlineRegistrationData?.due_date || '-' }</span>
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="flex text-sm">
                                    <div className="font-medium w-52 text-[#aaa] shrink-0">Rent Agreement Expiry Date</div>
                                    <span>{rentPaymentOnlineRegistrationData?.agreement_expiry_date ? moment(rentPaymentOnlineRegistrationData?.agreement_expiry_date)?.format('DD-MM-YYYY') : '-' }</span>
                                </div>
                            </div>
                        </div>
                        <div className="w-1/2 gap-2 pl-5">
                            <div className="mb-4 mt-[37px]">
                                <div className="flex text-sm">
                                    <div className="font-medium w-52 text-[#aaa] shrink-0">Flat</div>
                                    <span>{rentPaymentOnlineRegistrationData?.flat_no ||  '-' }</span>
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="flex text-sm">
                                    <div className="font-medium w-52 text-[#aaa] shrink-0">Gender</div>
                                    <span>{rentPaymentOnlineRegistrationData?.gender ==='M' ? 'Male' : 'Female' }</span>
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="flex text-sm">
                                    <div className="font-medium w-52 text-[#aaa] shrink-0">Country</div>
                                    <span>{rentPaymentOnlineRegistrationData?.mobile?.split('-')?.[0]?.includes('+') ? rentPaymentOnlineRegistrationData?.mobile?.split('-')?.[0] : '-' }</span>
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="flex text-sm">
                                    <div className="font-medium w-52 text-[#aaa] shrink-0">Email</div>
                                    <span>{rentPaymentOnlineRegistrationData?.email || '-' }</span>
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="flex text-sm">
                                    <div className="font-medium w-52 text-[#aaa] shrink-0">Pin Code</div>
                                    <span>{rentPaymentOnlineRegistrationData?.pincode || '-' }</span>
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="flex text-sm">
                                    <div className="font-medium w-52 text-[#aaa] shrink-0">GST Registration Number</div>
                                    <span>{rentPaymentOnlineRegistrationData?.tenant_gstin || '-' }</span>
                                </div>
                            </div>
                            <div className="mb-4 mt-[83px]">
                                <div className="flex text-sm">
                                    <div className="font-medium w-52 text-[#aaa] shrink-0">Frequency Of Rent</div>
                                    <span>{rentPaymentOnlineRegistrationData?.frequency_of_rent || '-' }</span>
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="flex text-sm">
                                    <div className="font-medium w-52 text-[#aaa] shrink-0">Rent Agreement</div>
                                    <span>{rentPaymentOnlineRegistrationData?.rent_agreement_url ? <Link className="underline text-blue-500" to={rentPaymentOnlineRegistrationData?.rent_agreement_url} target="_blank">Click here to view</Link> : '-' }</span>
                                </div>
                            </div>
                            
                        </div> 
                    </div>
                }
            </div>
        </div>
        </div>
        </div>
        <div className="mt-3">
            <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg p-2">
                <div className="flex justify-between border-b border-[#CCC] pb-2">
                <h1 className="text-base p-1 font-semibold">Landlord Details</h1>
                </div>
                <div className="flex gap-4 p-2 min-h-[150px]">
                    {
                        loading && tenantId !== rentPaymentOnlineRegistrationData?.tenant_id?.toString() ?
                        <Loader />
                        :  
                        <div className="flex flex-col justify-between w-full gap-3">
                            <div className="flex justify-between w-full gap-3">
                            <div className="w-1/2 gap-2 pr-5">
                                <div className="mb-4">
                                    <div className="flex text-sm">
                                        <div className="font-medium w-52 text-[#aaa] shrink-0">Name</div>
                                        <span>{rentPaymentOnlineRegistrationData?.landlord_name || '-' }</span>
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <div className="flex text-sm">
                                        <div className="font-medium w-52 text-[#aaa] shrink-0">PAN Number</div>
                                        <span>{rentPaymentOnlineRegistrationData?.landlord_pan || '-' }</span>
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <div className="flex text-sm">
                                        <div className="font-medium w-52 text-[#aaa] shrink-0">Bank Name of Landlord</div>
                                        <span>{rentPaymentOnlineRegistrationData?.bank_name || '-' }</span>
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <div className="flex text-sm">
                                        <div className="font-medium w-52 text-[#aaa] shrink-0">IFSC Code</div>
                                        <span>{rentPaymentOnlineRegistrationData?.ifsc_code || '-' }</span>
                                    </div>
                                </div>

                            </div>
                            <div className="w-1/2 gap-2 pl-5">
                                <div className="mb-4 mt-[37px]">
                                    <div className="flex text-sm">
                                        <div className="font-medium w-52 text-[#aaa] shrink-0">GST Registration Number</div>
                                        <span>{rentPaymentOnlineRegistrationData?.landlord_gstin || '-' }</span>
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <div className="flex text-sm">
                                        <div className="font-medium w-52 text-[#aaa] shrink-0">Bank Account Number of Landlord</div>
                                        <span>{rentPaymentOnlineRegistrationData?.account_no || '-' }</span>
                                    </div>
                                </div>

                            </div>
                            </div>
                        </div>
                    }
                </div>
        </div>
        </div>
      </div>
    </Layout>
  );
};

export default ViewOnlineRegFormForRentPayment;
