
import * as types from '../index.types';

export function getTimeSlotTypeList(data) {
    return {
        type: types.GET_TIME_SLOT_TYPE_LIST,
        data
    };
}

export function addTimeSlotType(data) {
    return {
        type: types.ADD_TIME_SLOT_TYPE,
        data
    };
}

export function updateTimeSlotType(data) {
    return {
        type: types.UPDATE_TIME_SLOT_TYPE,
        data
    };
}

export function deleteTimeSlotType(data) {
    return {
        type: types.DELETE_TIME_SLOT_TYPE,
        data
    };
}
