import * as type from "../../actions/index.types";

export function documentReducer(
  state = {
    loading: false,
    updateLoader: false,
    loadingSettings: true,
    error: null,
    documentFolderList: [],
    documentFileList: [],
    selectedFolderId: 0,
    documentsOccupantList: [],
    rootFolderId: 0,
    subFolderId: 0,
  },
  action
) {
  switch (action.type) {
    case type.GET_DOCUMENT_FOLDER_LIST:
      return {
        ...state,
        loadingSettings: true,
      };

    case type.GET_DOCUMENT_FOLDER_LIST_SUCCESS:
      return {
        ...state,
        loadingSettings: false,
        documentFolderList: action.payload,
      };

    case type.GET_DOCUMENT_FOLDER_LIST_FAILURE:
      return {
        ...state,
        loadingSettings: false,
      };

    case type.GET_DOCUMENT_FILE_LIST:
      return {
        ...state,
        loading: true,
      };

    case type.GET_DOCUMENT_FILE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        documentFileList: action.payload,
      };

    case type.GET_DOCUMENT_FILE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case type.CHANGE_FOLDER_ID:
      return {
        ...state,
        selectedFolderId: action.data.folderId,
        rootFolderId: action.data.rootFolderId,
        subFolderId: action.data.subFolderId,
      };

      case type.ADD_FOLDER_NAME:
      return {
        ...state,
        addLoader: true,
      };
    case type.ADD_FOLDER_NAME_SUCCESS:
      return {
        ...state,
        addLoader: false,
      };
    case type.ADD_FOLDER_NAME_FAILURE:
      return {
        ...state,
        addLoader: false,
      };

      case type.GET_DOCUMENT_OCCUPANTS_LIST:
        return {
          ...state,
          loading: true,
        };
  
      case type.GET_DOCUMENT_OCCUPANTS_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
          documentsOccupantList: action.payload,
        };
  
      case type.GET_DOCUMENT_OCCUPANTS_LIST_FAILURE:
        return {
          ...state,
          loading: false,
        };

        case type.UPDATE_DOCUMENTS_LIST:
      return {
        ...state,
        addLoader: true,
      };
    case type.UPDATE_DOCUMENTS_LIST_SUCCESS:
      return {
        ...state,
        addLoader: false,
      };
    case type.UPDATE_DOCUMENTS_LIST_FAILURE:
      return {
        ...state,
        addLoader: false,
      };

      case type.Edit_DOCUMENTS_LIST:
        return {
          ...state,
          addLoader: true,
        };
      case type.Edit_DOCUMENTS_LIST_SUCCESS:
        return {
          ...state,
          addLoader: false,
        };
      case type.Edit_DOCUMENTS_LIST_FAILURE:
        return {
          ...state,
          addLoader: false,
        };

        case type.DELETE_DOCUMENT:
      return {
        ...state,
      };
    case type.DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        documentFileList: state.documentFileList?.filter(
            (item) =>
              !(
                item.id === action.payload.fileId
              )
          ),
      };
    case type.DELETE_DOCUMENT_FAILURE:
      return {
        ...state,
      };

    default:
      return state;
  }
}
