import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import TextRadio from "../../../../components/InputFields/TextRadio";
import Button from "../../../../components/Button/Button";
import ButtonG from "../../../../components/Button/ButtonG";
import { CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  downloadCSVAllMemberReport,
  getFinanceMembersList,
  getLedgers,
} from "../../../../redux/actions/ReportActions/OldFinancialReportsActions";
import { useSelector } from "react-redux";
import { financialYears2 } from "../../../../consts/DropDownOptions";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import { MultiSelectFM2 } from "../../../../components/MultiSelect/MultiSelect";
import { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import moment from "moment";
import { downloadExcel } from "../../../../utils/helpers/downloadMedia";

const Accordion = ({
  label,
  isOpen,
  toggleAccordion,
  children,
  totalAmount,
}) => {
  return (
    <div className="border rounded-lg border-[#CCCCCC] my-2">
      <div
        className="cursor-pointer items-center bg-[#FDF2F3] rounded-lg p-3 border-b"
        onClick={toggleAccordion}
      >
        <div className="flex items-center w-full">
          <div>
            <button
              className="text-white text-base font-semibold mr-3 w-5 h-5 bg-red-650 
              border rounded flex justify-center items-center"
            >
              {isOpen ? (
                <FontAwesomeIcon icon={faChevronUp} size="xs" />
              ) : (
                <FontAwesomeIcon icon={faChevronDown} size="xs" />
              )}
            </button>
          </div>
          <div className="text-[#000000] grow w-full text-sm font-semibold justify-between flex">
            <span className="">{label}</span>
            {!!totalAmount && <span className="">totalAmount</span>}
          </div>
        </div>
      </div>
      {isOpen && <>{children}</>}
    </div>
  );
};

const Report = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [openAccordionIndex, setOpenAccordionIndex] = useState([]);
  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, billTypeList, financeMembersList, ledgers } = useSelector(
    (state) => state.oldFinancialReportsReducers
  );

  const toggleAccordion = (index) => {
    setOpenAccordionIndex((prevIndex) =>
      prevIndex.includes(index)
        ? prevIndex.filter((item) => item != index)
        : [...prevIndex, index]
    );
  };
  const handleSaveClick = async (values, { resetForm, initialValues }) => {
    dispatch(
      downloadCSVAllMemberReport({
        ...values,
        society_id: loginData?.default_community?.zipgrid_socsrno,
        onSuccessCallback: (data) => {
          downloadExcel({ data, fileName: `Members Ledger Report.xlsx` });
          resetForm();
        },
      })
    );
  };

  useEffect(() => {
    dispatch(
      getFinanceMembersList({
        society_registration:
          "MYA" + loginData?.default_community?.zipgrid_socsrno,
      })
    );
  }, []);

  return (
    <Accordion
      key={1}
      label={"All Members Ledger"}
      totalAmount={0}
      isOpen={openAccordionIndex.includes(1)}
      toggleAccordion={() => {
        toggleAccordion(1);
      }}
    >
      <Formik
        initialValues={{
          fin_year: "202324",
          start_date: "2023-03-31",
          end_date: "2024-04-01",
          selected_member: financeMembersList
            ? financeMembersList?.slice(1)?.map((item) => item?.regnno)
            : [],
          format: "excel",
          button_type: "",
        }}
        onSubmit={handleSaveClick}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <div className="w-full h-full bg-white  border-[#CCCCCC] rounded-lg p-2">
                <>
                  <div className="flex gap-4 p-2">
                    <div className="flex flex-col justify-between w-full gap-3">
                      <div className="flex justify-between w-full gap-3">
                        <div className="w-1/2 gap-2 pr-5">
                          <div className="flex  justify-between text-sm mb-[16px]">
                            <span className="mt-2">Financial Year</span>
                            <Dropdown2
                              options={financialYears2}
                              placeholder="Choose Financial Year"
                              className=""
                              width="224px"
                              height="37.78px"
                              onSelect={(selectedItem) => {
                                const valueParts =
                                  selectedItem.value.split("-");
                                const selectedValue = `${
                                  valueParts[0]
                                }${valueParts[1].slice(2)}`;
                                setFieldValue(`fin_year`, selectedValue);
                              }}
                              value={
                                values?.fin_year && {
                                  value: values?.fin_year,
                                  label: financialYears2?.find((item) => {
                                    const valueParts = item.value.split("-");
                                    const selectedValue = `${
                                      valueParts[0]
                                    }${valueParts[1].slice(2)}`;
                                    return selectedValue === values?.fin_year;
                                  })?.label,
                                }
                              }
                            />
                          </div>
                          <div className="flex  justify-between text-sm">
                            <span className="mt-2">Start Date</span>
                            <DatePickerComponentFM
                              className="w-[224px] h-[37.78px] justify-between"
                              onDateChange={(selectedDate) => {
                                setFieldValue(
                                  "start_date",
                                  moment(selectedDate).format("YYYY-MM-DD")
                                );

                                if (
                                  new Date(values.end_date).getTime() <
                                  new Date(selectedDate).getTime()
                                ) {
                                  setFieldValue("end_date", null);
                                }
                              }}
                              name={`start_date`}
                              defaultValue={
                                values.start_date
                                  ? new Date(values.start_date)
                                  : null
                              }
                              maxDate={new Date("2024-03-31")}
                            />
                          </div>

                          <div className="my-2 mb-6">
                            <TextRadio
                              label="Format"
                              options={[
                                // { label: "PDF", value: "pdf" },
                                { label: "Excel", value: "excel" },
                              ]?.map((item) => ({
                                name: `excel`,
                                value: item.value,
                                label: item.label,
                              }))}
                              defaultSelected={values?.format}
                              onChange={(selectemItem) =>
                                setFieldValue(`format`, selectemItem)
                              }
                              className={"w-56"}
                            />
                          </div>
                        </div>
                        <div className="w-1/2 gap-2 pr-5">
                          <div className="flex  justify-between text-sm ">
                            <span className="mt-2">Select Members</span>
                            <MultiSelectFM2
                              options={
                                financeMembersList
                                  ? financeMembersList
                                      .slice(1)
                                      ?.map((item) => ({
                                        value: item?.regnno,
                                        label: item?.shortname,
                                      }))
                                  : []
                              }
                              width="224px"
                              height={"fit-content"}
                              placeholder={`Select Members`}
                              className="text-xs"
                              isMulti={true}
                              loading={loading}
                              value={
                                values.selected_member.length > 0
                                  ? financeMembersList
                                      ?.filter((item) =>
                                        values.selected_member.includes(
                                          item?.regnno
                                        )
                                      )
                                      ?.map((item) => ({
                                        value: item?.regnno,
                                        label: item?.shortname,
                                      }))
                                  : []
                              }
                              onSelect={(selectedItem) => {
                                setFieldValue(
                                  "selected_member",
                                  selectedItem.map((item) => item?.value)
                                );
                              }}
                              name={`selected_member`}
                            />
                          </div>
                          <div className="flex  justify-between text-sm ">
                            <span className="mt-2">End Date</span>
                            <DatePickerComponentFM
                              className="w-[224px] h-[37.78px] justify-between"
                              onDateChange={(selectedDate) => {
                                setFieldValue(
                                  "end_date",
                                  moment(selectedDate).format("YYYY-MM-DD")
                                );
                              }}
                              minDate={
                                values?.start_date
                                  ? new Date(values.start_date)
                                  : new Date()
                              }
                              name={`end_date`}
                              defaultValue={
                                values?.end_date
                                  ? new Date(values.end_date)
                                  : null
                              }
                              maxDate={new Date("2024-03-31")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                <div className="flex justify-between border-t border-[#CCCCCC] pt-2">
                  <div className="flex gap-2">
                    <Button
                      label={
                        <span
                          className={`w-28 inline-block ${
                            values.button_type == "download"
                              ? " pointer-events-none"
                              : ""
                          }`}
                        >
                          {values?.button_type === "download" ? (
                            <CircularProgress
                              sx={{ color: "white" }}
                              size={17}
                            />
                          ) : (
                            "Download"
                          )}
                        </span>
                      }
                      onClick={() => {
                        setFieldValue("button_type", "download");
                      }}
                      className="px-5 h-8"
                      type="submit"
                    />
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Accordion>
  );
};

export default Report;
