import * as Yup from "yup";

export const addMoreHeadValidation = () => {
  return Yup.object({
    headName: Yup.string().required("*Required"),
    glCode: Yup.string().required("*Required"),
    type: Yup.string().required("*Required"),
    amount: Yup.number().typeError("*Must be number"),
    formula: Yup.string(),
  });
};

export const addInvoiceSeriesValidation = () => {
  return Yup.object({
    invoiceType: Yup.string().required("*Required"),
    fromDate: Yup.date().required("*Required"),
    toDate: Yup.date().required("*Required"),
    startNumber: Yup.number().typeError("*Must be number").required("*Required"),
    prefix: Yup.string(),
    suffix: Yup.string(),
    isZero: Yup.boolean(),
    numDigit: Yup.string().when(['isZero'],(isZero,schema) => {
      return isZero[0] ? schema.required("*Required") : schema.nullable();
    }),
  });
};
export const addTaxableSubHeadValidation = () => {
  return Yup.object({
    mainHeadId: Yup.number().required("*Required"),
    subHeadId: Yup.number().required("*Required"),
    type: Yup.number().required("*Required"),
    active: Yup.boolean(),
  });
};

export const headSettingValidation = () => {
  return Yup.object({
    headDetail: Yup.object().shape({
        main_head: Yup.array().of(
          Yup.object().shape({
            active : Yup.string().nullable(),
            mainerror : Yup.boolean(),
            //tariff_header : Yup.string().required("*Required"),
            tariff_header : Yup.string().when(['active'],(active,schema) => {
              return active[0]==="Yes" ? schema.required("*Required") : schema.nullable();
            }),
            order : Yup.number().when(['active'],(active,schema) => {
              return active[0]==="Yes" ? schema.required("*Required") 
              : schema.nullable();
            })
            // .test("orderCheck", "Enter Right Order", function (value) {
            //   return !this.parent.mainerror && parseInt(value) != 0 
            //   //return false;
            // })
            ,
            gl_code : Yup.string().when(['active'],(active,schema) => {
              return active[0]==="Yes" ? schema.required("*Required") : schema.nullable();
            }),
          })
        ),
        more_head: Yup.array().of(
          Yup.object().shape({
            active : Yup.string().nullable(),
            moreerror : Yup.boolean(),
            invoice_head : Yup.string().when(['active'],(active,schema) => {
              return active[0]==="Yes" ? schema.required("*Required") : schema.nullable();
            }),
            order : Yup.string().when(['active'],(active,schema) => {
              return active[0]==="Yes" ? schema.required("*Required") : schema.nullable();
            })
            // .test("orderCheck1", "Enter Right Order", function (value) {
            //   return !this.parent.moreerror && parseInt(value) != 0 
            //   //return false;
            // })
            ,
            gl_code : Yup.string().when(['active'],(active,schema) => {
              return active[0]==="Yes" ? schema.required("*Required") : schema.nullable();
            }),
          })
        ),
        arrear_head: Yup.array().of(
          Yup.object().shape({
            invoice_head : Yup.string(),
            arrearerror : Yup.boolean(),
            active : Yup.string().nullable(),
            tariff_header : Yup.string().when(['active'],(active,schema) => {
              return active[0]==="Yes" ? schema.required("*Required") : schema.nullable();
            }),
            order : Yup.string().when(['active'],(active,schema) => {
              return active[0]==="Yes" ? schema.required("*Required") : schema.nullable();
            })
            // .test("orderCheck2", "Enter Right Order", function (value) {
            //   return !this.parent.arrearerror && parseInt(value) != 0 
            //   //return false;
            // })
            ,
            gl_code : Yup.string().when('invoice_head', {
              alternatively: (val) => val === "Current Period Interest",// alternatively: (val) => val == true
              then: (schema) => schema.required("*Required"),
              otherwise: (schema) => schema.nullable(),
            }),
          })
        ),
      })
  });
};
