import { call, put, takeLatest } from 'redux-saga/effects'
import {notify} from '../../../../utils/notification'
import * as types from '../../../actions/index.types'
import * as req from '../../../../axios/request/SetupRecordsReqs/BulkUploadReqs/BulkUploadReqs'


function* downloadExcelFormat(action) {
    try {
        const result = yield call(req.downloadExcelFormatReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
        }else{
            notify('Unable to download sheet.')
        }
    } catch (e) {
        console.log(e)
    }
}

function* uploadStocietyStaffList(action) {
    try {
        const result = yield call(req.uploadStocietyStaffListReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.UPLOAD_STAFF_EXCEL_SHEET_SUCCESS, payload:  {error: result?.data?.message}});  
            action.data?.onSuccessCallback?.()
        }else{
            yield put({ type: types.UPLOAD_STAFF_EXCEL_SHEET_FAILURE});  
        }
        action.data?.onRequestEndCallback?.()
    } catch (e) {
        console.log(e)
    }
}

function* uploadToUpdateOldOccupantsList(action) {
    try {
        const result = yield call(req.uploadOldOccupantsListReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.UPLOAD_TO_UPDATE_OLD_OCCUPANT_EXCEL_SHEET_SUCCESS, payload:  {error: result?.data?.message}});  
            action.data?.onSuccessCallback?.()
        }else{
            yield put({ type: types.UPLOAD_TO_UPDATE_OLD_OCCUPANT_EXCEL_SHEET_FAILURE});  
        }
        action.data?.onRequestEndCallback?.()
    } catch (e) {
        console.log(e)
    }
}

function* uploadNewOccupantsList(action) {
    try {
        const result = yield call(req.uploadNewOccupantsListReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.UPLOAD_NEW_OCCUPANT_EXCEL_SHEET_SUCCESS, payload:  {error: result?.data?.message}});  
            action.data?.onSuccessCallback?.()
        }else{
            yield put({ type: types.UPLOAD_NEW_OCCUPANT_EXCEL_SHEET_FAILURE});  
        }
        action.data?.onRequestEndCallback?.()
    } catch (e) {
        console.log(e)
    }
}


function* uploadOccupantOpeningBalance(action) {
    try {
        const result = yield call(req.uploadOccupantOpeningBalanceReq, action);
        if (result.success === true) {
            notify('success', 'File uploaded successfully')
            yield put({ type: types.UPLOAD_OCCUPANT_OPENING_BALANCE_EXCEL_SHEET_SUCCESS, payload: {data:result.data,error:result.error}});  
            action.data?.onSuccessCallback?.()
        }else{
            yield put({ type: types.UPLOAD_OCCUPANT_OPENING_BALANCE_EXCEL_SHEET_FAILURE, payload:  {errorMsg: result?.response?.data?.message,error: result?.response?.data?.error}});  
        }
        action.data?.onRequestEndCallback?.()
    } catch (e) {
        console.log(e)
    }
}

function* downloadOpeningBalanceExcelSample(action) {
    try {
        const result = yield call(req.downloadOpeningBalanceExcelSampleReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
        }else{
            notify('Unable to download sheet.')
        }
    } catch (e) {
        console.log(e)
    }
}

export default function* BulkUploadSaga() {
    yield takeLatest(types.DOWNLOAD_EXCEL_FORMAT, downloadExcelFormat);
    yield takeLatest(types.UPLOAD_STAFF_EXCEL_SHEET, uploadStocietyStaffList);
    yield takeLatest(types.UPLOAD_TO_UPDATE_OLD_OCCUPANT_EXCEL_SHEET, uploadToUpdateOldOccupantsList);
    yield takeLatest(types.UPLOAD_NEW_OCCUPANT_EXCEL_SHEET, uploadNewOccupantsList);
    yield takeLatest(types.UPLOAD_OCCUPANT_OPENING_BALANCE_EXCEL_SHEET, uploadOccupantOpeningBalance);
    yield takeLatest(types.DOWNLOAD_OCCUPANT_OPENING_BALANCE_SHEET_SAMPLE, downloadOpeningBalanceExcelSample);
}

