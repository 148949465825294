import React, { useEffect } from "react";
import ListHeader from "../../../components/ListHeader/ListHeader";
import Layout from "../../Layout/Layout";
import LienMortgageRegisterTable from "./LienMortgageRegisterTable";
import { getMemberMortgageRegisterList } from "../../../redux/actions/ComplianceActions/RegistersAction";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

export const headCells = [
  { id: "id", label: "S No.", width: "70px" },
  { id: "memberFullName", label: "Member Full Name", sort: false, width: "100px" },
  { id: "wingNo", label: "Wing No.", sort: true, width: "80px" },
  { id: "floorNo", label: "Flat No.", sort: true, width: "100px" },
  { id: "lien.", label: "Lien/NonLien", sort: true, width: "110px" },
  { id: "action", label: "Action", sort: true, width: "90px" },
];

const rows = [
    {
        id:"1",
        sNo: "01",
        memberFullName: "Himanshi Mehta",
        wingNo: "P-Block",
        floorNo: "1",
        lien: "Non Lien"
    },
    {
        id:"2",
        sNo: "02",
        memberFullName: "Himanshi Mehta",
        wingNo: "P-Block",
        floorNo: "2",
        lien: "Non Lien"
    },
];

const LienMortgageRegister = () => {
  const dispatch = useDispatch()
  const {loading, memberMortgageRegisterList} = useSelector(state=>state.registersReducer)
  const {loginData} = useSelector(state=>state.loginReducer)
  const getData = ()=>{
    dispatch(getMemberMortgageRegisterList({societyId: loginData?.default_community?.community_id,}))
  }

  useEffect(()=>{
    getData()
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <ListHeader leftContent="Member List 'Lien/Mortgage Register'" ></ListHeader>
        <div className="ml-1">
          <LienMortgageRegisterTable
            loading={loading}
            data={memberMortgageRegisterList?.map((item, index)=>({
              sNo: ( 1+index)?.toString()?.padStart(2, "0"),
              memberFullName: `${item?.subscriber_prefix ? item.subscriber_prefix : ''} ${item?.occupant_name}` || '-',
              wingNo: item.wing_no || '-',
              floorNo: item?.flat_no || '-',
              lien: item?.lien_status || '-',
            }))}            
            columns={headCells}
            checkbox={false}
            pagination={false}
          ></LienMortgageRegisterTable>
        </div>
        {!memberMortgageRegisterList?.length && !loading && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
    </Layout>
  );
};

export default LienMortgageRegister;
