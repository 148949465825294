import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { getPropertyRegisterDetails } from '../../../redux/actions/ComplianceActions/RegistersAction'
import Loader from '../../../components/Loader/Loader'
import moment from 'moment'
import { validateDate } from '../../../utils/helpers/universalFunctions'
import { PrinterIcon } from '../../../assets'

function PrintPropertyRegister() {
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const {loading, propertyRegisterData} = useSelector(state=>state.registersReducer)
    const {loginData} = useSelector(state=>state.loginReducer)

    useEffect(()=>{
        dispatch(getPropertyRegisterDetails({societyId: loginData?.default_community?.community_id,}))
    }, [])

    
  useEffect(() => {
    if(!loading && propertyRegisterData){
      window.print()
    }
  }, [loading]);

  return loading ? (
    <Loader />
  ) : (
    <div  className=" p-3 w-[990px] mx-auto">
        <div className="font-bold text-lg text-red-500 mb-5 text-center underline">PROPERTY REGISTER</div>
        <table className="table-fixed w-full mx-auto">
            <tbody className='text-sm align-top'>
                <tr>
                    <td className='p-1.5 w-[50%] font-semibold'>Name Of The Society	</td>
                    <td className='p-1.5 w-[50%]'>{propertyRegisterData?.title || '-'}</td>
                </tr>
                <tr>
                    <td className='p-1.5 w-[50%] font-semibold'> Address	</td>
                    <td className='p-1.5 w-[50%]'>{propertyRegisterData?.office_address || '-'}</td>
                </tr>
                <tr>
                    <td className='p-1.5 w-[50%] font-semibold'>Registration No. & Date	</td>
                    <td className='p-1.5 w-[50%]'>
                        {`Reg No.: ${propertyRegisterData?.regn_no} Dated:`}
                        {validateDate(propertyRegisterData?.date_of_regn) ? moment(propertyRegisterData?.date_of_regn)?.format('DD-MM-YYYY') : '-'}
                    </td>
                </tr>
                <tr>
                    <td className='p-1.5 w-[50%] font-semibold'>Municipal House No.		</td>
                    <td className='p-1.5 w-[50%]'>{propertyRegisterData?.municipal_house_no || '-'}</td>
                </tr>
                <tr>
                    <td className='p-1.5 w-[50%] font-semibold'>Survey No./Sub Div No.	</td>
                    <td className='p-1.5 w-[50%]'>{propertyRegisterData?.sub_div_no || '-'}</td>
                </tr>
                <tr>
                    <td className='p-1.5 w-[50%] font-semibold'>Land Free/Lease Hold	</td>
                    <td className='p-1.5 w-[50%]'>{propertyRegisterData?.lease_hold || '-'}</td>
                </tr>
                <tr>
                    <td className='p-1.5 w-[50%] font-semibold'> Property Type</td>
                    <td className='p-1.5 w-[50%]'>{propertyRegisterData?.property_type || '-'}</td>
                </tr>
                <tr>
                    <td className='p-1.5 w-[50%] font-semibold'>Annual Lease Rent	</td>
                    <td className='p-1.5 w-[50%]'>
                        <div className='flex'>
                            <div className="w-40">Total Monthly Rent</div>
                            <div>{propertyRegisterData?.total_monthly_rent || '-'}</div>
                        </div>
                        <div className='flex'>
                            <div className="w-40">Less :- Operational Charges</div>
                            <div>{propertyRegisterData?.operational_charges || '-'}</div>
                        </div>
                        <div className='flex'>
                            <div className="w-40">Monthly Rent</div>
                            <div>{propertyRegisterData?.monthly_rent || '-'}</div>
                        </div>
                        <div className='flex'>
                            <div className="w-40">Annual Rent</div>
                            <div>{propertyRegisterData?.annual_rent || '-'}</div>
                        </div>
                        <div className='flex'>
                            <div className="w-40">Less 10% Standard Deduction</div>
                            <div>{propertyRegisterData?.ratab_value || '-'}</div>
                        </div>
                        <div className='flex'>
                            <div className="w-40"> Annual Net Rent</div>
                            <div>{propertyRegisterData?.annual_net_rent || '-'}</div>
                        </div>
                    </td>
                </tr>

            </tbody>
        </table>

      <table className="table-fixed text-sm my-9 w-full mx-auto border border-black">
        <thead>
            <tr className='bg-[#DDDDDD] font-semibold'>
                <th className='p-1.5 border border-black w-[10%]'>Total Built Up Area</th>
                <th className='p-1.5 border border-black w-[10%]'>Land Admeasuring</th>
            </tr>
        </thead>

        <tbody className='text-sm align-top text-center'>
            <tr>
                <td className='p-1.5  border border-black w-[10%]'>{propertyRegisterData?.area_of_plot || '-'}</td>
                <td className='p-1.5  border border-black w-[10%]'>{propertyRegisterData?.const_unit || '-'}</td>
            </tr>
        </tbody>
      </table>

      <table className="table-fixed w-full mx-auto">
            <tbody className='text-sm align-top'>
                <tr>
                    <td className='p-1.5 w-[50%] font-semibold'>Total No. Of Flats		</td>
                    <td className='p-1.5 w-[50%]'>{propertyRegisterData?.no_of_flats || '-'}</td>
                </tr>
                <tr>
                    <td className='p-1.5 w-[50%] font-semibold'> Garage/Stilt Parking		</td>
                    <td className='p-1.5 w-[50%]'>{propertyRegisterData?.garage_parking || '-'}</td>
                </tr>
                <tr>
                    <td className='p-1.5 w-[50%] font-semibold'>Land Cost</td>
                    <td className='p-1.5 w-[50%]'>{propertyRegisterData?.land_cost || '-'}</td>
                </tr>
                <tr>
                    <td className='p-1.5 w-[50%] font-semibold'>Construction Cost	</td>
                    <td className='p-1.5 w-[50%]'>{propertyRegisterData?.const_cost || '-'}</td>
                </tr>
                <tr>
                    <td className='p-1.5 w-[50%] font-semibold'>Annual Non-Agricultural Assessment</td>
                    <td className='p-1.5 w-[50%]'>{propertyRegisterData?.non_agricultural_assessment || '-'}</td>
                </tr>
                <tr>
                    <td className='p-1.5 w-[50%] font-semibold'> Particulars Of Building	</td>
                    <td className='p-1.5 w-[50%]'>{propertyRegisterData?.particulars_of_building || '-'}</td>
                </tr>
                <tr>
                    <td className='p-1.5 w-[50%] font-semibold'> Completion/Occupation Certificate (BCC/OC)	</td>
                    <td className='p-1.5 w-[50%]'>{propertyRegisterData?.completion_certificate || '-'}</td>
                </tr>
                <tr>
                    <td className='p-1.5 w-[50%] font-semibold'>Occupation Date		</td>
                    <td className='p-1.5 w-[50%]'>
                        {validateDate(propertyRegisterData?.occupation_date) ? moment(propertyRegisterData?.occupation_date)?.format('DD-MM-YYYY') : '-'}
                    </td>
                </tr>
                <tr>
                    <td className='p-1.5 w-[50%] font-semibold text-lg' colSpan={2}> Municipal Assessment :	</td>
                </tr>
                <tr>
                    <td className='p-1.5 w-[50%] font-semibold'> Year	</td>
                    <td className='p-1.5 w-[50%]'>{propertyRegisterData?.municipal_year || '-'}</td>
                </tr>
                <tr>
                    <td className='p-1.5 w-[50%] font-semibold'>Total Ratable Value (Res)</td>
                    <td className='p-1.5 w-[50%]'>{propertyRegisterData?.ratable_value || '-'}</td>
                </tr>
                <tr>
                    <td className='p-1.5 w-[50%] font-semibold'> Date Of Conveyance		</td>
                    <td className='p-1.5 w-[50%]'>{validateDate(propertyRegisterData?.conveyance_date) ? moment(propertyRegisterData?.conveyance_date)?.format('DD-MM-YYYY') : '-'}</td>
                </tr>
            </tbody>
        </table>


    <table className="table-fixed text-sm my-9 w-full mx-auto border border-black">
        <thead>
            <tr className='bg-[#DDDDDD] font-semibold'>
                <th className='p-1.5 border border-black w-[33.3%]'>Date</th>
                <th className='p-1.5 border border-black w-[33.3%]'>Seal</th>
                <th className='p-1.5 border border-black w-[33.3%]'>Hon. Chairman/Secretary</th>
            </tr>
        </thead>

        <tbody className='text-sm align-top text-center'>
            <tr>
                <td className='h-16 border border-black'></td>
                <td className='h-16 border border-black'></td>
                <td className='h-16 border border-black'></td>
            </tr>
        </tbody>
      </table>
      <div className="my-9 underline font-extrabold text-sm uppercase justify-center items-center cursor-pointer text-red-500 flex" onClick={()=>{window.print()}}> <PrinterIcon/> &nbsp; PRINT</div>
    </div>
  );
}

export default PrintPropertyRegister