import * as type from '../../../actions/index.types'

export function managingCommitteeReducer(state = {
    loading: false,
    updateLoader: false,
    managingCommitteeList: null,
    loadingOccupants: false,
    occupantsFormanagingCommittee: null,
    allCommitteeAuditLog: null,
    managingCommitteeAuditLog: null,
    managingCommitteeData: null,
    archivedMembers: null,
    archivedMembersDetails: null,
    accessSettingData: null,
    error:null
}, action) {

switch (action.type) {
    case type.GET_MANAGING_COMMITTIEE_DETAILED_LIST:
        return {
          ...state,
          loading: true,
          error: null
      }
    case type.GET_MANAGING_COMMITTIEE_DETAILED_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        managingCommitteeList: action.payload
      }
    case type.GET_MANAGING_COMMITTIEE_DETAILED_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        loading: false,
        managingCommitteeList: null,
      }  

    case type.ADD_MANAGING_COMMITTIEE:
      return {
        ...state,
        updateLoader: true,
      }
    case type.ADD_MANAGING_COMMITTIEE_SUCCESS:
      return {
        ...state,
        updateLoader: false,
  
      }
    case type.ADD_MANAGING_COMMITTIEE_FAILURE:
      return {
        ...state,
        updateLoader: false,
      }

    case type.GET_OCCUPANTS_FOR_MANAGING_COMMITTIEE:
        return {
          ...state,
          loadingOccupants: true,
          error: null
      }
    case type.GET_OCCUPANTS_FOR_MANAGING_COMMITTIEE_SUCCESS:
      return {
        ...state,
        error: null,
        loadingOccupants: false,
        occupantsFormanagingCommittee: action.payload
      }
    case type.GET_OCCUPANTS_FOR_MANAGING_COMMITTIEE_FAILURE:
      return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        loadingOccupants: false,
        occupantsFormanagingCommittee: null,
      }  

    case type.GET_ALL_COMMITTEE_AUDIT_LOG:
        return {
          ...state,
          loading: true,
          error: null
      }
    case type.GET_ALL_COMMITTEE_AUDIT_LOG_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        allCommitteeAuditLog: action.payload
      }
    case type.GET_ALL_COMMITTEE_AUDIT_LOG_FAILURE:
      return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        loading: false,
        allCommitteeAuditLog: null,
      }  

    case type.GET_MANAGING_COMMITTIEE_AUDIT_LOGS:
        return {
          ...state,
          loading: true,
          error: null
      }
    case type.GET_MANAGING_COMMITTIEE_AUDIT_LOGS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        managingCommitteeAuditLog: action.payload
      }
    case type.GET_MANAGING_COMMITTIEE_AUDIT_LOGS_FAILURE:
      return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        loading: false,
        managingCommitteeAuditLog: null,
      }  

    case type.GET_MANAGING_COMMITTIEE_DETAILS:
      return {
        ...state,
        loading: true,
        error: null
    }
    case type.GET_MANAGING_COMMITTIEE_DETAILS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        managingCommitteeData: action.payload
      }
    case type.GET_MANAGING_COMMITTIEE_DETAILS_FAILURE:
      return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        loading: false,
        managingCommitteeData: null,
      }  

    case type.GET_ARCHIVE_MANAGING_COMMITTIEE_MEMBER:
        return {
          ...state,
          loading: true,
          error: null
      }
    case type.GET_ARCHIVE_MANAGING_COMMITTIEE_MEMBER_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        archivedMembers: action.payload
      }
    case type.GET_ARCHIVE_MANAGING_COMMITTIEE_MEMBER_FAILURE:
      return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        loading: false,
        archivedMembers: null,
      }  

    case type.UPDATE_MANAGING_COMMITTIEE:
      return {
        ...state,
        updateLoader: true,
      }
    case type.UPDATE_MANAGING_COMMITTIEE_SUCCESS:
      return {
        ...state,
        updateLoader: false,
  
      }
    case type.UPDATE_MANAGING_COMMITTIEE_FAILURE:
      return {
        ...state,
        updateLoader: false,
      }

    case type.GET_MANAGING_COMMITTIEE_ACCESS_SETTING:
        return {
          ...state,
          loading: true,
          error: null
      }
    case type.GET_MANAGING_COMMITTIEE_ACCESS_SETTING_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        accessSettingData: action.payload
      }
    case type.GET_MANAGING_COMMITTIEE_ACCESS_SETTING_FAILURE:
      return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        loading: false,
        accessSettingData: null,
      } 

    case type.UPDATE_MANAGING_COMMITTIEE_ACCESS_SETTING:
      return {
        ...state,
        updateLoader: true,
      }
    case type.UPDATE_MANAGING_COMMITTIEE_ACCESS_SETTING_SUCCESS:
      return {
        ...state,
        updateLoader: false,
  
      }
    case type.UPDATE_MANAGING_COMMITTIEE_ACCESS_SETTING_FAILURE:
      return {
        ...state,
        updateLoader: false,
      }

  case type.DELETE_MANAGING_COMMITTIEE:
    return {
      ...state,
      updateLoader: true
    }
  case type.DELETE_MANAGING_COMMITTIEE_SUCCESS:
    return {
      ...state,
      updateLoader: false,
      managingCommitteeList: {
        ...state.managingCommitteeList,
        list: state.managingCommitteeList?.list?.filter(item=>!(action.payload?.committeeIds?.includes(item.committee_id)))
      }
    }
    
   case type.DELETE_MANAGING_COMMITTIEE_FAILURE:
      return {
        ...state,
        updateLoader: false
      }

    case type.RESIGN_MANAGING_COMMITTIEE:
      return {
        ...state,
        updateLoader: true,
      }
    case type.RESIGN_MANAGING_COMMITTIEE_SUCCESS:
      return {
        ...state,
        updateLoader: false,
        managingCommitteeData: {
          ...state.managingCommitteeData,
          members: state.managingCommitteeData?.members?.map((members) => members?.role_data_id === action.payload.roleId
            ? {...members, resign_retire_status: action.payload.type}
            : members
        )
        }
  
      }
    case type.RESIGN_MANAGING_COMMITTIEE_FAILURE:
      return {
        ...state,
        updateLoader: false,
      }

    case type.RETIRE_MANAGING_COMMITTIEE:
      return {
        ...state,
        updateLoader: true,
      }
    case type.RETIRE_MANAGING_COMMITTIEE_SUCCESS:
      return {
        ...state,
        updateLoader: false,
        managingCommitteeData: {
          ...state.managingCommitteeData,
          members: state.managingCommitteeData?.members?.map((members) => members?.role_data_id === action.payload.roleId
            ? {...members, resign_retire_status: action.payload.type}
            : members
        )
        }
      }
    case type.RETIRE_MANAGING_COMMITTIEE_FAILURE:
      return {
        ...state,
        updateLoader: false,
      }


    case type.VIEW_ARCHIVED_MANAGING_COMMITTEE_MEMBER:
        return {
          ...state,
          loading: true,
          error: null
      }
    case type.VIEW_ARCHIVED_MANAGING_COMMITTEE_MEMBER_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        archivedMembersDetails: action.payload
      }
    case type.VIEW_ARCHIVED_MANAGING_COMMITTEE_MEMBER_FAILURE:
      return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        loading: false,
        archivedMembersDetails: null,
      } 


    case type.DELETE_ARCHIVED_MANAGING_COMMITTEE_MEMBER:
      return {
        ...state,
      }
    case type.DELETE_ARCHIVED_MANAGING_COMMITTEE_MEMBER_SUCCESS:
      return {
        ...state,
        archivedMembers:  state.archivedMembers?.filter(item=> item.role_data_id !== action.payload.roleId)
      }
      
    case type.DELETE_ARCHIVED_MANAGING_COMMITTEE_MEMBER_FAILURE:
      return {
        ...state,
      }



    case type.APPROVE_AND_REJECT_MANAGING_COMMITTEE_MEMBER_RESIGNATION:
      return {
        ...state,
        updateLoader: true,
      }
    case type.APPROVE_AND_REJECT_MANAGING_COMMITTEE_MEMBER_RESIGNATION_SUCCESS:
      return {
        ...state,
        updateLoader: false,
        managingCommitteeData: {
          ...state.managingCommitteeData,
          members: state.managingCommitteeData?.members?.map((members) => members?.role_data_id === action.payload.roleId
            ? {...members, resign_retire_status:'No'}
            : members
        )
        }
      }

    case type.APPROVE_AND_REJECT_MANAGING_COMMITTEE_MEMBER_RESIGNATION_FAILURE:
      return {
        ...state,
        updateLoader: false,
      }


    case type.APPROVE_MANAGING_COMMITTEE_MEMBER_RETIREMENT:
      return {
        ...state,
        updateLoader: true,
      }
    case type.APPROVE_MANAGING_COMMITTEE_MEMBER_RETIREMENT_SUCCESS:
        return {
          ...state,
          updateLoader: false,
          managingCommitteeData: {
            ...state.managingCommitteeData,
            members: state.managingCommitteeData?.members?.filter(item => item?.role_data_id !== action.payload?.roleId)
          }
        }
    case type.APPROVE_MANAGING_COMMITTEE_MEMBER_RETIREMENT_FAILURE:
      return {
        ...state,
        updateLoader: false,
      }
  

  case type.REAPPOINT_MANAGING_COMMITTEE_MEMBER:
    return {
      ...state,
      updateLoader: true,
    }
  case type.REAPPOINT_MANAGING_COMMITTEE_MEMBER_SUCCESS:
    return {
      ...state,
      updateLoader: false,

    }
  case type.REAPPOINT_MANAGING_COMMITTEE_MEMBER_FAILURE:
    return {
      ...state,
      updateLoader: false,
    }
default:
  return state
}
}