import React, { useRef } from "react";
import { TextInputFM } from "../../../../components/InputFields/TextInput";
import Modal from "../../../../components/Modal/Modal";
import { Form, Formik } from "formik";
import { AddTimeSlotTypeValidation } from "../../../../validationSchema/ClubSchema/AddTimeSlotSchema";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateTimeSlotType } from "../../../../redux/actions/ClubActions/AddTimeSlotAction";

const EditTimeSlotTypeModal = ({ isOpen, data, onCancel, loading }) => {
  const formRef = useRef();
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {updateLoader} = useSelector(state=>state.addTimeSlotReducer)

  const onConfirm = () =>{
    formRef.current?.submitForm();
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      title="Edit Type"
      onCancel={onCancel}
      onConfirm={onConfirm}
      loading={updateLoader}
    >
      <Formik
        initialValues={{
          type: data.type || '',
          typeId: data.typeId || 0
        }}
        enableReinitialize
        innerRef={(f) => (formRef.current = f)}
        validationSchema={AddTimeSlotTypeValidation}
        onSubmit={(values, {resetForm})=>dispatch(updateTimeSlotType({...values, societyId: loginData?.default_community?.community_id, onSuccessCallback: ()=>{onCancel()}}))}
        >
          {
            ({values})=>(
            <Form>
              <TextInputFM label="Edit Type" placeholder="Enter Type" name='type' />
            </Form>
            )
        }
      </Formik>
    </Modal>
  );
};

export default EditTimeSlotTypeModal;
