import React, { useEffect } from "react";
import Layout from "../../../Layout/Layout";
import { useNavigate } from "react-router-dom";
import ParkingAuditLogsAccordions from "./ParkingAuditlogsAccordions";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getParkingChargeAudit } from "../../../../redux/actions/GateManagementActions/ParkingManagementAction";
import Loader from "../../../../components/Loader/Loader";

const ParkingChargesAuditLogs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading} = useSelector(state=>state.parkingManagementReducer)

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  useEffect(()=>{
    dispatch(getParkingChargeAudit({societyId: loginData?.default_community?.community_id}))
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack
            title="Audit Log Of Society Parking Charges"
            onClick={handleNavigation(-1)}
          ></ListHeaderBack>
        </div>
        <div>
          {
            loading ?
            <Loader/>
            : 
            <ParkingAuditLogsAccordions />
          }
        </div>
      </div>
    </Layout>
  );
};

export default ParkingChargesAuditLogs;
