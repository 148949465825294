import React, { useEffect, useState, useCallback } from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import Button from "../Button/Button";
import { CircularProgress, Rating } from "@mui/material";
import { TextArea } from "../InputFields/TextArea";
import { useDispatch } from "react-redux";
import {
  getSocietyStaffRatingData,
  getSocietyStaffRatingParameters,
  updateSocietyStaffRating,
} from "../../redux/actions/SetupRecordsActions/StaffManagementActions/StaffPersonnelAction";
import { addComplaintDashboardReview } from "../../redux/actions/DeskActions/DeskAction";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import { getPermission } from "../../utils/helpers/getPermissions";
import * as permissions from "../../Routing/permissions";

const ReviewSection = ({ name, date, label, description, value }) => {
  const { loginData } = useSelector((state) => state.loginReducer);

  return (
    <div className=" mt-4 ml-4">
      <div className="flex justify-between">
        {getPermission(loginData, [
          permissions.SHOW_REVIEWER_NAME_IN_STAFF_REVIEWS_PERMISSION,
        ]) && (
          <div className="font-semibold flex items-center justify-between  basis-full me-1">
            <div className="w-24 inline-block text-nowrap text-ellipsis overflow-hidden">
              {name}{" "}
            </div>{" "}
            - <div className="font-normal text-xs text-[#BBB]">{date}</div>
          </div>
        )}
        <Rating name="read-only" precision={0.1} value={value} readOnly />
      </div>
      <p className="font-normal text-[#222] text-justify">{description}</p>
    </div>
  );
};

const ReviewModal = ({ isOpen, onClose, row }) => {
  const dispatch = useDispatch();
  const [parameters, setParameters] = useState([]);
  const [comment, setComment] = useState("");
  const { loginData } = useSelector((state) => state.loginReducer);
  const { ratingData, ratingDataLoader, ratingParameters, updateLoader } =
    useSelector((state) => state.staffPersonnelReducer);

  const handleChangeRating = (e, value, index) => {
    setParameters((prev) => {
      let list = [...prev];
      prev[index].value = value || 0;
      return list;
    });
  };

  const handleSubmit = () => {
    dispatch(
      updateSocietyStaffRating({
        societyId: loginData?.default_community?.community_id,
        parameters,
        comment,
        ratingData,
        avgRating:
          parameters?.reduce((total, next) => total + next.value, 0) /
          parameters?.length,
        ratingId: ratingData?.my_given_rating?.[0]?.rating_id,
        staffId: row.staffId,
      })
    );
  };

  useEffect(() => {
    dispatch(getSocietyStaffRatingData({ staffId: row?.staffId }));
    dispatch(
      getSocietyStaffRatingParameters({
        societyId: loginData?.default_community?.community_id,
      })
    );
  }, []);

  useEffect(() => {
    if (ratingParameters?.length && ratingData) {
      setParameters(
        ratingParameters?.map((item, index) => ({
          ...item,
          value: ratingData?.my_given_rating?.[0]?.ratings[index]?.rating || 0,
        }))
      );
    }
    setComment(ratingData?.my_given_rating?.[0]?.comment || "");
  }, [ratingParameters, ratingData]);

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-60 z-20 ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="bg-white rounded-lg w-[760px] h-[435px]">
        <div className="flex p-2 -mb-2">
          <div className="w-[70%] font-semibold text-lg text-center flex justify-end items-center mb-1">
            Give Reviews
          </div>
          <div className="w-1/2 flex justify-end">
            <button
              onClick={onClose}
              className="bg-[#EAEAEA] rounded-full p-2 items-center mb-2 w-9 h-9"
            >
              <CloseIcon className="fill-current text-gray-600 hover:text-gray-800 ml-1 w-3 h-3 cursor-pointer" />
            </button>
          </div>
        </div>
        <div className="border-b border-[#CCCCCC]"></div>
        {ratingDataLoader ? (
          <Loader />
        ) : (
          <div className="w-full flex p-2">
            <div className="w-1/2 pr-2">
              <div className="font-semibold text-sm text-blue-500 ml-4 mb-2 capitalize">
                {row?.memberName}
              </div>
              <div className="h-[340px] overflow-scroll">
                {ratingData?.rating?.map((item, index) => (
                  <ReviewSection
                    key={index}
                    name={item?.rate_by || "Anonymous"}
                    date={
                      item?.updated_at ? item?.updated_at : item?.created_at
                    }
                    label="Punctuality"
                    value={item?.avg_rating}
                    description={item?.comment}
                  />
                ))}
              </div>
            </div>
            <div className="border-r border-[#CCC] mx-2"></div>
            <div className="w-1/2 mt-4 ml-2">
              <div className="h-[310px] overflow-scroll">
                <div className="rating-container-table text-sm">
                  {ratingData &&
                    parameters?.map((item, index) => (
                      <div className="items-center flex mb-4" key={index}>
                        <span className="w-1/2">{item?.label}</span>
                        <Rating
                          name={item.key}
                          value={item.value}
                          // onChange={(e, value)=>handleChangeRating(e, value, index)}
                        />
                      </div>
                    ))}
                  <div>
                    {"fadsf" + comment}
                    <TextArea
                      label="Comment"
                      placeholder="Enter Comment"
                      className={"w-[170px] mr-2 h-20"}
                      maxLength={200}
                      onChange={(e) => setComment(e.target.value)}
                      value={comment}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-center">
                <Button
                  label={
                    <span className="w-12 inline-block">
                      {updateLoader ? (
                        <CircularProgress sx={{ color: "white" }} size={17} />
                      ) : (
                        "Submit"
                      )}
                    </span>
                  }
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const ReviewModal2 = ({
  getTableData = null,
  onChangeReview = null,
  isOpen,
  onClose,
  row,
}) => {
  const dispatch = useDispatch();
  const [parameters, setParameters] = useState([
    { key: 1, label: "Ratings", value: 0 },
  ]);
  const [comment, setComment] = useState("");
  const { loginData } = useSelector((state) => state.loginReducer);

  const handleChangeRating = (e, value, index) => {
    setParameters((prev) => {
      let list = [...prev];
      prev[index].value = value || 0;
      return list;
    });
  };

  useEffect(() => {
    setParameters((prev) => {
      prev[0].value = row?.review_rating || 0;
      return prev;
    });
    setComment(row?.review_comment || "");
  }, [row]);

  const handleChangeReview = useCallback(
    (id = null, rating) => {
      onChangeReview((e) => {
        let list = [...e];
        list[id - 100] = { id: null, rating: null };
        list[id - 100].id = id;
        list[id - 100].rating = rating;
        return list;
      });
    },
    [onChangeReview]
  );

  const handleSubmit = () => {
    dispatch(
      addComplaintDashboardReview({
        complaint_id: row?.id,
        review_comment: comment,
        review_rating: parameters[0]?.value,
        onRequestEndCallback: (rating) => {
          onClose();
          handleChangeReview(row?.id, rating);
          getTableData();
        },
      })
    );
  };

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-60 z-20 ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="bg-white rounded-lg  ">
        <div className="flex p-2 -mb-2">
          <div className="w-[70%] font-semibold text-lg text-center flex justify-start items-center mb-1">
            Give Reviews
          </div>
          <div className="w-1/2 flex justify-end">
            <button
              onClick={onClose}
              className="bg-[#EAEAEA] rounded-full p-2 items-center mb-2 w-9 h-9"
            >
              <CloseIcon className="fill-current text-gray-600 hover:text-gray-800 ml-1 w-3 h-3 cursor-pointer" />
            </button>
          </div>
        </div>
        <div className="border-b border-[#CCCCCC]"></div>
        {!loginData ? (
          <Loader />
        ) : (
          <div className="w-full flex p-2">
            <div className="w-full mt-4 mx-2">
              <div className=" overflow-scroll">
                <div className="rating-container-table text-sm">
                  {!!loginData &&
                    parameters?.map((item, index) => (
                      <div className="items-center flex mb-4" key={index}>
                        <span className="w-[107px]">{item?.label}</span>
                        <div className="w-[212px]">
                          <Rating
                            name={item?.label}
                            value={item?.value}
                            onChange={(e, value) =>
                              handleChangeRating(e, value, index)
                            }
                            className={`${
                              !!row?.review_comment &&
                              !!row?.review_rating &&
                              " pointer-events-none"
                            }`}
                          />
                        </div>
                      </div>
                    ))}
                  <div>
                    <TextArea
                      label="Comment"
                      placeholder="Enter Comment"
                      className={`${
                        !!row?.review_comment &&
                        !!row?.review_rating &&
                        " pointer-events-none"
                      } mr-2 h-20 w-[200px]`}
                      maxLength={200}
                      onChange={(e) => setComment(e.target.value || "")}
                      value={comment}
                    />
                  </div>
                </div>
              </div>
              {(!row?.review_comment || !row?.review_rating) && (
                <div
                  className={`${
                    !comment.trim() ||
                    comment.length > 200 ||
                    !parameters[0].value
                      ? " opacity-50 pointer-events-none "
                      : ""
                  } flex justify-center pb-4`}
                >
                  <Button
                    label={
                      <span className="w-12 inline-block">
                        {!loginData ? (
                          <CircularProgress sx={{ color: "white" }} size={17} />
                        ) : (
                          "Submit"
                        )}
                      </span>
                    }
                    onClick={handleSubmit}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReviewModal;
