import React, { useEffect, useState } from "react";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import Layout from "../../pages/Layout/Layout";
import ContactCategoryTable from "./ContactCategoryTable";
import { categoryData } from "./ContactData";
import AddCategoryModal from "./AddCategoryModal";
import { CONTACT_ENDPOINT, MANAGE_CATEGORY_ENDPOINT } from "../../Routing/routes";
import Button from "../Button/Button";
import Search from "../SearchBox/Search";
import ListHeaderBack from "../ListHeader/ListHeaderBack";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getContactCategoryList } from "../../redux/actions/SocialAndCommunicationActions/LobbyActions/ImportantContactsAction";
import ButtonG from "../Button/ButtonG";

export const headingsCategory = [
  { id: 'sNo', label: 'SNo', sort: false, width: '60px' },
  { id: 'name', label: 'Name', sort: true,width: '600px' },
  { id: 'status', label: 'Status', sort: true, width: '230px' },
  {
    id: "action",
    label: "Action",
    sort: false,
  },
];

const ManageCategory = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loadingCategories, categoryList} = useSelector(state=>state.importantContactsReducer)
  const [isAddCategoryPopupOpen, setIsAddCategoryPopupOpen] = useState(false);

  const [isEditingCategory, setIsEditingCategory] = useState(false);
  const [categoryToEdit, setCategoryToEdit] = useState(null);

  const handleSearch = (text)=>{
    if(text && text !== searchParams.get("search")){
      searchParams.delete("search")
      searchParams.delete('page')
      searchParams.delete('pageLimit')
      navigate({
        pathname: `${MANAGE_CATEGORY_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["search", text]])}`,
      })
    }
  }

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleAddCategoryClick = () => {
    setIsAddCategoryPopupOpen(true);
  };

  const handleAddCategory = (categoryName) => {
    const newCategory = {
      id: categoryData.length + 1,
      name: categoryName,
    };
    categoryData.push(newCategory);
  };

  const handleEditCategoryClick = (category) => {
    setCategoryToEdit(category);
    setIsEditingCategory(true);
  };

  const handleEditCategory = (categoryId, categoryName, isSpecialCategory) => {
    const editedCategoryIndex = categoryData.findIndex(
      (c) => c.id === categoryId
    );
    if (editedCategoryIndex !== -1) {
      categoryData[editedCategoryIndex] = {
        ...categoryData[editedCategoryIndex],
        name: categoryName,
        isSpecial: isSpecialCategory,
      };
    }
    setIsEditingCategory(false);
  };

  
  const handleClear = ()=>{
    navigate(MANAGE_CATEGORY_ENDPOINT)
  }

  const getTableData =() =>{
    const data={
      societyId: loginData?.default_community?.community_id,
      searchText: searchParams.get("search") || '',
    }

    dispatch(getContactCategoryList(data))
  }

  useEffect(()=>{      
    getTableData()
  }, [searchParams])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 ">
        <ListHeaderBack
          onClick={handleNavigation(CONTACT_ENDPOINT)}
          title="Important Contacts Category"
        >
          <Search height="8" onclick={handleSearch} value={searchParams.get("search")} />
          <ButtonG label='Clear' className='h-8' onClick={handleClear} />
          <span className="border-r border-[#CCCCCC]"></span>
          <div className="flex">
            <Button
              label="Add Category"
              onClick={handleAddCategoryClick}
              className="font-semibold h-8"
            />
          </div>
        </ListHeaderBack>

        <ContactCategoryTable
            loading={loadingCategories}
            data={categoryList?.map((item, index)=>({
              sNo: (1+index)?.toString()?.padStart(2, "0"),
              categoryId: item?.cat_id || '-',
              name: item.category || '-',
              concernedPerson: item?.concerned_person || '-',
              contact: item?.contact || '-',
              email: item?.email1,
              status:  item?.status === 'A' ?  'Active': (item?.status === 'I' ? 'Inactive': 'Unapprove'),
            }))}
            columns={headingsCategory}
            checkbox={false}
            pagination={categoryList?.length}
            totalCount={categoryList?.total_record}
            totalPages={categoryList?.total_pages}
            start={(categoryList?.current_page_no - 1) * categoryList?.page_limit + 1}
            end={(categoryList?.current_page_no - 1) * categoryList?.page_limit + categoryList?.length}
            currentPage={categoryList?.current_page_no}
            rowsPerPage={searchParams.get("pageLimit") || 10}
            getTableData={getTableData}

          ></ContactCategoryTable>
          <AddCategoryModal
            isOpen={isAddCategoryPopupOpen || isEditingCategory}
            onClose={() => {
              setIsAddCategoryPopupOpen(false);
              setIsEditingCategory(false);
              setCategoryToEdit(null);
            }}
            onAddCategory={handleAddCategory}
            onEditCategory={handleEditCategory}
            categoryToEdit={categoryToEdit}
          />
      </div>
    </Layout>
  );
};

export default ManageCategory;
