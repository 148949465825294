import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import ListHeader from "../../components/ListHeader/ListHeader";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import {
  CREATE_FOLDER_ENDPOINT,
  CREATE_SUBFOLDER_ENDPOINT,
  UPLOAD_DOCUMENT_ENDPOINT,
} from "../../Routing/routes";
import { useDispatch, useSelector } from "react-redux";
import {
  changeFolderId,
  getDocFileList,
  getFolderList,
} from "../../redux/actions/DeskActions/DocumentActions";
import DocumentsTable from "../../components/TableComponent/DocumentsTable";
import { getPermission } from "../../utils/helpers/getPermissions";
import * as permissions from "../../Routing/permissions";
import { FolderOpen } from "@mui/icons-material";
import FolderIcon from "@mui/icons-material/Folder";

const headCells = [
  { id: "sNo", label: "S No.", sort: false, width: "50px" },
  { id: "filename", label: "File Name", sort: false, width: "250px" },
  { id: "download", label: "View", sort: false, width: "300px" },
  { id: "action", label: "Action", sort: false },
];

function Folder({ folder, open, onClick, Icon }) {
  const dispatch = useDispatch();
  return (
    <div
      className={`cursor-pointer hover:bg-[#CF001C0A] ${
        open ? "font-bold text-red-650" : ""
      }`}
    >
      <div
        className="border-b border-[#CCC] py-1 mx-0 flex items-center gap-2"
        onClick={() => {
          onClick && onClick();
        }}
      >
        <div className="flex gap-4 items-center">
        <div className="w-3 h-3 mb-3">
        <Icon />
        </div>
        {folder.name}
        </div>
      </div>
      {open && (
        <div className="ml-4">
          {folder.sub_folders?.map((subFolder) => (
            <Folder
              Icon={FolderOpen}
              key={subFolder.id}
              folder={subFolder}
              open
              onClick={() => {
                dispatch(
                  changeFolderId({
                    folderId: subFolder.id,
                    rootFolderId: folder.id,
                    subFolderId: subFolder.id,
                  })
                );
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
}

function Folders({ folders }) {
  const [open_id, setOpenID] = useState(null);
  const dispatch = useDispatch();

  return folders?.map((folder) => (
    <Folder
      Icon={FolderIcon}
      key={folder.id}
      folder={folder}
      open={folder.id === open_id}
      onClick={() => {
        setOpenID(folder.id);
        dispatch(
          changeFolderId({
            folderId: folder.id,
            rootFolderId: folder.id,
            subFolderId: null,
          })
        );
      }}
    />
  ));
}

function Files() {
  const data = useSelector((state) => state.documentReducer);
  const { loginData } = useSelector((state) => state.loginReducer);
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.documentReducer);

  useEffect(() => {
    dispatch(
      getDocFileList({
        selectedFolderId: data.selectedFolderId,
        societyId: loginData?.default_community?.community_id,
      })
    );
  }, [
    data.selectedFolderId,
    loginData?.default_community?.community_id,
    dispatch,
  ]);
  return (
    <>
      <DocumentsTable
        loading={loading}
        data={data?.documentFileList?.map((item) => ({
          id: item.id,
          filename: item.title,
          filePath: item.file,
        }))}
        columns={headCells}
        checkbox={true}
        pagination={false}
      ></DocumentsTable>
      {!data?.documentFileList?.length && (
        <div className="text-sm text-[#AAAAAA] text-center italic">
          No Records
        </div>
      )}
    </>
  );
}

function ComplaintAllDocuments() {
  const navigate = useNavigate();
  const data = useSelector((state) => state.documentReducer);
  const { loginData } = useSelector((state) => state.loginReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    let payload = { societyId: loginData?.default_community?.community_id };
    dispatch(getFolderList(payload));
  }, [dispatch, loginData?.default_community?.community_id]);

  const handleCreateFolderClick = () => {
    navigate(CREATE_FOLDER_ENDPOINT);
  };

  const handleCreateSubFolderClick = () => {
    navigate(CREATE_SUBFOLDER_ENDPOINT);
  };

  const handleUploadDocumentClick = () => {
    navigate(UPLOAD_DOCUMENT_ENDPOINT);
  };
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-3">
        <div>
          <ListHeader leftContent="Documents" onClick={() => navigate(-1)}>
            {getPermission(loginData, [
              permissions.CREATE_FOLDER_PERMISSION,
            ]) && (
              <>
                <Button label="Add Folders" onClick={handleCreateFolderClick} />
                <Button
                  label="Add SubFolders"
                  onClick={handleCreateSubFolderClick}
                />
              </>
            )}
            {getPermission(loginData, [
              permissions.UPLOAD_DOCUMENTS_PERMISSION,
            ]) && (
              <>
                <div className="mx-0.5 border-l-2"></div>
                <Button
                  label="Upload Document"
                  onClick={handleUploadDocumentClick}
                />
              </>
            )}
          </ListHeader>
        </div>
        <div className="w-full flex">
          <div className="w-1/4 border-t border-r border-[#CCC]">
            <p className="text-center text-base font-semibold border-b border-[#CCC] py-[12px]">
              Folders
            </p>
            <div>
              <Folders
                folders={data.documentFolderList}
                className="cursor-pointer"
              />
            </div>
          </div>
          <div className="w-3/4">
            <Files />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ComplaintAllDocuments;
