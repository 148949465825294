import { call, put, takeLatest } from 'redux-saga/effects'
import {notify} from '../../../utils/notification'
import * as types from '../../actions/index.types'
import * as req from '../../../axios/request/ComplianceReqs/MeetingsReqs'

function* getNoticeList(action) {
    try {
        const result = yield call(req.getNoticeListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_NOTICE_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_NOTICE_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getSavedNoticeDetails(action) {
    try {
        const result = yield call(req.getSavedNoticeDetailsReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_SAVED_NOTICE_DETAILS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_SAVED_NOTICE_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateSavedNotice(action) {
    try {
        const result = yield call(req.updateSavedNoticeReq, action);
        if (result.success === true) {
            notify('success', result.message)
            yield put({ type: types.UPDATE_SAVED_NOTICE_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.UPDATE_SAVED_NOTICE_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}


function* deleteSavedNotic(action) {
    try {
        const result = yield call(req.deleteSavedNoticReq, action);
        if (result.statusCode === 200) {
            notify('success', result.message);
            action.data?.onSuccessCallback?.()
            yield put({ type: types.DELETE_SAVED_NOTICE_SUCCESS, payload: action.data});  
            
            const newList = yield call(req.getNoticeListReq, action)
            if (newList.statusCode === 200)
                yield put({ type: types.GET_NOTICE_LIST_SUCCESS, payload: newList.data});  
        }else{
            yield put({ type: types.DELETE_SAVED_NOTICE_FAILURE});  

        }
    } catch (e) {
        console.log(e)
    }
}

export default function* meetingsSaga() {
    yield takeLatest(types.GET_NOTICE_LIST, getNoticeList);
    yield takeLatest(types.GET_SAVED_NOTICE_DETAILS, getSavedNoticeDetails);
    yield takeLatest(types.UPDATE_SAVED_NOTICE, updateSavedNotice);
    yield takeLatest(types.DELETE_SAVED_NOTICE, deleteSavedNotic);
}