import * as type from "../../../actions/index.types";

export function gstAndNOCReducer(
  state = {
    loading: false,
    updateLoader: false,
    gstAndNOCDetails: null,
    error: null,
  },
  action
) {
  switch (action.type) {
    case type.GET_GST_NOC_SETTING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_GST_NOC_SETTING_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        gstAndNOCDetails: action.payload,
      };
    case type.GET_GST_NOC_SETTING_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        gstAndNOCDetails: null,
      };
         
    
case type.UPDATE_GST_NOC_SETTING:
    return {
      ...state,
      updateLoader: true
    }
  case type.UPDATE_GST_NOC_SETTING_SUCCESS:
    return {
        ...state,
        updateLoader: false
      }

  case type.UPDATE_GST_NOC_SETTING_FAILURE:
    return {
        ...state,
        updateLoader: false
      }


    default:
      return state;
  }
}
