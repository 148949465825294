import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TextInputFM } from "../../../../components/InputFields/TextInput";
import Layout from "../../../Layout/Layout";
import Button from "../../../../components/Button/Button";
import ButtonG from "../../../../components/Button/ButtonG";
import { SOCIETY_STAFF_ENDPOINT } from "../../../../Routing/routes";
import { TextAreaFM } from "../../../../components/InputFields/TextArea";
import ProfilePicture from "../../../../components/ProfilePicture/ProfilePicture";
import AddStaffBoxForm from "./AddStaffBoxForm";
import { CaptureButtonFM } from "../../../../components/Button/CaptureButton";
import { UploadButtonFM } from "../../../../components/Button/UploadButton";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  addSocietyStaff,
  getSocietyStaffProfileList,
  getSocietyWingFlatList,
} from "../../../../redux/actions/SetupRecordsActions/StaffManagementActions/StaffPersonnelAction";
import { ErrorMessage, Form, Formik } from "formik";
import { AddSocietyStaffValidation } from "../../../../validationSchema/setupRecordsSchema/staffManagementSchema";
import { CircularProgress } from "@mui/material";
import { DropdownFM2 } from "../../../../components/Dropdown/dropdown2";
import {
  genderOptions,
  namePrefixOptions,
  societyStaffTypeOptions,
  societyStaffVehicleTypeOptions,
} from "../../../../consts/DropDownOptions";
import { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import moment from "moment";
import countries from "../../../../consts/countries";
import { DOMESTIC_STAFF_TYPE } from "../../../../consts/constant";
import { getOccupantDetails } from "../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/OccupantsInfoAction";
import Loader from "../../../../components/Loader/Loader";

const AddSocietyStaff = ({ type }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { subscribeId, userId, flatId } = useParams();

  const { loginData } = useSelector((state) => state.loginReducer);
  const { updateLoader, staffProfileList } = useSelector(
    (state) => state.staffPersonnelReducer
  );
  const [buttonType, setButtonType] = useState("");
  const occupantDetailLoading = useSelector(
    (state) => state.occupantInfoReducer.loading
  );
  const occupantDetail = useSelector(
    (state) => state.occupantInfoReducer.occupantDetail
  );

  const handleCancelClick = () => {
    navigate(-1);
  };

  const handleSaveClick = (values, { resetForm, initialValues }) => {
    if (loginData?.default_community?.blocks) {
      values.memberTitleId =
        loginData?.default_community?.blocks?.member_title_id;
    }
    // if (loginData?.default_community?.committees) {
    //   values.committeeTitleId =
    //     loginData?.default_community?.committees[0]?.committeeid;
    // }

    dispatch(
      addSocietyStaff({
        ...values,
        societyId: loginData?.default_community?.community_id,
        onSuccessCallback:
          buttonType === "Save"
            ? () => {
                navigate(-1);
              }
            : () => {
                resetForm();
              },
      })
    );
    // debugger;
  };

  useEffect(() => {
    const societyId = loginData?.default_community?.community_id;
    dispatch(getSocietyStaffProfileList({ societyId }));
    dispatch(getSocietyWingFlatList({ societyId }));

    if (type === DOMESTIC_STAFF_TYPE)
      dispatch(
        getOccupantDetails({
          subscribeId: subscribeId,
          societyId: loginData?.default_community?.community_id,
          userType: loginData?.user_type,
          userId: userId,
        })
      );
  }, []);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          initialValues={{
            prefix: "",
            firstName: "",
            lastName: "",
            profilePic: "",
            staffType: type === DOMESTIC_STAFF_TYPE ? "Domestic" : "Society",
            staffProfileId: "",
            startValidity: "",
            endValidity: "",
            workPerDay: "",
            salary: "",
            mobileCountryCode: "",
            countryISO: "",
            countryId: "",
            contact: "",
            email: "",
            gender: "",
            address: "",
            dob: "",
            vehicleType: "",
            vehicleNumber: "",
            fingerPrintImg: "",
            adharCardNo: "",
            adharDoc: "",
            panCardNo: "",
            panDoc: "",
            drivingLicenceNo: "",
            drivingLicenceDoc: "",
            rationNo: "",
            rationDoc: "",
            flatData:
              type === DOMESTIC_STAFF_TYPE
                ? [
                    {
                      flatId: parseInt(flatId) || "",
                      joinDate: "",
                      status: "",
                    },
                  ]
                : [],
          }}
          onSubmit={handleSaveClick}
          validationSchema={AddSocietyStaffValidation}
        >
          {({ values, errors, setFieldValue }) => {
            return (
              <Form>
                {/* {console.log(errors)} */}
                <div className="flex justify-between border-b border-[#CCC] pb-2">
                  <h1 className="text-base font-semibold p-1">
                    {type === DOMESTIC_STAFF_TYPE
                      ? `${
                          occupantDetail?.flat_data?.find(
                            (item) => item.flat_id?.toString() === flatId
                          )?.flat_no
                            ? `${
                                occupantDetail?.user_detail?.prefix
                                  ? occupantDetail?.user_detail?.prefix
                                  : ""
                              } ${occupantDetail?.user_detail?.f_name} ${
                                occupantDetail?.user_detail?.m_name
                                  ? occupantDetail?.user_detail?.m_name
                                  : ""
                              } ${occupantDetail?.user_detail?.l_name} (${
                                occupantDetail?.flat_data?.find(
                                  (item) => item.flat_id?.toString() === flatId
                                )?.wing_no
                              } - ${
                                occupantDetail?.flat_data?.find(
                                  (item) => item.flat_id?.toString() === flatId
                                )?.flat_no
                              }) >> Add Staff`
                            : ""
                        }`
                      : "Add Society Staff"}
                  </h1>
                  <div className="flex gap-2">
                    <ButtonG
                      label="Cancel"
                      onClick={handleCancelClick}
                      className="h-8"
                      type="button"
                    />
                    <Button
                      label={
                        <span className="w-12 inline-block">
                          {updateLoader && buttonType === "Save" ? (
                            <CircularProgress
                              sx={{ color: "white" }}
                              size={17}
                            />
                          ) : (
                            "Save"
                          )}
                        </span>
                      }
                      className="px-5 h-8"
                      type="submit"
                      onClick={() => setButtonType("Save")}
                      disabled={updateLoader}
                    />
                    <Button
                      label={
                        <span className="w-28 inline-block">
                          {updateLoader && buttonType === "Save & Add More" ? (
                            <CircularProgress
                              sx={{ color: "white" }}
                              size={17}
                            />
                          ) : (
                            "Save & Add More"
                          )}
                        </span>
                      }
                      disabled={updateLoader}
                      className="px-5 h-8"
                      type="submit"
                      onClick={() => setButtonType("Save & Add More")}
                    />
                  </div>
                </div>
                {occupantDetailLoading && !occupantDetail ? (
                  <Loader />
                ) : (
                  <>
                    {(occupantDetail?.flat_data?.find(
                      (item) => item.flat_id?.toString() === flatId
                    )?.flat_no ||
                      type !== DOMESTIC_STAFF_TYPE) && (
                      <>
                        <div className="flex gap-4 p-2">
                          <div className="flex flex-row justify-between w-full gap-7">
                            <div className="flex flex-col w-1/2">
                              <div className="flex justify-between text-sm">
                                <span className="mt-2">Staff First Name</span>
                                <div className="flex gap-2">
                                  <DropdownFM2
                                    options={namePrefixOptions}
                                    width={"73px"}
                                    className="text-xs"
                                    placeholder={"Select"}
                                    onSelect={(selectedItem) => {
                                      setFieldValue(
                                        `prefix`,
                                        selectedItem.value
                                      );
                                    }}
                                    height="38px"
                                    name={`prefix`}
                                    {...(values.prefix === "" && {
                                      value: null,
                                    })}
                                  />
                                  <TextInputFM
                                    label=""
                                    placeholder="Enter First Name"
                                    className="w-36"
                                    name={`firstName`}
                                    maxLength={20}
                                  />
                                </div>
                              </div>
                              <TextInputFM
                                label="Last Name"
                                placeholder="Enter Last Name"
                                className="w-56"
                                name={`lastName`}
                                maxLength={20}
                              />
                              <div className="flex justify-between">
                                <span className="text-sm mt-2">
                                  Society Staff Type
                                </span>
                                <DropdownFM2
                                  options={societyStaffTypeOptions}
                                  width={"224px"}
                                  className="text-xs"
                                  placeholder={"Select Staff Type"}
                                  onSelect={(selectedItem) => {
                                    setFieldValue(
                                      `staffType`,
                                      selectedItem.value
                                    );
                                    setFieldValue(`flatData`, []);
                                  }}
                                  height="38px"
                                  name={`staffType`}
                                  value={
                                    values.staffType
                                      ? {
                                          label: values.staffType,
                                          value: values.staffType,
                                        }
                                      : null
                                  }
                                  disabled={type === DOMESTIC_STAFF_TYPE}
                                />
                              </div>
                              <div className="flex justify-between">
                                <span className="text-sm mt-2">
                                  Staff Profile
                                </span>
                                <DropdownFM2
                                  options={
                                    staffProfileList?.map((item) => ({
                                      value: item?.id,
                                      label: item?.title,
                                    })) || []
                                  }
                                  width={"224px"}
                                  className="text-xs"
                                  placeholder={"Select Staff Profile"}
                                  onSelect={(selectedItem) => {
                                    setFieldValue(
                                      `staffProfileId`,
                                      selectedItem.value
                                    );
                                  }}
                                  height="38px"
                                  name={`staffProfileId`}
                                  {...(values.staffProfileId === "" && {
                                    value: null,
                                  })}
                                />
                              </div>
                              <div className="flex flex-row justify-between">
                                <span className="text-sm mt-2">
                                  Staff ID Validity From
                                </span>
                                <div className="flex gap-4">
                                  <DatePickerComponentFM
                                    className="w-56 h-9 justify-between"
                                    onDateChange={(selectedDate) => {
                                      setFieldValue(
                                        "startValidity",
                                        selectedDate
                                      );
                                    }}
                                    name="startValidity"
                                    defaultValue={
                                      values.startValidity
                                        ? new Date(values.startValidity)
                                        : null
                                    }
                                    maxDate={new Date()}
                                  />
                                </div>
                              </div>
                              <TextInputFM
                                label="Working Hours per Day"
                                placeholder="Enter"
                                className="w-56"
                                name={`workPerDay`}
                                type="number"
                                maxLength={2}
                              />
                              <div className="flex justify-between">
                                <span className="text-sm mt-2">Country</span>
                                <DropdownFM2
                                  options={
                                    countries?.map((item) => ({
                                      label: `${item?.name?.toUpperCase()} (${
                                        item?.phonecode
                                      })`,
                                      value: item?.phonecode?.replace("+", ""),
                                      iso: item.iso,
                                      id: item?.id,
                                    })) || []
                                  }
                                  width={"224px"}
                                  className="text-xs"
                                  placeholder={"Select Country"}
                                  onSelect={(selectedItem) => {
                                    setFieldValue(
                                      `mobileCountryCode`,
                                      selectedItem.value
                                    );
                                    setFieldValue(`countryId`, selectedItem.id);
                                    setFieldValue(
                                      `countryISO`,
                                      selectedItem.iso
                                    );
                                  }}
                                  height="38px"
                                  name={`mobileCountryCode`}
                                  {...(values.countryId === "" && {
                                    value: null,
                                  })}
                                />
                              </div>
                              <TextInputFM
                                label="Staff Contact Number"
                                placeholder="Enter contact number"
                                className="w-56"
                                name="contact"
                                type="phone"
                                maxLength={17}
                              />
                              <div className="flex justify-between">
                                <span className="text-sm mt-2">Gender</span>
                                <DropdownFM2
                                  options={genderOptions}
                                  width={"224px"}
                                  className="text-xs"
                                  placeholder="Select Gender"
                                  onSelect={(selectedItem) => {
                                    setFieldValue(`gender`, selectedItem.value);
                                  }}
                                  height="38px"
                                  name={`gender`}
                                  {...(values.gender === "" && { value: null })}
                                />
                              </div>
                              <div className="flex flex-row justify-between">
                                <span className="text-sm mt-2">
                                  Staff Date of Birth
                                </span>
                                <DatePickerComponentFM
                                  className="w-56 h-9 justify-between"
                                  onDateChange={(selectedDate) => {
                                    setFieldValue(
                                      "dob",
                                      moment(selectedDate)?.format("YYYY-MM-DD")
                                    );
                                  }}
                                  name="dob"
                                  defaultValue={
                                    values.dob ? new Date(values.dob) : null
                                  }
                                  maxDate={moment()
                                    .subtract(18, "years")
                                    .toDate()}
                                />
                              </div>
                              <div className="flex justify-between">
                                <span className="text-sm mt-2">
                                  Vehicle Type
                                </span>
                                <DropdownFM2
                                  options={societyStaffVehicleTypeOptions}
                                  width={"224px"}
                                  className="text-xs"
                                  placeholder="Select Vehicle Type"
                                  onSelect={(selectedItem) => {
                                    setFieldValue(
                                      `vehicleType`,
                                      selectedItem.value
                                    );
                                  }}
                                  height="38px"
                                  name={`vehicleType`}
                                  {...(values.vehicleType === "" && {
                                    value: null,
                                  })}
                                />
                              </div>
                              <div className="flex justify-between w-full">
                                <div className="text-sm w-1/2 mt-2">
                                  Staff Finger Image
                                </div>
                                <div className="w-56 mb-1">
                                  <CaptureButtonFM
                                    showFileName={true}
                                    name={`fingerPrintImg`}
                                    accept={
                                      "image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                    }
                                    onSelect={(e) =>
                                      setFieldValue(`fingerPrintImg`, e)
                                    }
                                    fileName={values.fingerPrintImg?.name}
                                  />
                                </div>
                              </div>
                              <TextInputFM
                                label="Identity Proof Number"
                                placeholder="Enter"
                                className="w-56"
                                name="adharCardNo"
                                type="alphaNumber"
                                maxLength={12}
                              />
                              <TextInputFM
                                label="Pan Card"
                                placeholder="Enter Pan Number"
                                className="w-56"
                                name="panCardNo"
                                type="alphaNumber"
                                maxLength={10}
                              />
                              <TextInputFM
                                label="Driving Licence"
                                placeholder="Enter Licence number"
                                className="w-56"
                                name="drivingLicenceNo"
                                type="alphaNumber"
                                maxLength={10}
                              />
                              <TextInputFM
                                label="Ration Card"
                                placeholder="Enter Ration Number"
                                className="w-56"
                                name="rationNo"
                                type="alphaNumber"
                                maxLength={10}
                              />
                            </div>
                            <div className="flex flex-col w-1/2">
                              <div className="my-1.5">
                                <ProfilePicture
                                  onSelect={(e) => {
                                    setFieldValue("profilePic", e);
                                  }}
                                  onDelete={(e) => {
                                    setFieldValue("profilePic", "");
                                  }}
                                />
                                <div className="text-red-500 text-end text-xs italic h-4 pe-32">
                                  <ErrorMessage name="profilePic" />
                                </div>
                              </div>
                              <div className="flex flex-row justify-between mt-[116px]">
                                <span className="text-sm mt-2">
                                  Staff ID Validity To
                                </span>
                                <DatePickerComponentFM
                                  className="w-56 h-9 justify-between"
                                  onDateChange={(selectedDate) => {
                                    setFieldValue("endValidity", selectedDate);
                                  }}
                                  name="endValidity"
                                  {...(values.startValidity && {
                                    minDate: new Date(
                                      moment(values.startValidity).add(
                                        1,
                                        "days"
                                      )
                                    ),
                                  })}
                                  defaultValue={
                                    values.endValidity
                                      ? new Date(values.endValidity)
                                      : null
                                  }
                                  readOnly={!values.startValidity}
                                />
                              </div>

                              <TextInputFM
                                label="Staff Salary"
                                placeholder="Enter Amount"
                                className="w-56"
                                name="salary"
                                type="number"
                                maxLength={10}
                              />
                              <TextInputFM
                                label="Staff Email ID"
                                placeholder="Enter Email"
                                className="w-56"
                                name="email"
                              />
                              <div className="w-full">
                                <TextAreaFM
                                  label="Address"
                                  placeholder="Enter Address"
                                  className={"w-56 h-[87px]"}
                                  name="address"
                                  maxLength={300}
                                />
                              </div>
                              <TextInputFM
                                label="Staff Vehicle Number"
                                placeholder="Enter vehicle number"
                                className="w-56"
                                name="vehicleNumber"
                                type="alphaNumber"
                                maxLength={10}
                              />
                              <div className="flex justify-between mb-2 mt-[50px]">
                                <span className="text-sm mt-2">
                                  Upload Identity Proof
                                </span>
                                <div className="w-1/2 -mr-1">
                                  <UploadButtonFM
                                    showFileName={true}
                                    name={`adharDoc`}
                                    accept={
                                      "image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                    }
                                    onSelect={(e) =>
                                      setFieldValue(`adharDoc`, e)
                                    }
                                    fileName={values?.adharDoc?.name}
                                  />
                                </div>
                              </div>
                              <div className="flex justify-between mb-2">
                                <span className="text-sm mt-2">
                                  Upload PAN Card
                                </span>
                                <div className="w-1/2 -mr-1">
                                  <UploadButtonFM
                                    showFileName={true}
                                    name={`panDoc`}
                                    accept={
                                      "image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                    }
                                    onSelect={(e) => setFieldValue(`panDoc`, e)}
                                    fileName={values?.panDoc?.name}
                                  />
                                </div>
                              </div>
                              <div className="flex justify-between mb-2">
                                <span className="text-sm mt-2">
                                  Upload Driving Licence
                                </span>
                                <div className="w-1/2 -mr-1">
                                  <UploadButtonFM
                                    showFileName={true}
                                    name={`drivingLicenceDoc`}
                                    accept={
                                      "image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                    }
                                    onSelect={(e) =>
                                      setFieldValue(`drivingLicenceDoc`, e)
                                    }
                                    fileName={values?.drivingLicenceDoc?.name}
                                  />
                                </div>
                              </div>
                              <div className="flex justify-between mb-2">
                                <span className="text-sm mt-2">
                                  Upload Ration Card
                                </span>
                                <div className="w-1/2 -mr-1">
                                  <UploadButtonFM
                                    showFileName={true}
                                    name={`rationDoc`}
                                    accept={
                                      "image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                    }
                                    onSelect={(e) =>
                                      setFieldValue(`rationDoc`, e)
                                    }
                                    fileName={values?.rationDoc?.name}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <AddStaffBoxForm
                          type={type}
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                      </>
                    )}
                  </>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    </Layout>
  );
};

export default AddSocietyStaff;
