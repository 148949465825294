import { notify } from '../../../../utils/notification';
import apiEndpoints from '../../../api';
import axiosInstance from '../../../axiosInstance'
import {logOut} from '../../../../utils/helpers/logOut'
import { errorMsg } from '../../../../consts/responseMessages';


export async function getEmployeeRolesListReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_EMPLOYEE_ROLE_LIST_API}?society_id=${action.data.societyId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getEmployeeRolesDetailsReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_EMPLOYEE_ROLE_DETAILS_API}?emp_role_id=${action.data.employeeRoleId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function addAndUpdateEmployeeRoleReq(action) {

    const BODY= {
        "society_id": action.data.societyId,
        "emp_role_id": action.data.id || 0,
        "access": action.data?.accessSetting,
        "role_title": action.data?.role,
        "switch_to_admin": action.data?.submitToAdmin
      }

    return axiosInstance.post(apiEndpoints.ADD_AND_UPDATE_EMPLOYEE_ROLE_DETAILS_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getOccupantsRolesListReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_OCCUPANT_ROLE_LIST_API}?society_id=${action.data.societyId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getOccupantsRolesDetailsReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_OCCUPANTS_ROLE_DETAILS_API}?occupant_role_id=${action.data.occupantRoleId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function addAndUpdateOccupantRoleReq(action) {

    const BODY= {
        "society_id": action.data?.societyId,
        "occupant_role_id": action.data?.id || 0,
        "access": action.data.accessSetting,
        "role_title": action.data.role,
        "switch_to_admin": action.data.submitToAdmin
      }

    return axiosInstance.post(apiEndpoints.ADD_AND_UPDATE_OCCUPANTS_ROLE_DETAILS_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getFamilyRolesListReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_FAMILY_ROLE_LIST_API}?society_id=${action.data.societyId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getFamilyRolesDetailsReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_FAMILY_ROLE_DETAILS_API}?family_role_id=${action.data.familyRoleId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function addAndUpdateFamilyRolesReq(action) {

    const BODY= {
        "society_id": action.data.societyId,
        "family_role_id": action.data.id || 0,
        "access": action.data?.accessSetting,
        "role_title": action.data?.role,
        "switch_to_admin": action.data?.submitToAdmin
      }

    return axiosInstance.post(apiEndpoints.ADD_AND_UPDATE_FAMILY_ROLE_DETAILS_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getRoleAndRightsSettingReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_ROLE_RIGHT_SETTINGS_API}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function updateRoleAndRightsStatusReq(action) {

    const BODY= {
        "id": action.data?.id,
        "flag": action.data?.flag,
        "status": action.data?.status
      }

    return axiosInstance.put(apiEndpoints.UPDATE_ROLE_RIGHT_STATUS_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function deleteEmployeeOccupantAndFamilyRoleReq(action) {
    const BODY= {
        "ids": action.data?.ids,
        "flag": action.data?.flag
      }
    return axiosInstance.delete(apiEndpoints.DELETE_RIGHTS_AND_ROLES_API, {data: BODY})
    .then(response => {
        return response;
    }).catch((errors) => {
        console.log("errors", errors);
        if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
            logOut()
        }else {
            notify('warning', "Something went wrong");
        }
        return errors

    })
}

export async function getSocietyAccessSettingReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_SOCIETY_ACCESS_SETTINGS_API}?society_id=${action.data.societyId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function updateSocietyAccessSettingReq(action) {

    const BODY= {
        "society_id": action.data?.societyId,
        "settings": action.data?.accessSetting
      }

    return axiosInstance.put(apiEndpoints.UPDATE_SOCIETY_ACCESS_SETTINGS_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}


