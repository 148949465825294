import * as Yup from "yup";

  export const MakeBookingValidation = () => {
    return Yup.object({
      frequency: Yup.string().required('*Required'),
      dateOfBooking: Yup.string()
        .when(['frequency'], (frequency, schema) => {
            return frequency[0] === 'One Time' ?
            schema.required("*Required")
            :
            schema
        }),
    fromDate: Yup.string()
        .when(['frequency'], (frequency, schema) => {
            return frequency[0] !== 'One Time' ?
            schema.required("*Required")
            :
            schema
        }),
     toDate: Yup.string()
        .when(['frequency'], (frequency, schema) => {
            return frequency[0] !== 'One Time' ?
            schema.required("*Required")
            :
            schema
        }),
    weekDays: Yup.array().of(
        Yup.string()
            .when(['frequency'], (frequency, schema) => {
                return frequency[0] === 'Weekly' ?
                schema.min(1, "*Please select  atleast 1 day")
                :
                schema
            }),
        ),
    slotType: Yup.string().required('*Required'),
    slotId: Yup.string().required('*Required'),
    slotName: Yup.string().required('*Required'),
    fromTime: Yup.string()
        .when(['slotType'], (slotType, schema) => {
            return slotType[0] === 'hours' ?
            schema.test('not-only-zeros', '*Required', value => {
              return /^(?:[01]\d|2[0-3]):[0-5]\d$/.test(value);
            })
            :
            schema
        }),
     toTime: Yup.string()
        .when(['slotType'], (slotType, schema) => {
            return slotType[0] === 'hours' ?
            schema.test('not-only-zeros', '*Required', value => {
              return /^(?:[01]\d|2[0-3]):[0-5]\d$/.test(value);
            })
            :
            schema
        }),

        noOfBooking: Yup.string()
          .required("*Required")
          .test('not-only-zeros', '*Invalid number', value => {
            return !/^0+$/.test(value);
          }),
        userGuidance: Yup.string(),
    });
  };



  export const BookValidation = () => {
    return Yup.object({
      securityDeposit: Yup.string().required('*Required'),
      noOfBooking: Yup.string().required('*Required'),
      memberType: Yup.string().required('*Required'),
      wingId: Yup.string().required('*Required'),
      flatId: Yup.string().required('*Required'),
      memberId: Yup.string().required('*Required'),
      memberName: Yup.string(),
      purpose: Yup.string(),
      other: Yup.string(),
    });
  };
  
  export const SendMessageValidation = () => {
    return Yup.object({
      subject: Yup.string().required('*Required'),
      message: Yup.string().required('*Required'),
    });
  };