import React, { useState } from "react";
import Modal from "./Modal";
import { useNavigate } from "react-router-dom";
import { SMS_CREDIT_ORDER_HISTORY_ENDPOINT } from "../../Routing/routes";
const BuyNowModal = ({ isOpen, onCancel }) => {
  const navigate = useNavigate();


  const [showNext, setShowNext] = useState(false);
  if (showNext)
    return (
      <Modal
        isOpen={isOpen}
        title="Buy Request"
        onCancel={() => {
          onCancel();
          setShowNext(false);
        }}
        onConfirm={() => {
          navigate(SMS_CREDIT_ORDER_HISTORY_ENDPOINT)
          // onConfirm();

          setShowNext(false);
        }}
        confirmTitle="View Order History"
      >
        <p className="flex justify-center text-center text-[16px]">
          Your request for sms credit point has been posted successfully.
        </p>
        <p className=" mt-6 text-[16px] text-center">Please wait for admin approval.
        </p>
      </Modal>
    );
  return (
    <Modal
      isOpen={isOpen}
      title="Buy Request"
      onCancel={() => {
        onCancel();
        setShowNext(false);
      }}
      onConfirm={() => setShowNext(true)}
    >
      <p className="flex justify-center font-semibold text-[16px]">
        Are you sure?
      </p>
      <p className=" mt-6 text-[16px] text-center">
        Do you really want to send request for this package?
      </p>
    </Modal>
  );
};

export default BuyNowModal;
