import React from "react";

const ButtonG = ({ label, onClick, className, type, disabled=false }) => {
  return (
    <button type={type} disabled={disabled} className={`${className} bg-[#CCCCCC] text-white text-xs px-3 py-1 font-semibold rounded-lg border-[#AAAAAA] border `} onClick={onClick}>
      {label}
    </button>
  );
};

export default ButtonG;
