import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../../../Layout/Layout";
import ListHeaderBack from "../../../../../components/ListHeader/ListHeaderBack";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../../../../components/Loader/Loader";
import { getOccupantDetails } from "../../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/OccupantsInfoAction";
import { validateDate } from "../../../../../utils/helpers/universalFunctions";
import moment from "moment";


const ViewFamilyMember = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id, userId, flatId, familyMemberId } = useParams();
    const [familyMemberData, setFamilyMemberData] = useState("");
    const { loginData } = useSelector((state) => state.loginReducer);
    const { loading, occupantDetail } = useSelector(
      (state) => state.occupantInfoReducer
    );

  const handleBackClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(
      getOccupantDetails({
        subscribeId: id,
        societyId: loginData?.default_community?.community_id,
        userType: loginData?.user_type,
        userId: userId,
      })
    );

  }, []);

  useEffect(() => {
    if (occupantDetail)
      setFamilyMemberData(occupantDetail?.flat_data?.find(item => item.flat_id?.toString() === flatId)?.family_data?.find(item => item.id?.toString() === familyMemberId))
  }, [occupantDetail])


  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 mb-5">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack
            onClick={handleBackClick}
            title="View Family Member Details"
          ></ListHeaderBack>
        </div>
        {
          loading && id !== familyMemberData?.emp_id?.toString() ?
          <Loader/>
          :
          <table className="mx-2">
            <tbody className="text-sm leading-8">
                <tr>
                  <td className="text-[#AAAAAA] font-semibold w-32">Name:</td>
                  <td className="capitalize">{familyMemberData?.full_name || '-'}</td>
                </tr>
                <tr>
                  <td className="text-[#AAAAAA] font-semibold w-32">Family Type:</td>
                  <td className="capitalize">{familyMemberData?.user_type || '-'}</td>
                </tr>
                <tr>
                  <td className="text-[#AAAAAA] font-semibold w-32">Age:</td>
                  <td>{familyMemberData?.age || '-'}</td>
                </tr>
                <tr>
                  <td className="text-[#AAAAAA] font-semibold w-32">Gender:</td>
                  <td>{familyMemberData?.gender || '-'}</td>
                </tr>
                <tr>
                  <td className="text-[#AAAAAA] font-semibold w-32">Relationship:</td>
                  <td>{familyMemberData?.relationship || '-'}</td>
                </tr>
                <tr>
                  <td className="text-[#AAAAAA] font-semibold w-32">Contact No:</td>
                  <td>{familyMemberData?.contact_no ? `${familyMemberData?.mobile_country_code ?`+${familyMemberData?.mobile_country_code?.replace('+', '')}-` : ''}${familyMemberData?.contact_no}` : '-'}</td>
                </tr>
                <tr>
                  <td className="text-[#AAAAAA] font-semibold w-32">Profession:</td>
                  <td>{familyMemberData?.email || '-'}</td>
                </tr>
                <tr>
                  <td className="text-[#AAAAAA] font-semibold w-32">DOB:</td>
                  <td>{ validateDate(familyMemberData?.dob) ? moment(familyMemberData?.dob)?.format('DD-MM-YYYY') : '-'}</td>
                </tr>
                <tr>
                  <td className="text-[#AAAAAA] font-semibold w-32">Anniversary Date:</td>
                  <td>{validateDate(familyMemberData?.anniversary_date) ? moment(familyMemberData?.anniversary_date)?.format('DD-MM-YYYY') : '-'}</td>
                </tr>
                <tr>
                  <td className="text-[#AAAAAA] font-semibold w-32">Blood Group:</td>
                  <td>{familyMemberData?.blood_group || '-'}</td>
                </tr>
                <tr>
                  <td className="text-[#AAAAAA] font-semibold w-32">Other Details:</td>
                  <td>{familyMemberData?.other_details || '-'}</td>
                </tr>
            </tbody>
          </table>
        }
      </div>
    </Layout>
  );
};

export default ViewFamilyMember;
