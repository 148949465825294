import React, { useEffect } from "react";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import { financialYears2 } from "../../../../consts/DropDownOptions";
import Search from "../../../../components/SearchBox/Search";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  downloadCSVJournals,
  downloadPDFJournals,
  getBillTypeList,
  getJournal,
} from "../../../../redux/actions/ReportActions/OldFinancialReportsActions";
import moment from "moment";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ACCOUNT_REPORTS_ENDPOINT } from "../../../../Routing/routes";
import JournalTable from "./JournalTable";
import ButtonIco from "../../../../components/Button/ButtonPrint";
import { DownloadExt } from "../../../../assets";
import RangeDatePicker from "../../../../components/DatePicker/RangeDatePicker";
import ButtonG from "../../../../components/Button/ButtonG";
import { downloadExcel } from "../../../../utils/helpers/downloadMedia";

export const headCells = [
  { id: "number", label: "Number", width: "90px" },
  { id: "date", label: "Date", width: "90px" },
  { id: "code", label: "Code", width: "90px" },
  { id: "headOfAccount", label: "Head Of Account", width: "90px" },
  { id: "narration", label: "Narration", width: "90px" },
  { id: "refNo", label: "Ref. No", width: "90px" },
  { id: "debit", label: "Debit", width: "90px" },
  { id: "credit", label: "Credit", width: "90px" },
];

const Journal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, billTypeList, journals } = useSelector(
    (state) => state.oldFinancialReportsReducers
  );

  const handleSearch = (text) => {
    if (text && text !== searchParams.get("search")) {
      searchParams.delete("page");
      searchParams.delete("search");
      navigate({
        pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["search", text?.trim()],
        ])}`,
      });
    } else if (text == "") {
      searchParams.delete("search");
      navigate({
        pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries()])}`,
      });
    }
  };

  const handleSelectYear = (selectedItem) => {
    searchParams.delete("page");

    if (selectedItem.value?.toString() !== searchParams.get("year")) {
      searchParams.delete("year");
      navigate({
        pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["year", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleStartDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !==
      searchParams.get("startDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("startDate");
      navigate({
        pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["startDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleEndDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !== searchParams.get("endDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("endDate");
      navigate({
        pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["endDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handelSelectBillType = (selectedItem) => {
    searchParams.delete("page");

    if (
      selectedItem.value?.toString() !== searchParams.get("daybook_short_form")
    ) {
      searchParams.delete("daybook_short_form");
      navigate({
        pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["daybook_short_form", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleClear = () => {
    navigate({
      pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
      search: `?${createSearchParams([["tab", 3]])}`,
    });
  };

  const handleDownloadCSV = () => {
    const onSucessCallback = (data) => {
      downloadExcel({ data, fileName: `Journals.xlsx` });
    };
    const data = {
      society_id: loginData?.default_community?.zipgrid_socsrno,
      // societyId: loginData?.default_community?.community_id,
      fin_year: searchParams.get("year"),
      daybook_short_form: searchParams.get("daybook_short_form"),
      from_date: searchParams.get("startDate"),
      to_date: searchParams.get("endDate"),
      page: searchParams.get("page") || 1,
      limit: searchParams.get("pageLimit") || 10,
      onSucessCallback,
    };
    dispatch(downloadCSVJournals(data));
  };

  const handleDownloadPDF = () => {
    const onSucessCallback = (data) => {
      downloadExcel({ data, fileName: `Journals.pdf` });
    };
    const data = {
      society_id: "S387",
      // societyId: loginData?.default_community?.community_id,
      fin_year: searchParams.get("year"),
      daybook_short_form: searchParams.get("daybook_short_form"),
      from_date: searchParams.get("startDate"),
      to_date: searchParams.get("endDate"),
      page: searchParams.get("page") || 1,
      limit: searchParams.get("pageLimit") || 10,
      onSucessCallback,
    };
    dispatch(downloadPDFJournals(data));
  };

  useEffect(() => {
    dispatch(
      getBillTypeList({
        society_id: loginData?.default_community?.zipgrid_socsrno,
        daybook_filter: "journals",
      })
    );

    dispatch(
      getJournal({
        society_id: loginData?.default_community?.zipgrid_socsrno,
        daybook_short_form: searchParams.get("daybook_short_form"),
        // voucher_type: searchParams.get("voucher_type"),
        from_date: searchParams.get("startDate"),
        to_date: searchParams.get("endDate"),
        fin_year: searchParams.get("year"),
        page: searchParams.get("page") || 1,
        limit: searchParams.get("pageLimit") || 10,
      })
    );
  }, [searchParams, dispatch]);

  return (
    <div className="">
      <div>
        <ListHeader leftContent="Journals">
          <Search
            height="8"
            onclick={handleSearch}
            value={searchParams.get("search")}
          />
          <span className="border-r border-[#CCC]"></span>
          {/* <ButtonIco
            icon={DownloadExt}
            children="Download PDF"
            className="h-8"
            type="button"
            onClick={handleDownloadPDF}
          /> */}
          <ButtonIco
            icon={DownloadExt}
            children="Download Excel"
            className="h-8"
            type="button"
            onClick={handleDownloadCSV}
          />
        </ListHeader>
      </div>
      <div className="flex gap-3 pb-2">
        <Dropdown2
          options={financialYears2}
          placeholder="Choose Financial Year"
          className=""
          width="172px"
          height="32px"
          onSelect={(selectedItem) => {
            const valueParts = selectedItem.value.split("-");
            const selectedValue = `${valueParts[0]}${valueParts[1].slice(2)}`;
            handleSelectYear({ ...selectedItem, value: selectedValue });
          }}
          value={
            searchParams.get("year") && {
              value: searchParams.get("year"),
              label: financialYears2?.find((item) => {
                const valueParts = item.value.split("-");
                const selectedValue = `${valueParts[0]}${valueParts[1].slice(
                  2
                )}`;
                return selectedValue === searchParams.get("year");
              })?.label,
            }
          }
        />
        <RangeDatePicker
          className="w-48 h-8"
          defaultStartValue={
            searchParams.get("startDate")
              ? new Date(searchParams.get("startDate"))
              : ""
          }
          defaultEndValue={
            searchParams.get("endDate")
              ? new Date(searchParams.get("endDate"))
              : ""
          }
          onStartDateChange={handleStartDate}
          onEndDateChange={handleEndDate}
        />

        <Dropdown2
          options={
            billTypeList?.map((item) => ({
              value: item?.daybookshfm,
              label: item?.daybookname,
            })) || []
          }
          placeholder="Select JBook"
          className=""
          width="182px"
          height="32px"
          onSelect={(selectedItem) => {
            handelSelectBillType(selectedItem);
          }}
          value={
            searchParams.get("daybook_short_form") && {
              value: searchParams.get("daybook_short_form"),
              label: billTypeList?.find(
                (item) =>
                  item.daybookshfm === searchParams.get("daybook_short_form")
              )?.daybookname,
            }
          }
        />
        <span className="border-r border-[#CCC]"></span>
        <ButtonG type="button" label="Clear" onClick={handleClear} />
      </div>
      <div className="ml-1">
        <JournalTable
          loading={loading}
          data={
            journals?.list?.map((item) => ({
              number: item?.vouchernumber?.value,
              date: item?.voucherdate
                ? moment(new Date(item?.voucherdate))?.format("DD-MM-YYYY")
                : "-",
              // code: item?. ,
              headOfAccount: item?.accountname,
              narration: item?.narration,
              code: item?.code,
              accountHead: item?.account_head,
              refNo: item?.chequenumber,
              debit: item?.amountdebit,
              credit: item?.amountcredit,
            })) || []
          }
          columns={headCells}
          checkbox={false}
          pagination={journals?.list?.length}
          totalCount={journals?.total_count}
          totalPages={journals?.total_pages}
          start={
            (journals?.current_page - 1) *
              (parseInt(journals?.page_limit) || 10) +
            1
          }
          end={
            (journals?.current_page - 1) *
              (parseInt(journals?.page_limit) || 10) +
            journals?.list?.length
          }
          currentPage={parseInt(journals?.current_page)}
          rowsPerPage={searchParams.get("pageLimit") || 10}
        ></JournalTable>
      </div>
      {!journals?.list?.length && !loading && (
        <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
          No Records
        </div>
      )}
    </div>
  );
};

export default Journal;
