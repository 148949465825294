import { ErrorMessage } from "formik";
import React, { useState } from "react";

const TimePicker = ({ width, height, name, onSelect, value }) => {
  const [selectedTime, setSelectedTime] = useState("jyj");

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
    onSelect(event.target.value);
  };

  return (
    <div className="">
      {/* <label htmlFor="timePicker" className="block text-sm font-medium text-gray-700">
        Select a time:
      </label> */}
      <div className="relative rounded-md">
        <input
          type="time"
          id="timePicker"
          name="timePicker"
          value={value}
          onChange={handleTimeChange}
          className={`w-${width || 56} h-${height || 9} bg-[#F9FAFC] appearance-none block px-3 py-2 border border-gray-300 rounded-md placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-650 focus:border-red-650 focus:z-10 sm:text-sm`}
        />
      </div>
      <div className={`text-red-500 text-xs italic h-4 text-wrap break-words`}>
        <ErrorMessage name={name} />
      </div>
      {/* {selectedTime && (
        <p className="mt-2 text-sm text-gray-500">Selected time: {selectedTime}</p>
      )} */}
    </div>
  );
};

export const TimePickerNotFM = ({ width, name, onSelect, value }) => {
  const [selectedTime, setSelectedTime] = useState("jyj");

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
    onSelect(event.target.value);
  };

  return (
    <div className="">
      {/* <label htmlFor="timePicker" className="block text-sm font-medium text-gray-700">
        Select a time:
      </label> */}
      <div className="relative rounded-md shadow-sm">
        <input
          type="time"
          id="timePicker"
          name="timePicker"
          value={value}
          onChange={handleTimeChange}
          className="w-32 h-9 bg-[#F9FAFC] appearance-none block px-3 py-2 border border-gray-300 rounded-md placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-650 focus:border-red-650 focus:z-10 sm:text-sm"
        />
      </div>
      {/* {selectedTime && (
        <p className="mt-2 text-sm text-gray-500">Selected time: {selectedTime}</p>
      )} */}
    </div>
  );
};

export default TimePicker;
