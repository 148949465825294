import React from "react";
import Layout from "../../pages/Layout/Layout";
import { faDownload, faPrint } from "@fortawesome/free-solid-svg-icons";
import SocietyTable from "./SocietyTable";
import { useState } from "react";
import { SocietyVendorsData } from "./ContactData";
import {
  ADD_RESIDENT_VENDOR_ENDPOINT,
  MANAGE_CATEGORY_ENDPOINT,
} from "../../Routing/routes";
import { useNavigate } from "react-router-dom";
import Dropdown from "../Dropdown/Dropdown";
import Search from "../SearchBox/Search";
import Button from "../Button/Button";
import ItemsPerPage from "../ItemsPerPage/ItemsPerPage";
import Pagination from "../Pagination/Pagination";
import ButtonIco from "../Button/ButtonPrint";
import ListHeader from "../ListHeader/ListHeader";

const ResidentVendors = () => {
  const [selectedNumber, setSelectedNumber] = useState("");
  const [currentPage, setCurrentPage] = useState(0);

  const navigate = useNavigate();

  const handleNumberClick = (number) => {
    setSelectedNumber(number);
  };

  const itemsPerPage = parseInt(selectedNumber) || 10;
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = SocietyVendorsData.slice(startIndex, endIndex);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const totalPages = Math.ceil(SocietyVendorsData.length / itemsPerPage);
  const startEntry = currentPage * itemsPerPage + 1;
  const endEntry = Math.min(
    (currentPage + 1) * itemsPerPage,
    SocietyVendorsData.length
  );

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <ListHeader leftContent="Resident Vendors">
          <div className="flex gap-2 items-center">
            <div className="border-r border-[#CCCCCC] h-full "></div>
            <Button
              label="Add Resident Vendor"
              onClick={handleNavigation(ADD_RESIDENT_VENDOR_ENDPOINT)}
              className="font-semibold h-8"
            />
            <Button
              label="Manage Category"
              onClick={handleNavigation(MANAGE_CATEGORY_ENDPOINT)}
              className="font-semibold h-8"
            />
          </div>
        </ListHeader>
        <div className="border-b border-[#CCCCCC]"></div>
        <div className="p-2 flex flex-row justify-between gap-2 -mb-2">
          <Search />
          <div className="flex justify-between items-center mb-2">
            <div className="flex items-center gap-2">
              <p>Filter</p>
              <Dropdown
                options={[
                  "Carpenters",
                  "Electrician",
                  "Ac Repair",
                  "Food & Snacks Supplier",
                ]}
                width="48"
                height="8"
                placeholder="Enter Category"
                className="text-xs"
                onSelect={(selectedItem) => {
                  // Handle the selected item here
                }}
              />
            </div>

            <div className="border-r border-[#CCC] mx-2 h-full"></div>
            <div className="flex gap-2">
              <ButtonIco
                icon={faPrint}
                // onClick={handlePrint}
                children="Print"
              />
              <ButtonIco icon={faDownload} children="Download Excel" />
            </div>
          </div>
        </div>

        <SocietyTable data={paginatedData} />

        <div className="border-b-2"></div>
        <div className="flex flex-row justify-between">
          <ItemsPerPage
            selectedNumber={selectedNumber}
            onSelect={handleNumberClick}
          />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            handlePageClick={handlePageClick}
            startEntry={startEntry}
            endEntry={endEntry}
            dataLength={SocietyVendorsData.length}
          />
        </div>
      </div>
    </Layout>
  );
};

export default ResidentVendors;
