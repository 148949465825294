import React, { useEffect } from "react";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import {
  ARCReportsYearOptions,
  monthOptions,
} from "../../../../consts/DropDownOptions";
import Search from "../../../../components/SearchBox/Search";
import ButtonG from "../../../../components/Button/ButtonG";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getBillTypeList,
  getMembersBillReports,
} from "../../../../redux/actions/ReportActions/OldFinancialReportsActions";
import MembersBillTable from "./MembersBillTable";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { BILL_REPORTS_ENDPOINT } from "../../../../Routing/routes";

export const headCells = [
  { id: "regn", label: "Reg no.", width: "90px" },
  { id: "flat", label: "Flat No.", width: "100px" },
  { id: "member", label: "Member Name", width: "100px" },
  { id: "fileName", label: "View/Download", width: "90px" },
];

const MembersBill = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  // const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, billTypeList, memberBillReports } = useSelector(
    (state) => state.oldFinancialReportsReducers
  );

  const handleSearch = (text) => {
    if (text && text !== searchParams.get("search")) {
      searchParams.delete("page");
      searchParams.delete("search");
      navigate({
        pathname: `${BILL_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["search", text?.trim()],
        ])}`,
      });
    }
  };

  const handelSelectBillType = (selectedItem) => {
    searchParams.delete("page");

    if (selectedItem.value?.toString() !== searchParams.get("billType")) {
      searchParams.delete("billType");
      navigate({
        pathname: `${BILL_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["billType", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleSelectMonth = (selectedItem) => {
    searchParams.delete("page");

    if (selectedItem.value?.toString() !== searchParams.get("month")) {
      searchParams.delete("month");
      navigate({
        pathname: `${BILL_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["month", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleSelectYear = (selectedItem) => {
    searchParams.delete("page");

    if (selectedItem.value?.toString() !== searchParams.get("year")) {
      searchParams.delete("year");
      navigate({
        pathname: `${BILL_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["year", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleClear = () => {
    navigate(BILL_REPORTS_ENDPOINT);
  };

  useEffect(() => {
    dispatch(
      getBillTypeList({ society_id: "S387", daybook_filter: "allType" })
    );

    dispatch(
      getMembersBillReports({
        society_registration: "MYAS387",
        page: searchParams.get("page") || 1,
        limit: searchParams.get("pageLimit") || 10,
        billType: searchParams.get("billType"),
        month: searchParams.get("month"),
        year: searchParams.get("year"),
      })
    );
  }, [searchParams]);

  return (
    <div className="mt-1">
      <div className="">
        <ListHeader leftContent="Members Bill">
          <Search
            height="8"
            onclick={handleSearch}
            value={searchParams.get("search")}
          />
          <span className="border-r border-[#CCC]"></span>
          <Dropdown2
            options={
              billTypeList?.map((item) => ({
                value: item?.adbregnno,
                label: item?.daybookname,
              })) || []
            }
            placeholder="Select Bill Type"
            className=""
            width="182px"
            height="32px"
            onSelect={(selectedItem) => {
              handelSelectBillType(selectedItem);
            }}
            value={
              searchParams.get("billType") && {
                value: searchParams.get("billType"),
                label: billTypeList?.find(
                  (item) => item.adbregnno === searchParams.get("billType")
                )?.daybookname,
              }
            }
          />
          <Dropdown2
            options={monthOptions}
            placeholder="Month"
            className=""
            width="120px"
            height="32px"
            onSelect={(selectedItem) => {
              handleSelectMonth(selectedItem);
            }}
            value={
              searchParams.get("month") && {
                value: searchParams.get("month"),
                label: monthOptions?.find(
                  (item) => item.value === searchParams.get("month")
                )?.label,
              }
            }
          />

          <Dropdown2
            options={ARCReportsYearOptions}
            placeholder="Year"
            className=""
            width="120px"
            height="32px"
            onSelect={(selectedItem) => {
              handleSelectYear(selectedItem);
            }}
            value={
              searchParams.get("year") && {
                value: searchParams.get("year"),
                label: ARCReportsYearOptions?.find(
                  (item) => item.value.toString() === searchParams.get("year")
                )?.label,
              }
            }
          />
          <span className="border-r border-[#CCC]"></span>
          <ButtonG label="Clear" className="h-8" onClick={handleClear} />
        </ListHeader>
      </div>
      <div className="ml-1">
        <MembersBillTable
          loading={loading}
          data={memberBillReports?.map((item) => ({
            regn: item?.blobmemregnid,
            flat: item?.blobmemflat,
            member: item?.blobmemname ,
            fileName: item?.blobfilename,
            downloadFile: item?.blobmembillsignedurl,
            pdfKey: item?.blobmembillpdfkey,
          })) || []}
          columns={headCells}
          checkbox={false}
          pagination={true}
        ></MembersBillTable>
      </div>
      {!memberBillReports?.length && (
        <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
          No Records
        </div>
      )}
    </div>
  );
};

export default MembersBill;
