import * as type from '../../actions/index.types'

export function logoutReducer(state = {
  loading: false,
  error:null
}, action) {

switch (action.type) {
  case type.LOGOUT:
    return {
      ...state,
      loading: true,
    }
  case type.LOGOUT_SUCCESS:
    return {
      ...state,
      error: null,
      loading: false,
    }

  case type.LOGOUT_FAILURE:
    return {
      ...state,
      error: action?.message || 'Somthing went wrong.',
      loading: false,
    }
  default:
    return state
}
}