import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ButtonG from "../../../../components/Button/ButtonG";
import Button from "../../../../components/Button/Button";
import { TextInputFM } from "../../../../components/InputFields/TextInput";
import Layout from "../../../Layout/Layout";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import { DeleteIcon } from "../../../../assets";
import { UploadButtonFM } from "../../../../components/Button/UploadButton";
import { FieldArray, Form, Formik } from "formik";
import { FlatDocumentValidation } from "../../../../validationSchema/setupRecordsSchema/occupants&TenantsSchema";
import { useDispatch } from "react-redux";
import { getFlatDocuments, updateFlatDocuments } from "../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/OccupantsInfoAction";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader/Loader";
import { CircularProgress } from "@mui/material";

const UploadFlatDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {flatId} = useParams()
  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, updateLoader, flatDocumentsDetails } = useSelector((state) => state.occupantInfoReducer);

  const handleCancelClick = () => {
    navigate(-1);
  };

  const handleSaveClick = (values, {resetForm}) => {
    dispatch(updateFlatDocuments({
      societyId: loginData?.default_community?.community_id,
      flatId,
      ...values,
      onSuccessCallback: ()=>{
        navigate(-1)
      }
    }))
  };

  const handleDelete=({arrayHelpers, values, index})=>{
    if(!values.documents[index].id){
      arrayHelpers.remove(index)
    }else{
      values.documents[index].status='D'
      values.documents[index].doc=values.documents[index].filePath
      dispatch(updateFlatDocuments({
        societyId: loginData?.default_community?.community_id,
        flatId,
        ...values,
        onSuccessCallback: ()=>{
          arrayHelpers.remove(index)
        }
      }))
    }
  }


  const handleAddMoreClick = (arrayHelpers) => {
    arrayHelpers.push({
      id: 0,
      title: '',
      doc: '',
      fileURL: '',
      status: 'A'
    });
  };

  useEffect(()=>{
    dispatch(getFlatDocuments({
      societyId: loginData?.default_community?.community_id,
      flatId,
    }))
    
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg p-2">
        <Formik
          initialValues={{
            flatDocId: flatDocumentsDetails?.flat_doc_id ||  0,
            saleDeed: flatDocumentsDetails?.sale_deed || '',
            noc: flatDocumentsDetails?.noc || '',
            rentAgreement: flatDocumentsDetails?.rent_agreement || '',
            documents: flatDocumentsDetails?.other_docs?.map((item)=>({
              id: item?.other_flat_doc_id || 0,
              title: item?.title || '',
              doc: item?.file || '',
              fileURL: item?.file_url || '',
              filePath: item.file || '',
              status: item?.status || ''
            }))  || []
          }}
          enableReinitialize
          validateOnChange={true}
          onSubmit={handleSaveClick}
          validationSchema={FlatDocumentValidation}
        >
          {({values, setFieldValue, setFieldTouched}) => {
            return (
              <Form>
                <div className="border-b border-[#CCC]">
                  <ListHeader leftContent="Upload Flat Documents">
                    <ButtonG
                    type='button'
                      label="Cancel"
                      onClick={handleCancelClick}
                      className="h-8 w-16"
                    />
                    <Button
                      label={
                        <span className="w-12 inline-block">
                          {updateLoader ? (
                            <CircularProgress
                              sx={{ color: "white" }}
                              size={17}
                            />
                          ) : (
                            "Save"
                          )}
                        </span>
                      }
                      className="px-3 h-8"
                      type="submit"
                    />
                  </ListHeader>
                </div>
                {
                  loading ?
                  <Loader/>
                  :
                  <>
                    <div className="border rounded-lg mt-2">
                      <div className="p-2">
                        <div className="flex justify-between mt-2">
                          <span className="text-sm mt-3 w-1/2">
                            Sale Deed
                          </span>
                          <div className="w-1/2">
                            <div className="h-4 -mt-4 mb-2">{flatDocumentsDetails?.sale_deed_url ? <Link to={flatDocumentsDetails?.sale_deed_url} className="underline text-blue-500 text-xs" target="_blank">Click here to View</Link> : '-'}</div>
                            <UploadButtonFM 
                              showFileName={true} 
                              name={`saleDeed`}
                              accept={"image/jpeg,image/gif,image/png,application/pdf,image/x-eps"}
                              onSelect={(e)=>{setFieldValue(`saleDeed`, e)}} 
                              fileName={values.saleDeed?.name}
                            />
                          </div>
                        </div>
                        <div className="flex justify-between mt-2">
                          <span className="text-sm mt-3 w-1/2">
                            Society NOC
                          </span>
                          <div className="w-1/2">
                            <div className="h-4 -mt-4 mb-2">{flatDocumentsDetails?.noc_url ? <Link to={flatDocumentsDetails?.noc_url} className="underline text-blue-500 text-xs" target="_blank">Click here to View</Link> : '-'}</div>
                            <UploadButtonFM 
                              showFileName={true} 
                              name={`noc`}
                              accept={"image/jpeg,image/gif,image/png,application/pdf,image/x-eps"}
                              onSelect={(e)=>{setFieldValue(`noc`, e)}} 
                              fileName={values.noc?.name}
                            />
                          </div>
                        </div>
                        <div className="flex justify-between mt-2">
                          <span className="text-sm mt-3 w-1/2">
                            Rent Agreement Upload
                          </span>
                          <div className="w-1/2">
                            <div className="h-4 -mt-4 mb-2">{flatDocumentsDetails?.rent_agreement_doc_url ? <Link to={flatDocumentsDetails?.rent_agreement_doc_url} className="underline text-blue-500 text-xs" target="_blank">Click here to View</Link> : '-'}</div>
                            <UploadButtonFM _url
                              showFileName={true} 
                              name={`rentAgreement`}
                              accept={"image/jpeg,image/gif,image/png,application/pdf,image/x-eps"}
                              onSelect={(e)=>{setFieldValue(`rentAgreement`, e)}} 
                              fileName={values.rentAgreement?.name}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex-wrap flex gap-2 w-full">
                    <FieldArray
                        name="documents"
                        render={(arrayHelpers) => (
                          <>
                            {values.documents &&
                              values.documents.length > 0 &&
                              values.documents.map((item, index) => (
                              <div className="border rounded-lg mt-4 w-full" key={item.id}>
                                <div className="flex justify-between items-center bg-[#FDF2F3] border-b border-[#CCC] py-2 px-2">
                                  <div className="font-semibold">Document No.: {index + 1}</div>
                                  <DeleteIcon className="fill-red-650 w-[14px] h-[14px] cursor-pointer" onClick={()=> handleDelete({arrayHelpers, values, index})} />
                                </div>
                                <div className="flex gap-3 p-2">
                                  <div className="w-full gap-2 mt-3">
                                    <div className="flex mt-2">
                                      <span className="text-sm mt-2 w-1/2">
                                        Title
                                      </span>
                                      <TextInputFM
                                        placeholder="Enter Name"
                                        className="w-56"
                                        name={`documents.${index}.title`}
                                      />
                                    </div>
                                    <div className="flex justify-between mt-2">
                                      <span className="text-sm mt-2 w-1/2">
                                        Document
                                      </span>
                                      <div className="w-1/2">
                                        <div className="h-4 -mt-4 mb-2">{values?.documents?.[index]?.fileURL ? <Link to={values?.documents?.[index]?.fileURL} className="underline text-blue-500 text-xs" target="_blank">Click here to View</Link> : '-'}</div>
                                        <UploadButtonFM 
                                          showFileName={true} 
                                          name={`documents.${index}.doc`}
                                          accept={"image/jpeg,image/gif,image/png,application/pdf,image/x-eps"}
                                          onSelect={(e)=>{setFieldValue(`documents.${index}.doc`, e);}} 
                                          fileName={values.documents[index].doc?.name}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                            }
                          <div className="flex w-[25%] mt-4">
                            <button
                              type="button"
                              label="Add More Documents"
                              onClick={()=>{handleAddMoreClick(arrayHelpers)}}
                              className="text-red-650 font-semibold border-2 border-dashed border-red-650 w-full h-10 p-2 bg-[#FDF6F6] flex justify-center rounded-md items-center"
                            >
                              Add More Documents
                            </button>
                          </div>
                          </>
                        )}
                        ></FieldArray>
                    </div>
                  </>
                }
              </Form>)
          }
        }
        </Formik>
      </div>
    </Layout>
  );
};

export default UploadFlatDetails;
