import React from "react";
import Button from "../Button/Button";
import ButtonG from "../Button/ButtonG";
import { TextInput } from "../InputFields/TextInput";
import UploadButton from "../Button/UploadButton";

const AddAlbumModal = ({ isOpen, onClose, tenantName, onDelete }) => {
  return (
    <div
      className={`fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-60 z-20 ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="bg-white rounded-lg w-[420px] h-[310px]">
        <div className="flex p-2 gap-8 text-center justify-center items-center -mb-2">
          <h2 className="p-2 text-lg font-semibold mb-2">Add Albums</h2>
          {/* <button
            onClick={onClose}
            className="bg-[#EAEAEA] rounded-full p-2 items-center mb-2 w-9 h-9 "
          >
            <CloseIcon className="fill-current text-gray-600 hover:text-gray-800 ml-1 w-3 h-3 cursor-pointer" />
          </button> */}
        </div>
        <div className="border-b border-[#CCCCCC]"></div>
        <div className="p-2 mt-3">
          <TextInput
            label="Album Name"
            placeholder="Enter Album Name"
            className="w-48"
          />
          <TextInput
            label="Album Location"
            placeholder="Enter Album Location"
            className="w-48"
          />
          <div className="flex justify-between">
            <span className="text-sm mt-2">Upload Image</span>
            <div className="w-3/4 flex justify-end">
              <UploadButton
                showFileName={true}
                // name={`tenantDetails.${entryIndex}.panCard`}
                // accept={"image/jpeg,image/gif,image/png,application/pdf,image/x-eps"}
                // onSelect={(e)=>setFieldValue(`tenantDetails.${entryIndex}.panCard`, e)}
                // fileName={values.tenantDetails[entryIndex].panCard?.name}
              />
            </div>
          </div>

          <div className="border-b mt-4"></div>

          <div className="flex gap-4 justify-center mt-4">
            <ButtonG label="Cancel" onClick={onClose} />
            <Button
              label="Send"
              className="h-9 px-5 font-semibold cursor-not-allowed opacity-50"
              disabled={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAlbumModal;
