export default function convertTime(inputTime) {
    var timeComponents = inputTime.split(':');
    var hours = parseInt(timeComponents[0]);
    var minutes = parseInt(timeComponents[1]);
    var ampm = hours >= 12 ? 'PM' : 'AM';

    if (hours >= 12) {
      hours = hours - 12;
    }
  
    hours = hours === 0 ? 12 : hours;
  
    // Format the result
    var formattedTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + ampm;
  
    return formattedTime;
  }