import React, { useEffect } from "react";
import { DropdownFM2 } from "../../../../../components/Dropdown/dropdown2";
import { TextInputFM } from "../../../../../components/InputFields/TextInput";
import { useDispatch } from "react-redux";
import { getRoleList, getRoleWiseList } from "../../../../../redux/actions/ClubActions/AddFacilityAction";
import { useSelector } from "react-redux";
import { MultiSelectFM } from "../../../../../components/MultiSelect/MultiSelect";

const MemberBeforeDueDate = ({values, setFieldValue}) => {
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {employeeRoleList, managingCommitteeRoleList, employeeList, managingCommitteeList} = useSelector(state=>state.addFacilityReducer)
  const {memberDueReminderData} = useSelector(state=>state.dueReminderReducer)

  const getListAccordingToRole = (key, id)=>{
    let data={
      roleType: key,
      roleTypeId: id,
      societyId: loginData?.default_community?.community_id,
    }
    dispatch(getRoleWiseList(data))
  }


  useEffect(()=>{
    let data={
      societyId: loginData?.default_community?.community_id,
    }
    dispatch(getRoleList({...data, roleType:'Employees'}))
    dispatch(getRoleList({...data, roleType:'Managing Staff'}))
  }, [])

  useEffect(()=>{
    let data={
      societyId: loginData?.default_community?.community_id,
    }
    
    if(memberDueReminderData){
      if(memberDueReminderData?.employee_type){
        dispatch(getRoleWiseList({...data, roleType:'Employees',roleTypeId: memberDueReminderData?.employee_type}))
      }
      if(memberDueReminderData?.managing_committees){
        dispatch(getRoleWiseList({...data, roleType:'Managing Staff',roleTypeId: memberDueReminderData?.managing_committees}))
      }
    }

  }, [memberDueReminderData])

  return (
    <div>
      <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg mt-3">
        <div className=" border-b border-[#CCC] pb-2 bg-[#FDF2F3]">
          <h1 className="text-base items-center p-2 -mb-2 ml-1">
            Before Due Date
          </h1>
        </div>
        <div className=" p-2">
          <div className="flex flex-col mt-2">
            <div className="w-1/2">
              <TextInputFM
                name={`noOfDaysBeforeDue`}
                type='number'
                className='w-60'
                label='No. Of Days Prior'
              />
              <div className="flex justify-between z-10">
                <span className="text-sm mt-2">Employee Type</span>
                <DropdownFM2
                    options={employeeRoleList?.map((item)=>({
                      label: item.title,
                      value: item?.id
                    }))|| []}
                    placeholder="Select Employee Type"
                    className=""
                    width="242px"
                    height="36px"
                    onSelect={(selectedItem) => {
                      setFieldValue('employeeRoleId', selectedItem.value)
                      setFieldValue('employeeId', [])
                      getListAccordingToRole('Employees', selectedItem.value)
                    }}
                    value={
                      values.employeeRoleId ? 
                      {
                        label: employeeRoleList?.find(item=> item.id === parseInt(values.employeeRoleId))?.title,
                        value: parseInt(values.employeeRoleId)
                      }
                      :null
                    }
                    name={"employeeRoleId"}
                  />
              </div>
              <div className="flex justify-between z-10">
                <span className="text-sm mt-2">Employee</span>
                <MultiSelectFM
                  name='employeeId'
                  options={
                    employeeList?.map((item) => ({
                      label: item?.full_name,
                      value: item.bridge_table_id,
                    })) || []
                  }
                  width="242px"
                  height={"fit-content"}
                  placeholder="Select Employee"
                  className="text-xs"
                  onSelect={(selectedItem) => {
                    setFieldValue(
                      "employeeId",
                      selectedItem.map((item) => item.value)
                    );
                  }}
                  isMulti={true}
                  value={values?.employeeId?.map((item) => ({
                    label: employeeList?.find(
                      (person) => person.bridge_table_id === parseInt(item)
                    )?.full_name,
                    value: parseInt(item),
                  }))}
                />
              </div>
              <div className="flex justify-between z-10">
                <span className="text-sm mt-2">Managing Committees Type</span>
                <DropdownFM2
                  options={managingCommitteeRoleList?.map((item)=>({
                    label: item.title,
                    value: item?.id
                  }))|| []}
                  placeholder="Select managing Committee"
                  className=""
                  width="242px"
                  height="36px"
                  onSelect={(selectedItem) => {
                    setFieldValue('managingCommitteId', selectedItem.value)
                    setFieldValue('managingCommitteMemberId', [])
                    getListAccordingToRole('Managing Staff', selectedItem.value)
                  }}
                  value={
                    values.managingCommitteId ? 
                    {
                      label: managingCommitteeRoleList?.find(item=> item.id === parseInt(values.managingCommitteId))?.title,
                      value: parseInt(values.managingCommitteId)
                    }
                    :null
                  }
                  name={"managingCommitteId"}
                />
              </div>
              <div className="flex justify-between z-10">
                <span className="text-sm mt-2">Managing Committee Member</span>
                <MultiSelectFM
                  name='managingCommitteMemberId'
                  options={
                    managingCommitteeList?.map((item) => ({
                      label: item?.full_name,
                      value: item.subscriber_id,
                    })) || []
                  }
                  width="242px"
                  height={"fit-content"}
                  placeholder="Search Member"
                  className="text-xs"
                  onSelect={(selectedItem) => {
                    setFieldValue(
                      "managingCommitteMemberId",
                      selectedItem.map((item) => item.value)
                    );
                  }}
                  isMulti={true}
                  value={values?.managingCommitteMemberId?.map((item) => ({
                    label: managingCommitteeList?.find(
                      (person) => person.subscriber_id === parseInt(item)
                    )?.full_name,
                    value: parseInt(item),
                  }))}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberBeforeDueDate;
