import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getReceiptDetails } from '../../../redux/actions/FinanceActions/BillingReportsAction';
import Layout from '../../Layout/Layout';
import { useSelector } from 'react-redux';
import ListHeaderBack from '../../../components/ListHeader/ListHeaderBack';
import moment from 'moment';
import Loader from '../../../components/Loader/Loader';

const invoiceDetails = [
    {"key":"propertytax","label":"Property Tax"},
    {"key":"watercharges","label":"Water Charges"},
    {"key":"electriccharges","label":"Common Electricity Charges"},
    {"key":"repairnmaintenance","label":"Repairs & Maintenance Fund"},
    {"key":"liftmaintenance","label":"Expenses on Lift Maintenance"},
    {"key":"sinkingfund","label":"Sinking Fund Contribution"},
    {"key":"housekeeping","label":"Service Charges- House Keeping"},
    {"key":"officecharges","label":"Service Charges- Office & Genral Ch"},
    {"key":"printingcharges","label":"Service Charges- Printing & Stationery"},
    {"key":"parkingcharges","label":"Parking Charges"},
    {"key":"defaultedcharges","label":"Interest on the defaulted Charges"},
    {"key":"loancharges","label":"Repayment of Loan and Interest"},
    {"key":"nonoccupancycharges","label":"Non Occupancy Charges"},
    {"key":"insurancecharge","label":"Insurance Charges"},
    {"key":"leaserent","label":"Lease Rent"},
    {"key":"agriculturaltax","label":"Non Agricultural Tax"},
    {"key":"otherchargesfn","label":"Other Charges- Function Ch."},
    {"key":"othercharges","label":"Other Charges-"},
    {"key":"repairfund","label":"Major Repair Fund"},
    {"key":"paintingfund","label":"Painting Fund"},
    {"key":"servicetax","label":"SGST"},
    {"key":"Sb_Cess","label":"CGST"},
    {"key":"Kk_Cess","label":"IGST"},
    {"key":"servicecharge","label":"Service Charge"},
    {"key":"securitycharges","label":"Security Charges"},
    {"key":"type","label":"Invoice"},
    {"key":"watertax","label":"Water Tax"},
    {"key":"othertax","label":"Other Tax"},
    {"key":"debitnote","label":"Debit Note"},
    {"key":"chequereturncharges","label":"Cheque Return Charges"},
    {"key":"other1","label":"Other1"},
    {"key":"other2","label":"Other2"},
    {"key":"other3","label":"Other3"},
    {"key":"other4","label":"Other4"},
    {"key":"roundoff","label":"roundoff"}
]

function ViewDeletedReceipt() {
    const {id} = useParams()
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const {loginData} = useSelector(state=>state.loginReducer)
    const {loading, receiptDetails} = useSelector(state=>state.billingReportsReducer)
  
    useEffect(()=>{
        dispatch(getReceiptDetails({
            societyId: loginData?.default_community?.community_id,
            id
        }))
    }, [])

    return (
        <Layout>
            <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
                <div className="border-b border-[#CCC]">
                <ListHeaderBack title="View Receipt"  onClick={()=>navigate(-1)}>
                </ListHeaderBack>
                </div>
                <div className="ml-1 text-sm">
                    {
                        loading ?
                        <Loader/>
                        :
                        <table className='w-full mt-2'>
                            <tbody className='border '>
                                <tr>
                                    <td colspan="2" align="center">
                                        <table className='w-full border border-black border-collapse table-fixed'>
                                            <tbody className='text-sm'>
                                                <tr>
                                                    <td colspan="2" className='text-center font-bold text-lg uppercase border  border-black p-2'><h2>Receipt</h2></td>
                                                </tr>
                                                <tr>
                                                    <td className='px-2 border border-black'>Receipt Number : {receiptDetails?.paymentDetail?.id}</td>
                                                    <td className='px-2 border border-black' align="right">Receipt Date : {moment( receiptDetails?.paymentDetail?.date, 'YYYY-MM-DD hh:mm:ss').format('DD-MM-YYYY')}	</td>
                                                </tr>
                                                <tr>
                                                    <td className='px-2 border border-black'>Invoice Reference Number : {receiptDetails?.societyInvoice?.invoice_no}</td>
                                                    <td className='px-2 border border-black' align="right">Invoice Date : {moment(receiptDetails?.societyInvoice?.invoice_date)?.format('DD-MM-YYYY')}</td>
                                                </tr>
                                                <tr>
                                                    <td className='px-2 border border-black'>Member Name : {receiptDetails?.societyInvoice?.member_name}</td>
                                                    <td className='px-2 border border-black' align="right"> Bill Period : {receiptDetails?.societyInvoice?.bill_period}</td>
                                                </tr>
                                                <tr>
                                                    <td className='px-2 border border-black'>Unit : Wing No. -  {receiptDetails?.societyInvoice?.wing_no}, Flat No. - {receiptDetails?.societyInvoice?.flat_no}, Area -  {receiptDetails?.societyInvoice?.flatarea}</td>
                                                    <td className='px-2 border border-black' align="right">Payment Due Date : {moment(receiptDetails?.societyInvoice?.payment_duedate)?.format('DD-MM-YYYY')}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <table className='w-full border border-black border-collapse table-fixed'>
                                            <tbody className='text-sm'>
                                                <tr style={{background: '#e6e6e6'}}>
                                                    <th className='px-2 border border-black' align="left">Item</th>
                                                    <th className='px-2 border border-black' align="left">Amount</th>
                                                </tr>
                                                {
                                                    invoiceDetails?.map((item)=>(
                                                        !!parseFloat(receiptDetails?.paymentDetail?.[item?.key]) ?
                                                        <tr>
                                                            <td className='px-2 border border-black'>{item?.label}</td>
                                                            <td className='px-2 border border-black'>{receiptDetails?.paymentDetail?.[item?.key]}</td>
                                                        </tr>
                                                        : 
                                                        <></>
                                                    ))
                                                }
                                                <tr>
                                                    <td className='px-2 border border-black'><b>Sub Total <b></b></b></td>
                                                    <td className='px-2 border border-black'><b>{receiptDetails?.paymentDetail?.subtotal}<b>
                                                    </b></b></td>
                                                </tr>
                                                <tr>
                                                    <td className='px-2 border border-black'>Principal Arrears</td>
                                                    <td className='px-2 border border-black'>{receiptDetails?.paymentDetail?.setoffOrder?.['Principal Arrears']}</td>
                                                </tr>
                                                <tr>
                                                    <td className='px-2 border border-black'>Current Period Interest</td>
                                                    <td className='px-2 border border-black'>{receiptDetails?.paymentDetail?.setoffOrder?.['Current Period Interest']}</td>
                                                </tr>
                                                <tr>
                                                    <td className='px-2 border border-black'>Debitnote Arrears</td>
                                                    <td className='px-2 border border-black'>{receiptDetails?.paymentDetail?.setoffOrder?.['Debitnote Arrears']}</td>
                                                </tr>
                                                <tr>
                                                    <td className='px-2 border border-black'>Interest Arrears</td>
                                                    <td className='px-2 border border-black'>{receiptDetails?.paymentDetail?.setoffOrder?.['Interest Arrears']}</td>
                                                </tr>
                                                <tr>
                                                    <td className='px-2 border border-black'>Past Advances &amp; Dues</td>
                                                    <td className='px-2 border border-black'>{receiptDetails?.paymentDetail?.setoffOrder?.['Past Advances & Dues']}</td>
                                                </tr>
                                                <tr>
                                                    <td className='px-2 border border-black'>&nbsp;</td>
                                                    <td className='px-2 border border-black'>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td className='px-2 border border-black'><strong>Grand Total</strong></td>
                                                    <td className='px-2 border border-black'><strong>{receiptDetails?.paymentDetail?.grandtotal}</strong></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                    <table className='w-full border border-black border-collapse table-fixed'>
                                        <tbody>
                                            <tr>
                                                <td className='px-2 border border-black' align="left">User ID :</td>
                                                <td className='px-2 border border-black' align="left"></td>
                                            </tr>
                                            <tr>
                                                <td className='px-2 border border-black' align="left">Received From :</td>
                                                <td className='px-2 border border-black' align="left"></td>
                                            </tr>
                                            <tr>
                                                <td className='px-2 border border-black' align="left">Modified By :</td>
                                                <td className='px-2 border border-black' align="left"> </td>
                                            </tr>
                                            <tr>
                                                <td className='px-2 border border-black' align="left">Modified Type :</td>
                                                <td className='px-2 border border-black' align="left"></td>
                                            </tr>
                                            <tr>
                                                <td className='px-2 border border-black' align="left">Ip Address :</td>
                                                <td className='px-2 border border-black' align="left">{receiptDetails?.societyPaymentAuditLog?.IpAddress}</td>
                                            </tr>
                                            <tr>
                                                <td className='px-2 border border-black'><b>{receiptDetails?.paymentDetail?.modeofpayment} Details</b></td>
                                                <td className='px-2 border border-black'>&nbsp;</td>
                                            </tr>
                                            {
                                                (receiptDetails?.paymentDetail?.modeofpayment === 'NEFT' || receiptDetails?.paymentDetail?.modeofpayment=== 'Cheque') &&
                                                <tr>
                                                    <td className='px-2 border border-black'>Bank Name</td>
                                                    <td className='px-2 border border-black'>{receiptDetails?.paymentDetail?.bank_name}</td>
                                                </tr>
                                            }
                                            {
                                                (receiptDetails?.paymentDetail?.modeofpayment === 'NEFT') &&
                                                <>
                                                    <tr>
                                                        <td className='px-2 border border-black'>Transaction Number</td>
                                                        <td className='px-2 border border-black'>{receiptDetails?.paymentDetail?.transaction_number}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='px-2 border border-black'>Transaction Dated</td>
                                                        <td className='px-2 border border-black'>{moment(receiptDetails?.societyInvoice?.invoice_date)?.format('DD-MM-YYYY')}</td>
                                                    </tr>
                                                </>
                                            }
                                            
                                            {
                                                (receiptDetails?.paymentDetail?.modeofpayment === 'Cheque') &&
                                                <>
                                                    <tr>
                                                        <td className='px-2 border border-black'>Branch Name</td>
                                                        <td className='px-2 border border-black'>{receiptDetails?.paymentDetail?.branch_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='px-2 border border-black'>Cheque Number</td>
                                                        <td className='px-2 border border-black'>{receiptDetails?.paymentDetail?.cheque_number}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='px-2 border border-black'>Cheque Dated</td>
                                                        <td className='px-2 border border-black'>{moment(receiptDetails?.paymentDetail?.cleardate)?.format('DD-MM-YYYY')}</td>
                                                    </tr>
                                                </>
                                            }           


                                            <tr>
                                                <td className='px-2 border border-black'>Amount</td>
                                                <td className='px-2 border border-black'>1.27</td>
                                            </tr>
                                            <tr>
                                                <td className='px-2 border border-black'>Payment Date</td>
                                                <td className='px-2 border border-black'>{moment(receiptDetails?.societyPaymentAuditLog?.['Payment Date'])?.format('DD-MM-YYYY')}</td>
                                            </tr>
                                            <tr>
                                                <td className='px-2 border border-black'>Status</td>
                                                <td className='px-2 border border-black'>{receiptDetails?.paymentDetail?.status}</td>
                                            </tr>
                                                                <tr>
                                                <td className='px-2 border border-black'>Reason</td>
                                                <td className='px-2 border border-black'>{receiptDetails?.societyInvoice?.reason}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </td>
                                </tr>
                            </tbody>
                        </table>
                    }
                </div>
            </div>
        </Layout>
    )
}

export default ViewDeletedReceipt