import React from "react";
import ListHeader from "../../../components/ListHeader/ListHeader";
import Search from "../../../components/SearchBox/Search";
import Layout from "../../Layout/Layout";
import ContentManagementTemplateTable from "../../../components/TableComponent/ContentManagmentTemplateTable";

export const headCells = [
  { id: "id", label: "S No.", width: "70px" },
  { id: "title", label: "Title", sort: false, width: "260px" },
  { id: "description", label: "Description", sort: true, width: "500px" },
  { id: "contactNo", label: "Action", sort: true,width: "100px"},
];

const rows = [
    {
        id:`1`,
        title: "Reminder For Society Nomination",
        description: "Dear {member name}, This is to remind that Nomination details is not filled for your flat no. {wing flat no}. Please fill the Nomination for",
    },
];
  
const ContentManagementTemplate = () => {
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Content Managment Template">
          </ListHeader>
        </div>

        <div className="py-1 flex justify-between gap-2 my-1">
          <Search
            height="8"
            width="48"
            placeholder="Search transaction"
          />
        </div>
        <div className="ml-1">
        <ContentManagementTemplateTable
            data= {rows}
            columns={headCells}
            checkbox={true}
            // pagination={staffPersonnelList?.staff_list?.length}
            // totalCount={staffPersonnelList?.total_count}
            // totalPages={staffPersonnelList?.total_pages}
            // start={(staffPersonnelList?.current_page - 1) * (searchParams.get("pageLimit") || 10) + 1}
            // end={(staffPersonnelList?.current_page - 1) * (searchParams.get("pageLimit") || 10) + staffPersonnelList?.staff_list?.length}
            // currentPage={parseInt(staffPersonnelList?.current_page)}
            // rowsPerPage={searchParams.get("pageLimit") || 10}
            // getTableData={getTableData}
          >
          </ContentManagementTemplateTable>
    </div>
        {!rows?.length && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ContentManagementTemplate;
