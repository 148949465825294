import React from "react";
import Modal from "./Modal";
const ChangeStatusModal = ({ isOpen, onCancel, text, onConfirm, loading }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      title="Change Status"
      onCancel={onCancel}
      onConfirm={onConfirm}
      loading={loading}
    >
      <p className="flex justify-center font-semibold text-[16px]">Are you sure?</p>
      <p className=" mt-6 text-[16px] text-center">
        {text}
      </p>
    </Modal>
  );
};

export default ChangeStatusModal;
