import React, { useEffect } from "react";
import Layout from "../Layout/Layout";
import ListHeaderBack from "../../components/ListHeader/ListHeaderBack";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import { Form, Formik } from "formik";
import { TextInputFM } from "../../components/InputFields/TextInput";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { SubFolderNameValidation } from "../../validationSchema/deskSchema/logaTicketSchema";
import { addFolderName, getFolderList } from "../../redux/actions/DeskActions/DocumentActions";
import { DropdownFM2 } from "../../components/Dropdown/dropdown2";

function CreateSubFolder() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { addLoader } = useSelector((state) => state.documentReducer);
  const data = useSelector((state) => state.documentReducer);
 
  useEffect(() => {
    let payload = { societyId: loginData?.default_community?.community_id };
    dispatch(getFolderList(payload));
  }, [dispatch, loginData?.default_community?.community_id]);
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          initialValues={{
            folderName: "",
            folderId: '',
            subFolderName: "",
          }}
          onSubmit={(values, { resetForm }) =>
            dispatch(
              addFolderName({
                ...values,
                societyId: loginData?.default_community?.community_id,
                onSuccesCallback: () => {
                  resetForm();
                },
              })
            )
          }
          validationSchema={SubFolderNameValidation}
        >
          {(formik) => {
            return (
              <Form>
                <div className="border-b border-[#CCC]">
                  <ListHeaderBack
                    title="Create SubFolders"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <Button
                      label={
                        <span className="inline-block">
                          {addLoader ? (
                            <CircularProgress
                              sx={{ color: "white" }}
                              size={17}
                            />
                          ) : (
                            "Create SubFolders"
                          )}
                        </span>
                      }
                      className="font-semibold h-8 px-4"
                      type={"submit"}
                    />
                  </ListHeaderBack>
                </div>
                <div className="py-4 gap-2">
                  <div className="px-2.5 w-6/12">
                    <div className="w-full flex justify-between">
                      <span>
                        Folder
                      </span>
                      <DropdownFM2
                        placeholder={"Select"}
                        name={"folderId"}
                        options={data.documentFolderList?.map(
                          (item) => ({
                            label: item.name,
                            value: item.id,
                          })
                        )}
                        width="224px"
                        height="36px"
                        onSelect={(selectedItem) => {
                          formik.setFieldValue(
                            "folderId",
                            selectedItem.value
                          );
                        }}
                      />
                    </div>
                    <TextInputFM
                      label={"Sub Folder"}
                      placeholder={"Enter title"}
                      className={"w-56 h-9"}
                      name="subFolderName"
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Layout>
  );
}

export default CreateSubFolder;
