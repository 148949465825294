import React, { useEffect, useState } from "react";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import Layout from "../../../Layout/Layout";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import { EMPLOYEE_ENDPOINT, EMPLOYEE_LOGIN_REPORT_ENDPOINT, EMPLOYEE_RECORDS_ENDPOINT, PRINT_EMPLOYEE_LOGIN_REPORT_ENDPOINT } from "../../../../Routing/routes";
import ButtonIco from "../../../../components/Button/ButtonPrint";
import Button from "../../../../components/Button/Button";
import { DownloadExt } from "../../../../assets";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import moment from "moment";
import {downloadEmployeeSheet, getEmployeeLoginReport, getEmployeeNameList} from '../../../../redux/actions/SetupRecordsActions/StaffManagementActions/EmployeeManagementAction'
import RangeDatePicker from "../../../../components/DatePicker/RangeDatePicker";
import EmployeeLoginTable from "./EmployeeLoginTable";
import { downloadExcel } from "../../../../utils/helpers/downloadMedia";
import ButtonG from "../../../../components/Button/ButtonG";

export const headingsReport = [
  { id: 'sNo', label: 'S No.', sort: false, width: '60px' },
  { id: 'loginName', label: 'Login Name', sort: true,width: '120px' },
  { id: 'userId', label: 'User ID', sort: true, width: '120px'  },
  { id: 'loginTime', label: 'Login Time', sort: true, width:'100px' },
  { id: 'logoutTime', label: 'Logout Time', sort: true, width: '100px' },
];

const EmployeeLoginReport = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {loading, loginReportList, employeeNameList} = useSelector(state=>state.employeeManagementReducer)
  const {loginData} = useSelector(state=>state.loginReducer)
  const [filter, setFilter] = useState({
    empId: searchParams.get("empId") || "",
    startDate: searchParams.get("startDate") || "",
    endDate: searchParams.get("endDate") || "",
  })

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handlePrint = () => {
    window.open(`${PRINT_EMPLOYEE_LOGIN_REPORT_ENDPOINT}?${createSearchParams([...searchParams.entries()])}`)
  };


  const handleSelectName = (selectedItem)=>{
    setFilter(prev=> { return {...prev, empId: selectedItem.value}})
  }

  const handleStartDate = (selectedDate)=>{
    setFilter(prev=> { return {...prev, startDate: moment(selectedDate).format('YYYY-MM-DD')}})
  }

  const handleEndDate = (selectedDate)=>{
    setFilter(prev=> { return {...prev, endDate: moment(selectedDate).format('YYYY-MM-DD')}})
  }

  const onSubmit= ()=>{
    navigate({
      pathname: `${EMPLOYEE_LOGIN_REPORT_ENDPOINT}`,
      search: `?${createSearchParams([...(Object.entries(filter))])}`,
  })
  }

  const handleClear = ()=>{
    navigate(EMPLOYEE_LOGIN_REPORT_ENDPOINT)
  }

  const getTableData = ()=>{
    const data = {
      empId: searchParams.get("empId") || "",
      startDate: searchParams.get("startDate") || "",
      endDate: searchParams.get("endDate") || "",
      page: searchParams.get("page") || 1,
      pageLimit: searchParams.get("pageLimit") || 10,
      societyId: loginData?.default_community?.community_id,
    }
    setFilter({
      empId: searchParams.get("empId") || "",
      startDate: searchParams.get("startDate") || "",
      endDate: searchParams.get("endDate") || "",
    })
    dispatch(getEmployeeLoginReport(data))
  }

  const handleDownloadExcel = ()=>{
    const onSucessCallback = (data)=>{
      downloadExcel({data, fileName: 'Employee Login Report.xlsx'})
    }
    const data = {
      empId: searchParams.get("empId") || "",
      startDate: searchParams.get("startDate") || "",
      endDate: searchParams.get("endDate") || "",
      societyId: loginData?.default_community?.community_id,
      onSucessCallback
    }
    dispatch(downloadEmployeeSheet(data))
  }

  useEffect(()=>{
    dispatch(getEmployeeNameList({societyId: loginData?.default_community?.community_id}))
  }, [])



  useEffect(()=>{
    getTableData()
  }, [searchParams])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 ">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack
            onClick={handleNavigation(EMPLOYEE_ENDPOINT)}
            title="Employee Login Report"
          >
            <Button
              label="Report Records"
              className="h-8 mr-2"
              onClick={handleNavigation(EMPLOYEE_RECORDS_ENDPOINT)}
            />
          </ListHeaderBack>
        </div>

        <div className="flex justify-between items-center p-2">
          <div className="flex items-center gap-3">
            <span className="text-xs">User Name</span>
            <Dropdown2
                options={employeeNameList?.map((item)=>({
                  value: item?.emp_id,
                  label: item?.emp_name
                })) || []}
                placeholder="Select User Name"
                className="text-[11px] ms-4"
                width="180px"
                height="32px"
                onSelect={(selectedItem) => {
                  handleSelectName(selectedItem)
                }}
                value={
                  filter.empId && {
                    value: filter.empId,
                    label: employeeNameList?.find((item) => item.emp_id?.toString() === filter.empId?.toString())?.emp_name
                  }
                }
              />

            <span className="text-xs">DateRange</span>
              <RangeDatePicker className="w-44 h-8"
                defaultStartValue={filter.startDate ? new Date(filter.startDate) : ''}
                defaultEndValue={filter.endDate ? new Date(filter.endDate) : ''}
                onStartDateChange={handleStartDate}
                onEndDateChange={handleEndDate}
               />
            <Button label="Search" onClick={onSubmit} className="h-8" />
            <ButtonG label='Clear' className='h-8' onClick={handleClear} />
          </div>
          <div className="flex gap-2">
            <span className="border-l border-[#CCCCCC]"></span>
            <div className="flex gap-2">
              <ButtonIco
                icon={faPrint}
                onClick={handlePrint}
                children="Print"
              />

              <ButtonIco icon={DownloadExt} children="Download Excel" onClick={handleDownloadExcel} />
            </div>
          </div>
        </div>
        <div className="ml-2">
        <EmployeeLoginTable
          loading={loading}
          data={loginReportList?.list?.map((item, index)=>({
            sNo: (((loginReportList?.current_page_no - 1) * loginReportList?.page_limit + 1)+index)?.toString()?.padStart(2, "0"),
            id: item?.emp_id || '-',
            loginName: item?.emp_name || '-',
            userId: item?.username || '-',
            loginTime: item.logintime ? moment.utc(item.logintime.replace(/\s[AaPp][Mm]$/, '')).local().format('YYYY-MM-DD hh:mm:ss A') : '-',
            logoutTime: item?.logouttime ?moment.utc(item?.logouttime.replace(/\s[AaPp][Mm]$/, '')).local().format('YYYY-MM-DD hh:mm:ss A') :  '-',
          }))}
          columns={headingsReport}
          checkbox={false}
          pagination={loginReportList?.list?.length}
          totalCount={loginReportList?.total_record}
          totalPages={loginReportList?.total_pages}
          start={(loginReportList?.current_page_no - 1) * loginReportList?.page_limit + 1}
          end={(loginReportList?.current_page_no - 1) * loginReportList?.page_limit + loginReportList?.list?.length}
          currentPage={loginReportList?.current_page_no}
          rowsPerPage={searchParams.get("pageLimit") || 10}
          getTableData={getTableData}
        ></EmployeeLoginTable>
        {!loginReportList?.list?.length && !loading && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
        </div>
      </div>
    </Layout>
  );
};

export default EmployeeLoginReport;
