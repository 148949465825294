import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MemberPrivacyValidation } from "../../../../validationSchema/setupRecordsSchema/occupants&TenantsSchema";
import { Field, FieldArray, Form, Formik } from "formik";
import Loader from '../../../../components/Loader/Loader'
import { updateMemberPrivacy } from "../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/OccupantsInfoAction";

const MembersPrivacyTable = ({setResetClick, setSaveClick, searchText}) => {
  const {loading, memberPrivacyData} = useSelector(state=>state.occupantInfoReducer)
  const dispatch = useDispatch()
  const formRef = useRef();


  useEffect(()=>{
    setSaveClick(()=>()=>{
      formRef.current?.submitForm()
    })

    setResetClick(()=>()=>{
      formRef.current?.resetForm()
    })
  }, [formRef])


  return (
    <>
      <div className="w-full p-2 border-[#CCC] mt-2">
        {
          loading?
          <Loader/>
          :
          <Formik
            enableReinitialize
            initialValues={{ memberPrivacy: memberPrivacyData ? memberPrivacyData?.map((item) => ({
              memberPrivacyId: item?.mem_privacy_id,
              name: item?.m_name || '',
              number: item?.m_number || '',
              email: item?.m_email || '',
              dob: item?.m_dob || '',
              staff: item?.m_staff || '',
              subcriberName: item?.subscriber_name || '',
              subcriberId: item?.subscriber_id
            })) : [] }}
            innerRef={(f) => (formRef.current = f)}
            onSubmit={values =>
              dispatch(updateMemberPrivacy({...values}))
            }
            validationSchema={MemberPrivacyValidation}
          >    
                {
                  ({values, setFieldValue,})=>{
                    return (
                      <Form>
                        <FieldArray
                          name="memberPrivacy"
                          render={arrayHelpers => (
                            <>
                            {
                                  values.memberPrivacy && values.memberPrivacy.length > 0 && (
                                  (searchText?.length ? values.memberPrivacy?.filter(item=> item.subcriberName?.toLowerCase().includes(searchText?.toLowerCase())) : values.memberPrivacy)?.map((member, index) => (
                                    <div className='flex mb-8' >
                                      <div className="w-[25%] text-red-650">{member?.subcriberName}</div>
                                      <div className="w-[75%]">
                                      <div className="flex border-b pb-2 mb-4 text-sm">
                                          <div className="w-[40%] text-xs"></div>
                                          <div className="flex justify-between w-full">
                                            <div className="w-[25%]">
                                              <span>Public</span>
                                            </div>
                                            <div className="w-[25%]">
                                              <span>Private</span>
                                            </div>
                                            <div className="w-[25%]">
                                              <span>Members</span>
                                            </div>
                                            <div className="w-[25%]">
                                              <span>Disable Login</span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="flex ">
                                          <div className="w-[40%] text-xs">Name</div>
                                          <div className="flex justify-between w-full">
                                            <div className="w-[25%]">
                                              <Field
                                                type="radio"
                                                name={`memberPrivacy.${index}.name`}
                                                value="public"
                                                className='accent-[#CF001C]'
                                              />
                                            </div>
                                            <div className="w-[25%]">
                                              <Field
                                                type="radio"
                                                name={`memberPrivacy.${index}.name`}
                                                value="private"
                                                className='accent-[#CF001C]'
                                              />
                                            </div>
                                            <div className="w-[25%]">
                                              <Field
                                                type="radio"
                                                name={`memberPrivacy.${index}.name`}
                                                value="members"
                                                className='accent-[#CF001C]'
                                              />
                                            </div>
                                            <div className="w-[25%]">
                                              <Field
                                                type="radio"
                                                name={`memberPrivacy.${index}.name`}
                                                value="disable-login"
                                                className='accent-[#CF001C]'
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="flex ">
                                          <div className="w-[40%] text-xs">Contact Number</div>
                                          <div className="flex justify-between w-full">
                                            <div className="w-[25%]">
                                              <Field
                                                type="radio"
                                                name={`memberPrivacy.${index}.number`}
                                                value="public"
                                                className='accent-[#CF001C]'
                                              />
                                            </div>
                                            <div className="w-[25%]">
                                              <Field
                                                type="radio"
                                                name={`memberPrivacy.${index}.number`}
                                                value="private"
                                                className='accent-[#CF001C]'
                                              />
                                            </div>
                                            <div className="w-[25%]">
                                              <Field
                                                type="radio"
                                                name={`memberPrivacy.${index}.number`}
                                                value="members"
                                                className='accent-[#CF001C]'
                                              />
                                            </div>
                                            <div className="w-[25%]">
                                              <Field
                                                type="radio"
                                                name={`memberPrivacy.${index}.number`}
                                                value="disable-login"
                                                className='accent-[#CF001C]'
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="flex ">
                                          <div className="w-[40%] text-xs">Email</div>
                                          <div className="flex justify-between w-full">
                                            <div className="w-[25%]">
                                              <Field
                                                type="radio"
                                                name={`memberPrivacy.${index}.email`}
                                                value="public"
                                                className='accent-[#CF001C]'
                                              />
                                            </div>
                                            <div className="w-[25%]">
                                              <Field
                                                type="radio"
                                                name={`memberPrivacy.${index}.email`}
                                                value="private"
                                                className='accent-[#CF001C]'
                                              />
                                            </div>
                                            <div className="w-[25%]">
                                              <Field
                                                type="radio"
                                                name={`memberPrivacy.${index}.email`}
                                                value="members"
                                                className='accent-[#CF001C]'
                                              />
                                            </div>
                                            <div className="w-[25%]">
                                              <Field
                                                type="radio"
                                                name={`memberPrivacy.${index}.email`}
                                                value="disable-login"
                                                className='accent-[#CF001C]'
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="flex ">
                                          <div className="w-[40%] text-xs">DOB</div>
                                          <div className="flex justify-between w-full">
                                            <div className="w-[25%]">
                                              <Field
                                                type="radio"
                                                name={`memberPrivacy.${index}.dob`}
                                                value="public"
                                                className='accent-[#CF001C]'
                                              />
                                            </div>
                                            <div className="w-[25%]">
                                              <Field
                                                type="radio"
                                                name={`memberPrivacy.${index}.dob`}
                                                value="private"
                                                className='accent-[#CF001C]'
                                              />
                                            </div>
                                            <div className="w-[25%]">
                                              <Field
                                                type="radio"
                                                name={`memberPrivacy.${index}.dob`}
                                                value="members"
                                                className='accent-[#CF001C]'
                                              />
                                            </div>
                                            <div className="w-[25%]">
                                              <Field
                                                type="radio"
                                                name={`memberPrivacy.${index}.dob`}
                                                value="disable-login"
                                                className='accent-[#CF001C]'
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="flex ">
                                          <div className="w-[40%] text-xs">Staff</div>
                                          <div className="flex justify-between w-full">
                                            <div className="w-[25%]">
                                              <Field
                                                type="radio"
                                                name={`memberPrivacy.${index}.staff`}
                                                value="public"
                                                className='accent-[#CF001C]'
                                              />
                                            </div>
                                            <div className="w-[25%]">
                                              <Field
                                                type="radio"
                                                name={`memberPrivacy.${index}.staff`}
                                                value="private"
                                                className='accent-[#CF001C]'
                                              />
                                            </div>
                                            <div className="w-[25%]">
                                              <Field
                                                type="radio"
                                                name={`memberPrivacy.${index}.staff`}
                                                value="members"
                                                className='accent-[#CF001C]'
                                              />
                                            </div>
                                            <div className="w-[25%]">
                                              <Field
                                                type="radio"
                                                name={`memberPrivacy.${index}.staff`}
                                                value="disable-login"
                                                className='accent-[#CF001C]'
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                  )
                            }
                            </>
                          )}
                        >
                        </FieldArray>
                      </Form>
                    )
                  }
                }
          </Formik>
        }
      </div>
    </>
  );
};

export default MembersPrivacyTable;
