import React, { useState } from "react";
import AccordionAccessSettings from "./AccordionAccessSettings";

const AccessSettingsComp = ({accessSetting, setAccessSetting}) => {
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };


  return (
    <>
      <div>
        {accessSetting && Object.entries(accessSetting)?.map((item, index) => (
          <AccordionAccessSettings
            label={item[0]}
            key={item[0]+ index}
            data={Object.entries(item?.[1])}
            isOpen={index === openAccordionIndex}
            toggleAccordion={() => toggleAccordion(index)}
            accessSetting = {accessSetting}
            setAccessSetting={setAccessSetting}
          />
        ))}
      </div>
    </>
  );
};

export default AccessSettingsComp;
