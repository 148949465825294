export const VendorsAuditData = [
  {
    label: "",
    values: [
      [
        {
          key: "vendor_Category",
          label: "Vendor Category",
          value: "Carpenters",
        },
      ],
      [{ key: "vendor_name", label: "Vendor Name", value: "LK Furniture" }],
      [{ key: "concerned_person", label: "Concerned Person", value: "Raj" }],
      [
        { key: "phone_number", label: "Phone Number", value: "9876543210" },
        { key: "email", label: "Email", value: "rakeshjain01@email.com" },
      ],
      [{ key: "other_details", label: "Other Details", value: "N/A" }],
      [{ key: "amc_exists", label: "AMC Exists", value: "Yes" }],
    ],
  },

  {
    label: "",
    values: [
      [
        { key: "amc_type", label: "AMC Type", value: "Assets AMC Contracts" },
        {
          key: "service_schedule",
          label: "Service Schedule",
          value: "Monthly",
        },
      ],
      [
        { key: "amc_start_date", label: "AMC Start Date", value: "N/A" },
        { key: "amc_end_date", label: "AMC End Date", value: "N/A" },
      ],
    ],
  },

  {
    label: "AMC Fee Details",
    values: [
      [{ key: "amc_fee", label: "AMC Fee Details", value: "N/A" }],
      [{ key: "service_tax", label: "Service Tax", value: "N/A" }],
      [{ key: "any_other_charge", label: "Any Other Charge", value: "N/A" }],
      [{ key: "total_bill_amount", label: "Total Bill Amount", value: "N/A" }],
      [
        {
          key: "applicable_tds_rate",
          label: "Applicable TDS Rate",
          value: "N/A",
        },
      ],
      [{ key: "contract_tenure", label: "Contract Tenure", value: "N/A" }],
      [
        {
          key: "amc_expiry_reminder",
          label: "AMC Expiry Reminder",
          value: "N/A",
        },
        {
          key: "amc_service_reminder",
          label: "AMC Service Reminder",
          value: "N/A",
        },
      ],
    ],
  },

  {
    label: "Choose Members To Send AMC Expory Email And SMS Reminder",
    values: [
      [
        { key: "employee_type", label: "Employee Type", value: "N/A" },
        { key: "employee", label: "Employee", value: "1" },
      ],
      [
        {
          key: "managing_committees_type",
          label: "Managing Committees Type",
          value: "N/A",
        },
        {
          key: "managing_committess_member",
          label: "Managing Committees Member",
          value: "N/A",
        },
      ],
    ],
  },
];
// [
//   { key: "vendor_name", label: "Vendor Name", value: "Pooja Electrical Works" },
//   { key: "vendor_Category", label: "Vendor Category", value: "Electronics" },
//   { key: "concerned_person", label: "Concerned Person", value: "Pooja" },
//   { key: "phone_number", label: "Phone Number", value: "9876543210" },
//   { key: "email", label: "Email", value: "poojajain02@email.com" },
//   { key: "other_details", label: "Other Details", value: "N/A" },
//   { key: "amc_exists", label: "AMC Exists", value: "No" },
// ],
// [
//   { key: "vendor_name", label: "Vendor Name", value: "Rajiv Snacks" },
//   { key: "vendor_Category", label: "Vendor Category", value: "Food & Snacks Suppliers" },
//   { key: "concerned_person", label: "Concerned Person", value: "Rajiv" },
//   { key: "phone_number", label: "Phone Number", value: "9876543210" },
//   { key: "email", label: "Email", value: "rakeshjain02@email.com" },
//   { key: "other_details", label: "Other Details", value: "N/A" },
//   { key: "amc_exists", label: "AMC Exists", value: "No" },
// ],
// ];
