import React, { useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/closeIcon.svg";
import Button from "../../../../components/Button/Button";
import Dropdown from "../../../../components/Dropdown/Dropdown";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getSocietyStaffApprovalSetting,
  updateSocietyStaffApprovalSetting,
} from "../../../../redux/actions/SetupRecordsActions/StaffManagementActions/StaffPersonnelAction";
import Loader from "../../../../components/Loader/Loader";
import { Form, Formik } from "formik";
import { ApprovalSettingValidation } from "../../../../validationSchema/setupRecordsSchema/staffManagementSchema";
import {
  getSocietyEmployeeTypes,
  getSocietyManagingCommitteeTypes,
} from "../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/TenantsInfoAction";
import { DropdownFM2 } from "../../../../components/Dropdown/dropdown2";
import { CircularProgress } from "@mui/material";

const ApprovalSettingsModal = ({ isOpen, onClose, onDelete }) => {
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const approvalSettingLoader = useSelector(
      (state) => state.staffPersonnelReducer.approvalSettingLoader
    ),
    updateLoader = useSelector(
      (state) => state.staffPersonnelReducer.updateLoader
    ),
    approvalSetting = useSelector(
      (state) => state.staffPersonnelReducer.approvalSetting
    );
  const { managingCommitteeTypesList, employeeTypeList } = useSelector(
    (state) => state.tenantInfoReducer
  );

  const handleCheckbox = (e, key, setFieldValue) => {
    setFieldValue(key, e.target.checked ? 1 : 0);
  };

  const handleSaveClick = (values, { resetForm, initialValues }) => {
    dispatch(
      updateSocietyStaffApprovalSetting({
        ...values,
        societyId: loginData?.default_community?.community_id,
        onSuccessCallback: () => {
          dispatch(
            getSocietyStaffApprovalSetting({
              societyId: loginData?.default_community?.community_id,
            })
          );
          onClose();
        },
      })
    );
  };

  useEffect(() => {
    let data = { societyId: loginData?.default_community?.community_id };
    dispatch(getSocietyManagingCommitteeTypes(data));
    dispatch(getSocietyEmployeeTypes(data));
    dispatch(getSocietyStaffApprovalSetting(data));
  }, []);

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-60 z-20 ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="bg-white rounded-lg w-[420px] pb-3">
        <div className="flex justify-end p-2 gap-10 items-center -mb-2">
          <h2 className="p-2 text-lg font-semibold mb-2 flex items-center justify-center">
            Staff Approval Settings
          </h2>
          <button
            onClick={onClose}
            className="bg-[#EAEAEA] rounded-full p-2 items-center mb-2 w-9 h-9 "
          >
            <CloseIcon className="fill-current text-gray-600 hover:text-gray-800 ml-1 w-3 h-3 cursor-pointer" />
          </button>
        </div>
        <div className="border-b border-[#CCCCCC]"></div>
        {approvalSettingLoader ? (
          <Loader />
        ) : (
          <div className="p-2">
            <Formik
              initialValues={{
                committeeApproval: approvalSetting?.committee_auto || 0,
                employeeApproval: approvalSetting?.employee_auto || 0,
                committeeId:
                  approvalSetting?.committee_id == 0 &&
                  approvalSetting?.employee_type_id != 0
                    ? "0"
                    : approvalSetting?.committee_id != 0
                    ? approvalSetting?.committee_id
                    : "",
                EmployeeId:
                  approvalSetting?.employee_type_id == 0 &&
                  approvalSetting?.committee_id != 0
                    ? "0"
                    : approvalSetting?.employee_type_id != 0
                    ? approvalSetting?.employee_type_id
                    : "",
              }}
              enableReinitialize
              onSubmit={handleSaveClick}
              validationSchema={ApprovalSettingValidation}
            >
              {({ values, dirty, setFieldValue }) => {
                return (
                  <Form>
                    {/* <div className="mb-[15px] px-2 items-center flex">
                      <Checkbox initialchecked={values.committeeApproval === 1} onChange={(e)=>{handleCheckbox(e, 'committeeApproval', setFieldValue)}} />
                      <span className="text-[13px]">Committee Autoapproval</span>
                    </div> */}
                    {/* <div className="mb-[15px] px-2 items-center flex">
                      <Checkbox initialchecked={values.employeeApproval === 1} onChange={(e)=>{handleCheckbox(e, 'employeeApproval', setFieldValue)}} />
                      <span className="text-[13px]">Employee Type Autoapproval</span>
                    </div> */}
                    <div className="mb-[15px] px-2 items-center flex">
                      <Checkbox
                        initialchecked={
                          values.committeeApproval === 1 &&
                          values.employeeApproval === 1
                        }
                        onChange={(e) => {
                          handleCheckbox(e, "committeeApproval", setFieldValue);
                          handleCheckbox(e, "employeeApproval", setFieldValue);
                        }}
                      />
                      <span className="text-[13px]"> Autoapproval</span>
                    </div>
                    <div className="flex p-2 pb-0 justify-between">
                      <span className="text-sm mt-2">Committee</span>
                      <DropdownFM2
                        options={
                          managingCommitteeTypesList?.map((item) => ({
                            label: item?.type,
                            value: item.committee_type_id,
                          })) || []
                        }
                        width="224px"
                        height="9"
                        placeholder="Search Committee"
                        className="text-xs"
                        onSelect={(selectedItem) => {
                          if (!values.EmployeeId)
                            setFieldValue("EmployeeId", "0");
                          setFieldValue("committeeId", selectedItem.value);
                        }}
                        value={
                          managingCommitteeTypesList?.find(
                            (item) =>
                              item.committee_type_id === values.committeeId
                          )?.type
                            ? {
                                label: managingCommitteeTypesList?.find(
                                  (item) =>
                                    item.committee_type_id ===
                                    values.committeeId
                                )?.type,
                                value: values.committeeId,
                              }
                            : null
                        }
                        name="committeeId"
                      />
                    </div>
                    <div className="p-2 flex justify-between pb-0">
                      <span className="text-sm">Employee Type</span>
                      <DropdownFM2
                        options={
                          employeeTypeList?.map((item) => ({
                            label: item?.type,
                            value: item.emloyee_type_id,
                          })) || []
                        }
                        width="224px"
                        height={"9"}
                        placeholder="Search Employee"
                        className="text-xs"
                        onSelect={(selectedItem) => {
                          if (!values.committeeId)
                            setFieldValue("committeeId", "0");
                          setFieldValue("EmployeeId", selectedItem.value);
                        }}
                        value={
                          employeeTypeList?.find(
                            (item) => item.emloyee_type_id === values.EmployeeId
                          )?.type
                            ? {
                                label: employeeTypeList?.find(
                                  (item) =>
                                    item.emloyee_type_id === values.EmployeeId
                                )?.type,
                                value: values.EmployeeId,
                              }
                            : null
                        }
                        name="EmployeeId"
                      />
                      {console.log(values.EmployeeId)}
                    </div>

                    <div className="border-b"></div>

                    <div className="flex justify-center mt-4">
                      <Button
                        label={
                          <span className="w-12 inline-block">
                            {updateLoader ? (
                              <CircularProgress
                                sx={{ color: "white" }}
                                size={17}
                              />
                            ) : (
                              "Save"
                            )}
                          </span>
                        }
                        className="h-9 px-5 font-semibold"
                        disabled={approvalSettingLoader || updateLoader}
                        type={"submit"}
                      />
                      <Button
                        label={<span className="w-12 inline-block">Clear</span>}
                        className="h-9 px-5 font-semibold !bg-[#CCCCCC] !border-[#CCCCCC] ms-2"
                        type={"button"}
                        onClick={() => {
                          setFieldValue("committeeId", "");
                          setFieldValue("EmployeeId", "");
                        }}
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        )}
      </div>
    </div>
  );
};

export default ApprovalSettingsModal;
