
export const AuditData = [
    {
        label: "",
        values: [
            { key: "Wing Number", label: "Wing Number", value: "D-Block" },
            { key: "Wing Title", label: "Wing Title", value: "" },
            { key: "Total Number Of Floors", label: "Total Number Of Floors", value: "8" },
            { key: "no_of_units", label: "Total Number Of Units", value: "8" },
        ],
    },
];
