import React, { useEffect,useState } from "react";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Button from "../../../../components/Button/Button";
import ButtonG from "../../../../components/Button/ButtonG";
import CreditNoteandAdvanceTable from "../../../../components/TableComponent/CreditNoteAndAdvance";
import Loader from "../../../../components/Loader/Loader";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getCreditNoteAdjustment } from "../../../../redux/actions/FinanceActions/BillingManagementActions/GenerateInvoiceAction";
import Modal from "react-modal";

const customStyles = {
  content: {
    width: "780px",
    height: "500px",
    minHeight: "fit-content",
    background: "#fff",
    border: "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
};

const CreditNoteandAdvanceModal = ({ isOpen, onClose, editInvoiceDetails, headData, index,credAdvId,handleCalculate}) => {
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading,creditnoteLoader, creditNoteAdjustment } = useSelector(
    (state) => state.generateInvoiceReducer
  );
  const [selected, setSelected] = useState([]);
  useEffect(() => {
    dispatch(
      getCreditNoteAdjustment({
        societyId: loginData?.default_community?.community_id,
        flatid: editInvoiceDetails[index]?.memberdata?.flatid,
        invoice_date: editInvoiceDetails[index]?.invoicedetail?.invoice_date,
        invoiceid: editInvoiceDetails[index]?.invoicedetail?.invoiceid,
        typeid: editInvoiceDetails[index]?.invoicedetail?.invoice_type,
      })
    );
    setSelected({ ...editInvoiceDetails[index]?.invoicedetail?.cradvdetail });
  }, []);
  const onSubmit = () => {
    //console.log(credAdvId)
    var totalCreditAdv = 0 ;
    creditNoteAdjustment.map((value)=>{
      if(Object.values(selected).includes(value.id)){
        totalCreditAdv += parseFloat(value.amount)
      }
    })
    editInvoiceDetails[index].invoicedetail.cradvdetail = selected
    editInvoiceDetails[index].invoicedetail.arearhead[credAdvId].value = -totalCreditAdv
    handleCalculate(editInvoiceDetails,index)
    onClose()
  }
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
      <div className="bg-white rounded-lg">
        <ListHeader leftContent="Invoice adjustment on credit note & Advances">
          <span className="border-r border-[#CCC]"></span>
          <ButtonG label="Cancel" type={"button"} onClick={onClose} />
          <Button label="Submit" onClick={onSubmit} disabled={creditnoteLoader}  />
        </ListHeader>
        <div>
        {creditnoteLoader? <Loader />:<CreditNoteandAdvanceTable
            loading={loading}
            data={creditNoteAdjustment?.map((item, index) => ({
              id: item.id,
              // credit_code: item?.credit_code,
              // debit_code: item?.debit_code,
              amount: item?.amount,
              date: item?.date,
              debitName: item?.debit_cause,
              creditName: item?.credit_cause,
            }))}
            index = {index}
            setSelected={setSelected}
            selected={selected}
            columns={headCells}
            checkbox={true}
            pagination={false}
          ></CreditNoteandAdvanceTable>}
          {!creditNoteAdjustment?.length && !creditnoteLoader && (
            <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
              No Records
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export const headCells = [
  {
    id: "date",
    label: "Date",
    sort: false,
    width: "100px",
  },
  { id: "debitName", label: "Debit Name", sort: false, width: "100px" },
  { id: "creditName", label: "Credit Name", sort: false, width: "120px" },
  { id: "amount", label: "Amount(Rs.)", sort: false, width: "80px" },
];
export default CreditNoteandAdvanceModal;
