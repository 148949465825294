import React from "react";
import Modal from "./Modal";
const RejectApproveModal = ({ isOpen, title, text, onCancel, onConfirm }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      title={title}
      onCancel={onCancel}
      onConfirm={onConfirm}
    >
      <p className="flex justify-center font-semibold text-[16px]">Are you sure?</p>
      <p className=" mt-6 text-[16px] text-center">
        {text}
      </p>
    </Modal>
  );
};

export default RejectApproveModal;
