import React, { useEffect } from "react";
import Layout from "../../../Layout/Layout";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Button from "../../../../components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import ButtonG from "../../../../components/Button/ButtonG";
import {  TextInputFM } from "../../../../components/InputFields/TextInput";
import { TextAreaFM } from "../../../../components/InputFields/TextArea";
import { DropdownFM2 } from "../../../../components/Dropdown/dropdown2";
import { AdvanceNoteMemberTypeOptions } from "../../../../consts/DropDownOptions";
import { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import { Form, Formik } from "formik";
import { EditAdvanceNoteValidation } from "../../../../validationSchema/financeSchema/billingManagementSchema";
import moment from "moment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getWingsAndFlatDetails } from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import { CircularProgress } from "@mui/material";
import { getBookingBankList, getBookingGLMappingList } from "../../../../redux/actions/ClubActions/ViewBookingsAction";
import { getAdvanceNoteDetails, getNonMemberList, updateAdvanceNote } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageAdvanceNoteAction";
import Loader from "../../../../components/Loader/Loader";

const EditAdvanceNote = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {id} = useParams()
  const {loginData} = useSelector(state=>state.loginReducer)
  const { glMappingList, bankList} = useSelector(state=>state.viewBookingReducer)
  const {loading, updateLoader, nonMemberList, advanceNoteDetails} = useSelector(state=>state.manageAdvanceNoteReducer)

  const handleSubmit=(values, {resetForm})=>{
      dispatch(updateAdvanceNote({
        ...values, 
        advanceNoteId: id,
        jounralId: advanceNoteDetails?.journal_id,
        registerId: advanceNoteDetails?.registerid,
        loginType: loginData?.user_type,
        societyId: loginData?.default_community?.community_id,
        loginId: loginData?.default_community?.tenant_id || loginData?.default_community?.subscriber_id || loginData?.default_community?.emp_id || loginData?.user_id,
        onSuccessCallback: ()=>{
          resetForm()
        }
      }))
  }


  useEffect(()=>{
    const societyId= loginData?.default_community?.community_id
    dispatch(getBookingBankList({societyId}))
    dispatch(getBookingGLMappingList({societyId}))
    dispatch(getWingsAndFlatDetails({societyId}))
    dispatch(getNonMemberList({societyId}))
    dispatch(getAdvanceNoteDetails({societyId, AdvanceNoteId: id}))
  }, [])


  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
      <Formik
          initialValues={{ 
            
              memberType: advanceNoteDetails?.userType.toLowerCase() || 'Member',
              date: advanceNoteDetails?.date || '',
              voucher: advanceNoteDetails?.Hard_copy_voucher || '',
              debitAccountId: advanceNoteDetails?.debit_code || '',
              creditAccountId: advanceNoteDetails?.credit_code ||  '',
              userId: advanceNoteDetails?.userId || '',
              userName: advanceNoteDetails?.additional_code || '',
              memberGLCode: advanceNoteDetails?.userType ==='member' ? '' : nonMemberList?.find(item => item.id?.toString()=== advanceNoteDetails?.userId)?.glcode,
              chequeNumber: advanceNoteDetails?.cheque_no || '',
              bankName: advanceNoteDetails?.bank_name ||  '',
              chequeDate: advanceNoteDetails?.cheque_date ||  '',
              paymentDate: advanceNoteDetails?.payment_date || '',
              amount: advanceNoteDetails?.amount ||  '',
              remarks: advanceNoteDetails?.remarks || '',
              sendEmail: 'Y',
              sendSMS: 'Y',
          }}
          enableReinitialize
          validationSchema={EditAdvanceNoteValidation}
          onSubmit={handleSubmit}
        >
          {
            ({values, errors, setFieldValue})=>(
              <Form>
                <div className="border-b border-[#CCC]">
                  <ListHeader leftContent="Edit Advance Note">
                    <div className="flex gap-2">
                      <ButtonG
                        label="Cancel"
                        onClick={()=>{navigate(-1)}}
                        className="h-8"
                        type="button"
                      />
                      <Button
                        label={
                          <span className="inline-block w-16">
                            {
                            updateLoader ?
                              <CircularProgress sx={{color: "white"}} size={17}/>
                              :
                              <>Save</>
                            }
                          </span>
                          }
                        className="h-8 px-5"
                        type="submit"
                      />
                    </div>
                  </ListHeader>
                </div>
                <div className="flex gap-4 p-2">
                  {
                    loading ?
                    <Loader/>
                    :
                    <div className="flex flex-row justify-between w-full gap-7">
                      <div className="flex flex-col w-1/2">
                      <div className="flex justify-between gap-3">
                          <div className="mt-2 text-sm">Member Type</div>
                            <DropdownFM2
                              options={AdvanceNoteMemberTypeOptions}
                              placeholder="Select Member Type"
                              className=""
                              width="225px"
                              height="36px"
                              onSelect={(selectedItem) => {
                                setFieldValue('memberType', selectedItem.value)
                                setFieldValue('userId', '')
                                setFieldValue('memberGLCode', '')
                              }}
                              value={!values.memberType ? 
                                  null
                                  :
                                  {
                                    label: AdvanceNoteMemberTypeOptions?.find(item=> item.value === values.memberType)?.label,
                                    value: values.memberType
                                  }
                                }
                              name={"memberType"}
                            />
                          </div>
                        <div className="flex flex-row justify-between">
                          <span className="mt-2 text-sm">Date of Advance	</span>
                          <div className="flex gap-3">
                            <DatePickerComponentFM
                              className="w-56 h-9" 
                              name={`date`}
                              onDateChange={(selectedDate) => {
                                setFieldValue(`date`, moment(selectedDate).format('YYYY-MM-DD'))
                              }}
                              minDate={new Date()}
                              defaultValue={values.date ? new Date(values.date) : null}
                            />
                          </div>
                        </div>
                        <TextInputFM
                          label="Hard Copy Voucher"
                          placeholder="Enter Voucher"
                          className="w-56"
                          type="alphaNumber"
                          name='voucher'
                          maxLength={10}
                        />
                        <div className="flex justify-between gap-3">
                          <div className="mt-2 text-sm">Debit Account Name	</div>
                            <DropdownFM2
                              options={bankList?.map((item)=>({
                                label: item.name,
                                value: item?.ac_code
                              }))|| []}
                              placeholder="Select A/c"
                              className=""
                              width="225px"
                              height="36px"
                              onSelect={(selectedItem) => {
                                setFieldValue('debitAccountId', selectedItem.value)
                              }}
                              value={
                                values.debitAccountId === "" ?
                                  null:
                                  {
                                    label: bankList?.find(item=> item.ac_code ===values.debitAccountId)?.name,
                                    values: values.debitAccountId
                                  }
                              }
                              name={"debitAccountId"}
                            />
                          </div>
                          <div className="flex justify-between gap-3">
                            <div className="mt-2 text-sm">Credit Account	</div>
                              <DropdownFM2
                                options={glMappingList?.map((item)=>({
                                  label: item.name,
                                  value: item?.ac_code
                                }))|| []}
                                placeholder="Select A/C"
                                className=""
                                width="225px"
                                height="36px"
                                onSelect={(selectedItem) => {
                                  setFieldValue('creditAccountId', selectedItem.value)
                                }}
                                name='creditAccount'
                                value={
                                  values.creditAccountId === "" ?
                                    null:
                                    {
                                      label: glMappingList?.find(item=> item.ac_code ===values.creditAccountId)?.name,
                                      values: values.creditAccountId
                                    }
                                }
                              />
                            </div>
                        {
                          values.memberType ==='member' ?
                          <div className="flex justify-between gap-3">
                            <div className="mt-2 text-sm">Member/Non-Member Details</div>
                            <div>
                              <div className="flex gap-2">
                              <DropdownFM2
                                options={glMappingList?.map((item)=>({
                                  label: item.name,
                                  value: item?.ac_code
                                }))|| []}
                                placeholder="Select A/C"
                                className=""
                                width="225px"
                                height="36px"
                                onSelect={(selectedItem) => {
                                  setFieldValue('creditAccountId', selectedItem.value)
                                }}
                                name={"creditAccountId"}
                                value={
                                  values.creditAccountId === "" ?
                                    null:
                                    {
                                      label: glMappingList?.find(item=> item.ac_code ===values.creditAccountId)?.name,
                                      values: values.creditAccountId
                                    }
                                }
                              />
                              </div>
                            </div>
                          </div>
                          :
                          <div className="flex justify-between gap-3">
                            <div className="mt-2 text-sm">Member/Non-Member Details	</div>
                              <DropdownFM2
                                options={nonMemberList?.map((item)=>({
                                  label: item?.vendorname,
                                  value: item?.id,
                                  venderGLCode: item?.glcode
                                }))|| []}
                                placeholder="Select vendor"
                                className=""
                                width="225px"
                                height="36px"
                                onSelect={(selectedItem) => {
                                  setFieldValue('vendorGLCode', selectedItem.venderGLCode)
                                  setFieldValue('userId', selectedItem.value)
                                }}
                                value={
                                  values.userId ? 
                                  {
                                    label: nonMemberList?.find(item=> item.id?.toString() === values.userId?.toString())?.vendorname,
                                    value: values.userId
                                  }
                                  : null
                                }
                                {...(values.userId === "" && {
                                  value: null,
                                })}
                                name={"userId"}
                              />
                            </div>
                          }
                        <TextInputFM
                          label="Cheque Number"
                          placeholder="Enter Cheque Number"
                          className="w-56"
                          type="alphaNumber"
                          name='chequeNumber'
                          maxLength={20}
                        />
                        <TextInputFM
                          label="Bank Name"
                          placeholder="Enter Bank Name"
                          className="w-56"
                          name='bankName'
                        />
                        <div className="flex flex-row justify-between">
                          <span className="mt-2 text-sm">Cheque Date</span>
                          <div className="flex gap-3">
                            <DatePickerComponentFM
                              className="w-56 h-9" 
                              name={`chequeDate`}
                              onDateChange={(selectedDate) => {
                                setFieldValue(`chequeDate`, moment(selectedDate).format('YYYY-MM-DD'))
                              }}
                              defaultValue={values.chequeDate ? new Date(values.chequeDate) : null}
                            />
                          </div>
                        </div>
                        <div className="flex flex-row justify-between">
                          <span className="mt-2 text-sm">Payment Date</span>
                          <div className="flex gap-3">
                            <DatePickerComponentFM
                              className="w-56 h-9" 
                              name={`paymentDate`}
                              onDateChange={(selectedDate) => {
                                setFieldValue(`paymentDate`, moment(selectedDate).format('YYYY-MM-DD'))
                              }}
                              defaultValue={values.paymentDate ? new Date(values.paymentDate) : null}
                            />
                          </div>
                        </div>
                        <TextInputFM
                          label="Amount"
                          placeholder="Enter Amount"
                          className="w-56"
                          type="decimal"
                          name='amount'
                          maxLength={10}
                        />
                        <div className="w-full">
                          <TextAreaFM
                            label="Remarks"
                            placeholder="Enter Remarks"
                            className={"w-56 h-[87px]"}
                            maxLength={300}
                            name='remarks'
                          />
                        </div>
                        <div className="flex items-center justify-between gap-3">
                          <div className="w-1/2 text-sm">Send SMS And Email</div>
                          <div className="flex items-center w-1/2 p-2 text-sm">
                            <Checkbox
                              text={"SMS"}
                              className={"w-28"}
                              initialchecked={values.sendSMS ==='Y'}
                              onChange={(e) => {setFieldValue('sendSMS', e.target.checked ? 'Y' : 'N')}}
                            />
                            <Checkbox
                              text={"Email"}
                              className={"w-28"}
                              initialchecked={values.sendEmail ==='Y'}
                              onChange={(e) => {setFieldValue('sendEmail', e.target.checked ? 'Y' : 'N')}}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col w-1/2">
                        <div className="mt-[275px] break-all text-sm text-[#AAAAAA]">
                          { values.memberType==='Member' && values.memberGLName}
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </Form>
            )
          }
          </Formik>
      </div>
    </Layout>
  );
};

export default EditAdvanceNote;
