import React from "react";
import { TextInputFM } from "../../../../../components/InputFields/TextInput";

const MemberAfterDueDate = () => {

  return (
    <div>
      <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg overflow-hidden mt-3">
        <div className=" border-b border-[#CCC] pb-2 bg-[#FDF2F3]">
          <h1 className="text-base items-center p-2 -mb-2 ml-1">After Due Date</h1>
        </div>
        <div className=" p-2">
            <div className="w-1/2">
              <TextInputFM
                name={`noOfDaysAfterDue`}
                type='number'
                className='w-60'
                label='No. Of Days Prior'
              />
            </div>
        </div>
      </div>
    </div>
  );
};

export default MemberAfterDueDate;
