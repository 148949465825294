import React, { useEffect } from "react";
import Layout from "../../../Layout/Layout";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Button from "../../../../components/Button/Button";
import BeforeDueDate from "./DueReminderComp/BeforeDueDate";
import AfterDueDate from "./DueReminderComp/AfterDueDate";
import { useNavigate } from "react-router-dom";
import {
  DEPOSIT_DUE_REMINDER_ENDPOINT,
  FIXED_DEPOSIT_MATURITY_REMINDER_ENDPOINT,
  MEMBER_DUE_REMINDER_ENDPOINT,
  NOMINATION_REMINDER_ENDPOINT,
} from "../../../../Routing/routes";
import { Form, Formik } from "formik";
import { DueReminderValidation } from "../../../../validationSchema/financeSchema/settingSchema";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getDueReminderDetails, updateDueReminderDetails } from "../../../../redux/actions/FinanceActions/SettingActions/DueRemindersAction";
import Loader from "../../../../components/Loader/Loader";

const DueReminder = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading, updateLoader, dueReminderData} = useSelector(state=>state.dueReminderReducer)

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleSubmit = (values, {resetForm})=>{
    if(values?.noOfDaysAfterDue?.length || values.noOfDaysBeforeDue?.length)
      dispatch(updateDueReminderDetails({...values, societyId: loginData?.default_community?.community_id}))
  }
  
  useEffect(()=>{
    dispatch(getDueReminderDetails({societyId: loginData?.default_community?.community_id}))
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Due Reminder">
          <Button
            type='button'
              label="Deposit Due Reminder"
              onClick={handleNavigation(DEPOSIT_DUE_REMINDER_ENDPOINT)}
            />
            <Button
            type='button'
              label="Member Due Reminder"
              onClick={handleNavigation(MEMBER_DUE_REMINDER_ENDPOINT)}
            />
            <Button
            type='button'
              label="FD Maturity Reminder"
              onClick={handleNavigation(
                FIXED_DEPOSIT_MATURITY_REMINDER_ENDPOINT
              )}
            />
            <Button
            type='button'
              label="Nominees Reminder"
              onClick={handleNavigation(NOMINATION_REMINDER_ENDPOINT)}
            />
          </ListHeader>
        </div>
        <Formik
          initialValues={{  
            noOfDaysBeforeDue: dueReminderData?.beforeDueDate?.map((item)=>({
              id: item.id,
              noOfDay: item.no_of_day_prior || ''
            })) || [],
            noOfDaysAfterDue: dueReminderData?.afterDueDate?.map((item)=>({
              id: item.id,
              noOfDay: item.no_of_day_prior || ''
            })) || [],
          }}
          enableReinitialize
          validationSchema={DueReminderValidation}
          onSubmit={handleSubmit}
        >
          {
            ({values, errors, setFieldValue})=>(
              <Form>
                <div className="flex py-1.5 justify-end border-b border-[#CCC]">
                  <Button
                    type='submit'
                    label={
                      <span className="w-16 inline-block">
                        {
                        updateLoader ?
                          <CircularProgress sx={{color: "white"}} size={17}/>
                          :
                          <>Save</>
                        }
                      </span>
                      }
                      disabled={loading || updateLoader}
                  />
                </div>
                {
                  loading ?
                  <Loader/>
                  :
                  <>
                    <div>
                      <BeforeDueDate values={values} setFieldValue={setFieldValue} />
                    </div>
                    <div>
                      <AfterDueDate values={values} setFieldValue={setFieldValue} />
                    </div>
                  </>
                }
              </Form>
            )
          }
          </Formik>
      </div>
    </Layout>
  );
};

export default DueReminder;
