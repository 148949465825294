import * as type from '../../../actions/index.types'

export function SocietyFormsAndByeLawsReducer(state = {
  loading: false,
  societyFormsAndByeLawsData: null,
  error:null
}, action) {
switch (action.type) {
  case type.GET_SOCIETY_FORMS_AND_BYE_LAWS:
    return {
      ...state,
      loading: true,
    }
  case type.GET_SOCIETY_FORMS_AND_BYE_LAWS_SUCCESS:
    return {
      ...state,
      error: null,
      societyFormsAndByeLawsData: action.payload,
      loading: false,
    }

  case type.GET_SOCIETY_FORMS_AND_BYE_LAWS_FAILURE:
    return {
      ...state,
      error: action?.message,
      loading: false,
      societyFormsAndByeLawsData: null,

    }
  default:
    return state
}
}