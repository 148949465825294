import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import "./Table.css";
import {
  AuditIcon,
  DeleteIcon,
  EditIcon,
  SendEmail,
  SortIcon,
} from "../../assets";
import styled from "@emotion/styled";
import ItemsPerPage from "../ItemsPerPage/ItemsPerPage";
import Pagination from "../Pagination/Pagination";
import {
  stableSort,
  getComparator,
} from "../../utils/helpers/universalFunctions";
import { Link, createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { CircularProgress, Tooltip } from "@mui/material";
import {
  EDIT_MEMBER_DETAILS_ENDPOINT,
  MEMBER_DETAILS_ENDPOINT,
  MEMBER_PRIVACY_ENDPOINT,
  OCCUPANTS_AUDIT_LOG_ENDPOINT,
  OCCUPANTS_INFO_ENDPOINT,
} from "../../Routing/routes";
import DeleteModal from "../Modal/DeleteModal";
import SendEmailModal from "../Modal/SendEmailModal";
import Loader from "../Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { changeOccupantStatus, changeOccupantStatusInBulk, deleteOccupants, sendEmailToOccupant } from "../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/OccupantsInfoAction";
import ChangeStatusModal from "../Modal/ChangeStatusModal";
import Dropdown2 from "../Dropdown/dropdown2";
import Button from "../Button/Button";

function ActionComponent({ flatId, userAccId, subscriberId, setSelected }) {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isSendEmailModalOpen, setIsSendEmailModalOpen] = useState(false);
  const {updateLoader} = useSelector(state=>state.occupantInfoReducer)
  const {ipAddress} = useSelector(state=>state.ipAddressReducer)
  const {loginData} = useSelector(state=>state.loginReducer)
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()


  const handleAuditClick = ()=>{
    navigate(`${OCCUPANTS_AUDIT_LOG_ENDPOINT}?flatId=${flatId}&userAccId=${userAccId}&subscriberId=${subscriberId}`);
  }

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleDelete = ()=>{
    let data = {
      ipAddress, 
      subscriberId, 
      deleteflatId: flatId, 
      sessionId: loginData?.session_id,
      societyId: loginData?.default_community?.community_id,
      fetchData: true,
      page: searchParams.get("page") || 1,
      searchText: searchParams.get("search") || '',
      pageLimit: searchParams.get("pageLimit") || 10,
      titleId: searchParams.get("titleId") || '',
      wingId: searchParams.get("wingId") || '',
      flatId: searchParams.get("flatId") || '',
      onSuccessCallback: ()=>{
        setShowDeleteModal(false);
        setSelected([]);
      }
    }
    dispatch(deleteOccupants(data))
  }

  const handleSendEmailOnSubmit = (data) =>{
    const sendRequest = {
      subject: data.subject,
      message: data.message,
      subscriberId,
      onRequestEndCallback: ()=>{
        setIsSendEmailModalOpen(false)
      }
    }
    dispatch(sendEmailToOccupant(sendRequest))
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Tooltip title="Edit" arrow>
        <EditIcon
          className="fill-[#555] hover:fill-red-650 me-1.5"
          onClick={() => {
            navigate(`${EDIT_MEMBER_DETAILS_ENDPOINT}/${subscriberId}/${userAccId}`);
          }}
        />
      </Tooltip>
      <Tooltip title="Audit Logs" arrow>
        <AuditIcon
          className="fill-[#555] hover:fill-red-650 me-1.5"
          onClick={handleAuditClick}
        />
      </Tooltip>
      <Tooltip title="Send Email" arrow>
        <SendEmail
          className="fill-[#555] hover:fill-red-650 me-1.5"
          onClick={() => setIsSendEmailModalOpen(true)}
        />
      </Tooltip>
      <Tooltip title="Delete" arrow>
        <DeleteIcon
          className="fill-[#555] hover:fill-red-650"
          onClick={() => handleDeleteClick()}
        />
      </Tooltip>
      {/* Delete Confirmation Modal */}
      <DeleteModal
        isOpen={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onDelete={handleDelete}
      />

      {/* Email Modal */}
      <SendEmailModal
        loading={updateLoader}
        isOpen={isSendEmailModalOpen}
        closeModal={() => setIsSendEmailModalOpen(false)}
        onSubmit={handleSendEmailOnSubmit}
      />
    </Box>
  );
}

const TableRowStyled = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #ffffff;
  }
  &:nth-of-type(even) {
    background-color: #f4f5f8;
  }
  &:nth-of-type(odd):hover {
    background-color: #ffffff;
  }
  &:nth-of-type(even):hover {
    background-color: #f4f5f8;
  }
`;

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    zIndex: 0,
    color: "#AAAAAA",
    fontSize: "13px",
    padding: '12px 8px 12px 0',
    wordBreak: "break-word",
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
    fontSize: "13px",
    padding: '12px 8px 12px 0',
    wordBreak: "break-word",
    cursor: "pointer"
  },
}));

const OccupantInfoTable = ({
  loading,
  data,
  columns,
  checkbox = true,
  pagination = true,

  //props are required if pagination is true
  totalCount,
  totalPages,
  start,
  end,
  currentPage,
  rowsPerPage,
  getTableData
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);

  const [selected, setSelected] = useState([]);
  const [showChangeStatusModal, setShowChangeStatusModal] = useState({id: '', open: false});
  const [action, setAction] = useState('');
  const {updateLoader} = useSelector(state=>state.occupantInfoReducer)
  const dispatch = useDispatch()


  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    const newSelected = event.target.checked ? data?.map((item) => ({subscriberId: item.subscriberId, flatId: item.flatId})) : [];
    setSelected(newSelected);
  };

  const handleClick = (event, id, flatId) => {
    const newSelected = selected.some(item => item.subscriberId === id && item.flatId === flatId)
      ? selected.filter((selectedItem) => !(selectedItem.subscriberId === id && selectedItem.flatId === flatId))
      : [...selected, {subscriberId: id, flatId}];
    setSelected(newSelected);
  };

  const handleChangePage = (newPage) => {
      searchParams.delete("page")
      navigate({
        pathname: `${OCCUPANTS_INFO_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["page", newPage]])}`,
      })
  };

  const handleChangeRowsPerPage = (value) => {
    if (rowsPerPage !== value) {
      searchParams.delete("pageLimit")
      navigate({
        pathname: `${OCCUPANTS_INFO_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["pageLimit", value]])}`,
      })
    }
  };

  const handleApply = ()=>{
    if(action && selected?.length){
      let data={
        subscribeIds: selected?.map(item=> item.subscriberId), 
        action: 'approve',
        onSuccessCallback: ()=>{
          setSelected([])
        }
      };
      dispatch(changeOccupantStatusInBulk(data))
    }
  }

  const isSelected = (id, flatId) => selected.some(item => item.subscriberId === id && item.flatId === flatId);

  const handleChangeStatus = (id, status)=>{
    let data={
      subscribeId: id, 
      status: status==='Disapprove' ? 'I' : 'A',
      onRequestEndCallback: ()=>{
        setShowChangeStatusModal(false)
      }
    };
    dispatch(changeOccupantStatus(data))
  }

  const handleMemberPrivacyClick = () => {
    const ids = [... new Set(selected?.map((item)=>(item.subscriberId)))]
      navigate({
        pathname: `${MEMBER_PRIVACY_ENDPOINT}`,
        search: `?${createSearchParams([...ids?.map((item)=>(
          ['subId', item]
        ))])}`,
      })
  };

  useEffect(()=>{      
    setSelected([])
  }, [searchParams])

  return (
    <Box
      sx={{ width: "100%", fontSize: "13px" }}
      className="table-component"
    >
       { !!selected?.length && (
          <div className="flex justify-between items-center gap-2 p-2">
            <div className="flex items-center gap-2">
              <Button
                label="Members Privacy"
                className="font-semibold px-4 py-2 h-8"
                onClick={handleMemberPrivacyClick}
              />
            </div>
            <div className="flex justify-end gap-3">
              <Dropdown2
                options={[
                  { value: "Bulk Member Approval", label: "Bulk Member Approval" },
                ]}
                placeholder="Choose Action"
                className="text-[11px] ms-4"
                width="160px"
                height="32px"
                onSelect={(selectedItem) => {setAction(selectedItem.value)}}
              />
              <Button label={
                  <span className="w-8 inline-block">
                    {
                      updateLoader ?
                        <CircularProgress sx={{color: "white"}} size={17}/>
                        :
                        'Apply'
                      }
                  </span>
                } 
                className="font-semibold px-4 h-8" type='button' onClick={handleApply} />
            </div>
          </div>
        )}
      <TableContainer
        className="border-t border-[#CCC]"
        sx={{minHeight: data?.length ? "500px" : 'none'}}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {!!checkbox && (
                <TableCellStyled size="small" sx={{pl:'0 !important', pr: 2, width: 36 }}>
                  <Checkbox
                    size="small"
                    indeterminate={false}
                    checked={data?.length > 0 && selected?.length === data?.length}
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all",
                    }}
                    sx={{
                      p: 0,
                      color: "#AAAAA",
                      "&.Mui-checked": {
                        color: "#CF001C",
                      },
                    }}
                    disabled={loading || !data}
                  />
                </TableCellStyled>
              )}
              {columns?.map((column) => (
                <TableCellStyled
                  size="small"
                  key={column.id+ 'heading'}
                  align={column.numeric ? "right" : "left"}
                  sortDirection={orderBy === column.id ? order : false}
                  sx={{
                    width: column.width || "fit-content",
                    minWidth: column.width || "fit-content",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={() => {
                      if (column.sort) {
                        handleRequestSort(column.id);
                      }
                    }}
                    IconComponent={SortIcon}
                    sx={{
                      ".MuiTableSortLabel-icon": {
                        opacity: "1 !important",
                      },
                      svg: {
                        flexShrink: 0,
                      },
                    }}
                    hideSortIcon={!column.sort}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCellStyled>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {
                loading ?
                <TableRow>
                  <TableCellStyled sx={{border: 'none'}} colSpan={columns?.length+1}><Loader/></TableCellStyled>
                </TableRow>
                :
                <>
                  {data?.length && stableSort(data, getComparator(order, orderBy))
                    ?.map((row, rowIndex) => {
                      const isRowSelected = isSelected(row?.subscriberId, row.flatId);

                      return (
                        <TableRowStyled
                          key={`${row?.userAccId}+${row?.flatId}+${rowIndex}`}
                          role="checkbox"
                          aria-checked={isRowSelected}
                          tabIndex={-1}
                          selected={isRowSelected}
                        >
                          {!!checkbox && (
                            <TableCellStyled size="small" sx={{pl:'0 !important', zIndex: 0}} onClick={(event) => handleClick(event, row?.subscriberId, row?.flatId)}>
                              <Checkbox
                                checked={isSelected(row?.subscriberId, row.flatId)}
                                onChange={(e) =>handleClick(e, row?.subscriberId, row?.flatId)}
                                size="small"
                                color="primary"
                                inputProps={{
                                  "aria-labelledby": `generic-table-checkbox-${row?.id}`,
                                }}
                                sx={{
                                  p: 0,
                                  color: "#AAAAA",
                                  "&.Mui-checked": {
                                    color: "#CF001C",
                                  },
                                }}

                              />
                            </TableCellStyled>
                          )}
                          {/* Serial Number */}
                          <TableCellStyled size="small" align="left" onClick={(event) => handleClick(event, row?.subscriberId, row?.flatId)}>
                            { row.sNo}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row?.flatno}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row?.title}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row.memberName}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row.type}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            <div>
                              <span onClick={()=>setShowChangeStatusModal({id: row.subscriberId, flatId: row.flatId, open: true})} className={`underline font-semibold ${row.status === 'Approve' ? 'text-green-500' : 'text-red-600'}`}>
                                {row?.status}
                              </span>
                                <ChangeStatusModal
                                  isOpen={showChangeStatusModal.open && row.subscriberId === showChangeStatusModal.id && row.flatId === showChangeStatusModal.flatId}
                                  onCancel={()=>setShowChangeStatusModal(false)}
                                  onConfirm={()=>{handleChangeStatus(row.subscriberId, row?.status)}}
                                  text={`Do you want to change status to ${row.status} ?`}
                                  loading={updateLoader}
                                />
                              </div>
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left" >
                            {row?.intercom} 
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row.contactNo}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row.email}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {
                              <p>{row?.liveshereornot+" "}
                                <Link
                                  className="text-[#0680FD] underline"
                                  to={`${MEMBER_DETAILS_ENDPOINT}/${row?.subscriberId}/${row?.userAccId}`}
                                >
                                  Details
                                </Link>
                              </p>
                            }
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row?.lastTimeActive}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            <ActionComponent flatId={row?.flatId} userAccId={row?.userAccId} subscriberId={row?.subscriberId} setSelected={setSelected}/>
                          </TableCellStyled>
                        </TableRowStyled>
                      );
                    })}
                </>
              }
          </TableBody>
        </Table>
      </TableContainer>

      {!!pagination && data && (
        <div className="flex flex-row justify-between mt-3 pt-1 border-t border-[#ccc] text-[14px]">
          <ItemsPerPage
            rowsPerPage={rowsPerPage}
            onChange={handleChangeRowsPerPage}
          />
          <Pagination
            totalCount={totalCount}
            totalPages={totalPages}
            start={start}
            end={end}
            currentPage={currentPage}
            onChange={handleChangePage}
          />
        </div>
      )}
    </Box>
  );
};

OccupantInfoTable.propTypes = {
  columns: PropTypes.array.isRequired,
  defaultSortField: PropTypes.string,
  rowsPerPageOptions: PropTypes.array,
};

export default OccupantInfoTable;
