import React, { useState } from "react";
import { TextInputFM } from "../../../components/InputFields/TextInput";
import Button from "../../../components/Button/Button";
import StaffDetailsModal from "./StaffDetailsModal";
import { Form, Formik } from "formik";
import { StaffInOutValidation } from "../../../validationSchema/gateManagementSchema/visitorAndVehicleInAndOutSchema";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getStaffDetailsByStaffCode } from "../../../redux/actions/GateManagementActions/VisitorAndVehicleInAndOutAction";
import { CircularProgress } from "@mui/material";

const StaffInOut = () => {
  const [staffDetails, setStaffDetails] = useState(false);
  const dispatch =useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading} = useSelector(state=>state.visitorAndVehicleInAndOutReducer)

  const handleStaffDetails = (values, {resetForm}) => {
    dispatch(getStaffDetailsByStaffCode({
      ...values,
      societyId: loginData?.default_community?.community_id,
      onSuccessCallback: ()=>{resetForm(); setStaffDetails(true)}
    }))
  };
  const handleChangeSettings = () => {
    setStaffDetails(false);
  };

  return (
    <>
      <Formik
        initialValues={{
          staffCode: ''
        }}
        onSubmit={handleStaffDetails}
        enableReinitialize
        validationSchema={StaffInOutValidation}
      >    
        {
          ({values, setFieldValue})=>{
            return (
              <Form>
                <div className="flex gap-5 mt-4">
                  <div className="w-1/2">
                    <TextInputFM
                      label="Staff Code"
                      placeholder="Enter Code"
                      className="w-36 h-9"
                      name='staffCode'
                      maxLength={4}
                      type='number'
                      errorBoxClassname='w-36'
                    />
                  </div>
                  <div className="w-3/4">
                    <Button type='submit'
                    label={
                        <span className="w-20 inline-block">
                          {
                            loading ?
                              <CircularProgress sx={{color: "white"}} size={17}/>
                              :
                              'Check In/Out'
                            }
                        </span>
                        } 
                    />
                  </div>
                </div>
              </Form>
            )
          }
        }
        </Formik>
      <StaffDetailsModal
        isOpen={staffDetails}
        onClose={() => {
          setStaffDetails(false);
        }}
        onConfirm={() => {
          handleChangeSettings();
        }}
      />
    </>
  );
};

export default StaffInOut;
