import React, { useState } from "react";

const FilterTextField = ({
  defaultFromValue = "",
  defaultToValue = "",

  onAmountFromChange = () => {},
  onAmountToChange = () => {},
}) => {
  const [firstField, setFirstField] = useState("");
  const [secondField, setSecondField] = useState("");

  return (
    <div className="w-56 h-8 flex justify-center items-center min-h-[32px] gap-2 text-sm border border-[#CCCCCC] rounded-md p-2 bg-[#F9FAFC]">
      <input
        type="number"
        placeholder="Amount From"
        className="bg-[#F9FAFC] outline-none border-none w-full placeholder-italic text-black not-italic text-xs font-semibold"
        value={defaultFromValue}
        onChange={onAmountFromChange}
      />
      <div className="">-</div>
      <input
        type="number"
        placeholder="Amount To"
        className="bg-[#F9FAFC] outline-none border-none w-full placeholder-italic text-black not-italic text-xs font-semibold"
        value={defaultToValue}
        onChange={onAmountToChange}
      />
    </div>
  );
};

export default FilterTextField;
