import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.css";
import { CalenderIcon } from "../../assets";
import moment from "moment";

const RangeDatePicker = ({
  className,
  onStartDateChange,
  onEndDateChange,
  defaultStartValue,
  defaultEndValue,
  startMaxValue,
  startMinValue,
  endMaxValue,
  endMinValue,
  readOnly = false,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    startDateRef?.current?.setState((prev) => ({ ...prev, open: false }));
    if (onStartDateChange) {
      onStartDateChange(date.toISOString());
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    startDateRef?.current?.setState((prev) => ({ ...prev, open: false }));
    if (onEndDateChange) {
      onEndDateChange(date.toISOString());
    }
  };

  useEffect(() => {
    if (defaultStartValue) {
      setStartDate(defaultStartValue);
    }
  }, [defaultStartValue]);

  return (
    <div>
      <div
        className={`${className}  flex justify-between items-center border border-[#CCC] rounded-md px-1.5 bg-[#F9FAFC]`}
      >
        <div
          className="relative  cursor-text w-fit"
          onClick={() => {
            startDateRef?.current?.setOpen(true);
          }}
        >
          <div className="absolute text-xs font-semibold py-1 bg-[#F9FAFC]">
            {defaultStartValue ? (
              <span>
                {moment(new Date(defaultStartValue)).format("DD/MM/YYYY")}
              </span>
            ) : (
              <span className="font-semibold text-[#AAAAAA] italic">
                {"From"}
              </span>
            )}
          </div>
          <DatePicker
          maxDate={startMaxValue}
            className="outline-none text-xs border-none w-[64px] bg-[#F9FAFC] font-semibold"
            placeholderText={"From"}
            selected={startDate}
            onChange={handleStartDateChange}
            dateFormat="dd/MM/yyyy"
            startDate={startDate}
            readOnly={readOnly}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            ref={startDateRef}
            scrollableYearDropdown
            showYearDropdown
            dateFormatCalendar="MMMM"
            // yearDropdownItemNumber={15}
          />
        </div>
        <span className="ps-1 pe-0.5">-</span>
        <div
          className="relative  cursor-text pe-1.5"
          onClick={() => {
            endDateRef?.current?.setOpen(true);
          }}
        >
          <div className="absolute text-xs font-semibold py-1 bg-[#F9FAFC]">
            {defaultEndValue ? (
              <span>
                {moment(new Date(defaultEndValue)).format("DD/MM/YYYY")}
              </span>
            ) : (
              <span className="font-semibold text-[#AAAAAA] italic">
                {"To"}
              </span>
            )}
          </div>
          <DatePicker
          maxDate={endMaxValue}
            className="outline-none text-xs border-none w-[64px] bg-[#F9FAFC] font-semibold"
            placeholderText={"To"}
            selected={endDate}
            onChange={handleEndDateChange}
            dateFormat="dd/MM/yyyy"
            minDate={new Date(moment(startDate).add(1, "days"))}
            readOnly={readOnly}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            ref={endDateRef}
            scrollableYearDropdown
            showYearDropdown
            dateFormatCalendar="MMMM"
            // yearDropdownItemNumber={15}
          />
        </div>
        <CalenderIcon />
      </div>
    </div>
  );
};

export default RangeDatePicker;
