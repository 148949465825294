import React, { useEffect } from "react";
import ListHeader from "../../../components/ListHeader/ListHeader";
import Layout from "../../Layout/Layout";
import IFormTable from "./IFormTable";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getMemberListFormatI } from "../../../redux/actions/ComplianceActions/RegistersAction";

export const headCells = [
  { id: "id", label: "S No.", width: "70px" },
  { id: "memberFullName", label: "Member Full Name", sort: false, width: "100px" },
  { id: "wingNo", label: "Wing No.", sort: true, width: "80px" },
  { id: "floorNo", label: "Floor No.", sort: true, width: "100px" },
  { id: "flatNo.", label: "Flat Number", sort: true, width: "110px" },
  { id: "action", label: "Action", sort: true, width: "90px" },
];


const IForm = () => {
  const dispatch = useDispatch()
  const {loading, memberListFormatI} = useSelector(state=>state.registersReducer)
  const {loginData} = useSelector(state=>state.loginReducer)
  const getData = ()=>{
    dispatch(getMemberListFormatI({societyId: loginData?.default_community?.community_id,}))
  }

  useEffect(()=>{
    getData()
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <ListHeader leftContent="Member List Format 'I'"></ListHeader>
        <div className="ml-1">
          <IFormTable
            loading={loading}
            data={memberListFormatI?.map((item, index)=>({
              sNo: ( 1+index)?.toString()?.padStart(2, "0"),
              memberFullName: item?.occupant_names || '-',
              wingNo: item.wing_no || '-',
              floorNo: item?.floor_no || '-',
              flatNo: item?.flat_no || '-',
              flatId: item?.flat_id || '-',
            }))}
            columns={headCells}
            checkbox={true}
            getTableData={getData}
            ></IFormTable>
        </div>
        {!memberListFormatI?.length && !loading && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
    </Layout>
  );
};

export default IForm;
