import React, { useState } from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import "./ArchiveTenantModal";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";


export const headCells = [
    {
      id: "bookingFrequency",
      label: "Booking Frequency",
      width: "100px",
    },
    { id: "timeSlot", label: "Time Slot", width: "200px" },
    {
      id: "chargesForMembers",
      label: "Charges For Members/Day Amount (Rs.)",
      width: "100px",
    },
    {
      id: "chargesForNonMembers",
      label: "Charges For Non Members/Day Amount (Rs.)",
      width: "130px",
    },
  ];

const RateChartModal = ({ isOpen, onClose, faciltyName, loading }) => {
  const {rateChart} = useSelector(state=>state.bookAFacilityReducer)


  return (
    <div
      className={`fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-60 z-20 ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="bg-white rounded-lg w-[600px]">
        <div className="flex justify-between p-2 gap-10 items-center -mb-2">
          <h2 className="p-2 text-lg font-semibold mb-2 flex items-center">
            Rate Chart - {faciltyName}
          </h2>
          <button
            onClick={()=>{onClose()}}
            className="bg-[#EAEAEA] rounded-full p-2.5 items-center mb-2"
          >
            <CloseIcon className="fill-current text-gray-600 hover:text-gray-800 w-4 h-3 cursor-pointer" />
          </button>
        </div>
        <div className="border-b border-[#CCCCCC]"></div>
        <div className="m-4 max-h-[400px] overflow-auto">
            <table className="table-fixed w-full">
                <thead className="border-b sticky top-0">
                    <tr className="text-[13px] text-[#AAAAAA] text-center bg-white">
                        {
                        headCells?.map((item, index)=>(
                            <td className={`w-[${item.width}] font-semibold px-3`}>{item.label}</td>
                        ))}
                    </tr>
                </thead>
                <tbody className="overflow-auto">
                    {
                        loading ?
                        <tr>
                            <td colSpan={4}><Loader/></td>
                        </tr>
                        :
                        rateChart?.map((item, index)=>(
                            <tr className="text-[13px] align-top text-center" key={index}>
                               <td className="p-1.5">{item.booking_type}</td>
                               <td className="p-1.5">{item.slot}</td>
                               <td className="p-1.5">{item.charges_for_members || '-'}</td>
                               <td className="p-1.5">{item.charges_for_nonMembers || '-'}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
      </div>
    </div>
  );
};

export default RateChartModal;
