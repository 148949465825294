import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TextInput, TextInputFM } from "../../../../components/InputFields/TextInput";
import Layout from "../../../Layout/Layout";
import Button from "../../../../components/Button/Button";
import ButtonG from "../../../../components/Button/ButtonG";
import { MANAGING_COMMITTEE_ENDPOINT } from "../../../../Routing/routes";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import { UploadButtonFM } from "../../../../components/Button/UploadButton";
import AddManagingCommitteeTable from "./AddManagingCommitteTable";
import { Form, Formik } from "formik";
import { AddManangingCommitteeValidation } from "../../../../validationSchema/setupRecordsSchema/staffManagementSchema";
import { useSelector, useDispatch } from "react-redux";
import { addManagingCommittee, getOccupantsForManagingCommittee } from "../../../../redux/actions/SetupRecordsActions/StaffManagementActions/ManangingCommitteeAction";
import { CircularProgress } from "@mui/material";

const AddCommittee = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {ipAddress} = useSelector(state=>state.ipAddressReducer)
  const {updateLoader, loadingOccupants, occupantsFormanagingCommittee} = useSelector(state=> state.managingCommitteeReducer)

  const handleCancelClick = () => {
    navigate(MANAGING_COMMITTEE_ENDPOINT);
  };

  const handleSaveClick = (values, {resetForm}) => {
    dispatch(addManagingCommittee({
      ...values, 
      ipAddress,
      societyId: loginData?.default_community?.community_id,
      sessionId: loginData?.session_id,
      onSuccessCallback: ()=>{
        resetForm()
      }
    }))
  };

  useEffect(()=>{
    dispatch(getOccupantsForManagingCommittee({societyId: loginData?.default_community?.community_id}))
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
      <Formik
          initialValues={{
            name: '',
            formationDate: '',
            resolutionDoc: '',
            selectedOccupants: occupantsFormanagingCommittee?.map((item)=>({
              memberId: item?.member_id,
              roles: '',
              joinDate: '',
              leaveDate: '',
              selected: false,
              name: item?.subscriber_name,
              title: item?.title,
              flat: item?.flat,
            })) || []
          }}
          enableReinitialize
          onSubmit={handleSaveClick}
          validationSchema={AddManangingCommitteeValidation}
          >    
            {
              ({values, errors, setFieldValue})=>{
                return (
                  <Form>
                    <div className="border-b border-[#CCC]">
                      <ListHeader leftContent="Add Managing Committee ">
                        <ButtonG
                          label="Cancel"
                          onClick={handleCancelClick}
                          className="h-8"
                          type='button'
                        />
                        <Button
                          label={
                            <span className="w-8 inline-block">
                              {
                                updateLoader ?
                                  <CircularProgress sx={{color: "white"}} size={17}/>
                                  :
                                  'Add'
                                }
                            </span>
                          } 
                          className=" h-8 px-4"
                          type='submit'
                          disabled={loadingOccupants || updateLoader}
                        />
                      </ListHeader>
                    </div>
                    <div className="flex gap-4 p-2">
                      <div className="flex flex-row justify-between w-full gap-7">
                        <div className="flex flex-col w-1/2">
                          <TextInputFM
                            label="Committee Name"
                            placeholder="Enter Full Name"
                            className="w-56"
                            maxLength={75}
                            name='name'
                          />
                          <div className="flex justify-between">
                            <div className="text-sm mt-2">Resolution Document</div>
                            <div className="w-1/2 justify-start -mr-[2px]">
                              <UploadButtonFM 
                                showFileName={true} 
                                name='resolutionDoc'
                                fileName={values?.resolutionDoc?.name}
                                onSelect={(e)=>{setFieldValue('resolutionDoc', e)}}
                                accept={"image/jpeg,image/jpg,image/png,application/pdf"}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col w-1/2 ">
                          <div className="flex justify-between">
                            <TextInput
                              label="Date of Managing Committee Formation"
                              className="w-56 hidden"
                            />
                            <DatePickerComponentFM 
                             className="w-56 h-9" 
                             name='formationDate'
                             onDateChange={(selectedDate) => {
                                setFieldValue('formationDate', selectedDate)
                              }}
                              defaultValue={values.formationDate ? new Date(values.formationDate) : null}
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                      
                      </div>
                    </div>
                    <div>
                      <AddManagingCommitteeTable values={values} setFieldValue={setFieldValue} />
                    </div>
                  </Form>)
              }
            }
            </Formik>
      </div>
    </Layout>
  );
};

export default AddCommittee;
