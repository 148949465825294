import * as types from '../index.types';

export function getDebitNotesReport(data) {
    return {
        type: types.GET_DEBIT_NOTES_REPORT,
        data
    };
}

export function downloadDebitNotesReport(data) {
    return {
        type: types.DOWNLOAD_DEBIT_NOTES_REPORT,
        data
    };
}

export function getDeletedReceipt(data) {
    return {
        type: types.GET_DELETED_RECEIPT,
        data
    };
}

export function getReceiptDetails(data) {
    return {
        type: types.GET_RECIEPT_DETAILS,
        data
    };
}

export function getChequeDepositSlipReport(data) {
    return {
        type: types.GET_CHEQUE_DEPOSIT_SLIP_REPORT,
        data
    };
}

export function getCountedInvoice(data) {
    return {
        type: types.GET_COUNTED_INVOICE_REPORT,
        data
    };
}

export function getReceiptList(data) {
    return {
        type: types.GET_RECEIPTS_LIST,
        data
    };
}

export function getIncompleteTransaction(data) {
    return {
        type: types.GET_INCOMPLETE_TRANSACTION,
        data
    };
}
export function getMemberDueSummary(data) {
    return {
        type: types.GET_MEMBER_DUE_SUMMARY,
        data
    };
}
export function getMemberBillDueReport(data) {
    return {
        type: types.GET_MEMBER_BILL_DUE_REPORT,
        data
    };
}
export function getOpeningBalanceData(data) {
    return {
        type: types.GET_OPENING_BALANCE_DATA,
        data
    };
}