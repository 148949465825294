import React, { useEffect } from "react";
import Layout from "../../Layout/Layout";
import Button from "../../../components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import ButtonG from "../../../components/Button/ButtonG";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import { Form, Formik } from "formik";
import { PaymentAndAdjustmentValidation } from "../../../validationSchema/ClubSchema/ViewBookingSchema";
import { DatePickerComponentFM } from "../../../components/DatePicker/DatePicker";
import { getBookingDetails, getBookingGLMappingList, makeBookingAdjustment } from "../../../redux/actions/ClubActions/ViewBookingsAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { DropdownFM2 } from "../../../components/Dropdown/dropdown2";
import { TextInputFM } from "../../../components/InputFields/TextInput";
import { TextAreaFM } from "../../../components/InputFields/TextArea";
import { CircularProgress } from "@mui/material";
import Loader from "../../../components/Loader/Loader";

const BookingPaymentAndAdjust = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {id} = useParams()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading, bookingDetailsData, updateLoader, glMappingList} = useSelector(state=>state.viewBookingReducer)
  const handleNavigation = (path) => () => {
    navigate(path);
  };


  const handleSubmit = (values, {resetForm}) => {
    dispatch(makeBookingAdjustment({
      bookingId: id,
      ...values,
      onSuccessCallback: ()=>{
        navigate(-1)
      }
    }))
  };

  useEffect(()=>{
    let data={
      societyId: loginData?.default_community?.community_id,
    }

    dispatch(getBookingGLMappingList(data))
    dispatch(getBookingDetails({bookingId: id}))

  }, [])
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          initialValues={{  
            date: '',
            creditAccount: '-',
            debitAccountId: '',
            amount: '',
            remarks: '',
          }}
          validationSchema={PaymentAndAdjustmentValidation}
          onSubmit={handleSubmit}
        >
          {
            ({values, setFieldValue})=>(
              <Form>
                <div className="border-b border-[#CCC]">
                  <ListHeaderBack title="Payment & Adjustment" onClick={handleNavigation(-1)}>
                    <div className="flex text-sm gap-3">
                      <div className="border-r border-[#CCC]"></div>
                      <ButtonG
                        label="Cancel"
                        type='button'
                        className="h-8"
                        onClick={handleNavigation(-1)}
                      />
                      <Button
                      type={'submit'}
                        label={
                          <span className="w-12 inline-block">
                            {
                            updateLoader ?
                              <CircularProgress sx={{color: "white"}} size={17}/>
                              :
                              <>Save</>
                            }
                          </span>
                          }  
                        className="h-8 px-4"
                      />
                    </div>
                  </ListHeaderBack>
                </div>
                {
                  loading ? 
                  <Loader/>
                  :
                  <div className="p-3 flex flex-col justify-between w-3/4">
                    <div className="flex ">
                      <div className="text-[15px] w-[35%] mt-2">Date of Adjustment</div>
                      <div className="justify-start items-center flex gap-4">
                        <DatePickerComponentFM
                          className="h-9 w-56" 
                          name={`date`}
                          onDateChange={(selectedDate) => {
                            setFieldValue(`date`, selectedDate)
                          }}
                          minDate={new Date()}
                          defaultValue={values.date ? new Date(values.date) : null}
                        />                    
                      </div>
                    </div>
                    <div className="flex">
                      <div className="text-[15px] w-[35%] mt-2">Debit Account</div>
                      <div className="justify-start items-center flex">
                        <DropdownFM2
                          options={glMappingList?.map((item)=>({
                            label: item.name,
                            value: item?.ac_code
                          }))|| []}
                          placeholder="Select Security Deposit A/c"
                          className=""
                          width="224px"
                          height="36px"
                          onSelect={(selectedItem) => {
                            setFieldValue('debitAccountId', selectedItem.value)
                          }}
                            {...(values.debitAccountId === "" && {
                              value: null,
                            })}
                          name={"debitAccountId"}
                        />
                      </div>
                    </div>
                    <div className="flex items-center mb-4">
                      <div className="text-[15px] w-[35%]">Credit Account</div>
                      <div className="">
                      <span className="text-sm">{bookingDetailsData?.account_detail || '-'}</span>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="text-[15px] w-[35%] mt -2">Amount</div>
                      <div className="">
                        <TextInputFM
                          label=""
                          placeholder="Enter Amount"
                          className="w-56"
                          type='number'
                          name='amount'
                          maxLength={15}
                        />
                      </div>
                    </div>
                    <div className="flex">
                      <div className="text-[15px] w-[35%]">Remarks</div>
                      <div className="">
                      <TextAreaFM name='remarks' className="w-56" row={4} placeholder="Enter Remarks" />
                      </div>
                    </div>
                  </div>
                }
              </Form>
            )}
          </Formik>
      </div>
    </Layout>
  );
};

export default BookingPaymentAndAdjust;
