//society profile
export const isRegisteredOptions = [
  { label: "Yes", value: "Y" },
  { label: "No", value: "N" },
];

//specific details
export const areaTypeOptions = [
  { value: 1, label: "Built Up Area" },
  { value: 2, label: "Carpet Area" },
];

export const constructionUnitOptions = [
  { value: "Sq. Ft.", label: "Sq. Ft." },
  { value: "Sq. Mtr", label: "Sq. Mtr" },
];

export const monthOptions = [
  { label: "January", value: "01" },
  { label: "February", value: "02" },
  { label: "March", value: "03" },
  { label: "April", value: "04" },
  { label: "May", value: "05" },
  { label: "June", value: "06" },
  { label: "July", value: "07" },
  { label: "August", value: "08" },
  { label: "September", value: "09" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];

export const dateOptions = [
  { label: "01", value: "01" },
  { label: "02", value: "02" },
  { label: "03", value: "03" },
  { label: "04", value: "04" },
  { label: "05", value: "05" },
  { label: "06", value: "06" },
  { label: "07", value: "07" },
  { label: "08", value: "08" },
  { label: "09", value: "09" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
  { label: "14", value: "14" },
  { label: "15", value: "15" },
  { label: "16", value: "16" },
  { label: "17", value: "17" },
  { label: "18", value: "18" },
  { label: "19", value: "19" },
  { label: "20", value: "20" },
  { label: "21", value: "21" },
  { label: "22", value: "22" },
  { label: "23", value: "23" },
  { label: "24", value: "24" },
  { label: "25", value: "25" },
  { label: "26", value: "26" },
  { label: "27", value: "27" },
  { label: "28", value: "28" },
  { label: "29", value: "29" },
  { label: "30", value: "30" },
  { label: "31", value: "31" },
];

export const frequencyOptions = [
  { label: "Monthly", value: "monthly" },
  { label: "Quaterly", value: "quaterly" },
  { label: "Yearly", value: "yearly" },
];

export const yearOptionsForCalander = Array.from(
  { length: new Date().getFullYear() + 10 - 2016 },
  (_, index) => {
    return {
      label: 2017 + index,
      value: 2017 + index,
    };
  }
).reverse();

export const makeBookingYearOptionsForCalander = Array.from(
  { length: new Date().getFullYear() + 10 - 2000 },
  (_, index) => {
    return {
      label: 2000 + index,
      value: 2000 + index,
    };
  }
).reverse();

export const yearOptions = Array.from(
  { length: new Date().getFullYear() - 2016 },
  (_, index) => {
    return {
      label: 2017 + index,
      value: 2017 + index,
    };
  }
).reverse();

export const utilityYearOptions = Array.from(
  { length: new Date().getFullYear() - 2016 },
  (_, index) => {
    return {
      label: 2017 + index,
      value: 2017 + index,
    };
  }
).reverse();

export const onBehalfOfOptions = [
  { value: 0, label: "Member" },
  { value: 1, label: "Tenant" },
];

export const genderOptions = [
  { value: "M", label: "Male" },
  { value: "F", label: "Female" },
  { value: "O", label: "Other" },
];

export const namePrefixOptions = [
  { value: "Mr", label: "Mr" },
  { value: "Mrs", label: "Mrs" },
  { value: "Master", label: "Master" },
  { value: "Miss", label: "Miss" },
  { value: "Dr", label: "Dr" },
  { value: "M/S", label: "M/S" },
  { value: "Capt", label: "Capt" },
  { value: "Smt", label: "Smt" },
];

export const registerAsOptions = [
  { name: "mainoption", value: "main", label: "Main" },
  { name: "jointoption", value: "joint", label: "Joint" },
];

export const tenantsStatusOptions = [
  { value: "P", label: "Unapprove" },
  { value: "A", label: "Active" },
  { value: "I", label: "Inactive" },
  { value: "E", label: "Expired" },
];

export const societyStaffStatusOptions = [
  { value: "pending", label: "Pending" },
  { value: "approval", label: "Approved" },
  { value: "reject", label: "Rejected" },
];

export const societyStaffVehicleTypeOptions = [
  { value: "", label: "None" },
  { value: "2Wheeler", label: "2 Wheeler" },
  { value: "4Wheeler", label: "4 Wheeler" },
];

export const societyStaffTypeOptions = [
  { value: "Domestic", label: "Domestic" },
  { value: "Society", label: "Society" },
];

export const rentRegistrationStatusOptions = [
  { value: "pending", label: "Pending" },
  { value: "approved", label: "Approved" },
];

export const societyStaffCurrentStatusptions = [
  { value: "A", label: "Working" },
  { value: "D", label: "Not Working" },
  { value: "I", label: "Leave" },
];

export const classMemberOptions = [
  { value: "1", label: "Individual" },
  { value: "2", label: "Company" },
  { value: "3", label: "Firm" },
  { value: "4", label: "Local Authority" },
  { value: "5", label: "Public Trust" },
  { value: "6", label: "State Govt Society" },
];

export const leaveAndLicenseStatusOptions = [
  { label: "All", value: "" },
  { label: "Open", value: "A" },
  { label: "In-progress", value: "P" },
  { label: "Hold", value: "H" },
  { label: "Closed", value: "I" },
];

const years = Array.from(
  { length: new Date().getFullYear() - 2000 },
  (_, index) => 2000 + index + 1
);
export const financialYears = years.map((year) => ({
  value: `${year}-${year + 1}`,
  label: `${year}-${year + 1}`,
}));

export const financialYears2 = years
  .map((year) => ({
    // value: `${year}`,
    value: `${year}-${year + 1}`,
    // value1: `${year + 1}`,
    label: `${year}-${year + 1}`,
  }))
  .reverse();

export const GstNocTableOption = [
  { name: "GstTableOption", value: "service", label: "Service" },
  { name: "GstTableOption", value: "goods", label: "Goods" },
];

export const receiptReportTypeOptions = [
  { name: "reportType", value: "all", label: "All" },
  { name: "reportType", value: "paid", label: "Paid" },
  { name: "reportType", value: "due", label: "Due" },
];

export const invoiceDurationOptions = [
  { name: "duration", value: 1, label: "Monthly" },
  { name: "duration", value: 2, label: "Bi-Monthly" },
  { name: "duration", value: 3, label: "Quaterly" },
  { name: "duration", value: 6, label: "Semi Annually" },
  { name: "duration", value: 12, label: "Annually" },
];

export const GateVehicleTypeOptions = [
  { value: 2, label: "2 Wheeler" },
  { value: 4, label: "4 Wheeler" },
];

export const VisitorTypeOptions = [
  { value: "visitors", label: "Visitor" },
  { value: "staff", label: "Staff" },
];

export const ProofTypeOptions = [
  { value: "PAN", label: "PAN Card" },
  { value: "Adhar", label: "Adhar Card" },
];

export const MainVisitorTypeOptions = [
  { value: "Visitor", label: "Visitor" },
  { value: "Staff", label: "Staff" },
];

export const PaymentStatusOptions = [
  { value: "BookednPaid", label: "Paid" },
  { value: "Booked", label: "Unpaid" },
];

export const BookingFrequencyTypeOptions = [
  { value: "One Time", label: "One Time" },
  { value: "Daily", label: "Daily" },
  { value: "Weekly", label: "Weekly" },
  { value: "Monthly", label: "Monthly" },
];

export const TimeSlotFrequencyOptions = [
  { value: "All", label: "Everyday" },
  { value: "Selected", label: "Selected Days" },
];

export const TimeSlotSelectedDaysOptions = [
  { value: "Sun", label: "Su" },
  { value: "Mon", label: "Mo" },
  { value: "Tue", label: "Tu" },
  { value: "Wed", label: "We" },
  { value: "Thu", label: "Th" },
  { value: "Fri", label: "Fr" },
  { value: "Sat", label: "Sa" },
];

export const TimeSlotLessThanOptions = [
  { value: "less than", label: "Less Than" },
  { value: "greater than", label: "Greater Than" },
];

export const Hoursoptions = [
  { value: "01", label: "01" },
  { value: "02", label: "02" },
  { value: "03", label: "03" },
  { value: "04", label: "04" },
  { value: "05", label: "05" },
  { value: "06", label: "06" },
  { value: "07", label: "07" },
  { value: "08", label: "08" },
  { value: "09", label: "09" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
  { value: "24", label: "24" },
];

export const MinuteIntervaloptions = [
  { value: "00", label: "00" },
  { value: "30", label: "30" },
];

export const CreditNoteMemberTypeOptions = [
  { value: "Member", label: "Member" },
  { value: "Tenant", label: "Non Member", isDisabled: false },
];

export const AdvanceNoteMemberTypeOptions = [
  { value: "member", label: "Member" },
  { value: "vendor", label: "Non Member" },
];

export const AdvanceNoteSearchByOptions = [
  { value: "date", label: "Advance Date" },
  { value: "CreatedOn", label: "Generation Date" },
  { value: "payment_date", label: "Adjustment Date" },
];

export const NomineeDueReminderScheduleOptions = [
  { value: "Weekly", label: "Weekly" },
  { value: "Monthly", label: "Monthly" },
  { value: "Quarterly", label: "Quarterly" },
  { value: "Semiannually", label: "Semiannually" },
  { value: "Annually", label: "Annually" },
];

export const BookingMemberTypeOptions = [
  { value: "Member", label: "Member" },
  { value: "Tenant", label: "Tenant" },
];

export const jFormYearsOptions = Array.from(
  { length: new Date().getFullYear() - 2023 + 1 },
  (_, index) => ({
    label: `${2023 + index} - ${2023 + index + 1}`,
    value: `${2023 + index} - ${2023 + index + 1}`,
    fromYear: 2023 + index,
    toYear: 2023 + index + 1,
  })
);

export const SavedNoticeMemberTypeOptions = [
  { value: "Annual Genral Meeting", label: "Annual Genral Meeting" },
  { value: "Special Genral Meeting", label: "Special Genral Meeting" },
  { value: "Committee Meeting", label: "Committee Meeting" },
];

export const VehicleTypeOptions = [
  { value: "2", label: "2 Wheeler" },
  { value: "4", label: "4 Wheeler" },
  { value: "Other", label: "Other" },
];
export const TypeOfReportOptions = [
  { value: "draft", label: "Draft" },
  { value: "final", label: "Final" },
];

export const TypeOfStatusOptions = [
  { value: "all", label: "All" },
  { value: "posted", label: "Posted" },
  { value: "cancel", label: "Cancel" },
];

export const ReceiptsPaymentsReceiptsOptions = [
  { value: "receipt", label: "Receipt" },
  { value: "payment", label: "Payment" },
  { value: "pg", label: "PG" },
];

export const ARCReportsYearOptions = Array.from(
  { length: new Date().getFullYear() - 2009 },
  (_, index) => {
    return {
      label: 2010 + index,
      value: 2010 + index,
    };
  }
).reverse();

export const EInvoiceStatusOptions = [
  { value: "generated", label: "Generated" },
  { value: "generationFailed", label: "Generation Failed" },
  { value: "cancelled", label: "Cancelled" },
  { value: "cancelledFailed", label: "Cancelled Failed" },
];

export const OutstandingReportOptions = [
  { value: "all", label: "All" },
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
];
