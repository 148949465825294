import { call, put, takeLatest,  } from 'redux-saga/effects'
import * as types from '../../actions/index.types'
import {notify} from '../../../utils/notification'
import webTokenStorer from '../../../utils/webStorer';
import { logoutReq } from '../../../axios/request/AuthReqs/authReq';


function* logout(action) {
    try {
        const result = yield call(logoutReq, action);
        if (result.statusCode === 200) {
            webTokenStorer.removeToken()
            //notify('success', result.message);
            window.location.href = '/account/login'
            yield put({ type: types.LOGOUT_SUCCESS, payload: result});  
        } else {
            yield put({ type: types.LOGOUT_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}


export default function* logoutSaga() {
    yield takeLatest(types.LOGOUT, logout);
}