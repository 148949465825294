import { notify } from '../../../../utils/notification';
import apiEndpoints from '../../../api';
import axiosInstance from '../../../axiosInstance'
import {logOut} from '../../../../utils/helpers/logOut'
import getBrowserDetails from '../../../../utils/helpers/getBrowserDetails'
import { errorMsg } from '../../../../consts/responseMessages';

export async function getSocietyProfileReq(action) {

    return axiosInstance.get(apiEndpoints.GET_SOCIETY_DETAILS_API)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getCurrencyListReq(action) {

    return axiosInstance.get(`${apiEndpoints.SOCIETY_PROFILE_GET_CURRENCY_LIST_API}?flag=currency`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }
            return errors

        })
}

export async function getProjectsListReq(action) {

    return axiosInstance.get(`${apiEndpoints.SOCIETY_PROFILE_GET_PROJECT_LIST_API}?flag=projects`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }
            return errors
        })
}

export async function getCountryStateCityListReq(action) {

    return axiosInstance.get(`${apiEndpoints.SOCIETY_PROFILE_GET_COUNTRY_STATES_CITIES_LIST_API}?flag=${action.data.flag}${action.data.country_id ? `&country_id=${action.data.country_id}`: ''}${action.data.state_id ? `&state_id=${action.data.state_id}`: ''}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }
            return errors

        })
}

export async function updateSocietyProfileReq(action) {

    const BODY={
        "society_name": action.data.societyName,
        "society_name_for_sms": action.data.societyNameForSMS,
        "society_id": parseInt(action.data.societyId),
        "brand_name": '',
        "profile_pic":  action.data?.profileURL,
        "pan_no":  action.data?.panNumber?.toUpperCase(),
        "tan_no":  action.data?.tanNumber?.toUpperCase(),
        "pay_pin":  '',
        "spintly_org_id":  action.data?.spintlyOrganisationID,
        "spintly_device_id":  action.data?.spintlyDeviceID?.toString(),//
        "cheque_in_name_of": action.data?.checkInTheNameOf,
        "currency_id": parseInt(action.data?.currencyName) || 0,

        "product_description": action.data?.description,
        "club_time": action.data?.clubTimming,

        "builder_name": action.data?.builderName,
        "complex_type": action.data?.complexType,
        "area": action.data?.area,
        "construction_status": action.data?.constructionStatus,
        "country_id": action.data?.country,
        "state_id": action.data?.state || 0,
        "city_id": action.data?.city || 0,
        "pincode": action.data?.pinCode,

        "association_name": action.data?.associationName,
        "gst_registration_no": action.data?.gstNo?.toUpperCase(),
        "lar_reg_no": action.data?.localAuthRegesNo,
        "is_association_registered": action.data?.isRegistered,
        "project_id": action.data?.projectName?.toString(),
        "property_tax_no": action.data?.propertyTaxNo,


        "address": action.data?.address,
        "phone": action.data?.phone,
        "email": action.data?.email,
        "website": action.data?.website,
        "other_details": action.data?.otherDetails,
        "support_email": action.data?.supportedEmail,

        "e_invoice_data": {"applicable_date": action.data?.einvoiceApplicableDate || "",
        "e_invoice_client_id": action.data?.clientId || "",
        "e_invoice_client_secret": action.data?.clientSecret || "",
        "e_invoice_username": action.data?.eInvoiceUsername || "",
        "e_invoice_password": action.data?.eInvoicePassword || "",
        "gst_hero_login": action.data?.gstHeroLogin || "",
        "gst_hero_password": action.data?.gstHeroPassword || "",

        "gst_id": action.data?.gstUsername || "",
        "gst_password": action.data?.gstPassword || "",
        "tds_id": action.data?.tdsUsername || "",
        "tds_password": action.data?.tdsPassword || "",
        "einvoice_id": action.data?.einvoiceUsernamecred || "",
        "einvoice_password": action.data?.einvoicePasswordcred || "",
        "it_id": action.data?.incomeTaxUsername || "",
        "it_password": action.data?.incomeTaxPassword || ""},

        "ip": action.data.ipAddress,
        "browser_details": getBrowserDetails(),
        "session_id": action?.data?.sessionId,
      }
        
    return axiosInstance.post(apiEndpoints.SOCIETY_PROFILE_UPDATE_SOCIETY_DETAILS_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

export async function getSocietyProfileAuditLogsReq(action) {
    return axiosInstance.get(`${apiEndpoints.SOCIETY_PROFILE_GET_AUDITLOGS_API}?flag=society${action.data.societyId ? `&society_id=${action.data.societyId}` : ''}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors
        })
}

export async function societyChangePasswordReq(action) {

    const BODY={
        "old_password": action.data.oldPassword,
        "new_password": action.data.newPassword,
        "confirm_new_password": action.data.confirmPassword,
        "ip": action.data.ipAddress,
        "browser_details": getBrowserDetails(),
        "session_id": action?.data?.sessionId
      }

    return axiosInstance.post(apiEndpoints.SOCIETY_PROFILE_CHANGE_PASSWORD_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}