import * as type from "../../../actions/index.types";

export function creditDebitTariffHeaderReducer(
  state = {
    loading: false,
    updateLoader: false,
    tariffHeaderList: null,
    tariffHeaderDetails: null,
    tariffHeaderAudit: null,
    tariffHeaderAllAudit: null,
    glMappingList: null,
    accountLedgerHeaderList: null,
    debitSettings: null,
    error: null,
  },
  action
) {
  switch (action.type) {
    case type.GET_TARIFF_HEADER_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_TARIFF_HEADER_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        tariffHeaderList: action.payload,
      };
    case type.GET_TARIFF_HEADER_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        tariffHeaderList: null,
      };
         
    case type.GET_TARIFF_HEADER_DETAILS:
    return {
        ...state,
        loading: true,
        error: null,
    };
    case type.GET_TARIFF_HEADER_DETAILS_SUCCESS:
    return {
        ...state,
        error: null,
        loading: false,
        tariffHeaderDetails: action.payload,
    };
    case type.GET_TARIFF_HEADER_DETAILS_FAILURE:
    return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        tariffHeaderDetails: null,
    };
   
    case type.GET_TARIFF_HEADER_AUDIT_LOGS:
        return {
          ...state,
          loading: true,
          error: null,
        };
    case type.GET_TARIFF_HEADER_AUDIT_LOGS_SUCCESS:
        return {
        ...state,
        error: null,
        loading: false,
        tariffHeaderAudit: action.payload,
        };
    case type.GET_TARIFF_HEADER_AUDIT_LOGS_FAILURE:
        return {
          ...state,
          error: action?.message || "Somthing went wrong.",
          loading: false,
          tariffHeaderAudit: null,
        };
       
    case type.GET_TARIFF_HEADER_ALL_AUDIT_LOGS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_TARIFF_HEADER_ALL_AUDIT_LOGS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        tariffHeaderAllAudit: action.payload,
      };
    case type.GET_TARIFF_HEADER_ALL_AUDIT_LOGS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        tariffHeaderAllAudit: null,
      };
        
    case type.UPDATE_TARIFF_HEADER:
        return {
            ...state,
            updateLoader: true
        }
    case type.UPDATE_TARIFF_HEADER_SUCCESS:
        return {
            ...state,
            updateLoader: false
        }
    case type.UPDATE_TARIFF_HEADER_FAILURE:
        return {
            ...state,
            updateLoader: false
        }

    case type.ADD_TARIFF_HEADER:
        return {
            ...state,
            updateLoader: true
        }
    case type.ADD_TARIFF_HEADER_SUCCESS:
        return {
            ...state,
            updateLoader: false
        }
    case type.ADD_TARIFF_HEADER_FAILURE:
        return {
            ...state,
            updateLoader: false
        }

    case type.GET_GL_MAPPING_LIST:
      return {
        ...state,
        error: null,
      };
    case type.GET_GL_MAPPING_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        glMappingList: action.payload,
      };
    case type.GET_GL_MAPPING_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        glMappingList: null,
      };

    case type.GET_ACCOUNT_LEDGER_HEADER_LIST:
      return {
        ...state,
        error: null,
      };
    case type.GET_ACCOUNT_LEDGER_HEADER_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        accountLedgerHeaderList: action.payload,
      };
    case type.GET_ACCOUNT_LEDGER_HEADER_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        accountLedgerHeaderList: null,
      };
      

    case type.GET_DEBIT_NOTE_SETTINGS:
    return {
        ...state,
        loading: true,
        error: null,
    };
    case type.GET_DEBIT_NOTE_SETTINGS_SUCCESS:
    return {
        ...state,
        error: null,
        loading: false,
        debitSettings: action.payload,
    };
    case type.GET_DEBIT_NOTE_SETTINGS_FAILURE:
    return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        debitSettings: null,
    };
        
    case type.UPDATE_DEBIT_NOTE_SETTINGS:
        return {
            ...state,
            updateLoader: true
        }
    case type.UPDATE_DEBIT_NOTE_SETTINGS_SUCCESS:
        return {
            ...state,
            updateLoader: false
        }
    case type.UPDATE_DEBIT_NOTE_SETTINGS_FAILURE:
        return {
            ...state,
            updateLoader: false
        }


    default:
      return state;
  }
}
