import React, { useEffect } from "react";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import Layout from "../../../Layout/Layout";
import { useNavigate, useParams } from "react-router-dom";
import ViewDebitNoteDetailsTable from "./ViewDebitNoteDetailsTable";
import { useDispatch } from "react-redux";
import { getSingleDebitNoteList } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageDebitNoteAction";
import { useSelector } from "react-redux";
export const headCells = [
  { id: "date", label: "Date", width: "100px" },
  { id: "receiptNo", label: "Receipt No.", width: "100px" },
  { id: "mode", label: "Mode of Payment", width: "100px" },
  { id: "totalAmount", label: "Total Amount", sort: true, width: "100px" },
  { id: "amountPaid", label: "Amount Paid", sort: true, width: "150px" },
  { id: "amountDue", label: "Amount Due", sort: true, width: "170px" },
  { id: "action", label: "Action", sort: true, width: "120px" },
];

const rows = [
  {
    sNo: 1,
    date: "2024-03-12",
    amountPaid: "1000.00",
    amountDue: "1231231230.00",
  },
];

export const ViewInvoiceDetailsTable = ({
  singleDebitNote,
  loading,
  id,
  getTableData = () => {},
}) => {
  return (
    <>
      <div>
        <div className="ml-1">
          <ViewDebitNoteDetailsTable
            loading={loading}
            data={singleDebitNote?.payments?.map((item, index) => ({
              date: item?.payment_date,
              totalAmount: singleDebitNote?.grandTotal,
              amountPaid: item?.amount || "",
              amountDue: item?.due || "",
              invid: item?.id,
              receiptid: item.receipt_no,
              invoiceid: id,
              mode: item?.modeofpayment,
              receiptNo: item?.receipt_no,
              PostStatus: item?.PostStatus || "",
              paymentInvoiceId: item?.id,
              status: item?.status,
              transactionid: item?.transactionid,
              transactionno: item?.transaction_number,
            }))}
            columns={headCells}
            checkbox={false}
            getTableData={getTableData}
            // pagination={true}
          ></ViewDebitNoteDetailsTable>
        </div>
        {!rows?.length && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
    </>
  );
};

function ViewDebitNoteDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loginData } = useSelector((state) => state.loginReducer);

  const { loading, singleDebitNoteList } = useSelector(
    (state) => state.manageDebitNoteReducer
  );
  const getTableData = () => {
    dispatch(
      getSingleDebitNoteList({
        societyId: loginData?.default_community?.community_id,
        debit_note_id: id,
      })
    );
  };
  useEffect(() => {
    getTableData();
  }, []);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack
            title="View Debit Note Details"
            onClick={() => {
              navigate(-1);
            }}
          ></ListHeaderBack>
        </div>
        {/* {loading !== myInvoiceList?.data ? (
            <Loader />
          ) : ( */}
        <div className="py-1 gap-2 my-1">
          <table className="table-auto w-full p-2">
            <tbody>
              <tr className="text-[#222] text-sm">
                <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                  Invoice No.:
                </td>
                <td className="py-2">
                  {singleDebitNoteList?.data?.invoice_no || "-"}
                </td>
              </tr>
              <tr className="text-[#222] text-sm">
                <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                  Flat No.:
                </td>
                <td className="py-2">
                  {singleDebitNoteList?.data?.flat_no || "-"}
                </td>
              </tr>
              <tr className="text-[#222] text-sm">
                <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                  Invoice Date:
                </td>
                <td className="py-2">
                  {singleDebitNoteList?.data?.invoice_date || "-"}
                </td>
              </tr>
              <tr className="text-[#222] text-sm">
                <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                  Status:
                </td>
                <td className="py-2">
                  {singleDebitNoteList?.data?.status || "-"}
                </td>
              </tr>
              <tr className="text-[#222] text-sm">
                <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                  Remarks:
                </td>
                <td className="py-2">
                  {singleDebitNoteList?.data?.remarks || "-"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* )} */}
        <div>
          <ViewInvoiceDetailsTable
            singleDebitNote={singleDebitNoteList?.data}
            loading={singleDebitNoteList?.payments?.length > 0 ? true : false}
            id={id}
            getTableData={getTableData}
          />
        </div>
      </div>
    </Layout>
  );
}

export default ViewDebitNoteDetails;
