import { call, put, takeLatest } from 'redux-saga/effects'
import * as types from '../../actions/index.types'
import * as req from '../../../axios/request/GateManagementReqs/VisitorAndVehicleInAndOutReqs'
import { notify } from '../../../utils/notification';
import { getFirebaseURL, uploadImageOnFirebase } from '../../../firebase/firebaseFunction';
import { visitorProfilePath } from '../../../firebase/getFirebasePath';

function* getFlatMemberList(action) {
    try {
        const result = yield call(req.getFlatMemberListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_FLAT_MEMBER_LIST_SUCCESS, payload: {flat_id: action.data.flatId, ...result.data}});  
        } else {
            yield put({ type: types.GET_FLAT_MEMBER_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getVisitorOrVehicleDashboardData(action) {
    try {
        const result = yield call(req.getVisitorOrVehicleDashboardDataReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_VISITOR_OR_VEHICLE_MANAGEMENT_DASHBOARD_DATA_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_VISITOR_OR_VEHICLE_MANAGEMENT_DASHBOARD_DATA_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getVisitorVehicleLog(action) {
    try {
        const result = yield call(req.getVisitorVehicleLogReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_VISITOR_VEHICLE_LOG_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_VISITOR_VEHICLE_LOG_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* downloadVisitorVehicleLog(action) {
    try {
        const result = yield call(req.downloadVisitorVehicleLogReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
        }else{
            notify('Unable to download sheet.')
        }
    } catch (e) {
    }
}

function* getSocietyEntryGate(action) {
    try {
        const result = yield call(req.getSocietyEntryGateReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_SOCIETY_ENTRY_GATE_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_SOCIETY_ENTRY_GATE_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* addUpdateSocietyEntryGate(action) {
    try {
        const result = yield call(req.addUpdateSocietyEntryGateReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.ADD_UPDATE_SOCIETY_ENTRY_GATE_SUCCESS, payload: action.data});  
            action.data?.onSuccessCallback?.()
        }else{
            yield put({ type: types.ADD_UPDATE_SOCIETY_ENTRY_GATE_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}

function* deleteSocietyEntryGate(action) {
    try {
        const result = yield call(req.deleteSocietyEntryGateReq, action);
        if (result.statusCode === 200) {
            notify('success', result.message);
            action.data?.onSuccessCallback()
        } else {
        }
    } catch (e) {
        console.log(e)
    }
}

function* getGateAccessData(action) {
    try {
        const result = yield call(req.getGateAccessDataReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_GATE_ACCESS_DATA_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_GATE_ACCESS_DATA_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* addGateAccessData(action) {
    try {
        const result = yield call(req.addGateAccessDataReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.ADD_UPDATE_GATE_ACCESS_DATA_SUCCESS, payload: action.data});  
            action.data?.onSucessCallback?.()
        }else{
            yield put({ type: types.ADD_UPDATE_GATE_ACCESS_DATA_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}

function* getVisitorLog(action) {
    try {
        const result = yield call(req.getVisitorLogReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_VISITOR_LOG_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_VISITOR_LOG_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* downloadVisitorLog(action) {
    try {
        const result = yield call(req.downloadVisitorLogReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
        }else{
            notify('Unable to download sheet.')
        }
    } catch (e) {
    }
}

function* getFrequentVisitorList(action) {
    try {
        const result = yield call(req.getFrequentVisitorListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_FREQUENT_VISITOR_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_FREQUENT_VISITOR_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getExpectedVisitorList(action) {
    try {
        const result = yield call(req.getExpectedVisitorListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_EXPECTED_VISITOR_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_EXPECTED_VISITOR_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateVisitorStatus(action) {
    try {
        const result = yield call(req.updateVisitorStatusReq, action);
        if (result.statusCode === 200) {
            notify('success', action.data.status==='Checkedin' ? 'Checked In successfully' : action.data.status==='Rejected' ? 'Rejected successfully' : 'Checked Out successfully')
            yield put({ type: types.UPDATE_VISITOR_STATUS_SUCCESS, payload: action.data});  
            if(action.data?.flag==='remove'){
                const newList = yield call(req.getExpectedVisitorListReq, action)
                if (newList.statusCode === 200)
                    yield put({ type: types.GET_EXPECTED_VISITOR_LIST_SUCCESS, payload: newList.data});  
            }
        }else{
            yield put({ type: types.UPDATE_VISITOR_STATUS_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}

function* getStaffDetailsByStaffCode(action) {
    try {
        const result = yield call(req.getStaffDetailsByStaffCodeReq, action);
        if (result.statusCode === 200) {
            action.data?.onSuccessCallback?.()
            yield put({ type: types.GET_STAFF_DETAILS_BY_STAFF_CODE_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_STAFF_DETAILS_BY_STAFF_CODE_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getVistorPurposeList(action) {
    try {
        const result = yield call(req.getVistorPurposeListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_VISITOR_PURPOSE_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_VISITOR_PURPOSE_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* deleteVistorPurpose(action) {
    try {
        const result = yield call(req.deleteVistorPurposeReq, action);
        if (result.statusCode === 200) {
            notify('success', 'Purpose deleted successfully.');
            yield put({ type: types.DELETE_VISITOR_PURPOSE_LIST_SUCCESS, payload: action.data});  
            const newList = yield call(req.getVistorPurposeListReq, action)
            if (newList.statusCode === 200)
                yield put({ type: types.GET_VISITOR_PURPOSE_LIST_SUCCESS, payload: newList.data});  
        }
    } catch (e) {
        console.log(e)
    }
}

function* getVistorData(action) {
    try {
        
        const result = yield call(req.getVistorDataReq, action);
        if (result.statusCode === 200) {
                result.data.profile_pic_url = !result.data.profile_pic?.includes('https:') ? yield getFirebaseURL(result?.data?.profile_pic) : result.data.profile_pic
            yield put({ type: types.GET_VISITORS_DATA_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_VISITORS_DATA_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* sendOTPToVisitor(action) {
    try {
        const result = yield call(req.sendOTPToVisitorReq, action);
        if (result.statusCode === 200) {
            notify('success', 'SMS sent successfully')
            yield put({ type: types.SEND_OTP_TO_VISITOR_SUCCESS, payload: result.data});  
            action.data?.onSuccessCallback?.()
        }else{
            yield put({ type: types.SEND_OTP_TO_VISITOR_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}

function* adNewVisitor(action) {
    try {
        if(action.data.photo?.includes('data:image/jpeg;base64,')){
            let path = visitorProfilePath({societyId: action.data.societyId});
            yield uploadImageOnFirebase(action.data.photo, path, true)
            action.data.photo= path;
        }

        const result = yield call(req.adNewVisitorReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.ADD_NEW_VISITOR_SUCCESS, payload: action.data});  
            action.data?.onSuccessCallback?.()
        }else{
            yield put({ type: types.ADD_NEW_VISITOR_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}

function* staffCheckIn(action) {
    try {
        const result = yield call(req.staffCheckInReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.STAFF_CHECKIN_SUCCESS, payload: action.data});  
            action.data?.onSuccessCallback?.()
        }else{
            yield put({ type: types.STAFF_CHECKIN_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}

function* getVisitorPassData(action) {
    try {
        const result = yield call(req.getVisitorPassDataReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_VISITOR_PASS_DATA_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_VISITOR_PASS_DATA_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

export default function* visitorAndVehicleInAndOutSaga() {
    yield takeLatest(types.GET_FLAT_MEMBER_LIST, getFlatMemberList);
    yield takeLatest(types.GET_VISITOR_OR_VEHICLE_MANAGEMENT_DASHBOARD_DATA, getVisitorOrVehicleDashboardData);
    yield takeLatest(types.GET_VISITOR_VEHICLE_LOG, getVisitorVehicleLog);
    yield takeLatest(types.DOWNLOAD_VISITOR_VEHICLE_LOG, downloadVisitorVehicleLog);
    yield takeLatest(types.GET_SOCIETY_ENTRY_GATE, getSocietyEntryGate);
    yield takeLatest(types.ADD_UPDATE_SOCIETY_ENTRY_GATE, addUpdateSocietyEntryGate);
    yield takeLatest(types.DELETE_SOCIETY_ENTRY_GATE, deleteSocietyEntryGate);
    yield takeLatest(types.GET_GATE_ACCESS_DATA, getGateAccessData);
    yield takeLatest(types.ADD_UPDATE_GATE_ACCESS_DATA, addGateAccessData);
    yield takeLatest(types.GET_VISITOR_LOG, getVisitorLog);
    yield takeLatest(types.DOWNLOAD_VISITOR_LOG, downloadVisitorLog);
    yield takeLatest(types.GET_FREQUENT_VISITOR_LIST, getFrequentVisitorList);
    yield takeLatest(types.GET_EXPECTED_VISITOR_LIST, getExpectedVisitorList);
    yield takeLatest(types.UPDATE_VISITOR_STATUS, updateVisitorStatus);
    yield takeLatest(types.GET_STAFF_DETAILS_BY_STAFF_CODE, getStaffDetailsByStaffCode);
    yield takeLatest(types.GET_VISITOR_PURPOSE_LIST, getVistorPurposeList);
    yield takeLatest(types.DELETE_VISITOR_PURPOSE_LIST, deleteVistorPurpose);
    yield takeLatest(types.GET_VISITORS_DATA, getVistorData);
    yield takeLatest(types.SEND_OTP_TO_VISITOR, sendOTPToVisitor);
    yield takeLatest(types.ADD_NEW_VISITOR, adNewVisitor);
    yield takeLatest(types.STAFF_CHECKIN, staffCheckIn);
    yield takeLatest(types.GET_VISITOR_PASS_DATA, getVisitorPassData);

}