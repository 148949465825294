import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import Layout from "../../../../pages/Layout/Layout";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import { useDispatch } from "react-redux";
import { getManagingCommitteeAuditLog } from "../../../../redux/actions/SetupRecordsActions/StaffManagementActions/ManangingCommitteeAction";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader/Loader";
import moment from "moment";
import { validateDate } from "../../../../utils/helpers/universalFunctions";

const Accordion = React.memo(
  ({ data, isOpen, toggleAccordion, label, descr, desc }) => {

    return (
      <div className="border rounded-lg border-[#CCCCCC] my-2 overflow-hidden">
        <div
          className="flex justify-between cursor-pointer items-center bg-[#FDF2F3] rounded-t-lg p-3 border-b "
          onClick={toggleAccordion}
        >
          <div className="flex items-center">
            <button className="text-white text-sm font-semibold mr-3 w-5 h-5 bg-red-650 border rounded flex justify-center items-center">
              {isOpen ? (
                <FontAwesomeIcon icon={faChevronUp} size="xs" />
              ) : (
                <FontAwesomeIcon icon={faChevronDown} size="xs" />
              )}
            </button>
            <div className="text-[#000000] text-sm font-semibold">{label}</div>
          </div>
          <div>
            <div className="text-xs text-right text-[#888]">{desc}</div>
            <div className="text-xs text-right text-[#888]">{descr}</div>
          </div>
        </div>
        {isOpen && (
          <>
            <div className="grid grid-cols-5 w-full text-sm p-2 font-bold">
              <div>{' '}</div>
              <div className="grid grid-cols-2 col-span-4">
                <div>Old Value</div>
                <div>New Value</div>
              </div>
            </div>
            {
              data?.changedFields?.includes('title') &&
              <div className="grid grid-cols-5 w-full text-sm p-2">
                <div>{"Committee Name"}</div>
                <div className="grid grid-cols-2 col-span-4">
                  <div>{data?.old_data?.title?.title || '-'}</div>
                  <div>{data?.new_data?.title?.title || '-'}</div>
                </div>
              </div>
            }
            {
              !!data?.changedFields?.replace('title', '')?.length &&
              <div className="grid grid-cols-5 w-full text-sm p-2">
                <div>{"Members"}</div>
                <div className="col-span-4">
                    {
                      data?.changedFields?.replace('title,', '')?.split(',')?.map((item, index)=>(
                        <div className="grid grid-cols-2 mb-7">
                          <div>
                            {
                              validateDate(data?.old_data?.join_date?.[item]) ?
                              <>
                                <div><span className="font-semibold">Member Name: </span>{data?.old_data?.name?.[item] || '-'} <span></span></div>
                                <div><span className="font-semibold">Role: </span>{data?.old_data?.roles?.[item] || '-'} <span></span></div>
                                <div><span className="font-semibold">Join Date: </span>{validateDate(data?.old_data?.join_date?.[item]) ? moment(new Date(data?.old_data?.join_date[item]))?.format('DD-MM-YYYY') : '-'} <span></span></div>
                                <div><span className="font-semibold">Leave Date: </span>{validateDate(data?.old_data?.leave_date?.[item]) ? moment(new Date(data?.old_data?.leave_date[item]))?.format('DD-MM-YYYY') : '-'} <span></span></div>
                              </>
                              :
                              <div>N/A</div>
                            }
                          </div>
                          <div>
                            {
                              validateDate(data?.new_data?.join_date?.[item]) ?
                              <>
                                <div><span className="font-semibold">Member Name: </span>{data?.new_data?.name?.[item] || '-'} <span></span></div>
                                <div><span className="font-semibold">Role: </span>{data?.new_data?.roles?.[item] || '-'} <span></span></div>
                                <div><span className="font-semibold">Join Date: </span>{validateDate(data?.new_data?.join_date?.[item]) ? moment(new Date(data?.new_data?.join_date[item]))?.format('DD-MM-YYYY') : '-'} <span></span></div>
                                <div><span className="font-semibold">Leave Date: </span>{validateDate(data?.new_data?.leave_date?.[item]) ? moment(new Date(data?.new_data?.leave_date[item]))?.format('DD-MM-YYYY') : '-'} <span></span></div>
                              </>
                              :
                              <div>N/A</div>
                            }
                          </div>
                        </div>
                      ))
                    }
                  </div>
              </div>
            }
          
          </>
        )}
      </div>
    );
  }
);

const CommitteeDetailsMembersAudit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {id} = useParams()
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const {loading, managingCommitteeAuditLog} = useSelector(state=> state.managingCommitteeReducer)

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const toggleAccordion = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(()=>{
    dispatch(getManagingCommitteeAuditLog({committeeId: id}))
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <ListHeaderBack
          onClick={handleNavigation(-1)}
          title="Committee Details Members Audit Logs"
        ></ListHeaderBack>
        <div className="border-b border-[#CCCCCC]"></div>
        {
          loading && id !== managingCommitteeAuditLog?.[0]?.committeeid?.toString() ?
          <Loader/>:
          <>
          {
            !!managingCommitteeAuditLog?.length ?
            <div>
              {
                managingCommitteeAuditLog?.map((item, index)=>(
                  <Accordion
                    label={`Audit ${(index+1)?.toString()?.padStart(2, '0')}`}
                    key={index}
                    data={item}
                    isOpen={index === openAccordionIndex}
                    toggleAccordion={() => toggleAccordion(index)}
                    desc={`Modified By ${item?.modified_by} On ${moment(item?.modified_dateTime)?.format('MMM DD, YYYY hh:mm A')}`}
                    descr={`Ip Address: ${item?.IpAddress}. Session id - ${item?.modified_sessionId}`}
                  />
                ))
              }
            </div>
            :
            <div className="text-sm text-[#AAAAAA] my-4 text-center">
              No Records
            </div>
          }
          </>
        }
      </div>
    </Layout>
  );
};

export default CommitteeDetailsMembersAudit;
