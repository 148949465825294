import React from "react";
import { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import { DeleteIcon } from "../../../../assets";
import { FieldArray } from "formik";
import { DropdownFM2 } from "../../../../components/Dropdown/dropdown2";
import { societyStaffCurrentStatusptions } from "../../../../consts/DropDownOptions";
import { useSelector } from "react-redux";

const TransferFlatBoxForm = ({values, setFieldValue}) => {
  const {wingsFlatData} = useSelector(state=>state.staffPersonnelReducer)

  return (
    <div>
      <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg mt-3">
        <div className=" border-b border-[#CCC] pb-2 bg-[#FDF2F3]">
          <h1 className="text-base items-center p-2 -mb-2 ml-1">Transferred To:</h1>
        </div>
        <div className=" p-2">
          <FieldArray
            name="flatData"
            render={arrayHelpers => (
              <div className="p-2 flex flex-wrap gap-2">
                {values.flatData && values.flatData.length > 0 && (
                  values.flatData.map((landlord, index) => (
                  <div className="flex items-start gap-5" key={index}>
                    <div className="flex justify-between gap-5 ">
                      <span className="text-sm mt-2">Flat Number</span>
                      <DropdownFM2
                          options={wingsFlatData?.map((item)=>({
                            value: item?.flat_id,
                            label: item?.wing_flat
                          })) || []}
                          width={"176px"}
                          className="text-xs"
                          placeholder="Select Flat"
                          onSelect={(selectedItem)=>{
                            setFieldValue(`flatData.${index}.flatId`, selectedItem.value)
                          }}
                          height='36px'
                          name={`flatData.${index}.flatId`}
                          {...(values.flatData[index].flatId==='' && {value: null})}
                        />
                    </div>
                    <div className="flex justify-between gap-1">
                      <span className="text-sm mt-2">Joining Date</span>
                      <DatePickerComponentFM
                        className="w-[176px] h-9 ml-4 justify-between"
                        onDateChange={(selectedDate) => {
                          setFieldValue(`flatData.${index}.joinDate`, selectedDate)
                        }}
                        name={`flatData.${index}.joinDate`}
                        defaultValue={values.flatData[index].joinDate ? new Date(values.flatData[index].joinDate) : null}
                      />
                    </div>
                    <div className="flex justify-between gap-5">
                      <span className="text-sm mt-2">Current Status</span>
                      <DropdownFM2
                          options={societyStaffCurrentStatusptions}
                          width={"176px"}
                          className="text-xs"
                          placeholder="Select Status"
                          onSelect={(selectedItem)=>{
                            setFieldValue(`flatData.${index}.status`, selectedItem.value)
                          }}
                          height='36px'
                          name={`flatData.${index}.status`}
                          {...(values.flatData[index].status==='' && {value: null})}
                        />
                    </div>
                    <div className="flex mt-1">
                      {
                        // values.flatData?.length >1 &&
                        <button
                          className="fill-red-650 p-2"
                          onClick={() => {arrayHelpers.remove(index)}}
                          type="button"
                        >
                          <DeleteIcon />
                        </button>
                      }
                    </div>
                  </div>
                ))
              )}
              <div>
                <button
                type="button"
                  className="text-red-650 font-semibold border-2 border-dashed border-red-650 w-28 text-xs whitespace-nowrap h-9 p-2 mb-4 bg-[#FDF6F6] flex justify-center rounded-md"
                  onClick={() =>
                    arrayHelpers.push({
                      flatId: '',
                      joinDate: '',
                      status: '',
                    })
                  }
                >
                  Add More
                </button>
              </div>
              </div>
            )}
            ></FieldArray>
        </div>
      </div>
    </div>
  );
};

export default TransferFlatBoxForm;
