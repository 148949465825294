import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../../Layout/Layout";
import {
  TENANTS_INFO_ENDPOINT,
  VIEW_TENANTS_DETAILS_ENDPOINT,
} from "../../../../Routing/routes";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import { useDispatch, useSelector } from "react-redux";
import { getTenantsDetails } from "../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/TenantsInfoAction";
import Loader from "../../../../components/Loader/Loader";
import moment from "moment";
import { validateDate } from "../../../../utils/helpers/universalFunctions";

const ViewTenantDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, tenantDetails } = useSelector(
    (state) => state.tenantInfoReducer
  );

  const handleBackClick = () => {
    navigate(TENANTS_INFO_ENDPOINT);
  };

  useEffect(() => {
    dispatch(getTenantsDetails({ tenantId: id }));
  }, []);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack
            onClick={handleBackClick}
            title="View Tenant Details"
          ></ListHeaderBack>
        </div>
        {loading && id !== tenantDetails?.tenant_id?.toString() ? (
          <Loader />
        ) : (
          <table className="border-separate border-spacing-y-3 ms-2">
            <tr className="text-sm">
              <td className="text-[rgb(170,170,170)] font-semibold w-40 align-top">
                Tenant Name:
              </td>
              <td>
                {tenantDetails?.usr_fname +
                  " " +
                  tenantDetails?.usr_mname +
                  " " +
                  tenantDetails?.usr_lname || "-"}
              </td>
            </tr>
            <tr className="text-sm">
              <td className="text-[rgb(170,170,170)] font-semibold w-40 align-top">
                Email Address:
              </td>
              <td>{tenantDetails?.email || "-"}</td>
            </tr>
            <tr className="text-sm">
              <td className="text-[rgb(170,170,170)] font-semibold w-40 align-top">
                Contact No.:
              </td>
              <td>
                {tenantDetails?.contact_no
                  ? "+" +
                    tenantDetails?.mobile_country_code +
                    "-" +
                    tenantDetails?.contact_no
                  : "-"}
              </td>
            </tr>
            <tr className="text-sm">
              <td className="text-[rgb(170,170,170)] font-semibold w-40 align-top">
                Alternate Contact No.:
              </td>
              <td>
                {tenantDetails?.alternate_contact_no
                  ? "+" +
                    tenantDetails?.mobile_country_code +
                    "-" +
                    tenantDetails?.alternate_contact_no
                  : "-"}
              </td>
            </tr>
            <tr className="text-sm">
              <td className="text-[rgb(170,170,170)] font-semibold w-40 align-top">
                Address:
              </td>
              <td>{tenantDetails?.address || "-"}</td>
            </tr>
            <tr className="text-sm">
              <td className="text-[rgb(170,170,170)] font-semibold w-40 align-top">
                Wing & Flat:
              </td>
              <td>{tenantDetails?.wing_no + " - " + tenantDetails?.flat_no}</td>
            </tr>
            <tr className="text-sm">
              <td className="text-[rgb(170,170,170)] font-semibold w-40 align-top">
                Rent Deed Start Date:
              </td>
              <td>
                {validateDate(tenantDetails?.start_date)
                  ? moment(new Date(tenantDetails?.start_date)).format(
                      "DD-MM-YYYY"
                    )
                  : "-"}
              </td>
            </tr>
            <tr className="text-sm">
              <td className="text-[rgb(170,170,170)] font-semibold w-40 align-top">
                Rent Deed End Date:
              </td>
              <td>
                {validateDate(tenantDetails?.end_date)
                  ? moment(new Date(tenantDetails?.end_date)).format(
                      "DD-MM-YYYY"
                    )
                  : "-"}
              </td>
            </tr>
            <tr className="text-sm">
              <td className="text-[rgb(170,170,170)] font-semibold w-40 align-top">
                Rent Agreement:
              </td>
              <td>
                {tenantDetails?.rent_agreement ? (
                  <span>
                    Yes
                    <Link
                      target="_blank"
                      className="ms-1 underline text-xs text-blue-500"
                      to={tenantDetails?.rentAgreementURL}
                    >
                      View Uploaded Doc
                    </Link>
                  </span>
                ) : (
                  "No"
                )}
              </td>
            </tr>
            <tr className="text-sm">
              <td className="text-[rgb(170,170,170)] font-semibold w-40 align-top">
                Identity Proof No.:
              </td>
              <td>
                {tenantDetails?.uidcard_no || "-"}{" "}
                {tenantDetails?.aadhar_card && (
                  <Link
                    target="_blank"
                    className="ms-1 underline text-xs text-blue-500"
                    to={tenantDetails?.aadharCardURL}
                  >
                    View Uploaded Doc
                  </Link>
                )}
              </td>
            </tr>
            <tr className="text-sm">
              <td className="text-[rgb(170,170,170)] font-semibold w-40 align-top">
                Pan Card No.:
              </td>
              <td>{tenantDetails?.pancard_no || "-"}</td>
            </tr>
            <tr className="text-sm">
              <td className="text-[rgb(170,170,170)] font-semibold w-40 align-top">
                GST Registration No.:
              </td>
              <td>{tenantDetails?.gst_registration_no || "-"}</td>
            </tr>
          </table>
        )}
      </div>
    </Layout>
  );
};

export default ViewTenantDetails;
