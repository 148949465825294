import React, { useState, useEffect } from "react";
import Modal from "../Modal/Modal";
import { TextInput } from "../InputFields/TextInput";
import Checkbox from "../Checkbox/Checkbox";
const EditCategoryModal = ({
  isOpen,
  onClose,
  categoryToEdit,
  onEditCategory,
}) => {
  const [editedCategoryName, setEditedCategoryName] = useState("");
  const [isSpecialCategory, setIsSpecialCategory] = useState(false);

  useEffect(() => {
    if (categoryToEdit) {
      setEditedCategoryName(categoryToEdit.name);
      setIsSpecialCategory(categoryToEdit.isSpecial);
    }
  }, [categoryToEdit]);

  const handleSaveCategory = () => {
    onEditCategory(categoryToEdit.id, editedCategoryName, isSpecialCategory);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Edit Category"
      onCancel={onClose}
      onConfirm={handleSaveCategory}
    >
      <div className="flex flex-col items-center gap-2">
        <div className="flex flex-col justify-between py-2">
          <TextInput
            label="Category:"
            placeholder="Enter Category"
            className="ml-2 w-52"
            onChange={(e) => setEditedCategoryName(e.target.value)}
          />
        </div>
        <div className="pl-12 -mt-4">
          <label className="flex items-center justify-start text-sm">
            <Checkbox text="Is this a special category" />
          </label>
        </div>
      </div>
    </Modal>
  );
};

export default EditCategoryModal;
