import * as type from "../../../actions/index.types";

export function manageInvoiceReducer(
  state = {
    loading: false,
    updateLoader: false,
    manageInvoiceCurrent: null,
    manageInvoicePrevious: null,
    manageInvoiceDraft: null,
    manageInvoiceActionsList: null,
    adjustmentPaymentDetails: null,
    adjustmentPaymentGetAmount: null,
    adjustmentPaymentSave: null,
    excelInvoiceData: null,
    editInvoiceDetails: null,
    verifyPostPaymentDetails: null,
    error: null,
  },
  action
) {
  switch (action.type) {
    case type.GET_MANAGE_INVOICE_CURRENT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_MANAGE_INVOICE_CURRENT_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        manageInvoiceCurrent: action.payload,
      };
    case type.GET_MANAGE_INVOICE_CURRENT_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        manageInvoiceCurrent: null,
      };

    case type.GET_MANAGE_INVOICE_DRAFT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_MANAGE_INVOICE_DRAFT_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        manageInvoiceDraft: action.payload,
      };
    case type.GET_MANAGE_INVOICE_DRAFT_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        manageInvoiceDraft: null,
      };

    case type.GET_MANAGE_INVOICE_PREVIOUS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_MANAGE_INVOICE_PREVIOUS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        manageInvoicePrevious: action.payload,
      };
    case type.GET_MANAGE_INVOICE_PREVIOUS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        manageInvoicePrevious: null,
      };

    case type.SINGLE_INVOICE_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.SINGLE_INVOICE_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        singleInvoice: action.payload,
      };
    case type.SINGLE_INVOICE_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        singleInvoice: null,
      };

    case type.GET_MANAGE_INVOICE_ACTIONS_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_MANAGE_INVOICE_ACTIONS_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        manageInvoiceActionsList: action.payload,
      };
    case type.GET_MANAGE_INVOICE_ACTIONS_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        manageInvoiceActionsList: null,
      };

    case type.ADJUSTMENT_PAYMENT_DETAILS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.ADJUSTMENT_PAYMENT_DETAILS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        adjustmentPaymentDetails: action.payload,
      };
    case type.ADJUSTMENT_PAYMENT_DETAILS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        adjustmentPaymentDetails: null,
      };

    case type.ADJUSTMENT_PAYMENT_GET_AMOUNT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.ADJUSTMENT_PAYMENT_GET_AMOUNT_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        adjustmentPaymentGetAmount: action.payload,
      };
    case type.ADJUSTMENT_PAYMENT_GET_AMOUNT_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        adjustmentPaymentGetAmount: null,
      };

    case type.ADJUSTMENT_PAYMENT_SAVE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.ADJUSTMENT_PAYMENT_SAVE_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        adjustmentPaymentSave: action.payload,
      };
    case type.ADJUSTMENT_PAYMENT_SAVE_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        adjustmentPaymentSave: null,
      };

    case type.UPLOAD_MANAGE_INVOICE_PAYMENT_EXCEL:
      return {
        ...state,
        uploadLoader: true,
        error: null,
      };
    case type.UPLOAD_MANAGE_INVOICE_PAYMENT_EXCEL_SUCCESS:
      return {
        ...state,
        error: action.error,
        uploadLoader: false,
        excelInvoiceData: action.payload,
      };
    case type.UPLOAD_MANAGE_INVOICE_PAYMENT_EXCEL_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        uploadLoader: false,
        excelInvoiceData: null,
      };

    case type.SAVE_MANAGE_INVOICE_PAYMENT_EXCEL:
      return {
        ...state,
        uploadLoader: true,
      };
    case type.SAVE_MANAGE_INVOICE_PAYMENT_EXCEL_SUCCESS:
      return {
        ...state,
        uploadLoader: false,
      };
    case type.SAVE_MANAGE_INVOICE_PAYMENT_EXCEL_FAILURE:
      return {
        ...state,
        uploadLoader: false,
      };
    case type.GET_EDIT_INVOICE_DETAILS:
      return {
        ...state,
        loading: true,
        error: null,
        editInvoiceDetails : null
      };
    case type.GET_EDIT_INVOICE_DETAILS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        editInvoiceDetails: JSON.parse(action.payload),
      };
    case type.GET_EDIT_INVOICE_DETAILS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        editInvoiceDetails: null,
      };

    case type.EDIT_INVOICE_DETAILS:
      return {
        ...state,
        uploadLoader: true,
      };
    case type.EDIT_INVOICE_DETAILS_SUCCESS:
      return {
        ...state,
        uploadLoader: false,
      };
    case type.EDIT_INVOICE_DETAILS_FAILURE:
      return {
        ...state,
        uploadLoader: false,
      };

    case type.UPDATE_INVOICE_REMARKS:
      return {
        ...state,
        uploadLoader: true,
      };
    case type.UPDATE_INVOICE_REMARKS_SUCCESS:
      return {
        ...state,
        uploadLoader: false,
      };
    case type.UPDATE_INVOICE_REMARKS_FAILURE:
      return {
        ...state,
        uploadLoader: false,
      };

    case type.UPLOAD_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        uploadLoader: false,
        excelInvoiceData: null,
      };

    case type.VERIFY_POST_PAYMENT_DETAILS:
      return {
        ...state,
        uploadLoader: true,
        error: null,
      };
    case type.VERIFY_POST_PAYMENT_DETAILS_SUCCESS:
      return {
        ...state,
        error: null,
        uploadLoader: false,
        verifyPostPaymentDetails: JSON.parse(action.payload),
      };
    case type.VERIFY_POST_PAYMENT_DETAILS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        uploadLoader: false,
        verifyPostPaymentDetails: null,
      };

    case type.VERIFY_POST_PAYMENT_UPDATE:
      return {
        ...state,
        uploadLoader: true,
      };
    case type.VERIFY_POST_PAYMENT_UPDATE_SUCCESS:
      return {
        ...state,
        uploadLoader: false,
      };
    case type.VERIFY_POST_PAYMENT_UPDATE_FAILURE:
      return {
        ...state,
        uploadLoader: false,
      };
    
      case type.BULK_VERIFY_POST_PAYMENT_UPDATE:
        return {
          ...state,
          uploadLoader: true,
        };
      case type.BULK_VERIFY_POST_PAYMENT_UPDATE_SUCCESS:
        return {
          ...state,
          uploadLoader: false,
        };
      case type.BULK_VERIFY_POST_PAYMENT_UPDATE_FAILURE:
        return {
          ...state,
          uploadLoader: false,
        };

    case type.SEND_INVOICE_REMINDER_EMAIL_SMS:
      return {
        ...state,
        uploadLoader: true,
      };
    case type.SEND_INVOICE_REMINDER_EMAIL_SMS_SUCCESS:
      return {
        ...state,
        uploadLoader: false,
      };
    case type.SEND_INVOICE_REMINDER_EMAIL_SMS_FAILURE:
      return {
        ...state,
        uploadLoader: false,
      };

    case type.SEND_RESEND_RECEIPT_EMAIL_SMS:
      return {
        ...state,
        uploadLoader: true,
      };
    case type.SEND_RESEND_RECEIPT_EMAIL_SMS_SUCCESS:
      return {
        ...state,
        uploadLoader: false,
      };
    case type.SEND_RESEND_RECEIPT_EMAIL_SMS_FAILURE:
      return {
        ...state,
        uploadLoader: false,
      };
    case type.SEND_INVOICE_EMAIL_SMS:
      return {
        ...state,
        uploadLoader: true,
      };
    case type.SEND_INVOICE_EMAIL_SMS_SUCCESS:
      return {
        ...state,
        uploadLoader: false,
      };
    case type.SEND_INVOICE_EMAIL_SMS_FAILURE:
      return {
        ...state,
        uploadLoader: false,
      };
    case type.REJECT_INVOICE:
      return {
        ...state,
        uploadLoader: true,
      };
    case type.REJECT_INVOICE_SUCCESS:
      return {
        ...state,
        uploadLoader: false,
      };
    case type.REJECT_INVOICE_FAILURE:
      return {
        ...state,
        uploadLoader: false,
      };

    default:
      return state;
  }
}
