import { call, put, takeLatest } from 'redux-saga/effects'
import {notify} from '../../../../utils/notification'
import * as types from '../../../actions/index.types'
import * as req from '../../../../axios/request/FinanceReqs/SettingReqs/InvoiceDetailsReqs'

function* getInvoiceDetails(action) {
    try {
        const result = yield call(req.getInvoiceDetailsReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_INVOICE_DETAILS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_INVOICE_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateInvoiceDetails(action) {
    try {
        const result = yield call(req.updateInvoiceDetailssReq, action);
        if (result.success === true) {
            notify('success', result.message)
            yield put({ type: types.UPDATE_INVOICE_DETAILS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.UPDATE_INVOICE_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}


export default function* invoiceDetailsSaga() {
    yield takeLatest(types.GET_INVOICE_DETAILS, getInvoiceDetails);
    yield takeLatest(types.UPDATE_INVOICE_DETAILS, updateInvoiceDetails);
}