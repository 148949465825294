import React, { useEffect } from "react";
import Search from "../../../components/SearchBox/Search";
import GenericTable from "../../../components/TableComponent/Table";
import Layout from "../../Layout/Layout";
import RangeDatePicker from "../../../components/DatePicker/RangeDatePicker";
import Dropdown2 from "../../../components/Dropdown/dropdown2";
import moment from "moment";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import { useDispatch } from "react-redux";
import { getIncompleteTransaction } from "../../../redux/actions/FinanceActions/BillingReportsAction";
import { useSelector } from "react-redux";
import { validateDate } from "../../../utils/helpers/universalFunctions";
import IncompleteTransactionsTable from "./IncompleteTransactionTable";
import { INCOMPLETE_TRANSACTIONS_ENDPOINT } from "../../../Routing/routes";

export const headCells = [
  { id: "id", label: "S No.", width: "80px" },
  { id: "date", label: "Date", sort: false, width: "90px" },
  { id: "unitNumber", label: "Unit", sort: true, width: "130px" },
  { id: "type", label: "Type", sort: true, width: "90px" },
  { id: "number", label: "Number", sort: true, width: "200px" },
  { id: "status", label: "Status", sort: true, width: "140px" },
  { id: "amount", label: "Amount", sort: true, width: "160px" },
  { id: "", label: "Action", sort: false },
];

const rows = [];

const IncompleteTransactions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, incompleteTransaction } = useSelector(
    (state) => state.billingReportsReducer
  );

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleSearch = (text) => {
    if (text && text !== searchParams.get("transactionid")) {
      searchParams.delete("page");
      searchParams.delete("transactionid");
      navigate({
        pathname: INCOMPLETE_TRANSACTIONS_ENDPOINT,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["transactionid", text?.trim()],
        ])}`,
      });
    }
  };

  const handleSelectMode = (selectedItem)=>{    
    console.log(selectedItem);
    
    if(selectedItem.value?.toString() !== searchParams.get("modeOfPayment")){
      searchParams.delete('page')
      searchParams.delete('pageLimit')
      searchParams.delete("modeOfPayment")
      navigate({
        pathname: INCOMPLETE_TRANSACTIONS_ENDPOINT,
        search: `?${createSearchParams([...searchParams.entries(), ["modeOfPayment", selectedItem.value]])}`,
      })
    }
  }

  const handleStartDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !==
      searchParams.get("fromDate")
    ) {
      searchParams.delete("fromDate");
      navigate({
        pathname: INCOMPLETE_TRANSACTIONS_ENDPOINT,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["fromDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleEndDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !== searchParams.get("toDate")
    ) {
      searchParams.delete("toDate");
      navigate({
        pathname: INCOMPLETE_TRANSACTIONS_ENDPOINT,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["toDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const getTableData = () => {
    const data = {
      societyId: loginData?.default_community?.community_id,
      transactionId: searchParams.get("transactionid") || '',
      modeOfPayment: searchParams.get("modeOfPayment") || '',
      fromDate: searchParams.get("fromDate") || '',
      toDate: searchParams.get("toDate") || '',
      page: searchParams.get("page") || 1,
      pageLimit: searchParams.get("pageLimit") || 10,
    }
    dispatch(getIncompleteTransaction(data))
  }

  useEffect(() => {
    getTableData()
  }, [searchParams])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack title="Incomplete Transactions" onClick={handleNavigation(-1)}>
            <Dropdown2
              options={[
                { value: "Cheque", label: "Cheque" },
                { value: "NEFT", label: "NEFT" },
                { value: "Cash", label: "Cash" },
                { value: "Online", label: "Online" },
              ]}
              width="190px"
              height="8"
              placeholder="Mode of Payment"
              onSelect={(selectedItem) => { 
                handleSelectMode(selectedItem)
              }}
            />
          </ListHeaderBack>
        </div>

        <div className="py-1 flex justify-between gap-2 my-1">
          <div className="flex gap-3">
            <Search height="8" width="48" placeholder="Search By Transaction No." onclick={handleSearch} value={searchParams.get('transactionid')} />
          </div>
          <div className="flex items-center gap-2">
            <RangeDatePicker
              className={"w-[190px] h-[32px]"}
              defaultStartValue={
                searchParams.get("fromDate")
                  ? new Date(searchParams.get("fromDate"))
                  : ""
              }
              defaultEndValue={
                searchParams.get("toDate")
                  ? new Date(searchParams.get("toDate"))
                  : ""
              }
              onStartDateChange={handleStartDate}
              onEndDateChange={handleEndDate}
            />
          </div>
        </div>
        <div className="ml-1">
          <IncompleteTransactionsTable
            loading={loading}
            data={incompleteTransaction?.data?.map((item, index) => ({
              //sNo: (((incompleteTransaction?.current_page_no - 1) * incompleteTransaction?.page_limit + 1)+index)?.toString()?.padStart(2, "0"),
              id: item?.enc_key,
              sNo: (index + 1)?.toString()?.padStart(2, '0'),
              date: validateDate(item?.Date) ? moment(item?.Date, 'YYYY-MM-DD hh:mm:ss').format('DD-MM-YYYY') : '-',
              unitNumber: item?.Unit || '-',
              type: item?.Type || '-',
              transactionid: item?.Number || '-',
              amount: item?.Amount || '-',
              status: item?.Status || '-',
            }))}
            columns={headCells}
            checkbox={false}
            pagination={incompleteTransaction?.data?.length}
            totalCount={incompleteTransaction?.paginate?.total_count}
            totalPages={incompleteTransaction?.paginate?.total_page}
            start={(incompleteTransaction?.paginate?.current) * incompleteTransaction?.paginate?.limit + 1}
            end={(incompleteTransaction?.paginate?.current) * incompleteTransaction?.paginate?.limit + incompleteTransaction?.data?.length}
            currentPage={parseInt(incompleteTransaction?.paginate?.current) + 1}
            rowsPerPage={searchParams.get("pageLimit") || 10}
            getTableData={getTableData}
          ></IncompleteTransactionsTable>
        </div>

        {!incompleteTransaction?.data?.length && !loading && (
          <div className="text-lg text-[#AAAAAA] mt-10 text-center italic">
            No Records
          </div>
        )}
      </div>
    </Layout>
  );
};

export default IncompleteTransactions;
