import React, { useState } from "react";
import Modal from "../Modal/Modal";
import { TextInput } from "../InputFields/TextInput";
import Checkbox from "../Checkbox/Checkbox";

const AddCategoryModal = ({
  isOpen,
  onClose,
  onAddCategory,
  onEditCategory,
  categoryToEdit,
}) => {
  const isEditing = !!categoryToEdit;
  const [categoryName, setCategoryName] = useState(
    isEditing ? categoryToEdit.name : ""
  );
  const [isSpecialCategory, setIsSpecialCategory] = useState(
    isEditing ? categoryToEdit.isSpecial : false
  );

  const handleSaveCategory = () => {
    if (isEditing) {
      onEditCategory(categoryToEdit.id, categoryName, isSpecialCategory);
    } else {
      onAddCategory(categoryName, isSpecialCategory);
    }
    setCategoryName("");
    setIsSpecialCategory(false);
    onClose();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={isEditing ? "Edit Category" : "Add Category"}
        onCancel={onClose}
        onConfirm={handleSaveCategory}
      >
        <div className="flex flex-col items-center gap-2">
          <div className="flex flex-col justify-between py-2">
            <TextInput
              label="Category:"
              placeholder="Enter Category"
              className="ml-4 w-56"
              onChange={(e) => setCategoryName(e.target.value)}
            />
          </div>
          <div className="pl-12 -mt-4">
            <label className="flex items-center justify-start text-sm">
               <Checkbox text="Is this a special category" />
              
            </label>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddCategoryModal;
