import React from "react";
import NoticeSection from "../../components/NoticeSection/NoticeSection";
import DropBox from "../../components/Dropbox/DropBox";
import Post from "../../components/Post/Post";
import NewsAndArchieve from "../../components/NewsAndArchive/NewsAndArchieve";
import Gallery from "../../components/Gallery/Gallery";
import Layout from "./Layout";
import Banner from "../../components/Banner/Banner";

const Home = () => {
  return (
    <Layout>
      {/* <div className="flex gap-4 w-full">
        <div className="w-2/3">
          <DropBox />
          <Post />
          <Post />
        </div>
        <div className="w-1/3">
          <NoticeSection />
          <NewsAndArchieve />
          <Gallery />
        </div>
      </div> */}
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
      <div className="text-center mt-72">
        <Banner />
      </div>
      </div>
    </Layout>
  );
};

export default Home;
