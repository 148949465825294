import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import "./Table.css";
import {
  SortIcon,
} from "../../assets";
import styled from "@emotion/styled";
import Pagination from "../Pagination/Pagination";
import {
  stableSort,
  getComparator,
} from "../../utils/helpers/universalFunctions";
import {createSearchParams,useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../Loader/Loader";
import ItemsPerPage from "../ItemsPerPage/ItemsPerPage";
import { ARCHIVE_MEMBERS_OCCUPANTS_ENDPOINT } from "../../Routing/routes";

const TableRowStyled = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #ffffff;
  }
  &:nth-of-type(even) {
    background-color: #f4f5f8;
  }
  &:nth-of-type(odd):hover {
    background-color: #ffffff;
  }
  &:nth-of-type(even):hover {
    background-color: #f4f5f8;
  }
`;

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    zIndex: 0,
    color: "#AAAAAA",
    fontSize: "13px",
    padding: '12px 0 12px 8px',
    wordBreak: "break-word",
    fontSize: "13px",
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
    fontSize: "13px",
    padding: '12px 0 12px 8px',
    wordBreak: "break-word",
    fontSize: "13px",
    cursor: "pointer"
  },
}));

const ArchiveOccupantsTable = ({
  loading,
  data,
  columns,
  checkbox = true,
  pagination = true,
  //props are required if pagination is true
  totalCount,
  totalPages,
  start,
  end,
  currentPage,
  rowsPerPage,
  getTableData
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);

  const [selected, setSelected] = useState([]);


  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    const newSelected = event.target.checked ? data?.map((item) => item.subscriberId) : [];
    setSelected(newSelected);
  };

  const handleClick = (event, id) => {
    const newSelected = selected.includes(id)
      ? selected.filter((selectedId) => selectedId !== id)
      : [...selected, id];
    setSelected(newSelected);
  };

  const isSelected = (id) => selected.includes(id);

  const handleChangePage = (newPage) => {
    searchParams.delete("page")
    navigate({
      pathname: `${ARCHIVE_MEMBERS_OCCUPANTS_ENDPOINT}`,
      search: `?${createSearchParams([...searchParams.entries(), ["page", newPage]])}`,
    })
  };

  const handleChangeRowsPerPage = (value) => {
    if (rowsPerPage !== value) {
      searchParams.delete("pageLimit")
      navigate({
        pathname: `${ARCHIVE_MEMBERS_OCCUPANTS_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["pageLimit", value]])}`,
      })
    }
  };

  return (
    <Box
      sx={{ width: "100%", fontSize: "13px" }}
      className="table-component"
    >{console.log(end)}
      <TableContainer
        className="border-t border-[#CCC]"
        sx={{minHeight: data?.length ? "500px" : 'none'}}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {!!checkbox && (
                <TableCellStyled
                  size="small"
                  sx={{pr: 2, width: 36 }}
                >
                  <Checkbox
                    size="small"
                    indeterminate={false}
                    checked={data?.length > 0 && selected?.length === data?.length}
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all",
                    }}
                    sx={{
                      p: 0,
                      color: "#AAAAA",
                      "&.Mui-checked": {
                        color: "#CF001C",
                      },
                    }}
                    disabled={loading || !data}
                  />
                </TableCellStyled>
              )}
              {columns?.map((column) => (
                <TableCellStyled
                  size="small"
                  key={column.id+ 'heading'}
                  align={column.numeric ? "right" : "left"}
                  sortDirection={orderBy === column.id ? order : false}
                  sx={{
                    width: column.width || "fit-content",
                    minWidth: column.width || "fit-content",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={() => {
                      if (column.sort) {
                        handleRequestSort(column.id);
                      }
                    }}
                    IconComponent={SortIcon}
                    sx={{
                      ".MuiTableSortLabel-icon": {
                        opacity: "1 !important",
                      },
                      svg: {
                        flexShrink: 0,
                      },
                    }}
                    hideSortIcon={!column.sort}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCellStyled>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {
                loading ?
                <TableRow>
                  <TableCellStyled sx={{border: 'none'}} colSpan={columns?.length+1}><Loader/></TableCellStyled>
                </TableRow>
                :
                <>
                  {!!data?.length && stableSort(data, getComparator(order, orderBy))
                    ?.map((row, rowIndex) => {
                      const isRowSelected = isSelected(row?.id);

                      return (
                        <TableRowStyled
                          key={row?.subscriberId + row?.flatId}
                          role="checkbox"
                          aria-checked={isRowSelected}
                          tabIndex={-1}
                          selected={isRowSelected}
                        >
                          {!!checkbox && (
                            <TableCellStyled size="small" sx={{zIndex: 0}} onClick={(event) => handleClick(event, row?.subscriberId)}>
                              <Checkbox
                                checked={selected?.includes(row?.subscriberId)}
                                onChange={(e) =>
                                  handleClick(e, row?.subscriberId)
                                }
                                size="small"
                                color="primary"
                                inputProps={{
                                  "aria-labelledby": `generic-table-checkbox-${row?.id}`,
                                }}
                                sx={{
                                  p: 0,
                                  color: "#AAAAA",
                                  "&.Mui-checked": {
                                    color: "#CF001C",
                                  },
                                }}

                              />
                            </TableCellStyled>
                          )}
                          {/* Serial Number */}
                          <TableCellStyled size="small" align="left" onClick={(event) => handleClick(event, row?.subscriberId)}>
                            {row.sNo}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row?.flatno}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row?.title}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left" sx={{textTransform: 'capitalize'}}>
                            {row.memberName}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left" sx={{textTransform: 'capitalize'}}>
                            {row.type}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row?.intercom} 
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row.contactNo}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row.email}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row?.archivedDate || '-'}
                          </TableCellStyled>
                        </TableRowStyled>
                      );
                    })}
                </>
              }
          </TableBody>
        </Table>
      </TableContainer>

      {!!pagination && data && (
        <div className="flex flex-row justify-between mt-3 pt-1 border-t border-[#ccc] text-[14px]">
          <ItemsPerPage
            rowsPerPage={rowsPerPage}
            onChange={handleChangeRowsPerPage}
          />
          <Pagination
            totalCount={totalCount}
            totalPages={totalPages}
            start={start}
            end={end}
            currentPage={currentPage}
            onChange={handleChangePage}
          />
        </div>
      )}
    </Box>
  );
};

ArchiveOccupantsTable.propTypes = {
  columns: PropTypes.array.isRequired,
  defaultSortField: PropTypes.string,
  rowsPerPageOptions: PropTypes.array,
};

export default ArchiveOccupantsTable;
