import { notify } from '../../../../utils/notification';
import apiEndpoints from '../../../api';
import axiosInstance from '../../../axiosInstance'
import {logOut} from '../../../../utils/helpers/logOut'
import { errorMsg } from '../../../../consts/responseMessages';



export async function getImportantContactListReq(action) {

    return axiosInstance.get(`${apiEndpoints.GET_IMPORTANT_CONTACTS_LIST_API}?society_id=${action.data?.societyId}&page_no=${action.data?.page}&page_limit=${action.data?.pageLimit}${action.data?.searchText ? `&search_text=${action.data?.searchText}`: ''}${action.data?.catId ? `&category_id=${action.data?.catId}`: ''}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data?.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)){
                logOut()
            }else if(errors.response?.data?.statusCode === 501){
                notify('warning', errors.response.data?.message);
            }
            return errors

        })
}

export async function getContactCategoryListReq(action) {

    return axiosInstance.get(`${apiEndpoints.GET_CONTACTS_CATEGORY_LIST_API}?society_id=${action.data?.societyId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data?.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)){
                logOut()
            }else if(errors.response?.data?.statusCode === 501){
                notify('warning', errors.response.data?.message);
            }
            return errors

        })
}