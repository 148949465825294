import React, { useState } from "react";
import Layout from "../../Layout/Layout";
import ListHeader from "../../../components/ListHeader/ListHeader";
import Button from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { ALBUM_PHOTOS_ENDPOINT } from "../../../Routing/routes";
import AddAlbumModal from "../../../components/Modal/AddAlbumModal";

const albums = [
  //   { title: "Create album", items: null, imgSrc: "" },
  {
    title: "Profile pictures",
    items: 11,
    imgSrc: "path_to_profile_picture.jpg",
  },
  { title: "Cover photos", items: 7, imgSrc: "path_to_cover_photo.jpg" },
  { title: "Untitled album", items: null, imgSrc: "" },
  { title: "Featured Photos", items: 15, imgSrc: "path_to_featured_photo.jpg" },
];

const PhotoGallery = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState([]);

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const [isAddAlbumModal, setAddAlbumModalOpen] = useState(false);

  const handleOpenAlbumModal = () => {
    setSelected([]);
    setAddAlbumModalOpen(true);
  };

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Photo Gallery >> Album">
            <div className="flex items-center gap-3 p-2">
              <Button label="Create Album" onClick={handleOpenAlbumModal} />
            </div>
          </ListHeader>
        </div>

        <div className="p-4 grid grid-cols-3 sm:grid-cols-3 lg:grid-cols-4 gap-4">
          {albums.map((album, index) => (
            <div key={index} className="border rounded-lg overflow-hidden">
              <div className="bg-[#CCC] h-32 flex items-center justify-center">
                {album.imgSrc ? (
                  <img
                    src={album.imgSrc}
                    alt={album.title}
                    className="h-full w-full object-cover"
                  />
                ) : (
                  <span className="text-4xl text-gray-500">+</span>
                )}
              </div>
              <div
                className="p-4 hover:underline cursor-pointer"
                onClick={handleNavigation(ALBUM_PHOTOS_ENDPOINT)}
              >
                <h3 className="text-lg font-semibold">{album.title}</h3>
                {album.items !== null && (
                  <p className="text-gray-600">{album.items} Items</p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      {isAddAlbumModal && (
        <AddAlbumModal
          isOpen={isAddAlbumModal}
          onClose={() => setAddAlbumModalOpen(false)}
        />
      )}
    </Layout>
  );
};

export default PhotoGallery;
