import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { CheckOutIcon, CheckInIcon, Checkbox } from "../../../assets";
import Loader from "../../../components/Loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import { addAcknowledgementNotice } from "../../../redux/actions/SocialAndCommunicationActions/NoticeAndCircularAction";
import { useNavigate, useParams } from "react-router-dom";
import { getFirebaseURL } from "../../../firebase/firebaseFunction";
const AddAcknowledgementNotice = () => {
  const { notice_id, user_id, user_type } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [status, setStatus] = useState(false);

  useEffect(() => {
    if (notice_id != 0 && user_id != 0 && user_type != 0) {
      dispatch(
        addAcknowledgementNotice({
          notice_id: notice_id,
          user_id: user_id,
          user_type: user_type,
          onSuccessCallback: () => {
            setStatus(true);
            setTimeout(() => {
              window.location = "/";
            }, 6000);
          },
        })
      );
    }
  }, []);

  useEffect(async () => {
    const attachment_url = await getFirebaseURL(searchParams.get("url"));
    if (attachment_url) {
      window.location = attachment_url;
    }
  }, [searchParams]);

  return (
    <>
      {console.log(status)}
      {!status ? (
        <Loader />
      ) : (
        <div className=" flex flex-wrap items-center justify-center min-h-[100vh] ">
          <div className="p-5 text-center">
            <Checkbox className="text-lg w-[70px] h-[70px] mx-auto" />
            <h2 className="lg:text-[50px] text-[40px]  mt-4 font-medium leading-normal">
              Acknowledged Successfully
            </h2>
          </div>
        </div>
      )}
    </>
  );
};

export default AddAcknowledgementNotice;
