import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Arrow } from "../../assets";
import { SOCIETY_VENDORS_ENDPOINT } from "../../Routing/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import Layout from "../../pages/Layout/Layout";
import { VendorsAuditData } from "../../consts/VendorAuditData";

const Accordion = React.memo(
  ({ data, isOpen, toggleAccordion, label, descr, desc }) => (
    <div className="border rounded-lg border-[#CCCCCC] my-2 overflow-hidden">
      <div
        className="flex justify-between cursor-pointer items-center bg-[#FDF2F3] p-3 border-b"
        onClick={toggleAccordion}
      >
        <div className="flex items-center">
          <button className="text-white text-sm font-semibold mr-3 w-5 h-5 bg-red-650 border rounded flex justify-center items-center">
            {isOpen ? (
              <FontAwesomeIcon icon={faChevronUp} size="xs" />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} size="xs" />
            )}
          </button>
          <div className="text-[#000000] text-sm font-semibold">{label}</div>
        </div>
        <div>
          <div className="text-xs text-right text-[#888888]">{desc}</div>
          <div className="text-xs text-right text-[#888888]">{descr}</div>
        </div>
      </div>
      {isOpen && (
        <div className="p-3">
          {VendorsAuditData.map((item, i0) => (
            <>
              {item.label ? (
                <div className="acord-section font-semibold text-[#222222] text-xs p-2">
                  {item.label}
                </div>
              ) : null}
              {item.values.map((rowData, i1) => (
                <>
                  <div className="flex justify-between p-2">
                    {rowData.map((fieldData, index) => (
                      <div className="flex justify-between w-1/2 text-xs">
                        <div className="w-1/2 text-[#AAAAAA]">{fieldData.label}</div>
                        <div className="w-1/2">{fieldData.value}</div>
                      </div>
                    ))}
                  </div>
                  {i0 === 0 && i1 === 5 ? <hr /> : null}
                </>
              ))}
            </>
          ))}
        </div>
      )}
    </div>
  )
);

const AuditLog = () => {
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const navigate = useNavigate();

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const toggleAccordion = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="flex gap-5 pb-2">
          <div className="flex w-1/2">
            <button
              onClick={handleNavigation(SOCIETY_VENDORS_ENDPOINT)}
              className="text-sm p-2 text-red-650 font-semibold flex gap-2 justify-center items-center"
            >
              <span>
                <Arrow />
              </span>
              Back
            </button>
            <span className="border-r border-[#CCCCCC]"></span>
            <h1 className="text-sm font-semibold p-2">Vendor Audit Logs</h1>
          </div>
        </div>
        <div className="border-b border-[#CCCCCC]"></div>
        <div>
          <Accordion
            label="LK Furniture"
            key={0}
            data={{}}
            isOpen={0 === openAccordionIndex}
            toggleAccordion={() => toggleAccordion(0)}
            desc="Modified By Synapse On Jun 27,2023 06:26 PM"
            descr="Ip Address: 182.70.189.63. Session id - sjgahkgueigfakshuerh@sjfhksj"
          />
        </div>
      </div>
    </Layout>
  );
};

export default AuditLog;
