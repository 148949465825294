import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ListHeader from "../ListHeader/ListHeader";
import Button from "../Button/Button";
import ButtonG from "../Button/ButtonG";
import Dropdown2 from "../Dropdown/dropdown2";
import Box from "@mui/material/Box";
import Modal from "react-modal";
import { TextInputFM } from "../InputFields/TextInput";
import { DropdownFM2 } from "../Dropdown/dropdown2";
import {
  getTaxableFormatList,
  addTaxableSubHead,
} from "../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";
import { notify } from "../../utils/notification";
import { Form, Formik, Field } from "formik";
import moment from "moment";
import { DatePickerComponent } from "../DatePicker/DatePicker";
import { addTaxableSubHeadValidation } from "../../validationSchema/setupRecordsSchema/invoiceSettingSchema";
import { CircularProgress } from "@mui/material";

const customStyles = {
  content: {
    width: "500px",
    height: "350px",
    minHeight: "fit-content",
    background: "#fff",
    border: "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    overflow : "visible",
  },
};
const AddTaxableSubHeadModal = ({ isOpen, onClose,id,headList,mainHead,typeId }) => {
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, addLoader } = useSelector(
    (state) => state.invoiceSettingReducer
  );


  const onSubmit = (values) => {
    console.log(values)
    dispatch(
      addTaxableSubHead({
        societyId: loginData?.default_community?.community_id,
        headId: values.mainHeadId,
        subId: values.subHeadId,
        type: values.type,
        typeId : typeId,
        active: values.active,
        onSuccessCallback: () => {
          onClose();
          dispatch(
            getTaxableFormatList({
              societyId: loginData?.default_community?.community_id,
              typeId:typeId
            })
          );
          //navigate(-1);
        },
      })
    );
  };
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
      <Formik
        initialValues={{
          mainHeadId: id,
          subHeadId: '',
          type: '',
          active: true,
        }}
        enableReinitialize
        validationSchema={addTaxableSubHeadValidation}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            headList ?
            <Form>
               <div className="bg-white border-b rounded-lg">
                <ListHeader leftContent="Add Sub Heads">
                  <span className="border-r border-[#CCC]"></span>
                  <ButtonG label="Cancel" type={"button"} onClick={onClose} />
                  <Button label={addLoader ? (
                                <CircularProgress
                                  sx={{ color: "white" }}
                                  size={17}
                                />
                              ) : (
                                "Save"
                              )} type={"submit"} disabled={addLoader || loading} />
                </ListHeader>
              </div>
              <Box sx={{ width: "100%", height: "67%", fontSize: "13px" }}>
                <div className="flex flex-wrap w-full gap-4 p-2 mt-3">
                  <table className="w-full">
                    <tbody>
                      <tr>
                        <td className="pb-2">Parent Header Name</td>
                        <td className="pb-2">
                          {mainHead.header_text }
                          <input type="hidden"  value={formik.values.mainHeadId} name="mainHeadId" />
                        </td>
                      </tr>
                      <tr>
                        <td>Head Name</td>
                        <td>
                          <DropdownFM2
                            options={headList.map((detail) => {
                              return {
                                value: detail.id,
                                label: `${detail.name}(${detail.title})`,
                                type: detail.type,
                              };
                            })}
                            value={
                              formik.values.subHeadId
                                ? {
                                    label: headList?.find((item) => item.id === formik.values.subHeadId)?.name || "",
                                    value: formik.values.subHeadId,
                                    type:  headList?.find((item) => item.id === formik.values.subHeadId)?.type || "",
                                  }
                                : null
                            }
                            onSelect={(selectedItem) => {
                              formik.setFieldValue(
                                "type",
                                selectedItem.type
                              );
                              formik.setFieldValue(
                                "subHeadId",
                                selectedItem.value
                              );
                            }}
                            name="subHeadId"
                            placeholder="Select head"
                            width="14rem"
                            height="36px"
                          />
                          <input type="hidden" value={formik.values.type} name="type" />
                        </td>
                      </tr>
                      <tr>
                        <td>Active</td>
                        <td>
                          <Field type="checkbox" name="active" className="mb-3 mr-2" />
                          Yes
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Box> 
            </Form> : <></> 
          );
        }}
      </Formik>
    </Modal>
  );
};
export default AddTaxableSubHeadModal;
