import * as types from '../../index.types';

export function getManageAdvanceList(data) {
    return {
        type: types.GET_ADVANCE_NOTE_LIST,
        data,
    };
}

export function getAdvanceNoteDetails(data) {
    return {
        type: types.GET_ADVANCE_NOTE_DETAILS,
        data,
    };
}

export function addAdvanceNote(data) {
    return {
        type: types.ADD_ADVANCE_NOTE,
        data,
    };
}

export function updateAdvanceNote(data) {
    return {
        type: types.UPDATE_ADVANCE_NOTE,
        data,
    };
}

export function deleteAdvanceNote(data) {
    return {
        type: types.DELETE_ADVANCE_NOTE,
        data,
    };
}


export function printAdvancetNoteInvoice(data) {
    return {
        type: types.PRINT_ADVANCE_NOTE_RECIEPT,
        data,
    };
}

export function getNonMemberList(data) {
    return {
        type: types.GET_NON_MEMBER_LIST,
        data,
    };
}

export function uploadAdvanceNoteExcel(data) {
    return {
        type: types.UPLOAD_ADVANCE_NOTE_EXCEL,
        data,
    };
}

export function saveAdvanceNoteExcel(data) {
    return {
        type: types.SAVE_ADVANCE_NOTE_EXCEL,
        data,
    };
}


export function downloadAdvanceExcelSample(data) {
    return {
        type: types.DOWNLOAD_ADVANCE_EXCEL_SAMPLE,
        data,
    };
}
