import React, { useEffect, useState } from "react";
import Layout from "../../../../Layout/Layout";
import { Link, createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import Search from "../../../../../components/SearchBox/Search";
import Button from "../../../../../components/Button/Button";
import ListHeader from "../../../../../components/ListHeader/ListHeader";
import {
  ADD_AGENCY_ENDPOINT,
  ADD_MAINTENANCE_STAFF_FORM_ENDPOINT,
  AGENCY_ENDPOINT,
  MAINTENANCE_STAFF_LIST_ENDPOINT,
} from "../../../../../Routing/routes";

import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { deleteMaintenanceStaffOrAgency, getAgencyCategoryList, getAgencyList } from "../../../../../redux/actions/SetupRecordsActions/StaffManagementActions/MaintenanceAgencyAction";
import Dropdown2 from "../../../../../components/Dropdown/dropdown2";
import AgencyTable from "./AgencyTable";
import * as permissions from '../../../../../Routing/permissions'
import { getPermission } from "../../../../../utils/helpers/getPermissions";
import ButtonG from "../../../../../components/Button/ButtonG";

export const headCellsAgency = [
  { id: "sNo", label: "S No.", width: "60px" },
  {
    id: "agencyName",
    label: "Agency Name",
    sort: false,
    width: "200px",
  },
  {
    id: "category",
    label: "Category Of Services",
    sort: false,
    width: "200px",
  },
  {
    id: "contact",
    label: "Contact Office",
    sort: false,
    width: "200px",
  },
  {
    id: "mobile",
    label: "Mobile No.",
    sort: false,
    width: "190px",
  },
  { id: "action", label: "Action" },
];


const Agency = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading, updateLoader, agencyList, agencyCategoryList} = useSelector(state=>state.agencyReducer)
  const {ipAddress} = useSelector(state=>state.ipAddressReducer)
  const [selected, setSelected] = useState([]);
  const [action, setAction] = useState('');

  const handleSearch = (text)=>{
    if(text && text !== searchParams.get("search")){
      searchParams.delete("search")
      navigate({
        pathname: `${AGENCY_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["search", text?.trim()]])}`,
      })
    }
  }

  
  const handelSelectAgencyCat = (selectedItem)=>{
    if(selectedItem.value?.toString() !== searchParams.get("agencyCatId")){
        searchParams.delete("agencyCatId")
        navigate({
          pathname: `${AGENCY_ENDPOINT}`,
          search: `?${createSearchParams([...searchParams.entries(), ["agencyCatId", selectedItem.value]])}`,
        })
    }
  }

  const handleClear = () => {
    navigate(AGENCY_ENDPOINT);
  };

  
  const handleApply = ()=>{
    if(action === 'Delete'){
      let data = {
        ids: selected, 
        sessionId: loginData?.session_id,
        societyId: loginData?.default_community?.community_id,
        searchText: searchParams.get("search") || '',
        agencyId: searchParams.get("agencyCatId") || '',
        flag: 'agency',
        ipAddress,
        onSuccessCallback: ()=>{
          setSelected([]);
        }
      }
      dispatch(deleteMaintenanceStaffOrAgency(data))
    }
  }


  const getTableData =() =>{
    const data={
      societyId: loginData?.default_community?.community_id,
      searchText: searchParams.get("search") || '',
      agencyCatId: searchParams.get("agencyCatId") || '',
    }

    dispatch(getAgencyList(data))
  }

  useEffect(()=>{
    dispatch(getAgencyCategoryList())
  }, [])

  useEffect(()=>{      
    getTableData()
  }, [searchParams])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Agency">
            <div className="flex text-sm gap-3">
              {
                getPermission(loginData, [permissions.VIEW_STAFF_PERMISSION]) &&
                <Link to={MAINTENANCE_STAFF_LIST_ENDPOINT}>
                  <Button label="Maintenance Staff" className="h-8" />
                </Link>
              }
              {
                getPermission(loginData, [permissions.ADD_STAFF_PERMISSION]) &&
                <Link to={ADD_MAINTENANCE_STAFF_FORM_ENDPOINT}>
                  <Button label="Add Maintenance Staff" className="h-8" />
                </Link>
              }
              {
                getPermission(loginData, [permissions.ADD_AGENCY_PERMISSION]) &&
                <>
                  <div className="border-r border-[#CCC]"></div>
                  <Link to={ADD_AGENCY_ENDPOINT} className="text-white">
                    <Button label="Add Agency" className="h-8" />
                  </Link>
                </>
              }
            </div>
          </ListHeader>
        </div>

        <div className="py-1 flex justify-between gap-2 my-1">
          <div className="flex">
            <Search height="8" onclick={handleSearch} value={searchParams.get("search")} placeholder="Search agency name, contact, c…" />
            <div className="ms-3 flex items-center">
              <span className="mr-4 text-[13px]">Agency</span>
              <Dropdown2
                options={agencyCategoryList?.map((item)=>({
                  value: item?.agency_cat_id,
                  label: item?.cat_name
                })) || []}
                placeholder="Select Agency Category"
                className="text-[11px] ms-4"
                width="180px"
                height="32px"
                onSelect={(selectedItem) => {
                  handelSelectAgencyCat(selectedItem)
                }}
                value={
                  searchParams.get("agencyCatId") && {
                    value: searchParams.get("agencyCatId"),
                    label: agencyCategoryList?.find((item) => item.agency_cat_id?.toString() === searchParams.get("agencyCatId"))?.cat_name
                  }
                }
              />
            </div>
            <ButtonG label="Clear" className="ml-3 h-8" onClick={handleClear} />
          </div>
          <div className="flex">
            {
              !!selected?.length &&
              <>
                <Dropdown2
                  options={[
                    { value: "Delete", label: "Delete" }
                  ]}
                  placeholder="Choose Action"
                  className="text-[11px]"
                  width="160px"
                  height="32px"
                  onSelect={(selectedItem) => {setAction(selectedItem.value)}}
                />
                <div className="ms-3">
                  <Button label={
                      <span className="w-8 inline-block">
                        {
                          updateLoader ?
                            <CircularProgress sx={{color: "white"}} size={17}/>
                            :
                            'Apply'
                          }
                      </span>
                    } 
                    className="font-semibold px-4 h-8" type='button' onClick={handleApply} disabled={!action} />
                </div>
              </>
            }
          </div>
        </div>
        <div className="ml-1">
          <AgencyTable
            loading={loading}
            data={agencyList?.list?.map((item, index)=>({
              agencyId: item?.agency_id,
              sNo: (index+1)?.toString()?.padStart(2, "0"),
              agencyName: item?.agency_name || '-',
              category: item?.cat_name || '-',
              contact: item?.contact_office  || '-',
              mobile: item?.mobile || '-'
            }))}
            columns={headCellsAgency}
            checkbox={true}
            pagination={false}
            
            getTableData={getTableData}

            selected={selected}
            setSelected={setSelected}
          ></AgencyTable>
        </div>

        {!agencyList?.list?.length && !loading && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Agency;
