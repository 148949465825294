import React, { useEffect, useState } from "react";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Layout from "../../../Layout/Layout";
import Button from "../../../../components/Button/Button";
import TimeSlotTypeTable from "../../../../components/TableComponent/TimeSlotTypeTable";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import AddTimeSlotTypeModal from "./AddTimeSlotTypeModal";
import { getTimeSlotTypeList } from "../../../../redux/actions/ClubActions/AddTimeSlotAction";

export const headCells = [
  { id: "sNo", label: "S No.", width: "80px" },
  { id: "type", label: "Type", sort: true, width: "400px" },
  { id: "action", label: "Action", width: "100px" },
];

const TimeSlotTypeList = () => {
  const [addTimeSlotType, setAddTimeSlotType] = useState(false);
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading, typeList} = useSelector(state=>state.addTimeSlotReducer)

  const handleAddTimeSlotType = () => {
    setAddTimeSlotType(true);
  };

  useEffect(()=>{
    dispatch(getTimeSlotTypeList({societyId: loginData?.default_community?.community_id}))
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <ListHeader leftContent="Manage Type">
          <div className="border-r border-[#CCC]"></div>
            <Button label="Add Type" onClick={handleAddTimeSlotType} />
        </ListHeader>
        <div className="ml-1">
          <TimeSlotTypeTable
            loading={loading}
            data={typeList?.map((item, index) => ({
              sNo: (index+1)?.toString()?.padStart(),
              type: item?.title,
              typeId: item?.id,
              societyId: item?.clubid,
              isDeleteAble: item.is_delete
            })) || []}
            columns={headCells}
            checkbox={false}
            pagination={true}
          ></TimeSlotTypeTable>
        </div>
        {!typeList?.length && !loading && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
      <AddTimeSlotTypeModal
        isOpen={addTimeSlotType}
        onCancel={() => {
          setAddTimeSlotType(false);
        }}
      />
    </Layout>
  );
};

export default TimeSlotTypeList;
