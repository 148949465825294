import * as types from '../index.types';

export function getFolderList(data) { 
    return {
        type: types.GET_DOCUMENT_FOLDER_LIST,
        data,
    };
}

export function getDocFileList(data) {
    return {
        type: types.GET_DOCUMENT_FILE_LIST,
        data,
    }
}

export function changeFolderId(data) {
    return {
        type: types.CHANGE_FOLDER_ID,
        data,
    }
}
export function addFolderName(data) {
    return {
        type: types.ADD_FOLDER_NAME,
        data
    };
}

export function updateDocumentsList(data) {
    return {
        type: types.UPDATE_DOCUMENTS_LIST,
        data
    };
}

export function getDocumentOccupantsList(data) {
    return {
        type: types.GET_DOCUMENT_OCCUPANTS_LIST,
        data,
    }
}

export function editDocumentList(data) {
    return {
        type: types.Edit_DOCUMENTS_LIST,
        data
    };
}

export function deleteDocuments(data) {
    return {
        type: types.DELETE_DOCUMENT,
        data
    };
}