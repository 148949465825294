import * as type from '../../../actions/index.types'

export function employeeManagementReducer(state = {
    loading: false,
    updateLoader: false,
    printLoader: false,
    employeeList: null,
    employeeTypeList: null,
    employeeAuditLogs: null,
    employeeDetails: null,
    employeeUserNameList: null,
    employeeNameList: null,
    existingEmployeeList: null,
    loginReportList: null,
    printLoginReportList: null,
    employeeReportRecord: null,
    printEmployeeReportRecordData: null,
    error:null
}, action) {

switch (action.type) {
    case type.GET_EMPLOYEE_LIST:
        return {
          ...state,
          loading: true,
          error: null
      }
    case type.GET_EMPLOYEE_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        employeeList: action.payload
      }
    case type.GET_EMPLOYEE_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        loading: false,
        employeeList: null,
      }  

    case type.GET_EMPLOYEE_TYPES_LIST_SUCCESS:
        return {
          ...state,
        error: null,
        employeeTypeList: action.payload
      }
    case type.GET_EMPLOYEE_TYPES_LIST_FAILURE:
      return {
        ...state,
        employeeTypeList: null,
      }  
      
    case type.CHANGE_EMPLOYEE_STATUS:
        return {
          ...state,
          updateLoader: true,
        }
    case type.CHANGE_EMPLOYEE_STATUS_SUCCESS:
      return {
        ...state,
        updateLoader: false,
        employeeList: {
          ...state.employeeList,
          list: state.employeeList?.list?.map( (employee) => employee?.emp_detail_id === action.payload.employeeDetailsId
            ? {...employee, status: action.payload.status}
            : employee
        )
        }
      }
    case type.CHANGE_EMPLOYEE_STATUS_FAILURE:
      return {
        ...state,
        updateLoader: false,
      }

    case type.DELETE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        employeeList: {
          ...state.employeeList,
          list: state.employeeList?.list?.filter(item=>!(item.emp_detail_id === action.payload.employeeDetailsId))
        }
      }
    case type.DELETE_EMPLOYEE_FAILURE:
      return {
        ...state,
      }

    case type.GET_EMPLOYEE_DETAILS:
      return {
        ...state,
        loading: true,
        error: null
    }
    case type.GET_EMPLOYEE_DETAILS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        employeeDetails: action.payload
      }
    case type.GET_EMPLOYEE_DETAILS_FAILURE:
      return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        loading: false,
        employeeDetails: null,
      } 

    case type.ADD_EMPLOYEE:
      return {
        ...state,
        updateLoader: true,
      }
    case type.ADD_EMPLOYEE_SUCCESS:
      return {
        ...state,
        updateLoader: false,
  
      }
    case type.ADD_EMPLOYEE_FAILURE:
      return {
        ...state,
        updateLoader: false,
      }

    case type.GET_EMPLOYEE_AUDIT_LOGS:
        return {
          ...state,
          loading: true,
          error: null
      }
    case type.GET_EMPLOYEE_AUDIT_LOGS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        employeeAuditLogs: action.payload
      }
    case type.GET_EMPLOYEE_AUDIT_LOGS_FAILURE:
      return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        loading: false,
        employeeAuditLogs: null,
      }  

    case type.UPDATE_EMPLOYEE_DATA:
      return {
        ...state,
        updateLoader: true,
      }
    case type.UPDATE_EMPLOYEE_DATA_SUCCESS:
      return {
        ...state,
        updateLoader: false,
  
      }
    case type.UPDATE_EMPLOYEE_DATA_FAILURE:
      return {
        ...state,
        updateLoader: false,
      }


    case type.GET_EMPLOYEE_USERNAMES_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        employeeUserNameList: action.payload
      }
    case type.GET_EMPLOYEE_USERNAMES_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        employeeUserNameList: null,
      } 


    case type.GET_EMPLOYEE_NAMES_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        employeeNameList: action.payload
      }
    case type.GET_EMPLOYEE_NAMES_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        employeeNameList: null,
      } 


    case type.GET_EXISTING_EMPLOYEE_LIST:
        return {
          ...state,
          loading: true,
          error: null
      }
    case type.GET_EXISTING_EMPLOYEE_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        existingEmployeeList: action.payload
      }
    case type.GET_EXISTING_EMPLOYEE_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        loading: false,
        existingEmployeeList: null,
      } 


    case type.ADD_FROM_EXISTING_EMPLOYEE:
      return {
        ...state,
        updateLoader: true,
      }
    case type.ADD_FROM_EXISTING_EMPLOYEE_SUCCESS:
      return {
        ...state,
        updateLoader: false,
  
      }
    case type.ADD_FROM_EXISTING_EMPLOYEE_FAILURE:
      return {
        ...state,
        updateLoader: false,
      }


    case type.GET_EMPLOYEEE_LOGIN_REPORT:
        return {
          ...state,
          loading: true,
          error: null
      }
    case type.GET_EMPLOYEEE_LOGIN_REPORT_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        loginReportList: action.payload
      }
    case type.GET_EMPLOYEEE_LOGIN_REPORT_FAILURE:
      return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        loading: false,
        loginReportList: null,
      } 


    case type.PRINT_EMPLOYEEE_LOGIN_REPORT:
        return {
          ...state,
          printLoader: true,
          error: null
      }
    case type.PRINT_EMPLOYEEE_LOGIN_REPORT_SUCCESS:
      return {
        ...state,
        error: null,
        printLoader: false,
        printLoginReportList: action.payload
      }
    case type.PRINT_EMPLOYEEE_LOGIN_REPORT_FAILURE:
      return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        printLoader: false,
        printLoginReportList: null,
      } 

    case type.GET_EMPLOYEEE_REPORT_RECORD:
        return {
          ...state,
          loading: true,
          error: null
      }
    case type.GET_EMPLOYEEE_REPORT_RECORD_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        employeeReportRecord: action.payload
      }
    case type.GET_EMPLOYEEE_REPORT_RECORD_FAILURE:
      return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        loading: false,
        employeeReportRecord: null,
      } 


      case type.PRINT_EMPLOYEEE_REPORT_RECORD:
        return {
          ...state,
          printLoader: true,
          error: null
        }
      case type.PRINT_EMPLOYEEE_REPORT_RECORD_SUCCESS:
        return {
          ...state,
          error: null,
          printLoader: false,
          printEmployeeReportRecordData: action.payload
        }
      case type.PRINT_EMPLOYEEE_REPORT_RECORD_FAILURE:
        return {
          ...state,
          error: action?.message || 'Somthing went wrong.',
          printLoader: false,
          printEmployeeReportRecordData: null,
        } 
    
default:
  return state
}
}