import React from "react";
import { useNavigate } from "react-router-dom";
import {
  CHANGE_PASSWORD_ENDPOINT,
  EDITSOCIETY_PROFILE_ENDPOINT,
  SOCIETY_PROFILE_AUDIT_LOGS_ENDPOINT,
} from "../../../Routing/routes";
import Layout from "../../Layout/Layout";
import SocietyProfileAccordion from "../../../components/AuditLogsandAccordions/SocietyProfileAccordion";
import Button from "../../../components/Button/Button";
import { getPermission } from "../../../utils/helpers/getPermissions";
import { useSelector } from "react-redux";
import * as permissions from "../../../Routing/permissions";

const SocietyProfile = () => {
  const navigate = useNavigate();
  const { loginData } = useSelector((state) => state.loginReducer);

  const handleEditProfileClick = () => {
    navigate(EDITSOCIETY_PROFILE_ENDPOINT);
  };

  const handleChangePasswordClick = () => {
    navigate(CHANGE_PASSWORD_ENDPOINT);
  };

  const handleAuditLogs = () => {
    navigate(SOCIETY_PROFILE_AUDIT_LOGS_ENDPOINT);
  };

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="flex justify-between border-b border-[#CCC] pb-2">
          <h1 className="text-base font-semibold p-1">Society Profile</h1>
          <div className="flex gap-2">
            <div className="flex gap-2">
              <span className="border-l border-[#CCC] h-full"></span>
              {getPermission(loginData, [
                permissions.EDIT_SOCIETY_PROFILE_PERMISSION,
              ]) && (
                <Button
                  label="Edit Profile"
                  onClick={handleEditProfileClick}
                  className="font-semibold h-8"
                />
              )}
              {/* {getPermission(loginData, [permissions.CHANGE_PASSWORD_PERMISSION]) &&  */}
              <Button
                label="Change Password"
                onClick={handleChangePasswordClick}
                className="font-semibold h-8"
              />
              {/* } */}
              {getPermission(loginData, [
                permissions.VIEW_SOCIETY_PROFILE_AUDIT_PERMISSION,
              ]) && (
                <Button
                  label="Audit Log"
                  onClick={handleAuditLogs}
                  className="font-semibold h-8"
                />
              )}
            </div>
          </div>
        </div>
        <SocietyProfileAccordion />
      </div>
    </Layout>
  );
};

export default SocietyProfile;
