import React from "react";
import { ReactComponent as CloseIcon } from "../../../assets/icons/closeIcon.svg";
import ImageComponent from "../../../components/ImageComponent/ImageComponent";

const VisitorDetailsModal = ({ isOpen, onClose, data }) => {
    
  return (
    <div
      className={`fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-60 z-20 ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="bg-white rounded-lg w-[520px] h-fit">
        <div className="flex justify-between p-2 gap-8 items-center -mb-2">
          <h2 className="p-2 text-lg font-semibold mb-2 items-center text-center w-[140%] flex justify-end">
            Visitor Details
          </h2>
          <div className="w-1/2 flex justify-end">
            <button
              onClick={onClose}
              className="bg-[#EAEAEA] rounded-full p-2 items-center mb-2 w-9 h-9 "
            >
              <CloseIcon className="fill-current text-gray-600 hover:text-gray-800 ml-1 w-3 h-3 cursor-pointer" />
            </button>
          </div>
        </div>
        <div className="border-b border-[#CCCCCC]"></div>
        <div className="p-3 h-[480px] overflow-y-scroll">
          <>
            <div className="flex justify-center">
                <ImageComponent defaultUrl={data?.image} width={32} height={32} />
            </div>
            <div className={`text-center font-bold uppercase text-base my-2 ${data.status==='Rejected'? 'text-red-600' : 'text-green-500'}`}>
                {data.status}
            </div>
            <div className="grid grid-cols-2">
                <div className="grid grid-cols-1 gap-3">
                  <div className="flex items-center">
                      <span className="text-sm font-semibold w-28">Visitor Name:</span>
                      <span>{data.name}</span>
                  </div>
                  <div className="flex items-center">
                      <span className="text-sm font-semibold w-28">Type:</span>
                      <span>{data.type}</span>
                  </div>
                  <div className="flex items-center">
                      <span className="text-sm font-semibold w-28">From:</span>
                      <span>{data.from}</span>
                  </div>
                  <div className="flex items-center">
                      <span className="text-sm font-semibold w-28">ID Proof:</span>
                      <span>{data.idProof}</span>
                  </div>
                  <div className="flex items-center">
                      <span className="text-sm font-semibold w-28">Belongings:</span>
                      <span>{data.belongings}</span>
                  </div>
                  <div className="flex items-center">
                      <span className="text-sm font-semibold w-28">Flat Details:</span>
                      <span>{data.flat}</span>
                  </div>
                  <div className="flex items-center">
                      <span className="text-sm font-semibold w-28">Comments:</span>
                      <span>{data.comments}</span>
                  </div>
                  <div className="flex items-center">
                      <span className="text-sm font-semibold w-28">InGate:</span>
                      <span>{data.inGate}</span>
                  </div>
                  <div className="flex items-center">
                      <span className="text-sm font-semibold w-28">Check In:</span>
                      <span>{data.checkIn || '-'}</span>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-3">
                <div className="flex items-center">
                    <span className="text-sm font-semibold w-28">Count:</span>
                    <span>{data.count}</span>
                </div>
                <div className="flex items-center">
                    <span className="text-sm font-semibold w-28">Contact:</span>
                    <span>{data.phone}</span>
                </div>
                <div className="flex items-center">
                    <span className="text-sm font-semibold w-28">Purpose:</span>
                    <span>{data.purpose}</span>
                </div>
                <div className="flex items-center">
                    <span className="text-sm font-semibold w-28">Approved By:</span>
                    <span>{data.approveBy}</span>
                </div>
                <div className="flex items-center">
                    <span className="text-sm font-semibold w-28">Vehicle No.:</span>
                    <span>{data.vehicleNo}</span>
                </div>
                <div className="flex items-center mt-[70px]">
                    <span className="text-sm font-semibold w-28">OutGate:</span>
                    <span>{data.outGate}</span>
                </div>
                <div className="flex items-center">
                      <span className="text-sm font-semibold w-28">Checkout:</span>
                      <span>{data.checkOut || '-'}</span>
                  </div>
                </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default VisitorDetailsModal;
