import React, { useEffect, useState } from "react";
import { Link, createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import {
  ACCESS_SETTINGS_ENDPOINT,
  ADD_FROM_EXISITING_TENANT_ENDPOINT,
  ADD_TENANTS_ENDPOINT,
  ARCHIVE_TENANTS_ENDPOINT,
  PRINT_TENANTS_INFO_ENDPOINT,
  TENANTS_AUDIT_LOG_ENDPOINT,
  TENANTS_INFO_ENDPOINT,
} from "../../../../Routing/routes";
import Search from "../../../../components/SearchBox/Search";
import Layout from "../../../Layout/Layout";
import { DownloadExt, UploadEx } from "../../../../assets";
import Button from "../../../../components/Button/Button";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import TenancyExpiryModal from "../../../../components/Modal/TenancyExpiryModal";
import UploadTenantExcelModal from "../../../../components/Modal/UploadTenantExcelModal";
import ButtonIco from "../../../../components/Button/ButtonPrint";
import TenantInfoListTable from "../../../../components/TableComponent/TenantInfoListTable";
import { useDispatch, useSelector } from "react-redux";
import  {changeTenantStatusInBulk, deleteTenantsInBulk, downloadTenantsDetailsList, getTenantsDetailsList, sendEmailToTeanants, uploadTenantsDetails} from '../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/TenantsInfoAction'
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import { tenantsStatusOptions } from "../../../../consts/DropDownOptions";
import moment from "moment";
import { downloadExcel } from "../../../../utils/helpers/downloadMedia";
import { CircularProgress } from "@mui/material";
import { getFlatDetails, getWingsAndFlatDetails } from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import SendEmailModal from "../../../../components/Modal/SendEmailModal";
import * as permissions from '../../../../Routing/permissions'
import { getPermission } from "../../../../utils/helpers/getPermissions";
import { validateDate } from "../../../../utils/helpers/universalFunctions";
import { downloadExcelFormat } from "../../../../redux/actions/SetupRecordsActions/BulkUploadActions/BulkUploadAction";
import ButtonG from "../../../../components/Button/ButtonG";


export const headingsTenants = [
  { id: 'sNo', label: 'SNo', sort: false, width: '35px' },
  { id: 'tenantName', label: 'Tenant Name', sort: true,width: '120px' },
  { id: 'wingNo', label: 'Wing No.', sort: true, width:'80px'  },
  { id: 'flatNo', label: 'Flat No.', sort: true, width:'80px' },
  { id: 'memberName', label: 'Owner Name', sort: true, width: '110px' },
  { id: 'rentDeedStartDate', label: 'Rent Deed Start Date', sort: true, width:'90px' },
  { id: 'rentDeedEndDate', label: 'Rent Deed End Date', sort: true, width:'90px'},
  { id: 'lastTimeActive', label: 'Last Activity', sort: true,width:'100px' },
  { id: 'status', label: 'Status', sort: true, width: '80px' },
  {
    id: "action",
    label: "Action",
    sort: false,
  },
];

const tenantBulkActionOptions = [
  { value: "Delete", label: "Delete", permissions: [permissions.DELETE_TENANTS_PERMISSION] },
  { value: "Approve Selected", label: "Approve Selected", permissions: [permissions.APPROVE_TENANTS_PERMISSION] },
  { value: "Send Invite", label: "Send Invite", permissions: [permissions.SEND_INVITE_IN_TENANTS_PERMISSION]},
]

const TenantsInfo = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {wingsData,flatsData} = useSelector(state=>state.wingsReducer)
  const {loading, updateLoader, tenantsList} = useSelector(state=>state.tenantInfoReducer)
  const {ipAddress} = useSelector(state=>state.ipAddressReducer)
  const {loginData} = useSelector(state=>state.loginReducer)

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isExpiryReminderModalOpen, setExpiryReminderModalOpen] = useState(false);
  const [action, setAction] = useState('');
  const [selected, setSelected] = useState([]);
  const [file, setFile] = useState(null)
  const [isSendEmailModalOpen, setIsSendEmailModalOpen] = useState(false);

  const handleOpenExpiryReminderModal = () => {
    setSelected([])
    setExpiryReminderModalOpen(true);
  };

  const handleOpenUploadModal = () => {
    setIsUploadModalOpen(true);
  };

  const handleCloseUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  const handleSearch = (text)=>{
    if(text && text !== searchParams.get("search")){
      searchParams.delete("search")
      searchParams.delete('page')
      searchParams.delete('pageLimit')
      navigate({
        pathname: `${TENANTS_INFO_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["search", text]])}`,
      })
    }
  }

  const handelSelectWing = (selectedItem)=>{
    if(selectedItem.value?.toString() !== searchParams.get("wingId")){
      dispatch(getFlatDetails({societyId: loginData?.default_community?.community_id, wingId: selectedItem?.value}))
      searchParams.delete('page')
      searchParams.delete('pageLimit')
      searchParams.delete("wingId")
      searchParams.delete("flatId")
      navigate({
        pathname: `${TENANTS_INFO_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["wingId", selectedItem.value]])}`,
      })
    }
  }

  const handelSelectFlat = (selectedItem)=>{
    if(selectedItem.value?.toString() !== searchParams.get("flatId")){
    searchParams.delete('page')
    searchParams.delete('pageLimit')
    searchParams.delete("flatId")
    navigate({
      pathname: `${TENANTS_INFO_ENDPOINT}`,
      search: `?${createSearchParams([...searchParams.entries(), ["flatId", selectedItem.value]])}`,
    })
  }
  }

  const handelSelectStatus = (selectedItem)=>{
    if(selectedItem.value?.toString() !== searchParams.get("status")){
      searchParams.delete('page')
      searchParams.delete('pageLimit')
      searchParams.delete("status")
      navigate({
        pathname: `${TENANTS_INFO_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["status", selectedItem.value]])}`,
      })
    }
  }

  const handleDownloadExcel = ()=>{
    const onSucessCallback = (data)=>{
      downloadExcel({data, fileName: 'Tenant List Sheet.xlsx'})
    }
    const data = {
      societyId: loginData?.default_community?.community_id, 
      searchText: searchParams.get("search") || '',
      status: searchParams.get("status") || '',
      wingId: searchParams.get("wingId") || '',
      flatId: searchParams.get("flatId") || '',
      onSucessCallback
    }
    dispatch(downloadTenantsDetailsList(data))
  }

  const handleDownloadSampleLink = ()=>{
    const onSucessCallback = (data)=>{
      downloadExcel({data, fileName: 'Tenant Sample sheet.xlsx'})
    }
    const data = {
      societyId: loginData?.default_community?.community_id, 
      searchText: searchParams.get("search") || '',
      status: searchParams.get("status") || '',
      wingId: searchParams.get("wingId") || '',
      flatId: searchParams.get("flatId") || '',
      isSample: true,
      onSucessCallback
    }
    dispatch(downloadTenantsDetailsList(data))
  }

  const handleDownloadFormatLink = ()=>{
    const onSucessCallback = (data)=>{
      downloadExcel({data, fileName: 'Tenant sheet Format.xlsx'})
    }
    const data = {
      societyId: loginData?.default_community?.community_id, 
      flag: 'tenants',
      onSucessCallback
    }
    dispatch(downloadExcelFormat(data))
  }

  const handleApply = ()=>{
    if(action === 'Delete'){
      let data={
        societyId: loginData?.default_community?.community_id,
        page: searchParams.get("page") || 1,
        searchText: searchParams.get("search") || '',
        pageLimit: searchParams.get("pageLimit") || 10,
        status: searchParams.get("status") || '',
        wingId: searchParams.get("wingId") || '',
        flatId: searchParams.get("flatId") || '',
        tenantIds: selected,
        flag: 'Tenant',
        onSuccessCallback: ()=>{
          setSelected([])
        }
      }
      dispatch(deleteTenantsInBulk(data))
    }

    if(action === 'Approve Selected'){
      let data={
        tenantIds: selected,
        onSuccessCallback: ()=>{
          setSelected([])
        }
      }
      dispatch(changeTenantStatusInBulk(data))
    }

    if(action==='Send Invite'){
      setIsSendEmailModalOpen(true)
    }
  }

  const handleSendEmailOnSubmit = (data) =>{
    const sendRequest = {
      subject: data.subject,
      message: data.message,
      emailIds: tenantsList?.tenants_list?.filter(obj => selected.includes(obj.tenant_id))
        ?.map(obj => obj.email)?.join(),
      onRequestEndCallback: ()=>{
        setIsSendEmailModalOpen(false)
      }
    }
    dispatch(sendEmailToTeanants(sendRequest))
  }

  const handlePrint =()=>{
    window.open(`${PRINT_TENANTS_INFO_ENDPOINT}?${createSearchParams([...searchParams.entries()])}`)
  }

  const handleClear = ()=>{
    navigate(TENANTS_INFO_ENDPOINT)
  }

  const getTableData =() =>{
    const data={
      societyId: loginData?.default_community?.community_id,
      page: searchParams.get("page") || 1,
      searchText: searchParams.get("search") || '',
      pageLimit: searchParams.get("pageLimit") || 10,
      status: searchParams.get("status") || '',
      wingId: searchParams.get("wingId") || '',
      flatId: searchParams.get("flatId") || '',
    }

    dispatch(getTenantsDetailsList(data))
  }

  const onUpload = ()=>{
    let data={
      societyId: loginData?.default_community?.community_id,
      ipAddress,
      file,
      sessionId: loginData?.session_id,
      onSuccessCallback: ()=>{
        navigate(TENANTS_INFO_ENDPOINT)
      },

      onRequestEndCallback: ()=>{
        setFile(null)
        setIsUploadModalOpen(false)
      }
    }
    dispatch(uploadTenantsDetails(data))
  }

  useEffect(()=>{      
    getTableData()
  }, [searchParams])

  useEffect(()=>{
    dispatch(getWingsAndFlatDetails({societyId: loginData?.default_community?.community_id}))

    if(searchParams.get("wingId")){
      dispatch(getFlatDetails({societyId: loginData?.default_community?.community_id, wingId: searchParams.get("wingId")}))
    }
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 relative">
        <div className="flex justify-between gap-10 items-center border-b border-[#CCC] pb-2">
          <div className="flex justify-between items-center">
            <h1 className="text-base font-semibold p-1">Tenants Info</h1>
          </div>
          <div className="flex text-sm gap-3">
            {
              getPermission(loginData, [permissions.VIEW_ARCHIVE_TENANTS_PERMISSION]) &&
              <Link to={ARCHIVE_TENANTS_ENDPOINT} className="text-white">
                <Button label="Archive Tenants" className="py-2 h-8" />
              </Link>
            }
            {
              getPermission(loginData, [permissions.VIEW_TENANTS_AUDIT_LOG_PERMISSION]) &&
              <Link to={TENANTS_AUDIT_LOG_ENDPOINT}>
                <Button label="Audit Logs" className="py-2 h-8" />
              </Link>
            }
            {
              getPermission(loginData, [permissions.EXPIRY_REMINDER_PERMISSION]) &&
              <Link>
                <Button
                  label="Expiry Reminder"
                  className="py-2 h-8"
                  onClick={handleOpenExpiryReminderModal}
                />
              </Link>
            }

            {
              getPermission(loginData, [permissions.ACCESS_SETTING_PERMISSION]) &&
              <Link to={ACCESS_SETTINGS_ENDPOINT}>
                <Button label="Access Setting" className="py-2 h-8" />
              </Link>
            }
            {
              getPermission(loginData, [permissions.ADD_TENANTS_PERMISSION]) &&
              <>
                <div className="border-r border-[#CCC]"></div>
                <Link to={ADD_TENANTS_ENDPOINT} className="text-white">
                  <Button label="Add Tenants" className="py-2 h-8" />
                </Link>
              </>
            }
            {
              getPermission(loginData, [permissions.ADD_FROM_EXISTING_TENANTS_PERMISSION]) &&
              <Link
                to={ADD_FROM_EXISITING_TENANT_ENDPOINT}
                className="text-white"
              >
                <Button label="Add from Existing" className="py-2 h-8" />
              </Link>
            }
          </div>
        </div>
        <div className="flex flex-col">
          <div className="py-2 flex flex-row justify-between gap-2">
          <Search height="8" onclick={handleSearch} value={searchParams.get("search")} />
            <div className="flex justify-between items-center">
              <div className="border-r border-[#CCC] mx-4 h-full"></div>
              <div className="flex gap-2">
                <button
                  onClick={handleOpenUploadModal}
                  className="flex text-red-650 border border-red-650 bg-white text-xs px-2 font-semibold items-center whitespace-nowrap rounded-lg"
                >
                  <UploadEx className="mr-2 fill-red-650" /> Upload Modified/New
                  Flat Excel
                </button>
                {
                  getPermission(loginData, [permissions.PRINT_TENANTS_REPORT_PERMISSION]) &&
                  <ButtonIco icon={faPrint} children="Print" onClick={handlePrint} />
                }
                <ButtonIco icon={DownloadExt} children="Download Excel" onClick={handleDownloadExcel} type='button' />
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center gap-2 p-2">
            <div className="flex items-center gap-2">
              <p className="text-[13px]">Filter</p>
              <Dropdown2
                options={wingsData?.map((item)=>({
                  value: item?.wing_id,
                  label: item?.number
                })) || []}
                placeholder="Select Wing"
                className="text-[11px] ms-4"
                width="180px"
                height="32px"
                onSelect={(selectedItem) => {
                  handelSelectWing(selectedItem)
                }}
                value={
                  searchParams.get("wingId") && {
                    value: searchParams.get("wingId"),
                    label: wingsData?.find((item) => item.wing_id?.toString() === searchParams.get("wingId"))?.number
                  }
                }
              />
              <Dropdown2
                options={searchParams.get('wingId') && flatsData?.manage_flat_details?.map((item)=>({
                  value: item?.flat_id,
                  label: item?.flat_no
                })) || []}
                placeholder="Select Flat"
                className="text-[11px] ms-4"
                width="180px"
                height="32px"
                onSelect={(selectedItem) => {
                  handelSelectFlat(selectedItem)
                }}
                value={
                  searchParams.get("flatId") && {
                    value: searchParams.get("flatId"),
                    label: flatsData?.manage_flat_details?.find((item) => item.flat_id?.toString() === searchParams.get("flatId"))?.flat_no
                  }
                }
              />
              <Dropdown2
                options={tenantsStatusOptions}
                placeholder="Select Status"
                className="text-[11px] ms-4"
                width="180px"
                height="32px"
                onSelect={(selectedItem) => {
                  handelSelectStatus(selectedItem)
                }}
                value={
                  searchParams.get("status") && {
                    value: searchParams.get("status"),
                    label: tenantsStatusOptions?.find((item) => item.value === searchParams.get("status"))?.label
                  }
                }
              />
              <ButtonG label='Clear' className='h-8' onClick={handleClear} />

            </div>
            { !!selected?.length && (
                <div className="flex">
                  <div className="me-2">
                    <Dropdown2
                      options={tenantBulkActionOptions.filter(item=> getPermission(loginData, item.permissions))}
                      placeholder="Choose Action"
                      className="text-[11px]"
                      width="160px"
                      height="32px"
                      onSelect={(selectedItem) => {setAction(selectedItem.value)}}
                    />
                  </div>
                  <Button label={
                      <span className="w-8 inline-block">
                        {
                          updateLoader ?
                            <CircularProgress sx={{color: "white"}} size={17}/>
                            :
                            'Apply'
                          }
                      </span>
                    } 
                    className="font-semibold px-4 h-8" type='button' onClick={handleApply} disabled={!action} />
                </div>
              )}
          </div>
        </div>
        <TenantInfoListTable
          loading={loading}
          data={tenantsList?.tenants_list?.map((item, index)=>({
            sNo: (((tenantsList?.current_page_no - 1) * tenantsList?.page_limit + 1)+index)?.toString()?.padStart(2, "0"),
            tenantId: item?.tenant_id || '-',
            flatId: item.flat_id || '-',
            tenantName: item?.tenant_name || '-',
            wingNo: item?.wing_no || '-',
            flatNo: item?.flat_no || '-',
            memberName: item?.member_name || '-',
            rentDeedStartDate: validateDate(item?.start_date) ? moment(new Date(item?.start_date))?.format('YYYY-MM-DD') : '-',
            rentDeedEndDate: validateDate(item?.end_date) ? moment(new Date(item?.end_date))?.format('YYYY-MM-DD') : '-',
            lastTimeActive: validateDate(item?.last_activity_time) ? moment(new Date(item?.last_activity_time))?.format('ddd, MMM DD,YYYY hh:mm A') : '-',
            status: validateDate(item?.end_date) && moment(new Date(item?.end_date)).isBefore(new Date()) ? 'Expired' : (item?.status === 'A' ?  'Active': (item?.status === 'I' ? 'Inactive': 'Unapprove')),
            email: item?.email
          }))}
          columns={headingsTenants}
          checkbox={true}
          pagination={tenantsList?.tenants_list?.length}
          totalCount={tenantsList?.total_record}
          totalPages={tenantsList?.total_pages}
          start={(tenantsList?.current_page_no - 1) * tenantsList?.page_limit + 1}
          end={(tenantsList?.current_page_no - 1) * tenantsList?.page_limit + tenantsList?.tenants_list?.length}
          currentPage={tenantsList?.current_page_no}
          rowsPerPage={searchParams.get("pageLimit") || 10}
          getTableData={getTableData}

          selected={selected}
          setSelected={setSelected}
        ></TenantInfoListTable>

        {!tenantsList?.tenants_list?.length && !loading && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
      {isUploadModalOpen && (
        <UploadTenantExcelModal
          isOpen={isUploadModalOpen}
          onClose={handleCloseUploadModal}
          buttons={['Download Sample','Download CSV Sample', 'Download Format']}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          file={file}
          setFile={setFile}
          onUpload={onUpload}
          downloadCSVSampleLink={handleDownloadSampleLink}
          downloadSampleLink={handleDownloadSampleLink}
          downloadFormatLink={handleDownloadFormatLink}
        />
      )}

      {isExpiryReminderModalOpen && (
        <TenancyExpiryModal
          isOpen={isExpiryReminderModalOpen}
          onClose={() => setExpiryReminderModalOpen(false)}
        />
      )}

      <SendEmailModal
        isOpen={isSendEmailModalOpen}
        closeModal={() => setIsSendEmailModalOpen(false)}
        loading={updateLoader}
        onSubmit={handleSendEmailOnSubmit}
      />
    </Layout>
  );
};

export default TenantsInfo;
