import React, { useEffect } from "react";
import { TextInputFM } from "../../../../components/InputFields/TextInput";
import  { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import TextRadio from "../../../../components/InputFields/TextRadio";
import { TextAreaFM } from "../../../../components/InputFields/TextArea";
import { UploadButtonFM } from "../../../../components/Button/UploadButton";
import { DropdownFM2 } from "../../../../components/Dropdown/dropdown2";
import { getFlatDetails, getWingsAndFlatDetails } from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import { useDispatch, useSelector } from "react-redux";
import { dateOptions, frequencyOptions, genderOptions } from "../../../../consts/DropDownOptions";
import countries from "../../../../consts/countries";
import Loader from "../../../../components/Loader/Loader";
import { Link } from "react-router-dom";
import moment from "moment";

const RegFormTenantDetails = ({tenantId, values, setFieldValue}) => {
  
  const dispatch = useDispatch()
  const {wingsData,flatsData} = useSelector(state=>state.wingsReducer)
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading, rentPaymentOnlineRegistrationData} = useSelector(state => state.tenantInfoReducer)

  const radioOptions = [
    {value: "individual", label: "Individual" },
    {value: "company", label: "Company" },
  ];

  useEffect(()=>{
    const societyId= loginData?.default_community?.community_id
    dispatch(getWingsAndFlatDetails({societyId}))
  }, [])


  return (
    <div>
      <div className="flex gap-4 p-2 min-h-[720px]">
        <div className="flex flex-col  w-full gap-3">
          <div className=" text-[#AAAAAA]">Tenant Details</div>
          {
            loading && tenantId !== rentPaymentOnlineRegistrationData?.tenant_id?.toString() ?
            <Loader />
            :
            <div className="flex justify-between w-full gap-3">
              <div className="w-1/2 gap-1">
                <div className="mt-2 mb-7">
                  <TextRadio
                    label="Individual/Company"
                    options={radioOptions?.map((item)=>({
                      name: `type`,
                      value: item.value,
                      label: item.label
                    }))}
                    defaultSelected={values.type}
                    onChange={(selectemItem)=>setFieldValue(`type`, selectemItem)}
                    className={'w-56'}
                  />
                </div>
                <div className="flex justify-between text-sm">
                  <span>Tenant First Name</span>
                  <TextInputFM
                    label=""
                    placeholder="Enter First Name"
                    className="w-56"
                    name='firstName'
                    maxLength={30}
                  />
                </div>
                <div className="flex flex-row justify-between">
                  <span className="text-sm mt-2">Wing</span>
                  <div className="text-xs">
                    <DropdownFM2
                      options={wingsData?.map((item)=>({
                        value: item?.wing_id,
                        label: item?.number
                      })) || []}
                      width={"224px"}
                      className="text-xs"
                      placeholder={"Select Wing"}
                      onSelect={(selectedItem)=>{
                        setFieldValue(`wingId`, selectedItem.value)
                        setFieldValue(`flatId`, '')
                        dispatch(getFlatDetails({societyId: loginData?.default_community?.community_id, wingId: selectedItem?.value}))
                      }}
                      height='38px'
                      name={`wingId`}
                      value={values.wingId==='' ? null :
                        { 
                          value: values.wingId,
                          label: wingsData?.find(item=> item.wing_id === parseInt(values.wingId))?.number
                        }}
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <span className="text-sm  mt-2">Date of Birth</span>
                  <DatePickerComponentFM
                    className="w-56 h-9 ml-4 justify-between"
                    onDateChange={(selectedDate) => {
                      setFieldValue('dob', selectedDate)
                    }}
                    name='dob'
                    defaultValue={values.dob ? new Date(values.dob) : null}
                    maxDate={moment().subtract(18, 'years').toDate()}
                  />
                </div>
                <div className="flex flex-row justify-between">
                  <span className="text-sm mt-2">gender</span>
                  <DropdownFM2
                    options={genderOptions}
                    width={"224px"}
                    className="text-xs"
                    placeholder="Select Gender"
                    onSelect={(selectedItem)=>{
                      setFieldValue(`gender`, selectedItem.value)
                    }}
                    height='38px'
                    name={`gender`}
                    value={values.gender==='' ? null :
                        {
                          value: values.gender,
                          label: genderOptions?.find(item => item.value === values.gender)?.label
                        }}
                  />
                </div>
                <TextInputFM
                  label="Mobile Number"
                  placeholder="Enter Number"
                  className="w-56"
                  type='number'
                  name='mobileNo'
                  maxLength={12}
                />
                <div className="flex justify-between">
                  <span className="text-sm mt-2">Tenancy Property Address</span>
                  <div>
                    <TextAreaFM
                      placeholder="Enter Address"
                      className={"w-56"}
                      maxLength={200}
                      name='rentPropertyAddress'
                    />
                  </div>
                </div>
                <TextInputFM
                  label="City"
                  placeholder="Enter City"
                  className="w-56"
                  maxLength={40}
                  name='city'
                />
                <TextInputFM
                  label="PAN Number"
                  placeholder="Enter Pan Number"
                  className="w-56"
                  type='alphaNumber'
                  maxLength={10}
                  name='panCardNo'
                />
                <TextInputFM
                  label="Rent Amount"
                  placeholder="Enter Amount"
                  className="w-56"
                  name='rentAmount'
                  type='number'
                  maxLength={15}
                />
                <div className="flex">
                  <span className="text-sm mt-2 w-60 me-1.5">Monthly Due Date</span>
                  <DropdownFM2
                    options={dateOptions}
                    width={"130px"}
                    className="text-xs"
                    placeholder="Select Date"
                    onSelect={(selectedItem)=>{
                      setFieldValue(`mothlyDueDate`, selectedItem.value)
                    }}
                    height='38px'
                    name={`mothlyDueDate`}
                    value={values.mothlyDueDate==='' ? null :
                      {
                        label: values.mothlyDueDate,
                        value: values.mothlyDueDate
                      }
                    }
                  />
                </div>
                <div className="flex justify-between text-sm mb-4">
                  <span className="mt-2">Rent Agreement Expiry Date</span>
                  <DatePickerComponentFM
                    className="w-56 h-9 ml-4 justify-between"
                    onDateChange={(selectedDate) => {
                      setFieldValue('rentAgreementExpiryDate', selectedDate)
                    }}
                    name='rentAgreementExpiryDate'
                    defaultValue={values.rentAgreementExpiryDate ? new Date(values.rentAgreementExpiryDate) : null}
                    minDate={new Date()}
                  />
                </div>
              </div>
              <div className="w-1/2 gap-2 pl-5">
                <div className="mt-14">
                  <TextInputFM
                    label="Last Name"
                    placeholder="Enter Last Name"
                    className="w-56"
                    name='lastName'
                    maxLength={30}
                  />
                </div>
                <div className="flex items-center justify-between mb-4">
                  <span className="text-sm">Flat</span>
                  <div className="text-xs">
                    <DropdownFM2
                      options={values.wingId ? flatsData?.manage_flat_details?.map((item)=>({
                        value: item?.flat_id,
                        label: item?.flat_no
                      })) : []}
                      width={"224px"}
                      className="text-xs"
                      placeholder={"Select Flat/Unit"}
                      onSelect={(selectedItem)=>{
                        setFieldValue(`flatId`, selectedItem.value)
                      }}
                      height='38px'
                      name={`flatId`}
                      value={values.wingId==='' ? null :
                        {
                          value: values.flatId,
                          label: flatsData?.manage_flat_details?.find(item=> item.flat_id === parseInt(values.flatId))?.flat_no
                        }}
                          
                    />
                  </div>
                </div>
                <div className="flex  justify-between text-sm mt-[55px]">
                  <span className="mt-2">Country</span>
                  <DropdownFM2
                    options={ countries?.map((item)=>({
                      label: `${item?.name?.toUpperCase()} (${item?.phonecode})`,
                      value: item?.phonecode?.replace("+",""),
                      iso: item.iso,
                      id: item?.id
                    })) || []}
                    width={"224px"}
                    className="text-xs"
                    placeholder={"Select Country"}
                    onSelect={(selectedItem)=>{
                      setFieldValue(`mobileCountryCode`, selectedItem.value)
                      setFieldValue(`countryId`, selectedItem.id)
                      setFieldValue(`countryISO`, selectedItem.iso)
                    }}
                    height='38px'
                    name={`mobileCountryCode`}
                    value={values.mobileCountryCode==='' ? null :
                      {
                        label: `${countries?.find(item=> item.phonecode?.replace('+','') === values.mobileCountryCode)?.name?.toUpperCase()} (+${values.mobileCountryCode})`,
                        value: values.mobileCountryCode,
                        iso: countries?.find(item=> item.phonecode?.replace('+','') === values.mobileCountryCode)?.iso,
                        id: values.countryId
                      }
                    }
                  />
                </div>
                <TextInputFM
                  label="Secondary Email Id"
                  placeholder="Enter Email"
                  className="w-56"
                  name='email'
                />
                <div className="mt-[63px]">
                  <TextInputFM
                    label="Pincode"
                    placeholder="XXXXXX"
                    className="w-56"
                    type='number'
                    name='pinCode'
                    maxLength={6}
                  />
                </div>
                <div className="mt-[57px]">
                  <TextInputFM
                    label="GST Registration Number"
                    placeholder="Enter GST number"
                    className="w-56"
                    name='gstRegistrationNo'
                    maxLength={15}
                    type='alphaNumber'
                  />
                </div>
                <div className="flex  justify-between text-sm">
                  <span className="mt-2">Frequency Of Rent</span>
                  <DropdownFM2
                    options={frequencyOptions}
                    width={"224px"}
                    className="text-xs"
                    placeholder="Select frequency"
                    onSelect={(selectedItem)=>{
                      setFieldValue(`frequencyOfRent`, selectedItem.value)
                    }}
                    height='38px'
                    name={`frequencyOfRent`}
                    value={values.frequencyOfRent==='' ? null :
                      {
                        label: frequencyOptions?.find(item=> item.value === values.frequencyOfRent)?.label,
                        value: values.frequencyOfRent
                      }
                    }
                  />
                </div>
                {
                  <div className="text-sm flex justify-between">
                    <span className="mt-1.5">Rent Agreement</span>
                    <div className="w-1/2">
                      {values.rentAgreementFileURL ? <Link to={values.rentAgreementFileURL} target="_blank" className="underline text-blue-500">Click here to view</Link> : '-'}
                    </div>
                  </div>
                }
                <div className="text-sm flex justify-between ">
                  <span className="mt-1.5">Rent Agreement Upload</span>
                  <div className="w-1/2">
                    <UploadButtonFM showFileName={true} 
                      name={`rentAgreementFile`}
                      accept={"image/jpeg,image/gif,image/png,application/pdf,image/x-eps"}
                      onSelect={(e)=>setFieldValue(`rentAgreementFile`, e)} 
                      fileName={values.rentAgreementFile?.name}
                    />
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default RegFormTenantDetails;
