import * as type from "../../actions/index.types";

export function myInvoiceReducer(
  state = {
    loading: false,
    updateLoader: false,
    printLoader: false,
    myInvoiceList: [],
    invoiceStatusList: [],
    invoicePaymentList: [],
    singleInvoice: [],
    printInvoice: null,
    printReceipt: null,
    error: null,
  },
  action
) {
  switch (action.type) {
      case type.GET_MY_INVOICE_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_MY_INVOICE_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        myInvoiceList: action.payload,
      };
    case type.GET_MY_INVOICE_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        myInvoiceList: null,
      };

      case type.GET_MY_INVOICE_STATUS_LIST:
        return {
          ...state,
          //loading: true,
          error: null,
        };
      case type.GET_MY_INVOICE_STATUS_LIST_SUCCESS:
        return {
          ...state,
          error: null,
          //loading: false,
          invoiceStatusList: action.payload,
        };
      case type.GET_MY_INVOICE_STATUS_LIST_FAILURE:
        return {
          ...state,
          error: action?.message || "Somthing went wrong.",
          //loading: false,
          invoiceStatusList: null,
        };

        case type.GET_MY_INVOICE_PAYMENT_LIST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case type.GET_MY_INVOICE_PAYMENT_LIST_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          invoicePaymentList: action.payload,
        };
      case type.GET_MY_INVOICE_PAYMENT_LIST_FAILURE:
        return {
          ...state,
          error: action?.message || "Somthing went wrong.",
          loading: false,
          invoicePaymentList: null,
        };

        case type.SINGLE_INVOICE_LIST:
          return {
            ...state,
            loading: true,
            error: null,
          };
        case type.SINGLE_INVOICE_LIST_SUCCESS:
          return {
            ...state,
            error: null,
            loading: false,
            singleInvoice: action.payload,
          };
        case type.SINGLE_INVOICE_LIST_FAILURE:
          return {
            ...state,
            error: action?.message || "Somthing went wrong.",
            loading: false,
            singleInvoice: null,
          };

          case type.GET_MY_INVOICE_PRINT_INVOICE:
            return {
              ...state,
              printLoader: true
            };
          case type.GET_MY_INVOICE_PRINT_INVOICE_SUCCESS:
            return {
              ...state,
              printLoader: false,
              printInvoice: action.payload,
            };
          case type.GET_MY_INVOICE_PRINT_INVOICE_FAILURE:
            return {
              ...state,
              printLoader: false,
              printInvoice: null,
            };

            case type.GET_INVOICE_RECEIPT:
              return {
                ...state,
                printLoader: true,
              };
            case type.GET_INVOICE_RECEIPT_SUCCESS:
              return {
                ...state,
                printLoader: false,
                printReceipt: action.payload,
              };
            case type.GET_INVOICE_RECEIPT_FAILURE:
              return {
                ...state,
                printLoader: false,
                printReceipt: null,
              };

    default:
      return state;
  }
}
