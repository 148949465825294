import React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '10px 16px'
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function CustomizedTableHead({ columns }) {
  return (
    <TableHead>
      <TableRow>
        {columns.map((column, index) => (
          <StyledTableCell key={index} align={column.align}>
            {column.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function CustomizedTableBody({ rows, columns }) {
  return (
    <TableBody>
      {rows.map((row, index) => (
        <StyledTableRow key={'0'+ index}>
          {columns.map((column, i2) => (
            <StyledTableCell key={'00'+ i2} align={column.align}>
              {
                column.id === 'sno'
                ?
                index+1+'.'
                :
                row[column.id]
              }
            </StyledTableCell>
          ))}
        </StyledTableRow>
      ))}
    </TableBody>
  );
}

function WingsAccordionTable({ columns, rows }) {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <CustomizedTableHead columns={columns} />
        <CustomizedTableBody rows={rows} columns={columns} />
      </Table>
    </TableContainer>
  );
}

const columns = [
  { id: 'sno', label: 'S.No', align: 'left' },
  { id: 'number', label: 'Wing Number', align: 'left' },
  { id: 'title', label: 'Wing Title', align: 'left' },
  { id: 'no_of_floors', label: 'Total No. Of Floors', align: 'left' },
  { id: 'no_of_units', label: 'Total No. Of Units', align: 'left' },
];

export default function App({data}) {
  return <WingsAccordionTable columns={columns} rows={data} />;
}
