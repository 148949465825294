import React from "react";
import Modal from "./Modal";
const LeavePageModal = ({ isOpen, onCancel, onConfirm, loading }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      title={'Leave Page'}
      onCancel={onCancel}
      onConfirm={onConfirm}
      loading={loading}
    >
      <p className="flex justify-center font-semibold text-[16px]">Are you sure?</p>
      <p className=" mt-6 text-[16px] text-center">
        Do you want to save current data and leave this page ?
      </p>
    </Modal>
  );
};

export default LeavePageModal;
