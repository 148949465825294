import React, { useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader/Loader";
// import { getChequeDepositSlipReport } from "../../../redux/actions/FinanceActions/BillingReportsAction";
import parse from "html-react-parser";
import { getDeletedInvoiceReport } from "../../../../redux/actions/ReportActions/FinanceReportActions";
import moment from "moment";
import { validateDate } from "../../../../utils/helpers/universalFunctions";
import { printMyInvoiceBill } from "../../../../redux/actions/FinanceActions/MyInvoiceActions";
import { getDebiteNoteInvoicePrint } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageDebitNoteAction";

const PrintDeletedDebitNoteReport = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [headCells, setHeadCells] = useState([]);

  const { loginData } = useSelector((state) => state.loginReducer);
  const { printDebitInvoice, printLoader } = useSelector(
    (state) => state.manageDebitNoteReducer
  );

  useEffect(() => {
    if (!isNaN(id))
      dispatch(
        getDebiteNoteInvoicePrint({
          societyId: loginData?.default_community?.community_id,
          invid: [id],
        })
      );
  }, []);
  useEffect(() => {
    if (printDebitInvoice) {
      setTimeout(function () {
        window.print();
      }, 1000);
    }
  }, [printDebitInvoice]);

  return (
    <div>
      {printLoader && <Loader />}
      {printDebitInvoice ? (
        parse(printDebitInvoice)
      ) : (
        <div className="text-[11px] my-10 text-center italic">No Records</div>
      )}
    </div>
  );
};

export default PrintDeletedDebitNoteReport;
