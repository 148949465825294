import React, { useEffect } from "react";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { ONLINE_REGISTRATION_FORM_FOR_RENT_PAYMENT_ENDPOINT, RENT_REGISTRATION_LIST_ENDPOINT } from "../../../../Routing/routes";
import Layout from "../../../Layout/Layout";
import Search from "../../../../components/SearchBox/Search";
import {rentRegistrationStatusOptions} from '../../../../consts/DropDownOptions'
import RegRequestTable from "./RegRequestTable";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import { useDispatch, useSelector } from "react-redux";
import { getRentPaymentOnlineRegistrationList } from "../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/TenantsInfoAction";
import { getFlatDetails, getWingsAndFlatDetails } from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";

export const headingsRegRequest = [
  { id: 'sNo', label: 'S No.', sort: false, width: '50px' },
  { id: 'tenantName', label: 'Tenant Name', sort: true,width: '120px' },
  { id: 'flatNo', label: 'Flat', sort: true, width:'120px' },
  { id: 'aggrementFile', label: 'Aggrement File', sort: true, width:'130px' },
  { id: 'contact', label: 'Contact No', sort: true, width: '120px' },
  { id: 'email', label: 'Email', sort: true, width:'120px' },
  { id: 'amount', label: 'Rent Amount', sort: true, width:'110px'},
  { id: 'status', label: 'Status', sort: true, width:'80px' },
  {
    id: "action",
    label: "Action",
    sort: false,
  },
];

const OnlineRentRegistrationList = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {wingsData,flatsData} = useSelector(state=>state.wingsReducer)
  const {loading, rentPaymentOnlineRegistrationList} = useSelector(state=>state.tenantInfoReducer)
  const {loginData} = useSelector(state=>state.loginReducer)

  const handleBackClick = () => {
    navigate(`${ONLINE_REGISTRATION_FORM_FOR_RENT_PAYMENT_ENDPOINT}/:id`);
  };

  const handleSearch = (text)=>{
    if(text && text !== searchParams.get("search")){
      searchParams.delete('page')
      searchParams.delete('pageLimit')
      searchParams.delete("search")
      navigate({
        pathname: `${RENT_REGISTRATION_LIST_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["search", text]])}`,
      })
    }
  }

  const handleSelectWing = (selectedItem)=>{
    if(selectedItem.value?.toString() !== searchParams.get("wingId")){
      dispatch(getFlatDetails({societyId: loginData?.default_community?.community_id, wingId: selectedItem?.value}))
      searchParams.delete('page')
      searchParams.delete('pageLimit')
      searchParams.delete("wingId")
      searchParams.delete("flatId")
      navigate({
        pathname: `${RENT_REGISTRATION_LIST_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["wingId", selectedItem.value]])}`,
      })
    }
  }
  
  const handleSelectFlat = (selectedItem)=>{
    if(selectedItem.value?.toString() !== searchParams.get("flatId")){
      searchParams.delete('page')
      searchParams.delete('pageLimit')
      searchParams.delete("flatId")
      navigate({
        pathname: `${RENT_REGISTRATION_LIST_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["flatId", selectedItem.value]])}`,
      })
    }
  }
  

  const handleStatus = (selectedItem)=>{
    if(selectedItem.value !== searchParams.get("status")){
      searchParams.delete('page')
      searchParams.delete('pageLimit')
      searchParams.delete("status")
      navigate({
        pathname: `${RENT_REGISTRATION_LIST_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["status", selectedItem.value]])}`,
      })
    }
  }

  const getTableData =() =>{
    const data={
      societyId: loginData?.default_community?.community_id,
      page: searchParams.get("page") || 1,
      searchText: searchParams.get("search") || '',
      pageLimit: searchParams.get("pageLimit") || 10,
      status: searchParams.get("status") || '',
      wingId: searchParams.get("wingId") || '',
      flatId: searchParams.get("flatId") || '',
    }

    dispatch(getRentPaymentOnlineRegistrationList(data))
  }

  useEffect(()=>{      
    getTableData()
  }, [searchParams])

  useEffect(()=>{
    dispatch(getWingsAndFlatDetails({societyId: loginData?.default_community?.community_id}))

    if(searchParams.get("wingId")){
      dispatch(getFlatDetails({societyId: loginData?.default_community?.community_id, wingId: searchParams.get("wingId")}))
    }
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <ListHeaderBack
          onClick={handleBackClick}
          title="Online Rent Registration List"
        ></ListHeaderBack>
        <div className="border-b border-[#CCCCCC]"></div>
        <div className="p-2 -mb-2 flex flex-row items-center justify-between gap-2">
          <Search height="8" placeholder={'Search by ticket No.'} onclick={handleSearch} value={searchParams.get("search")} />
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <p>Filter</p>
              <Dropdown2
                options={wingsData?.map((item)=>({
                  value: item?.wing_id,
                  label: item?.number
                })) || []}
                placeholder="Select Wing"
                className="text-[11px] ms-4"
                width="180px"
                height="32px"
                onSelect={(selectedItem) => {
                  handleSelectWing(selectedItem)
                }}
                value={
                  searchParams.get("wingId") && {
                    value: searchParams.get("wingId"),
                    label: wingsData?.find((item) => item.wing_id?.toString() === searchParams.get("wingId"))?.number
                  }
                }
              />
              <Dropdown2
                options={searchParams.get('wingId') && flatsData?.manage_flat_details?.map((item)=>({
                  value: item?.flat_id,
                  label: item?.flat_no
                })) || []}
                placeholder="Select Flat"
                className="text-[11px] ms-4"
                width="180px"
                height="32px"
                onSelect={(selectedItem) => {
                  handleSelectFlat(selectedItem)
                }}
                value={
                  searchParams.get("flatId") && {
                    value: searchParams.get("flatId"),
                    label: flatsData?.manage_flat_details?.find((item) => item.flat_id?.toString() === searchParams.get("flatId"))?.flat_no
                  }
                }
              />
              <Dropdown2
                options={rentRegistrationStatusOptions}
                placeholder="Select Status"
                className="text-[11px] ms-4"
                width="180px"
                height="32px"
                onSelect={(selectedItem) => {
                  handleStatus(selectedItem)
                }}
                value={
                  searchParams.get("status") && {
                    value: searchParams.get("status"),
                    label: rentRegistrationStatusOptions?.find((item) => item.value === searchParams.get("status"))?.label
                  }
                }
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-between gap-4 p-2">
          {/* <SocietyTable data={paginatedData} /> */}
          <RegRequestTable
            loading={loading}
            data={rentPaymentOnlineRegistrationList?.occupants_list?.map((item, index)=>({
              sNo: (((rentPaymentOnlineRegistrationList?.current_page_no - 1) * rentPaymentOnlineRegistrationList?.page_limit + 1)+index)?.toString()?.padStart(2, "0"),
              id: item?.registration_id || '-',
              tenantId: item?.tenant_id || '-',
              tenantName: item?.first_name + ' ' + item?.last_name || '-',
              tenantDetails: item?.ticket_no || '-',
              aggrementFile: item?.rent_agreement_url,
              contact: item?.mobile || '-',
              flatNo: item?.wing_no + '  ' + item?.flat_no || '-',
              email: item?.email,
              amount: item?.rent_amount || '-',
              status: item?.approval_status || '-',
            }))}
            columns={headingsRegRequest}
            checkbox={false}
            pagination={rentPaymentOnlineRegistrationList?.occupants_list?.length}
            totalCount={rentPaymentOnlineRegistrationList?.total_record}
            totalPages={rentPaymentOnlineRegistrationList?.total_pages}
            start={(rentPaymentOnlineRegistrationList?.current_page_no - 1) * rentPaymentOnlineRegistrationList?.page_limit + 1}
            end={(rentPaymentOnlineRegistrationList?.current_page_no - 1) * rentPaymentOnlineRegistrationList?.page_limit + rentPaymentOnlineRegistrationList?.occupants_list?.length}
            currentPage={rentPaymentOnlineRegistrationList?.current_page_no}
            rowsPerPage={searchParams.get("pageLimit") || 10}
            getTableData={getTableData}
          ></RegRequestTable>
          {!rentPaymentOnlineRegistrationList?.occupants_list?.length && !loading && (
            <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
              No Records
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default OnlineRentRegistrationList;
