import React, { useState } from "react";
import PropTypes from "prop-types";
import "../../../components/TableComponent/Table.css";
import { DeleteIcon } from "../../../assets";
import Dropdown2, { DropdownFM2 } from "../../../components/Dropdown/dropdown2";
import { BookingFrequencyTypeOptions } from "../../../consts/DropDownOptions";
import { TextInput, TextInputFM } from "../../../components/InputFields/TextInput";
import { FieldArray } from "formik";
import { useSelector } from "react-redux";



const RateChartTable = ({
  values, 
  setFieldValue,
  columns,

}) => {
  const  timeSlotList = useSelector(state=>state.bookAFacilityReducer.timeSlotList)


  return (

    <div className="table-container w-full">
      <table className="table-auto w-full b">
        <thead>
          <tr className="text-[13px] text-[#AAAAAA] text-left">
            {
              columns?.map((item, index)=>(
                <td className={`w-[${item.width}] font-semibold px-3`}>{item.label}</td>
              ))}
              <td width={'60px'}></td>
          </tr>
        </thead>
        <tbody>
          <tr className="text-xs">&nbsp;</tr>
          {<FieldArray
            name="rateChart"
            render={arrayHelpers => (
              <>
                {values.rateChart && values.rateChart.length > 0 && (
                  values.rateChart.map((row, index) => {
                  return (
                    <tr key={index} className="align-top" >
                      <td className='px-2'>
                        <div>
                        <DropdownFM2
                            options={BookingFrequencyTypeOptions}
                            placeholder="Select Frequency"
                            className=""
                            width="240px"
                            height="37.6px"
                            onSelect={(selectedItem) => {
                              setFieldValue(`rateChart.${index}.frequency`, selectedItem.value)
                            }}
                            name={`rateChart.${index}.frequency`}
                            {...(values.rateChart[index].frequency === "" && { value: null})}

                          />
                        </div>
                      </td>
                      <td className='px-2'>
                        <div>
                          <DropdownFM2
                            options={timeSlotList?.slots?.map((item=>({
                              label: item.slot,
                              value: item.id
                            })))}
                            placeholder="Select Slot"
                            className=""
                            width="240px"
                            height="37.6px"
                            onSelect={(selectedItem) => {
                              setFieldValue(`rateChart.${index}.timeSlotId`, selectedItem.value)
                            }}
                            name={`rateChart.${index}.timeSlotId`}
                            {...(values.rateChart[index].timeSlotId === "" && { value: null})}                                />
                        </div>
                      </td>
                      <td className='px-2'>
                        <TextInputFM
                          label=""
                          className="w-44"
                          placeholder="Enter"
                          type='number'
                          maxLength={15}
                          name={`rateChart.${index}.chargeForMember`}
                        />
                      </td>
                      <td className='px-2'>
                        <TextInputFM
                          label=""
                          className="w-44"
                          placeholder="Enter"
                          type='number'
                          maxLength={15}
                          name={`rateChart.${index}.chargeForNonMember`}
                        />
                      </td>
                      <td >
                        <button
                          type="button"

                        >
                          {
                            values.rateChart?.length>1 &&
                            <DeleteIcon
                              onClick={()=>arrayHelpers.remove(index)}
                              className="fill-red-650 mt-3 ml-2 cursor-pointer"
                            />
                          }
                        </button>
                      </td>
                    </tr>
                  );
                })
                )}
                <tr>
                <td
                  colSpan={columns?.length + 1}
                >
                  <div className="h-8 my-2 flex justify-center">
                    <button
                      type="button"
                      label="Add More"
                      onClick={() =>
                        arrayHelpers.push({
                          frequency: '',
                          timeSlotId: '',
                          chargeForNonMember: '',
                          chargeForMember: '',
                        })
                      }
                      className="text-red-650 font-semibold border-2 border-dashed border-red-650 p-2 bg-[#FDF6F6] flex justify-center rounded-md items-center"
                    >
                      Add More
                    </button>
                  </div>
                </td>
                </tr>
              </>
              )
            }
            ></FieldArray>
          }
        </tbody>
      </table>
    </div>
  );
};

RateChartTable.propTypes = {
  columns: PropTypes.array.isRequired,
  defaultSortField: PropTypes.string,
  rowsPerPageOptions: PropTypes.array,
};

export default RateChartTable;
