import { call, put, takeLatest } from 'redux-saga/effects'
import {notify} from '../../../../utils/notification'
import * as types from '../../../actions/index.types'
import * as req from '../../../../axios/request/SetupRecordsReqs/StaffManagementReqs/ManagingCommitteeReqs'
import {getFirebaseURL, uploadImageOnFirebase} from '../../../../firebase/firebaseFunction'
import { managingCommitteeDocsPath } from '../../../../firebase/getFirebasePath'

function* getManagingCommitteeDetailedList(action) {
    try {
        const result = yield call(req.getManagingCommitteeDetailedListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_MANAGING_COMMITTIEE_DETAILED_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_MANAGING_COMMITTIEE_DETAILED_LIST_FAILURE, payload: action.data, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}


function* addManagingCommittee(action) {
    try {
        if(typeof action.data.resolutionDoc !== 'string'){
            let path = managingCommitteeDocsPath({societyId: action.data.societyId, fileName: action.data.resolutionDoc?.name});
            yield uploadImageOnFirebase(action.data.resolutionDoc, path)
            action.data.resolutionDoc= path;
        }

        const result = yield call(req.addManagingCommitteeReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.ADD_MANAGING_COMMITTIEE_SUCCESS, payload: action.data});  
            action.data?.onSuccessCallback?.()
        }else{
            yield put({ type: types.ADD_MANAGING_COMMITTIEE_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}

function* getOccupantsForManagingCommittee(action) {
    try {
        const result = yield call(req.getOccupantsForManagingCommitteeReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_OCCUPANTS_FOR_MANAGING_COMMITTIEE_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_OCCUPANTS_FOR_MANAGING_COMMITTIEE_FAILURE, payload: action.data, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getAllManagingCommitteeAuditLog(action) {
    try {
        const result = yield call(req.getAllManagingCommitteeAuditLogReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_ALL_COMMITTEE_AUDIT_LOG_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_ALL_COMMITTEE_AUDIT_LOG_FAILURE, payload: action.data, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getManagingCommitteeAuditLog(action) {
    try {
        const result = yield call(req.getManagingCommitteeAuditLogReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_MANAGING_COMMITTIEE_AUDIT_LOGS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_MANAGING_COMMITTIEE_AUDIT_LOGS_FAILURE, payload: action.data, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getManagingCommitteeDetails(action) {
    try {
        const result = yield call(req.getManagingCommitteeDetailsReq, action);
        if (result.statusCode === 200) {
            result.data.resolution_doc_url= yield getFirebaseURL(result.data?.resolution_doc)
            yield put({ type: types.GET_MANAGING_COMMITTIEE_DETAILS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_MANAGING_COMMITTIEE_DETAILS_FAILURE, payload: action.data, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getManagingCommitteeArhiveMember(action) {
    try {
        const result = yield call(req.getManagingCommitteeArhiveMemberReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_ARCHIVE_MANAGING_COMMITTIEE_MEMBER_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_ARCHIVE_MANAGING_COMMITTIEE_MEMBER_FAILURE, payload: action.data, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateManagingCommittee(action) {
    try {
        if(typeof action.data.resolutionDoc !== 'string'){
            let path = managingCommitteeDocsPath({societyId: action.data.societyId, fileName: action.data.resolutionDoc?.name});
            yield uploadImageOnFirebase(action.data.resolutionDoc, path)
            action.data.resolutionDoc= path;
        }
        
        const result = yield call(req.updateManagingCommitteeReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.UPDATE_MANAGING_COMMITTIEE_SUCCESS, payload: action.data});  
            action.data?.onSuccessCallback?.()
            const newData = yield call(req.getManagingCommitteeDetailsReq, action)
            if (newData.statusCode === 200){
                newData.data.resolution_doc_url= yield getFirebaseURL(newData.data?.resolution_doc)
                yield put({ type: types.GET_MANAGING_COMMITTIEE_DETAILS_SUCCESS, payload: newData.data});  
            }
        }else{
            yield put({ type: types.UPDATE_MANAGING_COMMITTIEE_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}

function* getManagingCommitteeAccessSetting(action) {
    try {
        const result = yield call(req.getManagingCommitteeAccessSettingReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_MANAGING_COMMITTIEE_ACCESS_SETTING_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_MANAGING_COMMITTIEE_ACCESS_SETTING_FAILURE, payload: action.data, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateManagingCommitteeAccessSetting(action) {
    try {
        const result = yield call(req.updateManagingCommitteeAccessSettingReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.UPDATE_MANAGING_COMMITTIEE_ACCESS_SETTING_SUCCESS, payload: action.data});  
            action.data?.onSuccessCallback?.()
        }else{
            yield put({ type: types.UPDATE_MANAGING_COMMITTIEE_ACCESS_SETTING_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}

function* deleteManagingCommittee(action) {
    try {
        const result = yield call(req.deleteManagingCommitteeReq, action);
        if (result.statusCode === 200) {
            notify('success', 'Data deleted successfully.');
            action.data?.onSuccessCallback?.()
            yield put({ type: types.DELETE_MANAGING_COMMITTIEE_SUCCESS, payload: action.data});  
            const newManagingCommitteeList = yield call(req.getManagingCommitteeDetailedListReq, action)
            if (newManagingCommitteeList.statusCode === 200)
                yield put({ type: types.GET_MANAGING_COMMITTIEE_DETAILED_LIST_SUCCESS, payload: newManagingCommitteeList.data});  
        }
    } catch (e) {
        console.log(e)
    }
}

function* resignFromManagingCommittee(action) {
    try {
        const result = yield call(req.resignFromManagingCommitteeReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.RESIGN_MANAGING_COMMITTIEE_SUCCESS, payload: action.data});  
            action.data?.onSuccessCallback?.()
        }else{
            yield put({ type: types.RESIGN_MANAGING_COMMITTIEE_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}

function* retireFromManagingCommittee(action) {
    try {
        const result = yield call(req.retireFromManagingCommitteeReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.RETIRE_MANAGING_COMMITTIEE_SUCCESS, payload: action.data});  
            action.data?.onSuccessCallback?.()
        }else{
            yield put({ type: types.RETIRE_MANAGING_COMMITTIEE_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}


function* getArchivedCommitteeMemberData(action) {
    try {
        const result = yield call(req.getArchivedCommitteeMemberDataReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.VIEW_ARCHIVED_MANAGING_COMMITTEE_MEMBER_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.VIEW_ARCHIVED_MANAGING_COMMITTEE_MEMBER_FAILURE, payload: action.data, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}


function* deleteArchivedCommitteeMember(action) {
    try {
        const result = yield call(req.deleteArchivedCommitteeMemberReq, action);
        if (result.statusCode === 200) {
            notify('success', 'Data deleted successfully.');
            action.data?.onSuccessCallback?.()
            yield put({ type: types.DELETE_ARCHIVED_MANAGING_COMMITTEE_MEMBER_SUCCESS, payload: action.data});  
            const newArchiveMemberList = yield call(req.getManagingCommitteeArhiveMemberReq, action)
            if (newArchiveMemberList.statusCode === 200)
                yield put({ type: types.GET_ARCHIVE_MANAGING_COMMITTIEE_MEMBER_SUCCESS, payload: newArchiveMemberList.data});  
        }
    } catch (e) {
        console.log(e)
    }
}




function* approveAndRejectCommitteeMemberResignation(action) {
    try {
        if(typeof action.data.approveDoc !== 'string'){
            let path = managingCommitteeDocsPath({societyId: action.data.societyId, fileName: action.data.approveDoc?.name});
            yield uploadImageOnFirebase(action.data.approveDoc, path)
            action.data.approveDoc= path;
        }

        const result = yield call(req.approveAndRejectCommitteeMemberResignationReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.APPROVE_AND_REJECT_MANAGING_COMMITTEE_MEMBER_RESIGNATION_SUCCESS, payload: action.data});  
            action.data?.onSuccessCallback?.()
        }else{
            yield put({ type: types.APPROVE_AND_REJECT_MANAGING_COMMITTEE_MEMBER_RESIGNATION_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}

function* approveCommitteeMemberRetirement(action) {
    try {
        
        const result = yield call(req.approveCommitteeMemberRetirementReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.APPROVE_MANAGING_COMMITTEE_MEMBER_RETIREMENT_SUCCESS, payload: action.data});  
            action.data?.onSuccessCallback?.()
        }else{
            yield put({ type: types.APPROVE_MANAGING_COMMITTEE_MEMBER_RETIREMENT_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}

function* reappointCommitteeMemberRetirement(action) {
    try {
        if(typeof action.data.requiredDoc !== 'string'){
            let path = managingCommitteeDocsPath({societyId: action.data.societyId, fileName: action.data.requiredDoc?.name});
            yield uploadImageOnFirebase(action.data.requiredDoc, path)
            action.data.requiredDoc= path;
        }


        const result = yield call(req.reappointCommitteeMemberRetirementReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.REAPPOINT_MANAGING_COMMITTEE_MEMBER_SUCCESS, payload: action.data});  
            action.data?.onSuccessCallback?.()
        }else{
            yield put({ type: types.REAPPOINT_MANAGING_COMMITTEE_MEMBER_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}

export default function* ManagingCommitteeSaga() {
    yield takeLatest(types.GET_MANAGING_COMMITTIEE_DETAILED_LIST, getManagingCommitteeDetailedList);
    yield takeLatest(types.ADD_MANAGING_COMMITTIEE, addManagingCommittee);
    yield takeLatest(types.GET_OCCUPANTS_FOR_MANAGING_COMMITTIEE, getOccupantsForManagingCommittee);
    yield takeLatest(types.GET_ALL_COMMITTEE_AUDIT_LOG, getAllManagingCommitteeAuditLog);
    yield takeLatest(types.GET_MANAGING_COMMITTIEE_AUDIT_LOGS, getManagingCommitteeAuditLog);
    yield takeLatest(types.GET_MANAGING_COMMITTIEE_DETAILS, getManagingCommitteeDetails);
    yield takeLatest(types.GET_ARCHIVE_MANAGING_COMMITTIEE_MEMBER, getManagingCommitteeArhiveMember);
    yield takeLatest(types.UPDATE_MANAGING_COMMITTIEE, updateManagingCommittee);
    yield takeLatest(types.GET_MANAGING_COMMITTIEE_ACCESS_SETTING, getManagingCommitteeAccessSetting);
    yield takeLatest(types.UPDATE_MANAGING_COMMITTIEE_ACCESS_SETTING, updateManagingCommitteeAccessSetting);
    yield takeLatest(types.DELETE_MANAGING_COMMITTIEE, deleteManagingCommittee);
    yield takeLatest(types.RESIGN_MANAGING_COMMITTIEE, resignFromManagingCommittee);
    yield takeLatest(types.RETIRE_MANAGING_COMMITTIEE, retireFromManagingCommittee);
    yield takeLatest(types.VIEW_ARCHIVED_MANAGING_COMMITTEE_MEMBER, getArchivedCommitteeMemberData);
    yield takeLatest(types.DELETE_ARCHIVED_MANAGING_COMMITTEE_MEMBER, deleteArchivedCommitteeMember);
    yield takeLatest(types.APPROVE_AND_REJECT_MANAGING_COMMITTEE_MEMBER_RESIGNATION, approveAndRejectCommitteeMemberResignation);
    yield takeLatest(types.APPROVE_MANAGING_COMMITTEE_MEMBER_RETIREMENT, approveCommitteeMemberRetirement);
    yield takeLatest(types.REAPPOINT_MANAGING_COMMITTEE_MEMBER, reappointCommitteeMemberRetirement);

}