import { SOCIETY_FORGOT_PASSWORD } from '../index.types';


export function societyForgotPassword(data) {
    return {
        type: SOCIETY_FORGOT_PASSWORD,
        data
    };
}

