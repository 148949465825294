import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SearchIcon } from '../../assets';


function Search({ placeholder, width, height, className, value, onclick, onChange=undefined}) {
  const [search, setSearch] = useState(value|| '')

  const handleSearch = (e)=>{
    setSearch(e.target.value); 
    onChange?.(e.target.value)
  }

  const handleEnterKey= (e)=>{
    if(e.key === "Enter"){
      onclick(search)
    }
  }

  useEffect(()=>{
      setSearch(value || '')
  }, [value])
  
  return (
    <div className={`${className} flex text-sm p-1 indent-1 bg-[#F9FAFC] border border-[#CCCCCC] rounded-lg h-${height}`}>
      <input
        type="text"
        placeholder={placeholder}
        className={`rounded-lg border-none outline-none bg-[#F9FAFC] w-${width}`}
        value={search}
        onChange={handleSearch}
        onKeyPress={handleEnterKey}
      />
      <button className='ps-2' type='button'>
        {/* <img src={search} alt="search" className="h-5 w-5" /> */}
        <SearchIcon className='h-[14px] w-[14px]' onClick={(e)=>{onclick(search)}} />
      </button>
    </div>
  );
}

Search.propTypes = {
  placeholder: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

Search.defaultProps = {
  placeholder: 'Search...',
  width: 'full', 
  height: '8',  
};

export default Search;
