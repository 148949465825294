import React from "react";
import "./forgotpass.css";
import AuthContainer from "../AuthContainer"; 
import ResetPasswordForm from "./ForgotPasswordForm";

const ForgotPassword = () => {
  return (
    <AuthContainer>
      <ResetPasswordForm />
    </AuthContainer>
  );
};

export default ForgotPassword;
