import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TextInputFM } from "../../../../../components/InputFields/TextInput";
import Layout from "../../../../Layout/Layout";
import Button from "../../../../../components/Button/Button";
import ButtonG from "../../../../../components/Button/ButtonG";
import {DropdownFM2} from "../../../../../components/Dropdown/dropdown2";
import { MAINTENANCE_STAFF_LIST_ENDPOINT } from "../../../../../Routing/routes";
import {TextAreaFM } from "../../../../../components/InputFields/TextArea";
import ListHeader from "../../../../../components/ListHeader/ListHeader";
import { UploadButtonFM } from "../../../../../components/Button/UploadButton";
import { namePrefixOptions } from "../../../../../consts/DropDownOptions";
import { Form, Formik } from "formik";
import { AddUpdateMaintenanceStaffValidation } from "../../../../../validationSchema/setupRecordsSchema/staffManagementSchema";
import { useDispatch, useSelector } from "react-redux";
import { addUpdateMaintenanceStaff, getAgencyList } from "../../../../../redux/actions/SetupRecordsActions/StaffManagementActions/MaintenanceAgencyAction";
import countries from "../../../../../consts/countries";
import { CircularProgress } from "@mui/material";

const AddMaintenanceStaff = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const {agencyList} = useSelector(state=>state.agencyReducer)
  const {loginData} = useSelector(state=>state.loginReducer)
  const {ipAddress} = useSelector(state=>state.ipAddressReducer)
  const {updateLoader} = useSelector(state=>state.maintenanceStaffReducer)

  const handleCancelClick = () => {
    navigate(MAINTENANCE_STAFF_LIST_ENDPOINT);
  };


  const handleSaveClick = async(values, {resetForm, initialValues}) => {

    dispatch(addUpdateMaintenanceStaff({
      ...values, 
      ipAddress,
      societyId: loginData?.default_community?.community_id,
      sessionId: loginData?.session_id,
      onSuccessCallback: ()=>{
        resetForm()
      }
    }))
  };
  useEffect(()=>{
    dispatch(getAgencyList({societyId: loginData?.default_community?.community_id}))
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
      <Formik
          initialValues={{
            prefix: '',
            firstName: '',
            lastName: '',
            responsibility: '',
            title: '',
            agencyId: '',
            countryId: '',
            mobileCountryCode: '',
            countryISO: '',
            mobileNo: '',
            email:  '',
            otherDetails:  '',
            idCard: '',
            profilePic: ''
          }}
          onSubmit={handleSaveClick}
          validationSchema={AddUpdateMaintenanceStaffValidation}
          >    
          {
            ({values, dirty, setFieldValue})=>{
              return (
                <Form>
                  <div className="border-b border-[#CCC]">
                    <ListHeader leftContent="Add Maintenance Staff">
                      <ButtonG
                        label="Cancel"
                        onClick={handleCancelClick}
                        className="h-8"
                        type={'button'}
                      />
                      <Button label={
                            <span className="w-12 inline-block">
                              {
                                updateLoader ?
                                  <CircularProgress sx={{color: "white"}} size={17}/>
                                  :
                                  'Save'
                                }
                            </span>
                            } 
                        className=" h-8" type='submit'
                        disabled={updateLoader}
                     />
                    </ListHeader>
                  </div>
                  <div className="flex gap-4 p-2">
                    <div className="flex flex-row justify-between w-full gap-7">
                      <div className="flex flex-col w-1/2">
                        <div className="flex justify-between text-sm items-center">
                          <span className="mb-[11px]">First Name</span>
                          <div className="flex gap-2">
                            <DropdownFM2
                              options={namePrefixOptions}
                              width={"73px"}
                              className="text-xs"
                              placeholder={"Select"}
                              onSelect={(selectedItem)=>{
                                setFieldValue(`prefix`, selectedItem.value)
                              }}
                              height='38px'
                              name={`prefix`}
                              {...(values.prefix ==='' && {value: null})}
                            />
                            <TextInputFM
                              label=""
                              placeholder="Enter First Name"
                              className="w-36"
                              name={`firstName`}
                              maxLength={20}
                              />
                          </div>
                        </div>

                        <TextInputFM
                            label="Title"
                            placeholder="Enter Title"
                            className="w-56"
                            name={`title`}
                            maxLength={50}
                          />
                          <div className="flex  justify-between text-sm">
                            <span className="mt-2">Country</span>
                            <DropdownFM2
                              options={ countries?.map((item)=>({
                                label: `${item?.name?.toUpperCase()} (${item?.phonecode})`,
                                value: item?.phonecode?.replace("+",""),
                                iso: item.iso,
                                id: item?.id
                              })) || []}
                              width={"224px"}
                              className="text-xs"
                              placeholder={"Select Country"}
                              onSelect={(selectedItem)=>{
                                setFieldValue(`mobileCountryCode`, selectedItem.value)
                                setFieldValue(`countryId`, selectedItem.id)
                                setFieldValue(`countryISO`, selectedItem.iso)
                              }}
                              height='38px'
                              name={`mobileCountryCode`}
                              {...(values.mobileCountryCode==='' && {value: null})}
                            />
                          </div>
                          <TextInputFM
                            label="Mobile Number"
                            placeholder="Enter Number"
                            className="w-56"
                            name={`mobileNo`}
                            type='number'
                            maxLength={20}
                          />
                        <div className="w-full">
                          <TextAreaFM
                            label="Other Details"
                            placeholder="Enter Details"
                            className={"w-56 h-[75px]"}
                            name={`otherDetails`}
                          />
                        </div>
                        <div className="flex items-center justify-between mb-4">
                          <span className="text-sm">Agency Name</span>
                          <DropdownFM2
                            options={agencyList?.list?.map((item)=>({
                              value: item?.agency_id,
                              label: item?.agency_name
                            })) || []}
                            width={"224px"}
                            className="text-xs"
                            placeholder={"Select Agency"}
                            onSelect={(selectedItem)=>{
                              setFieldValue(`agencyId`, selectedItem.value)
                            }}
                            height='38px'
                            name={`agencyId`}
                            noOptionsMessage={!agencyList?.list?.length ? 'Please add agency first' : ''}
                            {...(values.agencyId ==='' && {value: null})}
                          />
                        </div>
                        <div className="flex justify-between mb-4 items-center">
                          <span className="text-sm">Image Upload</span>
                          <div className=" w-1/2 -mr-2">
                          <UploadButtonFM showFileName={true} 
                            name={`profilePic`}
                            accept={"image/jpeg,image/gif,image/png,application/pdf,image/x-eps"}
                            onSelect={(e)=>setFieldValue(`profilePic`, e)} 
                            fileName={values.profilePic?.name}
                          />
                          </div>
                        </div>
                        <div className="flex justify-between mb-4 items-center">
                          <span className="text-sm">ID Card</span>
                          <div className=" w-1/2 -mr-2">
                          <UploadButtonFM showFileName={true} 
                            name={`idCard`}
                            accept={"image/jpeg,image/gif,image/png,application/pdf,image/x-eps"}
                            onSelect={(e)=>setFieldValue(`idCard`, e)} 
                            fileName={values.idCard?.name}
                          />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col w-1/2 ">
                        <TextInputFM
                          label="Last Name"
                          placeholder="Enter Last Name"
                          className="w-56"
                          name={`lastName`}
                          type='alphaNumber'
                          maxLength={20}
                        />
                        <div className="">
                          <TextInputFM
                            label="Responsibility"
                            placeholder="Enter Responsibility"
                            className="w-56"
                            name={`responsibility`}
                            type='alphaNumber'
                            maxLength={50}
                          />
                          <TextInputFM
                            label="Email ID (Optional)"
                            placeholder="Enter Email"
                            className="w-56"
                            name={`email`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
          }
          </Formik>
      </div>
    </Layout>
  );
};

export default AddMaintenanceStaff;
