import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getArchivedCommitteeMemberData } from '../../../../redux/actions/SetupRecordsActions/StaffManagementActions/ManangingCommitteeAction'
import Layout from '../../../Layout/Layout'
import ListHeaderBack from '../../../../components/ListHeader/ListHeaderBack'
import { ARCHIVE_COMMITTEE_MEMBERS_ENDPOINT } from '../../../../Routing/routes'
import Loader from '../../../../components/Loader/Loader'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { validateDate } from '../../../../utils/helpers/universalFunctions'

function ViewArchiveCommitteeMember() {
    const {id, roleId} = useParams()
    const dispatch = useDispatch()
    const navigate= useNavigate()
    const {loading, archivedMembersDetails} = useSelector(state=> state.managingCommitteeReducer)

    const handleBackClick = ()=>{
        navigate(ARCHIVE_COMMITTEE_MEMBERS_ENDPOINT)
    }

    useEffect(()=>{
        dispatch(getArchivedCommitteeMemberData({committeeId: id, roleId}))
    }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 mb-5">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack
            onClick={handleBackClick}
            title="View Archive Committe Member Details"
          ></ListHeaderBack>
        </div>
        <div className="px-2">
          {
            loading && id !== archivedMembersDetails?.emp_id?.toString() ?
            <Loader/>
            :
            <table className="w-full">
              <tbody className="text-sm align-top text-justify">
                  <tr>
                    <td className="text-[#AAAAAA] font-semibold w-48 py-2.5">Member Name:</td>
                    <td className='py-2.5 break-all capitalize'>{archivedMembersDetails?.subscriber_name || '-'}</td>
                  </tr>
                  <tr>
                    <td className="text-[#AAAAAA] font-semibold w-48 py-2.5">Roles And Responsibility:</td>
                    <td className='py-2.5 break-all'>{archivedMembersDetails?.roles || '-'}</td>
                  </tr>
                  <tr>
                    <td className="text-[#AAAAAA] font-semibold w-48 py-2.5">Join Date:</td>
                    <td className='py-2.5 break-all'>{validateDate(archivedMembersDetails?.join_date) ? moment(new Date(archivedMembersDetails?.join_date))?.format('DD-MM-YYYY') : '-'}</td>
                  </tr>
                  <tr>
                    <td className="text-[#AAAAAA] font-semibold w-48 py-2.5">Leave Date:</td>
                    <td className='py-2.5 break-all'>
                      {
                        archivedMembersDetails?.resign_retire_status === 'RES'?
                        (validateDate(archivedMembersDetails?.resign_date) ? moment(new Date(archivedMembersDetails?.resign_date))?.format('DD-MM-YYYY') : '-'):
                        (validateDate(archivedMembersDetails?.retire_date) ? moment(new Date(archivedMembersDetails?.retire_date))?.format('DD-MM-YYYY') : '-')
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className="text-[#AAAAAA] font-semibold w-48 py-2.5">Resignation/Retire Details</td>
                    <td className='py-2.5 break-all' ><div  dangerouslySetInnerHTML={{__html: new DOMParser().parseFromString(archivedMembersDetails?.leave_details, 'text/html').body.textContent}}></div></td>
                  </tr>
                  <tr>
                    <td className="text-[#AAAAAA] font-semibold w-48 py-2.5">Remarks:</td>
                    <td className='py-2.5 break-all'>{archivedMembersDetails?.remarks || '-'}</td>
                  </tr>
                  <tr>
                    <td className="text-[#AAAAAA] font-semibold w-48 py-2.5">Effective Date:</td>
                    <td className='py-2.5 break-all'>
                      {
                        archivedMembersDetails?.resign_retire_date ?
                        moment(new Date(archivedMembersDetails?.resign_retire_date))?.format('DD-MM-YYYY') : '-'
                      }
                    </td>
                  </tr>
              </tbody>
            </table>
          }
        </div>
      </div>
    </Layout>
  )
}

export default ViewArchiveCommitteeMember