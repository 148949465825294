import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import {
  DeleteIcon,
  SortIcon,
  EditIcon,
  ViewIcon,
  AuditIcon,
} from "../../../../assets/index";
import styled from "@emotion/styled";
import ItemsPerPage from "../../../../components/ItemsPerPage/ItemsPerPage";
import Pagination from "../../../../components/Pagination/Pagination";
import {
  stableSort,
  getComparator,
} from "../../../../utils/helpers/universalFunctions";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import DeleteModal from "../../../../components/Modal/DeleteModal";
import Loader from "../../../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { Box, Tooltip } from "@mui/material";
import { AUDIT_EMPLOYEE_DETAILS_ENDPOINT, EDIT_EMPLOYEE_DETAILS_ENDPOINT, EMPLOYEE_ENDPOINT, VIEW_EMPLOYEE_DETAILS_ENDPOINT } from "../../../../Routing/routes";
import ChangeStatusModal from "../../../../components/Modal/ChangeStatusModal";
import { changeEmployeeStatus, deleteEmployee } from "../../../../redux/actions/SetupRecordsActions/StaffManagementActions/EmployeeManagementAction";
import { getPermission } from "../../../../utils/helpers/getPermissions";
import * as permissions from '../../../../Routing/permissions'

function ActionComponent({ id, employeeId }) {
    const navigate = useNavigate();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const {loginData} = useSelector(state=>state.loginReducer)
    const [searchParams, setSearchParams] = useSearchParams()
    const dispatch = useDispatch()

    const handleDeleteClick = () => {
      setShowDeleteModal(true);
    };

  
    const handleDelete = ()=>{
      let data = {
        employeeDetailsId: id, 
        societyId: loginData?.default_community?.community_id,
        page: searchParams.get("page") || 1,
        searchText: searchParams.get("search") || '',
        pageLimit: searchParams.get("pageLimit") || 10,
        onSuccessCallback: ()=>{
          setShowDeleteModal(false);
        }
      }
      dispatch(deleteEmployee(data))
    }
  
    return (
        <Box sx={{ display: "flex", alignItems: "center"}}>
            {
              getPermission(loginData, [permissions.VIEW_EMPLOYEE_PERMISSION]) &&
              <Tooltip title="View">
                <ViewIcon
                    className="fill-[#555] hover:fill-red-650 me-3"
                    onClick={() => {navigate(`${VIEW_EMPLOYEE_DETAILS_ENDPOINT}/${employeeId}`)}}
                />
              </Tooltip>
            }
            {
              getPermission(loginData, [permissions.EDIT_EMPLOYEE_PERMISSION]) &&
              <Tooltip title="Edit">
                <EditIcon
                    className="fill-[#555] hover:fill-red-650 me-3"
                    onClick={() => {navigate(`${EDIT_EMPLOYEE_DETAILS_ENDPOINT}/${employeeId}/${id}`)}}
                />
              </Tooltip>
            }
            {
              getPermission(loginData, [permissions.VIEW_EMPLOYEE_AUDIT_LOGS_PERMISSION]) &&
              <Tooltip title="Audit Log">
                  <AuditIcon
                  className="fill-[#555] hover:fill-red-650 me-3"
                  onClick={() => {navigate(`${AUDIT_EMPLOYEE_DETAILS_ENDPOINT}/${employeeId}`)}}
                  />
              </Tooltip>
            }
            {
              getPermission(loginData, [permissions.DELETE_EMPLOYEE_PERMISSION]) &&
              <Tooltip title="Delete">
                  <DeleteIcon
                  className="fill-[#555] hover:fill-red-650 me-3  w-[14px]"
                  onClick={() => handleDeleteClick()}
                  />
              </Tooltip>
            }
    
            {/* Delete Confirmation Modal */}
            <DeleteModal
            isOpen={showDeleteModal}
            onCancel={() => setShowDeleteModal(false)}
            onDelete={handleDelete}
            />
        </Box>
    );
  }

  const TableRowStyled = styled(TableRow)`
    &:nth-of-type(odd) {
      background-color: #ffffff;
    }
    &:nth-of-type(even) {
      background-color: #f4f5f8;
    }
    &:nth-of-type(odd):hover {
      background-color: #ffffff;
    }
    &:nth-of-type(even):hover {
      background-color: #f4f5f8;
    }
  `;

  const TableCellStyled = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontWeight: 600,
      zIndex: 0,
      color: "#AAAAAA",
      fontSize: "13px",
      padding: '12px 8px 12px 0',
      wordBreak: "break-word",
      fontSize: "13px",
    },
    [`&.${tableCellClasses.body}`]: {
      borderBottom: "none",
      fontSize: "13px",
      padding: '12px 8px 12px 0',
      wordBreak: "break-word",
      fontSize: "13px",
      cursor: "pointer"
    },
  }));
  
const EmployeeListTable = ({
  loading,
  data,
  columns,
  checkbox = true,
  pagination = true,

  //props are required if pagination is true
  totalCount,
  totalPages,
  start,
  end,
  currentPage,
  rowsPerPage,
  getTableData,

}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const [selected, setSelected] = useState([])
  const {updateLoader} = useSelector(state=> state.employeeManagementReducer)
  const {loginData} = useSelector(state=>state.loginReducer)
  const [showChangeStatusModal, setShowChangeStatusModal] = useState({id: '', open: false});

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };


  const handleSelectAllClick = (event) => {
    const newSelected = event.target.checked ? data.map((item) => item.employeeDetailsId) : [];
    setSelected(newSelected);
  };

  const handleClick = (event, id) => {
    const newSelected = selected.includes(id)
      ? selected.filter((selectedId) => selectedId !== id)
      : [...selected, id];
    setSelected(newSelected);
  };


  const handleChangePage = (newPage) => {
      searchParams.delete("page")
      navigate({
        pathname: `${EMPLOYEE_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["page", newPage]])}`,
      })
  };

  const handleChangeRowsPerPage = (value) => {
    if (rowsPerPage !== value) {
      searchParams.delete("pageLimit")
      navigate({
        pathname: `${EMPLOYEE_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["pageLimit", value]])}`,
      })
    }
  };

  const isSelected = (id) => selected.includes(id);

  const handleChangeStatus = (id, status)=>{
    let data={
      employeeDetailsId: id, 
      status: status==='Active' ? 'I' : 'A',
      onRequestEndCallback: ()=>{
        setShowChangeStatusModal(false)
      }
    };
    dispatch(changeEmployeeStatus(data))
  }

  useEffect(()=>{      
    setSelected([])
  }, [searchParams])

  return (
    <Box
      sx={{ width: "100%", fontSize: "13px" }}
      className="table-component"
    >
      <TableContainer
        className="border-t border-[#CCC]"
        sx={{minHeight: data?.length ? "500px" : 'none'}}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {!!checkbox && (
                <TableCellStyled size="small" sx={{pl:'0 !important', pr: 2, width: 36 }}>
                  <Checkbox
                    size="small"
                    indeterminate={false}
                    checked={data?.length > 0 && selected?.length === data?.length}
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all",
                    }}
                    sx={{
                      p: 0,
                      color: "#AAAAA",
                      "&.Mui-checked": {
                        color: "#CF001C",
                      },
                    }}
                    disabled={loading || !data}
                  />
                </TableCellStyled>
              )}
              {columns?.map((column) => (
                <TableCellStyled
                  size="small"
                  key={column.id+ 'heading'}
                  align={column.numeric ? "right" : "left"}
                  sortDirection={orderBy === column.id ? order : false}
                  sx={{
                    width: column.width || "fit-content",
                    minWidth: column.width || "fit-content",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={() => {
                      if (column.sort) {
                        handleRequestSort(column.id);
                      }
                    }}
                    IconComponent={SortIcon}
                    sx={{
                      ".MuiTableSortLabel-icon": {
                        opacity: "1 !important",
                      },
                      svg: {
                        flexShrink: 0,
                      },
                    }}
                    hideSortIcon={!column.sort}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCellStyled>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {
                loading ?
                <TableRow>
                  <TableCellStyled sx={{border: 'none'}} colSpan={columns?.length+1}><Loader/></TableCellStyled>
                </TableRow>
                :
                <>
                  {!!data?.length && stableSort(data, getComparator(order, orderBy))
                    ?.map((row, rowIndex) => {
                      const isRowSelected = isSelected(row?.employeeDetailsId);

                      return (
                        <TableRowStyled
                          key={`${row?.userAccId}+${row?.flatId}+${rowIndex}`}
                          role="checkbox"
                          aria-checked={isRowSelected}
                          tabIndex={-1}
                          selected={isRowSelected}
                        >
                          {!!checkbox && (
                            <TableCellStyled size="small" sx={{pl:'0 !important', zIndex: 0}} onClick={(event) => handleClick(event, row?.employeeDetailsId)}>
                              <Checkbox
                                checked={isSelected(row?.employeeDetailsId)}
                                onChange={(e) =>handleClick(e, row?.employeeDetailsId)}
                                size="small"
                                color="primary"
                                inputProps={{
                                  "aria-labelledby": `generic-table-checkbox-${row?.id}`,
                                }}
                                sx={{
                                  p: 0,
                                  color: "#AAAAA",
                                  "&.Mui-checked": {
                                    color: "#CF001C",
                                  },
                                }}

                              />
                            </TableCellStyled>
                          )}
                          {/* Serial Number */}
                          <TableCellStyled size="small" align="left" onClick={(event) => handleClick(event, row?.employeeDetailsId)}>
                            { row.sNo}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left" sx={{textTransform: 'capitalize'}}>
                            {row?.name}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row?.type}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row.phoneNumber}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row.emailAddress}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            <div 
                                onClick={getPermission(loginData, [permissions.ACTIVE_INACTIVE_EMPLOYEE_PERMISSION]) ? ()=> setShowChangeStatusModal({id: row.employeeDetailsId, open: true}) : undefined} 
                                className={`underline font-semibold ${row.status==='Active' ? 'text-green-600' : (row.status === 'Inactive' ? 'text-red-500' : 'text-yellow-500')}`} >
                            {
                                row.status
                            }
                            </div>
                            <ChangeStatusModal
                                isOpen={showChangeStatusModal.open && row.employeeDetailsId === showChangeStatusModal.id}
                                onCancel={()=>setShowChangeStatusModal(false)}
                                onConfirm={()=>{handleChangeStatus(row.employeeDetailsId, row?.status)}}
                                text={`Do you want to change status to ${row?.status==='Active' ? 'Inactive' : 'Active'} ?`}
                                loading={updateLoader}
                              />
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            <ActionComponent id={row?.employeeDetailsId} getTableData={getTableData} employeeId={row.employeeId}/>
                          </TableCellStyled>
                        </TableRowStyled>
                      );
                    })}
                </>
              }
          </TableBody>
        </Table>
      </TableContainer>

      {!!pagination && data && (
        <div className="flex flex-row justify-between mt-3 pt-1 border-t border-[#ccc] text-[14px]">
          <ItemsPerPage
            rowsPerPage={rowsPerPage}
            onChange={handleChangeRowsPerPage}
          />
          <Pagination
            totalCount={totalCount}
            totalPages={totalPages}
            start={start}
            end={end}
            currentPage={currentPage}
            onChange={handleChangePage}
          />
        </div>
      )}
    </Box>
  );
};

EmployeeListTable.propTypes = {
  columns: PropTypes.array.isRequired,
  defaultSortField: PropTypes.string,
  rowsPerPageOptions: PropTypes.array,
};

export default EmployeeListTable;
