import * as types from '../../index.types';

export function getCreditDebitTariffHeaderList(data) {
    return {
        type: types.GET_TARIFF_HEADER_LIST,
        data
    };
}

export function getCreditDebitTariffHeaderDetails(data) {
    return {
        type: types.GET_TARIFF_HEADER_DETAILS,
        data
    };
}

export function getCreditDebitTariffHeaderAudit(data) {
    return {
        type: types.GET_TARIFF_HEADER_AUDIT_LOGS,
        data
    };
}

export function getCreditDebitTariffHeaderAllAudit(data) {
    return {
        type: types.GET_TARIFF_HEADER_ALL_AUDIT_LOGS,
        data
    };
}

export function updateCreditDebitTariffHeaders(data) {
    return {
        type: types.UPDATE_TARIFF_HEADER,
        data
    };
}

export function addCreditDebitTariffHeaders(data) {
    return {
        type: types.ADD_TARIFF_HEADER,
        data
    };
}

export function getGLMappingList(data) {
    return {
        type: types.GET_GL_MAPPING_LIST,
        data
    };
}

export function getAccountLedgerHeaderList(data) {
    return {
        type: types.GET_ACCOUNT_LEDGER_HEADER_LIST,
        data
    };
}

export function getDebitSetting(data) {
    return {
        type: types.GET_DEBIT_NOTE_SETTINGS,
        data
    };
}

export function updateDebitSettings(data) {
    return {
        type: types.UPDATE_DEBIT_NOTE_SETTINGS,
        data
    };
}


