import React, { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import moment from "moment";
import { toWords } from "number-to-words";
import { printNoticeCircular } from "../../../redux/actions/SocialAndCommunicationActions/NoticeAndCircularAction";
import Loader from "../../../components/Loader/Loader";
import { validateDate } from "../../../utils/helpers/universalFunctions";
import parse from "html-react-parser";

const PrintNoticeAndCircular = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, printNoticeCircularDetail } = useSelector(
    (state) => state.noticeAndCircularReducer
  );

  const getTableData = () => {
    const data = {
      id,
    };
    dispatch(printNoticeCircular(data));
  };

  useEffect(() => {
    getTableData();
    const afterprint = (e) => {
      e.preventDefault();
    };

    window.addEventListener("afterprint", afterprint);

    return () => {
      window.removeEventListener("afterprint", afterprint);
    };
  }, []);

  useEffect(() => {
    if (!loading && printNoticeCircularDetail) {
      setTimeout(() => {
        window.print();
      }, 200);
    }
  }, [loading]);

  return loading || !printNoticeCircularDetail ? (
    <Loader />
  ) : (
    <>
      <div className="p-1 max-w-[900px] mx-auto">
        {parse(printNoticeCircularDetail)}

        {!printNoticeCircularDetail && !loading && (
          <div className="text-sm my-10 text-center italic">No Records</div>
        )}
      </div>
    </>
  );
};

export default PrintNoticeAndCircular;
