import React, { useEffect, useState } from "react";
import { DefaultLogo, DeleteIcon } from "../../assets";
import { getFirebaseURL } from "../../firebase/firebaseFunction";
import { Skeleton } from "@mui/material";

const ProfilePicture = ({defaultURL, placeHolder = "Choose File", direction = "row", edit=true, onSelect=undefined, onDelete}) => {
  const [profilePicture, setProfilePicture] = useState(null);
  const [loader, setLoader] = useState(true);


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePicture(URL.createObjectURL(file));
    }
    if(onSelect){
      onSelect(file)
    }
  };

  const handleDeletePicture = () => {
    setProfilePicture(null);
    if(onDelete){
      onDelete()
    }
  };

  useEffect(()=>{
    const getURL = async()=>{
      const url = await getFirebaseURL(defaultURL);
      setLoader(prev=>{
        setProfilePicture(url)
        return false;
      })
    }
    if(defaultURL?.includes('https:')){
      setProfilePicture(defaultURL) 
      setLoader(false)
    }else if(defaultURL){
      getURL()
    }else{
      setProfilePicture(null)
      setLoader(false)
    }
  }, [defaultURL])

  return (
    <div className={`flex flex-${direction} ${direction==='col' && 'items-center'} justify-between text-[13px] font-bold text-[#AAAAAA]`}>
      {edit && <label className="mb-1 text-[#222] font-normal">Change Profile Image</label>}
      {
        profilePicture ? (
          <div className={`flex flex-${direction}`}>
            <div className={`relative flex flex-${direction}`}>
                {
                  loader ?
                  <Skeleton variant="rounded" width={128} height={128} />
                  :
                  <img
                    src={profilePicture}
                    alt="Profile"
                    className="w-32 h-32 rounded-lg object-cover"
                  />
                }
              {
                edit &&
                <button
                onClick={handleDeletePicture}
                className="absolute top-[50px] left-[50px] w-9 h-9 bg-white text-red-650 p-1 rounded-full"
              >
                <DeleteIcon className="fill-current text-red-650 hover:text-red-800 w-5 h-4 ml-1 cursor-pointer" />
              </button>
              }
            </div>
            {
              edit&&
              <div
                className={`flex flex-${direction} items-center justify-center ${
                  direction === "col" ? "" : "h-32 ml-6"
                }`}
              >
                <input
                  type="file"
                  id="profilePicture"
                  accept="image/*"
                  onChange={handleFileChange}
                  className="sr-only"
                />
                <label
                  htmlFor="profilePicture"
                  className="cursor-pointer text-red-650 text-[13px] rounded-lg font-semibold"
                >
                  {placeHolder}
                </label>
              </div>
            }
          </div>
        ) : (
          <div className={`relative flex flex-${direction}`}>
            {
              loader ?
                <Skeleton variant="rounded" width={128} height={128} />
                :
                <DefaultLogo className="h-32 w-32" />
            }
            {
              edit &&
              <div
                className={`flex flex-row justify-center items-center ${
                  direction === "col" ? "" : "h-32 ml-7"
                }`}
              >
                <input
                  type="file"
                  id="profilePicture"
                  accept="image/*"
                  onChange={handleFileChange}
                  className="sr-only"
                />
                <label
                  htmlFor="profilePicture"
                  className="cursor-pointer text-red-650 text-[13px] rounded-lg font-semibold"
                >
                  {placeHolder}
                </label>
              </div>
            }
          </div>
        )
      }
    </div>
  );
};

export default ProfilePicture;
