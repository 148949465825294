import { call, put, takeLatest } from 'redux-saga/effects'
import { notify } from '../../../../utils/notification'
import * as types from '../../../actions/index.types'
import * as req from '../../../../axios/request/SetupRecordsReqs/StaffManagementReqs/StaffPersonnelReqs'
import { societyStaffDocsPath } from '../../../../firebase/getFirebasePath';
import { getFirebaseURL, uploadImageOnFirebase } from '../../../../firebase/firebaseFunction';

function* getSocietyStaffList(action) {
    try {
        const result = yield call(req.getSocietyStaffListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_SOCIETY_STAFF_LIST_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_SOCIETY_STAFF_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getSocietyStaffProfileList(action) {
    try {
        const result = yield call(req.getSocietyStaffProfileListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_SOCIETY_STAFF_PROFILE_LIST_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_SOCIETY_STAFF_PROFILE_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getSocietyStaffDetails(action) {
    try {
        const result = yield call(req.getSocietyStaffDetailsReq, action);
        if (result.statusCode === 200) {
            const doc = [
                {
                    key: 'profile_image_url',
                    value: result.data?.staff_details?.profile_image
                },
                {
                    key: 'fringer_print_url',
                    value: result.data?.staff_details?.fringer_print
                },
                {
                    key: 'pan_image_url',
                    value: result.data?.staff_details?.pan_image
                },
                {
                    key: 'uid_image_url',
                    value: result.data?.staff_details?.uid_image
                },
                {
                    key: 'ration_image_url',
                    value: result.data?.staff_details?.ration_image
                },
                {
                    key: 'driving_licence_image_url',
                    value: result.data?.staff_details?.driving_licence_image
                },
            ]

            for (let item of doc) {
                if (typeof !item.value?.includes('https:'))
                    result.data.staff_details[item.key] = yield getFirebaseURL(item.value)
                else
                    result.data.staff_details[item.key] = item.value
            }

            yield put({ type: types.GET_SOCIETY_STAFF_DETAILS_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_SOCIETY_STAFF_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* addSocietyStaff(action) {
    try {
        let { profilePic, fingerPrintImg, adharDoc, panDoc, drivingLicenceDoc, rationDoc, societyId } = action.data;
        if (typeof profilePic !== 'string') {
            let path = societyStaffDocsPath({ societyId, fileName: profilePic?.name });
            action.data.profilePic = path
            yield uploadImageOnFirebase(profilePic, path)
        }

        if (typeof fingerPrintImg !== 'string') {
            let path = societyStaffDocsPath({ societyId, fileName: fingerPrintImg?.name });
            action.data.fingerPrintImg = path
            yield uploadImageOnFirebase(fingerPrintImg, path)

        }

        if (typeof adharDoc !== 'string') {
            let path = societyStaffDocsPath({ societyId, fileName: adharDoc?.name });
            action.data.adharDoc = path
            yield uploadImageOnFirebase(adharDoc, path)

        }

        if (typeof panDoc !== 'string') {
            let path = societyStaffDocsPath({ societyId, fileName: panDoc?.name });
            action.data.panDoc = path
            yield uploadImageOnFirebase(panDoc, path)

        }

        if (typeof rationDoc !== 'string') {
            let path = societyStaffDocsPath({ societyId, fileName: rationDoc?.name });
            action.data.rationDoc = path
            yield uploadImageOnFirebase(rationDoc, path)

        }

        if (typeof drivingLicenceDoc !== 'string') {
            let path = societyStaffDocsPath({ societyId, fileName: drivingLicenceDoc?.name });
            action.data.drivingLicenceDoc = path
            yield uploadImageOnFirebase(drivingLicenceDoc, path)

        }

        const result = yield call(req.addSocietyStaffReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.ADD_SOCIETY_STAFF_SUCCESS, payload: action.data });
            action.data?.onSuccessCallback?.()
        } else {
            yield put({ type: types.ADD_SOCIETY_STAFF_FAILURE });
        }
    } catch (e) {
        console.log(e)
    }
}

function* downloadSocietyStaffList(action) {
    try {
        const result = yield call(req.downloadSocietyStaffExcelSheetReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
        } else {
            notify('Unable to download sheet.')
        }
    } catch (e) {
    }
}

function* updateSocietyStaff(action) {
    try {
        let { profilePic, fingerPrintImg, adharDoc, panDoc, drivingLicenceDoc, rationDoc, societyId } = action.data;
        if (typeof profilePic !== 'string') {
            let path = societyStaffDocsPath({ societyId, fileName: profilePic?.name });
            action.data.profilePic = path
            yield uploadImageOnFirebase(profilePic, path)
        }

        if (typeof fingerPrintImg !== 'string') {
            let path = societyStaffDocsPath({ societyId, fileName: fingerPrintImg?.name });
            action.data.fingerPrintImg = path
            yield uploadImageOnFirebase(fingerPrintImg, path)

        }

        if (typeof adharDoc !== 'string') {
            let path = societyStaffDocsPath({ societyId, fileName: adharDoc?.name });
            action.data.adharDoc = path
            yield uploadImageOnFirebase(adharDoc, path)

        }

        if (typeof panDoc !== 'string') {
            let path = societyStaffDocsPath({ societyId, fileName: panDoc?.name });
            action.data.panDoc = path
            yield uploadImageOnFirebase(panDoc, path)

        }

        if (typeof rationDoc !== 'string') {
            let path = societyStaffDocsPath({ societyId, fileName: rationDoc?.name });
            action.data.rationDoc = path
            yield uploadImageOnFirebase(rationDoc, path)

        }

        if (typeof drivingLicenceDoc !== 'string') {
            let path = societyStaffDocsPath({ societyId, fileName: drivingLicenceDoc?.name });
            action.data.drivingLicenceDoc = path
            yield uploadImageOnFirebase(drivingLicenceDoc, path)

        }
        const result = yield call(req.updateSocietyStaffReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.ADD_SOCIETY_STAFF_SUCCESS, payload: action.data });
            action.data?.onSuccessCallback?.()
        } else {
            yield put({ type: types.ADD_SOCIETY_STAFF_FAILURE });
        }
    } catch (e) {
        console.log(e)
    }
}

function* deleteSocietyStaff(action) {
    try {
        const result = yield call(req.deleteSocietyStaffReq, action);
        if (result.statusCode === 200) {
            notify('success', 'Data deleted successfully.');
            action.data?.onSuccessCallback?.()
            yield put({ type: types.DELETE_SOCIETY_STAFF_SUCCESS, payload: action.data });
            const newStaffList = yield call(req.getSocietyStaffListReq, action)
            if (newStaffList.statusCode === 200)
                yield put({ type: types.GET_SOCIETY_STAFF_LIST_SUCCESS, payload: newStaffList.data });
        }
    } catch (e) {
        console.log(e)
    }
}


function* getSocietyWingFlatList(action) {
    try {
        const result = yield call(req.getSocietyWingFlatListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_SOCIETY_WING_FLAT_LIST_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_SOCIETY_WING_FLAT_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* printSocietyStaffIDCards(action) {
    try {
        const result = yield call(req.printSocietyStaffIDCardsReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.PRINT_SOCIETY_STAFF_CARD_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.PRINT_SOCIETY_STAFF_CARD_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}



function* getSocietyStaffRatingData(action) {
    try {
        const result = yield call(req.getSocietyStaffRatingDataReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_SOCIET_STAFF_RATING_DATA_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_SOCIET_STAFF_RATING_DATA_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getSocietyStaffRatingParameters(action) {
    try {
        const result = yield call(req.getSocietyStaffRatingParametersReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_SOCIET_STAFF_RATING_PARAMETERS_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_SOCIET_STAFF_RATING_PARAMETERS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateSocietyStaffRating(action) {
    try {
        const result = yield call(req.updateSocietyStaffRatingReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.UPDATE_SOCIET_STAFF_RATING_DATA_SUCCESS, payload: action.data });
            action.data?.onSuccessCallback?.()
        } else {
            yield put({ type: types.UPDATE_SOCIET_STAFF_RATING_DATA_FAILURE });
        }
    } catch (e) {
        yield put({ type: types.UPDATE_SOCIET_STAFF_RATING_DATA_FAILURE });
        console.log(e)
    }
}

function* sendStaffCodeSmsToStaff(action) {
    try {
        const result = yield call(req.sendStaffCodeSmsToStaffReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.SEND_STAFF_CODE_SMS_TO_STAFF_SUCCESS, payload: action.data });
            action.data?.onSuccessCallback?.()
        } else {
            yield put({ type: types.SEND_STAFF_CODE_SMS_TO_STAFF_FAILURE });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getIDCardFieldsData(action) {
    try {
        const result = yield call(req.getIDCardFieldsDataReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_ID_CARD_FIELDS_DATA_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_ID_CARD_FIELDS_DATA_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateIDCardFieldsData(action) {
    try {
        const result = yield call(req.updateIDCardFieldsDataReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.UPDATE_ID_CARD_FIELDS_DATA_SUCCESS, payload: action.data });
            action.data?.onSuccessCallback?.()
        } else {
            yield put({ type: types.UPDATE_ID_CARD_FIELDS_DATA_FAILURE });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getSocietyStaffApprovalSetting(action) {
    try {
        const result = yield call(req.getSocietyStaffApprovalSettingReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_APPROVAL_SETTINGS_DATA_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_APPROVAL_SETTINGS_DATA_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateSocietyStaffApprovalSetting(action) {
    try {
        const result = yield call(req.updateSocietyStaffApprovalSettingReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.UPDATE_APPROVAL_SETTINGS_DATA_SUCCESS, payload: action.data });
            action.data?.onSuccessCallback?.()
        } else {
            yield put({ type: types.UPDATE_APPROVAL_SETTINGS_DATA_FAILURE });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateSocietyStaffApprovalStatus(action) {
    try {
        const result = yield call(req.updateSocietyStaffApprovalStatusReq, action);
        if (result.statusCode === 200) {
            notify('success', 'Status changed successfully')
            yield put({ type: types.UPDATE_APPROVAL_STATUS_DATA_SUCCESS, payload: action.data });
            action.data?.onSuccessCallback?.()
        } else {
            yield put({ type: types.UPDATE_APPROVAL_STATUS_DATA_FAILURE });
        }
    } catch (e) {
        console.log(e)
    }
}

function* downloadSocietyStaffSummaryReport(action) {
    try {
        const result = yield call(req.downloadSocietyStaffSummaryReportReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
        } else {
            notify('Unable to download sheet.')
        }
    } catch (e) {
    }
}



export default function* staffPersonnelSaga() {
    yield takeLatest(types.GET_SOCIETY_STAFF_LIST, getSocietyStaffList);
    yield takeLatest(types.GET_SOCIETY_STAFF_PROFILE_LIST, getSocietyStaffProfileList);
    yield takeLatest(types.GET_SOCIETY_STAFF_DETAILS, getSocietyStaffDetails);
    yield takeLatest(types.ADD_SOCIETY_STAFF, addSocietyStaff);
    yield takeLatest(types.GET_SOCIET_STAFF_RATING_DATA, getSocietyStaffRatingData);
    yield takeLatest(types.GET_SOCIET_STAFF_RATING_PARAMETERS, getSocietyStaffRatingParameters);
    yield takeLatest(types.UPDATE_SOCIET_STAFF_RATING_DATA, updateSocietyStaffRating);
    yield takeLatest(types.DOWNLOAD_SOCIETY_STAFF_EXCEL_SHEET, downloadSocietyStaffList);
    yield takeLatest(types.UPDATE_SOCIETY_STAFF, updateSocietyStaff);
    yield takeLatest(types.DELETE_SOCIETY_STAFF, deleteSocietyStaff);
    yield takeLatest(types.GET_SOCIETY_WING_FLAT_LIST, getSocietyWingFlatList);
    yield takeLatest(types.PRINT_SOCIETY_STAFF_CARD, printSocietyStaffIDCards);
    yield takeLatest(types.SEND_STAFF_CODE_SMS_TO_STAFF, sendStaffCodeSmsToStaff);
    yield takeLatest(types.GET_ID_CARD_FIELDS_DATA, getIDCardFieldsData);
    yield takeLatest(types.UPDATE_ID_CARD_FIELDS_DATA, updateIDCardFieldsData);
    yield takeLatest(types.GET_APPROVAL_SETTINGS_DATA, getSocietyStaffApprovalSetting);
    yield takeLatest(types.UPDATE_APPROVAL_SETTINGS_DATA, updateSocietyStaffApprovalSetting);
    yield takeLatest(types.UPDATE_APPROVAL_STATUS_DATA, updateSocietyStaffApprovalStatus);
    yield takeLatest(types.DOWNLOAD_SOCIETY_STAFF_SUMMARY_REPORT, downloadSocietyStaffSummaryReport);
}