import React, { useState } from "react";
import { SocietyVendorsData } from "./ContactData";
import { ViewIcon, EditIcon, DeleteIcon, AuditIcon } from "../../assets";
import { useNavigate } from "react-router-dom";
import {
  ADD_CONTACT_ENDPOINT,
  VENDOR_AUDIT_LOG_ENDPOINT,
  VIEW_CONTACT_DETAILS_ENDPOINT,
} from "../../Routing/routes";
import DeleteModal from "../../components/Modal/DeleteModal";

const SocietyTable = ({ contactData }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const tableData = SocietyVendorsData;
  const navigate = useNavigate();
  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  

  return (
    <div className="min-h-[77%]">
      <table className="w-full table-fixed">
        <thead className="border-t border-b border-[#CCC]">
          {
            <tr className="text-[#AAAAAA]">
              <th className="w-28 p-2 text-[13px] font-semibold tracking-wide text-left">
                <div>Vendor Name</div>
              </th>
              <th className="w-20 p-2 text-[13px] font-semibold tracking-wide text-left">
                <div>Service Category </div>
              </th>
              <th className="p-2 text-[13px] font-semibold tracking-wide text-left">
                <div>Concerned Person</div>
              </th>
              <th className="p-2 text-[13px] font-semibold tracking-wide text-left">
                <div>Phone Number</div>
              </th>
              <th className="p-2 text-[13px] font-semibold tracking-wide text-left">
                <div>Schedule</div>
              </th>
              <th className="p-2 text-[13px] font-semibold tracking-wide text-left">
                <div>AMC Start Date</div>
              </th>
              <th className="p-2 text-[13px] font-semibold tracking-wide text-left">
                <div>AMC End Date</div>
              </th>
              <th className="p-2 text-[13px] font-semibold tracking-wide text-left">
                <div>Status</div>
              </th>
              <th className="p-2 text-[13px] font-semibold tracking-wide text-left">
                <div>Action</div>
              </th>
            </tr>
          }
        </thead>
        <tbody className="divide-y divide-gray-100">
          {tableData.map((item, index) => (
            <tr
              key={index}
              className={index % 2 === 0 ? "bg-white" : "bg-[#F4F5F8]"}
            >
              <td className="w-24 p-2 text-[13px] text-[#222222]">
              {item.vendor_name}
              </td>
              <td className="w-24 p-2 text-[13px]">
              {item.service_category}
              </td>
              <td className="p-2 text-[13px] whitespace-nowrap">
                {item.concerned_person}
              </td>
              <td className="p-2 text-[13px] whitespace-nowrap">
                {item.phoneNumber}
              </td>
              <td className="p-2 text-[13px] whitespace-nowrap">
              {item.schedule}
              </td>
              <td className="p-2 text-[13px]  whitespace-nowrap">{item.AMC_Start_Date}</td>
              <td className="p-2 text-[13px]  whitespace-nowrap">{item.AMC_End_Date}</td>
              <td className="p-2 text-[13px]  whitespace-nowrap">{item.status}</td>
              <td className="p-2 text-[13px]  whitespace-nowrap">
                {" "}
                <div className="inline-flex items-center">
            <button
              className="mr-2 w-[14px] h-[14px]"
              title="View"
              onClick={handleNavigation(VIEW_CONTACT_DETAILS_ENDPOINT)}
            >
              <ViewIcon className="fill-[#555] hover:fill-red-650" />
            </button>

            <button
              className="mr-2 h-[12px] w-[12px]"
              title="Edit"
              onClick={handleNavigation(ADD_CONTACT_ENDPOINT)}
            >
              <EditIcon className="fill-[#555] hover:fill-red-650" />
            </button>

            <button
              className="mr-2 h-[14px] w-[13px]"
              title="Audit"
              onClick={handleNavigation(VENDOR_AUDIT_LOG_ENDPOINT)}
            >
              <AuditIcon className="fill-[#555] hover:fill-red-650" />
            </button>

            <button
              className="h-[12px] w-[10px]"
              title="Delete"
              onClick={() => handleDeleteClick(item)}
            >
              <DeleteIcon className="fill-[#555] hover:fill-red-650" />
            </button>
          </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Delete Confirmation Modal */}
      <DeleteModal
        isOpen={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onDelete={() => {
          setShowDeleteModal(false);
        }}
      />
    </div>
  );
};
export default SocietyTable;
