import * as types from '../../index.types';

export function getEmployeeList(data) {
    return {
        type: types.GET_EMPLOYEE_LIST,
        data
    };
}

export function addEmployee(data) {
    return {
        type: types.ADD_EMPLOYEE,
        data
    };
}

export function getEmployeeTypesList(data) {
    return {
        type: types.GET_EMPLOYEE_TYPES_LIST,
        data
    };
}

export function changeEmployeeStatus(data) {
    return {
        type: types.CHANGE_EMPLOYEE_STATUS,
        data
    };
}

export function deleteEmployee(data) {
    return {
        type: types.DELETE_EMPLOYEE,
        data
    };
}

export function getEmployeeAuditLogs(data) {
    return {
        type: types.GET_EMPLOYEE_AUDIT_LOGS,
        data
    };
}

export function getEmployeeDetails(data) {
    return {
        type: types.GET_EMPLOYEE_DETAILS,
        data
    };
}

export function updateEmployeeData(data) {
    return {
        type: types.UPDATE_EMPLOYEE_DATA,
        data
    };
}


export function getEmployeeUserNameList(data) {
    return {
        type: types.GET_EMPLOYEE_USERNAMES_LIST,
        data
    };
}

export function getEmployeeNameList(data) {
    return {
        type: types.GET_EMPLOYEE_NAMES_LIST,
        data
    };
}

export function getExistingEmployee(data) {
    return {
        type: types.GET_EXISTING_EMPLOYEE_LIST,
        data
    };
}

export function addFromExistingEmployee(data) {
    return {
        type: types.ADD_FROM_EXISTING_EMPLOYEE,
        data
    };
}

export function getEmployeeLoginReport(data) {
    return {
        type: types.GET_EMPLOYEEE_LOGIN_REPORT,
        data
    };
}

export function printEmployeeLoginReport(data) {
    return {
        type: types.PRINT_EMPLOYEEE_LOGIN_REPORT,
        data
    };
}

export function downloadEmployeeSheet(data) {
    console.log(data)
    return {
        type: types.DOWNLOAD_EMPLOYEEE_LOGIN_REPORT,
        data
    };
}


export function getEmployeeReportRecord(data) {
    return {
        type: types.GET_EMPLOYEEE_REPORT_RECORD,
        data
    };
}

export function printEmployeeReportRecord(data) {
    return {
        type: types.PRINT_EMPLOYEEE_REPORT_RECORD,
        data
    };
}