import React from "react";

const IncermentDropdown = ({ value, onChange, id, type, className, height, width }) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused && "1px solid #aaaaaa",
      minHeight: '20px',
      height: height || "22px",
      borderRadius: "0.375rem",
      fontSize: '12px',
      fontWeight: 600,
    }),
    container: (provided,state) => ({
      ...provided,
      width: width,
    }),
    valueContainer: (provided,state) => ({
      ...provided,
      height: 'fit-content',
      margin: '0'
    }),
    option: base => ({
      ...base,
      fontSize: '12px',
      fontWeight: 600,
      color: 'black'
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: '0 8px ',
      "svg": {
        width: '14.5px',
      }
    }),
  };

  return (
    <input
      id={id}
      type={type}
      style={customStyles}
      value={value}
      onChange={onChange}
      theme={(theme) => ({
        ...theme,
        isFocused: "#DDD",
        colors: {
          ...theme.colors,
          primary25: "#DDD",
          primary: "#DDD",
        },
      })}
      className={`${className} bg-[#F9FAFC] border border-[#CCC] rounded-md px-2 py-2`}
    />
  );
};

export default IncermentDropdown;
