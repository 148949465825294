import React, { useEffect } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { printSocietyStaffIDCards } from "../../redux/actions/SetupRecordsActions/StaffManagementActions/StaffPersonnelAction";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import moment from "moment";
import ImageComponent from "../ImageComponent/ImageComponent";
import { validateDate } from "../../utils/helpers/universalFunctions";

const IdCard = ({
  societyName,
  jobName,
  imageUrl,
  name,
  validity,
  flat,
  mobileNo,
  vehicleNo,
  employeeCode,
  staffType,
  societyLogo,
  qrCode,
  permissions
}) => {
  return (
    <div className="w-[350px]  mt-7 mx-auto bg-white shadow-lg overflow-hidden border border-[#FAFAFA] text-[#222]">
      <div className="px-6 py-3 bg-[#2e7db1]">
        <h1 className="font-bold text-[22px] text-center text-white py-3">
          {societyName}
        </h1>
        <div className="border-b border-white w-full h-full"></div>
        {
          permissions.dept === 'Y' &&
          <p className=" text-center text-white text-[16px] mt-1 font-semibold">
            {jobName}
          </p>
        }
      </div>

      <div className="px-3">
        <div className="flex">
          <div className="w-[46%] mt-3">
            {permissions?.profile==='Y' && <ImageComponent defaultUrl={imageUrl} width={32} height={32} /> }

          </div>
          <div className="w-[54%]">
            {permissions?.name === 'Y' && <p className="font-extrabold text-[16px] leading-6 mt-2 mb-2.5 capitalize">{name}</p>}
            <div className="flex gap-1">
              <table>
                <tbody className="align-top">
                  {
                    permissions?.tenure === 'Y' &&
                    <tr>
                      <td className="text-[#222] w-20 text-xs">Validity:</td>
                      <td className="text-xs font-semibold capitalize">{validity}</td>
                    </tr>
                  }
                  {
                    permissions?.mobile === 'Y' &&
                    <tr>
                      <td className="text-[#222] w-20 text-xs">Mobile No:</td>
                      <td className="text-xs font-semibold capitalize">{mobileNo}</td>
                    </tr>
                  }
                  {
                    permissions?.flat_details === 'Y' &&
                    <tr>
                      <td className="text-[#222] w-20 text-xs">Staff Type:</td>
                      <td className="text-xs font-semibold capitalize">{staffType}</td>
                    </tr>
                  }
                  {
                    permissions?.vehicle_details ==='Y' && 
                    <tr>
                      <td className="text-[#222] w-20 text-xs">Vehicle No:</td>
                      <td className="text-xs font-semibold capitalize">{vehicleNo}</td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div>
          {
            permissions?.flat_details === 'Y' &&
            <div className="mt-2 text-[13px]">
              <span className="text-[#222] w-20">Unit/Flat No.: &nbsp;</span>
              <span className="font-semibold capitalize">{flat}</span>
            </div>
          }
        </div>

        <hr className="my-2" />
      </div>

      <div className="flex justify-center px-6 py-1">
        {/* QR Code component or image goes here */}
        {qrCode && <div className="w-36 h-36"><ImageComponent defaultUrl={qrCode} width={36} height={36}/> </div>}
      </div>

      <div className="px-6 py-2 bg-[#2e7db1]">
        <div className="flex justify-between items-center">
          <p className="text-white ">Employee Code </p>
          <h1 className="font-bold text-[22px] text-center text-white mb-2 ">
            {employeeCode}
          </h1>
        </div>
        <div className="border-b border-white w-full h-full text-center"></div>
        <div className="text-center">
          <Link to={'https://zipgrid.com/'} target='_blank' className=" text-white text-[13px] mt-1 font-semibold">
              www.zipgrid.com
          </Link>
        </div>
      </div>
    </div>
  );
};
const Card = () => {
  const {id} = useParams();
  const dispatch = useDispatch()
  const {loading, printCardData} = useSelector(state=> state.staffPersonnelReducer)
  const {loginData} = useSelector(state=>state.loginReducer)
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(()=>{
    dispatch(printSocietyStaffIDCards({societyId: id, staffIds: searchParams.get("staff_id"), societyType: searchParams.get("society_type")}))
  }, [])

  return (
    <div className="flex items-center flex-wrap justify-center bg-gray-100 mb-10">
      {
        loading ?
        <Loader/>
        :
        <>
          {
            printCardData && printCardData?.cards?.map((item)=>(
              <IdCard
                //societyLogo={loginData?.default_community?.profile_photo_url}
                societyName={printCardData?.society_name}
                jobName={item?.profile || '-'}
                imageUrl={item?.staff_image}
                name={`${item?.staff_name_prefix ? `${item?.staff_name_prefix}. ` : ''}${item?.staff_name}` || '-'}
                validity={`${validateDate(item?.end_validity) ? moment(item?.end_validity)?.format('DD-MM-YYYY') : '-'}`}
                flat={item?.flat_details || '-'}
                mobileNo={`${item?.mobile_country_code ? `+${item?.mobile_country_code}-` : ''}${item.staff_contact}` || '-'}
                vehicleNo={`${item?.vehicle_type || '-'} ${item?.staff_vehicle_number?.toUpperCase() ? `(${item?.staff_vehicle_number?.toUpperCase()})` : '-'}`}
                staffType={item?.staff_type}
                employeeCode={item?.staff_code}
                qrCode={item?.qr_image}
                permissions={printCardData?.id_card_fields_permissions}
              />
            ))
          }
        </>
      }
    </div>
  );
};

export default Card;
