import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {notify} from '../../../../utils/notification'
import * as types from '../../../actions/index.types'
import * as req from '../../../../axios/request/SetupRecordsReqs/BuildingReqs/SocietyProfileReqs'
import { getFirebaseURL, uploadImageOnFirebase } from '../../../../firebase/firebaseFunction';
import { societyProfilePath } from '../../../../firebase/getFirebasePath';
 

function* getSocietyProfile(action) {
    try {
        const result = yield call(req.getSocietyProfileReq);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_SOCIETY_PROFILE_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_SOCIETY_PROFILE_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getCurrencyList(action) {
    try {
        const result = yield call(req.getCurrencyListReq);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_CURRENCY_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_CURRENCY_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getProjectsList(action) {
    try {
        const result = yield call(req.getProjectsListReq);
        if (result.statusCode === 200) {
            //notify('success', result.message);
            yield put({ type: types.GET_PROJECTS_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_PROJECTS_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getCountryStateCityList(action) {
    try {
        const result = yield call(req.getCountryStateCityListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_COUNTRY_STATE_CITY_LIST_SUCCESS, payload: {flag: action.data.flag, data: result.data}});  
        } else {
            yield put({ type: types.GET_COUNTRY_STATE_CITY_LIST_FAILURE, payload: {flag: action.data.flag, message: result?.response?.data?.message} });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateSocietyProfile(action) {
    try {
        if(typeof action.data.profileURL !== 'string'){
            let path = societyProfilePath({societyId: action.data.societyId, fileName: action.data.profileURL?.name});
            yield uploadImageOnFirebase(action.data.profileURL, path)
            action.data.profileURL = path
        }
        
        const result = yield call(req.updateSocietyProfileReq, action);
        if (result.statusCode === 200) {
            let profileURL = yield getFirebaseURL(action.data?.profileURL)
            notify('success', result.message);
            action.data?.onSuccessCallback?.()
            yield put({ type: types.UPDATE_SOCIETY_PROFILE_SUCCESS, payload: {profileURL, societyName: action.data.societyName}});

        }else{
            yield put({ type: types.UPDATE_SOCIETY_PROFILE_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getSocietyProfileAuditLogs(action) {
    try {
        const result = yield call(req.getSocietyProfileAuditLogsReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_SOCIETY_PROFILE_AUDIT_LOGS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_SOCIETY_PROFILE_AUDIT_LOGS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* societyChangePassword(action) {
    try {
        const result = yield call(req.societyChangePasswordReq, action);
        if (result.statusCode === 200) {
            notify('success', result.message);
        }
        action.data.onSuccessCallback()
    } catch (e) {
        console.log(e)
    }
}


export default function* societyProfileSaga() {
    yield takeLatest(types.GET_SOCIETY_PROFILE, getSocietyProfile);
    yield takeLatest(types.GET_CURRENCY_LIST, getCurrencyList);
    yield takeLatest(types.GET_PROJECTS_LIST, getProjectsList);
    yield takeEvery(types.GET_COUNTRY_STATE_CITY_LIST, getCountryStateCityList);
    yield takeLatest(types.UPDATE_SOCIETY_PROFILE, updateSocietyProfile);
    yield takeLatest(types.GET_SOCIETY_PROFILE_AUDIT_LOGS, getSocietyProfileAuditLogs);
    yield takeLatest(types.SOCIETY_CHANGE_PASSWORD, societyChangePassword);
}