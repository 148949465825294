import React from 'react'
import ListHeaderBack from '../../../components/ListHeader/ListHeaderBack'
import Layout from '../../Layout/Layout'
import { useNavigate } from 'react-router-dom';

export const smsOrder = [
  {
    key: "ticket_id",
    label: "Sms Credit:",
    value: "034",
  },
  {
    key: "open_for",
    label: "Amount:",
    value: "Testing",
  },
  {
    key: "logged_by",
    label: "Sent Date:",
    value: "Lift Maintenance Recovery",
  },
  {
    key: "flat_no",
    label: "Status:",
    value: "N/A",
  },
]

function ViewSmsOrder() {
    const navigate = useNavigate();

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack title="View SMS Order" onClick={()=>{navigate(-1)}}>
          </ListHeaderBack>
        </div>
        <div className="py-1 gap-2 my-1">
          <table className="table-auto w-full">
              <tbody>
                {
                  smsOrder?.map((item)=>(
                    <tr className="text-[#222222] text-sm">
                      <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">{item.label}:</td>
                      <td className="py-2">{item.value || '-'}</td>
                    </tr>
                  ))
                }
              </tbody>
          </table>
        </div>
      </div>
    </Layout>
  )
}

export default ViewSmsOrder