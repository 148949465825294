import React, { useEffect } from 'react'
import Layout from '../../../Layout/Layout'
import ListHeader from '../../../../components/ListHeader/ListHeader'
import Button from '../../../../components/Button/Button'
import { useNavigate } from 'react-router-dom'
import { DEPOSIT_DUE_REMINDER_ENDPOINT, DUE_REMINDER_ENDPOINT, FIXED_DEPOSIT_MATURITY_REMINDER_ENDPOINT, NOMINATION_REMINDER_ENDPOINT } from '../../../../Routing/routes'
import MemberAfterDueDate from './MemberDueReminderComp/MemberAfterDueDate'
import MemberBeforeDueDate from './MemberDueReminderComp/MemberBeforeDueDate'
import { Form, Formik } from 'formik'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { getMemberDueReminderDetails, updateMemberDueReminderDetails } from '../../../../redux/actions/FinanceActions/SettingActions/DueRemindersAction'
import { CircularProgress } from '@mui/material'
import { MemberDueReminderValidation } from '../../../../validationSchema/financeSchema/settingSchema'
import Loader from '../../../../components/Loader/Loader'

const MemberDueReminder = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading, updateLoader, memberDueReminderData} = useSelector(state=>state.dueReminderReducer)
  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleSubmit = (values, {resetForm})=>{
    dispatch(updateMemberDueReminderDetails({...values, societyId: loginData?.default_community?.community_id}))
  }
  
  useEffect(()=>{
    dispatch(getMemberDueReminderDetails({societyId: loginData?.default_community?.community_id}))
  }, [])

  return (
    <Layout>
        <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg p-2">
           <div className='border-b border-[#CCC]'>
           <ListHeader leftContent="Member Due Reminder">
              <Button
                type='button'
                  label="Deposit Due Reminder"
                  onClick={handleNavigation(DEPOSIT_DUE_REMINDER_ENDPOINT)}
                />
              <Button label="Due Reminder" onClick={handleNavigation(DUE_REMINDER_ENDPOINT)}/>
              <Button label="FD Maturity Reminder" onClick={handleNavigation(FIXED_DEPOSIT_MATURITY_REMINDER_ENDPOINT)} />
              <Button label="Nominees Reminder" onClick={handleNavigation(NOMINATION_REMINDER_ENDPOINT)} />
            </ListHeader>
           </div>
           <Formik
              initialValues={{  
                id: memberDueReminderData?.id || 0,
                noOfDaysBeforeDue: memberDueReminderData?.no_of_day_before || '',
                noOfDaysAfterDue: memberDueReminderData?.no_of_day_after || '',
                employeeRoleId: memberDueReminderData?.employee_type || '',
                employeeId: memberDueReminderData?.employee ? memberDueReminderData?.employee?.split(',') : [],
                managingCommitteId: memberDueReminderData?.managing_committees || '',
                managingCommitteMemberId: memberDueReminderData?.mc_member ? memberDueReminderData?.mc_member?.split(',') : [],
              }}
              enableReinitialize
              validationSchema={MemberDueReminderValidation}
              onSubmit={handleSubmit}
            >
              {
                ({values, errors, setFieldValue})=>(
                  <Form>
                    <div className="flex py-1.5 justify-end border-b border-[#CCC]">
                      <Button
                        type='submit'
                        label={
                          <span className="w-16 inline-block">
                            {
                            updateLoader ?
                              <CircularProgress sx={{color: "white"}} size={17}/>
                              :
                              <>Save</>
                            }
                          </span>
                          }
                          disabled={loading || updateLoader}
                      />
                    </div>
                    {
                      loading ?
                      <Loader/>
                      :
                      <>
                        <div>
                          <MemberBeforeDueDate values={values} setFieldValue={setFieldValue} />
                        </div>
                        <div>
                            <MemberAfterDueDate values={values} setFieldValue={setFieldValue} />
                        </div>
                      </>
                    }
                  </Form>
                )
              }
            </Formik>
        </div>
    </Layout>
  )
}

export default MemberDueReminder