import React, { useEffect } from "react";
import Layout from "../../../../Layout/Layout";
import ListHeaderBack from "../../../../../components/ListHeader/ListHeaderBack";
import { useNavigate, useParams } from "react-router-dom";
import { EMPLOYEE_ENDPOINT } from "../../../../../Routing/routes";
import { useDispatch } from "react-redux";
import { getEmployeeAuditLogs, getEmployeeDetails } from "../../../../../redux/actions/SetupRecordsActions/StaffManagementActions/EmployeeManagementAction";
import { useSelector } from "react-redux";
import Loader from "../../../../../components/Loader/Loader";
import moment from "moment";

const headCells = [
  { id: "oldPhoneNumber", label: "Old Phone Number", width: 64 },
  { id: "newPhoneNumber", label: "New Phone Number", width: 64 },
  { id: "modifiedBy", label: "Modified By", width: 36 },
  { id: "modifiedOn", label: "Modified On", width: 40 },
];

const headCells2 = [
  { id: "oldEmail", label: "Old Email", width: 64 },
  { id: "newEmail", label: "New Email", width: 64 },
  { id: "modifiedBy", label: "Modified By", width: 36 },
  { id: "modifiedOn", label: "Modified On", width: 40 },
];

const EmployeeAudit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {id} = useParams()
  const {loading, employeeAuditLogs, employeeDetails} = useSelector(state=>state.employeeManagementReducer)

  const handleBackClick = () => {
    navigate(EMPLOYEE_ENDPOINT);
  };
  
  useEffect(()=>{
    dispatch(getEmployeeAuditLogs({employeeId: id}))
    dispatch(getEmployeeDetails({employeeId: id}))
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <ListHeaderBack
          onClick={handleBackClick}
          className={'capitalize'}
          title={` Audit Logs ${employeeDetails?.emp_name && !loading ? `- ${employeeDetails?.emp_name}` : ''}`}
        ></ListHeaderBack>
        <div className="ml-2 border-t border-[#CCCCCC] ">
        {
          loading && id !== employeeAuditLogs?.[0]?.emp_id?.toString() ?
          <Loader/>
          :
          <>
            {
              !!employeeAuditLogs?.length &&
              <div>
                <table className="table-auto w-full mb-10">
                  <thead>
                    <tr className="text-[#AAAAAA] text-left border-[#CCCCCC] border-b text-sm">
                      {
                        headCells?.map((item)=> (
                          <th className={`px-4 py-2 w-${item.width}`}>{item?.label}</th>
                        ))
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {
                      employeeAuditLogs?.map(audit=> {
                        return audit?.changedFields?.includes('Phone') ?
                          <tr className="text-[#222222] text-sm">
                            <td className="px-4 py-2 w-64 break-all">{`${audit?.old_data?.['mobile_country_code'] ? `+${audit?.old_data?.['mobile_country_code']}-` : ''}${audit?.old_data?.['Phone']}` || '-'}</td>
                            <td className="px-4 py-2 w-64 break-all">{`${audit?.new_data?.['mobile_country_code'] ? `+${audit?.new_data?.['mobile_country_code']}-` : ''}${audit?.new_data?.['Phone']}` || '-'}</td>
                            <td className="px-4 py-2 w-32 break-all">{audit?.['modified_by'] || '-'}</td>
                            <td className="px-4 py-2 w-40 break-all">{audit?.['modified_dateTime'] ? moment(new Date(audit?.['modified_dateTime']))?.format('MMM DD, YYYY hh:mm A')  : '-'}</td>
                          </tr>
                          : null
                        }
                      )
                    }
                  </tbody>
                </table>
                <table className="table-auto w-full border-t border-[#CCCCCC]">
                <thead>
                  <tr className="text-[#AAAAAA] text-left border-[#CCCCCC] border-b text-sm">
                    {
                      headCells2?.map((item)=> (
                        <th className={`px-4 py-2 w-${item.width}`}>{item?.label}</th>
                      ))
                    }
                  </tr>
                </thead>
                <tbody>
                {
                      employeeAuditLogs?.map(audit=> {
                        return audit?.changedFields?.includes('Email ID') ?
                          <tr className="text-[#222222] text-sm">
                            <td className="px-4 py-2 w-64 break-all">{audit?.old_data?.['Email ID'] || '-'}</td>
                            <td className="px-4 py-2 w-64 break-all">{audit?.new_data?.['Email ID'] || '-'}</td>
                            <td className="px-4 py-2 w-32 break-all">{audit?.['modified_by'] || '-'}</td>
                            <td className="px-4 py-2 w-40 break-all">{audit?.['modified_dateTime'] ? moment(new Date(audit?.['modified_dateTime']))?.format('MMM DD, YYYY hh:mm A')  : '-'}</td>
                          </tr>
                          : null
                        }
                      )
                    }
                </tbody>
              </table>
              </div>
            }
          </>
        }
        {!loading && !employeeAuditLogs?.length && (
            <div className="text-sm text-[#AAAAAA] py-4 text-center">
              No Records
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default EmployeeAudit;
