import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Layout from "../../../Layout/Layout";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import ButtonIco from "../../../../components/Button/ButtonPrint";
import { TextInput } from "../../../../components/InputFields/TextInput";
import {DatePickerComponent} from "../../../../components/DatePicker/DatePicker";
import { TimePickerNotFM } from "../../../../components/DatePicker/TimePicker";
import UploadButton from "../../../../components/Button/UploadButton";
import Checkbox from "../../../../components/Checkbox/Checkbox";

function CheckboxGroup({ options, selectedOptions, onChange }) {
  const [selectOptions, setSelectOptions] = useState(selectedOptions || []);

  const handleSelectAll = () => {
    if (options?.length && options?.length === selectOptions?.length) {
      setSelectOptions([]);
    } else {
      setSelectOptions(options);
    }

    //onChange
  };

  const handleSingleCheckbox = (e, item) => {
    console.log(selectOptions);
    if (e.target.checked) {
      setSelectOptions((prev) => [...prev, item]);
    } else {
      setSelectOptions((prev) => prev.filter((ele) => ele !== item));
    }
  };

  return (
    <>
      <div>
        <Checkbox
          text={"Select All"}
          initialChecked={
            options?.length && options?.length === selectOptions?.length
          }
          onChange={handleSelectAll}
        />
      </div>
      <div></div>
      {options?.map((item) => {
        return (
          <div key={item}>
            <Checkbox
              initialChecked={selectOptions?.includes(item)}
              text={item}
              onChange={(e) => {
                handleSingleCheckbox(e, item);
              }}
            />
          </div>
        );
      })}
    </>
  );
}

function RescheduleSentNotice() {
  const navigate = useNavigate();

  const handlePrint = () => {
    // window.open(`${""}?${createSearchParams([...searchParams.entries()])}`)
  };
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack
            title="Reschedule A Meeting"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ButtonIco icon={faPrint} children="Print" onClick={handlePrint} />
          </ListHeaderBack>
        </div>
        <div className="p-5 px-10 gap-2">
          <div className="text-base flex justify-between items-center">
            <div className="text-left flex items-center gap-3">
              <p className="mb-4">TelePhone No.: </p>
              <p>
                <TextInput label="" className="w-44" placeholder="Enter No." />
              </p>
            </div>
            <div className="text-right flex items-center gap-3">
              <p className="mb-4">Date:</p>
              <p className="mb-4">
                <DatePickerComponent
                  className="w-44 h-[38px] justify-between"
                  onDateChange={(selectedDate) => {
                    // setFieldValue('fromDate', selectedDate)
                  }}
                  name="fromDate"
                  // defaultValue={values.fromDate ? new Date(values.fromDate) : null}
                />
              </p>
            </div>
          </div>
          <div className="mt-4">
            <p className="text-[24px] font-bold text-red-650 text-center">
              Treasure Town Society
            </p>
            <p className="text-center text-base font-semibold">
              Society Address
            </p>
          </div>
          <div className="border-[#222] border-b my-4" />
          <div className="text-base font-bold text-center flex justify-center">
            Notice
          </div>
          <div className="mt-5 text-left text-[13px]">
            <p className="mt-8 mb-1">Dear Sir/Ma'am</p>

            <div className="mb-3 flex flex-wrap items-center gap-2">
              <p className="mb-4">
                Notice is hereby given to all the members that the
              </p>
              <p>
                <TextInput label="" placeholder="Enter Date" className="w-20" />
              </p>
              <p className="mb-4">
                Special General Meeting of the SYnapse will be held on{" "}
              </p>
              <p>
                <TextInput label="" placeholder="Day" className="w-24" />
              </p>
              <p className="mb-4">, the </p>
              <p>
                <DatePickerComponent
                  className="w-44 h-[38px] justify-between"
                  onDateChange={(selectedDate) => {
                    // setFieldValue('fromDate', selectedDate)
                  }}
                  name="fromDate"
                  // defaultValue={values.fromDate ? new Date(values.fromDate) : null}
                />
              </p>
              <p className=""> at </p>
              <p>
                <TimePickerNotFM
                  width={28}
                  height={9}
                  name={`timeSlot.startTime`}
                  // value={values.timeSlot[index].startTime}
                  // onSelect={(value)=>{setFieldValue(`timeSlot.${index}.startTime`, value)}}
                />
              </p>
              <p className=""> at </p>
              <p>
                <TextInput label="" placeholder="Day" className="w-32 -mb-4" />
              </p>
              <p className="">, to transact the following buisness. </p>
            </div>
          </div>

          <div className="mt-5">
            <p className="font-semibold mb-2">Agenda</p>
            <div className="editor-container border h-40 w-full bg-[#F9FAFC]">
              <Editor
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                toolbar={{
                  options: [
                    "inline",
                    "blockType",
                    "fontSize",
                    "list",
                    "textAlign",
                    "history",
                    "colorPicker",
                  ],
                  inline: {
                    inDropdown: false,
                    options: ["bold", "italic", "underline"],
                  },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: false, options: ["redo", "undo"] },
                  colorPicker: {
                    className: undefined,
                    component: undefined,
                    popupClassName: undefined,
                  },
                }}
              />
            </div>
            <div className="text-right text-[13px] mt-3">
              By Order of the Managing Committee
            </div>
            <div className="text-right text-[13px] font-semibold">
              Treasurer Town Society
            </div>
            <div className="flex justify-end my-3">
              <TextInput label="" placeholder="Day" className="w-32 -mb-4" />
            </div>
            <div className="text-right font-semibold text-[13px]">
              Honorary Secretary
            </div>
          </div>

          <div className="mt-5">
            <p className="font-semibold mb-2">Notes</p>
            <div className="editor-container border h-40 w-full bg-[#F9FAFC]">
              <Editor
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                toolbar={{
                  options: [
                    "inline",
                    "blockType",
                    "fontSize",
                    "list",
                    "textAlign",
                    "history",
                    "colorPicker",
                  ],
                  inline: {
                    inDropdown: false,
                    options: ["bold", "italic", "underline"],
                  },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: false, options: ["redo", "undo"] },
                  colorPicker: {
                    className: undefined,
                    component: undefined,
                    popupClassName: undefined,
                  },
                }}
              />
            </div>
            <div className="flex justify-between w-1/2 items-center mt-4">
              <p className="text-[13px]">Attachments</p>
              <p>
                {" "}
                <UploadButton
                  showFileName={true}
                  name={`attachments`}
                  accept={
                    "image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                  }
                  // onSelect={(e) =>
                  //   formik.setFieldValue(`attachments`, e)
                  // }
                  // fileName={formik.values?.attachments?.name}
                />
              </p>
            </div>
          </div>

          <div className="text-[13px]">
            <div className="font-bold my-5">Choose Members To Send Email</div>
            <div className="grid grid-cols-4 gap-y-1.5">
              <CheckboxGroup options={[]} selectedOptions={[""]} />
            </div>
            <div className="font-bold my-5">Members</div>
            <div className="grid grid-cols-3 gap-y-1.5">
              <CheckboxGroup
                options={[
                  "Himanshi Mehta (P-Block - TPP003)",
                  "Test User (Remote - 3649)",
                  "Dipanshi J (P-Block - TPP002)",
                  "Kapil Kumar Kumar (P-Block - TPP001)",
                  "Test Joint (Remote - 3649)",
                  "Dheeraj Karda (P-Block - TPP001)",
                  "Vinesh Sandoria (P-Block - TPP055, P-Block - TPP054, P-Block - TPP051)",
                  "Dipanshi Asawa (P-Block - TPP10, P-Block - TPP20)",
                ]}
                selectedOptions={[]}
              />
            </div>

            <div className="font-bold my-5">Joint Members</div>
            <div className="grid grid-cols-3 gap-y-1.5">
              <CheckboxGroup
                options={[
                  "Himanshi Mehta (P-Block - TPP003)",
                  "Test User (Remote - 3649)",
                ]}
                selectedOptions={["Kapil Kumar Kumar (P-Block - TPP001)"]}
              />
            </div>

            <div className="font-bold my-5">Treasurers</div>
            <div className="grid grid-cols-3 gap-y-1.5">
              <CheckboxGroup
                options={[
                  "Himanshi Mehta (P-Block - TPP003)",
                  "Test User (Remote - 3649)",
                ]}
                selectedOptions={["Kapil Kumar Kumar (P-Block - TPP001)"]}
              />
            </div>

            <div className="font-bold my-5">Chairman</div>
            <div className="grid grid-cols-3 gap-y-1.5">
              <CheckboxGroup
                options={[
                  "Himanshi Mehta (P-Block - TPP003)",
                  "Test User (Remote - 3649)",
                ]}
                selectedOptions={["Kapil Kumar Kumar (P-Block - TPP001)"]}
              />
            </div>
          </div>

          <div className="mt-4">
            <p className="font-semibold mb-2">Set Reminder</p>

            <div className="text-right flex justify-between w-1/2 items-center gap-3">
              <p className="mb-4 text-[13px]">Date:</p>
              <p className="mb-4">
                <DatePickerComponent
                  className="w-44 h-[36px] justify-between"
                  onDateChange={(selectedDate) => {
                    // setFieldValue('fromDate', selectedDate)
                  }}
                  name="fromDate"
                  // defaultValue={values.fromDate ? new Date(values.fromDate) : null}
                />
              </p>
            </div>

            <div className="text-right flex justify-between w-1/2 items-center gap-3">
              <p className="mb-4 text-[13px]">Time</p>
              <p>
                <TimePickerNotFM
                  width={28}
                  height={9}
                  name={`timeSlot.startTime`}
                  // value={values.timeSlot[index].startTime}
                  // onSelect={(value)=>{setFieldValue(`timeSlot.${index}.startTime`, value)}}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default RescheduleSentNotice;
