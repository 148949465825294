import React, { useRef } from "react";
import TenantDetailsForm from "./TaLandlordDetailsForm";
import TaOtherDetails from "./TaOtherDetails";
import Layout from "../../../Layout/Layout";
import { DownloadExt } from "../../../../assets";
import ButtonG from "../../../../components/Button/ButtonG";
import Button from "../../../../components/Button/Button";
import ButtonIco from "../../../../components/Button/ButtonPrint";
import { downloadFileFromURL } from "../../../../utils/helpers/downloadMedia";
import { Form, Formik } from "formik";
import { ApplyForTenancyValidation } from "../../../../validationSchema/setupRecordsSchema/occupants&TenantsSchema";
import  {applyForTenancy} from '../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/ApplyForTenancyAction'
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";

const TenantApplicationForm = () => {
  const dispatch =useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {ipAddress} = useSelector(state=>state.ipAddressReducer)
  const {updateLoader} = useSelector(state=>state.applyForTenancyReducer)
  const formRef = useRef();

  const downloadFile = (url, fileName) =>{
    downloadFileFromURL({
      url, 
      fileName
    })
  }


  return (
    <Layout>
      <div className="flex flex-col gap-2 w-full">
        <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg p-2">
        <Formik
          enableReinitialize
          initialValues={{ 
            tenantDetails: [{
                  name: '',
                  contactNo: '',
                  email: '',
                  relative: [{
                    name: '',
                    relationship: '',
                  }],
                  identityProof: '',
                  panCard: '',
                  noc: '',
                  form11: '',
                  form29:  '',
                }
              ],
              fromDate: '',
              toDate: '',
              cargoDate: '',
              description: '',
              wingId: '',
              flatId: '',
              acceptTerms: ''
          }}
          onSubmit={values =>
            {
              dispatch(applyForTenancy({...values, ipAddress, societyId: loginData?.default_community?.community_id, sessionId: loginData?.session_id, onSuccessCallback: ()=>{formRef?.current?.resetForm()}}))
          }}
          innerRef={(f) => (formRef.current = f)}
          validationSchema={ApplyForTenancyValidation}
        >    
          {
            ({values, setFieldValue, resetForm})=>{
              return (
                <Form>
                  <div className="flex justify-between gap-5 pb-2 border-b border-[#CCCC]">
                    <div className="flex w-1/2 items-center">
                      <h1 className="text-sm font-semibold p-1">
                        Tenant Application Form
                      </h1>
                    </div>
                    <div className="flex items-center gap-2">
                      <div className="flex gap-2 whitespace-nowrap">
                        <ButtonIco icon={DownloadExt} children="Download Form 29" className="w-[146px]" onClick={()=>{downloadFile('https://firebasestorage.googleapis.com/v0/b/np-zg-dev-neo-bucket/o/excelSheetSampleAndFormat%2FForm%2029.docx?alt=media&token=3b665d87-7cd4-42d7-a050-2e5d67083739', 'form29')}}/>
                        <ButtonIco icon={DownloadExt} children="Download Form 11" className="w-[146px]" onClick={()=>{downloadFile('https://firebasestorage.googleapis.com/v0/b/np-zg-dev-neo-bucket/o/excelSheetSampleAndFormat%2FForm%2011.docx?alt=media&token=ed258fe7-cfa0-42a5-b53f-92971ca3f19c', 'form11')}}/>
                      </div>
                      <div className="border-r border-[#CCC] w-full h-full"></div>
                      <ButtonG
                        label="Cancel"
                        className="h-8"
                        type='button'
                        onClick={resetForm}
                      />
                      <Button
                        label={
                          <span className="w-12 inline-block">
                            {
                              updateLoader ?
                                <CircularProgress sx={{color: "white"}} size={17}/>
                                :
                                'Save'
                              }
                          </span>
                          } 
                        className="h-8 px-4"
                        type={'submit'}
                      />
                    </div>
                  </div>
                  <div className="">
                    <div>
                      <TenantDetailsForm values={values} setFieldValue={setFieldValue} />
                    </div>
                    <div>
                      <TaOtherDetails values={values} setFieldValue={setFieldValue} />
                    </div>
                  </div>
                </Form>
            )}
          }
          </Formik>
        </div>
      </div>
    </Layout>
  );
};

export default TenantApplicationForm;
