import React from 'react';
import {Navigate, Outlet} from 'react-router-dom'
import { LOGIN_ENDPOINT } from './routes';
import webTokenStorer from '../utils/webStorer';
import { useSelector } from 'react-redux';
import FullScreenLoader from '../components/Loader/FullScreenLoader';

const  ProtectedRoute = () => {
  const {reloginLoading} = useSelector(state=>state.loginReducer)
  const isLoggedIn =  !!(webTokenStorer.getToken());

  return isLoggedIn ? 
  <>
  {
    reloginLoading ? <FullScreenLoader/> : <Outlet/>
  }
  </>
   : 
  <>{webTokenStorer.removeToken()} <Navigate to={LOGIN_ENDPOINT}/></>
}

export default ProtectedRoute;