import React, { useEffect, useState } from "react";
import Button from "../../../../components/Button/Button";
import { EditIcon } from "../../../../assets";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ADD_FROM_EXISITING_OCCUPANT_ENDPOINT, FAMILY_MEMBERS_LIST_ENDPOINT, MEMBER_STAFF_DETAILS_ENDPOINT, NOMINEES_DETAILS_ENDPOINT, UPLOAD_FLAT_DOCUMENTS_ENDPOINT, VEHICLE_DETAILS_ENDPOINT } from "../../../../Routing/routes";
import Loader from '../../../../components/Loader/Loader'
import moment from "moment";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MemberDetailstData } from "../../../../consts/MemberDetailsData";
import ImageComponent from "../../../../components/ImageComponent/ImageComponent";
import { validateDate } from "../../../../utils/helpers/universalFunctions";

const Accordion = ({ label, isOpen, toggleAccordion, children }) => {

    return (
      <div className="border rounded-lg border-[#CCCCCC] my-2">
        <div
          className="flex justify-between cursor-pointer items-center bg-[#FDF2F3] rounded-lg p-3 border-b"
          onClick={toggleAccordion}
        >
          <div className="flex items-center">
            <button className="text-white text-base font-semibold mr-3 w-5 h-5 bg-red-650 
              border rounded flex justify-center items-center">
              {isOpen ? (
                <FontAwesomeIcon icon={faChevronUp} size="xs" />
              ) : (
                <FontAwesomeIcon icon={faChevronDown} size="xs" />
              )}
            </button>
            <div className="text-[#000000] text-sm font-semibold">{label}</div>
          </div>
        </div>
        {isOpen && (
            <>
                {children}
            </>
        )}
      </div>
    )
  }

const FlatForm = ({currentTab, flatData}) => {
  const navigate = useNavigate();
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const { id, userId } = useParams();

  const handleNavigation = (path) => () => {
    navigate(path);
  };
  const toggleAccordion = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(()=>{
    setOpenAccordionIndex(null)
  }, [currentTab])

  return (
    <>
        <div className="mt-5 text-sm">
            <div className="grid grid-cols-2 gap-5 mb-4">
                <div className="flex">
                    <div className="font-medium w-52 text-[#aaa] shrink-0">Wing Number</div>
                    <div>{flatData?.wing_no || '-'}</div>
                </div>
                <div className="flex">
                    <div className="font-medium w-52 text-[#aaa] shrink-0">Floor Number</div>
                    <div>{flatData?.floor_no || '-'}</div>
                </div>
            </div>
            <div className="grid grid-cols-2 gap-5 mb-4">
                <div className="flex">
                    <div className="font-medium w-52 text-[#aaa] shrink-0">Flat Number</div>
                    <div>{flatData?.flat_no || '-'}</div>
                </div>
                <div className="flex">
                    <div className="font-medium w-52 text-[#aaa] shrink-0">Main Member</div>
                    <div>{flatData?.main_member_name || '-'}</div>
                </div>
            </div>
            <div className="grid grid-cols-2 gap-5 mb-4">
                <div className="flex">
                    <div className="font-medium w-52 text-[#aaa] shrink-0">Build Up Area in Sq. Ft.</div>
                    <div>{flatData?.area || '-'}</div>
                </div>
                <div className="flex">
                    <div className="font-medium w-52 text-[#aaa] shrink-0">Joint Member</div>
                    <div>{flatData?.joint_member_name || '-'}</div>
                </div>
            </div>
            <div className="grid grid-cols-2 gap-5 mb-4">
                <div className="flex">
                    <div className="font-medium w-52 text-[#aaa] shrink-0">Lives Here Or Not</div>
                    <div>{flatData?.lives_here || '-'}</div>
                </div>
                <div className="flex">
                    <div className="font-medium w-52 text-[#aaa] shrink-0">Co-owner</div>
                    <div>{flatData?.co_owners || '-'}</div>
                </div>
            </div>
            <div className="grid grid-cols-2 gap-5 mb-4">
                <div className="flex">
                    <div className="font-medium w-52 text-[#aaa] shrink-0">Date of Payment Of Entrance Fee</div>
                    <div>{validateDate(flatData?.date_of_entrance_fee) ? moment(new Date (flatData?.date_of_entrance_fee)).format('DD MMM, YYYY') : '-'}</div>
                </div>

                <div className="flex">
                    <div className="font-medium w-52 text-[#aaa] shrink-0">Date of Membership</div>
                    <div>{validateDate(flatData?.date_of_membership) ? moment(new Date (flatData?.date_of_membership)).format('DD MMM, YYYY') : '-'}</div>
                </div>
            </div>
            <div className="grid grid-cols-2 gap-5 mb-4">
                <div className="flex">
                    <div className="font-medium w-52 text-[#aaa] shrink-0">Date of Admission</div>
                    <div>{validateDate(flatData?.date_of_admission) ? moment(new Date (flatData?.date_of_admission)).format('DD MMM, YYYY') : '-'}</div>
                </div>

                <div className="flex">
                    <div className="font-medium w-52 text-[#aaa] shrink-0">Age On The Date Of Admission</div>
                    <div>{flatData?.age_on_date_of_admission || '-'}</div>
                </div>
            </div>

            <div className="grid grid-cols-2 gap-5 mb-4">
                <div className="flex">
                    <div className="font-medium w-52 text-[#aaa] shrink-0">Landline Number (Residence)</div>
                    <div>{flatData?.landline_resi || '-'}</div>
                </div>

                <div className="flex">
                    <div className="font-medium w-52 text-[#aaa] shrink-0">Intercom No.</div>
                    <div>{flatData?.intercom_no || '-'}</div>
                </div>
            </div>

            <div className="grid grid-cols-2 gap-5 mb-4">
                <div className="flex">
                    <div className="font-medium w-52 text-[#aaa] shrink-0">Profession</div>
                    <div>{flatData?.profession || '-'}</div>
                </div>

                <div className="flex">
                    <div className="font-medium w-52 text-[#aaa] shrink-0">Photo ID Submitted Or Not</div>
                    <div>{flatData?.photo_id_submitted || '-'}</div>
                </div>
            </div>

            <div className="grid grid-cols-2 gap-5 mb-4">
                <div className="flex">
                    <div className="font-medium w-52 text-[#aaa] shrink-0">Full Address</div>
                    <div>
                        {flatData?.flat_address || '-'}
                        <div className='mt-3 text-sm'>
                            <Link to={`${UPLOAD_FLAT_DOCUMENTS_ENDPOINT}/${flatData?.flat_id}`}>
                                <button className="-mt-4 text-blue-600 cursor-pointer hover:underline" >
                                Upload Flat Documents
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="flex text-sm mb-4">
                    <div className="font-medium w-52 text-[#aaa] shrink-0">Brief Description Of Flat</div>
                    <div>{flatData?.flat_desc || '-'}</div>
                </div>
            </div>

            <div className="grid grid-cols-2 gap-5 mb-4">
                <div className="flex">
                    <div className="font-medium w-52 text-[#aaa] shrink-0">Number Of Share Certificate</div>
                    <div>{flatData?.no_of_share_certificate || '-'}</div>
                </div>
                <div className="flex">
                    <div className="font-medium w-52 text-[#aaa] shrink-0">Share Certificate Number</div>
                    <div>{flatData?.share_cert_no || '-'}</div>
                </div>
            </div>

            <div className="grid grid-cols-2 gap-5 mb-4">
                <div className="flex">
                    <div className="font-medium w-52 text-[#aaa] shrink-0">Electric Meter</div>
                    <div>{flatData?.electric_meter || '-'}</div>
                </div>
                <div className="flex">
                    <div className="font-medium w-52 text-[#aaa] shrink-0">Sale Deed Number</div>
                    <div>{flatData?.sale_deed_no || '-'}</div>
                </div>
            </div>

            <div className="grid grid-cols-2 gap-5 mb-4">
                <div className="flex">
                    <div className="font-medium w-52 text-[#aaa] shrink-0">Member Security Deposit</div>
                    <div>{flatData?.member_deposit || '-'}</div>
                </div>

                <div className="flex">
                    <div className="font-medium w-52 text-[#aaa] shrink-0">Builder Security Deposit</div>
                    <div>{flatData?.builder_deposit || '-'}</div>
                </div>
            </div>

            <div className="grid grid-cols-2 gap-5 mb-4">
                <div className="flex">
                    <span className="font-medium w-52 text-[#aaa] shrink-0">Number Of Family Member</span>
                    <div className="flex items-center">
                        <span className="me-3">{flatData?.family_count?.toString()?.padStart(2, '0') || '00'}</span>
                        <button className="text-red-650 cursor-pointer flex items-center gap-2" onClick={handleNavigation(`${FAMILY_MEMBERS_LIST_ENDPOINT}/${id}/${userId}/${flatData?.flat_id}`)}>
                            <EditIcon className="fill-red-650" /> Edit
                        </button>
                    </div>
                </div>

                <div className="flex">
                    <span className="font-medium w-52 text-[#aaa] shrink-0">Number Of Nominee</span>{console.log(flatData.no_of_nominee)}
                    <div className="flex items-center">
                        <span className="me-3">{flatData?.no_of_nominee?.toString()?.padStart(2, '0') || '00'}</span>
                        <button className="text-red-650 cursor-pointer flex items-center gap-2" onClick={handleNavigation(`${NOMINEES_DETAILS_ENDPOINT}/${id}/${userId}/${flatData?.flat_id}`)}>
                        <EditIcon className="fill-red-650" /> Edit
                        </button>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-2 gap-5 mb-4">
                <div className="flex">
                    <span className="font-medium w-52 text-[#aaa] shrink-0">Number of Vehicle</span>
                    <div className="flex items-center">
                        <span className="me-3">{flatData?.vehicle_count?.toString()?.padStart(2, '0') || '00'}</span>
                        <button className="text-red-650 cursor-pointer flex items-center gap-2" onClick={handleNavigation(`${VEHICLE_DETAILS_ENDPOINT}/${id}/${userId}/${flatData?.flat_id}`)}>
                            <EditIcon className="fill-red-650" /> Edit
                        </button>
                    </div>
                </div>

                <div className="flex">
                    <span className="font-medium w-52 text-[#aaa] shrink-0">Number Of Staff</span>
                    <div className="flex">
                    <span className="me-3">{flatData?.staff_count?.toString()?.padStart(2, '0') || '00'}</span>
                    <button className="text-red-650 cursor-pointer flex items-center gap-2" onClick={handleNavigation(`${MEMBER_STAFF_DETAILS_ENDPOINT}/${id}/${userId}/${flatData?.flat_id}`)}>
                        <EditIcon className="fill-red-650" /> Edit
                    </button>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-2 gap-5 mb-4">
                <div className="flex">
                    <span className="font-medium w-52 text-[#aaa] shrink-0">Number of Water Inlets</span>
                    <div className="flex">
                    <span>{flatData?.no_of_water_inlets?.toString()?.padStart(2, '0') || '00'}</span>
                    </div>
                </div>
            </div>

            {
                flatData?.rent_details?.split(',')?.[0] !== 'undefined'
                &&
                <>
                    <div className="grid grid-cols-2 gap-5 mb-4">
                        <div className="flex">
                            <div className="font-medium w-52 text-[#aaa] shrink-0">Rent Start Date</div>
                            <div>{flatData?.rent_details?.split(',')?.[0] ? moment(new Date(flatData?.rent_details?.split(',')?.[0])).format('DD-MM-YYYY') :  '-'}</div>
                        </div>
                        <div className="flex">
                            <div className="font-medium w-52 text-[#aaa] shrink-0">Rent End Date</div>
                            <div>{flatData?.rent_details?.split(',')?.[1] ? moment(new Date(flatData?.rent_details?.split(',')?.[1])).format('DD-MM-YYYY') :  '-'}</div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-5 mb-4">
                        <div className="flex">
                            <div className="font-medium w-52 text-[#aaa] shrink-0">Rent Agreement</div>
                            <div>{flatData?.rent_details?.split(',')?.[2] ? <Link target="_blank" className="ms-1 underline text-xs text-blue-500" to={flatData?.rent_details?.split(',')?.[2]}>Click TO View</Link> :  '-'}</div>
                        </div>
                    </div>
                </>
            }
        </div>
        <div className="capitalize">
            <Accordion
                label={MemberDetailstData[0]?.label}
                key={0}
                isOpen={0 === openAccordionIndex}
                toggleAccordion={() => toggleAccordion(0)}
            >
                {
                    flatData?.family_data?.map((family, index)=>{
                        return (
                            <div className="p-3 text-[#222222] text-sm" key={family?.id}>
                            <div className="flex">
                                <div className=" font-semibold text-right">{`Family Member ${(index+1)?.toString()?.padStart(2, '0')}`}</div>
                            </div>
                            <hr className="mt-2"/>
                            {MemberDetailstData?.[0]?.values.map((item, i0) => (
                                <React.Fragment key={i0}>
                                <div className="flex w-full text-sm p-1 mb-1">
                                    <div className="w-52 font-semibold text-[#AAA]">{item.label}</div>
                                    <div>
                                        {
                                            (family?.[item.key] && (item.key ==='dob'|| item.key === 'anniversary_date'))
                                            ?
                                            (validateDate(family?.[item.key]) ? moment(family?.[item.key])?.format('DD, MMM YYYY') : '-')
                                            :
                                            (family?.[item.key]?.toString() || '-')
                                        
                                        }
                                    </div>
                                </div>
                                </React.Fragment>
                            ))}
                            </div>
                        )
                        })
                    }
            </Accordion>
            <Accordion
                label={MemberDetailstData[1]?.label}
                key={1}
                isOpen={1 === openAccordionIndex}
                toggleAccordion={() => toggleAccordion(1)}
            >
                {
                    flatData?.staff_data?.map((staff, index)=>{
                        return (
                            <div className="p-3 text-[#222222] text-sm" key={staff?.id}>
                                <div className="flex">
                                    <div className=" font-semibold text-right">{`Staff Member ${(index+1)?.toString()?.padStart(2, '0')}`}</div>
                                </div>
                                <hr className="mt-2"/>
                                <div className="flex mt-3">
                                    <div className="w-3/4">
                                        {MemberDetailstData?.[1]?.values.map((item, i0) => (
                                            <React.Fragment key={i0}>
                                                <div className="flex w-full text-sm p-1 mb-1">
                                                    <div className="w-52 font-semibold text-[#AAA]">{item.label}</div>
                                                    <div>
                                                        {
                                                            (staff?.[item.key] && (item.key==='add_proof_doc' || item.key==='photo_id' || item.key==='other_document'))? 
                                                            <Link target="_blank" className="ms-1 underline text-xs text-blue-500" to={staff?.[item.key]}>Click TO View</Link>
                                                            :
                                                            item.key==='phone_number'? `${staff?.['mobile_country_code'] ? `+${staff?.['mobile_country_code']}-`: ''}${staff?.['phone_number']}` : (staff?.[item.key]?.toString() || '-')
                                                        }
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                    <div>
                                        <ImageComponent
                                            width={32}
                                            height={32}
                                            defaultUrl={staff?.['profile_image']}
                                        /> 
                                    </div> 
                                </div>
                            </div>
                        )
                        })
                    }
            </Accordion>
            <Accordion
                label={'Flat Documents'}
                key={'Flat Documents'}
                isOpen={'Flat Documents' === openAccordionIndex}
                toggleAccordion={() => toggleAccordion('Flat Documents')}
            >
                <div className="p-3">

                    <div className="flex w-full text-sm p-1 mb-1">
                        <div className="w-52 font-semibold text-[#AAA]">Rend Agreement</div>
                        <div>{flatData?.flat_docs?.rent_agreement ? <Link target="_blank" className="ms-1 underline text-sm text-blue-500" to={flatData?.flat_docs?.rent_agreement}>Click TO View</Link> : '-'}</div>
                    </div>
                    <div className="flex w-full text-sm p-1 mb-1">
                        <div className="w-52 font-semibold text-[#AAA]">NOC</div>
                        <div>{flatData?.flat_docs?.noc ? <Link target="_blank" className="ms-1 underline text-sm text-blue-500" to={flatData?.flat_docs?.noc}>Click TO View</Link> : '-'}</div>
                    </div>
                </div>
            </Accordion>
            <Accordion
                label={MemberDetailstData[2]?.label}
                key={2}
                isOpen={2 === openAccordionIndex}
                toggleAccordion={() => toggleAccordion(2)}
            >
                {
                    flatData?.nominee_data?.map((nominee, index)=>{
                        return (
                            <div className="p-3 text-[#222222] text-sm" key={nominee?.id}>
                                <div className="flex">
                                    <div className=" font-semibold text-right">{`Nominee ${(index+1)?.toString()?.padStart(2, '0')}`}</div>
                                </div>
                                <hr className="mt-2"/>
                                {MemberDetailstData?.[2]?.values.map((item, i0) => (
                                    <React.Fragment key={i0}>
                                    <div className="flex w-full text-sm p-1 mb-1">
                                        <div className="w-52 font-semibold text-[#AAA]">{item.label}</div>
                                        <div>{nominee?.[item.key]?.toString() || '-'}</div>
                                    </div>
                                    </React.Fragment>
                                ))}
                            </div>
                        )
                        })
                    }
            </Accordion>
            <Accordion
                label={MemberDetailstData[3]?.label}
                key={3}
                isOpen={3 === openAccordionIndex}
                toggleAccordion={() => toggleAccordion(3)}
            >
                {
                    flatData?.vehicle_data?.map((vehicle, index)=>{
                        return (
                            <div className="p-3 text-[#222222] text-sm" key={vehicle?.id}>
                                <div className="flex">
                                    <div className=" font-semibold text-right">{`Vehicle ${(index+1)?.toString()?.padStart(2, '0')}`}</div>
                                </div>
                                <hr className="mt-2"/>
                                {MemberDetailstData?.[3]?.values.map((item, i0) => (
                                    <React.Fragment key={i0}>
                                    <div className="flex w-full text-sm p-1 mb-1">
                                        <div className="w-52 font-semibold text-[#AAA]">{item.label}</div>
                                        <div>{vehicle?.[item.key]?.toString() || '-'}</div>
                                    </div>
                                    </React.Fragment>
                                ))}
                            </div>
                        )
                        })
                    }
            </Accordion>
        </div>
    </>
  );
};

const FlatTab = ({ label, active = false, className, onClick }) => {
  return (
    <div
      className={`inline-flex items-center gap-2 border-b-4 py-2 pl-2 cursor-pointer font-semibold text-sm  ${
        active ? "border-red-650 text-red-650" : "border-transparent "
      } ${className}`}
    >
      <span onClick={onClick}>{label}</span>
      <div className="border-r border-[#CCC] py-3"></div>
    </div>
  );
};
const FlatTabsContainer = ({ data, setData, currentTab, setCurrentTab }) => {

  return (
    <div className="border bg-[#FDF6F6] rounded-lg overflow-hidden h-fit">
      {data.map((item, index) => (
        <FlatTab
          key={index}
          active={index === currentTab}
          onClick={() => setCurrentTab(index)}
          label={`Flat Details: ${item}`}
          className="mr-2"
        />
      ))}
    </div>
  );
};

const FlatDetails = ({userDetails, flatDetails, loading}) => {
  const navigate = useNavigate()
  const [data, setData] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);

  const handleChooseMoreFlatModal = () => {
    navigate(`${ADD_FROM_EXISITING_OCCUPANT_ENDPOINT}?search=${encodeURIComponent(userDetails?.subscriber_pri_email)}`)
  }

  useEffect(()=>{
    if(flatDetails)
        setData(flatDetails?.map((item, index)=> (index+1)?.toString()?.padStart(2, '0')))
  },[flatDetails])

  return (
    <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg p-4">
      <div className="mb-3">
        <ListHeader leftContent="Flat Details">
          <Button label="Choose More Flat" className="h-8 " onClick={handleChooseMoreFlatModal} />
        </ListHeader>
      </div>
      <div className="min-h-[300px]">
        {flatDetails?.length === 0 ? (
            <div className="flex items-center justify-center">No Flat Details</div>
        ) : (
            loading ?
            <Loader/>
            :
            <>
            <FlatTabsContainer
                data={data}
                setData={setData}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
            />
            {flatDetails?.[currentTab] && <FlatForm currentTab={currentTab} flatData={flatDetails?.[currentTab]} />}
            </>
        )}
        </div>

      </div>
  );
};

export default FlatDetails;
