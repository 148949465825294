import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../../Layout/Layout";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import ButtonG from "../../../../components/Button/ButtonG";
import { TextInputFM } from "../../../../components/InputFields/TextInput";
import { TextAreaFM } from "../../../../components/InputFields/TextArea";
import Button from "../../../../components/Button/Button";
import { useDispatch } from "react-redux";
import { addEmployee, getEmployeeTypesList, getEmployeeUserNameList } from "../../../../redux/actions/SetupRecordsActions/StaffManagementActions/EmployeeManagementAction";
import { useSelector } from "react-redux";
import { Form, Formik } from "formik";
import { AddEmployeeValidation } from "../../../../validationSchema/setupRecordsSchema/staffManagementSchema";
import { DropdownFM2 } from "../../../../components/Dropdown/dropdown2";
import countries from "../../../../consts/countries";
import { CircularProgress } from "@mui/material";
import { PasswordInputFM } from "../../../../components/InputFields/PasswordInput";

const AddEmployeeForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {updateLoader, employeeTypeList, employeeUserNameList} = useSelector(state=>state.employeeManagementReducer)

  const handleCancelClick = () => {
    navigate(-1);
  };

  const handleSaveClick = async(values, {resetForm, initialValues}) => {

    dispatch(addEmployee({
      ...values, 
      societyId: loginData?.default_community?.community_id,
      onSuccessCallback: ()=>{
        resetForm()
      }
    }))

  };
  
  useEffect(()=>{
    dispatch(getEmployeeUserNameList())
    dispatch(getEmployeeTypesList({societyId: loginData?.default_community?.community_id}))
  },[])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 mb-5">
        <Formik
          initialValues={{
            employeeTypeId: '',
            fullName: '',
            userName: '',
            mobileCountryCode: '',
            countryISO: '',
            mobileNo: '',
            email:   '',
            address: '',
            password: '',
            confirmPassword: ''
          }}
          onSubmit={handleSaveClick}
          validationSchema={AddEmployeeValidation}
          >    
            {
              ({values, errors, setFieldValue})=>{
                return (
                  <Form>
                  <div className="border-b border-[#CCC]">
                    <ListHeader leftContent="Add Employee">
                      {/* Children content */}
                      <ButtonG label="Cancel" onClick={handleCancelClick} type='button' />
                      <Button label={
                        <span className="w-12 inline-block">
                          {
                            updateLoader ?
                              <CircularProgress sx={{color: "white"}} size={17}/>
                              :
                              'Save'
                            }
                        </span>
                        }  type='submit' className="px-4" />
                    </ListHeader>
                  </div>
                    <div className="flex justify-between gap-4 p-2">
                      <div className="flex flex-col w-1/2 border-r pr-5 border-[#CCC]">
                        <div className="flex justify-between mt-3">
                          <span className="text-sm mt-2">Employee Type</span>
                          <DropdownFM2
                            options={employeeTypeList?.map((item)=>({
                              value: item?.emp_type_id,
                              label: item?.type
                            })) || []}
                            width={"208px"}
                            className="text-xs"
                            placeholder={"Select Employee Type"}
                            onSelect={(selectedItem)=>{
                              setFieldValue(`employeeTypeId`, selectedItem.value)
                            }}
                            height='38px'
                            name={`employeeTypeId`}
                            {...(values.employeeTypeId==='' && {value: null})}
                          />
                        </div>
                        <TextInputFM
                          label="Full Name"
                          placeholder="Enter Full Name"
                          className="w-52"
                          name='fullName'
                          maxLength={60}
                        />
                        <TextInputFM
                          label="User Name"
                          placeholder="Enter User Name"
                          className="w-52"
                          name='userName'
                          maxLength={30}
                          customError={employeeUserNameList?.usernames?.split(',')?.includes(values.userName)? 'Username already exists.' : ''}
                        />
                        <div className="flex justify-between ">
                          <span className="text-sm mt-2">Country</span>
                          <DropdownFM2
                            options={ countries?.map((item)=>({
                              label: `${item?.name?.toUpperCase()} (${item?.phonecode})`,
                              value: item?.phonecode?.replace("+",""),
                              iso: item.iso,
                              id: item?.id
                            })) || []}
                            width={"208px"}
                            className="text-xs"
                            placeholder={"Select Country"}
                            onSelect={(selectedItem)=>{
                              setFieldValue(`mobileCountryCode`, selectedItem.value)
                              setFieldValue(`countryISO`, selectedItem.iso)
                            }}
                            height='38px'
                            name={`mobileCountryCode`}
                            {...(values.mobileCountryCode==='' && {value: null})}
                          />
                        </div>
                        <TextInputFM
                          label="Phone Number"
                          placeholder="Enter Phone Number"
                          className="w-52"
                          name='mobileNo'
                          type='number'
                          maxLength={12}
                        />
                        <TextInputFM
                          label="Email Address"
                          placeholder="Enter Email Address"
                          className="w-52"
                          name='email'
                        />
                        <TextAreaFM
                          label="Address"
                          placeholder="Enter Address"
                          className={"w-52"}
                          name='address'
                          maxLength={200}
                          row={4}
                        />
                      </div>
                      <div className="flex flex-col w-1/2 my-3">
                        <span className="text-base">Create Password</span>
                        <p className="text-[#999999] text-xs mb-4">
                          A valid password should have more than 8 characters, Contains a
                          capital case - lower case, at least a number. Must contain 1
                          special character.
                        </p>
                        <PasswordInputFM
                          label="Password"
                          placeholder="Enter Password"
                          className="w-52"
                          name='password'
                        />
                        <PasswordInputFM
                          label="Confirm Password"
                          placeholder="Enter Confirm Password"
                          className="w-52"
                          name='confirmPassword'
                        />
                      </div>
                    </div>
                  </Form>
                )
              }
            }
        </Formik>
      </div>
    </Layout>
  );
};

export default AddEmployeeForm;
