import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  FORGOTPASSWORD_ENDPOINT,
  HOME_ENDPOINT,
} from "../../../Routing/routes";
import Checkbox from "../../../components/Checkbox/Checkbox";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { loginValidation } from "../../../validationSchema/authSchema";
import { useDispatch, useSelector } from "react-redux";
import {login} from '../../../redux/actions/AuthActions/LoginAction'
import { CircularProgress } from "@mui/material";
import { webCredentialsStorer } from "../../../utils/webStorer";


const LoginForm = ({ navigate }) => {
  const [checked, setChecked] = useState(webCredentialsStorer?.getCredentials() ? true : false)
  const dispatch = useDispatch()
  const {loading} = useSelector(state=>state.loginReducer)
  const {ipAddress} = useSelector(state=>state.ipAddressReducer)

  const handleSubmit = (values, {resetForm}) => {
    const sendRequest = {
      onSuccessCallback: ()=>{
        navigate(HOME_ENDPOINT)
      },
      rememberMe: checked,
      ...values,
      ipAddress
    };
    dispatch(login(sendRequest))
  };

  const handleRememberMe = (event) => {
    setChecked(event.target.checked);
    if(!event.target.checked)
      webCredentialsStorer.removeCredentials()
  };


  return (
    <div className="flex justify-center items-center h-full w-auto bgClasGrad">
      <div className=" rounded p-8 mb-4 ">
        <div>
          <h3 className="flex justify-center font-medium">Login to</h3>
          <h1 className="tracking-normal text-red-650 font-bold text-3xl">
            Zipgrid Neo Console
          </h1>
          <div className="border border-[#DDDDDD] m-4 opacity-1"></div>
        </div>

        <Formik
          initialValues={{
            email: webCredentialsStorer?.getCredentials()?.id || '',
            password: webCredentialsStorer?.getCredentials()?.pwd || ''
          }}
          validationSchema={loginValidation}
          onSubmit= {handleSubmit}
        >
          {
            (formik)=>{
              return(
                <Form>
                  <div
                    className={`mb-4 ${
                      formik.touched.email && formik.errors.email ? "border-red-650" : ""
                    }`}
                  >
                    <Field
                      name="email"
                      className={` appearance-none border rounded w-full py-2 px-3 text-[#999999] leading-tight italic focus:outline-none ${
                        formik.touched.email && formik.errors.email ? "border-red-650" : ""
                      }`}
                      type="text"
                      placeholder="Username"
                      autoComplete="off"
                    />
                    <div className="text-red-500 text-xs italic h-1">
                      <ErrorMessage name='email'/>
                    </div>
                  </div>
                  <div
                    className={`mb-6 ${
                      formik.touched.password && formik.errors.password ? "border-red-650" : ""
                    }`}
                  >
                    <Field
                      name="password"
                      className={` appearance-none border rounded w-full py-2 px-3 text-[#999999] leading-tight italic focus:outline-none ${
                        formik.touched.password && formik.errors.password ? "border-red-650" : ""
                      }`}
                      type="password"
                      placeholder="Password"
                      autoComplete="off"
                    />
                    <div className="text-red-500 text-xs italic h-1">
                      <ErrorMessage name='password'/>
                    </div>
                  </div>
                  <div className="flex items-center justify-between mb-4 text-sm">
                    <Checkbox text="Remember Me" initialchecked={checked} onChange={handleRememberMe} />
                    <button className="inline-block align-baseline font-bold text-sm text-[#999999] hover:text-[#CF001C]" type="button">
                      <Link to={FORGOTPASSWORD_ENDPOINT}>Forgot Password?</Link>
                    </button>
                  </div>
                  <button
                    className={`bg-red-300  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full border-solid border-[#941A25] ${
                      !loading &&  formik.isValid ? "bg-red-650 cursor-pointer" : "cursor-not-allowed"
                    }`}
                    type="submit"
                    disabled={loading || !formik.isValid}
                  >
                    {
                      loading ?
                        <CircularProgress sx={{color: "white"}} size={17}/>
                        :
                        'LOGIN'
                    }
                  </button>
                </Form>
              )
            }
          }
        </Formik>
      </div>
    </div>
  );
};

export default LoginForm;
