
import * as types from '../index.types';

export function getFacilityList(data) {
    return {
        type: types.GET_FACILITY_LIST,
        data
    };
}

export function getTimeSlotList(data) {
    return {
        type: types.GET_TIME_SLOT_LIST,
        data
    };
}

export function getBookingFrequencyList(data) {
    return {
        type: types.GET_BOOKING_FREQUENCY_LIST,
        data
    };
}

export function getFacilityDetails(data) {
    return {
        type: types.GET_FACILITY_DETAILS,
        data
    };
}

export function updateFacility(data) {
    return {
        type: types.UPDATE_FACILITY,
        data
    };
}

export function getFacilityAuditLogst(data) {
    return {
        type: types.GET_FACILITY_AUDIT_LOGS,
        data
    };
}

export function deleteFacility(data) {
    return {
        type: types.DELETE_FACILITY,
        data
    };
}

export function updateTimeSlot(data) {
    return {
        type: types.ADD_UPDATE_TIME_SLOT,
        data
    };
}

export function getRateChart(data) {
    return {
        type: types.GET_RATE_CHART,
        data
    };
}

export function updateRateChart(data) {
    return {
        type: types.ADD_UPDATE_RATE_CHART,
        data
    };
}

export function checkAvailibility(data) {
    return {
        type: types.CHECK_BOOKING_AVAILIBILITY,
        data
    };
}

export function clearcheckAvailibility(data) {
    return {
        type: types.CLEAR_CHECK_BOOKING_AVAILIBILITY_DATA,
        data
    };
}

export function addBooking(data) {
    return {
        type: types.ADD_BOOKING,
        data
    };
}


export function changeFacilityStatus(data) {
    return {
        type: types.CHANGE_FACILITY_STATUS,
        data
    };
}

export function deleteTimeSlot(data) {
    return {
        type: types.DELETE_TIME_SLOT,
        data
    };
}

export function deleteRateChart(data) {
    return {
        type: types.DELETE_RATE_CHART,
        data
    };
}


export function sendMessageToAdministrator(data) {
    return {
        type: types.SEND_MESSAGE_TO_ADMINISTRATOR,
        data
    };
}

export function getFacilityPaymentLink(data) {
    return {
        type: types.GET_FACILTIY_ONLINE_PAYMENT_LINK,
        data
    };
}