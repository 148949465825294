import React, { useEffect, useState } from "react";
import VisitorLogTable from "./VisitorLogTable";
import Search from "../../../components/SearchBox/Search";
import ButtonIco from "../../../components/Button/ButtonPrint";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { DownloadExt, RefreshIcon } from "../../../assets";
import Dropdown2 from "../../../components/Dropdown/dropdown2";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { downloadVisitorLog, getVisitorLog, getVisitorOrVehicleDashboardData } from "../../../redux/actions/GateManagementActions/VisitorAndVehicleInAndOutAction";
import ButtonG from "../../../components/Button/ButtonG";
import { VisitorTypeOptions } from "../../../consts/DropDownOptions";
import moment from "moment";
import { validateDate } from "../../../utils/helpers/universalFunctions";
import { downloadExcel } from "../../../utils/helpers/downloadMedia";
import { PRINT_VISITOR__LOGS_ENDPOINT } from "../../../Routing/routes";
import { createSearchParams } from "react-router-dom";

export const headCells = [
  { id: "sNo", label: "S No.", width: "45px" },
  { id: "image", label: "Image", width: "70px" },
  { id: "name", label: "Name", sort: true, width: "100px" },
  { id: "phone", label: "Phone", sort: true, width: "100px" },
  { id: "type", label: "Type", sort: true, width: "60px" },
  { id: "from", label: "From", sort: true, width: "60px" },
  { id: "flat", label: "Flat", sort: true, width: "75px" },
  { id: "purpose", label: "Purpose", sort: true, width: "76px" },
  { id: "status", label: "Status", sort: true, width: "80px" },
  { id: "inOut", label: "In/Out", width: "170px" },
  { id: "action", label: "Action", width: "80px" },
];


const VisitorStaffLog = ({filter}) => {
  const [tableFilter, setTableFilter] = useState({
    searchText: '',
    type: '',
    page: 1,
    pageLimit: 10,
    refreshedTime: new Date()
  })
  const dispatch =useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading, visitorLog} = useSelector(state=>state.visitorAndVehicleInAndOutReducer)

  const handleSearch = (text)=>{
    if(text){
      setTableFilter(prev=> ({...prev, searchText: text?.trim(), page: 1}))
    }
  }

  const handleClear = ()=>{
    setTableFilter({
      searchText: '',
      type: '',
      page: 1,
      pageLimit: 10
    })
  }



  const handlePrint =()=>{
    let filterData = {...filter, ...tableFilter}
    delete filterData.page;
    delete filterData.pageLimit;
    delete filterData.refreshedTime;
    window.open(`${PRINT_VISITOR__LOGS_ENDPOINT}?${createSearchParams([...Object.entries(filterData)])}`)
  }


  
  const handleDownloadExcel = ()=>{
    const onSucessCallback = (data)=>{
      downloadExcel({data, fileName: 'Visitor logs.xlsx'})
    }
    const data = {
      societyId: loginData?.default_community?.community_id, 
      ...filter,
      onSucessCallback
    }
    dispatch(downloadVisitorLog(data))
  }

  const getDashboardData = ()=>{
    const data={
      societyId: loginData?.default_community?.community_id,
      ...filter,
      flag: 'visitors'
    }
    dispatch(getVisitorOrVehicleDashboardData(data))
  }

  const getTableData = ()=>{
    const data={
      societyId: loginData?.default_community?.community_id,
      ...tableFilter,
      ...filter,
    }
    dispatch(getVisitorLog(data))
  }

  const handleRefresh = ()=>{
    if(moment(tableFilter.refreshedTime)?.format('DD-MM-YYYY hh:mm A') !== moment()?.format('DD-MM-YYYY hh:mm A')){
      setTableFilter(prev=> ({...prev, refreshedTime: new Date()}))
    }
    getDashboardData()
  }
  
  useEffect(()=>{
    setTableFilter(prev=>({...prev, page: 1}))
  }, [filter])
  
  useEffect(()=>{
    getTableData()
  }, [tableFilter])


  return (
    <>
      <div>
        <div className="flex justify-between my-2">
          <div className="flex items-center justify-between flex-grow">
            <div className="flex">
              <Search height="8" placeholder={'Name/Contact'} onclick={handleSearch} value={tableFilter.searchText} />
              <div className="mx-2">
                <Dropdown2
                  options={VisitorTypeOptions}
                  placeholder="Select Visitor Type"
                  className="text-[11px] ml-4"
                  width="180px"
                  height="32px"
                  onSelect={(selectedItem) => {
                    setTableFilter(prev=>({...prev, type: selectedItem.value}))
                  }}
                  value={
                    tableFilter.type && {
                      value: tableFilter.type,
                      label: VisitorTypeOptions?.find((item) => item.value === tableFilter.type)?.label
                    }
                  }
                />
              </div>
              <ButtonG label='Clear' className='h-8' onClick={handleClear} />
            </div>
            <div className="text-xs flex text-[#aaaaaa] me-2">Updated On: {moment(filter.refreshedTime)?.format('DD-MM-YYYY hh:mm A')} <span className={`ms-2 cursor-pointer ${loading ? 'animate-spin': ''}`} onClick={handleRefresh}><RefreshIcon/></span> </div>
          </div>
          <div className="flex gap-3">
            <div className="border-r border-[#CCC]"></div>
            <ButtonIco icon={faPrint} onClick={handlePrint} children="Print" />
            <ButtonIco icon={DownloadExt} children="Download Excel" onClick={handleDownloadExcel} />
          </div>
        </div>
        <div className="ml-1">
        <VisitorLogTable
            loading={loading}
            data={visitorLog?.logs?.map((item, index)=>({
              sNo: (((visitorLog?.current_page_no - 1) * (parseInt(visitorLog?.page_limit) || 10) + 1)+index)?.toString()?.padStart(2, "0"),
              visitorLogId: item?.visitor_log_id,
              image: item?.vphoto,
              name: `${item?.vname} ${item?.lname ? item?.lname : ''}` || '-',
              phone: `${item?.VCountryCode ? `+${item?.VCountryCode?.replace('+', '')}-` : ''}${item.vphone}` || '-',
              type: item?.type || '-',
              flat: item?.flats_details || 'Society Office',
              from: item?.vfrom || '-',
              purpose: item.purpose || '-',
              status: item.checkstatus || '-',
              vehicleNo: item.vehicleno || '-',
              count: item.vcount || '-',
              approveBy: item?.approved_by || '-',
              belongings: item?.vbelongings || '-',
              comments: item?.comments || '-',
              inGate: item.checkingate || '-',
              outGate: item.checkoutgate || '-',
              idProof: `${item?.idproofdetail ? `${item.idproofdetail} ` : ''}${item?.idproof ? `(${item.idproof})` : '-'}`,
              checkIn: validateDate(item?.checkin) && (item.checkstatus !== 'Timeout' && item.checkstatus !=='Rejected') ? moment(item?.checkin)?.utc()?.format('DD-MM-YYYY hh:mm A') : '',
              checkOut: validateDate(item?.checkout) && (item.checkstatus !== 'Timeout' && item.checkstatus !=='Rejected') ? moment(item?.checkout)?.utc()?.format('DD-MM-YYYY hh:mm A') : ''
            }))}
            columns={headCells}
            checkbox={false}
            pagination={visitorLog?.logs?.length}
            totalCount={visitorLog?.total_record}
            totalPages={visitorLog?.total_pages}
            start={(visitorLog?.current_page_no - 1) * (parseInt(visitorLog?.page_limit) || 10) + 1}
            end={(visitorLog?.current_page_no - 1) * (parseInt(visitorLog?.page_limit) || 10) + visitorLog?.logs?.length}
            currentPage={parseInt(visitorLog?.current_page_no)}
            rowsPerPage={parseInt(visitorLog?.page_limit) || 10}
            allFilter={{...filter, ...tableFilter}}
            setTableFilter={setTableFilter}
          ></VisitorLogTable>
          </div>
          {!visitorLog?.logs?.length && !loading && (
            <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
              No Records
            </div>
          )}
      </div>
    </>
  );
};

export default VisitorStaffLog;
