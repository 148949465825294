import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import moment from "moment";
import { getMemberDueSummary } from "../../../redux/actions/FinanceActions/BillingReportsAction";
import parse from 'html-react-parser';
import { DownloadEx, DownloadExt, PrinterIcon } from "../../../assets";
import ButtonIco from "../../../components/Button/ButtonPrint";
// [
//   "Wing No.",
//   "Flat No.",
//   "Member Name",
//   "Bill Amount",
//   "Payment Received",
//   "Bill Dues",
//   "Outstanding Debit Note",
//   "Debit Note Payment Received",
//   "Outstanding Credit Note",
//   "Outstanding Advances",
//   "Accounts - Receivable",
//   "Total Dues"
// ],

export const headingsTenants = [
  { id: 'sNo', label: 'S No.', width: '45px' },
  { id: 'wingFlat', label: 'Wing & Flat No.', sort: true, width: '100px' },
  { id: 'memberName', label: 'Member Name', sort: true, width: '80px' },
  { id: 'chequeNo', label: 'Cheque No/DD.No', sort: true, width: '90px' },
  { id: 'date', label: 'Date', sort: true, width: '100px' },
  { id: 'bankName', label: 'Bank Name', sort: true, width: '100px' },
  { id: 'branch', label: 'Branch', sort: true, width: '100px' },
  { id: 'amount', label: 'Amount', sort: true, width: '80px' },
];

const PrintMemberDueSummary = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams()
  const [total, setTotal] = useState()
  const { loginData } = useSelector((state) => state.loginReducer);
  const { printLoader, memberDueSummary } = useSelector(
    (state) => state.billingReportsReducer
  );

  const getTableData = () => {
    const data = {
      societyId: loginData?.default_community?.community_id,
      reportType: searchParams.get("reportType") || '',
      wingId: searchParams.get("wingId") || '',
      flatId: searchParams.get("flatId") || '',
      memberName: searchParams.get("memberName") || '',
      flatArea: searchParams.get("flatArea") || '',
      toDate: searchParams.get("toDate") || '',
    }
    dispatch(getMemberDueSummary(data));
  }

  const findTotal = () => {
    let totalResult = []
    memberDueSummary?.data?.forEach((innerArray, index) => {
      innerArray?.slice(3)?.forEach((value, subIndex) => {
        let Value = parseFloat(value)
        if (!totalResult[subIndex]) {
          totalResult[subIndex] = Value;
        } else {
          totalResult[subIndex] += Value;
        }
      });
    });
    totalResult = totalResult?.map(item => item?.toFixed(2))
    setTotal(totalResult)
  }

  useEffect(() => {
    getTableData()

    const afterprint = (e) => {
      e.preventDefault()
    };

    window.addEventListener("afterprint", afterprint);

    return () => {
      window.removeEventListener("afterprint", afterprint);
    }
  }, [])


  useEffect(() => {
    if (!printLoader && memberDueSummary) {
      //window.print()
      findTotal()
    }
  }, [printLoader]);

  return (
    <>
      {printLoader || !memberDueSummary ?
        <Loader />
        :
        <>
          <div className="flex justify-end p-2 drop-shadow-sm gap-2 noprint">
            <button
              type="button"
              onClick={() => window.print()}
              className="flex text-red-650 border border-red-650 bg-white text-xs px-2 font-semibold items-center whitespace-nowrap rounded-lg"
            >
              <PrinterIcon className="mr-2 fill-red-650" /> Print
            </button>
            <div className="border-r border-[#CCC]"></div>
            <ButtonIco
              icon={DownloadExt}
              children="Download Excel"
              className="h-8"
              type="button"
            // onClick={handleDownloadExcel}
            />
          </div>
          {typeof memberDueSummary === 'string' && memberDueSummary.trim() !== '' ?
            <>
              {parse(memberDueSummary)}
            </>
            :
            <div className="text-lg text-[#AAAAAA] mt-10 text-center italic">
              No Records
            </div>
          }
        </>
      }
    </>
  )
};

export default PrintMemberDueSummary;

{/* <div className=" p-1">
  <table className="table-auto w-full mx-auto border-collapse border border-black">
    <caption className="caption-top mb-10 mt-4">
      <div className="underline font-extrabold text-lg uppercase mb-3">{memberDueSummary?.society_title}</div>
      <div className="underline font-bold text-sm">(Reg No.: {memberDueSummary?.reg_no} &nbsp; Dated: {moment(new Date(memberDueSummary?.reg_date))?.format('DD-MM-YYYY')})</div>
      <div className="font-bold mb-5 text-sm">{memberDueSummary?.office_addrezzss} </div>
      <div className="text-[15px] text-left"><span className="font-bold">Members Detailed Due Report Summary As Of: &nbsp; </span> {moment().format('DD-MM-YYYY')}</div>
    </caption>
    <thead>
      <tr>
        {
          memberDueSummary?.header?.slice(0, -1)?.map((item, index) => (
            <td className={`text-sm p-1 h-16 font-semibold text-[14px] border border-black w-[7%]`} key={index}>
              {item}
            </td>
          ))
        }
      </tr>
    </thead>
    <tbody>
      {
        memberDueSummary?.data?.map((item, index) => (
          <React.Fragment key={index}>
            {
              !!item?.length &&
              <tr className="text-[#222] text-sm">
                {
                  item?.map((value, valueIndex) => {
                    value = isNaN(parseFloat(value)) ? value : parseFloat(value)?.toFixed(2)
                    return (
                      <td className="p-1 border border-black align-top" key={valueIndex}>
                        {value?.toString() || '-'}
                      </td>
                    )
                  })
                }
              </tr>
            }
          </React.Fragment>
        ))
      }

      <tr className="text-[#222] font-bold text-sm h-16">
        <td className="p-1 border border-black" colSpan={3}>
          Total (Rs.)
        </td>
        {
          total?.map((item, index) => (
            <td className="p-1 border border-black" key={index}>
              {item}
            </td>
          ))
        }
      </tr>
    </tbody>
  </table>
  {!memberDueSummary?.data?.length && !printLoader && (
    <div className="text-sm my-10 text-center italic">
      No Records
    </div>
  )}
</div> */}