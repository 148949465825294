import React, { useEffect } from "react";
import { DeleteIcon } from "../../../../assets";
import Dropdown2, { DropdownFM2 } from "../../../../components/Dropdown/dropdown2";
import { societyStaffCurrentStatusptions } from "../../../../consts/DropDownOptions";
import { TextInput, TextInputFM } from "../../../../components/InputFields/TextInput";
import TextRadio from "../../../../components/InputFields/TextRadio";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getCreditDebitTariffHeaderList } from "../../../../redux/actions/FinanceActions/SettingActions/CreditDebitTariffHeaderAction";
import { FieldArray } from "formik";
import { getBookingGLMappingList } from "../../../../redux/actions/ClubActions/ViewBookingsAction";

const EditCreditDetailsBox = ({ values, setFieldValue }) => {
    const dispatch = useDispatch()
    const {loginData} = useSelector(state=>state.loginReducer)
    const {tariffHeaderList} = useSelector(state=>state.creditDebitTariffHeaderReducer)
    const { glMappingList} = useSelector(state=>state.viewBookingReducer)

  const handleRadio = (value, key, setFieldValue) => {
    setFieldValue(key, value)
  };

  const timeOfDayOptions = [
    { name: "timeOfDay", value: "Y", label: "Service" },
    { name: "timeOfDay", value: "N", label: "Goods" },
  ];

  useEffect(()=>{
    const data={
        societyId: loginData?.default_community?.community_id,
        page: 'all',
        searchText: '',
        pageLimit: ''
      }
      dispatch(getBookingGLMappingList(data))
      dispatch(getCreditDebitTariffHeaderList(data))  
    }, [])

  return (
    <div>
      <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg mt-3">
        <div className=" border-b border-[#CCC] pb-2 bg-[#FDF2F3]">
          <h1 className="text-base items-center p-2 -mb-2 ml-1">
            Cause Details
          </h1>
        </div>
        <div className=" p-2">
          <div className="p-2 flex flex-wrap gap-2">
          {<FieldArray
            name="details"
            render={arrayHelpers => (
              <>
                {values.details && values.details.length > 0 && (
                  values.details.map((row, index) => {
                  return (
                    <>
                      <div className="flex items-start gap-5">
                        <div className="flex justify-between items-start gap-5 ">
                          <DropdownFM2
                            options={tariffHeaderList?.causes?.filter(i=>i.type==='creditnote')?.map(item=>({
                              label: item.cause,
                              value: item.id,
                              debitaccId: item.GLmapping,
                              hsnCode: item.hsn_code,
                              serviceGood: item.servicegoods,
                              amount: item.amount,
                              gstApplicable: item.gstapplicable
                            }))}
                            width={"176px"}
                            className="text-xs"
                            placeholder="Select Cause"
                            onSelect={(selectedItem) => {
                              setFieldValue(`details.${index}.tariffHeaderId`, selectedItem.label)
                              setFieldValue(`details.${index}.debitAccountId`, selectedItem.debitaccId)
                              setFieldValue(`details.${index}.hsnCode`, selectedItem.hsnCode)
                              setFieldValue(`details.${index}.serviceGood`, selectedItem.serviceGood==='service' ? 'Y' : 'N')
                              setFieldValue(`details.${index}.amount`, selectedItem.amount)
                              setFieldValue(`details.${index}.gstApplicable`, selectedItem.gstApplicable)
                            }}
                            height="38px"
                            name={`details.${index}.tariffHeaderId`}
                            value={
                              values.details[index]?.tariffHeaderId ? 
                              {
                                label: values.details[index]?.tariffHeaderId,
                                value: tariffHeaderList?.causes?.find(item=> item.cause === values.details[index]?.tariffHeaderId)?.id,
                              }
                              :null
                            }
                          />
                          <DropdownFM2
                            options={glMappingList?.map(item=> ({
                              label: item.name,
                              value: item.ac_code
                            }))}
                            width={"176px"}
                            className="text-xs"
                            placeholder="Select Debit Account"
                            onSelect={(selectedItem) => {
                              setFieldValue(`details.${index}.debitAccountId`, selectedItem.value)
                            }}
                            height="38px"
                            name={`details.${index}.debitAccountId`}
                            value={
                              values.details[index]?.debitAccountId ? 
                              {
                                label: glMappingList?.find(item=> item.ac_code===values.details[index]?.debitAccountId)?.name,
                                value: values.details[index]?.debitAccountId
                              }
                              :null
                            }
                          />
                          <TextInputFM
                            label=""
                            placeholder="Enter HSN Code"
                            className="w-28"
                            name={`details.${index}.hsnCode`}
                          />
                          <TextRadio
                            label=""
                            options={timeOfDayOptions}
                            onChange={(value)=>{handleRadio(value, `details.${index}.serviceGood`, setFieldValue)}}
                            className={"w-44 mt-3"}
                            name={`details.${index}.serviceGood`}
                            defaultSelected={values.details[index]?.serviceGood}
                          />
                          <TextInputFM
                            label=""
                            placeholder="Enter Amount"
                            className="w-32"
                            name={`details.${index}.amount`}
                            type='decimal'
                          />
                        </div>
                        <div className="flex mt-1">
                          {!values.details[index].id && (
                            <button
                              className="fill-red-650 p-2"
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                              type="button"
                            >
                              <DeleteIcon />
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  )
                })
              )}
              <div>
                <button
                  type="button"
                  className="text-red-650 font-semibold border-2 border-dashed border-red-650 w-28 text-xs whitespace-nowrap h-9 p-2 mb-4 bg-[#FDF6F6] flex justify-center rounded-md"
                  onClick={() =>
                    arrayHelpers.push({
                      id: 0,
                      tariffHeaderId: '',
                      debitAccountId: '',
                      hsnCode: '',
                      serviceGood: 'Y',
                      amount: '',
                    })
                  }
                >
                  Add More
                </button>
              </div>
              </>
            )}></FieldArray>
              }
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCreditDetailsBox;
