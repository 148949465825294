import * as Yup from "yup";
import "yup-phone-lite";
import { checkFileSizeLessThan2 } from "../../utils/helpers/universalFunctions";

export const addNoticeCircularValidation = () => {
    return Yup.object({
        attachments: Yup.array().of(Yup.object().shape({
            file: Yup.mixed().test('check-size', 'File size should not be more than 2MB', checkFileSizeLessThan2)
        })),
        // descriptionDetails: Yup.string().trim().required('*Description is required').min(10, '*Must contain min 10 letters'),
        title: Yup.string().trim().required('*Subject is required').min(3, '*Subject must contain min 10 letters'),
        types: Yup.string().required("*Required"),
        notice_date: Yup.string().required("*Required"),
        // expiry_date: Yup.string().required("*Required"),
        by_order: Yup.string().default("on order secretory")

    })
}