import React, { useEffect } from "react";
import Layout from "../../../Layout/Layout";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Button from "../../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import ButtonG from "../../../../components/Button/ButtonG";
import { TextInputFM } from "../../../../components/InputFields/TextInput";
import { TextAreaFM } from "../../../../components/InputFields/TextArea";
import { DropdownFM2 } from "../../../../components/Dropdown/dropdown2";
import { CreditNoteMemberTypeOptions } from "../../../../consts/DropDownOptions";
import { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import CreditDetailsBox from "./CreditDetailsBox";
import { Form, Formik } from "formik";
import { AddCreditNoteValidation } from "../../../../validationSchema/financeSchema/billingManagementSchema";
import moment from "moment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getWingsAndFlatDetails } from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import { addCreditNote, getFlatWiseInvoiceList } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageCreditNoteAction";
import { CircularProgress } from "@mui/material";
import { MultiSelectFM } from "../../../../components/MultiSelect/MultiSelect";
import { getBookingGLMappingList } from "../../../../redux/actions/ClubActions/ViewBookingsAction";
import { getInvoiceTypeList, } from "../../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";
import { MANAGE_CREDIT_NOTE_ENDPOINT } from "../../../../Routing/routes";

const AddCreditNote = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { wingsData } = useSelector(state => state.wingsReducer)
  const { loginData } = useSelector(state => state.loginReducer)
  const { updateLoader, invoiceList, loadingInvoiceList } = useSelector(state => state.manageCreditNoteReducer)
  const { glMappingList } = useSelector(state => state.viewBookingReducer)
  const { tariffHeaderList } = useSelector(state => state.creditDebitTariffHeaderReducer)
  const { typeLists } = useSelector((state) => state.invoiceSettingReducer)

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleSubmit = (values, { resetForm }) => {
    dispatch(addCreditNote({
      ...values,
      societyId: loginData?.default_community?.community_id,
      loginType: loginData?.user_type,
      invoiceType: values.invoiceType || 1,
      sgstGLCode: tariffHeaderList?.gst_detail?.sgst ? glMappingList?.find(item => item.name.includes('SGST PAYABLE A/C'))?.ac_code : '',
      cgstGLCode: tariffHeaderList?.gst_detail?.cgst ? glMappingList?.find(item => item.name.includes('CGST PAYABLE A/C'))?.ac_code : '',

      sgstAmount: (values.gstApplicable === 'N' || !tariffHeaderList?.gst_detail?.sgst) ? '0' : ((values.details?.reduce((partialSum, item) => {
        const old_total = parseFloat(item.amount) || 0;
        return partialSum + old_total;
      }, 0) || 0) * (parseFloat(tariffHeaderList?.gst_detail?.sgst) / 100))?.toFixed(2),

      cgstAmount: (values.gstApplicable === 'N' || !tariffHeaderList?.gst_detail?.cgst) ? '0' : ((values.details?.reduce((partialSum, item) => {
        const old_total = parseFloat(item.amount) || 0;
        return partialSum + old_total;
      }, 0) || 0) * (parseFloat(tariffHeaderList?.gst_detail?.cgst) / 100))?.toFixed(2),

      grandTotal: values.gstApplicable === 'N' ?
        (values.details?.reduce((partialSum, item) => {
          const old_total = parseFloat(item.amount) || 0;
          return partialSum + old_total;
        }, 0) || 0)?.toFixed(2)
        :
        ((values.details?.reduce((partialSum, item) => {
          const old_total = parseFloat(item.amount) || 0;
          return partialSum + old_total;
        }, 0) || 0) * ((100 + parseFloat(tariffHeaderList?.gst_detail?.sgst || 0) + parseFloat(tariffHeaderList?.gst_detail?.cgst || 0)) / 100))?.toFixed(2),

      onSuccessCallback: () => {
        handleNavigation(MANAGE_CREDIT_NOTE_ENDPOINT)
      }
    }))
  }



  useEffect(() => {
    const societyId = loginData?.default_community?.community_id
    dispatch(getInvoiceTypeList({ societyId }))
    dispatch(getBookingGLMappingList({ societyId }))
    dispatch(getWingsAndFlatDetails({ societyId }))
  }, [])


  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          initialValues={{
            invoiceType: 1,
            memberType: 'Member',
            date: '',
            voucher: '',
            remarks: '',
            wingId: '',
            flatId: '',
            memberId: '',
            memberGLCode: '',
            memberGLName: '',

            invoiceList: [],
            sendEmail: 'Y',
            sendSMS: 'Y',

            referenceInvoice: [],
            gstApplicable: 'N',
            details: [{
              tariffHeaderId: '',
              debitAccountId: '',
              hsnCode: '',
              serviceGood: 'Y',
              amount: '',
              gstApplicable: 'N'
            }],
          }}
          validationSchema={AddCreditNoteValidation}
          onSubmit={handleSubmit}
        >
          {
            ({ values, errors, setFieldValue }) => (
              <Form>
                <div className="border-b border-[#CCC]">
                  <ListHeader leftContent="Add Credit Note">
                    <div className="flex gap-2">
                      <ButtonG
                        label="Cancel"
                        onClick={() => { navigate(-1) }}
                        className="h-8"
                        type="button"
                      />
                      <Button
                        label={
                          <span className="inline-block w-16">
                            {
                              updateLoader ?
                                <CircularProgress sx={{ color: "white" }} size={17} />
                                :
                                <>Save</>
                            }
                          </span>
                        }
                        className="h-8 px-5"
                        type="submit"
                      />
                    </div>
                  </ListHeader>
                </div>
                <div className="flex gap-4 p-2">
                  <div className="flex flex-row justify-between w-full gap-7">
                    <div className="flex flex-col w-1/2">
                      <div className="flex justify-between gap-3">
                        <div className="mt-2 text-sm">Invoice Type</div>
                        <DropdownFM2
                          options={typeLists?.map(
                            (item) => ({
                              label: item?.invoice_title,
                              value: item?.id,
                            })
                          ) || []}
                          placeholder="Select Invoice Type"
                          className=""
                          width="225px"
                          height="36px"
                          onSelect={(selectedItem) => {
                            setFieldValue('invoiceType', selectedItem.value)

                          }}
                          value={values.invoiceType === "" ?
                            null
                            :
                            {
                              label: typeLists?.find(item => item.id === values.invoiceType)?.invoice_title || "",
                              value: values.invoiceType
                            }
                          }
                          name={"invoiceType"}
                        />
                      </div>
                      <div className="flex justify-between gap-3">

                        <div className="mt-2 text-sm">Member Type</div>
                        <DropdownFM2
                          options={CreditNoteMemberTypeOptions}
                          placeholder="Select Member Type"
                          className=""
                          width="225px"
                          height="36px"
                          onSelect={(selectedItem) => {
                            setFieldValue('memberType', selectedItem.value)
                            setFieldValue('wingId', '')
                            setFieldValue('flatId', '')
                            setFieldValue('memberId', '')
                            setFieldValue('memberGLCode', '')
                            setFieldValue('memberGLName', '')
                            setFieldValue('invoiceList', [])
                            setFieldValue('referenceInvoice', [])
                          }}
                          value={values.memberType === "" ?
                            null
                            :
                            {
                              label: CreditNoteMemberTypeOptions?.find(item => item.value === values.memberType)?.label,
                              value: values.memberType
                            }
                          }
                          name={"memberType"}
                        />
                      </div>
                      <div className="flex flex-row justify-between">
                        <span className="mt-2 text-sm">Credit Note Date	</span>
                        <div className="flex gap-3">
                          <DatePickerComponentFM
                            className="w-56 h-9"
                            name={`date`}
                            onDateChange={(selectedDate) => {
                              setFieldValue(`date`, moment(selectedDate).format('YYYY-MM-DD'))
                            }}
                            minDate={new Date()}
                            defaultValue={values.date ? new Date(values.date) : null}
                          />
                        </div>
                      </div>
                      <TextInputFM
                        label="Hard Copy Voucher"
                        placeholder="Enter Voucher"
                        className="w-56"
                        type="alphaNumber"
                        name='voucher'
                        maxLength={10}
                      />
                      <div className="w-full">
                        <TextAreaFM
                          label="Remarks"
                          placeholder="Enter Remarks"
                          className={"w-56 h-[87px]"}
                          maxLength={300}
                          name='remarks'
                        />
                      </div>
                      {
                        values.memberType === 'Member' ?
                          <div className="flex justify-between gap-3">
                            <div className="mt-2 text-sm">Member/Vendor Details</div>
                            <div>
                              <div className="flex gap-2">
                                <DropdownFM2
                                  options={wingsData?.map((item) => ({
                                    value: item?.wing_id,
                                    label: item?.number,
                                  })) || []}
                                  width={"108px"}
                                  className="text-xs"
                                  placeholder={"Wing"}
                                  onSelect={(selectedItem) => {
                                    setFieldValue(`wingId`, selectedItem.value)
                                    setFieldValue(`flatId`, '')
                                    setFieldValue(`memberId`, '')
                                    setFieldValue(`memberGLCode`, '')
                                    setFieldValue(`memberGLName`, '')
                                    setFieldValue(`invoiceList`, [])
                                    setFieldValue(`referenceInvoice`, [])
                                    dispatch(getFlatWiseInvoiceList({ societyId: loginData?.default_community?.community_id, wingId: selectedItem?.value }))
                                  }}
                                  height='36px'
                                  name={`wingId`}
                                  {...(values.wingId === '' && { value: null })}
                                />
                                <DropdownFM2
                                  options={values.wingId && invoiceList?.filter(item => item.main_member && item.gl_codeid > 0)?.map((item) => ({
                                    value: item?.id,
                                    label: item?.flat_no,
                                    memberId: item?.main_member,
                                    memberGLCode: item?.codeId,
                                    memberGLName: item?.acName,
                                    invoiceList: item?.invoices
                                  })) || []}
                                  loading={loadingInvoiceList}
                                  width={"108px"}
                                  className="text-xs"
                                  placeholder={"Flat/Unit"}
                                  onSelect={(selectedItem) => {
                                    console.log(selectedItem)
                                    setFieldValue(`memberId`, selectedItem.memberId)
                                    setFieldValue(`memberGLCode`, selectedItem.memberGLCode)
                                    setFieldValue(`memberGLName`, selectedItem.memberGLName)
                                    setFieldValue(`invoiceList`, selectedItem.invoiceList)
                                    setFieldValue(`referenceInvoice`, [])
                                    setFieldValue(`flatId`, selectedItem.value)
                                  }}
                                  height='36px'
                                  name={`flatId`}
                                  {...(values.flatId === '' && { value: null })}
                                />
                              </div>
                            </div>
                          </div>
                          :
                          <div className="flex justify-between gap-3">
                            <div className="mt-2 text-sm">Member/Non-Member Details	</div>
                            <DropdownFM2
                              options={glMappingList?.map((item) => ({
                                label: item.name,
                                value: item?.ac_code
                              })) || []}
                              placeholder="Select Debit A/C"
                              className=""
                              width="225px"
                              height="36px"
                              onSelect={(selectedItem) => {
                                setFieldValue('memberGLCode', selectedItem.value)
                                setFieldValue('memberGLName', selectedItem.label)
                              }}
                              {...(values.memberGLCode === "" && {
                                value: null,
                              })}
                              name={"memberGLCode"}
                            />
                          </div>
                      }
                      <div className="flex justify-between gap-">
                        <div className="w-1/2 mt-2 text-sm">Reference Invoice</div>
                        <div className="w-1/2 ml-3 text-sm flex-start">
                          <MultiSelectFM
                            name='referenceInvoice'
                            options={
                              values.invoiceList?.filter(item => item.invoice_no)?.map((item) => ({
                                label: item?.invoice_no,
                                value: item.id,
                              })) || []
                            }
                            width="220px"
                            height={"fit-content"}
                            placeholder="Select Invoice No."
                            className="text-xs"
                            onSelect={(selectedItem) => {
                              setFieldValue(
                                "referenceInvoice",
                                selectedItem.map((item) => item.value)
                              );
                            }}
                            isMulti={true}
                            value={values.invoiceList?.filter(item => values.referenceInvoice?.includes(item.id))?.map((item) => ({
                              label: item?.invoice_no,
                              value: item.id,
                            })) || []}
                          />
                        </div>
                      </div>
                      <div className="flex items-center justify-between gap-3">
                        <div className="w-1/2 text-sm">GST Applicable</div>
                        <div className="w-1/2 py-2 text-sm flex-start">
                          <Checkbox
                            text={""}
                            className={"w-28"}
                            initialchecked={values.gstApplicable === 'Y'}
                            onChange={(e) => { setFieldValue('gstApplicable', e.target.checked ? 'Y' : 'N') }}
                          />
                        </div>
                      </div>
                      <div className="flex items-center justify-between gap-3">
                        <div className="w-1/2 text-sm">Send SMS And Email</div>
                        <div className="flex items-center w-1/2 p-2 text-sm">
                          <Checkbox
                            text={"SMS"}
                            className={"w-28"}
                            initialchecked={values.sendSMS === 'Y'}
                            onChange={(e) => { setFieldValue('sendSMS', e.target.checked ? 'Y' : 'N') }}
                          />
                          <Checkbox
                            text={"Email"}
                            className={"w-28"}
                            initialchecked={values.sendEmail === 'Y'}
                            onChange={(e) => { setFieldValue('sendEmail', e.target.checked ? 'Y' : 'N') }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col w-1/2">
                      <div className="mt-[330px] break-all text-sm text-[#AAAAAA]">
                        {values.memberType === 'Member' && values.memberGLName}
                      </div>
                    </div>
                  </div>

                </div>
                <CreditDetailsBox
                  values={values}
                  setFieldValue={setFieldValue}
                />
              </Form>
            )
          }
        </Formik>
      </div>
    </Layout>
  );
};

export default AddCreditNote;
