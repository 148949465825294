// import React, { useState } from "react";
// import { EditIcon, DeleteIcon, SortIcon } from "../../assets";
// import EditCategoryModal from "./EditCategoryModal";
// import { categoryData } from "./ContactData";
// import DeleteModal from "../Modal/DeleteModal";

// const ContactCategoryTable = ({ data, onDeleteCategory, onEditCategory }) => {
//   const [showDeleteModal, setShowDeleteModal] = useState(false);
//   const [showEditModal, setShowEditModal] = useState(false);
//   const [categoryToEdit, setCategoryToEdit] = useState(null);

//   const handleEditClick = (category) => {
//     setCategoryToEdit(category);
//     setShowEditModal(true);
//   };

//   const handleDeleteClick = (employee) => {
//     setShowDeleteModal(true);
//   };
//   const [sortColumn, setSortColumn] = useState(null);
//   const [sortOrder, setSortOrder] = useState("asc");

//   const handleSort = (columnName) => {
//     if (sortColumn === columnName) {
//       setSortOrder(sortOrder === "asc" ? "desc" : "asc");
//     } else {
//       setSortColumn(columnName);
//       setSortOrder("asc");
//     }
//   };

//   const sortedData = [...categoryData];

//   if (sortColumn) {
//     sortedData.sort((a, b) => {
//       const aValue = a[sortColumn];
//       const bValue = b[sortColumn];
//       if (sortOrder === "asc") {
//         return aValue.localeCompare(bValue);
//       } else {
//         return bValue.localeCompare(aValue);
//       }
//     });
//   }

//   return (
//     <div className="w-full h-[84%]">
//       <table className="min-w-full">
//         <thead className="border-b border-t border-[#CCC]">
//           <tr>
//             <th className="pl-3 py-3 text-left text-xs leading-3 text-[#AAAAAA] tracking-wider">
//               <div className="flex items-center cursor-pointer">S.No</div>
//             </th>
//             <th className=" py-3 text-left text-xs leading-3 text-[#AAAAAA] tracking-wider">
//               <div
//                 className="flex items-center cursor-pointer"
//                 onClick={() => handleSort("concerned_person")}
//               >
//                 Category Name
//                 <SortIcon className="ml-1 w-2 h-2" />
//               </div>
//             </th>
//             <th className="pl-56 py-3 text-left text-xs leading-3 text-[#AAAAAA] tracking-wider">
//               <div
//                 className="flex items-center cursor-pointer"
//                 onClick={() => handleSort("status")}
//               >
//                 Status
//                 <SortIcon className="ml-1 w-2 h-2" />
//               </div>
//             </th>
//             <th className="py-3 text-xs leading-3 text-[#AAAAAA] tracking-wider">
//               Action
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           {sortedData.map((item, index) => (
//             <tr
//               key={item.id}
//               className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
//             >
//               <td className="px-3 h-9 whitespace-no-wrap text-xs leading-4 text-[#222222]">
//                 {item.id}
//               </td>
//               <td className=" h-9 whitespace-no-wrap text-xs leading-4 text-[#222222]">
//                 {item.category_name}
//               </td>
//               <td className="pl-56 h-9 whitespace-no-wrap text-xs leading-4 text-[#222222]">
//                 {item.status}
//               </td>
//               <td className=" h-9 whitespace-no-wrap text-xs leading-4 text-[#222222] flex flex-row items-center justify-center">
//                 <div className="inline-flex items-center">
//                   <button
//                     onClick={() => handleEditClick(item)}
//                     className="mr-4 w-[12px] h-[12px]"
//                     title="Edit"
//                   >
//                     <EditIcon className="fill-[#555] hover:fill-red-650" />
//                   </button>
//                   <button
//                     className="h-[9px] w-[9px]"
//                     title="Delete"
//                     onClick={() => handleDeleteClick(item)}
//                   >
//                     <DeleteIcon className="fill-[#555] hover:fill-red-650" />
//                   </button>
//                 </div>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       {/* Edit Category Modal */}
//       <EditCategoryModal
//         isOpen={showEditModal}
//         onClose={() => setShowEditModal(false)}
//         categoryToEdit={categoryToEdit}
//         onEditCategory={onEditCategory}
//       />
//       {/* Delete Confirmation Modal */}
//       <DeleteModal
//         isOpen={showDeleteModal}
//         onCancel={() => setShowDeleteModal(false)}
//         onDelete={() => {
//           setShowDeleteModal(false);
//         }}
//       />
//     </div>
//   );
// };

// export default ContactCategoryTable;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import {
  DeleteIcon,
  EditIcon,
  SortIcon, 
} from "../../assets";
import styled from "@emotion/styled";
import ItemsPerPage from "../ItemsPerPage/ItemsPerPage";
import Pagination from "../Pagination/Pagination";
import {
  stableSort,
  getComparator,
} from "../../utils/helpers/universalFunctions";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { EDIT_TENANTS_DETAILS_ENDPOINT, TENANTS_INFO_ENDPOINT } from "../../Routing/routes";
import DeleteModal from "../Modal/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import { changeTenantStatus, deleteTenant } from "../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/TenantsInfoAction";
import ChangeStatusModal from "../Modal/ChangeStatusModal";
import * as permissions from '../../Routing/permissions'
import { getPermission } from "../../utils/helpers/getPermissions";
import EditCategoryModal from "./EditCategoryModal";

function ActionComponent({ categoryId}) {
    const navigate = useNavigate();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const {loginData} = useSelector(state=>state.loginReducer)
    const {ipAddress} = useSelector(state=>state.ipAddressReducer)

    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()

    const handleDeleteClick = () => {
      setShowDeleteModal(true);
    };

    const handleDelete = ()=>{
      let data = {
        ipAddress, 
        deletecategoryId: categoryId, 
        sessionId: loginData?.session_id,
        societyId: loginData?.default_community?.community_id,
        page: searchParams.get("page") || 1,
        searchText: searchParams.get("search") || '',
        pageLimit: searchParams.get("pageLimit") || 10,
        status: searchParams.get("status") || '',
        wingId: searchParams.get("wingId") || '',
        flatId: searchParams.get("flatId") || '',
        flag: 'Tenant',
        onSuccessCallback: ()=>{
          setShowDeleteModal(false);
        }
      }
      dispatch(deleteTenant(data))
    }
  
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {
          getPermission(loginData, [permissions.EDIT_TENANTS_PERMISSION]) &&
          <Tooltip title="Edit" arrow>
            <EditIcon
              className="fill-[#555] hover:fill-red-650 me-[8px]"
              onClick={()=>setShowEditModal(true)}
            />
          </Tooltip>
        }
        {
          getPermission(loginData, [permissions.DELETE_TENANTS_PERMISSION]) &&
          <Tooltip title="Delete" arrow>
            <DeleteIcon
              className="fill-[#555] hover:fill-red-650"
              onClick={() => handleDeleteClick()}
            />
          </Tooltip>
        }

        {/* Delete Confirmation Modal */}
        <EditCategoryModal
        isOpen={showEditModal}
          onClose={() => setShowEditModal(false)}
        />
        {/* Delete Confirmation Modal */}
        <DeleteModal
          isOpen={showDeleteModal}
          onCancel={() => setShowDeleteModal(false)}
          onDelete={() => {
            setShowDeleteModal(false);
          }}
        />
      </Box>
    );
  }

  const TableRowStyled = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #ffffff;
  }
  &:nth-of-type(even) {
    background-color: #f4f5f8;
  }
  &:nth-of-type(odd):hover {
    background-color: #ffffff;
  }
  &:nth-of-type(even):hover {
    background-color: #f4f5f8;
  }
`;

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    zIndex: 0,
    color: "#AAAAAA",
    fontSize: "13px",
    padding: '12px 8px 12px 0',
    wordBreak: "break-word",
    fontSize: "13px",
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
    fontSize: "13px",
    padding: '12px 8px 12px 0',
    wordBreak: "break-word",
    fontSize: "13px",
    cursor: "pointer"
  },
}));

const ContactCategoryTable = ({
  loading,
  data,
  columns,
  checkbox = true,
  pagination = true,

  //props are required if pagination is true
  totalCount,
  totalPages,
  start,
  end,
  currentPage,
  rowsPerPage,
  
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const [selected, setSelected] = useState()

  const [showChangeStatusModal, setShowChangeStatusModal] = useState({id: '', open: false});
  const dispatch = useDispatch()
  const {updateLoader} = useSelector(state=>state.tenantInfoReducer)
  const {loginData} = useSelector(state=>state.loginReducer)


  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    const newSelected = event.target.checked ? data.map((item) => item.categoryId) : [];
    setSelected(newSelected);
  };

  const handleClick = (event, id) => {
    const newSelected = selected.includes(id)
      ? selected.filter((selectedId) => selectedId !== id)
      : [...selected, id];
    setSelected(newSelected);
  };

  const handleChangePage = (newPage) => {
    searchParams.delete("page")
    navigate({
      pathname: `${TENANTS_INFO_ENDPOINT}`,
      search: `?${createSearchParams([...searchParams.entries(), ["page", newPage]])}`,
    })
  } ;

  const handleChangeRowsPerPage = (value) => {
    if (rowsPerPage !== value) {
      searchParams.delete("pageLimit")
      navigate({
        pathname: `${TENANTS_INFO_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["pageLimit", value]])}`,
      })
    }
  };

  
  const isSelected = (id) => selected.includes(id);


  const handleChangeStatus = (id, status)=>{
    let data={
      categoryId: id, 
      status: status==='Active' ? 'I' : 'A',
      onRequestEndCallback: ()=>{
        setShowChangeStatusModal(false)
      }
    };
    dispatch(changeTenantStatus(data))
  }

  useEffect(()=>{      
    setSelected([])
  }, [searchParams])

  return (
    <Box
      sx={{ width: "100%", fontSize: "13px" }}
      className="table-component"
    >
    <TableContainer
      className="border-t border-[#CCC]"
      sx={{minHeight: data?.length ? "500px" : 'none'}}
    >
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {!!checkbox && (
              <TableCellStyled size="small" sx={{pl:'0 !important', pr: 2, width: 36 }}>
                <Checkbox
                  size="small"
                  indeterminate={false}
                  checked={data?.length > 0 && selected?.length === data?.length}
                  onChange={handleSelectAllClick}
                  inputProps={{
                    "aria-label": "select all",
                  }}
                  sx={{
                    p: 0,
                    color: "#AAAAA",
                    "&.Mui-checked": {
                      color: "#CF001C",
                    },
                  }}
                  disabled={loading || !data}
                />
              </TableCellStyled>
            )}
            {columns?.map((column) => (
              <TableCellStyled
                size="small"
                key={column.id+ 'heading'}
                align={column.numeric ? "right" : "left"}
                sortDirection={orderBy === column.id ? order : false}
                sx={{

                  width: column.width || "fit-content",
                  minWidth: column.width || "fit-content",
                }}
              >
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={orderBy === column.id ? order : "asc"}
                  onClick={() => {
                    if (column.sort) {
                      handleRequestSort(column.id);
                    }
                  }}
                  IconComponent={SortIcon}
                  sx={{
                    ".MuiTableSortLabel-icon": {
                      opacity: "1 !important",
                    },
                    svg: {
                      flexShrink: 0,
                    },
                  }}
                  hideSortIcon={!column.sort}
                >
                  {column.label}
                  {orderBy === column.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCellStyled>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {
              loading ?
              <TableRow>
                <TableCellStyled sx={{border: 'none'}} colSpan={columns?.length+1}><Loader/></TableCellStyled>
              </TableRow>
              :
              <>
                {!!data?.length && stableSort(data, getComparator(order, orderBy))
                  ?.map((row, rowIndex) => {
                    const isRowSelected = isSelected(row?.id);

                    return (
                      <TableRowStyled
                        key={row?.categoryId}
                        role="checkbox"
                        aria-checked={isRowSelected}
                        tabIndex={-1}
                        selected={isRowSelected}
                      >
                        {!!checkbox && (
                          <TableCellStyled size="small" sx={{pl:'0 !important', zIndex: 0}} onClick={(event) => handleClick(event, row?.categoryId)}>
                            <Checkbox
                              checked={selected?.includes(row?.categoryId)}
                              onChange={(e) =>
                                handleClick(e, row?.categoryId)
                              }
                              size="small"
                              color="primary"
                              inputProps={{
                                "aria-labelledby": `generic-table-checkbox-${row?.id}`,
                              }}
                              sx={{
                                p: 0,
                                color: "#AAAAA",
                                "&.Mui-checked": {
                                  color: "#CF001C",
                                },
                              }}

                            />
                          </TableCellStyled>
                        )}

                        {/* Serial Number */}
                        <TableCellStyled size="small" align="left" onClick={(event) => handleClick(event, row?.categoryId)}>
                          {row?.sNo}
                        </TableCellStyled>
      
                        <TableCellStyled size="small" align="left" sx={{textTransform: 'capitalize'}}>
                          {row?.name}
                        </TableCellStyled>

                        <TableCellStyled size="small" align="left" >
                          {
                            row.status === 'Expired' ?
                            <u className="text-red-650 font-semibold">{row.status}</u>:
                            <div>
                              <u 
                                onClick={()=> 
                                  getPermission(loginData, [permissions.APPROVE_TENANTS_PERMISSION]) ? setShowChangeStatusModal({id: row.categoryId, open: true}) : undefined
                                } 
                                className={`font-semibold ${row.status==='Active' ? 'text-green-600' : (row.status === 'Inactive' ? 'text-orange-500' : 'text-yellow-500')}`} >

                                {row?.status}

                              </u>
                              <ChangeStatusModal
                                isOpen={showChangeStatusModal.open && row.categoryId === showChangeStatusModal.id}
                                onCancel={()=>setShowChangeStatusModal(false)}
                                onConfirm={()=>{handleChangeStatus(row.categoryId, row?.status)}}
                                text={`Do you want to change status to ${row?.status==='Active' ? 'Inactive' : 'Active'} ?`}
                                loading={updateLoader}
                              />
                            </div>
                          }
                        </TableCellStyled>
                        
                        <TableCellStyled size="small" align="left">
                          <ActionComponent categoryId={row.categoryId}/>
                        </TableCellStyled>
                      </TableRowStyled>
                    );
                  })}
              </>
            }
          </TableBody>
        </Table>
      </TableContainer>

      {!!pagination && data && (
        <div className="flex flex-row justify-between mt-3 pt-1 border-t border-[#ccc] text-[14px]">
          <ItemsPerPage
            rowsPerPage={rowsPerPage}
            onChange={handleChangeRowsPerPage}
          />
          <Pagination
            totalCount={totalCount}
            totalPages={totalPages}
            start={start}
            end={end}
            currentPage={currentPage}
            onChange={handleChangePage}
          />
        </div>
      )}
    </Box>
  );
};

ContactCategoryTable.propTypes = {
  columns: PropTypes.array.isRequired,
  defaultSortField: PropTypes.string,
  rowsPerPageOptions: PropTypes.array,
};

export default ContactCategoryTable;
