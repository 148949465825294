import React, { useState } from "react";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import ReviewModal, { ReviewModal2 } from "../Modal/ReviewModal";
import { getPermission } from "../../utils/helpers/getPermissions";
import * as permissions from "../../Routing/permissions";
import { useSelector } from "react-redux";

const RatingComp = ({ row }) => {
  const [isReviewModalOpen, setReviewModalOpen] = useState(false);

  const handleOpenReviewModal = () => {
    setReviewModalOpen(true);
  };

  return (
    <Box
      sx={{
        "& > legend": { mt: 2 },
      }}
    >
      <RatingSection
        label="Give Review"
        name="read-only"
        value={row?.rating}
        onClick={handleOpenReviewModal}
        readOnly
      />

      {isReviewModalOpen && (
        <ReviewModal
          row={row}
          key={row.staffId}
          isOpen={isReviewModalOpen}
          onClose={() => setReviewModalOpen(false)}
        />
      )}
    </Box>
  );
};
export const RatingComp2 = ({
  getTableData = null,
  onChangeReview = null,
  row,
}) => {
  const [isReviewModalOpen, setReviewModalOpen] = useState(false);
  const { loginData } = useSelector((state) => state.loginReducer);

  const handleOpenReviewModal = () => {
    setReviewModalOpen(true);
  };

  return (
    <Box
      sx={{
        "& > legend": { mt: 2 },
        display: "flex",
      }}
    >
      <RatingSection
        label={`${
          row?.status == "Closed"
            ? loginData?.user_type == "Society"
              ? row?.review_rating > 0
                ? "View Review"
                : ""
              : row?.review_rating > 0
              ? "View Review"
              : " Give Review"
            : ""
        }`}
        name="read-only"
        value={row?.review_rating}
        onClick={handleOpenReviewModal}
        readOnly
      />

      {isReviewModalOpen && (
        <ReviewModal2
          row={row}
          // key={row.staffId}
          getTableData={getTableData}
          onChangeReview={onChangeReview}
          isOpen={isReviewModalOpen}
          onClose={() => setReviewModalOpen(false)}
        />
      )}
    </Box>
  );
};

const RatingSection = ({ label, name, value, readOnly, disabled, onClick }) => {
  const { loginData } = useSelector((state) => state.loginReducer);

  return (
    <>
      <div className="flex-col items-center flex">
        <Rating
          name={name}
          value={value}
          readOnly={readOnly}
          size="small"
          precision={0.1}
          // disabled={disabled}
        />
        {getPermission(loginData, [
          permissions.GIVE_STAFF_RATING_PERMISSION,
        ]) && (
          <button
            className="text-center underline text-[#0680FD] cursor-pointer text-xs"
            onClick={onClick}
          >
            {label}
          </button>
        )}
      </div>
    </>
  );
};

export default RatingComp;
