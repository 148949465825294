import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../../Layout/Layout";
import Button from "../../../../components/Button/Button";
import EditInvoiceAccordion from "./EditInvoiceAccordion";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import { getEditInvoiceDetails, updateInvoiceDetails } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageInvoiceActions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { MANAGE_INVOICE_CURRENT_ENDPOINT } from "../../../../Routing/routes";
import editCalculation from "../../../../utils/helpers/invoiceCalculation"
import Loader from "../../../../components/Loader/Loader";
import { CircularProgress } from "@mui/material";

const EditInvoice = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, uploadLoader, editInvoiceDetails } = useSelector(
    (state) => state.manageInvoiceReducer
  );

  const [invoiceData, setInvoiceData] = useState();
  const [headData, setHeadData] = useState([]);

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  useEffect(() => {
    dispatch(
      getEditInvoiceDetails({
        invIds: [id],
        societyId: loginData?.default_community?.community_id,
        is_draft: 0,
      })
    );
  }, []);

  const handlePost = () => {
    dispatch(
      updateInvoiceDetails({
        invIds: [id],
        societyId: loginData?.default_community?.community_id,
        is_draft: 0,
        status: "update",
        invoiceData,
        onSuccessCallback: () => {
          navigate(MANAGE_INVOICE_CURRENT_ENDPOINT);
        },
      }),
    );
  };

  useEffect(() => {
    setInvoiceData(editInvoiceDetails);
  }, [editInvoiceDetails]);

  return (
    <Layout>
      <div className="flex flex-col w-full h-full gap-2">
        <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg p-2">
          <div className="border-b border-[#CCC]">
            <ListHeaderBack
              title="Edit Invoices"
              onClick={handleNavigation(-1)}
            >
              <div className="border-r border-[#CCC] w-full h-full"></div>
              <Button label={
                <span className="inline-block w-12">
                  {uploadLoader ? (
                    <CircularProgress
                      sx={{ color: "white" }}
                      size={17}
                    />
                  ) : ("Edit")}
                </span>
              } className="h-8 px-4" onClick={handlePost} disabled={loading || uploadLoader} />
            </ListHeaderBack>
          </div>
          <div>
            {loading ? <Loader /> : <EditInvoiceAccordion
              editCalculation={editCalculation}
              setInvoiceData={setInvoiceData}
              invoiceData={invoiceData}
              headData={headData}
            />}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EditInvoice;
