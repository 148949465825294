import React, { useEffect } from "react";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Layout from "../../../Layout/Layout";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import {
  OutstandingReportOptions,
  financialYears,
  financialYears2,
} from "../../../../consts/DropDownOptions";
import Search from "../../../../components/SearchBox/Search";
import OutstandingReportTable from "./OutstandingReportTable";
import ButtonIco from "../../../../components/Button/ButtonPrint";
import { DownloadExt } from "../../../../assets";
import ButtonG from "../../../../components/Button/ButtonG";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  downloadOutstandingReports,
  getBillTypeList,
  getOutstandingReports,
} from "../../../../redux/actions/ReportActions/OldFinancialReportsActions";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { DatePickerComponent } from "../../../../components/DatePicker/DatePicker";
import { FINANCE_REPORTS_ENDPOINT } from "../../../../Routing/routes";
import moment from "moment";
import { downloadExcel } from "../../../../utils/helpers/downloadMedia";

export const headCells = [
  { id: "flat", label: "Flat No.", width: "100px" },
  { id: "memberName", label: "Member Name", width: "100px" },
  { id: "openingBalance", label: "Opening Balance", width: "90px" },
  { id: "debit", label: "Debit", width: "90px" },
  { id: "credit", label: "Credit", width: "100px" },
  { id: "outstanding", label: "Outstanding", width: "100px" },
];

const OutstandingReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, billTypeList, outStandingReports } = useSelector(
    (state) => state.oldFinancialReportsReducers
  );

  const handleSearch = (text) => {
    if (text && text !== searchParams.get("search")) {
      searchParams.delete("page");
      searchParams.delete("search");
      navigate({
        pathname: `${FINANCE_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["search", text?.trim()],
        ])}`,
      });
    }
  };

  const handleJoinDate = (selectedDate) => {
    if (
      selectedDate &&
      moment(selectedDate)?.format("YYYY-MM-DD") !==
        searchParams.get("as_on_date")
    ) {
      searchParams.delete("pageLimit");
      searchParams.delete("as_on_date");
      navigate({
        pathname: `${FINANCE_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["as_on_date", moment(selectedDate)?.format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleSelectYear = (selectedItem) => {
    searchParams.delete("page");

    if (selectedItem.value?.toString() !== searchParams.get("year")) {
      searchParams.delete("year");
      navigate({
        pathname: `${FINANCE_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["year", selectedItem.value],
        ])}`,
      });
    }
  };

  const handelSelectType = (selectedItem) => {
    searchParams.delete("page");

    if (selectedItem.value?.toString() !== searchParams.get("user_type")) {
      searchParams.delete("user_type");
      navigate({
        pathname: `${FINANCE_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["user_type", selectedItem.value],
        ])}`,
      });
    }
  };

  const handelSelectBillType = (selectedItem) => {
    searchParams.delete("page");

    if (selectedItem.value?.toString() !== searchParams.get("billType")) {
      searchParams.delete("billType");
      navigate({
        pathname: `${FINANCE_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["billType", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleClear = () => {
    navigate(FINANCE_REPORTS_ENDPOINT);
  };
  const handleDownloadCSV = () => {
    const onSucessCallback = (data) => {
      downloadExcel({ data, fileName: `Finance Reports.xlsx` });
    };
    const data = {
      society_id: loginData?.default_community?.zipgrid_socsrno,
      page: searchParams.get("page") || 1,
      limit: searchParams.get("pageLimit") || 10,
      fin_year: searchParams.get("year"),
      user_type: searchParams.get("user_type"),
      day_book_id: searchParams.get("billType"),
      as_on_date: searchParams.get("as_on_date"),
      onSucessCallback,
    };
    dispatch(downloadOutstandingReports(data));
  };

  useEffect(() => {
    dispatch(
      getBillTypeList({
        society_id: loginData?.default_community?.zipgrid_socsrno,
        daybook_filter: "allType",
      })
    );

    if (!searchParams.get("billType")) {
      navigate({
        pathname: `${FINANCE_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["billType", "00"],
        ])}`,
      });
    }

    dispatch(
      getOutstandingReports({
        society_id: loginData?.default_community?.zipgrid_socsrno,
        page: searchParams.get("page") || 1,
        limit: searchParams.get("pageLimit") || 10,
        fin_year: searchParams.get("year"),
        // fin_year: "202324",
        user_type: searchParams.get("user_type"),
        day_book_id: searchParams.get("billType"),
        as_on_date: searchParams.get("as_on_date"),
      })
    );
  }, [searchParams]);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Finance Reports - Outstanding Report">
            {/* <span className="border-r border-[#CCC]"></span> */}
            {/* <ButtonIco
              icon={DownloadExt}
              children="Send PDF"
              className="h-8"
              type="button"
              onClick={""}
            /> */}
            <ButtonIco
              icon={DownloadExt}
              children="Download Excel"
              className="h-8"
              type="button"
              onClick={handleDownloadCSV}
            />
          </ListHeader>
        </div>

        <div className="flex justify-end gap-3 py-2">
          <Search
            height="8"
            onclick={handleSearch}
            value={searchParams.get("search")}
          />
          <Dropdown2
            options={financialYears2}
            placeholder="Choose Financial Year"
            className=""
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              handleSelectYear(selectedItem);
            }}
            value={
              searchParams.get("year") && {
                value: searchParams.get("year"),
                label: financialYears2?.find(
                  (item) => item.value === searchParams.get("year")
                )?.label,
              }
            }
          />
          <DatePickerComponent
            className="w-48 h-8"
            onDateChange={handleJoinDate}
            defaultValue={
              searchParams.get("as_on_date")
                ? new Date(searchParams.get("as_on_date"))
                : ""
            }
            maxDate={new Date("2024-03-31")}
          />
          <span className="border-r border-[#CCC]"></span>
          <Dropdown2
            options={OutstandingReportOptions}
            width={"172px"}
            height="32px"
            className="text-xs"
            placeholder={"Select Type"}
            onSelect={(selectedItem) => {
              handelSelectType(selectedItem);
            }}
            value={
              searchParams.get("user_type") && {
                value: searchParams.get("user_type"),
                label: OutstandingReportOptions?.find(
                  (item) => item.value === searchParams.get("user_type")
                )?.label,
              }
            }
          />
          <Dropdown2
            options={
              billTypeList
                ? [
                    { daybookname: "All", adbregnno: "00" },
                    ...billTypeList,
                  ]?.map((item) => ({
                    value: item?.adbregnno,
                    label: item?.daybookname,
                  }))
                : []
            }
            placeholder="Select Bill Type"
            className=""
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              handelSelectBillType(selectedItem);
            }}
            value={
              billTypeList &&
              searchParams.get("billType") && {
                value: searchParams.get("billType"),
                label: [
                  { daybookname: "All", adbregnno: "00" },
                  ...billTypeList,
                ]?.find(
                  (item) => item.adbregnno == searchParams.get("billType")
                )?.daybookname,
              }
            }
          />
          <span className="border-r border-[#CCC]"></span>
          <ButtonG label="Clear" className="h-8" onClick={handleClear} />
        </div>
        <div className="ml-1">
          <OutstandingReportTable
            loading={loading}
            data={
              outStandingReports?.list?.map((item) => ({
                flat: item?.flat_no,
                member: item?.member_name,
                openingBalance: item?.opening_balance,
                debit: item?.debit,
                credit: item?.credit,
                outstanding: item?.outstanding,
              })) || []
            }
            columns={headCells}
            checkbox={false}
            pagination={
              outStandingReports?.list?.length > 0
                ? outStandingReports?.list?.length
                : 0
            }
            totalCount={outStandingReports?.total_count}
            totalPages={outStandingReports?.total_pages}
            start={
              (outStandingReports?.current_page - 1) *
                (parseInt(outStandingReports?.current_limit) || 10) +
              1
            }
            end={
              (outStandingReports?.current_page - 1) *
                (parseInt(outStandingReports?.current_limit) || 10) +
              outStandingReports?.list?.length
            }
            currentPage={parseInt(outStandingReports?.current_page)}
            rowsPerPage={parseInt(outStandingReports?.current_limit) || 10}
          ></OutstandingReportTable>
        </div>
        {!outStandingReports?.list?.length && !loading && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
    </Layout>
  );
};

export default OutstandingReport;
