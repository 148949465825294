import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ARCHIVE_MEMBERS_OCCUPANTS_ENDPOINT, OCCUPANTS_INFO_ENDPOINT } from "../../../../Routing/routes";
import Layout from "../../../Layout/Layout";
import Button from "../../../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {getOccupantDetails } from '../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/OccupantsInfoAction'
import countries from '../../../../consts/countries'
import Loader from '../../../../components/Loader/Loader'
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import moment from "moment";
import FlatDetails from "./FlatDetails";
import { validateDate } from "../../../../utils/helpers/universalFunctions";
import ImageComponent from "../../../../components/ImageComponent/ImageComponent";


const MembersDetails = () => {
  const navigate = useNavigate();
  const dispatch =useDispatch()
  const {id, userId} = useParams()
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const {loading, occupantDetail} = useSelector(state=>state.occupantInfoReducer)
  const {loginData} = useSelector(state=>state.loginReducer)

  const toggleAccordion = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleArchiveMembersClick = () => {
    navigate(ARCHIVE_MEMBERS_OCCUPANTS_ENDPOINT);
  };

  useEffect(()=>{
    dispatch(getOccupantDetails({
      subscribeId: id, 
      societyId: loginData?.default_community?.community_id,
      userType: loginData?.user_type,
      userId: userId
    }))
  }, [])

  return (
    <Layout>
      <div className="flex flex-col gap-2 w-full">
        <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg p-2">
          <div className="border-b border-[#CCC]">
            <ListHeaderBack title="Member Details" onClick={()=>{navigate(OCCUPANTS_INFO_ENDPOINT)}}>
              <Button label='Archive Member' type={'button'} onClick={handleArchiveMembersClick} />
            </ListHeaderBack>
          </div>
          <div className="min-h-[410px]">
            {
              loading && id !== occupantDetail?.user_detail?.subscribe_id ?
              <Loader/>
              :
              <>
              {
                occupantDetail &&
                <div className="grid grid-cols-2 gap-4 min p-3">
                  <div>
                    <div className="flex text-sm mb-4">
                      <div className="font-medium w-52 text-[#aaa] shrink-0">Name</div>
                      <div className="break-all">{occupantDetail?.user_detail?.prefix + '. ' + occupantDetail?.user_detail?.f_name + ' ' + occupantDetail?.user_detail?.m_name + ' ' + occupantDetail?.user_detail?.l_name || '-'}</div>
                    </div>
                    <div className="flex text-sm mb-4">
                      <div className="font-medium w-52 text-[#aaa] shrink-0">Title</div>
                      <div className="break-all">{occupantDetail?.user_detail?.subscriber_title || '-'}</div>
                    </div>
                    <div className="flex text-sm mb-4">
                      <div className="font-medium w-52 text-[#aaa] shrink-0">Country Code</div>
                      <div className="break-all"> { countries.filter(item=> item.phonecode.replace('+', '') === occupantDetail?.user_detail?.mobile_country_code?.replace('+', ''))?.[0]?.name + ' (+' + occupantDetail?.user_detail?.mobile_country_code?.replace('+', '') + ')' || '-'}</div>
                    </div>
                    <div className="flex text-sm mb-4">
                      <div className="font-medium w-52 text-[#aaa] shrink-0">Mobile Number</div>
                      <div className="break-all">{occupantDetail?.user_detail?.mobile_number}</div>
                    </div>
                    <div className="flex text-sm mb-4">
                      <div className="font-medium w-52 text-[#aaa] shrink-0">Secondary Mobile Number</div>
                      <div className="break-all">{occupantDetail?.user_detail?.alternate_number || '-'}</div>
                    </div>
                    <div className="flex text-sm mb-4">
                      <div className="font-medium w-52 text-[#aaa] shrink-0">Identity Proof No.</div>
                      <div className="break-all">{occupantDetail?.user_detail?.aadhar_uid_no || '-'}</div>
                    </div>
                    <div className="flex text-sm mb-4">
                      <div className="font-medium w-52 text-[#aaa] shrink-0">Primary Email ID</div>
                      <div className="break-all">{occupantDetail?.user_detail?.subscriber_pri_email || '-'}</div>
                    </div>
                    <div className="flex text-sm mb-4">
                      <div className="font-medium w-52 text-[#aaa] shrink-0">Secondary Email</div>
                      <div className="break-all">{occupantDetail?.user_detail?.subscriber_sec_email || '-'}</div>
                    </div>                    
                    <div className="flex text-sm mb-4">
                      <div className="font-medium w-52 text-[#aaa] shrink-0">GST Registration No.</div>
                      <div className="break-all">{occupantDetail?.user_detail?.gst_registration_no || '-'}</div>
                    </div>
                    <div className="flex text-sm mb-4">
                      <div className="font-medium w-52 text-[#aaa] shrink-0">GST Register Address</div>
                      <div className="break-all">{occupantDetail?.user_detail?.gst_register_address || '-'}</div>
                    </div>
                    <div className="flex text-sm mb-4">
                      <div className="font-medium w-52 text-[#aaa] shrink-0">GST Register Pin Code</div>
                      <div className="break-all">{occupantDetail?.user_detail?.gst_register_address_pin_code || '-'}</div>
                    </div>
                  </div>
                  <div className="relative">
                    <div className="flex text-sm mb-4">
                      <div className="font-medium w-52 text-[#aaa] shrink-0">Name as on Register</div>
                      <div className="break-all">{occupantDetail?.user_detail?.prefix + '. ' + occupantDetail?.user_detail?.f_name + ' ' + occupantDetail?.user_detail?.m_name + ' ' + occupantDetail?.user_detail?.l_name || '-'}</div>
                    </div>
                    <div className="flex text-sm mb-4">
                      <div className="font-medium w-52 text-[#aaa] shrink-0">Class Of Member</div>
                      <div className="break-all">{occupantDetail?.user_detail?.class_of_member || '-' }</div>
                    </div>
                    <div className="flex text-sm mb-4">
                      <div className="font-medium w-52 text-[#aaa] shrink-0">Landline Number (Office) </div>
                      <div className="break-all">{occupantDetail?.user_detail?.landline_office || '-'}</div>
                    </div>
                    <div className="flex text-sm mb-4">
                      <div className="font-medium w-52 text-[#aaa] shrink-0">Identity Proof Attachment</div>
                      <div className="break-all">{occupantDetail?.user_detail?.aadhar_file ? <Link target="_blank" className="ms-1 underline text-sm text-blue-500" to={occupantDetail?.user_detail?.aadhar_file_url}>Click To View</Link> : '-'}</div>
                    </div>
                    <div className="flex text-sm mb-4">
                      <div className="font-medium w-52 text-[#aaa] shrink-0">Pancard No.</div>
                      <div className="break-all">{occupantDetail?.user_detail?.pancard_no || '-'}</div>
                    </div>
                    <div className="flex text-sm mb-4">
                      <div className="font-medium w-52 text-[#aaa] shrink-0">Send Email On Secondary Email </div>
                      <div className="break-all">{occupantDetail?.user_detail?.send_email_on_sec_email || '-'}</div>
                    </div>
                    <div className="flex text-sm mt-8 mb-4">
                      <div className="font-medium w-52 text-[#aaa] shrink-0">Gender</div>
                      <div className="break-all">{occupantDetail?.user_detail?.gender || '-'}</div>
                    </div>
                    <div className="flex text-sm mb-4">
                      <div className="font-medium w-52 text-[#aaa] shrink-0">Date of Birth</div>
                      <div className="break-all">{validateDate(occupantDetail?.user_detail?.dob) ? moment(new Date (occupantDetail?.user_detail?.dob)).format('DD MMM, YYYY') : '-'}</div>
                    </div>
                    <div className="flex">
                      <div className="flex text-sm w-2/3">
                        <div className="font-medium w-52 text-[#aaa] shrink-0">Anniversary Date </div>
                        <div className="break-all">{(validateDate(occupantDetail?.user_detail?.anniversary_date))? moment(new Date (occupantDetail?.user_detail?.anniversary_date)).format('DD MMM, YYYY') : '-'}</div>
                      </div>
                    </div>
                    <div className="absolute right-0 bottom-2">
                      <ImageComponent className="float-right" defaultUrl={occupantDetail?.user_detail?.profile_image_url} width={32} height={32} />
                    </div>
                  </div>
                </div>
              }
              </>
            }
          </div>

          </div>
          <div className="mt-2">
            <FlatDetails 
              userDetails={occupantDetail?.user_detail} 
              flatDetails={occupantDetail?.flat_data}
              loading={loading && id !== occupantDetail?.user_detail?.subscribe_id}
              />
          </div>
      </div>
    </Layout>
  );
};

export default MembersDetails;
