import React from "react";
import Calendar from "react-calendar";
// import 'react-calendar/dist/Calendar.css';

import './Calender.css';

const MyCalendar = ({ selectedDate, handleDateChange, minDate }) => (
  <Calendar
    minDate={minDate}
    onClickDay={handleDateChange}
    value={selectedDate}
    activeStartDate={selectedDate}
    locale="en-US"
    formatShortWeekday={(locale, date) =>
      new Intl.DateTimeFormat(locale, { weekday: 'short' }).format(date).charAt(0)
    }
    formatDay={(locale, date) => {
      return new Intl.DateTimeFormat(locale, { day: '2-digit' }).format(date);
    }} 
  />
);

export default MyCalendar;
