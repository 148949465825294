import * as type from "../../actions/index.types";

export function meetingsReducer(
  state = {
    loading: false,
    updateLoader: false,
    printLoader: false,
    noticeList: null,
    savedNoticeDetails: null,
    error: null,
  },
  action
) {
  switch (action.type) {
    case type.GET_NOTICE_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_NOTICE_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        noticeList: action.payload,
      };
    case type.GET_NOTICE_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        noticeList: null,
      };

    case type.GET_SAVED_NOTICE_DETAILS:
    return {
        ...state,
        loading: true,
        error: null,
    };
    case type.GET_SAVED_NOTICE_DETAILS_SUCCESS:
    return {
        ...state,
        error: null,
        loading: false,
        savedNoticeDetails: action.payload,
    };
    case type.GET_SAVED_NOTICE_DETAILS_FAILURE:
    return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        savedNoticeDetails: null,
    };

    case type.UPDATE_SAVED_NOTICE:
    return {
        ...state,
        updateLoader: true,
    };
    case type.UPDATE_SAVED_NOTICE_SUCCESS:
    return {
        ...state,
        updateLoader: false,
    };
    case type.UPDATE_SAVED_NOTICE_FAILURE:
    return {
        ...state,
        updateLoader: false,
    };

    case type.DELETE_SAVED_NOTICE:
    return {
        ...state,
        updateLoader: true,
    };
    case type.DELETE_SAVED_NOTICE_SUCCESS:
    return {
        ...state,
        updateLoader: false,
    };
    case type.DELETE_SAVED_NOTICE_FAILURE:
    return {
        ...state,
        updateLoader: false,
    };

    default:
      return state;
  }
}
