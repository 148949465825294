import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import ListHeaderBack from "../../components/ListHeader/ListHeaderBack";
import Button from "../../components/Button/Button";
import ButtonG from "../../components/Button/ButtonG";
import Checkbox from "../../components/Checkbox/Checkbox";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { TextInputFM } from "../../components/InputFields/TextInput";
import { UploadButtonFM } from "../../components/Button/UploadButton";
import { DropdownFM2 } from "../../components/Dropdown/dropdown2";
import { useDispatch, useSelector } from "react-redux";
import {
  editDocumentList,
  getDocFileList,
  getDocumentOccupantsList,
  getFolderList,
} from "../../redux/actions/DeskActions/DocumentActions";
import { Form, Formik } from "formik";
import { CircularProgress } from "@mui/material";
import { UploadDocumentValidation } from "../../validationSchema/deskSchema/logaTicketSchema";
import { getPermission } from "../../utils/helpers/getPermissions";
import * as permissions from "../../Routing/permissions";

function CheckboxGroup({
  options,
  selectedOptions,
  onChange,
  setSelectedOptions,
}) {
  const handleSelectAll = () => {
    if (options?.length && options?.length === selectedOptions?.length) {
      setSelectedOptions([]);
    } else {
      setSelectedOptions(options?.map((item) => item.id));
    }
  };

  const handleSingleCheckbox = (e, item) => {
    if (e.target.checked) {
      setSelectedOptions((prev) => {
        const r = [...prev, item];
        return r;
      });
    } else {
      setSelectedOptions((prev) => {
        const r = prev.filter((ele) => ele !== item);
        return r;
      });
    }
  };

  return (
    <>
      <div>
        <Checkbox
          text={"Select All"}
          initialchecked={
            options?.length && options?.length === selectedOptions?.length
          }
          onChange={handleSelectAll}
        />
      </div>
      {options?.map((item, index) => {
        return (
          <div key={index}>
            <Checkbox
              initialchecked={selectedOptions?.includes(item.id)}
              text={`${item.name} (${item.wingFlat})`}
              onChange={(e) => {
                handleSingleCheckbox(e, item.id);
              }}
            />
          </div>
        );
      })}
    </>
  );
}

function EditDocument() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { rootFolderId, subFolderId, filename } = useLocation().state;
  const fileId = parseInt(useParams().id);
  const [subFolderNewId, setSubFolderId] = useState(subFolderId);
  const { loginData } = useSelector((state) => state.loginReducer);
  const { updateLoader, documentsOccupantList, documentFileList } = useSelector(
    (state) => state.documentReducer
  );
  const data = useSelector((state) => state.documentReducer);
  const [folderSelected, setFolderSelected] = useState(() =>
    data.documentFolderList?.find((item) => item.id === rootFolderId)
  );
  const [subFolderSelected, setSubFolderSelected] = useState(
    data.documentFolderList?.find((item) => item.id === rootFolderId)
  );
  const [viewListAccess, setViewListAccess] = useState([]);
  const [smsListAccess, setSmsListAccess] = useState([]);

  const handleSaveClick = async (values, { resetForm }) => {
    let sendRequest = {
      id: fileId,
      societyId: loginData?.default_community?.community_id,
      ...values,
      viewListAccess,
      smsListAccess,
    };
    dispatch(editDocumentList(sendRequest));
  };

  useEffect(() => {
    if (documentFileList) {
      let file_object = documentFileList.filter((item) => item.id === fileId);
      if (file_object.length) file_object = file_object[0];
      else file_object = false;

      if (file_object) {
        if (file_object.view_access !== "") {
          const view_access = file_object.view_access
            .split(",")
            .map((id) => parseInt(id));
          setViewListAccess(view_access);
        }
        if (file_object.sms_access !== "") {
          const sms_access = file_object.sms_access
            .split(",")
            .map((id) => parseInt(id));
          setSmsListAccess(sms_access);
        }
      }
    }
  }, [documentFileList]);

  useEffect(() => {}, [viewListAccess]);
  useEffect(() => {
    let payload = { societyId: loginData?.default_community?.community_id };
    dispatch(getFolderList(payload));
    dispatch(getDocumentOccupantsList(payload));
    dispatch(
      getDocFileList({
        selectedFolderId: data.selectedFolderId,
        societyId: loginData?.default_community?.community_id,
      })
    );
  }, []);

  const goback = () => {
    navigate(-1);
  };

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          initialValues={{
            folderName: "",
            folderId: documentFileList?.find((item) => item.id === fileId)
              ?.folder,
            title: filename,
            file: documentFileList?.find((item) => item.id === fileId)?.file,
            view_access: documentFileList?.viewListAccess,
            sms_access: documentFileList?.smsListAccess,
          }}
          enableReinitialize
          validationSchema={UploadDocumentValidation}
          onSubmit={handleSaveClick}
        >
          {(formik) => {
            return (
              <Form>
                <div className="border-b border-[#CCC]">
                  <ListHeaderBack title="Edit Document" onClick={goback}>
                    <ButtonG
                      label="Cancel"
                      className="h-8"
                      onClick={goback}
                      type={`button`}
                    />
                    <Button
                      label={
                        <span className="inline-block">
                          {updateLoader ? (
                            <CircularProgress
                              sx={{ color: "white" }}
                              size={17}
                            />
                          ) : (
                            "Save"
                          )}
                        </span>
                      }
                      className="h-8 px-4"
                      type={`submit`}
                    />
                  </ListHeaderBack>
                </div>
                <div className="py-2 gap-2 m-1">
                  <div className="text-sm">
                    <div className="w-1/2 grid grid-cols-2 gap-y-3 text-[#222222]">
                      <div className="mt-2">Select Folder</div>
                      <div>
                        <DropdownFM2
                          placeholder={"Select"}
                          name={"folderId"}
                          options={data.documentFolderList?.map((item) => ({
                            label: item.name,
                            value: item.id,
                            folder: item,
                          }))}
                          // value={{
                          //   label: folderSelected?.name,
                          //   value: folderSelected?.id?.toString,
                          // }}
                          value={{
                            label: data?.documentFolderList?.find(
                              (item) =>
                                item.id === parseInt(formik.values.folderId)
                            )?.name,
                            value: formik.values.folderId,
                          }}
                          width="224px"
                          height="36px"
                          onSelect={(selectedItem) => {
                            setSubFolderId(null);
                            setFolderSelected(selectedItem.folder);
                            formik.setFieldValue(
                              "folderId",
                              parseInt(selectedItem.value)
                            );
                          }}
                        />
                      </div>
                      {subFolderNewId ? (
                        <>
                          <div className="mt-2">Select SubFolder 1</div>
                          <div>
                            <DropdownFM2
                              placeholder={"Select"}
                              name={"folderId"}
                              options={subFolderSelected?.sub_folders?.map(
                                (item) => ({
                                  label: item.name,
                                  value: item.id,
                                })
                              )}
                              value={{
                                label: subFolderSelected?.sub_folders?.find(
                                  (item) => item.id === subFolderNewId
                                )?.name,
                                value: subFolderNewId,
                              }}
                              width="224px"
                              height="36px"
                              onSelect={(selectedItem) => {
                                setSubFolderId(null);
                                formik.setFieldValue(
                                  "folderId",
                                  selectedItem.value
                                );
                              }}
                            />
                          </div>
                        </>
                      ) : folderSelected ? (
                        folderSelected.sub_folders.length !== 0 ? (
                          <>
                            <div className="mt-2">Select SubFolder 2</div>
                            <div>
                              <DropdownFM2
                                placeholder={"Select"}
                                name={"folderId"}
                                options={folderSelected.sub_folders?.map(
                                  (item) => ({
                                    label: item.name,
                                    value: item.id,
                                  })
                                )}
                                width="224px"
                                height="36px"
                                onSelect={(selectedItem) => {
                                  formik.setFieldValue(
                                    "folderId",
                                    selectedItem.value
                                  );
                                }}
                              />
                            </div>
                          </>
                        ) : null
                      ) : null}

                      <div className="mt-2">Title</div>
                      <div>
                        <TextInputFM
                          label=""
                          placeholder="Enter"
                          className="w-56"
                          name={`title`}
                        />
                      </div>
                      <div>View Document</div>
                      <div>
                        {getPermission(loginData, [
                          permissions.DOWNLOAD_DOCUMENTS_PERMISSION,
                        ]) &&
                        documentFileList?.find((item) => item.id === fileId)
                          ?.file ? (
                          <Link
                            className="underline text-blue-500"
                            to={
                              documentFileList?.find(
                                (item) => item.id === fileId
                              )?.file
                            }
                            target="_blank"
                          >
                            Click here to view
                          </Link>
                        ) : (
                          "-"
                        )}
                      </div>

                      <div className="mt-2">Choose Document</div>
                      <div>
                        <UploadButtonFM
                          showFileName={true}
                          name={`file`}
                          accept={
                            "image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                          }
                          onSelect={(e) => formik.setFieldValue(`file`, e)}
                          fileName={formik.values.file?.name}
                        />
                      </div>
                    </div>

                    <div className="font-bold my-5">
                      Choose Members Who Can See This Document
                    </div>
                    <div className="w-full grid grid-cols-4 gap-2">
                      <CheckboxGroup
                        options={documentsOccupantList?.map((item) => ({
                          id: item?.user_id,
                          name: item?.full_name,
                          wingFlat: item?.wing_flat,
                          flatId: item?.house_unique_id,
                        }))}
                        selectedOptions={viewListAccess}
                        setSelectedOptions={setViewListAccess}
                        name={`view_access`}
                      />
                    </div>

                    <div className="font-bold my-5">
                      Choose Members To Send SMS
                    </div>
                    <div className="w-full grid grid-cols-4 gap-2">
                      <CheckboxGroup
                        options={documentsOccupantList?.map((item) => ({
                          id: item?.user_id,
                          name: item?.full_name,
                          wingFlat: item?.wing_flat,
                          flatId: item?.house_unique_id,
                        }))}
                        selectedOptions={smsListAccess}
                        setSelectedOptions={setSmsListAccess}
                        name={`sms_access`}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Layout>
  );
}

export default EditDocument;
