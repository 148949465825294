import * as types from '../../index.types';

export function getEmployeeRolesList(data) {
    return {
        type: types.GET_EMPLOYEE_ROLE_LIST,
        data
    };
}

export function getEmployeeRolesDetails(data) {
    return {
        type: types.GET_EMPLOYEE_ROLE_DETAILS,
        data
    };
}

export function addAndUpdateEmployeeRole(data) {
    return {
        type: types.ADD_AND_UPDATE_EMPLOYEE_ROLE_DETAILS,
        data
    };
}

export function getOccupantsRolesList(data) {
    return {
        type: types.GET_OCCUPANT_ROLE_LIST,
        data
    };
}

export function getOccupantsRolesDetails(data) {
    return {
        type: types.GET_OCCUPANTS_ROLE_DETAILS,
        data
    };
}

export function addAndUpdateOccupantRole(data) {
    return {
        type: types.ADD_AND_UPDATE_OCCUPANTS_ROLE_DETAILS,
        data
    };
}

export function getFamilyRolesList(data) {
    return {
        type: types.GET_FAMILY_ROLE_LIST,
        data
    };
}

export function getFamilyRolesDetails(data) {
    return {
        type: types.GET_FAMILY_ROLE_DETAILS,
        data
    };
}

export function addAndUpdateFamilyRoles(data) {
    return {
        type: types.ADD_AND_UPDATE_FAMILY_ROLE_DETAILS,
        data
    };
}

export function getRoleAndRightsSetting(data) {
    return {
        type: types.GET_ROLE_RIGHT_SETTINGS,
        data
    };
}

export function updateRoleAndRightsStatus(data) {
    return {
        type: types.UPDATE_ROLE_RIGHT_STATUS,
        data
    };
}

export function deleteEmployeeOccupantAndFamilyRole(data) {
    return {
        type: types.DELETE_RIGHTS_AND_ROLES,
        data
    };
}

export function getSocietyAccessSetting(data) {
    return {
        type: types.GET_SOCIETY_ACCESS_SETTINGS,
        data
    };
}

export function updateSocietyAccessSetting(data) {
    return {
        type: types.UPDATE_SOCIETY_ACCESS_SETTINGS,
        data
    };
}