import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Accordion = ({
  label,
  isOpen,
  toggleAccordion,
  children,
  description, // added description prop
}) => {
  return (
    <div className="border rounded-lg border-[#CCCCCC] my-2">
      <div className="cursor-pointer items-center bg-[#FDF2F3] rounded-lg p-3 border-b">
        <div className="flex items-center justify-between w-full">
          <div
            className="flex items-center overflow-hidden text-ellipsis basis-full"
            onClick={toggleAccordion}
          >
            <div>
              <button
                className="text-white text-base font-semibold mr-3 w-5 h-5 bg-red-650 
                border rounded flex justify-center items-center"
              >
                {isOpen ? (
                  <FontAwesomeIcon icon={faChevronUp} size="xs" />
                ) : (
                  <FontAwesomeIcon icon={faChevronDown} size="xs" />
                )}
              </button>
            </div>
            <div className="text-[#000000] text-sm font-semibold justify-between flex w-full text-justify">
              <span className="w-full mr-10 capitalize">{label}</span>
            </div>
          </div>
        </div>
      </div>
      {isOpen && <>{children}</>}
    </div>
  );
};

const ParkingChargeAccordions = ({ data }) => {
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const {parkingChargesList} = useSelector(state=>state.parkingManagementReducer)

  const toggleAccordion = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div>
      {data?.map((item, index) => (
        <Accordion
          label={item?.title}
          key={index}
          isOpen={index === openAccordionIndex}
          toggleAccordion={() => toggleAccordion(index)}
          description={item?.description}
        >
          <div className="p-3 text-[#222222] text-sm flex">
            <div className="w-1/2">
              {item?.description?.map((subItem, i) => (
                <div key={i} className="flex justify-between mb-3">
                  <p>{subItem.label}:</p>
                  <p>{parkingChargesList?.[subItem.key] || 0}</p>
                </div>
              ))}
            </div>
          </div>
        </Accordion>
      ))}
    </div>
  );
};

export default ParkingChargeAccordions;
