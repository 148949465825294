import { call, put, takeLatest } from 'redux-saga/effects'
import {notify} from '../../../../utils/notification'
import * as types from '../../../actions/index.types'
import * as req from '../../../../axios/request/SetupRecordsReqs/Occupants&TenantsReqs/ViewInvitiesReqs'

function* getInvitedOccupantsAndTenantsList(action) {
    try {
        const result = yield call(req.getInvitedOccupantsAndTenantsListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_INVITED_OCCUPANTS_AND_TENANTS_LIST_SUCCESS, payload: {...action.data, ...result.data}});  
        } else {
            yield put({ type: types.GET_INVITED_OCCUPANTS_AND_TENANTS_LIST_FAILURE, payload: action.data, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}



function* sendInviteToOccupantsAndTenants(action) {
    try {
        const result = yield call(req.sendInviteToOccupantsAndTenantsReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.SEND_INVITE_EMAIL_TO_OCCUPANTS_AND_TENANTS_SUCCESS});  
        }else{
            notify('Unable to send Email.')
            yield put({ type: types.SEND_INVITE_EMAIL_TO_OCCUPANTS_AND_TENANTS_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}
export default function* ViewInvitiesSaga() {
    yield takeLatest(types.GET_INVITED_OCCUPANTS_AND_TENANTS_LIST, getInvitedOccupantsAndTenantsList);
    yield takeLatest(types.SEND_INVITE_EMAIL_TO_OCCUPANTS_AND_TENANTS, sendInviteToOccupantsAndTenants);
}