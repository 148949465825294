import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SOCIETY_PROFILE_ENDPOINT } from "../../../Routing/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import Layout from "../../Layout/Layout";
import { SocietyProfileAuditData } from "../../../consts/SocietyProfileAuditData";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import { useDispatch, useSelector } from "react-redux";
import {getSocietyProfileAuditLogs} from '../../../redux/actions/SetupRecordsActions/BuildingActions/SocietyProfileAction'
import moment from "moment";
import Loader from '../../../components/Loader/Loader'
import ImageComponent from "../../../components/ImageComponent/ImageComponent";

const Accordion = React.memo(
  ({ data, isOpen, toggleAccordion, label, descr, desc }) => {  
    const oldSocietyDetails = data?.old_society_details
    const newSocietyDetails = data?.new_society_details

    return (
      <div className="border rounded-lg border-[#CCCCCC] my-2 overflow-hidden">
        <div
          className="flex justify-between cursor-pointer items-center bg-[#FDF2F3] rounded-t-lg p-3 border-b "
          onClick={toggleAccordion}
        >
          <div className="flex items-center">
            <button className="text-white text-sm font-semibold mr-3 w-5 h-5 bg-red-650 border rounded flex justify-center items-center">
              {isOpen ? (
                <FontAwesomeIcon icon={faChevronUp} size="xs" />
              ) : (
                <FontAwesomeIcon icon={faChevronDown} size="xs" />
              )}
            </button>
            <div className="text-[#000000] text-sm font-semibold">{label}</div>
          </div>
          <div>
            <div className="text-xs text-right text-[#888]">{desc}</div>
            <div className="text-xs text-right text-[#888]">{descr}</div>
          </div>
        </div>
        {isOpen && (
          <div className="p-3">
            {SocietyProfileAuditData.map((item, i0) => (
              <React.Fragment key={i0}>
                {item.label ? (
                  <div className="acord-section font-semibold text-[#222222] p-2 text-sm">
                    {item.label}
                  </div>
                ) : null}
                {item.values.map((rowData, i1) => (
                  <React.Fragment key={i1}>
                    <div className="flex justify-between p-2">
                      {rowData.map((fieldData, index) => (
                        <div
                          className="flex justify-between w-full text-sm"
                          key={index}
                        >
                          <div className="w-1/2">{fieldData.label}</div>
                          <div className="w-1/2">
                            {
                              fieldData.key === 'logo' ? (
                                <ImageComponent 
                                  width={32}
                                  height={32}
                                  defaultUrl={oldSocietyDetails[fieldData.key]}
                                />  
                            ) : (
                              oldSocietyDetails[fieldData.key] ? oldSocietyDetails[fieldData.key] : '-'
                            )}
                          </div>
                          <div className="w-1/2">
                            {
                              fieldData.key === 'logo' ? (
                                <ImageComponent 
                                  width={32}
                                  height={32}
                                  defaultUrl={newSocietyDetails[fieldData.key]}
                                /> 
                            ) : (
                              newSocietyDetails?.[fieldData.key] ? newSocietyDetails?.[fieldData.key] : '-'
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    );
  }
);

const SocietyProfileAuditLog = () => {
  const navigate = useNavigate();
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading, societyProfileAuditLogData} = useSelector(state=>state.societyProfileReducer)

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const toggleAccordion = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(()=>{
    const sendRequest={
      societyId: loginData?.default_community?.community_id,
    }
    dispatch(getSocietyProfileAuditLogs(sendRequest))
  },[])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <ListHeaderBack
          onClick={handleNavigation(SOCIETY_PROFILE_ENDPOINT)}
          title="Society Profile Audit Logs"
        ></ListHeaderBack>
        <div className="border-b border-[#CCCCCC]"></div>
        <div>

          {
            loading && !societyProfileAuditLogData ?
            <Loader/>
            :
            societyProfileAuditLogData &&
            societyProfileAuditLogData?.map((item, index)=>{
              return (
                <Accordion
                  label={`Audit ${index+1}`}
                  key={index}
                  data={societyProfileAuditLogData?.[index]}
                  isOpen={index === openAccordionIndex}
                  toggleAccordion={() => toggleAccordion(index)}
                  desc={`Modified By ${item?.modified_by} On ${moment(new Date(item?.modified_dateTime))?.format('MMM DD,YYYY hh:mm A')}`}
                  descr={`Ip Address: ${item?.IpAddress}. Session id - ${item?.modified_sessionId}`}
                />
              )
            })
          }
        </div>
      </div>
    </Layout>
  );
};

export default SocietyProfileAuditLog;
