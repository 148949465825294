import * as Yup from "yup";

export const AddUpdateEmployeeRoleValidation = () => {
    return Yup.object({
      role: Yup.string().required('*Required'),
      submitToAdmin: Yup.string(),
    })
  };

export const AddUpdateOccupantRoleValidation = () => {
    return Yup.object({
        role: Yup.string().required('*Required'),
        submitToAdmin: Yup.string(),
    })
};

export const AddUpdateFamilyRoleValidation = () => {
    return Yup.object({
        role: Yup.string().required('*Required'),
        submitToAdmin: Yup.string(),
    })
};