import React, { useEffect } from "react";
import ListHeader from "../../../components/ListHeader/ListHeader";
import Layout from "../../Layout/Layout";
import Button from "../../../components/Button/Button";
import Search from "../../../components/SearchBox/Search";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import AllFacilityTable from "./AllFacilityTable";
import { ADD_FACILITY_ENDPOINT, ALL_FACILITY_ENDPOINT } from "../../../Routing/routes";
import { useDispatch } from "react-redux";
import { getFacilityList } from "../../../redux/actions/ClubActions/BookAFacilityAction";
import { useSelector } from "react-redux";
import ButtonG from "../../../components/Button/ButtonG";

export const headCells = [
  { id: "sNo", label: "S No.", width: "50px" },
  { id: "facilityName", label: "Facility Name", sort: true, width: "180px" },
  { id: "booking", label: "Booking", sort: true, width: "130px" },
  {
    id: "chargeable",
    label: "Chargeable",
    sort: true,
    width: "140px",
  },
  { id: "status", label: "Status", sort: true, width: "120px" },
  { id: "action", label: "Action", width: "80px" },
];

const rows = [
  {
    sNo: 1,
    facilityName: "Chess Playing Room",
    booking: "Daily",
    chargeable: "Yes",
    status: "Approved",
  },
];

const AllFacility = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading, facilityList} = useSelector(state=>state.bookAFacilityReducer)

  const handleNavigation = (path) => () => {
    navigate(path);
  };
  
  const handleSearch = (text)=>{
    if(text && text !== searchParams.get("search")){
      searchParams.delete('page')
      searchParams.delete("search")
      navigate({
        pathname: `${ALL_FACILITY_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["search", text?.trim()]])}`,
      })
    }
  }

  const handleClear = ()=>{
    navigate(ALL_FACILITY_ENDPOINT)
  }
  
  const getTableData =() =>{
    const data={
      societyId: loginData?.default_community?.community_id,
      page: searchParams.get("page") || 1,
      searchText: searchParams.get("search") || '',
      pageLimit: searchParams.get("pageLimit") || 10,
    }
    dispatch(getFacilityList(data))
  }
    
  useEffect(()=>{      
    getTableData()
  }, [searchParams])


  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <ListHeader leftContent="All Facility">
          <Search 
            height="8" 
            onclick={handleSearch} 
            value={searchParams.get("search")} 
            placeholder="Search by facility name"
          />
          <ButtonG label='Clear' className='h-8' onClick={handleClear} />
          <div className="border-r border-[#CCC]"></div>
          <Button label="Add New Facility" onClick={handleNavigation(ADD_FACILITY_ENDPOINT)} />
        </ListHeader>
        <div className="ml-1">
          <AllFacilityTable
            loading={loading}
            data={facilityList?.facilty_list?.map((item, index)=>({
              sNo: (index+1)?.toString()?.padStart(2, '0'),
              facilityId: item.id,
              facilityName:  item.facilities_name,
              booking: item.booking,
              chargeable: item.chargeable,
              status: item.status,
            }))}
            columns={headCells}
            checkbox={false}
            pagination={facilityList?.facilty_list?.length}
            totalCount={facilityList?.total_count}
            totalPages={facilityList?.total_pages}
            start={(facilityList?.current_page - 1) * (searchParams.get("pageLimit") || 10) + 1}
            end={(facilityList?.current_page - 1) * (searchParams.get("pageLimit") || 10) + facilityList?.facilty_list?.length}
            currentPage={parseInt(facilityList?.current_page)}
            rowsPerPage={searchParams.get("pageLimit") || 10}          ></AllFacilityTable>
        </div>
        {!facilityList?.facilty_list?.length && !loading && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
    </Layout>
  );
};

export default AllFacility;
