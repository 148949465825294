export const SocietyProfileAuditData = [
  {
    label: "",
    values: [
      [
        {
          key: "title",
          label: "Society Name",
          value: "Treasure Town Society",
          value2: "Synapse",
        },
      ],
      [
        {
          key: "logo",
          label: "Logo",
          value:
            "https://images.unsplash.com/photo-1682686580391-615b1f28e5ee?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          value2:
            "https://images.unsplash.com/photo-1682686580391-615b1f28e5ee?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
      ],
      // [{ key: "brand_name", label: "Brand Name", value: "-", value2: "-" }],
      [{ key: "pan_no", label: "Pan Number", value: "-", value2: "-" }],
      [{ key: "tan_no", label: "Tan Number", value: "-", value2: "-" }],
      [
        {
          key: "sprintly_id",
          label: "Spintly Organisation ID",
          value: "3859",
          value2: "-",
        },
      ],
      [
        {
          key: "sprintly_device",
          label: "Spintly Device ID",
          value: "4611",
          value2: "-",
        },
      ],
      [
        {
          key: "currency",
          label: "Community Currency",
          value: "Rupees",
          value2: "-",
        },
      ],
    ],
  },

  {
    label: "About",
    values: [
      [
        {
          key: "product_description",
          label: "Description",
          value: "N/A",
          value2: "N/A",
        },
      ],
      [
        {
          key: "club_timing",
          label: "Club Timing",
          value: "N/A",
          value2: "N/A",
        },
      ],
    ],
  },

  {
    label: "Basic Information",
    values: [
      [
        {
          key: "builder_name",
          label: "Builder Name",
          value: "N/A",
          value2: "N/A",
        },
      ],
      [
        {
          key: "complex_type",
          label: "Complex Type",
          value: "N/A",
          value2: "N/A",
        },
      ],
      [
        {
          key: "construction_status",
          label: "Construction Status",
          value: "N/A",
          value2: "N/A",
        },
      ],
      [{ key: "area", label: "Area", value: "Mumbai", value2: "N/A" }],
      [{ key: "city_name", label: "City", value: "Mumbai", value2: "N/A" }],
      [{ key: "state_name", label: "State", value: "Maharashtra", value2: "N/A" }],
      [{ key: "pincode", label: "Pin Code", value: "452012", value2: "N/A" }],
    ],
  },

  {
    label: "Association Information",
    values: [
      [
        {
          key: "association_name",
          label: "Association Name",
          value: "N/A",
          value2: "N/A",
        },
      ],
      [
        {
          key: "isAssociationRegistered",
          label: "Is Registered",
          value: "Yes",
          value2: "N/A",
        },
      ],
      [
        {
          key: "gst_registration_no",
          label: "GST Registration No.",
          value: "N/A",
          value2: "N/A",
        },
      ],
      // [
      //   {
      //     key: "brand_name",
      //     label: "Brand Name",
      //     value: "Kalanu Group",
      //     value2: "N/A",
      //   },
      // ],

      [
        {
          key: "pt_number",
          label: "Property Tax Number",
          value: "-",
          value2: "N/A",
        },
      ],
    ],
  },
  {
    label: "Contact Information",
    values: [
      [
        {
          key: "office_address",
          label: "Address",
          value: "Mumbai Kurla",
          value2: "N/A",
        },
      ],
      [
        {
          key: "phone",
          label: "Phone",
          value: "9876543210",
          value2: "N/A",
        },
      ],
      [
        {
          key: "email",
          label: "Email",
          value: "Treasuretown@email.com",
          value2: "N/A",
        },
      ],
      [
        {
          key: "website",
          label: "Website",
          value: "N/A",
          value2: "N/A",
        },
      ],
      [
        {
          key: "other_details",
          label: "Other Details",
          value: "N/A",
          value2: "N/A",
        },
      ],
      // [
      //   {
      //     key: "check_login",
      //     label: "Check Login to Send SMS",
      //     value: "Yes",
      //     value2: "N/A",
      //   },
      // ],
      [
        {
          key: "support_email",
          label: "Support Email",
          value: "N/A",
          value2: "N/A",
        },
      ],
    ],
  },
];