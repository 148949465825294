import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextInputFM } from "../../../../components/InputFields/TextInput";
import Layout from "../../../Layout/Layout";
import Button from "../../../../components/Button/Button";
import ButtonG from "../../../../components/Button/ButtonG";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Form, Formik } from "formik";
import { DropdownFM2 } from "../../../../components/Dropdown/dropdown2";
import {
  genderOptions,
  societyStaffTypeOptions,
} from "../../../../consts/DropDownOptions";
import { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import TransferFlatBoxForm from "./TransferFlatBoxForm";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import { TextAreaFM } from "../../../../components/InputFields/TextArea";

const TransferFlatForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  //   const {updateLoader, staffProfileList} = useSelector(state=>state.staffPersonnelReducer)
  const [buttonType, setButtonType] = useState("");

  const handleCancelClick = () => {
    navigate(-1);
  };

  //   const handleSaveClick = (values, {resetForm, initialValues}) => {

  //     dispatch(addSocietyStaff({
  //       ...values,
  //       societyId: loginData?.default_community?.community_id,
  //       onSuccessCallback: buttonType==='Save' ? ()=>{
  //         navigate(SOCIETY_STAFF_ENDPOINT)
  //       }
  //       :
  //       ()=>{
  //         resetForm()
  //       }
  //     }))
  //   };

  //   useEffect(()=>{
  //     const societyId= loginData?.default_community?.community_id
  //     dispatch(getSocietyStaffProfileList({societyId}))
  //     dispatch(getSocietyWingFlatList({societyId}))

  //   }, [])

  const [checked, setChecked] = useState(false);

  const handleChecked = (event, setFieldValue) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      setFieldValue("isUrgent", "Yes");
    } else {
      setFieldValue("isUrgent", "No");
    }
  };

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          initialValues={{
            prefix: "",
            firstName: "",
            lastName: "",
            profilePic: "",
            staffType: "",
            staffProfileId: "",
            startValidity: "",
            endValidity: "",
            workPerDay: "",
            salary: "",
            mobileCountryCode: "",
            countryISO: "",
            countryId: "",
            contact: "",
            email: "",
            gender: "",
            address: "",
            dob: "",
            vehicleType: "",
            vehicleNumber: "",
            fingerPrintImg: "",
            adharCardNo: "",
            adharDoc: "",
            panCardNo: "",
            panDoc: "",
            drivingLicenceNo: "",
            drivingLicenceDoc: "",
            rationNo: "",
            rationDoc: "",
            flatData: [
              {
                flatId: "",
                joinDate: "",
                status: "",
              },
            ],
          }}
          // onSubmit={handleSaveClick}
          validationSchema={""}
        >
          {({ values, errors, setFieldValue }) => {
            return (
              <Form>
                <div className="flex justify-between border-b border-[#CCC] pb-2">
                  <h1 className="text-base font-semibold p-1">
                    {" "}
                    Transfer of Flat No. (A-Block - A703)
                  </h1>
                  <div className="flex gap-2">
                    <ButtonG
                      label="Cancel"
                      onClick={handleCancelClick}
                      className="h-8"
                      type="button"
                    />
                    <Button
                      label="Save"
                      //   label={
                      //     <span className="w-12 inline-block">
                      //       {
                      //         updateLoader && buttonType==='Save' ?
                      //           <CircularProgress sx={{color: "white"}} size={17}/>
                      //           :
                      //           'Save'
                      //         }
                      //     </span>
                      //     }
                      className="px-5 h-8"
                      type="submit"
                      onClick={() => setButtonType("Save")}
                    />
                  </div>
                </div>
                <div className="flex gap-4 p-2">
                  <div className="flex flex-row justify-between w-full gap-7">
                    <div className="flex flex-col w-1/2">
                      <div className="flex items-center justify-between text-sm my-4">
                        <span className="">Full Name</span>
                        <div className="flex justify-between gap-2">
                          <span>-</span>
                          <span>
                            {" "}
                            <Checkbox
                              text="Remove Tenants"
                              className="text-[13px]"
                              initialchecked={checked}
                              onChange={(e) => {
                                handleChecked();
                              }}
                            />
                          </span>
                        </div>
                      </div>
                      {/* <div className="border-b border-[#CCC]"></div> */}
                      <TextInputFM
                        label="Authority For Transfer"
                        placeholder="Enter "
                        className="w-56"
                        name={`lastName`}
                        maxLength={20}
                      />
                        <div className="flex flex-row justify-between">
                        <span className="text-sm mt-2">
                          Date of Admission
                        </span>
                        <div className="flex gap-4">
                          <DatePickerComponentFM
                            className="w-56 h-9 justify-between"
                            onDateChange={(selectedDate) => {
                              setFieldValue("startValidity", selectedDate);
                            }}
                            name="startValidity"
                            defaultValue={
                              values.startValidity
                                ? new Date(values.startValidity)
                                : null
                            }
                            maxDate={new Date()}
                          />
                        </div>
                      </div>
                      <div className="flex flex-row justify-between">
                        <span className="text-sm mt-2">
                          Date of Cessation
                        </span>
                        <div className="flex gap-4">
                          <DatePickerComponentFM
                            className="w-56 h-9 justify-between"
                            onDateChange={(selectedDate) => {
                              setFieldValue("startValidity", selectedDate);
                            }}
                            name="startValidity"
                            defaultValue={
                              values.startValidity
                                ? new Date(values.startValidity)
                                : null
                            }
                            maxDate={new Date()}
                          />
                        </div>
                      </div>
                        <TextAreaFM
                          label="Reason for Cessation"
                          placeholder="Enter"
                          className="w-56"
                          name={`workPerDay`}
                          type="number"
                          row={4}
                        />
                        <TextAreaFM
                          label="Remarks (I Register)"
                          placeholder="Enter"
                          className="w-56"
                          name={`workPerDay`}
                          type="number"
                          row={4}
                        />
                        <TextAreaFM
                          label="Remarks (Share Register)"
                          placeholder="Enter"
                          className="w-56"
                          name={`workPerDay`}
                          type="number"
                          row={4}
                        />
                        <TextInputFM
                        label="Cashbook Journal Folio No."
                        placeholder="Enter contact number"
                        className="w-56"
                        name="contact"
                        type="phone"
                        maxLength={17}
                      />
                    </div>
                  </div>
                </div>
                <TransferFlatBoxForm
                  values={values}
                  setFieldValue={setFieldValue}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </Layout>
  );
};

export default TransferFlatForm;
