import React, { useEffect, useState } from "react";
import Layout from "../../../Layout/Layout";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../../components/Button/Button";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import {
  makeBookingYearOptionsForCalander,
  monthOptions,
} from "../../../../consts/DropDownOptions";
import BookingCalender from "../../../../components/BookingCalender/BookingCalender";
import { getBookingList } from "../../../../redux/actions/ClubActions/ViewBookingsAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  getFacilityList,
  getRateChart,
  getTimeSlotList,
} from "../../../../redux/actions/ClubActions/BookAFacilityAction";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import { SELECT_MINUTES_FOR_MINUTES_ENDPOINT } from "../../../../Routing/routes";

const ScheduleAMeeting = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loginData } = useSelector((state) => state.loginReducer);

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  useEffect(() => {
    dispatch(getRateChart({ facilityId: id }));
    dispatch(
      getFacilityList({ societyId: loginData?.default_community?.community_id })
    );
    dispatch(getTimeSlotList({ facilityId: id }));
  }, []);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader
            leftContent={`Schedule A Meeting`}
          >
            <Button label="Go" className="h-8 px-6" onClick={handleNavigation(SELECT_MINUTES_FOR_MINUTES_ENDPOINT)} />
          </ListHeader>
        </div>
        <div className="w-full flex flex-col">
          <CalenderComp />
        </div>
      </div>
    </Layout>
  );
};

export const CalenderComp = () => {
  const [date, setDate] = useState(new Date());
  const [calenderData, setCalenderData] = useState([]);
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { id } = useParams();
  const { loading, bookingList } = useSelector(
    (state) => state.viewBookingReducer
  );

  const getTableData = () => {
    const data = {
      societyId: loginData?.default_community?.community_id,
      page: 0,
      facilityId: id,
    };
    dispatch(getBookingList(data));
  };

  useEffect(() => {
    getTableData();
  }, [date]);

  useEffect(() => {
    let data = [];

    bookingList?.booking_list?.forEach((item) => {
      if (item.status === "A" || item.status === "UP") {
        if (moment(item.from_date)?.isSame(item?.to_date)) {
          let existingDateIndex = data?.findIndex(
            (item) => item.date === moment(item.from_date)?.format("DD-MM-YYYY")
          );
          if (existingDateIndex >= 0) {
            data[existingDateIndex].entries = [
              ...data[existingDateIndex].entries,
              {
                name: item?.wing_flat,
                status: item.status,
              },
            ];
          } else {
            data.push({
              date: moment(item.from_date)?.format("DD-MM-YYYY"),
              entries: [
                {
                  name: item?.wing_flat,
                  status: item.status,
                },
              ],
            });
          }
        } else {
          const start = new Date(item.from_date);
          const end = new Date(item.to_date);

          for (
            let date = start;
            date <= end;
            date.setDate(date.getDate() + 1)
          ) {
            let existingDateIndex = data?.findIndex(
              (item) => item.date === moment(date)?.format("DD-MM-YYYY")
            );
            if (existingDateIndex >= 0) {
              data[existingDateIndex].entries = [
                ...data[existingDateIndex].entries,
                {
                  name: item?.wing_flat,
                  status: item.status,
                  time: `${moment(item?.start_time, "hh:mm:ss")?.format(
                    "hh:mm: A"
                  )} - ${moment(item?.end_time, "hh:mm:ss")?.format(
                    "hh:mm: A"
                  )}`,
                },
              ];
            } else {
              data.push({
                date: moment(date)?.format("DD-MM-YYYY"),
                entries: [
                  {
                    name: item?.wing_flat,
                    status: item.status,
                    time: `${moment(item?.start_time, "hh:mm:ss")?.format(
                      "hh:mm: A"
                    )} - ${moment(item?.end_time, "hh:mm:ss")?.format(
                      "hh:mm: A"
                    )}`,
                  },
                ],
              });
            }
          }
        }
      }
    });
    setCalenderData(data);
  }, [bookingList]);

  return (
    <>
      <div className="px-20 py-4">
        <div className="flex justify-between gap-4 items-center my-3">
          <div className="flex gap-2 items-center">
            {/* Year Dropdown */}
            <span>Year</span>
            <Dropdown2
              options={makeBookingYearOptionsForCalander}
              width="100px"
              height="36px"
              placeholder={date.getFullYear()}
              onSelect={(selectedItem) => {
                const newDate = new Date(date);
                newDate.setFullYear(parseInt(selectedItem.value));
                setDate(newDate);
              }}
              value={{
                label: date.getFullYear(),
                value: date.getFullYear(),
              }}
            />
          </div>
          <div className="flex gap-2 items-center">
            <span>Month</span>
            <Dropdown2
              options={monthOptions}
              width="100px"
              height="36px"
              placeholder={date.toLocaleString("en-US", { month: "long" })}
              onSelect={(selectedItem) => {
                const newDate = new Date(date);
                newDate.setDate(1);
                newDate.setMonth(parseInt(selectedItem.value) - 1);
                setDate(newDate);
              }}
              value={{
                label: monthOptions?.find(
                  (item) =>
                    item.value ===
                    (date.getMonth() + 1)?.toString()?.padStart(2, "0")
                )?.label,
                value: (date.getMonth() + 1)?.toString()?.padStart(2, "0"),
              }}
            />
          </div>
          {/* Month Dropdown */}
        </div>
      </div>

      <div className="ms-2 px-16">
        <BookingCalender
          selectedDate={date}
          calenderData={calenderData}
          handleDateChange={undefined}
        />
      </div>
    </>
  );
};

export default ScheduleAMeeting;
