import React from "react";
import { useNavigate } from "react-router-dom";
import { RESIDENT_VENDORS_ENDPOINT } from "../../Routing/routes";
import { TextInput } from "../InputFields/TextInput";
import { TextArea } from "../InputFields/TextArea";
import TextRadio from "../InputFields/TextRadio";
import Dropdown from "../Dropdown/Dropdown";
import Button from "../Button/Button";
import ButtonG from "../Button/ButtonG";
import Layout from "../../pages/Layout/Layout";

const AddResidentVendor = () => {
  const navigate = useNavigate();

  const handleCancelClick = () => {
    navigate(RESIDENT_VENDORS_ENDPOINT);
  };

  const handleSaveClick = () => {
    console.log("Form data saved to the console.");
  };
  const handleTimeOfDayChange = (value) => {};

  const timeOfDayOptions = [
    { name: "timeOfDay", value: "day", label: "Day" },
    { name: "timeOfDay", value: "night", label: "Night" },
  ];

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 mb-5">
        <div className="flex justify-between border-b border-[#CCC] pb-2">
          <h1 className="text-base font-semibold p-1 items-center flex">
            Add Resident Vendor
          </h1>
          <div className="flex gap-2">
            <ButtonG
              label="Cancel"
              onClick={handleCancelClick}
              className="h-8"
            />
            <Button label="Save" onClick={handleSaveClick} className="h-8" />
          </div>
        </div>
        <div className="flex justify-between gap-4 p-2">
          <div className="flex flex-col gap-2 w-1/2 pr-5">
            <div className="flex items-center justify-between mb-4 mt-3">
              <span className="text-sm">Category</span>
              <Dropdown
                options={[
                  "Security",
                  "Emergency Contact",
                  "Society AMC Category",
                  "Society Vendor Category",
                ]}
                width="52"
                placeholder="Select Category"
                className="text-xs"
                onSelect={(selectedItem) => {
                  // Handle the selected item here
                }}
              />
            </div>
            <TextInput
              label="Full Name"
              placeholder="Enter Full Name"
              className="w-52"
            />
            <TextInput
              label="Phone Number"
              placeholder="Enter Phone Number"
              className="w-52"
            />
            <TextInput
              label="Email Address"
              placeholder="Enter Email Address"
              className="w-52"
            />
            <TextArea
              label="Address"
              placeholder="Enter Address"
              className={"w-52"}
            />
            <div className="my-3">
              <TextRadio
                label="Time of Day"
                options={timeOfDayOptions}
                defaultSelected="day"
                onChange={handleTimeOfDayChange}
                className={'w-52'}
              />
          </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddResidentVendor;
