import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import ListHeaderBack from "../../components/ListHeader/ListHeaderBack";
import Button from "../../components/Button/Button";
import ButtonG from "../../components/Button/ButtonG";
import Checkbox from "../../components/Checkbox/Checkbox";
import { useNavigate } from "react-router-dom";
import { TextInputFM } from "../../components/InputFields/TextInput";
import { UploadButtonFM } from "../../components/Button/UploadButton";
import { DropdownFM2 } from "../../components/Dropdown/dropdown2";
import { useDispatch, useSelector } from "react-redux";
import {
  getDocumentOccupantsList,
  getFolderList,
  updateDocumentsList,
} from "../../redux/actions/DeskActions/DocumentActions";
import { Form, Formik } from "formik";
import { CircularProgress } from "@mui/material";
import { UploadDocumentValidation } from "../../validationSchema/deskSchema/logaTicketSchema";

function CheckboxGroup({
  options,
  selectedOptions,
  setSelectedOptions,
}) {

  const handleSelectAll = () => {
    if (options?.length && options?.length === selectedOptions?.length) {
      setSelectedOptions([]);
    } else {
      setSelectedOptions(options?.map((item) => item.id));
    }
  };

  const handleSingleCheckbox = (e, item) => {
    if (e.target.checked) {
      setSelectedOptions((prev) => [...prev, item]);
    } else {
      setSelectedOptions((prev) => prev.filter((ele) => ele !== item));
    }
  };

  return (
    <>
      <div>
        <Checkbox
          text={"Select All"}
          initialchecked={
            options?.length && options?.length === selectedOptions?.length 
          }
          onChange={handleSelectAll}
        />
      </div>
      {options?.map((item, index) => {
        return (
          <div key={index}>
            <Checkbox
              initialchecked={selectedOptions?.includes(item.id)}
              text={`${item.name} (${item.wingFlat})`}
              onChange={(e) => {
                handleSingleCheckbox(e, item.id);
              }}
            />
          </div>
        );
      })}
    </>
  );
}

function UploadDocument() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { updateLoader, documentsOccupantList } = useSelector(
    (state) => state.documentReducer
  );
  const data = useSelector((state) => state.documentReducer);
  const [folderSelected, setFolderSelected] = useState(null);
  const [viewListAccess, setViewListAccess] = useState([]);
  const [smsListAccess, setSmsListAccess] = useState([]);

  const handleSaveClick = async(values, { resetForm }) => {
    let sendRequest = {
      ...values,
      societyId: loginData?.default_community?.community_id,
      viewListAccess,
      smsListAccess,
      onSuccessCallback: ()=>{
        resetForm()
      }
    };
    dispatch(updateDocumentsList(sendRequest))

  };

  useEffect(() => {
    let payload = { societyId: loginData?.default_community?.community_id };
    dispatch(getFolderList(payload));
    dispatch(getDocumentOccupantsList(payload));
  }, []);

  const goback = () => {
    navigate(-1);
  };

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          initialValues={{
            folderName: "",
            folderId: "",
            title: "",
            chooseDocument: "",
          }}
          enableReinitialize
          validationSchema={UploadDocumentValidation}
          onSubmit={handleSaveClick}
        >
          {(formik) => {
            return (
              <Form>
                <div className="border-b border-[#CCC]">
                  <ListHeaderBack title="Upload Documents" onClick={goback}>
                    <ButtonG
                      label="Cancel"
                      className="h-8"
                      onClick={goback}
                      type={`button`}
                    />
                    <Button
                      label={
                        <span className="inline-block">
                          {updateLoader ? (
                            <CircularProgress
                              sx={{ color: "white" }}
                              size={17}
                            />
                          ) : (
                            "Save"
                          )}
                        </span>
                      }
                      className="h-8 px-4"
                      type={`submit`}
                    />
                  </ListHeaderBack>
                </div>
                <div className="py-2 gap-2 m-1">
                  <div className="text-sm">
                    <div className="w-1/2 grid grid-cols-2 gap-y-3 text-[#222222]">
                      <div className="mt-2">Select Folder</div>
                      <div>
                        <DropdownFM2
                          placeholder={"Select"}
                          name={"folderId"}
                          options={data.documentFolderList?.map((item) => ({
                            label: item.name,
                            value: item.id,
                            folder: item,
                          }))}
                          width="224px"
                          height="36px"
                          onSelect={(selectedItem) => {
                            setFolderSelected(selectedItem.folder);
                            formik.setFieldValue(
                              "folderId",
                              selectedItem.value
                            );
                          }}
                        />
                      </div>
                      {folderSelected &&
                        folderSelected.sub_folders.length !== 0 && (
                          <>
                            <div className="mt-2">Select SubFolder</div>
                            <div>
                              <DropdownFM2
                                placeholder={"Select"}
                                name={"folderId"}
                                options={folderSelected.sub_folders?.map(
                                  (item) => ({
                                    label: item.name,
                                    value: item.id,
                                  })
                                )}
                                width="224px"
                                height="36px"
                                onSelect={(selectedItem) => {
                                  formik.setFieldValue(
                                    "folderId",
                                    selectedItem.value
                                  );
                                }}
                              />
                            </div>
                          </>
                        )}

                      <div className="mt-2">Title</div>
                      <div>
                        <TextInputFM
                          label=""
                          placeholder="Enter"
                          className="w-56"
                          name={`title`}
                        />
                      </div>

                      <div className="mt-2">Choose Document</div>
                      <div>
                        <UploadButtonFM
                          showFileName={true}
                          name={`chooseDocument`}
                          accept={
                            "image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                          }
                          onSelect={(e) =>
                            formik.setFieldValue(`chooseDocument`, e)
                          }
                          fileName={formik.values.chooseDocument?.name}
                        />
                      </div>
                    </div>

                    <div className="font-bold my-5">
                      Choose Members Who Can See This Document
                    </div>
                    <div className="w-full grid grid-cols-4 gap-2">
                      <CheckboxGroup
                        options={documentsOccupantList?.map((item) => ({
                          id: item?.user_id,
                          name: item?.full_name,
                          wingFlat: item?.wing_flat,
                          flatId: item?.house_unique_id,
                        }))}
                        selectedOptions={viewListAccess}
                        setSelectedOptions={setViewListAccess}
                      />
                    </div>

                    <div className="font-bold my-5">
                      Choose Members To Send SMS
                    </div>
                    <div className="w-full grid grid-cols-4 gap-2">
                      <CheckboxGroup
                        options={documentsOccupantList?.map((item) => ({
                          id: item?.user_id,
                          name: item?.full_name,
                          wingFlat: item?.wing_flat,
                          flatId: item?.house_unique_id,
                        }))}
                        selectedOptions={smsListAccess}
                        setSelectedOptions={setSmsListAccess}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Layout>
  );
}

export default UploadDocument;
