import { notify } from '../../../../utils/notification';
import apiEndpoints from '../../../api';
import axiosInstance2 from '../../../axiosInstance2'
import {logOut} from '../../../../utils/helpers/logOut'
import { errorMsg } from '../../../../consts/responseMessages';


export async function getGSTAndNOCSettingReq(action) {
    const BODY={
        'id': action.data?.societyId,
    }

    return axiosInstance2.post(apiEndpoints.GET_GST_NOC_SETTING_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}


export async function updateGSTAndNOCSettingReq(action) {
    const {gstData} = action.data
    const BODY ={
        "id": action.data.societyId,
        "limit": gstData.gstSetting.exemption.value,
        "tax": gstData.gstSetting.sgst.value,
        "Sb_Cess": gstData.gstSetting.cgst.value,
        "kk_Cess": gstData.gstSetting.igst.value,
        "applied": gstData.gstSetting.gstAppliedOn.value,
        "noc_rate": gstData.nocSetting.noc.value,
        "automaticnoccal": gstData.nocSetting.calculateAuto.value,
        "exemption": {},
        "servicegood": {}
    }

    gstData.tableData.forEach(item=>{
        if(item.gst==='Yes')
            BODY[item.key] = item.gst;      

        if(item.gstExemption)
            BODY['exemption'][`${item.key}`] = item.gstExemption;

        if(item.noc==='Yes')
            BODY[`${item.key}_noc`] = item.noc;

        if(item.showServiceGood && item.serviceGood==='Y')
            BODY['servicegood'][item.key] = item.serviceGood;
    })


    return axiosInstance2.post(apiEndpoints.UPDATE_GST_NOC_SETTING_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}