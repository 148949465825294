import React, { useState } from "react";
import ViewCommitteeAccordionAccessSettings from "./ViewCommitteeAccordionAccessSetting";

const ViewCommitteeAccessSettingsComp = ({accessSetting}) => {
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      <div>
        {accessSetting && Object.entries(accessSetting)?.map((item, index) => (
          <ViewCommitteeAccordionAccessSettings
            label={item[0]}
            key={item[0]+ index}
            data={Object.entries(item?.[1])}
            isOpen={index === openAccordionIndex}
            toggleAccordion={() => toggleAccordion(index)}
            accessSetting = {accessSetting}
            desc={item.desc}
          />
        ))}
      </div>
    </>
  );
};

export default ViewCommitteeAccessSettingsComp;
