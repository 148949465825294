import { notify } from '../../../../utils/notification';
import apiEndpoints from '../../../api';
import axiosInstance from '../../../axiosInstance'
import { logOut } from '../../../../utils/helpers/logOut'
import { errorMsg } from '../../../../consts/responseMessages';



export async function getSocietyStaffListReq(action) {

    return axiosInstance.get(`${apiEndpoints.GET_SOCIETY_STAFF_LIST_API}?society_id=${action.data.societyId}&page=${action.data.page}&limit=${action.data.pageLimit}${action.data.searchText ? `&search_text=${encodeURIComponent(action.data.searchText)}` : ''}${action.data.wingId ? `&wing_id=${action.data.wingId}` : ''}${action.data.flatId ? `&flat_id=${action.data.flatId}` : ''}${action.data.profileId ? `&staff_profile_id=${action.data.profileId}` : ''}${action.data.societyType ? `&filter_staff_type=${action.data.societyType}` : ''}${action.data.status ? `&filter_approval=${action.data.status}` : ''}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}


export async function getSocietyStaffProfileListReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_SOCIETY_STAFF_PROFILE_LIST_API}?society_id=${action.data.societyId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getSocietyStaffDetailsReq(action) {
    return axiosInstance.get(`${apiEndpoints.VIEW_SOCIETY_STAFF_API}?id=${action.data.staffId}&staff_type=Society`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function addSocietyStaffReq(action) {

    const BODY = {
        "society_id": action.data?.societyId,
        "staff_prefix": action.data.prefix,
        "staff_first_name": action.data?.firstName,
        "staff_last_name": action.data?.lastName,
        "profile_image": action.data?.profilePic,
        "type_of_staff": action.data?.staffType,
        "staff_profile_id": action.data?.staffProfileId,
        "start_validity": action.data?.startValidity,
        "end_validity": action.data?.endValidity,
        "working_hours_per_day": parseInt(action.data?.workPerDay),
        "staff_salary": parseInt(action.data?.salary),
        "mobile_country_code": action.data.mobileCountryCode,
        "staff_contact": action.data?.contact,
        "staff_email": action.data?.email,
        "staff_gender": action.data?.gender,
        "staff_address": action.data?.address,
        "date_of_birth": action.data?.dob,
        "vehicle_type": action.data?.vehicleType,
        "staff_vehicle_number": action.data?.vehicleNumber,
        "finger_print_img": action.data?.fingerPrintImg,
        "addhar_no": action.data?.adharCardNo,
        "addhar_card_doc": action.data?.adharDoc,
        "staff_pan_no": action.data?.panCardNo?.toUpperCase(),
        "pan_card_doc": action.data?.panDoc,
        "driving_licence_no": action.data?.drivingLicenceNo,
        "driving_license_doc": action.data?.drivingLicenceDoc,
        "ration_no": action.data?.rationNo,
        "ration_card_doc": action.data?.rationDoc,
        "flats_data": action.data?.flatData?.map((item) => ({
            "flat_id": item?.flatId,
            "join_date": item?.joinDate,
            "status": item?.status
        })),
        "member_title_id": action.data?.memberTitleId ? action.data?.memberTitleId : "",
        // "committee_title_id": action.data?.committeeTitleId ? action.data?.committeeTitleId : "",

    }

    return axiosInstance.post(apiEndpoints.ADD_SOCIETY_STAFF_API, { ...BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data?.statusCode === 400) {
                notify('error', errors.response?.data?.message);
            } else if (errors.response?.data?.statusCode === 401) {
                notify('error', errors.response?.data?.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function downloadSocietyStaffExcelSheetReq(action) {

    return axiosInstance.get(`${apiEndpoints.DOWNLOAD_STAFF_LIST_API}?society_id=${action.data.societyId}&is_sample=${action.data.isSample ? 1 : 0}${action.data.searchText ? `&search_text=${encodeURIComponent(action.data.searchText)}` : ''}${action.data.wingId ? `&wing_id=${action.data.wingId}` : ''}${action.data.flatId ? `&flat_id=${action.data.flatId}` : ''}${action.data.profileId ? `&staff_profile_id=${action.data.profileId}` : ''}${action.data.societyType ? `&filter_staff_type=${action.data.societyType}` : ''}${action.data.status ? `&filter_approval=${action.data.status}` : ''}`, {
        responseType: 'arraybuffer',
    })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function updateSocietyStaffReq(action) {

    const BODY = {
        "staff_id": action.data?.staffId,
        "staff_level_id": action.data?.staffLevelId,
        "staff_doc_id": action.data?.staffDocId,
        "society_id": action.data?.societyId,
        "staff_prefix": action.data.prefix,
        "staff_first_name": action.data?.firstName,
        "staff_last_name": action.data?.lastName,
        "profile_image": action.data?.profilePic,
        "type_of_staff": action.data?.staffType,
        "staff_profile_id": action.data?.staffProfileId,
        "start_validity": action.data?.startValidity,
        "end_validity": action.data?.endValidity,
        "working_hours_per_day": parseInt(action.data?.workPerDay),
        "staff_salary": parseInt(action.data?.salary),
        "mobile_country_code": action.data.mobileCountryCode,
        "staff_contact": action.data?.contact,
        "staff_email": action.data?.email,
        "staff_gender": action.data?.gender,
        "staff_address": action.data?.address,
        "date_of_birth": action.data?.dob,
        "vehicle_type": action.data?.vehicleType,
        "staff_vehicle_number": action.data?.vehicleNumber,
        "finger_print_img": action.data?.fingerPrintImg,
        "addhar_no": action.data?.adharCardNo,
        "addhar_card_doc": action.data?.adharDoc,
        "staff_pan_no": action.data?.panCardNo?.toUpperCase(),
        "pan_card_doc": action.data?.panDoc,
        "driving_licence_no": action.data?.drivingLicenceNo,
        "driving_license_doc": action.data?.drivingLicenceDoc,
        "ration_no": action.data?.rationNo,
        "ration_card_doc": action.data?.rationDoc,
        "flats_data": action.data?.flatData?.map((item) => ({
            "staff_flat_data_id": item?.staffFlatId,
            "flat_id": item?.flatId,
            "join_date": item?.joinDate,
            "status": item?.status,
            "resign_date": item?.dateofResign || '0000-00-00',
            "resign_reason": item?.reason
        }))
    }

    return axiosInstance.put(apiEndpoints.UPDATE_SOCIETY_STAFF_API, { ...BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            } else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function deleteSocietyStaffReq(action) {
    const BODY = {
        "staff_level_id": action.data?.staffLevelId,
        "staff_id": action.data?.staffId
    }

    return axiosInstance.delete(apiEndpoints.DELETE_SOCIETY_STAFF_API, { data: BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getSocietyWingFlatListReq(action) {

    return axiosInstance.get(`${apiEndpoints.GET_SOCIETY_WING_FLAT_LIST_API}?society_id=${action.data.societyId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}


export async function printSocietyStaffIDCardsReq(action) {

    return axiosInstance.get(`${apiEndpoints.PRINT_SOCIETY_STAFF_CARD_API}?staff_ids=${action.data.staffIds}&society_id=${action.data.societyId}&society_type=${action.data.societyType}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}



export async function getSocietyStaffRatingDataReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_SOCIET_STAFF_RATING_DATA_API}?staff_id=${action.data.staffId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getSocietyStaffRatingParametersReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_SOCIET_STAFF_RATING_PARAMETERS_API}?society_id=${action.data.societyId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function updateSocietyStaffRatingReq(action) {

    const BODY = {
        "society_id": action.data.societyId,
        "rating_id": action.data.ratingId || 0,
        "staff_id": action.data.staffId,
        "flat_id": 0,
        "ratings": action.data?.parameters?.map(item => ({
            "parameter_id": item.id,
            "rating": item.value
        })),
        "avg_rating": action.data.avgRating,
        "comment": action.data?.comment || ' '
    }
    return axiosInstance.post(apiEndpoints.UPDATE_SOCIET_STAFF_RATING_DATA_API, { ...BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            } else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function sendStaffCodeSmsToStaffReq(action) {

    const BODY = {
        "staff_data": action.data.staffData?.map((item) => ({
            "staff_id": item.staffId,
            "staff_contact": item?.mobileNo,
            "staff_code": item.staffCode,
            "staff_name": item?.memberName
        }))
    }

    return axiosInstance.post(apiEndpoints.SEND_STAFF_CODE_SMS_TO_STAFF_API, { ...BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            } else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getIDCardFieldsDataReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_ID_CARD_FIELDS_DATA_API}?society_id=${action.data.societyId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function updateIDCardFieldsDataReq(action) {

    const BODY = {
        "id": action.data.idData?.id,
        "society_id": action.data.societyId,
        "name": action.data.idData?.name || 'N',
        "mobile": action.data.idData?.mobile || 'N',
        "flat_details": action.data.idData?.flat_details || 'N',
        "vehicle_details": action.data.idData?.vehicle_details || 'N',
        "profile": action.data.idData?.profile || 'N',
        "dept": action.data.idData?.dept || 'N',
        "tenure": action.data.idData?.tenure || 'N',
    }

    return axiosInstance.post(apiEndpoints.UPDATE_ID_CARD_FIELDS_DATA_API, { ...BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            } else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}


export async function getSocietyStaffApprovalSettingReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_APPROVAL_SETTINGS_DATA_API}?society_id=${action.data.societyId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function updateSocietyStaffApprovalSettingReq(action) {

    const BODY = {
        "society_id": action.data?.societyId,
        "committee_auto": action.data?.committeeApproval,
        "employee_auto": action.data?.employeeApproval,
        "committee_id": action.data?.committeeId,
        "employee_type_id": action.data?.EmployeeId
    }

    return axiosInstance.post(apiEndpoints.UPDATE_APPROVAL_SETTINGS_DATA_API, { ...BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            } else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function updateSocietyStaffApprovalStatusReq(action) {

    const BODY = {
        "staff_id": action.data?.staff_id,
        "staff_level_id": action.data?.staff_level_id,
        "approval_status": action.data?.approval_status,
        "approval_comments": action.data?.approval_comments
    }

    return axiosInstance.put(apiEndpoints.UPDATE_APPROVAL_STATUS_DATA_API, { ...BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            } else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function downloadSocietyStaffSummaryReportReq(action) {

    return axiosInstance.get(`${apiEndpoints.DOWNLOAD_SOCIETY_STAFF_SUMMARY_REPORT_API}?society_id=${action.data.societyId}`, {
        responseType: 'arraybuffer',
    })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}
