import * as type from "../../actions/index.types";

export function complaintCategoryReducer(
  state = {
    loading: true,
    updateLoader: false,
    loadingSettings: true,
    error: null,
    complaintCategoryList: [],
    complaintCategoryDetailsList: null,
    complaintSetting: [],
    complaintTypeSetting: [],
  },
  action
) {
  switch (action.type) {
    //   Complaint Category List
    case type.GET_COMPLAINT_CATEGORY_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case type.GET_COMPLAINT_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        complaintCategoryList: action.payload,
      };

    case type.GET_COMPLAINT_CATEGORY_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        complaintCategoryList: null,
      };

    case type.GET_COMPLAINT_CATEGORY_DETAILS_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case type.GET_COMPLAINT_CATEGORY_DETAILS_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        complaintCategoryDetailsList: action.payload,
      };

    case type.GET_COMPLAINT_CATEGORY_DETAILS_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
      };

    case type.ADD_COMPLAINT_CATEGORY_LIST:
      return {
        ...state,
        addLoader: true,
      };
    case type.ADD_COMPLAINT_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        addLoader: false,
      };
    case type.ADD_COMPLAINT_CATEGORY_LIST_FAILURE:
      return {
        ...state,
        addLoader: false,
      };

    case type.EDIT_COMPLAINT_CATEGORY_LIST:
      return {
        ...state,
        addLoader: true,
      };
    case type.EDIT_COMPLAINT_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        addLoader: false,
      };
    case type.EDIT_COMPLAINT_CATEGORY_LIST_FAILURE:
      return {
        ...state,
        addLoader: false,
      };

    case type.DELETE_CATEGORY:
      return {
        ...state,
      };
    case type.DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        complaintCategoryList: state.complaintCategoryList?.filter(
          (item) => !(item.id === action.payload.fileId)
        ),
      };
    case type.DELETE_CATEGORY_FAILURE:
      return {
        ...state,
      };

    case type.GET_COMPLAINT_SETTING_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case type.GET_COMPLAINT_SETTING_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        complaintSetting: action.payload,
      };

    case type.GET_COMPLAINT_SETTING_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
      };

    case type.UPDATE_COMPLAINT_SETTING_LIST:
      return {
        ...state,
        addLoader: true,
      };
    case type.UPDATE_COMPLAINT_SETTING_LIST_SUCCESS:
      return {
        ...state,
        addLoader: false,
        complaintSetting: action.payload,
      };
    case type.UPDATE_COMPLAINT_SETTING_LIST_FAILURE:
      return {
        ...state,
        addLoader: false,
      };

      case type.GET_COMPLAINT_TYPE_SETTING:
        return {
          ...state,
          loadingSettings: true,
        };
  
      case type.GET_COMPLAINT_TYPE_SETTING_SUCCESS:
        return {
          ...state,
          loadingSettings: false,
          complaintTypeSetting: action.payload,
        };
  
      case type.GET_COMPLAINT_TYPE_SETTING_FAILURE:
        return {
          ...state,
          loadingSettings: false,
        };
  
      case type.UPDATE_COMPLAINT_TYPE_SETTING:
        return {
          ...state,
          updateLoader: true,
        };
      case type.UPDATE_COMPLAINT_TYPE_SETTING_SUCCESS:
        return {
          ...state,
          updateLoader: false,
        };
      case type.UPDATE_COMPLAINT_TYPE_SETTING_FAILURE:
        return {
          ...state,
          updateLoader: false,
        };

    default:
      return state;
  }
}
