import React, { useEffect } from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import Button from "../Button/Button";
import {
  onlyNumberKey,
  pasteOnlyNumberKey,
} from "../../utils/helpers/universalFunctions";
import { useDispatch, useSelector } from "react-redux";
import {
  getSocietyEmployeeTypes,
  getSocietyManagingCommitteeTypes,
  getTenancyExpiryReminderData,
  updateTenancyExpiryReminder,
} from "../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/TenantsInfoAction";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { CircularProgress } from "@mui/material";
import { TenancyExpiryReminderValidation } from "../../validationSchema/setupRecordsSchema/occupants&TenantsSchema";
import MultiSelect from "../MultiSelect/MultiSelect";
import Loader from "../Loader/Loader";

const TenancyExpiryModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const {
    updateLoader,
    expiryReminderLoader,
    managingCommitteeTypesList,
    employeeTypeList,
    expiryReminderData,
  } = useSelector((state) => state.tenantInfoReducer);

  const handleSubmit = (values, { resetForm }) => {
    const data = {
      societyId: loginData?.default_community?.community_id,
      ...values,
      onRequestEndCallback: () => {
        onClose();
      },
    };
    dispatch(updateTenancyExpiryReminder(data));
  };

  useEffect(() => {
    const data = {
      societyId: loginData?.default_community?.community_id,
    };
    dispatch(getSocietyManagingCommitteeTypes(data));
    dispatch(getSocietyEmployeeTypes(data));
    dispatch(getTenancyExpiryReminderData(data));
  }, []);
  
  return (
    <div
      className={`fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-60 z-20 ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="bg-white rounded-lg w-[470px] h-fit">
        <div className="flex justify-end p-2 gap-8 items-center -mb-2">
          <h2 className="p-2 text-lg font-semibold mb-2 flex items-center justify-center">
            Tenancy Expiry Reminder Settings
          </h2>
          <button
            onClick={onClose}
            className="bg-[#EAEAEA] rounded-full p-2 items-center mb-2 w-9 h-9 "
          >
            <CloseIcon className="fill-current text-gray-600 hover:text-gray-800 ml-1 w-3 h-3 cursor-pointer" />
          </button>
        </div>
        <div className="border-b border-[#CCCCCC]"></div>
        <div className="p-2">
          <Formik
            enableReinitialize
            initialValues={{
              reminderId: expiryReminderData?.reminder_data_id,
              days: expiryReminderData?.day_prior || "",
              employeeIds:
                expiryReminderData?.emp_type?.map(
                  (item) => item.emloyee_type_id
                ) || [],
              committeeIds:
                expiryReminderData?.committee_type?.map(
                  (item) => item.committee_type_id
                ) || [],
            }}
            onSubmit={handleSubmit}
            validationSchema={TenancyExpiryReminderValidation}
          >
            {({ values, setFieldValue, errors }) => {
              return (
                <Form className="min-h-[277px]">
                  {
                    expiryReminderLoader ? 
                    <Loader/>:
                    <>
                      <div className="p-3 flex justify-between items-center gap-4">
                        <div className="flex flex-col">
                          <span className="text-sm text-[#222]">
                            Tenancy Expiry Reminder:
                          </span>
                          <span className="text-xs text-[#AAA]">
                            {" "}
                            (No. of Days Prior)
                          </span>
                        </div>
                        <div className="w-1/2 -mr-1">
                          <div className="flex flex-row border border-[#CCC] rounded-md overflow-hidden h-9 bg-[#F9FAFC] items-center w-28">
                            <Field
                              type="text"
                              className="border-none outline-none w-10 bg-[#F9FAFC] ml-3"
                              placeholder="xx"
                              maxLength={2}
                              onKeyPress={onlyNumberKey}
                              onPaste={pasteOnlyNumberKey}
                              name="days"
                            />
                            <input
                              type="text"
                              className="border-none outline-none w-12 bg-[#F9FAFC] disabled:cursor"
                              value="Days"
                              disabled
                            />
                          </div>
                          <div
                            className={`text-red-500 text-xs italic h-4 text-wrap break-words`}
                          >
                            <ErrorMessage name={"days"} />
                          </div>
                        </div>
                      </div>
                      <div className="flex px-2">
                        <p className="text-xs font-semibold text-center">
                          Choose Members To Send Tenancy Expiry Email & SMS Reminder
                        </p>
                      </div>
                      <div className="p-2 flex justify-between items-center">
                        <span className="text-sm">Employee Type</span>
                        <MultiSelect
                          options={
                            employeeTypeList?.map((item) => ({
                              label: item?.type,
                              value: item.emloyee_type_id,
                            })) || []
                          }
                          width="220px"
                          height={"fit-content"}
                          placeholder="Search Employee"
                          className="text-xs"
                          onSelect={(selectedItem) => {
                            setFieldValue(
                              "employeeIds",
                              selectedItem.map((item) => item.value)
                            );
                          }}
                          isMulti={true}
                          value={values?.employeeIds?.map((item) => ({
                            label: employeeTypeList?.find(
                              (emp) => emp.emloyee_type_id === item
                            ).type,
                            value: item,
                          }))}
                        />
                      </div>
                      <div className="flex p-2 justify-between mb-2 items-center">
                        <span className="text-sm">Managing Committees Type</span>
                        <MultiSelect
                          options={
                            managingCommitteeTypesList?.map((item) => ({
                              label: item?.type,
                              value: item.committee_type_id,
                            })) || []
                          }
                          width="220px"
                          height={"fit-content"}
                          placeholder="Search Employee"
                          className="text-xs"
                          onSelect={(selectedItem) => {
                            setFieldValue(
                              "committeeIds",
                              selectedItem.map((item) => item.value)
                            );
                          }}
                          isMulti={true}
                          value={values?.committeeIds?.map((item) => ({
                            label: managingCommitteeTypesList?.find(
                              (committee) => committee.committee_type_id === item
                            ).type,
                            value: item,
                          }))}
                        />
                      </div>
                      <div className="border-b"></div>

                      <div className="flex justify-center mt-4">
                        <Button
                          label={
                            <span className="w-12 inline-block">
                              {updateLoader ? (
                                <CircularProgress
                                  sx={{ color: "white" }}
                                  size={17}
                                />
                              ) : (
                                "Send"
                              )}
                            </span>
                          }
                          className="h-9 px-5 font-semibold"
                          disabled={updateLoader}
                          type={"submit"}
                        />
                      </div>
                    </>
                  }
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default TenancyExpiryModal;
