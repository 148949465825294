import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ButtonIco = ({ onClick, className, icon, children, type }) => {
  return (
    <div className="font-semibold">
      <button
      type='button'
        onClick={onClick}
        className={`${className} flex text-red-650 font-semibold border border-red-650 bg-white text-xs h-8 px-2 items-center rounded-lg `}
      >
        {icon && typeof icon === 'string' ? (
          // Assume it's a local icon
          <img src={icon} alt="Local Icon" className="mr-2 fill-red-650" />
        ) : (
          // Assume it's a Font Awesome icon
          icon && <FontAwesomeIcon icon={icon} className="mr-2 fill-red-650" />
        )}
        {children}
      </button>
    </div>
  );
};

export default ButtonIco;
