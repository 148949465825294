import React from "react";
import Layout from "../../../Layout/Layout";
import ListHeader from "../../../../components/ListHeader/ListHeader";

function AuditLogSavedNotice() {

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader
            leftContent="Audit Logs Saved Notice"
          >
          </ListHeader>
        </div>
      </div>
    </Layout>
  );
}

export default AuditLogSavedNotice;
