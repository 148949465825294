import React, { useEffect } from 'react'
import ListHeaderBack from '../../../components/ListHeader/ListHeaderBack'
import Layout from '../../Layout/Layout'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getCreditDebitTariffHeaderDetails } from '../../../redux/actions/FinanceActions/SettingActions/CreditDebitTariffHeaderAction';
import { useSelector } from 'react-redux';
import Loader from '../../../components/Loader/Loader';

export const complaintData = [
  {
    key: "orderid",
    label: "Order ID:",
    value: "034",
  },
  {
    key: "cause",
    label: "Cause:",
    value: "Testing",
  },
  {
    key: "glMappingName",
    label: "GL Mappping:",
    value: "Lift Maintenance Recovery",
  },
  {
    key: "amount",
    label: "Amount:",
    value: "N/A",
  },
]

function ViewNoteCause() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const {id} = useParams()
    const {loading, tariffHeaderDetails} = useSelector(state=>state.creditDebitTariffHeaderReducer)
    const {loginData} = useSelector(state=>state.loginReducer)

    useEffect(()=>{
      dispatch(getCreditDebitTariffHeaderDetails({tariffHeaderId: id, societyId: loginData?.default_community?.community_id}))
    }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack title="View Debit Note Tariff Header" onClick={()=>{navigate(-1)}}>
          </ListHeaderBack>
        </div>
        <div className="py-1 gap-2 my-1">
          {
            loading && tariffHeaderDetails?.id?.toString() !== id ?
            <Loader/>
            :
            <>
            {
              tariffHeaderDetails &&
              <table className="table-auto w-full">
                  <tbody>
                    {
                      complaintData?.map((item)=>(
                        <tr className="text-[#222222] text-sm">
                          <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">{item.label}:</td>
                          <td className="py-2">{tariffHeaderDetails?.[item.key]?.toString() || '-'}</td>
                        </tr>
                      ))
                    }
                  </tbody>
              </table>
            }
            </>
          }
        </div>
      </div>
    </Layout>
  )
}

export default ViewNoteCause