import { CircularProgress } from '@mui/material'
import React from 'react'

function Loader() {
  return (
    <div className='w-full text-center'>
        <CircularProgress sx={{color: '#CF001C', my: 5}}/>
    </div>
  )
}

export default Loader