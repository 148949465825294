import React, { useEffect, useState } from "react";
import Layout from "../../../Layout/Layout";
import { useNavigate, useParams } from "react-router-dom";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import { TextAreaFM } from "../../../../components/InputFields/TextArea";
import { DropdownFM2 } from "../../../../components/Dropdown/dropdown2";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import Button from "../../../../components/Button/Button";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getManagingCommitteeDetails, resignFromManagingCommittee } from "../../../../redux/actions/SetupRecordsActions/StaffManagementActions/ManangingCommitteeAction";
import Loader from "../../../../components/Loader/Loader";
import { Form, Formik } from "formik";
import { ResignLetterValidation } from "../../../../validationSchema/setupRecordsSchema/staffManagementSchema";
import { CircularProgress } from "@mui/material";
import moment from "moment";

const ResignManagingCommittee = () => {
  const navigate = useNavigate();
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading, updateLoader, managingCommitteeData} = useSelector(state=> state.managingCommitteeReducer)
  const {ipAddress} = useSelector(state=>state.ipAddressReducer)
  const [data, setData]= useState(null)
  const dispatch = useDispatch()
  const {id, roleId} = useParams()

  const handleSubmit = (values, {resetForm}) => {
    const letter = `&lt;p&gt;&amp;nbsp;To,&lt;/p&gt; &lt;p align=&quot;left&quot;&gt;The Managing Committee&lt;br /&gt; ${loginData?.default_community?.community_name} &lt;/p&gt; &lt;p align=&quot;right&quot;&gt;Date: ${moment().format('DD-MM-YYYY')}&lt;/p&gt; &lt;p&gt;&amp;nbsp;&lt;/p&gt; &lt;p align=&quot;left&quot;&gt;Sub: Resignation from the Managing Committee&lt;/p&gt; &lt;p&gt;&amp;nbsp;&lt;/p&gt; &lt;p align=&quot;left&quot;&gt;I would like to resign from the Managing Committee with effect from :&amp;nbsp; ${moment(values.dateOfResign)?.format('DD-MM-YYYY')}.&lt;/p&gt; &lt;p align=&quot;left&quot;&gt;The reasons for my resignation is following: ${values.reason} &lt;/p&gt; &lt;p align=&quot;left&quot;&gt;Request you to please accept my resignation.&lt;br /&gt; &amp;nbsp;&lt;/p&gt; &lt;p align=&quot;left&quot;&gt;${data?.subscriber_name} &lt;br /&gt; ${data?.flat_no} &lt;br /&gt; of ${loginData?.default_community?.community_name} &lt;/p&gt;`
    dispatch(resignFromManagingCommittee({
      ...values, 
      roleId: parseInt(roleId),
      committeeId: parseInt(id),
      ipAddress,
      email: data?.member_email,
      societyId: loginData?.default_community?.community_id,
      sessionId: loginData?.session_id,
      type: 'RES',
      onSuccessCallback: ()=>{
        navigate(-1)
      },
      reasonHTML: new DOMParser().parseFromString(letter, 'text/html').body.textContent,
      reason: letter
    }))
  };

  useEffect(()=>{      
    dispatch(getManagingCommitteeDetails({committeeId: id}))
  }, [])

  useEffect(()=>{      
    if(managingCommitteeData){
      setData(managingCommitteeData?.members?.find(item=>item.role_data_id?.toString() === roleId && item.resign_retire_status === 'No'))
    }
  }, [managingCommitteeData])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
      <Formik
        initialValues={{
          dateOfResign: '',
          reason: '',
          authority: '',
          sendNotification: 0,
          approvalReqired: 0,
        }}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={ResignLetterValidation}
        >    
          {
            ({values, errors, setFieldValue})=>{
              return (
                <Form>
                  <div className="border-b border-[#CCC]">
                    <ListHeaderBack
                      title="Resign From Managing Committees"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      <Button label={
                        <span className="w-12 inline-block">
                          {
                            updateLoader ?
                              <CircularProgress sx={{color: "white"}} size={17}/>
                              :
                              'Submit'
                            }
                        </span>
                      }  
                      disabled={loading || updateLoader}
                      />
                    </ListHeaderBack>
                  </div>

                  <div className="m-2">
                    <div className="text-center text-lg font-bold">
                      Resignation Letter
                    </div>
                    {
                      loading ?
                      <Loader/>
                      :
                      <>
                      {
                        data &&
                        <>
                          <div className="mb-4">
                            <label
                              htmlFor="to"
                              className="block text-sm font-medium text-gray-600"
                            >
                              To
                            </label>
                            <label
                              htmlFor="to"
                              className="block text-sm font-medium text-gray-600"
                            >
                              The Managing Committee
                            </label>
                            <label
                              htmlFor="to"
                              className="block text-sm font-medium text-gray-600 capitalize"
                            >
                              {loginData?.default_community?.community_name}
                            </label>
                          </div>
                          <div className="mb-4">
                            <label
                              htmlFor="subject"
                              className="block text-sm font-medium text-gray-600"
                            >
                              Subject: Resignation from the Managing Committee
                            </label>
                          </div>
                          <div className="mb-6">
                            <label
                              htmlFor="body"
                              className="block text-sm font-medium text-gray-600"
                            >
                            </label>
                            <div className="text-sm flex gap-3">
                              I would like to resign from the Managing Committee with effect
                              from: <DatePickerComponentFM
                                      className="w-52 h-9 ml-4" 
                                      name='dateOfResign'
                                      onDateChange={(selectedDate) => {
                                          setFieldValue('dateOfResign', selectedDate)
                                        }}
                                        minDate={new Date()}
                                        defaultValue={values.dateOfResign ? new Date(values.dateOfResign) : null}
                                      />
                            </div>
                            <p className="text-sm ">The reasons for my resignation are as follows:</p>
                            <div className="mt-2">
                              <TextAreaFM
                                placeholder={'Enter'}
                                className={'w-[650px]'}
                                row={6}
                                name='reason'
                              />          
                            </div>
                            <p className="text-sm">Request you to please accept my resignation.</p>
                              <div className="text-sm mt-3">
                            <p>
                              {data?.subscriber_name}
                              <br />
                              ({data?.flat_no})
                              <br />
                              of {loginData?.default_community?.community_name}
                            </p>
                              </div>

                              <div className="flex gap-3 mt-4 mb-3">
                                  <span className="text-sm mt-2">Approving Authority:</span>
                              <DropdownFM2
                                options={ [
                                    { value: "Admin", label: "Admin" },
                                    { value: "Employee", label: "Employee" },
                                    { value: "Chairman", label: "Chairman" },
                                  ]}
                                placeholder="Select Name"
                                className="text-[11px] ms-4"
                                width="224px"
                                height="36px"
                                onSelect={(selectedItem)=>{setFieldValue('authority', selectedItem.value)}}
                                name='authority'
                              />
                              </div>
                              <div className="mb-3">
                                <Checkbox
                                  text="Send notification to other Committee Members."
                                  className="text-sm"
                                  initialchecked={values.sendNotification === 1}
                                  onChange={(e) => {setFieldValue('sendNotification', e.target.checked ? 1 : 0)}}
                                />
                                </div>
                                <div className="">
                                <Checkbox
                                  text="Approval Required."
                                  className="text-sm"
                                  initialchecked={values.approvalReqired === 1}
                                  onChange={(e) => {setFieldValue('approvalReqired', e.target.checked ? 1 : 0)}}
                                />
                                </div>
                              </div>
                            </>
                                      
                          }
                          </>
                        }
                      </div>
                    </Form>
                    )
                  }
                }
            </Formik>
      </div>
    </Layout>
  );
};

export default ResignManagingCommittee;
