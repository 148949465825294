import * as type from '../../actions/index.types'

export function forgotPasswordReducer(state = {
  loading: false,
  error: null
}, action) {

  switch (action.type) {
    case type.SOCIETY_FORGOT_PASSWORD:
      return {
        ...state,
        loading: true,
        error: null
      }
    case type.SOCIETY_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
      }

    case type.SOCIETY_FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        loading: false,
      }
    default:
      return state
  }
}