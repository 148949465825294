import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { TextInputFM } from "../../../../components/InputFields/TextInput";
import Layout from "../../../Layout/Layout";
import Button from "../../../../components/Button/Button";
import ButtonG from "../../../../components/Button/ButtonG";
import { MANAGING_COMMITTEE_ENDPOINT } from "../../../../Routing/routes";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import  { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import { UploadButtonFM } from "../../../../components/Button/UploadButton";
import EditManagingCommitteeTable from "../../../../components/TableComponent/EditManagingCommitteeTable";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getManagingCommitteeDetails, getOccupantsForManagingCommittee, updateManagingCommittee } from "../../../../redux/actions/SetupRecordsActions/StaffManagementActions/ManangingCommitteeAction";
import Loader from "../../../../components/Loader/Loader";
import { Formik, Form} from "formik";
import { AddManangingCommitteeValidation } from "../../../../validationSchema/setupRecordsSchema/staffManagementSchema";
import { CircularProgress } from "@mui/material";
import { validateDate } from "../../../../utils/helpers/universalFunctions";

const headCells = [
  {
    id: "member",
    label: "Member (Flat & Wing No.)",
    sort: false,
    width: "200px",
  },
  { id: "role", label: "Roles & Responsibility", sort: false, width: "180px" },
  { id: "joinDate", label: "Join Date", sort: false, width: "180px" },
  { id: "leaveDate", label: "Leave Date", sort: false, width: "180px" },
  { id: "status", label: "Status", sort: false,width: "100px"  },
  { id: "action", label: "Action", sort: false },
];


const EditCommittee = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {ipAddress} = useSelector(state=>state.ipAddressReducer)
  const {loading, updateLoader, loadingOccupants, occupantsFormanagingCommittee, managingCommitteeData} = useSelector(state=> state.managingCommitteeReducer)
  const {id} = useParams()

  const handleCancelClick = () => {
    navigate(MANAGING_COMMITTEE_ENDPOINT);
  };

  const handleSaveClick = async(values, {resetForm}) => {

    dispatch(updateManagingCommittee({
      ...values, 
      ipAddress,
      societyId: loginData?.default_community?.community_id,
      sessionId: loginData?.session_id,
      committeeId: id,
      onSuccessCallback: ()=>{
        navigate(-1)
      }
    }))
  };

  useEffect(()=>{
    dispatch(getOccupantsForManagingCommittee({societyId: loginData?.default_community?.community_id}))
  }, [])

  const getTableData =() =>{
    const data={
      committeeId: id,
    }

    dispatch(getManagingCommitteeDetails(data))
  }

  useEffect(()=>{      
    getTableData()
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
      <Formik
        initialValues={{
          name: managingCommitteeData?.title,
          formationDate: managingCommitteeData?.committee_creation_date,
          resolutionDoc: managingCommitteeData?.resolution_doc || '',
          selectedOccupants: occupantsFormanagingCommittee?.map((item)=>{
            let index = managingCommitteeData?.members?.findIndex(obj => obj?.member_id === item?.member_id)
            return ({
              memberId: item?.member_id,
              roleId: managingCommitteeData?.members[index]?.role_data_id || 0,
              status: managingCommitteeData?.members[index]?.role_data_id ? 'A' : '',
              roles: managingCommitteeData?.members[index]?.roles || '',
              joinDate:  validateDate(managingCommitteeData?.members[index]?.join_date) ? managingCommitteeData?.members[index]?.join_date : '',
              leaveDate: validateDate(managingCommitteeData?.members[index]?.leave_date ) ? managingCommitteeData?.members[index]?.leave_date : '',
              selected: index>=0,
              name: item?.subscriber_name || '',
              title: item?.title || '',
              flat: item?.flat,
              email: managingCommitteeData?.members[index]?.member_email || '',
              statusType: managingCommitteeData?.members[index]?.resign_retire_status,
          })
          }) || []
        }}
        enableReinitialize
        onSubmit={handleSaveClick}
        validationSchema={AddManangingCommitteeValidation}
        >    
          {
            ({values, errors, dirty, setFieldValue})=>{
              return (
                <Form>{console.log(errors, values)}
                  <div className="border-b border-[#CCC]">
                    <ListHeader leftContent="Edit Managing Committee ">
                      <ButtonG
                        label="Cancel"
                        onClick={handleCancelClick}
                        className="h-8"
                      />
                      <Button
                          label={
                            <span className="w-8 inline-block">
                              {
                                updateLoader ?
                                  <CircularProgress sx={{color: "white"}} size={17}/>
                                  :
                                  'Save'
                                }
                            </span>
                          } 
                          className=" h-8 px-4"
                          type='submit'
                          disabled={loadingOccupants || updateLoader || loading || !dirty}
                        />
                    </ListHeader>
                  </div>
                  {
                    loading && managingCommitteeData?.committee_id?.toString() !== id ?
                    <Loader/>
                    :
                    <>
                      <div className="flex gap-4 p-2 mb-3">
                        <div className="flex flex-row justify-between w-full gap-7">
                          <div className="flex flex-col w-1/2">
                          <TextInputFM
                            label="Committee Name"
                            placeholder="Enter Full Name"
                            className="w-56"
                            maxLength={75}
                            name='name'
                          />
                          <div className="flex items-center justify-between mb-2">
                              <div className="text-sm">Uploaded Resolution Document</div>
                              <div className="w-1/2 justify-start -mr-[2px]">
                                {managingCommitteeData?.resolution_doc_url ? <Link className="text-xs text-blue-500 underline" to={managingCommitteeData?.resolution_doc_url} target="_blank">Click To View</Link> : '-'}
                              </div>
                            </div>
                          <div className="flex items-center justify-between">
                            <div className="text-sm">Resolution Document</div>
                            <div className="w-1/2 justify-start -mr-[2px] h-9">
                              <UploadButtonFM
                                showFileName={true} 
                                name='resolutionDoc'
                                fileName={values?.resolutionDoc?.name}
                                onSelect={(e)=>{setFieldValue('resolutionDoc', e)}}
                                accept={"image/jpeg,image/jpg,image/png,application/pdf"}
                              />
                            </div>
                          </div>
                          </div>
                          <div className="flex flex-col w-1/2 ">
                            <div className="flex justify-between items-center">
                              {/* <TextInput
                                label="Date of Managing Committee Formation"
                                className="w-56 hidden"
                              /> */}
                              <span className="text-sm">Date of Managing Committee Formation</span>
                              <DatePickerComponentFM
                                className="w-56 h-9" 
                                name='formationDate'
                                onDateChange={(selectedDate) => {
                                    setFieldValue('formationDate', selectedDate)
                                  }}
                                  defaultValue={values.formationDate ? new Date(values.formationDate) : null}
                                />
                            </div>
                          </div>
                        </div>
                        <div>
                        
                        </div>
                      </div>
                      <div>
                        <EditManagingCommitteeTable
                        loading={loadingOccupants}
                        values={{...values, committeeId: id}}
                        setFieldValue={setFieldValue}
                        columns={headCells}
                        checkbox={true}
                        pagination={false}
                      ></EditManagingCommitteeTable>
                      {!occupantsFormanagingCommittee?.length && !loadingOccupants  && (
                        <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
                          No Records
                        </div>
                      )}
                      </div>
                    </>
                  }
                </Form>
              )
            }
          }
        </Formik>
      </div>
    </Layout>
  );
};

export default EditCommittee;
