import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SOCIETY_VENDORS_ENDPOINT } from "../../Routing/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faUpload } from "@fortawesome/free-solid-svg-icons";
import { TextInput } from "../InputFields/TextInput";
import { TextArea } from "../InputFields/TextArea";
import Dropdown from "../Dropdown/Dropdown";
import Button from "../Button/Button";
import ButtonG from "../Button/ButtonG";
import Layout from "../../pages/Layout/Layout";

const AddVendor = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    vendor_category: "",
    vendor_name: "",
    concerned_person: "",
    office_email_address: "",
    address: "",
    pan_number: "",
    vendor_tax_status: "",
    sac_code: "",
    sgst: "",
    cgst: "",
    igst: "",
    office_contact_number: "",
    phone_number: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    tan_number: "",
    st_gst_reg_number: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCancelClick = () => {
    navigate(SOCIETY_VENDORS_ENDPOINT);
  };

  const handleSaveClick = () => {
    // To Implement form submission logic here.
    console.log("Form data saved to the console.", formData);
  };

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="flex justify-between border-b border-[#CCC] pb-2">
          <h1 className="text-base font-semibold p-1">Add Vendor</h1>
          <div className="flex gap-2">
            <div className="flex gap-2">
              <button className="flex text-red-650 border border-red-650 bg-white text-xs px-3 items-center rounded-lg">
                <FontAwesomeIcon icon={faUpload} className="mr-2" /> Upload
                Vendor
              </button>
              <button className="flex text-red-650 border border-red-650 bg-white text-xs px-3 items-center rounded-lg">
                <FontAwesomeIcon icon={faDownload} className="mr-2" /> Download
                Sample
              </button>
              <span className="border-r border-[#CCCCCC]"></span>
            </div>
            <div className="flex gap-2">
              <ButtonG label="Cancel" onClick={handleCancelClick} />
              <Button label="Save" onClick={handleSaveClick} className="px-6" />
            </div>
          </div>
        </div>
        <div className="flex justify-between gap-4 p-2">
          <div className="flex flex-col gap-2 w-1/2 pr-5">
            <div className="flex items-center justify-between mb-4 mt-3">
              <span className="text-sm">Vendor Category</span>
              <Dropdown
                options={[
                  "Carpenters",
                  "Electrician",
                  "Ac Repair",
                  "Gate",
                  "AC Repair",
                  "Architect",
                ]}
                width="52"
                placeholder="Enter Category"
                className="text-xs"
                onSelect={(selectedItem) => {}}
              />
            </div>
            <TextInput
              label="Vendor Name"
              placeholder="Enter Full Name"
              className="w-52"
              value={formData.vendor_name}
              onChange={handleInputChange}
            />
            <TextInput
              label="Concerned Person"
              placeholder="Enter Full Name"
              className="w-52"
              value={formData.concerned_person}
              onChange={handleInputChange}
            />
            <TextInput
              label="Office Email Address"
              placeholder="Enter Email Address"
              className="w-52"
              value={formData.office_email_address}
              onChange={handleInputChange}
            />
            <TextArea
              label="Address"
              placeholder="Enter Address"
              className={"w-52 h-20"}
              value={formData.address}
              onChange={handleInputChange}
            />
            <TextInput
              label="Pan Number"
              placeholder="Enter Pan Number"
              className="w-52"
              value={formData.pan_number}
              onChange={handleInputChange}
            />
            <div className="flex items-center justify-between mb-4 mt-3">
              <span className="text-sm">Vendor Tax Status</span>
              <Dropdown
                options={[
                  "Individual",
                  "Limited Company",
                  "Partnership",
                  "Others",
                ]}
                width="52"
                placeholder="Enter Tax Status"
                className="text-xs"
                onSelect={(selectedItem) => {}}
              />
            </div>
            <TextInput
              label="SAC Code"
              placeholder="Enter Details"
              className="w-52"
              value={formData.sac_code}
              onChange={handleInputChange}
            />
            <TextInput
              label="SGST (%)"
              placeholder="Enter Details"
              className="w-52"
              value={formData.sgst}
              onChange={handleInputChange}
            />
            <TextInput
              label="CGST (%)"
              placeholder="Enter Details"
              className="w-52"
              value={formData.cgst}
              onChange={handleInputChange}
            />
            <TextInput
              label="IGST (%)"
              placeholder="Enter Details"
              className="w-52"
              value={formData.igst}
              onChange={handleInputChange}
            />
          </div>
          <div className="flex flex-col gap-2 w-1/2 pr-5 mt-[70px]">
            <TextInput
              label="Office Contact Number"
              placeholder="Enter Phone Number"
              className="w-52"
              value={formData.office_contact_number}
              onChange={handleInputChange}
            />
            <TextInput
              label="Phone Number"
              placeholder="Enter Phone Number"
              className="w-52"
              value={formData.phone_number}
              onChange={handleInputChange}
            />
            <div className="flex gap-4 mt-12">
              <div className="flex items-center justify-between mb-4 mt-2 gap-4">
                <span className="text-sm">Country</span>
                <Dropdown
                  options={["India", "USA", "Australia", "England"]}
                  width="32"
                  placeholder="Enter Country"
                  className="text-xs"
                  onSelect={(selectedItem) => {}}
                  value={formData.country}
                  onChange={(value) =>
                    setFormData({ ...formData, country: value })
                  }
                />
              </div>
              <div className="flex items-center justify-between mb-4 mt-2 gap-10">
                <span className="text-sm ml-4">State</span>
                <Dropdown
                  options={["India", "USA", "Australia", "England"]}
                  width="32"
                  placeholder="Enter State"
                  className="text-xs"
                  onSelect={(selectedItem) => {}}
                  value={formData.state}
                  onChange={(value) =>
                    setFormData({ ...formData, state: value })
                  }
                />
              </div>
            </div>
            <div className="flex justify-between gap-2 items-center">
            <div className="flex items-center justify-between mb-2 -mt-2 gap-10">
                <span className="text-sm">City</span>
                <Dropdown
                  options={["India", "USA", "Australia", "England"]}
                  width="32"
                  placeholder="Enter City"
                  className="text-xs"
                  onSelect={(selectedItem) => {}}
                  value={formData.city}
                  onChange={(value) =>
                    setFormData({ ...formData, city: value })
                  }
                />
              </div>
              <div className="flex justify-between items-center mb-2 gap-6">
                <span className="text-sm">Pincode</span>
                <div className="border border-[#CCCCCC] rounded-md indent-1 w-32 h-8 bg-[#F9FAFC]">
                  <input
                    type="text"
                    placeholder="Enter Details"
                    className="outline-none border-none placeholder-italic text-black not-italic text-xs items-center bg-[#F9FAFC] w-28"
                    value={formData.pincode}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            <TextInput
              label="Tan Number"
              placeholder="Enter Tan Number"
              className="w-52 mt-2"
              value={formData.tan_number}
              onChange={handleInputChange}
            />
            <TextInput
              label="ST/GST/Reg.Number"
              placeholder="Enter Details"
              className="w-52 mt-3"
              value={formData.st_gst_reg_number}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddVendor;
