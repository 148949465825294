import React, { useEffect } from "react";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Layout from "../../../Layout/Layout";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import {
  ARCReportsYearOptions,
  TypeOfReportOptions,
  monthOptions,
} from "../../../../consts/DropDownOptions";
import Search from "../../../../components/SearchBox/Search";
import ViewBillGenerationTable from "./ViewBillGenerationsTable";
import ButtonIco from "../../../../components/Button/ButtonPrint";
import { DownloadExt } from "../../../../assets";
import ButtonG from "../../../../components/Button/ButtonG";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getBillGenerationViewReports,
  getBillTypeList,
  getFinanceMembersList,
} from "../../../../redux/actions/ReportActions/OldFinancialReportsActions";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { VIEW_BILL_GENERATION_ENDPOINT } from "../../../../Routing/routes";

export const headCells = [
  {
    id: "billNo",
    label: "Bill No.",
    width: "100px",
  },
  { id: "regn", label: "Regn", width: "90px" },
  { id: "flat", label: "Flat", width: "100px" },
  { id: "member", label: "Member", width: "100px" },
  { id: "billTotal", label: "Bill Total", width: "90px" },
  { id: "princArr", label: "Princ. Arr", width: "100px" },
  { id: "intArr", label: "Int. Arr", width: "100px" },
  { id: "curInt", label: "Curr. Int", width: "100px" },
  { id: "advInt", label: "Adv. Int", width: "100px" },
  { id: "adjAmt", label: "Adj. Amt", width: "100px" },
  { id: "recNo", label: "Rec. No", width: "100px" },
  { id: "recAmt", label: "Rec. Amt", width: "100px" },
  { id: "chqNo", label: "Chq. No", width: "100px" },
  { id: "lastBillNo", label: "Last Bill No.", width: "100px" },
  { id: "bank", label: "Bank", width: "100px" },
  { id: "grandTotal", label: "Grand Total", width: "100px" },
  { id: "invoiceId", label: "Invoice Id", width: "100px" },
  { id: "payPin", label: "Pay Pin", width: "100px" },
  { id: "einvoiceIRN", label: "E-invoice IRN", width: "430px" },
  {
    id: "einvoiceIRNCancelStatus",
    label: "E-invoice IRN Cancel Status",
    width: "100px",
  },
  {
    id: "einvoiceIRNErrorPoint",
    label: "E-invoice IRN Error Point",
    width: "100px",
  },
  {
    id: "einvoiceIRNErrorMessage",
    label: "E-invoice IRN Error Message",
    width: "100px",
  },
];

const ViewBillGeneration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  // const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, financeMembersList, billTypeList, billGenerationReports } =
    useSelector((state) => state.oldFinancialReportsReducers);

  const handleSearch = (text) => {
    if (text && text !== searchParams.get("search")) {
      searchParams.delete("page");
      searchParams.delete("search");
      navigate({
        pathname: `${VIEW_BILL_GENERATION_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["search", text?.trim()],
        ])}`,
      });
    }
  };
  const handelSelectReportType = (selectedItem) => {
    searchParams.delete("page");

    if (selectedItem.value?.toString() !== searchParams.get("typeOfReport")) {
      searchParams.delete("typeOfReport");
      navigate({
        pathname: `${VIEW_BILL_GENERATION_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["typeOfReport", selectedItem.value],
        ])}`,
      });
    }
  };

  const handelSelectBillType = (selectedItem) => {
    searchParams.delete("page");

    if (selectedItem.value?.toString() !== searchParams.get("billType")) {
      searchParams.delete("billType");
      navigate({
        pathname: `${VIEW_BILL_GENERATION_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["billType", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleSelectMembersList = (selectedItem) => {
    searchParams.delete("page");

    if (selectedItem.value?.toString() !== searchParams.get("financeMemberList")) {
      searchParams.delete("financeMemberList");
      navigate({
        pathname: `${VIEW_BILL_GENERATION_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["financeMemberList", selectedItem.value],
        ])}`,
      });
    }
  };


  const handleSelectMonth = (selectedItem) => {
    searchParams.delete("page");

    if (selectedItem.value?.toString() !== searchParams.get("month")) {
      searchParams.delete("month");
      navigate({
        pathname: `${VIEW_BILL_GENERATION_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["month", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleSelectYear = (selectedItem) => {
    searchParams.delete("page");

    if (selectedItem.value?.toString() !== searchParams.get("year")) {
      searchParams.delete("year");
      navigate({
        pathname: `${VIEW_BILL_GENERATION_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["year", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleClear = () => {
    navigate(VIEW_BILL_GENERATION_ENDPOINT);
  };

  useEffect(() => {
    dispatch(getBillTypeList({ society_id: "S387", daybook_filter: "allType" }));

    dispatch(getFinanceMembersList({ society_registration: "MYAS387" }));
    dispatch(getBillGenerationViewReports({
      society_registration: "MYAS387",
      page: searchParams.get("page") || 1,
      limit: searchParams.get("pageLimit") || 10,
      typeOfReport: searchParams.get("typeOfReport"),
      billType: searchParams.get("billType"),
      month: searchParams.get("month"),
      year: searchParams.get("year"),
      financeMemberList: searchParams.get("financeMemberList"),
    }))
  }, [searchParams]);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Bill Generation - View">
            <ButtonIco
              icon={DownloadExt}
              children="Download PDF"
              className="h-8"
              type="button"
              onClick={""}
            />
            <ButtonIco
              icon={DownloadExt}
              children="Download Excel"
              className="h-8"
              type="button"
              onClick={""}
            />
            <span className="border-r border-[#CCC]"></span>
            <Search
              height="8"
              onclick={handleSearch}
              value={searchParams.get("search")}
            />
          </ListHeader>
        </div>

        <div className="flex gap-3 py-2">
          <Dropdown2
            options={TypeOfReportOptions}
            placeholder="Select Report Type"
            className=""
            width="182px"
            height="32px"
            onSelect={(selectedItem) => {
              handelSelectReportType(selectedItem);
            }}
            value={
              searchParams.get("typeOfReport") && {
                value: searchParams.get("typeOfReport"),
                label: TypeOfReportOptions?.find(
                  (item) => item.value === searchParams.get("typeOfReport")
                )?.label,
              }
            }
          />
          <Dropdown2
            options={
              billTypeList?.map((item) => ({
                value: item?.adbregnno,
                label: item?.daybookname,
              })) || []
            }
            placeholder="Select Bill Type"
            className=""
            width="182px"
            height="32px"
            onSelect={(selectedItem) => {
              handelSelectBillType(selectedItem);
            }}
            value={
              searchParams.get("billType") && {
                value: searchParams.get("billType"),
                label: billTypeList?.find(
                  (item) => item.adbregnno === searchParams.get("billType")
                )?.daybookname,
              }
            }
          />
          <Dropdown2
            options={monthOptions}
            placeholder="Month"
            className=""
            width="120px"
            height="32px"
            onSelect={(selectedItem) => {
              handleSelectMonth(selectedItem);
            }}
            value={
              searchParams.get("month") && {
                value: searchParams.get("month"),
                label: monthOptions?.find(
                  (item) => item.value === searchParams.get("month")
                )?.label,
              }
            }
          />

          <Dropdown2
            options={ARCReportsYearOptions}
            placeholder="Year"
            className=""
            width="120px"
            height="32px"
            onSelect={(selectedItem) => {
              handleSelectYear(selectedItem);
            }}
            value={
              searchParams.get("year") && {
                value: searchParams.get("year"),
                label: ARCReportsYearOptions?.find(
                  (item) => item.value.toString() === searchParams.get("year")
                )?.label,
              }
            }
          />
          <span className="border-r border-[#CCC]"></span>
          <Dropdown2
            options={
              financeMembersList?.map((item) => ({
                value: item?.regnno,
                label: item?.shortname,
              })) || []
            }
            width={"182px"}
            height="32px"
            className="text-xs"
            placeholder={"Select Members"}
            onSelect={(selectedItem) => {
              handleSelectMembersList(selectedItem);
            }}
            value={
              searchParams.get("financeMemberList") && {
                value: searchParams.get("financeMemberList"),
                label: financeMembersList?.find(
                  (item) => item.regnno === searchParams.get("financeMemberList")
                )?.shortname,
              }
            }
          />
          <span className="border-r border-[#CCC]"></span>
          <ButtonG label="Clear" className="h-8" onClick={handleClear} />
        </div>
        <div className="ml-1">
          <ViewBillGenerationTable
            loading={loading}
            data={billGenerationReports?.map((item) => ({
              billNo: item?.billnumber?.value,
              regn: item?.memberregn,
              flat: item?.flatno,
              member: item?.flatowner ,
              billTotal: item?.totalbillamt?.value,
              princArr: item?.arrearsamt1?.value ,
              intArr: item?.arrearsamt2?.value ,
              curInt: item?.currentmonthinterestamt?.value ,
              advInt: item?.advance_interest?.value ,
              adjAmt: item?.adjustmentbill_amt?.value ,
              recNo: item?.receiptnumber?.value ,
              chqNo: item?.chequenumber ,
              lastBillNo: item?.lastbillno ,
              bank: item?.bankname ,
              grandTotal: item?.totalbillamt?.value,
              invoiceId: item?.['eisids.zbinid']?.value ,
              payPin: item?.pay_pin,
              einvoiceIRN: item?.['einvcr.irn'],
              einvoiceIRNCancelStatus: item?.['einvcr.is_cancelled'] ,
              // einvoiceIRNErrorPoint: item?. ,
              // einvoiceIRNErrorMessage: item?. ,
            })) || []}
            columns={headCells}
            checkbox={false}
            pagination={true}
          ></ViewBillGenerationTable>
        </div>
        {!billGenerationReports?.length && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ViewBillGeneration;
