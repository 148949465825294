import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";


function RadioButtonsGroup({ label, options, name, value, onChange, flexDirection = 'row',accentColor = "#CF001C"}) {
  const flexContainerStyle = {
    display: "flex",
    flexDirection: flexDirection,
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "13px",
    marginBottom: "-11px",
    
  };

  const radioStyle = {
    '&.Mui-checked': {
      color: accentColor,
      "& .MuiSvgIcon-root": {
        borderColor: "#AAAAA", 
      },
    },
  };
  return (
    <FormControl style={flexContainerStyle}>
      <span>{label}</span>
      <RadioGroup row={flexDirection === 'row'} name={name} onChange={onChange} value={value}>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio sx={radioStyle}  size="small" />}
            label={option.label}
            disabled={option.disabled}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export default RadioButtonsGroup;
