import React, { useEffect, useState } from "react";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Layout from "../../../Layout/Layout";
import Button from "../../../../components/Button/Button";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import SavedNoticeTable from "./SavedNoticeTable";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import { SavedNoticeMemberTypeOptions } from "../../../../consts/DropDownOptions";
import RangeDatePicker from "../../../../components/DatePicker/RangeDatePicker";
import { SAVED_NOTICE_ENDPOINT, SAVED_NOTICE_SENT_MINUTES_ENDPOINT } from "../../../../Routing/routes";
import ArchiveTenantModal from "../../../../components/Modal/ArchiveTenantModal";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getNoticeList } from "../../../../redux/actions/ComplianceActions/MeetingsAction";

export const headCells = [
  { id: "id", label: "S No.", width: "50px" },
  {
    id: "meetingSubject",
    label: "Meeting Subject",
    sort: true,
    width: "120px",
  },
  { id: "date", label: "Date", sort: true, width: "80px" },
  { id: "time.", label: "Time", sort: true, width: "90px" },
  { id: "venue", label: "Venue", sort: true, width: "90px" },
  { id: "honorarySecretary", label: "Honorary Secretary", sort: true, width: "120px" },
  { id: "action", label: "Action", sort: false, width: "90px" },
];

const rows = [
  {
    id: "1",
    sNo: "01",
    meetingSubject: "Himanshi Mehta",
    date: "P-Block",
    time: "TPP001",
    venue: "NOC for Transfer",
    honorarySecretary: "23-02-2024",
  },
  {
    id: "2",
    sNo: "02",
    meetingSubject: "Riya Mehta",
    date: "C-Block",
    time: "TPP002",
    venue: "NOC for Passport",
    honorarySecretary: "23-02-2024",
  },
];

const SavedNotice = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const {loading, noticeList} = useSelector(state=>state.meetingsReducer)
  const {loginData} = useSelector(state=>state.loginReducer)

  const handleArchiveClick = () => {
    setIsArchiveModalOpen(true);
  };

  const handleNavigation = (path) => () => {
    navigate(path);
  };
  
  const handelSelectMemberType = (selectedItem)=>{
    searchParams.delete('page')

    if(selectedItem.value?.toString() !== searchParams.get("meetingType")){
      searchParams.delete("meetingType")
      navigate({
        pathname: `${SAVED_NOTICE_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["meetingType", selectedItem.value]])}`,
      })
    }
  }
  
  const handleStartDate = (selectedDate)=>{
    if(moment(selectedDate).format('YYYY-MM-DD') !== searchParams.get("startDate")){
      searchParams.delete('page')
      searchParams.delete('pageLimit')
      searchParams.delete("startDate")
      navigate({
        pathname: `${SAVED_NOTICE_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["startDate", moment(selectedDate).format('YYYY-MM-DD')]])}`,
      })
    }
  }

  const handleEndDate = (selectedDate)=>{
    if(moment(selectedDate).format('YYYY-MM-DD') !== searchParams.get("endDate")){
      searchParams.delete('page')
      searchParams.delete('pageLimit')
      searchParams.delete("endDate")
      navigate({
        pathname: `${SAVED_NOTICE_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["endDate", moment(selectedDate).format('YYYY-MM-DD')]])}`,
      })
    }
  }

  const getTableData =() =>{
    const data={
      societyId: loginData?.default_community?.community_id,
      page: searchParams.get("page") || 1,
      pageLimit: searchParams.get("pageLimit") || 10,
      meetingType: searchParams.get("meetingType") || '',
      startDate: searchParams.get("startDate") || '',
      endDate: searchParams.get("endDate") || '',
      type: 'saved'
    }

    dispatch(getNoticeList(data))
  }

  useEffect(()=>{      
    getTableData()
  }, [searchParams])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Saved Notice">
            <Button label="Sent Minutes" onClick={handleNavigation(SAVED_NOTICE_SENT_MINUTES_ENDPOINT)} />
            <Button label="Track Action" onClick={""} />
            <span className="border-r border-[#CCC]"></span>
            <Button label="Schedule A Meeting" onClick={() => handleArchiveClick()} />
          </ListHeader>
        </div>
        <div className="flex justify-between my-2">
        <Dropdown2
            options={SavedNoticeMemberTypeOptions}
            placeholder="Select Meeting Type"
            className="text-[11px] ms-4"
            width="208px"
            height="32px"
            onSelect={(selectedItem) => {
              handelSelectMemberType(selectedItem)
            }}
            value={
              searchParams.get("meetingType") && {
                value: searchParams.get("meetingType"),
                label: SavedNoticeMemberTypeOptions?.find((item) => item.value === searchParams.get("status"))?.label
              }
            }
          />
          <RangeDatePicker className="w-48 h-8"
            defaultStartValue={searchParams.get("startDate") ? new Date(searchParams.get("startDate")) : ''}
            defaultEndValue={searchParams.get("endDate") ? new Date(searchParams.get("endDate")) : ''}
            onStartDateChange={handleStartDate}
            onEndDateChange={handleEndDate}
            />
        </div>
        <div className="ml-1">
          <SavedNoticeTable
            loading={loading}
            data={noticeList?.list?.map((item, index)=>({
              sNo: (((noticeList?.current_page_no - 1) * noticeList?.page_limit + 1)+index)?.toString()?.padStart(2, "0"),
              noticeId: item?.m_notice_id || '-',
              meetingSubject: item?.meeting_type || '-',
              date: item?.notice_on ? moment(new Date(item?.notice_on))?.format('DD-MM-YYYY') : '-',
              time: item?.notice_time || '-',
              venue: item?.notice_venue || '-',
              honorarySecretary: item?.notice_name || '-',
              status: item?.status || '-',
            }))}
            columns={headCells}
            checkbox={false}
            pagination={noticeList?.list?.length}
            totalCount={noticeList?.total_record}
            totalPages={noticeList?.total_pages}
            start={(noticeList?.current_page_no - 1) * noticeList?.page_limit + 1}
            end={(noticeList?.current_page_no - 1) * noticeList?.page_limit + noticeList?.list?.length}
            currentPage={noticeList?.current_page_no}
            rowsPerPage={searchParams.get("pageLimit") || 10}
            getTableData={getTableData}
          ></SavedNoticeTable>
        </div>
        {!rows?.length && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
      <ArchiveTenantModal
        isOpen={isArchiveModalOpen}
        onClose={() => setIsArchiveModalOpen(false)}
        minDate={moment(new Date()).add(1, 'days').toDate()}
        // loading={updateLoader}
      />
    </Layout>
  );
};

export default SavedNotice;
