import React, { useState } from "react";
import Layout from "../../../Layout/Layout";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import { Box, Tab, Tabs } from "@mui/material";
import styled from "@emotion/styled";
import EInvoiceReport from "./EInvoiceReport";
import InterestStatement from "./InterestStatement";
import MembersBill from "./MembersBill";
import SocietyBills from "./SocietyBills";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { BILL_REPORTS_ENDPOINT } from "../../../../Routing/routes";

function BillReports() {
  const StyledTab = styled(Tab)({
    textTransform: "none",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "'Open Sans', sans-serif",
    borderRight: "1px solid #CCC",
    background: "#FDF2F3",

    "&.Mui-selected": {
      color: "#CF001C",
    },
  });

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const qtab = parseInt(searchParams.get("tab"));
  const [tabValue, setTabValue] = useState(qtab?qtab : 0);
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
    searchParams.delete("tab")
    navigate({
      pathname: `${BILL_REPORTS_ENDPOINT}`,
      search: `?${createSearchParams([...searchParams.entries(), ["tab", newValue]])}`,
    })
  };

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Bill Reports">
          </ListHeader>
        </div>
        <div className="mt-3 border border-[#CCC] rounded-lg overflow-hidden rounded-b-none ">
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            TabIndicatorProps={{
              style: {
                backgroundColor: "#CF001C",
              },
            }}
          >
            <StyledTab label="Members Bill" />
            <StyledTab label="Society Bills" />
            <StyledTab label="Interest Statement" />
            <StyledTab label="E Invoice Report" />
          </Tabs>
        </div>

        <div>
          <TabPanel value={tabValue} index={0}>
            <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-1 rounded-t-none border-t-0 min-h-[500px]">
              <MembersBill />
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-1 rounded-t-none border-t-0 min-h-[500px]">
              <SocietyBills />
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 rounded-t-none border-t-0 min-h-[500px]">
              <InterestStatement />
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 rounded-t-none border-t-0 min-h-[500px]">
              <EInvoiceReport />
            </div>
          </TabPanel>
        </div>
      </div>
    </Layout>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default BillReports;
