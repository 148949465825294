import React, { useState } from "react";
import Layout from "../../../Layout/Layout";
import Button from "../../../../components/Button/Button";
import ButtonG from "../../../../components/Button/ButtonG";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import { useNavigate } from "react-router-dom";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import { societyStaffTypeOptions } from "../../../../consts/DropDownOptions";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import { CREATE_MINUTES_MEETINGS_ENDPOINT } from "../../../../Routing/routes";

function CheckboxGroup({ options, selectedOptions, onChange }) {
  const [selectOptions, setSelectOptions] = useState(selectedOptions || []);

  const handleSelectAll = () => {
    if (options?.length && options?.length === selectOptions?.length) {
      setSelectOptions([]);
    } else {
      setSelectOptions(options);
    }
  };

  const handleSingleCheckbox = (e, item) => {
    console.log(selectOptions);
    if (e.target.checked) {
      setSelectOptions((prev) => [...prev, item]);
    } else {
      setSelectOptions((prev) => prev.filter((ele) => ele !== item));
    }
  };

  return (
    <>
      <div>
        <Checkbox
          text={"Select All"}
          initialChecked={
            options?.length && options?.length === selectOptions?.length
          }
          onChange={handleSelectAll}
        />
      </div>
      <div></div>
      {options?.map((item) => {
        return (
          <div key={item}>
            <Checkbox
              initialChecked={selectOptions?.includes(item)}
              text={item}
              onChange={(e) => {
                handleSingleCheckbox(e, item);
              }}
            />
          </div>
        );
      })}
    </>
  );
}

function CreateMinutesForMeetings() {
  const navigate = useNavigate();

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Create Minutes">
            <ButtonG label="Cancel" className="h-8" onClick={handleNavigation(-1)} />
            <Button label="Go" className="h-8 px-6" onClick={handleNavigation(CREATE_MINUTES_MEETINGS_ENDPOINT)}  />
          </ListHeader>
        </div>
        <div className="py-2 gap-2 m-1">
          <div className="text-sm">
            <div className="w-1/2 grid grid-cols-2 gap-y-3 text-[#222222]">
              <div className="mt-2">Meeting Type</div>
              <div>
                <Dropdown2
                  options={societyStaffTypeOptions}
                  placeholder="Meeting Type"
                  className=""
                  width="208px"
                  height="36px"
                  onSelect={(selectedItem) => {}}
                  //   {...(formik.values.subCategory === "" && {
                  //     value: null,
                  //   })}
                  name={"subCategory"}
                />
              </div>
            </div>

            <div className="font-bold my-5">
              Members
            </div>
            <div className="w-3/5 grid grid-cols-2 gap-y-1.5">
              <CheckboxGroup
                options={[
                  "Himanshi Mehta (P-Block - TPP003)",
                  "Test User (Remote - 3649)",
                  "Dipanshi J (P-Block - TPP002)",
                  "Kapil Kumar Kumar (P-Block - TPP001)",
                  "Test Joint (Remote - 3649)",
                  "Dheeraj Karda (P-Block - TPP001)",
                  "Vinesh Sandoria (P-Block - TPP055, P-Block - TPP054, P-Block - TPP051)",
                  "Dipanshi Asawa (P-Block - TPP10, P-Block - TPP20)",
                ]}
                selectedOptions={[]}
              />
            </div>

            <div className="font-bold my-5">Joint Members</div>
            <div className="w-3/5 grid grid-cols-2 gap-y-1.5">
              <CheckboxGroup
                options={[
                  "Himanshi Mehta (P-Block - TPP003)",
                  "Test User (Remote - 3649)",
                ]}
                selectedOptions={["Kapil Kumar Kumar (P-Block - TPP001)"]}
              />
            </div>

            <div className="font-bold my-5">Treasurers</div>
            <div className="w-3/5 grid grid-cols-2 gap-y-1.5">
              <CheckboxGroup
                options={[
                  "Himanshi Mehta (P-Block - TPP003)",
                  "Test User (Remote - 3649)",
                ]}
                selectedOptions={["Kapil Kumar Kumar (P-Block - TPP001)"]}
              />
            </div>

            <div className="font-bold my-5">Chairman</div>
            <div className="w-3/5 grid grid-cols-2 gap-y-1.5">
              <CheckboxGroup
                options={[
                  "Himanshi Mehta (P-Block - TPP003)",
                  "Test User (Remote - 3649)",
                ]}
                selectedOptions={["Kapil Kumar Kumar (P-Block - TPP001)"]}
              />
            </div>

            <div className="font-bold my-5">Secretary</div>
            <div className="w-3/5 grid grid-cols-2 gap-y-1.5">
              <CheckboxGroup
                options={[
                ]}
                selectedOptions={["Kapil Kumar Kumar (P-Block - TPP001)"]}
              />
            </div>

            <div className="font-bold my-5">Test Role</div>
            <div className="w-3/5 grid grid-cols-2 gap-y-1.5">
              <CheckboxGroup
                options={[
                  "Himanshi Mehta (P-Block - TPP003)",
                ]}
                selectedOptions={["Kapil Kumar Kumar (P-Block - TPP001)"]}
              />
            </div>


          </div>
        </div>
      </div>
    </Layout>
  );
}

export default CreateMinutesForMeetings;
