import React, { useEffect, useState } from "react";
import Layout from "../../../Layout/Layout";
import { Link, createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import Search from "../../../../components/SearchBox/Search";
import { DownloadExt } from "../../../../assets";
import Button from "../../../../components/Button/Button";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import ButtonIco from "../../../../components/Button/ButtonPrint";
import { ADD_SOCIETY_STAFF_ENDPOINT, SOCIETY_STAFF_ENDPOINT } from "../../../../Routing/routes";
import IdCardFieldsModal from "../../StaffManagement/StaffPersonnel/IdCardFieldsModal";
import ApprovalSettingsModal from "../../StaffManagement/StaffPersonnel/ApprovalSettings";
import { useDispatch, useSelector } from "react-redux";
import { getFlatDetails, getWingsAndFlatDetails } from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import { societyStaffStatusOptions, societyStaffTypeOptions } from "../../../../consts/DropDownOptions";
import {downloadSocietyStaffExcelSheet, downloadSocietyStaffSummaryReport, getIDCardFieldsData, getSocietyStaffList, getSocietyStaffProfileList} from '../../../../redux/actions/SetupRecordsActions/StaffManagementActions/StaffPersonnelAction'
import { downloadExcel } from "../../../../utils/helpers/downloadMedia";
import ButtonG from '../../../../components/Button/ButtonG'
import * as permissions from '../../../../Routing/permissions'
import { getPermission } from "../../../../utils/helpers/getPermissions";
import OccupantStaffListTable from "../../../../components/TableComponent/OccupantsStaffTable";

const ButtonsOutside = () => {
  const [isIDCardModalOpen, setIDCardModalOpen] = useState(false);
  const [isApprovalSettingsOpen, setApprovalSettingsOpen] = useState(false);
  const {loginData} = useSelector(state=>state.loginReducer)

  const handleOpenIDCardFieldsModal = () => {
    setIDCardModalOpen(true);
  }

  const handleisApprovalSettingsModal = () => {
    setApprovalSettingsOpen(true);
  }

  return <>
      <Button label="ID Cards Fields" className="h-8" onClick={handleOpenIDCardFieldsModal} />
    {
      getPermission(loginData, [permissions.STAFF_APPROVAL_SETTING_PERMISSION]) &&
      <Button label="Approval Setting" className="h-8" onClick={handleisApprovalSettingsModal} />
    }
    {isIDCardModalOpen && (
      <IdCardFieldsModal
      isOpen={isIDCardModalOpen}
      onClose={() => setIDCardModalOpen(false)}
      />
      )}
      {isApprovalSettingsOpen && (
      <ApprovalSettingsModal
      isOpen={isApprovalSettingsOpen}
      onClose={() => setApprovalSettingsOpen(false)}
      />
    )}
  </> 
  
}

const headingsSociety = [
  { id: 'sNo', label: 'S No.', sort: false, width: '50px' },
  { id: 'staffImage', label: 'Staff Image', sort: true,width: '130px' },
  { id: 'memberName', label: 'Staff Name', sort: true, width: '120px' },
  { id: 'staffDetails', label: 'Staff Details', sort: true, width: '130px' },
  { id: 'contactNo', label: 'Contact No.', sort: true , width: '120px'},
  { id: 'flatNo', label: 'Flat No.', sort: true , width: '150px'},
  { id: 'review', label: 'Review', sort: true, width: '120px' },
  {
    id: "action",
    label: "Action",
    sort: false,
  },
];

const SocietyStaffList = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {wingsData,flatsData} = useSelector(state=>state.wingsReducer)
  const {loginData} = useSelector(state=>state.loginReducer)
  const loading = useSelector(state=>state.staffPersonnelReducer.loading), 
    staffProfileList =  useSelector(state=>state.staffPersonnelReducer.staffProfileList), 
    staffPersonnelList= useSelector(state=>state.staffPersonnelReducer.staffPersonnelList);

  const handleSearch = (text)=>{
    if(text && text !== searchParams.get("search")){
      searchParams.delete('page')
      searchParams.delete("search")
      navigate({
        pathname: `${SOCIETY_STAFF_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["search", text?.trim()]])}`,
      })
    }
  }

  const handelSelectWing = (selectedItem)=>{
    searchParams.delete('page')
    if(selectedItem.value?.toString() !== searchParams.get("wingId")){
      dispatch(getFlatDetails({societyId: loginData?.default_community?.community_id, wingId: selectedItem?.value}))

      searchParams.delete("wingId")
      searchParams.delete("flatId")
      navigate({
        pathname: `${SOCIETY_STAFF_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["wingId", selectedItem.value]])}`,
      })
    }
  }

  const handelSelectFlat = (selectedItem)=>{
    searchParams.delete('page')
    if(selectedItem.value?.toString() !== searchParams.get("flatId")){
      searchParams.delete("flatId")
      navigate({
        pathname: `${SOCIETY_STAFF_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["flatId", selectedItem.value]])}`,
      })
    }
  }

  const handelSelectStatus = (selectedItem)=>{
    searchParams.delete('page')

    if(selectedItem.value?.toString() !== searchParams.get("status")){
      searchParams.delete("status")
      navigate({
        pathname: `${SOCIETY_STAFF_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["status", selectedItem.value]])}`,
      })
    }
  }

  const handelSelectStaffType = (selectedItem)=>{
    searchParams.delete('page')

    if(selectedItem.value?.toString() !== searchParams.get("societyType")){
      searchParams.delete("societyType")
      navigate({
        pathname: `${SOCIETY_STAFF_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["societyType", selectedItem.value]])}`,
      })
    }
  }

  const handelSelectSocietyProfile = (selectedItem)=>{
    searchParams.delete('page')

    if(selectedItem.value?.toString() !== searchParams.get("societyProfile")){
      searchParams.delete("societyProfile")
      navigate({
        pathname: `${SOCIETY_STAFF_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["societyProfile", selectedItem.value]])}`,
      })
    }
  }

  const handleDownloadExcel = ()=>{
    const onSucessCallback = (data)=>{
      downloadExcel({data, fileName: 'Society Staff List.xlsx'})
    }
    const data = {
      societyId: loginData?.default_community?.community_id, 
      searchText: searchParams.get("search") || '',
      wingId: searchParams.get("wingId") || '',
      flatId: searchParams.get("flatId") || '',
      status: searchParams.get("status") || '',
      societyType: searchParams.get("societyType") || '',
      profileId: searchParams.get("societyProfile") || '',
      onSucessCallback
    }
    dispatch(downloadSocietyStaffExcelSheet(data))
  }

  const handleDownloadSummaryReport = ()=>{
    const onSucessCallback = (data)=>{
      downloadExcel({data, fileName: 'Society Staff Summary Report.xlsx'})
    }
    const data = {
      societyId: loginData?.default_community?.community_id, 
      onSucessCallback
    }
    dispatch(downloadSocietyStaffSummaryReport(data))
  }

  const handleClear = ()=>{
    navigate(SOCIETY_STAFF_ENDPOINT)
  }

  const getTableData =() =>{
    const data={
      societyId: loginData?.default_community?.community_id,
      page: searchParams.get("page") || 1,
      searchText: searchParams.get("search") || '',
      pageLimit: searchParams.get("pageLimit") || 10,
      wingId: searchParams.get("wingId") || '',
      flatId: searchParams.get("flatId") || '',
      status: searchParams.get("status") || '',
      societyType: searchParams.get("societyType") || '',
      profileId: searchParams.get("societyProfile") || '',
    }
    dispatch(getSocietyStaffList(data))
  }
  
  
  useEffect(()=>{
    let societyId= loginData?.default_community?.community_id
    dispatch(getWingsAndFlatDetails({societyId}))
    dispatch(getSocietyStaffProfileList({societyId}))
    if(searchParams.get("wingId")){
      dispatch(getFlatDetails({societyId, wingId: searchParams.get("wingId")}))
    }
    dispatch(getIDCardFieldsData({societyId}))
  }, [])
  
  useEffect(()=>{      
    getTableData()
  }, [searchParams])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Society Staff List">
            <div className="flex text-sm gap-3">
              <ButtonsOutside />
              <div className="border-r border-[#CCC]"></div>
              {
                getPermission(loginData, [permissions.ADD_STAFF_PERMISSION]) &&
                
                <Link to={ADD_SOCIETY_STAFF_ENDPOINT} className="text-white">
                  <Button label="Add Staff" className="h-8" />
                </Link>
              }
            </div>
          </ListHeader>
        </div>

        <div className="p-1 flex flex-row justify-between gap-2 mt-1">
          <Search 
            height="8" 
            onclick={handleSearch} 
            value={searchParams.get("search")} 
            placeholder="Search staff name, code, c…"
          />

          <div className="flex justify-between items-center gap-2">
            <div className="border-r border-[#CCC] h-full"></div>
            <ButtonG label='Clear' className='h-8' onClick={handleClear} />
            {
              getPermission(loginData, [permissions.DOWNLOAD_STAFF_ATTENDENCE_REPORT_PERMISSION]) &&
              <ButtonIco icon={DownloadExt} children="Summary Report" onClick={handleDownloadSummaryReport} />
            }
            <ButtonIco icon={DownloadExt} children="Download Excel" onClick={handleDownloadExcel} />
          </div>
        </div>
        <div className="flex items-center gap-2 p-2">
          <div>Filter</div>
          <Dropdown2
              options={wingsData?.map((item)=>({
                value: item?.wing_id,
                label: item?.number
              })) || []}
              placeholder="Select Wing"
              className="text-[11px] ms-4"
              width="172px"
              height="32px"
              onSelect={(selectedItem) => {
                handelSelectWing(selectedItem)
              }}
              value={
                searchParams.get("wingId") && {
                  value: searchParams.get("wingId"),
                  label: wingsData?.find((item) => item.wing_id?.toString() === searchParams.get("wingId"))?.number
                }
              }
            />
            <Dropdown2
              options={searchParams.get('wingId') && flatsData?.manage_flat_details?.map((item)=>({
                value: item?.flat_id,
                label: item?.flat_no
              })) || []}
              placeholder="Select Flat"
              className="text-[11px] ms-4"
              width="172px"
              height="32px"
              onSelect={(selectedItem) => {
                handelSelectFlat(selectedItem)
              }}
              value={
                searchParams.get("flatId") && {
                  value: searchParams.get("flatId"),
                  label: flatsData?.manage_flat_details?.find((item) => item.flat_id?.toString() === searchParams.get("flatId"))?.flat_no
                }
              }
            />
          <Dropdown2
            options={societyStaffStatusOptions}
            placeholder="Select Status"
            className="text-[11px] ms-4"
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              handelSelectStatus(selectedItem)
            }}
            value={
              searchParams.get("status") && {
                value: searchParams.get("status"),
                label: societyStaffStatusOptions?.find((item) => item.value === searchParams.get("status"))?.label
              }
            }
          />

          <Dropdown2
            options={societyStaffTypeOptions}
            placeholder="Staff Type"
            className="text-[11px] ms-4"
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              handelSelectStaffType(selectedItem)
            }}
            value={
              searchParams.get("societyType") && {
                value: searchParams.get("societyType"),
                label: societyStaffTypeOptions?.find((item) => item.value === searchParams.get("societyType"))?.label
              }
            }
          />
          <Dropdown2
            options={staffProfileList?.map((item)=>({
              value: item?.id,
              label: item?.title
            })) || []}
            placeholder="Society Profile"
            className="text-[11px] ms-4"
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              handelSelectSocietyProfile(selectedItem)
            }}
            value={
              searchParams.get("societyProfile") && {
                value: searchParams.get("societyProfile"),
                label: staffProfileList?.find((item) => item.id?.toString() === searchParams.get("societyProfile"))?.title
              }
            }
          />
        </div>
          <OccupantStaffListTable
            loading={loading}
            data={staffPersonnelList?.staff_list?.map((item, index)=>({
              sNo: (((staffPersonnelList?.current_page - 1) * (searchParams.get("pageLimit") || 10) + 1)+index)?.toString()?.padStart(2, "0"),
              staffLevelId: item?.staff_level_id,
              staffId: item?.id,
              imageURL: item?.profile_image,
              memberName: `${item?.staff_name_prefix ? `${item?.staff_name_prefix}. ` : ''} ${item?.full_name}` || '-',
              staffCode: item?.staff_code || '-',
              profile: item?.staff_role || '-',
              mobileNo: `${item?.mobile_country_code ? `+${item?.mobile_country_code}-` : ''}${item?.phone_number}` || '-',
              flatNo: item?.wing_flat || '-',
              rating: item?.rating || 0,
            }))}
            columns={headingsSociety}
            checkbox={true}
            pagination={staffPersonnelList?.staff_list?.length}
            totalCount={staffPersonnelList?.total_count}
            totalPages={staffPersonnelList?.total_pages}
            start={(staffPersonnelList?.current_page - 1) * (searchParams.get("pageLimit") || 10) + 1}
            end={(staffPersonnelList?.current_page - 1) * (searchParams.get("pageLimit") || 10) + staffPersonnelList?.staff_list?.length}
            currentPage={parseInt(staffPersonnelList?.current_page)}
            rowsPerPage={searchParams.get("pageLimit") || 10}
            getTableData={getTableData}
          >
          </OccupantStaffListTable>

        {!staffPersonnelList?.staff_list?.length && !loading && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
      
      
    </Layout>
  );
};

export default SocietyStaffList;
