import * as Yup from "yup";

Yup.addMethod(Yup.object, "checkUnique", function (arrayName, propertyName, message) {
  return this.test("unique", message, function (value) {
    const idx = this.options.index;

    let errorIndex;
    let checkError = (this.parent || []).filter((_, i) => i !== idx).some((item, index) => {
      errorIndex = index;
      return item[`${propertyName[0]}`] === value[`${propertyName[0]}`] && item[`${propertyName[1]}`] === value[`${propertyName[1]}`]
    })

    if(checkError){
      throw this.createError({
        path: `${arrayName}[${idx}].${propertyName[0]}`,
        message,
      });
      
    }
    return true;
  });
}
);


  export const AddRateChartValidation = () => {
    return Yup.object({
      facilityId: Yup.string().required('*Required'),
      rateChart: Yup.array().of(
        Yup.object().shape({
          frequency: Yup.string().required("*Required"),
          timeSlotId: Yup.string().required("*Required"),
          chargeForNonMember: Yup.string(),
          chargeForMember: Yup.string().required("*Required"),
        })
        .checkUnique("rateChart", ["frequency", "timeSlotId"], "*Frequency and Time Slot must be unique"),
      )
    });
  };