import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Layout from "../../../Layout/Layout";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import { TextInput } from "../../../../components/InputFields/TextInput";
import {DatePickerComponent} from "../../../../components/DatePicker/DatePicker";
import { TimePickerNotFM } from "../../../../components/DatePicker/TimePicker";
import UploadButton from "../../../../components/Button/UploadButton";
import Button from "../../../../components/Button/Button";
import ListMembersList from "./ListOfMembersAccordion";
import ListMembersAccordion from "./ListOfMembersAccordion";
import { TextArea } from "../../../../components/InputFields/TextArea";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import { societyStaffTypeOptions } from "../../../../consts/DropDownOptions";

function CreateMinutes() {
  const navigate = useNavigate();

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack
            title="Create Minutes"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Button label="Preview" className="h-8" />
            <span className="border-r border-[#CCC]"> </span>
            <Button label="Save" className="h-8" />
            <Button label="Save As Draft" className="h-8" />
          </ListHeaderBack>
        </div>
        <div className="p-5 px-10 gap-2">
          <div className="mt-2">
            <p className="text-[24px] font-bold text-red-650 text-center">
              Treasure Town Society
            </p>
            <p className="text-center text-base font-semibold">
              Society Address
            </p>
          </div>
          <div className="border-[#222] border-b my-4" />
          <div className="mt-5 text-left text-[13px]">
            <div className="mb-3 flex flex-wrap items-center gap-2">
              <p className="mb-4">Minutes of the</p>
              <p>
                <TextInput label="" placeholder="Enter Date" className="w-20" />
              </p>
              <p className="mb-4">
                Annual General Meeting of the SYnapse will be held on{" "}
              </p>
              <p>
                <TextInput label="" placeholder="Day" className="w-24" />
              </p>
              <p className="mb-4">, the </p>
              <p>
                <DatePickerComponent
                  className="w-44 h-[38px] justify-between"
                  onDateChange={(selectedDate) => {
                    // setFieldValue('fromDate', selectedDate)
                  }}
                  name="fromDate"
                  // defaultValue={values.fromDate ? new Date(values.fromDate) : null}
                />
              </p>
              <p className=""> at </p>
              <p>
                <TextInput label="" placeholder="Day" className="w-32 -mb-4" />
              </p>
            </div>
            <div className="flex gap-3 items-center mt-2">
              <p>No. Of Members Attended: </p>
              <p>
                <DatePickerComponent
                  className="w-44 h-[38px] justify-between"
                  onDateChange={(selectedDate) => {
                    // setFieldValue('fromDate', selectedDate)
                  }}
                  name="fromDate"
                  // defaultValue={values.fromDate ? new Date(values.fromDate) : null}
                />
              </p>
            </div>
          </div>

          <div>
            <ListMembersAccordion />

            <div className="flex justify-center">
              <button
                type="button"
                className="text-red-650 font-semibold border-2 border-dashed border-red-650 w-24 h-8 p-2 bg-[#FDF6F6] flex justify-center rounded-md items-center text-xs"
              >
                Add More
              </button>
            </div>

            <div>
              <TextArea label="Preliminary Notes" row="4" className="w-80" />
            </div>

            <div className="border-b border-[#CCC]"></div>
          </div>

          <div className="mt-5">
            <p className="font-semibold mb-2">Agenda</p>
            <div className="editor-container border h-40 w-full bg-[#F9FAFC]">
              <Editor
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                toolbar={{
                  options: [
                    "inline",
                    "blockType",
                    "fontSize",
                    "list",
                    "textAlign",
                    "history",
                    "colorPicker",
                  ],
                  inline: {
                    inDropdown: false,
                    options: ["bold", "italic", "underline"],
                  },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: false, options: ["redo", "undo"] },
                  colorPicker: {
                    className: undefined,
                    component: undefined,
                    popupClassName: undefined,
                  },
                }}
              />
            </div>
          </div>

          <div className="mt-5">
            <p className="font-semibold mb-2">Resolution No. 1</p>
            <div className="editor-container border h-40 w-full bg-[#F9FAFC]">
              <Editor
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                toolbar={{
                  options: [
                    "inline",
                    "blockType",
                    "fontSize",
                    "list",
                    "textAlign",
                    "history",
                    "colorPicker",
                  ],
                  inline: {
                    inDropdown: false,
                    options: ["bold", "italic", "underline"],
                  },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: false, options: ["redo", "undo"] },
                  colorPicker: {
                    className: undefined,
                    component: undefined,
                    popupClassName: undefined,
                  },
                }}
              />
            </div>
            <div className="flex justify-between items-center mt-4">
              <span className="text-sm">Proposed By</span>
              <span>
                <Dropdown2
                  options={societyStaffTypeOptions}
                  placeholder="Meeting Type"
                  className=""
                  width="208px"
                  height="36px"
                  onSelect={(selectedItem) => {}}
                  //   {...(formik.values.subCategory === "" && {
                  //     value: null,
                  //   })}
                  name={"subCategory"}
                />
              </span>
              <span className="text-sm">Seconded By</span>
              <span>
                <Dropdown2
                  options={societyStaffTypeOptions}
                  placeholder="Meeting Type"
                  className=""
                  width="208px"
                  height="36px"
                  onSelect={(selectedItem) => {}}
                  //   {...(formik.values.subCategory === "" && {
                  //     value: null,
                  //   })}
                  name={"subCategory"}
                />
              </span>
            </div>
            <div className="mt-3">
              <span>
                <TextInput label="" placeholder="Enter" className="w-96" />
              </span>
            </div>
          </div>
          <div className="border-b border-[#CCC]"></div>
          <div className="flex justify-center whitespace-nowrap mt-4">
            <button
              type="button"
              className="text-red-650 font-semibold border-2 border-dashed border-red-650 w-32 h-8 p-2 bg-[#FDF6F6] flex justify-center rounded-md items-center text-xs"
            >
              Add More Agenda
            </button>
          </div>

          <div className="mt-4">
            <span>
              <TextInput label="" placeholder="Enter" className="w-96" />
            </span>
            <span>
              <TextArea
                label=""
                row="4"
                className="w-96"
                placeholder="Enter Keywords"
              />
            </span>
          </div>
          <div className="flex justify-between">
            <div>
              <span>
                <TextInput label="" placeholder="Enter" className="w-44" />
              </span>
              <span>
                <DatePickerComponent
                  className="w-44 h-[38px] justify-between"
                  onDateChange={(selectedDate) => {
                    // setFieldValue('fromDate', selectedDate)
                  }}
                  name="fromDate"
                  // defaultValue={values.fromDate ? new Date(values.fromDate) : null}
                />
              </span>
            </div>
            <div className="flex">
              <span>For:</span>
              <span>Treasure Town</span>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default CreateMinutes;
