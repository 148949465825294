import { PASSWORD_RESET } from '../index.types';


export function passwordReset(data) {
    return {
        type: PASSWORD_RESET,
        data
    };
}

