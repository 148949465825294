import axios from "axios";
import webTokenStorer from "../utils/webStorer";
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_NODE_MOBILE_BASE_URL,
});


//- Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    console.log("config", config.data);
    let userData = webTokenStorer.getToken()

    if (userData?.access_token) {
        config.headers['Authorization'] = `Bearer ${userData.access_token}`;
    }
    return config;
  },

  function (error) {
      //- Do something with request error
      return Promise.reject(error); 
  }
);

//- Add a response  interceptor
axiosInstance.interceptors.response.use(
  function (response) {
      console.log("interceptorresponse ", response);

      //- return response;
      return response.data;
  },
  function (error) {
      return Promise.reject(error);
  }
);

export default axiosInstance;