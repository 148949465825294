export const data = [
    {
      id: `01`,
      concerned_person: "Rakesh",
      phoneNumber: "9876543210",
      emailAddress: "rakeshjain01@gmail.com",
      category: "Emergency Contact",
      working_shift: "Society Night Staff",
      status: "Active",
    },
    {
      id: `02`,
      concerned_person: "Rohan",
      phoneNumber: "9876543210",
      emailAddress: "rohanjain01@gmail.com",
      category: "Emergency Contact",
      working_shift: "Society Day Staff",
      status: "Active",
    },
    {
      id: `03`,
      concerned_person: "Mahesh",
      phoneNumber: "9876543210",
      emailAddress: "rohanjain01@gmail.com",
      category: "Emergency Contact",
      working_shift: "Society Day Staff",
      status: "Active",
    },
    {
      id: `04`,
      concerned_person: "Akash",
      phoneNumber: "9876543210",
      emailAddress: "akashjain01@gmail.com",
      category: "Security",
      working_shift: "Society Night Staff",
      status: "Active",
    },
    // {
    //   id: `05`,
    //   concerned_person: "Ghanshyam",
    //   phoneNumber: "9876543210",
    //   emailAddress: "ghanshyam01@gmail.com",
    //   category: "Society Vendor",
    //   working_shift: "Society Night Staff",
    //   status: "Active",
    // },
    // {
    //   id: `06`,
    //   concerned_person: "Rupa",
    //   phoneNumber: "9876543210",
    //   emailAddress: "rupa01@gmail.com",
    //   category: "Society AMC",
    //   working_shift: "Society Night Staff",
    //   status: "Active",
    // },
  ];

  export const categoryData = [
    {
      id: `01`,
      category_name: "Emergency Contact",
      status: "Active",
    },
    {
      id: `02`,
      category_name: "Security",
      status: "Active",
    },
    {
      id: `03`,
      category_name: "Society AMC Category",
      status: "Active",
    },
    {
      id: `04`,
      category_name: "Society Vendor Category",
      status: "Active",
    },
  ];

  export const SocietyVendorsData = [
    {
      vendor_name: "LK Furniture",
      service_category: "Carpenters",
      concerned_person: "Rakesh",
      phoneNumber: "9876543210",
      schedule: "Monthly",
      AMC_Start_Date: "N/A",
      AMC_End_Date: "N/A",
      status: "Active",
    },
    {
      vendor_name: "City Furniture",
      service_category: "Carpenters",
      concerned_person: "Rahul Jat",
      phoneNumber: "9876543210",
      schedule: "Monthly",
      AMC_Start_Date: "16-08-2023",
      AMC_End_Date: "15-08-2024",
      status: "Active",
    },
    {
      vendor_name: "Bharat Electrical & Hardware Stores",
      service_category: "Electrician",
      concerned_person: "Bharat",
      phoneNumber: "9876543210",
      schedule: "Monthly",
      AMC_Start_Date: "N/A",
      AMC_End_Date: "N/A",
      status: "Active",
    },
    {
      vendor_name: "Pooja Electrical Works",
      service_category: "Electrician",
      concerned_person: "Ms. Pooja",
      phoneNumber: "9876543210",
      schedule: "Other",
      AMC_Start_Date: "16-08-2023",
      AMC_End_Date: "15-08-2024",
      status: "Active",
    },
    {
      vendor_name: "Krishna Agency",
      service_category: "AC Repair",
      concerned_person: "Sagar",
      phoneNumber: "9876543210",
      schedule: "Other",
      AMC_Start_Date: "16-08-2023",
      AMC_End_Date: "15-08-2024",
      status: "Active",
    },
    {
      vendor_name: "Rajiv Snacks",
      service_category: "Food & Snacks Suppliers",
      concerned_person: "Rajiv",
      phoneNumber: "9876543210",
      schedule: "Other",
      AMC_Start_Date: "16-08-2023",
      AMC_End_Date: "15-08-2024",
      status: "Active",
    },
  ];