import * as types from '../../index.types';

export function getTenantsDetailsList(data) {
    return {
        type: types.GET_TENANTS_DETAILS_LIST,
        data
    };
}

export function downloadTenantsDetailsList(data) {
    return {
        type: types.DOWNLOAD_TENANTS_LIST,
        data
    };
}

export function uploadTenantsDetails(data) {
    return {
        type: types.UPLOAD_TENANTS,
        data
    };
}

export function clearUploadError(data) {
    return {
        type: types.UPLOAD_TENANTS_CLEAR_ERROR,
        data
    };
}

export function addTenant(data) {
    return {
        type: types.ADD_TENANT,
        data
    };
}

export function deleteTenant(data) {
    return {
        type: types.DELETE_TENANT,
        data
    };
}

export function deleteTenantsInBulk(data) {
    return {
        type: types.DELETE_TENANT_IN_BULK,
        data
    };
}

export function changeTenantStatus(data) {
    return {
        type: types.CHANGE_TENANTS_STATUS,
        data
    };
}

export function changeTenantStatusInBulk(data) {
    return {
        type: types.CHANGE_TENANTS_STATUS_IN_BULK,
        data
    };
}

export function sendEmailToTeanants(data) {
    return {
        type: types.SEND_EMAIL_TO_TENANTS,
        data
    };
}

export function getTenantsDetails(data) {
    return {
        type: types.GET_TENANT_DETAILS,
        data
    };
}

export function updateTenantDetails(data) {
    return {
        type: types.UPDATE_TENANT_DETAILS,
        data
    };
}

export function getExistingTenants(data) {
    return {
        type: types.GET_EXISTING_TENANTS,
        data
    };
}

export function addExistingTenants(data) {
    return {
        type: types.ADD_EXISTING_TENANTS,
        data
    };
}

export function getTenantsAuditLlogs(data) {
    return {
        type: types.GET_TENANTS_AUDIT_LOGS,
        data
    };
}

export function getArchivedTenants(data) {
    return {
        type: types.GET_ARCHIVED_TENANTS,
        data
    };
}


export function archiveTenant(data) {
    return {
        type: types.ARCHIVE_TENANT,
        data
    };
}

export function getSocietyManagingCommitteeTypes(data) {
    return {
        type: types.GET_MANAGING_COMITTEE_TYPES_LIST,
        data
    };
}

export function getSocietyEmployeeTypes(data) {
    return {
        type: types.GET_EMPLOYEES_TYPE_LIST_IN_TENANTS,
        data
    };
}

export function getTenancyExpiryReminderData(data) {
    return {
        type: types.GET_TENANCY_EXPIRY_REMINDER_DATA,
        data
    };
}

export function updateTenancyExpiryReminder(data) {
    return {
        type: types.UPDATE_TENANCY_EXPIRY_REMINDER_DATA,
        data
    };
}

export function renewTenantDetail(data) {
    return {
        type: types.RENEW_FORM,
        data
    };
}

export function getRentPaymentOnlineRegistrationList(data) {
    return {
        type: types.RENT_PAYMENT_ONLINE_REGISTRATION_LIST,
        data
    };
}

export function getRentPaymentOnlineRegistrationData(data) {
    return {
        type: types.GET_RENT_PAYMENT_ONLINE_REGISTRATION_DATA,
        data
    };
}

export function updateRentPaymantOnlineRegistration(data) {
    return {
        type: types.UPDATE_RENT_PAYMENT_ONLINE_REGISTRATION_DATA,
        data
    };
}

export function deleteRentPaymantOnlineRegistration(data) {
    return {
        type: types.DELETE_RENT_PAYMENT_ONLINE_REGISTRATION_DATA,
        data
    };
}


export function getAccessSettingData(data) {
    return {
        type: types.GET_ACCESS_SETTING_DATA,
        data
    };
}

export function updateAccessSettings(data) {
    return {
        type: types.UPDATE_ACCESS_SETTING,
        data
    };
}

export function printTenantsDetailsList(data) {
    return {
        type: types.PRINT_TENANTS_LIST,
        data
    };
}