import React, { useEffect, useState } from 'react'
import Loader from '../../../../components/Loader/Loader'
import Layout from '../../../Layout/Layout'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {getTenantsAuditLlogs} from '../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/TenantsInfoAction'
import { Arrow } from '../../../../assets';
import InfiniteScroll from 'react-infinite-scroll-component';
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TenantsAuditData } from '../../../../consts/TenantsAuditData';
import moment from 'moment';
import { validateDate } from '../../../../utils/helpers/universalFunctions';

const Accordion = ({ label, isOpen, toggleAccordion, data, desc, descr}) => {

  return (
    <div className="border rounded-lg border-[#CCCCCC] my-2">
      <div
        className="flex justify-between cursor-pointer items-center bg-[#FDF2F3] rounded-lg p-3 border-b"
        onClick={toggleAccordion}
      >
        <div className="flex items-center">
          <button className="text-white text-base font-semibold mr-3 w-5 h-5 bg-red-650 
            border rounded flex justify-center items-center">
            {isOpen ? (
              <FontAwesomeIcon icon={faChevronUp} size="xs" />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} size="xs" />
            )}
          </button>
          <div className="text-[#000000] text-sm font-semibold capitalize">{label}</div>
        </div>
        <div>
            <div className="text-xs text-right text-[#888]">{desc}</div>
            <div className="text-xs text-right text-[#888]">{descr}</div>
          </div>
      </div>
      
      {isOpen && (
        <>
          <div className="flex justify-between w-full text-sm p-2 font-bold">
            <div className="w-1/2">{' '}</div>
            <div className="w-1/2">Old Value</div>
            <div className="w-1/2">New Value</div>
          </div>
          {
            TenantsAuditData?.[0]?.values.map((item, i0) => (
              <React.Fragment key={i0}>
                <div className="flex justify-between w-full text-sm p-2">
                  <div className="w-1/2">{item?.label}</div>
                  <div className="w-1/2">{item.key?.includes('Date') ? (validateDate(data?.old_data?.[item.key]) ? moment(data?.old_data?.[item.key])?.format('DD-MM-YYYY'): '-')  :  (data?.old_data?.[item.key]?.toString() || '-')}</div>
                  <div className="w-1/2">{item.key?.includes('Date') ? (validateDate(data?.new_data?.[item.key]) ? moment(data?.new_data?.[item.key])?.format('DD-MM-YYYY') : '-')  :  (data?.new_data?.[item.key]?.toString() || '-')}</div>
                </div>
              </React.Fragment>
            ))
          }
        </>
      )}
    </div>
  )
}

function TenantsAuditList() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {loading, tenantAuditLogs} = useSelector(state=>state.tenantInfoReducer)
  const [page, setPage] = useState(0)
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const toggleAccordion = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const fetchData = ()=>{
    if(!tenantAuditLogs?.total_pages || tenantAuditLogs?.current_page_no < tenantAuditLogs?.total_pages){
      let nextPage = tenantAuditLogs?.current_page_no ? parseInt(tenantAuditLogs?.current_page_no) +1 : page+1;
      dispatch(getTenantsAuditLlogs({pageLimit: 10, page: nextPage}))
      setPage(nextPage)
    }
  }

  useEffect(()=>{
    fetchData()
  }, [])

  return (
    <Layout>
    <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
      <div className="flex gap-5 pb-2">
        <div className="flex w-1/2">
          <button
            onClick={handleNavigation(-1)}
            className="text-sm p-2 text-red-650 font-semibold flex gap-2 justify-center items-center"
          >
            <span>
              <Arrow />
            </span>
            Back
          </button>
          <span className="border-r border-[#CCCCCC]"></span>
          <h1 className="text-sm font-semibold p-2">Tenants Audit Logs</h1>
        </div>
      </div>
      <div className="border-b border-[#CCCCCC]"></div>
      {
        loading && tenantAuditLogs?.tenant_audit_log?.length ===0 ?
        <Loader />
        :
        <>
          {
            !!tenantAuditLogs?.tenant_audit_log?.length &&
            <div>
              <InfiniteScroll
                dataLength={tenantAuditLogs?.tenant_audit_log?.length} //This is important field to render the next data
                next={fetchData}
                hasMore={!tenantAuditLogs?.total_pages || tenantAuditLogs?.current_page_no < tenantAuditLogs?.total_pages}
                loader={<Loader/>}
                endMessage={<></>}
                scrollThreshold={0.6}
              >
                {tenantAuditLogs?.tenant_audit_log?.length > 0 && (
                    tenantAuditLogs?.tenant_audit_log?.map((audit, index)=>{

                      // audit.old_data['Rent Deed Start Date'] = audit?.old_data?.['Rent Deed Start Date'] ? moment(new Date(audit?.old_data['Rent Deed Start Date'])).format('DD-MM-YYYY') : null
                      // audit.old_data['Rent Deed End Date'] = audit?.old_data?.['Rent Deed End Date'] ? moment(new Date(audit?.old_data['Rent Deed Start Date'])).format('DD-MM-YYYY') : null
                      
                  return (
                      <Accordion
                        label={audit?.old_data?.['Tenant Name']}
                        key={index}
                        data={audit}
                        isOpen={index === openAccordionIndex}
                        toggleAccordion={() => toggleAccordion(index)}
                        desc={`Modified By ${audit?.modified_by} On ${moment(new Date(audit?.modified_dateTime))?.format('MMM DD,YYYY hh:mm A')}`}
                        descr={`Ip Address: ${audit?.IpAddress}. Session id - ${audit?.modified_sessionId}`}
                      />
                      )
                    })
                  )
                }
            </InfiniteScroll>
            </div>
          }
        </>
      }
      {
        !loading && tenantAuditLogs?.tenant_audit_log?.length ===0 &&
        <div className="text-center py-4 text-[#999999] flex justify-center">No Audits</div>
      }
    </div>
  </Layout>
  )
}

export default TenantsAuditList