import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader/Loader";
import moment from "moment";
import { printEmployeeReportRecord } from "../../../../redux/actions/SetupRecordsActions/StaffManagementActions/EmployeeManagementAction";

export const headingsEmployeeLoginRecord = [
    { id: 'sNo', label: 'S No.', sort: false, width: '60px' },
    { id: 'loginName', label: 'Login Name', sort: true,width: '120px' },
    { id: 'userId', label: 'User ID', sort: true, width: '120px'  },
    { id: 'loginTime', label: 'Login Time', sort: true, width:'100px' },
    { id: 'logoutTime', label: 'Logout Time', sort: true, width: '100px' },
  ];

const PrintEmployeeReportRecords = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams()

  const { loginData } = useSelector((state) => state.loginReducer);
  const {printLoader, printEmployeeReportRecordData} = useSelector(state=>state.employeeManagementReducer)
  const {ipAddress} = useSelector(state=>state.ipAddressReducer)

  const getTableData =() =>{
    const data={
        empId: searchParams.get("empId") || "",
        startDate: searchParams.get("startDate") || "",
        endDate: searchParams.get("endDate") || "",
        page: 0,
        pageLimit: 0,
        societyId: loginData?.default_community?.community_id,
        ipAddress,
        sessionId: loginData?.session_id,
    }
    dispatch(printEmployeeReportRecord(data));
  }
  
  useEffect(()=>{      
    getTableData()

    const afterprint = (e) => {
      e.preventDefault()
    };
    
    window.addEventListener("afterprint", afterprint);

    return () => {
      window.removeEventListener("afterprint", afterprint);
    }
  }, [])


  useEffect(() => {
    if(!printLoader && printEmployeeReportRecordData){
      window.print()
    }
  }, [printLoader]);


  return printLoader ? (
    <Loader />
  ) : (
    <div className="m-1">
      <table className="table-auto w-full mx-auto border-collapse border border-black">
        <caption className="caption-top mb-10 mt-4">
          <div className="underline font-extrabold text-lg uppercase mb-3">{printEmployeeReportRecordData?.societyDetails?.title}</div>
          <div className="underline font-bold text-sm">(Reg No.: {printEmployeeReportRecordData?.societyDetails?.regn_no} &nbsp; Dated: {moment(new Date(printEmployeeReportRecordData?.societyDetails?.date_of_regn))?.format('DD-MM-YYYY')})</div>
          <div className="font-bold mb-5 text-sm">{printEmployeeReportRecordData?.societyDetails?.office_address} </div>
        </caption>
        <thead>
          <tr>
            {
              headingsEmployeeLoginRecord?.map((item)=>(
                <td className="text-sm p-1 font-semibold border border-black" key={item?.id}>
                  {item?.label}
                </td>
              ))
            }
          </tr>
        </thead>
        <tbody>
            {
              printEmployeeReportRecordData?.list?.map((item, index)=>(
                <React.Fragment  key={index}>
                {
                  !!Object.keys(item)?.length &&
                  <tr className="text-[#222] text-sm">
                    <td className="p-1 border border-black align-top">
                      {(index+1)?.toString()?.padStart(2, 0)}
                    </td>
                    <td className="p-1 border border-black align-top capitalize">
                      {item?.type || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.name || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.date? moment(item?.date)?.format('DD-MM-YYYY hh:mm A') : '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.ip || '-'}
                    </td>
                </tr>
                }
                </React.Fragment>
              ))
            }
          
        </tbody>
      </table>
      {!!printEmployeeReportRecordData?.tenants_list?.length && !printLoader && (
          <div className="text-sm my-10 text-center italic">
            No Records
          </div>
        )}
    </div>
  );
};

export default PrintEmployeeReportRecords;
