export const BankAuditData = [
    {
        label: "",
        values: [
            { key: "Details", label: "Details", value: "D-Block" },
            { key: "Bank Account Number", label: "Bank Account Number", value: "" },
            { key: "Bank Account Type", label: "Bank Account Type", value: "" },
            { key: "IFSC Code", label: "IFSC Code", value: "8" },
            { key: "MICR Code", label: "MICR Code", value: "8" },
            { key: "Cheque Return Charges", label: "Cheque Return Charges", value: "8" },
            { key: "Bank Address", label: "Bank Address", value: "8" },
        ],
    },
];
