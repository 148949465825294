import React, { useEffect, useState } from "react";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import Layout from "../../../Layout/Layout";
import ArchiveTenantsTable from "../../../../components/TableComponent/ArchiveTenantsTable";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import { ARCHIVE_TENANTS_ENDPOINT, TENANTS_INFO_ENDPOINT } from "../../../../Routing/routes";
import { useDispatch, useSelector } from "react-redux";
import {deleteTenantsInBulk, getArchivedTenants} from '../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/TenantsInfoAction'
import moment from "moment";
import Search from "../../../../components/SearchBox/Search";
import { getFlatDetails, getWingsAndFlatDetails } from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import { CircularProgress } from "@mui/material";
import Button from "../../../../components/Button/Button";
import * as permissions from '../../../../Routing/permissions'
import { getPermission } from "../../../../utils/helpers/getPermissions";

const headings = [
  { id: 'sNo', label: 'S No.', sort: false, width: '42px' },
  { id: 'tenantName', label: 'Tenant Name', sort: true,width: '120px' },
  { id: 'wingNo', label: 'Wing No.', sort: true, width:'80px' },
  { id: 'flatNo', label: 'Flat No.', sort: true, width:'75px' },
  { id: 'ownerName', label: 'Owner Name', sort: true, width: '110px' },
  { id: 'rentDeedStartDate', label: 'Rent Deed Start Date', sort: true, width:'110px' },
  { id: 'rentDeedEndDate', label: 'Rent Deed End Date', sort: true, width:'110px'},
  { id: 'renew', label: 'Renew', sort: true,width:'80px' },
  { id: 'leavingDate', label: 'Leaving Date', sort: true, width:'120px' },
  {
    id: "action",
    label: "Action",
    sort: false,
  },
];


const ArchiveTenants = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading, updateLoader, archivedTenantsList} = useSelector(state=>state.tenantInfoReducer)
  const {wingsData,flatsData} = useSelector(state=>state.wingsReducer)
  const [selected, setSelected] = useState([]);
  const [action, setAction] = useState('');


  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleSearch = (text)=>{
    if(text && text !== searchParams.get("search")){
      searchParams.delete('page')
      searchParams.delete('pageLimit')
      searchParams.delete("search")
      navigate({
        pathname: `${ARCHIVE_TENANTS_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["search", text]])}`,
      })
    }
  }

  const handelSelectWing = (selectedItem)=>{
    if(selectedItem.value?.toString() !== searchParams.get("wingId")){
      dispatch(getFlatDetails({societyId: loginData?.default_community?.community_id, wingId: selectedItem?.value}))
      searchParams.delete('page')
      searchParams.delete('pageLimit')
      searchParams.delete("wingId")
      searchParams.delete("flatId")
      navigate({
        pathname: `${ARCHIVE_TENANTS_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["wingId", selectedItem.value]])}`,
      })
    }
  }

  const handelSelectFlat = (selectedItem)=>{
    if(selectedItem.value?.toString() !== searchParams.get("flatId")){
    searchParams.delete('page')
    searchParams.delete('pageLimit')
    searchParams.delete("flatId")
    navigate({
      pathname: `${ARCHIVE_TENANTS_ENDPOINT}`,
      search: `?${createSearchParams([...searchParams.entries(), ["flatId", selectedItem.value]])}`,
    })
  }
  }

  const handleApply = ()=>{
    if(action === 'Delete'){
      let data={
        societyId: loginData?.default_community?.community_id,
        page: searchParams.get("page") || 1,
        searchText: searchParams.get("search") || '',
        pageLimit: searchParams.get("pageLimit") || 10,
        wingId: searchParams.get("wingId") || '',
        flatId: searchParams.get("flatId") || '',
        tenantIds: selected,
        flag: 'Archive',
        onSuccessCallback: ()=>{
          setSelected([])
        }
      }
      dispatch(deleteTenantsInBulk(data))
    }
  }

  const getTableData =() =>{
    const data={
      societyId: loginData?.default_community?.community_id,
      page: searchParams.get("page") || 1,
      pageLimit: searchParams.get("pageLimit") || 10,
      searchText: searchParams.get("search") || '',
      wingId: searchParams.get("wingId") || '',
      flatId: searchParams.get("flatId") || '',
    }

    dispatch(getArchivedTenants(data))
  }

  useEffect(()=>{      
    getTableData()
  }, [searchParams])

  useEffect(()=>{
    dispatch(getWingsAndFlatDetails({societyId: loginData?.default_community?.community_id}))

    if(searchParams.get("wingId")){
      dispatch(getFlatDetails({societyId: loginData?.default_community?.community_id, wingId: searchParams.get("wingId")}))
    }
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#ccc]">
          <ListHeaderBack
            onClick={handleNavigation(TENANTS_INFO_ENDPOINT)}
            title="Archive Tenants"
            className={'justify-end'}
          >
            <Search height="8" onclick={handleSearch} value={searchParams.get("search")} />
          </ListHeaderBack>
        </div>
        <div>
        <div className="flex justify-between items-center gap-2 p-2">
            <div className="flex items-center gap-2">
              <p className="text-[13px]">Filter</p>
              <Dropdown2
                options={wingsData?.map((item)=>({
                  value: item?.wing_id,
                  label: item?.number
                })) || []}
                placeholder="Select Wing"
                className="text-[11px] ms-4"
                width="180px"
                height="32px"
                onSelect={(selectedItem) => {
                  handelSelectWing(selectedItem)
                }}
                value={
                  searchParams.get("wingId") && {
                    value: searchParams.get("wingId"),
                    label: wingsData?.find((item) => item.wing_id?.toString() === searchParams.get("wingId"))?.number
                  }
                }
              />
              <Dropdown2
                options={searchParams.get('wingId') && flatsData?.manage_flat_details?.map((item)=>({
                  value: item?.flat_id,
                  label: item?.flat_no
                })) || []}
                placeholder="Select Flat"
                className="text-[11px] ms-4"
                width="180px"
                height="32px"
                onSelect={(selectedItem) => {
                  handelSelectFlat(selectedItem)
                }}
                value={
                  searchParams.get("flatId") && {
                    value: searchParams.get("flatId"),
                    label: flatsData?.manage_flat_details?.find((item) => item.flat_id?.toString() === searchParams.get("flatId"))?.flat_no
                  }
                }
              />
            </div>
            { !!selected?.length && getPermission(loginData, [permissions.DELETE_ARCHIVE_TENANTS_PERMISSION]) && (
                <div className="flex">
                  <div className="me-2">
                    <Dropdown2
                      options={[
                        { value: "Delete", label: "Delete" },

                      ]}
                      placeholder="Choose Action"
                      className="text-[11px]"
                      width="160px"
                      height="32px"
                      onSelect={(selectedItem) => {setAction(selectedItem.value)}}
                    />
                  </div>
                  <Button label={
                      <span className="w-8 inline-block">
                        {
                          updateLoader ?
                            <CircularProgress sx={{color: "white"}} size={17}/>
                            :
                            'Apply'
                          }
                      </span>
                    } 
                    className="font-semibold px-4 h-8" type='button' onClick={handleApply} disabled={!action} />
                </div>
              )}
          </div>
        <ArchiveTenantsTable
          loading={loading}
          data={archivedTenantsList?.tenants_list?.map((item, index)=>({
            sNo: (((archivedTenantsList?.current_page_no - 1) * archivedTenantsList?.page_limit + 1)+index)?.toString()?.padStart(2, "0"),
            tenantId: item?.tenant_id || '-',
            flatId: item.flat_id || '-',
            tenantName: item?.tenant_name || '-',
            wingNo: item?.wing_no || '-',
            flatNo: item?.flat_no || '-',
            ownerName: item?.member_name || '-',
            rentDeedStartDate: item?.start_date ? moment(new Date(item?.start_date))?.format('DD-MM-YYYY') : '-',
            rentDeedEndDate: item?.end_date ? moment(new Date(item?.end_date))?.format('DD-MM-YYYY') : '-',
            renew: item?.renew || '-',
            leavingDate: item?.leaving_date ? moment(new Date(item?.leaving_date))?.format('DD-MM-YYYY') : '-',
          }))}
          columns={headings}
          checkbox={true}
          pagination={archivedTenantsList?.tenants_list?.length}
          totalCount={archivedTenantsList?.total_record}
          totalPages={archivedTenantsList?.total_pages}
          start={(archivedTenantsList?.current_page_no - 1) * archivedTenantsList?.page_limit + 1}
          end={(archivedTenantsList?.current_page_no - 1) * archivedTenantsList?.page_limit + archivedTenantsList?.tenants_list?.length}
          currentPage={archivedTenantsList?.current_page_no}
          rowsPerPage={searchParams.get("pageLimit") || 10}
          getTableData={getTableData}

          selected={selected}
          setSelected={setSelected}
        ></ArchiveTenantsTable>
        {!archivedTenantsList?.tenants_list?.length && !loading && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}

        </div>
      </div>
    </Layout>
  );
};

export default ArchiveTenants;
