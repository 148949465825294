import { notify } from "../../../utils/notification";
import apiEndpoints from "../../api";
import axiosInstance2 from "../../axiosInstance2";
import { logOut } from "../../../utils/helpers/logOut";
import { errorMsg } from "../../../consts/responseMessages";

export async function getMyInvoiceListReq(action) {
  const BODY = {
    id: action.data?.societyId,
    invoiceno: action.data?.searchBy,
    typeid: action.data?.typeid,
    status: action.data?.status,
    from_date: action.data?.from_date,
    to_date: action.data?.to_date,
    page: action.data?.page -1,
    limit: action.data?.pageLimit,
  };
  return axiosInstance2
    .post(`${apiEndpoints.GET_MY_INVOICE_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function getInvoiceStatusListReq(action) {
  const BODY = {
    act: action.data?.act,
  };

  return axiosInstance2
    .post(apiEndpoints.MY_INVOICE_STATUS_DATE_FILTERS_API, {...BODY})
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        (errors.response?.data.success === true &&
          errorMsg.INVALID_ACCESS_TOKEN.includes(
            errors.response?.data.message
          )) ||
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)
      ) {
        logOut();
      } else if (errors.response?.data.success === false) {
        notify("error", errors.response.data.message);
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function getMyInvoicePaymentListReq(action) {
  const BODY = {
    id: action.data?.invoiceNo,
  };
  return axiosInstance2
    .post(`${apiEndpoints.MY_INVOICE_PAYMENT_LIST_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function getSingleInvoiceReq(action) {
  const BODY = {
    id: action.data?.invoiceNo,
  };
  return axiosInstance2
    .post(`${apiEndpoints.SINGLE_INVOICE_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function getReceiptReq(action) {
  const BODY = {
    id: action.data?.societyId,
    // invid: action.data?.invid,
    receiptid: action.data?.receiptid,
  };
  return axiosInstance2
    .post(`${apiEndpoints.MY_INVOICE_RECEIPT_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function getInvoiceReq(action) {
  const BODY = {
    id: action.data?.societyId,
    invIds: action.data?.invid,
  };
  return axiosInstance2
    .post(`${apiEndpoints.MY_INVOICE_PRINT_INVOICE_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}