import React from 'react'
import Dropdown2 from '../Dropdown/dropdown2'
import { ErrorMessage } from 'formik';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import styled from "@emotion/styled";
import { outlinedInputClasses, stackClasses } from '@mui/material';
import dayjs from 'dayjs';

function TimeSelectorFM({time, setTime, width, minTime, name}) {

    return (
        <div>
            <div className='flex gap-x-2'>
                <Dropdown2
                    options={Array.from({ length: 24 }, (_, index) => {
                        const value = (index).toString().padStart(2, '0');
                        return { value, label: value, isDisabled: (!index) ? false : (minTime && minTime?.split(':')?.[0] && ((parseInt(minTime?.split(':')?.[0]) > index) ||  (parseInt(minTime?.split(':')?.[0]) === index && minTime?.split(':')?.[1] ==='30')))};
                    })}
                    placeholder="HH"
                    className="text-[11px] me-4"
                    width={width}
                    height="36px"
                    onSelect={(selectedItem) => {
                        if(minTime && (minTime?.split(':')?.[0]===selectedItem.value))
                            setTime(time && time?.split(':')?.[0] ? `${selectedItem.value}:30` : `${selectedItem.value}:30`)
                        else
                            setTime(time && time?.split(':')?.[0] ? `${selectedItem.value}:${time?.split(':')?.[1] || '00'}` : `${selectedItem.value}${time || ':00'}`)
                    }}
                    value={
                        time?.split(':')?.[0] ?
                        {label: time?.split(':')?.[0], value: time?.split(':')?.[0]}
                        :null
                    }
                />
                <Dropdown2
                    options={[
                        { value: '00', label: '00', isDisabled: minTime && minTime?.split(':')?.[0] && ((minTime?.split(':')?.[0] === time?.split(':')?.[0]))},
                        { value: '30', label: '30', isDisabled: minTime && minTime?.split(':')?.[0] && ((minTime?.split(':')?.[0] === '23'))},
                    ]}
                    placeholder="MM"
                    className="text-[11px] ms-4"
                    width={width}
                    height="36px"
                    onSelect={(selectedItem) => {
                        setTime(time && time?.split(':')?.[0] ? `${time?.split(':')?.[0]}:${selectedItem.value}` : `:${selectedItem.value}`)
                    }}
                    value={
                        time?.split(':')?.[1] ?
                        {label: time?.split(':')?.[1], value: time?.split(':')?.[1]}
                        :null
                    }            
                />
            </div>
            <div className={`text-red-500 text-xs italic h-[16px]`}>
                <ErrorMessage name={name}/>
            </div>
        </div>
    )
}

export default TimeSelectorFM

const TimePickerStyled = styled(TimePicker)(({ height, width }) => ({
    '.MuiStack-root': {
        padding: 0
    },
    '.MuiInputBase-root':{
        fontSize: '13px',
        borderRadius: '6px',
        paddingTop: 0,
        backgroundColor: '#F9FAFC',
        display: 'flex',
        alignItems: 'center',
        height: height || '38px',
        border: '1px solid #cccccc',

    },
    '.Mui-focused':{
        outline: 'none',
        border: '1px solid #cccccc',
    },

    [`.${outlinedInputClasses.root}`]: {
        width: width || '208px',
        
    },
    '& .MuiInputLabel-root': { 
        lineHeight: 1 
    },

    '.MuiButtonBase-root':{
        padding: '3px',
    },

    'input': {
        padding: 0,
        paddingLeft: '9px'
    },
    
    
    'fieldset': {
        border: 'none',
    },
                    
    '.MuiOutlinedInput-notchedOutline:hover':{
        border: 'none'
    }

}))

export function TimeSelectorFM2 ({width, height, ampm=false, name, minTime, maxTime, time, setTime, minutesStep, isDisabled}) {

    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                    sx={{padding: 0, '.MuiTextField-root':{minWidth: '100px !important'}}}
                    components={['MobileTimePicker', 'MobileTimePicker', 'MobileTimePicker']}
                >
                    <TimePickerStyled
                        ampm={ampm}
                        value={dayjs(time)}
                        views={['hours', 'minutes']}   
                        {...(minTime && {minTime: dayjs(minTime)})}
                        {...(maxTime && {maxTime: dayjs(maxTime)})}
                        closeOnSelect={false}                             
                        minutesStep={minutesStep}
                        selectedSections={null}
                        timeSteps={{ hours: 1, minutes: minutesStep || 1, seconds: 1 }}
                        height={height}
                        width={width}
                        disabled={isDisabled}
                        slotProps={{
                            popper: {
                            sx: {
                                boxShadow: 'none',
                                "& .MuiList-root": {
                                },
                                "& .MuiMenuItem-root": {
                                "&.Mui-selected": {
                                    backgroundColor: '#CF001C'
                                },
                                "&.Mui-selected:hover": {
                                    backgroundColor: '#CF001C',
                                    color: 'white'
                                },
                                },
                                "& .MuiMenuItem-root:hover": {
                                    color: 'black',
                                    backgroundColor: "#FDF2F3"
                                },
                                "& .MuiDialogActions-root button": {
                                    color: '#CF001C'
                                },
                            },
                            },
                        }}
                        onChange={(value)=>{
                            setTime(value)
                        }}
                    />
                </DemoContainer>
            </LocalizationProvider>
            <div className={`text-red-500 text-xs italic h-[16px]`}>
                <ErrorMessage name={name}/>
            </div>
        </div>
    )
}