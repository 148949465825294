import * as types from '../../index.types';


export function getSocietyProfile() {
    return {
        type: types.GET_SOCIETY_PROFILE,
    };
}

export function getCurrencyList(data) {
    return {
        type: types.GET_CURRENCY_LIST,
        data
    };
}

export function getProjectList(data) {
    return {
        type: types.GET_PROJECTS_LIST,
        data
    };
}

export function getCountryStateCityList(data) {
    return {
        type: types.GET_COUNTRY_STATE_CITY_LIST,
        data
    };
}

export function updateSocietyProfile(data) {
    return {
        type: types.UPDATE_SOCIETY_PROFILE,
        data
    };
}

export function societyChangePassword(data) {
    return {
        type: types.SOCIETY_CHANGE_PASSWORD,
        data
    };
}

export function getSocietyProfileAuditLogs(data) {
    return {
        type: types.GET_SOCIETY_PROFILE_AUDIT_LOGS,
        data
    };
}


