import { ErrorMessage, FastField } from "formik";
import { onlyDecimalKey, pasteOnlyDecimalKey } from "../../utils/helpers/universalFunctions";
import { memo } from "react";
export const NumberInput = memo(({ label, name, placeholder, className, value, type, maxLength, disabled=false, onChange=undefined }) => (
  <div className="flex justify-between mb-0.5">
    {label && <span className="text-sm mt-[7.8px]">{label}</span>}
    <div>
      <div className={`${className} text-sm border border-[#CCCCCC] rounded-md p-2 bg-[#F9FAFC] `}>
        <input
          type="number"
          placeholder={placeholder}
          className="bg-[#F9FAFC] outline-none border-none w-full placeholder-italic text-black not-italic text-xs font-semibold"
          name={name}
          autoComplete='off'
          value={value}
          disabled={disabled}
          maxLength={maxLength}
          onChange={onChange}
          onKeyPress={onlyDecimalKey}
          onPaste={pasteOnlyDecimalKey}
        />
      </div>
    </div>
  </div>
));
export const NumberInputFM = memo(({ label, name, placeholder, className, value, type, maxLength, disabled=false, onChange=undefined,customError, errorBoxClassname }) => (
  <div className="flex justify-between mb-2">
    {label && <span className="text-sm mt-[7.8px]">{label}</span>}
    <div>
      <div className={`${className} text-sm border border-[#CCCCCC] rounded-md p-2 bg-[#F9FAFC] `}>
        <input
          type="number"
          placeholder={placeholder}
          className="bg-[#F9FAFC] outline-none border-none w-full placeholder-italic text-black not-italic text-xs font-semibold"
          name={name}
          autoComplete='off'
          value={value}
          disabled={disabled}
          maxLength={maxLength}
          onChange={onChange}
          onKeyPress={onlyDecimalKey}
          onPaste={pasteOnlyDecimalKey}
        />
      </div>
      <div className={`text-red-500 text-xs italic h-auto text-wrap break-words ${errorBoxClassname}`}>
          {customError || <ErrorMessage name={name}/>}
      </div> 
    </div>
  </div>
));