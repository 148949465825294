import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MAINTENANCE_STAFF_LIST_ENDPOINT } from "../../../../../Routing/routes";
import { DropdownFM2 } from "../../../../../components/Dropdown/dropdown2";
import Layout from "../../../../Layout/Layout";
import ListHeader from "../../../../../components/ListHeader/ListHeader";
import ButtonG from "../../../../../components/Button/ButtonG";
import Button from "../../../../../components/Button/Button";
import { TextInputFM } from "../../../../../components/InputFields/TextInput";
import { TextAreaFM } from "../../../../../components/InputFields/TextArea";
import { UploadButtonFM } from "../../../../../components/Button/UploadButton";
import { namePrefixOptions } from "../../../../../consts/DropDownOptions";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import { AddUpdateMaintenanceStaffValidation } from "../../../../../validationSchema/setupRecordsSchema/staffManagementSchema";
import { addUpdateMaintenanceStaff, getAgencyList, getMaintenanceStaffDetails } from "../../../../../redux/actions/SetupRecordsActions/StaffManagementActions/MaintenanceAgencyAction";
import countries from "../../../../../consts/countries";
import { CircularProgress } from "@mui/material";
import Loader from "../../../../../components/Loader/Loader";

const EditMaintenanceStaff = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {id} = useParams()
  const {agencyList} = useSelector(state=>state.agencyReducer)
  const {updateLoader, loading, maintenanceStaffDetails} = useSelector(state=> state.maintenanceStaffReducer)
  const {loginData} = useSelector(state=>state.loginReducer)
  const {ipAddress} = useSelector(state=>state.ipAddressReducer)

  const handleCancelClick = () => {
    navigate(MAINTENANCE_STAFF_LIST_ENDPOINT);
  };

  const handleSaveClick = async(values, {resetForm, initialValues}) => {
    dispatch(addUpdateMaintenanceStaff({
      ...values, 
      ipAddress,
      societyId: loginData?.default_community?.community_id,
      sessionId: loginData?.session_id,
    }))
  };
  useEffect(()=>{
    dispatch(getMaintenanceStaffDetails({staffId: id}))
    dispatch(getAgencyList({societyId: loginData?.default_community?.community_id}))
  },[])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
            initialValues={{
              staffId: id,
              prefix: namePrefixOptions?.find(item=> item.label === maintenanceStaffDetails?.name?.split(' ')?.[0])?.label || '',
              firstName: namePrefixOptions?.find(item=> item.label === maintenanceStaffDetails?.name?.split(' ')?.[0])?.label ? maintenanceStaffDetails?.name?.split(' ')?.[1] : maintenanceStaffDetails?.name?.split(' ')?.[0],
              lastName: namePrefixOptions?.find(item=> item.label === maintenanceStaffDetails?.name?.split(' ')?.[0])?.label ? maintenanceStaffDetails?.name?.split(' ')?.[2] : maintenanceStaffDetails?.name?.split(' ')?.[1],
              responsibility: maintenanceStaffDetails?.responsibility || '',
              title: maintenanceStaffDetails?.title ||  '',
              agencyId: maintenanceStaffDetails?.agency_id ||  '',
              countryISO: countries?.find(item=> item.phonecode?.replace('+','') === maintenanceStaffDetails?.mobile_country_code)?.iso,
              countryId: countries?.find(item=> item.phonecode?.replace('+','') === maintenanceStaffDetails?.mobile_country_code)?.id,              
              mobileCountryCode: maintenanceStaffDetails?.mobile_country_code || '',
              mobileNo: maintenanceStaffDetails?.contact || '',
              email:  maintenanceStaffDetails?.email ||   '',
              otherDetails: maintenanceStaffDetails?.other_details ||  '',
              idCard: maintenanceStaffDetails?.id_upload || '',
              profilePic: maintenanceStaffDetails?.image || ''
            }}
            enableReinitialize
            onSubmit={handleSaveClick}
            validationSchema={AddUpdateMaintenanceStaffValidation}
            >    
            {
              ({values, dirty, setFieldValue})=>{
                return (
                  <Form>
                    <div className="border-b border-[#CCC]">
                      <ListHeader leftContent="Edit Maintenance Staff">
                        <ButtonG
                          label="Cancel"
                          onClick={handleCancelClick}
                          className="h-8"
                          type='button'
                        />
                        <Button label={
                            <span className="w-12 inline-block">
                              {
                                updateLoader ?
                                  <CircularProgress sx={{color: "white"}} size={17}/>
                                  :
                                  'Save'
                                }
                            </span>
                            }  
                            className=" h-8" type='submit' 
                            disabled={loading || updateLoader || !dirty}
                          />
                      </ListHeader>
                    </div>
                    <div className="flex gap-4 p-2">
                      {
                        loading && maintenanceStaffDetails?.agency_staff_id?.toString() !== id ?
                        <Loader/>
                        :
                        <div className="flex flex-row justify-between w-full gap-7">
                          <div className="flex flex-col w-1/2">
                            <div className="flex justify-between text-sm items-center">
                              <span className="mb-[11px]">First Name</span>
                              <div className="flex gap-2">
                              <DropdownFM2
                                options={namePrefixOptions}
                                width={"73px"}
                                className="text-xs"
                                placeholder={"Select"}
                                onSelect={(selectedItem) => {
                                  setFieldValue(`prefix`, selectedItem.value);
                                }}
                                height="37.8px"
                                name={`prefix`}
                                value = {values.prefix &&{
                                  label: values.prefix,
                                  value: values.prefix,
                                }} 
                              />
                                <TextInputFM
                                  placeholder="Enter First Name"
                                  className="w-36"
                                  name='firstName'
                                  maxLength={20}
                                />
                              </div>
                            </div>
                            <TextInputFM
                              label="Title"
                              placeholder="Enter Title"
                              className="w-56"
                              name='title'
                              maxLength={20}
                            />

                            <div className="flex justify-between ">
                              <span className="text-sm mt-2">Country</span>
                              <DropdownFM2
                                options={ countries?.map((item)=>({
                                  label: `${item?.name?.toUpperCase()} (${item?.phonecode})`,
                                  value: item?.phonecode?.replace("+",""),
                                  iso: item.iso,
                                  id: item?.id
                                })) || []}
                                width={"224px"}
                                className="text-xs"
                                placeholder={"Select Country"}
                                onSelect={(selectedItem)=>{
                                  setFieldValue(`mobileCountryCode`, selectedItem.value)
                                  setFieldValue(`countryId`, selectedItem.id)
                                  setFieldValue(`countryISO`, selectedItem.iso)
                                }}
                                height='38px'
                                name={`mobileCountryCode`}
                                value={{
                                  label: `${countries?.find(item=> item.phonecode?.replace('+','') === values?.mobileCountryCode)?.name?.toUpperCase()} (+${values.mobileCountryCode})`,
                                  value: values.mobileCountryCode,
                                  iso: values.countryISO,
                                  id: values.countryId
                                }}
                              />
                            </div>
                            <TextInputFM
                              label="Mobile Number"
                              placeholder="Enter Number"
                              className="w-56"
                              name='mobileNo'
                              type='number'
                              maxLength={15}
                            />
                            <div className="w-full">
                              <TextAreaFM
                                label="Other Details"
                                placeholder="Enter Details"
                                className={"w-56 h-[75px]"}
                                name='otherDetails'
                              />
                            </div>
                            <div className="flex items-center justify-between mb-4">
                              <span className="text-sm">Agency Name</span>
                              <DropdownFM2
                                options={agencyList?.list?.map((item)=>({
                                  value: item?.agency_id,
                                  label: item?.agency_name
                                })) || []}
                                width={"224px"}
                                className="text-xs"
                                placeholder={"Select Agency"}
                                onSelect={(selectedItem)=>{
                                  setFieldValue(`agencyId`, selectedItem.value)
                                }}
                                height='38px'
                                name={`agencyId`}
                                value={values.agencyId && {
                                  value: values.agencyId,
                                  label: agencyList?.list?.find(item=> item.agency_id === values.agencyId)?.agency_name
                                }}
                              />
          
                            </div>
                            <div className="mb-2">
                              <div className="flex justify-between">
                                <span className="text-sm">Image</span>
                                <div className="w-1/2 -mr-1">
                                  {maintenanceStaffDetails?.imageURL ? <Link target="_blank" className="underline text-xs text-blue-500" to={maintenanceStaffDetails?.imageURL}>Click here to view</Link> : '-'}
                                </div>
                              </div>
                            </div>
                            <div className="flex justify-between mb-4 items-center">
                              <span className="text-sm">Image Upload</span>
                              <div className=" w-1/2 -mr-2">
                                <UploadButtonFM showFileName={true} 
                                    name={`profilePic`}
                                    accept={"image/jpeg,image/gif,image/png,application/pdf,image/x-eps"}
                                    onSelect={(e)=>setFieldValue(`profilePic`, e)} 
                                    fileName={values?.profilePic?.name}
                                  />
                              </div>
                            </div>
                            <div className="mb-2">
                              <div className="flex justify-between">
                                <span className="text-sm">ID Card</span>
                                <div className="w-1/2 -mr-1">
                                  {maintenanceStaffDetails?.idCardeURL ? <Link target="_blank" className="underline text-xs text-blue-500" to={maintenanceStaffDetails?.idCardeURL}>Click here to view</Link> : '-'}
                                </div>
                              </div>
                            </div>
                            <div className="flex justify-between mb-4 items-center">
                              <span className="text-sm">ID Card Upload</span>
                              <div className=" w-1/2 -mr-2">
                                <UploadButtonFM showFileName={true} 
                                  name={`idCard`}
                                  accept={"image/jpeg,image/gif,image/png,application/pdf,image/x-eps"}
                                  onSelect={(e)=>setFieldValue(`idCard`, e)} 
                                  fileName={values?.idCard?.name}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col w-1/2 ">
                            <TextInputFM
                              label='Last Name'
                              placeholder="Enter Last Name"
                              className="w-56"
                              name='lastName'
                              maxLength={20}
                            />
                            <div className="">
                              <TextInputFM
                                placeholder="Enter Responsibility"
                                label='Responsibility'
                                className="w-56"
                                name='responsibility'
                              />
                              <TextInputFM
                                placeholder="Email ID (Optional)"
                                label='Email'
                                className="w-56"
                                name='email'
                              />
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </Form>)
              }
            }
          </Formik>
      </div>
    </Layout>
  );
};

export default EditMaintenanceStaff;
