import * as type from '../../../actions/index.types'

export function applyForTenancyReducer(state = {
  updateLoader: false,
  error:null
}, action) {

switch (action.type) {
  case type.APPLY_FOR_TENANCY:
    return {
      ...state,
      updateLoader: true
    }
  case type.APPLY_FOR_TENANCY_SUCCESS:
    return {
        ...state,
        updateLoader: false
      }

  case type.APPLY_FOR_TENANCY_FAILURE:
    return {
        ...state,
        updateLoader: false
      }

default:
  return state
}
}