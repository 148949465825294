import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button/Button";
import ButtonG from "../../../components/Button/ButtonG";
import { TextInputFM } from "../../../components/InputFields/TextInput";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import { updateComplaintEscalationSettingsValidation } from "../../../validationSchema/deskSchema/logaTicketSchema";
import { CircularProgress } from "@mui/material";
import Loader from "../../../components/Loader/Loader";
import { getComplaintEscalationSettings, updateComplaintEscalationSettings } from "../../../redux/actions/DeskActions/DeskAction";

function EditComplaintEscalationLevelSetting() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [data, setData] = useState();
  const { loading, updateLoader, complaintEscalationList } = useSelector(
    (state) => state.deskReducers
  );
  const { ipAddress } = useSelector((state) => state.ipAddressReducer);
  const { loginData } = useSelector((state) => state.loginReducer);

  const handleSaveClick = (values, { resetForm }) => {
    let sendRequest = {
      ...values,
      ipAddress,
      id: data.id,
      sessionId: loginData?.session_id,
    };
    dispatch(updateComplaintEscalationSettings(sendRequest));
  };

  useEffect(() => {
    dispatch(getComplaintEscalationSettings(
      {
        societyId: loginData?.default_community?.community_id,
      }
    ));

    
  }, []);

  useEffect(() => {
    if (complaintEscalationList.length) {
      const filteredData = complaintEscalationList.filter(item => item.id?.toString() === id);
      if (filteredData.length > 0) {
        
        setData(filteredData[0]);
      } else {
        
      }
    }
  }, [complaintEscalationList]);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          initialValues={{
            escalationlevel: data?.level || "",
            stage: data?.stage || "",
            duration: data?.duration || "",
          }}
          enableReinitialize
          validationSchema={updateComplaintEscalationSettingsValidation}
          onSubmit={handleSaveClick}
        >
          {(formik) => {
            return (
              <Form>
                <div className="border-b border-[#CCC]">
                  <ListHeaderBack
                    title="Complaints Settings - Update Escalation Level"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <ButtonG
                      label="Cancel"
                      className="h-8"
                      type={'button'}
                      onClick={() => {
                        navigate(-1);
                      }}
                    />
                    <Button label={
                          <span className="inline-block">
                            {
                              updateLoader ?
                                <CircularProgress sx={{color: "white"}} size={17}/>
                                :
                                'Update'
                              }
                          </span>
                        }
                        className="px-5 h-8"
                        type={'submit'}
                        disabled={loading  && !complaintEscalationList} />
                  </ListHeaderBack>
                </div>
                {
                  loading && !complaintEscalationList ?
                  <Loader />
                  :
                <div className="p-2 gap-2">
                  <div className="w-[450px] text-sm mt-3">
                    <TextInputFM
                      label={"Escalation Level"}
                      placeholder={"Enter"}
                      className={"w-52"}
                      name='escalationlevel'
                    />
                    <TextInputFM
                    disabled
                      label={"Stage"}
                      placeholder={"Enter"}
                      className={"w-52"}
                      name='stage'
                    />
                    <TextInputFM
                      label={"Duration (In Days)"}
                      placeholder={"Enter"}
                      className={"w-52"}
                      name='duration'
                      type={`number`}
                    />
                  </div>
                </div>
                }
              </Form>
            );
          }}
        </Formik>
      </div>
    </Layout>
  );
}

export default EditComplaintEscalationLevelSetting;
