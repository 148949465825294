import { notify } from "../../../../utils/notification";
import apiEndpoints from "../../../api";
import axiosInstance2 from "../../../axiosInstance2";
import { logOut } from "../../../../utils/helpers/logOut";
import { errorMsg } from "../../../../consts/responseMessages";

export async function getManageInvoiceCurrentReq(action) {
  const BODY = {
    id: action.data?.societyId,
    is_web: action.data?.is_web,
    invoiceno: action.data?.searchBy,
    page: action.data?.page - 1,
    limit: action.data?.pageLimit,
    status: action.data?.status,
    search_by: action.data?.statusby,
    from_date: action.data?.from_date,
    to_date: action.data?.to_date,
    //search_by: action.data?.searchBy,
    wing_no: action.data?.wingId,
    flat_no: action.data?.flatId,
    member_name: action.data?.searchBy,
    from_amount: action.data?.from_amount,
    to_amount: action.data?.to_amount,
    AccountEntryStatus: action.data?.AccountEntryStatus,
    typeid: action.data?.InvoiceType,
  };
  return axiosInstance2
    .post(`${apiEndpoints.MANAGE_CURRENT_INVOICE_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function getManageInvoicePreviousReq(action) {
  const BODY = {
    id: action.data?.societyId,
    search_by: action.data?.status,
    from_date: action.data?.from_date,
    to_date: action.data?.to_date,
    page: toString(action.data?.page) - 1,
    limit: action.data?.pageLimit,
    wing_no: action.data?.wingId,
    flat_no: action.data?.flatId,
    member_name: action.data?.searchBy,
    typeid: action.data?.InvoiceType,
    //invoice_no: action.data?.searchBy,
  };
  return axiosInstance2
    .post(`${apiEndpoints.MANAGE_PREVIOUS_INVOICE_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function getManageInvoiceDraftReq(action) {
  const BODY = {
    id: action.data?.societyId,
    status: action.data?.status,
    from_date: action.data?.from_date,
    to_date: action.data?.to_date,
    page: toString(action.data?.page) - 1,
    limit: action.data?.pageLimit,
    wing_no: action.data?.wingId,
    flat_no: action.data?.flatId,
    membername: action.data?.membername,
    typeid: action.data?.InvoiceType,
  };
  return axiosInstance2
    .post(`${apiEndpoints.MANAGE_DRAFT_INVOICE_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function getManageInvoiceActionsListReq(action) {
  const BODY = {
    act: action.data?.act,
  };
  return axiosInstance2
    .post(`${apiEndpoints.INVOICE_ACTIONS_LIST_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function updateInvoiceRemarksReq(action) {
  const BODY = {
    invid: action.data?.invid,
    remarks: action.data?.remarks,
  };
  return axiosInstance2
    .post(`${apiEndpoints.UPDATE_INVOICE_REMARKS_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function adjustPaymentDetailReq(action) {
  const BODY = {
    id: action.data?.societyId,
    invid: action.data?.invid,
  };
  return axiosInstance2
    .post(`${apiEndpoints.ADJUST_PAYMENT_DETAILS_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function adjustPaymentGetAmountReq(action) {
  const BODY = {
    pageid: action.data?.societyId,
    flat_info: action.data?.flat_info,
  };
  return axiosInstance2
    .post(`${apiEndpoints.ADJUST_PAYMENT_GET_AMOUNT_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function adjustPaymentSaveReq(action) {
  const BODY = {
    pageid: action.data?.societyId,
    invid: action.data?.invid,
    invoice_no: action.data?.invoice_no,
    memberid: action.data?.memberid,
    maxamount: action.data?.maxamount,
    adjustment_date: action.data?.date,
    debitac: action.data?.debitac,
    amount: action.data?.amount,
    remarks: action.data?.remarks,
  };
  return axiosInstance2
    .post(`${apiEndpoints.ADJUSTMENT_PAYMENT_SAVE_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function downloadPaymentExcelReq(action) {
  return axiosInstance2
    .post(
      `${apiEndpoints.DOWNLOAD_PAYMENT_EXCEL_API}`,
      {},
      {
        responseType: "arraybuffer",
      }
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        (errors.response?.data.success === true &&
          errorMsg.INVALID_ACCESS_TOKEN.includes(
            errors.response?.data.message
          )) ||
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)
      ) {
        logOut();
      } else if (errors.response?.data.success === false) {
        notify("error", errors.response.data.message);
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function detailsSocietyPaymentReq(action) {
  var bodyFormData = new FormData();
  bodyFormData.append("pageid", action.data?.societyId);
  bodyFormData.append("excel", action.data?.file);
  return axiosInstance2
    .post(`${apiEndpoints.DETAIL_SOCIETY_PAYMENT_API}`, bodyFormData)
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        (errors.response?.data.success === true &&
          errorMsg.INVALID_ACCESS_TOKEN.includes(
            errors.response?.data.message
          )) ||
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)
      ) {
        logOut();
      } else if (errors.response?.data.success === false) {
        notify("error", errors.response.data.message);
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}
export async function uploadPaymentExcelReq(action) {
  //let  uploadingExcelData  = action.data.uploadingExcelData;
  // uploadingExcelData = uploadingExcelData?.filter(item=> item.selected)
  //console.log(action,'action')
  const BODY = {
    pageid: action.data.societyId,
    bankid: action.data.bankId,
    paymentdetail: action.data.uploadingExcelData,
  };

  return axiosInstance2
    .post(`${apiEndpoints.UPLOAD_PAYMENT_EXCEL_API}`, { ...BODY })

    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        (errors.response?.data.success === true &&
          errorMsg.INVALID_ACCESS_TOKEN.includes(
            errors.response?.data.message
          )) ||
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)
      ) {
        logOut();
      } else if (errors.response?.data.success === false) {
        notify("error", errors.response.data.message);
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function getEditInvoiceDetailsReq(action) {
  const BODY = {
    pageid: action.data.societyId,
    invIds: action.data.invIds,
    is_draft: action.data.is_draft,
  };

  return axiosInstance2
    .post(`${apiEndpoints.GET_EDIT_INVOICE_API}`, { ...BODY })

    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        (errors.response?.data.success === true &&
          errorMsg.INVALID_ACCESS_TOKEN.includes(
            errors.response?.data.message
          )) ||
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)
      ) {
        logOut();
      } else if (errors.response?.data.success === false) {
        notify("error", errors.response.data.message);
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}
export async function editInvoiceDetailsReq(action) {
  const BODY = {
    pageid: action.data.societyId,
    invIds: action.data.invIds,
    is_draft: action.data.is_draft,
    status: action.data.status,
    ...action.data.invoiceData,
  };

  return axiosInstance2
    .post(`${apiEndpoints.UPDATE_INVOICE_API}`, { ...BODY })

    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        (errors.response?.data.success === true &&
          errorMsg.INVALID_ACCESS_TOKEN.includes(
            errors.response?.data.message
          )) ||
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)
      ) {
        logOut();
      } else if (errors.response?.data.success === false) {
        notify("error", errors.response.data.message);
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function deleteInvoiceReq(action) {
  const BODY = {
    pageid: action.data?.societyId,
    invid: action.data?.invid,
  };
  return axiosInstance2
    .post(apiEndpoints.DELETE_MANAGE_INVOICE_API, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        (errors.response?.data.success === true &&
          errorMsg.INVALID_ACCESS_TOKEN.includes(
            errors.response?.data.message
          )) ||
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)
      ) {
        logOut();
      } else if (errors.response?.data.success === false) {
        notify("error", errors.response.data.message);
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function rejectInvoiceReq(action) {
  const BODY = {
    pageid: action.data?.societyId,
    invid: action.data?.invid,
    payid: action.data?.payid,
    send_reject_email: action.data?.send_reject_email,
    reasonType: action.data?.reasonType,
    reason: action.data?.reason,
  };
  return axiosInstance2
    .post(apiEndpoints.REJECT_MANAGE_INVOCIE_API, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        (errors.response?.data.success === true &&
          errorMsg.INVALID_ACCESS_TOKEN.includes(
            errors.response?.data.message
          )) ||
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)
      ) {
        logOut();
      } else if (errors.response?.data.success === false) {
        notify("error", errors.response.data.message);
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function verifyPostPaymentDetailsReq(action) {
  const BODY = {
    pageid: action.data?.societyId,
    invid: action.data?.invid,
  };
  return axiosInstance2
    .post(apiEndpoints.VERIFY_POST_PAYMENT_DETAILS_API, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        (errors.response?.data.success === true &&
          errorMsg.INVALID_ACCESS_TOKEN.includes(
            errors.response?.data.message
          )) ||
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)
      ) {
        logOut();
      } else if (errors.response?.data.success === false) {
        notify("error", errors.response.data.message);
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function verifyPostPaymentUpdateReq(action) {
  const BODY = {
    pageid: action.data?.societyId,
    invid: action.data?.bankinvid,
    bankid: action.data?.bankid,
    payid: action.data?.payid,
  };
  return axiosInstance2
    .post(apiEndpoints.VERIFY_POST_PAYMENT_UPDATE_API, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        (errors.response?.data.success === true &&
          errorMsg.INVALID_ACCESS_TOKEN.includes(
            errors.response?.data.message
          )) ||
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)
      ) {
        logOut();
      } else if (errors.response?.data.success === false) {
        notify("error", errors.response.data.message);
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}
export async function bulkVerifyPostPaymentReq(action) {
  const BODY = {
    pageid: action.data?.society_id,
    bankid: action.data?.bank_id,
    receiptid: action.data?.receipt_id,
  };
  return axiosInstance2
    .post(apiEndpoints.BULK_VERIFY_POST_PAYMENT_UPDATE_API, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        (errors.response?.data.success === true &&
          errorMsg.INVALID_ACCESS_TOKEN.includes(
            errors.response?.data.message
          )) ||
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)
      ) {
        logOut();
      } else if (errors.response?.data.success === false) {
        notify("error", errors.response.data.message);
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}
export async function sendInvoiceReminderEmailSmsReq(action) {
  const BODY = {
    id: action.data?.societyId,
    invIds: action.data?.invIds,
    notificationType: action.data?.notificationType,
  };

  return axiosInstance2
    .post(apiEndpoints.SEND_INVOICE_REMINDER_EMAIL_SMS, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 400) {
        notify("error", errors.response.data.message);
      } else if (errors.response.data.statusCode === 401) {
        notify("error", errors.response.data.message);
        logOut();
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function sendResendReceiptEmailSmsReq(action) {
  const BODY = {
    id: action.data?.societyId,
    paymentIds: action.data?.paymentIds,
    notificationType: action.data?.notificationType,
  };

  return axiosInstance2
    .post(apiEndpoints.SEND_RESEND_RECEIPT_EMAIL_SMS, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 400) {
        notify("error", errors.response.data.message);
      } else if (errors.response.data.statusCode === 401) {
        notify("error", errors.response.data.message);
        logOut();
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function resendInvoiceEmailSmsReq(action) {
  const BODY = {
    id: action.data?.societyId,
    invIds: action.data?.invIds,
    notificationType: action.data?.notificationType,
  };

  return axiosInstance2
    .post(apiEndpoints.SEND_INVOICE_EMAIL_SMS, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 400) {
        notify("error", errors.response.data.message);
      } else if (errors.response.data.statusCode === 401) {
        notify("error", errors.response.data.message);
        logOut();
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function downloadInvoiceDraftExcelReq(action) {
  const BODY = {
    id: action.data?.societyId,
  };

  return axiosInstance2
    .post(
      `${apiEndpoints.DOWNLOAD_INVOICE_DRAFT_INVOICE_API}`,
      { ...BODY },
      {
        responseType: "arraybuffer",
      }
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        (errors.response?.data.success === true &&
          errorMsg.INVALID_ACCESS_TOKEN.includes(
            errors.response?.data.message
          )) ||
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)
      ) {
        logOut();
      } else if (errors.response?.data.success === false) {
        notify("error", errors.response.data.message);
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}
