import * as type from "../../actions/index.types";

export function visitorAndVehicleInAndOutReducer(
  state = {
    loadingDashboard: false,
    dashboardData: null,

    loading: false,
    updateLoader: false,
    mobilPinCheckData: null,
    vehicleLog: null,
    visitorLog: null,
    frequentVisitorList: null,
    expectedVisitorList: null,
    staffDetails: null,
    visitorPassData: null,

    purposeList: null,
    memberList: null,
    existingVisitorData: null,

    loadingModal: false,
    societyGateList: null,
    accessData: null,
    error: null,
  },
  action
) {
  switch (action.type) {
    case type.GET_FLAT_MEMBER_LIST:
      return {
        ...state,
        error: null,
      };
    case type.GET_FLAT_MEMBER_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        memberList: action.payload,
      };
    case type.GET_FLAT_MEMBER_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        memberList: null,
      };

    case type.SEND_OTP_TO_VISITOR:
      return {
        ...state,
        error: null,
      };
    case type.SEND_OTP_TO_VISITOR_SUCCESS:
      return {
        ...state,
        error: null,
        mobilPinCheckData: action.payload,
      };
    case type.SEND_OTP_TO_VISITOR_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        mobilPinCheckData: null,
      };

    case type.GET_VISITOR_OR_VEHICLE_MANAGEMENT_DASHBOARD_DATA:
      return {
        ...state,
        loadingDashboard: true,
        error: null,
      };
    case type.GET_VISITOR_OR_VEHICLE_MANAGEMENT_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        error: null,
        loadingDashboard: false,
        dashboardData: action.payload,
      };
    case type.GET_VISITOR_OR_VEHICLE_MANAGEMENT_DASHBOARD_DATA_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loadingDashboard: false,
        dashboardData: null,
      };

    case type.GET_VISITOR_VEHICLE_LOG:
    return {
        ...state,
        loading: true,
        error: null,
    };
    case type.GET_VISITOR_VEHICLE_LOG_SUCCESS:
    return {
        ...state,
        error: null,
        loading: false,
        vehicleLog: action.payload,
    };
    case type.GET_VISITOR_VEHICLE_LOG_FAILURE:
    return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        vehicleLog: null,
    };

    case type.GET_SOCIETY_ENTRY_GATE:
    return {
        ...state,
        loadingModal: true,
        error: null,
    };
    case type.GET_SOCIETY_ENTRY_GATE_SUCCESS:
    return {
        ...state,
        error: null,
        loadingModal: false,
        societyGateList: action.payload,
    };
    case type.GET_SOCIETY_ENTRY_GATE_FAILURE:
    return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loadingModal: false,
        societyGateList: null,
    };

    case type.ADD_UPDATE_SOCIETY_ENTRY_GATE:
    return {
        ...state,
        updateLoader: true,
    };
    case type.ADD_UPDATE_SOCIETY_ENTRY_GATE_SUCCESS:
    return {
        ...state,
        updateLoader: false,
    };
    case type.ADD_UPDATE_SOCIETY_ENTRY_GATE_FAILURE:
    return {
        ...state,
        updateLoader: false,
    };

    case type.GET_GATE_ACCESS_DATA:
    return {
        ...state,
        loadingModal: true,
        error: null,
    };
    case type.GET_GATE_ACCESS_DATA_SUCCESS:
    return {
        ...state,
        error: null,
        loadingModal: false,
        accessData: action.payload,
    };
    case type.GET_GATE_ACCESS_DATA_FAILURE:
    return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loadingModal: false,
        accessData: null,
    };
    
    case type.ADD_UPDATE_GATE_ACCESS_DATA:
        return {
            ...state,
            updateLoader: true,
        };
    case type.ADD_UPDATE_GATE_ACCESS_DATA_SUCCESS:
    return {
        ...state,
        updateLoader: false,
    };
    case type.ADD_UPDATE_GATE_ACCESS_DATA_FAILURE:
    return {
        ...state,
        updateLoader: false,
    };
        
    case type.GET_VISITOR_LOG:
    return {
        ...state,
        loading: true,
        error: null,
    };
    case type.GET_VISITOR_LOG_SUCCESS:
    return {
        ...state,
        error: null,
        loading: false,
        visitorLog: action.payload,
    };
    case type.GET_VISITOR_LOG_FAILURE:
    return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        visitorLog: null,
    };

    case type.GET_FREQUENT_VISITOR_LIST:
      return {
          ...state,
          loading: true,
          error: null,
      };
    case type.GET_FREQUENT_VISITOR_LIST_SUCCESS:
    return {
        ...state,
        error: null,
        loading: false,
        frequentVisitorList: action.payload,
    };
    case type.GET_FREQUENT_VISITOR_LIST_FAILURE:
      return {
          ...state,
          error: action?.message || "Somthing went wrong.",
          loading: false,
          frequentVisitorList: null,
      };
  
    case type.GET_EXPECTED_VISITOR_LIST:
    return {
        ...state,
        loading: true,
        error: null,
    };
    case type.GET_EXPECTED_VISITOR_LIST_SUCCESS:
    return {
        ...state,
        error: null,
        loading: false,
        expectedVisitorList: action.payload,
    };
    case type.GET_EXPECTED_VISITOR_LIST_FAILURE:
    return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        expectedVisitorList: null,
    };

    case type.UPDATE_VISITOR_STATUS_SUCCESS:
    if(action.payload.flag==='remove'){
      return {
        ...state,
        expectedVisitorList: {
          ...state.expectedVisitorList,
          list: state.expectedVisitorList?.list?.filter(item=>!(item.visitor_log_id === action.payload.visitorLogId))
        }
      }
    }else{
      return {
        ...state,
        visitorLog: {
          ...state.visitorLog,
          logs: state.visitorLog?.logs?.map( (visitor) => visitor?.visitor_log_id === action.payload.visitorLogId
            ? {...visitor, checkstatus: action.payload.status}
            : visitor
        )
        }
      }
    }
    
    case type.GET_STAFF_DETAILS_BY_STAFF_CODE:
      return {
          ...state,
          loading: true,
          error: null,
      };
    case type.GET_STAFF_DETAILS_BY_STAFF_CODE_SUCCESS:
    return {
        ...state,
        error: null,
        loading: false,
        staffDetails: action.payload,
    };
    case type.GET_STAFF_DETAILS_BY_STAFF_CODE_FAILURE:
      return {
          ...state,
          error: action?.message || "Somthing went wrong.",
          loading: false,
          staffDetails: null,
      };
  
    
    case type.STAFF_CHECKIN:
      return {
          ...state,
          updateLoader: true,
      };
      case type.STAFF_CHECKIN_SUCCESS:
      return {
          ...state,
          updateLoader: false,
      };
      case type.STAFF_CHECKIN_FAILURE:
      return {
          ...state,
          updateLoader: false,
      };

      case type.ADD_NEW_VISITOR:
      return {
          ...state,
          updateLoader: true,
      };
      case type.ADD_NEW_VISITOR_SUCCESS:
      return {
          ...state,
          updateLoader: false,
      };
      case type.ADD_NEW_VISITOR_FAILURE:
      return {
          ...state,
          updateLoader: false,
      };

    case type.GET_VISITOR_PASS_DATA:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_VISITOR_PASS_DATA_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        visitorPassData: action.payload,
      };
    case type.GET_VISITOR_PASS_DATA_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        visitorPassData: null,
      };


    case type.GET_VISITOR_PURPOSE_LIST:
      return {
        ...state,
        error: null,
      };
    case type.GET_VISITOR_PURPOSE_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        purposeList: action.payload,
      };
    case type.GET_VISITOR_PURPOSE_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        purposeList: null,
      };


    case type.GET_VISITORS_DATA:
      return {
        ...state,
        error: null,
      };
    case type.GET_VISITORS_DATA_SUCCESS:
      return {
        ...state,
        error: null,
        existingVisitorData: action.payload,
      };
    case type.GET_VISITORS_DATA_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        existingVisitorData: null,
      };
    case type.CLEAR_GET_VISITOR_PASS_DATA:
      return {
        ...state,
        error: null,
        existingVisitorData: null,
      };

    default:
      return state;
  }
}
