import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../Layout/Layout";
import { Arrow } from "../../../assets";
import Loader from '../../../components/Loader/Loader'
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getFacilityAuditLogs } from "../../../redux/actions/ClubActions/ViewBookingsAction";
import ImageComponent from "../../../components/ImageComponent/ImageComponent";

const Accordion = ({ data, isOpen, toggleAccordion, label, descr, desc }) => {

  return (
    <div className="border rounded-lg border-[#CCCCCC] my-2">
      <div
        className="flex justify-between cursor-pointer items-center bg-[#FDF2F3] rounded-lg p-3 border-b"
        onClick={toggleAccordion}
      >
        <div className="flex items-center">
          <button className="text-white text-base font-semibold mr-3 w-5 h-5 bg-red-650 
            border rounded flex justify-center items-center">
            {isOpen ? (
              <FontAwesomeIcon icon={faChevronUp} size="xs" />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} size="xs" />
            )}
          </button>
          <div className="text-[#000000] text-sm font-semibold">{label}</div>
        </div>
        <div>
          <div className="text-xs text-right text-[#888]">{desc}</div>
          <div className="text-xs text-right text-[#888]">{descr}</div>
        </div>
      </div>
      {isOpen && (
              <div className="p-3 text-[#222222] text-sm">
                <table className="table-fixed w-full capitalize">
                <tbody>
                  <tr className="text-[#222222] font-semibold text-sm">
                    <td></td>
                    <td className="px-2">Old Value</td>
                    <td className="py-2">New Value</td>
                  </tr>
                  <tr className="text-[#222222] text-sm">
                      <td className="px-2.5 w-48 py-2 text-[#AAAAAA] font-bold align-top">
                      Image:
                      </td>
                      <td className="py-2">
                        <ImageComponent defaultUrl={data?.old_nominee_details?.image} width={32} height={32} />
                      </td>
                      <td>
                        <ImageComponent defaultUrl={data?.new_nominee_details?.image} width={32} height={32} />
                      </td>
                    </tr>
                    <tr className="text-[#222222] text-sm">
                      <td className="px-2.5 w-48 py-2 text-[#AAAAAA] font-bold align-top">
                      Facility Name:
                      </td>
                      <td className="py-1">{data?.old_nominee_details?.facilities_name || "-"}</td>
                      <td className="py-1">{data?.new_nominee_details?.facilities_name || "-"}</td>
                    </tr>
                    <tr className="text-[#222222] text-sm">
                      <td className="px-2.5 w-48 py-1 text-[#AAAAAA] font-bold align-top">
                      Booking:
                      </td>
                      <td className="py-1">{data?.old_nominee_details?.booking || "-"}</td>
                      <td className="py-1">{data?.new_nominee_details?.booking || "-"}</td>
                    </tr>
                    <tr className="text-[#222222] text-sm">
                      <td className="px-2.5 w-48 py-1 text-[#AAAAAA] font-bold align-top">
                      Is Chargeable:
                      </td>
                      <td className="py-1">{data?.old_nominee_details?.chargeable || "-"}</td>
                      <td className="py-1">{data?.new_nominee_details?.chargeable || "-"}</td>
                    </tr>
                    <tr className="text-[#222222] text-sm">
                      <td className="px-2.5 w-48 py-1 text-[#AAAAAA] font-bold align-top">
                      Facility Manager:
                      </td>
                      <td className="py-1">{`${data?.old_nominee_details?.facility_manager} ${data?.old_nominee_details?.facility_manager_name ? `(${data?.old_nominee_details?.facility_manager_name})` : ''}` || "-"}</td>
                      <td className="py-1">{`${data?.new_nominee_details?.facility_manager} ${data?.new_nominee_details?.facility_manager_name ? `(${data?.new_nominee_details?.facility_manager_name})` : ''}` || "-"}</td>
                    </tr>
                    <tr className="text-[#222222] text-sm">
                      <td className="px-2.5 w-48 py-1 text-[#AAAAAA] font-bold align-top">
                      Managing Committee:
                      </td>
                      <td className="py-1">{`${data?.old_nominee_details?.managing_committees} ${data?.old_nominee_details?.managing_committee_member ? `(${data?.old_nominee_details?.managing_committee_member})` : ''}` || "-"}</td>
                      <td className="py-1">{`${data?.new_nominee_details?.managing_committees} ${data?.new_nominee_details?.managing_committee_member ? `(${data?.new_nominee_details?.managing_committee_member})` : ''}` || "-"}</td>
                    </tr>
                    <tr className="text-[#222222] text-sm">
                      <td className="px-2.5 w-48 py-1 text-[#AAAAAA] font-bold align-top">
                      Vendor Category:
                      </td>
                      <td className="py-1">{data?.old_nominee_details?.vandor_cat_id || "-"}</td>
                      <td className="py-1">{data?.new_nominee_details?.vandor_cat_id || "-"}</td>
                    </tr>
                    <tr className="text-[#222222] text-sm">
                      <td className="px-2.5 w-48 py-1 text-[#AAAAAA] font-bold align-top">
                      Vendor:
                      </td>
                      <td className="py-1">{data?.old_nominee_details?.society_vendor_id || "-"}</td>
                      <td className="py-1">{data?.new_nominee_details?.society_vendor_id || "-"}</td>
                    </tr>
                    <tr className="text-[#222222] text-sm">
                      <td className="px-2.5 w-48 py-1 text-[#AAAAAA] font-bold align-top">
                      Concerned Persons:
                      </td>
                      <td className="py-1">{data?.old_nominee_details?.vendor_concerned_person || "-"}</td>
                      <td className="py-1">{data?.new_nominee_details?.vendor_concerned_person || "-"}</td>
                    </tr>
                    <tr className="text-[#222222] text-sm">
                      <td className="px-2.5 w-48 py-1 text-[#AAAAAA] font-bold align-top">
                      Usage Guidance:
                      </td>
                      <td className="py-1">{data?.old_nominee_details?.usage_guidance || "-"}</td>
                      <td className="py-1">{data?.new_nominee_details?.usage_guidance || "-"}</td>
                    </tr>
                    <tr className="text-[#222222] text-sm">
                      <td className="px-2.5 w-48 py-1 text-[#AAAAAA] font-bold align-top">
                      Description:
                      </td>
                      <td className="py-1">{data?.old_nominee_details?.instructions || "-"}</td>
                      <td className="py-1">{data?.new_nominee_details?.instructions || "-"}</td>
                    </tr>
                </tbody>
              </table>
              </div>
    )}
    </div>
  )
}

const ViewFacilityAuditLogs = () => {
  const navigate = useNavigate();
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const dispatch = useDispatch()
  const {id} = useParams()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading, facilityAudit} = useSelector(state=>state.viewBookingReducer)

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const toggleAccordion = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };


  useEffect(()=>{
      dispatch(getFacilityAuditLogs({societyId: loginData?.default_community?.community_id, facilityId: id}))
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="flex gap-5 pb-2">
          <div className="flex w-1/2">
            <button
              onClick={handleNavigation(-1)}
              className="text-sm p-2 text-red-650 font-semibold flex gap-2 justify-center items-center"
            >
              <span>
                <Arrow />
              </span>
              Back
            </button>
            <span className="border-r border-[#CCCCCC]"></span>
            <h1 className="text-sm font-semibold p-2">Facility Audit Logs</h1>
          </div>
        </div>
        <div className="border-b border-[#CCCCCC]"></div>
        {
          loading ?
          <Loader/>
          :
          <div>
          {facilityAudit?.update_logs?.length > 0 ? (
              facilityAudit?.update_logs?.map((audit, index)=>(
                <Accordion
                  label={`Audit Log ${(index+1)?.toString()?.padStart(2, '0')}`}
                  key={index}
                  data={audit}
                  isOpen={index === openAccordionIndex}
                  toggleAccordion={() => toggleAccordion(index)}
                  desc={`Modified By ${audit?.modified_by} On ${moment(new Date(audit?.modified_dateTime))?.format('MMM DD,YYYY hh:mm A')}`}
                  descr={`Ip Address: ${audit?.IpAddress}. Session id - ${audit?.modified_sessionId}`}
                />
              ))
            ) : (
              <div className="text-center py-4 text-[#999999] flex justify-center">No Audits</div>
            )}
          </div>
        }
      </div>
    </Layout>
  );
};

export default ViewFacilityAuditLogs;
