import React, { useEffect } from "react";
import Layout from "../../../Layout/Layout";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Button from "../../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import {
  AUDIT_LOG_SOCIETY_PARKING_CHARGES_ENDPOINT,
  EDIT_SOCIETY_PARKING_CHARGES_ENDPOINT,
} from "../../../../Routing/routes";
import ParkingChargeAccordions from "./SocietyParkingAccordions";
import { useDispatch } from "react-redux";
import { getParkingChargesList } from "../../../../redux/actions/GateManagementActions/ParkingManagementAction";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader/Loader";
import * as permissions from '../../../../Routing/permissions'
import { getPermission } from "../../../../utils/helpers/getPermissions";

const ParkingData = [
  {
    id: 1,
    title: "For 4 Wheeler",
    description: [
      {label: "1st Parking", key:'4w_1parking'},
      {label: "2nd Parking", key:'4w_2parking'},
      {label: "3rd Parking", key:'4w_3parking'},
      {label: "4th Parking", key:'4w_4parking'},
      {label: "5th Parking", key:'4w_5parking'},
      {label: "6th Parking", key:'4w_6parking'},
      {label: "7th Parking", key:'4w_7parking'},
      {label: "8th Parking", key:'4w_8parking'},
      {label: "9th Parking", key:'4w_9parking'},
      {label: "10th Parking", key:'4w_10parking'},
    ]
  },
  {
    id: 2,
    title: "For 2 Wheeler",
    description: [
      {label: "1st Parking", key:'2w_1parking'},
      {label: "2nd Parking", key:'2w_2parking'},
      {label: "3rd Parking", key:'2w_3parking'},
      {label: "4th Parking", key:'2w_4parking'},
      {label: "5th Parking", key:'2w_5parking'},
      {label: "6th Parking", key:'2w_6parking'},
      {label: "7th Parking", key:'2w_7parking'},
      {label: "8th Parking", key:'2w_8parking'},
      {label: "9th Parking", key:'2w_9parking'},
      {label: "10th Parking", key:'2w_10parking'},
    ]
  },
  {
    id: 3,
    title: "For Others",
    description: [
      {label: "1st Parking", key:'otherw_1parking'},
      {label: "2nd Parking", key:'otherw_2parking'},
      {label: "3rd Parking", key:'otherw_3parking'},
      {label: "4th Parking", key:'otherw_4parking'},
      {label: "5th Parking", key:'otherw_5parking'},
      {label: "6th Parking", key:'otherw_6parking'},
      {label: "7th Parking", key:'otherw_7parking'},
      {label: "8th Parking", key:'otherw_8parking'},
      {label: "9th Parking", key:'otherw_9parking'},
      {label: "10th Parking", key:'otherw_10parking'},
    ]
  },
];

const SocietyParkingCharges = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading} = useSelector(state=>state.parkingManagementReducer)

  const handleNavigation = (path) => () => {
    navigate(path); 
  };

  useEffect(()=>{
    dispatch(getParkingChargesList({societyId: loginData?.default_community?.community_id}))
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Society Parking Charges">
          {
            getPermission(loginData, [permissions.VIEW_PARKING_CHARGE_AUDIT_PERMISSION])
            &&
            <Button
              label="Audit Log"
              onClick={handleNavigation(
                AUDIT_LOG_SOCIETY_PARKING_CHARGES_ENDPOINT
              )}
            />
          }
            {
              getPermission(loginData, [permissions.EDIT_PARKING_CHARGES_PERMISSION])
              &&
              <>
                <div className="border-r border-[#CCC]"></div>
                <Button
                  label="Edit Parking Charges"
                  onClick={handleNavigation(EDIT_SOCIETY_PARKING_CHARGES_ENDPOINT)}
                />
              </>
            }
          </ListHeader>
        </div>
        <div>
          {
            loading ?
            <Loader/>
            :
            <ParkingChargeAccordions data={ParkingData} />
          }
        </div>
      </div>
    </Layout>
  );
};

export default SocietyParkingCharges;
