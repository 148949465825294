import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ButtonG from "../../../../components/Button/ButtonG";
import Button from "../../../../components/Button/Button";
import { TextInputFM } from "../../../../components/InputFields/TextInput";
import { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import Layout from "../../../Layout/Layout";
import { TextAreaFM } from "../../../../components/InputFields/TextArea";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import {
  UploadButtonFM,
} from "../../../../components/Button/UploadButton";
import { Form, Formik } from "formik";
import { MortgageValidation } from "../../../../validationSchema/setupRecordsSchema/occupants&TenantsSchema";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addMortgageDetails,
  getMortgageDetails,
} from "../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/OccupantsInfoAction";
import Loader from "../../../../components/Loader/Loader";
import { validateDate } from "../../../../utils/helpers/universalFunctions";

const MortgageRegister = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { society_id, flat_id } = useParams();
  const { mortgageDetails, updateLoader, loading } = useSelector(
    (state) => state.occupantInfoReducer
  );

  const handleCancelClick = () => {
    navigate(-1);
  };
  const handleSaveClick = async (values, { resetForm }) => {
    const mortgage_id = mortgageDetails?.mortgage_id;
    dispatch(
      addMortgageDetails({
        ...values,
        mortgageId: mortgage_id,
        societyId: society_id,
        flatId: flat_id,
        onSuccessCallback: ()=>{
          navigate(-1)
        }
      })
    );
  };

  useEffect(() => {
    dispatch(
      getMortgageDetails({
        societyId: society_id,
        flatId: flat_id,
      })
    );
  }, []);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg p-2">
        <Formik
          initialValues={{
            id: mortgageDetails?.mortgage_id,
            bankName: mortgageDetails?.financial_institute,
            lienLetter:
               validateDate(mortgageDetails?.lien_letter)
                ? mortgageDetails?.lien_letter
                : "",
            lienReceipt:
               validateDate(mortgageDetails?.lien_receipt)
                ? mortgageDetails?.lien_receipt
                : "",
            dateLienCancellation:
               validateDate(mortgageDetails?.date_lien_cancellation)
                ? mortgageDetails?.date_lien_cancellation
                : "",
            cancellationDetails: mortgageDetails?.cancellation_details,
            mcMeeting:
              validateDate(mortgageDetails?.mc_meeting)
                ? mortgageDetails?.mc_meeting
                : "",
            resolutionRef: mortgageDetails?.resolution_reference,
            remark: mortgageDetails?.remark,
            letterOfLien: mortgageDetails?.charge_letter_doc || "",
            cancellationLetter: mortgageDetails?.cancel_letter_doc || "",
            societyNoc: mortgageDetails?.society_noc_doc || "",
          }}
          enableReinitialize
          onSubmit={handleSaveClick}
          validationSchema={MortgageValidation}
        >
          {(formik) => {
            return (
              <Form>
                <div className="border-b border-[#CCC]">
                  <div className="ml-1">
                    <ListHeader leftContent="Lien/Mortgage Register">
                      <ButtonG
                        label="Cancel"
                        onClick={handleCancelClick}
                        className="h-8 w-16"
                        type={"button"}
                      />
                      <Button
                        label={
                          <span className="w-12 inline-block">
                            {updateLoader ? (
                              <CircularProgress
                                sx={{ color: "white" }}
                                size={17}
                              />
                            ) : (
                              "Save"
                            )}
                          </span>
                        }
                        className="h-8"
                        type={"submit"}
                      />
                    </ListHeader>
                  </div>
                </div>
                {loading ? (
                  <Loader />
                ) : (
                  <div className="flex gap-4 p-2">
                    <div className="flex flex-col justify-between w-full gap-3">
                      <div className="flex justify-between w-full gap-3">
                        <div className="w-1/2 gap-2 pr-5">
                          <TextInputFM
                            label="Name of the Bank"
                            placeholder="Enter Bank Name"
                            className="w-56"
                            name={`bankName`}
                          />
                          <div className="flex justify-between text-sm">
                            <span className="mt-2">Date of Lien Letter</span>
                            <DatePickerComponentFM
                              className="w-56 h-9 justify-between flex"
                              onDateChange={(selectedDate) => {
                                formik.setFieldValue(
                                  "lienLetter",
                                  selectedDate
                                );
                              }}
                              name="lienLetter"
                              defaultValue={
                                formik.values.lienLetter
                                  ? new Date(formik.values.lienLetter)
                                  : null
                              }
                            />
                          </div>
                          <div className="flex justify-between text-sm">
                            <span className="mt-2">Lien Letter Receipt Date</span>
                            <DatePickerComponentFM
                              className="w-56 h-9 justify-between"
                              onDateChange={(selectedDate) => {
                                formik.setFieldValue(
                                  "lienReceipt",
                                  selectedDate
                                );
                              }}
                              name="lienReceipt"
                              defaultValue={
                                formik.values.lienReceipt
                                  ? new Date(formik.values.lienReceipt)
                                  : null
                              }
                            />
                          </div>
                          <div className="flex justify-between text-sm">
                            <span className="mt-2">Date of Lien Cancellation</span>
                            <DatePickerComponentFM
                              className="w-56 h-9 justify-between"
                              onDateChange={(selectedDate) => {
                                formik.setFieldValue(
                                  "dateLienCancellation",
                                  selectedDate
                                );
                              }}
                              name="dateLienCancellation"
                              defaultValue={
                                formik.values.dateLienCancellation
                                  ? new Date(formik.values.dateLienCancellation)
                                  : null
                              }
                            />
                          </div>
                          <div>
                            <TextAreaFM
                              label="Lien Cancellation Details"
                              placeholder="Enter Details"
                              className="w-56"
                              row="5"
                              name="cancellationDetails"
                            />
                          </div>

                          <div className="flex justify-between text-sm">
                            <span className="mt-2">MC Meeting Passed Date</span>
                            <DatePickerComponentFM
                              className="w-56 h-9 justify-between"
                              onDateChange={(selectedDate) => {
                                formik.setFieldValue("mcMeeting", selectedDate);
                              }}
                              name="mcMeeting"
                              defaultValue={
                                formik.values.mcMeeting
                                  ? new Date(formik.values.mcMeeting)
                                  : null
                              }
                            />
                          </div>
                          <div>
                            <TextAreaFM
                              label="Resolution Reference"
                              placeholder="Enter Resolution"
                              className="w-56"
                              row="5"
                              name="resolutionRef"
                            />
                          </div>
                          <div>
                            <TextAreaFM
                              label="Remarks"
                              placeholder="Enter Remarks"
                              className="w-56"
                              row="5"
                              name="remark"
                            />
                          </div>
                          <div className="flex text-sm items-center mb-1">
                            <div className="mt-2  w-[232px]">Uploaded Letter of Lien</div>
                            <div className="break-all">{mortgageDetails?.charge_letter_url ? <Link target="_blank" className="ms-1 underline text-sm text-blue-500" to={mortgageDetails?.charge_letter_url}>Click To View</Link> : '-'}</div>
                          </div>
                          <div className=" flex mb-1 justify-between">
                            <label
                              className="text-sm mt-2"
                              htmlFor="uploadLetterOfLien"
                            >
                              Upload Letter of Lien
                            </label>
                            <div className="w-[225px]">
                              <UploadButtonFM
                                showFileName={true}
                                accept={"image/jpeg,image/gif,image/png,application/pdf,image/x-eps"}
                                name="letterOfLien"
                                onSelect={(e)=>formik.setFieldValue(`letterOfLien`, e)} 
                              fileName={formik.values?.letterOfLien?.name}
                              />
                            </div>
                          </div>
                          <div className="flex text-sm items-center mb-1">
                            <div className="mt-2  w-[232px]">Uploaded Cancellation Letter</div>
                            <div className="break-all">{mortgageDetails?.cancel_letter_doc_url ? <Link target="_blank" className="ms-1 underline text-sm text-blue-500" to={mortgageDetails?.cancel_letter_doc_url}>Click To View</Link> : '-'}</div>
                          </div>
                          <div className=" flex mb-1 justify-between">
                            <label
                              className="text-sm mt-2"
                              htmlFor="uploadCancellationLetter"
                            >
                              Upload Cancellation Letter
                            </label>
                            <div className="w-[225px]">
                              <UploadButtonFM
                                showFileName={true}
                                accept={"image/jpeg,image/gif,image/png,application/pdf,image/x-eps"}
                                name="cancellationLetter"
                                onSelect={(e)=>formik.setFieldValue(`cancellationLetter`, e)} 
                              fileName={formik.values?.cancellationLetter?.name}
                              />
                            </div>
                          </div>
                          <div className="flex text-sm items-center mb-1">
                            <div className="mt-2  w-[232px]">Uploaded Society NOC</div>
                            <div className="break-all">{mortgageDetails?.society_noc_doc_url ? <Link target="_blank" className="ms-1 underline text-sm text-blue-500" to={mortgageDetails?.society_noc_doc_url}>Click To View</Link> : '-'}</div>
                          </div>
                          <div className=" flex mb-1 justify-between">
                            <label
                              className="text-sm mt-2"
                              htmlFor="uploadSocietyNOC"
                            >
                              Upload Society NOC
                            </label>
                            <div className="w-[225px]">
                              <UploadButtonFM
                                showFileName={true}
                                accept={"image/jpeg,image/gif,image/png,application/pdf,image/x-eps"}
                                name="societyNoc"
                                onSelect={(e)=>formik.setFieldValue(`societyNoc`, e)} 
                                fileName={formik.values?.societyNoc?.name}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    </Layout>
  );
};

export default MortgageRegister;
