import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../../../Layout/Layout";
import ListHeaderBack from "../../../../../components/ListHeader/ListHeaderBack";
import { useDispatch } from "react-redux";
import {getAgencyDetails} from '../../../../../redux/actions/SetupRecordsActions/StaffManagementActions/MaintenanceAgencyAction'
import { useSelector } from "react-redux";
import Loader from "../../../../../components/Loader/Loader";

const agencyData = [
  {
    key: "cat_name",
    label: "Category Of Service",
    value: "Generators",
  },
  {
    key: "agency_name",
    label: "Agency Name",
    value: "Genarator maintenance service",
  },
  {
    key: "contact_office",
    label: "Contact Office",
    value: "Surat GJ",
  },
  {
    key: "pan_no",
    label: "PAN No",
    value: "HADI4578D",
  },
  {
    key: "tan_no",
    label: "TAN No",
    value: "YSDI4588D",
  },
  {
    key: "concerned_person",
    label: "Concerned Person Name",
    value: "Kishor Sharma",
  },
  {
    key: "mobile",
    label: "Mobile No",
    value: "00-00-0000",
  },
  {
    key: "other_details",
    label: "Other Details (If Any)",
    value: "00-00-0000",
  },
];

function ViewAgency() {
  const navigate = useNavigate();
  const dispatch =useDispatch()
  const {id} = useParams()
  const {loading, agencyDetails} = useSelector(state=> state.agencyReducer)

  useEffect(()=>{
    dispatch(getAgencyDetails({agencyId: id}))
  }, [])
 
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack
            title="View Agency"
            onClick={() => {
              navigate(-1);
            }}
          ></ListHeaderBack>
        </div>
        <div className="py-1 gap-2 my-1">
          {
            loading && agencyDetails?.agency_id?.toString() !== id ?
            <Loader/>
            :
            <table className="table-auto w-full">
              <tbody>
                {agencyData?.map((item) => (
                  <tr className="text-[#222222] text-sm">
                    <td className="px-2.5 w-44 py-2 text-[#AAAAAA] font-bold align-top">
                      {item.label}:
                    </td>
                    <td className="py-2">{agencyDetails?.[item.key] || "-"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          }
        </div>
      </div>
    </Layout>
  );
}

export default ViewAgency;
