import * as type from '../../../actions/index.types'

export function societyProfileReducer(state = {
  loading: false,
  updateLoading: false,
  societyProfileData: null,
  societyProfileAuditLogData: null,
  error:null
}, action) {

switch (action.type) {
  case type.GET_SOCIETY_PROFILE:
    return {
      ...state,
      loading: true,
    }
  case type.GET_SOCIETY_PROFILE_SUCCESS:
    return {
      ...state,
      error: null,
      loading: false,
      societyProfileData: action.payload
    }
  case type.GET_SOCIETY_PROFILE_FAILURE:
    return {
      ...state,
      error: action?.message || 'Somthing went wrong.',
      loading: false,
      societyProfileData: null,
    }


  case type.UPDATE_SOCIETY_PROFILE:
    return {
      ...state,
      updateLoading: true,
    }
  case type.UPDATE_SOCIETY_PROFILE_SUCCESS:
    return {
      ...state,
      updateLoading: false,
    }
  case type.UPDATE_SOCIETY_PROFILE_FAILURE:
    return {
      ...state,
      updateLoading: false,
    }

  
  case type.GET_SOCIETY_PROFILE_AUDIT_LOGS:
    return {
      ...state,
      loading: true,
    }
  case type.GET_SOCIETY_PROFILE_AUDIT_LOGS_SUCCESS:
    return {
      ...state,
      error: null,
      loading: false,
      societyProfileAuditLogData: action.payload
    }
  case type.GET_SOCIETY_PROFILE_AUDIT_LOGS_FAILURE:
    return {
      ...state,
      error: action?.message || 'Somthing went wrong.',
      loading: false,
      societyProfileAuditLogData: null,

    }

  default:
    return state
}
}

export function currencyListReducer(state = {
  loading: false,
  currencyList: null,
  error:null
}, action) {

switch (action.type) {
  case type.GET_CURRENCY_LIST:
    return {
      ...state,
      loading: true,
    }
  case type.GET_CURRENCY_LIST_SUCCESS:
    return {
      ...state,
      error: null,
      loading: false,
      currencyList: action.payload
    }

  case type.GET_CURRENCY_LIST_FAILURE:
    return {
      ...state,
      error: action?.message || 'Somthing went wrong.',
      loading: false,
      currencyList: null,
    }

  default:
    return state
}
}

export function projectListReducer(state = {
  loading: false,
  projectList: null,
  error:null
}, action) {

switch (action.type) {
  case type.GET_PROJECTS_LIST:
    return {
      ...state,
      loading: true,
    }
  case type.GET_PROJECTS_LIST_SUCCESS:
    return {
      ...state,
      error: null,
      loading: false,
      projectList: action.payload
    }
  case type.GET_PROJECTS_LIST_FAILURE:
    return {
      ...state,
      error: action?.message || 'Somthing went wrong.',
      loading: false,
      projectList: null,
    }

  default:
    return state
}
}

export function countryStateCityReducer(state = {
  country:{
    loading: false,
    countryList: null,
    error: null
  },
  state:{
    loading: false,
    stateList: null,
    error: null
  },
  city:{
    loading: false,
    cityList: null,
    error: null
  },
}, action) {

switch (action.type) {
  
  case type.GET_COUNTRY_STATE_CITY_LIST:
    return {
      ...state,
      [action.data?.flag]: {
        ...state[action.data?.flag],
        loading: true,
      },
    }
  case type.GET_COUNTRY_STATE_CITY_LIST_SUCCESS:
    return {
      ...state,
      [action.payload?.flag]: {
        loading: false,
        error:null,
        [`${action.payload.flag}List`]: action.payload.data
      },
    }

  case type.GET_COUNTRY_STATE_CITY_LIST_FAILURE:
    return {
      ...state,
      [action.payload?.flag]: {
        loading: false,
        error: action?.message || 'Somthing went wrong.',
        [`${action.payload.flag}List`]: null
      },
    }

  default:
    return state
}
}
