import React, { useState } from "react";
import Layout from "../../pages/Layout/Layout";
import { faDownload, faPrint } from "@fortawesome/free-solid-svg-icons";
import SocietyTable from "./SocietyTable";
import { SocietyVendorsData } from "./ContactData";
import {
  ADD_SOCIETY_VENDOR_ENDPOINT,
  ARCHIVEDAMC_ENDPOINT,
  VENDOR_AUDIT_LOG_ENDPOINT,
} from "../../Routing/routes";
import { useNavigate } from "react-router-dom";
import AMCContractReportModal from "./AMCContractModal";
import Dropdown from "../Dropdown/Dropdown";
import Button from "../Button/Button";
import Search from "../SearchBox/Search";
import Checkbox from "../Checkbox/Checkbox";
import ItemsPerPage from "../ItemsPerPage/ItemsPerPage";
import Pagination from "../Pagination/Pagination";
import ListHeader from "../ListHeader/ListHeader";
import ButtonIco from "../Button/ButtonPrint";

const SocietyVendors = () => {
  const [selectedNumber, setSelectedNumber] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [isAddCategoryPopupOpen, setIsAddCategoryPopupOpen] = useState(false);

  const navigate = useNavigate();

  const handleNumberClick = (number) => {
    setSelectedNumber(number);
  };

  const itemsPerPage = parseInt(selectedNumber) || 10;
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = SocietyVendorsData.slice(startIndex, endIndex);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const totalPages = Math.ceil(SocietyVendorsData.length / itemsPerPage);
  const startEntry = currentPage * itemsPerPage + 1;
  const endEntry = Math.min(
    (currentPage + 1) * itemsPerPage,
    SocietyVendorsData.length
  );

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleAMCContractModalClick = () => {
    setIsAddCategoryPopupOpen(true);
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <ListHeader leftContent="Society Vendors">
          <div className="flex gap-2 font-semibold">
            <Button
              label="Archived AMC Contract"
              onClick={handleNavigation(ARCHIVEDAMC_ENDPOINT)}
            />
            <Button
              label="AMC Contract Report"
              onClick={handleAMCContractModalClick}
            />
            <Button
              label="Audit Log"
              onClick={handleNavigation(VENDOR_AUDIT_LOG_ENDPOINT)}
            />
            <div className="border-r border-[#CCCCCC] h-full "></div>
            <Button
              label="Add Society Vendor"
              onClick={handleNavigation(ADD_SOCIETY_VENDOR_ENDPOINT)}
            />
          </div>
        </ListHeader>
        <div className="border-b border-[#CCCCCC]"></div>
        <div className="p-2 flex flex-row justify-between gap-2 -mb-2">
          <Search />
          <div className="flex justify-between items-center mb-2">
            <div className="flex items-center gap-2">
              <p>Filter</p>
              <Dropdown
                options={[
                  "Carpenters",
                  "Electrician",
                  "Ac Repair",
                  "Food & Snacks Supplier",
                ]}
                width="48"
                height="8"
                className="text-xs"
                placeholder="Select Category"
                onSelect={(selectedItem) => {
                  // Handle the selected item here
                }}
              />
              <div className="items-center text-xs inline-flex">
                <Checkbox text="Show AMC Contract Only" />
              </div>
            </div>
            <div className="border-r border-[#CCC] mx-2 h-full"></div>
            <div className="flex gap-2">
              <ButtonIco
                icon={faPrint}
                onClick={handlePrint}
                children="Print"
              />
              <ButtonIco icon={faDownload} children="Download Excel" />
            </div>
          </div>
        </div>

        <SocietyTable data={paginatedData} />

        <div className="border-b-2"></div>
        <div className="flex flex-row justify-between">
          <ItemsPerPage
            selectedNumber={selectedNumber}
            onSelect={handleNumberClick}
          />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            handlePageClick={handlePageClick}
            startEntry={startEntry}
            endEntry={endEntry}
            dataLength={SocietyVendorsData.length}
          />
        </div>
      </div>
      <AMCContractReportModal
        isOpen={isAddCategoryPopupOpen}
        onClose={() => {
          setIsAddCategoryPopupOpen(false);
        }}
      />
    </Layout>
  );
};

export default SocietyVendors;
