import { call, put, takeLatest } from 'redux-saga/effects'
import {notify} from '../../../../utils/notification'
import * as types from '../../../actions/index.types'
import * as req from '../../../../axios/request/SetupRecordsReqs/Occupants&TenantsReqs/TenantsInfoReqs'
import { getFirebaseURL, uploadImageOnFirebase } from '../../../../firebase/firebaseFunction';
import { societyDocsPath, societyProfilePath } from '../../../../firebase/getFirebasePath';

function* getTenantDetailsList(action) {
    try {
        const result = yield call(req.getTenantsListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_TENANTS_DETAILS_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_TENANTS_DETAILS_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* downloadTenantDetailsList(action) {
    try {
        const result = yield call(req.downloadTenantsExcelSheetReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
        }else{
            notify('Unable to download sheet.')
        }
    } catch (e) {
    }
}

function* uploadTenantsExcelSheet(action) {
    try {
        const result = yield call(req.uploadTenantsReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.UPLOAD_TENANTS_SUCCESS, payload: {error: result?.data?.message}});  
            action.data?.onSuccessCallback?.()
        }else{
            yield put({ type: types.UPLOAD_TENANTS_FAILURE});  
        }
        action.data?.onRequestEndCallback?.()
    } catch (e) {
        console.log(e)
    }
}

function* addTenants(action) {
    try {
        if(typeof action.data.rentAgreementFile !== 'string'){
            let path = societyProfilePath({societyId: action.data.societyId, flatId: action.data.flatId, fileName: action.data.rentAgreementFile?.name});
            yield uploadImageOnFirebase(action.data.rentAgreementFile, path)
            action.data.rentAgreementFile= path;
        }
        if(typeof action.data.adharFile !== 'string'){
            let path = societyDocsPath({societyId: action.data.societyId, flatId: action.data.flatId, fileName: action.data.adharFile?.name});
            yield uploadImageOnFirebase(action.data.adharFile, path)
            action.data.adharFile= path;
        }
        const result = yield call(req.addTenantReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.ADD_TENANT_SUCCESS, payload: action.data});  
            action.data?.onSuccessCallback?.()
        }else{
            yield put({ type: types.ADD_TENANT_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}

function* deleteTenant(action) {
    try {
        const result = yield call(req.deleteTenantReq, action);
        if (result.statusCode === 200) {
            notify('success', 'Data deleted successfully.');
            action.data?.onSuccessCallback()
            yield put({ type: types.DELETE_TENANT_SUCCESS, payload: action.data});  
            if(action.data?.flag==='Tenant'){
                const newTenantList = yield call(req.getTenantsListReq, action)
                if (newTenantList.statusCode === 200)
                    yield put({ type: types.GET_TENANTS_DETAILS_LIST_SUCCESS, payload: newTenantList.data});  
            }else{
                const newTenantList = yield call(req.getArchivedTenantReq, action)
                if (newTenantList.statusCode === 200)
                    yield put({ type: types.GET_ARCHIVED_TENANTS_SUCCESS, payload: newTenantList.data});  
            }
        }
    } catch (e) {
        console.log(e)
    }
}

function* deleteTenantInBulk(action) {
    try {
        const result = yield call(req.deleteTenantsInBulkReq, action);
        if (result.statusCode === 200) {
            notify('success', result.message);
            action.data?.onSuccessCallback?.()
            yield put({ type: types.DELETE_TENANT_IN_BULK_SUCCESS, payload: action.data});  
            if(action.data?.flag==='Tenant'){
                const newTenantList = yield call(req.getTenantsListReq, action)
                if (newTenantList.statusCode === 200)
                    yield put({ type: types.GET_TENANTS_DETAILS_LIST_SUCCESS, payload: newTenantList.data});  
            }else{
                const newTenantList = yield call(req.getArchivedTenantReq, action)
                if (newTenantList.statusCode === 200)
                    yield put({ type: types.GET_ARCHIVED_TENANTS_SUCCESS, payload: newTenantList.data});  
            }
        }
    } catch (e) {
        console.log(e)
    }
}

function* changeTenantStatus(action) {
    action.data?.onRequestEndCallback?.()
    try {
        const result = yield call(req.changeTenantStatusReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.CHANGE_TENANTS_STATUS_SUCCESS, payload: action.data});  
        }else{
            yield put({ type: types.CHANGE_TENANTS_STATUS_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}

function* changeTenantStatusInBulk(action) {
    try {
        const result = yield call(req.changeTenantStatusInBulkReq, action);
        if (result.statusCode === 200) {
            action.data?.onSuccessCallback?.()
            notify('success', result?.message)
            yield put({ type: types.CHANGE_TENANTS_STATUS_IN_BULK_SUCCESS, payload: action.data});  
        }else{
            yield put({ type: types.CHANGE_TENANTS_STATUS_IN_BULK_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}

function* sendEmailToTenant(action) {
    try {
        const result = yield call(req.sendEmailToTenantsReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.SEND_EMAIL_TO_TENANTS_SUCCESS});  
        }else{
            notify('Unable to send Email.')
            yield put({ type: types.SEND_EMAIL_TO_TENANTS_FAILURE});  
        }
        action.data?.onRequestEndCallback?.()
    } catch (e) {
    }
}

function* getTenantDetails(action) {
    try {
        const result = yield call(req.getTenantsDetailsReq, action);
        if (result.statusCode === 200) {
            result.data.rentAgreementURL = yield getFirebaseURL(result?.data?.rent_agreement)
            result.data.aadharCardURL = yield getFirebaseURL(result?.data?.aadhar_card)
            yield put({ type: types.GET_TENANT_DETAILS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_TENANT_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateTenantsDetails(action) {
    try {
        if(typeof action.data.rentAgreementFile !== 'string'){
            let path = societyProfilePath({societyId: action.data.societyId, flatId: action.data.flats?.[0]?.flatId, fileName: action.data.rentAgreementFile?.name});
            yield uploadImageOnFirebase(action.data.rentAgreementFile, path)
            action.data.rentAgreementFile= path;
        }
        if(typeof action.data.adharFile !== 'string'){
            let path = societyDocsPath({societyId: action.data.societyId, flatId: action.data.flats?.[0]?.flatId, fileName: action.data.adharFile?.name});
            yield uploadImageOnFirebase(action.data.adharFile, path)
            action.data.adharFile= path;
        }
        const result = yield call(req.updateTenantsDetailReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.UPDATE_TENANT_DETAILS_SUCCESS, payload: action.data});  
            action.data?.onSuccessCallback?.()
        }else{
            yield put({ type: types.UPDATE_TENANT_DETAILS_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}

function* getExistingTenants(action) {
    try {
        const result = yield call(req.getExistingTenantReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_EXISTING_TENANTS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_EXISTING_TENANTS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* addExistingTenants(action) {
    try {
        const result = yield call(req.addExistingTenantReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.ADD_EXISTING_TENANTS_SUCCESS, payload: action.data});  
            action.data?.onSuccessCallback?.()
        }else{
            yield put({ type: types.ADD_EXISTING_TENANTS_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}

function* getTenantAuditLogs(action) {
    try {
        const result = yield call(req.getTenantAuditLogsReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_TENANTS_AUDIT_LOGS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_TENANTS_AUDIT_LOGS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}


function* getArchivedTenantsList(action) {
    try {
        const result = yield call(req.getArchivedTenantReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_ARCHIVED_TENANTS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_ARCHIVED_TENANTS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* archiveTenant(action) {
    try {
        const result = yield call(req.archiveTenantReq, action);
        if (result.statusCode === 200) {
            notify('success', 'Tenant archived successfully.');
            yield put({ type: types.ARCHIVE_TENANT_SUCCESS, payload: action.data});  
            const newTenantList = yield call(req.getTenantsListReq, action)
            if (newTenantList.statusCode === 200)
                yield put({ type: types.GET_TENANTS_DETAILS_LIST_SUCCESS, payload: newTenantList.data});  
        }
        action.data?.onRequestEndCallback?.()
    } catch (e) {
        console.log(e)
    }
}

function* getSocietyManagingCommitteeTypes(action) {
    try {
        const result = yield call(req.getSocietyManagingCommitteeTypesReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_MANAGING_COMITTEE_TYPES_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_MANAGING_COMITTEE_TYPES_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getSocietyEmployeeTypes(action) {
    try {
        const result = yield call(req.getSocietyEmployeeTypesReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_EMPLOYEES_TYPE_LIST_IN_TENANTS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_EMPLOYEES_TYPE_LIST_IN_TENANTS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getTenancyExpiryReminderData(action) {
    try {
        const result = yield call(req.getTenancyExpiryReminderDataReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_TENANCY_EXPIRY_REMINDER_DATA_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_TENANCY_EXPIRY_REMINDER_DATA_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}


function* updateTenancyExpiryReminder(action) {
    try {
        const result = yield call(req.updateTenancyExpiryReminderReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.UPDATE_TENANCY_EXPIRY_REMINDER_DATA_SUCCESS, payload: action.data}); 
        }
        action.data?.onRequestEndCallback?.()
        yield put({ type: types.UPDATE_TENANCY_EXPIRY_REMINDER_DATA_FAILURE});  
    } catch (e) {
        console.log(e)
    }
}

function* renewTenantDetail(action) {
    try {
        if(typeof action.data.rentAgreementFile !== 'string'){
            let path = societyProfilePath({societyId: action.data.societyId, flatId: action.data.flatId, fileName: action.data.rentAgreementFile?.name});
            yield uploadImageOnFirebase(action.data.rentAgreementFile, path)
            action.data.rentAgreementFile= path;
        }
        const result = yield call(req.renewTenantDetailReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.RENEW_FORM_SUCCESS, payload: action.data}); 
        }
        yield put({ type: types.RENEW_FORM_FAILURE}); 
    } catch (e) {
        console.log(e)
    }
}

function* getRentPaymentOnlineRegistrationList(action) {
    try {
        const result = yield call(req.getRentPaymentOnlineRegistrationListReq, action);
        if (result.statusCode === 200) {
            for(let item of result.data?.occupants_list){
                item.rent_agreement_url = yield getFirebaseURL(item?.rent_agreement_url)
            }
            yield put({ type: types.RENT_PAYMENT_ONLINE_REGISTRATION_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.RENT_PAYMENT_ONLINE_REGISTRATION_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getRentPaymentOnlineRegistrationData(action) {
    try {
        const result = yield call(req.getRentPaymentOnlineRegistrationDataReq, action);
        if (result.statusCode === 200) {
            result.data.rent_agreement_path = result?.data?.rent_agreement_url
            result.data.rent_agreement_url = yield getFirebaseURL(result?.data?.rent_agreement_url)
            yield put({ type: types.GET_RENT_PAYMENT_ONLINE_REGISTRATION_DATA_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_RENT_PAYMENT_ONLINE_REGISTRATION_DATA_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateRentPaymantOnlineRegistration(action) {
    try {
        if(typeof action.data.rentAgreementFile !== 'string'){
            let path = societyProfilePath({societyId: action.data.societyId, flatId: action.data.flats?.[0]?.flatId, fileName: action.data.rentAgreementFile?.name});
            yield uploadImageOnFirebase(action.data.rentAgreementFile, path)
            action.data.rentAgreementFile= path;
        }
        const result = yield call(req.updateRentPaymantOnlineRegistrationReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            action.data?.onSuccessCallback?.()
            yield put({ type: types.UPDATE_RENT_PAYMENT_ONLINE_REGISTRATION_DATA_SUCCESS, payload: action.data}); 
        }
        yield put({ type: types.UPDATE_RENT_PAYMENT_ONLINE_REGISTRATION_DATA_FAILURE}); 
    } catch (e) {
        console.log(e) 
    }
}


function* deleteRentPaymantOnlineRegistration(action) {
    try {
        const result = yield call(req.deleteRentPaymantOnlineRegistratioReq, action);
        if (result.statusCode === 200) {
            notify('success', 'Data deleted successfully.');
            action.data?.onSuccessCallback()
            yield put({ type: types.DELETE_RENT_PAYMENT_ONLINE_REGISTRATION_DATA_SUCCESS, payload: action.data});  
            const newList = yield call(req.getRentPaymentOnlineRegistrationListReq, action)
            if (newList.statusCode === 200)
                yield put({ type: types.GET_RENT_PAYMENT_ONLINE_REGISTRATION_DATA_SUCCESS, payload: newList.data});  
        }
    } catch (e) {
        console.log(e)
    }
}

function* getAccessSettingData(action) {
    try {
        const result = yield call(req.getAccessSettingDataReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_ACCESS_SETTING_DATA_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_ACCESS_SETTING_DATA_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateAccessSettings(action) {
    try {
        const result = yield call(req.updateAccessSettingsReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.UPDATE_ACCESS_SETTING_SUCCESS, payload: action.data}); 
        }
        yield put({ type: types.UPDATE_ACCESS_SETTING_FAILURE}); 
    } catch (e) {
        console.log(e)
    }
}

function* printTenantDetailsList(action) {
    try {
        const result = yield call(req.printTenantsListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.PRINT_TENANTS_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.PRINT_TENANTS_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

export default function* TenantsInfoSaga() {
    yield takeLatest(types.GET_TENANTS_DETAILS_LIST, getTenantDetailsList);
    yield takeLatest(types.DOWNLOAD_TENANTS_LIST, downloadTenantDetailsList);
    yield takeLatest(types.UPLOAD_TENANTS, uploadTenantsExcelSheet);
    yield takeLatest(types.ADD_TENANT, addTenants);
    yield takeLatest(types.DELETE_TENANT, deleteTenant);
    yield takeLatest(types.DELETE_TENANT_IN_BULK, deleteTenantInBulk);
    yield takeLatest(types.CHANGE_TENANTS_STATUS, changeTenantStatus);
    yield takeLatest(types.CHANGE_TENANTS_STATUS_IN_BULK, changeTenantStatusInBulk);
    yield takeLatest(types.SEND_EMAIL_TO_TENANTS, sendEmailToTenant);
    yield takeLatest(types.GET_TENANT_DETAILS, getTenantDetails);
    yield takeLatest(types.UPDATE_TENANT_DETAILS, updateTenantsDetails);
    yield takeLatest(types.GET_EXISTING_TENANTS, getExistingTenants);
    yield takeLatest(types.ADD_EXISTING_TENANTS, addExistingTenants);
    yield takeLatest(types.GET_TENANTS_AUDIT_LOGS, getTenantAuditLogs);
    yield takeLatest(types.GET_ARCHIVED_TENANTS, getArchivedTenantsList);
    yield takeLatest(types.ARCHIVE_TENANT, archiveTenant);
    yield takeLatest(types.GET_MANAGING_COMITTEE_TYPES_LIST, getSocietyManagingCommitteeTypes);
    yield takeLatest(types.GET_EMPLOYEES_TYPE_LIST_IN_TENANTS, getSocietyEmployeeTypes);
    yield takeLatest(types.GET_TENANCY_EXPIRY_REMINDER_DATA, getTenancyExpiryReminderData);
    yield takeLatest(types.UPDATE_TENANCY_EXPIRY_REMINDER_DATA, updateTenancyExpiryReminder);
    yield takeLatest(types.RENEW_FORM, renewTenantDetail);
    yield takeLatest(types.RENT_PAYMENT_ONLINE_REGISTRATION_LIST, getRentPaymentOnlineRegistrationList);
    yield takeLatest(types.GET_RENT_PAYMENT_ONLINE_REGISTRATION_DATA, getRentPaymentOnlineRegistrationData);
    yield takeLatest(types.UPDATE_RENT_PAYMENT_ONLINE_REGISTRATION_DATA, updateRentPaymantOnlineRegistration);
    yield takeLatest(types.DELETE_RENT_PAYMENT_ONLINE_REGISTRATION_DATA, deleteRentPaymantOnlineRegistration);
    yield takeLatest(types.GET_ACCESS_SETTING_DATA, getAccessSettingData);
    yield takeLatest(types.UPDATE_ACCESS_SETTING, updateAccessSettings);
    yield takeLatest(types.PRINT_TENANTS_LIST, printTenantDetailsList);
}