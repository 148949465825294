import React, { useEffect } from "react";
import Layout from "../../Layout/Layout";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button/Button";
import ButtonG from "../../../components/Button/ButtonG";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getFacilityList, getRateChart, getTimeSlotList, updateRateChart } from "../../../redux/actions/ClubActions/BookAFacilityAction";
import { Form, Formik } from "formik";
import { AddRateChartValidation } from "../../../validationSchema/ClubSchema/AddRateChartSchema";
import { CircularProgress } from "@mui/material";
import EditRateChartTable from "./EditRateChartTable";
import Loader from "../../../components/Loader/Loader";

export const headCells = [
  {
    id: "bookingFrequency",
    label: "Booking Frequency",
    width: "180px",
  },
  { id: "timeSlot", label: "Time Slot", width: "180px" },
  {
    id: "chargesForMembers",
    label: "Charges For Members/Day Amount (Rs.)",
    width: "200px",
  },
  {
    id: "chargesForNonMembers",
    label: "Charges For Non Members/Day Amount (Rs.)",
    width: "200px",
  },
];

const EditRateChart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {id} = useParams()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loadingRateChart, updateLoader, rateChart, facilityList} = useSelector(state=>state.bookAFacilityReducer)

  const handleSubmit = (values, {resetForm})=>{
    dispatch(updateRateChart({...values, type: 'updated'}))
  }
  
  useEffect(()=>{
    dispatch(getFacilityList({societyId: loginData?.default_community?.community_id}))
    dispatch(getRateChart({facilityId: id}))
    dispatch(getTimeSlotList({facilityId: id}))
  }, [])


  return (
    <Layout>
      <Formik
        initialValues={{  
          facilityId: id || '', 
          rateChart: rateChart?.map((item)=>({
            id: item.id,
            frequency: item.booking_type,
            timeSlotId: item.time_slot,
            chargeForMember: item.charges_for_members,
            chargeForNonMember: item.charges_for_nonMembers,
          }))

        }}
        enableReinitialize
        validateOnBlur={true}
        validationSchema={AddRateChartValidation}
        onSubmit={handleSubmit}
      >
        {
          ({values, errors, setFieldValue})=>(
            <Form>
              <div className="w-full h-full bg-white border min-h-[609px] border-[#CCC] rounded-lg p-2">
                <div className="border-b mb-3 border-[#CCC]">
                  <ListHeaderBack
                    title={`Edit Rate Chart ${!loadingRateChart && facilityList?.facilty_list ? `- ${facilityList?.facilty_list?.find(item=> item.id?.toString() === id)?.facilities_name}` : ''}`}
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <ButtonG label="Cancel" type='button' onClick={()=>{navigate(-1)}} className="h-8" />
                    <Button label={
                      <span className="w-16 inline-block">
                        {
                        updateLoader ?
                          <CircularProgress sx={{color: "white"}} size={17}/>
                          :
                          <>Update</>
                        }
                      </span>
                      } type='submit' className="h-8" />
                  </ListHeaderBack>
                </div>
                {
                  loadingRateChart ?
                  <Loader/>
                  :
                  <>
                  {
                    rateChart &&
                    <>
                      <div className="border border-[#CCC] rounded-lg">
                        <div className="border-b p-3 text-sm font-semibold bg-[#FDF2F3] flex justify-between items-center">
                          Rate Chart
                        </div>
                        <div className="p-2 ml-1">
                          <EditRateChartTable
                            values={values}
                            setFieldValue={setFieldValue}
                            columns={headCells}
                          ></EditRateChartTable>
                        </div>
                      </div>
                    </>
                  }
                  </>
                }
              </div>
            </Form>
          )
        }
        </Formik>
    </Layout>
  );
};

export default EditRateChart;
