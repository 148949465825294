import React, { useEffect } from "react";
import Layout from "../../Layout/Layout";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button/Button";
import ButtonG from "../../../components/Button/ButtonG";
import EditTimeSlotTable from "./EditTimeSlotTable";
import EditTimeSlotHourlyTable from "./EditTimeSlotHourlyTable";
import Loader from "../../../components/Loader/Loader";
import { Form, Formik } from "formik";
import { AddTimeSlotValidation } from "../../../validationSchema/ClubSchema/AddTimeSlotSchema";
import { useDispatch } from "react-redux";
import {
  getFacilityList,
  getTimeSlotList,
  updateTimeSlot,
} from "../../../redux/actions/ClubActions/BookAFacilityAction";
import { useSelector } from "react-redux";
import { getTimeSlotTypeList } from "../../../redux/actions/ClubActions/AddTimeSlotAction";
import { CircularProgress } from "@mui/material";
import { ALL_FACILITY_ENDPOINT } from "../../../Routing/routes";
import { getPermission } from "../../../utils/helpers/getPermissions";
import * as permissions from "../../../Routing/permissions";

const EditTimeSlot = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, loadingTimeSlot, updateLoader, facilityList, timeSlotList } =
    useSelector((state) => state.bookAFacilityReducer);

  const handleSubmit = (values, { resetForm }) => {
    dispatch(updateTimeSlot({ ...values, type: "updated" }));
  };

  useEffect(() => {
    dispatch(
      getTimeSlotTypeList({
        societyId: loginData?.default_community?.community_id,
      })
    );
    dispatch(
      getFacilityList({ societyId: loginData?.default_community?.community_id })
    );
    dispatch(getTimeSlotList({ facilityId: id }));
  }, []);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          initialValues={{
            facilityId: id,
            timeSlot: timeSlotList?.slots
              ?.filter((item) => item.slot_type === "timeRange")
              ?.map((item) => ({
                id: item.id,
                type: item?.slot?.slice(27, item?.slot?.length - 1) || "",
                ticket: item?.available_ticket_count || "",
                startTime: item?.start_time?.slice(0, -3) || "",
                endTime: item?.end_time?.slice(0, -3) || "",
                frequency:
                  item?.applicabledays &&
                  item?.applicabledays?.split(",")?.length < 7
                    ? "Selected"
                    : "All",
                selectedDays:
                  item?.applicabledays &&
                  item?.applicabledays?.split(",")?.length < 7
                    ? item?.applicabledays?.split(",")
                    : [],
              })),
            timeSlotHourly: timeSlotList?.slots
              ?.filter((item) => item.slot_type === "hours")
              ?.map((item) => ({
                id: item.id,
                // type: item?.slot?.slice(18, item?.slot?.length - 1) || "",
                type:
                  item?.slot
                    ?.split("hrs")[1]
                    .slice(2, item?.slot?.split("hrs")[1].length - 1) || "",
                ticket: item?.available_ticket_count || "",
                lessThan: item?.slot.split("n")[0] + "n",
                hours: item?.slot_hours || "",
              })),
          }}
          enableReinitialize
          validationSchema={AddTimeSlotValidation}
          onSubmit={handleSubmit}
        >
          {({ values, errors, setFieldValue }) => (
            <Form>
              <div className="border-b border-[#CCC]">
                <ListHeaderBack
                  title={`Edit Time Slot ${
                    !loading && facilityList?.facilty_list
                      ? `- ${
                          facilityList?.facilty_list?.find(
                            (item) => item.id?.toString() === id
                          )?.facilities_name
                        }`
                      : ""
                  }`}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <ButtonG
                    label="Cancel"
                    type="buton"
                    onClick={() => navigate(ALL_FACILITY_ENDPOINT)}
                    className="h-8"
                  />
                  {getPermission(loginData, [
                    permissions.EDIT_TIME_SLOT_PERMISSION,
                  ]) && (
                    <Button
                      label={
                        <span className="w-16 inline-block">
                          {updateLoader ? (
                            <CircularProgress
                              sx={{ color: "white" }}
                              size={17}
                            />
                          ) : (
                            <>Update</>
                          )}
                        </span>
                      }
                      className="h-8"
                    />
                  )}
                </ListHeaderBack>
              </div>
              {loadingTimeSlot ? (
                <Loader />
              ) : (
                <>
                  <div className="border border-[#CCC] rounded-lg mt-2 min-h-[300px]">
                    <div className="border-b p-3 text-sm font-semibold bg-[#FDF2F3] flex justify-between items-center">
                      Time Slot
                    </div>
                    <div className="mx-2">
                      <EditTimeSlotTable
                        values={values}
                        setFieldValue={setFieldValue}
                      ></EditTimeSlotTable>
                    </div>
                  </div>

                  <div className="border border-[#CCC] rounded-lg mt-2 min-h-[300px]">
                    <div className="border-b p-3 text-sm font-semibold bg-[#FDF2F3] flex justify-between items-center">
                      Time Slot Hourly
                    </div>
                    <div className="mx-2">
                      <EditTimeSlotHourlyTable
                        values={values}
                        setFieldValue={setFieldValue}
                      ></EditTimeSlotHourlyTable>
                    </div>
                  </div>
                </>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};

export default EditTimeSlot;
