import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextInputFM } from "../../../components/InputFields/TextInput";
import { SOCIETY_PROFILE_ENDPOINT } from "../../../Routing/routes";
import Layout from "../../Layout/Layout";
import Button from "../../../components/Button/Button";
import ButtonG from "../../../components/Button/ButtonG";
import { TextAreaFM } from "../../../components/InputFields/TextArea";
import ProfilePicture from "../../../components/ProfilePicture/ProfilePicture";
import { Form, Formik } from "formik";
import { EditSocietyProfileValidation } from "../../../validationSchema/setupRecordsSchema/buildingSchema";
import { useDispatch, useSelector } from "react-redux";
import {
  getCountryStateCityList,
  getCurrencyList,
  getProjectList,
  getSocietyProfile,
  updateSocietyProfile,
} from "../../../redux/actions/SetupRecordsActions/BuildingActions/SocietyProfileAction";
import { isRegisteredOptions } from "../../../consts/DropDownOptions";
import { CircularProgress } from "@mui/material";
import Loader from "../../../components/Loader/Loader";
import { DropdownFM2 } from "../../../components/Dropdown/dropdown2";
import { getPermission } from "../../../utils/helpers/getPermissions";
import { EDIT_PROFILE_PICTURE_PERMISSION } from "../../../Routing/permissions";
import TimePicker from "../../../components/DatePicker/TimePicker";
import { DatePickerComponentFM } from "../../../components/DatePicker/DatePicker";
import { PasswordInputFM } from "../../../components/InputFields/PasswordInput";

const EditSocietyProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, updateLoading, societyProfileData } = useSelector(
    (state) => state.societyProfileReducer
  );
  const { ipAddress } = useSelector((state) => state.ipAddressReducer);
  const { loginData } = useSelector((state) => state.loginReducer);
  const [profileData, setProfileData] = useState(null);
  const [eInvoice, setEInvoice] = useState(null);
  const [taxCreds, setTaxCreds] = useState(null);
  const { currencyList } = useSelector((state) => state.currencyListReducer);
  const { projectList } = useSelector((state) => state.projectListReducer);
  const { country, state, city } = useSelector(
    (state) => state.countryStateCityReducer
  );

  const handleCancelClick = () => {
    navigate(SOCIETY_PROFILE_ENDPOINT);
  };

  const handleSaveClick = async (values, { resetForm, initialValues }) => {
    dispatch(
      updateSocietyProfile({
        ...values,
        ipAddress,
        societyId: profileData?.society_id,
        sessionId: loginData?.session_id,
        onSuccessCallback: () => {
          navigate(SOCIETY_PROFILE_ENDPOINT);
        },
      })
    );
  };

  useEffect(() => {
    dispatch(getCurrencyList());
    dispatch(getProjectList());
    dispatch(getCountryStateCityList({ flag: "country" }));

    if (!societyProfileData) {
      dispatch(getSocietyProfile());
    }
  }, []);

  useEffect(() => {
    if (societyProfileData) {
      setProfileData(societyProfileData?.profile_data);
    }
    if (societyProfileData?.profile_data?.country_id) {
      dispatch(
        getCountryStateCityList({
          flag: "state",
          country_id: societyProfileData?.profile_data?.country_id,
        })
      );
    }
    if (societyProfileData?.profile_data?.state_id) {
      dispatch(
        getCountryStateCityList({
          flag: "city",
          state_id: societyProfileData?.profile_data?.state_id,
        })
      );
    }

    if (societyProfileData) {
      setEInvoice(societyProfileData?.e_invoice);
    }

    if (societyProfileData) {
      setTaxCreds(societyProfileData?.tax_creds);
    }
  }, [societyProfileData]);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          initialValues={{
            societyName: profileData?.title || "",
            profileURL: profileData?.society_logo || "",
            societyNameForSMS: profileData?.short_name || "",
            // brandName: profileData?.brand_name || '',
            panNumber: profileData?.pan_no || "",
            tanNumber: profileData?.tan_no || "",
            //payPin: profileData?.pay_pin || '',
            spintlyOrganisationID: profileData?.sprintly_id || "",
            spintlyDeviceID: profileData?.sprintly_device || "",
            checkInTheNameOf: profileData?.cheque_pay_to_soc || "",
            currencyName: profileData?.currency_id || "",

            description: profileData?.product_description || "",
            clubTimming: profileData?.club_timimg || "",

            builderName: profileData?.builder_name || "",
            complexType: profileData?.complex_type || "",
            area: profileData?.area || "",
            constructionStatus: profileData?.construction_status || "",
            country: profileData?.country_id || "",
            state: profileData?.state_id || "",
            city: profileData?.city_id || "",
            pinCode: profileData?.pincode || "",

            associationName: profileData?.association_name || "",
            gstNo: profileData?.gst_registration_no || "",
            isRegistered: profileData?.isAssociationRegistered || "",
            projectName: profileData?.project_id || "",
            propertyTaxNo: profileData?.pt_number || "",
            localAuthRegesNo: profileData?.lar_reg_no || "",

            address: profileData?.office_address || "",
            website: profileData?.website || "",
            phone: profileData?.phone
              ? `${profileData?.mobile_country_code}-${profileData?.phone}`
              : "",
            email: profileData?.email || "",
            supportedEmail: profileData?.support_email || "",
            otherDetails: profileData?.other_details || "",

            einvoiceApplicableDate: eInvoice?.applicable_date || "",
            clientId: eInvoice?.client_id || "",
            clientSecret: eInvoice?.client_secret || "",
            eInvoiceUsername: eInvoice?.username || "",
            eInvoicePassword: eInvoice?.password || "",
            gstHeroLogin: eInvoice?.gst_username || "",
            gstHeroPassword: eInvoice?.gst_pass || "",

            gstUsername: taxCreds?.gst_id || "",
            gstPassword: taxCreds?.gst_password || "",
            tdsUsername: taxCreds?.tds_id || "",
            tdsPassword: taxCreds?.tds_password || "",
            einvoiceUsernamecred: taxCreds?.einvoice_id || "",
            einvoicePasswordcred: taxCreds?.einvoice_password || "",
            incomeTaxUsername: taxCreds?.it_id || "",
            incomeTaxPassword: taxCreds?.it_password || "",
          }}
          enableReinitialize
          validationSchema={EditSocietyProfileValidation}
          onSubmit={handleSaveClick}
        >
          {(formik) => {
            return (
              <Form>
                <div className="flex justify-between border-b border-[#CCC] pb-2">
                  <h1 className="text-base font-semibold p-1 items-center">
                    Edit Society Profile
                  </h1>
                  <div className="flex gap-2">
                    <ButtonG
                      type="button"
                      label="Cancel"
                      className="h-8"
                      onClick={handleCancelClick}
                    />
                    <Button
                      label={
                        <span className="w-12 inline-block">
                          {updateLoading ? (
                            <CircularProgress
                              sx={{ color: "white" }}
                              size={17}
                            />
                          ) : (
                            "Update"
                          )}
                        </span>
                      }
                      type="submit"
                      className=" h-8"
                      disabled={loading || !societyProfileData || !formik.dirty}
                    />
                  </div>
                </div>
                {loading && !societyProfileData ? (
                  <Loader />
                ) : (
                  <>
                    <div className="flex justify-between gap-4 p-2">
                      <div className="flex flex-col w-1/2 pr-5">
                        <TextInputFM
                          label="Society Name"
                          placeholder="Enter Society Name"
                          className="w-56"
                          name="societyName"
                          maxLength={60}
                        />
                        <TextInputFM
                          label="Society Name For SMS"
                          placeholder="Enter Society Name For SMS"
                          className="w-56"
                          name="societyNameForSMS"
                          maxLength={60}
                        />
                        {/* <TextInputFM
                            label="Brand Name"
                            placeholder="Enter Full Name"
                            className="w-56"
                            name='brandName'
                            maxLength={60}
                          /> */}
                        <TextInputFM
                          label="Pan Number"
                          placeholder="Enter Pan Number"
                          className="w-56"
                          name="panNumber"
                          type="alphaNumber"
                          maxLength={10}
                        />
                        {/* <TextInputFM
                            label="Pay Pin"
                            placeholder="Enter Pay Pin"
                            className="w-56"
                            name='payPin'
                            type='alphaNumber'
                            maxLength={10}
                          /> */}
                        <TextInputFM
                          label="Tan Number:"
                          placeholder="Enter Tan Number"
                          className="w-56"
                          name="tanNumber"
                          type="alphaNumber"
                          maxLength={10}
                        />
                        <TextInputFM
                          label="Spintly Organisation ID"
                          placeholder="Spintly Organisation ID"
                          className="w-56"
                          name="spintlyOrganisationID"
                        />
                        <TextInputFM
                          label="Cheque In The Name of"
                          placeholder="Enter full name"
                          className="w-56"
                          name="checkInTheNameOf"
                          maxLength={40}
                        />
                      </div>
                      <div className="flex flex-col w-1/2 pr-5">
                        <div className="mt-2">
                          {profileData && (
                            <ProfilePicture
                              defaultURL={profileData?.society_logo}
                              onSelect={(e) => {
                                formik.setFieldValue("profileURL", e);
                              }}
                              onDelete={(e) => {
                                formik.setFieldValue("profileURL", "");
                              }}
                              //edit={getPermission(loginData, [EDIT_PROFILE_PICTURE_PERMISSION])}
                            />
                          )}
                        </div>

                        <div className="mt-[85px]">
                          <TextInputFM
                            label="Spintly Device ID"
                            placeholder="Enter Device ID"
                            className="w-56"
                            name="spintlyDeviceID"
                            type={"alphNumber"}
                            maxLength={10}
                          />
                        </div>

                        <div className="flex justify-between text-sm">
                          <span className="mt-2">Community Currency</span>
                          <DropdownFM2
                            options={
                              currencyList
                                ? currencyList?.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                  }))
                                : []
                            }
                            width="224px"
                            height="37.6px"
                            className="text-xs"
                            placeholder="Select Currency"
                            onSelect={(selectedItem) => {
                              formik.setFieldValue(
                                "currencyName",
                                selectedItem.value
                              );
                            }}
                            value={{
                              label: currencyList?.filter(
                                (item) => item.id === formik.values.currencyName
                              )?.[0]?.name,
                              value: formik.values.currencyName,
                            }}
                            name="currencyName"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="border-b border-[#CCC]"></div>
                    <div className="flex flex-col justify-between gap-4 p-2">
                      <span className="font-semibold text-sm">About</span>
                      <div className="flex">
                        <div className="flex justify-between gap-2 w-1/2 pr-8">
                          <TextAreaFM
                            label="Description"
                            placeholder="Enter detail"
                            className={"w-56 h-20"}
                            name="description"
                            row={5}
                          />
                        </div>
                        <div className="flex flex-col gap-2 w-1/2 pr-5">
                          <div className="flex justify-between">
                            <span className="text-sm mt-2">Club Timming: </span>
                            <TimePicker
                              name={"clubTimming"}
                              value={formik.values.clubTimming}
                              onSelect={(value) => {
                                formik.setFieldValue("clubTimming", value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-b border-[#CCC]"></div>
                    <div className="flex flex-col justify-between gap-4 p-2 pt-5">
                      <span className="font-semibold text-sm">
                        Basic Information
                      </span>
                      <div className="flex">
                        <div className="flex flex-col w-1/2 pr-8">
                          <TextInputFM
                            label="Builder Name"
                            placeholder="Enter Details"
                            className="w-56"
                            name="builderName"
                            maxLength={50}
                          />

                          <TextInputFM
                            label="Constructions Status"
                            placeholder="Enter"
                            className="w-56"
                            name="constructionStatus"
                            maxLength={20}
                          />
                          <div className="flex justify-between">
                            <span className="text-sm mt-2">Country</span>
                            <DropdownFM2
                              options={
                                country?.countryList
                                  ? country?.countryList?.map((item) => ({
                                      label: item.name,
                                      value: item.id,
                                    }))
                                  : []
                              }
                              width="224px"
                              height="37.6px"
                              placeholder="Select Country"
                              className="text-xs"
                              onSelect={(selectedItem) => {
                                formik.setFieldValue(
                                  "country",
                                  selectedItem.value
                                );
                                if (
                                  formik.values.country !== selectedItem.value
                                ) {
                                  formik.setFieldValue("state", "");
                                  formik.setFieldValue("city", "");
                                  dispatch(
                                    getCountryStateCityList({
                                      flag: "state",
                                      country_id: selectedItem.value,
                                    })
                                  );
                                }
                              }}
                              value={{
                                value: formik.values.country,
                                label: country?.countryList?.filter(
                                  (item) => item.id === formik.values.country
                                )?.[0]?.name,
                              }}
                              name="country"
                            />
                          </div>
                          <div className="flex justify-between">
                            <span className="text-sm mt-2">City</span>
                            <DropdownFM2
                              options={
                                formik.values.state && city?.cityList
                                  ? city?.cityList?.map((item) => ({
                                      label: item.name,
                                      value: item.id,
                                    }))
                                  : []
                              }
                              width="224px"
                              height="37.6px"
                              className="text-xs"
                              placeholder="Select City"
                              onSelect={(selectedItem) => {
                                formik.setFieldValue(
                                  "city",
                                  selectedItem.value
                                );
                              }}
                              value={
                                formik.values.city &&
                                city?.cityList?.filter(
                                  (item) => item.id === formik.values.city
                                )?.[0]?.name && {
                                  value: formik.values.city,
                                  label: city?.cityList?.filter(
                                    (item) => item.id === formik.values.city
                                  )?.[0]?.name,
                                }
                              }
                              name="city"
                            />
                          </div>
                        </div>
                        <div className="flex flex-col w-1/2 pr-5">
                          <TextInputFM
                            label="Complex Type"
                            placeholder="Enter Details"
                            className="w-56"
                            name="complexType"
                            maxLength={20}
                          />
                          <TextInputFM
                            label="Area"
                            placeholder="Enter Details"
                            className="w-56"
                            name="area"
                            maxLength={50}
                          />
                          <div className="flex justify-between">
                            <span className="text-sm mt-2">State</span>
                            <DropdownFM2
                              options={
                                state?.stateList
                                  ? state?.stateList?.map((item) => ({
                                      label: item.name,
                                      value: item.id,
                                    }))
                                  : []
                              }
                              width="224px"
                              height="37.6px"
                              className="text-xs"
                              placeholder="Select State"
                              onSelect={(selectedItem) => {
                                formik.setFieldValue(
                                  "state",
                                  selectedItem.value
                                );
                                if (
                                  formik.values.state !== selectedItem.value
                                ) {
                                  formik.setFieldValue("city", "");
                                  dispatch(
                                    getCountryStateCityList({
                                      flag: "city",
                                      state_id: selectedItem.value,
                                    })
                                  );
                                }
                              }}
                              value={
                                formik.values.state &&
                                state?.stateList?.filter(
                                  (item) => item.id === formik.values.state
                                )?.[0]?.name && {
                                  value: formik.values.state,
                                  label: state?.stateList?.filter(
                                    (item) => item.id === formik.values.state
                                  )?.[0]?.name,
                                }
                              }
                              name="state"
                            />
                          </div>
                          <div className="">
                            <TextInputFM
                              label="Pincode"
                              placeholder="Enter Pincode"
                              className="w-56"
                              name="pinCode"
                              type={"number"}
                              maxLength={6}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-b border-[#CCC]"></div>
                    <div className="flex flex-col justify-between gap-4 p-2 pt-5">
                      <span className="font-semibold text-sm">
                        Association Information
                      </span>
                      <div className="flex">
                        <div className="flex flex-col w-1/2 pr-8">
                          <TextInputFM
                            label="Association Name"
                            placeholder="Enter Details"
                            className="w-56"
                            name="associationName"
                            maxLength={75}
                          />
                          <div className="flex justify-between">
                            <span className="text-sm mt-2">Is Registered</span>
                            <DropdownFM2
                              options={isRegisteredOptions}
                              width="224px"
                              height="37.6px"
                              placeholder="Select Option"
                              className="text-xs"
                              onSelect={(selectedItem) => {
                                formik.setFieldValue(
                                  "isRegistered",
                                  selectedItem.value
                                );
                              }}
                              value={
                                profileData?.isAssociationRegistered && {
                                  label: isRegisteredOptions?.filter(
                                    (item) =>
                                      item.value?.toLowerCase() ===
                                      formik.values.isRegistered?.toLowerCase()
                                  )?.[0]?.label,
                                  value: formik.values.isRegistered,
                                }
                              }
                              name="isRegistered"
                            />
                          </div>
                          <TextInputFM
                            label="Local Authority Registration No."
                            placeholder="Enter Details"
                            className="w-56"
                            name="localAuthRegesNo"
                            maxLength={20}
                          />
                        </div>
                        <div className="flex flex-col w-1/2 pr-5">
                          <TextInputFM
                            label="GST Registration No."
                            placeholder="Enter Details"
                            className="w-56"
                            name="gstNo"
                            type="alphaNumber"
                            maxLength={15}
                          />
                          <div className="flex justify-between">
                            <span className="text-sm mt-2">Project Name</span>
                            <DropdownFM2
                              options={
                                projectList
                                  ? projectList?.map((item) => ({
                                      label: item.name,
                                      value: item.id,
                                    }))
                                  : []
                              }
                              width="224px"
                              height="37.6px"
                              placeholder="Select Option"
                              className="text-xs"
                              onSelect={(selectedItem) => {
                                formik.setFieldValue(
                                  "projectName",
                                  selectedItem.value
                                );
                              }}
                              value={
                                profileData?.project_name && {
                                  label: projectList?.filter(
                                    (item) =>
                                      item.id === formik.values.projectName
                                  )?.[0]?.name,
                                  value: formik.values.projectName,
                                }
                              }
                              name="projectName"
                            />
                          </div>
                          <div>
                            <TextInputFM
                              label="Property Tax Number"
                              placeholder="Enter Details"
                              className="w-56"
                              name="propertyTaxNo"
                              type="alphaNumber"
                              maxLength={15}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-b border-[#CCC]"></div>
                    <div className="flex flex-col justify-between gap-4 p-2 pt-5">
                      <span className="font-semibold text-sm">
                        Contact Information
                      </span>
                      <div className="flex">
                        <div className="flex flex-col w-1/2 pr-8">
                          <TextAreaFM
                            label="Address"
                            placeholder="Enter Details"
                            className={"w-56"}
                            name="address"
                            maxLength={100}
                          />
                          <TextInputFM
                            label="Phone"
                            placeholder="Enter Number"
                            className="w-56"
                            name="phone"
                            type="phone"
                            maxLength={16}
                          />
                          <TextInputFM
                            label="Email"
                            placeholder="Enter Email"
                            className="w-56"
                            name="email"
                          />
                          <div className="flex justify-between items-center">
                            <div className="">
                              {/* <TextRadio
                                  label="Check Login to Send SMS"
                                  options={checkOptions}
                                  defaultSelected="yes"
                                  onChange={handleCheckLoginChange}
                                /> */}
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col w-1/2 pr-5">
                          <TextInputFM
                            label="Website"
                            placeholder="Enter Details"
                            className="w-56"
                            name="website"
                          />
                          <TextInputFM
                            label="Other Details"
                            placeholder="Enter Details"
                            className="w-56"
                            name="otherDetails"
                            maxLength={500}
                          />
                          <div>
                            <TextInputFM
                              label="Support Email"
                              placeholder="Enter Email"
                              className="w-56"
                              name="supportedEmail"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="border-b border-[#CCC]"></div>
                    <div className="flex flex-col justify-between gap-4 p-2 pt-5">
                      <span className="font-semibold text-sm">
                        E-Invoice Details
                      </span>
                      <div className="flex">
                        <div className="flex flex-col w-1/2 pr-8">
                          <div className="flex justify-between items-center">
                            <span className="text-sm mb-3">
                              {"E-Invoice Applicable Date:"}
                            </span>
                            <div>
                              <DatePickerComponentFM
                                className="w-56 h-9 ml-4 justify-between"
                                onDateChange={(selectedDate) => {
                                  formik.setFieldValue(
                                    "einvoiceApplicableDate",
                                    selectedDate
                                  );
                                }}
                                name="einvoiceApplicableDate"
                                defaultValue={
                                  formik.values.einvoiceApplicableDate
                                    ? new Date(
                                        formik.values.einvoiceApplicableDate
                                      )
                                    : null
                                }
                              />
                            </div>
                          </div>
                          <TextInputFM
                            label="Client Id"
                            placeholder="Enter Id"
                            className="w-56"
                            name="clientId"
                          />
                          <TextInputFM
                            label="E-Invoice Username"
                            placeholder="Enter UserName"
                            className="w-56"
                            name="eInvoiceUsername"
                          />
                          <TextInputFM
                            label="GST Hero Login"
                            placeholder="Enter Details"
                            className="w-56"
                            name="gstHeroLogin"
                          />
                          <TextInputFM
                            label="GST Username"
                            placeholder="Enter Username"
                            className="w-56"
                            name="gstUsername"
                          />
                          <TextInputFM
                            label="TDS Username"
                            placeholder="Enter Username"
                            className="w-56"
                            name="tdsUsername"
                          />
                          <TextInputFM
                            label="EInvoice Username"
                            placeholder="Enter Username"
                            className="w-56"
                            name="einvoiceUsernamecred"
                          />
                          <TextInputFM
                            label="Income Tax Username"
                            placeholder="Enter Username"
                            className="w-56"
                            name="incomeTaxUsername"
                          />
                        </div>
                        <div className="flex flex-col w-1/2 pr-5">
                          <div className="mt-14">
                            <PasswordInputFM
                              label="Client Secret"
                              placeholder="Enter Secret"
                              className="w-56"
                              name="clientSecret"
                            />
                          </div>
                          <PasswordInputFM
                            label="E-Invoice Password"
                            placeholder="Enter Password"
                            className="w-56"
                            name="eInvoicePassword"
                          />

                          <PasswordInputFM
                            label="GST Hero Password"
                            placeholder="Enter Password"
                            className="w-56"
                            name="gstHeroPassword"
                          />
                          <PasswordInputFM
                            label="GST Password"
                            placeholder="Enter Password"
                            className="w-56"
                            name="gstPassword"
                          />
                          <PasswordInputFM
                            label="TDS Password"
                            placeholder="Enter Password"
                            className="w-56"
                            name="tdsPassword"
                          />
                          <PasswordInputFM
                            label="E Invoice Password"
                            placeholder="Enter Password"
                            className="w-56"
                            name="einvoicePasswordcred"
                          />
                          <PasswordInputFM
                            label="Income Tax Password"
                            placeholder="Enter Password"
                            className="w-56"
                            name="incomeTaxPassword"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    </Layout>
  );
};

export default EditSocietyProfile;
