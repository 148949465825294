import { call, put, takeLatest } from 'redux-saga/effects'
import * as types from '../../actions/index.types'
import * as req from '../../../axios/request/ReportsReqs/FinanceReportsReqs/FinanceReportsReq'
import { notify } from '../../../utils/notification';

function* getDebitNoteInvoiceReports(action) {
    try {
        const result = yield call(req.getDebitNoteInvoiceReportReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_DEBIT_NOTE_INVOICE_REPORTS_SUCCESS, payload: result });
        } else {
            yield put({ type: types.GET_DEBIT_NOTE_INVOICE_REPORTS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* downloadDebitNoteInvoiceReports(action) {
    try {
        const result = yield call(req.downloadDebitNoteInvoiceReportReq, action);
        if (result.success === true) {
            action.data?.onSuccessCallback(result.download_url)
            action.data.download_url = result.download_url;
            yield call(req.deleteFianceReportExcelReq, action);

        } else {
            // yield put({ type: types.GET_INVOICE_REPORTS_FAILURE, message: result?.response?.data?.message });
            notify("Download failed, Please try again!")
        }
    } catch (e) {
        console.log(e)
    }
}

function* getInvoiceReports(action) {
    try {
        const result = yield call(req.getInvoiceReportReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_INVOICE_REPORTS_SUCCESS, payload: result });
        } else {
            yield put({ type: types.GET_INVOICE_REPORTS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* downloadInvoiceReports(action) {
    try {
        const result = yield call(req.downloadInvoiceReportReq, action);
        if (result.success === true) {
            action.data?.onSuccessCallback(result.download_url)
            action.data.download_url = result.download_url;
            yield call(req.deleteFianceReportExcelReq, action);

        } else {
            // yield put({ type: types.GET_INVOICE_REPORTS_FAILURE, message: result?.response?.data?.message });
            notify("Download failed, Please try again!")
        }
    } catch (e) {
        console.log(e)
    }
}

function* getReceiptReports(action) {
    try {
        const result = yield call(req.getReceiptReportReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_RECEIPT_REPORTS_SUCCESS, payload: result });
        } else {
            yield put({ type: types.GET_RECEIPT_REPORTS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* downloadReceiptReports(action) {
    try {
        const result = yield call(req.downloadInvoiceReportReq, action);
        if (result.success === true) {
            action.data?.onSuccessCallback(result.download_url)
            action.data.download_url = result.download_url;
            yield call(req.deleteFianceReportExcelReq, action);

        } else {
            // yield put({ type: types.GET_INVOICE_REPORTS_FAILURE, message: result?.response?.data?.message });
            notify("Download failed, Please try again!")
        }
    } catch (e) {
        console.log(e)
    }
}

function* getSupplementaryBillReports(action) {
    try {
        const result = yield call(req.getSupplementaryBillReportReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_SUPPLEMNTARY_BILL_REPORTS_SUCCESS, payload: result });
        } else {
            yield put({ type: types.GET_SUPPLEMNTARY_BILL_REPORTS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* getDebitNoteReceiptReports(action) {
    try {
        const result = yield call(req.getDebitNoteReceiptReportReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_DEBIT_NOTE_RECEIPT_REPORTS_SUCCESS, payload: result });
        } else {
            yield put({ type: types.GET_DEBIT_NOTE_RECEIPT_REPORTS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* downloadDebitNoteReceiptReports(action) {
    try {
        const result = yield call(req.downloadDebitNoteReceiptReportReq, action);
        if (result.success === true) {
            action.data?.onSuccessCallback(result.download_url)
            action.data.download_url = result.download_url;
            yield call(req.deleteFianceReportExcelReq, action);

        } else {
            notify("Download failed, Please try again!")
        }
    } catch (e) {
        console.log(e)
    }
}

function* getDeletedInvoiceReports(action) {
    try {
        const result = yield call(req.getDeletedInvoiceReportReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_DELETED_INVOICE_REPORTS_SUCCESS, payload: result });
        } else {
            yield put({ type: types.GET_DELETED_INVOICE_REPORTS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* downloadDeletedInvoiceReports(action) {
    try {
        const result = yield call(req.downloadDeletedInvoiceReportReq, action);
        if (result.success === true) {
            action.data?.onSuccessCallback(result.download_url)
            action.data.download_url = result.download_url;
            yield call(req.deleteFianceReportExcelReq, action);

        } else {
            notify("Download failed, Please try again!")
        }
    } catch (e) {
        console.log(e)
    }
}

function* getDeletedDebitNoteReports(action) {
    try {
        const result = yield call(req.getDeletedDebitNoteReportReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_DELETED_DEBIT_NOTE_REPORTS_SUCCESS, payload: result });
        } else {
            yield put({ type: types.GET_DELETED_DEBIT_NOTE_REPORTS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}


function* getInvoiceInterestReports(action) {
    try {
        const result = yield call(req.getInvoiceInterestReportReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_INVOICE_INTEREST_REPORTS_SUCCESS, payload: result });
        } else {
            yield put({ type: types.GET_INVOICE_INTEREST_REPORTS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getPrevInvoiceInterestReports(action) {
    try {
        const result = yield call(req.getPrevInvoiceInterestReportReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_PREV_INVOICE_INTEREST_REPORTS_SUCCESS, payload: result });
        } else {
            yield put({ type: types.GET_PREV_INVOICE_INTEREST_REPORTS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getInterestCalculationReports(action) {
    try {
        const result = yield call(req.getInterestCalculationReportReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_INTEREST_CALCULATION_REPORTS_SUCCESS, payload: result });
        } else {
            yield put({ type: types.GET_INTEREST_CALCULATION_REPORTS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getReconciliationReports(action) {
    try {
        const result = yield call(req.getReconciliationReportReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_RECONCILIATION_REPORTS_SUCCESS, payload: result });
        } else {
            yield put({ type: types.GET_RECONCILIATION_REPORTS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}


function* getDebitnoteInterestCalculationReports(action) {
    try {
        const result = yield call(req.getDebitNoteInterestCalculationReportReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_DEBITNOTE_INTEREST_CALCULATION_REPORTS_SUCCESS, payload: result });
        } else {
            yield put({ type: types.GET_DEBITNOTE_INTEREST_CALCULATION_REPORTS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* downloadDebitnoteInterestCalculationReports(action) {
    try {
        const result = yield call(req.downloadDebitNoteInterestCalculationReportReq, action);
        if (result.success === true) {
            action.data?.onSuccessCallback(result.download_url)
            action.data.download_url = result.download_url;
            yield call(req.deleteFianceReportExcelReq, action);

        } else {
            notify("Download failed, Please try again!")
        }
    } catch (e) {
        console.log(e)
    }
}



export default function* financeReportSaga() {
    yield takeLatest(types.GET_DEBIT_NOTE_INVOICE_REPORTS, getDebitNoteInvoiceReports);
    yield takeLatest(types.DOWNLOAD_DEBIT_NOTE_INVOICE_REPORTS, downloadDebitNoteInvoiceReports);
    yield takeLatest(types.GET_INVOICE_REPORTS, getInvoiceReports);
    yield takeLatest(types.DOWNLOAD_INVOICE_REPORTS, downloadInvoiceReports);
    yield takeLatest(types.GET_RECEIPT_REPORTS, getReceiptReports);
    yield takeLatest(types.DOWNLOAD_RECEIPT_REPORTS, downloadReceiptReports);
    yield takeLatest(types.GET_SUPPLEMNTARY_BILL_REPORTS, getSupplementaryBillReports);
    yield takeLatest(types.GET_DEBIT_NOTE_RECEIPT_REPORTS, getDebitNoteReceiptReports);
    yield takeLatest(types.DOWNLOAD_DEBIT_NOTE_RECEIPT_REPORTS, downloadDebitNoteReceiptReports);
    yield takeLatest(types.GET_DELETED_INVOICE_REPORTS, getDeletedInvoiceReports);
    yield takeLatest(types.DOWNLOAD_DELETED_INVOICE_REPORTS, downloadDeletedInvoiceReports);
    yield takeLatest(types.GET_DELETED_DEBIT_NOTE_REPORTS, getDeletedDebitNoteReports);
    yield takeLatest(types.GET_INVOICE_INTEREST_REPORTS, getInvoiceInterestReports);
    yield takeLatest(types.GET_PREV_INVOICE_INTEREST_REPORTS, getPrevInvoiceInterestReports);
    yield takeLatest(types.GET_PREV_INVOICE_INTEREST_REPORTS, getPrevInvoiceInterestReports);
    yield takeLatest(types.GET_INTEREST_CALCULATION_REPORTS, getInterestCalculationReports);
    yield takeLatest(types.GET_RECONCILIATION_REPORTS, getReconciliationReports);
    yield takeLatest(types.GET_DEBITNOTE_INTEREST_CALCULATION_REPORTS, getDebitnoteInterestCalculationReports);
    yield takeLatest(types.DOWNLOAD_DEBITNOTE_INTEREST_CALCULATION_REPORTS, downloadDebitnoteInterestCalculationReports);

}