import React from "react";
import bgim from "../../assets/icons/zgbg1.png";
import ziplogo from "../../assets/icons/zglogo.svg";
import './Login/Login.css';

const AuthContainer = ({ children }) => {
  return (
    <div className="Container bg-[#F3F4F9]">
      <div className="fixed top-0 left-0 flex items-center justify-center w-[100%] h-[100%]">
        <div className="bg-white rounded-lg shadow-md flex relative w-[1055px] h-[640px]">
          <div className="w-[165%] relative">
            {/* Image */}
            <img
              src={bgim}
              alt="Login"
              className="rounded-lg w-[100%] h-[100%] object-cover"
              loading="lazy"
            />
            {/* Gradient overlay */}
            <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center rounded-ss-lg rounded-es-lg gradientClass">
              {/* SVG Image */}
              <img src={ziplogo} alt="ZipGrid" className="w-40 mb-5" />
              {/* Text */}
              <p className="text-white text-center text-xl font-bold mt-2 opacity-1">
                Simplify Your Society Management
              </p>
              <p className="text-white text-center opacity-1">
                With services designed for your society
              </p>
            </div>
          </div>
          <div className="w-full">
            {children} 
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthContainer;
