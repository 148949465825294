import React, { useEffect } from "react";
import { Link, createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import {
  ADD_FROM_EXISITING_OCCUPANT_ENDPOINT,
  ADD_OCCUPANTS_ENDPOINT,
  ARCHIVE_MEMBERS_OCCUPANTS_ENDPOINT,
  OCCUPANTS_INFO_ENDPOINT,
} from "../../../../Routing/routes";
import Search from "../../../../components/SearchBox/Search";
import { DownloadExt } from "../../../../assets";
import Button from "../../../../components/Button/Button";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import ButtonIco from "../../../../components/Button/ButtonPrint";
import Layout from "../../../Layout/Layout";
import OccupantInfoTable from "../../../../components/TableComponent/OccupantInfoTable";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import { useDispatch, useSelector } from "react-redux";
import { downloadOccupantsDetailsList, getOccupantsDetailsList, getTitleList } from "../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/OccupantsInfoAction";
import { downloadExcel } from "../../../../utils/helpers/downloadMedia";
import moment from "moment";
import { getFlatDetails, getWingsAndFlatDetails } from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import ButtonG from "../../../../components/Button/ButtonG";

const headingsOccupants = [
  { id: 'sNo', label: 'S No.', sort: false, width: '42px' },
  { id: 'flatno', label: 'Flat No.', sort: true,width: '62px' },
  { id: 'title', label: 'Title', sort: true, width:'70px' },
  { id: 'memberName', label: 'Member Name', sort: true, width:'80px' },
  { id: 'type', label: 'Type', sort: true, width: '60px' },
  { id: 'status', label: 'Status', sort: true, width:'82px' },
  { id: 'intercom', label: 'Intercom', sort: true, width:'83px'},
  { id: 'contactNo', label: 'Contact No.', sort: true,width:'110px' },
  { id: 'email', label: 'Email', sort: true, width:'80px' },
  { id: 'liveshereornot', label: 'LivesHere Or Not', sort: true, width: '88px' },
  { id: 'lastTimeActive', label: 'Last Activity Time', sort: true,width:'100px' },
  {
    id: "action",
    label: "Action",
    sort: false,
  },
];


const OccupantsInfo = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {wingsData,flatsData} = useSelector(state=>state.wingsReducer)
  const {loading, titleList, occupantsList} = useSelector(state=>state.occupantInfoReducer)
  const {loginData} = useSelector(state=>state.loginReducer)


  const handleSearch = (text)=>{
    if(text && text !== searchParams.get("search")){
      searchParams.delete('page')
      searchParams.delete('pageLimit')
      searchParams.delete("search")
      navigate({
        pathname: `${OCCUPANTS_INFO_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["search", text?.trim()]])}`,
      })
    }
  }

  const handelSelectTile = (selectedItem)=>{
    if(selectedItem.value?.toString() !== searchParams.get("titleId")){
        searchParams.delete('page')
        searchParams.delete('pageLimit')
        searchParams.delete("titleId")
        navigate({
          pathname: `${OCCUPANTS_INFO_ENDPOINT}`,
          search: `?${createSearchParams([...searchParams.entries(), ["titleId", selectedItem.value]])}`,
        })
    }
  }

  const handelSelectWing = (selectedItem)=>{
    if(selectedItem.value?.toString() !== searchParams.get("wingId")){
      dispatch(getFlatDetails({societyId: loginData?.default_community?.community_id, wingId: selectedItem?.value}))
      searchParams.delete('page')
      searchParams.delete('pageLimit')
      searchParams.delete("wingId")
      searchParams.delete("flatId")
      navigate({
        pathname: `${OCCUPANTS_INFO_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["wingId", selectedItem.value]])}`,
      })
    }
  }

  const handelSelectFlat = (selectedItem)=>{
    if(selectedItem.value?.toString() !== searchParams.get("flatId")){
      searchParams.delete('page')
      searchParams.delete('pageLimit')
      searchParams.delete("flatId")
      navigate({
        pathname: `${OCCUPANTS_INFO_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["flatId", selectedItem.value]])}`,
      })
    }
  }

  const handleDownloadExcel = ()=>{
    const onSucessCallback = (data)=>{
      downloadExcel({data, fileName: 'occupants_sheet.xlsx'})
    }
    const data = {
      societyId: loginData?.default_community?.community_id, 
      searchText: searchParams.get("search") || '',
      titleId: searchParams.get("titleId") || '',
      wingId: searchParams.get("wingId") || '',
      flatId: searchParams.get("flatId") || '',
      onSucessCallback
    }
    dispatch(downloadOccupantsDetailsList(data))
  }

  const handleClear = ()=>{
    navigate(OCCUPANTS_INFO_ENDPOINT)
  }

  const getTableData =() =>{
    const data={
      societyId: loginData?.default_community?.community_id,
      page: searchParams.get("page") || 1,
      searchText: searchParams.get("search") || '',
      pageLimit: searchParams.get("pageLimit") || 10,
      titleId: searchParams.get("titleId") || '',
      wingId: searchParams.get("wingId") || '',
      flatId: searchParams.get("flatId") || '',
    }
    dispatch(getOccupantsDetailsList(data))
  }

  useEffect(()=>{      
    getTableData()
  }, [searchParams])

  useEffect(()=>{
    dispatch(getWingsAndFlatDetails({societyId: loginData?.default_community?.community_id}))
    dispatch(getTitleList({societyId: loginData?.default_community?.community_id}))

    if(searchParams.get("wingId")){
      dispatch(getFlatDetails({societyId: loginData?.default_community?.community_id, wingId: searchParams.get("wingId")}))
    }
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Occupants Info">
            <div className="flex text-sm gap-2">
              <Link
                to={ARCHIVE_MEMBERS_OCCUPANTS_ENDPOINT}
                className="text-white"
              >
                <Button label="Archive Members" className="h-8" />
              </Link>
              <div className="border-r border-[#CCC]"></div>
              <Link to={ADD_OCCUPANTS_ENDPOINT} className="text-white">
                <Button label="Add Occupant" className="h-8" />
              </Link>
              <Link
                to={ADD_FROM_EXISITING_OCCUPANT_ENDPOINT}
                className="text-white"
              >
                <Button label="Add From Existing" className="h-8" />
              </Link>
            </div>
          </ListHeader>
        </div>
        <div className="p-1 flex justify-between gap-2 mt-1 mb-1">
          <Search width="36" height="8" onclick={handleSearch} value={searchParams.get("search")} />
          <div className="flex justify-between items-center gap-2">
            {/* <p className="text-[13px]">Filter</p> */}
            <Dropdown2
              options={titleList?.map((item)=>({
                value: item?.title_id,
                label: item?.title
              })) || []}
              placeholder="Select Title"
              className="text-[11px] ms-4"
              width="180px"
              height="32px"
              onSelect={(selectedItem) => {
                handelSelectTile(selectedItem)
              }}
              value={
                searchParams.get("titleId") && {
                  value: searchParams.get("titleId"),
                  label: titleList?.find((item) => item.title_id?.toString() === searchParams.get("titleId"))?.title
                }
              }
            />
            <Dropdown2
              options={wingsData?.map((item)=>({
                value: item?.wing_id,
                label: item?.number
              })) || []}
              placeholder="Select Wing"
              className="text-[11px] ms-4"
              width="180px"
              height="32px"
              onSelect={(selectedItem) => {
                handelSelectWing(selectedItem)
              }}
              value={
                searchParams.get("wingId") && {
                  value: searchParams.get("wingId"),
                  label: wingsData?.find((item) => item.wing_id?.toString() === searchParams.get("wingId"))?.number
                }
              }
            />
            <Dropdown2
              options={searchParams.get('wingId') && flatsData?.manage_flat_details?.map((item)=>({
                value: item?.flat_id,
                label: item?.flat_no
              })) || []}
              placeholder="Select Flat"
              className="text-[11px] ms-4"
              width="180px"
              height="32px"
              onSelect={(selectedItem) => {
                handelSelectFlat(selectedItem)
              }}
              value={
                searchParams.get("flatId") && {
                  value: searchParams.get("flatId"),
                  label: flatsData?.manage_flat_details?.find((item) => item.flat_id?.toString() === searchParams.get("flatId"))?.flat_no
                }
              }
            />
            <ButtonG label='Clear' className='h-8' onClick={handleClear} />

            <div className="border-r border-[#CCC] h-full"></div>
            <div className="flex gap-2">
              <ButtonIco
                icon={DownloadExt}
                children="Download Excel"
                className="h-8"
                type='button'
                onClick={handleDownloadExcel}
              />
            </div>
          </div>
        </div>
        <OccupantInfoTable
          loading={loading}
          data={occupantsList?.occupants_list?.map((item, index)=>({
            sNo: (((occupantsList?.current_page_no - 1) * occupantsList?.page_limit + 1)+index)?.toString()?.padStart(2, "0"),
            flatno: item?.wing_flat || '-',
            title: item?.title  || '-',
            memberName: item?.subscriber_prefix ? item?.subscriber_prefix + ". " + item?.subscriber_name : item?.subscriber_name,
            type: item?.joint_member && item?.joint_member?.split(',')?.includes(item?.subscriber_id?.toString()) ?'Joint' : 'Main',
            status: item?.status === 'A' ?  'Disapprove': 'Approve',
            intercom: item?.intercom_no || 'N/A',
            contactNo: `+${parseInt(item?.mobile_country_code?.replace('+', '')) ? item?.mobile_country_code?.replace('+', '') : '91'}-${item?.subscriber_number}`  || '-',
            liveshereornot: item?.lives_here  || '-',
            lastTimeActive: item?.last_activity_time ? moment(new Date(item?.last_activity_time))?.format('MMM DD,YYYY hh:mm A') : '-',
            flatId: item?.flat_id,
            userAccId: item?.user_acc_id,
            subscriberId: item?.subscriber_id,
            email: item?.subscriber_email  || '-'
          }))}
          columns={headingsOccupants}
          checkbox={true}
          pagination={occupantsList?.occupants_list?.length}
          totalCount={occupantsList?.total_record}
          totalPages={occupantsList?.total_pages}
          start={(occupantsList?.current_page_no - 1) * occupantsList?.page_limit + 1}
          end={(occupantsList?.current_page_no - 1) * occupantsList?.page_limit + occupantsList?.occupants_list?.length}
          currentPage={occupantsList?.current_page_no}
          rowsPerPage={searchParams.get("pageLimit") || 10}
          getTableData={getTableData}
        ></OccupantInfoTable>
        {!occupantsList?.occupants_list?.length && !loading && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
    </Layout>
  );
};

export default OccupantsInfo;
