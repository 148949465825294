import React from "react";
import "./resetpass.css";
import AuthContainer from "../AuthContainer"; 
import ResetPasswordForm from "./ResetPasswordForm";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const navigate = useNavigate();

  return (
    <AuthContainer>
      <ResetPasswordForm navigate={navigate}/>
    </AuthContainer>
  );
};

export default ResetPassword;
