import React, { useEffect, useState } from "react";

import {
  ApproveIcon,
  ClosedIcon,
  ReappointIcon,
} from "../../assets/index";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { Box, Tooltip } from "@mui/material";
import { APPROVE_RESIGN_ENDPOINT, REAPPOINT_MEMBER_ENDPOINT } from "../../Routing/routes";
import { FieldArray } from "formik";
import { TextInputFM } from "../InputFields/TextInput";
import { DatePickerComponentFM } from "../DatePicker/DatePicker";
import Checkbox from "../Checkbox/Checkbox";
import RejectApproveModal from "../Modal/RejectApproveModal";
import { approveAndRejectCommitteeMemberResignation, approveCommitteeMemberRetirement } from "../../redux/actions/SetupRecordsActions/StaffManagementActions/ManangingCommitteeAction";
import moment from "moment";
import { validateDate } from "../../utils/helpers/universalFunctions";


function ActionComponent({row, committeeId}) {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [rejectResignationModal, setRejectResignationModal] = useState(false);
    const [approveRetirementModal, setApproveRetirementModal] = useState(false);

    const handleApproveRetirement=()=>{
      dispatch(approveCommitteeMemberRetirement({
        memberName:row?.name,
        memberFlat: row?.flat,
        memberEmail: row?.email,
        roleId: parseInt(row.roleId),
        committeeId: parseInt(committeeId),
        onSuccessCallback: ()=>{
          setApproveRetirementModal(false)
        }
      }))
    }

    const handleRejectRetirement=()=>{
      dispatch(approveAndRejectCommitteeMemberResignation({
        roleId: parseInt(row.roleId),
        committeeId: parseInt(committeeId),
        sendEmail: 1,
        isApprove: 'No',
        memberName:row?.name,
        flat: row?.flat,
        email: row?.email,
        onSuccessCallback: ()=>{
          setRejectResignationModal(false)
        }
      }))
    }

    return (
        <Box sx={{ display: "flex", mt:1.5, alignItems: "center", cursor: "pointer"}}>
          {
            row.statusType === 'RES' &&
            <>
            <Tooltip title="Approve Resignation">
              <ApproveIcon
                  className="fill-[#555] hover:fill-red-650 me-2"
                  onClick={() => {navigate(`${APPROVE_RESIGN_ENDPOINT}/${committeeId}/${row.roleId}`)}}
              />
            </Tooltip>
            <Tooltip title="Reject Resignation">
              <ClosedIcon
                  className="fill-[#555] hover:fill-red-650 me-2"
                  onClick={() => {setRejectResignationModal(true)}}
              />
            </Tooltip>
            </>
          }

          {
            row.statusType === 'RET' &&
            <>
            <Tooltip title="Reappoint Member">
              <ReappointIcon
                  className="fill-[#555] hover:fill-red-650 me-2"
                  onClick={() => {navigate(`${REAPPOINT_MEMBER_ENDPOINT}/${committeeId}/${row.roleId}`)}}
              />
            </Tooltip>
            <Tooltip title="Approve Retirement">
              <ApproveIcon
                  className="fill-[#555] hover:fill-red-650 me-2"
                  onClick={() => {setApproveRetirementModal(true)}}
              />
            </Tooltip>
            </>
          }

          <RejectApproveModal
            isOpen={rejectResignationModal}
            title={'Reject Resignation'}
            onCancel={()=> setRejectResignationModal(false)}
            text={'Do you want to reject resignation ?'}
            onConfirm={handleRejectRetirement}
          />

          <RejectApproveModal
            isOpen={approveRetirementModal}
            title={'Approve Retirement'}
            onCancel={()=> setApproveRetirementModal(false)}
            text={'Do you want to approve retirement ?'}
            onConfirm={handleApproveRetirement}
          />
        </Box>
    );
  }

const EditManagingCommitteeTable = ({
  loading,
  columns,
  checkbox = true,

  getTableData,

  values, 
  setFieldValue

}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [selected, setSelected] = useState([])
  const { occupantsFormanagingCommittee} = useSelector(state=> state.managingCommitteeReducer)

  const toggleSelectAll = (e) => {
    let newList = values?.selectedOccupants?.flatMap((item) => ({...item, selected: e.target.checked}));
    setFieldValue('selectedOccupants', newList)
  };

  const checkedInputData = (id) => {
    return values.selectedOccupants?.some(obj =>obj.memberId===id && obj.selected)
  };

  const handleCheckedData = (e, index) => {
    if(values.selectedOccupants?.[index]?.status)
      setFieldValue(`selectedOccupants.${index}.status`, e.target.checked ? 'A' : 'D')
    setFieldValue(`selectedOccupants.${index}.selected`, e.target.checked ? true : false)
  };


  const isSelected = (id) => selected.includes(id);


  useEffect(()=>{      
    setSelected([])
  }, [searchParams])

  return (
    <>
      <table className="table-auto w-full">
        <thead>
          <tr className="border-b border-t border-[#CCCCCC] whitespace-nowrap text-[13px] text-[#AAAAAA] text-left">
            {!!checkbox && (
              <td className="w-[35px] px-2 py-2">
                <Checkbox
                  initialchecked={values.selectedOccupants?.filter(item => item.selected)?.length === occupantsFormanagingCommittee?.length}
                  onChange={toggleSelectAll}
                />
              </td>
            )}
            {columns?.map((column) => (
              <td className={`px-3 w-[${column.width}]`} >{column.label}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {
              loading ?
              <tr>
                <td colSpan={columns?.length+1}><Loader/></td>
              </tr>
              :
              <>
                {<FieldArray
                    name="selectedOccupants"
                    render={arrayHelpers => (
                      <>
                        {values.selectedOccupants && values.selectedOccupants.length > 0 && (
                          values.selectedOccupants.map((row, rowIndex) => {
                            const isRowSelected = isSelected(row?.memberId);
                            return (
                            <tr className={`align-top ${rowIndex%2 === 0 ? '': 'bg-[#F4F5F8]'}`}
                              key={`${row?.userAccId}+${row?.flatId}+${rowIndex}`}
                              selected={isRowSelected}
                            >
                              {!!checkbox && (
                                <td className="px-2 pt-2">
                                  <Checkbox
                                    initialchecked={checkedInputData(row.memberId)}
                                    onChange={(e) => handleCheckedData(e, rowIndex)}
                                  />
                                </td>
                              )}

                              <td className="px-2 pt-2 capitalize text-[13px] font-normal">
                              {`${row.name} (${row.flat})`}
                              </td>

                              <td className="px-2 pt-2">
                              <TextInputFM
                                label=""
                                placeholder="Enter Detail"
                                className="w-40 h-9"
                                name={`selectedOccupants.${rowIndex}.roles`}
                              />
                              </td>

                              <td className="px-2 pt-2">
                                <DatePickerComponentFM
                                  className="h-9" 
                                  name={`selectedOccupants.${rowIndex}.joinDate`}
                                  onDateChange={(selectedDate) => {
                                    setFieldValue(`selectedOccupants.${rowIndex}.joinDate`, selectedDate)
                                    if(validateDate(values.selectedOccupants?.[rowIndex]?.leaveDate) && moment(values.selectedOccupants?.[rowIndex]?.leaveDate).isBefore(new Date(selectedDate)))
                                      setFieldValue(`selectedOccupants.${rowIndex}.leaveDate`, '')
                                  }}
                                  defaultValue={values.selectedOccupants?.[rowIndex]?.joinDate ? new Date(values.selectedOccupants?.[rowIndex]?.joinDate) : null}
                                />
                              </td>

                              <td className="px-2 pt-2">
                                <DatePickerComponentFM  
                                  className="h-9" 
                                  name={`selectedOccupants.${rowIndex}.leaveDate`}
                                  onDateChange={(selectedDate) => {
                                    setFieldValue(`selectedOccupants.${rowIndex}.leaveDate`, selectedDate)
                                  }}
                                  defaultValue={values.selectedOccupants?.[rowIndex]?.leaveDate ? new Date(values.selectedOccupants?.[rowIndex]?.leaveDate) : null}
                                  readOnly={!values.selectedOccupants?.[rowIndex]?.joinDate}
                                  {...(values.selectedOccupants?.[rowIndex]?.joinDate && {minDate: new Date(moment(values.selectedOccupants?.[rowIndex]?.joinDate).add(1, 'days'))})}
                                  />
                              </td>

                              <td className="px-2 pt-2 text-[13px]">
                                <div className={`${row.statusType === 'No' && !(row?.leaveDate && moment(row?.leaveDate)?.isBefore(new Date())) ? 'text-green-500 ' : 'text-red-500'} mt-2 font-semibold underline`}>
                                    {
                                      row.statusType ?
                                      (row.statusType === 'RES' ? 'Resigned' : (row.statusType === 'RET' ? 'Retired' : (row?.leaveDate && moment(row?.leaveDate)?.isBefore(new Date()) ? 'Not Working' : 'Working')) || '-')
                                      :
                                      null
                                    }
                                </div>
                              </td>

                              <td className="px-2 pt-2">
                                <ActionComponent 
                                  getTableData={getTableData}
                                  row={row} 
                                  committeeId={values.committeeId}
                                />
                              </td>
                            </tr>
                            )
                          }
                        ))}
                      </>
                    )}>

                    </FieldArray>
                  }
              </>
            }
        </tbody>
      </table>
    </>
  );
};

export default EditManagingCommitteeTable;
