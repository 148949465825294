import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TextInputFM } from "../../../../../components/InputFields/TextInput";
import Layout from "../../../../Layout/Layout";
import Button from "../../../../../components/Button/Button";
import ButtonG from "../../../../../components/Button/ButtonG";
import { AGENCY_ENDPOINT } from "../../../../../Routing/routes";
import {TextAreaFM } from "../../../../../components/InputFields/TextArea";
import ListHeader from "../../../../../components/ListHeader/ListHeader";
import { Form, Formik } from "formik";
import { AddUpdateAgencyValidation } from "../../../../../validationSchema/setupRecordsSchema/staffManagementSchema";
import { DropdownFM2 } from "../../../../../components/Dropdown/dropdown2";
import { useDispatch } from "react-redux";
import { addUpdatAgency, getAgencyCategoryList } from "../../../../../redux/actions/SetupRecordsActions/StaffManagementActions/MaintenanceAgencyAction";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import countries from "../../../../../consts/countries";

const AddAgency = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {updateLoader, agencyCategoryList} = useSelector(state=>state.agencyReducer)
  const handleCancelClick = () => {
    navigate(AGENCY_ENDPOINT);
  };

  const handleSaveClick = (values, {resetForm}) => {
    dispatch(addUpdatAgency({
      ...values, 
      societyId: loginData?.default_community?.community_id,
      onSuccessCallback: ()=>{
        resetForm()
      }
    }))
  };

  useEffect(()=>{
    dispatch(getAgencyCategoryList())
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
      <Formik
          initialValues={{
            categoryId: '',
            agencyName: '',
            officeAddress: '',
            panCardNo: '',
            tanNo: '',
            concernedPersonName: '',
            otherDetails: '',
            mobileCountryCode: '',
            countryISO: '',
            mobileNo: ''
          }}
          onSubmit={handleSaveClick}
          validationSchema={AddUpdateAgencyValidation}
          >    
          {
            ({values, errors, setFieldValue})=>{
              return (
                <Form>
                  <div className="border-b border-[#CCC]">
                    <ListHeader leftContent="Add Agency">
                      <ButtonG
                        label="Cancel"
                        onClick={handleCancelClick}
                        type='button'
                        className="h-8"
                      />
                      <Button label={
                            <span className="w-12 inline-block">
                              {
                                updateLoader ?
                                  <CircularProgress sx={{color: "white"}} size={17}/>
                                  :
                                  'Add'
                                }
                            </span>
                            }  className=" h-8 px-4" type='submit'
                            disabled={updateLoader}
                          />
                    </ListHeader>
                  </div>
                  <div className="flex gap-4 p-2">
                    <div className="flex flex-row justify-between w-full gap-7">
                      <div className="flex flex-col w-1/2">
                        <div className="flex items-center justify-between">
                          <span className="text-sm">Category Of Service</span>
                          <DropdownFM2
                            options={agencyCategoryList?.map((item)=>({
                              value: item?.agency_cat_id,
                              label: item?.cat_name
                            })) || []}
                            width={"224px"}
                            className="text-xs"
                            placeholder={"Select Category"}
                            onSelect={(selectedItem)=>{
                              setFieldValue(`categoryId`, selectedItem.value)
                            }}
                            height='38px'
                            name={`categoryId`}
                            {...(values.categoryId ==='' && {value: null})}
                          />
                        </div>
                        <TextInputFM
                          label="Agency Name"
                          placeholder="Enter Agency Name"
                          className="w-56"
                          name='agencyName'
                          maxLength={80}
                        />
                        <div className="w-full">
                          <TextAreaFM
                            label="Office Address"
                            placeholder="Enter Address"
                            className={"w-56 h-[75px]"}
                            name='officeAddress'
                            maxLength={200}
                          />
                        </div>
                        <TextInputFM
                          label="Pan Card"
                          placeholder="Enter Pan Number"
                          className="w-56"
                          name='panCardNo'
                          type='alphaNumber'
                          maxLength={10}
                        />
                        <TextInputFM
                          label="Tan Card"
                          placeholder="Enter Tan Number"
                          className="w-56"
                          name='tanNo'
                          type='alphaNumber'
                          maxLength={10}
                        />
                        <TextInputFM
                          label="Concerned Person Name"
                          placeholder="Enter Name"
                          className="w-56"
                          name='concernedPersonName'
                          maxLength={60}
                        />
                        <div className="w-full mb-4">
                          <TextAreaFM
                            label="Other Details (Optional)"
                            placeholder="Enter Details"
                            className={"w-56  h-[75px]"}
                            name='otherDetails'
                            maxLength={500}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col w-1/2 ">
                        <div className="mt-[205px]">
                        <div className="flex  justify-between text-sm mt-[55px]">
                          <span className="mt-2">Country</span>
                          <DropdownFM2
                            options={ countries?.map((item)=>({
                              label: `${item?.name?.toUpperCase()} (${item?.phonecode})`,
                              value: item?.phonecode?.replace("+",""),
                              iso: item.iso,
                              id: item?.id
                            })) || []}
                            width={"224px"}
                            className="text-xs"
                            placeholder={"Select Country"}
                            onSelect={(selectedItem)=>{
                              setFieldValue(`mobileCountryCode`, selectedItem.value)
                              setFieldValue(`countryId`, selectedItem.id)
                              setFieldValue(`countryISO`, selectedItem.iso)
                            }}
                            height='38px'
                            name={`mobileCountryCode`}
                            value={values.mobileCountryCode==='' ? null :
                              {
                                label: `${countries?.find(item=> item.phonecode?.replace('+','') === values.mobileCountryCode)?.name?.toUpperCase()} (+${values.mobileCountryCode})`,
                                value: values.mobileCountryCode,
                                iso: countries?.find(item=> item.phonecode?.replace('+','') === values.mobileCountryCode)?.iso,
                                id: values.countryId
                              }
                            }
                          />
                        </div>
                          <TextInputFM
                            label="Mobile Number"
                            placeholder="Enter Mobile Number"
                            className="w-56"
                            name='mobileNo'
                            type='phone'
                            maxLength={17}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )
            }
          }
      </Formik>
      </div>
    </Layout>
  );
};

export default AddAgency;
