import React, { useEffect, useRef } from "react";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { EMPLOYEE_ENDPOINT, EXISTING_EMPLOYEE_ENDPOINT } from "../../../../Routing/routes";
import Layout from "../../../Layout/Layout";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Search from "../../../../components/SearchBox/Search";
import ButtonG from "../../../../components/Button/ButtonG";
import Button from "../../../../components/Button/Button";
import {addFromExistingEmployee, getEmployeeTypesList, getExistingEmployee} from '../../../../redux/actions/SetupRecordsActions/StaffManagementActions/EmployeeManagementAction'
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Form, Formik } from "formik";
import { AddFromExistingEmployeeValidation } from "../../../../validationSchema/setupRecordsSchema/staffManagementSchema";
import Loader from "../../../../components/Loader/Loader";
import { DropdownFM2 } from "../../../../components/Dropdown/dropdown2";
import { CircularProgress } from "@mui/material";

const ExisitingEmployeeScreen = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  const formRef = useRef();
  const {loading, updateLoader, existingEmployeeList, employeeTypeList} = useSelector(state=>state.employeeManagementReducer)
  const {loginData} = useSelector(state=>state.loginReducer)

  const handleCancelClick = () => {
    navigate(EMPLOYEE_ENDPOINT);
  };

  const handleSearch = (text)=>{
    if(text){
      navigate({
        pathname: `${EXISTING_EMPLOYEE_ENDPOINT}`,
        search: `?${createSearchParams([["search", text?.trim()]])}`,
      }, { replace: true })
    }
  }

  const handleSaveClick = (values, {resetForm}) => {
    dispatch(addFromExistingEmployee({
      ...values,
      societyId: loginData?.default_community?.community_id,
      onSuccessCallback: ()=>{
        resetForm()
      }
    }))
  };

  const getTableData =() =>{
    const data={
      searchText: searchParams.get("search") || ''
    }
    dispatch(getExistingEmployee(data))
  }

  useEffect(()=>{      
    getTableData()
  }, [searchParams])

  useEffect(()=>{
    dispatch(getEmployeeTypesList({societyId: loginData?.default_community?.community_id}))
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 mb-5">
      <Formik
          initialValues={{
            employeeId: existingEmployeeList?.emp_id,
            employeeTypeId: '',
          }}
          enableReinitialize
          innerRef={(f) => (formRef.current = f)}
          onSubmit={handleSaveClick}
          validationSchema={AddFromExistingEmployeeValidation}
          >    
            {
              ({values, errors, setFieldValue})=>{
                return (
                  <Form>
                    <div className="border-b border-[#CCC]">
                      <ListHeader leftContent="Add Employee From Existing">
                        <div className="flex gap-3">
                          <Search placeholder='Search by email or name' onclick={handleSearch} value={searchParams.get("search")}/>
                          <div className="border-r border-[#CCC]"></div>
                          <ButtonG label="Cancel" type='button' onClick={handleCancelClick} />
                          <Button label={
                            <span className="w-12 inline-block">
                              {
                                updateLoader ?
                                  <CircularProgress sx={{color: "white"}} size={17}/>
                                  :
                                  'Save'
                                }
                            </span>
                            }  type='button' onClick={()=>formRef.current?.submitForm()} className="px-4" />
                        </div>
                      </ListHeader>
                    </div>
                    <div>
                      {
                        loading && searchParams.get("search") ?
                        <Loader/>
                        :
                        <>
                          {
                            existingEmployeeList && searchParams.get("search") ?
                            <div className="ms-5 mt-5 w-[400px]">
                              <div className="flex text-sm mb-5">
                                <div className="w-48">Name</div>
                                <div className="capitalize">{existingEmployeeList?.name}</div>
                              </div>
                              <div className="flex  justify-between text-sm mb-1">
                                <div className="mt-2 w-48">Employee Type</div>
                                <DropdownFM2
                                  options={employeeTypeList?.map((item)=>({
                                    value: item?.emp_type_id,
                                    label: item?.type
                                  })) || []}
                                  width={"208px"}
                                  className="text-xs"
                                  placeholder={"Select Employee Type"}
                                  onSelect={(selectedItem)=>{
                                    setFieldValue(`employeeTypeId`, selectedItem.value)
                                  }}
                                  height='38px'
                                  name={`employeeTypeId`}
                                  {...(values.employeeTypeId==='' && {value: null})}
                                />
                              </div>
                              
                            </div>
                            :
                            <div className="text-center py-4 text-[#999999] flex justify-center">
                              No Records
                            </div>
                          }

                        </>
                      }
                    </div>
                    </Form>
                )}
              }
            </Formik>
      </div>
    </Layout>
  );
};

export default ExisitingEmployeeScreen;
