import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import InvoiceStructure from "./InvoiceStructure";
import { EditPaymentOptions } from "./PaymentOptions";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getBookingInvoice } from "../../../redux/actions/ClubActions/ViewBookingsAction";
import TextRadio from "../../../components/InputFields/TextRadio";
import Loader from "../../../components/Loader/Loader";


function EditPayment() {
  const data = useLocation().state;
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, updateLoader, invoiceDetails } = useSelector(
    (state) => state.viewBookingReducer
  );
  const { loginData } = useSelector((state) => state.loginReducer);
  const [repaymentMethod, setRepaymentMethod] = useState("Other");
  const handleRepaymentMethodChange = (value) => {
    setRepaymentMethod(value);
  };

  useEffect(() => {
    dispatch(
      getBookingInvoice({
        invoiceId: id,
        payid: data,
        societyId: loginData?.default_community?.community_id,
      })
    );
  }, []);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack
            title="Edit Payment"
            onClick={() => {
              navigate(-1);
            }}
          ></ListHeaderBack>
        </div>
        {loading ? (
          <Loader />
        ) : (
        <div className="gap-2 py-1 my-1">
          <InvoiceStructure
            invoiceDetails={invoiceDetails}
            loginData={loginData}
            loading={loading}
          />
          <div>
          <RepaymentOptions
            repaymentMethod ={repaymentMethod}
            setRepaymentMethod ={setRepaymentMethod}
            />
          </div>
          <div>
            <EditPaymentOptions
              invid={id}
              invoiceDetails={invoiceDetails}
              loginData={loginData}
              loading={loading}
              updateLoader={updateLoader}
              payid={data}
              repaymentMethod={repaymentMethod}
            />
          </div>
        </div>
        )}
      </div>
    </Layout>
  );
}

  export const RepaymentOptions = ({repaymentMethod,setRepaymentMethod}) => {

    const repaymentOptions = [
      { name: "paymentReason", value: "Cheque Dishonour", label: "Cheque Dishonour" },
      { name: "paymentReason", value: "Other", label: "Other" }
      // { name: "paymentDetails", value: "Repayment", label: "Repayment" },
      
    ];
  
    return (
      <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg mt-3">
        <div className=" border-b border-[#CCC] rounded-tl-lg rounded-tr-lg pb-2 bg-[#FDF2F3] flex justify-between">
          <h1 className="items-center p-2 -mb-2 text-base font-semibold">
            Reason
          </h1>
        </div>
        <div className="p-2">
          <div className="flex items-center gap-3 mr-40">
            <TextRadio
              label=""
              options={repaymentOptions}
              defaultSelected={repaymentMethod}
              onChange={setRepaymentMethod}
              className={"w-52 whitespace-nowrap"}
            />
            {/* <Checkbox
              text=" Repayment"
              className="text-sm"
              
              // initialchecked={
              // repayment
              // invoiceGenerator[item]?.sendemailnotification === "Yes"
              //   ? true
              //   : false
              // }
              // onChange={(e) => {
              //   setInvoiceData((prev) => {
              //     let newData = { ...prev };
              //     newData[item].sendemailnotification = e.target.checked
              //       ? "Yes"
              //       : "No";
              //     return newData;
              //   });
              // }}
            />
  
            <Checkbox
              text="Cheque Dishonour"
              className="text-sm"
              // initialchecked={resonType}
            />
            <Checkbox
              text="Other"
              className="text-sm"
              // initialchecked={resonType}
            /> */}
          </div>
        </div>
      </div>
    );
  };
  
  export default EditPayment;
  