import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ButtonG from "../../../../../components/Button/ButtonG";
import Button from "../../../../../components/Button/Button";
import { TextInputFM } from "../../../../../components/InputFields/TextInput";
import { DatePickerComponentFM } from "../../../../../components/DatePicker/DatePicker";
import Layout from "../../../../Layout/Layout";
import { TextAreaFM } from "../../../../../components/InputFields/TextArea";
import ListHeader from "../../../../../components/ListHeader/ListHeader";
import { DropdownFM2 } from "../../../../../components/Dropdown/dropdown2";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import { DeleteIcon } from "../../../../../assets";
import { FieldArray, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  addFamilyMember,
  getOccupantDetails,
} from "../../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/OccupantsInfoAction";
import { CircularProgress } from "@mui/material";
import { FamilyMemberValidation } from "../../../../../validationSchema/setupRecordsSchema/occupants&TenantsSchema";
import countries from "../../../../../consts/countries";
import moment from "moment";
import { genderOptions } from "../../../../../consts/DropDownOptions";
import { getFamilyRolesList } from "../../../../../redux/actions/SetupRecordsActions/ManageRolesAndRightsActions/ManageRolesAndRightsAction";

const AddFamilyMember = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, userId, flatId } = useParams();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, updateLoader, occupantDetail } = useSelector(
    (state) => state.occupantInfoReducer
  );
  const {familyRoleList} = useSelector(state=>state.manageRoleAndRightsReducer)

  const handleCancelClick = () => {
    navigate(-1);
  };

  const handleSaveClick = async (values, { resetForm, initialValues }) => {
    dispatch(
      addFamilyMember({
        societyId: loginData?.default_community?.community_id,
        ...values,
        flatId: flatId,
        onSuccessCallback: () => {
            navigate(-1)
          },
      })
    );
  };

  const handleAddMoreClick = (arrayHelpers) => {
    arrayHelpers.push({
      isFamilyLogin: 0,
      name: "",
      age: "",
      emailAddress: "",
      gender: "",
      relationship: "",
      country: "",
      mobileNo: "",
      profession: "",
      dateOfBirth: "",
      anniversaryDate: "",
      bloodGroup: "",
      otherDetails: "",
    });
  };

  useEffect(() => {
    dispatch(
      getOccupantDetails({
        subscribeId: id,
        societyId: loginData?.default_community?.community_id,
        userType: loginData?.user_type,
        userId: userId,
      })
    );
    dispatch(getFamilyRolesList({societyId: loginData?.default_community?.community_id}))

  }, []);

  return (
    <Layout>
      <div className="flex gap-2 w-full">
        <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg p-2">
          <Formik
            initialValues={{
              familyMembers: [
                {
                  role: '',
                  isFamilyLogin: 0,
                  name: "",
                  age: "",
                  emailAddress: "",
                  gender: "",
                  relationship: "",
                  mobileCountryCode: '',
                  countryISO: "",
                  mobileNo: "",
                  profession: "",
                  dateOfBirth: "",
                  anniversaryDate: "",
                  bloodGroup: "",
                  otherDetails: "",
                },
              ],
            }}
            enableReinitialize
            onSubmit={handleSaveClick}
            validationSchema={FamilyMemberValidation}
          >
            {(formik) => {
              return (
                <Form>
                  <div className="border-b border-[#CCC]">
                    <ListHeader
                      leftContent={
                        occupantDetail &&
                        `${
                          occupantDetail?.user_detail?.prefix +
                          ". " +
                          occupantDetail?.user_detail?.f_name +
                          " " +
                          occupantDetail?.user_detail?.m_name +
                          " " +
                          occupantDetail?.user_detail?.l_name
                        }
                    (${
                      occupantDetail?.flat_data?.find(
                        (item) => item.flat_id?.toString() === flatId
                      )?.flat_no
                    }) >>> Add Family Member`
                      }
                    >
                      <ButtonG
                        label="Cancel"
                        onClick={handleCancelClick}
                        className="h-8 w-16"
                        type={`button`}
                      />
                      <Button
                        label={
                          <span className="w-12 inline-block">
                            {updateLoader ? (
                              <CircularProgress
                                sx={{ color: "white" }}
                                size={17}
                              />
                            ) : (
                              "Save"
                            )}
                          </span>
                        }
                        className="px-3 h-8"
                        type="submit"
                      />
                    </ListHeader>
                  </div>
                  <div className="flex-wrap flex gap-2 w-full">
                    <FieldArray
                      name="familyMembers"
                      render={(arrayHelpers) => (
                        <>
                          {formik.values.familyMembers &&
                            formik.values.familyMembers.length > 0 &&
                            formik.values.familyMembers.map((item, index) => (
                              <div className="border rounded-lg mt-2 w-[49%]">
                                <div className="flex justify-between items-center bg-[#FDF2F3] border-b border-[#CCC] py-2 px-2">
                                  <div className="">
                                    Family Member: {index + 1}
                                  </div>
                                  {
                                    formik.values.familyMembers?.length>1 &&
                                    <DeleteIcon className="fill-red-650 cursor-pointer" onClick={()=>{arrayHelpers.remove(index)}} />
                                  }
                                </div>
                                <div key={index} className="flex gap-4 p-2">
                                  <div className="flex flex-col justify-between w-1/2 gap-3">
                                    {/* Role Dropdown */}
                                    <div className="flex justify-between w-full gap-3">
                                      <div className=" gap-2 pr-5 mt-3">
                                        <div className="flex justify-between">
                                          <span className="text-sm mt-2">
                                            Select Role
                                          </span>
                                          <DropdownFM2
                                            options={familyRoleList?.map((item)=>({
                                              value: item.family_role_id,
                                              label: item.title
                                            })) || []}
                                            name={`familyMembers.${index}.role`}
                                            placeholder="Select"
                                            width="224px"
                                            height="36px"
                                            onSelect={(selectedItem)=> formik.setFieldValue(`familyMembers.${index}.role`, selectedItem.value)}
                                            {...(formik.values.familyMembers[index].role ==="" && {value: null})}
                                          />
                                        </div>
                                        {/* Is Family Login Checkbox */}
                                        <div className="flex justify-between items-center mb-4">
                                          <span className="text-sm w-56 align-top">
                                            Is Family Login?
                                          </span>
                                          <div className="w-56">
                                            <Checkbox
                                              text="Yes"
                                              initialchecked={formik.values.familyMembers[index].isFamilyLogin ===1? true: false}
                                              onChange={(isChecked) =>
                                                formik.setFieldValue(
                                                  `familyMembers.${index}.isFamilyLogin`,
                                                  formik.values.familyMembers[index].isFamilyLogin === 1
                                                    ? 0
                                                    : 1
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                        <TextInputFM
                                          label="Name"
                                          placeholder="Enter Name"
                                          className="w-56"
                                          name={`familyMembers.${index}.name`}
                                        />
                                        <TextInputFM
                                          label="Age"
                                          placeholder="Enter Age"
                                          className="w-56"
                                          type={`number`}
                                          maxLength={3}
                                          name={`familyMembers.${index}.age`}
                                        />
                                        <TextInputFM
                                          label="Email Address"
                                          placeholder="Enter Email"
                                          className="w-56"
                                          name={`familyMembers.${index}.emailAddress`}
                                        />
                                        <div className="flex justify-between">
                                          <span className="text-sm mt-2">
                                            Gender
                                          </span>
                                          <DropdownFM2
                                            options={genderOptions}
                                            width={"224px"}
                                            className="text-xs"
                                            placeholder="Select Gender"
                                            onSelect={(selectedItem) => {
                                              formik.setFieldValue(
                                                `familyMembers.${index}.gender`,
                                                selectedItem.label
                                              );
                                            }}
                                            height="38px"
                                            name={`familyMembers.${index}.gender`}
                                            {...(formik.values.familyMembers[index].gender ==="" && {value: null})}
                                          />
                                        </div>
                                        <TextInputFM
                                          label="Relationship"
                                          placeholder="Enter "
                                          className="w-56"
                                          name={`familyMembers.${index}.relationship`}
                                        />
                                        <div className="flex justify-between">
                                          <span className="text-sm mt-2">
                                            Country
                                          </span>
                                          <DropdownFM2
                                            options={
                                              countries?.map((item) => ({
                                                label: `${item?.name?.toUpperCase()} (${
                                                  item?.phonecode
                                                })`,
                                                value: item?.phonecode?.replace(
                                                  "+",
                                                  ""
                                                ),
                                                iso: item.iso,
                                                id: item?.id,
                                              })) || []
                                            }
                                            width={"224px"}
                                            className="text-xs"
                                            placeholder={"Select Country"}
                                            onSelect={(selectedItem) => {
                                              formik.setFieldValue(
                                                `familyMembers.${index}.mobileCountryCode`,
                                                selectedItem.value
                                              );
                                              formik.setFieldValue(
                                                `familyMembers.${index}.countryISO`,
                                                selectedItem.iso
                                              );
                                            }}
                                            height="38px"
                                            name={`familyMembers.${index}.mobileCountryCode`}
                                            {...(formik.values.familyMembers[index].mobileCountryCode === "" && {
                                              value: null,
                                            })}
                                          />
                                        </div>
                                        <TextInputFM
                                          label="Contact No."
                                          placeholder="Enter Number"
                                          className="w-56"
                                          type={`number`}
                                          name={`familyMembers.${index}.mobileNo`}
                                        />
                                        <TextInputFM
                                          name={`familyMembers.${index}.profession`}
                                          label="Profession"
                                          placeholder="Enter Profession"
                                          className="w-56"
                                        />
                                        <div className="flex justify-between text-sm">
                                          <span className="mt-2">Date of Birth</span>
                                          <DatePickerComponentFM
                                            className="w-56 h-9 justify-between"
                                            onDateChange={(selectedDate) =>
                                              formik.setFieldValue(
                                                `familyMembers.${index}.dateOfBirth`,
                                                moment(
                                                  new Date(selectedDate)
                                                ).format("YYYY-MM-DD")
                                              )
                                            }
                                            name={`familyMembers.${index}.dateOfBirth`}
                                            defaultValue={
                                              formik.values?.familyMembers[index]?.dateOfBirth
                                                ? new Date(
                                                    formik.values?.familyMembers[index]?.dateOfBirth
                                                  )
                                                : null
                                            }
                                            maxDate={new Date()}
                                          />
                                        </div>
                                        <div className="flex justify-between text-sm">
                                          <span className='mt-2'>Anniversary Date</span>
                                          <DatePickerComponentFM
                                            className="w-56 h-9 justify-between"
                                            onDateChange={(selectedDate) =>
                                              formik.setFieldValue(
                                                `familyMembers.${index}.anniversaryDate`,
                                                moment(
                                                  new Date(selectedDate)
                                                ).format("YYYY-MM-DD")
                                              )
                                            }
                                            name={`familyMembers.${index}.anniversaryDate`}
                                            defaultValue={
                                              formik.values?.familyMembers[
                                                index
                                              ]?.anniversaryDate
                                                ? new Date(
                                                    formik.values?.familyMembers[
                                                      index
                                                    ]?.anniversaryDate
                                                  )
                                                : null
                                            }
                                            maxDate={new Date()}
                                          />
                                        </div>
                                        <TextInputFM
                                          label="Blood Group"
                                          placeholder="Enter Blood Group"
                                          className="w-56"
                                          name={`familyMembers.${index}.bloodGroup`}
                                        />
                                        <div>
                                          <TextAreaFM
                                            label="Other Details"
                                            placeholder="Enter Details"
                                            className="w-56 h-20"
                                            name={`familyMembers.${index}.otherDetails`}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          <div className="flex w-[49%] mt-4">
                            <button
                              type="button"
                              label="Add More"
                              onClick={() => {
                                handleAddMoreClick(arrayHelpers);
                              }}
                              className="text-red-650 font-semibold border-2 border-dashed border-red-650 w-full h-[155px] p-2 bg-[#FDF6F6] flex justify-center rounded-md items-center"
                            >
                              Add More
                            </button>
                          </div>
                        </>
                      )}
                    ></FieldArray>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </Layout>
  );
};

export default AddFamilyMember;
