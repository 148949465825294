import { ErrorMessage, Field } from 'formik';
import React, { memo, useState } from 'react'
import { HideIcon, ViewIcon1 } from '../../assets';

export const PasswordInputFM = memo(({ label, name, placeholder, className, errorBoxClassname, disabled=false }) => {
    const [hide, setHide] = useState(false)
    
    return (
        <div className="flex justify-between mb-0.5">
        {label && <span className="text-sm mt-2">{label}</span>}
        <div >
            <div className={`${className} text-sm border border-[#CCCCCC] rounded-md p-2 bg-[#F9FAFC] flex items-center justify-between`}>
                <Field
                    disabled={disabled}
                    type={!hide? "password" : "text"}
                    placeholder={placeholder}
                    className="bg-[#F9FAFC] outline-none border-none w-full placeholder-italic text-black not-italic text-xs font-semibold me-2"
                    name={name}
                    autoComplete='new-password'
                />
                <span className='cursor-pointer' onClick={()=> setHide(prev=> !prev)}>
                    {hide ? <HideIcon/> : <ViewIcon1/>}
                </span>
            </div>
            <div className={`text-red-500 text-xs italic h-5 ${className} text-wrap break-words ${errorBoxClassname}`}>
                <ErrorMessage name={name}/>
            </div> 
        </div>
        </div>
    )
});