import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import "../../../../components/TableComponent/Table.css";
import styled from "@emotion/styled";
import {
  useSearchParams,
} from "react-router-dom";
import {
  SortIcon,
} from "../../../../assets";
import {
  getComparator,
  stableSort,
} from "../../../../utils/helpers/universalFunctions";
import Loader from "../../../../components/Loader/Loader";
import { TextInput } from "../../../../components/InputFields/TextInput";
import {DatePickerComponent} from "../../../../components/DatePicker/DatePicker";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import moment from "moment";

const TableRowStyled = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #ffffff;
  }
  &:nth-of-type(even) {
    background-color: #f4f5f8;
  }
  &:nth-of-type(odd):hover {
    background-color: #ffffff;
  }
  &:nth-of-type(even):hover {
    background-color: #f4f5f8;
  }
`;

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    zIndex: 0,
    color: "#AAAAAA",
    fontSize: "13px",
    padding: "10px 6px 10px 0",
    wordBreak: "break-word",
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
    fontSize: "12px",
    padding: "10px 6px 10px 0",
    wordBreak: "break-word",
    cursor: "pointer",
  },
}));

const UploadExcelTable = ({
  loading,
  data,
  columns,
  setUploadingExcelData,
  checkbox = true,
  pagination = true,

  //props are required if pagination is true
  totalCount,
  totalPages,
  start,
  end,
  currentPage,
  rowsPerPage,
}) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const [selected, setSelected] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const modeofpaymentoption = [
    {
      value: "CASH",
      label: "CASH",
    },
    {
      value: "CHEQUE",
      label: "CHEQUE",
    },
    {
      value: "NEFT",
      label: "NEFT",
    },
  ];
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    const newSelected = event.target.checked ? data.map((item) => item.id) : [];
    setSelected(newSelected);
  };

  const handleClick = (event, id) => {
    const newSelected = selected.includes(id)
      ? selected.filter((selectedId) => selectedId !== id)
      : [...selected, id];
    setSelected(newSelected);
  };

  const isSelected = (id) => selected.includes(id);

  useEffect(() => {
    setSelected([]);
  }, [searchParams]);

  return (
    <Box sx={{ width: "100%", fontSize: "13px" }} className="h-full table-component">
      <TableContainer
        className="border-t border-[#CCC]"
        sx={{ minHeight: Object.keys(data).length >0 ? "500px" : "none" }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {/* {!!checkbox && (
                <TableCellStyled
                  size="small"
                  sx={{ pl: "0 !important", pr: 2, width: 36 }}
                >
                  <Checkbox
                    size="small"
                    indeterminate={false}
                    checked={
                      data?.length > 0 && selected?.length === data?.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all",
                    }}
                    sx={{
                      p: 0,
                      color: "#AAAAA",
                      "&.Mui-checked": {
                        color: "#CF001C",
                      },
                    }}
                    disabled={loading || !data}
                  />
                </TableCellStyled>
              )} */}
              {columns?.map((column) => (
                <TableCellStyled
                  size="small"
                  key={column.id + "heading"}
                  align={column.numeric ? "right" : "left"}
                  sortDirection={orderBy === column.id ? order : false}
                  sx={{
                    width: column.width || "fit-content",
                    minWidth: column.width || "fit-content",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={() => {
                      if (column.sort) {
                        handleRequestSort(column.id);
                      }
                    }}
                    IconComponent={SortIcon}
                    sx={{
                      ".MuiTableSortLabel-icon": {
                        opacity: "1 !important",
                      },
                      svg: {
                        flexShrink: 0,
                      },
                    }}
                    hideSortIcon={!column.sort}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCellStyled>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCellStyled
                  sx={{ border: "none" }}
                  colSpan={columns?.length + 1}
                >
                  <Loader />
                </TableCellStyled>
              </TableRow>
            ) : (
              <>
              {console.log(data,'data')}
                {Object.keys(data).length >0 &&
                  Object.keys(data).map((row) => {
                      console.log(row,'row')
                      const isRowSelected = isSelected(row);

                      return (
                        <TableRowStyled
                          key={row}
                          role="checkbox"
                          aria-checked={isRowSelected}
                          tabIndex={-1}
                          selected={isRowSelected}
                        >
                          {/* {!!checkbox && (
                            <TableCellStyled
                              size="small"
                              sx={{ pl: "0 !important", zIndex: 0 }}
                              onClick={(event) =>
                                handleClick(event, row?.staffId)
                              }
                            >
                              <Checkbox
                                checked={selected?.includes(row?.staffId)}
                                onChange={(e) => handleClick(e, row?.staffId)}
                                size="small"
                                color="primary"
                                inputProps={{
                                  "aria-labelledby": `generic-table-checkbox-${row?.id}`,
                                }}
                                sx={{
                                  p: 0,
                                  color: "#AAAAA",
                                  "&.Mui-checked": {
                                    color: "#CF001C",
                                  },
                                }}
                              />
                            </TableCellStyled>
                          )} */}

                          {/* Serial Number */}
                          {/* <TableCellStyled
                            size="small"
                            align="left"
                            sx={{ pl: "2" }}
                            onClick={(event) => handleClick(event, row?.id)}
                          >
                            {row?.sNo}
                          </TableCellStyled> */}

                          <TableCellStyled size="small" align="left">
                            {data[row].member_name} <br/>
                            {data[row].unit} <br/>
                            {data[row].invoice_no} <br/>
                            {data[row].daybook}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                          <Dropdown2
                            options={
                              modeofpaymentoption?.map((item) => ({
                                value: item?.value,
                                label: item?.label,
                              })) || []
                            }
                            placeholder="Select MOP"
                            className="text-[11px] ms-4"
                            width="100px"
                            height="32px"
                            onSelect={(e) => {
                              data[row].modeofpayment = e.value
                              setUploadingExcelData({...data})
                              //handleSelectFlat(selectedItem);
                            }}
                            value={[
                              {
                                label : data[row].modeofpayment,
                                value: data[row].modeofpayment
                              }
                            ]}
                          />
                            {/* {row.paymentMode} */}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="center">
                            {data[row].dueamount}
                          </TableCellStyled>
                          <TableCellStyled size="small" align="left">
                          <TextInput
                            placeholder="Enter Amount"
                            className="w-[80px]"
                            value={parseFloat(data[row].amount) || 0}
                            type="decimal"
                            onChange={ (e) => {
                              data[row].amount = parseFloat(e.target.value)
                              setUploadingExcelData({...data})
                            }}
                          />
                          </TableCellStyled>
                          <TableCellStyled size="small" align="left">
                            <TextInput
                              placeholder="Enter Cheque Number"
                              className="w-[80px]"
                              value={data[row].chequenumber || null}
                              type="text"
                              onChange={ (e) => {
                                data[row].chequenumber = e.target.value
                                setUploadingExcelData({...data})
                              }}
                            />
                            {/* {row.chqTranNumber} */}
                          </TableCellStyled>
                          <TableCellStyled size="small" align="left">
                          <DatePickerComponent
                              defaultValue={data[row].chequedate? new Date(data[row].chequedate): null}
                              className="w-24 h-9"
                              onDateChange={(selectedDate) => {
                                data[row].chequedate = moment(selectedDate).format("YYYY-MM-DD")
                                setUploadingExcelData({...data})
                              }}
                            />
                          </TableCellStyled>
                          <TableCellStyled size="small" align="left">
                            <TextInput
                              placeholder="Enter Bank name"
                              className="w-[80px]"
                              value={data[row].bankname || null}
                              type="text"
                              onChange={ (e) => {
                                data[row].bankname = e.target.value
                                setUploadingExcelData({...data})
                              }}
                            />
                            {/* {row.bankName} */}
                          </TableCellStyled>
                          <TableCellStyled size="small" align="left">
                            <TextInput
                              placeholder="Enter Branch Name"
                              className="w-[80px]"
                              value={data[row].branchname || null}
                              type="text"
                              onChange={ (e) => {
                                data[row].branchname = e.target.value
                                setUploadingExcelData({...data})
                              }}
                            />
                            {/* {row.branchName} */}
                          </TableCellStyled>
                          <TableCellStyled size="small" align="left">
                            <DatePickerComponent
                              defaultValue={data[row].paymentdate? new Date(data[row].paymentdate): null}
                              className="w-24 h-9"
                              onDateChange={(selectedDate) => {
                                data[row].paymentdate = moment(selectedDate).format("YYYY-MM-DD")
                                setUploadingExcelData({...data})
                              }}
                            />
                            {/* {row.paymentDate} */}
                          </TableCellStyled>
                          <TableCellStyled size="small" align="left">
                          <DatePickerComponent
                              defaultValue={data[row].cleardate? new Date(data[row].cleardate): null}
                              className="w-24 h-9"
                              onDateChange={(selectedDate) => {
                                data[row].cleardate = moment(selectedDate).format("YYYY-MM-DD")
                                setUploadingExcelData({...data})
                              }}
                            />
                            {/* {row.clearDate} */}
                          </TableCellStyled>

                        </TableRowStyled>
                      );
                    }
                  )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

UploadExcelTable.propTypes = {
  columns: PropTypes.array.isRequired,
  defaultSortField: PropTypes.string,
  rowsPerPageOptions: PropTypes.array,
};

export default UploadExcelTable;
