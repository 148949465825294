import moment from 'moment'
import * as type from '../../../actions/index.types'

export function tenantInfoReducer(state = {
  loading: false,
  updateLoader: false,
  printLoader: false,
  expiryReminderLoader: false,
  tenantsList: null,
  printTenantList: null,
  tenantDetails: null,
  existingTenantList: null,
  archivedTenantsList: null,
  tenantAuditLogs: {
    tenant_audit_log:[]
  },
  managingCommitteeTypesList: null,
  employeeTypeList: null,
  expiryReminderData: null,

  rentPaymentOnlineRegistrationList: null,
  rentPaymentOnlineRegistrationData: null,
  accessSettingData: null,
  error:null,
  uploadError: null
}, action) {

switch (action.type) {
  case type.GET_TENANTS_DETAILS_LIST:
    return {
      ...state,
      loading: true,
      error: null
    }
  case type.GET_TENANTS_DETAILS_LIST_SUCCESS:
    return {
      ...state,
      error: null,
      loading: false,
      tenantsList: action.payload
    }
  case type.GET_TENANTS_DETAILS_LIST_FAILURE:
    return {
      ...state,
      error: action?.message || 'Somthing went wrong.',
      loading: false,
      tenantsList: null,
    }
  
  case type.UPLOAD_TENANTS:
    return {
      ...state,
      updateLoader: true,
      uploadError: null,
    }
  case type.UPLOAD_TENANTS_SUCCESS:
    return {
      ...state,
      updateLoader: false,
      uploadError: action.payload.error

    }
  case type.UPLOAD_TENANTS_FAILURE:
    return {
      ...state,
      updateLoader: false,
      uploadError: action?.payload?.error || ''
    }
  case type.UPLOAD_TENANTS_CLEAR_ERROR:
    return {
      ...state,
      uploadError: ''
    }

  case type.ADD_TENANT:
    return {
      ...state,
      updateLoader: true,
    }
  case type.ADD_TENANT_SUCCESS:
    return {
      ...state,
      updateLoader: false,
    }
  case type.ADD_TENANT_FAILURE:
    return {
      ...state,
      updateLoader: false,
    }

  case type.DELETE_TENANT:
    return {
      ...state,
    }
  case type.DELETE_TENANT_SUCCESS:
    if(action.payload.flag==='Tenant'){
      return {
        ...state,
        tenantsList: {
          ...state.tenantsList,
          tenants_list: state.tenantsList?.tenants_list?.filter(item=>!(item.tenant_id === action.payload.deletetenantId))
        }
      }
    }else{
      return {
        ...state,
        archivedTenantsList: {
          ...state.archivedTenantsList,
          tenants_list: state.archivedTenantsList?.tenants_list?.filter(item=>!(item.tenant_id === action.payload.deletetenantId))
        }
      }
    }
    
   case type.DELETE_TENANT_FAILURE:
      return {
        ...state,
      }

    case type.DELETE_TENANT_IN_BULK:
    return {
      ...state,
      updateLoader: true
    }
  case type.DELETE_TENANT_IN_BULK_SUCCESS:
    if(action.payload.flag==='Tenant'){
      return {
        ...state,
        updateLoader: false,
        tenantsList: {
          ...state.tenantsList,
          tenants_list: state.tenantsList?.tenants_list?.filter(item=>!(action.payload?.tenantIds?.includes(item.tenant_id)))
        }
      }
    }else{
      return {
        ...state,
        updateLoader: false,
        archivedTenantsList: {
          ...state.archivedTenantsList,
          tenants_list: state.archivedTenantsList?.tenants_list?.filter(item=>!(action.payload?.tenantIds?.includes(item.tenant_id)))
        }
      }
    }
    
  case type.DELETE_TENANT_IN_BULK_FAILURE:
    return {
      ...state,
      updateLoader: false
    }

  case type.CHANGE_TENANTS_STATUS:
    return {
      ...state,
      updateLoader: true,
    }
  case type.CHANGE_TENANTS_STATUS_SUCCESS:
    return {
      ...state,
      updateLoader: false,
      tenantsList: {
        ...state.tenantsList,
        tenants_list: state.tenantsList?.tenants_list?.map( (tenant) => tenant?.tenant_id === action.payload.tenantId
          ? {...tenant, status: action.payload.status}
          : tenant
      )
      }
    }
  case type.CHANGE_TENANTS_STATUS_FAILURE:
    return {
      ...state,
      updateLoader: false,
    }

  case type.CHANGE_TENANTS_STATUS_IN_BULK:
    return {
      ...state,
      updateLoader: true,
    }
  case type.CHANGE_TENANTS_STATUS_IN_BULK_SUCCESS:
    return {
      ...state,
      updateLoader: false,
      tenantsList: {
        ...state.tenantsList,
        tenants_list: state.tenantsList?.tenants_list?.map( (tenant) => action.payload.tenantIds?.includes(tenant?.tenant_id)
          ? {...tenant, status: tenant?.status==='P' && moment(new Date(tenant?.end_date)).isAfter(new Date()) ? 'A' : tenant?.status}
          : tenant
      )
      }
    }
  case type.CHANGE_TENANTS_STATUS_IN_BULK_FAILURE:
      return {
        ...state,
        updateLoader: false,
      }

  case type.SEND_EMAIL_TO_TENANTS:
    return {
      ...state,
      updateLoader: true,
    }
  case type.SEND_EMAIL_TO_TENANTS_SUCCESS:
    return {
      ...state,
      updateLoader: false,
    }
  case type.SEND_EMAIL_TO_TENANTS_FAILURE:
    return {
      ...state,
      updateLoader: false,
    }
    
  case type.GET_TENANT_DETAILS:
    return {
      ...state,
      loading: true,
      error: null
    }
  case type.GET_TENANT_DETAILS_SUCCESS:
    return {
      ...state,
      error: null,
      loading: false,
      tenantDetails: action.payload
    }
  case type.GET_TENANT_DETAILS_FAILURE:
    return {
      ...state,
      error: action?.message || 'Somthing went wrong.',
      loading: false,
      tenantDetails: null,
    }
  
  case type.UPDATE_TENANT_DETAILS:
    return {
      ...state,
      updateLoader: true,
    }
  case type.UPDATE_TENANT_DETAILS_SUCCESS:
    return {
      ...state,
      updateLoader: false,
    }
  case type.UPDATE_TENANT_DETAILS_FAILURE:
    return {
      ...state,
      updateLoader: false,
    }

  case type.GET_EXISTING_TENANTS:
    return {
      ...state,
      loading: true,
      error: null
    }
  case type.GET_EXISTING_TENANTS_SUCCESS:
    return {
      ...state,
      error: null,
      loading: false,
      existingTenantList: action.payload
    }
  case type.GET_EXISTING_TENANTS_FAILURE:
    return {
      ...state,
      error: action?.message || 'Somthing went wrong.',
      loading: false,
      existingTenantList: null,
    }

  case type.ADD_EXISTING_TENANTS:
    return {
      ...state,
      updateLoader: true,
    }
  case type.ADD_EXISTING_TENANTS_SUCCESS:
    return {
      ...state,
      updateLoader: false,
    }
  case type.ADD_EXISTING_TENANTS_FAILURE:
    return {
      ...state,
      updateLoader: false,
    }

  case type.GET_TENANTS_AUDIT_LOGS:
    return {
      ...state,
      loading: true,
      error: null
    }
  case type.GET_TENANTS_AUDIT_LOGS_SUCCESS:
    return {
      ...state,
      error: null,
      loading: false,
      tenantAuditLogs: {
        ...state.tenantAuditLogs,
        total_pages: action.payload.total_pages,
        current_page_no: action.payload.current_page_no,
        tenant_audit_log: [...(state.tenantAuditLogs?.tenant_audit_log?.length ? state.tenantAuditLogs?.tenant_audit_log: []), ...action.payload?.tenant_audit_log]
      }
    }
  case type.GET_TENANTS_AUDIT_LOGS_FAILURE:
    return {
      ...state,
      error: action?.message || 'Somthing went wrong.',
      loading: false,
      tenantAuditLogs: null,
    }

  case type.GET_ARCHIVED_TENANTS:
    return {
      ...state,
      loading: true,
      error: null
    }
  case type.GET_ARCHIVED_TENANTS_SUCCESS:
    return {
      ...state,
      error: null,
      loading: false,
      archivedTenantsList: action.payload
    }
  case type.GET_ARCHIVED_TENANTS_FAILURE:
    return {
      ...state,
      error: action?.message || 'Somthing went wrong.',
      loading: false,
      archivedTenantsList: null,
    }

  case type.ARCHIVE_TENANT:
    return {
      ...state,
    }
  case type.ARCHIVE_TENANT_SUCCESS:
    return {
      ...state,
      tenantsList: {
        ...state.tenantsList,
        tenants_list: state.tenantsList?.tenants_list?.filter(item=>!(item.tenant_id === action.payload.tenantId))
      }
    }
  case type.ARCHIVE_TENANT_FAILURE:
    return {
      ...state,
    }

  case type.GET_MANAGING_COMITTEE_TYPES_LIST_SUCCESS:
    return {
      ...state,
      managingCommitteeTypesList: action.payload
    }
  
  case type.GET_EMPLOYEES_TYPE_LIST_IN_TENANTS_SUCCESS:
    return {
      ...state,
      employeeTypeList: action.payload
    }

  case type.GET_TENANCY_EXPIRY_REMINDER_DATA:
    return {
      ...state,
      expiryReminderLoader: true,
    }

  case type.GET_TENANCY_EXPIRY_REMINDER_DATA_SUCCESS:
    return {
      ...state,
      expiryReminderLoader: false,
      expiryReminderData: action.payload
    }

  case type.GET_TENANCY_EXPIRY_REMINDER_DATA_FAILURE:
    return {
      ...state,
      expiryReminderLoader: false,
      expiryReminderData: null
    }


  case type.UPDATE_TENANCY_EXPIRY_REMINDER_DATA:
    return {
      ...state,
      updateLoader: true,
    }
  case type.UPDATE_TENANCY_EXPIRY_REMINDER_DATA_SUCCESS:
    return {
      ...state,
      updateLoader: false,
    }
  case type.UPDATE_TENANCY_EXPIRY_REMINDER_DATA_FAILURE:
    return {
      ...state,
      updateLoader: false,
    }

  case type.RENEW_FORM:
    return {
      ...state,
      updateLoader: true,
    }
  case type.RENEW_FORM_SUCCESS:
    return {
      ...state,
      updateLoader: false,
    }
  case type.RENEW_FORM_FAILURE:
    return {
      ...state,
      updateLoader: false,
    }
  
  
  case type.RENT_PAYMENT_ONLINE_REGISTRATION_LIST:
    return {
      ...state,
      loading: true,
      error: null
    }
  case type.RENT_PAYMENT_ONLINE_REGISTRATION_LIST_SUCCESS:
    return {
      ...state,
      error: null,
      loading: false,
      rentPaymentOnlineRegistrationList: action.payload
    }
  case type.RENT_PAYMENT_ONLINE_REGISTRATION_LIST_FAILURE:
    return {
      ...state,
      error: action?.message || 'Somthing went wrong.',
      loading: false,
      rentPaymentOnlineRegistrationList: null,
    }


  case type.GET_RENT_PAYMENT_ONLINE_REGISTRATION_DATA:
    return {
      ...state,
      loading: true,
      error: null
    }
  case type.GET_RENT_PAYMENT_ONLINE_REGISTRATION_DATA_SUCCESS:
    return {
      ...state,
      error: null,
      loading: false,
      rentPaymentOnlineRegistrationData: action.payload
    }
  case type.GET_RENT_PAYMENT_ONLINE_REGISTRATION_DATA_FAILURE:
    return {
      ...state,
      error: action?.message || 'Somthing went wrong.',
      loading: false,
      rentPaymentOnlineRegistrationData: null,
    }


  case type.UPDATE_RENT_PAYMENT_ONLINE_REGISTRATION_DATA:
    return {
      ...state,
      updateLoader: true,
    }
  case type.UPDATE_RENT_PAYMENT_ONLINE_REGISTRATION_DATA_SUCCESS:
    return {
      ...state,
      updateLoader: false,
    }
  case type.UPDATE_RENT_PAYMENT_ONLINE_REGISTRATION_DATA_FAILURE:
    return {
      ...state,
      updateLoader: false,
    }

  case type.DELETE_RENT_PAYMENT_ONLINE_REGISTRATION_DATA:
    return {
      ...state,
    }
  case type.DELETE_RENT_PAYMENT_ONLINE_REGISTRATION_DATA_SUCCESS:
    return {
      ...state,
      rentPaymentOnlineRegistrationList: {
        ...state.rentPaymentOnlineRegistrationList,
        occupants_list: state.rentPaymentOnlineRegistrationList?.occupants_list?.filter(item=>!(item.registration_id === action.payload.registrationId))
      }
    }
    
    case type.DELETE_RENT_PAYMENT_ONLINE_REGISTRATION_DATA_FAILURE:
      return {
        ...state,
      }

  case type.GET_ACCESS_SETTING_DATA:
    return {
      ...state,
      loading: true,
      error: null
    }
  case type.GET_ACCESS_SETTING_DATA_SUCCESS:
    return {
      ...state,
      error: null,
      loading: false,
      accessSettingData: action.payload
    }
  case type.GET_ACCESS_SETTING_DATA_FAILURE:
    return {
      ...state,
      error: action?.message || 'Somthing went wrong.',
      loading: false,
      accessSettingData: null,
    }


  case type.UPDATE_ACCESS_SETTING:
    return {
      ...state,
      updateLoader: true,
    }
  case type.UPDATE_ACCESS_SETTING_SUCCESS:
    return {
      ...state,
      updateLoader: false,
    }
  case type.UPDATE_ACCESS_SETTING_FAILURE:
    return {
      ...state,
      updateLoader: false,
    }


  case type.PRINT_TENANTS_LIST:
    return {
      ...state,
      printLoader: true,
      error: null,
      printTenantList: null
    }
  case type.PRINT_TENANTS_LIST_SUCCESS:
    return {
      ...state,
      error: null,
      printLoader: false,
      printTenantList: action.payload
    }
    case type.PRINT_TENANTS_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        printLoader: false,
        tenantsList: null,
      }
default:
  return state
}
}