import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import VerifyAndPostStructure from "./VerifyandPostInvoiceStructure";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import Button from "../../../../components/Button/Button";
import ButtonG from "../../../../components/Button/ButtonG";
import { getBookingInvoice } from "../../../../redux/actions/ClubActions/ViewBookingsAction";
import { verifyPostPaymentUpdate } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageInvoiceActions";
import {useNavigate} from "react-router-dom";
import {VIEW_MANAGE_INVOICE_CURRENT_ENDPOINT} from "../../../../Routing/routes";
import { CircularProgress } from "@mui/material";
const customStyles = {
  content: {
    width: "780px",
    height: "600px",
    minHeight: "fit-content",
    background: "#fff",
    border: "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
};

Modal.setAppElement("#root");

const VerifyAndPostModal = ({
  isOpen,
  onClose,
  invoiceid,
  paymentInvoiceId,
  transactionid,
  receiptNo,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedBankId, setSelectedBankId] = useState(null);

  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, updateLoader, invoiceDetails } = useSelector(
    (state) => state.viewBookingReducer
  );

  useEffect(() => {
    if (isOpen) {
      dispatch(
        getBookingInvoice({
          invoiceId: invoiceid,
          societyId: loginData?.default_community?.community_id,
          payid: paymentInvoiceId,
        })
      );
    }
  }, [isOpen]);

  const handlePost = () => {
    dispatch(
      verifyPostPaymentUpdate({
        societyId: loginData?.default_community?.community_id,
        bankinvid: invoiceid,
        bankid: selectedBankId,
        payid: paymentInvoiceId,
        onSuccessCallback: () => {
          navigate(`${VIEW_MANAGE_INVOICE_CURRENT_ENDPOINT}/${invoiceid}`);
          onClose() 
        },
      })
    );
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
      <div className="p-6 bg-white rounded-lg">
        <VerifyAndPostStructure
          invoiceDetails={invoiceDetails}
          loginData={loginData}
          loading={loading}
        />
        <div className="mt-4">
          <p className="mb-2 font-bold">Payment Details</p>
          <div className="grid grid-cols-2 gap-4">
            <div className="text-sm">Transaction Id</div>
            <div>{transactionid}</div>
            <div className="text-sm">Receipt No.</div>
            <div>{receiptNo}</div>
            <div className="text-sm">Mode Of Payment</div>
            <div>{invoiceDetails?.data?.modeofpayment}</div>
            <div className="text-sm">Transaction Number</div>
            <div>{invoiceDetails?.data?.cheque_number}</div>
            <div className="text-sm">Payment Date</div>
            <div>{invoiceDetails?.data?.payment_date}</div>
          </div>
        </div>
        <div className="mt-4">
          <p className="mb-2 font-bold">Bank Details</p>
          <div className="flex items-center justify-between">
            <div className="text-sm">Society Bank Name</div>
            <div className="ml-4">
              <Dropdown2
                options={
                  invoiceDetails?.data?.bankdetail?.map((item) => ({
                    label: `${item.bank_name} [Account - ${item.account_no}]`,
                    value: item.bankid,
                  })) || []
                }
                placeholder="Select"
                width="224px"
                height="36px"
                name="bankId"
                onSelect={(selectedItem) => {
                  setSelectedBankId(selectedItem.value);
                }}
                value={
                  invoiceDetails?.data?.bankdetail?.find(
                    (item) => item.bankid === selectedBankId
                  )
                    ? {
                        label: `${
                          invoiceDetails?.data?.bankdetail?.find(
                            (item) => item.bankid === selectedBankId
                          ).bank_name
                        } [Account - ${
                          invoiceDetails?.data?.bankdetail?.find(
                            (item) => item.bankid === selectedBankId
                          ).account_no
                        }]`,
                        value: selectedBankId,
                      }
                    : null
                }
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end w-full mt-6">
          <div className="flex gap-3">
            <Button label={
                          <span className="inline-block w-12">
                            {updateLoader ? (
                              <CircularProgress
                                sx={{ color: "white" }}
                                size={17}
                              />
                            ) : (
                              <>Verify</>
                            )}
                          </span>
                        } onClick={handlePost} />
            <ButtonG label="Cancel" onClick={onClose} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default VerifyAndPostModal;
