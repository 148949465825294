import { call, put, takeLatest } from 'redux-saga/effects'
import {notify} from '../../../../utils/notification'
import * as types from '../../../actions/index.types'
import * as req from '../../../../axios/request/SetupRecordsReqs/StaffManagementReqs/EmployeeManagementReqs'

function* getEmployeeList(action) {
    try {
        const result = yield call(req.getEmployeeListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_EMPLOYEE_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_EMPLOYEE_LIST_FAILURE, payload: action.data, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getEmployeeTypeList(action) {
    try {
        const result = yield call(req.getEmployeeTypeListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_EMPLOYEE_TYPES_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_EMPLOYEE_TYPES_LIST_FAILURE, payload: action.data, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* addEmployee(action) {
    try {
        const result = yield call(req.addEmployeeReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.ADD_EMPLOYEE_SUCCESS, payload: action.data});  
            action.data?.onSuccessCallback?.()
        }else{
            yield put({ type: types.ADD_EMPLOYEE_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}

function* changeEmployeeStatus(action) {
    action.data?.onRequestEndCallback?.()
    try {
        const result = yield call(req.changeEmployeeStatusReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.CHANGE_EMPLOYEE_STATUS_SUCCESS, payload: action.data});  
        }else{
            yield put({ type: types.CHANGE_EMPLOYEE_STATUS_FAILURE});  
        }
    } catch (e) {
    }
}

function* deleteEmployee(action) {
    try {
        const result = yield call(req.deleteEmployeeReq, action);
        if (result.statusCode === 200) {
            notify('success', 'Data deleted successfully.');
            action.data?.onSuccessCallback()
            yield put({ type: types.DELETE_EMPLOYEE_SUCCESS, payload: action.data});  
            const newEmployeeList = yield call(req.getEmployeeListReq, action)
            if (newEmployeeList.statusCode === 200)
                yield put({ type: types.GET_EMPLOYEE_LIST_SUCCESS, payload: newEmployeeList.data});  
        }
    } catch (e) {
        console.log(e)
    }
}

function* getEmployeeDetails(action) {
    try {
        const result = yield call(req.getEmployeeDetailsReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_EMPLOYEE_DETAILS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_EMPLOYEE_DETAILS_FAILURE, payload: action.data, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getEmployeeAuditLogs(action) {
    try {
        const result = yield call(req.getEmployeeAuditLogsReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_EMPLOYEE_AUDIT_LOGS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_EMPLOYEE_AUDIT_LOGS_FAILURE, payload: action.data, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateEmployeeDetails(action) {
    try {
        const result = yield call(req.updateEmployeeDetailsReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.UPDATE_EMPLOYEE_DATA_SUCCESS, payload: action.data});  
            action.data?.onSuccessCallback?.()
        }else{
            yield put({ type: types.UPDATE_EMPLOYEE_DATA_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}

function* getEmployeeUserNameList(action) {
    try {
        const result = yield call(req.getEmployeeUserNameListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_EMPLOYEE_USERNAMES_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_EMPLOYEE_USERNAMES_LIST_FAILURE, payload: action.data, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getEmployeeNameList(action) {
    try {
        const result = yield call(req.getEmployeeNameListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_EMPLOYEE_NAMES_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_EMPLOYEE_NAMES_LIST_FAILURE, payload: action.data, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getExistingEmployee(action) {
    try {
        const result = yield call(req.getExistingEmployeeReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_EXISTING_EMPLOYEE_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_EXISTING_EMPLOYEE_LIST_FAILURE, payload: action.data, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* addFromExistingEmployee(action) {
    try {
        const result = yield call(req.addFromExistingEmployeeReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.ADD_FROM_EXISTING_EMPLOYEE_SUCCESS, payload: action.data});  
            action.data?.onSuccessCallback?.()
        }else{
            yield put({ type: types.ADD_FROM_EXISTING_EMPLOYEE_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}


function* getEmployeeLoginReport(action) {
    try {
        const result = yield call(req.getEmployeeLoginReportReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_EMPLOYEEE_LOGIN_REPORT_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_EMPLOYEEE_LOGIN_REPORT_FAILURE, payload: action.data, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* printEmployeeLoginReport(action) {
    try {
        const result = yield call(req.printEmployeeLoginReportReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.PRINT_EMPLOYEEE_LOGIN_REPORT_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.PRINT_EMPLOYEEE_LOGIN_REPORT_FAILURE, payload: action.data, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* downloadEmployeeSheet(action) {
    try {
        const result = yield call(req.downloadEmployeeSheetReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
        }else{
            notify('Unable to download sheet.')
        }
    } catch (e) {
    }
}

function* getEmployeeReportRecord(action) {
    try {
        const result = yield call(req.getEmployeeReportRecordReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_EMPLOYEEE_REPORT_RECORD_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_EMPLOYEEE_REPORT_RECORD_FAILURE, payload: action.data, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* printEmployeeReportRecord(action) {
    try {
        const result = yield call(req.getEmployeeReportRecordReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.PRINT_EMPLOYEEE_REPORT_RECORD_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.PRINT_EMPLOYEEE_REPORT_RECORD_FAILURE, payload: action.data, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}


export default function* EmployeeManagementSaga() {
    yield takeLatest(types.GET_EMPLOYEE_LIST, getEmployeeList);
    yield takeLatest(types.GET_EMPLOYEE_TYPES_LIST, getEmployeeTypeList);
    yield takeLatest(types.CHANGE_EMPLOYEE_STATUS, changeEmployeeStatus);
    yield takeLatest(types.DELETE_EMPLOYEE, deleteEmployee);
    yield takeLatest(types.GET_EMPLOYEE_DETAILS, getEmployeeDetails);
    yield takeLatest(types.ADD_EMPLOYEE, addEmployee);
    yield takeLatest(types.GET_EMPLOYEE_AUDIT_LOGS, getEmployeeAuditLogs);
    yield takeLatest(types.UPDATE_EMPLOYEE_DATA, updateEmployeeDetails);
    yield takeLatest(types.GET_EMPLOYEE_USERNAMES_LIST, getEmployeeUserNameList);
    yield takeLatest(types.GET_EMPLOYEE_NAMES_LIST, getEmployeeNameList);
    yield takeLatest(types.GET_EXISTING_EMPLOYEE_LIST, getExistingEmployee);
    yield takeLatest(types.ADD_FROM_EXISTING_EMPLOYEE, addFromExistingEmployee);
    yield takeLatest(types.GET_EMPLOYEEE_LOGIN_REPORT, getEmployeeLoginReport);
    yield takeLatest(types.PRINT_EMPLOYEEE_LOGIN_REPORT, printEmployeeLoginReport);
    yield takeLatest(types.DOWNLOAD_EMPLOYEEE_LOGIN_REPORT, downloadEmployeeSheet);
    yield takeLatest(types.GET_EMPLOYEEE_REPORT_RECORD, getEmployeeReportRecord);
    yield takeLatest(types.PRINT_EMPLOYEEE_REPORT_RECORD, printEmployeeReportRecord);
}