export const societyProfilePath = ({ societyId, fileName }) => {
    return `/society/${societyId}/profile/${Date.now()}-${fileName?.replace(/\s/g, '')}`
}

export const societyDocsPath = ({ societyId, fileName }) => {
    return `/society/${societyId}/docs/${Date.now()}-${fileName?.replace(/\s/g, '')}`
}

export const flatsDocsPath = ({ societyId, flatId, fileName }) => {
    return `/society/${societyId}/flats/${flatId}/docs/${Date.now()}-${fileName?.replace(/\s/g, '')}`
}



export const societyStaffProfilePath = ({ societyId, fileName }) => {
    return `/society/${societyId}/society_staff/profile/${Date.now()}-${fileName?.replace(/\s/g, '')}`
}

export const societyStaffDocsPath = ({ societyId, fileName }) => {
    return `/society/${societyId}/society_staff/docs/${Date.now()}-${fileName?.replace(/\s/g, '')}`
}



export const societyOccupantProfilePath = ({ societyId, flatId, fileName }) => {
    return `/society/${societyId}/flats/${flatId}/profile/users/${Date.now()}-${fileName?.replace(/\s/g, '')}`
}

export const societyOccupantDocsPath = ({ societyId, flatId, fileName }) => {
    return `society/${societyId}/flats/${flatId}/docs/${Date.now()}-${fileName?.replace(/\s/g, '')}`
}

export const noticeCircularDosPath = ({ societyId, fileName }) => {
    return `society/${societyId}/notice_circular/docs/${Date.now()}-${fileName?.replace(/\s/g, '')}`
}


export const managingCommitteeDocsPath = ({ societyId, fileName }) => {
    return `/society/${societyId}/documents/managing_committee/${Date.now()}-${fileName?.replace(/\s/g, '')}`
}

export const complaintTicketDocPath = ({ societyId, flatId, fileName }) => {
    return `society/${societyId}/flats/${flatId}/complaints/tickets/${Date.now()}-${fileName?.replace(/\s/g, '')}`
}

export const complaintDocPath = ({ societyId, flatId, fileName }) => {
    return `society/${societyId}/documents/complaints/${Date.now()}-${fileName?.replace(/\s/g, '')}`
}


export const visitorProfilePath = ({ societyId, flatId, fileName }) => {
    return `society/${societyId}/visitors/${Date.now()}.jpg`
}

export const facilityDocsPath = ({ societyId, flatId, fileName }) => {
    return `society/${societyId}/facility/${Date.now()}-${fileName?.replace(/\s/g, '')}`
}