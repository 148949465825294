import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ListHeader from "../ListHeader/ListHeader";
import Button from "../Button/Button";
import ButtonG from "../Button/ButtonG";
import Dropdown2 from "../Dropdown/dropdown2";
import Box from "@mui/material/Box";
import Modal from "react-modal";
import { TextInputFM } from "../InputFields/TextInput";
import { DropdownFM2 } from "../Dropdown/dropdown2";
import {
  getInvoiceSeriesList,
  addInvoiceSeries,
} from "../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";
import { notify } from "../../utils/notification";
import { Form, Formik, Field } from "formik";
import moment from "moment";
import { DatePickerComponentFM } from "../DatePicker/DatePicker";
import { addInvoiceSeriesValidation } from "../../validationSchema/setupRecordsSchema/invoiceSettingSchema";
import { CircularProgress } from "@mui/material";

const customStyles = {
  content: {
    width: "500px",
    height: "auto",
    minHeight: "fit-content",
    background: "#fff",
    border: "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
};
const AddInvoiceSeriesModal = ({ isOpen, onClose, headId , typeId }) => {
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, addLoader, bankDetailsData } = useSelector(
    (state) => state.bankDetailsReducer
  );
  let types = typeId === '1' ?  [
    {
      value: "Maintenance Invoice",
      label: "Maintenance Invoice",
    },
    {
      value: "Credit Note",
      label: "Credit Note",
    },
    {
      value: "Debit Note",
      label: "Debit Note",
    },
    {
      value: "Supplementary Invoice",
      label: "Supplementary Invoice",
    },
    {
      value: "No Due Certificate",
      label: "No Due Certificate",
    }, 
  ] : [
    {
      value: "Main Invoice",
      label: "Main Invoice",
    },
    {
      value: "Credit Note",
      label: "Credit Note",
    },
    {
      value: "Debit Note",
      label: "Debit Note",
    },
    {
      value: "Supplementary Invoice",
      label: "Supplementary Invoice",
    }];
  const onSubmit = (values) => {
    dispatch(
      addInvoiceSeries({
        societyId: loginData?.default_community?.community_id,
        invoiceType: values.invoiceType,
        typeId: typeId,
        fromDate: values.fromDate,
        toDate: values.toDate,
        startNumber: values.startNumber,
        prefix: values.prefix,
        suffix: values.suffix,
        isZero: values.isZero,
        numDigit: values.numDigit,
        onSuccessCallback: () => {
          onClose();
          dispatch(
            getInvoiceSeriesList({
              societyId: loginData?.default_community?.community_id,
              typeId: typeId,
            })
          );
          //navigate(-1);
        },
      })
    );
  };
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
      <Formik
        initialValues={{
          invoiceType: typeId === "1" ?"Maintenance Invoice" : "Main Invoice",
          fromDate: "",
          toDate: "",
          prefix: "",
          suffix: "",
          startNumber: 1,
          isZero: true,
          numDigit: 1,
        }}
        enableReinitialize
        validationSchema={addInvoiceSeriesValidation}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <div className="bg-white border-b rounded-lg">
                <ListHeader leftContent="Add Invoice Series">
                  <span className="border-r border-[#CCC]"></span>
                  <ButtonG label="Cancel" type={"button"} onClick={onClose} />
                  <Button label={addLoader ? (
                                <CircularProgress
                                  sx={{ color: "white" }}
                                  size={17}
                                />
                              ) : (
                                "Save"
                              )} type={"submit"} disabled={loading} />
                </ListHeader>
              </div>
              <Box sx={{ width: "100%", height: "67%", fontSize: "13px" }}>
                <div className="flex flex-wrap w-full gap-4 p-2 mt-3">
                  <table className="w-full">
                    <tbody>
                      <tr>
                        <td className="w-1/2">Invoice Type</td>
                        <td className="w-1/2">
                          <DropdownFM2
                            options={types.map((detail) => {
                              return {
                                value: detail.value,
                                label: detail.label,
                              };
                            })}
                            value={
                              formik.values.invoiceType
                                ? {
                                    label: formik.values.invoiceType,
                                    value: formik.values.invoiceType,
                                  }
                                : null
                            }
                            onSelect={(selectedItem) => {
                              formik.setFieldValue(
                                "invoiceType",
                                selectedItem.value
                              );
                            }}
                            name="invoiceType"
                            placeholder="Select Invoice Type"
                            width="14rem"
                            height="36px"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>From Date</td>
                        <td>
                          <DatePickerComponentFM
                            defaultValue={
                              formik.values.fromDate
                                ? new Date(formik.values.fromDate)
                                : null
                            }
                            name="fromDate"
                            className="w-56 mb-2 h-9"
                            onDateChange={(selectedDate) => {
                              formik.setFieldValue(
                                "fromDate",
                                moment(selectedDate).format("YYYY-MM-DD")
                              );
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>to Date</td>
                        <td>
                          <DatePickerComponentFM
                            defaultValue={
                              formik.values.toDate
                                ? new Date(formik.values.toDate)
                                : null
                            }
                            name="toDate"
                            className="w-56 mb-2 h-9"
                            onDateChange={(selectedDate) => {
                              formik.setFieldValue(
                                "toDate",
                                moment(selectedDate).format("YYYY-MM-DD")
                              );
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Prefix</td>
                        <td>
                          <TextInputFM
                            
                            placeholder="Enter Prefix"
                            className="w-56 h-9"
                            name="prefix"
                            type="text"
                            maxLength={20}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Start Number</td>
                        <td>
                          <TextInputFM
                            placeholder="Enter Start Number"
                            className="w-56 h-9"
                            name="startNumber"
                            type="decimal"
                            maxLength={20}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Suffix</td>
                        <td>
                          <TextInputFM
                            label=""
                            placeholder="Enter Suffix"
                            className="w-56 h-9"
                            name="suffix"
                            type="text"
                            maxLength={20}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Is Zero Prepend</td>
                        <td>
                          <Field type="checkbox" name="isZero" className="mb-2 mr-2" />
                          Yes
                        </td>
                      </tr>
                      <tr>
                        <td>Number Of Digit</td>
                        <td>
                          <TextInputFM
                            label=""
                            placeholder="Enter Number Of Digit"
                            className="w-56 h-9"
                            name="numDigit"
                            type="decimal"
                            maxLength={20}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
export default AddInvoiceSeriesModal;
