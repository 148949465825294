import React, { useEffect } from "react";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import {
  financialYears2,
} from "../../../../consts/DropDownOptions";
import Search from "../../../../components/SearchBox/Search";
import ButtonG from "../../../../components/Button/ButtonG";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getSocietyBillReports,
} from "../../../../redux/actions/ReportActions/OldFinancialReportsActions";
import SocietyBillsTable from "./SocietyBillsTable";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { BILL_REPORTS_ENDPOINT } from "../../../../Routing/routes";

export const headCells = [
  { id: "billType", label: "Bill Type", width: "90px" },
  { id: "month", label: "Month", width: "80px" },
  { id: "year", label: "Year", width: "80px" },
  { id: "billsPdfViewDownload", label: "Bills Pdf View/Download", width: "140px" },
  { id: "billsRegisterViewDownload", label: "Bills Register View/Download", width: "140px" },
  { id: "billsRegisterExcelViewDownload", label: "Bills Register Excel View/Download", width: "150px" },
  { id: "collection", label: "Collection", width: "110px" },
  { id: "acknowledgementSheet", label: "Acknowledgement Sheet", width: "150px" },
];

const SocietyBills = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  // const { loginData } = useSelector((state) => state.loginReducer);
  const { loading,societyBillReports } =
    useSelector((state) => state.oldFinancialReportsReducers);

    const handleSearch = (text) => {
      if (text && text !== searchParams.get("search")) {
        searchParams.delete("page");
        searchParams.delete("search");
        navigate({
          pathname: `${BILL_REPORTS_ENDPOINT}`,
          search: `?${createSearchParams([
            ...searchParams.entries(),
            ["search", text?.trim()],
          ])}`,
        });
      }
    };

    const handleSelectMonth = (selectedItem) => {
      setTimeout(() => {
        console.log(searchParams.get("end_year"), "test")

      }, 1000)
      searchParams.delete("page");
  
      if (selectedItem.value?.toString() !== searchParams.get("start_year")) {
        searchParams.delete("start_year");
        navigate({
          pathname: `${BILL_REPORTS_ENDPOINT}`,
          search: `?${createSearchParams([
            ...searchParams.entries(),
            ["start_year", selectedItem.value],
            ["end_year", searchParams.get("end_year")],
          ])}`,
        });
      }
    };

    const handleClear = () => {
      navigate(BILL_REPORTS_ENDPOINT);
    };
  
    useEffect(() => {
      dispatch(
        getSocietyBillReports({
          society_registration: "MYAS387",
          page: searchParams.get("page") || 1,
          limit: searchParams.get("pageLimit") || 10,
          start_year: searchParams.get("start_year"),
          end_year: searchParams.get("end_year"),
        })
      );
    }, [searchParams]);

  return (
    <div className="mt-1">
      <div className="">
        <ListHeader leftContent="Society Bills">
        <Search
            height="8"
            onclick={handleSearch}
            value={searchParams.get("search")}
          />
          <span className="border-r border-[#CCC]"></span>
          <Dropdown2
            options={financialYears2}
            placeholder="Choose Financial Year"
            className=""
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              handleSelectMonth(selectedItem);
            }}
            value={
              searchParams.get("start_year") && {
                value: searchParams.get("start_year"),
                value1: searchParams.get("end_year"),
                label: financialYears2?.find(
                  (item) => item.value === item.value1 === searchParams.get("start_year") === searchParams.get("end_year")
                )?.label,
              }
            }
          />
          <span className="border-r border-[#CCC]"></span>
          <ButtonG label="Clear" onclick={handleClear} />
        </ListHeader>
      </div>
      <div className="ml-1">
        <SocietyBillsTable
          loading={loading}
          data={societyBillReports?.map((item) => ({
            billType: item?.smbblobbilltypeid,
            month: item?.smbblobmonth,
            year: item?.smbblobyear ,
            billsPdfViewDownload: item?.smbblobsocmthbillfilename,
            billsRegisterViewDownload: item?.smbblobsocbillregifilename,
            billsRegisterExcelViewDownload: item?.blobmembillpdfkey,
            collection: item?.collections,
            acknowledgementSheet: item?.acknowledgementSheet,
          })) || []}
          columns={headCells}
          checkbox={false}
          pagination={true}
        ></SocietyBillsTable>
      </div>
      {!societyBillReports?.length && (
        <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
          No Records
        </div>
      )}
    </div>
  );
};

export default SocietyBills;
