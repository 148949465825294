import React, { useEffect } from "react";
import Layout from "../../../Layout/Layout";
import Button from "../../../../components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import ButtonG from "../../../../components/Button/ButtonG";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import { DropdownFM2 } from "../../../../components/Dropdown/dropdown2";
import { TextInputFM } from "../../../../components/InputFields/TextInput";
import { TextAreaFM } from "../../../../components/InputFields/TextArea";
import { useSelector, useDispatch } from "react-redux";
import { Form, Formik } from "formik";
import { PaymentAndAdjustmentValidationInvoice } from "../../../../validationSchema/ClubSchema/ViewBookingSchema";
import {
  getAdjustmentPaymentDetails,
  getAdjustmentPaymentSave,
} from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageDebitNoteAction";
import { getBookingGLMappingList } from "../../../../redux/actions/ClubActions/ViewBookingsAction";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import { MANAGE_DEBIT_CURRENT_ENDPOINT } from "../../../../Routing/routes";

const PaymentAndAdjustment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { updateLoader, loading, adjustmentPaymentDetails } = useSelector(
    (state) => state.manageDebitNoteReducer
  );
  const { glMappingList } = useSelector((state) => state.viewBookingReducer);

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleSubmit = (values, { resetForm }) => {
    console.log("check");
    dispatch(
      getAdjustmentPaymentSave({
        societyId: loginData.default_community.community_id,
        invid: id,
        ...values,
        onSuccessCallback: () => {
          navigate(MANAGE_DEBIT_CURRENT_ENDPOINT);
          resetForm();
        },
      })
    );
  };

  useEffect(() => {
    if (loginData?.default_community?.community_id && id) {
      dispatch(
        getAdjustmentPaymentDetails({
          societyId: loginData.default_community.community_id,
          invid: id,
        })
      );
    }
    if (loginData?.default_community?.community_id) {
      dispatch(
        getBookingGLMappingList({
          societyId: loginData.default_community.community_id,
        })
      );
    }
  }, [dispatch, id, loginData?.default_community?.community_id]);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        {adjustmentPaymentDetails && (
          <Formik
            initialValues={{
              date: "",
              creditAccount: adjustmentPaymentDetails.data?.credit_name || "1",
              debitac: "",
              amount: adjustmentPaymentDetails.data?.grandtotal || "",
              remarks: "",
              invoice_no: adjustmentPaymentDetails?.data?.invoice_no || "",
              memberid: adjustmentPaymentDetails?.data?.memberid || "",
              maxamount: adjustmentPaymentDetails?.data?.dueamount || "",
            }}
            enableReinitialize
            validationSchema={PaymentAndAdjustmentValidationInvoice}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="border-b border-[#CCC]">
                  <ListHeaderBack
                    title="Payment & Adjustment"
                    onClick={handleNavigation(-1)}
                  >
                    <div className="flex gap-3 text-sm">
                      <div className="border-r border-[#CCC]"></div>
                      <ButtonG
                        label="Cancel"
                        type="button"
                        className="h-8"
                        onClick={handleNavigation(-1)}
                      />
                      <Button
                        type="submit"
                        label={
                          <span className="inline-block w-12">
                            {updateLoader || loading ? (
                              <CircularProgress
                                sx={{ color: "white" }}
                                size={17}
                              />
                            ) : (
                              <>Save</>
                            )}
                          </span>
                        }
                        className="h-8 px-4"
                      />
                    </div>
                  </ListHeaderBack>
                </div>
                <div className="flex flex-col justify-between w-3/4 p-3">
                  <div className="flex items-center">
                    <div className="text-[15px] w-[35%] -mt-3">
                      Date of Adjustment
                    </div>
                    <div className="flex items-center justify-start gap-4">
                      <DatePickerComponentFM
                        className="w-56 h-9"
                        name="date"
                        onDateChange={(selectedDate) => {
                          setFieldValue(
                            "date",
                            moment(selectedDate).format("YYYY-MM-DD")
                          );
                        }}
                        minDate={new Date()}
                        defaultValue={
                          values.date ? new Date(values.date) : null
                        }
                      />
                    </div>
                  </div>
                  <div className="flex items-center mb-2">
                    <div className="text-[15px] w-[35%] -mt-3">
                      Debit Account
                    </div>
                    <div className="flex items-center justify-start gap-4">
                      <DropdownFM2
                        options={
                          glMappingList?.map((item) => ({
                            label: item.name,
                            value: item?.ac_code,
                          })) || []
                        }
                        placeholder="Select Security Deposit A/c"
                        className=""
                        width="224px"
                        height="36px"
                        onSelect={(selectedItem) => {
                          setFieldValue("debitac", selectedItem.value);
                        }}
                        value={
                          values?.debitac && {
                            value: values?.debitac || "",
                            label:
                              glMappingList?.find(
                                (item) => item?.ac_code == values?.debitac
                              ).name || "",
                          }
                        }
                        name="debitac"
                      />
                    </div>
                  </div>
                  <div className="flex items-center mb-4">
                    <div className="text-[15px] w-[35%]">Credit Account</div>
                    <div className="">
                      <span className="text-sm">
                        {adjustmentPaymentDetails.data?.credit_name || "-"}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className="text-[15px] w-[35%] -mt-2">Amount</div>
                    <div className="">
                      <TextInputFM
                        label=""
                        placeholder="Enter Amount"
                        className="w-56"
                        type="number"
                        name="amount"
                        maxLength={15}
                      />
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className="text-[15px] w-[35%] -mt-4">Remarks</div>
                    <div className="">
                      <TextAreaFM
                        name="remarks"
                        className="w-56"
                        row={4}
                        placeholder="Enter Remarks"
                      />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </Layout>
  );
};

export default PaymentAndAdjustment;
