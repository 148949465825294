import React from "react";
import { TextInputFM } from "../../../components/InputFields/TextInput";
import { DropdownFM2 } from "../../../components/Dropdown/dropdown2";
import { useSelector } from "react-redux";
import { FieldArray } from "formik";
import {
  Hoursoptions,
  TimeSlotLessThanOptions,
} from "../../../consts/DropDownOptions";
import { DeleteIcon } from "../../../assets";

import { TimeSelectorFM2 } from "../../../components/TimeSelector/TimeSelector";
import moment from "moment";
import { getDateObjectFromTimeString } from "../../../utils/helpers/universalFunctions";

export const headCells = [
  {
    id: "type",
    label: "Type",
    width: "100px",
  },
  { id: "ticket", label: "Ticket/Passes", width: "100px" },
  {
    id: "lessThan",
    label: "Less Than/Greater Than",
    width: "150px",
  },
  {
    id: "hours",
    label: "Hours",
    width: "250px",
  },
];

function TimeSlotHourlyTable({ values, setFieldValue }) {
  const { typeList } = useSelector((state) => state.addTimeSlotReducer);

  return (
    <table className="w-full">
      <thead className="font-semibold text-sm text-[#AAA]">
        <tr>
          {headCells?.map((item, index) => (
            <td className="p-1 w-28 py-2 mb-2 border-b border-[#DDD]  ">
              {item.label}
            </td>
          ))}
          <td className="p-1 w-28 pb-2 mb-2 border-b border-[#DDD]  "></td>
        </tr>
      </thead>
      <tbody>
        <tr className="h-2"></tr>
        <FieldArray
          name="timeSlotHourly"
          render={(arrayHelpers) => (
            <>
              {values.timeSlotHourly &&
                values.timeSlotHourly.length > 0 &&
                values.timeSlotHourly.map((slot, index) => (
                  <tr className="align-top">
                    <td className="px-1">
                      <DropdownFM2
                        options={
                          typeList?.map((item) => ({
                            label: item.title,
                            value: item.id,
                          })) || []
                        }
                        placeholder="Select Type"
                        className="text-[11px] ms-4"
                        width="120px"
                        height="36px"
                        onSelect={(selectedItem) => {
                          setFieldValue(
                            `timeSlotHourly.${index}.type`,
                            selectedItem.label
                          );
                        }}
                        {...(values.timeSlotHourly[index].type === "" && {
                          value: null,
                        })}
                        name={`timeSlotHourly.${index}.type`}
                      />
                    </td>
                    <td className="px-1">
                      <TextInputFM
                        label=""
                        placeholder="Enter"
                        className="w-28 h-9"
                        type="number"
                        name={`timeSlotHourly.${index}.ticket`}
                      />
                    </td>

                    <td className="px-1">
                      <DropdownFM2
                        options={TimeSlotLessThanOptions}
                        placeholder="Select "
                        className="text-[11px] ms-4"
                        width="140px"
                        height="36px"
                        onSelect={(selectedItem) => {
                          setFieldValue(
                            `timeSlotHourly.${index}.lessThan`,
                            selectedItem.value
                          );
                        }}
                        {...(values.timeSlotHourly[index].lessThan === "" && {
                          value: null,
                        })}
                        name={`timeSlotHourly.${index}.lessThan`}
                      />
                    </td>
                    <td className="px-1">
                      <TimeSelectorFM2
                        width={"110px"}
                        height={"37px"}
                        setTime={(value) => {
                          setFieldValue(
                            `timeSlotHourly.${index}.hours`,
                            moment(new Date(value)).format("HH:mm")
                          );
                        }}
                        name={`timeSlotHourly.${index}.hours`}
                        minutesStep={15}
                        time={getDateObjectFromTimeString(
                          values.timeSlotHourly[index].hours
                        )}
                        minTime={getDateObjectFromTimeString("00:15")}
                      />
                    </td>

                    <td>
                      {
                        <DeleteIcon
                          onClick={() => arrayHelpers.remove(index)}
                          className="fill-red-650 mt-3 ms-6 cursor-pointer"
                        />
                      }
                    </td>
                  </tr>
                ))}
              <tr>
                <td colSpan={7}>
                  <div className="h-8 my-2 flex justify-center">
                    <button
                      type="button"
                      label="Add More"
                      onClick={() =>
                        arrayHelpers.push({
                          type: "",
                          ticket: "",
                          lessThan: "",
                          hours: "",
                        })
                      }
                      className="text-red-650 font-semibold border-2 border-dashed border-red-650 p-2 bg-[#FDF6F6] flex justify-center rounded-md items-center"
                    >
                      Add More
                    </button>
                  </div>
                </td>
              </tr>
            </>
          )}
        ></FieldArray>
      </tbody>
    </table>
  );
}

export default TimeSlotHourlyTable;
