import React, { useEffect } from "react";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import {
  financialYears,
  financialYears2,
} from "../../../../consts/DropDownOptions";
import Search from "../../../../components/SearchBox/Search";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  downloadCSVCashBankBook,
  getBillTypeList,
  getCashBankBook,
} from "../../../../redux/actions/ReportActions/OldFinancialReportsActions";
import moment from "moment";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ACCOUNT_REPORTS_ENDPOINT } from "../../../../Routing/routes";
import ButtonIco from "../../../../components/Button/ButtonPrint";
import { DownloadExt } from "../../../../assets";
import RangeDatePicker from "../../../../components/DatePicker/RangeDatePicker";
import CashBankbookTable from "./CashBankbookTable";
import ButtonG from "../../../../components/Button/ButtonG";
import { downloadExcel } from "../../../../utils/helpers/downloadMedia";

export const headCells = [
  { id: "date", label: "Date", width: "90px" },
  { id: "vouchernumber", label: "Voucher Number", width: "90px" },
  { id: "headOfAccount", label: "Head Of Account", width: "90px" },
  { id: "flatNo", label: "Flat No.", width: "90px" },
  { id: "code", label: "Code", width: "90px" },
  { id: "billName", label: "Bill Name", width: "90px" },
  { id: "fromTo", label: "From/To", width: "90px" },
  { id: "narration", label: "Narration", width: "90px" },
  { id: "chqNo", label: "Chq. No", width: "90px" },
  { id: "receipts", label: "Receipts", width: "90px" },
  { id: "payments", label: "Payments", width: "90px" },
  { id: "balance", label: "Balance", width: "90px" },
  { id: "dc", label: "D/C", width: "90px" },
];

const CashBankBook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, billTypeList, cashBankBook } = useSelector(
    (state) => state.oldFinancialReportsReducers
  );

  const handleSearch = (text) => {
    if (text && text !== searchParams.get("search")) {
      searchParams.delete("page");
      searchParams.delete("search");
      navigate({
        pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["search", text?.trim()],
        ])}`,
      });
    }
  };

  const handleSelectYear = (selectedItem) => {
    searchParams.delete("page");

    if (selectedItem.value?.toString() !== searchParams.get("year")) {
      searchParams.delete("year");
      navigate({
        pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["year", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleStartDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !==
      searchParams.get("startDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("startDate");
      navigate({
        pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["startDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleEndDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !== searchParams.get("endDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("endDate");
      navigate({
        pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["endDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handelSelectBillType = (selectedItem) => {
    searchParams.delete("page");

    if (
      selectedItem.value?.toString() !== searchParams.get("daybook_short_form")
    ) {
      searchParams.delete("daybook_short_form");
      navigate({
        pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["daybook_short_form", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleClear = () => {
    navigate({
      pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
      search: `?${createSearchParams([["tab", 4]])}`,
    });
  };
  const handleDownloadCSV = () => {
    const onSucessCallback = (data) => {
      downloadExcel({ data, fileName: `Cash/BankBooks.xlsx` });
    };
    const data = {
      society_id: loginData?.default_community?.zipgrid_socsrno,
      // societyId: loginData?.default_community?.community_id,
      fin_year: searchParams.get("year"),
      daybook_short_form: searchParams.get("daybook_short_form"),
      from_date: searchParams.get("startDate"),
      to_date: searchParams.get("endDate"),
      page: searchParams.get("page") || 1,
      limit: searchParams.get("pageLimit") || 10,
      onSucessCallback,
    };
    dispatch(downloadCSVCashBankBook(data));
  };

  useEffect(() => {
    dispatch(
      getBillTypeList({
        society_id: loginData?.default_community?.zipgrid_socsrno,
        daybook_filter: "receiptPayment",
      })
    );

    dispatch(
      getCashBankBook({
        society_id: loginData?.default_community?.zipgrid_socsrno,
        daybook_short_form: searchParams.get("daybook_short_form"),
        fin_year: searchParams.get("year"),
        // fin_year: "202324",
        from_date: searchParams.get("startDate"),
        to_date: searchParams.get("endDate"),
        page: searchParams.get("page") || 1,
        limit: searchParams.get("pageLimit") || 10,
      })
    );
  }, [searchParams]);

  return (
    <div className="">
      <div>
        <ListHeader leftContent="Cash/BankBooks">
          <Search
            height="8"
            onclick={handleSearch}
            value={searchParams.get("search")}
          />
          <span className="border-r border-[#CCC]"></span>
          {/* <ButtonIco
            icon={DownloadExt}
            children="Download PDF"
            className="h-8"
            type="button"
            onClick={""}
          /> */}
          <ButtonIco
            icon={DownloadExt}
            children="Download Excel"
            className="h-8"
            type="button"
            onClick={handleDownloadCSV}
          />
        </ListHeader>
      </div>
      <div className="flex gap-3 pb-2">
        <Dropdown2
          options={financialYears2}
          placeholder="Choose Financial Year"
          className=""
          width="172px"
          height="32px"
          onSelect={(selectedItem) => {
            const valueParts = selectedItem.value.split("-");
            const selectedValue = `${valueParts[0]}${valueParts[1].slice(2)}`;
            handleSelectYear({ ...selectedItem, value: selectedValue });
          }}
          value={
            searchParams.get("year") && {
              value: searchParams.get("year"),
              label: financialYears2?.find((item) => {
                const valueParts = item.value.split("-");
                const selectedValue = `${valueParts[0]}${valueParts[1].slice(
                  2
                )}`;
                return selectedValue === searchParams.get("year");
              })?.label,
            }
          }
        />
        <RangeDatePicker
          className="w-48 h-8"
          defaultStartValue={
            searchParams.get("startDate")
              ? new Date(searchParams.get("startDate"))
              : ""
          }
          defaultEndValue={
            searchParams.get("endDate")
              ? new Date(searchParams.get("endDate"))
              : ""
          }
          onStartDateChange={handleStartDate}
          onEndDateChange={handleEndDate}
        />

        <Dropdown2
          options={
            billTypeList?.map((item) => ({
              value: item?.daybookshfm,
              label: item?.daybookname,
            })) || []
          }
          placeholder="Select JBook"
          className=""
          width="182px"
          height="32px"
          onSelect={(selectedItem) => {
            handelSelectBillType(selectedItem);
          }}
          value={
            searchParams.get("daybook_short_form") && {
              value: searchParams.get("daybook_short_form"),
              label: billTypeList?.find(
                (item) =>
                  item.daybookshfm === searchParams.get("daybook_short_form")
              )?.daybookname,
            }
          }
        />
        <span className="border-r border-[#CCC]"></span>
        <ButtonG label="Clear" onClick={handleClear} />
      </div>
      <div className="ml-1">
        <CashBankbookTable
          loading={loading}
          data={
            cashBankBook?.map((item) => ({
              date: item?.voucherdate
                ? moment(new Date(item?.voucherdate))?.format("DD-MM-YYYY")
                : "-",
              vouchernumber: item?.vouchernumber?.value,
              headOfAccount: item?.accountname,
              flatNo: item?.refflatno,
              code: item?.subledger,
              // billName: item?. ,
              // fromTo: item?. ,
              narration: item?.narration,
              chqNo: item?.chequenumber,
              receipts: item?.amountcredit,
              payments: item?.amountdebit,
              balance: item?.mAccBalance,
              dc: item?.entrydrcr,
            })) || []
          }
          columns={headCells}
          checkbox={false}
          pagination={
            cashBankBook?.list?.length > 0 ? cashBankBook?.list?.length : 0
          }
          totalCount={cashBankBook?.total_count}
          totalPages={cashBankBook?.total_pages}
          start={
            (cashBankBook?.current_page - 1) *
              (parseInt(cashBankBook?.page_limit) || 10) +
            1
          }
          end={
            (cashBankBook?.current_page - 1) *
              (parseInt(cashBankBook?.page_limit) || 10) +
            cashBankBook?.list?.length
          }
          currentPage={parseInt(cashBankBook?.current_page)}
          rowsPerPage={searchParams.get("pageLimit") || 10}
        ></CashBankbookTable>
      </div>
      {!cashBankBook?.length && !loading && (
        <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
          No Records
        </div>
      )}
    </div>
  );
};

export default CashBankBook;
