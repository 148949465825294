import * as Yup from "yup";


export const loginValidation = ()=>{
    return Yup.object({
        email: Yup.string().matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/, "*Please enter valid email address").required("*Please enter email address"),
        password: Yup.string()
            .required("*Password is required")
           
    })
} 

export const forgotPasswordValidation = ()=>{
    return Yup.object({
        email: Yup.string().matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/, "*Please enter valid email address").required("*Please enter email address"),           
    })
} 

export const resetPasswordValidation = ()=>{
    return Yup.object({
        password: Yup.string()
            .required("*New password is required")
            .matches(/^[\S]+$/ , "*Password cannot contain spaces")
            .matches(/^[^_]+$/, "*Password cannot contain underscores")
            .min(8, "*Password must be at least 8 characters long")
            .max(255, "*Password must be no more than 255 characters long")
            .matches( /[a-z]/, "*Password must contain at least 1 lowercase letter.")
            .matches( /[A-Z]/, "*Password must contain at least 1 uppercase letter.")
            .matches( /[0-9]/, "*Password should contain altleast 1 digit.")
            .matches(  /[!@#$%^&*]/, "*Password should contain altleast 1 special character.")
            ,
        confirmPassword: Yup.string()
            .required("*Confirm password is required")
            .oneOf([Yup.ref('password'), null], 'Passwords must match')   
    })
} 
