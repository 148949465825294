import React from "react";
import { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import { TextAreaFM } from "../../../../components/InputFields/TextArea";
import { TextInputFM } from "../../../../components/InputFields/TextInput";
import { DropdownFM2 } from "../../../../components/Dropdown/dropdown2";
import { Form, Formik } from "formik";
import { NEFTSchema } from "../../../../validationSchema/financeSchema/billingManagementSchema";
import moment from "moment";
import Button from "../../../../components/Button/Button";
import { CircularProgress } from "@mui/material";

const NEFTDetailsForm = ({
  invoiceDetails,
  updateLoader,
  loginData,
  handleSubmit: pHandleSubmit,
}) => {

  const handleSubmit = (values) => {
    pHandleSubmit(values);
  };

  return (
    <div className="w-full gap-5 flex flex-col justify-between mt-2">
      <Formik
        initialValues={{
          amount:
            invoiceDetails?.data?.invoicedetail?.grandtotal?.amount2 || "",
          bankId: invoiceDetails?.data?.bankdetail?.bankid || "",
          transaction_number: "",
          date: "",
          paymentdate: "",
          cleardate: "",
          bankname: "",
          remark: "",
          modeofpayment: "NEFT",
        }}
        enableReinitialize
        validationSchema={NEFTSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <div className="pl-2 font-semibold">
                <span>NEFT Details</span>
              </div>
              <div className="w-[50%] p-2">
                <div className="flex items-center justify-between">
                  <div className="text-sm -mt-4">Amount</div>
                  <div className="">
                    <TextInputFM
                      label=""
                      placeholder="Enter Amount"
                      className="w-56"
                      name="amount"
                      maxLength={8}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="text-sm -mt-4">Society Bank Name</div>
                  <div className="">
                    <DropdownFM2
                      options={
                        invoiceDetails?.data?.bankdetail?.map((item) => ({
                          label: `${item?.bank_name} [Account - ${item?.account_no}]`,
                          value: item?.bankid,
                        })) || []
                      }
                      placeholder="Select"
                      width="224px"
                      height="36px"
                      onSelect={(selectedItem) => {
                        formik.setFieldValue("bankId", selectedItem.value);
                      }}
                      value={
                        formik.values.bankId === ""
                          ? null
                          : {
                              label: invoiceDetails?.data?.bankdetail?.find(
                                (item) => item.bankid === formik.values.bankId
                              )?.bank_name,
                              values: formik.values.bankId,
                            }
                      }
                      name="bankId"
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="text-sm -mt-4">Transaction Number</div>
                  <div className="">
                    <TextInputFM
                      label=""
                      placeholder="Enter Transaction No."
                      className="w-56"
                      name="transaction_number"
                      type="alphaNumber"
                      maxLength={4}
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <span className="text-sm mt-2">Transaction Date</span>
                  <div className="flex gap-3">
                    <DatePickerComponentFM
                      className="w-56 h-9 ml-4 justify-between"
                      onDateChange={(selectedDate) => {
                        formik.setFieldValue(
                          `date`,
                          moment(selectedDate).format("YYYY-MM-DD")
                        );
                      }}
                      minDate={new Date()}
                      name="date"
                      defaultValue={
                        formik.values.date
                          ? new Date(formik.values.date)
                          : null
                      }
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="text-sm -mt-4">Bank Name</div>
                  <div className="">
                    <TextInputFM
                      label=""
                      placeholder="Enter Bank Name"
                      className="w-56"
                      name="bankname"
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <span className="text-sm mt-2">Payment Date</span>
                  <div className="flex gap-3">
                    <DatePickerComponentFM
                      className="h-9 w-56"
                      name={`paymentdate`}
                      onDateChange={(selectedDate) => {
                        formik.setFieldValue(
                          `paymentdate`,
                          moment(selectedDate).format("YYYY-MM-DD")
                        );
                      }}
                      defaultValue={
                        formik.values.paymentdate
                          ? new Date(formik.values.paymentdate)
                          : null
                      }
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <span className="text-sm mt-2">Payment Clear Date</span>
                  <div className="flex gap-3">
                    <DatePickerComponentFM
                      className="h-9 w-56"
                      name={`cleardate`}
                      onDateChange={(selectedDate) => {
                        formik.setFieldValue(
                          `cleardate`,
                          moment(selectedDate).format("YYYY-MM-DD")
                        );
                      }}
                      defaultValue={
                        formik.values.cleardate
                          ? new Date(formik.values.cleardate)
                          : null
                      }
                    />
                  </div>
                </div>
                <div>
                  <TextAreaFM
                    label="Remarks"
                    placeholder="Enter Remarks"
                    className="w-56 h-20"
                    name="remark"
                    maxLength={300}
                  />
                </div>
                <div className="ml-[250px] mb-2">
                  <Button
                    label={
                      <span className="w-16 inline-block">
                        {updateLoader ? (
                          <CircularProgress sx={{ color: "white" }} size={17} />
                        ) : (
                          "Submit"
                        )}
                      </span>
                    }
                    className="px-5 h-8"
                    type="submit"
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default NEFTDetailsForm;
