import * as types from '../index.types';

export function getComplaintDashboardContentList(data) {
    return {
        type: types.GET_COMPLAINT_DASHBOARD_LIST,
        data
    };
}

export function getComplaintDashboardList(data) {
    return {
        type: types.GET_COMPLAINT_DASHBOARD_CONTENT_LIST,
        data
    };
}

export function getComplaintAgingList(data) {
    return {
        type: types.GET_COMPLAINT_AGING_LIST,
        data,
    };
}

export function getComplaintEmailSms(data) {
    return {
        type: types.GET_COMPLAINT_EMAIL_SMS_LIST,
        data,
    };
}

export function updateComplaintEmailSms(data) {
    return {
        type: types.UPDATE_COMPLAINT_EMAIL_SMS_LIST,
        data
    };
}

export function getComplaintEscalationSettings(data) {
    return {
        type: types.GET_COMPLAINT_ESCALATION_LIST_SETTINGS,
        data,
    };
}

export function updateComplaintEscalationSettings(data) {
    return {
        type: types.UPDATE_COMPLAINT_ESCALATION_LIST_SETTINGS,
        data
    };
}

export function getLoggedComplaintTicketDetails(data) {
    return {
        type: types.GET_COMPLAINT_LOGGED_TICKET,
        data,
    }
}

export function getAssignedReqRoles(data) {
    return {
        type: types.GET_ASSIGNED_REQ_ROLES,
        data,
    };
}

export function getAssignedSubReqRoles(data) {

    return {
        type: types.GET_ASSIGNED_REQ_SUB_ROLES,
        data,
    };
}

export function getAssignedMemberListRoles(data) {
    return {
        type: types.GET_ASSIGNED_MEMBERS_LIST_ROLES,
        data,
    };
}

export function getAssignedComplaintData(data) {

    return {
        type: types.GET_ASSIGNED_COMPLAINT_DATA,
        data,
    };
}

export function addAssignedComplaintData(data) {

    return {
        type: types.ADD_ASSIGNED_COMPLAINT_DATA,
        data,
    };
}

export function changeComplaintStatus(data) {
    return {
        type: types.CHANGE_COMPLAINT_STATUS,
        data
    };
}

export function downloadComplaintsExcelSheet(data) {
    return {
        type: types.DOWNLOAD_COMPLAINTS_EXCEL_SHEET,
        data
    };
}

export function getPostComment(data) {

    return {
        type: types.GET_POST_COMMENT_DATA,
        data,
    };
}

export function addPostComment(data) {

    return {
        type: types.ADD_POST_COMMENT_DATA,
        data,
    };
}

export function getComplaintStatusList(data) {
    return {
        type: types.GET_COMPLAINT_STATUS_DATA,
        data
    };
}

export function getSocietyDetailsList(data) {
    return {
        type: types.GET_SOCIETY_LIST_DETAILS_DATA,
        data
    }
}

export function addComplaintDashboardReview(data) {
    return {
        type: types.ADD_COMPLAINT_DASHBOARD_REVIEW,
        data
    }
}