import React, { useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader/Loader";
// import { getChequeDepositSlipReport } from "../../../redux/actions/FinanceActions/BillingReportsAction";
import parse from "html-react-parser";
import { getDeletedInvoiceReport } from "../../../../redux/actions/ReportActions/FinanceReportActions";
import moment from "moment";
import { validateDate } from "../../../../utils/helpers/universalFunctions";
import { printMyInvoiceBill } from "../../../../redux/actions/FinanceActions/MyInvoiceActions";

const PrintDeletedInvoiceReport = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [headCells, setHeadCells] = useState([]);

  const { loginData } = useSelector((state) => state.loginReducer);
  const { printInvoice, printLoader } = useSelector(
    (state) => state.myInvoiceReducer
  );

  useEffect(() => {
    if (!isNaN(id))
      dispatch(
        printMyInvoiceBill({
          societyId: loginData?.default_community?.community_id,
          invid: [parseInt(id)],
          onSuccessCallback: () => {
            setTimeout(function () {
              window.print();
            }, 1000);
          },
        })
      );
  }, []);

  return (
    <div>
      {printLoader && <Loader />}
      {printInvoice ? (
        parse(printInvoice)
      ) : (
        <div className="text-[11px] my-10 text-center italic">No Records</div>
      )}
    </div>
  );
};

export default PrintDeletedInvoiceReport;
