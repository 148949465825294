import React, { useEffect, useState } from "react";
import Search from "../../../components/SearchBox/Search";
import { getExpectedVisitorList, getVisitorOrVehicleDashboardData } from "../../../redux/actions/GateManagementActions/VisitorAndVehicleInAndOutAction";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ExpectedVisitorTable from "./ExpectedVisitorTable";
import ButtonG from "../../../components/Button/ButtonG";
import moment from "moment";
import { RefreshIcon } from "../../../assets";

export const headCells = [
  { id: "sNo", label: "S No.", width: "70px" },
  { id: "name", label: "Name", width: "200px" },
  { id: "visitedDate", label: "Visited Date", sort: true, width: "200px" },
  { id: "flat", label: "Flat", sort: true, width: "120px" },
  { id: "contact", label: "Contact", sort: true, width: "150px" },
  { id: "otp", label: "OTP", sort: true, width: "120px" },
  { id: "action", label: "Action", sort: true, width: "80px" },
];


const ExpectedVisitor = ({filter}) => {
  const [tableFilter, setTableFilter] = useState({
    searchText: '',
    page: 1,
    pageLimit: 10,
    refreshedTime: new Date()
  })
  const dispatch =useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading, expectedVisitorList} = useSelector(state=>state.visitorAndVehicleInAndOutReducer)

  const handleSearch = (text)=>{
    if(text){
      setTableFilter(prev=> ({...prev, searchText: text?.trim(), page: 1}))
    }
  }

  const handleClear = ()=>{
    setTableFilter({
      searchText: '',
      page: 1,
      pageLimit: 10
    })
  }
  const getDashboardData = ()=>{
    const data={
      societyId: loginData?.default_community?.community_id,
      ...filter,
      flag: 'visitors'
    }
    dispatch(getVisitorOrVehicleDashboardData(data))
  }

  const handleRefresh = ()=>{
    if(moment(tableFilter.refreshedTime)?.format('DD-MM-YYYY hh:mm A') !== moment()?.format('DD-MM-YYYY hh:mm A')){
      setTableFilter(prev=> ({...prev, refreshedTime: new Date()}))
    }
    getDashboardData()
  }

  const getTableData = ()=>{
    const data={
      societyId: loginData?.default_community?.community_id,
      ...tableFilter,
      ...filter,
    }
    dispatch(getExpectedVisitorList(data))
  }
  
  useEffect(()=>{
    setTableFilter(prev=>({...prev, page: 1}))
  }, [filter])
  
  useEffect(()=>{
    getTableData()
  }, [tableFilter])

  return (
    <>
      <div>
        <div className="flex justify-between items-center my-2">
          <div className="flex">
            <Search height="8" placeholder={'Name/Contact'} onclick={handleSearch} value={tableFilter.searchText} />
            <ButtonG label='Clear' className='h-8 ms-2' onClick={handleClear} />
          </div>
          <div className="text-xs flex text-[#aaaaaa]">Updated On: {moment(filter.refreshedTime)?.format('DD-MM-YYYY hh:mm A')} <span className={`ms-2 cursor-pointer ${loading ? 'animate-spin': ''}`} onClick={handleRefresh}><RefreshIcon/></span> </div>
        </div>
        <div className="ml-1">
          <ExpectedVisitorTable
            loading={loading}
            data={expectedVisitorList?.list?.map((item, index)=>({
              sNo: (((expectedVisitorList?.current_page_no - 1) * (parseInt(expectedVisitorList?.page_limit) || 10) + 1)+index)?.toString()?.padStart(2, "0"),
              visitoLogId: item?.visitor_log_id,
              name: `${item?.vname} ${item?.lname ? item?.lname : ''}` || '-',
              visitedDate: item?.visit_date || '-',
              flat: item?.whomtomeet || '-',
              contact: `${item?.VCountryCode ? `+${item?.VCountryCode?.replace('+', '')}-` : ''}${item.vphone}` || '-',
              otp: item?.otp || '-',
            }))}
            columns={headCells}
            checkbox={false}
            pagination={expectedVisitorList?.list?.length}
            totalCount={expectedVisitorList?.total_record}
            totalPages={expectedVisitorList?.total_pages}
            start={(expectedVisitorList?.current_page_no - 1) * (parseInt(expectedVisitorList?.page_limit) || 10) + 1}
            end={(expectedVisitorList?.current_page_no - 1) * (parseInt(expectedVisitorList?.page_limit) || 10) + expectedVisitorList?.list?.length}
            currentPage={parseInt(expectedVisitorList?.current_page_no)}
            rowsPerPage={parseInt(expectedVisitorList?.page_limit) || 10}
            allFilter={{...filter, ...tableFilter}}
            setTableFilter={setTableFilter}
          ></ExpectedVisitorTable>
          </div>
          {!expectedVisitorList?.list?.length && !loading && (
            <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
              No Records
            </div>
          )}
      </div>
    </>
  );
};

export default ExpectedVisitor;
