
export function  percentage(percentage, totalValue){
  return (percentage * totalValue) / 100;
}
export default function invoiceCalculation (invoicedata, index) {
  if (invoicedata[index].invoicedetail) {
    let subtotal = 0;
    let grandtotal = 0;
    let sgstrate = parseInt(invoicedata["servicetaxrate"]);
    let cgstrate = parseInt(invoicedata["sbcessrate"]);
    let igstrate = parseInt(invoicedata["kkcessrate"]);
    let exemptionlimit = parseFloat(invoicedata["exemptionlimit"]);

    let mainhead = invoicedata[index]?.invoicedetail?.mainhead ? Object.entries(invoicedata[index]?.invoicedetail?.mainhead) : [];
    let morehead = invoicedata[index]?.invoicedetail?.morehead? Object.entries(invoicedata[index]?.invoicedetail?.morehead): [];
    let arrearhead = invoicedata[index]?.invoicedetail?.arearhead ? Object.entries(invoicedata[index]?.invoicedetail?.arearhead): [];
    let roundoff = invoicedata[index]?.invoicedetail?.roundoff ? Object.entries(invoicedata[index]?.invoicedetail?.roundoff): [];
    let sgstamount = 0;
    let cgstamount = 0;
    let igstamount = 0;
    let current_exemption = 0;
    mainhead.forEach((key) => {
      if (key[0] !== "Sb_Cess" &&key[0] !== "servicetax" && key[0] !== "Kk_Cess") {
        if (key[1]?.gst_applicable === "Yes" && key[1]?.exemption === 0) {
          current_exemption += parseFloat(key[1]?.value);
        }
      }
    });
    if (morehead.length > 0) {
      morehead.forEach((key) => {
        var moreValue = parseFloat(key[1]?.value)
        if(key[1]?.head_type==="percentage"){
          var totalValue = 0 ;
          var percentageValue = !isNaN(key[1]?.value) ? parseFloat(key[1].percentage) : 0;
          //console.log(key[1]?.head_deatail)
          //console.log(percentage)
          if(key[1]?.head_deatail.length > 0) { 
            key[1]?.head_deatail.forEach((headKey) => {
              if( invoicedata[index].invoicedetail.mainhead && invoicedata[index].invoicedetail.mainhead[headKey]){
                totalValue += invoicedata[index].invoicedetail.mainhead[headKey].value
                //console.log(totalValue)
              }
              if(invoicedata[index].invoicedetail.morehead && invoicedata[index].invoicedetail.morehead[headKey]){
                totalValue += invoicedata[index].invoicedetail.morehead[headKey].value
                //console.log(totalValue)
              }
              if(headKey==="Current Period Interest"){
                //console.log(arrearhead.find((item) => item[1]?.key === headKey)[1]['value'])
                totalValue += arrearhead.length > 0 ? arrearhead.find((item) => item[1]?.key === headKey)[1]['value'] : 0
                //console.log(totalValue)
                //totalValue += invoicedata[index].invoicedetail.morehead[headKey].value
              }
              
            })
          }
          moreValue = totalValue
          invoicedata[index].invoicedetail.morehead[key[0]].value = percentage(percentageValue,totalValue).toFixed(2)
        }
        if (key[1]?.gst_applicable === "Yes" && key[1]?.exemption === 0) {
          current_exemption += moreValue;
        }
        
      });
    }
    if (arrearhead.length > 0) {
      arrearhead.forEach((key) => {
        if (key[1]?.gst_applicable === "Yes" && key[1]?.exemption === 0) {
          current_exemption += parseFloat(key[1]?.value);
        }
      });
    }
    //console.log(current_exemption,'current_exemption')
    if(invoicedata["auto_calculation"]==="Yes" && mainhead.length > 0) {
      if (!isNaN(sgstrate)) {
        mainhead.forEach((key) => {
          if (key[0] !== "Sb_Cess" && key[0] !== "servicetax" && key[0] !== "Kk_Cess") {
            if (key[1]?.exemption === 1) {
              sgstamount += !isNaN(key[1]?.value)? percentage(sgstrate, parseFloat(key[1]?.value)): 0;
            } else if (current_exemption > exemptionlimit && key[1]?.gst_applicable === "Yes") {
              sgstamount += !isNaN(key[1]?.value) ? percentage(sgstrate, parseFloat(key[1]?.value)) : 0;
            }
          }
        });
        if (morehead.length > 0) {
          morehead.forEach((key) => {
            if (key[1]?.exemption === 1) {
              sgstamount += !isNaN(key[1]?.value) ? percentage(sgstrate, parseFloat(key[1]?.value)) : 0;
            } else if ( current_exemption > exemptionlimit && key[1]?.gst_applicable === "Yes") {
              sgstamount += !isNaN(key[1]?.value) ? percentage(sgstrate, parseFloat(key[1]?.value)) : 0;
            }
          });
        }
        if (arrearhead.length > 0) {
          arrearhead.forEach((key) => {
            if (key[1]?.exemption === 1) {
              sgstamount += !isNaN(key[1]?.value)? percentage(sgstrate, parseFloat(key[1]?.value)): 0;
            } else if (current_exemption > exemptionlimit && key[1]?.gst_applicable === "Yes") {
              sgstamount += !isNaN(key[1]?.value) ? percentage(sgstrate, parseFloat(key[1]?.value)) : 0;
            }
          });
        }
          if (invoicedata[index].invoicedetail.mainhead.servicetax && Object.entries(invoicedata[index].invoicedetail.mainhead.servicetax).length > 0) {
            invoicedata[index].invoicedetail.mainhead["servicetax"].value =sgstamount.toFixed(2);
            //subtotal += sgstamount
          }
        
      }
      if (!isNaN(cgstrate)) {
        mainhead.forEach((key) => {
          if (key[0] !== "Sb_Cess" && key[0] !== "servicetax" && key[0] !== "Kk_Cess") {
            if (key[1]?.exemption === 1) {
              cgstamount += !isNaN(key[1]?.value) ? percentage(cgstrate, parseFloat(key[1]?.value)): 0;
            } else if (current_exemption > exemptionlimit && key[1]?.gst_applicable === "Yes") {
              cgstamount += !isNaN(key[1]?.value) ? percentage(cgstrate, parseFloat(key[1]?.value)) : 0;
            }
          }
        });
        if (morehead.length > 0) {
          morehead.forEach((key) => {
            if (key[1]?.exemption === 1) {
              cgstamount += !isNaN(key[1]?.value)? percentage(cgstrate, parseFloat(key[1]?.value)): 0;
            } else if (current_exemption > exemptionlimit && key[1]?.gst_applicable === "Yes") {
              cgstamount += !isNaN(key[1]?.value)? percentage(cgstrate, parseFloat(key[1]?.value)): 0;
            }
          });
        }
        if (arrearhead.length > 0) {
          arrearhead.forEach((key) => {
            if (key[1]?.exemption === 1) {
              cgstamount += !isNaN(key[1]?.value)
                ? percentage(cgstrate, parseFloat(key[1]?.value))
                : 0;
            } else if (
              current_exemption > exemptionlimit &&
              key[1]?.gst_applicable === "Yes"
            ) {
              cgstamount += !isNaN(key[1]?.value)
                ? percentage(cgstrate, parseFloat(key[1]?.value))
                : 0;
            }
          });
        }
        if (
          invoicedata[index].invoicedetail.mainhead["Sb_Cess"] &&
          Object.entries(invoicedata[index].invoicedetail.mainhead["Sb_Cess"])
            .length > 0
        ) {
          invoicedata[index].invoicedetail.mainhead["Sb_Cess"].value =
            cgstamount.toFixed(2);
          //subtotal += cgstamount
        }
      }
      if (!isNaN(igstrate)) {
        mainhead.forEach((key) => {
          if (
            key[0] !== "Sb_Cess" &&
            key[0] !== "servicetax" &&
            key[0] !== "Kk_Cess"
          ) {
            if (key[1]?.exemption === 1) {
              igstamount += !isNaN(key[1]?.value)
                ? percentage(igstrate, parseFloat(key[1]?.value))
                : 0;
            } else if (
              current_exemption > exemptionlimit &&
              key[1]?.gst_applicable === "Yes"
            ) {
              igstamount += !isNaN(key[1]?.value)
                ? percentage(igstrate, parseFloat(key[1]?.value))
                : 0;
            }
          }
        });
        if (morehead.length > 0) {
          morehead.forEach((key) => {
            if (key[1]?.exemption === 1) {
              igstamount += !isNaN(key[1]?.value)
                ? percentage(igstrate, parseFloat(key[1]?.value))
                : 0;
            } else if (
              current_exemption > exemptionlimit &&
              key[1]?.gst_applicable === "Yes"
            ) {
              igstamount += !isNaN(key[1]?.value)
                ? percentage(igstrate, parseFloat(key[1]?.value))
                : 0;
            }
          });
        }
        if (arrearhead.length > 0) {
          arrearhead.forEach((key) => {
            if (key[1]?.exemption === 1) {
              igstamount += !isNaN(key[1]?.value)
                ? percentage(igstrate, parseFloat(key[1]?.value))
                : 0;
            } else if (
              current_exemption > exemptionlimit &&
              key[1]?.gst_applicable === "Yes"
            ) {
              igstamount += !isNaN(key[1]?.value)
                ? percentage(igstrate, parseFloat(key[1]?.value))
                : 0;
            }
          });
        }
        if (
          invoicedata[index].invoicedetail.mainhead["KK_Cess"] &&
          Object.entries(invoicedata[index].invoicedetail.mainhead["KK_Cess"])
            .length > 0
        ) {
          invoicedata[index].invoicedetail.mainhead["KK_Cess"].value =
            igstamount.toFixed(2);
          //subtotal += igstamount
        }
      }
    }
   
    if (mainhead.length > 0) {
      mainhead.forEach((key) => {
        subtotal += !isNaN(key[1]?.value) ? parseFloat(key[1]?.value) : 0;
      });
    }
    if (morehead.length > 0) {
      morehead.forEach((key) => {
        subtotal += !isNaN(key[1]?.value) ? parseFloat(key[1]?.value) : 0;
      });
    }

    subtotal = !isNaN(subtotal) ? subtotal.toFixed(2) : 0;
    if (roundoff.length > 0) {
      //if(roundoff[])
      var subtotal_withpoint = subtotal;
      subtotal = Math.round(subtotal);
      var roundoff_value = subtotal - subtotal_withpoint;
      invoicedata[index].invoicedetail.roundoff["value"] =
        roundoff_value.toFixed(2);
    }
    grandtotal += parseFloat(subtotal);
    if (arrearhead.length > 0) {
      arrearhead.map((key) => {
         grandtotal += !isNaN(key[1]?.value) ? parseFloat(key[1]?.value) : 0;
      });
    }
    invoicedata[index].invoicedetail.subtotal = subtotal;
    invoicedata[index].invoicedetail.grandtotal = grandtotal.toFixed(2);
  }
  return invoicedata;
}
