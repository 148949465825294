import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Layout from "../../Layout/Layout";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import Button from "../../../components/Button/Button";
import {
  getNoticeCircularDetails,
  postNoticeCircularComment,
} from "../../../redux/actions/SocialAndCommunicationActions/NoticeAndCircularAction";
import ButtonG from "../../../components/Button/ButtonG";
import { TextArea } from "../../../components/InputFields/TextArea";
import Dropdown2 from "../../../components/Dropdown/dropdown2";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
function ViewNoticeCircularArchive() {
  //   const [noticeCircularComment, setNoticeCircularComment] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, status } = useParams();
  const { loading, noticeCircularDetail } = useSelector(
    (state) => state.noticeAndCircularReducer
  );
  const { loginData } = useSelector((state) => state.loginReducer);

  // const contentRef = useRef();

  // useEffect(() => {
  //   if (noticeCircularDetail?.notice && contentRef) {
  //     contentRef.current.innerHTML = noticeCircularDetail?.notice;
  //   }
  // }, [noticeCircularDetail?.notice]);

  useEffect(() => {
    dispatch(
      getNoticeCircularDetails({
        id,
      })
    );
  }, []);
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack
            title="View Notice & Circular"
            onClick={() => {
              navigate(-1);
            }}
          ></ListHeaderBack>{" "}
        </div>
        {loading && id !== noticeCircularDetail?.notice_detail?.id ? (
          <Loader />
        ) : (
          <>
            <div className="p-2 gap-2">
              <table className="table-auto w-full text-[#222222] text-sm">
                <tbody>
                  <tr>
                    <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                      Subject:
                    </td>
                    <td className="py-2">
                      {noticeCircularDetail?.notice_detail?.title || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                      Type:
                    </td>
                    <td className="py-2">
                      {noticeCircularDetail?.notice_detail?.type || "-"}
                    </td>
                  </tr>

                  <tr>
                    <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                      Description:
                    </td>
                    <td className="py-2">
                      {noticeCircularDetail?.notice_detail?.description ? (
                        <div
                          className="vc-description  border-2 border-gray-100 bg-[##f5f5f5] p-2"
                          // ref={contentRef}
                          dangerouslySetInnerHTML={{
                            __html:
                              noticeCircularDetail?.notice_detail?.description,
                          }}
                        ></div>
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                  {/* <tr>
                    <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                      Date of Notice:
                    </td>
                    <td className="py-2">
                      {noticeCircularDetail?.notice_detail?.date !==
                      "0000-00-00 00:00:00"
                        ? new Date(noticeCircularDetail?.notice_detail?.date)
                            .toLocaleString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })
                            .split("/")
                            .join("-")
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                      Expiry Date:
                    </td>
                    <td className="py-2">
                      {noticeCircularDetail?.notice_detail?.expiry_date !==
                      "0000-00-00 00:00:00"
                        ? new Date(
                            noticeCircularDetail?.notice_detail?.expiry_date
                          )
                            .toLocaleString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })
                            .split("/")
                            .join("-")
                        : "-"}
                    </td>
                  </tr> */}
                  <tr>
                    <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                      Attachment:
                    </td>

                    <td>
                      {noticeCircularDetail?.attachments &&
                      noticeCircularDetail?.attachments?.length > 0 ? (
                        <ol className=" list-[auto] ps-4">
                          {noticeCircularDetail?.attachments?.map(
                            (attach, index) => {
                              {
                                return attach?.attachment_url ? (
                                  <>
                                    <li key={index}>
                                      <Link
                                        target="_blank"
                                        className="ms-1 underline text-xs text-blue-500"
                                        to={attach?.attachment_url}
                                      >
                                        View Uploaded Doc
                                      </Link>
                                    </li>
                                  </>
                                ) : (
                                  <li key={index}>-</li>
                                );
                              }
                            }
                          )}
                        </ol>
                      ) : (
                        <span>-</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                      Created By:
                    </td>
                    <td className="py-2">
                      {noticeCircularDetail?.notice_detail?.createdBy || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                      Created On:
                    </td>
                    <td className="py-2">
                      {moment(
                        new Date(noticeCircularDetail?.notice_detail?.date)
                      )?.format("MMM DD, YYYY") || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                      Reference No:
                    </td>
                    <td className="py-2">
                      {noticeCircularDetail?.notice_detail?.ref_no || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                      Notice Date:
                    </td>
                    <td className="py-2">
                      {moment(
                        new Date(
                          noticeCircularDetail?.notice_detail?.notice_date
                        )
                      )?.format("MMM DD, YYYY") || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                      Expiry Date:
                    </td>
                    <td className="py-2">
                      {moment(
                        new Date(
                          noticeCircularDetail?.notice_detail?.expiry_date
                        )
                      )?.format("MMM DD, YYYY") || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                      Email Notification Send To:
                    </td>
                    <td className="py-2">
                      <ul className="">
                        {noticeCircularDetail?.notice_detail?.email_send_to
                          .length > 0
                          ? noticeCircularDetail?.notice_detail?.email_send_to.map(
                              (item, index) => {
                                return <li> {index + 1 + ". " + item}</li>;
                              }
                            )
                          : "-"}
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
}

export default ViewNoticeCircularArchive;
