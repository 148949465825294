import React, { useState } from "react";
import Layout from "../../../Layout/Layout";
import Button from "../../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import { societyStaffTypeOptions } from "../../../../consts/DropDownOptions";
import RangeDatePicker from "../../../../components/DatePicker/RangeDatePicker";
import ButtonG from "../../../../components/Button/ButtonG";
import SentNoticeTable from "../SavedMinutes/SavedMinutesTable";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import ArchiveTenantModal from "../../../../components/Modal/ArchiveTenantModal";
import moment from "moment";
import { SENT_NOTICE_SENT_MINUTES_ENDPOINT } from "../../../../Routing/routes";

export const headCells = [
  { id: "id", label: "S No.", width: "50px" },
  {
    id: "meetingSubject",
    label: "Meeting Subject",
    sort: true,
    width: "120px",
  },
  { id: "date", label: "Date", sort: true, width: "80px" },
  { id: "time.", label: "Time", sort: true, width: "90px" },
  { id: "venue", label: "Venue", sort: true, width: "90px" },
  { id: "honorarySecretary", label: "Honorary Secretary", sort: true, width: "120px" },
  { id: "status", label: "Status", sort: true, width: "90px" },
  { id: "action", label: "Action", sort: false, width: "90px" },
];

const rows = [
  {
    id: "1",
    sNo: "01",
    meetingSubject: "Himanshi Mehta",
    date: "P-Block",
    time: "TPP001",
    venue: "NOC for Transfer",
    honorarySecretary: "23-02-2024",
    status: "Sent",

  },
  {
    id: "2",
    sNo: "02",
    meetingSubject: "Riya Mehta",
    date: "C-Block",
    time: "TPP002",
    venue: "NOC for Passport",
    honorarySecretary: "23-02-2024",
    status: "Cancelled",
  },
];

const SentNotice = () => {
  const navigate = useNavigate();
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);

  const handleArchiveClick = () => {
    setIsArchiveModalOpen(true);
  };
  const handleNavigation = (path) => () => {
    navigate(path);
  };
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Sent Notice" onClick={handleNavigation(-1)}>
            <Button label="Sent Minutes" onClick={handleNavigation(SENT_NOTICE_SENT_MINUTES_ENDPOINT)} />
            <Button label="Track Action" onClick={handleNavigation("")} />
            <span className="border-r border-[#CCC]"> </span>
            <Button label="Schedule A Meeting" onClick={() => handleArchiveClick()} />
          </ListHeader>
        </div>
        <div className="flex justify-between my-2">
          <Dropdown2
            options={societyStaffTypeOptions}
            placeholder="Meeting Type"
            className=""
            width="208px"
            height="36px"
            onSelect={(selectedItem) => {}}
            //   {...(formik.values.subCategory === "" && {
            //     value: null,
            //   })}
            name={"subCategory"}
          />
          <div className="flex gap-3">
          <RangeDatePicker
            className="w-48 h-9"
            // defaultStartValue={searchParams.get("startDate") ? new Date(searchParams.get("startDate")) : ''}
            // defaultEndValue={searchParams.get("endDate") ? new Date(searchParams.get("endDate")) : ''}
            // onStartDateChange={handleStartDate}
            // onEndDateChange={handleEndDate}
          />
          <ButtonG label="Clear" />
          </div>
        </div>
        <div className="ml-1">
          <SentNoticeTable
            data={rows}
            columns={headCells}
            checkbox={false}
            pagination={true}
          ></SentNoticeTable>
        </div>
        {!rows?.length && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
      <ArchiveTenantModal
        isOpen={isArchiveModalOpen}
        onClose={() => setIsArchiveModalOpen(false)}
        minDate={moment(new Date()).add(1, 'days').toDate()}
        // loading={updateLoader}
      />
    </Layout>
  );
};

export default SentNotice;
