import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../../Layout/Layout";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import {
  EDIT_SOCIETY_STAFF_ENDPOINT,
  SOCIETY_STAFF_ENDPOINT,
} from "../../../../Routing/routes";
import Button from "../../../../components/Button/Button";
import { ViewStaffDetailsData } from "../../../../consts/ViewStaffDetailsData";
import ProfilePicture from "../../../../components/ProfilePicture/ProfilePicture";
import { useDispatch, useSelector } from "react-redux";
import { getSocietyStaffDetails } from "../../../../redux/actions/SetupRecordsActions/StaffManagementActions/StaffPersonnelAction";
import Loader from "../../../../components/Loader/Loader";
import moment from "moment";
import {
  genderOptions,
  societyStaffCurrentStatusptions,
} from "../../../../consts/DropDownOptions";
import ImageComponent from "../../../../components/ImageComponent/ImageComponent";
import { validateDate } from "../../../../utils/helpers/universalFunctions";
const ViewStaffDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, staffDetails } = useSelector(
    (state) => state.staffPersonnelReducer
  );
  const { id } = useParams();

  const handleBackClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(getSocietyStaffDetails({ staffId: id }));
  }, []);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack
            onClick={handleBackClick}
            title="Staff Details"
            className="text-lg"
          >
            <Button
              label="Edit"
              className="px-5"
              onClick={() => {
                navigate(`${EDIT_SOCIETY_STAFF_ENDPOINT}/${id}`);
              }}
            />
          </ListHeaderBack>
        </div>
        {loading && staffDetails?.staff_details?.staff_id?.toString() !== id ? (
          <Loader />
        ) : (
          <div className="p-4 w-[3/4] text-sm relative">
            <div className="absolute top-[40px] right-[67px]">
              <ProfilePicture
                direction="col"
                placeHolder="Change"
                defaultURL={staffDetails?.staff_details?.profile_image}
                edit={false}
              />
            </div>

            <div className="absolute top-[320px] right-[67px]">
              {/* <span className="text-[#AAA]">QR Code</span> */}
              <ImageComponent
                defaultUrl={staffDetails?.staff_details?.qr_image}
                width={36}
                height={36}
              />
            </div>
            {ViewStaffDetailsData.map((section, index) => (
              <div key={index} className="mb-4  border-b border-[#CCC]">
                <h2 className="font-semibold mb-2">{section.label}</h2>
                {section.values.map((row, rowIndex) => (
                  <div key={`0${rowIndex}`} className="flex mb-2">
                    {row.map((item, itemIndex) => (
                      <div
                        key={`00${itemIndex}`}
                        className="w-full flex mb-2 gap-3"
                      >
                        <p className="text-[#AAA]">{item.label}</p>
                        <p className="">
                          {section.label === "Document Details" ? (
                            <span>
                              {staffDetails?.staff_details?.[item.key] || "-"}{" "}
                              {staffDetails?.staff_details?.[item?.key2] && (
                                <Link
                                  to={staffDetails?.staff_details?.[item?.key2]}
                                  className="underline text-blue-500 text-xs"
                                  target="_blank"
                                >
                                  Click here to View
                                </Link>
                              )}{" "}
                            </span>
                          ) : item?.key === "id_validity" ? (
                            `${
                              validateDate(
                                staffDetails?.staff_details?.start_validity
                              )
                                ? moment(
                                    staffDetails?.staff_details?.start_validity
                                  )?.format("MMM DD, YYYY")
                                : "-"
                            } - ${
                              validateDate(
                                staffDetails?.staff_details?.end_validity
                              )
                                ? moment(
                                    staffDetails?.staff_details?.end_validity
                                  )?.format("MMM DD, YYYY")
                                : "-"
                            }`
                          ) : item?.key === "staff_gender" ? (
                            genderOptions?.find(
                              (item) =>
                                item.value ===
                                staffDetails?.staff_details?.staff_gender
                            )?.label
                          ) : item?.key === "phone_number" ? (
                            `+${
                              staffDetails?.staff_details?.mobile_country_code
                                ? `${staffDetails?.staff_details?.mobile_country_code}-`
                                : ""
                            }${staffDetails?.staff_details?.phone_number}`
                          ) : item?.key === "approval_status" ? (
                            item?.value?.[
                              staffDetails?.staff_details?.approval_status
                            ]
                          ) : item?.key === "approved_by" ? (
                            (staffDetails?.staff_details?.COMMITTEE_USER_NAME ||
                            staffDetails?.staff_details?.EMPLOYEE_NAME
                              ? staffDetails?.staff_details
                                  ?.COMMITTEE_USER_NAME ||
                                staffDetails?.staff_details?.EMPLOYEE_NAME
                              : "-") +
                            ` (${staffDetails?.staff_details?.approved_by})`
                          ) : item?.key === "staff_fname" ? (
                            `${
                              staffDetails?.staff_details?.staff_name_prefix
                                ? `${staffDetails?.staff_details?.staff_name_prefix}. `
                                : ""
                            } ${staffDetails?.staff_details?.[item?.key]} ${
                              staffDetails?.staff_details?.staff_lname
                                ? `${staffDetails?.staff_details?.staff_lname}`
                                : ""
                            }`
                          ) : (
                            staffDetails?.staff_details?.[item?.key] || "-"
                          )}
                        </p>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ))}
            {staffDetails?.staff_details?.["staff_type"] === "Domestic" && (
              <div className="mb-4  border-b border-[#CCC]">
                <h2 className="font-semibold mb-2">Flat Details</h2>
                {staffDetails?.staff_flat_details?.map((item, itemIndex) => (
                  <div key={itemIndex} className="w-full flex mb-2 gap-3">
                    <div className="font-semibold me-5">
                      Flat {(itemIndex + 1)?.toString()?.padStart(2, "0")}
                    </div>
                    <div>
                      <div className="w-full flex mb-2 gap-3">
                        <p className="text-[#AAA]">Flat No.:</p>
                        <p className="capitalize">
                          {item?.wing_flat} ({item?.subscriber_name})
                        </p>
                      </div>
                      <div className="w-full flex mb-2 gap-3">
                        <p className="text-[#AAA]">Join Date:</p>
                        <p className="">
                          {item?.joining_date &&
                          item?.joining_date !== "0000-00-00"
                            ? moment(new Date(item?.joining_date))?.format(
                                "DD-MM-YYYY"
                              )
                            : "-"}
                        </p>
                      </div>
                      <div className="w-full flex mb-2 gap-3">
                        <p className="text-[#AAA]">Status:</p>
                        <p className="">
                          {societyStaffCurrentStatusptions?.find(
                            (e) => e.value === item.status
                          )?.label || "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ViewStaffDetails;
