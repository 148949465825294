import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  RENT_REGISTRATION_LIST_ENDPOINT,
  TENANTS_INFO_ENDPOINT,
} from "../../../../Routing/routes";
import Layout from "../../../Layout/Layout";
import Button from "../../../../components/Button/Button";
import ButtonG from "../../../../components/Button/ButtonG";
import RegFormTenantDetails from "./RegFormTenantDetails";
import RegFormLandlordDetails from "./RegFormLandlordDetails";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import { Form, Formik } from "formik";
import { AddOnlineRegistrationFormForRentPaymentValidation } from "../../../../validationSchema/setupRecordsSchema/occupants&TenantsSchema";
import { useDispatch, useSelector } from "react-redux";
import {getRentPaymentOnlineRegistrationData, updateRentPaymantOnlineRegistration} from '../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/TenantsInfoAction'
import { getFlatDetails } from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import countries from "../../../../consts/countries";
import { CircularProgress } from "@mui/material";

const OnlineRegFormForRentPayment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { loginData } = useSelector((state) => state.loginReducer);
  const {loading, updateLoader, rentPaymentOnlineRegistrationData} = useSelector(state => state.tenantInfoReducer)

  const { tenantId} = useParams()

  const handleBackClick = () => {
    navigate(TENANTS_INFO_ENDPOINT);
  };

  const handleRegistrationRequestList = () => {
    navigate(RENT_REGISTRATION_LIST_ENDPOINT);
  };

  const handleSubmit = async(values, {resetForm})=>{
    const data={
      societyId: loginData?.default_community?.community_id,
      tenantId,
      registrationId: rentPaymentOnlineRegistrationData?.registration_id || 0,
      ...values,
      onSuccessCallback: ()=>{
        dispatch(getRentPaymentOnlineRegistrationData({tenantId, societyId: loginData?.default_community?.community_id}))
      },

    }
    dispatch(updateRentPaymantOnlineRegistration(data))
  }

  useEffect(()=>{
      dispatch(getRentPaymentOnlineRegistrationData({tenantId, societyId: loginData?.default_community?.community_id}))
  }, [])

  useEffect(()=>{
    if(tenantId === rentPaymentOnlineRegistrationData?.tenant_id?.toString()){
      if(rentPaymentOnlineRegistrationData?.flat_id)
      dispatch(getFlatDetails({societyId: loginData?.default_community?.community_id, wingId: parseInt(rentPaymentOnlineRegistrationData?.wing_id)}))
    }
  }, [rentPaymentOnlineRegistrationData])

  return (
    <Layout>
      <div className="flex flex-col gap-2 w-full">
      <Formik
        initialValues={{
          regId: rentPaymentOnlineRegistrationData?.registration_id || 0,
          rentAgreementFileURL: rentPaymentOnlineRegistrationData?.rent_agreement_url || '',

          type: rentPaymentOnlineRegistrationData?.individual_or_company || 'individual',
          firstName: rentPaymentOnlineRegistrationData?.first_name || '',
          lastName: rentPaymentOnlineRegistrationData?.last_name || '',
          wingId: rentPaymentOnlineRegistrationData?.wing_id || '',
          flatId: rentPaymentOnlineRegistrationData?.flat_id || '',
          dob: rentPaymentOnlineRegistrationData?.dob || '',
          gender: rentPaymentOnlineRegistrationData?.gender || '',
          mobileCountryCode: rentPaymentOnlineRegistrationData?.mobile?.split('-')?.[0]?.includes('+') ? rentPaymentOnlineRegistrationData?.mobile?.split('-')[0]?.replace('+', '') : '',
          countryISO: countries?.find(item=> item.phonecode?.replace('+','') === rentPaymentOnlineRegistrationData?.mobile?.split('-')[0]?.replace('+', ''))?.iso || '',
          mobileNo: (rentPaymentOnlineRegistrationData?.mobile?.split('-')?.length>1 ? rentPaymentOnlineRegistrationData?.mobile?.split('-')?.[1]: rentPaymentOnlineRegistrationData?.mobile?.split('-')?.[0]) || '',
          email: rentPaymentOnlineRegistrationData?.email || '',
          rentPropertyAddress: rentPaymentOnlineRegistrationData?.address || '',
          city: rentPaymentOnlineRegistrationData?.city || '',
          pinCode: rentPaymentOnlineRegistrationData?.pincode || '',
          adharCardNo: rentPaymentOnlineRegistrationData?.aadhar_no ||  '',
          rentAgreementFile: rentPaymentOnlineRegistrationData?.rent_agreement_path || '',
          panCardNo: rentPaymentOnlineRegistrationData?.tenant_pan ||  '',
          gstRegistrationNo: rentPaymentOnlineRegistrationData?.tenant_gstin || '',
          rentAmount: rentPaymentOnlineRegistrationData?.rent_amount || '',
          frequencyOfRent: rentPaymentOnlineRegistrationData?.frequency_of_rent || '',
          mothlyDueDate: rentPaymentOnlineRegistrationData?.due_date?.toString()?.padStart(2, '0') || '',
          rentAgreementExpiryDate: rentPaymentOnlineRegistrationData?.agreement_expiry_date || '',
          landLordDetails: {
            firstName: rentPaymentOnlineRegistrationData?.landlord_name?.split(' ')?.[0] || '',
            lastName: rentPaymentOnlineRegistrationData?.landlord_name?.split(' ')?.[1] || '',
            panCardNo: rentPaymentOnlineRegistrationData?.landlord_pan || '',
            gstRegistrationNo: rentPaymentOnlineRegistrationData?.landlord_gstin ||  '',
            bankName: rentPaymentOnlineRegistrationData?.bank_name || '',
            accountNumber: rentPaymentOnlineRegistrationData?.account_no || '',
            ifscCode: rentPaymentOnlineRegistrationData?.ifsc_code || '',
          },
        }}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={AddOnlineRegistrationFormForRentPaymentValidation}
      >   
      {
        ({values, dirty, setFieldValue, resetForm})=>{
          return (
            <Form>
              <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg p-2">
                <div className="border-b border-[#CCC]">
                  <ListHeaderBack
                    onClick={handleBackClick}
                    title={`${!values.regId? 'Add' : 'Edit'} Online Registration Form For Rent Payment`}
                  >
                    <div className="flex items-center gap-2">
                      <Button
                        label="Registration Request List"
                        className="whitespace-nowrap py-2"
                        onClick={handleRegistrationRequestList}
                        type={'button'}
                      />
                      <div className="border-r w-full h-full"></div>
                      <ButtonG
                        label="Reset"
                        className="py-2"
                        onClick={resetForm}
                        type={'button'}
                        disabled={loading}
                      />
                      <Button
                        label={
                          <span className="w-12 inline-block">
                            {
                              updateLoader ?
                                <CircularProgress sx={{color: "white"}} size={17}/>
                                :
                                'Save'
                              }
                          </span>
                          } 
                        className="py-2 px-4"
                        type={'submit'}
                        disabled={loading || updateLoader}
                      />
                    </div>
                  </ListHeaderBack>
                </div>
                <div>
                  <RegFormTenantDetails values={values} setFieldValue={setFieldValue} tenantId={tenantId} />
                </div>
              </div>
              <div className="mt-3">
                <RegFormLandlordDetails values={values} setFieldValue={setFieldValue} tenantId={tenantId} />
              </div>
            </Form>
          )}
      }
      </Formik>
      </div>
    </Layout>
  );
};

export default OnlineRegFormForRentPayment;
