import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TextAreaFM } from "../../../../components/InputFields/TextArea";
import Layout from "../../../Layout/Layout";
import Button from "../../../../components/Button/Button";
import ButtonG from "../../../../components/Button/ButtonG";
import { MANAGING_COMMITTEE_ENDPOINT } from "../../../../Routing/routes";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import  { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import { UploadButtonFM } from "../../../../components/Button/UploadButton";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { approveAndRejectCommitteeMemberResignation, getManagingCommitteeDetails } from "../../../../redux/actions/SetupRecordsActions/StaffManagementActions/ManangingCommitteeAction";
import Loader from "../../../../components/Loader/Loader";
import { Formik, Form} from "formik";
import { ApproveResignValidation } from "../../../../validationSchema/setupRecordsSchema/staffManagementSchema";
import { CircularProgress } from "@mui/material";
import Checkbox from "../../../../components/Checkbox/Checkbox";


const ApproveResign = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading, updateLoader, loadingOccupants, managingCommitteeData} = useSelector(state=> state.managingCommitteeReducer)
  const {id, roleId} = useParams()
  const [data, setData] = useState()

  const handleCancelClick = () => {
    navigate(MANAGING_COMMITTEE_ENDPOINT);
  };

  const handleSaveClick = async(values, {resetForm}) => {

    dispatch(approveAndRejectCommitteeMemberResignation({
      ...values, 
      societyId: loginData?.default_community?.community_id,
      committeeId: id,
      roleId,
      isApprove: 'Yes',
      memberName: data?.subscriber_name,
      flat: data?.flat_no,
      email: data?.member_email,
      onSuccessCallback: ()=>{
        navigate(-1)
      }
    }))
  };


  const getTableData =() =>{
    const data={
      committeeId: id,
    }

    dispatch(getManagingCommitteeDetails(data))
  }

  useEffect(()=>{      
    getTableData()
  }, [])

  useEffect(()=>{
    const item = managingCommitteeData?.members?.find(item=> item.role_data_id?.toString() === roleId && item.resign_retire_status !== 'No')
    if(item){
      setData(item)
    }

  }, [managingCommitteeData])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
      <Formik
        initialValues={{
          effectiveDate: '',
          remarks: '',
          approveDoc: '',
          sendEmail: 0,
        }}
        enableReinitialize
        onSubmit={handleSaveClick}
        validationSchema={ApproveResignValidation}
        >    
          {
            ({values, errors, setFieldValue})=>{
              return (
                <Form>
                  <div className="border-b border-[#CCC]">
                    <ListHeader leftContent={`Approve Resignation » ${data?.subscriber_name ? data?.subscriber_name: ''}`}>
                      <ButtonG
                        label="Cancel"
                        onClick={handleCancelClick}
                        className="h-8"
                      />
                      <Button
                          label={
                            <span className="w-8 inline-block">
                              {
                                updateLoader ?
                                  <CircularProgress sx={{color: "white"}} size={17}/>
                                  :
                                  'Save'
                                }
                            </span>
                          } 
                          className=" h-8 px-4"
                          type='submit'
                          disabled={loadingOccupants || updateLoader || loading}
                        />
                    </ListHeader>
                  </div>
                  {
                    loading && managingCommitteeData?.committee_id?.toString() !== id ?
                    <Loader/>
                    :
                    <>
                    {
                      data &&
                      <div className="flex gap-4 p-2 mb-3">
                        <div className="w-1/2">
                          <div className="flex justify-between ">
                            <span className="text-sm mt-2 ">Effective Date</span>
                            <DatePickerComponentFM
                              className="w-56 h-9" 
                              name='effectiveDate'
                              onDateChange={(selectedDate) => {
                                  setFieldValue('effectiveDate', selectedDate)
                                }}
                                minDate={new Date()}
                                defaultValue={values.effectiveDate ? new Date(values.effectiveDate) : null}
                              />
                          </div>
                          <TextAreaFM
                            label="Remarks"
                            placeholder="Enter Remarks"
                            className="w-56"
                            maxLength={200}
                            name='remarks'
                            row={6}
                          />
                          <div className="flex justify-between">
                            <div className="text-sm mt-2">Approval Document</div>
                            <div className="w-56">
                              <UploadButtonFM
                                showFileName={true} 
                                name='approveDoc'
                                fileName={values?.approveDoc?.name}
                                onSelect={(e)=>{setFieldValue('approveDoc', e)}}
                                accept={"image/jpeg,image/jpg,image/png,application/pdf"}
                              />
                            </div>
                          </div>
                          <Checkbox
                            text="Send Emails to the Committee Members"
                            className="text-sm"
                            initialchecked={values.sendEmail === 1}
                            onChange={(e) => {setFieldValue('sendEmail', e.target.checked ? 1 : 0)}}
                          />
                          </div>
                        </div>
                    }
                        <div>
                      </div>
                    </>
                  }
                </Form>
              )
            }
          }
        </Formik>
      </div>
    </Layout>
  );
};

export default ApproveResign;
