import * as types from '../../index.types';

export function getInvoiceDetails(data) {
    return {
        type: types.GET_INVOICE_DETAILS,
        data
    };
}

export function updateInvoiceDetails(data) {
    return {
        type: types.UPDATE_INVOICE_DETAILS,
        data
    };
}
