import React, { useEffect } from "react";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import {
  ARCReportsYearOptions,
  monthOptions,
} from "../../../../consts/DropDownOptions";
import Search from "../../../../components/SearchBox/Search";
import ButtonG from "../../../../components/Button/ButtonG";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getBillTypeList,
  getFinanceMembersList,
  getInterestStatementReports,
} from "../../../../redux/actions/ReportActions/OldFinancialReportsActions";
import InterestStatementTable from "./InterestStatementTable";
import ButtonIco from "../../../../components/Button/ButtonPrint";
import { DownloadExt } from "../../../../assets";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { BILL_REPORTS_ENDPOINT } from "../../../../Routing/routes";

export const headCells = [
  { id: "flat", label: "Flat", width: "60px" },
  { id: "memberName", label: "Member Name", width: "120px" },
  { id: "arrearType", label: "Arrear Type", width: "100px" },
  {
    id: "dateStart",
    label: "Date(Start/Due Date)",
    width: "110px",
  },
  {
    id: "dateEnd",
    label: "Date(End/Receipt Date)",
    width: "110px",
  },
  {
    id: "receiptNumber",
    label: "Receipt Number",
    width: "150px",
  },
  { id: "due", label: "Due", width: "60px" },
  { id: "received", label: "Received", width: "70px" },
  {
    id: "adjustmentAmount",
    label: "Adjustment Amount",
    width: "100px",
  },
  { id: "amountForIntCalc", label: "Amount For Int. Calc.", width: "100px" },
  { id: "delayDay", label: "Delay Day's", width: "80px" },
  { id: "interest", label: "Interest", width: "80px" },
  { id: "receiptBalance", label: "Receipt Balance", width: "80px" },
  { id: "dueBalance", label: "Due Balance", width: "80px" },
];

const InterestStatement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  // const { loginData } = useSelector((state) => state.loginReducer);
  const {
    loading,
    billTypeList,
    financeMembersList,
    interestStatementReports,
  } = useSelector((state) => state.oldFinancialReportsReducers);

  const handleSearch = (text) => {
    if (text && text !== searchParams.get("search")) {
      searchParams.delete("page");
      searchParams.delete("search");
      navigate({
        pathname: `${BILL_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["search", text?.trim()],
        ])}`,
      });
    }
  };

  const handelSelectBillType = (selectedItem) => {
    searchParams.delete("page");

    if (selectedItem.value?.toString() !== searchParams.get("billType")) {
      searchParams.delete("billType");
      navigate({
        pathname: `${BILL_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["billType", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleSelectMembersList = (selectedItem) => {
    searchParams.delete("page");

    if (
      selectedItem.value?.toString() !== searchParams.get("financeMemberList")
    ) {
      searchParams.delete("financeMemberList");
      navigate({
        pathname: `${BILL_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["financeMemberList", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleSelectMonth = (selectedItem) => {
    searchParams.delete("page");

    if (selectedItem.value?.toString() !== searchParams.get("month")) {
      searchParams.delete("month");
      navigate({
        pathname: `${BILL_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["month", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleSelectYear = (selectedItem) => {
    searchParams.delete("page");

    if (selectedItem.value?.toString() !== searchParams.get("year")) {
      searchParams.delete("year");
      navigate({
        pathname: `${BILL_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["year", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleClear = () => {
    navigate(BILL_REPORTS_ENDPOINT);
  };

  useEffect(() => {
    dispatch(
      getBillTypeList({ society_id: "S387", daybook_filter: "allType" })
    );

    dispatch(getFinanceMembersList({ society_registration: "MYAS387" }));

    dispatch(
      getInterestStatementReports({
        society_id: "S001",
        // society_registration: "MYAS387",
        page: searchParams.get("page") || 1,
        limit: searchParams.get("pageLimit") || 10,
        billType: searchParams.get("billType"),
        month: searchParams.get("month"),
        year: searchParams.get("year"),
        financeMemberList: searchParams.get("financeMemberList"),
      })
    );
  }, [searchParams]);

  return (
    <div className="mt-1">
      <div className="">
        <ListHeader leftContent="Interest Statement">
          <Search
            height="8"
            onclick={handleSearch}
            value={searchParams.get("search")}
          />
          <span className="border-r border-[#CCC]"></span>
          <ButtonIco
            icon={DownloadExt}
            children="Download Excel"
            className="h-8"
            type="button"
            onClick={""}
          />
        </ListHeader>
      </div>
      <div className="flex gap-3 py-2">
        <Dropdown2
          options={
            billTypeList?.map((item) => ({
              value: item?.adbregnno,
              label: item?.daybookname,
            })) || []
          }
          placeholder="Select Bill Type"
          className=""
          width="182px"
          height="32px"
          onSelect={(selectedItem) => {
            handelSelectBillType(selectedItem);
          }}
          value={
            searchParams.get("billType") && {
              value: searchParams.get("billType"),
              label: billTypeList?.find(
                (item) => item.adbregnno === searchParams.get("billType")
              )?.daybookname,
            }
          }
        />
        <Dropdown2
          options={monthOptions}
          placeholder="Month"
          className=""
          width="120px"
          height="32px"
          onSelect={(selectedItem) => {
            handleSelectMonth(selectedItem);
          }}
          value={
            searchParams.get("month") && {
              value: searchParams.get("month"),
              label: monthOptions?.find(
                (item) => item.value === searchParams.get("month")
              )?.label,
            }
          }
        />

        <Dropdown2
          options={ARCReportsYearOptions}
          placeholder="Year"
          className=""
          width="120px"
          height="32px"
          onSelect={(selectedItem) => {
            handleSelectYear(selectedItem);
          }}
          value={
            searchParams.get("year") && {
              value: searchParams.get("year"),
              label: ARCReportsYearOptions?.find(
                (item) => item.value.toString() === searchParams.get("year")
              )?.label,
            }
          }
        />
        <span className="border-r border-[#CCC]"></span>
        <Dropdown2
          options={
            financeMembersList?.map((item) => ({
              value: item?.regnno,
              label: item?.shortname,
            })) || []
          }
          width={"182px"}
          height="32px"
          className="text-xs"
          placeholder={"Select Members"}
          onSelect={(selectedItem) => {
            handleSelectMembersList(selectedItem);
          }}
          value={
            searchParams.get("financeMemberList") && {
              value: searchParams.get("financeMemberList"),
              label: financeMembersList?.find(
                (item) => item.regnno === searchParams.get("financeMemberList")
              )?.shortname,
            }
          }
        />
        <span className="border-r border-[#CCC]"></span>
        <ButtonG label="Clear" className="h-8" onClick={handleClear} />
      </div>
      <div className="ml-1">
        <InterestStatementTable
          loading={loading}
          data={
            interestStatementReports?.map((item) => ({
              flat: item?.BIB_flat,
              // memberName: item?.blobmemname ,
              // arrearType: item?.arrearType,
              dateStart: item?.BIB_lastBillDate,
              dateEnd: item?.BIB_RecDate,
              receiptNumber: item?.BIB_recNo.value,
              due: item?.BIB_balanceamt.value,
              received: item?.BIB_recAmnt?.value,
              adjustmentAmount: item?.BIB_adjustamt?.value,
              amountForIntCalc: item?.BIB_amountonint?.value,
              delayDay: item?.BIB_days?.value,
              interest: item?.BIB_InterestAmnt?.value,
              receiptBalance: item?.BIB_recBalance?.value,
              dueBalance: item?.BIB_amountonint?.value,
            })) || []
          }
          columns={headCells}
          checkbox={false}
          pagination={true}
        ></InterestStatementTable>
      </div>
      {!interestStatementReports?.length && (
        <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
          No Records
        </div>
      )}
    </div>
  );
};

export default InterestStatement;
