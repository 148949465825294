import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../Layout/Layout";
import { Arrow } from "../../../assets";
import Loader from '../../../components/Loader/Loader'
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import {getWingAuditlogs} from '../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction'
import moment from "moment";
import { AuditData } from "../../../consts/WingsAuditData";

const Accordion = ({ label, isOpen, toggleAccordion, data }) => {

  return (
    <div className="border rounded-lg border-[#CCCCCC] my-2">
      <div
        className="flex justify-between cursor-pointer items-center bg-[#FDF2F3] rounded-lg p-3 border-b"
        onClick={toggleAccordion}
      >
        <div className="flex items-center">
          <button className="text-white text-base font-semibold mr-3 w-5 h-5 bg-red-650 
            border rounded flex justify-center items-center">
            {isOpen ? (
              <FontAwesomeIcon icon={faChevronUp} size="xs" />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} size="xs" />
            )}
          </button>
          <div className="text-[#000000] text-sm font-semibold">{label}</div>
        </div>
      </div>
      {isOpen && (
          data?.audit_logs?.map((audit, index)=>{
            return (
              <div className="p-3 text-[#222222] text-sm" key={audit?.log_id}>
                <div className="flex justify-end flex-col mb-4">
                  <div className="text-xs text-right text-[#888]">{`Modified By ${audit?.modified_by} On ${moment(new Date(audit?.modified_dateTime))?.format('MMM DD,YYYY hh:mm A')}`}</div>
                  <div className="text-xs text-right text-[#888]">{`Ip Address: ${audit?.IpAddress}. Session id - ${audit?.modified_sessionId}`}</div>
                </div>
                <div className="flex justify-between w-full text-sm p-2 font-bold">
                  <div className="w-1/2">{' '}</div>
                  <div className="w-1/2">Old Value</div>
                  <div className="w-1/2">New Value</div>
                </div>
                {AuditData?.[0]?.values.map((item, i0) => (
                  <React.Fragment key={i0}>
                    <div className="flex justify-between w-full text-sm p-2">
                      <div className="w-1/2">{item.label}</div>
                      <div className="w-1/2">{audit?.old_data?.[item.key]?.toString() || '-'}</div>
                      <div className="w-1/2">{audit?.new_data?.[item.key]?.toString() || '-'}</div>
                    </div>
                  </React.Fragment>
                ))}
                <hr className="mt-4"/>
              </div>
            )
          })
        
      )}
    </div>
  )
}

const WingsAuditLog = () => {
  const navigate = useNavigate();
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading, wingsAuditData} = useSelector(state=>state.wingsReducer)

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const toggleAccordion = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(()=>{
    const sendRequest={
      societyId: loginData?.default_community?.community_id,
    }
    dispatch(getWingAuditlogs(sendRequest))
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="flex gap-5 pb-2">
          <div className="flex w-1/2">
            <button
              onClick={handleNavigation(-1)}
              className="text-sm p-2 text-red-650 font-semibold flex gap-2 justify-center items-center"
            >
              <span>
                <Arrow />
              </span>
              Back
            </button>
            <span className="border-r border-[#CCCCCC]"></span>
            <h1 className="text-sm font-semibold p-2">Wings Audit Logs</h1>
          </div>
        </div>
        <div className="border-b border-[#CCCCCC]"></div>
        {
          loading && !wingsAuditData ?
          <Loader/>
          :
          <div>
          {wingsAuditData?.length > 0 ? (
              wingsAuditData?.map((audit, index)=>(
                <Accordion
                  label={audit.wing_no}
                  key={index}
                  data={audit}
                  isOpen={index === openAccordionIndex}
                  toggleAccordion={() => toggleAccordion(index)}
                />
              ))
            ) : (
              <div className="text-center py-4 text-[#999999] flex justify-center">No Audits</div>
            )}
          </div>
        }
      </div>
    </Layout>
  );
};

export default WingsAuditLog;
