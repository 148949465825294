import { notify } from '../../../../utils/notification';
import apiEndpoints from '../../../api';
import axiosInstance from '../../../axiosInstance'
import {logOut} from '../../../../utils/helpers/logOut'
import getBrowserDetails from '../../../../utils/helpers/getBrowserDetails'
import { errorMsg } from '../../../../consts/responseMessages';

export async function getMaintenanceStaffListReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_MAINTENANCE_STAFF_LIST_API}?society_id=${action.data.societyId}&page_no=${action.data.page}&page_limit=${action.data.pageLimit}${action.data.searchText ? `&search_text=${encodeURIComponent(action.data.searchText)}`: ''}${action.data.agencyId ? `&agency_id=${action.data.agencyId}`: ''}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getAgencyListReq(action) {               
    return axiosInstance.get(`${apiEndpoints.GET_AGENCY_LIST_API}?society_id=${action.data.societyId}${action.data.searchText ? `&search_text=${encodeURIComponent(action.data.searchText)}`: ''}${action.data.agencyCatId ? `&agency_cat_id=${action.data.agencyCatId}`: ''}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function addUpdateMaintenanceStaffReq(action) {

    const BODY= 	{
        "society_id": action.data.societyId,
        "agency_id": action.data.agencyId || 0,
        "agency_staff_id": action.data.staffId || 0,
        "name": action.data?.prefix + ' ' + action.data?.firstName + ' ' +  action.data?.lastName,
        "title": action.data?.title,
        "email": action.data?.email,
        "responsibility": action.data?.responsibility,
        "other_details": action.data?.otherDetails,
        "image": action.data?.profilePic || '',
        "id_upload": action.data?.idCard || '',
        "contact": action.data?.mobileNo,
        "mobile_country_code": action.data.mobileCountryCode,
        "browser_details": getBrowserDetails(),
        "session_id": action.data?.sessionId,
        "ip": action.data?.ipAddress
      }

    return axiosInstance.post(apiEndpoints.ADD_UPDATE_MAINTENANCE_STAFF_LIST_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}


export async function getMaintenanceStaffDetialsReq(action) {               
    return axiosInstance.get(`${apiEndpoints.GET_MAINTENANCE_STAFF_DETAILS_API}?agency_staff_id=${action.data.staffId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function deleteMaintenanceStaffOrAgencyReq(action) {
    const BODY = {
        "ids": action.data?.ids,
        "flag": action.data?.flag,
        "browser_details": getBrowserDetails(),
        "session_id": action.data.sessionId,
        "ip": action.data.ipAddress
      }

    return axiosInstance
      .delete(apiEndpoints.DELETE_MAINTENANCE_STAFF_OR_AGENCY_LIST_API, { data: BODY })
      .then((response) => {
        return response;
      })
      .catch((errors) => {
        console.log("errors", errors);
        if (
          errors.response?.data.statusCode === 400 &&
          errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
        ) {
          logOut();
        } else {
          notify("warning", "Something went wrong");
        }
        return errors;
      });
  }

  export async function getAgencyCategoryListReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_AGENCY_CATEGORY_LIST_API}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getAgencyDetailsReq(action) {               
    return axiosInstance.get(`${apiEndpoints.GET_AGENCY_DETAILS_API}?agency_id=${action.data.agencyId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function addUpdateAgencyReq(action) {

    const BODY= {
        "society_id": action.data?.societyId,
        "agency_id": action.data?.agencyId || 0,
        "agency_cat_id": action.data?.categoryId,
        "contact_office": action.data?.officeAddress,
        "agency_name": action.data?.agencyName,
        "pan_no": action.data?.panCardNo?.toUpperCase(),
        "tan_no": action.data?.tanNo?.toUpperCase(),
        "other_details": action.data?.otherDetails,
        "concerned_person": action.data?.concernedPersonName,
        "mobile": '+' + action.data?.mobileCountryCode + '-' + action.data?.mobileNo
      }

    return axiosInstance.post(apiEndpoints.ADD_UPDATE_AGENCY_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}
