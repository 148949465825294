import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../../Layout/Layout";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import ViewCommitteeAccessSettingsComp from "./ViewCommitteeAccessSettingsComp";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getManagingCommitteeAccessSetting } from "../../../../redux/actions/SetupRecordsActions/StaffManagementActions/ManangingCommitteeAction";
import Loader from "../../../../components/Loader/Loader";

const ViewCommitteeAccessSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {id} = useParams()
  const {loading, accessSettingData} = useSelector(state=>state.managingCommitteeReducer)


  const handleNavigation = (path) => () => {
    navigate(path);
  };

  useEffect(()=>{
    dispatch(getManagingCommitteeAccessSetting({committeeId: id}))
  }, [])
  return (
    <>
      <Layout>
        <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        
          <div className="border-b border-[#CCC] items-center">
            <ListHeaderBack
            className='capitalize'
              onClick={handleNavigation(-1)}
              title={`View Access Settings  ${!loading && accessSettingData? `- ${accessSettingData?.title}` : ''}`}
            >
            </ListHeaderBack>
          </div>
          <div>
            {/* Accordion */}
            {
              loading && id !== accessSettingData?.committee_id?.toString() ?
              <Loader/>
                :
              <>
              {
                !!accessSettingData?.access_check ?
                <ViewCommitteeAccessSettingsComp accessSetting={accessSettingData?.access}/>:
                <div className="text-sm text-[#AAAAAA] my-4 text-center">
                  Access Denied
                </div>
              }
              </>
            }
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ViewCommitteeAccessSettings;
