import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Layout from '../../Layout/Layout';
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import { useDispatch } from "react-redux";
import { getFacilityAuditLogs } from "../../../redux/actions/ClubActions/ViewBookingsAction";
import Loader from "../../../components/Loader/Loader";
import moment from "moment";
import ImageComponent from "../../../components/ImageComponent/ImageComponent";
import { useNavigate } from "react-router-dom";

const AuditData=[
    {key: 'facilities_name', label: 'Facilities Name :	'},
    {key: 'booking ', label: 'Booking:'},
    {key: 'chargeable', label: 'Is Chargeable'},
    {key: 'security_deposit', label: 'Security Deposit'},
]


const Accordion = React.memo(
    ({ data, isOpen, toggleAccordion, label, descr, desc, children}) => {  
  
      return (
        <div className="border rounded-lg border-[#CCCCCC] my-2 overflow-hidden">
          <div
            className="flex justify-between cursor-pointer items-center bg-[#FDF2F3] rounded-t-lg p-3 border-b "
            onClick={toggleAccordion}
          >
            <div className="flex items-center">
              <button className="text-white text-sm font-semibold mr-3 w-5 h-5 bg-red-650 border rounded flex justify-center items-center">
                {isOpen ? (
                  <FontAwesomeIcon icon={faChevronUp} size="xs" />
                ) : (
                  <FontAwesomeIcon icon={faChevronDown} size="xs" />
                )}
              </button>
              <div className="text-[#000000] text-sm font-semibold">{label}</div>
            </div>
            <div>
              <div className="text-xs text-right text-[#888]">{desc}</div>
              <div className="text-xs text-right text-[#888]">{descr}</div>
            </div>
          </div>
          {isOpen && (
            <div className="p-3">
              <div className="flex text-sm mb-2">
                  <div className="w-56 font-semibold text-[#AAAAAA]">Image</div>
                  <div><ImageComponent defaultUrl={data?.image} width={32} height={32} /> </div>
              </div>
              {
                AuditData?.map((item, index)=>(
                    <div className="flex text-sm mb-2">
                        <div className="w-56 font-semibold text-[#AAAAAA]">{item.label}</div>
                        <div>{data[item.key]?.toString() || '-'}</div>
                    </div>
                ))
              }
              <div className="flex text-sm mb-2">
                  <div className="w-56 font-semibold text-[#AAAAAA]">Facility Manager</div>
                  <div>{data.facility_manager ? `${data.facility_manager} ${data?.old_nominee_details?.facility_manager_name ? `(${data?.old_nominee_details?.facility_manager_name})` : ''}` : '-'}  </div>
              </div>
              <div className="flex text-sm mb-2">
                  <div className="w-56 font-semibold text-[#AAAAAA]">Managing Committee</div>
                  <div>{data.managing_committees ? `${data.managing_committees} ${data?.old_nominee_details?.managing_committee_member ? `(${data?.old_nominee_details?.managing_committee_member})` : ''}` : '-'}  </div>
              </div>
              <div className="flex text-sm mb-2">
                  <div className="w-56 font-semibold text-[#AAAAAA]">Vendor Category</div>
                  <div>{data?.vandor_cat_id || '-'}  </div>
              </div>
              <div className="flex text-sm mb-2">
                  <div className="w-56 font-semibold text-[#AAAAAA]">Vendor</div>
                  <div>{data?.society_vendor_id || '-'}  </div>
              </div>
              <div className="flex text-sm mb-2">
                  <div className="w-56 font-semibold text-[#AAAAAA]">Concerned Persons</div>
                  <div>{data?.vendor_concerned_person || '-'}  </div>
              </div>
              <div className="flex text-sm mb-2">
                  <div className="w-56 font-semibold text-[#AAAAAA]">Usage Guidance</div>
                  <div>{data?.usage_guidance || '-'}  </div>
              </div>
              <div className="flex text-sm mb-2">
                  <div className="w-56 font-semibold text-[#AAAAAA]">Description</div>
                  <div>{data?.instructions || '-'}  </div>
              </div>
            </div>
            
          )}
        </div>
      );
    }
  );

const FacilityAuditAccordians = ({ data }) => {
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const {facilityAudit} = useSelector(state=>state.viewBookingReducer)

  const toggleAccordion = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div>
        <div className="font-semibold my-2">Add Logs</div>
        {facilityAudit?.add_logs?.map((item, index) => (
            <Accordion
            label={`Audit ${index+1}`}
            key={index}
            isOpen={index === openAccordionIndex}
            toggleAccordion={() => toggleAccordion(index)}
            desc={`Modified By ${item?.modified_by} On ${moment(new Date(item?.modified_dateTime))?.format('MMM DD,YYYY hh:mm A')}`}
            descr={`Ip Address: ${item?.IpAddress}. Session id - ${item?.modified_sessionId}`}
            data={item.new_nominee_details}
        >

        </Accordion>
        ))}

        <div className="font-semibold my-2">Edit Logs</div>
        {facilityAudit?.update_logs?.map((item, index) => (
            <Accordion
            label={`Audit ${index+1}`}
            key={index}
            isOpen={index === openAccordionIndex}
            toggleAccordion={() => toggleAccordion(index)}
            desc={`Modified By ${item?.modified_by} On ${moment(new Date(item?.modified_dateTime))?.format('MMM DD,YYYY hh:mm A')}`}
            descr={`Ip Address: ${item?.IpAddress}. Session id - ${item?.modified_sessionId}`}
            data={item.new_nominee_details}
        >

        </Accordion>
        ))}

        <div className="font-semibold my-2">Delete Logs</div>
        {facilityAudit?.delete_logs?.map((item, index) => (
            <Accordion
            label={`Audit ${index+1}`}
            key={index}
            isOpen={index === openAccordionIndex}
            toggleAccordion={() => toggleAccordion(index)}
            desc={`Modified By ${item?.modified_by} On ${moment(new Date(item?.modified_dateTime))?.format('MMM DD,YYYY hh:mm A')}`}
            descr={`Ip Address: ${item?.IpAddress}. Session id - ${item?.modified_sessionId}`}
            data={item.new_nominee_details}
        >

        </Accordion>
        ))}
    </div>
  );
};



function FacilityAuditLogs() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {loading} = useSelector(state=>state.viewBookingReducer)
    const {loginData} = useSelector(state=>state.loginReducer)

    useEffect(()=>{
        dispatch(getFacilityAuditLogs({societyId: loginData?.default_community?.community_id}))
    }, [])

    return (
        <Layout>
          <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
            <div className="border-b border-[#CCC]">
              <ListHeaderBack onClick={()=>navigate(-1)} title="Facility Audit">
              </ListHeaderBack>
            </div>
            <div>
              {
                loading ?
                <Loader/>
                :
                <FacilityAuditAccordians />
              }
            </div>
          </div>
        </Layout>
      );
}

export default FacilityAuditLogs