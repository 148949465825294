import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ComplaintSettingTable from "../../../components/TableComponent/ComplaintSettingTable";
import Loader from "../../../components/Loader/Loader";
import { getComplaintSettingReqV2 } from "../../../axios/request/DeskReqs/ComplaintCategoryReqs/ComplaintCategoryReqs";

const headCells = [
  {
    id: "categories",
    label: "Complaint Categories",
    sort: false,
    width: "250px",
  },
  { id: "L-1", label: "L-1", sort: false, width: "230px" },
  { id: "L-2", label: "L-2", sort: false, width: "230px" },
  { id: "L-3", label: "L-3", sort: false, width: "230px" },
];

const Table = () => {
  const { loginData } = useSelector((state) => state.loginReducer);
  const [complaintSetting, setComplaintSetting] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadComplaintSetting = async () => {
      try {
        let data = await getComplaintSettingReqV2({
          societyId: loginData?.default_community?.community_id,
        });
        setComplaintSetting(data.data);
      } catch {
        alert("Something Went wrong");
      } finally {
        setLoading(false);
      }
    };
    loadComplaintSetting();
  }, []);
  return (
    <>
      {!loading && complaintSetting?.setting.length ? (
        <ComplaintSettingTable
          loading={loading}
          data={complaintSetting}
          columns={headCells}
          checkbox={false}
          pagination={false}
        ></ComplaintSettingTable>
      ) : (
        <div>
          <Loader />
        </div>
      )}
      {!loading && !complaintSetting?.setting.length ? (
        <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
          No Records
        </div>
      ) : null}
    </>
  );
};

function ComplaintSetting() {

  return (
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2.5">
        <Table />
      </div>

  );
}

export default ComplaintSetting;
