import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Layout from "../../../Layout/Layout";
import ArchiveOccupantsTable from "../../../../components/TableComponent/ArchiveOccupantsTable";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import { OCCUPANTS_INFO_ENDPOINT } from "../../../../Routing/routes";
import { useDispatch, useSelector } from "react-redux";
import { getArchiveOccupantsList } from "../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/OccupantsInfoAction";
import moment from "moment";

const headings = [
  { id: 'sNo', label: 'S No.', sort: false, width: '42px' },
  { id: 'flatno', label: 'Flat No.', sort: true,width: '110px' },
  { id: 'title', label: 'Title', sort: true, width:'90px' },
  { id: 'memberName', label: 'Member Name', sort: true, width:'130px' },
  { id: 'type', label: 'Type', sort: true, width: '80px' },
  { id: 'intercom', label: 'Intercom', sort: true, width:'90px'},
  { id: 'contactNo', label: 'Contact No.', sort: true,width:'110px' },
  { id: 'email', label: 'Email', sort: true, width:'150px' },
  { id: 'archivedDate', label: 'Archived Date', sort: true,width:'120px' },

];

const rows = []

const ArchiveOccupantsMembers = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()

  const dispatch = useDispatch()
  const {loading, archiveOccupants} = useSelector(state=>state.occupantInfoReducer)
  const {loginData} = useSelector(state=>state.loginReducer)


  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const getTableData =() =>{
    const data={
      societyId: loginData?.default_community?.community_id,
      page: searchParams.get("page") || 1,
      pageLimit: searchParams.get("pageLimit") || 10,
    }

    dispatch(getArchiveOccupantsList(data))
  }

  useEffect(()=>{      
    getTableData()
  }, [searchParams])


  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="flex gap-5">
          <ListHeaderBack
            onClick={()=> navigate(-1)}
            title="Archive Members"
          ></ListHeaderBack>
        </div>
        <div className="mx-1.5">
          <ArchiveOccupantsTable
            loading={loading}
            data={archiveOccupants?.archived_occupants_list?.map((item, index)=>({
              sNo: (((archiveOccupants?.current_page_no - 1) * (searchParams.get("pageLimit") || 10) + 1)+index)?.toString()?.padStart(2, "0"),
              flatno: (item?.wing_no + '-' +  item?.flat_no) || '-',
              title: item?.title  || '-',
              memberName: item?.subscriber_prefix ? item?.subscriber_prefix + ". " + item?.subscriber_name : item?.subscriber_name,
              type: item?.type || '-',
              intercom: item?.intercom_no || 'N/A',
              contactNo: `+${item?.mobile_country_code}-${item?.subscriber_number}`  || '-',
              liveshereornot: item?.lives_here  || '-',
              archivedDate: item?.archived_date ? moment(new Date(item?.archived_date))?.format('MMM DD,YYYY hh:mm A') : '-',
              flatId: item?.flat_id,
              email: item?.subscriber_email  || '-',
              subscriberId: item?.subscriber_id,
            }))}
            columns={headings}
            checkbox={false}
            pagination={archiveOccupants?.archived_occupants_list?.length}
            totalPages={archiveOccupants?.total_pages}
            totalCount={archiveOccupants?.total_record} 
            currentPage={archiveOccupants?.current_page_no}
            start={(archiveOccupants?.current_page_no - 1) * (searchParams.get("pageLimit") || 10) + 1}
            end={(archiveOccupants?.current_page_no - 1) * (searchParams.get("pageLimit") || 10) + archiveOccupants?.archived_occupants_list?.length}
            rowsPerPage={searchParams.get("pageLimit") || 10}
            getTableData={getTableData}
          ></ArchiveOccupantsTable>
          {!archiveOccupants?.archived_occupants_list?.length  && !loading &&  (
            <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
              No Records
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ArchiveOccupantsMembers;
