import * as type from "../../../actions/index.types";

export function importantContactsReducer(
  state = {
    loading: false,
    loadingCategories: false,
    updateLoader: false,
    importantContactList: null,
    categoryList: null,
    error: null,
  },
  action
) {
  switch (action.type) {
    case type.GET_IMPORTANT_CONTACTS_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_IMPORTANT_CONTACTS_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        importantContactList: action.payload,
      };
    case type.GET_IMPORTANT_CONTACTS_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        importantContactList: null,
      };

    
    case type.GET_CONTACTS_CATEGORY_LIST:
    return {
        ...state,
        loadingCategories: true,
        error: null,
    };
    case type.GET_CONTACTS_CATEGORY_LIST_SUCCESS:
    return {
        ...state,
        error: null,
        loadingCategories: false,
        categoryList: action.payload,
    };
    case type.GET_CONTACTS_CATEGORY_LIST_FAILURE:
    return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loadingCategories: false,
        categoryList: null,
    };

    default:
      return state;
  }
}
