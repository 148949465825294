import { ErrorMessage, FastField } from "formik";
import { onlyAlphaNumberKey, onlyDecimalKey, onlyNumberKey, onlyPhoneKey, pasteOnlyAlphaNumberKey, pasteOnlyDecimalKey, pasteOnlyNumberKey } from "../../utils/helpers/universalFunctions";
import { memo } from "react";

export const TextInput = memo(({ label, name, placeholder, className, value, type, maxLength, disabled=false, onChange=undefined }) => (
    <div className="flex items-center justify-between w-full ">
      {label && <span className="text-sm">{label}</span>}
      <div className={`${className}`}>
        <div className={`${className} text-sm border border-[#CCCCCC] rounded-md p-2 bg-[#F9FAFC] `}>
          {
            type==='number' || type==='decimal' || type==='phone' ?
            <input
              type="text"
              placeholder={placeholder}
              className="bg-[#F9FAFC] outline-none border-none w-full placeholder-italic text-black not-italic text-xs font-semibold"
              name={name}
              autoComplete='off'
              value={value}
              disabled={disabled}
              maxLength={maxLength}
              onChange={onChange}
              onKeyPress={type==='number' ? onlyNumberKey :  (type==='decimal' ? onlyDecimalKey : onlyPhoneKey)}
              onPaste={type==='number' ? pasteOnlyNumberKey :  (type==='decimal' ? pasteOnlyDecimalKey : pasteOnlyNumberKey)}
            />
            :
            (type==='alphaNumber'?
              <input
                type="text"
                placeholder={placeholder}
                className="bg-[#F9FAFC] outline-none border-none w-full placeholder-italic text-black not-italic text-xs font-semibold"
                name={name}
                autoComplete='off'
                value={value}
                disabled={disabled}
                maxLength={maxLength}
                onChange={onChange}
                onKeyPress={onlyAlphaNumberKey}
                onPaste={pasteOnlyAlphaNumberKey}
              />
            :
            <input
              type="text"
              disabled={disabled}
              placeholder={placeholder}
              className="bg-[#F9FAFC] outline-none border-none w-full placeholder-italic text-black not-italic text-xs font-semibold"
              name={name}
              autoComplete='off'
              value={value}
              maxLength={maxLength}
              onChange={onChange}
            />)
          }
        </div>
      </div>
    </div>
  ));

export const TextInputFM = memo(({ label, name, placeholder, className, type, maxLength, customError, errorBoxClassname, disabled=false }) => (
    <div className="flex justify-between w-full mb-2">
      {label && <span className="text-sm mt-[7.8px]">{label}</span>}
      <div>
        <div className={`${className} text-sm border border-[#CCCCCC] rounded-md p-2 bg-[#F9FAFC] `}>
          {
            type==='number' || type==='decimal' || type==='phone' ?
              <FastField
              disabled={disabled}
              type="text"
              placeholder={placeholder}
              className="bg-[#F9FAFC] outline-none border-none w-full placeholder-italic text-black not-italic text-xs font-semibold"
              name={name}
              autoComplete='off'
              maxLength={maxLength}
              onKeyPress={type==='number' ? onlyNumberKey :  (type==='decimal' ? onlyDecimalKey : onlyPhoneKey)}
              onPaste={type==='number' ? pasteOnlyNumberKey :  (type==='decimal' ? pasteOnlyDecimalKey : pasteOnlyNumberKey)}
            />
            :
            (type==='alphaNumber'?
                <FastField
                disabled={disabled}
                  type="text"
                  placeholder={placeholder}
                  className="bg-[#F9FAFC] outline-none border-none w-full placeholder-italic text-black not-italic text-xs font-semibold"
                  name={name}
                  autoComplete='off'
                  maxLength={maxLength}
                  onKeyPress={onlyAlphaNumberKey}
                  onPaste={pasteOnlyAlphaNumberKey}
              />
            :
            <FastField
            disabled={disabled}
              type="text"
              placeholder={placeholder}
              className="bg-[#F9FAFC] outline-none border-none w-full placeholder-italic text-black not-italic text-xs font-semibold"
              name={name}
              autoComplete='off'
              maxLength={maxLength}
            />)
          }
        </div>
        <div className={`text-red-500 text-xs italic h-auto text-wrap break-words ${errorBoxClassname}`}>
          {customError || <ErrorMessage name={name}/>}
        </div> 
      </div>
    </div>
  ));