import React, { useEffect } from "react";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import { TextInputFM } from "../../../../components/InputFields/TextInput";
import ProfilePicture from "../../../../components/ProfilePicture/ProfilePicture";
import { UploadButtonFM } from "../../../../components/Button/UploadButton";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getTitleList,
} from "../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/OccupantsInfoAction";
import { DropdownFM2 } from "../../../../components/Dropdown/dropdown2";
import {
  classMemberOptions,
  genderOptions,
  namePrefixOptions,
} from "../../../../consts/DropDownOptions";
import { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import countries from "../../../../consts/countries";
import moment from "moment";
import Loader from "../../../../components/Loader/Loader";

const EditMemberDetailsForm = ({values, setFieldValue, loading}) => {
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { titleList, occupantDetail } = useSelector((state) => state.occupantInfoReducer);


  useEffect(()=>{
    const societyId= loginData?.default_community?.community_id
    dispatch(getTitleList({societyId}))
  }, [])

  return (
    <div>
      <div className="flex justify-between w-full gap-3 min-h-[545px]">
        {occupantDetail?.length === 0 ? (
          <div className="flex justify-center items-center"> No Details </div>
        ) : loading ? (
          <Loader />
        ) : (
          <>
        <div className="w-1/2 gap-2 pr-5">
          <div className="flex justify-between text-sm items-center">
            <span className="mb-3">First Name</span>
            <div className="flex gap-2">
              <DropdownFM2
                options={namePrefixOptions}
                width={"73px"}
                className="text-xs"
                placeholder={"Select"}
                onSelect={(selectedItem) => {
                  setFieldValue(`prefix`, selectedItem.value);
                }}
                height="38px"
                name={`prefix`}
                value = {values.prefix &&{
                  label: values.prefix,
                  value: values.prefix,
                }} 
              />
              <TextInputFM
                label=""
                placeholder="Enter First Name"
                className="w-36"
                name={`firstName`}
                maxLength={30}
              />
            </div>
          </div>
          <TextInputFM
            label="Last Name"
            placeholder="Enter Last Name"
            className="w-56"
            name={`lastName`}
            maxLength={30}
          />
          <div className="flex  justify-between text-sm">
            <span className="mt-2">Class of Member</span>
            <DropdownFM2
              options={classMemberOptions}
              width={"224px"}
              className="text-xs"
              placeholder="Select Member"
              onSelect={(selectedItem) => {
                setFieldValue(`classMemberId`, selectedItem.value);
                setFieldValue(`classMember`, selectedItem.label);
              }}
              height="38px"
              name={`classMemberId`}
              value = { values.classMemberId && {
                label:  values.classMember,
                value: values.classMemberId,
              }} 
            />
          </div>
          <div className="flex  justify-between text-sm">
            <span className="mt-2">Gender</span>
            <DropdownFM2
              options={genderOptions}
              width={"224px"}
              className="text-xs"
              placeholder="Select Gender"
              onSelect={(selectedItem) => {
                setFieldValue(`gender`, selectedItem.value);
              }}
              height="38px"
              name={`gender`}
              value = {values.gender && {
                label: genderOptions?.find(item => item.value === values.gender)?.label,
                value: values.gender,
              }} 
            />
          </div>
          <TextInputFM
            label="Mobile Number"
            placeholder="Enter Number"
            className="w-56"
            name={`mobileNo`}
            type="number"
            maxLength={12}
          />
          <div className="w-full flex -mt-1 ms-1 mb-2">
            <div className="w-1/2"></div>
            <div className="w-1/2 text-xs">
              <Checkbox
                text="Public"
                initialchecked={values?.mobileVisible === "Yes"}
                onChange={(e) => {
                  setFieldValue( `mobileVisible`, e.target.checked ? "Yes" : "No");
                }}
              />
            </div>
          </div>
          <TextInputFM
            label="Landline Number(Office)"
            placeholder="Enter Number"
            className="w-56"
            name={`landlineNo`}
            type="number"
            maxLength={12}
          />
          <TextInputFM
            label="Primary Email Id"
            placeholder="Enter Email ID"
            className="w-56"
            name={`primaryEmail`}
          />
          <div className="w-full flex -mt-2 mb-3">
            <div className="w-1/2"></div>
            <div className="w-1/2">
              <div className="flex justify-between ms-1.5 mt-2">
                <Checkbox
                  text="Public"
                  className="text-xs"
                  initialchecked={values?.emailVisible === "Yes"}
                  onChange={(e) => {
                    setFieldValue(
                      `emailVisible`,
                      e.target.checked ? "Yes" : "No"
                    );
                  }}
                />
                <Checkbox
                  text="Login Access"
                  className="text-xs"
                  initialchecked={!!values?.loginAccess}
                  onChange={(e) => {
                    setFieldValue(`loginAccess`, e.target.checked ? 1 : 0);
                  }}
                />
              </div>
            </div>
          </div>
          <TextInputFM
            label="Identity Proof Number"
            placeholder="Enter"
            className="w-56"
            type="alphaNumber"
            name={`adharCardNo`}
            maxLength="12"
          />
          <TextInputFM
            label="Pan Card"
            placeholder="Enter Pan Number"
            className="w-56"
            name={`panCardNo`}
            type="alphaNumber"
            maxLength="10"
          />
          <div className="mt-[56px]">
            <div className="flex flex-row items-center justify-between">
              <span className="text-sm">Date of Birth</span>
              <DatePickerComponentFM
                className="w-56 h-9 ml-4 justify-between"
                onDateChange={(selectedDate) => {
                  setFieldValue("dob", moment(new Date(selectedDate)).format('YYYY-MM-DD'));
                }}
                name="dob"
                defaultValue={
                  values.dob ? new Date(values.dob) : null
                }
                maxDate={moment().subtract(18, 'years').toDate()}
              />
            </div>
            <div className="flex flex-row items-center justify-between mb-4">
              <span className="text-sm">Anniversary Date</span>
              <DatePickerComponentFM
                className="w-56 h-9 ml-4 justify-between"
                onDateChange={(selectedDate) => {
                  setFieldValue("anniversaryDate", selectedDate);
                }}
                name="anniversaryDate"
                defaultValue={
                  values.anniversaryDate
                    ? new Date(values.anniversaryDate)
                    : null
                }
                maxDate={new Date()}
              />
            </div>
          </div>
        </div>
        <div className="w-1/2 gap-2 pr-5">
          <TextInputFM
            label="Middle Name"
            placeholder="Enter Middle Name"
            className="w-56"
            name={`middleName`}
          />
          <div className="flex flex-row justify-between text-sm mt-3 mb-7">
            <div className="w-1/2">Member Name</div>
            <div className="w-1/2 ">
              <div>
                {occupantDetail?.user_detail?.prefix +
                  ". " +
                  occupantDetail?.user_detail?.f_name +
                  " " +
                  occupantDetail?.user_detail?.m_name +
                  " " +
                  occupantDetail?.user_detail?.l_name || "-"}
              </div>
            </div>
          </div>
          <div className="flex  justify-between text-sm">
            <span className="mt-2">Role</span>
            <DropdownFM2
              options={
                titleList?.map((item) => ({
                  label: item.title,
                  value: item?.title_id,
                })) || []
              }
              width={"224px"}
              className="text-xs"
              placeholder={"Select Role"}
              onSelect={(selectedItem) => {
                setFieldValue(`title`, selectedItem.label);
                setFieldValue(`titleId`, selectedItem.value);
              }}
              height="38px"
              name={`titleId`}
              value = {values.titleId && {
                label: titleList?.find(item=> item.title_id === values.titleId)?.title,
                value: values.titleId,
              }} 
            />
          </div>
          <div className="flex  justify-between text-sm">
            <span className="mt-2">Country</span>
            <DropdownFM2
              options={ countries?.map((item)=>({
                label: `${item?.name?.toUpperCase()} (${item?.phonecode})`,
                value: item?.phonecode?.replace("+",""),
                iso: item.iso,
                id: item?.id
              })) || []}
              width={"224px"}
              className="text-xs"
              placeholder={"Select Country"}
              onSelect={(selectedItem)=>{
                setFieldValue(`mobileCountryCode`, selectedItem.value)
                setFieldValue(`countryId`, selectedItem.id)
                setFieldValue(`countryISO`, selectedItem.iso)
              }}
              height='38px'
              name={`mobileCountryCode`}
              value={values.mobileCountryCode && {
                label: `${countries?.find(item=> item.phonecode?.replace('+','') === occupantDetail?.user_detail?.mobile_country_code?.replace('+', ''))?.name?.toUpperCase()} (+${values.mobileCountryCode})`,
                value: values.mobileCountryCode,
                iso: values.countryISO,
                id: values.countryId
              }}
            />
          </div>
          <TextInputFM
            label="Secondary Mobile Number"
            placeholder="Enter Number"
            className="w-56"
            type="number"
            name={`secondaryMobileNo`}
            maxLength={12}
          />
          <div className="w-full flex -mt-2">
            <div className="w-1/2"></div>
            <div className="w-1/2">
              <Checkbox
                text="Send SMS on Alternate Number"
                className="text-xs whitespace-nowrap"
                initialchecked={values?.sendeSMSOnAlternateNo === "Yes"}
                onChange={(e) => {
                  setFieldValue(
                    `sendeSMSOnAlternateNo`,
                    e.target.checked ? "Yes" : "No"
                  );
                }}
              />
            </div>
          </div>
          <div className="mt-1">
            <TextInputFM
              label="Secondary Email Id"
              placeholder="Enter Email"
              className="w-56"
              name={`secondaryEmail`}
            />
            <div className="w-full flex -mt-2">
              <div className="w-1/2"></div>
              <div className="w-1/2">
                <Checkbox
                  text="Send SMS on Alternate Email"
                  className="text-xs"
                  initialchecked={values?.sendeSMSOnAlternateEmail === "Yes"}
                  onChange={(e) => {
                    setFieldValue(
                      `sendeSMSOnAlternateEmail`,
                      e.target.checked ? "Yes" : "No"
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex text-sm mb-4 mt-10">
            <div className="w-1/2">Identity Proof Attachment</div>
            <div>{occupantDetail?.user_detail?.aadhar_file_url ? <Link target="_blank" className="ms-1 underline text-xs text-blue-500" to={occupantDetail?.user_detail?.aadhar_file_url}>Click To View</Link> : '-'}</div>
          </div>
          <div className="text-sm flex justify-between items-center mb-6">
            <span className="-mt-7">Upload Identity Proof</span>
            <div className="w-1/2 -mt-2">
              <UploadButtonFM
                showFileName={true}
                name={`adharFile`}
                accept={
                  "image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                }
                onSelect={(e) => setFieldValue(`adharFile`, e)}
                fileName={values.adharFile?.name}
                className="h-9"
              />
            </div>
          </div>
          <div className="-mt-5">
            <TextInputFM
              label="GST Registration Number"
              placeholder="Enter GST Number"
              className="w-56"
              name={`gstRegistrationNumber`}
              type="alphaNumber"
              maxLength={15}
            />
          </div>
          <div className="w-full flex -mt-2 mb-4">
            <div className="w-1/2"></div>
            <div className="w-1/2">
              <Checkbox
                text="Show In Bill"
                className="text-xs"
                initialchecked={!!values?.showInBill}
                onChange={(e) => {
                  setFieldValue(`showInBill`, e.target.checked ? 1 : 0);
                }}
              />
            </div>
          </div>
          <div className="mt-10">
            <ProfilePicture
              onDelete={(e)=>{setFieldValue('profilePic', '')}}
              onSelect={(e) => setFieldValue(`profilePic`, e)}
              defaultURL={occupantDetail?.user_detail?.profile_image_url}
            />
          </div>
        </div>
          </>     
        )}
      </div>
    </div>
  );
};

export default EditMemberDetailsForm;
