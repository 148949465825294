import React, { useState } from "react";
import { DeleteIcon, UploadEx } from "../../../../assets";
import { FieldArray } from "formik";
import { TextInputFM } from "../../../../components/InputFields/TextInput";
import { UploadButtonFM } from "../../../../components/Button/UploadButton";

const TenantDetailsForm = ({values, setFieldValue}) => {

  return (
    <div>
      <FieldArray
        name="tenantDetails"
        render={arrayHelpers => (
          <>
            <div className="p-2 flex flex-wrap gap-4 items-center">
              {values.tenantDetails && values.tenantDetails.length > 0 && (
                values.tenantDetails.map((tenant, entryIndex) => (
                <div
                  key={entryIndex}
                  className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg overflow-hidden mt-3"
                >
                  <div className="border-b border-[#CCC] pb-2 bg-[#FDF2F3] flex justify-between">
                    <h1 className="text-base items-center p-2 -mb-2 ml-1">
                      Tenant Details
                    </h1>
                    {values.tenantDetails.length > 1 && (
                      <button
                        className="text-red-650 font-semibold text-xs mr-2 mt-2"
                        type='button'
                        onClick={()=> arrayHelpers.remove(entryIndex)}
                      >
                        <DeleteIcon className="fill-red-650" />
                      </button>
                    )}
                  </div>
                  <div className="p-2">
                    <div className="flex flex-col">
                      <div className="flex flex-row gap-5">
                        <React.Fragment>
                          <div className="flex justify-between gap-5">
                            <span className="text-sm mt-2">Tenant Name</span>
                              <TextInputFM
                                placeholder="Enter Tenant Name"
                                className="w-40"
                                name={`tenantDetails.${entryIndex}.name`}
                                maxLength={50}
                              />
                          </div>
                          <div className="flex justify-between gap-5">
                            <span className="text-sm mt-2">Contact Number</span>
                            <TextInputFM
                              placeholder="Enter Contact Number"
                              className="w-40"
                              name={`tenantDetails.${entryIndex}.contactNo`}
                              type='number'
                              maxLength={14}
                            />
                          </div>
                          <div className="flex justify-between gap-5">
                            <span className="text-sm mt-2">Email ID</span>
                            <TextInputFM
                              placeholder="Enter Email ID"
                              className="w-40"
                              name={`tenantDetails.${entryIndex}.email`}
                            />
                          </div>
                        </React.Fragment>
                      </div>
                    </div>
                    <div>
                      <div className="w-full">
                        <div className="gap-2 w-full">
                          <FieldArray
                            name={`tenantDetails.${entryIndex}.relative`}
                            render={arrayHelpers2 => (
                              <>
                              <div className="flex gap-4 w-full">
                                <div className="p-2 flex flex-wrap gap-4 items-center">
                                  {values.tenantDetails[entryIndex].relative && values.tenantDetails[entryIndex].relative.length > 0 && (
                                    values.tenantDetails[entryIndex].relative.map((relative, entryIndex2) => (
                                      <div className="flex flex-row items-start gap-7" key={entryIndex2}>
                                        <div className="flex justify-between gap-2">
                                          <span className="text-sm mt-2">Tenant Relative</span>
                                          <TextInputFM
                                            placeholder="Enter Relative Name"
                                            className="w-40"
                                            name={`tenantDetails.${entryIndex}.relative.${entryIndex2}.name`}
                                            maxLength={50}
                                          />
                                        </div>
                                        <div className="flex justify-between gap-7">
                                          <span className="text-sm mt-2">Relationship</span>
                                          <TextInputFM
                                            placeholder="Enter Relationship"
                                            className="w-40"
                                            name={`tenantDetails.${entryIndex}.relative.${entryIndex2}.relationship`}
                                            maxLength={50}
                                          />
                                        </div>
                                        {values.tenantDetails[entryIndex].relative.length > 1 &&
                                          <button
                                            className="text-red-650 font-semibold text-xs mr-2 mt-3"
                                            type='button'
                                            onClick={()=> arrayHelpers2.remove(entryIndex2)}
                                          >
                                            <DeleteIcon className="fill-red-650" />
                                          </button>
                                        }
                                      </div>
                                    )))}                                    
                                  </div>
                                  </div>
                                  <div className="mb-5">
                                  
                                  <button
                                    className="text-red-650 font-semibold border-2 border-dashed border-red-650 w-28 text-xs whitespace-nowrap h-9 p-2 bg-[#FDF6F6] flex justify-center rounded-md"
                                    type='button'
                                    onClick={() =>
                                      arrayHelpers2.push({
                                        name: '',
                                        relationship: '',
                                      })
                                    }
                                  >
                                    Add More
                                  </button>
                                  </div>
                                </>)
                            }>
                            </FieldArray>

                          <div className="pb-2 text-left">
                            <h1 className="text-base items-center text-[#AAA]">
                              Attachments
                            </h1>
                          </div>
                          <div className="w-full flex ml-2">
                            <div className="w-1/2">
                              <div className="flex justify-between p-1">
                                <span className="text-sm mt-2">Identity Proof</span>
                                <div className="w-1/2">
                                  <UploadButtonFM showFileName={true} 
                                    name={`tenantDetails.${entryIndex}.identityProof`}
                                    accept={"image/jpeg,image/gif,image/png,application/pdf,image/x-eps"}
                                    onSelect={(e)=>setFieldValue(`tenantDetails.${entryIndex}.identityProof`, e)} 
                                    fileName={values.tenantDetails[entryIndex].identityProof?.name}
                                  />
                                </div>
                              </div>

                              <div className="flex justify-between p-1">
                                <span className="text-sm mt-2">No Dues Certificate</span>
                                <div className="w-1/2">
                                  <UploadButtonFM showFileName={true} 
                                    name={`tenantDetails.${entryIndex}.noc`}
                                    accept={"image/jpeg,image/gif,image/png,application/pdf,image/x-eps"}
                                    onSelect={(e)=>{
                                      setFieldValue(`tenantDetails.${entryIndex}.noc`, e)}} 
                                    fileName={values.tenantDetails[entryIndex].noc?.name}
                                  />
                                </div>
                              </div>

                              <div className="flex justify-between p-1">
                                <span className="text-sm mt-2">Form 29</span>
                                <div className="w-1/2">
                                  <UploadButtonFM showFileName={true} 
                                    name={`tenantDetails.${entryIndex}.form29`}
                                    accept={"image/jpeg,image/gif,image/png,application/pdf,image/x-eps"}
                                    onSelect={(e)=>setFieldValue(`tenantDetails.${entryIndex}.form29`, e)} 
                                    fileName={values.tenantDetails[entryIndex].form29?.name}
                                  />
                                </div>
                              </div>
                              
                            </div>
                            <div className="w-1/2">
                              <div className="flex justify-between p-1">
                                <span className="text-sm mt-2">Pan Card</span>
                                <div className="w-1/2">
                                  <UploadButtonFM showFileName={true} 
                                    name={`tenantDetails.${entryIndex}.panCard`}
                                    accept={"image/jpeg,image/gif,image/png,application/pdf,image/x-eps"}
                                    onSelect={(e)=>setFieldValue(`tenantDetails.${entryIndex}.panCard`, e)} 
                                    fileName={values.tenantDetails[entryIndex].panCard?.name}
                                  />
                                </div>
                              </div>

                              <div className="flex justify-between p-1">
                                <span className="text-sm mt-2">Form 11</span>
                                <div className="w-1/2">
                                  <UploadButtonFM showFileName={true} 
                                    name={`tenantDetails.${entryIndex}.form11`}
                                    accept={"image/jpeg,image/gif,image/png,application/pdf,image/x-eps"}
                                    onSelect={(e)=>setFieldValue(`tenantDetails.${entryIndex}.form11`, e)} 
                                    fileName={values.tenantDetails[entryIndex].form11?.name}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
              ))}
            </div>
            <div className="mt-2">
              <button
                className="text-red-650 font-semibold border-2 border-dashed border-red-650 w-28 text-xs whitespace-nowrap h-9 p-2 bg-[#FDF6F6] flex justify-center rounded-md"
                onClick={() =>
                  arrayHelpers.push({
                    name: '',
                    contactNo: '',
                    email: '',
                    relative: [{
                      name: '',
                      relationship: '',
                    }],
                    identityProof: '',
                    panCard: '',
                    noc: '',
                    form11: '',
                    form29:  '',
                  })
                }
                type='button'
              >
                Add More
              </button>
            </div>
          </>)
          }>
        </FieldArray>
    </div>
  );
};

export default TenantDetailsForm;
