import React, { useEffect } from "react";
import { ReactComponent as CloseIcon } from "../../../assets/icons/closeIcon.svg";
import Button from "../../../components/Button/Button";
import TextRadio from "../../../components/InputFields/TextRadio";
import { DropdownFM2 } from "../../../components/Dropdown/dropdown2";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { addGateAccessData, getGateAccessData, getSocietyEntryGate } from "../../../redux/actions/GateManagementActions/VisitorAndVehicleInAndOutAction";
import { Formik, Form } from "formik";
import { GateSettingValidation } from "../../../validationSchema/gateManagementSchema/visitorAndVehicleInAndOutSchema";
import Loader from "../../../components/Loader/Loader";
import { CircularProgress } from "@mui/material";

const VisitorSettingModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch()
  const { loginData } = useSelector((state) => state.loginReducer);
  const {loadingModal, updateLoader, accessData, societyGateList } = useSelector((state) => state.visitorAndVehicleInAndOutReducer);

  const typeOptions = [
    { name: "type", value: "StaffBoth", label: "Both" },
    { name: "type", value: "StaffIn", label: "Check In" },
    { name: "type", value: "StaffOut", label: "Check Out" },
  ];

  useEffect(()=>{
    dispatch(getGateAccessData({societyId: loginData?.default_community?.community_id, sessionId: loginData?.session_id}))
    dispatch(getSocietyEntryGate({societyId: loginData?.default_community?.community_id}))
  }, [])

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-60 z-20 ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="bg-white rounded-lg w-[420px] h-fit">
        <div className="flex justify-between p-2 gap-8 items-center -mb-2">
          <h2 className="p-2 text-lg font-semibold mb-2 items-center text-center w-[80%] flex justify-end">
            Settings
          </h2>
          <div className="w-1/2 flex justify-end">
            <button
              onClick={onClose}
              className="bg-[#EAEAEA] rounded-full p-2 items-center mb-2 w-9 h-9 "
            >
              <CloseIcon className="fill-current text-gray-600 hover:text-gray-800 ml-1 w-3 h-3 cursor-pointer" />
            </button>
          </div>
        </div>
        <div className="border-b border-[#CCCCCC]"></div>
        <div className="p-2 min-h-[210px]">
          {
            loadingModal ?
            <Loader/>:
            <Formik
              enableReinitialize
              initialValues={{ 
                id: accessData?.id,
                inGate: accessData?.staffInGate ? parseInt(accessData?.staffInGate) : '',
                outGate: accessData?.staffOutGate ? parseInt(accessData?.staffOutGate) : '',
                gateAccess: accessData?.staffAccess || 'StaffBoth',
              }}
              onSubmit={values =>
                dispatch(addGateAccessData({...values, societyId: loginData?.default_community?.community_id, sessionId: loginData?.session_id, onSucessCallback: ()=>{onClose()}}))
              }
              validationSchema={GateSettingValidation}
              >    
              {
                ({values, dirty, setFieldValue})=>{
                  return (
                    <Form>
                      <>
                        <div className="px-2 flex justify-between">
                          <span className="text-sm mt-2">In Gate:</span>
                          <DropdownFM2
                            options={societyGateList?.map(item => ({
                              value: item?.gate_id,
                              label: item?.entry_gate
                            })) || []}
                            placeholder="Select Type"
                            className=""
                            width="220px"
                            height="36px"
                            name={"inGate"}
                            onSelect={(selectedItem)=>{
                              setFieldValue('inGate', selectedItem.value)
                            }}
                            value={values.inGate ? {
                              label: societyGateList?.find(item=>item.gate_id === values.inGate)?.entry_gate,
                              value: values.inGate
                            }: null}
                          />
                        </div>
                        <div className="flex px-2 justify-between">
                          <span className="text-sm mt-2">Out Gate:</span>
                          <DropdownFM2
                            options={societyGateList?.map(item => ({
                              value: item?.gate_id,
                              label: item?.entry_gate
                            })) || []}
                            placeholder="Select Type"
                            className=""
                            width="220px"
                            height="36px"
                            name={"outGate"}
                            onSelect={(selectedItem)=>{
                              setFieldValue('outGate', selectedItem.value)
                            }}
                            value={values.outGate ? {
                              label: societyGateList?.find(item=>item.gate_id === values.outGate)?.entry_gate,
                              value: values.outGate
                            }: null}
                        />
                        </div>
                        <div className="p-2 my-1">
                          <TextRadio
                            label="Gate Access"
                            options={typeOptions}
                            defaultSelected={values.gateAccess}
                            onChange={(value)=> {setFieldValue('gateAccess', value)}}
                            className={"w-56 whitespace-nowrap text-xs"}
                          />
                        </div>
                        <div className="border-b"></div>
                        <div className="flex justify-center mt-2">
                          <Button
                            label={
                              <span className="w-8 inline-block">
                                {
                                  updateLoader ?
                                    <CircularProgress sx={{color: "white"}} size={17}/>
                                    :
                                    'Save'
                                  }
                              </span>
                            }
                            className="h-8 font-semibold"
                            disabled={loadingModal || updateLoader || !dirty}
                            type={"submit"}
                          />
                        </div>
                      </>
                    </Form>)
                }
              }
              </Formik>
          }
        </div>
      </div>
    </div>
  );
};

export default VisitorSettingModal;
