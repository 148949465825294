import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../actions/index.types";
import * as req from "../../../axios/request/DeskReqs/DocumentReqs/DocumentReqs";
import { notify } from "../../../utils/notification";
import { getFirebaseURL, uploadImageOnFirebase } from "../../../firebase/firebaseFunction";
import { complaintDocPath, complaintTicketDocPath } from "../../../firebase/getFirebasePath";

function* getFolderList(action) {
  try {
    const result = yield call(req.getFolderListReq, action);
    if (result.statusCode === 200) {
      yield put({
        type: types.GET_DOCUMENT_FOLDER_LIST_SUCCESS,
        payload: result.data,
      });
    } else {
      yield put({
        type: types.GET_DOCUMENT_FOLDER_LIST_FAILURE,
        message: result?.response?.data?.message,
      });
    }
  } catch (e) {
    console.log(e);
  }
}

function* getDocFileList(action) {
  try {
    const result = yield call(req.getDocFileListReq, action);
    if (result.statusCode === 200) {
      for(let doc of result.data){
        doc.file = yield getFirebaseURL(doc?.file)
    }
      yield put({
        type: types.GET_DOCUMENT_FILE_LIST_SUCCESS,
        payload: result.data,
      });
    } else {
      yield put({
        type: types.GET_DOCUMENT_FILE_LIST_FAILURE,
        message: result?.response?.data?.message,
      });
    }
  } catch (e) {
    console.log(e);
  }
}

function* addFolderName(action) {
  try {
    const result = yield call(req.addFolderNameReq, action);
    if (result.statusCode === 200) {
      notify("success", result.message);
      action.data?.onSuccesCallback();
      yield put({ type: types.ADD_FOLDER_NAME_SUCCESS });
    } else {
      yield put({
        type: types.ADD_FOLDER_NAME_FAILURE,
        message: result?.response?.data?.message,
      });
    }
  } catch (e) {
    console.log(e);
  }
}

function* getDocumentOccupantList(action) {
  try {
    const result = yield call(req.getDocumentOccupantsListReq, action);
    if (result.statusCode === 200) {
      yield put({
        type: types.GET_DOCUMENT_OCCUPANTS_LIST_SUCCESS,
        payload: result.data,
      });
    } else {
      yield put({
        type: types.GET_DOCUMENT_OCCUPANTS_LIST_SUCCESS,
        message: result?.response?.data?.message,
      });
    }
  } catch (e) {
    console.log(e);
  }
}

function* updateDocumentsList(action) {
  try {
    const chooseDocumentPath = complaintDocPath({societyId: action.data?.societyId, fileName: action.data.chooseDocument?.name})
    yield uploadImageOnFirebase(action.data.chooseDocument, chooseDocumentPath)
    action.data.chooseDocument = chooseDocumentPath;

    const result = yield call(req.uploadDocumentReq, action);
    if (result.statusCode === 200) {
      notify("success", result.message);
      action.data?.onSuccessCallback();
      yield put({ type: types.UPDATE_DOCUMENTS_LIST_SUCCESS });
    } else {
      yield put({
        type: types.UPDATE_DOCUMENTS_LIST_FAILURE,
        message: result?.response?.data?.message,
      });
    }
  } catch (e) {
    console.log(e);
  }
}

function* editDocumentList(action) {
  try {
    if(typeof action.data.file !== 'string'){
      let path = complaintDocPath({societyId: action.data.societyId, fileName: action.data.file?.name});
      yield uploadImageOnFirebase(action.data.file, path)
      action.data.file= path;
  }
    const result = yield call(req.editDocumentReq, action);
    if (result.statusCode === 200) {
      notify("success", result.message);
      yield put({ type: types.Edit_DOCUMENTS_LIST_SUCCESS });
    } else {
      yield put({
        type: types.Edit_DOCUMENTS_LIST_FAILURE,
        message: result?.response?.data?.message,
      });
    }
  } catch (e) {
    console.log(e);
  }
}

function* deleteDocument(action) {
  try {
    const result = yield call(req.deleteDocumentReqs, action);
    if (result.statusCode === 200) {
      notify("success", result.message);
      action.data?.onSuccessCallback();
      yield put({ type: types.DELETE_DOCUMENT_SUCCESS, payload: action.data });
    }
  } catch (e) {
    console.log(e);
  }
}

export default function* getDocumentSaga() {
  yield takeLatest(types.GET_DOCUMENT_FOLDER_LIST, getFolderList);
  yield takeLatest(types.GET_DOCUMENT_FILE_LIST, getDocFileList);
  yield takeLatest(types.ADD_FOLDER_NAME, addFolderName);
  yield takeLatest(types.GET_DOCUMENT_OCCUPANTS_LIST, getDocumentOccupantList);
  yield takeLatest(types.UPDATE_DOCUMENTS_LIST, updateDocumentsList);
  yield takeLatest(types.Edit_DOCUMENTS_LIST, editDocumentList);
  yield takeLatest(types.DELETE_DOCUMENT, deleteDocument);
}
