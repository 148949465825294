import React, { useState } from "react";
import { DownArrow } from "../../assets";

const ItemsPerPage = ({rowsPerPage, onChange}) => {
  const [isOpen, setIsOpen] = useState(false);
  const numbers = [10, 25, 50, 100];

  const handleOnSelect = (number) => {
    onChange(number)
  };

  return (
    <div className="relative text-left flex flex-row p-2 items-center">
      <p className="mr-2">Items per page:</p>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="bg-white border border-[#CCC] rounded-md focus:outline-none flex flex-row items-center p-1"
        style={{ paddingLeft: "4px" }}
      >
        <span className="mr-1">{rowsPerPage}</span>
        <span>
          <DownArrow/>
        </span>
      </button>
      {isOpen && (
        <div className=" absolute right-[10px] mt-2 w-14 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
          <div className="py-1">
            {numbers.map((number, index) => (
              <button
                key={index}
                onClick={() => {
                  handleOnSelect(number);
                  setIsOpen(false);
                }}
                className="block w-full text-left px-4 py-2 hover:bg-[#CCC] focus:outline-none"
              >
                {number}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ItemsPerPage;
