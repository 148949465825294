import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import ListHeader from "../../../components/ListHeader/ListHeader";
import Button from "../../../components/Button/Button";
import RangeDatePicker from "../../../components/DatePicker/RangeDatePicker";
import { Box, Skeleton, Tab, Tabs } from "@mui/material";
import styled from "@emotion/styled";
import MainVisitorForm from "./MainVisitorForm";
import StaffInOut from "./StaffInOut";
import VisitorStaffLog from "./VisitorLog";
import FrequentVisitor from "./FrequentVisitor";
import ExpectedVisitor from "./ExpectedVisitor";
import AddGateModal from "./AddGateModal";
import VisitorSettingModal from "./VisitorSettingsModal";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import moment from "moment";
import { getVisitorOrVehicleDashboardData } from "../../../redux/actions/GateManagementActions/VisitorAndVehicleInAndOutAction";
import { addPadZero } from "../../../utils/helpers/universalFunctions";
import { getPermission } from "../../../utils/helpers/getPermissions";
import * as permissions from '../../../Routing/permissions'

const categories = [
  {title: "Frequent Visitor",  key: 'frequent_visitors'},
  {title: "Overstayed Visitor",  key: 'over_stay'},
  {title: "Expected Visitor",  key: 'expected'},
  {title: "Waiting At Gate",  key: 'waiting_at_gate'},
];


const boxData = [
  {
    row1: {
      label: "Checked In",
      value: "05",
      key:'checked_in'
    },
    row2: {
      label: "Checked Out",
      value: "05",
      key:'checked_out'
    },
  },
];

const boxData1 = [
  {
    row1: {
      label: "Checked In",
      value: "10",
      key: 'checked_in'
    },
    row2: {
      label: "Checked Out",
      value: "05",
      key: 'checked_out'
    },
  },
];

const renderBox = (boxData, heading, data, loading) => (
  <div className="flex items-center border-[1px] mb-2 border-[#CCC] text-[11px] w-full rounded-lg overflow-hidden bg-[#FDF2F3] h-[120px]">
    {
      loading ?
      <Skeleton animation='wave' variant="rounded" sx={{height: '120px', width: '100%',}} />
      :
      <>
        <div className="p-2 text-center ">
          <div className="font-bold border-r border-[#CCC] pe-4">{heading}</div>
          <div className="text-[#CF001C] font-bold text-[34px] border-r border-[#CCC] pe-4">
            {addPadZero(data?.currently_in_premises)}
          </div>
          <div className="text-[#AAAAAA] text-center border-r border-[#CCC] pe-4">
            Currently in Premises
          </div>
        </div>
        {boxData[0] && (
          <>
            <div className="py-1.5 font-bold text-center">
              <div className="grid grid-cols-2 text-[11px] h-12">
                <div className="col-span-2 border-b-[1px] border-[#CCC] mx-4 flex flex-col justify-center">
                  <div className={!boxData[0].row2.value && "text-[#CF001C]"}>
                    {boxData[0].row1.label}
                  </div>
                  <div className="text-[#CF001C] text-[20px]">
                    {addPadZero(data?.[boxData[0].row1.key])}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 text-[11px] h-12">
                <div className="col-span-2 mx-4 flex flex-col justify-center whitespace-nowrap mt-2">
                  <div className={!boxData[0].row2.value && "text-[#CF001C]"}>
                    {boxData[0].row2.label}
                  </div>
                  <div className="text-[#CF001C] text-[20px]">
                    {addPadZero(data?.[boxData[0].row2.key])}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    }
  </div>
);

function VisitorDashboard() {

  const StyledTab = styled(Tab)({
    textTransform: "none",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "'Open Sans', sans-serif",
    borderRight: "1px solid #CCC",
    background: "#FDF2F3",

    "&.Mui-selected": {
      color: "#CF001C",
    },
  });

  const [tabValue, setTabValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };
  const [settings, setSettings] = useState(false);
  const [addGate, setAddGate] = useState(false);
  const [filter, setFilter] = useState({
    fromDate: moment().subtract(2, "days").format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
  })
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loadingDashboard, dashboardData} = useSelector(state=>state.visitorAndVehicleInAndOutReducer)

  const handleSettings = () => {
    setSettings(true);
  };
  const handleChangeSettings = () => {
    setSettings(false);
  };
  const handleAddSetting = () => {
    setAddGate(true);
  };

  const handleStartDate = (selectedDate)=>{
    if(moment(selectedDate).format('YYYY-MM-DD') !== filter.fromDate){
      setFilter(prev=> ({...prev, fromDate: moment(selectedDate).format('YYYY-MM-DD')}))
    }
  }

  const handleEndDate = (selectedDate)=>{
    if(moment(selectedDate).format('YYYY-MM-DD') !== filter.toDate){
      setFilter(prev=> ({...prev, toDate: moment(selectedDate).format('YYYY-MM-DD')}))
    }
  }

  const getData = ()=>{
    const data={
      societyId: loginData?.default_community?.community_id,
      ...filter,
      flag: 'visitors'
    }
    dispatch(getVisitorOrVehicleDashboardData(data))
  }

      
  const tabData = [
    {
      index:0,
      label: "Main Visitor",
      permissionPath: [permissions.ADD_VISITOR_PERMISSION]
    },
    {
      index:1,
      label: "Staff In/Out",
    },
    {
      index:2,
      label: "Visitor/Staff Log",
      permissionPath: [permissions.VIEW_VISITOR_LOG_PERMISSION]
    },
    {
      index:3,
      label: "Frequent Visitor",
      permissionPath: [permissions.VIEW_FREQUENT_VISITOR_PERMISSION]
    },
    {
      index:4,
      label: "Expected Visitor",
      permissionPath: [permissions.VIEW_EXPECTED_VISITOR_PERMISSION]
    },
]

  useEffect(()=>{
    getData()
  }, [filter])

  
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Visitor Dashboard">
            <RangeDatePicker className="w-44 h-8"
              defaultStartValue={new Date(filter.fromDate)}
              defaultEndValue={new Date(filter.toDate)}
              onStartDateChange={handleStartDate}
              onEndDateChange={handleEndDate}
              />
            <div className="border-r border-[#CCC]"></div>
            <div className="flex text-sm gap-3">
              <Button
                label="Settings"
                className="h-8"
                onClick={handleSettings}
              />
              <Button
                label="Add Gate"
                className="h-8"
                onClick={handleAddSetting}
              />
            </div>
          </ListHeader>
        </div>

        <div className="p-1 mt-2 w-full">
          <div className="grid grid-cols-4 gap-3">
            {renderBox(boxData, "Visitors Count", dashboardData?.visitor_data, loadingDashboard)}
            {renderBox(boxData1, "Staff Count", dashboardData?.staff_data, loadingDashboard)}
            <div className="grid grid-cols-2 col-span-2 gap-x-3 gap-y-0 w-full">
              {categories?.map((category) => (
                <div
                  className="border-[1px] border-[#CCC] font-bold rounded-lg overflow-hidden bg-[#FDF2F3] h-[53px]"
                >
                  {
                    loadingDashboard ?
                    <Skeleton animation='wave' variant="rounded" sx={{height: '53px', width: '100%',}} />
                    :
                    <div className="px-2.5 flex items-center justify-between h-full">
                      <span className="text-sm">{category.title}</span>
                      <span className="text-[#CF001C] text-[24px] border-l-[1px]  pl-1">
                        {addPadZero(dashboardData?.visitor_data?.[category.key])}
                      </span>
                    </div>
                  }
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="border-b border-[#CCC]"></div>
        <div className="mt-3 border border-[#CCC] rounded-lg overflow-hidden rounded-b-none ">
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            TabIndicatorProps={{
              style: {
                backgroundColor: "#CF001C",
              },
            }}
          >
            {
              tabData?.map((item, index)=>(
                !item?.permissionPath ?
                  <StyledTab key={index} value={item.index} label={item.label} /> 
                  :
                  getPermission(loginData, item?.permissionPath) ? 
                    <StyledTab key={index} value={item.index} label={item.label} /> 
                    : 
                    ''
              ))
            }
          </Tabs>
        </div>

        <div>
          <TabPanel value={tabValue} index={0}>
            <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 rounded-t-none border-t-0 min-h-[500px]">
              <MainVisitorForm getData={getData} />
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 rounded-t-none border-t-0 min-h-[500px]">
              <StaffInOut />
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-1 rounded-t-none border-t-0 min-h-[500px]">
              <VisitorStaffLog filter={filter} setFilter={setFilter}/>
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-1 rounded-t-none border-t-0 min-h-[500px]">
              <FrequentVisitor filter={filter} setFilter={setFilter}/>
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
            <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-1 rounded-t-none border-t-0 min-h-[500px]">
              <ExpectedVisitor filter={filter} setFilter={setFilter} />
            </div>
          </TabPanel>
        </div>
      </div>
      {
        settings &&
        <VisitorSettingModal
          isOpen={settings}
          onClose={() => {
            setSettings(false);
          }}
          onConfirm={() => {
            handleChangeSettings();
          }}
        />
      }
      {
        addGate &&
        <AddGateModal
          isOpen={addGate}
          onClose={() => {
            setAddGate(false);
          }}
        />
      }
    </Layout>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default VisitorDashboard;
