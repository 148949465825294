import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { login } from "../../../redux/actions/AuthActions/LoginAction";
import { HOME_ENDPOINT } from "../../../Routing/routes";
import FullScreenLoader from "../../../components/Loader/FullScreenLoader";
import { useSelector } from "react-redux";

const AutoSignIn = () => {
  const dispatch = useDispatch();
  const { encData } = useParams();
  const navigate = useNavigate();
  const {reloginLoading} = useSelector(state=>state.loginReducer)

  useEffect(() => {
    try {
      const decoded = jwtDecode(encData,);
      const sendRequest = {
        onSuccessCallback: ()=>{
          navigate(HOME_ENDPOINT)
        },
        rememberMe: false,
        email: decoded.email,
        password: decoded.password,
        ipAddress: decoded.ip,   
        deviceType: decoded.device_type,
        os: decoded.os_version,
        version: decoded.app_version,
        iemi: decoded.phone_imei,
        phoneBrand: decoded.phone_brand,
        autoLogin: true
      };
      dispatch(login(sendRequest))

    } catch (error) {
      
      
    }
  }, []);
  return (
    <>
      {
        reloginLoading && <FullScreenLoader/>
      }
    </>
  );
};

export default AutoSignIn;
