
import { notify } from '../../../../utils/notification';
import apiEndpoints from '../../../api';
import axiosInstance from '../../../axiosInstance'
import {logOut} from '../../../../utils/helpers/logOut'
import getBrowserDetails from '../../../../utils/helpers/getBrowserDetails'
import moment from 'moment';
import { errorMsg } from '../../../../consts/responseMessages';

export async function applyForTenancyReq(action) {

    const BODY= {
        "society_id": action.data.societyId,
        "tenant_list": action.data?.tenantDetails?.map(item=>({
          "tenant_name": item.name,
          "contact_no": item.contactNo,
          "email": item.email,
          "attachments": [
            {
              "attachment_name": 'Aadhaar card',
              "attachment": item?.identityProof
            },
            {
              "attachment_name": 'Pan card',
              "attachment": item?.panCard
            },
            {
              "attachment_name": 'No Dues Certificate',
              "attachment": item?.noc
            },
            {
              "attachment_name": 'Form 11',
              "attachment": item?.form11
            },
            {
              "attachment_name": 'Form 29',
              "attachment": item?.form29
            }
          ],
          "relatives": item?.relative?.map(relative=>({
            "member_name": relative?.name,
            "relation": relative?.relationship
          }))
        })),

        "flat_id": action.data?.wingId,
        "wing_id": action.data?.flatId,
        "rent_deed_start_date": action.data?.fromDate,
        "rent_deed_end_date": action.data?.toDate,
        "cargo_date": action.data?.cargoDate,
        "description": action.data?.description,
        "datetime": moment().format('YYYY-MM-DD hh:mm:ss'),
        "term": 'accepted',
        "ip": action.data?.ipAddress,
        "browser_details": getBrowserDetails(),
        "session_id": action.data?.sessionId
      }

    return axiosInstance.post(apiEndpoints.APPLY_FOR_TENANCY_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}