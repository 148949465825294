import { notify } from '../../../../utils/notification';
import apiEndpoints from '../../../api';
import axiosInstance from '../../../axiosInstance'
import {logOut} from '../../../../utils/helpers/logOut'
import getBrowserDetails from '../../../../utils/helpers/getBrowserDetails'
import moment from 'moment';
import { errorMsg } from '../../../../consts/responseMessages';

export async function getTenantsListReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_TENANTS_LIST_API}?society_id=${action.data.societyId}&page_no=${action.data.page}&page_limit=${action.data.pageLimit}${action.data.wingId ? `&wing_id=${action.data.wingId}` : '' }${action.data.flatId ? `&flat_id=${action.data.flatId}` : '' }${action.data.status ? `&status=${action.data.status}` : '' }${action.data.searchText ? `&search_text=${encodeURIComponent(action.data.searchText)}` : '' }&date=${moment().format('YYYY-MM-DD')}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function downloadTenantsExcelSheetReq(action) {

    return axiosInstance.get(`${apiEndpoints.DOWNLOAD_TENANTS_EXCEL_API}?society_id=${action.data?.societyId}&is_sample=${action.data?.isSample ?1 : 0}${action.data.wingId ? `&wing_id=${action.data.wingId}` : '' }${action.data.flatId ? `&flat_id=${action.data.flatId}` : '' }${action.data.status ? `&status=${action.data.status}` : '' }${action.data.searchText ? `&search_text=${encodeURIComponent(action.data.searchText)}` : '' }&date=${moment().format('YYYY-MM-DD')}`, {
        responseType: 'arraybuffer',
    })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function uploadTenantsReq(action) {

    var bodyFormData = new FormData();
    bodyFormData.append('society_id', action.data?.societyId);
    bodyFormData.append('browser_details', getBrowserDetails());
    bodyFormData.append('session_id', action.data?.sessionId);
    bodyFormData.append('ip', action.data?.ipAddress);
    bodyFormData.append('file', action.data.file);

    return axiosInstance.post(apiEndpoints.UPLOAD_TENANTS_API, bodyFormData)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                if(errors.response?.data?.type === 'COLS_DATA_NOT_FOUND')
                    notify('error', "Columns name doesn't match");
                else
                    notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function addTenantReq(action) {

    const BODY= {
        "society_id": action.data?.societyId,
        "flat_id": action.data?.flatId,
        "wing_id": action.data?.wingId,
        "first_name": action?.data?.firstName,
        "last_name": action?.data?.lastName,
        "middle_name": action?.data?.middleName,
        "tenant_country_id": action?.data?.countryId?.toString(),
        "contact_no": action?.data?.mobileNo,
        "alternate_contact_no": action?.data?.secondaryMobileNo,
        "mobile_country_code": action?.data?.mobileCountryCode,
        "email": action?.data?.primaryEmail,
        "address": action?.data?.address,
        "rent_deed_start_date": action?.data?.rentDeedStartDate,
        "rent_deed_end_date": action?.data?.rentDeedEndDate,
        "rent_agreement": action?.data?.rentAgreementFile,
        "uidcard_no": action?.data?.adharCardNo?.toUpperCase(),
        "aadhar_card": action.data?.adharFile,
        "pancard_no": action?.data?.panCardNo?.toUpperCase(),
        "gst_registration_no": action?.data?.gstRegistrationNo?.toUpperCase(),
        "gst_visible": action?.data?.showInBill,
        "ip": action?.data?.ipAddress,
        "browser_details": getBrowserDetails(),
        "session_id": action?.data?.sessionId
      }

    return axiosInstance.post(apiEndpoints.ADD_TENANT_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function deleteTenantReq(action) {
    const BODY= {
        "tenant_id": action.data?.deletetenantId,
        "leaving_date": moment().format('DD-MM-YYYY hh:mm:ss'),
        "flag": "delete",
        "ip": action.data?.ipAddress,
        "browser_details": getBrowserDetails(),
        "session_id": action.data?.sessionId
      }
    return axiosInstance.put(apiEndpoints.DELETE_TENANT_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

export async function deleteTenantsInBulkReq(action) {
    const BODY= {
        "tenant_ids": action.data?.tenantIds
      }

    return axiosInstance.delete(apiEndpoints.DELETE_TENANTS_IN_BULK_API, {data: BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function changeTenantStatusReq(action) {

    const BODY= {
        "status": action.data?.status,
        "tenant_ids": action.data?.tenantId
      }

    return axiosInstance.put(apiEndpoints.CHANGE_TENANT_STATUS_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function changeTenantStatusInBulkReq(action) {

    const BODY= {
        "tenant_ids": action.data?.tenantIds
      }

    return axiosInstance.put(apiEndpoints.CHANGE_TENANT_STATUS_IN_BULK_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function sendEmailToTenantsReq(action) {

    const BODY= {
        "email_subject": action.data?.subject,
        "email_body": action.data?.message,
        "email_ids": action.data?.emailIds
      }

    return axiosInstance.post(apiEndpoints.SEND_EMAIL_TO_TENANTS_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getTenantsDetailsReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_TENANT_DETAILS_API}?tenant_id=${action.data.tenantId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function updateTenantsDetailReq(action) {

    const BODY= {
        "tenant_id": action.data?.tenantId,
        "flat_id": action.data?.flatId,
        "wing_id": action.data?.wingId,
        "first_name": action?.data?.firstName,
        "last_name": action?.data?.lastName,
        "middle_name": action?.data?.middleName,
        "tenant_country_id": action?.data?.countryId?.toString(),
        "contact_no": action?.data?.mobileNo,
        "alternate_contact_no": action?.data?.secondaryMobileNo,
        "mobile_country_code": action?.data?.mobileCountryCode,
        "email": action?.data?.primaryEmail,
        "address": action?.data?.address,
        "rent_deed_start_date": action?.data?.rentDeedStartDate,
        "rent_deed_end_date": action?.data?.rentDeedEndDate,
        "rent_agreement": action?.data?.rentAgreementFile,
        'aadhar_card': action.data?.adharFile,
        "uidcard_no": action?.data?.adharCardNo?.toUpperCase(),
        "pancard_no": action?.data?.panCardNo?.toUpperCase(),
        "gst_registration_no": action?.data?.gstRegistrationNo?.toUpperCase(),
        "gst_visible": action?.data?.showInBill,
        "ip": action?.data?.ipAddress,
        "browser_details": getBrowserDetails(),
        "session_id": action?.data?.sessionId
      }

    return axiosInstance.put(apiEndpoints.UPDATE_TENANT_DETAILS_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getExistingTenantReq(action) {

    return axiosInstance.get(`${apiEndpoints.GET_EXISTING_TENANTS_API}?search_text=${encodeURIComponent(action.data.searchText)}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function addExistingTenantReq(action) {

    const BODY= {
        "society_id": action.data.societyId,
        "flat_id": action.data.flatId,
        "wing_id": action.data.wingId,
        "user_acc_id": action.data.userId,
        "first_name": action.data?.firstName,
        "last_name": action.data?.lastName,
        "middle_name": action.data?.middleName,
        "tenant_country_id": action.data?.countryID,
        "contact_no": action.data?.mobileNo,
        "mobile_country_code": action.data?.mobileCountryCode,
        "email": action.data?.email,
        "rent_deed_start_date": action.data?.rentDeedStartDate,
        "rent_deed_end_date": action.data?.rentDeedEndDate,
        "rent_agreement": action.data?.rentAgreementFile,
        "ip": action.data?.ipAddress,
        "browser_details": getBrowserDetails(),
        "session_id": action.data?.sessionId
      }

    return axiosInstance.post(apiEndpoints.ADD_EXISTING_TENANTS_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getTenantAuditLogsReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_TENANTS_AUDIT_LOGS_API}?page_no=${action.data.page}&page_limit=${action.data.pageLimit}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getArchivedTenantReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_ARCHIVED_TENANTS_API}?society_id=${action.data.societyId}&page_no=${action.data.page}&page_limit=${action.data.pageLimit}${action.data.wingId ? `&wing_id=${action.data.wingId}` : '' }${action.data.flatId ? `&flat_id=${action.data.flatId}` : '' }${action.data.searchText ? `&search_text=${encodeURIComponent(action.data.searchText)}` : '' }`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function archiveTenantReq(action) {
    const BODY= {
        "tenant_id": action.data?.tenantId,
        "leaving_date": action.data.leavingDate,
        "flag": "archive",
        "ip": action.data?.ipAddress,
        "browser_details": getBrowserDetails(),
        "session_id": action.data?.sessionId
      }
    return axiosInstance.put(apiEndpoints.ARCHIVE_TENANT_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

export async function getSocietyManagingCommitteeTypesReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_MANAGING_COMITTEE_TYPES_LIST_API}?society_id=${action.data.societyId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getSocietyEmployeeTypesReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_EMPLOYEE_TYPES_LIST_IN_TENANTS_API}?society_id=${action.data.societyId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getTenancyExpiryReminderDataReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_TENANCY_EXPIRY_REMINDER_DATA_API}?society_id=${action.data.societyId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function updateTenancyExpiryReminderReq(action) {
    const BODY= {
        "reminder_data_id": action.data.reminderId || 0,
        "society_id": action.data.societyId,
        "employee_type_ids": action.data?.employeeIds,
        "committee_type_ids": action.data?.committeeIds,
        "day_prior": action.data.days
      }
    return axiosInstance.post(apiEndpoints.UPDATE_TENANCY_EXPIRY_REMINDER_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

export async function renewTenantDetailReq(action) {
    const BODY=  {
      "tenant_id": action.data?.tenantId,
      "flat_id": action.data?.flatId,
      "wing_id": action.data?.wingId,
      "first_name": action.data?.firstName,
      "last_name": action.data?.lastName,
      "middle_name": action.data?.middleName,
      "tenant_country_id": action.data?.countryId?.toString(),
      "contact_no": action.data?.mobileNo,
      "alternate_contact_no": action.data?.alternateMobileNo,
      "mobile_country_code": action.data?.mobileCountryCode,
      "email": action.data?.email,
      "rent_deed_start_date": action.data?.rentDeedStartDate,
      "rent_deed_end_date": action.data?.rentDeedEndDate,
      "rent_agreement": action.data?.rentAgreementFile,
      "uidcard_no": action.data?.adharCardNo?.toUpperCase(),
      "pancard_no": action.data?.panCardNo?.toUpperCase(),
      "ip": action.data?.ipAddress,
      "browser_details": getBrowserDetails(),
      "session_id": action.data?.sessionId
    }
    return axiosInstance.put(apiEndpoints.RENEW_FORM_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

export async function getRentPaymentOnlineRegistrationListReq(action) {
    return axiosInstance.get(`${apiEndpoints.RENT_PAYMENT_ONLINE_REGISTRATION_LIST_API}?society_id=${action.data.societyId}&page_no=${action.data.page}&page_limit=${action.data.pageLimit}${action.data.wingId ? `&wing_id=${action.data.wingId}` : '' }${action.data.flatId ? `&flat_id=${action.data.flatId}` : '' }${action.data.status ? `&status=${action.data.status}` : '' }${action.data.searchText ? `&search_text=${encodeURIComponent(action.data.searchText)}` : '' }`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getRentPaymentOnlineRegistrationDataReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_RENT_PAYMENT_ONLINE_REGISTRATION_DATA_API}?society_id=${action.data.societyId}&tenant_id=${action.data.tenantId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function updateRentPaymantOnlineRegistrationReq(action) {
    const BODY= {
        "society_id": action.data?.societyId,
        "tenant_id": action.data?.tenantId,
        "registration_id": action.data?.registrationId || 0,
        "individual_or_company": action.data?.type,
        "first_name": action.data?.firstName,
        "last_name": action.data?.lastName,
        "wing_id": action.data?.wingId,
        "flat_id": action.data?.flatId,
        "gender": action.data?.gender,
        "dob": action.data?.dob,
        "mobile": '+' + action.data?.mobileCountryCode + '-' + action.data?.mobileNo,
        "email": action.data?.email,
        "city": (action.data?.city) || 0,
        "pincode": parseInt(action.data?.pinCode),
        "address": action.data?.rentPropertyAddress,
        "rent_agreement_url": action.data?.rentAgreementFile,
        "rent_amount": parseFloat(action.data?.rentAmount),
        "tenant_pan": action.data?.panCardNo?.toUpperCase(),
        "tenant_gstin": action.data?.gstRegistrationNo?.toUpperCase(),
        "frequency_of_rent": action.data?.frequencyOfRent,
        "due_date": parseInt(action.data?.mothlyDueDate),
        "agreement_expiry_date": action.data?.rentAgreementExpiryDate,
        "landlord_name": action.data?.landLordDetails?.firstName + ' ' + action.data?.landLordDetails?.lastName,
        "landlord_pan": action.data?.landLordDetails?.panCardNo?.toUpperCase(),
        "landlord_gstin": action.data?.landLordDetails?.gstRegistrationNo?.toUpperCase(),
        "bank_name": action.data?.landLordDetails?.bankName,
        "account_no": action.data?.landLordDetails?.accountNumber,
        "ifsc_code": action.data?.landLordDetails?.ifscCode?.toUpperCase()
      }
    return axiosInstance.post(apiEndpoints.UPDATE_RENT_PAYMENT_ONLINE_REGISTRATION_DATA_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

export async function deleteRentPaymantOnlineRegistratioReq(action) {
    const BODY= {
        "society_id": action.data.societyId,
        "registration_id": action.data?.registrationId
      }
      return axiosInstance.delete(apiEndpoints.DELETE_RENT_PAYMENT_ONLINE_REGISTRATION_DATA_API, {data: BODY})
      .then(response => {
          return response;
      }).catch((errors) => {
          console.log("errors", errors);
          if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
              logOut()
          }else {
              notify('warning', "Something went wrong");
          }
          return errors

      })
}

export async function getAccessSettingDataReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_ACCESS_SETTING_DATA_API}?society_id=${action.data.societyId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function updateAccessSettingsReq(action) {
    const BODY= {
        "access": action.data?.accessSetting,
        "tenant_access_id": action.data?.tenantAccessId
      }

    return axiosInstance.put(apiEndpoints.UPDATE_ACCESS_SETTING_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

export async function printTenantsListReq(action) {
    return axiosInstance.get(`${apiEndpoints.PRINT_TENANT_LIST_API}?society_id=${action.data.societyId}${action.data.wingId ? `&wing_id=${action.data.wingId}` : '' }${action.data.flatId ? `&flat_id=${action.data.flatId}` : '' }${action.data.status ? `&status=${action.data.status}` : '' }${action.data.searchText ? `&search_text=${encodeURIComponent(action.data.searchText)}` : '' }&date=${moment().format('YYYY-MM-DD')}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}