import React, { useEffect } from "react";
import Layout from "../../../Layout/Layout";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import { ARCHIVE_COMMITTEE_MEMBERS_ENDPOINT, MANAGING_COMMITTEE_ENDPOINT } from "../../../../Routing/routes";
import Search from "../../../../components/SearchBox/Search";
import {DatePickerComponent} from "../../../../components/DatePicker/DatePicker";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import ArchiveCommitteeMemberTable from "./ArchiveCommitteeMemberTable";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getManagingCommitteeArhiveMember } from "../../../../redux/actions/SetupRecordsActions/StaffManagementActions/ManangingCommitteeAction";
import moment from "moment";
import ButtonG from "../../../../components/Button/ButtonG";
import { validateDate } from "../../../../utils/helpers/universalFunctions";

const headCells = [
  { id: 'sNo', label: 'S No.', sort: false, width: '50px'},
  { id: 'committeeName', label: 'Committee Name', sort: true, width: '180px'},
  { id: 'member', label: 'Member Flat & Wing No.', sort: true, width: '200px'},
  { id: 'rolesResponsibility', label: 'Roles & Responsibility', sort: true, width: '180px'},
  { id: 'joinDate', label: 'Join Date', sort: true, width: '100px'},
  { id: 'leaveDate', label: 'Leave Date', sort: true, width: '100px'},
  { id: 'status', label: 'Status', sort: true, width: '90px'},
  {
    id: "action",
    label: "Action",
    sort: false,
  },
];

const ArchiveCommitteeMember = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {loading, archivedMembers} = useSelector(state=> state.managingCommitteeReducer)
  const {loginData} = useSelector(state=>state.loginReducer)

  const handleBackClick = () => {
    navigate(MANAGING_COMMITTEE_ENDPOINT);
  };

  const handleSearch = (text)=>{
    if(text && text !== searchParams.get("search")){
      searchParams.delete("search")
      navigate({
        pathname: `${ARCHIVE_COMMITTEE_MEMBERS_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["search", text?.trim()]])}`,
      })
    }
  }

  const handleJoinDate = (selectedDate)=>{
    if(selectedDate && moment(selectedDate)?.format('YYYY-MM-DD') !== searchParams.get('joinDate')){
      searchParams.delete('joinDate')
      navigate({
        pathname: `${ARCHIVE_COMMITTEE_MEMBERS_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ['joinDate', moment(selectedDate)?.format('YYYY-MM-DD')]])}`,
      })
    }
  }


  const handleLeaveDate = (selectedDate)=>{
    if(selectedDate && moment(selectedDate)?.format('YYYY-MM-DD') !== searchParams.get('leaveDate')){
      searchParams.delete('leaveDate')
      navigate({
        pathname: `${ARCHIVE_COMMITTEE_MEMBERS_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ['leaveDate', moment(selectedDate)?.format('YYYY-MM-DD')]])}`,
      })
    }
  }

  const handleClear = ()=>{
    navigate(ARCHIVE_COMMITTEE_MEMBERS_ENDPOINT)
  }

  const getTableData =() =>{
    const data={
      societyId: loginData?.default_community?.community_id,
      searchText: searchParams.get("search") || '',
      joinDate: searchParams.get("joinDate") || '',
      leaveDate: searchParams.get("leaveDate") || '',
    }

    dispatch(getManagingCommitteeArhiveMember(data))
  }

  useEffect(()=>{      
    getTableData()
  }, [searchParams])


  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <ListHeaderBack
          onClick={handleBackClick}
          title="Archive Committee Member"
        ></ListHeaderBack>
        <div className="border-b border-[#CCCCCC]"></div>
        <div className="py-2 flex justify-between gap-2">
          <Search height="8" placeholder={'Search...'} onclick={handleSearch} value={searchParams.get("search")} />          
          <div className="flex items-center gap-3">
            <div className="flex items-center">
              <span className="mr-4 text-[13px]">Join Date:</span>
              <DatePickerComponent 
                className="w-40 h-8" 
                onDateChange={handleJoinDate} 
                defaultValue={searchParams.get('joinDate') ? new Date(searchParams.get('joinDate')) : ''}
              />
            </div>
            <div className="flex items-center">
              <span className="mr-4 text-[13px]">Leave Date:</span>
              <DatePickerComponent 
                className="w-40 h-8" 
                onDateChange={handleLeaveDate} 
                defaultValue={searchParams.get('leaveDate') ? new Date(searchParams.get('leaveDate')) : ''}
              />
            </div>
            <ButtonG label='Clear' className='h-8' onClick={handleClear} />
          </div>
        </div>

          <ArchiveCommitteeMemberTable
            loading={loading}
            data={archivedMembers?.map((item, index)=>({
              sNo: (index+1)?.toString()?.padStart(2, "0"),
              committeeId: item?.committee_id,
              roleId: item?.role_data_id,
              committeeName: item?.title || '',
              member: item?.subscriber_name  || '-',
              flat: item?.flat_no  || '-',
              rolesResponsibility: item?.roles || '-',
              joinDate: validateDate(item?.join_date) ? moment(new Date(item?.join_date))?.format('DD-MM-YYYY') : '-',
              leaveDate: item?.resign_retire_status === 'RES'?
              (validateDate(item?.resign_date) ? moment(new Date(item?.resign_date))?.format('DD-MM-YYYY') : '-'):
              (validateDate(item?.retire_date) ? moment(new Date(item?.retire_date))?.format('DD-MM-YYYY') : '-'),
              status: item?.resign_retire_status === 'RES' ? 'Resigned' : (item?.resign_retire_status === 'RET' ? 'Retired' : '-'),
            }))}
            columns={headCells}
            checkbox={false}
            pagination={false}
            // totalCount={archivedMembers?.total_record}
            // totalPages={archivedMembers?.total_pages}
            // start={(archivedMembers?.current_page_no - 1) * archivedMembers?.page_limit + 1}
            // end={(archivedMembers?.current_page_no - 1) * archivedMembers?.page_limit + archivedMembers?.length}
            // currentPage={archivedMembers?.current_page_no}
            // rowsPerPage={searchParams.get("pageLimit") || 10}
            getTableData={getTableData}
          ></ArchiveCommitteeMemberTable>
          
          {!archivedMembers?.length && !loading && (
            <div className="text-sm text-[#AAAAAA] my-4 text-center">
              No Records
            </div>
          )}
      </div>
    </Layout>
  );
};

export default ArchiveCommitteeMember;
