export const SocietyProfileData = [
  {
    label: "",
    values: [
      [
        {
          key: "title",
          label: "Society Name",
          value: "Treasure Town Society",
        },
      ],
      [
        {
          key: "short_name",
          label: "Society Name For SMS",
          value: "0",
        },
      ],
      [{ key: "brand_name", label: "Brand Name", value: "-" }],
      [
        { key: "pan_no", label: "Pan Number", value: "-" },
        { key: "tan_no", label: "Tan Number", value: "-" },
      ],
      [{ key: "pay_pin", label: "Pay PIN", value: "TzNcJZ" }],
      [
        {
          key: "sprintly_id",
          label: "Spintly Organisation ID",
          value: "3859",
        },
        {
          key: "sprintly_device",
          label: "Spintly Device ID",
          value: "4611",
        },
      ],
      [
        {
          key: "cheque_pay_to_soc",
          label: "Cheque In The Name of",
          value: "-",
        },
        {
          key: "currency_name",
          label: "Community Currency",
          value: "Rupees",
        },
      ],
    ],
  },

  {
    label: "About",
    values: [
      [
        { key: "product_description", label: "Description", value: "N/A" },
        {
          key: "club_timimg",
          label: "Club Timing",
          value: "N/A",
        },
      ],
    ],
  },

  {
    label: "Basic Information",
    values: [
      [
        { key: "builder_name", label: "Builder Name", value: "N/A" },
        { key: "complex_type", label: "Complex Type", value: "N/A" },
      ],
      [
        {
          key: "construction_status",
          label: "Construction Status",
          value: "N/A",
        },
      ],
      [
        { key: "area", label: "Area", value: "Mumbai" },
        { key: "city_name", label: "City", value: "Mumbai" },
      ],
      [
        { key: "state_name", label: "State", value: "Maharashtra" },
        { key: "pincode", label: "Pin Code", value: "452012" },
      ],
    ],
  },

  {
    label: "Association Information",
    values: [
      [{ key: "association_name", label: "Association Name", value: "N/A" }],
      [
        {
          key: "isAssociationRegistered",
          label: "Is Registered",
          value: "Yes",
        },
        {
          key: "gst_registration_no",
          label: "GST Registration No.",
          value: "N/A",
        },
      ],
      [
        {
          key: "associate_brand_name",
          label: "Brand Name",
          value: "Kalanu Group",
        },
        {
          key: "project_name",
          label: "Project Name",
          value: "Treasure",
        },
      ],
      [
        {
          key: "lar_reg_no",
          label: "Local Authority  Registration No.",
          value: "-",
        },
        {
          key: "pt_number",
          label: "Property Tax Number",
          value: "-",
        },
      ],
    ],
  },
  {
    label: "Contact Information",
    values: [
      [
        { key: "office_address", label: "Address", value: "Mumbai Kurla" },
        {
          key: "website",
          label: "Website",
          value: "N/A",
        }
      ],
      [
        {
          key: "phone",
          label: "Phone",
          value: "9876543210",
        },
        {
          key: "other_details",
          label: "Other Details",
          value: "N/A",
        },
      ],
      [
        {
          key: "email",
          label: "Email",
          value: "Treasuretown@email.com",
        },
        {
          key: "support_email",
          label: "Support Email",
          value: "N/A",
        },
      ],
    ],
  },
];

export const WingsData = {
  columns: [
    { id: "sno", label: "S.No", align: "left" },
    { id: "wingNumber", label: "Wing Number", align: "right" },
    { id: "wingTitle", label: "Wing Title", align: "right" },
    { id: "totalNoOfFloors", label: "Total No. Of Floors", align: "right" },
    { id: "totalNoOfUnits", label: "Total No. Of Units", align: "right" },
  ],

  rows: [
    {
      sno: "01",
      wingNumber: 159,
      wingTitle: 6.0,
      totalNoOfFloors: 24,
      totalNoOfUnits: 4.0,
    },
    {
      sno: "02",
      wingNumber: 237,
      wingTitle: 9.0,
      totalNoOfFloors: 37,
      totalNoOfUnits: 4.3,
    },
    {
      sno: "03",
      wingNumber: 262,
      wingTitle: 16.0,
      totalNoOfFloors: 24,
      totalNoOfUnits: 6.0,
    },
  ],
};

export const SpecificDetailsData = [
  {
    label: "",
    values: [
      [
        {
          key: "regn_no",
          label: "Registration Data",
          value: "-",
        },
      ],
      [
        {
          key: "date_of_regn",
          label: "Date of Registration",
          value: "00-00-0000",
        },
      ],
      [
        {
          key: "built_up_area",
          label: "Total Built Up Area",
          value: "-",
        },
      ],
      [{ key: "area_of_plot", label: "Area of Plot", value: "-" }],
      [
        {
          key: "construction_cost",
          label: "Construction Cost Per Unit",
          value: "Sq. Ft.",
        },
      ],
      [
        {
          key: "no_of_water_inlets",
          label: "Total number of Water Inlets",
          value: "-",
        },
      ],
      [
        {
          key: "value_per_share",
          label: "Value Per Share",
          value: "-",
        },
      ],
    ],
  },
];
