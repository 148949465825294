import { call, put, takeLatest } from 'redux-saga/effects'
import {notify} from '../../../../utils/notification'
import * as types from '../../../actions/index.types'
import * as req from '../../../../axios/request/SetupRecordsReqs/ManageRolesAndRights/ManageRolesAndRightsReq'

function* getEmployeeRolesList(action) {
    try {
        const result = yield call(req.getEmployeeRolesListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_EMPLOYEE_ROLE_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_EMPLOYEE_ROLE_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getEmployeeRolesDetails(action) {
    try {
        const result = yield call(req.getEmployeeRolesDetailsReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_EMPLOYEE_ROLE_DETAILS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_EMPLOYEE_ROLE_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* addAndUpdateEmployeeRole(action) {
    try {
        const result = yield call(req.addAndUpdateEmployeeRoleReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.ADD_AND_UPDATE_EMPLOYEE_ROLE_DETAILS_SUCCESS, payload: action.data});  
            action.data?.onSuccessCallback?.()
        }else{
            yield put({ type: types.ADD_AND_UPDATE_EMPLOYEE_ROLE_DETAILS_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}

function* getOccupantsRolesList(action) {
    try {
        const result = yield call(req.getOccupantsRolesListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_OCCUPANT_ROLE_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_OCCUPANT_ROLE_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getOccupantsRolesDetails(action) {
    try {
        const result = yield call(req.getOccupantsRolesDetailsReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_OCCUPANTS_ROLE_DETAILS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_OCCUPANTS_ROLE_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* addAndUpdateOccupantRole(action) {
    try {
        const result = yield call(req.addAndUpdateOccupantRoleReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.ADD_AND_UPDATE_OCCUPANTS_ROLE_DETAILS_SUCCESS, payload: action.data});  
            action.data?.onSuccessCallback?.()
        }else{
            yield put({ type: types.ADD_AND_UPDATE_OCCUPANTS_ROLE_DETAILS_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}

function* getFamilyRolesList(action) {
    try {
        const result = yield call(req.getFamilyRolesListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_FAMILY_ROLE_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_FAMILY_ROLE_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getFamilyRolesDetails(action) {
    try {
        const result = yield call(req.getFamilyRolesDetailsReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_FAMILY_ROLE_DETAILS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_FAMILY_ROLE_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* addAndUpdateFamilyRoles(action) {
    try {
        const result = yield call(req.addAndUpdateFamilyRolesReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.ADD_AND_UPDATE_FAMILY_ROLE_DETAILS_SUCCESS, payload: action.data});  
            action.data?.onSuccessCallback?.()
        }else{
            yield put({ type: types.ADD_AND_UPDATE_FAMILY_ROLE_DETAILS_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}


function* getRoleAndRightsSetting(action) {
    try {
        const result = yield call(req.getRoleAndRightsSettingReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_ROLE_RIGHT_SETTINGS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_ROLE_RIGHT_SETTINGS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateRoleAndRightsStatus(action) {
    try {
        const result = yield call(req.updateRoleAndRightsStatusReq, action);
        if (result.statusCode === 200) {
            notify('success', 'Status updated successfully')
            yield put({ type: types.UPDATE_ROLE_RIGHT_STATUS_SUCCESS, payload: action.data});  
        }else{
            yield put({ type: types.UPDATE_ROLE_RIGHT_STATUS_FAILURE});  
        }
        action.data?.onRequestEndCallback?.()
    } catch (e) {
        console.log(e)
    }
}

function* deleteEmployeeOccupantAndFamilyRole(action) {
    try {
        const result = yield call(req.deleteEmployeeOccupantAndFamilyRoleReq, action);
        if (result.statusCode === 200) {
            notify('success', 'Data deleted successfully.');
            action.data?.onSuccessCallback()
            yield put({ type: types.DELETE_RIGHTS_AND_ROLES_SUCCESS, payload: action.data});  
        }
    } catch (e) {
        console.log(e)
    }
}

function* getSocietyAccessSetting(action) {
    try {
        const result = yield call(req.getSocietyAccessSettingReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_SOCIETY_ACCESS_SETTINGS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_SOCIETY_ACCESS_SETTINGS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateSocietyAccessSetting(action) {
    try {
        const result = yield call(req.updateSocietyAccessSettingReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.UPDATE_SOCIETY_ACCESS_SETTINGS_SUCCESS, payload: action.data});  
            action.data?.onSuccessCallback?.()
        }else{
            yield put({ type: types.UPDATE_SOCIETY_ACCESS_SETTINGS_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}


export default function* MangeRolesAndRightsaga() {
    yield takeLatest(types.GET_EMPLOYEE_ROLE_LIST, getEmployeeRolesList);
    yield takeLatest(types.GET_EMPLOYEE_ROLE_DETAILS, getEmployeeRolesDetails);
    yield takeLatest(types.ADD_AND_UPDATE_EMPLOYEE_ROLE_DETAILS, addAndUpdateEmployeeRole);
    yield takeLatest(types.GET_OCCUPANT_ROLE_LIST, getOccupantsRolesList);
    yield takeLatest(types.GET_OCCUPANTS_ROLE_DETAILS, getOccupantsRolesDetails);
    yield takeLatest(types.ADD_AND_UPDATE_OCCUPANTS_ROLE_DETAILS, addAndUpdateOccupantRole);
    yield takeLatest(types.GET_FAMILY_ROLE_LIST, getFamilyRolesList);
    yield takeLatest(types.GET_FAMILY_ROLE_DETAILS, getFamilyRolesDetails);
    yield takeLatest(types.ADD_AND_UPDATE_FAMILY_ROLE_DETAILS, addAndUpdateFamilyRoles);
    yield takeLatest(types.GET_ROLE_RIGHT_SETTINGS, getRoleAndRightsSetting);
    yield takeLatest(types.UPDATE_ROLE_RIGHT_STATUS, updateRoleAndRightsStatus);
    yield takeLatest(types.DELETE_RIGHTS_AND_ROLES, deleteEmployeeOccupantAndFamilyRole);
    yield takeLatest(types.GET_SOCIETY_ACCESS_SETTINGS, getSocietyAccessSetting);
    yield takeLatest(types.UPDATE_SOCIETY_ACCESS_SETTINGS, updateSocietyAccessSetting);}