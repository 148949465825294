import { combineReducers } from 'redux'
import { ipAddressReducer } from '../reducers/IPAddressReducers/IPAddressReducer'
import { forgotPasswordReducer } from '../reducers/AuthReducers/forgotPasswordReducer'
import { loginReducer } from '../reducers/AuthReducers/loginReducer'
import { passwordResetReducer } from '../reducers/AuthReducers/passwordResetReducer'
import { logoutReducer } from '../reducers/AuthReducers/logoutReducer'
import {
    societyProfileReducer,
    currencyListReducer,
    projectListReducer,
    countryStateCityReducer,
} from './SetupRecordsReducers/BuildingReducers/societyProfileReducer'
import { specificDetailsReducer } from './SetupRecordsReducers/BuildingReducers/specificDetailsReducer'
import {
    wingsReducer,
    headListReducer
} from './SetupRecordsReducers/BuildingReducers/wingsAndFlatsReducer'
import {
    bankDetailsReducer,
    bankNameReducer
} from './SetupRecordsReducers/BuildingReducers/BankDetailsReducer'
import { occupantInfoReducer } from './SetupRecordsReducers/Occupants&TenantsReducers/OccupantsInfoReducer'
import { tenantInfoReducer } from './SetupRecordsReducers/Occupants&TenantsReducers/TenantsInfoReducer'
import { leaveAndLicenseReducer } from './SetupRecordsReducers/Occupants&TenantsReducers/LeaveAndLicenseReducer'
import { applyForTenancyReducer } from './SetupRecordsReducers/Occupants&TenantsReducers/ApplyForTenancyReducer'
import { viewInvitiesInfoReducer } from './SetupRecordsReducers/Occupants&TenantsReducers/ViewInvitiesReducer'
import { staffPersonnelReducer } from './SetupRecordsReducers/StaffManagementReducers/StaffPersonnelReducer'
import {
    maintenanceStaffReducer,
    agencyReducer
} from './SetupRecordsReducers/StaffManagementReducers/MaintenanceAgencyReducer'
import { employeeManagementReducer } from './SetupRecordsReducers/StaffManagementReducers/EmployeeManagementReducer'
import { managingCommitteeReducer } from './SetupRecordsReducers/StaffManagementReducers/ManagingCommitteeReducer'
import { bulkUploadReducer } from './SetupRecordsReducers/BulkUploadsReducers/BulkUploadsReducer'
import { manageRoleAndRightsReducer } from './SetupRecordsReducers/ManageRolesAndRightsReducers/ManageRolesAndRightsReducer'
import { SocietyFormsAndByeLawsReducer } from './SetupRecordsReducers/SocietyFormsAndByeLawsReducers/SocietyFormsAndByeLawsReducers'
import { importantContactsReducer } from './SocialAndCommunicationReducers/LobbyReducers/ImportantContactsReducer'
import { noticeAndCircularReducer } from './SocialAndCommunicationReducers/NoticeAndCircularReducer'
import { billingReportsReducer } from './FinanceReducers/BillingReportsReducer'
import { myInvoiceReducer } from './FinanceReducers/MyInvoiceReducers'
import { creditDebitTariffHeaderReducer } from './FinanceReducers/SettingReducers/CreditDebitTariffHeaderReducer'
import { invoiceDetailsReducer } from './FinanceReducers/SettingReducers/InvoiceDetailsReducer'
import { logaComplaintReducer } from './DeskReducers/LogaComplaintReducer'
import { documentReducer } from './DeskReducers/DocumentReducer'
import { deskReducers } from './DeskReducers/DeskReducer'
import { complaintCategoryReducer } from './DeskReducers/ComplaintCategoryReducer'
import { visitorAndVehicleInAndOutReducer } from './GateManagementReducers/VisitorAndVehicleInAndOutReducer'
import { parkingManagementReducer } from './GateManagementReducers/ParkingManagementReducers'
import { addFacilityReducer } from './ClubReducers/AddFacilityReducer'
import { viewBookingReducer } from './ClubReducers/ViewBookingsReducer'
import { bookAFacilityReducer } from './ClubReducers/BookAFacilityReducer'
import { addTimeSlotReducer } from './ClubReducers/AddTimeSlotReducer'
import { manageInvoiceReducer } from './FinanceReducers/BillingManagementReducers/ManageInvoiceReducer'
import { manageAdvanceNoteReducer } from './FinanceReducers/BillingManagementReducers/ManageAdvanceNoteReducer'
import { manageCreditNoteReducer } from './FinanceReducers/BillingManagementReducers/ManageCreditNoteReducer'
import { dueReminderReducer } from './FinanceReducers/SettingReducers/DueRemindersReducer'
import { gstAndNOCReducer } from './SetupRecordsReducers/BillingAndInvoiceSetupReducers/GSTAndNOCSettingReducer'
import { invoiceSettingReducer } from './SetupRecordsReducers/BillingAndInvoiceSetupReducers/InvoiceSettingReducer'
import { manageDebitNoteReducer } from './FinanceReducers/BillingManagementReducers/ManageDebitNoteReducer'
import { registersReducer } from './ComplianceReducers/RegistersReducer'
import { meetingsReducer } from './ComplianceReducers/MeetingsReducer'
import { financeReportReducer } from './ReportsReducers/FinanceReportReducer'
import { oldFinancialReportsReducers } from './ReportsReducers/OldFinancialReportsReducers'
import { generateInvoiceReducer } from './FinanceReducers/BillingManagementReducers/GenerateInvoiceReducer'

const rootReducer = combineReducers({
    ipAddressReducer,
    forgotPasswordReducer,
    loginReducer,
    passwordResetReducer,
    logoutReducer,

    societyProfileReducer,
    currencyListReducer,
    projectListReducer,
    countryStateCityReducer,
    specificDetailsReducer,
    wingsReducer,
    headListReducer,
    bankDetailsReducer,
    bankNameReducer,

    occupantInfoReducer,
    tenantInfoReducer,
    leaveAndLicenseReducer,
    applyForTenancyReducer,
    viewInvitiesInfoReducer,

    staffPersonnelReducer,
    maintenanceStaffReducer,
    agencyReducer,
    employeeManagementReducer,
    managingCommitteeReducer,

    gstAndNOCReducer,
    invoiceSettingReducer,

    bulkUploadReducer,

    manageRoleAndRightsReducer,

    SocietyFormsAndByeLawsReducer,

    importantContactsReducer,
    noticeAndCircularReducer,

    myInvoiceReducer,
    manageCreditNoteReducer,
    manageAdvanceNoteReducer,
    billingReportsReducer,
    creditDebitTariffHeaderReducer,
    invoiceDetailsReducer,
    manageInvoiceReducer,
    manageDebitNoteReducer,
    dueReminderReducer,
    generateInvoiceReducer,

    registersReducer,
    meetingsReducer,

    deskReducers,
    logaComplaintReducer,
    documentReducer,
    complaintCategoryReducer,

    viewBookingReducer,
    addFacilityReducer,
    bookAFacilityReducer,
    addTimeSlotReducer,

    visitorAndVehicleInAndOutReducer,
    parkingManagementReducer,
    financeReportReducer,
    oldFinancialReportsReducers,

})

export default rootReducer;