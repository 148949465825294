import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../../Layout/Layout";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import ButtonG from "../../../../components/Button/ButtonG";
import Button from "../../../../components/Button/Button";
import { TextInputFM } from "../../../../components/InputFields/TextInput";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import AddOccupantRolesAccordion from "./AddOccupantRolesAccordion";
import { Form, Formik } from "formik";
import { AddUpdateOccupantRoleValidation } from "../../../../validationSchema/setupRecordsSchema/ManageRolesAndRightsSchema";
import { addAndUpdateOccupantRole, getRoleAndRightsSetting } from "../../../../redux/actions/SetupRecordsActions/ManageRolesAndRightsActions/ManageRolesAndRightsAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import Loader from "../../../../components/Loader/Loader";
import { getAccessSettingData } from "../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/TenantsInfoAction";
import { removeZeroValues } from "../../../../utils/helpers/universalFunctions";

function AddOccupantRoles({ action }) {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {updateLoader, loadingAccessSettings, accessSettingData} = useSelector(state=>state.manageRoleAndRightsReducer)
  const {loading} = useSelector(state=>state.tenantInfoReducer)
  const roleWiseAccessSetting = useSelector(state=>state.tenantInfoReducer?.accessSettingData)
  const [accessSetting, setAccessSetting] = useState(null)
  const [title, setTitle] = useState(null)

  const handleSaveClick = (values, {resetForm}) => {
    const modifiedAccessSetting = removeZeroValues(JSON.parse(JSON.stringify(accessSetting)))

    dispatch(addAndUpdateOccupantRole({
      ...values, 
      societyId: loginData?.default_community?.community_id,
      accessSetting: JSON.stringify(modifiedAccessSetting),
      onSuccessCallback: ()=>{
        resetForm();
        setAccessSetting(accessSettingData)
        setTitle(null)
      }
    }))
  };

  useEffect(()=>{
    dispatch(getRoleAndRightsSetting())
    dispatch(getAccessSettingData({societyId: loginData?.default_community?.community_id}))
  }, [])

  useEffect(()=>{
    if(accessSettingData)
      setAccessSetting(accessSettingData)
  }, [accessSettingData])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          initialValues={{
            role: '',
            submitToAdmin: 0,
          }}
          onSubmit={handleSaveClick}
          validationSchema={AddUpdateOccupantRoleValidation}
          >    
            {
              ({values, errors, setFieldValue})=>{
                return (
                  <Form>
                    <div className="border-b border-[#CCC]">
                      <ListHeaderBack
                        title={"Add Occupant Roles"}
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        <ButtonG
                          label="Cancel"
                          className="h-8"
                          onClick={() => {
                            navigate(-1);
                          }}
                          type={'button'}
                        />
                        <Button
                          label={
                            <span className="w-8 inline-block">
                              {
                                updateLoader ?
                                  <CircularProgress sx={{color: "white"}} size={17}/>
                                  :
                                  'Add'
                                }
                            </span>
                          } 
                          className=" h-8 px-4"
                          type='submit'
                          disabled={loading || updateLoader}
                        />
                      </ListHeaderBack>
                    </div>
                    {
                      (loading && !roleWiseAccessSetting) || (loadingAccessSettings && !accessSettingData)  ?
                      <Loader/>
                      :
                      <div className="p-1 py-3 gap-2 m-1">
                        <div className="w-1/2 text-sm">
                          <TextInputFM
                            label="Role Title"
                            placeholder="Enter Role"
                            className="w-56"
                            maxLength={75}
                            name='role'
                          />
                          <div className="mb-3 flex justify-between items-center">
                            <div className="w-1/2 mr-10">Switch To Admin</div>
                            <div className="w-1/2">
                              <Checkbox
                                text="Yes"
                                className="text-sm"
                                initialchecked={!!values.submitToAdmin}
                                onChange={(e) => {setFieldValue('submitToAdmin', e.target.checked ? 1 : 0)}}
                              />
                            </div>
                          </div>
                          <div className="flex justify-between items-center">
                            <span>Give Access Like</span>
                            <Dropdown2
                              options={roleWiseAccessSetting?.title_data?.map(item=>({
                                label: item?.title,
                                value: item?.id
                              }))}
                              width="224px"
                              height="36px"
                              placeholder="Search Title"
                              className="text-xs"
                              onSelect={(selectedItem) => {
                                setTitle(selectedItem)
                                setAccessSetting(roleWiseAccessSetting?.title_data?.find(item => item.id === selectedItem.value)?.access)
                              }}
                              value={title}
                            />
                          </div>
                        </div>
                        <div className="border-b border-[#CCC] my-4"></div>
                        <div>
                          <AddOccupantRolesAccordion accessSetting={accessSetting} setAccessSetting={setAccessSetting}/>
                        </div>
                      </div>
                    }
                  </Form>
                )}
            }
          </Formik>
      </div>
    </Layout>
  );
}

export default AddOccupantRoles;
