import React, { useEffect } from "react";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Layout from "../../../Layout/Layout";
import Button from "../../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { ADD_EMPLOYEE_ROLES_ENDPOINT } from "../../../../Routing/routes";
import ManageEmployeeRolesTable from "../../../../components/TableComponent/ManageEmployeeRolesTable";
import { useDispatch } from "react-redux";
import { getEmployeeRolesList } from "../../../../redux/actions/SetupRecordsActions/ManageRolesAndRightsActions/ManageRolesAndRightsAction";
import { useSelector } from "react-redux";
import * as permissions from '../../../../Routing/permissions'
import { getPermission } from "../../../../utils/helpers/getPermissions";

export const headCells = [
  { id: "id", label: "S No.", width: "120px" },
  { id: "role", label: "Role", sort: true, width: "400px" },
  { id: "status", label: "Status", sort: true, width: "300px" },
  { id: "action", label: "Action", width: "100px" },
];


const ManageEmployeeRoles = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading, employeeRoleList} = useSelector(state=>state.manageRoleAndRightsReducer)

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  useEffect(()=>{
    dispatch(getEmployeeRolesList({societyId: loginData?.default_community?.community_id}))
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
       
          <ListHeader leftContent="Manage Employee Role">
            <div className="border-r border-[#CCC]"></div>
              {
                getPermission(loginData, [permissions.ADD_EMPLOYEE_ROLE_PERMISSION]) &&
                <Button
                  label="Add Employee Role"
                  onClick={handleNavigation(ADD_EMPLOYEE_ROLES_ENDPOINT)}
                />
              }
            
          </ListHeader>
        <div className="ml-1">
          <ManageEmployeeRolesTable
            loading={loading}
            columns={headCells}
            checkbox={true}
            pagination={false}
            data={employeeRoleList?.map((item, index)=>({
              sNo: (index + 1)?.toString()?.padStart(2, "0"),
              role: item?.title || '-',
              status:item?.status === 'A' ? 'Active' : 'Inactive',
              employeeRoleId: item?.emp_role_id
            }))}
          ></ManageEmployeeRolesTable>
        </div>
        {!employeeRoleList?.length && !loading && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ManageEmployeeRoles;
