import { notify } from '../../../utils/notification';
import apiEndpoints from '../../api';
import axiosInstance from '../../axiosInstance'
import {logOut} from '../../../utils/helpers/logOut'
import moment from 'moment';
import { errorMsg } from '../../../consts/responseMessages';

export async function getNoticeListReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_NOTICE_LIST_API}?society_id=${action.data.societyId}&page_no=${action.data.page}&page_limit=${action.data.pageLimit}${action.data.type ? `&type=${action.data.type}` : '' }${action.data.startDate ? `&from_date=${action.data.startDate}` : '' }${action.data.endDate ? `&to_date=${action.data.endDate}` : '' }${action.data.meetingType ? `&meeting_type=${encodeURIComponent(action.data.meetingType)}` : '' }`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getSavedNoticeDetailsReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_SAVED_NOTICE_DETAILS_API}?&status=${action.data.status}&m_notice_id=${action.data.noticeId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function updateSavedNoticeReq(action) {

    const BODY= {
        "m_notice_id": 0,
        "meeting_type": "string",
        "notice_on": "string",
        "notice_time": "string",
        "notice_day": "string",
        "notice_venue": "string",
        "notice_name": "string",
        "notice_desc": "string",
        "telephone": "string",
        "notice": "string",
        "agenda": "string",
        "note2": "string",
        "remind_date": "string",
        "remind_time": "string",
        "members": "string",
        "status": "string",
        "member_emails": "string",
        "member_names": "string",
        "attachments": [
          {
            "notice_doc_id": 0,
            "file_name": "string",
            "attachment": "string",
            "status": "string"
          }
        ],
        "ip": "string",
        "browser_details": "string",
        "session_id": "string"
      }

    return axiosInstance.post(apiEndpoints.UPDATE_SAVED_NOTICE_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function deleteSavedNoticReq(action) {
    const BODY= {
        "m_notice_id": 0,
        "browser_details": "string",
        "session_id": "string",
        "ip": "string"
      }

    return axiosInstance.put(apiEndpoints.DELETE_SAVED_NOTICE_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}