import React, { useEffect,useState } from "react";
import Layout from "../../../Layout/Layout";
import RangeDatePicker from "../../../../components/DatePicker/RangeDatePicker";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import Search from "../../../../components/SearchBox/Search";
import Button from "../../../../components/Button/Button";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import ManageInvoiceDraftTable from "../../../../components/TableComponent/ManageInvoiceDraftTable";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  GENERATE_INVOICE_ENDPOINT,
  MANAGE_INVOICE_CURRENT_ENDPOINT,
  MANAGE_INVOICE_DRAFTS_ENDPOINT,
  MANAGE_INVOICE_PREVIOUS_ENDPOINT,
} from "../../../../Routing/routes";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { downloadInvoiceDraftExcel, getManageInvoiceDraft } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageInvoiceActions";
import moment from "moment";
import {
  getFlatDetails,
  getWingsAndFlatDetails,
} from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import {
  getInvoiceTypeList,
} from "../../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";
import ButtonIco from "../../../../components/Button/ButtonPrint";
import { DownloadExt } from "../../../../assets";
import ButtonG from "../../../../components/Button/ButtonG";
import { downloadExcel } from "../../../../utils/helpers/downloadMedia";

export const headingsInvoice = [
  { id: "sNo", label: "SNo.", sort: false, width: "40px" },
  { id: "memberName", label: "Member Name", sort: true, width: "120px" },
  {
    id: "flatNo",
    label: "Flat No.",
    sort: true,
    width: "140px",
  },
  { id: "invoiceDate", label: "Invoice Date", sort: true, width: "110px" },
  {
    id: "savedOn",
    label: "Saved On",
    sort: true,
    width: "100px",
  },
  { id: "amount", label: "Amount(Rs.)", sort: true, width: "180px" },
  { id: "status", label: "Status", sort: true, width: "100px" },
  {
    id: "action",
    label: "Action",
    sort: false,
  },
];

const ManageInvoiceDraft = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [invoiceTypeList, setInvoiceTypeList] = useState([]);
  const { loginData } = useSelector((state) => state.loginReducer);
  const { manageInvoiceDraft, loading } = useSelector(
    (state) => state.manageInvoiceReducer
  );
  const { typeLists } = useSelector(
    (state) => state.invoiceSettingReducer
  );
  const { wingsData, flatsData } = useSelector((state) => state.wingsReducer);

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleSearch = (text) => {
    if (text && text !== searchParams.get("search")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("search");
      navigate({
        pathname: `${MANAGE_INVOICE_DRAFTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["search", text],
        ])}`,
      });
    }
  };
  const handelSelectInvoiceType = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("typeid");
    navigate({
      pathname: `${MANAGE_INVOICE_DRAFTS_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["typeid", selectedItem.value],
      ])}`,
    });
  };
  const handleSelectWing = (selectedItem) => {
    if (selectedItem.value?.toString() !== searchParams.get("wingId")) {
      dispatch(
        getFlatDetails({
          societyId: loginData?.default_community?.community_id,
          wingId: selectedItem?.value,
        })
      );
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("wingId");
      searchParams.delete("flatId");
      navigate({
        pathname: `${MANAGE_INVOICE_DRAFTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["wingId", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleSelectFlat = (selectedItem) => {
    if (selectedItem.value?.toString() !== searchParams.get("flatId")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("flatId");
      navigate({
        pathname: `${MANAGE_INVOICE_DRAFTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["flatId", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleStartDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !==
      searchParams.get("startDate")
    ) {
      searchParams.delete("startDate");
      navigate({
        pathname: `${MANAGE_INVOICE_DRAFTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["startDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleEndDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !== searchParams.get("endDate")
    ) {
      searchParams.delete("endDate");
      navigate({
        pathname: `${MANAGE_INVOICE_DRAFTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["endDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleDownloadSampleAndFormatExcel = (flag) => {
    const onSucessCallback = (data) => {
      downloadExcel({ data, fileName: `SocietyInvoiceDraft ${flag}.xlsx` });
    };
    const data = {
      flag,
      societyId:loginData?.default_community?.community_id,
      onSucessCallback,
    };
    dispatch(downloadInvoiceDraftExcel(data));
  };

  const handleClear = () => {
    navigate(MANAGE_INVOICE_DRAFTS_ENDPOINT);
  };

  useEffect(() => {
    let societyId = loginData?.default_community?.community_id;
    dispatch(getWingsAndFlatDetails({ societyId }));
    dispatch(getInvoiceTypeList({
      societyId: loginData?.default_community?.community_id,
    }))
    if (searchParams.get("wingId")) {
      dispatch(
        getFlatDetails({ societyId, wingId: searchParams.get("wingId") })
      );
    }
  }, []);
  useEffect(() => {
    if (typeLists) {
      setInvoiceTypeList(typeLists)
    }
  }, [typeLists])
  const reload = () => {
    dispatch(
      getManageInvoiceDraft({
        societyId: loginData?.default_community?.community_id,
        page: searchParams.get("page") || 1,
        pageLimit: searchParams.get("pageLimit") || 10,
        invoiceno: searchParams.get("invoiceno") || "",
        membername : searchParams.get("search") || "",
        from_date: searchParams.get("startDate") || "",
        to_date: searchParams.get("endDate") || "",
        wingId: searchParams.get("wingId") || "",
        flatId: searchParams.get("flatId") || "",
        InvoiceType: searchParams.get("typeid") || 1,
      })
    );
  }
  useEffect(() => {
    dispatch(
      getManageInvoiceDraft({
        societyId: loginData?.default_community?.community_id,
        page: searchParams.get("page") || 1,
        pageLimit: searchParams.get("pageLimit") || 10,
        invoiceno: searchParams.get("invoiceno") || "",
        membername : searchParams.get("search") || "",
        from_date: searchParams.get("startDate") || "",
        to_date: searchParams.get("endDate") || "",
        wingId: searchParams.get("wingId") || "",
        flatId: searchParams.get("flatId") || "",
        InvoiceType: searchParams.get("typeid") || 1,
      })
    );
  }, [searchParams]);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Invoice - Draft">
            <div className="flex gap-3 text-sm">
              <ButtonIco icon={DownloadExt} children="Download Excel" onClick={() => {
                  handleDownloadSampleAndFormatExcel("sample");
                }} />
              <div className="border-r border-[#CCC]"></div>
              <Button
                label="Current"
                className="h-8"
                onClick={handleNavigation(MANAGE_INVOICE_CURRENT_ENDPOINT)}
              />
              <Button
                label="Previous"
                className="h-8 px-4"
                onClick={handleNavigation(MANAGE_INVOICE_PREVIOUS_ENDPOINT)}
              />
              <div className="border-r border-[#CCC]"></div>
              <Button
                label="Generate Invoice"
                onClick={handleNavigation(GENERATE_INVOICE_ENDPOINT)}
              />
            </div>
          </ListHeader>
        </div>

        <div className="flex flex-row justify-between gap-4 p-1 my-1">
          <Search
            height="8"
            onclick={handleSearch}
            value={searchParams.get("search")}
            placeholder="Search..."
          />
          <div className="flex items-center gap-2">
            {/* <div>Filter</div> */}
          <Dropdown2
            options={
              invoiceTypeList
                ? invoiceTypeList?.map(
                  (item) => ({
                    label: item?.invoice_title,
                    value: item?.id,
                  })
                )
                : []
            }
            placeholder="Invoice type"
            className="text-[11px] ms-4"
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              handelSelectInvoiceType(selectedItem);
            }}
            value={
              searchParams.get("typeid") && {
                value: searchParams.get("typeid"),
                label: invoiceTypeList ? invoiceTypeList?.find((item) => item?.id === parseInt(searchParams.get("typeid")))?.invoice_title : "",
              }
            }
          />
            <Dropdown2
              options={
                wingsData?.map((item) => ({
                  value: item?.wing_id,
                  label: item?.number,
                })) || []
              }
              placeholder="Select Wing"
              className="text-[11px] ms-4"
              width="172px"
              height="32px"
              onSelect={(selectedItem) => {
                handleSelectWing(selectedItem);
              }}
              value={
                searchParams.get("wingId") && {
                  value: searchParams.get("wingId"),
                  label: wingsData?.find(
                    (item) =>
                      item.wing_id?.toString() === searchParams.get("wingId")
                  )?.number,
                }
              }
            />
            <Dropdown2
              options={
                (searchParams.get("wingId") &&
                  flatsData?.manage_flat_details?.map((item) => ({
                    value: item?.flat_id,
                    label: item?.flat_no,
                  }))) ||
                []
              }
              placeholder="Select Flat"
              className="text-[11px] ms-4"
              width="172px"
              height="32px"
              onSelect={(selectedItem) => {
                handleSelectFlat(selectedItem);
              }}
              value={
                searchParams.get("flatId") && {
                  value: searchParams.get("flatId"),
                  label: flatsData?.manage_flat_details?.find(
                    (item) =>
                      item.flat_id?.toString() === searchParams.get("flatId")
                  )?.flat_no,
                }
              }
            />
            <RangeDatePicker
              className={"w-[190px] h-8"}
              defaultStartValue={
                searchParams.get("startDate")
                  ? new Date(searchParams.get("startDate"))
                  : ""
              }
              defaultEndValue={
                searchParams.get("endDate")
                  ? new Date(searchParams.get("endDate"))
                  : ""
              }
              onStartDateChange={handleStartDate}
              onEndDateChange={handleEndDate}
            />
          </div>
          <div className="flex gap-3" >
            <div className="border-r border-[#CCC]"></div>
            <ButtonG label="Clear" className="h-8" onClick={handleClear} />
          </div>
        </div>
        <div>
          <ManageInvoiceDraftTable
            loading={loading}
            data={manageInvoiceDraft?.data?.invoice_list?.map(
              (item, index) => ({
                sNo:
                  manageInvoiceDraft?.data?.paginate?.current *
                    manageInvoiceDraft?.data?.paginate?.limit +
                  1 +
                  index,
                id: item.id,
                memberName: item?.member?.subscriber_name,
                flatNo: item.flat?.flat_no,
                wingTitle: item.flat?.wing?.number,
                invoiceDate: item.invoice_date
                  ? moment(new Date(item?.invoice_date))?.format("DD-MM-YYYY")
                  : "-",
                savedOn: item.date
                  ? moment(new Date(item?.date))?.format("DD-MM-YYYY")
                  : "-",
                amount: item.grandtotal,
                amountPaid: item.amount_paid,
                amountDue: item.dueAmount,
                status: item.generationStatus,
              })
            )}
            reload={reload}
            columns={headingsInvoice}
            checkbox={true}
            pagination={manageInvoiceDraft?.data?.invoice_list?.length}
            totalCount={manageInvoiceDraft?.data?.paginate?.total_count}
            totalPages={manageInvoiceDraft?.data?.paginate?.total_page}
            start={
              manageInvoiceDraft?.data?.paginate?.current *
                manageInvoiceDraft?.data?.paginate?.limit +
              1
            }
            end={
              manageInvoiceDraft?.data?.paginate?.current *
                manageInvoiceDraft?.data?.paginate?.limit +
              manageInvoiceDraft?.data?.invoice_list?.length
            }
            currentPage={parseInt(manageInvoiceDraft?.paginate?.current) + 1}
            rowsPerPage={searchParams.get("pageLimit") || 10}
          ></ManageInvoiceDraftTable>

          {!manageInvoiceDraft?.data?.invoice_list?.length && (
            <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
              No Records
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ManageInvoiceDraft;
