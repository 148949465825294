import React from 'react'
import Checkbox from '../../../../components/Checkbox/Checkbox';
import { TextInput } from '../../../../components/InputFields/TextInput';
import {DatePickerComponent} from '../../../../components/DatePicker/DatePicker';
import TextRadio from '../../../../components/InputFields/TextRadio';
import { TextArea } from '../../../../components/InputFields/TextArea';
import moment from 'moment';

const headCells = [
    { id: "type", label: "Type", sort: false, width: "110px" },
    { id: "memberName", label: "Member Name", sort: false, width: "200px" },
    { id: "creditNoteDate", label: "Credit Note Date", sort: false, width: "160px"  },
    { id: "hardCopy", label: "Hard Copy Voucher", sort: false, width: "160px"  },
    { id: "referenceInvoice", label: "Reference Invoice No.", sort: false, width: "160px"  },
    { id: "gstApplicable", label: "GST Applicable", sort: false, width: "120px"  },
  ];

const serviceOptions = [
{ name: "service", value: "Y", label: "Service" },
{ name: "service", value: "N", label: "Goods" },
];


function ExcelDataTable({uploadingExcelData, setUploadingExcelData}) {

  return (
    <div>
        <table>
      <thead className="text-[#AAAAAA] font-semibold" >
        <tr>
            <th className={`border-y p-2 text-[13px] border-[#DDD] text-left w-[35px]`}>
                <Checkbox 
                initialchecked={!uploadingExcelData?.find(item=> !item.selected)}
                onChange={(e)=>{
                    setUploadingExcelData(prev=>{
                        let newData= [...prev]
                        newData.forEach(item=>{
                            item.selected=e.target.checked;
                        })
                        return newData
                    })
                }}
                />
            </th>
            {
                headCells?.map((item)=>(
                <th className={`border-y py-2 text-[13px] border-[#DDD] text-left w-[${item.width}]`} >{item.label}</th>
                ))
            }
        </tr>
      </thead>
      <tbody>
        {
            uploadingExcelData?.map((item, index)=>(
                item.error ?
                <tr>
                    <td className='py-3 px-2'>
                        <Checkbox
                            initialchecked={false} 
                            disabled={true}
                        />
                    </td>
                    <td colSpan={6} className="py-4  text-red-650 cursor-pointer text-wrap break-all text-xs font-semibold text-justify">
                        {item.massage}
                    </td>
                </tr>
                :
                <>
                    <tr className='align-top bg-[#F4F5F8]'>
                        <td className='py-3 px-2'>
                            <Checkbox
                                initialchecked={item.selected} 
                                onChange={(e)=>{
                                    setUploadingExcelData(prev=>{
                                        let newData = [...prev]
                                        newData[index].selected = e.target.checked;
                                        return newData;
                                    })
                                }}
                            />
                        </td>
                        <td className='py-3'>
                            <TextInput
                                className='w-24'
                                value={item.type}
                                onChange={(e)=>{
                                    setUploadingExcelData(prev=>{
                                        let newData = [...prev]
                                        newData[index].type = e.target.value;
                                        return newData;
                                    })
                                }}
                                disabled={true}
                            />
                        </td>
                        <td className='py-3 text-[13px]'>
                            {item.member_glacc}
                        </td>
                        <td className='py-3 text-[13px]'>
                            <DatePickerComponent
                                defaultValue={item.date ? new Date(item.date) : null}
                                className='w-36 h-9'
                                onDateChange={(selectedDate)=>{
                                    setUploadingExcelData(prev=>{
                                        let newData = [...prev]
                                        newData[index].date = moment(selectedDate).format('YYYY-MM-DD');
                                        return newData;
                                    })
                                }}
                            />
                        </td>
                        <td className='py-3'>
                            <TextInput
                                className='w-32'
                                value={item.voucher_no}
                                onChange={(e)=>{
                                    setUploadingExcelData(prev=>{
                                        let newData = [...prev]
                                        newData[index].voucher_no = e.target.value;
                                        return newData;
                                    })
                                }}
                            />
                        </td>
                        <td className='py-3'>
                        </td>
                        <td className='py-3 text-[13px]'>
                            {item.gst_applicable==='Y' ? 'Yes' : 'No'}
                        </td>

                    </tr>
                    <tr className='align-top'>
                        <td className='py-3 px-2'>
                        </td>
                        <td className='py-3'>
                            <div className="text-[13px] text-[#AAAAAA] font-semibold mb-3">Cause</div>
                            <TextInput
                                className='w-24'
                                value={item.cause}
                                onChange={(e)=>{
                                    setUploadingExcelData(prev=>{
                                        let newData = [...prev]
                                        newData[index].cause = e.target.value;
                                        return newData;
                                    })
                                }}
                            />
                            <div className="text-[13px] text-[#AAAAAA] font-semibold mb-3 mt-1 h-[37px]">SGST Rate</div>
                            <div className="text-[13px] text-[#AAAAAA] font-semibold mb-3 mt-1 h-[37px]">CGST Rate</div>
                            <div className="text-[13px] text-[#AAAAAA] font-semibold mb-3 mt-1 h-[37px]">Grand Total</div>
                            <div className="text-[13px] text-[#AAAAAA] font-semibold mb-3 mt-1 h-[37px]">Remark</div>
                        </td>
                        <td className='py-3' colSpan={2}>
                            <div className="text-[13px] text-[#AAAAAA] font-semibold mb-3">Debit A/C</div>
                            <TextInput
                                className='w-[345px]'
                                value={item.creditDetails}
                                onChange={(e)=>{
                                    setUploadingExcelData(prev=>{
                                        let newData = [...prev]
                                        newData[index].creditDetails = e.target.value;
                                        return newData;
                                    })
                                }}
                            />
                            <TextArea
                                className='w-[345px] mt-[145px]'
                                row={3}
                                type='number'
                                value={item.remark || ''}
                                    onChange={(e)=>{
                                        setUploadingExcelData(prev=>{
                                            let newData = [...prev]
                                            newData[index].remark = e.target.value;
                                            return newData;
                                        })
                                    }}
                            />
                        </td>
                        <td className='py-3 text-[13px] '>
                            <div className="text-[13px] text-[#AAAAAA] font-semibold mb-3">HSN Code</div>

                            <TextInput
                                value={item.hsn_code || ''}
                                onChange={(e)=>{
                                    setUploadingExcelData(prev=>{
                                        let newData = [...prev]
                                        newData[index].hsn_code = e.target.value;
                                        return newData;
                                    })
                                }}
                                className='w-32'
                            />
                        </td>
                        <td className='py-3 text-[13px] '>
                            <div className="text-[13px] text-[#AAAAAA] font-semibold mb-3">Service/Good</div>
                            <TextRadio
                                label={''}
                                name={`service${index}`}
                                defaultSelected={item.service}
                                options={serviceOptions}
                                className={'my-1.5 text-[12px]'}
                                onChange={(value)=>{
                                    setUploadingExcelData(prev=>{
                                        let newData = [...prev]
                                        newData[index].service = value;
                                        return newData;
                                    })
                                }}
                            />
                        </td>
                        <td className='py-3 text-[13px] '>
                            <div className="text-[13px] text-[#AAAAAA] font-semibold mb-3">Amount (Rs.)</div>

                            <TextInput
                                className='w-32'
                                type='number'
                                value={item.amount || ''}
                                onChange={(e)=>{
                                    setUploadingExcelData(prev=>{
                                        let newData = [...prev]
                                        newData[index].amount = e.target.value;
                                        newData[index].grand_total = item.gst_applicable==='Y' ? (parseFloat(newData[index].amount) * (parseFloat(item.cgstrate) * parseFloat(item.sgstrate) + 100))/100  : parseFloat(e.target.value)?.toFixed(2);
                                        return newData;
                                    })
                                }}
                            />
                            {
                                item.gst_applicable==='Y' &&
                                <>
                                    <TextInput
                                        disabled={true}
                                        className='w-32'
                                        type='number'
                                        value={item.cgstrate || '0.00'}
                                            onChange={(e)=>{
                                                setUploadingExcelData(prev=>{
                                                    let newData = [...prev]
                                                    newData[index].cgstrate = e.target.value;
                                                    return newData;
                                                })
                                            }}
                                    />
                                    <TextInput
                                        disabled={true}
                                        className='w-32'
                                        type='number'
                                        value={item.sgstrate || '0.00'}
                                            onChange={(e)=>{
                                                setUploadingExcelData(prev=>{
                                                    let newData = [...prev]
                                                    newData[index].sgstrate = e.target.value;
                                                    return newData;
                                                })
                                            }}
                                    />
                                </>
                            }
                            <TextInput
                                className='w-32'
                                type='number'
                                value={item.grand_total || ''}
                                disabled={true}
                            />
                        </td>
            
                    </tr>
                </>
            ))
        }

      </tbody>
    </table>
    </div>
  )
}

export default ExcelDataTable