import * as types from '../../index.types';

export function getDueReminderDetails(data) {
    return {
        type: types.GET_DUE_REMINDER_DETAILS,
        data
    };
}

export function updateDueReminderDetails(data) {
    return {
        type: types.UPDATE_DUE_REMINDER_DETAILS,
        data
    };
}

export function deleteDueReminderDetails(data) {
    return {
        type: types.DELETE_DUE_REMINDER,
        data
    };
}

export function getDebitNoteDueReminderDetails(data) {
    return {
        type: types.GET_DEBIT_NOTE_DUE_REMINDER_DETAILS,
        data
    };
}

export function updateDebitNoteDueReminderDetails(data) {
    return {
        type: types.UPDATE_DEBIT_NOTE_DUE_REMINDER_DETAILS,
        data
    };
}

export function getFDMaturityDueReminderDetails(data) {
    return {
        type: types.GET_FD_MATURITY_DUE_REMINDER_DETAILS,
        data
    };
}

export function updateFDMaturityDueReminderDetails(data) {
    return {
        type: types.UPDATE_FD_MATURITY_DUE_REMINDER_DETAILS,
        data
    };
}

export function getMemberDueReminderDetails(data) {
    return {
        type: types.GET_MEMBER_DUE_REMINDER_DETAILS,
        data
    };
}

export function updateMemberDueReminderDetails(data) {
    return {
        type: types.UPDATE_MEMBER_DUE_REMINDER_DETAILS,
        data
    };
}

export function getNomineeDueReminderDetails(data) {
    return {
        type: types.GET_NOMINEE_DUE_REMINDER_DETAILS,
        data
    };
}

export function updateNomineeDueReminderDetails(data) {
    return {
        type: types.UPDATE_NOMINEE_DUE_REMINDER_DETAILS,
        data
    };
}

export function getMemberListAccordingToRole(data) {
    return {
        type: types.GET_MEMBER_LIST_ACCORDING_TO_ROLE,
        data
    };
}
