import React, { useState } from "react";
import PropTypes from "prop-types";
import "../../../components/TableComponent/Table.css";
import { DeleteIcon } from "../../../assets";
import { DropdownFM2 } from "../../../components/Dropdown/dropdown2";
import { BookingFrequencyTypeOptions } from "../../../consts/DropDownOptions";
import { TextInputFM } from "../../../components/InputFields/TextInput";
import { FieldArray } from "formik";
import { useSelector } from "react-redux";
import DeleteModal from "../../../components/Modal/DeleteModal";
import { deleteRateChart } from "../../../redux/actions/ClubActions/BookAFacilityAction";
import { useDispatch } from "react-redux";
import { getPermission } from "../../../utils/helpers/getPermissions";
import * as permissions from '../../../Routing/permissions'


const EditRateChartTable = ({
  values, 
  setFieldValue,
  columns,

}) => {
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {updateLoader} = useSelector(state=>state.bookAFacilityReducer)
  const  timeSlotList = useSelector(state=>state.bookAFacilityReducer.timeSlotList)
  const [deleteModal, setDeleteModal] = useState({id: '', open: false});
  
  const handleDelete = (id, arrayHelpers, index) => {
    if(id){
      dispatch(deleteRateChart({
        ...values,
        rateChartId: id,
        onSuccessCallback: ()=>{arrayHelpers.remove(index)}
      }))
    }else{
      arrayHelpers.remove(index)
    }
  }
  
  

  return (

    <div className="table-container w-full">
      <table className="table-auto w-full b">
        <thead>
          <tr className="text-[13px] text-[#AAAAAA] text-left">
            {
              columns?.map((item, index)=>(
                <td className={`w-[${item.width}] font-semibold px-3`}>{item.label}</td>
              ))}
              <td width={'60px'}></td>
          </tr>
        </thead>
        <tbody>
          <tr className="text-xs">&nbsp;</tr>
          {<FieldArray
            name="rateChart"
            render={arrayHelpers => (
              <>
                {values.rateChart && values.rateChart.length > 0 && (
                  values.rateChart.map((row, index) => {
                  return (
                    <tr key={index} className="align-top" >
                      <td className='px-2'>
                        <div>
                        <DropdownFM2
                            options={BookingFrequencyTypeOptions}
                            placeholder="Select Frequency"
                            className=""
                            width="240px"
                            height="37.6px"
                            onSelect={(selectedItem) => {
                              setFieldValue(`rateChart.${index}.frequency`, selectedItem.value)
                            }}
                            name={`rateChart.${index}.frequency`}
                            value={
                              values.rateChart[index]?.frequency ?
                              {
                                label: BookingFrequencyTypeOptions?.find(item=> item.value === values.rateChart[index]?.frequency)?.label,
                                value: values.rateChart[index]?.frequency
                              }
                              : null
                            }

                          />
                        </div>
                      </td>
                      <td className='px-2'>
                        <div>
                          <DropdownFM2
                            options={timeSlotList?.slots?.map((item=>({
                              label: item.slot,
                              value: item.id
                            })))}
                            placeholder="Select Slot"
                            className=""
                            width="240px"
                            height="37.6px"
                            onSelect={(selectedItem) => {
                              setFieldValue(`rateChart.${index}.timeSlotId`, selectedItem.value)
                            }}
                            name={`rateChart.${index}.timeSlotId`}
                            value={
                              values.rateChart[index]?.timeSlotId ?
                              {
                                label: timeSlotList?.slots?.find(item=> item.id === parseInt(values.rateChart[index]?.timeSlotId))?.slot,
                                value: values.rateChart[index]?.timeSlotId
                              }
                              : null
                            }
                         />
                        </div>
                      </td>
                      <td className='px-2'>
                        <TextInputFM
                          label=""
                          className="w-44"
                          placeholder="Enter"
                          type='number'
                          maxLength={15}
                          name={`rateChart.${index}.chargeForMember`}
                        />
                      </td>
                      <td className='px-2'>
                        <TextInputFM
                          label=""
                          className="w-44"
                          placeholder="Enter"
                          type='number'
                          maxLength={15}
                          name={`rateChart.${index}.chargeForNonMember`}
                        />
                      </td>
                      <td >
                        <button
                          type="button"

                        >
                          {
                            getPermission(loginData, [permissions.DELETE_RATE_CHART_PERMISSION]) &&
                            <DeleteIcon
                            onClick={row.id ? ()=> setDeleteModal({id: row.id, open: true}) : ()=>{handleDelete(row.id, arrayHelpers, index)}} 
                            className="fill-red-650 mt-3 ml-2 cursor-pointer"
                            />
                          }
                        </button>
                        <DeleteModal
                          title='Delete Rate Chart'
                          loading={updateLoader}
                          isOpen={deleteModal.open && row.id === deleteModal.id}
                          onCancel={() => setDeleteModal(false)}
                          onDelete={()=>{handleDelete(row.id, arrayHelpers, index)}}
                          text='Do you really want to delete this rate chart?'

                        />
                      </td>
                    </tr>
                  );
                })
                )}
                <tr>
                <td
                  colSpan={columns?.length + 1}
                >
                  <div className="h-8 my-2 flex justify-center">
                    <button
                      type="button"
                      label="Add More"
                      onClick={() =>
                        arrayHelpers.push({
                          frequency: '',
                          timeSlotId: '',
                          chargeForNonMember: '',
                          chargeForMember: '',
                        })
                      }
                      className="text-red-650 font-semibold border-2 border-dashed border-red-650 p-2 bg-[#FDF6F6] flex justify-center rounded-md items-center"
                    >
                      Add More
                    </button>
                  </div>
                </td>
                </tr>
              </>
              )
            }
            ></FieldArray>
          }
        </tbody>
      </table>
    </div>
  );
};

EditRateChartTable.propTypes = {
  columns: PropTypes.array.isRequired,
  defaultSortField: PropTypes.string,
  rowsPerPageOptions: PropTypes.array,
};

export default EditRateChartTable;
