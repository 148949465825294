import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import * as types from '../../actions/index.types'
import * as req from '../../../axios/request/ClubReqs/AddFacilityReqs'
import { notify } from '../../../utils/notification';
import { facilityDocsPath } from '../../../firebase/getFirebasePath';
import { uploadImageOnFirebase } from '../../../firebase/firebaseFunction';

function* getRoleList(action) {
    try {
        const result = yield call(req.getRoleListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_ROLE_LIST_SUCCESS, payload: {...action.data, data: result.data}});  
        } else {
            yield put({ type: types.GET_ROLE_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getRoleWiseList(action) {
    try {
        const result = yield call(req.getRoleWiseListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_ROLE_WISE_LIST_SUCCESS, payload: {...action.data, data: result.data}});  
        } else {
            yield put({ type: types.GET_ROLE_WISE_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getVendorConcernedPersonList(action) {
    
    try {
        const result = yield call(req.getVendorConcernedPersonListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_VENDOR_CONCERNED_PERSONS_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_VENDOR_CONCERNED_PERSONS_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* addFacility(action) {
    if(typeof action.data.attachments !== 'string'){
        let path = facilityDocsPath({societyId: action.data.societyId, flatId: action.data.flatId, fileName: action.data.attachments?.name});
        yield uploadImageOnFirebase(action.data.attachments, path)
        action.data.attachments= path;
    }
    try {
        const result = yield call(req.addFacilityReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.ADD_FACILITY_SUCCESS, payload: action.data});  
            action.data?.onSuccessCallback?.()
        }else{
            yield put({ type: types.ADD_FACILITY_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}


export default function* addFacilitySaga() {
    yield takeEvery(types.GET_ROLE_LIST, getRoleList);
    yield takeEvery(types.GET_ROLE_WISE_LIST, getRoleWiseList);
    yield takeLatest(types.GET_VENDOR_CONCERNED_PERSONS_LIST, getVendorConcernedPersonList);
    yield takeLatest(types.ADD_FACILITY, addFacility);

}