import React, { useEffect } from "react";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import {
  financialYears,
  financialYears2,
} from "../../../../consts/DropDownOptions";
import Search from "../../../../components/SearchBox/Search";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  downloadCSVJournals,
  downloadCSVLedgers,
  getBillTypeList,
  getFinanceMembersList,
  getLedgers,
} from "../../../../redux/actions/ReportActions/OldFinancialReportsActions";
import moment from "moment";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ACCOUNT_REPORTS_ENDPOINT } from "../../../../Routing/routes";
import ButtonIco from "../../../../components/Button/ButtonPrint";
import { DownloadExt } from "../../../../assets";
import RangeDatePicker from "../../../../components/DatePicker/RangeDatePicker";
import LedgerTable from "./LedgerTable";
import ButtonG from "../../../../components/Button/ButtonG";
import { downloadExcel } from "../../../../utils/helpers/downloadMedia";

export const headCells = [
  { id: "date", label: "Date", width: "90px" },
  { id: "vouchernumber", label: "Voucher Number", width: "80px" },
  { id: "daybook", label: "DayBook", width: "90px" },
  { id: "code", label: "Code", width: "80px" },
  { id: "billName", label: "Bill Name", width: "90px" },
  { id: "narration", label: "Narration", width: "90px" },
  { id: "refrenceNumber", label: "Refrence No.", width: "90px" },
  { id: "payPin", label: "Pay Pin", width: "90px" },
  { id: "debit", label: "Debit", width: "90px" },
  { id: "credit", label: "Credit", width: "90px" },
  { id: "balance", label: "Balance", width: "90px" },
  { id: "dc", label: "D/C", width: "90px" },
];

const rows = [];

const Ledger = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, billTypeList, financeMembersList, ledgers } = useSelector(
    (state) => state.oldFinancialReportsReducers
  );

  const handleSearch = (text) => {
    if (text && text !== searchParams.get("search")) {
      searchParams.delete("page");
      searchParams.delete("search");
      navigate({
        pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["search", text?.trim()],
        ])}`,
      });
    }
  };

  const handelSelectBillType = (selectedItem) => {
    searchParams.delete("page");

    if (selectedItem.value?.toString() !== searchParams.get("billType")) {
      searchParams.delete("billType");
      navigate({
        pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["billType", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleSelectMembersList = (selectedItem) => {
    searchParams.delete("page");

    if (
      selectedItem.value?.toString() !== searchParams.get("financeMemberList")
    ) {
      searchParams.delete("financeMemberList");
      navigate({
        pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["financeMemberList", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleSelectYear = (selectedItem) => {
    searchParams.delete("page");

    if (selectedItem.value?.toString() !== searchParams.get("year")) {
      searchParams.delete("year");
      navigate({
        pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["year", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleStartDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !==
      searchParams.get("startDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("startDate");
      navigate({
        pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["startDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleEndDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !== searchParams.get("endDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("endDate");
      navigate({
        pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["endDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleClear = () => {
    navigate({
      pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
      search: `?${createSearchParams([["tab", 5]])}`,
    });
  };

  const handleDownloadCSV = () => {
    const onSucessCallback = (data) => {
      downloadExcel({ data, fileName: `Cash/BankBooks.xlsx` });
    };
    const data = {
      society_id: loginData?.default_community?.zipgrid_socsrno,
      // societyId: loginData?.default_community?.community_id,
      fin_year: searchParams.get("year"),
      day_book_id: searchParams.get("billType"),
      account_head: searchParams.get("financeMemberList")
        ? "MYA" +
          loginData?.default_community?.zipgrid_socsrno +
          searchParams.get("financeMemberList")
        : "",
      from_date: searchParams.get("startDate"),
      to_date: searchParams.get("endDate"),
      page: searchParams.get("page") || 1,
      limit: searchParams.get("pageLimit") || 10,
      onSucessCallback,
    };
    dispatch(downloadCSVLedgers(data));
  };

  useEffect(() => {
    dispatch(
      getBillTypeList({
        society_id: loginData?.default_community?.zipgrid_socsrno,
        daybook_filter: "allType",
      })
    );

    dispatch(
      getFinanceMembersList({
        society_registration:
          "MYA" + loginData?.default_community?.zipgrid_socsrno,
      })
    );

    dispatch(
      getLedgers({
        society_id: loginData?.default_community?.zipgrid_socsrno,
        // account_head: searchParams.get("account_head"),
        account_head: searchParams.get("financeMemberList")
          ? "MYA" +
            loginData?.default_community?.zipgrid_socsrno +
            searchParams.get("financeMemberList")
          : "",
        fin_year: searchParams.get("year"),
        day_book_id: searchParams.get("billType"),
        from_date: searchParams.get("startDate"),
        to_date: searchParams.get("endDate"),
        page: searchParams.get("page") || 1,
        limit: searchParams.get("pageLimit") || 10,
      })
    );
  }, [searchParams]);

  return (
    <div className="">
      <div>
        <ListHeader leftContent="Ledgers">
          <Search
            height="8"
            onclick={handleSearch}
            value={searchParams.get("search")}
          />
          <span className="border-r border-[#CCC]"></span>
          {/* <ButtonIco
            icon={DownloadExt}
            children="Download PDF"
            className="h-8"
            type="button"
            onClick={""}
          /> */}
          <ButtonIco
            icon={DownloadExt}
            children="Download Excel"
            className="h-8"
            type="button"
            onClick={handleDownloadCSV}
          />
        </ListHeader>
      </div>
      <div className="flex gap-3 pb-2">
        <Dropdown2
          options={financialYears2}
          placeholder="Choose Financial Year"
          className=""
          width="172px"
          height="32px"
          onSelect={(selectedItem) => {
            const valueParts = selectedItem.value.split("-");
            const selectedValue = `${valueParts[0]}${valueParts[1].slice(2)}`;
            handleSelectYear({ ...selectedItem, value: selectedValue });
          }}
          value={
            searchParams.get("year") && {
              value: searchParams.get("year"),
              label: financialYears2?.find((item) => {
                const valueParts = item.value.split("-");
                const selectedValue = `${valueParts[0]}${valueParts[1].slice(
                  2
                )}`;
                return selectedValue === searchParams.get("year");
              })?.label,
            }
          }
        />
        <RangeDatePicker
          className="w-52 h-8"
          defaultStartValue={
            searchParams.get("startDate")
              ? new Date(searchParams.get("startDate"))
              : ""
          }
          defaultEndValue={
            searchParams.get("endDate")
              ? new Date(searchParams.get("endDate"))
              : ""
          }
          onStartDateChange={handleStartDate}
          onEndDateChange={handleEndDate}
        />

        <Dropdown2
          options={
            financeMembersList
              ? financeMembersList?.map((item) => ({
                  value: item?.regnno,
                  label: item?.shortname,
                }))
              : []
          }
          width={"182px"}
          height="32px"
          className="text-xs"
          placeholder={"Select Accounts"}
          onSelect={(selectedItem) => {
            handleSelectMembersList(selectedItem);
          }}
          value={
            financeMembersList && searchParams.get("financeMemberList")
              ? {
                  value: searchParams.get("financeMemberList"),
                  label: financeMembersList?.find(
                    (item) =>
                      item?.regnno === searchParams.get("financeMemberList")
                  )?.shortname,
                }
              : null
          }
        />
        <Dropdown2
          options={
            billTypeList
              ? [{ adbregnno: "00", daybookname: "All" }, ...billTypeList]?.map(
                  (item) => ({
                    value: item?.adbregnno,
                    label: item?.daybookname,
                  })
                )
              : []
          }
          placeholder="Select Sub Ledger"
          className=""
          width="182px"
          height="32px"
          onSelect={(selectedItem) => {
            handelSelectBillType(selectedItem);
          }}
          value={
            billTypeList && searchParams.get("billType")
              ? {
                  value: searchParams.get("billType"),
                  label: [
                    { adbregnno: "00", daybookname: "All" },
                    ,
                    ...billTypeList,
                  ]?.find(
                    (item) => item?.adbregnno === searchParams.get("billType")
                  )?.daybookname,
                }
              : null
          }
        />
        <span className="border-r border-[#CCC]"></span>
        <ButtonG label="Clear" className="h-8" onClick={handleClear} />
      </div>
      <div className="ml-1">
        <LedgerTable
          loading={loading}
          data={
            ledgers?.list?.map((item) => ({
              date: item?.date
                ? moment(new Date(item?.date))?.format("DD-MM-YYYY")
                : "-",
              vouchernumber: item?.voucher_number,
              daybook: item?.daybook,
              code: item?.code,
              billName: item?.bill_name,
              narration: item?.narration,
              refrenceNumber: item?.ref_number,
              payPin: item?.pay_pin,
              debit: item?.debit,
              credit: item?.credit,
              balance: item?.balance,
              dc: item?.dc,
            })) || []
          }
          columns={headCells}
          checkbox={false}
          pagination={ledgers?.list?.length > 0 ? ledgers?.list?.length : 0}
          totalCount={ledgers?.total_count}
          totalPages={ledgers?.total_pages}
          start={
            (ledgers?.current_page - 1) *
              (parseInt(ledgers?.page_limit) || 10) +
            1
          }
          end={
            (ledgers?.current_page - 1) *
              (parseInt(ledgers?.page_limit) || 10) +
            ledgers?.list?.length
          }
          currentPage={parseInt(ledgers?.current_page)}
          rowsPerPage={searchParams.get("pageLimit") || 10}
        ></LedgerTable>
      </div>
      {!ledgers?.list?.length && !loading && (
        <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
          No Records
        </div>
      )}
    </div>
  );
};

export default Ledger;
