import * as type from "../../../actions/index.types";

export function bankDetailsReducer(
  state = {
    loading: false,
    updateLoader: false,
    bankDetailsData: null,
    bankDetailList: null,
    bankTypeList: null,
    bankAuditLogsData: null,
    error: null,
  },
  action
) {
  switch (action.type) {
    case type.GET_BANK_DETAILS:
      return {
        ...state,
        loading: true,
      };
    case type.GET_BANK_DETAILS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        bankDetailsData: action.payload,
      };
    case type.GET_BANK_DETAILS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        bankDetailsData: null,
      };

    case type.GET_BANK_DETAIL_AND_TYPE_LIST_SUCCESS:
      if (action.payload.flag === "detail")
        return {
          ...state,
          bankDetailList: action.payload.list,
        };
      else
        return {
          ...state,
          bankTypeList: action.payload.list,
        };
    case type.GET_BANK_DETAIL_AND_TYPE_LIST_FAILURE:
      if (action.payload.flag === "detail")
        return {
          ...state,
          bankDetailList: null,
        };
      else
        return {
          ...state,
          bankTypeList: null,
        };

    case type.GET_BANK_DETAILS_AUDIT_LOGS:
      return {
        ...state,
        loading: true,
      };
    case type.GET_BANK_DETAILS_AUDIT_LOGS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        bankAuditLogsData: action.payload,
      };
    case type.GET_BANK_DETAILS_AUDIT_LOGS_FAILURE:
      return {
        ...state,
        bankAuditLogsData: null,
        error: action?.message || "Somthing went wrong.",
        loading: false,
      };

    case type.ADD_BANK_DETAILS:
      return {
        ...state,
        updateLoader: true,
      };
    case type.ADD_BANK_DETAILS_SUCCESS:
      return {
        ...state,
        updateLoader: false,
      };
    case type.ADD_BANK_DETAILS_FAILURE:
      return {
        ...state,
        updateLoader: false,
      };

    default:
      return state;
  }
}

export function bankNameReducer(
  state = {
    loading: false,
    addLoader: false,
    bankNameList: null,
    error: null,
  },
  action
) {
  switch (action.type) {
    case type.GET_BANK_NAME_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_BANK_NAME_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        bankNameList: action.payload,
      };
    case type.GET_BANK_NAME_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        bankNameList: null,
      };

    case type.ADD_BANK_NAME:
      return {
        ...state,
        addLoader: true,
      };
    case type.ADD_BANK_NAME_SUCCESS:
      return {
        ...state,
        addLoader: false,
      };
    case type.ADD_BANK_NAME_FAILURE:
      return {
        ...state,
        addLoader: false,
      };

    default:
      return state;
  }
}
