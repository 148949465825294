import React from "react";
import { ReactComponent as CloseIcon } from "../../../assets/icons/closeIcon.svg";
import Button from "../../../components/Button/Button";
import { useSelector } from "react-redux";
import ImageComponent from "../../../components/ImageComponent/ImageComponent";
import { CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { staffCheckIn } from "../../../redux/actions/GateManagementActions/VisitorAndVehicleInAndOutAction";

const StaffDetailsModal = ({ isOpen, onClose }) => {
  const dispatch =useDispatch()
  const {ipAddress} = useSelector(state=> state.ipAddressReducer)
  const {updateLoader, staffDetails} = useSelector(state=>state.visitorAndVehicleInAndOutReducer)
  const handleIn = ()=>{
    let data={
      visitorLogId: staffDetails?.visitor_log_id,
      staffId: staffDetails?.staff_id,
      profilePic: staffDetails?.profile_image,
      fName: staffDetails?.staff_fname,
      lName: staffDetails?.staff_lname,
      mobile: `${staffDetails?.mobile_country_code ? `${staffDetails?.mobile_country_code}-` : '91-' }${staffDetails?.staff_contact}`,
      vehicleType: staffDetails?.vehicle_type,
      vehicleNo: staffDetails?.staff_vehicle_number,
      email: staffDetails?.staff_email,
      profile: staffDetails?.profile,
      from: staffDetails?.staff_address,
      flatIds: staffDetails?.flat_ids,
      idProofNo: staffDetails?.uid_no || '',
      membersId: staffDetails?.member_ids,
      status: staffDetails?.status === 'Checkedin' ? 'Checkedout' : 'Checkedin',
      ipAddress,
      onSuccessCallback: ()=>{
        onClose()
      }
    }
    dispatch(staffCheckIn(data))
  }

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-60 z-20 ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="bg-white rounded-lg w-[450px] h-fit">
        <div className="flex justify-between p-2 gap-8 items-center -mb-2">
          <h2 className="p-2 text-lg font-semibold mb-2 items-center text-center w-[140%] flex justify-end">
            Staff Details In/Out
          </h2>
          <div className="w-1/2 flex justify-end">
            <button
              onClick={onClose}
              className="bg-[#EAEAEA] rounded-full p-2 items-center mb-2 w-9 h-9 "
            >
              <CloseIcon className="fill-current text-gray-600 hover:text-gray-800 ml-1 w-3 h-3 cursor-pointer" />
            </button>
          </div>
        </div>
        <div className="border-b border-[#CCCCCC]"></div>
        <div className="p-2 text-sm">
          <>
          <div className="flex justify-center mb-3">
            <ImageComponent defaultUrl={staffDetails?.profile_image} width={32} height={32} />
          </div>
          <div>
            <div className="grid grid-cols-2">
              <div className="p-2 flex">
                <span className="text-sm font-semibold  w-24 flex-shrink-0">Visitor Name:</span>
                <span>{staffDetails?.staff_name_prefix ? staffDetails?.staff_name_prefix : ''} {staffDetails?.staff_fname} {staffDetails?.staff_lname}</span>
              </div>
              <div className="p-2 flex">
                <span className="text-sm font-semibold  w-24 flex-shrink-0">Staff Code:</span>
                <span>{staffDetails?.staff_code}</span>
              </div>
            </div>
            <div className="grid grid-cols-2">
              <div className="p-2 flex">
                <span className="text-sm font-semibold  w-24 flex-shrink-0">Contact:</span>
                <span>{`${staffDetails?.mobile_country_code ? `+${staffDetails?.mobile_country_code}-` : ''}${staffDetails?.staff_contact}`}</span>
              </div>
              <div className="p-2 flex">
                <span className="text-sm font-semibold  w-24 flex-shrink-0">Flat Details:</span>
                <span>{staffDetails?.flats || '-'}</span>
              </div>
            </div>
          </div>
            <div className="border-b border-[#CCC] mt-1"></div>
            <div className="flex justify-center mt-2">
              <Button
                label={
                  <span className="w-8 inline-block">
                    {
                      updateLoader ?
                        <CircularProgress sx={{color: "white"}} size={17}/>
                        :
                        staffDetails?.status === 'Checkedin' ? 'Out' : 'In'
                      }
                  </span>
                  } 
                onClick={handleIn}
                className="h-8 font-semibold"
                disabled={false}
                type={"submit"}
              />
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default StaffDetailsModal;
