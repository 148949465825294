import React, { useEffect, useState } from "react";
import Layout from "../../../Layout/Layout";
import Button from "../../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { TENANTS_INFO_ENDPOINT } from "../../../../Routing/routes";
import AccessSettingsComp from "../../../../components/AuditLogsandAccordions/AccessSettingsAccordion";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import { useDispatch, useSelector } from "react-redux";
import { getAccessSettingData, updateAccessSettings } from "../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/TenantsInfoAction";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import Loader from "../../../../components/Loader/Loader";
import { CircularProgress } from "@mui/material";
import { removeZeroValues } from "../../../../utils/helpers/universalFunctions";

const AccessSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading, updateLoader, accessSettingData} = useSelector(state=>state.tenantInfoReducer)

  const [title, setTitle] = useState(null)

  const [accessSetting, setAccessSetting] = useState(null)

  
  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleSave= ()=>{
    const modifiedAccessSetting = removeZeroValues(JSON.parse(JSON.stringify(accessSetting)))
    dispatch(updateAccessSettings({
      accessSetting: JSON.stringify(modifiedAccessSetting),
      tenantAccessId: accessSettingData?.tenant_access_id
    }))
  }

  useEffect(()=>{
    dispatch(getAccessSettingData({societyId: loginData?.default_community?.community_id}))
  }, [])

  useEffect(()=>{
    setAccessSetting(accessSettingData?.tenant_access)
  }, [accessSettingData])



  return (
    <>
      <Layout>
        <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        
          <div className="border-b border-[#CCC] items-center">
            <ListHeaderBack
              onClick={handleNavigation(TENANTS_INFO_ENDPOINT)}
              title="Access Settings"
            >
              <div className="flex items-center gap-2">
                <p>Filter</p>
                <Dropdown2
                  options={accessSettingData?.title_data?.map(item=>({
                    label: item?.title,
                    value: item?.id
                  }))}
                  width="180px"
                  height="8"
                  placeholder="Search Title"
                  className="text-xs"
                  onSelect={(selectedItem) => {
                    setTitle(selectedItem)
                    setAccessSetting(accessSettingData?.title_data?.find(item => item.id === selectedItem.value)?.access)
                  }}
                  value={title}
                />
              </div>
              <div className="border-r"></div>
              <Button label={
                    <span className="w-12 inline-block">
                      {
                        updateLoader ?
                          <CircularProgress sx={{color: "white"}} size={17}/>
                          :
                          'Update'
                        }
                    </span>
                    }
                 className="h-8" onClick={handleSave} />
            </ListHeaderBack>
          </div>
          <div>
            {/* Accordion */}
            {
              loading && !accessSettingData ?
              <Loader/>
                :
              <AccessSettingsComp accessSetting={accessSetting} setAccessSetting={setAccessSetting} />
            }
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AccessSettings;
