
import * as types from '../index.types';

export function getRoleList(data) {
    return {
        type: types.GET_ROLE_LIST,
        data
    };
}

export function getRoleWiseList(data) {
    return {
        type: types.GET_ROLE_WISE_LIST,
        data
    };
}

export function getVendorConcernedPersonList(data) {
    return {
        type: types.GET_VENDOR_CONCERNED_PERSONS_LIST,
        data
    };
}

export function addFacility(data) {
    return {
        type: types.ADD_FACILITY,
        data
    };
}
