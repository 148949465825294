import React, { useState,Fragment} from "react";
import { useNavigate } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Button from "../Button/Button";
import { CircularProgress } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { INVOICE_SETTINGS_TAXABLE_INVOICE_SUBHEAD_ENDPOINT } from "../../Routing/routes";

const Example = ({ list,taxableList,setTaxableList,handleDelete,deleteId,updateLoader,handleOrderChange,typeId }) => {
  const navigate = useNavigate();
  const headCells = [
    { id: "move", label: "Move", width: "80px" },
    { id: "id", label: "S No.", width: "80px" },
    { id: "headerName", label: "Header Name", sort: false, width: "180px" },
    { id: "subtotal", label: "Sub Total Label", sort: false, width: "180px" },
    { id: "showSubTotl", label: "Show Sub Total", sort: false, width: "220px" },
    // { id: "billingCycle", label: "Billing Cycle", sort: false, width: "230px" },
    { id: "action", label: "Action" },
  ];
  const [users, setUsers] = useState([]);

  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = Array.from(list);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setTaxableList({ ...taxableList, taxable_list: tempData });
    let result = tempData.map((item) => (item.id));
    handleOrderChange(result)
  };
  return (
    <div className="mt-4 App">
      
    <DragDropContext onDragEnd={handleDragEnd}>
      <TableContainer >
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            {headCells?.map((head, index) => (
              <TableCell
               size="small"
               key={head.id + "heading"}
               align={head.numeric ? "right" : "left"}
               sx={{
                 width: head.width || "fit-content",
                 minWidth: head.width || "fit-content",
               }}
             >
               {head.label}
              </TableCell>
              //<TableCell></TableCell>
            ))}
          </TableRow>
        </TableHead>
        <Droppable droppableId="droppable-1">
          {(provider) => (
            <tbody
              className="text-capitalize"
              ref={provider.innerRef}
              {...provider.droppableProps}
            >
              {list?.map((tax, index) => (
                <Draggable
                  key={`${tax.id}`}
                  draggableId={`${tax.id}`}
                  index={index}
                >
                  {(provider) => (
                  <TableRow {...provider.draggableProps} ref={provider.innerRef}>
                    <TableCell {...provider.dragHandleProps}> = </TableCell>
                    <TableCell>{index+1}</TableCell>
                    <TableCell>{tax.header_text}</TableCell>
                    <TableCell>{tax.sub_total_text}</TableCell>
                    <TableCell>{tax.hide_show_sub_total === 1 ? 'Yes' : 'No'}</TableCell>
                    <TableCell> <Button
                          label="Sub-Head"
                          className="h-8"
                          onClick={() => {
                            navigate(`${INVOICE_SETTINGS_TAXABLE_INVOICE_SUBHEAD_ENDPOINT}/${typeId}/${tax.id}`)
                          }}
                          disabled={updateLoader}
                        /> <Button
                          label={
                            <span className="inline-block w-12">
                              {deleteId===tax.id ? (
                                <CircularProgress
                                  sx={{ color: "white" }}
                                  size={17}
                                />
                              ) : (
                                "Delete"
                              )}
                            </span>
                          }
                          className="h-8"
                          onClick={() => handleDelete(tax.id)}
                          disabled={updateLoader}
                        /></TableCell>
                  </TableRow>
                  )}
                </Draggable>
              ))}
              {provider.placeholder}
            </tbody>
          )}
       </Droppable>
     
        
        </Table>
      </TableContainer>
    </DragDropContext>
    {(list.length === 0)  && (
          <div className="text-lg text-[#AAAAAA] mt-5 text-center italic">
            No Records
          </div>
        )}
  </div>
  );
};

export default Example;
