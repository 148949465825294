import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import { TextAreaFM } from "../../../components/InputFields/TextArea";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addBooking, getFacilityDetails, getRateChart, sendMessageToAdministrator } from "../../../redux/actions/ClubActions/BookAFacilityAction";
import Loader from "../../../components/Loader/Loader";
import { Form, Formik } from "formik";
import { BookValidation } from "../../../validationSchema/ClubSchema/BookAFacilitySchema";
import { TextInput, TextInputFM } from "../../../components/InputFields/TextInput";
import TextRadio from "../../../components/InputFields/TextRadio";
import { DropdownFM2 } from "../../../components/Dropdown/dropdown2";
import { BookingMemberTypeOptions } from "../../../consts/DropDownOptions";
import { getFlatsNo, getWingsList } from "../../../redux/actions/DeskActions/LogaComplaintActions";
import Button from "../../../components/Button/Button";
import SendMessageModal from "../../../components/Modal/SendMessageModal";
import { getPermission } from "../../../utils/helpers/getPermissions";
import * as permissions from '../../../Routing/permissions'
import PaymentStructure from "./PaymentStructure";
import PaymentMethods from "./PaymentMethods";
import { CircularProgress } from "@mui/material";

const MakePaymentBookings = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const {state} = location;
  const [showModal, setShowModal] = useState(false);
  const [values, setValues] = useState({});
  const [calulatedData, setCalculatedData] = useState({})
  const {loginData, userWingsList, userFlatList} = useSelector(state=>state.loginReducer)
  const {wingsList, flatsList} = useSelector((state) => state.logaComplaintReducer);
  const {loading, loadingRateChart, updateLoader, rateChart, facilityDetails} = useSelector(state=>state.bookAFacilityReducer)
  const navigate = useNavigate();
  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const sendMessage = (values)=>{
    dispatch(sendMessageToAdministrator({...values, purpose: 'Facility Booking', onSuccessCallback: ()=>{
      navigate(-1)
    }}))
  }

  const handleSubmit =(values, {resetForm})=>{
    dispatch(addBooking({
      ...values,
      grandTotal: facilityDetails?.chargeable==='Yes' ?
        ((state?.noOfBooking * calulatedData?.charge)+ parseFloat(calulatedData?.securityDeposit))?.toFixed(2)
        :
        calulatedData?.securityDeposit          
      ,
      societyId: loginData?.default_community?.community_id,
      ...state,
      onSuccessCallback: ()=>{
        navigate(-2)
      }
    }))
  }

  useEffect(()=>{
    const data = { societyId: loginData?.default_community?.community_id };
    dispatch(getWingsList(data));
    dispatch(getFacilityDetails({facilityId: state.facilityId}))
    dispatch(getRateChart({facilityId: state.facilityId}))
  }, [])

  useEffect(()=>{
    setCalculatedData(prev=>({...prev, securityDeposit: values.securityDeposit ? parseFloat(values.securityDeposit)?.toFixed(2) : '0.00'}))
  }, [values])

  useEffect(()=>{
    if(facilityDetails && rateChart){
      setCalculatedData({
        securityDeposit: facilityDetails?.security_deposit ? parseFloat(facilityDetails?.security_deposit)?.toFixed(2) : '0.00',
        charge: facilityDetails?.chargeable==='Yes' ? parseFloat(rateChart?.find(item=>item.time_slot===state.slotId?.toString() && item.booking_type ===state.frequency)?.charges_for_members)?.toFixed(2) : '0.00'
      })
    }
  }, [facilityDetails, rateChart])


  return (
    <>
      <Layout>
        <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
          <div className="border-b border-[#CCC]">
            <ListHeaderBack
              onClick={handleNavigation(-1)}
              title="Make Payment"
            >
            </ListHeaderBack>
          </div>
          {
            loading || loadingRateChart ?
            <Loader/>
            :
            <>
              {
                (facilityDetails?.chargeable==='No' ||  rateChart?.find(item=>item.time_slot===state.slotId?.toString() && item.booking_type ===state.frequency)?.charges_for_members ) ?
                  <Formik
                    initialValues={{
                      securityDeposit: facilityDetails?.security_deposit,
                      noOfBooking: state?.noOfBooking,
                      memberType: loginData?.user_type === 'Tenant' ? 'Tenant' : 'Member',
                      wingId: (loginData?.user_type === 'Tenant' || loginData?.user_type === 'User') ? loginData?.default_community?.blocks?.wing_id : '',
                      flatId: (loginData?.user_type === 'Tenant' || loginData?.user_type === 'User') ? loginData?.default_community?.blocks?.houseUniqueId :  '',
                      memberId: loginData?.user_type === 'Tenant' ? loginData?.default_community?.tenant_id : (loginData?.user_type === 'User' ? loginData?.default_community?.subscriber_id : ''),
                      memberName: (loginData?.user_type === 'Tenant' || loginData?.user_type === 'User') ? loginData?.user_name : '',
                      purpose: ' ',
                      other: ' ',
                      mode: 'No'
                    }}
                    enableReinitialize
                    validationSchema={BookValidation}
                    onSubmit={handleSubmit}
                    innerRef={(formikActions) => formikActions? setValues(formikActions.values) : setValues({})}       
                  >
                    {
                      ({values, errors, setFieldValue})=>(
                        <Form>
                          <>
                            <div className="my-4">
                              <PaymentStructure calulatedData={calulatedData} />
                            </div>
                            <div>
                              <span className="font-semibold ml-2 mb-4">
                                Please Fill Up the Other Details
                              </span>

                              <table className="table-auto w-full">
                                <tbody>
                                  <tr className="text-[#222222] text-sm">
                                    <td className="px-2.5 w-52  pt-2 text-[#AAAAAA] font-bold align-top">
                                      Security Deposit Amount:
                                    </td>
                                    <td className="">
                                      <TextInputFM
                                        className='w-56'
                                        placeholder='Enter'
                                        name='securityDeposit'
                                        type='decimal'
                                        maxLength={8}
                                      />
                                    </td>
                                  </tr>
                                  <tr className="text-[#222222] text-sm">
                                    <td className="px-2.5 w-52  pt-2 text-[#AAAAAA] font-bold align-top">
                                      Booking For:
                                    </td>
                                    <td className="">
                                      <TextInput
                                        className='w-56'
                                        placeholder='Enter'
                                        type='number'
                                        value={values.noOfBooking}
                                        maxLength={8}
                                        disabled={true}
                                      />
                                    </td>
                                  </tr>
                                  <tr className="text-[#222222] text-sm">
                                    <td className="px-2.5 w-52  text-[#AAAAAA] font-bold align-top">
                                      Booking Type:
                                    </td>
                                    <td className="text-sm py-3">
                                      <TextRadio
                                        options={[
                                          {label: 'Self', value: 'Self'},
                                          {label: 'Other', value: 'Other'},
                                        ]}
                                        defaultSelected={(loginData?.user_type==='Society'||loginData?.user_type==='Employee') ? 'Other' : 'Self'}
                                        name='type'
                                        disabled={true}
                                      />
                                    </td>
                                  </tr>
                                  <tr className="text-[#222222] text-sm">
                                    <td className="px-2.5 w-52  text-[#AAAAAA] font-bold align-top">
                                      Member Type:
                                    </td>
                                    <td className="">
                                    <DropdownFM2
                                      options={BookingMemberTypeOptions}
                                      placeholder="Select Member Type"
                                      className=""
                                      width="225px"
                                      height="36px"
                                      onSelect={(selectedItem) => {
                                        setFieldValue('memberType', selectedItem.value)
                                        setFieldValue('wingId', '')
                                        setFieldValue('flatId', '')
                                        setFieldValue('memberId', '')
                                        setFieldValue('memberName', '')
                                      }}
                                      value={values.memberType === "" ? 
                                          null
                                          :
                                          {
                                            label: BookingMemberTypeOptions?.find(item=> item.value === values.memberType)?.label,
                                            value: values.memberType
                                          }
                                        }
                                      name={"memberType"}
                                      disabled={loginData?.user_type==='User'||loginData?.user_type==='Tenant'}
                                    />
                                    </td>
                                  </tr>
                                  <tr className="text-[#222222] text-sm">
                                    <td className="px-2.5 w-52  text-[#AAAAAA] font-bold align-top">
                                      Wing No. & Flat No.:
                                    </td>
                                    <td className="">
                                    <div className="w-56 flex gap-4 mr-4">
                                      {
                                        (loginData?.user_type === 'Tenant' || loginData?.user_type === 'User')
                                        ?
                                        <div className="mb-4">
                                          {loginData?.default_community?.blocks?.wing_flat}
                                        </div>
                                        :
                                        <>
                                          <DropdownFM2
                                            className={"me-4"}
                                            options={
                                                (loginData?.user_type==='User'||loginData?.user_type==='Tenant') ?
                                                userWingsList?.map(item => ({
                                                  label: item.number,
                                                  value: item.wing_id,
                                                }))
                                                :
                                                wingsList?.map((item) => ({
                                                label: item.title,
                                                value: item.id,
                                              }))
                                            }
                                            width="104px"
                                            height="36px"
                                            placeholder="Wing"
                                            name={"wingId"}

                                            onSelect={(selectedItem) => {
                                              setFieldValue("wingId",selectedItem.value);
                                              setFieldValue("flatId", '');
                                              setFieldValue("memberId", '');
                                              setFieldValue("memberName", '');
                                              let data = {
                                                societyId:loginData?.default_community?.community_id,
                                                wingId: selectedItem.value,
                                                userType: values.memberType === 'Member' ? '' : 'Tenant'
                                              };
                                              if(!(loginData?.user_type==='User'|| loginData?.user_type==='Tenant'))
                                                dispatch(getFlatsNo(data));
                                            }}

                                            {...(values.wingId === "" && {
                                              value: null,
                                            })}
                                          />
                                          <DropdownFM2
                                            options={
                                              values.wingId ?
                                                (loginData?.user_type==='User'||loginData?.user_type==='Tenant') ?
                                                userFlatList?.filter(item=>item.wing_id===values.wingId)?.map(item => ({
                                                  label: item.number,
                                                  value: item.flat_id,
                                                  subscriber_id: loginData?.default_community?.subscriber_id,
                                                  memberName: loginData?.user_name,
                                                  tenant_id: loginData?.default_community?.tenant_id,
                                                  tenant_full_name: loginData?.user_name
                                                }))
                                                :
                                                flatsList?.filter(item=> values.memberType==='Member'? item?.subscriber_id : item?.tenantid)?.map((item) => ({
                                                  label: item.flat_no,
                                                  value: item.house_unique_id,
                                                  subscriber_id: item.subscriber_id,
                                                  memberName: item.full_name,
                                                  tenant_id: item.tenantid,
                                                  tenant_full_name: item.tenant_full_name
                                                })) 
                                              : []
                                            }
                                            name={"flatId"}
                                            width="104px"
                                            height="36px"
                                            placeholder="Flat/Unit"
                                            onSelect={(selectedItem) => {
                                              setFieldValue("flatId", selectedItem.value);
                                              setFieldValue("memberName", values.memberType==='Member' ? selectedItem.memberName : selectedItem.tenant_full_name);
                                              setFieldValue("memberId", values.memberType==='Member' ? selectedItem.subscriber_id : selectedItem.tenant_id);
                                            }}
                                            {...(values.flatId === "" && {
                                              value: null,
                                            })}
                                          />
                                        </>
                                      }
                                      </div>
                                    </td>
                                  </tr>
                                  <tr className="text-[#222222] text-sm">
                                    <td className="px-2.5 w-52 py-1 text-[#AAAAAA] font-bold align-top">
                                      {values.memberType} Name:
                                    </td>
                                    <td className="py-1 pb-4">
                                        {values.memberName || '-'}
                                    </td>
                                  </tr>
                                  <tr className="text-[#222222] text-sm">
                                    <td className="px-2.5 w-52 py-1 text-[#AAAAAA] font-bold align-top">
                                      Purpose Of Booking:
                                    </td>
                                    <td>
                                      <div>
                                        <TextAreaFM
                                          label=""
                                          placeholder="Enter"
                                          row="4"
                                          className="w-56"
                                          name='purpose'
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                  <tr className="text-[#222222] text-sm">
                                    <td className="px-2.5 w-52 py-1 text-[#AAAAAA] font-bold align-top">
                                      Other:
                                    </td>
                                    <td>
                                      <div>
                                        <TextAreaFM
                                          label=""
                                          placeholder="Enter"
                                          row="4"
                                          className="w-56"
                                          name='other'
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td></td>
                                    <td>
                                      <TextRadio
                                        options={[
                                          {label: 'Book', value: 'No'},
                                          {label: 'Book & Pay', value: 'Yes'},
                                        ]}
                                        onChange={(value)=>setFieldValue('mode', value)}
                                        defaultSelected={values.mode}
                                        name='mode'
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div>
                              {
                                // values.mode === 'Yes'
                                // ?
                                // <PaymentMethods />
                                // :
                                <>
                                  <Button 
                                    label={
                                      <span className="w-16 inline-block">
                                        {
                                        updateLoader ?
                                          <CircularProgress sx={{color: "white"}} size={17}/>
                                          :
                                          <>Submit</>
                                        }
                                      </span>
                                    }
                                    type='submit' 
                                    className="my-5 ms-64" 
                                    disabled={updateLoader} />
                                </>
                              }
                            </div>
                          </>
                        </Form>
                      )
                    }
                  </Formik>
                :
                <div className="m-8 font-semibold text-center text-red-500 text-sm">
                  This facility is marked as chargeable. <br/>
                  Please reach out to the administrator to set rates to enable booking
                  or try another time-slot
                  <br />
                  {
                    getPermission(loginData, [permissions.SEND_MESSAGE_TO_ADMINISTRATOR_PERMISSION]) &&
                    <div className="mt-8 text-[#000]">
                      Facility list is incomplete? <span onClick={()=>setShowModal(true)} className="cursor-pointer text-blue-500">Send message to the administrator</span> providing facility details to be added.
                      </div>
                  }
                </div>
              }
            </>
          }
        </div>
          <SendMessageModal
            isOpen={showModal}
            onClose={() => {
              setShowModal(false);
            }}
            onConfirm={(values)=>{sendMessage(values)}}
            loading={updateLoader}
          />
      </Layout>
    </>
  );
};

export default MakePaymentBookings;
