import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { getNominationRegisterList } from '../../../redux/actions/ComplianceActions/RegistersAction'
import Loader from '../../../components/Loader/Loader'
import moment from 'moment'
import { validateDate } from '../../../utils/helpers/universalFunctions'
import { PrinterIcon } from '../../../assets'

export const headingsTenants = [
    { id: 'sNo', label: 'S No.', width: '60px' },
    { id: 'date', label: 'Full Name Of The Member Making Nomination	', sort: true,width: '8.6' },
    { id: 'folioNo', label: 'Wing No.', sort: true, width:'8.6'  },
    { id: 'certificateNo', label: 'Flat No.', sort: true, width:'8.6' },
    { id: 'DistnoOfShare', label: 'Date Of Nomination', sort: true, width: '8.6' },
    { id: 'noOfShare', label: 'Name/s Of Nominee/s & Address/es Of The Nominee/s', sort: true, width:'8.6' },
    { id: 'valueOfShare', label: 'Relation', sort: true, width:'8.6'},
    { id: 'nameOfMember', label: 'Percentage', sort: true,width:'8.6' },
    { id: 'flat', label: 'Date Of The Managing Committee Meeting In Which The Nomination Was Recorded', sort: true, width: '8.6' },
    { id: 'dateOfTransfer', label: 'Date Of Any Subsequent Revocation Of Nomination	', sort: true, width: '8.6' },
    { id: 'jounralFolioNo', label: 'Next Folio', sort: true, width: '8.6' },
    { id: 'flat', label: 'Remarks', sort: true, width: '8.6' },
  ];
  
function PrintNominationRegister() {
    const dispatch = useDispatch()
    const {loading, nominationRegisterList} = useSelector(state=>state.registersReducer)
    const {loginData} = useSelector(state=>state.loginReducer)

    useEffect(()=>{
        dispatch(getNominationRegisterList({societyId: loginData?.default_community?.community_id,}))
    }, [])

    
  useEffect(() => {
    if(!loading && nominationRegisterList){
      window.print()
    }
  }, [loading]);

  return loading ? (
    <Loader />
  ) : (
    <div className="m-1">
      <table className="table-auto w-full mx-auto border-collapse border border-black">
        <caption className="caption-top mb-10 mt-4">
          <div className="underline font-extrabold text-lg uppercase mb-3">{loginData?.default_community?.community_name}</div>
          <div className="underline font-bold text-sm">(Reg No.: {loginData?.default_community?.regn_no} &nbsp; Dated: {moment(new Date(loginData?.default_community?.date_of_regn))?.format('DD-MM-YYYY')})</div>
          <div className="font-bold mb-5 text-sm">{loginData?.default_community?.office_address} </div>
          <div className="underline font-extrabold text-sm text-red-500 uppercase mt-3">REGISTER OF NOMINATION</div>
        </caption>
        <thead>
          <tr>
            {
              headingsTenants?.map((item)=>(
                <td className={`text-sm p-1 font-semibold bg-[#DDDDDD] border border-black w-[${item.width}]`} key={item?.id}>
                  {item?.label}
                </td>
              ))
            }
          </tr>
          <tr>
            <td className="text-sm p-1 font-semibold bg-[#DDDDDD] border border-black">1</td>
            <td className="text-sm p-1 font-semibold bg-[#DDDDDD] border border-black">2</td>
            <td className="text-sm p-1 font-semibold bg-[#DDDDDD] border border-black">2A</td>
            <td className="text-sm p-1 font-semibold bg-[#DDDDDD] border border-black">2B</td>
            <td className="text-sm p-1 font-semibold bg-[#DDDDDD] border border-black">3</td>
            <td className="text-sm p-1 font-semibold bg-[#DDDDDD] border border-black">4</td>
            <td className="text-sm p-1 font-semibold bg-[#DDDDDD] border border-black">4-A</td>
            <td className="text-sm p-1 font-semibold bg-[#DDDDDD] border border-black">4-B</td>
            <td className="text-sm p-1 font-semibold bg-[#DDDDDD] border border-black">5</td>
            <td className="text-sm p-1 font-semibold bg-[#DDDDDD] border border-black">6</td>
            <td className="text-sm p-1 font-semibold bg-[#DDDDDD] border border-black">6-A</td>
            <td className="text-sm p-1 font-semibold bg-[#DDDDDD] border border-black">7</td>
          </tr>
        </thead>
        <tbody>
            {
              nominationRegisterList?.map((item, index)=>(
                <React.Fragment  key={index}>
                {
                  !!Object.keys(item)?.length &&
                  <tr className="text-[#222] text-sm">
                    <td className="p-1 border border-black align-top">
                      {(index+1)?.toString()?.padStart(2, 0)}
                    </td>
                    <td className="p-1 border border-black align-top capitalize">
                        {item?.occupant_name || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.wing_no || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.flat_no || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                    {validateDate(item?.date_of_nomination) ? moment(new Date(item?.date_of_nomination))?.format('DD-MM-YYYY') : '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      <div>Name: {item?.nominee || '-'}</div>
                      <div>Address: {item?.address || '-'}</div>
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.relationship || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.share || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.remarfks || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {validateDate(item?.date_of_subsequent_revocation) ? moment(new Date(item?.date_of_subsequent_revocation))?.format('DD-MM-YYYY') : '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.remardfks || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.remarks || '-'}
                    </td>
                  </tr>
                }
                </React.Fragment>
              ))
            }
          
        </tbody>
      </table>
      {!nominationRegisterList?.length && !loading && (
          <div className="text-sm my-10 text-center italic">
            No Records
          </div>
        )}

        <div className="my-9 underline font-extrabold text-sm uppercase justify-center items-center cursor-pointer text-red-500 flex" onClick={()=>{window.print()}}> <PrinterIcon/>  &nbsp; PRINT</div>
    </div>
  );
}

export default PrintNominationRegister