import * as type from "../../../actions/index.types";

export function manageAdvanceNoteReducer(
  state = {
    loading: false,
    printLoader: false,
    updateLoader: false,
    advanceNoteList: null,
    advanceNoteDetails: null,
    advanceReciept: null,
    nonMemberList: null,
    excelData: null,
    error: null,
  },
  action
) {
  switch (action.type) {
    case type.GET_ADVANCE_NOTE_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_ADVANCE_NOTE_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        advanceNoteList: action.payload,
      };
    case type.GET_ADVANCE_NOTE_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        advanceNoteList: null,
      };
    
    case type.GET_ADVANCE_NOTE_DETAILS:
    return {
        ...state,
        loading: true,
        error: null,
    };
    case type.GET_ADVANCE_NOTE_DETAILS_SUCCESS:
    return {
        ...state,
        error: null,
        loading: false,
        advanceNoteDetails: action.payload,
    };
    case type.GET_ADVANCE_NOTE_DETAILS_FAILURE:
    return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        advanceNoteDetails: null,
    };
        
    case type.ADD_ADVANCE_NOTE:
    return {
        ...state,
        updateLoader: true
    }
    case type.ADD_ADVANCE_NOTE_SUCCESS:
    return {
        ...state,
        updateLoader: false
        }

    case type.ADD_ADVANCE_NOTE_FAILURE:
    return {
        ...state,
        updateLoader: false
        }

    case type.UPDATE_ADVANCE_NOTE:
        return {
        ...state,
        updateLoader: true
        }
    case type.UPDATE_ADVANCE_NOTE_SUCCESS:
    return {
        ...state,
        updateLoader: false
        }
    case type.UPDATE_ADVANCE_NOTE_FAILURE:
    return {
        ...state,
        updateLoader: false
        }


    case type.DELETE_ADVANCE_NOTE:
        return {
        ...state,
        updateLoader: true
        }
    case type.DELETE_ADVANCE_NOTE_SUCCESS:
    return {
        ...state,
        updateLoader: false,
        advanceNoteList: {
          ...state.advanceNoteList,
          list: state.advanceNoteList.list.filter(item=> item.id !== action.payload.advanceNoteId)
        }
      }
    case type.DELETE_ADVANCE_NOTE_FAILURE:
    return {
        ...state,
        updateLoader: false
        }

    case type.PRINT_ADVANCE_NOTE_RECIEPT:
        return {
        ...state,
        printLoader: true
        }
    case type.PRINT_ADVANCE_NOTE_RECIEPT_SUCCESS:
    return {
        ...state,
        printLoader: false,
        advanceReciept: action.payload
    }
    case type.PRINT_ADVANCE_NOTE_RECIEPT_FAILURE:
    return {
        ...state,
        printLoader: false,
        advanceReciept: null,
        }

    case type.GET_NON_MEMBER_LIST:
      return {
        ...state,
        error: null,
        nonMemberList: null
      };
    case type.GET_NON_MEMBER_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        nonMemberList: action.payload,
      };
    case type.GET_NON_MEMBER_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        nonMemberList: null,
      };


    case type.UPLOAD_ADVANCE_NOTE_EXCEL:
      return {
        ...state,
        uploadLoader: true,
        error: null,
      };
    case type.UPLOAD_ADVANCE_NOTE_EXCEL_SUCCESS:
      return {
        ...state,
        error: null,
        uploadLoader: false,
        excelData: action.payload,
      };
    case type.UPLOAD_ADVANCE_NOTE_EXCEL_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        uploadLoader: false,
        excelData: null,
      };
    
    case type.UPLOAD_CLEAR_ERROR:
        return {
          ...state,
          error: null,
          uploadLoader: false,
          excelData: null,
        };
      
    
    case type.SAVE_ADVANCE_NOTE_EXCEL:
        return {
        ...state,
        uploadLoader: true
        }
    case type.SAVE_ADVANCE_NOTE_EXCEL_SUCCESS:
    return {
        ...state,
        uploadLoader: false
        }
    case type.SAVE_ADVANCE_NOTE_EXCEL_FAILURE:
    return {
        ...state,
        uploadLoader: false
        }
        
    default:
      return state;
  }
}
