import moment from 'moment'
import * as type from '../../../actions/index.types'

export function manageRoleAndRightsReducer(state = {
  loading: false,
  updateLoader: false,
  printLoader: false,
  employeeRoleList: null,
  employeeRoleDetails: null,
  occupantsRoleList: null,
  occupantRoleDetails: null,
  familyRoleList: null,
  familyRoleDetails: null,
  loadingAccessSettings: false,
  accessSettingData: null,
  societyAccessSetting: null,
  error:null
}, action) {

switch (action.type) {
    case type.GET_EMPLOYEE_ROLE_LIST:
        return {
        ...state,
        loading: true,
        error: null
        }
    case type.GET_EMPLOYEE_ROLE_LIST_SUCCESS:
        return {
        ...state,
        error: null,
        loading: false,
        employeeRoleList: action.payload
        }
    case type.GET_EMPLOYEE_ROLE_LIST_FAILURE:
        return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        loading: false,
        employeeRoleList: null,
        }

    case type.GET_EMPLOYEE_ROLE_DETAILS:
        return {
            ...state,
            loading: true,
            error: null
        }
    case type.GET_EMPLOYEE_ROLE_DETAILS_SUCCESS:
        return {
            ...state,
            error: null,
            loading: false,
            employeeRoleDetails: action.payload
        }
    case type.GET_EMPLOYEE_ROLE_DETAILS_FAILURE:
        return {
            ...state,
            error: action?.message || 'Somthing went wrong.',
            loading: false,
            employeeRoleDetails: null,
        }

    case type.ADD_AND_UPDATE_EMPLOYEE_ROLE_DETAILS:
        return {
        ...state,
        updateLoader: true,
        }
    case type.ADD_AND_UPDATE_EMPLOYEE_ROLE_DETAILS_SUCCESS:
        return {
        ...state,
        updateLoader: false,
        }
    case type.ADD_AND_UPDATE_EMPLOYEE_ROLE_DETAILS_FAILURE:
        return {
        ...state,
        updateLoader: false,
        }

    case type.GET_OCCUPANT_ROLE_LIST:
        return {
        ...state,
        loading: true,
        error: null
        }
    case type.GET_OCCUPANT_ROLE_LIST_SUCCESS:
        return {
        ...state,
        error: null,
        loading: false,
        occupantsRoleList: action.payload
        }
    case type.GET_OCCUPANT_ROLE_LIST_FAILURE:
        return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        loading: false,
        occupantsRoleList: null,
        }

    case type.GET_OCCUPANTS_ROLE_DETAILS:
        return {
            ...state,
            loading: true,
            error: null
        }
    case type.GET_OCCUPANTS_ROLE_DETAILS_SUCCESS:
        return {
            ...state,
            error: null,
            loading: false,
            occupantRoleDetails: action.payload
        }
    case type.GET_OCCUPANTS_ROLE_DETAILS_FAILURE:
        return {
            ...state,
            error: action?.message || 'Somthing went wrong.',
            loading: false,
            occupantRoleDetails: null,
        }

    case type.ADD_AND_UPDATE_OCCUPANTS_ROLE_DETAILS:
        return {
        ...state,
        updateLoader: true,
        }
    case type.ADD_AND_UPDATE_OCCUPANTS_ROLE_DETAILS_SUCCESS:
        return {
        ...state,
        updateLoader: false,
        }
    case type.ADD_AND_UPDATE_OCCUPANTS_ROLE_DETAILS_FAILURE:
        return {
        ...state,
        updateLoader: false,
        }
    

    case type.GET_FAMILY_ROLE_LIST:
        return {
        ...state,
        loading: true,
        error: null
        }
    case type.GET_FAMILY_ROLE_LIST_SUCCESS:
        return {
        ...state,
        error: null,
        loading: false,
        familyRoleList: action.payload
        }
    case type.GET_FAMILY_ROLE_LIST_FAILURE:
        return {
        ...state,
        error: action?.message || 'Somthing went wrong.',
        loading: false,
        familyRoleList: null,
        }

    case type.GET_FAMILY_ROLE_DETAILS:
        return {
            ...state,
            loading: true,
            error: null
        }
    case type.GET_FAMILY_ROLE_DETAILS_SUCCESS:
        return {
            ...state,
            error: null,
            loading: false,
            familyRoleDetails: action.payload
        }
    case type.GET_FAMILY_ROLE_DETAILS_FAILURE:
        return {
            ...state,
            error: action?.message || 'Somthing went wrong.',
            loading: false,
            familyRoleDetails: null,
        }

    case type.ADD_AND_UPDATE_FAMILY_ROLE_DETAILS:
        return {
        ...state,
        updateLoader: true,
        }
    case type.ADD_AND_UPDATE_FAMILY_ROLE_DETAILS_SUCCESS:
        return {
        ...state,
        updateLoader: false,
        }
    case type.ADD_AND_UPDATE_FAMILY_ROLE_DETAILS_FAILURE:
        return {
        ...state,
        updateLoader: false,
        }


    case type.GET_ROLE_RIGHT_SETTINGS:
        return {
            ...state,
            loadingAccessSettings: true,
            error: null
        }
    case type.GET_ROLE_RIGHT_SETTINGS_SUCCESS:
        return {
            ...state,
            error: null,
            loadingAccessSettings: false,
            accessSettingData: action.payload
        }
    case type.GET_ROLE_RIGHT_SETTINGS_FAILURE:
        return {
            ...state,
            error: action?.message || 'Somthing went wrong.',
            loadingAccessSettings: false,
            accessSettingData: null,
        }

    case type.UPDATE_ROLE_RIGHT_STATUS:
        return {
        ...state,
        updateLoader: true,
        }
    case type.UPDATE_ROLE_RIGHT_STATUS_SUCCESS:
        if(action.payload.flag==='employee'){
            return {
                ...state,
                updateLoader: false,
                employeeRoleList: state.employeeRoleList?.map( (employeeRole) => employeeRole?.emp_role_id === action.payload.id
                    ? {...employeeRole, status: action.payload.status}
                    : employeeRole
                )
                
              }
        }else if(action.payload.flag==='occupant'){
            console.log(state.occupantsRoleList)
            return {
                ...state,
                updateLoader: false,
                occupantsRoleList: state.occupantsRoleList?.map( (occupantRole) => occupantRole?.occupant_role_id === action.payload.id
                    ? {...occupantRole, status: action.payload.status}
                    : occupantRole
                )
                
              }
        }else{
            return {
                ...state,
                updateLoader: false,
                familyRoleList: state.familyRoleList?.map( (familyRole) => familyRole?.family_role_id === action.payload.id
                    ? {...familyRole, status: action.payload.status}
                    : familyRole
                )
                
              }
        }
        return {
        ...state,
        updateLoader: false,
        }
    case type.UPDATE_ROLE_RIGHT_STATUS_FAILURE:
        return {
        ...state,
        updateLoader: false,
        }
        

    case type.DELETE_RIGHTS_AND_ROLES_SUCCESS:
        if(action.payload?.flag==='employee'){
            return {
                ...state,
                employeeRoleList: state.employeeRoleList?.filter(item=> !action.payload.ids?.includes(item.emp_role_id))
            }
        }if(action.payload?.flag==='occupant'){
            return {
                ...state,
                occupantsRoleList: state.occupantsRoleList?.filter(item=> !action.payload.ids?.includes(item.occupant_role_id))
            }
        }else{
            return {
                ...state,
                familyRoleList: state.familyRoleList?.filter(item=> !action.payload.ids?.includes(item.family_role_id))
            }
        }


    case type.GET_SOCIETY_ACCESS_SETTINGS:
        return {
            ...state,
            loading: true,
            error: null
        }
    case type.GET_SOCIETY_ACCESS_SETTINGS_SUCCESS:
        return {
            ...state,
            error: null,
            loading: false,
            societyAccessSetting: action.payload
        }
    case type.GET_SOCIETY_ACCESS_SETTINGS_FAILURE:
        return {
            ...state,
            error: action?.message || 'Somthing went wrong.',
            loading: false,
            societyAccessSetting: null,
        }

    case type.UPDATE_SOCIETY_ACCESS_SETTINGS:
        return {
        ...state,
        updateLoader: true,
        }
    case type.UPDATE_SOCIETY_ACCESS_SETTINGS_SUCCESS:
        return {
        ...state,
        updateLoader: false,
        societyAccessSetting: {...state.societyAccessSetting, settings: action.payload.accessSetting}
        }
    case type.UPDATE_SOCIETY_ACCESS_SETTINGS_FAILURE:
        return {
        ...state,
        updateLoader: false,
        }


    default:
        return state
    }
}