import {call, put, takeLatest } from 'redux-saga/effects'
import {notify} from '../../../../utils/notification'
import * as types from '../../../actions/index.types'
import * as req from '../../../../axios/request/SetupRecordsReqs/StaffManagementReqs/MaintenanceAgencyReqs'
import { getFirebaseURL, uploadImageOnFirebase } from '../../../../firebase/firebaseFunction';
import { societyStaffDocsPath, societyStaffProfilePath } from '../../../../firebase/getFirebasePath';

function* getMaintenanceStaffList(action) {
    try {
        const result = yield call(req.getMaintenanceStaffListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_MAINTENANCE_STAFF_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_MAINTENANCE_STAFF_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getAgencyList(action) {
    try {
        const result = yield call(req.getAgencyListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_AGENCY_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_AGENCY_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* addUpdateMaintenanceStaff(action) {
    try {
        if(typeof action.data.profilePic !== 'string'){
            let path = societyStaffProfilePath({societyId: action.data.societyId, fileName: action.data.profilePic?.name});
            yield uploadImageOnFirebase(action.data.profilePic, path)
            action.data.profilePic= path;
        }

        if(typeof action.data.idCard !== 'string'){
            let path = societyStaffDocsPath({societyId: action.data.societyId, fileName: action.data.idCard?.name});
            yield uploadImageOnFirebase(action.data.idCard, path)
            action.data.idCard= path;
        }

        const result = yield call(req.addUpdateMaintenanceStaffReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.ADD_UPDATE_MAINTENANCE_STAFF_SUCCESS, payload: result.data});  
            action.data?.onSuccessCallback?.()
        }else{
            yield put({ type: types.ADD_UPDATE_MAINTENANCE_STAFF_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}

function* getMaintenanceStaffDetails(action) {
    try {
        const result = yield call(req.getMaintenanceStaffDetialsReq, action);
        if (result.statusCode === 200) {
            result.data.imageURL = yield getFirebaseURL(result?.data?.image)
            result.data.idCardeURL = yield getFirebaseURL(result?.data?.id_upload)
            yield put({ type: types.GET_MAINTENANCE_STAFF_DETAILS_SUCCESS, payload: result.data});  
        }else{
            yield put({ type: types.GET_MAINTENANCE_STAFF_DETAILS_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}

function* deleteMaintenanceStaffOrAgency(action) {
    try {
        const result = yield call(req.deleteMaintenanceStaffOrAgencyReq, action);
        if (result.statusCode === 200) {
            action.data?.onSuccessCallback?.()
            notify('success', result.message);
            yield put({ type: types.DELETE_MAINTENANCE_STAFF_OR_AGENCY_SUCCESS, payload: action.data}); 
            if(action.data.flag=== 'agency'){
                const newList = yield call(req.getAgencyListReq, action)
                if (newList.statusCode === 200)
                    yield put({ type: types.GET_AGENCY_LIST_SUCCESS, payload: newList.data});  
            }
            else{
                const newList = yield call(req.getMaintenanceStaffListReq, action)
                if (newList.statusCode === 200)
                    yield put({ type: types.GET_MAINTENANCE_STAFF_LIST_SUCCESS, payload: newList.data});  
            } 
        }
    } catch (e) {
        console.log(e)
    }
}


function* getAgencyCategoryList(action) {
    try {
        const result = yield call(req.getAgencyCategoryListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_AGENCY_CATEGORY_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_AGENCY_CATEGORY_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getAgencyDetails(action) {
    try {
        const result = yield call(req.getAgencyDetailsReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_AGENCY_DETAILS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_AGENCY_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* addUpdateAgency(action) {
    try {
        const result = yield call(req.addUpdateAgencyReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.ADD_UPDATE_AGENCY_SUCCESS, payload: result.data});  
            action.data?.onSuccessCallback?.()
        }else{
            yield put({ type: types.ADD_UPDATE_AGENCY_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}

export default function* maintenanceAgencySaga() {
    yield takeLatest(types.GET_MAINTENANCE_STAFF_LIST, getMaintenanceStaffList);
    yield takeLatest(types.GET_AGENCY_LIST, getAgencyList);
    yield takeLatest(types.ADD_UPDATE_MAINTENANCE_STAFF, addUpdateMaintenanceStaff);
    yield takeLatest(types.GET_MAINTENANCE_STAFF_DETAILS, getMaintenanceStaffDetails);
    yield takeLatest(types.DELETE_MAINTENANCE_STAFF_OR_AGENCY, deleteMaintenanceStaffOrAgency);
    yield takeLatest(types.GET_AGENCY_CATEGORY_LIST, getAgencyCategoryList);
    yield takeLatest(types.GET_AGENCY_DETAILS, getAgencyDetails);
    yield takeLatest(types.ADD_UPDATE_AGENCY, addUpdateAgency);
}