import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import * as types from '../../actions/index.types'
import * as req from '../../../axios/request/ClubReqs/AddTimeSlotReqs'
import { notify } from '../../../utils/notification';

function* getTimeSlotTypeList(action) {
    try {
        const result = yield call(req.getTimeSlotTypeListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_TIME_SLOT_TYPE_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_TIME_SLOT_TYPE_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* addTimeSlotType(action) {
    try {
        const result = yield call(req.addTimeSlotTypeReq, action);
        if (result.statusCode === 200) {
            notify('success', 'Type added successfully')
            yield put({ type: types.ADD_TIME_SLOT_TYPE_SUCCESS, payload: action.data});  
            action.data?.onSuccessCallback?.()
            const newList = yield call(req.getTimeSlotTypeListReq, action)
            if (newList.statusCode === 200)
                yield put({ type: types.GET_TIME_SLOT_TYPE_LIST_SUCCESS, payload: newList.data});  
        }else{
            yield put({ type: types.ADD_TIME_SLOT_TYPE_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateTimeSlotType(action) {
    try {
        const result = yield call(req.updateTimeSlotTypeReq, action);
        if (result.statusCode === 200) {
            notify('success', 'Type updated successfully')
            yield put({ type: types.UPDATE_TIME_SLOT_TYPE_SUCCESS, payload: action.data});  
            action.data?.onSuccessCallback?.()
            const newList = yield call(req.getTimeSlotTypeListReq, action)
            if (newList.statusCode === 200)
                yield put({ type: types.GET_TIME_SLOT_TYPE_LIST_SUCCESS, payload: newList.data});  
        }else{
            yield put({ type: types.UPDATE_TIME_SLOT_TYPE_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}

function* deleteTimeSlotType(action) {
    try {
        const result = yield call(req.deleteTimeSlotTypeReq, action);
        if (result.statusCode === 200) {
            notify('success', 'Type removed successfully');
            action.data?.onSuccessCallback?.()
            yield put({ type: types.DELETE_TIME_SLOT_TYPE_SUCCESS, payload: action.data});  
            const newList = yield call(req.getTimeSlotTypeListReq, action)
            if (newList.statusCode === 200)
                yield put({ type: types.GET_TIME_SLOT_TYPE_LIST_SUCCESS, payload: newList.data});  
        }else{
            yield put({ type: types.DELETE_TIME_SLOT_TYPE_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}


export default function* addTimeSlotSaga() {
    yield takeLatest(types.GET_TIME_SLOT_TYPE_LIST, getTimeSlotTypeList);
    yield takeLatest(types.ADD_TIME_SLOT_TYPE, addTimeSlotType);
    yield takeLatest(types.UPDATE_TIME_SLOT_TYPE, updateTimeSlotType);
    yield takeLatest(types.DELETE_TIME_SLOT_TYPE, deleteTimeSlotType);
}