import * as type from "../../actions/index.types";

export function noticeAndCircularReducer(
    state = {
        loading: false,
        updateLoader: false,
        noticeCircularList: null,
        noticeCircularDetail: null,
        printNoticeCircularDetail: "",
        noticeCircularTypes: [],
        noticeCircularManagingCommittee: [],
        noticeCircularArchiveList: [],
        noticeCircularAcknowledgementList: [],
        error: null,
    },
    action
) {
    switch (action.type) {

        case type.GET_NOTICE_CIRCULAR_LIST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case type.GET_NOTICE_CIRCULAR_LIST_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                noticeCircularList: action.payload,
            };
        case type.GET_NOTICE_CIRCULAR_LIST_FAILURE:
            return {
                ...state,
                error: action?.message || "Somthing went wrong.",
                loading: false,
                noticeCircularList: null,
            };

        case type.RESEND_NOTICE_CIRCULAR:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case type.RESEND_NOTICE_CIRCULAR_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                resendNoticeCircular: true,
            };
        case type.RESEND_NOTICE_CIRCULAR_FAILURE:
            return {
                ...state,
                error: action?.message || "Somthing went wrong.",
                loading: false,
                resendNoticeCircular: null,
            };


        case type.DELETE_NOTICE_CIRCULAR:
            return {
                ...state,
                loading: true,
                error: null,

            };
        case type.DELETE_NOTICE_CIRCULAR_SUCCESS:
            return {
                ...state,
                noticeCircularList: {
                    ...state.noticeCircularList,
                    list: state.noticeCircularList?.list?.filter(
                        (item) =>
                            !(item.m_notice_id === action.payload.m_notice_id)
                    ),
                },
                error: null,
                loading: false,
            };
        case type.DELETE_NOTICE_CIRCULAR_FAILURE:
            return {
                ...state,
                error: action?.message || "Somthing went wrong.",
                loading: false,
                noticeCircular: null,
            };


        case type.GET_NOTICE_CIRCULAR_DETAILS:
            return {
                ...state,
                loading: true,
                error: null,

            };
        case type.GET_NOTICE_CIRCULAR_DETAILS_SUCCESS:
            return {
                ...state,
                noticeCircularDetail: action.payload,
                error: null,
                loading: false,
            };
        case type.GET_NOTICE_CIRCULAR_DETAILS_FAILURE:
            return {
                ...state,
                error: action?.message || "Somthing went wrong.",
                loading: false,
                noticeCircularDetail: null,
            };

        case type.PRINT_NOTICE_CIRCULAR:
            return {
                ...state,
                loading: true,
                error: null,

            };
        case type.PRINT_NOTICE_CIRCULAR_SUCCESS:
            return {
                ...state,
                printNoticeCircularDetail: action.payload,
                error: null,
                loading: false,
            };
        case type.PRINT_NOTICE_CIRCULAR_SUCCESS:
            return {
                ...state,
                error: action?.message || "Somthing went wrong.",
                loading: false,
                printNoticeCircularDetail: '',
            };



        case type.POST_NOTICE_CIRCULAR_COMMENT:
            return {
                ...state,
                loading: true,
                error: null,

            };
        case type.POST_NOTICE_CIRCULAR_COMMENT_SUCCESS:
            return {
                ...state,
                noticeCircularComment: true,
                error: null,
                loading: false,
            };
        case type.POST_NOTICE_CIRCULAR_COMMENT_FAILURE:
            return {
                ...state,
                error: action?.message || "Somthing went wrong.",
                loading: false,
                noticeCircularComment: null,

            };


        case type.ADD_NOTICE_CIRCULAR:
            return {
                ...state,
                updateLoader: true,
                error: null,

            };
        case type.ADD_NOTICE_CIRCULAR_SUCCESS:
            return {
                ...state,
                addNoticeCircular: action.payload,
                error: null,
                updateLoader: false,
            };
        case type.ADD_NOTICE_CIRCULAR_FAILURE:
            return {
                ...state,
                addNoticeCircular: [],
                error: action?.message || "Somthing went wrong.",
                updateLoader: false,

            };

        case type.UPDATE_NOTICE_CIRCULAR:
            return {
                ...state,
                updateLoader: true,
                error: null,

            };
        case type.UPDATE_NOTICE_CIRCULAR_SUCCESS:
            return {
                ...state,
                updateNoticeCircular: action.payload,
                error: null,
                updateLoader: false,
            };
        case type.UPDATE_NOTICE_CIRCULAR_FAILURE:
            return {
                ...state,
                updateNoticeCircular: [],
                error: action?.message || "Somthing went wrong.",
                updateLoader: false,

            };


        case type.GET_NOTICE_CIRCULAR_TYPES:
            return {
                ...state,
                updateLoader: true,
                error: null,

            };
        case type.GET_NOTICE_CIRCULAR_TYPES_SUCCESS:
            return {
                ...state,
                noticeCircularTypes: action.payload,
                error: null,
                updateLoader: false,
            };
        case type.GET_NOTICE_CIRCULAR_TYPES_FAILURE:
            return {
                ...state,
                noticeCircularTypes: [],
                error: action?.message || "Somthing went wrong.",
                updateLoader: false,

            };

        case type.GET_NOTICE_CIRCULAR_MANAGING_COMMITTEE:
            return {
                ...state,
                updateLoader: true,
                error: null,

            };
        case type.GET_NOTICE_CIRCULAR_MANAGING_COMMITTEE_SUCCESS:
            return {
                ...state,
                noticeCircularManagingCommittee: action.payload,
                error: null,
                updateLoader: false,
            };
        case type.GET_NOTICE_CIRCULAR_MANAGING_COMMITTEE_FAILURE:
            return {
                ...state,
                noticeCircularManagingCommittee: [],
                error: action?.message || "Somthing went wrong.",
                updateLoader: false,

            };

        case type.GET_NOTICE_CIRCULAR_ARCHIVE_LIST:
            return {
                ...state,
                loading: true,
                error: null,

            };
        case type.GET_NOTICE_CIRCULAR_ARCHIVE_LIST_SUCCESS:
            return {
                ...state,
                noticeCircularArchiveList: action.payload,
                error: null,
                loading: false,
            };
        case type.GET_NOTICE_CIRCULAR_ARCHIVE_LIST_FAILURE:
            return {
                ...state,
                noticeCircularArchiveList: [],
                error: action?.message || "Somthing went wrong.",
                loading: false,

            };

        case type.ADD_ACKNOWLEDGEMENT_NOTICE:
            return {
                ...state,
                loading: true,
                error: null,

            };
        case type.ADD_ACKNOWLEDGEMENT_NOTICE_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
            };
        case type.ADD_ACKNOWLEDGEMENT_NOTICE_FAILURE:
            return {
                ...state,
                error: action?.message || "Somthing went wrong.",
                loading: false,

            };

        case type.GET_NOTICE_CIRCULAR_ACKNOWLEDGEMENT_LIST:
            return {
                ...state,
                loading: true,
                error: null,

            };
        case type.GET_NOTICE_CIRCULAR_ACKNOWLEDGEMENT_LIST_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                noticeCircularAcknowledgementList: action.payload,
            };
        case type.GET_NOTICE_CIRCULAR_ACKNOWLEDGEMENT_LIST_FAILURE:
            return {
                ...state,
                error: action?.message || "Somthing went wrong.",
                loading: false,
                noticeCircularAcknowledgementList: null

            };

        case type.DELETE_NOTICE_CIRCULAR_ARCHIVE:
            return {
                ...state,
                loading: true,
                error: null,

            };
        case type.DELETE_NOTICE_CIRCULAR_ARCHIVE_SUCCESS:
            return {
                ...state,
                noticeCircularArchiveList: {
                    ...state.noticeCircularArchiveList,
                    list: state.noticeCircularArchiveList?.list?.filter(
                        (item) =>
                            !(item.id === action.payload.id)
                    ),
                },
                error: null,
                loading: false,
            };
        case type.DELETE_NOTICE_CIRCULAR_ARCHIVE_FAILURE:
            return {
                ...state,
                error: action?.message || "Somthing went wrong.",
                loading: false,
                noticeCircularArchive: null,
            };

        default:
            return state;


    }
}
