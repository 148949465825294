import * as type from "../../actions/index.types";

export function addTimeSlotReducer(
  state = {
    loading: false,
    updateLoader: false,
    typeList: null,
    error: null,
  },
  action
) {
  switch (action.type) {

    case type.GET_TIME_SLOT_TYPE_LIST:
      return {
        ...state,
        error: null,
        loading: true
      };
    case type.GET_TIME_SLOT_TYPE_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        typeList: action.payload,
      };
    case type.GET_TIME_SLOT_TYPE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action?.message || "Somthing went wrong.",
        typeList: null,
      };


    case type.ADD_TIME_SLOT_TYPE:
      return {
          ...state,
          updateLoader: true,
      };
    case type.ADD_TIME_SLOT_TYPE_SUCCESS:
    return {
        ...state,
        updateLoader: false,
    };
    case type.ADD_TIME_SLOT_TYPE_FAILURE:
      return {
          ...state,
          updateLoader: false,
      };



    case type.UPDATE_TIME_SLOT_TYPE:
      return {
          ...state,
          updateLoader: true,
      };
    case type.UPDATE_TIME_SLOT_TYPE_SUCCESS:
    return {
        ...state,
        updateLoader: false,
    };
    case type.UPDATE_TIME_SLOT_TYPE_FAILURE:
      return {
          ...state,
          updateLoader: false,
      };

    case type.DELETE_TIME_SLOT_TYPE:
      return {
        ...state,
        updateLoader: true
      }
    case type.DELETE_TIME_SLOT_TYPE_SUCCESS:
      return {
        ...state,
        updateLoader: false,
        typeList: state.typeList?.filter(item=>!(item.id === action.payload.typeId))
      }
      
    case type.DELETE_TIME_SLOT_TYPE_FAILURE:
        return {
          ...state,
          updateLoader: false,
        }

    default:
      return state;
    }
}
