import React from "react";
import { resetPasswordValidation } from "../../../validationSchema/authSchema";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import {passwordReset} from '../../../redux/actions/AuthActions/PasswordResetAction'
import {LOGIN_ENDPOINT} from '../../../Routing/routes'
const ResetPasswordForm = ({navigate}) => {
  const dispatch = useDispatch()
  const {loading} = useSelector(state=>state.passwordResetReducer)
  const {token} = useParams()

  const handleSubmit = (values, {resetForm}) => {
    const sendRequest = {
      token,
      ...values,
      onSuccessCallback: () => {
        navigate(LOGIN_ENDPOINT);
      }
    }
    dispatch(passwordReset(sendRequest))
  };

  return (
    <div className="flex justify-center items-center h-full w-auto bgClasGrad">
      <div className="rounded p-8 mb-4">
        <div>
          <h3 className="flex justify-center">Reset Password For</h3>
          <h1 className="tracking-normal text-red-600 font-bold text-3xl">
            Zipgrid Neo Console
          </h1>
          <div className="border border-1 border-black-700 m-5 opacity-1"></div>
        </div>

        <Formik
          initialValues={{
            password: '',
            confirmPassword: ''
          }}
          validationSchema={resetPasswordValidation}
          onSubmit= {handleSubmit}
        >
          {
            (formik)=>{
              return(
                <Form>
                  <div className="mb-4">
                    <Field
                      className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight 
                      italic focus:outline-none focus:shadow-outline ${
                        formik.touched.password && formik.errors.password ? "border-red-500" : ""
                      }`}
                      id="password"
                      type="password"
                      placeholder="New Password"
                      name='password'
                      autoComplete='off'
                    />
                    <div className="text-red-500 text-xs italic h-1">
                      <ErrorMessage name='password'/>
                    </div>
                  </div>

                  <div className="mb-5">
                    <Field
                      className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight italic
                      focus:outline-none focus:shadow-outline ${
                        formik.touched.confirmPassword && formik.errors.confirmPassword ? "border-red-500" : ""
                      }`}
                      id="confirmPassword"
                      type="password"
                      placeholder="Confirm Password"
                      name='confirmPassword'
                      autoComplete='off'
                    />
                    <div className="text-red-500 text-xs italic h-1">
                      <ErrorMessage name='confirmPassword'/>
                    </div>
                  </div>

                  <button
                    className={`bg-red-300  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full border-solid border-[#941A25] ${
                      !loading && (formik.dirty && formik.isValid) ? "bg-red-650 cursor-pointer" : "cursor-not-allowed"
                    }`}
                    disabled={loading || !(formik.dirty && formik.isValid)}
                    type="submit"
                  >
                    {
                      loading ?
                        <CircularProgress sx={{color: "white"}} size={17}/>
                        :
                        'Reset Password'
                    }
                    
                  </button>
                </Form>
              )
            }
          }
        </Formik>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
