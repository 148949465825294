import React, { useEffect } from "react";
import ListHeader from "../../../components/ListHeader/ListHeader";
import Search from "../../../components/SearchBox/Search";
import Layout from "../../Layout/Layout";
import { useDispatch } from "react-redux";
import { getDeletedReceipt } from "../../../redux/actions/FinanceActions/BillingReportsAction";
import { useSelector } from "react-redux";
import DeleteReceiptsTable from "./DeleteReceiptsTable";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { DELETE_RECEIPT_ENDPOINT } from "../../../Routing/routes";
import moment from "moment";

export const headCells = [
  { id: "sNo", label: "S No.", width: "45px" },
  { id: "date", label: "Date", sort: false, width: "77px" },
  { id: "unitNumber", label: "Unit", sort: true, width: "80px" },
  { id: "type", label: "Type", sort: true, width: "70px" },
  { id: "amount", label: "Amount", sort: true, width: "80px"   },
  { id: "userId", label: "User Ids", sort: true, width: "110px" },
  {
    id: "transactionRefNo",
    label: "Chq/Tran Ref No.",
    sort: true,
    width: "140px",
  },
  { id: "userType", label: "User Type", sort: true, width: "100px" },
  { id: "ip", label: "Ip Address", sort: true,width: "110px"  },
  { id: "status", label: "Status", sort: true, width: "90px" },
  { id: "action", label: "Action", sort: false,  },
];

const rows = [];

const DeleteReceipts = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading, deletedReceiptList} = useSelector(state=>state.billingReportsReducer)

  const handleSearch = (text)=>{
    if(text && text !== searchParams.get("search")){
      searchParams.delete("search")
      searchParams.delete('page')
      searchParams.delete('pageLimit')
      navigate({
        pathname: `${DELETE_RECEIPT_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["search", text]])}`,
      })
    } else {
      searchParams.delete("search")
      searchParams.delete('page')
      searchParams.delete('pageLimit')
      navigate({
        pathname: DELETE_RECEIPT_ENDPOINT,
        search: `?${createSearchParams([...searchParams.entries()])}`,
      })
    }
  }

  const getTableData =() =>{
    const data={
      societyId: loginData?.default_community?.community_id,
      page: searchParams.get("page") || 1,
      searchText: searchParams.get("search") || '',
      pageLimit: searchParams.get("pageLimit") || 10,
    }

    dispatch(getDeletedReceipt(data))
  }
  
  useEffect(()=>{
    getTableData()
  }, [searchParams])
  
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Delete Receipt">
          </ListHeader>
        </div>

        <div className="py-1 flex justify-between gap-2 my-1">
          <Search placeholder='Search by cheque or transaction' height="8" onclick={handleSearch} value={searchParams.get("search")} />
        </div>
        <div className="ml-1">
          <DeleteReceiptsTable
          loading={loading}
          data={deletedReceiptList?.data?.map((item, index)=>({
            //sNo: (((deletedReceiptList?.current - 1) * deletedReceiptList?.limit + 1)+index)?.toString()?.padStart(2, "0"),
            id: item?.enc_key,
            sNo: (index+1)?.toString()?.padStart(2, '0'),
            date: moment( item?.date, 'YYYY-MM-DD hh:mm:ss').format('DD-MM-YYYY') || '-',
            unitNumber: item?.unit || '-',
            type: item?.modeofpayment || '-',
            amount: item?.amount || '-',
            userId: item?.email || '-',
            transactionRefNo: item?.cheque_number || item?.transaction_number || '-',
            userType: item?.modified_type || '-',
            ip: item?.IpAddress || '-',
            status: item?.status || '-',
          }))}
          columns={headCells}
          checkbox={false}
          // pagination={false}
          pagination={deletedReceiptList?.data?.length}
          totalCount={deletedReceiptList?.paginate?.total_count}
          totalPages={deletedReceiptList?.paginate?.total_page}
          start={(deletedReceiptList?.paginate?.current) * deletedReceiptList?.paginate?.limit + 1}
          end={(deletedReceiptList?.paginate?.current) * deletedReceiptList?.paginate?.limit + deletedReceiptList?.data?.length}
          currentPage={parseInt(deletedReceiptList?.paginate?.current) + 1}
          rowsPerPage={searchParams.get("pageLimit") || 10}
          getTableData={getTableData}
        ></DeleteReceiptsTable>

        {!deletedReceiptList?.data?.length && !loading && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
        </div>
      </div>
    </Layout>
  );
};

export default DeleteReceipts;
