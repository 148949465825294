import React from "react";
import { useNavigate } from "react-router-dom";
import { Arrow } from "../../assets";
import Layout from "../../pages/Layout/Layout";
import { CONTACT_ENDPOINT } from "../../Routing/routes";

const ViewContactDetails = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(CONTACT_ENDPOINT);
  };
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg p-2">
        <div className="flex gap-2 border-b border-[#CCC] p-2">
          <button
            onClick={handleBackClick}
            className="text-sm font-semibold text-red-650 flex gap-2 justify-center items-center"
          >
            <span>
              <Arrow />
            </span>
            Back
          </button>
          <span className="border-r"></span>
          <h1 className="text-sm font-semibold">View Contact Details</h1>
        </div>
        <div className="flex gap-4 p-2">
          <div className="text-[#AAAAAA] font-semibold text-sm leading-9">
            <p>Category:</p>
            <p>Full Name:</p>
            <p>Working Shift:</p>
            <p>Phone Number:</p>
            <p>Email Address:</p>
            <p>Address:</p>
          </div>
          <div className="text-sm leading-9 text-[AAAAAA]">
            <p>Emergency Contact Category</p>
            <p>Rakesh</p>
            <p>Society Night staff</p>
            <p>9876543210</p>
            <p>rakeshjain01@gmail.com</p>
            <p>41/6, Manisha Nagar, Nr Central Swimming Pool, Mumbai, 456001</p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ViewContactDetails;
