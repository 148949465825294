
import * as types from '../index.types';

export function getBookingList(data) {
    return {
        type: types.GET_BOOKING_LIST,
        data
    };
}


export function getBookingStatusList(data) {
    return {
        type: types.GET_BOOKING_STATUS_LIST,
        data
    };
}


export function getBookingDetails(data) {
    return {
        type: types.GET_BOOKING_DETAILS,
        data
    };
}


export function makeBookingAdjustment(data) {
    return {
        type: types.MAKE_BOOKING_ADJUSTMENT,
        data
    };
}

export function getFacilityReport(data) {
    return {
        type: types.GET_FACILITY_REPORT,
        data
    };
}


export function getFacilityAuditLogs(data) {
    return {
        type: types.GET_ALL_FACILITY_AUDIT_LOG,
        data
    };
}


export function changeBookingStatus(data) {
    return {
        type: types.CHANGE_BOOKING_STATUS,
        data
    };
}

export function downloadFacilityReportExcelSheet(data) {
    return {
        type: types.DOWNLOAD_FACILITY_REPORT_EXCEL,
        data
    };
}

export function getBookingGLMappingList(data) {
    return {
        type: types.GET_BOOKING_GL_MAPPING_LIST,
        data
    };
}

export function getBookingBankList(data) {
    return {
        type: types.GET_BOOKING_BANK_ACCOUNT_LIST,
        data
    };
}

export function getBookingInvoice(data) {
    return {
        type: types.GET_BOOKING_INVOICE,
        data
    };
}

export function makeBookingPayment(data) {
    return {
        type: types.MAKE_BOOKING_PAYMENT,
        data
    };
}
