import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "../Modal/Modal";
import RangeDatePicker from "../DatePicker/RangeDatePicker";
import Dropdown2 from "../Dropdown/dropdown2";
import {TextInput} from '../InputFields/TextInput'

const AMCContractReportModal = ({ isOpen, onClose }) => {
  const [vendorName, setVendorName] = useState("");
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title="AMC Contract Report"
        onCancel={onClose}
        onConfirm={() => {
          // Handle Confirm (e.g., fetch data or perform actions)
          onClose();
        }}
      >
        <div className="flex flex-col">
          <div className="flex flex-row justify-between items-center mb-4">
            <label className="flex gap-2 items-center text-sm ">
              Vendor Category:
            </label>
            <Dropdown2
                options={[
                  "Carpenters",
                  "Electrician",
                  "Ac Repair",
                  "Food & Snacks Supplier",
                ]}
                placeholder="Select Category"
                className="text-xs"
                width="176px"
                height="36px"
                onSelect={(selectedItem) => {
                  // Handle the selected item here
                }}
              />
          </div>
          <div>
            <TextInput label='Vendor Name:'
              placeholder="Enter Vendor Name"
              className="w-44"
             />
          </div>
          <div className="flex flex-row justify-between items-center gap-6">
            <label className="flex gap-2 items-center text-sm">Date Range:</label>
            <RangeDatePicker className="h-9"/>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AMCContractReportModal;
