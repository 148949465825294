import React from "react";
import ListHeader from "../../../components/ListHeader/ListHeader";
import Layout from "../../Layout/Layout";
import Button from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import ButtonG from "../../../components/Button/ButtonG";
import { TextInput } from "../../../components/InputFields/TextInput";
import Dropdown2 from "../../../components/Dropdown/dropdown2";
import { societyStaffTypeOptions } from "../../../consts/DropDownOptions";
import {DatePickerComponent} from "../../../components/DatePicker/DatePicker";
const IssueDocument = () => {
  const navigate = useNavigate();
  const handleNavigation = (path) => () => {
    navigate(path);
  };
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Issue New Document">
            <ButtonG label="Cancel" onClick={handleNavigation(-1)} />
            <Button label="Next" onClick={""} />
          </ListHeader>
        </div>
        <div className="flex justify-between gap-4 mt-4 border-b border-[#CCC]">
          <table className="border-separate">
            <tr>
              <td className="text-sm w-56 pt-2.5 align-top">Letter</td>
              <td>
                <div className="mb-4">
                  <Dropdown2
                    options={societyStaffTypeOptions}
                    placeholder="Select Manager"
                    className=""
                    width="230px"
                    height="36px"
                    onSelect={(selectedItem) => {}}
                    //   {...(formik.values.subCategory === "" && {
                    //     value: null,
                    //   })}
                    name={"subCategory"}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="text-sm w-56 pt-2.5 align-top">Member Details</td>
              <td>
                <div className="mb-4 flex gap-3">
                  <Dropdown2
                    options={societyStaffTypeOptions}
                    placeholder="Select Wing"
                    className="whitespace-nowrap"
                    width="115px"
                    height="36px"
                    onSelect={(selectedItem) => {}}
                    //   {...(formik.values.subCategory === "" && {
                    //     value: null,
                    //   })}
                    name={"subCategory"}
                  />
                  <Dropdown2
                    options={societyStaffTypeOptions}
                    placeholder="Select Flat"
                    className="whitespace-nowrap"
                    width="105px"
                    height="36px"
                    onSelect={(selectedItem) => {}}
                    //   {...(formik.values.subCategory === "" && {
                    //     value: null,
                    //   })}
                    name={"subCategory"}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="text-sm w-56 pt-2.5 align-top">Member Name</td>
              <td>
                <div className="mb-4">
                  <Dropdown2
                    options={societyStaffTypeOptions}
                    placeholder="Select Member"
                    className=""
                    width="230px"
                    height="36px"
                    onSelect={(selectedItem) => {}}
                    //   {...(formik.values.subCategory === "" && {
                    //     value: null,
                    //   })}
                    name={"subCategory"}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="text-sm w-56 pt-2.5 align-top">Ref. Number</td>
              <td>
                <TextInput
                  label=""
                  placeholder="Enter Name"
                  className="w-[230px]"
                />
              </td>
            </tr>
          </table>
        </div>
        <div className="">
          <div className="text-sm font-semibold p-1 mt-2">Other Details</div>
          <table className="border-separate">
            <tr>
              <td className="text-sm w-56 pt-2.5 align-top">Since Date</td>
              <td>
                <div className="mb-4">
                  <DatePickerComponent
                    className="w-[230px] h-9 justify-between"
                    onDateChange={(selectedDate) => {
                      // setFieldValue('fromDate', selectedDate)
                    }}
                    name="fromDate"
                    // defaultValue={values.fromDate ? new Date(values.fromDate) : null}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="text-sm w-56 pt-2.5 align-top">
                Other Family Member Name
              </td>
              <td>
                <TextInput
                  label=""
                  placeholder="Enter Name"
                  className="w-[230px]"
                />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default IssueDocument;
