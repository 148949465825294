import React, { useState } from "react";
import Layout from "../../../Layout/Layout";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import UploadButton from "../../../../components/Button/UploadButton";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import ButtonIco from "../../../../components/Button/ButtonPrint";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../../components/Button/Button";
import { TRANSFER_FLAT_FORM_DOCUMENTS_ENDPOINT } from "../../../../Routing/routes";
import { useNavigate } from "react-router-dom";

const FlatDocument = () => {
  const navigate = useNavigate();
  const [selectedDocuments, setSelectedDocuments] = useState([
    "Notice of Intention to Transfer. [(Form No. 20 A)]",
    "Letter of Consent.",
    "Transfer Form along with Transfer Fee Rs. 50/- & Share Premium Amount up to Rs.25,000/-. [Form No. 21 (1)]",
    "Application for Membership along with Entrance Fee Rs. 10/-. [ Form No. 22.1 ]",
    "Declaration on Rs. 20/- Stamp Paper by the Transferee as per the provision of Urban Land Ceiling Act, 1976.[ Form No. 25 ]",
    "Undertaking on Rs. 20/- Stamp Paper to discharge the Liabilities of the society if the Prospective Member is a non-earning person (If Applicable)",
  ]);

  const handlePrint = () => {
    // window.open(`${""}?${createSearchParams([...searchParams.entries()])}`)
  }

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Flat List">
          <ButtonIco icon={faPrint} children="Print" onClick={handlePrint} />
          <span className="border-r border-[#CCC]"></span>
          <Button label="Next" className="h-8" onClick={handleNavigation(TRANSFER_FLAT_FORM_DOCUMENTS_ENDPOINT)} />
          </ListHeader>
        </div>
        <div className="border-b border-[#CCC] py-2 mb-2">
           <Checkbox /> Select All
        </div>
        <div className="space-y-4">
          {selectedDocuments.map((doc, index) => (
            <>
              <div key={index} className="flex items-center">
                <Checkbox />
                <label className="ml-3 block text-gray-700 font-medium">
                  {doc}
                </label>
              </div>
              <div className="ml-7">
              <UploadButton showFileName="true" />
              </div>
            </>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default FlatDocument;
