import React, { useEffect } from "react";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import Layout from "../../../Layout/Layout";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getSavedNoticeDetails } from "../../../../redux/actions/ComplianceActions/MeetingsAction";
import { useSelector } from "react-redux";
import moment from "moment";
import parse from 'html-react-parser';
import Loader from "../../../../components/Loader/Loader";

function ViewSavedNotice() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {id, status} = useParams()
  const {loading, savedNoticeDetails} = useSelector(state=>state.meetingsReducer)
  const {loginData} = useSelector(state=>state.loginReducer)

  useEffect(()=>{
    dispatch(getSavedNoticeDetails({noticeId: id, status}))
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack
            title="View Saved Notice"
            onClick={() => {
              navigate(-1);
            }}
          >
          </ListHeaderBack>
        </div>
        {
          loading ?
          <Loader/>
          :
          <div className="p-5 px-9 gap-2 my-1">
            <div className="text-base flex justify-between">
              <span className="text-left">TelePhone No.: {savedNoticeDetails?.telephone || '-'}</span>
              <span className="text-right">Date: {savedNoticeDetails?.notice_on ? moment(savedNoticeDetails?.notice_on).format('DD-MM-YYYY') : '-'}</span>
            </div>
            <div className="mt-6 text-center">
              <p className="text-[24px] font-bold text-red-650">
                {loginData?.default_community?.community_name}
              </p>
              <div className="underline font-bold text-sm">(Reg No.: {loginData?.default_community?.regn_no} &nbsp; Dated: {moment(new Date(loginData?.default_community?.date_of_regn))?.format('DD-MM-YYYY')})</div>
              <div className="font-bold mb-5 text-sm">{loginData?.default_community?.office_address} </div>
            </div>
            <div className="border-[#222] border-b my-6" />
            <div className="text-lg font-bold text-center mt-7">
              Notice
            </div>
            <div className="mt-5 text-left text-[13px]">
              <p className="mb-3">Dear members,</p>

              <p className="mb-3">
              Notice is hereby given to all members that the {savedNoticeDetails?.notice_desc || '-'} {savedNoticeDetails?.meeting_type || '-'} of The {loginData?.default_community?.community_name} will be held on {savedNoticeDetails?.notice_day}, the {moment(new Date(loginData?.default_community?.date_of_regn))?.format('DD-MM-YYYY')} at {savedNoticeDetails?.notice_time || '-'} at {savedNoticeDetails?.notice_venue}, to transact the following business.
              </p>

              <p className="font-semibold">Agenda</p>
              <p className="mb-6">
                {savedNoticeDetails?.agenda || '-'}
              </p>
            </div>
              <div className="text-right text-[13px]">
              <p className="">By Order Of The Managing Committee</p>
              <p className="font-semibold">{loginData?.default_community?.community_name}</p>
              </div>
            <div className="text-right font-semibold text-[13px] mt-8">
              <p className="font-normal">{savedNoticeDetails?.notice_name || '-'}</p>
              Honorary Secretary
            </div>

            <div className="text-[13px] text-left">
            <p className="font-semibold">Notes</p>
              <p className="mb-6">
                * The said building has ground Plus Three storey without the
                facility of lift.
              </p>

              <p className="font-semibold">Receiver</p>
              <p className="mb-6"></p>

              <p className="font-semibold">Member</p>
              <p className="mb-6">
              Vinesh Sandoria, Test User, Dheeraj Karda, Diapnshi J, Test joint, Himanshi Mehta
              </p>

              <p className="font-semibold">Chairman</p>
              <p className="mb-6">
              Vinesh Sandoria, Himanshi Mehta
              </p>

              <p className="font-semibold">Reminder</p>
              <p className="flex">
              <span className="font-semibold">Date: </span> 
              <span>11-04-2023</span>
              </p>
              <p>
              <span className="font-semibold">Time: </span> 
              <span>11-04-2023</span>
              </p>
            </div>
          </div>
        }
      </div>
    </Layout>
  );
}

export default ViewSavedNotice;
