import * as types from '../index.types';

export function getFlatMemberList(data) {
    return {
        type: types.GET_FLAT_MEMBER_LIST,
        data
    };
}

export function getVisitorOrVehicleDashboardData(data) {
    return {
        type: types.GET_VISITOR_OR_VEHICLE_MANAGEMENT_DASHBOARD_DATA,
        data
    };
}

export function getVisitorVehicleLog(data) {
    return {
        type: types.GET_VISITOR_VEHICLE_LOG,
        data
    };
}

export function downloadVisitorVehicleLog(data) {
    return {
        type: types.DOWNLOAD_VISITOR_VEHICLE_LOG,
        data
    };
}

export function getSocietyEntryGate(data) {
    return {
        type: types.GET_SOCIETY_ENTRY_GATE,
        data
    };
}

export function addUpdateSocietyEntryGateReq(data) {
    return {
        type: types.ADD_UPDATE_SOCIETY_ENTRY_GATE,
        data
    };
}

export function deleteSocietyEntryGate(data) {
    return {
        type: types.DELETE_SOCIETY_ENTRY_GATE,
        data
    };
}

export function getGateAccessData(data) {
    return {
        type: types.GET_GATE_ACCESS_DATA,
        data
    };
}

export function addGateAccessData(data) {
    return {
        type: types.ADD_UPDATE_GATE_ACCESS_DATA,
        data
    };
}

export function getVisitorLog(data) {
    return {
        type: types.GET_VISITOR_LOG,
        data
    };
}

export function downloadVisitorLog(data) {
    return {
        type: types.DOWNLOAD_VISITOR_LOG,
        data
    };
}

export function getFrequentVisitorList(data) {
    return {
        type: types.GET_FREQUENT_VISITOR_LIST,
        data
    };
}

export function getExpectedVisitorList(data) {
    return {
        type: types.GET_EXPECTED_VISITOR_LIST,
        data
    };
}

export function updateVisitorStatus(data) {
    return {
        type: types.UPDATE_VISITOR_STATUS,
        data
    };
}

export function getStaffDetailsByStaffCode(data) {
    return {
        type: types.GET_STAFF_DETAILS_BY_STAFF_CODE,
        data
    };
}

export function getVistorPurposeList(data) {
    return {
        type: types.GET_VISITOR_PURPOSE_LIST,
        data
    };
}

export function deleteVistorPurposeReq(data) {
    return {
        type: types.DELETE_VISITOR_PURPOSE_LIST,
        data
    };
}

export function getVistorData(data) {
    return {
        type: types.GET_VISITORS_DATA,
        data
    };
}

export function clearVisitorData(data) {
    return {
        type: types.CLEAR_GET_VISITOR_PASS_DATA,
        data
    };
}

export function sendOTPToVisitor(data) {
    return {
        type: types.SEND_OTP_TO_VISITOR,
        data
    };
}

export function adNewVisitor(data) {
    return {
        type: types.ADD_NEW_VISITOR,
        data
    };
}

export function staffCheckIn(data) {
    return {
        type: types.STAFF_CHECKIN,
        data
    };
}

export function getVisitorPassData(data) {
    return {
        type: types.GET_VISITOR_PASS_DATA,
        data
    };
}
