import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../../../Layout/Layout";
import ListHeaderBack from "../../../../../components/ListHeader/ListHeaderBack";
import { useDispatch, useSelector } from "react-redux";
import { getMaintenanceStaffDetails } from "../../../../../redux/actions/SetupRecordsActions/StaffManagementActions/MaintenanceAgencyAction";
import { Link } from "react-router-dom";
import Loader from "../../../../../components/Loader/Loader";

const MaintenanceStaffData = [
  {
    key: "name",
    label: "Name of Staff",
    value: "item.ticket",
  },
  {
    key: "title",
    label: "Title",
    value: "0",
  },
  {
    key: "responsibility",
    label: "Responsibility",
    value: "18 Days 21 Hours 31 Minutes 23 Seconds0",
  },
  {
    key: "contact",
    label: "Contact No",
    value: "P-Block - TPP001",
  },
  {
    key: "email",
    label: "Email (If Any)",
    value: "Others",
  },
  {
    key: "other_details",
    label: "Other Details",
    value: "",
  },
  {
    key: "image",
    label: "Image Upload (If Any)",
    value: "00-00-0000",
  },
  {
    key: "agency_name",
    label: "Agency Name",
    value: "00-00-0000",
  },
];

function ViewMaintenanceStaffDetails() {
  const navigate = useNavigate();
  const dispach =useDispatch()
  const {id} = useParams()
  const {loading, maintenanceStaffDetails} = useSelector(state=> state.maintenanceStaffReducer)

  useEffect(()=>{
    dispach(getMaintenanceStaffDetails({staffId: id}))
  },[])
 
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack
            title="View Maintenance Staff"
            onClick={() => {
              navigate(-1);
            }}
          ></ListHeaderBack>
        </div>
        <div className="py-1 gap-2 my-1">
          {
            loading && maintenanceStaffDetails?.agency_staff_id?.toString() !== id ?
            <Loader/>
            :
            <table className="table-auto w-full">
              <tbody>
                {MaintenanceStaffData?.map((item) => (
                  <tr className="text-[#222222] text-sm">
                    <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                      {item.label}:
                    </td>
                    <td className="py-2">
                      {
                        (item.key==='contact') ?
                          (`+${maintenanceStaffDetails?.['mobile_country_code']}-${maintenanceStaffDetails?.['contact']}` || '-') 
                          :
                          (item.key==='image') ?
                            (maintenanceStaffDetails?.['imageURL'] ? <Link to={maintenanceStaffDetails?.['imageURL']} target="_blank" className="ms-1 underline text-xs text-blue-500" >Click here to view</Link> : '-')
                            :
                            (maintenanceStaffDetails?.[item.key] || "-")
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          }
        </div>
      </div>
    </Layout>
  );
}

export default ViewMaintenanceStaffDetails;
