import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../../Layout/Layout";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import ViewEmployeeRoleAccordion from "./ViewEmployeeRoleAccordion";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getEmployeeRolesDetails } from "../../../../redux/actions/SetupRecordsActions/ManageRolesAndRightsActions/ManageRolesAndRightsAction";
import Loader from "../../../../components/Loader/Loader";

function ViewEmployeeRoles({ action }) {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {loading, employeeRoleDetails} = useSelector(state=>state.manageRoleAndRightsReducer)
  const {id} = useParams()

  useEffect(()=>{
    dispatch(getEmployeeRolesDetails({employeeRoleId: id}))
  }, [])


  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack
            title={"View Employee Roles"}
            onClick={() => {
              navigate(-1);
            }}
          >
          </ListHeaderBack>
        </div>
        <div className="p-1 py-3 gap-2 m-1">
          {
            loading && employeeRoleDetails?.emp_role_id?.toString() !== id ?
            <Loader/>
            :
            <>
              <div className="w-1/2 text-sm">
                <div className="flex gap-7">
                    <p className="text-[#AAA] text-sm font-semibold">Role Title:</p>
                    <p>{employeeRoleDetails?.title}</p>
                </div>
              </div>
              <div className="border-b border-[#CCC] my-4"></div>
              <div>
                <ViewEmployeeRoleAccordion accessSetting={employeeRoleDetails?.access} />
              </div>
            </>
          }
        </div>
      </div>
    </Layout>
  );
}

export default ViewEmployeeRoles;
