import { call, put, takeLatest } from 'redux-saga/effects'
import {notify} from '../../../../utils/notification'
import * as types from '../../../actions/index.types'
import * as req from '../../../../axios/request/FinanceReqs/BillingManagementReqs/ManageAdvanceNoteReqs'

function* getManageAdvanceList(action) {
    try {
        const result = yield call(req.getManageAdvanceListReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_ADVANCE_NOTE_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_ADVANCE_NOTE_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getAdvanceNoteDetails(action) {
    try {
        const result = yield call(req.getAdvanceNoteDetailsReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_ADVANCE_NOTE_DETAILS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_ADVANCE_NOTE_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* addAdvanceNote(action) {
    try {
        const result = yield call(req.addAdvanceNoteReq, action);
        if (result.success === true) {
            notify('success', result.message)
            action.data?.onSuccessCallback?.() 
            yield put({ type: types.ADD_ADVANCE_NOTE_SUCCESS, payload: result.data});
           
        } else {
            yield put({ type: types.ADD_ADVANCE_NOTE_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateAdvanceNote(action) {
    try {
        const result = yield call(req.updateAdvanceNoteReq, action);
        if (result.success === true) {
            notify('success', result.message)
            yield put({ type: types.UPDATE_ADVANCE_NOTE_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.UPDATE_ADVANCE_NOTE_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* deleteAdvanceNote(action) {
    try {
        const result = yield call(req.deleteAdvanceNoteReq, action);
        if (result.success === true) {
            notify('success', result.message)
            action.data?.onSuccessCallback?.()
            yield put({ type: types.DELETE_ADVANCE_NOTE_SUCCESS, payload: action.data});  
            const newList = yield call(req.getManageAdvanceListReq, action)
            if (newList.success === true)
                yield put({ type: types.GET_ADVANCE_NOTE_LIST_SUCCESS, payload: newList.data});  
        } else {
            yield put({ type: types.DELETE_ADVANCE_NOTE_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}


function* printAdvancetNoteInvoice(action) {
    try {
        const result = yield call(req.printAdvancetNoteInvoiceReq, action);
        yield put({ type: types.PRINT_ADVANCE_NOTE_RECIEPT_SUCCESS, payload: result});  
    } catch (e) {
        yield put({ type: types.PRINT_ADVANCE_NOTE_RECIEPT_FAILURE, message: e });
        console.log(e)
    }
}


function* getNonMemberList(action) {
    try {
        const result = yield call(req.getNonMemberListReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_NON_MEMBER_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_NON_MEMBER_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* uploadAdvanceNoteExcel(action) {
    try {
        const result = yield call(req.uploadAdvanceNoteExcelReq, action);
        if (result.success === true) {
            yield put({ type: types.UPLOAD_ADVANCE_NOTE_EXCEL_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.UPLOAD_ADVANCE_NOTE_EXCEL_FAILURE, message: result?.response?.data?.message });
        }
        action.data.onRequestEndCallback?.()
    } catch (e) {
        console.log(e)
    }
}

function* saveAdvanceNoteExcel(action) {
    try {
        const result = yield call(req.saveAdvanceNoteExcelReq, action);
        if (result.success === true) {
            notify('success', result.message)
            yield put({ type: types.SAVE_ADVANCE_NOTE_EXCEL_SUCCESS, payload: result.data});  
            action.data?.onSuccessCallback?.()
        } else {
            yield put({ type: types.SAVE_ADVANCE_NOTE_EXCEL_FAILURE, message: result?.response?.data?.message });
        }
        action.data.onRequestEndCallback?.()
    } catch (e) {
        console.log(e)
    }
}
function* downloadAdvanceExcelSample(action) {
    try {
        const result = yield call(req.downloadAdvanceExcelSampleReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
        }else{
            notify('Unable to download sheet.')
        }
    } catch (e) {
        console.log(e)
    }
}

export default function* manageAdvanceNoteSaga() {
    yield takeLatest(types.GET_ADVANCE_NOTE_LIST, getManageAdvanceList);
    yield takeLatest(types.GET_ADVANCE_NOTE_DETAILS, getAdvanceNoteDetails);
    yield takeLatest(types.ADD_ADVANCE_NOTE, addAdvanceNote);
    yield takeLatest(types.UPDATE_ADVANCE_NOTE, updateAdvanceNote);
    yield takeLatest(types.DELETE_ADVANCE_NOTE, deleteAdvanceNote);
    yield takeLatest(types.PRINT_ADVANCE_NOTE_RECIEPT, printAdvancetNoteInvoice);
    yield takeLatest(types.GET_NON_MEMBER_LIST, getNonMemberList);
    yield takeLatest(types.UPLOAD_ADVANCE_NOTE_EXCEL, uploadAdvanceNoteExcel);
    yield takeLatest(types.SAVE_ADVANCE_NOTE_EXCEL, saveAdvanceNoteExcel);
    yield takeLatest(types.DOWNLOAD_ADVANCE_EXCEL_SAMPLE, downloadAdvanceExcelSample);
}