import React, { useEffect, useState } from "react";
import Layout from "../../../Layout/Layout";
import RangeDatePicker from "../../../../components/DatePicker/RangeDatePicker";
import FilterTextField from "../../../../components/InputFields/FilterTextFields";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import { TextInput } from "../../../../components/InputFields/TextInput";
import Search from "../../../../components/SearchBox/Search";
import Button from "../../../../components/Button/Button";
import ButtonIco from "../../../../components/Button/ButtonPrint";
import { DownloadExt, UploadEx } from "../../../../assets";
import UploadTenantExcelModal from "../../../../components/Modal/UploadTenantExcelModal";
import UploadDebitNoteExcelModal from "../../../../components/Modal/UploadDebitNoteExcelUploadModal";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  ADD_DEBIT_NOTE_ENDPOINT,
  MAKE_PAYMENT_DEBIT_NOTE_MULTI_ENDPOINT,
  MANAGE_DEBIT_CURRENT_ENDPOINT,
  MANAGE_DEBIT_DRAFT_ENDPOINT,
  MANAGE_DEBIT_PREVIOUS_ENDPOINT,
} from "../../../../Routing/routes";
import ManageDebitCurrentTable from "../../../../components/TableComponent/ManageDebitCurrentTable";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import moment from "moment";
import { downloadExcel } from "../../../../utils/helpers/downloadMedia";
import {
  getDebitNoteCurrent,
  uploadDebitNoteCurrentExcel,
  downloadDebitNoteCurrentPayment,
  uploadDebitNoteCurrentPayment,
} from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageDebitNoteAction";
import ButtonG from "../../../../components/Button/ButtonG";
import {
  getFlatDetails,
  getWingsAndFlatDetails,
} from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import { getInvoiceTypeList } from "../../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";

const headingsInvoice = [
  { id: "sNo", label: "SNo.", sort: false, width: "40px" },
  { id: "invoiceNo", label: "Bill Number", sort: true, width: "160px" },
  { id: "memberDetails", label: "Member Details", sort: true, width: "150px" },
  { id: "invoiceDate", label: "Bill Date", sort: true, width: "100px" },
  {
    id: "generationDate",
    label: "Generation Date",
    sort: true,
    width: "130px",
  },
  { id: "amount", label: "Amount", sort: true, width: "120px" },
  { id: "status", label: "Status", sort: true, width: "100px" },
  {
    id: "action",
    label: "Action",
    sort: false,
  },
];

const ManageDebitNoteCurrent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loginData } = useSelector((state) => state.loginReducer);

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [file, setFile] = useState(null);

  const [defaultFromValue, setDefaultFromValue] = useState("");
  const [defaultToValue, setDefaultToValue] = useState("");
  const [invoiceType, setInvoicetype] = useState(1);

  const { manageDebitNoteCurrent, loading, updateLoader, uploadLoader } =
    useSelector((state) => state.manageDebitNoteReducer);
  const { wingsData, flatsData } = useSelector((state) => state.wingsReducer);

  const { typeLists } = useSelector((state) => state.invoiceSettingReducer);
  const [invoiceTypeList, setInvoiceTypeList] = useState([]);
  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleOpenUploadModal = () => {
    setIsUploadModalOpen(true);
  };
  const handleCloseUploadModal = () => {
    setIsUploadModalOpen(false);
  };
  const onUpload = () => {
    let data = {
      societyId: loginData?.default_community?.community_id,
      invoiceType: invoiceType,
      file,
      onSuccessCallback: (result) => {
        if (result?.data.length > 0) {
          navigate(MAKE_PAYMENT_DEBIT_NOTE_MULTI_ENDPOINT, {
            state: { data: result.data },
          });
        }

        navigate(MANAGE_DEBIT_CURRENT_ENDPOINT);
      },
      onRequestEndCallback: () => {
        setFile(null);
        setIsUploadModalOpen(false);
      },
    };
    dispatch(uploadDebitNoteCurrentPayment(data));
  };

  const handleDownloadExcel = () => {
    const onSucessCallback = (data) => {
      downloadExcel({ data, fileName: "societydebitpayment.xlsx" });
    };
    const data = {
      societyId: loginData?.default_community?.community_id,
      onSucessCallback,
    };
    dispatch(downloadDebitNoteCurrentPayment(data));
  };
  const handelSelectInvoiceType = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("typeid");
    navigate({
      pathname: `${MANAGE_DEBIT_CURRENT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["typeid", selectedItem.value],
      ])}`,
    });
  };
  const handleSearch = (text) => {
    if (text && text !== searchParams.get("subscriber_name")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("subscriber_name");
      navigate({
        pathname: `${MANAGE_DEBIT_CURRENT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["subscriber_name", text],
        ])}`,
      });
    } else if (!text) {
      searchParams.delete("subscriber_name");
      navigate({
        pathname: `${MANAGE_DEBIT_CURRENT_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries()])}`,
      });
    }
  };
  const handeleSearchBy = (text) => {
    if (text && text !== searchParams.get("searchBy")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("searchBy");
      navigate({
        pathname: `${MANAGE_DEBIT_CURRENT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["searchBy", text.value],
        ])}`,
      });
    }
  };
  const handleAmountChange = (clear = false) => {
    if (clear) {
      searchParams.delete("to_amount");
      searchParams.delete("from_amount");
      setDefaultFromValue("");
      setDefaultToValue("");
      navigate({
        pathname: `${MANAGE_DEBIT_CURRENT_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries()])}`,
      });
    } else {
      searchParams.delete("to_amount");
      searchParams.delete("from_amount");
      navigate({
        pathname: `${MANAGE_DEBIT_CURRENT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["from_amount", defaultFromValue],
          ["to_amount", defaultToValue],
        ])}`,
      });
    }
  };

  const handelSelectStatus = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("status");

    navigate({
      pathname: `${MANAGE_DEBIT_CURRENT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["status", selectedItem.value],
      ])}`,
    });
  };
  const handelSelectBillType = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("status");
    searchParams.delete("bill_type");

    navigate({
      pathname: `${MANAGE_DEBIT_CURRENT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["bill_type", selectedItem.value],
      ])}`,
    });
  };
  const handleSelectDebitorType = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("status");
    searchParams.delete("debitor_type");

    navigate({
      pathname: `${MANAGE_DEBIT_CURRENT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["debitor_type", selectedItem.value],
      ])}`,
    });
  };
  const handleSelectWing = (selectedItem) => {
    if (selectedItem.value?.toString() !== searchParams.get("wingId")) {
      dispatch(
        getFlatDetails({
          societyId: loginData?.default_community?.community_id,
          wingId: selectedItem?.value,
        })
      );
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("wingId");
      searchParams.delete("flatId");
      navigate({
        pathname: `${MANAGE_DEBIT_CURRENT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["wingId", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleSelectFlat = (selectedItem) => {
    if (selectedItem.value?.toString() !== searchParams.get("flatId")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("flatId");
      navigate({
        pathname: `${MANAGE_DEBIT_CURRENT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["flatId", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleStartDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !==
      searchParams.get("startDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("startDate");
      navigate({
        pathname: `${MANAGE_DEBIT_CURRENT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["startDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleEndDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !== searchParams.get("endDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("endDate");
      navigate({
        pathname: `${MANAGE_DEBIT_CURRENT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["endDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleClear = () => {
    setDefaultFromValue("");
    setDefaultToValue("");
    navigate(`${MANAGE_DEBIT_CURRENT_ENDPOINT}?searchBy=invoice_date`);
  };

  useEffect(() => {
    let societyId = loginData?.default_community?.community_id;
    dispatch(getWingsAndFlatDetails({ societyId }));
    if (searchParams.get("wingId")) {
      dispatch(
        getFlatDetails({ societyId, wingId: searchParams.get("wingId") })
      );
    }
    dispatch(
      getInvoiceTypeList({
        societyId: loginData?.default_community?.community_id,
      })
    );
    if (!searchParams.get("searchBy")) {
      navigate({
        pathname: `${MANAGE_DEBIT_CURRENT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["searchBy", "invoice_date"],
        ])}`,
      });
    }
    setDefaultFromValue("");
    setDefaultToValue("");
  }, []);

  const getTableData = () => {
    dispatch(
      getDebitNoteCurrent({
        societyId: loginData?.default_community?.community_id,
        page: searchParams.get("page") || 0,
        pageLimit: searchParams.get("pageLimit") || 10,
        invoiceno: searchParams.get("invoiceno") || "",
        subscriber_name: searchParams.get("subscriber_name") || "",

        searchBy: searchParams.get("searchBy") || "",
        from_amount: searchParams.get("from_amount") || "",
        to_amount: searchParams.get("to_amount") || "",
        status: searchParams.get("status") || "",
        from_date: searchParams.get("startDate") || "",
        to_date: searchParams.get("endDate") || "",
        wingId: searchParams.get("wingId") || "",
        flatId: searchParams.get("flatId") || "",
        bill_type: searchParams.get("bill_type") || "",
        debitor_type: searchParams.get("debitor_type") || "",
        InvoiceType: searchParams.get("typeid") || "",
      })
    );
  };
  useEffect(() => {
    getTableData();
  }, [searchParams]);
  useEffect(() => {
    if (typeLists) {
      setInvoiceTypeList(typeLists);
    }
  }, [typeLists]);
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Debit Note - Current">
            <div className="flex text-sm gap-3">
              <button
                type="button"
                onClick={handleOpenUploadModal}
                className="flex text-red-650 border border-red-650 bg-white text-xs px-2 font-semibold items-center whitespace-nowrap rounded-lg"
              >
                <UploadEx className="mr-2 fill-red-650" /> Upload Payment Excel
              </button>
              {/* <ButtonIco
                type="button"
                onClick={handleDownloadExcel}
                icon={DownloadExt}
                children="Download Payment Excel"
              /> */}
              <div className="border-r border-[#CCC]"></div>
              <Button
                label="Previous"
                className="h-8"
                onClick={handleNavigation(MANAGE_DEBIT_PREVIOUS_ENDPOINT)}
              />
              <Button
                label="Draft"
                className="h-8 px-4"
                onClick={handleNavigation(MANAGE_DEBIT_DRAFT_ENDPOINT)}
              />
              <div className="border-r border-[#CCC]"></div>
              {/* <Button
                label="Upload Debit Note"
                onClick={handleNavigation("")}
              /> */}
              <Button
                label="Add Debit Note"
                onClick={handleNavigation(ADD_DEBIT_NOTE_ENDPOINT)}
              />
            </div>
          </ListHeader>
        </div>

        <div className="p-1 flex flex-row justify-between items-center gap-4 mt-1">
          <Search
            height="8"
            onclick={handleSearch}
            value={searchParams.get("subscriber_name")}
            placeholder="Search..."
          />
          <div className="flex justify-between items-center gap-3">
            <div className="text-sm">Invoice Type</div>
            <Dropdown2
              options={
                invoiceTypeList
                  ? invoiceTypeList?.map((item) => ({
                      label: item?.invoice_title,
                      value: item?.id,
                    }))
                  : []
              }
              placeholder="Invoice type"
              className="text-[11px] ms-4"
              width="172px"
              height="32px"
              onSelect={(selectedItem) => {
                handelSelectInvoiceType(selectedItem);
              }}
              value={
                searchParams.get("typeid") && {
                  value: searchParams.get("typeid"),
                  label: invoiceTypeList
                    ? invoiceTypeList?.find(
                        (item) =>
                          item?.id === parseInt(searchParams.get("typeid"))
                      )?.invoice_title
                    : "",
                }
              }
            />
          </div>
          <div className="flex justify-between items-center gap-3">
            <div className="text-sm">Search By</div>
            <Dropdown2
              options={
                manageDebitNoteCurrent?.data?.date_filter
                  ? Object.entries(
                      manageDebitNoteCurrent?.data?.date_filter
                    )?.map((item) => ({
                      label: item?.[1],
                      value: item?.[0],
                    }))
                  : []
              }
              placeholder="Invoice Date"
              className="text-[11px] ms-4"
              width="160px"
              height="32px"
              onSelect={(selectedItem) => {
                handeleSearchBy(selectedItem);
              }}
              value={
                searchParams.get("searchBy") && {
                  value: searchParams.get("searchBy"),
                  label:
                    manageDebitNoteCurrent?.data?.date_filter?.[
                      searchParams.get("searchBy")
                    ],
                }
              }
            />
            {/* <div className="border-r border-[#CCC] h-full"></div>

           
            <div className="border-r border-[#CCC] h-full"></div> */}

            <div className="flex justify-between gap-3 items-center">
              <RangeDatePicker
                className={"w-[190px] h-8"}
                defaultStartValue={
                  searchParams.get("startDate")
                    ? new Date(searchParams.get("startDate"))
                    : ""
                }
                defaultEndValue={
                  searchParams.get("endDate")
                    ? new Date(searchParams.get("endDate"))
                    : ""
                }
                onStartDateChange={handleStartDate}
                onEndDateChange={handleEndDate}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2 p-2">
          <Dropdown2
            options={
              wingsData?.map((item) => ({
                value: item?.wing_id,
                label: item?.number,
              })) || []
            }
            placeholder="Select Wing"
            className="text-[11px] ms-4"
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              handleSelectWing(selectedItem);
            }}
            value={
              searchParams.get("wingId") && {
                value: searchParams.get("wingId"),
                label: wingsData?.find(
                  (item) =>
                    item.wing_id?.toString() === searchParams.get("wingId")
                )?.number,
              }
            }
          />
          <Dropdown2
            options={
              (searchParams.get("wingId") &&
                flatsData?.manage_flat_details?.map((item) => ({
                  value: item?.flat_id,
                  label: item?.flat_no,
                }))) ||
              []
            }
            placeholder="Select Flat"
            className="text-[11px] ms-4"
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              handleSelectFlat(selectedItem);
            }}
            value={
              searchParams.get("flatId") && {
                value: searchParams.get("flatId"),
                label: flatsData?.manage_flat_details?.find(
                  (item) =>
                    item.flat_id?.toString() === searchParams.get("flatId")
                )?.flat_no,
              }
            }
          />
          <Dropdown2
            options={
              manageDebitNoteCurrent?.data?.bill_status
                ? Object.entries(
                    manageDebitNoteCurrent?.data?.bill_status
                  )?.map((item) => ({
                    label: item?.[1],
                    value: item?.[0],
                  }))
                : []
            }
            placeholder="Select Status"
            className="text-[11px] ms-4"
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              handelSelectStatus(selectedItem);
            }}
            value={
              searchParams.get("status") && {
                value: searchParams.get("status"),
                label:
                  manageDebitNoteCurrent?.data?.bill_status?.[
                    searchParams.get("status")
                  ],
              }
            }
          />
          <Dropdown2
            options={
              manageDebitNoteCurrent?.data?.invoice_type
                ? Object.entries(
                    manageDebitNoteCurrent?.data?.invoice_type
                  )?.map((item) => ({
                    label: item?.[1],
                    value: item?.[0],
                  }))
                : []
            }
            placeholder="Select Bill Type"
            className="text-[11px] ms-4"
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              handelSelectBillType(selectedItem);
            }}
            value={
              searchParams.get("bill_type") && {
                value: searchParams.get("bill_type"),
                label:
                  manageDebitNoteCurrent?.data?.invoice_type?.[
                    searchParams.get("bill_type")
                  ],
              }
            }
          />
          <Dropdown2
            options={
              manageDebitNoteCurrent?.data?.debitor_type
                ? Object.entries(
                    manageDebitNoteCurrent?.data?.debitor_type
                  )?.map((item) => ({
                    label: item?.[1],
                    value: item?.[0],
                  }))
                : []
            }
            placeholder="Select Debitor Type"
            className="text-[11px] ms-4"
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              handleSelectDebitorType(selectedItem);
            }}
            value={
              searchParams.get("debitor_type") && {
                value: searchParams.get("debitor_type"),
                label:
                  manageDebitNoteCurrent?.data?.debitor_type?.[
                    searchParams.get("debitor_type")
                  ],
              }
            }
          />
          <ButtonG label="Clear" className="h-8" onClick={handleClear} />
        </div>
        <div>
          <div className="flex justify-end gap-3 items-center my-2">
            <div className="text-sm">Due Filter</div>
            <FilterTextField
              defaultFromValue={defaultFromValue && defaultFromValue}
              defaultToValue={defaultToValue && defaultToValue}
              onAmountFromChange={(e) => {
                setDefaultFromValue(e.target.value);
              }}
              onAmountToChange={(e) => {
                setDefaultToValue(e.target.value);
              }}
            />
            <Button
              label="Search"
              type="button"
              onClick={() => {
                handleAmountChange();
              }}
              className={`${
                !defaultFromValue || !defaultToValue ? "opacity-50" : ""
              }`}
              disabled={!defaultFromValue || !defaultToValue}
            />
            <Button
              label="Reset"
              type="button"
              className={`${
                !defaultFromValue || !defaultToValue ? "opacity-50" : ""
              }`}
              onClick={() => handleAmountChange(true)}
              disabled={!defaultFromValue || !defaultToValue}
            />
          </div>
        </div>
        <div>
          <ManageDebitCurrentTable
            loading={loading}
            data={manageDebitNoteCurrent?.data?.debitnote_details?.map(
              (item, index) => ({
                sNo:
                  manageDebitNoteCurrent?.data?.paginate?.current *
                    manageDebitNoteCurrent?.data?.paginate?.limit +
                  1 +
                  index,
                id: item.id,
                invoiceNo: item?.invoice_no,
                memberDetails: item?.subscriber_name,
                wingNo: item?.wing?.number,
                flatNo: item?.flat?.flat_no,
                invoiceDate: item.invoice_date
                  ? moment(new Date(item?.invoice_date))?.format("DD-MM-YYYY")
                  : "-",
                generationDate: item?.CreatedOn
                  ? moment(new Date(item?.CreatedOn))?.format("DD-MM-YYYY")
                  : "-",
                amount: item.grandtotal,
                amountPaid: item.paid_amount,
                amountDue: item.due_amount,
                status: item.row_status,
                remarks: item.remarks,
                ismakePayment: item.ismakePayment ? item.ismakePayment : "",
                paymentLink: item?.paymentLink,
                payid: item.payments
                  ? item.payments.map((payment) => payment.id)
                  : [],
              })
            )}
            columns={headingsInvoice}
            checkbox={true}
            pagination={manageDebitNoteCurrent?.data?.debitnote_details?.length}
            totalCount={manageDebitNoteCurrent?.data?.paginate?.total_count}
            totalPages={manageDebitNoteCurrent?.data?.paginate?.total_page}
            start={
              manageDebitNoteCurrent?.data?.paginate?.current *
                manageDebitNoteCurrent?.data?.paginate?.limit +
              1
            }
            end={
              manageDebitNoteCurrent?.data?.paginate?.current *
                manageDebitNoteCurrent?.data?.paginate?.limit +
              manageDebitNoteCurrent?.data?.debitnote_details?.length
            }
            currentPage={parseInt(
              manageDebitNoteCurrent?.data?.paginate?.current
            )}
            rowsPerPage={searchParams.get("pageLimit") || 10}
            getTableData={getTableData}
            selected={selected}
            setSelected={setSelected}
          ></ManageDebitCurrentTable>

          {!manageDebitNoteCurrent?.data?.debitnote_details?.length &&
            !loading && (
              <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
                No Records
              </div>
            )}
          {isUploadModalOpen && (
            <UploadDebitNoteExcelModal
              isOpen={isUploadModalOpen}
              onClose={handleCloseUploadModal}
              buttons={[
                "Download Sample",
                // "Download CSV Sample",
                // "Download Format",
              ]}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              file={file}
              setFile={setFile}
              onUpload={onUpload}
              // invoiceTypeList={invoiceTypeList}
              // setInvoicetype={setInvoicetype}
              // invoiceType={invoiceType}
              // downloadCSVSampleLink={handleDownloadSampleLink}
              downloadSampleLink={handleDownloadExcel}
              // downloadFormatLink={handleDownloadFormatLink}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ManageDebitNoteCurrent;
