import * as type from "../../actions/index.types";

export function parkingManagementReducer(
  state = {
    loading: false,
    updateLoader: false,
    parkingTypeList: null,
    parkingChargesList: null,
    parkingChargesAudit: null,

    error: null,
  },
  action
) {
  switch (action.type) {
    case type.GET_PARKING_TYPE_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_PARKING_TYPE_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        parkingTypeList: action.payload,
      };
    case type.GET_PARKING_TYPE_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        parkingTypeList: null,
      };


    case type.ADD_UPDATE_PARKING_TYPE_DETAILS:
      return {
          ...state,
          updateLoader: true,
      };
    case type.ADD_UPDATE_PARKING_TYPE_DETAILS_SUCCESS:
    return {
        ...state,
        updateLoader: false,
    };
    case type.ADD_UPDATE_PARKING_TYPE_DETAILS_FAILURE:
      return {
          ...state,
          updateLoader: false,
      };

    case type.DELETE_PARKING_TYPE_DETAILS:
    return {
      ...state,
    }
    case type.DELETE_PARKING_TYPE_DETAILS_SUCCESS:
      return {
        ...state,
        parkingTypeList: state.parkingTypeList?.filter(item=>!(item.parking_type_id === action.payload.parkingTypeId))
      }
      
    case type.DELETE_PARKING_TYPE_DETAILS_FAILURE:
        return {
          ...state,
        }

    case type.GET_PARKING_CHARGE_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_PARKING_CHARGE_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        parkingChargesList: action.payload,
      };
    case type.GET_PARKING_CHARGE_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        parkingChargesList: null,
      };

    case type.GET_PARKING_CHARGE_AUDIT_LOGS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_PARKING_CHARGE_AUDIT_LOGS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        parkingChargesAudit: action.payload,
      };
    case type.GET_PARKING_CHARGE_AUDIT_LOGS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        parkingChargesAudit: null,
      };
  

    case type.ADD_UPDATE_PARKING_CHARGE_LIST:
      return {
          ...state,
          updateLoader: true,
      };
    case type.ADD_UPDATE_PARKING_CHARGE_LIST_SUCCESS:
    return {
        ...state,
        updateLoader: false,
    };
    case type.ADD_UPDATE_PARKING_CHARGE_LIST_FAILURE:
      return {
          ...state,
          updateLoader: false,
      };

        
    default:
      return state;
    }
}
