import React, { useEffect, useRef } from "react";
import ListHeader from "../../../components/ListHeader/ListHeader";
import Layout from "../../Layout/Layout";
import Button from "../../../components/Button/Button";
import { Link, useNavigate, useParams } from "react-router-dom";
import ButtonG from "../../../components/Button/ButtonG";
import { TextInputFM } from "../../../components/InputFields/TextInput";
import TextRadio from "../../../components/InputFields/TextRadio";
import { DropdownFM2 } from "../../../components/Dropdown/dropdown2";
import {TextAreaFM } from "../../../components/InputFields/TextArea";
import { UploadButtonFM } from "../../../components/Button/UploadButton";
import { FieldArray, Form, Formik } from "formik";
import { AddFacilityValidation } from "../../../validationSchema/ClubSchema/AddFacilitySchema";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getBookingBankList, getBookingGLMappingList } from "../../../redux/actions/ClubActions/ViewBookingsAction";
import  { DatePickerComponentFM } from "../../../components/DatePicker/DatePicker";
import { DeleteIcon } from "../../../assets";
import Checkbox from "../../../components/Checkbox/Checkbox";
import { CircularProgress } from "@mui/material";
import { getRoleList, getRoleWiseList, getVendorConcernedPersonList } from "../../../redux/actions/ClubActions/AddFacilityAction";
import { MultiSelectFM } from "../../../components/MultiSelect/MultiSelect";
import moment from "moment";
import { getFacilityDetails, updateFacility } from "../../../redux/actions/ClubActions/BookAFacilityAction";
import Loader from "../../../components/Loader/Loader";
import { ALL_FACILITY_ENDPOINT } from "../../../Routing/routes";

const EditFacility = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const formRef = useRef();
  const {id} = useParams()
  const {loginData} = useSelector(state=>state.loginReducer)
  const { glMappingList, bankList} = useSelector(state=>state.viewBookingReducer)
  const {loading, updateLoader, facilityDetails} = useSelector(state=>state.bookAFacilityReducer)
  const {employeeRoleList, managingCommitteeRoleList, vendorRoleList, employeeList, managingCommitteeList, vendorList, vendorConcernedPersonList} = useSelector(state=>state.addFacilityReducer)
  const {ipAddress} = useSelector(state=>state.ipAddressReducer)

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleBookingChange = (value) => {
    let {setFieldValue} = formRef.current;
    setFieldValue('booking', value)
    if(value==='Applicable'){
      setFieldValue('isChargeble',  facilityDetails?.chargeable || 'No')
      setFieldValue('chargeOn', facilityDetails?.charge_on || 'member')
      setFieldValue('bookingAvailableUpto', facilityDetails?.bookingAvailableUpto?.split(',')[1] || '')
      setFieldValue('securityDepositAmount', facilityDetails?.security_deposit || '')
      setFieldValue('securityDepositAccId', facilityDetails?.security_deposit_gl_ac || '')
      setFieldValue('facilityChargeAccId', facilityDetails?.facilitycharges || '')
      setFieldValue('bankAccId', facilityDetails?.bankac || '')
    }else{
      setFieldValue('isChargeble', 'No')
      setFieldValue('chargeOn', 'member')
      setFieldValue('bookingAvailableUpto', '')
      setFieldValue('securityDepositAmount', '')
      setFieldValue('securityDepositAccId', '')
      setFieldValue('facilityChargeAccId', '')
      setFieldValue('bankAccId', '')
    }
  };

  const handleUnavailableDateChange = (value) => {
    let {setFieldValue} = formRef.current;
    setFieldValue('haveUnavailableDates', value)
    if(value==='No'){
      setFieldValue('unavailableDates', [{date: ''}])
    }else{
      setFieldValue('unavailableDates', facilityDetails?.unavailableDates?.split(',')?.map((item)=>({
        date: item
      })) || [{date: ''}])
    }
  };
  const handleRadioChange = (key, value) => {
    let {setFieldValue} = formRef.current;
    setFieldValue(key, value)
  };

  const bookingRadioOptions = [
    { name: "booking", value: "Applicable", label: "Applicable" },
    { name: "booking", value: "Not Applicable", label: "Not Applicable" },
  ];
  const chargableRadioOptions = [
    { name: "isChargeble", value: "Yes", label: "Yes" },
    { name: "isChargeble", value: "No", label: "No" },
  ];
  const bookingAutoApproveRadioOptions = [
    { name: "bookingAutoApprove", value: "Y", label: "Yes" },
    { name: "bookingAutoApprove", value: "N", label: "No" },
  ];
  const haveUnavailableDatesRadioOptions = [
    { name: "haveUnavailableDates", value: "Yes", label: "Yes" },
    { name: "haveUnavailableDates", value: "No", label: "No" },
  ];


  const chargeOnRadioOptions = [
    { name: "chargeOn", value: "member", label: "Member Count" },
    { name: "chargeOn", value: "day", label: "Day Count" },
  ];

  const getListAccordingToRole = (key, id)=>{
      let data={
        roleType: key,
        roleTypeId: id,
        societyId: loginData?.default_community?.community_id,
      }
      dispatch(getRoleWiseList(data))
  }

  const handleSubmit =(values, {resetForm})=>{
    let data={
      ipAddress,
      sessionId: loginData?.session_id,
      societyId: loginData?.default_community?.community_id,
      ...values,
      chargeOn: values.booking==='Applicable' ? values.chargeOn : '',
      onSuccessCallback: ()=>{
        navigate(ALL_FACILITY_ENDPOINT)
      }
    }
    dispatch(updateFacility(data))
  }

  useEffect(()=>{
    let data={
      societyId: loginData?.default_community?.community_id,
    }
    dispatch(getFacilityDetails({facilityId: id}))
    dispatch(getBookingGLMappingList(data))
    dispatch(getBookingBankList(data))
    dispatch(getRoleList({...data, roleType:'Vendors'}))
    dispatch(getRoleList({...data, roleType:'Employees'}))
    dispatch(getRoleList({...data, roleType:'Managing Staff'}))
  }, [])

  useEffect(()=>{
    let data={
      societyId: loginData?.default_community?.community_id,
    }
    
    if(facilityDetails){
      if(facilityDetails?.facility_manager){
        dispatch(getRoleWiseList({...data, roleType:'Employees',roleTypeId: facilityDetails?.facility_manager}))
      }
      if(facilityDetails?.managing_committees){
        dispatch(getRoleWiseList({...data, roleType:'Managing Staff',roleTypeId: facilityDetails?.managing_committees}))
      }
      if(facilityDetails?.vandor_cat_id){
        dispatch(getRoleWiseList({...data, roleType:'Vendors',roleTypeId: facilityDetails?.vandor_cat_id}))
      }
      if(facilityDetails?.society_vendor_id){
        dispatch(getVendorConcernedPersonList({vendorId: facilityDetails?.society_vendor_id}))
      }
    }

  }, [facilityDetails])
  
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          initialValues={{  
            facilityId: facilityDetails?.id,
            facilityName: facilityDetails?.facilities_name || '', 
            booking: facilityDetails?.booking || 'Not Applicable',
            isChargeble: facilityDetails?.chargeable || 'No',
            chargeOn: facilityDetails?.chargeable==='Yes' ? facilityDetails?.charge_on : 'member',
            bookingAvailableUpto: facilityDetails?.bookingAvailableUpto?.split(',')[1] || '',
            securityDepositAmount: facilityDetails?.security_deposit || '',
            securityDepositAccId: facilityDetails?.security_deposit_gl_ac || '',
            facilityChargeAccId: facilityDetails?.facilitycharges || '',
            bankAccId: facilityDetails?.bankac || '',
            bookingAutoApprove: facilityDetails?.autoapprove || 'N',
            haveUnavailableDates: facilityDetails?.unavailableDates && facilityDetails?.unavailableDates?.split(',')?.length ? 'Yes' : 'No',
            unavailableDates: facilityDetails?.unavailableDates?.split(',')?.map((item)=>({
              date: item
            })) || [{date: ''}],
            facilityManagerId: facilityDetails?.facility_manager ? parseInt(facilityDetails?.facility_manager) : '',
            employeeId: facilityDetails?.facility_manager_name ? facilityDetails?.facility_manager_name?.split(',')?.map(item=> parseInt(item)) : [],
            managingCommitteId: facilityDetails?.managing_committees ? parseInt(facilityDetails?.managing_committees) : '',
            managingCommitteMemberId: facilityDetails?.managing_committee_member ? facilityDetails?.managing_committee_member?.split(',')?.map(item=> parseInt(item)) :  [],
            vendorCategoryId: facilityDetails?.vandor_cat_id ? parseInt(facilityDetails?.vandor_cat_id) : '',
            vendorId:  facilityDetails?.society_vendor_id ? parseInt(facilityDetails?.society_vendor_id) :  '',
            concernedPersonId: facilityDetails?.vendor_concerned_person ? facilityDetails?.vendor_concerned_person?.split(',')?.map(item=> parseInt(item)) :  [],
            attachments: facilityDetails?.facility_image || '',
            guidance: facilityDetails?.usage_guidance || '',
            description: facilityDetails?.instructions || ''
          }}
          enableReinitialize
          innerRef={(f) => (formRef.current = f)}
          validationSchema={AddFacilityValidation}
          onSubmit={handleSubmit}
        >
          {
            ({values, errors, setFieldValue})=>(
              <Form>{console.log(errors)}
                <div className="border-b border-[#CCC]">
                  <ListHeader leftContent="Edit Facility">
                    <ButtonG label="Cancel" type="button" onClick={handleNavigation(-1)} />
                    <Button label={
                      <span className="w-16 inline-block">
                        {
                        updateLoader ?
                          <CircularProgress sx={{color: "white"}} size={17}/>
                          :
                          <>Update</>
                        }
                      </span>
                      }   />
                  </ListHeader>
                </div>
                {
                  loading?
                  <Loader/>
                  :
                  <div className="flex justify-between mt-4">
                    <table className="border-separate">
                      <tr>
                        <td className="text-sm w-56 pt-2.5 align-top">Facility Name</td>
                        <td>
                          <TextInputFM name='facilityName' label="" placeholder="Enter Name" className="w-52" />
                        </td>
                      </tr>
                      <tr>
                        <td className="text-sm w-56 align-top mb-3">Booking</td>
                        <td>
                          <TextRadio
                            label=""
                            className="w-52 text-xs whitespace-nowrap "
                            options={bookingRadioOptions}
                            defaultSelected={values.booking}
                            onChange={handleBookingChange}
                          />
                        </td>
                      </tr>
                      {
                        values.booking === 'Applicable' &&
                        <>
                          <tr>
                            <td className="text-sm w-56 pt-2.5 align-top">Is Chargeable</td>
                            <td>
                              <TextRadio
                                label=""
                                className="w-52 text-xs whitespace-nowrap my-3"
                                options={chargableRadioOptions}
                                defaultSelected={values.isChargeble}
                                onChange={(value)=>{handleRadioChange('isChargeble', value); if(value==='Yes'){setFieldValue('chargeOn', 'member')}}}
                                />
                            </td>
                          </tr>
                          {
                            (values.isChargeble && values.isChargeble ==='Yes') &&
                            <tr>
                              <td className="text-sm w-56 pt-2.5 align-top">Charge On</td>
                              <td>
                                <TextRadio
                                  label=""
                                  className="w-52 text-xs whitespace-nowrap my-3"
                                  options={chargeOnRadioOptions}
                                  defaultSelected={values.chargeOn}
                                  onChange={(value)=>{handleRadioChange('chargeOn', value);}}
                                  />
                              </td>
                            </tr>
                          }
                          <tr>
                            <td className="text-sm w-56 pt-2 align-top">
                              Booking Available Upto
                            </td>
                            <td>
                              <div>
                                <DatePickerComponentFM
                                    className="h-9 w-52" 
                                    name={`bookingAvailableUpto`}
                                    onDateChange={(selectedDate) => {
                                      setFieldValue(`bookingAvailableUpto`, moment(selectedDate).format('YYYY-MM-DD'))
                                    }}
                                    minDate={facilityDetails?.bookingAvailableUpto?.split(',')[1] && moment(facilityDetails?.bookingAvailableUpto?.split(',')[1])?.isBefore(new Date()) ? new Date(facilityDetails?.bookingAvailableUpto?.split(',')[1]) : new Date()}
                                    defaultValue={values.bookingAvailableUpto ? new Date(values.bookingAvailableUpto) : null}
                                  />
                                  {
                                    values.bookingAvailableUpto && moment(values.bookingAvailableUpto)?.isAfter(new Date()) ?
                                    <div className="text-sm mb-2">Upto { values.bookingAvailableUpto ? Math.ceil(moment(new Date(values.bookingAvailableUpto)).diff(new Date(), 'days', true)) : 0} Days</div>
                                    :
                                    <></>
                                  }
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-sm w-56 pt-2 align-top">
                              Security Deposit Amount(Rs.)
                            </td>
                            <td>
                              <TextInputFM
                                label=""
                                placeholder="Enter Amount"
                                className="w-52"
                                type='number'
                                name='securityDepositAmount'
                                maxLength={15}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-sm w-56 pt-2 align-top">
                              Security Deposit A/c
                            </td>
                            <td>
                              <div>
                                <DropdownFM2
                                  options={glMappingList?.map((item)=>({
                                    label: item.name,
                                    value: item?.ac_code
                                  }))|| []}
                                  placeholder="Select Security Deposit A/c"
                                  className=""
                                  width="208px"
                                  height="36px"
                                  onSelect={(selectedItem) => {
                                    setFieldValue('securityDepositAccId', selectedItem.value)
                                  }}
                                  value={
                                    values.securityDepositAccId ? 
                                    {
                                      label: glMappingList?.find(item=> item.ac_code === values.securityDepositAccId)?.name,
                                      value: values.securityDepositAccId
                                    }
                                    :null
                                  }
                                  name={"securityDepositAccId"}
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-sm w-56 pt-2 align-top">
                              Facility Charge A/c
                            </td>
                            <td>
                              <div>
                                <DropdownFM2
                                  options={glMappingList?.map((item)=>({
                                    label: item.name,
                                    value: item?.ac_code
                                  }))|| []}
                                  placeholder="Select Facility Charge A/c"
                                  className=""
                                  width="208px"
                                  height="36px"
                                  onSelect={(selectedItem) => {
                                    setFieldValue('facilityChargeAccId', selectedItem.value)
                                  }}
                                  value={
                                    values.facilityChargeAccId ? 
                                    {
                                      label: glMappingList?.find(item=> item.ac_code === values.facilityChargeAccId)?.name,
                                      value: values.facilityChargeAccId
                                    }
                                    :null
                                  }
                                  name={"facilityChargeAccId"}
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-sm w-56 pt-2 align-top">Bank A/c</td>
                            <td>
                              <div>
                                <DropdownFM2
                                  options={bankList?.map((item)=>({
                                    label: item.name,
                                    value: item?.ac_code
                                  }))|| []}
                                  placeholder="Select Bank A/c"
                                  className=""
                                  width="208px"
                                  height="36px"
                                  onSelect={(selectedItem) => {
                                    setFieldValue('bankAccId', selectedItem.value)
                                  }}
                                  value={
                                    values.bankAccId ? 
                                    {
                                      label: bankList?.find(item=> item.ac_code === values.bankAccId)?.name,
                                      value: values.bankAccId
                                    }
                                    :null
                                  }
                                  name={"bankAccId"}
                                />
                              </div>
                            </td>
                          </tr>
                        </>
                      }
                      <tr>
                        <td className="text-sm w-56 pt-2.5 align-top">
                          Booking Auto Approve
                        </td>
                        <td>
                          <TextRadio
                            label=""
                            className="w-52 text-xs whitespace-nowrap my-3"
                            options={bookingAutoApproveRadioOptions}
                            defaultSelected={values.bookingAutoApprove}
                            onChange={(value)=>{handleRadioChange('bookingAutoApprove', value)}}
                            />
                        </td>
                      </tr>
                      <tr>
                        <td className="text-sm w-56 pt-2.5 align-top">
                          Unavaliable Dates
                        </td>
                        <td>
                          <TextRadio
                            label=""
                            className="w-52 text-xs whitespace-nowrap my-3"
                            options={haveUnavailableDatesRadioOptions}
                            defaultSelected={values.haveUnavailableDates}
                            onChange={handleUnavailableDateChange}
                          />
                        </td>
                      </tr>
                      {
                        values.haveUnavailableDates === 'Yes' &&
                        <>
                          <tr>
                            <td>
                            </td>
                            <td>
                                <FieldArray
                                  name="unavailableDates"
                                  render={arrayHelpers => (
                                    <div className="p-2">
                                      {values.unavailableDates && values.unavailableDates.length > 0 && (
                                        values.unavailableDates.map((date, index) => (
                                          <div className="flex">
                                            <DatePickerComponentFM
                                                className="h-9 w-52" 
                                                name={`unavailableDates.${index}.date`}
                                                onDateChange={(selectedDate) => {
                                                  setFieldValue(`unavailableDates.${index}.date`, moment(selectedDate).format('DD/MM/YYYY'))
                                                }}
                                                minDate={new Date()}
                                                defaultValue={values.unavailableDates[index]?.date ? moment(values.unavailableDates[index]?.date, 'DD/MM/YYYY').toDate() : null}
                                                />
                                                {
                                                  values.unavailableDates?.length>1 &&
                                                  <DeleteIcon
                                                    onClick={()=>arrayHelpers.remove(index)}
                                                    className="fill-red-650 mt-3 ml-2 cursor-pointer"
                                                  />
                                                }
                                          </div>
                                        )))}
                                        <button
                                          type="button"
                                          onClick={() =>
                                            arrayHelpers.push({
                                              date: '',
                                            })
                                          }
                                          className="text-red-650 font-semibold border-2 border-dashed border-red-650 w-24 h-8 p-2 bg-[#FDF6F6] flex justify-center rounded-md items-center text-xs"
                                        >
                                          Add More
                                        </button>
                                      </div>
                                    )}
                                    ></FieldArray>
                            </td>
                          </tr>
                        </>
                      }
                      <tr>
                        <td className="text-sm w-56 pt-2.5 align-top">
                          Facility Manager
                        </td>
                        <td>
                          <div>
                            <DropdownFM2
                              options={employeeRoleList?.map((item)=>({
                                label: item.title,
                                value: item?.id
                              }))|| []}
                              placeholder="Select facility manager"
                              className=""
                              width="208px"
                              height="36px"
                              onSelect={(selectedItem) => {
                                setFieldValue('facilityManagerId', selectedItem.value)
                                setFieldValue('employeeId', [])
                                getListAccordingToRole('Employees', selectedItem.value)
                              }}
                              value={
                                values.facilityManagerId ? 
                                {
                                  label: employeeRoleList?.find(item=> item.id === values.facilityManagerId)?.title,
                                  value: values.facilityManagerId
                                }
                                :null
                              }
                              name={"facilityManagerId"}
                            />
                          </div>
                        </td>
                      </tr>
                      {
                        values.facilityManagerId &&
                        <tr>
                          <td></td>
                          <td>
                            <div className="grid grid-cols-3 text-xs capitalize mb-3 gap-3 break-all">
                              {
                                employeeList?.map((item, index)=>(
                                  <Checkbox
                                    text={item?.full_name}
                                    className={"w-full mr-4"}
                                    initialchecked={values.employeeId?.includes(item.bridge_table_id)}
                                    onChange={(e) => {
                                      if(values.employeeId?.includes(item.bridge_table_id)){
                                        let list = [...values.employeeId]
                                        let index = list.indexOf(item.bridge_table_id)
                                        if (index !== -1) {
                                          list.splice(index, 1);
                                        }
                                        setFieldValue('employeeId',list)
                                      }
                                      else
                                        setFieldValue('employeeId', [...values.employeeId, item.bridge_table_id])
                                    }}
                                  />
                                ))
                              }
                            </div>
                          </td>
                        </tr>
                      }
                      <tr>
                        <td className="text-sm w-56 pt-2.5 align-top">
                          Managing Committee
                        </td>
                        <td>
                          <div>
                            <DropdownFM2
                              options={managingCommitteeRoleList?.map((item)=>({
                                label: item.title,
                                value: item?.id
                              }))|| []}
                              placeholder="Select managing Committee"
                              className=""
                              width="208px"
                              height="36px"
                              onSelect={(selectedItem) => {
                                setFieldValue('managingCommitteId', selectedItem.value)
                                setFieldValue('managingCommitteMemberId', [])
                                getListAccordingToRole('Managing Staff', selectedItem.value)
                              }}
                              value={
                                values.managingCommitteId ? 
                                {
                                  label: managingCommitteeRoleList?.find(item=> item.id === values.managingCommitteId)?.title,
                                  value: values.managingCommitteId
                                }
                                :null
                              }
                              name={"managingCommitteId"}
                            />
                          </div>
                        </td>
                      </tr>
                      {
                        values.managingCommitteId && 
                        <tr>
                          <td></td>
                          <td>
                            <div className="grid grid-cols-3 text-xs capitalize mb-3 gap-2 break-all">
                              {
                                managingCommitteeList?.filter(item=>item.subscriber_id)?.map((item, index)=>(
                                  <Checkbox
                                    text={item?.full_name}
                                    className={"w-full"}
                                    initialchecked={values.managingCommitteMemberId?.includes(item.subscriber_id)}
                                    onChange={(e) => {
                                      if(values.managingCommitteMemberId?.includes(item.subscriber_id)){
                                        let list = [...values.managingCommitteMemberId]
                                        let index = list.indexOf(item.subscriber_id)
                                        if (index !== -1) {
                                          list.splice(index, 1);
                                        }
                                        setFieldValue('managingCommitteMemberId',list)
                                      }
                                      else
                                        setFieldValue('managingCommitteMemberId', [...values.managingCommitteMemberId, item.subscriber_id])
                                    }}
                                  />
                                ))
                              }
                            </div>
                          </td>
                        </tr>
                      }
                      <tr>
                        <td className="text-sm w-56 pt-2.5 align-top">Vendor</td>
                        <td className="flex">
                          <div>
                          <DropdownFM2
                            options={vendorRoleList?.map((item)=>({
                              label: item.title,
                              value: item?.id
                            }))|| []}
                            placeholder="Select Vendor Category"
                            className=""
                            width="208px"
                            height="36px"
                            onSelect={(selectedItem) => {
                              setFieldValue('vendorCategoryId', selectedItem.value)
                              setFieldValue('vendorId', '')
                              setFieldValue('concernedPersonId', [])
                              getListAccordingToRole('Vendors', selectedItem.value)
                            }}
                            value={
                              values.vendorCategoryId ? 
                              {
                                label: vendorRoleList?.find(item=> item.id === values.vendorCategoryId)?.title,
                                value: values.vendorCategoryId
                              }
                              :null
                            }
                            name={"vendorCategoryId"}
                          />
                          </div>
                          <div className="ms-2">
                          <DropdownFM2
                            options={values.vendorCategoryId ? vendorList?.map((item)=>({
                              label: item?.full_name,
                              value: item?.id
                            })) : []}
                            placeholder="Select Vendor"
                            className=""
                            width="208px"
                            height="36px"
                            onSelect={(selectedItem) => {
                              setFieldValue('vendorId', selectedItem.value)
                              setFieldValue('concernedPersonId', [])
                              dispatch(getVendorConcernedPersonList({vendorId: selectedItem.value}))
                            }}
                            value={
                              values.vendorId ? 
                              {
                                label: vendorList?.find(item=> item.id === values.vendorId)?.full_name,
                                value: values.vendorId
                              }
                              :null
                            }
                            name={"vendorId"}
                          />
                          </div>
                        </td>
                      </tr>
                      {
                        values.vendorId &&
                        <tr>
                          <td className="text-sm w-56 pt-2.5 align-top">
                            Concerned Persons
                          </td>
                          <td>
                            <div>
                              <MultiSelectFM
                                name='concernedPersonId'
                                options={
                                  vendorConcernedPersonList?.map((item) => ({
                                    label: item?.full_name,
                                    value: item.id,
                                  })) || []
                                }
                                width="208px"
                                height={"fit-content"}
                                placeholder="Search Concern Person"
                                className="text-xs"
                                onSelect={(selectedItem) => {
                                  setFieldValue(
                                    "concernedPersonId",
                                    selectedItem.map((item) => item.value)
                                  );
                                }}
                                isMulti={true}
                                value={values?.concernedPersonId?.map((item) => ({
                                  label: vendorConcernedPersonList?.find(
                                    (person) => person.id === item
                                  )?.full_name,
                                  value: item,
                                }))}
                              />
                            </div>
                          </td>
                        </tr>
                      }
                      <tr>
                        <td className="text-sm w-56 pt-2.5 align-top">Uploaded Image</td>
                        <td>
                        {facilityDetails?.facility_image_url ? <Link className="text-xs text-blue-500 underline" to={facilityDetails?.facility_image_url} target="_blank">Click To View</Link> : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-sm w-56 pt-2.5 align-top">Attachments</td>
                        <td>
                          <div className="mb-4">
                            <UploadButtonFM showFileName={true} 
                                name={`attachments`}
                                accept={"image/jpeg,image/gif,image/png,application/pdf,image/x-eps"}
                                onSelect={(e)=>setFieldValue(`attachments`, e)} 
                                fileName={values?.attachments?.name}
                                />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-sm w-56 align-top">Usage Guidance</td>
                        <td>
                          <TextAreaFM
                            row="4"
                            label=""
                            placeholder="Enter Name"
                            className="w-56"
                            name='guidance'
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="text-sm w-56 p align-top">Description</td>
                        <td>
                          <TextAreaFM
                            row="4"
                            label=""
                            placeholder="Enter Name"
                            className="w-56"
                            name='description'
                          />
                        </td>
                      </tr>
                    </table>
                  </div>
                }

              </Form>
            )
          }
        </Formik>
      </div>
    </Layout>
  );
};

export default EditFacility;
