import * as types from '../../index.types';

export function getBankDetailSAndTypeList(data) {
    return {
        type: types.GET_BANK_DETAIL_AND_TYPE_LIST,
        data
    };
}

export function getBankDetails() {
    return {
        type: types.GET_BANK_DETAILS,
    };
}

export function addBankDetails(data) {
    return {
        type: types.ADD_BANK_DETAILS,
        data
    };
}

export function getBankDetailsAuditLog(data) {
    console.log(data)
    return {
        type: types.GET_BANK_DETAILS_AUDIT_LOGS,
        data
    };
}

export function deleteBankData(data) {
    return {
        type: types.DELETE_BANK_DATA,
        data
    };
}

export function getBankNameList(data) {
    return {
        type: types.GET_BANK_NAME_LIST,
        data
    };
}

export function addBankName(data) {
    return {
        type: types.ADD_BANK_NAME,
        data
    };
}
