import * as types from '../../index.types';

export function getInvitedOccupantsAndTenantsList(data) {
    return {
        type: types.GET_INVITED_OCCUPANTS_AND_TENANTS_LIST,
        data
    };
}

export function sendInviteToOccupantsAndTenants(data) {
    return {
        type: types.SEND_INVITE_EMAIL_TO_OCCUPANTS_AND_TENANTS,
        data
    };
}