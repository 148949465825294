import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {DatePickerComponent} from "../../../../components/DatePicker/DatePicker";
import Checkbox from "../../../../components/Checkbox/Checkbox";

const Accordion = ({
  label,
  isOpen,
  toggleAccordion,
  children,
  totalAmount,
}) => {
  return (
    <div className="border rounded-lg border-[#CCCCCC] my-2">
      <div
        className="cursor-pointer items-center bg-[#FDF2F3] rounded-lg p-3 border-b"
        onClick={toggleAccordion}
      >
        <div className="flex items-center w-full">
          <div>
            <button
              className="text-white text-base font-semibold mr-3 w-5 h-5 bg-red-650 
              border rounded flex justify-center items-center"
            >
              {isOpen ? (
                <FontAwesomeIcon icon={faChevronUp} size="xs" />
              ) : (
                <FontAwesomeIcon icon={faChevronDown} size="xs" />
              )}
            </button>
          </div>
          <div className="text-[#000000] grow w-full text-sm font-semibold justify-between flex">
            <span className="">{label}</span>
          </div>
        </div>
      </div>
      {isOpen && <>{children}</>}
    </div>
  );
};

function CheckboxGroup({ options, selectedOptions, onChange }) {
    const [selectOptions, setSelectOptions] = useState(selectedOptions || []);
  
    const handleSelectAll = () => {
      if (options?.length && options?.length === selectOptions?.length) {
        setSelectOptions([]);
      } else {
        setSelectOptions(options);
      }
  
      //onChange
    };
  
    const handleSingleCheckbox = (e, item) => {
      console.log(selectOptions);
      if (e.target.checked) {
        setSelectOptions((prev) => [...prev, item]);
      } else {
        setSelectOptions((prev) => prev.filter((ele) => ele !== item));
      }
    };
  
    return (
      <>
        <div>
          <Checkbox
            text={"Select All"}
            initialChecked={
              options?.length && options?.length === selectOptions?.length
            }
            onChange={handleSelectAll}
          />
        </div>
        <div></div>
        {options?.map((item) => {
          return (
            <div key={item}>
              <Checkbox
                initialChecked={selectOptions?.includes(item)}
                text={item}
                onChange={(e) => {
                  handleSingleCheckbox(e, item);
                }}
              />
            </div>
          );
        })}
      </>
    );
  }

const ListMembersAccordion = () => {
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const [checkboxStates, setCheckboxStates] = useState({
    showUnit: true,
    showFlatArea: true,
  });

  const toggleAccordion = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleChecked = (checkboxName) => {
    setCheckboxStates((prevState) => ({
      ...prevState,
      [checkboxName]: !prevState[checkboxName],
    }));
  };

  const accordionData = [
    {
      label: "List of Members Attended",
      index: 0,
    },
  ];

  return (
    <div>
      {accordionData.map((data) => (
        <Accordion
          key={data.index}
          label={data.label}
          totalAmount={data.totalAmount}
          isOpen={data.index === openAccordionIndex}
          toggleAccordion={() => toggleAccordion(data.index)}
        >
          <div className="p-3">
            <div className="text-[13px]">
              <div className="font-bold my-5">Choose Members To Send Email</div>
              <div className="grid grid-cols-4 gap-y-1.5">
                <CheckboxGroup options={[]} selectedOptions={[""]} />
              </div>
              <div className="font-bold my-5">Members</div>
              <div className="grid grid-cols-3 gap-y-1.5">
                <CheckboxGroup
                  options={[
                    "Himanshi Mehta (P-Block - TPP003)",
                    "Test User (Remote - 3649)",
                    "Dipanshi J (P-Block - TPP002)",
                    "Kapil Kumar Kumar (P-Block - TPP001)",
                    "Test Joint (Remote - 3649)",
                    "Dheeraj Karda (P-Block - TPP001)",
                    "Vinesh Sandoria (P-Block - TPP055, P-Block - TPP054, P-Block - TPP051)",
                    "Dipanshi Asawa (P-Block - TPP10, P-Block - TPP20)",
                  ]}
                  selectedOptions={[]}
                />
              </div>

              <div className="font-bold my-5">Joint Members</div>
              <div className="grid grid-cols-3 gap-y-1.5">
                <CheckboxGroup
                  options={[
                    "Himanshi Mehta (P-Block - TPP003)",
                    "Test User (Remote - 3649)",
                  ]}
                  selectedOptions={["Kapil Kumar Kumar (P-Block - TPP001)"]}
                />
              </div>

              <div className="font-bold my-5">Treasurers</div>
              <div className="grid grid-cols-3 gap-y-1.5">
                <CheckboxGroup
                  options={[
                    "Himanshi Mehta (P-Block - TPP003)",
                    "Test User (Remote - 3649)",
                  ]}
                  selectedOptions={["Kapil Kumar Kumar (P-Block - TPP001)"]}
                />
              </div>

              <div className="font-bold my-5">Chairman</div>
              <div className="grid grid-cols-3 gap-y-1.5">
                <CheckboxGroup
                  options={[
                    "Himanshi Mehta (P-Block - TPP003)",
                    "Test User (Remote - 3649)",
                  ]}
                  selectedOptions={["Kapil Kumar Kumar (P-Block - TPP001)"]}
                />
              </div>
            </div>
          </div>
        </Accordion>
      ))}
    </div>
  );
};

export default ListMembersAccordion;


