import React from "react";
import { TextInputFM } from "../../../../components/InputFields/TextInput";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader/Loader";
import { FieldArray } from "formik";
import moment from "moment";
import { validateDate } from "../../../../utils/helpers/universalFunctions";

const AddManagingCommitteeTable = ({values, setFieldValue}) => {


  const {loadingOccupants, occupantsFormanagingCommittee} = useSelector(state=> state.managingCommitteeReducer)

  const checkedInputData = (id) => {
    return values.selectedOccupants?.some(obj =>obj.memberId===id && obj.selected)
  };

  const handleCheckedData = (e, id) => {
    let newList = values?.selectedOccupants?.flatMap((item) => (item.memberId===id ? {...item, selected: e.target.checked} : item));
    console.log(newList)
    setFieldValue('selectedOccupants', newList)
  };

  const toggleSelectAll = (e) => {
    let newList = values?.selectedOccupants?.flatMap((item) => ({...item, selected: e.target.checked}));
    setFieldValue('selectedOccupants', newList)
  };


  return (
    <div className="table-container w-full">
      {
        loadingOccupants?
        <Loader/>
        :
        <table className="table-auto w-full b">
          <thead>
            <tr className="border-b border-t border-[#CCCCCC] whitespace-nowrap text-[13px] text-[#AAAAAA] text-left">
              <td className="w-[35px] px-2 py-2">
                <Checkbox
                  initialchecked={values.selectedOccupants?.filter(item => item.selected)?.length === occupantsFormanagingCommittee?.length}
                  onChange={toggleSelectAll}
                />
              </td>
              <td className="w-40 px-3">Member (Flat & Wing No.)</td>
              <td className="w-[100px] px-3">Roles & Responsibility</td>
              <td className="w-40 px-3 ">Join Date</td>
              <td className="w-40 px-3 ">Leave Date</td>
            </tr>
          </thead>
          <tbody>
            <tr className="text-xs">&nbsp;</tr>
            {<FieldArray
              name="selectedOccupants"
              render={arrayHelpers => (
                <>
                  {values.selectedOccupants && values.selectedOccupants.length > 0 && (
                    values.selectedOccupants.map((occupant, index) => (
                      <tr key={index} className="text-xs align-top">
                        <td className="px-2">
                          <Checkbox
                            initialchecked={checkedInputData(occupant.memberId)}
                            onChange={(e) => handleCheckedData(e, occupant.memberId)}
                          />
                        </td>
                        <td className="w-40 px-2">
                          <div className="w-56 h-9 capitalize text-[13px] font-normal">
                            {`${occupant.name} (${occupant.flat})`}
                          </div>
                        </td>
                        <td className="w-40 px-2">
                          <TextInputFM
                            label=""
                            placeholder="Enter Detail"
                            className="w-56 h-9"
                            name={`selectedOccupants.${index}.roles`}
                          />
                        </td>
                        <td className="w-56 px-2">
                          <DatePickerComponentFM  
                            className="h-9" 
                            name={`selectedOccupants.${index}.joinDate`}
                            onDateChange={(selectedDate) => {
                              setFieldValue(`selectedOccupants.${index}.joinDate`, selectedDate)
                              if(validateDate(values.selectedOccupants?.[index]?.leaveDate) && moment(values.selectedOccupants?.[index]?.leaveDate).isBefore(new Date(selectedDate)))
                                setFieldValue(`selectedOccupants.${index}.leaveDate`, '')
                            }}
                            defaultValue={values.selectedOccupants?.[index]?.joinDate ? new Date(values.selectedOccupants?.[index]?.joinDate) : null}
                          />
                        </td>
                        <td className="w-56 px-2">
                          <DatePickerComponentFM  
                            className="h-9" 
                            name={`selectedOccupants.${index}.leaveDate`}
                            onDateChange={(selectedDate) => {
                              setFieldValue(`selectedOccupants.${index}.leaveDate`, selectedDate)
                            }}
                            defaultValue={values.selectedOccupants?.[index]?.leaveDate ? new Date(values.selectedOccupants?.[index]?.leaveDate) : null}
                            readOnly={!values.selectedOccupants?.[index]?.joinDate}
                            {...(values.selectedOccupants?.[index]?.joinDate && {minDate: new Date(moment(values.selectedOccupants?.[index]?.joinDate).add(1, 'days'))})}
                          />
                        </td>
                      </tr>))
                  )}
                </>
                )}
              ></FieldArray>
            }
          </tbody>
        </table>
      }
    </div>
  );
};

export default AddManagingCommitteeTable;
