import * as type from '../../../actions/index.types'

export function viewInvitiesInfoReducer(state = {
  loading: false,
  updateLoader: false,
  occupantsList: null,
  tenantsList: null,
  error:null
}, action) {

switch (action.type) {
  case type.GET_INVITED_OCCUPANTS_AND_TENANTS_LIST:
    return {
      ...state,
      loading: true,
      error: null
    }
  case type.GET_INVITED_OCCUPANTS_AND_TENANTS_LIST_SUCCESS:
    if(action.payload.flag === 'occupant')
        return {
            ...state,
            error: null,
            loading: false,
            occupantsList: action.payload
        }
    else
        return {
            ...state,
            error: null,
            loading: false,
            tenantsList: action.payload
        }

  case type.GET_INVITED_OCCUPANTS_AND_TENANTS_LIST_FAILURE:
    if(action.payload.flag === 'occupant')
        return {
            ...state,
            error: action?.message || 'Somthing went wrong.',
            loading: false,
            occupantsList: null,
        }
    else
        return {
            ...state,
            error: action?.message || 'Somthing went wrong.',
            loading: false,
            tenantsList: null,
        }
  
  case type.SEND_INVITE_EMAIL_TO_OCCUPANTS_AND_TENANTS:
    return {
      ...state,
      updateLoader: true,
    }
  case type.SEND_INVITE_EMAIL_TO_OCCUPANTS_AND_TENANTS_SUCCESS:
    return {
      ...state,
      updateLoader: false,

    }
  case type.SEND_INVITE_EMAIL_TO_OCCUPANTS_AND_TENANTS_FAILURE:
    return {
      ...state,
      updateLoader: false,
    }
default:
  return state
}
}