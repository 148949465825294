import { useSelector } from "react-redux";
import { getPermission } from "../utils/helpers/getPermissions";

const PermissionOnComponent = ({permissionPaths, component})=>{
    const {loginData} = useSelector(state=> state.loginReducer)
    return (
      getPermission(loginData, permissionPaths)?
      <>{component}</> :
      <>not found</>
    )
  }

export default PermissionOnComponent;