import * as type from "../../../actions/index.types";

export function manageCreditNoteReducer(
  state = {
    loading: false,
    printLoader: false,
    updateLoader: false,
    uploadLoader: false,
    creditNoteList: null,
    creditNoteDetails: null,
    invoiceData: null,
    loadingInvoiceList: false,
    invoiceList: null,
    excelData: null,
    error: null,
  },
  action
) {
  switch (action.type) {
    case type.GET_CREDIT_NOTE_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_CREDIT_NOTE_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        creditNoteList: action.payload,
      };
    case type.GET_CREDIT_NOTE_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        creditNoteList: null,
      };
    
    case type.GET_CREDIT_NOTE_DETAILS:
    return {
        ...state,
        loading: true,
        error: null,
    };
    case type.GET_CREDIT_NOTE_DETAILS_SUCCESS:
    return {
        ...state,
        error: null,
        loading: false,
        creditNoteDetails: action.payload,
    };
    case type.GET_CREDIT_NOTE_DETAILS_FAILURE:
    return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        creditNoteDetails: null,
    };
        
    case type.ADD_CREDIT_NOTE:
    return {
        ...state,
        updateLoader: true
    }
    case type.ADD_CREDIT_NOTE_SUCCESS:
    return {
        ...state,
        updateLoader: false
        }

    case type.ADD_CREDIT_NOTE_FAILURE:
    return {
        ...state,
        updateLoader: false
        }

    case type.UPDATE_CREDIT_NOTE:
        return {
        ...state,
        updateLoader: true
        }
    case type.UPDATE_CREDIT_NOTE_SUCCESS:
    return {
        ...state,
        updateLoader: false
        }
    case type.UPDATE_CREDIT_NOTE_FAILURE:
    return {
        ...state,
        updateLoader: false
        }


    case type.DELETE_CREDIT_NOTE:
        return {
        ...state,
        updateLoader: true
        }
    case type.DELETE_CREDIT_NOTE_SUCCESS:
    return {
        ...state,
        updateLoader: false,
        creditNoteList: {
          ...state.creditNoteList,
          data: state.creditNoteList.data.filter(item=> item.id !== action.payload.creditNoteId)
        }
        }
    case type.DELETE_CREDIT_NOTE_FAILURE:
    return {
        ...state,
        updateLoader: false
        }
    
    case type.PRINT_CREDIT_NOTE_INVOICE:
      return {
      ...state,
      printLoader: true
      }
  case type.PRINT_CREDIT_NOTE_INVOICE_SUCCESS:
  return {
      ...state,
      printLoader: false,
      invoiceData: action.payload
  }
  case type.PRINT_CREDIT_NOTE_INVOICE_FAILURE:
  return {
      ...state,
      printLoader: false,
      invoiceData: null,
      }

    case type.GET_FLAT_WISE_INVOICE_LIST:
      return {
        ...state,
        loadingInvoiceList: true,
        error: null,
        invoiceList: null
      };
    case type.GET_FLAT_WISE_INVOICE_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loadingInvoiceList: false,

        invoiceList: action.payload,
      };
    case type.GET_FLAT_WISE_INVOICE_LIST_FAILURE:
      return {
        ...state,
        loadingInvoiceList: false,
        error: action?.message || "Somthing went wrong.",
        invoiceList: null,
      };
      

    case type.UPLOAD_CREDIT_NOTE_EXCEL:
      return {
        ...state,
        uploadLoader: true,
        error: null,
      };
    case type.UPLOAD_CREDIT_NOTE_EXCEL_SUCCESS:
      return {
        ...state,
        error: null,
        uploadLoader: false,
        excelData: action.payload,
      };
    case type.UPLOAD_CREDIT_NOTE_EXCEL_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        uploadLoader: false,
        excelData: null,
      };
    
    case type.UPLOAD_CLEAR_ERROR:
        return {
          ...state,
          error: null,
          uploadLoader: false,
          excelData: null,
        };
      
    
    case type.SAVE_CREDIT_NOTE_EXCEL:
        return {
        ...state,
        uploadLoader: true
        }
    case type.SAVE_CREDIT_NOTE_EXCEL_SUCCESS:
    return {
        ...state,
        uploadLoader: false
        }
    case type.SAVE_CREDIT_NOTE_EXCEL_FAILURE:
    return {
        ...state,
        uploadLoader: false
        }


    default:
      return state;
  }
}
