import React, { useEffect, useState } from "react";
import Layout from "../../../Layout/Layout";
import Button from "../../../../components/Button/Button";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import ButtonG from "../../../../components/Button/ButtonG";
import EditParkingAccordions from "./EditParkingAccordions";
import { useNavigate } from "react-router-dom";
import { addUpdateParkingCharge, getParkingChargesList } from "../../../../redux/actions/GateManagementActions/ParkingManagementAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader/Loader";
import { CircularProgress } from "@mui/material";

const ParkingData = [
  {
    id: 1,
    title: "For 4 Wheeler",
    description: [
      {label: "1st Parking", key:'4w_1parking'},
      {label: "2nd Parking", key:'4w_2parking'},
      {label: "3rd Parking", key:'4w_3parking'},
      {label: "4th Parking", key:'4w_4parking'},
      {label: "5th Parking", key:'4w_5parking'},
      {label: "6th Parking", key:'4w_6parking'},
      {label: "7th Parking", key:'4w_7parking'},
      {label: "8th Parking", key:'4w_8parking'},
      {label: "9th Parking", key:'4w_9parking'},
      {label: "10th Parking", key:'4w_10parking'},
    ]
  },
  {
    id: 2,
    title: "For 2 Wheeler",
    description: [
      {label: "1st Parking", key:'2w_1parking'},
      {label: "2nd Parking", key:'2w_2parking'},
      {label: "3rd Parking", key:'2w_3parking'},
      {label: "4th Parking", key:'2w_4parking'},
      {label: "5th Parking", key:'2w_5parking'},
      {label: "6th Parking", key:'2w_6parking'},
      {label: "7th Parking", key:'2w_7parking'},
      {label: "8th Parking", key:'2w_8parking'},
      {label: "9th Parking", key:'2w_9parking'},
      {label: "10th Parking", key:'2w_10parking'},
    ]
  },
  {
    id: 3,
    title: "For Others",
    description: [
      {label: "1st Parking", key:'otherw_1parking'},
      {label: "2nd Parking", key:'otherw_2parking'},
      {label: "3rd Parking", key:'otherw_3parking'},
      {label: "4th Parking", key:'otherw_4parking'},
      {label: "5th Parking", key:'otherw_5parking'},
      {label: "6th Parking", key:'otherw_6parking'},
      {label: "7th Parking", key:'otherw_7parking'},
      {label: "8th Parking", key:'otherw_8parking'},
      {label: "9th Parking", key:'otherw_9parking'},
      {label: "10th Parking", key:'otherw_10parking'},
    ]
  },
];

const EditSocietyParkingCharges = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(ParkingData)
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading, parkingChargesList, updateLoader} = useSelector(state=>state.parkingManagementReducer)
  const {ipAddress} = useSelector(state=> state.ipAddressReducer)
  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleSubmit = ()=>{
    dispatch(addUpdateParkingCharge({
      data, 
      parkingChargeId: parkingChargesList?.parking_charges_id, 
      societyId: loginData?.default_community?.community_id,
      ipAddress,
      sessionId: loginData?.session_id,
      
    }))
  }

  useEffect(()=>{
    if(parkingChargesList)
      setData(prev => {
        let list = [...prev]
        list.map(obj => ({ ...obj, description: obj.description?.map(subObj => ({...subObj, })) }))
        list.forEach(item => {
          item.description.forEach(desc => {
            desc.value = parkingChargesList[desc.key] || 0; 
          });
        });
        return list;
    })
  }, [parkingChargesList])

  useEffect(()=>{
    dispatch(getParkingChargesList({societyId: loginData?.default_community?.community_id}))
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack
            title="Update Annual Parking Charges"
            onClick={handleNavigation(-1)}
          >
            <div className="border-r border-[#CCC]"></div>
            <ButtonG label="Cancel" onClick={handleNavigation(-1)} className="h-8" />
            <Button label={
                <span className="w-12 inline-block">
                {
                updateLoader ?
                  <CircularProgress sx={{color: "white"}} size={17}/>
                  :
                  'Update'
                }
              </span>
            } 
            onClick={handleSubmit} className="h-8" />
          </ListHeaderBack>
        </div>
        <div>
          {
            loading ?
            <Loader/>
            :
            <EditParkingAccordions data={data} setData={setData} />
          }
        </div>
      </div>
    </Layout>
  );
};

export default EditSocietyParkingCharges;
