import React from "react";
import { DeleteIcon } from "../../../../../assets";
import { FieldArray } from "formik";
import { TextInputFM } from "../../../../../components/InputFields/TextInput";
import { deleteDueReminderDetails } from "../../../../../redux/actions/FinanceActions/SettingActions/DueRemindersAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const AfterDueDate = ({values}) => {
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)

  const handleDelete = (id, arrayHelpers, index) => {
    if(id){
      dispatch(deleteDueReminderDetails({
        ...values,
        id,
        societyId: loginData?.default_community?.community_id,
        onSuccessCallback: ()=>{arrayHelpers.remove(index)}
      }))
    }else{
      arrayHelpers.remove(index)
    }
  }

  return (
    <div>
      <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg overflow-hidden mt-3">
        <div className=" border-b border-[#CCC] pb-2 bg-[#FDF2F3]">
          <h1 className="text-base items-center p-2 -mb-2 ml-1">After Due Date</h1>
        </div>
        <div className=" p-2">
        {<FieldArray
            name="noOfDaysAfterDue"
            render={arrayHelpers => (
              <>
                <div className="flex flex-col">
                  <div className="w-1/2">
                    {values.noOfDaysAfterDue && values.noOfDaysAfterDue.length > 0 && (
                      values.noOfDaysAfterDue.map((row, index) => {
                        return (
                          <div className="flex" key={index}>
                            <div className="w-[80%]">
                              <TextInputFM
                                name={`noOfDaysAfterDue.${index}.noOfDay`}
                                type='number'
                                className='w-56'
                                label='No. Of Days After'
                              />
                            </div>
                            <span
                            onClick={()=>{handleDelete(row.id, arrayHelpers, index)}}
                              className="text-red-650 m-3 ml-5 cursor-pointer fill-red-600"
                            >
                              <DeleteIcon />
                            </span>
                          </div>
                        )
                      }))
                    }
                  </div>
                </div>
              <div className="">
                <button
                type="button"
                  className="text-red-650 font-semibold border-2 border-dashed border-red-650 w-28 text-xs whitespace-nowrap h-9 p-2 mb-4 bg-[#FDF6F6] flex justify-center rounded-md"
                  onClick={()=>{
                    arrayHelpers.push({
                      id: 0,
                      noOfDay: ''
                    })
                  }}
                >
                  Add More
                </button>
              </div>
              </>
            )}>

            </FieldArray>
          }
        </div>
      </div>
    </div>
  );
};

export default AfterDueDate;
