import { notify } from '../../../../utils/notification';
import apiEndpoints from '../../../api';
import axiosInstance2 from '../../../axiosInstance2'
import {logOut} from '../../../../utils/helpers/logOut'
import { errorMsg } from '../../../../consts/responseMessages';


export async function getInvoiceDetailsReq(action) {
    var bodyFormData = new FormData();
    bodyFormData.append('id', action.data?.societyId);
    bodyFormData.append('wing', action.data?.wingId);

    return axiosInstance2.post(apiEndpoints.GET_INVOICE_DETAILS_API, bodyFormData)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function updateInvoiceDetailssReq(action) {
    var bodyFormData = new FormData();
    bodyFormData.append('pageid', action.data?.societyId);
    bodyFormData.append('wingno', action.data?.wingId);
    bodyFormData.append('wingstatus', action.data?.wingStatus);
    bodyFormData.append('useTax', action.data?.useAnnualPropertyTax);
    bodyFormData.append('duration', action.data?.duration);
    bodyFormData.append('annual_property_tax', action.data?.annualPropertyTax);
    bodyFormData.append('built_up_area', action.data?.buildUpArea);
    bodyFormData.append('no_of_flats', action.data?.noOfFlats);
    bodyFormData.append('annual_water_charges', action.data?.annualWaterCharge);
    bodyFormData.append('no_of_water_inlets', action.data?.noOfInlets);
    bodyFormData.append('annual_security_charges', action.data?.annualSecurityCharge);
    bodyFormData.append('total_house_keeping', action.data?.totalHouseKeeping);
    bodyFormData.append('annual_lift_maintenance', action.data?.totalAnnualLiftMaintenance);
    bodyFormData.append('electricity_charges', action.data?.electricityCharge);
    bodyFormData.append('construction_cost', action.data?.constructionCost);
    bodyFormData.append('sinking_fund', action.data?.sinkingFund);
    bodyFormData.append('annual_insurance_charges', action.data?.insuranceCharge);
    bodyFormData.append('annual_lease_rent', action.data?.leaseRent);
    bodyFormData.append('annual_agricultural_tax', action.data?.nonAgriculturalTax);
    bodyFormData.append('repairs_maintenance', action.data?.repairFund);
    bodyFormData.append('other_funds', action.data?.otherFunds);
    bodyFormData.append('service_charges', action.data?.serviceCharges);

    return axiosInstance2.post(apiEndpoints.UPDATE_INVOICE_DETAILS_API, bodyFormData)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}
