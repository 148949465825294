import * as types from '../index.types';

export function getParkingTypeList(data) {
    return {
        type: types.GET_PARKING_TYPE_LIST,
        data
    };
}

export function getParkingTypeDetails(data) {
    return {
        type: types.GET_PARKING_TYPE_DETAILS,
        data
    };
}

export function addUpdateParkingTypeDetails(data) {
    return {
        type: types.ADD_UPDATE_PARKING_TYPE_DETAILS,
        data
    };
}

export function deleteParkingTypeDetails(data) {
    return {
        type: types.DELETE_PARKING_TYPE_DETAILS,
        data
    };
}

export function getParkingChargesList(data) {
    return {
        type: types.GET_PARKING_CHARGE_LIST,
        data
    };
}

export function addUpdateParkingCharge(data) {
    return {
        type: types.ADD_UPDATE_PARKING_CHARGE_LIST,
        data
    };
}

export function getParkingChargeAudit(data) {
    return {
        type: types.GET_PARKING_CHARGE_AUDIT_LOGS,
        data
    };
}
