import { call, put, takeLatest } from 'redux-saga/effects'
import * as types from '../../actions/index.types'
import {notify} from '../../../utils/notification'
import webTokenStorer, { webCredentialsStorer } from '../../../utils/webStorer';
import * as  req from '../../../axios/request/AuthReqs/authReq'
import { getFirebaseURL } from '../../../firebase/firebaseFunction';


function* login(action) {
    try {
        const result = yield call(req.loginReq, action);
        if (result.statusCode === 200) {
            webTokenStorer.saveToken({
                access_token: result.data.access_token, 
                society_id: result.data?.default_community?.community_id, 
                user_role: result.data.user_role,
                house_unique_id: result.data?.default_community?.blocks?.[0]?.houseUniqueId || 0,
                rememberMe: action.data.rememberMe
            })
            const reloginResult = yield call(req.reloginReq, {data: {
                society_id: result.data?.default_community?.community_id,
                house_unique_id: result.data?.default_community?.blocks?.[0]?.houseUniqueId || 0,
                user_role: result.data.user_role, 
                firstLogin: true,
                ipAddress: action.data?.ipAddress
            }});

            if (reloginResult.statusCode === 200) {
                reloginResult.data.default_community.profile_photo_url = yield getFirebaseURL(result.data.default_community?.profile_photo)

                webTokenStorer.saveToken({
                    access_token: reloginResult.data.access_token, 
                    society_id: reloginResult.data.default_community?.community_id, 
                    house_unique_id: reloginResult.data?.default_community?.blocks?.houseUniqueId || 0,
                    user_role: reloginResult.data.user_type, 
                    //rememberMe: action.data.rememberMe
                })
                if(action.data.rememberMe)
                    webCredentialsStorer.saveCredentials({id: action.data.email, pwd: action.data.password})

                yield put({ type: types.LOGIN_SUCCESS, payload: reloginResult.data});
                action.data?.onSuccessCallback()  
                notify('success', reloginResult.message);
            }
            else {
                webTokenStorer.removeToken()
                yield put({ type: types.LOGIN_FAILURE, message: result?.response?.data?.message });
            }
        } else {
            webTokenStorer.removeToken()
            yield put({ type: types.LOGIN_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* relogin(action) {
    try {
        const result = yield call(req.reloginReq, action);
        if (result.statusCode === 200) {
            webTokenStorer.saveToken({
                access_token: result.data.access_token, 
                society_id: result.data.default_community?.community_id, 
                user_role: result.data.user_type,
                house_unique_id: result.data?.default_community?.blocks?.houseUniqueId || 0,
                //rememberMe: webTokenStorer.checkRememberMe() 
            })
            action.data?.onSuccessCallback?.()
            result.data.default_community.profile_photo_url = yield getFirebaseURL(result.data.default_community?.profile_photo)

            yield put({ type: types.RELOGIN_SUCCESS, payload: result.data});
        } else {
            yield put({ type: types.RELOGIN_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}


export default function* loginSaga() {
    yield takeLatest(types.LOGIN, login);
    yield takeLatest(types.RELOGIN, relogin);
}