import React from 'react';

const ListHeader = ({ leftContent, children }) => {
  return (
    <div className="flex justify-between items-center border-[#CCC] pb-2 h-10">
      <div className="text-base font-semibold">{leftContent}</div>
      <div className="flex gap-2">{children}</div>
    </div>
  );
};

export default ListHeader;
