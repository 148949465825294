import React from 'react';
import { ErrorMessage } from "formik";

function Checkbox({ text, initialchecked, onChange, className, disabled = false }) {

  return (
    <label className={`${className} inline-flex items-center`}>
      <input
        disabled={disabled}
        type="checkbox"
        className="hidden"
        checked={initialchecked}
        onChange={onChange}
      />
      <span className={`w-[14px] h-[14px] border mt-0.5 mr-2 shrink-0 ${initialchecked ? 'bg-red-650 border-red-650' : 'bg-white border-[#CCC]'}`}>
        {initialchecked && (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="text-white">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
          </svg>
        )}
      </span>
      {text}
    </label>
  );
}

export function CheckboxFM({ text, initialchecked, onChange, className, disabled = false,name }) {

  return (
    <div className="mb-1">
      <label className={`${className} inline-flex items-center`}>
        <input
          disabled={disabled}
          type="checkbox"
          className="hidden"
          checked={initialchecked}
          onChange={onChange}
          name={name}
        />
        <span className={`w-[14px] h-[14px] border mt-0.5 mr-2 shrink-0 ${initialchecked ? 'bg-red-650 border-red-650' : 'bg-white border-[#CCC]'}`}>
          {initialchecked && (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="text-white">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
            </svg>
          )}
        </span>
        {text}
      </label>
      <div className="h-4 text-xs italic text-red-500 ">
        <ErrorMessage name={name} />
      </div>
    </div>
  );
}

export default Checkbox;