import { call, put, takeLatest } from 'redux-saga/effects'
import * as types from '../../../actions/index.types'
import * as req from '../../../../axios/request/FinanceReqs/BillingManagementReqs/GenerateInvoiceReq'
import * as req1 from '../../../../axios/request/FinanceReqs/BillingManagementReqs/ManageInvoiceReqs'
import { notify } from '../../../../utils/notification';

function* generateInvoice(action) {
    try {
        const result = yield call(req.generateInvoiceReq, action);
        if (result.success === true) {
            yield put({ type: types.GENERATE_INVOICE_SUCCESS, payload: result.data}); 
            action.data?.onSuccessCallback?.() 
        } else {
            yield put({ type: types.GENERATE_INVOICE_FAILURE, message: result?.response?.data?.message });
        }
        action.data.onRequestEndCallback?.()
    } catch (e) {
        console.log(e)
    }
}

function* invoiceGeneratorList(action) {
    try {
        const result = yield call(req.invoiceGeneratorListReq, action);
        if (result.success === true) {
            notify('success', 'Invoice added successfully')
            yield put({ type: types.INVOICE_GENERATOR_LIST_SUCCESS, payload: result.data});  
            action.data?.onSuccessCallback?.()
        } else {
            yield put({ type: types.INVOICE_GENERATOR_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getBillPeriod(action) {
    try {
        const result = yield call(req.getBillPeriodReq, action);
        if (result.success === true) {
            // notify('success', result.message)
            action.data?.onSuccessCallback?.()

            yield put({ type: types.GET_BILL_PERIOD_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_BILL_PERIOD_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getCreditNoteAdjustment(action) {
    try {
        const result = yield call(req.getCreditNoteAdjustmentReq, action);
        if (result.success === true) {
            // notify('success', result.message)
            yield put({ type: types.GET_CREDIT_NOTE_ADJUSTMENT_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_CREDIT_NOTE_ADJUSTMENT_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}


function* getDebitNoteAdjustment(action) {
    try {
        const result = yield call(req.getDebitNoteAdjustmentReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_DEBIT_NOTE_ADJUSTMENT_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_DEBIT_NOTE_ADJUSTMENT_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* downloadGenerateInvoiceExcelFormat(action) {
    try {
        const result = yield call(req.downloadInvoiceExcelReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
        }else{
            notify('Unable to download sheet.')
        }
    } catch (e) {
        console.log(e)
    }
}

function* uploadGenerateInvoiceExcel(action) {
    try {
        const result = yield call(req.detailsGenerateInvoiceReq, action);
        if (result.success === true) {
            yield put({ type: types.UPLOAD_GENERATE_INVOICE_EXCEL_SUCCESS, payload: result.data});
            action.data?.onSuccessCallback?.()   
        } else {
            yield put({ type: types.UPLOAD_GENERATE_INVOICE_EXCEL_FAILURE, message: result?.response?.data?.message });
        }
        action.data.onRequestEndCallback?.()
    } catch (e) {
        console.log(e)
    }
}

// function* saveGenerateInvoiceExcel(action) {
//     try {
//         const result = yield call(req.uploadPaymentExcelReq, action);
//         if (result.success === true) {
//             notify('success', result.message)
//             yield put({ type: types.SAVE_MANAGE_INVOICE_PAYMENT_EXCEL_SUCCESS, payload: result.data});  
//         } else {
//             yield put({ type: types.SAVE_MANAGE_INVOICE_PAYMENT_EXCEL_FAILURE, message: result?.response?.data?.message });
//         }
//         action.data.onRequestEndCallback?.()
//     } catch (e) {
//         console.log(e)
//     }
// }


export default function* generateInvoiceSaga() {
    yield takeLatest(types.GENERATE_INVOICE, generateInvoice);
    yield takeLatest(types.INVOICE_GENERATOR_LIST, invoiceGeneratorList);
    yield takeLatest(types.GET_BILL_PERIOD, getBillPeriod);
    yield takeLatest(types.GET_CREDIT_NOTE_ADJUSTMENT, getCreditNoteAdjustment);
    yield takeLatest(types.GET_DEBIT_NOTE_ADJUSTMENT, getDebitNoteAdjustment);
    yield takeLatest(types.DOWNLOAD_GENERATE_EXCEL_FORMAT, downloadGenerateInvoiceExcelFormat);
    yield takeLatest(types.UPLOAD_GENERATE_INVOICE_EXCEL, uploadGenerateInvoiceExcel);
}