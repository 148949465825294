export const TenantsAuditData = [
    {
        label: "",
        values: [
            { key: "Tenant Name", label: "Tenant Name", value: "" },
            { key: "Email ID", label: "Email Address", value: "" },
            { key: "Contact No", label: "Contact No.", value: "" },
            { key: "wing_no", label: "Wing No.", value: "8" },
            { key: "flat_no", label: "Flat No.", value: "8" },
            { key: "Rent Deed Start Date", label: "Rent Deed Start Date", value: "8" },
            { key: "Rent Deed End Date", label: "Rent Deed End Date", value: "8" },
            { key: "UID Card No", label: "UID Card No.", value: "8" },
            { key: "PAN Card No", label: "PAN Card No.", value: "8" },
        ],
    },
];
