import React, { useState } from "react";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const Accordion2 = React.memo(
    ({ data, isOpen, toggleAccordion, label,  setAccessSetting, accessSetting }) => {

    const handlCheckAll = (e, item)=>{
      let newSetting = {...accessSetting}
      if(e.target.checked){
        if(Object.keys(item?.[1])?.length === 0){
          accessSetting[label][item?.[0]]=[]
        }
        else{
          for( let key in accessSetting[label][item?.[0]]){
            accessSetting[label][item?.[0]][key]=1;
          }
        }
        setAccessSetting(newSetting)
      }else{
        if(Array.isArray(item?.[1])){
          accessSetting[label][item?.[0]]={}
        }else{
          for( let key in accessSetting[label][item?.[0]]){
            accessSetting[label][item?.[0]][key]=0;
          }
        }
        setAccessSetting(newSetting)
      }
    }

    const handlCheckOne = (e, item, subItem)=>{
      let newSetting = {...accessSetting}
      if(e.target.checked){
        accessSetting[label][item?.[0]][subItem]=1;
        setAccessSetting(newSetting)
      }else{
        accessSetting[label][item?.[0]][subItem]=0;
        setAccessSetting(newSetting)
      }
    }

    return (
    <div className="border rounded-lg border-[#CCCCCC] my-2 overflow-hidden">
      <div
        className="flex justify-between cursor-pointer items-center bg-[#FDF2F3] rounded-t-lg p-3 border-b"
        onClick={toggleAccordion}
      >
        <div className="flex items-center">
          <button type='button' className="text-white text-sm font-semibold mr-3 w-5 h-5 bg-red-650 border rounded flex justify-center items-center">
            {isOpen ? (
              <FontAwesomeIcon icon={faChevronUp} size="xs" />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} size="xs" />
            )}
          </button>
          <div className="text-[#000000] text-sm font-semibold">{label}</div>
        </div>
      </div>
      {isOpen && (
        <div className="p-3 grid grid-cols-3">
          {/* <div> */}
          {data.map((item, i0) => (
            <div className="mb-10" key={item?.[0]+i0}>
              {item?.[0] ? (
                <div className="acord-section text-sm font-semibold text-[#222222] p-2">
                  <div className="inline-flex items-center">
                    <Checkbox 
                      initialchecked={Array.isArray(item?.[1]) || (Object.entries(item?.[1])?.length ? Object.entries(item?.[1])?.reduce((partialSum, e)=> partialSum && !!e?.[1], true) : false)}
                      onChange={(e)=>{handlCheckAll(e, item)}}
                     />
                    {item?.[0]}
                  </div>
                </div>
              ) : null}
              {Object.entries(item?.[1])?.map((rowData, i1) => (
                <div className="ml-6" key={rowData?.[0] + i1}>
                  <div className="flex justify-between py-1">
                      <div className="flex flex-row items-center text-xs">
                        <div className="inline-flex items-center whitespace-nowrap">
                          <Checkbox 
                            initialchecked={!!rowData?.[1]} 
                            onChange={(e)=>{handlCheckOne(e, item, rowData?.[0])}}
                          /> 
                        </div>
                        <div>{rowData?.[0]}</div>
                      </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>)
  }

  );
  
  

const EditEmployeeRolesAccordion = ({accessSetting, setAccessSetting}) => {
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };


  return (
    <>
      <div>
        {accessSetting && Object.entries(accessSetting)?.map((item, index) => (
          <Accordion2
            label={item[0]}
            key={item[0]+ index}
            data={Object.entries(item?.[1])}
            isOpen={index === openAccordionIndex}
            toggleAccordion={() => toggleAccordion(index)}
            accessSetting = {accessSetting}
            setAccessSetting={setAccessSetting}
          />
        ))}
      </div>
    </>
  );
};

export default EditEmployeeRolesAccordion;
