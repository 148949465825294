import React, { useEffect, useState } from "react";
import Button from "../../../../components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../../Layout/Layout";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import CommitteeAccessSettingsComp from "./CommitteeAccessSettingsComp";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getManagingCommitteeAccessSetting, updateManagingCommitteeAccessSetting } from "../../../../redux/actions/SetupRecordsActions/StaffManagementActions/ManangingCommitteeAction";
import { CircularProgress } from "@mui/material";
import Loader from "../../../../components/Loader/Loader";
import { removeZeroValues } from "../../../../utils/helpers/universalFunctions";

const CommitteeAccessSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {id} = useParams()
  const {loading, updateLoader, accessSettingData} = useSelector(state=>state.managingCommitteeReducer)

  const [accessSetting, setAccessSetting] = useState(null)
  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleSave= ()=>{
    const modifiedAccessSetting = removeZeroValues(JSON.parse(JSON.stringify(accessSetting)))
    dispatch(updateManagingCommitteeAccessSetting({
      accessSetting: JSON.stringify(modifiedAccessSetting),
      committeeId: accessSettingData?.committee_id
    }))
  }

  useEffect(()=>{
    dispatch(getManagingCommitteeAccessSetting({committeeId: id}))
  }, [])

  useEffect(()=>{
    setAccessSetting(accessSettingData?.access)
  }, [accessSettingData])

  return (
    <>
      <Layout>
        <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        
          <div className="border-b border-[#CCC] items-center">
            <ListHeaderBack
              onClick={handleNavigation(-1)}
              title={`Access Settings  ${!loading && accessSettingData? `- ${accessSettingData?.title}` : ''}`}
            >
              <div className="border-r"></div>
              <Button label={
                <span className="w-12 inline-block">
                  {
                    updateLoader ?
                      <CircularProgress sx={{color: "white"}} size={17}/>
                      :
                      'Update'
                    }
                </span>
                }
                className="h-8" onClick={handleSave} />
            </ListHeaderBack>
          </div>
          <div>
            {/* Accordion */}
            {
              loading && id !== accessSettingData?.committee_id?.toString() ?
              <Loader/>
                :
              <CommitteeAccessSettingsComp accessSetting={accessSetting} setAccessSetting={setAccessSetting} />
            }
          </div>
        </div>
      </Layout>
    </>
  );
};

export default CommitteeAccessSettings;
