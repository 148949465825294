import { call, put, takeLatest } from 'redux-saga/effects'
import * as types from '../../actions/index.types'
import { getIPAddressReq } from '../../../axios/request/ipAddressReq';

function* getIPAddress(action) {
    try {
        const result = yield call(getIPAddressReq, action);
        if (result.status === 200) {
            yield put({ type: types.GET_IP_ADDRESS_SUCCESS, payload: result.data?.ip});  
        } else {
            yield put({ type: types.GET_IP_ADDRESS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}


export default function* IPAddressSaga() {
    yield takeLatest(types.GET_IP_ADDRESS, getIPAddress);
}