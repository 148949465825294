import * as types from '../index.types';

export function getMyInvoiceList(data) {
    return {
        type: types.GET_MY_INVOICE_LIST,
        data,
    };
}

export function getInvoiceStatusList(data) {
    return {
        type: types.GET_MY_INVOICE_STATUS_LIST,
        data,
    };
}

export function getInvoicePaymentList(data) {
    return {
        type: types.GET_MY_INVOICE_PAYMENT_LIST,
        data,
    }
}

export function getSingleInvoiceList(data) {
    return {
        type: types.SINGLE_INVOICE_LIST,
        data,
    }
}

export function getMyInvoiceReceipt(data) {
    return {
        type: types.GET_INVOICE_RECEIPT,
        data,
    }
}

export function printMyInvoiceBill(data) {
    return {
        type: types.GET_MY_INVOICE_PRINT_INVOICE,
        data,
    }
}