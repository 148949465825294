import { call, put, takeLatest } from 'redux-saga/effects'
import {notify} from '../../../../utils/notification'
import * as types from '../../../actions/index.types'
import * as req from '../../../../axios/request/SetupRecordsReqs/Occupants&TenantsReqs/ApplyForTenancyReqs'
import { uploadImageOnFirebase } from '../../../../firebase/firebaseFunction'
import { flatsDocsPath } from '../../../../firebase/getFirebasePath'


function* applyForTenancy(action) {

    try {
        const path = (fileName)=>{
            return flatsDocsPath ({
                societyId: action.data?.societyId,
                flatId: action.data.flatId,
                fileName
            })
        }

        for(let tenant of action.data.tenantDetails){
            let identityProofPath = path(tenant.identityProof?.name);
            yield call(uploadImageOnFirebase, tenant.identityProof, identityProofPath)
            tenant.identityProof= identityProofPath

            let panCardpath =  path(tenant.panCard?.name)
            yield call(uploadImageOnFirebase, tenant.panCard, panCardpath)
            tenant.panCard = panCardpath

            let nocPath = path(tenant.noc?.name)
            yield call(uploadImageOnFirebase, tenant.noc, nocPath)
            tenant.noc= nocPath

            let form11Path = path(tenant.form11?.name)
            yield call(uploadImageOnFirebase, tenant.form11, form11Path)
            tenant.form11= form11Path

            let form29Path = path(tenant.form29?.name)
            yield call(uploadImageOnFirebase, tenant.form29, form29Path)
            tenant.form29= form29Path
        }
        
        const result = yield call(req.applyForTenancyReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.APPLY_FOR_TENANCY_SUCCESS, payload: action.data});  
            action.data?.onSuccessCallback()
        }else{
            yield put({ type: types.APPLY_FOR_TENANCY_FAILURE});  
        }
    } catch (e) {
        console.log(e)
    }
}

export default function* ApplyForTenancySaga() {
    yield takeLatest(types.APPLY_FOR_TENANCY, applyForTenancy);
}