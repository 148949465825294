import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import moment from "moment";
import { getDebitNotesReport } from "../../../redux/actions/FinanceActions/BillingReportsAction";
import { validateDate } from "../../../utils/helpers/universalFunctions";

export const headingsCells = [
  { id: 'sNo', label: 'S No.', width: '45px' },
  { id: 'date', label: 'Date', sort: true, width:'100px'},
  { id: 'wingFlat', label: 'Wing & Flat No.', sort: true,width: '100px' },
  { id: 'type', label: 'Type', sort: true, width: '80px' },
  { id: 'number', label: 'Number', sort: true, width:'90px' },
  { id: 'transNo', label: 'Transaction Ref No', sort: true,width:'100px' },
  { id: 'status', label: 'Status', sort: true,width:'100px' },
  { id: 'amount', label: 'Amount', sort: true, width: '80px' },
];

const PrintDebitNoteHistory = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams()
  const [total, setTotal] = useState()
  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, debitNotesReportList } = useSelector(
    (state) => state.billingReportsReducer
  );

  const getTableData =() =>{
    const data={
      societyId: loginData?.default_community?.community_id,
      page: 'all',
      pageLimit: searchParams.get("pageLimit") || 10,
      transactionId: searchParams.get("transactionId") || '',
      modeOfPayment: searchParams.get("modeOfPayment") || '',
      fromDate: searchParams.get("fromDate") || '',
      toDate: searchParams.get("toDate") || '',
      wingId: searchParams.get("wingId") || '',
      flatId: searchParams.get("flatId") || '',
      searchBy: searchParams.get("searchBy") || '',
    }

    dispatch(getDebitNotesReport(data))
  }


  useEffect(()=>{      
    getTableData()

    const afterprint = (e) => {
      e.preventDefault()
    };
    
    window.addEventListener("afterprint", afterprint);

    return () => {
      window.removeEventListener("afterprint", afterprint);
    }
  }, [])


  useEffect(() => {
    if(!loading && debitNotesReportList){
      window.print()
    }
  }, [loading]);


  return loading ? (
    <Loader />
  ) : (
    <div  className=" p-1">
      <table className="table-auto w-full mx-auto border-collapse border border-black">
        <thead>
          <tr>
            {
              headingsCells?.map((item, index)=>(
                <td className={`text-sm p-1 h-16 font-semibold text-[14px] border border-black w-[7%]`} key={index}>
                  {item?.label}
                </td>
              ))
            }
          </tr>
        </thead>
        <tbody>
            {
                debitNotesReportList?.debitNote_history?.map((item, index)=>(
                    <tr className="text-[#222] text-sm" key={index}>
                        <td className={`p-1 border border-black align-top w-[5%]`}>{(index+1)?.toString()?.padStart(2, '0')}</td>

                        <td className={`p-1 border border-black align-top w-[13%]`}>{validateDate(item?.payment_date) ? moment(new Date(item?.payment_date))?.format('DD-MM-YYYY') : '-'}</td>
                        <td className={`p-1 border border-black align-top w-[13%]`}>{`${item?.wing?.number}-${item?.flat?.flat_no}` || '-'}</td>
                        <td className={`p-1 border border-black align-top w-[13%]`}>{item?.modeofpayment || '-'}</td>
                        <td className={`p-1 border border-black align-top w-[13%]`}>{item?.cheque_number || '-'}</td>
                        <td className={`p-1 border border-black align-top w-[13%]`}>{item?.transactionid || '-'}</td>
                        <td className={`p-1 border border-black align-top w-[13%]`}>{item?.status || '-'}</td>
                        <td className={`p-1 border border-black align-top w-[13%]`}>{item?.grandtotal}</td>
                    </tr>
                ))
            }
            
        </tbody>
      </table>
      {!debitNotesReportList?.debitNote_history?.length && !loading && (
          <div className="text-sm my-10 text-center italic">
            No Records
          </div>
        )}
    </div>
  );
};

export default PrintDebitNoteHistory;
