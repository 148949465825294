import apiEndpoints from "../../../api";
import axiosInstance from "../../../axiosInstance";
import { logOut } from "../../../../utils/helpers/logOut";
import { notify } from "../../../../utils/notification";
import { errorMsg } from "../../../../consts/responseMessages";

export async function getFolderListReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.DOCUMENTS_LIST_API}?society_id=${action.data.societyId}`
      )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
          logOut()
      }else if(errors.response?.data.statusCode === 501){
          notify('warning', errors.response.data.message);
      }
      return errors
    });
}

export async function getDocFileListReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.GET_DOCUMENT_FILE_LIST_API}?society_id=${action.data.societyId}&folder_id=${action.data.selectedFolderId}`
      )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
          logOut()
      }else if(errors.response?.data.statusCode === 501){
          notify('warning', errors.response.data.message);
      }
      return errors
    });
}

export async function addFolderNameReq(action) {

  const BODY= {
      "folder_name": action?.data?.folderName || action?.data?.subFolderName,
      "society_id": action.data?.societyId,
      "parent_fol_id" : action?.data?.folderId || 0,
    }

  return axiosInstance.post
  (
    `${apiEndpoints.ADD_FOLDER_API}`,
     {...BODY})
      .then(response => {
          return response;
      }).catch((errors) => {
          console.log("errors", errors);
          if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
              logOut()
          }else if (errors.response?.data.statusCode === 400) {
              notify('error', errors.response.data.message);
          }else if (errors.response.data.statusCode === 401) {
              notify('error', errors.response.data.message);
              logOut()
          } else {
              notify('warning', "Something went wrong");
          }
          return errors

      })
}

export async function getDocumentOccupantsListReq(action) {
  return axiosInstance
    .get(
      `${apiEndpoints.GET_COMPLAINT_OCCUPANTS_LIST_API}?society_id=${action.data.societyId}`
      )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
          logOut()
      }else if(errors.response?.data.statusCode === 501){
          notify('warning', errors.response.data.message);
      }
      return errors
    });
}

export async function uploadDocumentReq(action) {
  const BODY= {
    "society_id": action?.data?.societyId,
      "folder_id": action?.data?.folderId,
      "title" : action?.data?.title,
      "file_name" : action?.data?.chooseDocument,
      "view_access" : action?.data?.viewListAccess,
      "sms_access" : action?.data?.smsListAccess,
    }

  return axiosInstance.post
  (
    `${apiEndpoints.ADD_DOCUMENT_API}`, 
    {...BODY}
    )
      .then(response => {
          return response;
      }).catch((errors) => {
          console.log("errors", errors);
          if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
              logOut()
          }else if (errors.response?.data.statusCode === 400) {
              notify('error', errors.response.data.message);
          }else if (errors.response.data.statusCode === 401) {
              notify('error', errors.response.data.message);
              logOut()
          } else {
              notify('warning', "Something went wrong");
          }
          return errors

      })
}

export async function editDocumentReq(action) {
  const BODY = 
    {
      "id" : action?.data?.id,
      "folder_id": action?.data?.folderId,
      "title" : action?.data?.title,
      "file_name" : action?.data?.file,
      "view_access" : action?.data?.viewListAccess,
      "sms_access" : action?.data?.smsListAccess,
  }

    return axiosInstance.put(
      `${apiEndpoints.EDIT_DOCUMENT_API}`
      , {...BODY})
      .then(response => {
          return response;
      }).catch((errors) => {
          console.log("errors", errors);
          if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
              logOut()
          }else if (errors.response?.data.statusCode === 400) {
              notify('error', errors.response.data.message);
          }else if (errors.response.data.statusCode === 401) {
              notify('error', errors.response.data.message);
              logOut()
          } else {
              notify('warning', "Something went wrong");
          }
          return errors

      })
}

export async function deleteDocumentReqs(action) {
  const fileId = action.data?.fileId;

  return axiosInstance
    .delete(
      `${apiEndpoints.DELETE_DOCUMENT_API}/${fileId}`
      )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}