import React from "react";
import { ReactComponent as CloseIcon } from "../../../assets/icons/closeIcon.svg";
import { useSelector } from "react-redux";
import moment from "moment";

export const details = [
    {
      key: "name",
      label: "Booking",
      value: "Applicable",
    },
    {
      key: "wing&Flat",
      label: "Is Chargable",
      value: "Yes",
    },
    {
      key: "purposeOfBooking",
      label: "Booking Available Upto",
      value: "16-08-2022",
    },
    {
      key: "bookingFrequency",
      label: "Unavailable Date",
      value: "Daily",
    },
    {
      key: "dateRange",
      label: "Security Deposit Amount(Rs.)",
      value: "100",
    },
    {
      key: "timeSlot",
      label: "Facility Manager",
      value: "Himanshi Mehta",
    },
  ];
const ViewMembersListMortgageDetailsModal = ({ isOpen, onClose }) => {

  const {loginData} = useSelector(state=>state.loginReducer)

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-60 z-20 ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="bg-white rounded-lg w-[600px] px-2 min-h-[300px] h-fit">
        <div className="flex justify-between p-2 gap-8 items-center -mb-2">
          <h2 className="p-2 text-lg font-semibold mb-2 items-center text-center w-[180%] flex justify-end">
            LIEN/MORTGAGE REGISTER
          </h2>
          <div className="w-1/2 flex justify-end">
            <button
              onClick={onClose}
              className="bg-[#EAEAEA] rounded-full p-2 items-center mb-2 w-9 h-9 "
            >
              <CloseIcon className="fill-current text-gray-600 hover:text-gray-800 ml-1 w-3 h-3 cursor-pointer" />
            </button>
          </div>
        </div>
        <div className="border-b border-[#CCCCCC]"></div>
        <div className="py-1 gap-2 my-1">
          <div className="text-center">
            <div className="underline font-extrabold text-base uppercase mb-2">{loginData?.default_community?.community_name}</div>
            <div className="underline font-bold text-xs">(Reg No.: {loginData?.default_community?.regn_no} &nbsp; Dated: {moment(new Date(loginData?.default_community?.date_of_regn))?.format('DD-MM-YYYY')})</div>
            <div className="font-bold mb-5 text-xs">{loginData?.default_community?.office_address} </div>
          </div>
          <table className="table-auto w-full">
            <tbody>
              {details?.map((item) => (
                <tr className="text-[#222222] text-sm">
                  <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                    {item.label}:
                  </td>
                  <td className="py-2">{item.value || "-"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ViewMembersListMortgageDetailsModal;
