import React, { useEffect } from "react";
import Layout from "../../Layout/Layout";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";
import ButtonG from "../../../components/Button/ButtonG";
import RateChartTable from "./RateChartTable";
import { DropdownFM2 } from "../../../components/Dropdown/dropdown2";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getFacilityList, getTimeSlotList, updateRateChart } from "../../../redux/actions/ClubActions/BookAFacilityAction";
import { Form, Formik } from "formik";
import { AddRateChartValidation } from "../../../validationSchema/ClubSchema/AddRateChartSchema";
import { CircularProgress } from "@mui/material";

export const headCells = [
  {
    id: "bookingFrequency",
    label: "Booking Frequency",
    width: "180px",
  },
  { id: "timeSlot", label: "Time Slot", width: "180px" },
  {
    id: "chargesForMembers",
    label: "Charges For Members/Day Amount (Rs.)",
    width: "200px",
  },
  {
    id: "chargesForNonMembers",
    label: "Charges For Non Members/Day Amount (Rs.)",
    width: "200px",
  },
];

const AddRateChart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {facilityList, updateLoader} = useSelector(state=>state.bookAFacilityReducer)

  const handleSubmit = (values, {resetForm})=>{
    dispatch(updateRateChart({
      ...values, 
      type: 'added',
      onSuccessCallback: ()=>{resetForm()}
    }))
  }
  
  useEffect(()=>{
    dispatch(getFacilityList({societyId: loginData?.default_community?.community_id}))
  }, [])


  return (
    <Layout>
      <Formik
        initialValues={{  
          facilityId: '', 
          rateChart: [{
            frequency: '',
            timeSlotId: '',
            chargeForNonMember: '',
            chargeForMember: '',
          }]
        }}
        validationSchema={AddRateChartValidation}
        onSubmit={handleSubmit}
      >
        {
          ({values, errors, setFieldValue})=>(
            <Form>
              <div className="w-full h-full bg-white border min-h-[609px] border-[#CCC] rounded-lg p-2">
                <div className="border-b border-[#CCC]">
                  <ListHeaderBack
                    title="Add Rate Chart"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <ButtonG label="Cancel" type='button' onClick={()=>{navigate(-1)}} className="h-8" />
                    <Button label={
                      <span className="w-16 inline-block">
                        {
                        updateLoader ?
                          <CircularProgress sx={{color: "white"}} size={17}/>
                          :
                          <>Add</>
                        }
                      </span>
                      } type='submit' className="h-8" />
                  </ListHeaderBack>
                </div>
                <div className="mt-3 flex w-[35%] justify-between">
                  <div className="text-sm mt-2">Facility Name</div>
                  <div>{console.log(errors)}
                    <DropdownFM2
                      options={facilityList?.facilty_list?.filter(item=> (item.booking==='Applicable' && item.chargeable ==='Yes'))?.map((item)=>({
                        label: item.facilities_name,
                        value: item.id
                      }))}
                      placeholder="Select Facility"
                      className="text-[11px] ms-4"
                      width="192px"
                      height="32px"
                      onSelect={(selectedItem) => {
                        setFieldValue('facilityId', selectedItem.value)
                        dispatch(getTimeSlotList({facilityId: selectedItem.value}))
                        setFieldValue('rateChart', [{
                          frequency: '',
                          timeSlotId: '',
                          chargeForNonMember: '',
                          chargeForMember: '',
                        }])
                      }}
                      {...(values.facilityId === "" && { value: null})}
                      name='facilityId'
                    />
                  </div>
                  </div>
                <div className="border border-[#CCC] rounded-lg">
                  <div className="border-b p-3 text-sm font-semibold bg-[#FDF2F3] flex justify-between items-center">
                    Rate Chart
                  </div>
                  <div className="p-2 ml-1">
                    <RateChartTable
                      values={values}
                      setFieldValue={setFieldValue}
                      columns={headCells}
                    ></RateChartTable>
                  </div>
                </div>
              </div>

            </Form>
          )
        }
        </Formik>
    </Layout>
  );
};

export default AddRateChart;
