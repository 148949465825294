import React from "react";
import {TextInputFM } from "../../../../components/InputFields/TextInput";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader/Loader";

const RegFormLandlordDetails = ({tenantId}) => {
  const {loading, rentPaymentOnlineRegistrationData} = useSelector(state => state.tenantInfoReducer)


  return (
    <div>
      <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg p-2">
        <div className="flex justify-between border-b border-[#CCC] pb-2">
          <h1 className="text-base p-1 font-semibold">Landlord Details</h1>
        </div>
        <div className="flex gap-4 p-2 min-h-[250px]">
        {
          loading && tenantId !== rentPaymentOnlineRegistrationData?.tenant_id?.toString() ?
          <Loader />
          :  
          <div className="flex flex-col justify-between w-full gap-3">
            <div className="flex justify-between w-full gap-3">
              <div className="w-1/2 gap-2 pr-5">
                <TextInputFM
                  label="First Name"
                  placeholder="Enter First Name"
                  className="w-56"
                  name='landLordDetails.firstName'
                  maxLength={30}
                />
                <TextInputFM
                  label="PAN Number"
                  placeholder="Enter Pan Number"
                  className="w-56"
                  type='alphaNumber'
                  name='landLordDetails.panCardNo'
                  maxLength={10}
                />
                <TextInputFM
                  label="Bank Name of Landlord"
                  placeholder="Enter Bank Name"
                  className="w-56"
                  name='landLordDetails.bankName'
                  maxLength={60}
                />
                <TextInputFM
                  label="IFSC Code"
                  placeholder="IFSC Code"
                  className="w-56"
                  name='landLordDetails.ifscCode'
                  type='alphaNumber'
                  maxLength={11}
                />
              </div>
              <div className="w-1/2 gap-2 pl-5">
                <TextInputFM
                  label="Last Name"
                  placeholder="Enter Last Name"
                  className="w-56"
                  maxLength={30}
                  name='landLordDetails.lastName'
                />
                <div className="mt-[55px]">
                  <TextInputFM
                    label="GST Registration Number"
                    placeholder="Enter GST Number"
                    className="w-56"
                    name='landLordDetails.gstRegistrationNo'
                    maxLength={15}
                    type='alphaNumber'
                  />
                </div>
                <div>
                  <TextInputFM
                    label="Bank Account Number of Landlord"
                    placeholder="Enter Account Number"
                    className="w-56 h-9 font-semibold"
                    name={`landLordDetails.accountNumber`}
                    type='number'
                    maxLength={14}
                  />
                </div>
              </div>
            </div>
          </div>
        }
        </div>
      </div>
    </div>
  );
};

export default RegFormLandlordDetails;
