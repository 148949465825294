import * as types from '../../index.types';

export function getMaintenanceStaffList(data) {
    return {
        type: types.GET_MAINTENANCE_STAFF_LIST,
        data
    };
}

export function getAgencyList(data) {
    return {
        type: types.GET_AGENCY_LIST,
        data
    };
}


export function addUpdateMaintenanceStaff(data) {
    return {
        type: types.ADD_UPDATE_MAINTENANCE_STAFF,
        data
    };
}

export function getMaintenanceStaffDetails(data) {
    return {
        type: types.GET_MAINTENANCE_STAFF_DETAILS,
        data
    };
}

export function deleteMaintenanceStaffOrAgency(data) {
    return {
        type: types.DELETE_MAINTENANCE_STAFF_OR_AGENCY,
        data
    };
}

export function getAgencyCategoryList(data) {
    return {
        type: types.GET_AGENCY_CATEGORY_LIST,
        data
    };
}

export function getAgencyDetails(data) {
    return {
        type: types.GET_AGENCY_DETAILS,
        data
    };
}


export function addUpdatAgency(data) {
    return {
        type: types.ADD_UPDATE_AGENCY,
        data
    };
}
