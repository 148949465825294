import React, { useRef, useState } from "react";
import { FingerprintOutlined } from "@mui/icons-material";
import { ErrorMessage } from "formik";

const CaptureButton = ({showFileName=false}) => {
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null)

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleButtonChange = (e) => {
    // Handle the file change here
    const selectedFile = e.target.files[0];
    setFile(e.target.files[0])
    console.log("Selected File:", selectedFile);
  };

  
  return (
    <div className="flex items-center">
      <div className="font-semibold">
        <button
          className="h-9 flex text-red-650 border border-red-650 bg-white
                        text-xs px-2 items-center rounded-lg"
          onClick={handleButtonClick}
        >
          <FingerprintOutlined className="mr-2 fill-red-650 h-8" /> Capture
        </button>
        <input
          ref={fileInputRef}
          className="text-xs rounded-lg w-56 py-2 px-3 text-gray-700 border-none items-center hidden"
          id="rentAgreementUpload"
          name="rentAgreementUpload"
          type="file"
          required
          onChange={handleButtonChange}
        />
      </div>
      {showFileName && <div className="text-xs ms-2 overflow-hidden text-nowrap text-ellipsis">{file?.name || <span className="text-[#AAAAAA]">No File Chosen</span>}</div>}
    </div>
  );
};

export const CaptureButtonFM = ({showFileName=false, fileName, name, accept, onSelect=()=>{}}) => {
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null)
  const [sizeError, setSizeError] = useState(false)


  const handleButtonChange = (e) => {
    // Handle the file change here
    const selectedFile = e.target.files[0];
    if(e.target.files[0]?.size > 2097152){
      setSizeError(true)
   }else{
      setSizeError(false)
   }
   if(e.target.files[0]){
     setFile(e.target.files[0]);
     onSelect(selectedFile)
   }
  };

  
  return (
    <div>
      <div className="flex items-center">
        <div className="font-semibold">
          <label
            htmlFor={name}
            className="h-9 flex text-red-650 border border-red-650 bg-white
                        text-xs px-2 items-center rounded-lg"
          >
            <FingerprintOutlined className="mr-2 fill-red-650 h-8" /> Capture
          </label>
          <input
            className="text-xs rounded-lg w-56 py-2 px-3 text-gray-700 border-none items-center hidden"
            name={name}
            id={name}
            type="file"
            accept={accept}
            onChange={handleButtonChange}
          />
        </div>
        {showFileName && (
          <div className="text-xs ms-2 overflow-hidden text-nowrap text-ellipsis">
            {fileName || (
              <span className="text-[#AAAAAA]">No File Chosen</span>
            )}
          </div>
        )}      </div>
      <div className={`text-red-500 text-xs italic h-3 text-wrap break-words`}>
        {sizeError ? 'File size should not be more than 2MB' : <ErrorMessage name={name} />}
      </div>
    </div>
  );
};

export default CaptureButton;
