import axios from "axios";

export async function getIPAddressReq(action) {
    // https://ipapi.co/json/
    return axios.get("https://api.ipify.org/?format=json")
        .then(response => { 
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            return errors
        })
}