import React from "react";
import Layout from "../../Layout/Layout";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import { useNavigate } from "react-router-dom";
import SmsCreditOrderHistoryTable from "../../../components/TableComponent/SmsCreditOrderHistoryTable";

export const headCells = [
  { id: "id", label: "S No.", width: "100px" },
  { id: "smsCredit", label: "SMS Credit", sort: false, width: "230px" },
  { id: "amount", label: "Amount", sort: true, width: "200px" },
  { id: "status", label: "Status", sort: true, width: "150px" },
  { id: "orderDate", label: "Order Date", sort: true, width: "170px" },
  { id: "action", label: "Acion", sort: true, width: "100px" },
];

const rows = [
  {
    id: `1`,
    smsCredit: "1000",
    amount: "350",
    status: "Accepted",
    orderDate: "11-04-2023 04:09 PM",
  },
  {
    id: `2`,
    smsCredit: "10000",
    amount: "450",
    status: "Pending",
    orderDate: "11-04-2024 04:04 PM",
  },
];

const SmsCreditOrderHistory = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => () => {
    navigate(path);
  };
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <ListHeaderBack
          title="SMS Credit Order History"
          onClick={handleNavigation(-1)}
        >
          <div className="text-[#AAA] items-center text-xs mt-2 font-semibold">
            Remaining SMS Credit (649)
          </div>
        </ListHeaderBack>
        <div className="ml-1">
          <SmsCreditOrderHistoryTable
            data={rows}
            columns={headCells}
            checkbox={false}
            // pagination={staffPersonnelList?.staff_list?.length}
            // totalCount={staffPersonnelList?.total_count}
            // totalPages={staffPersonnelList?.total_pages}
            // start={(staffPersonnelList?.current_page - 1) * (searchParams.get("pageLimit") || 10) + 1}
            // end={(staffPersonnelList?.current_page - 1) * (searchParams.get("pageLimit") || 10) + staffPersonnelList?.staff_list?.length}
            // currentPage={parseInt(staffPersonnelList?.current_page)}
            // rowsPerPage={searchParams.get("pageLimit") || 10}
            // getTableData={getTableData}
          ></SmsCreditOrderHistoryTable>
        </div>
        {!rows?.length && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
    </Layout>
  );
};

export default SmsCreditOrderHistory;
