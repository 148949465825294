import * as type from '../../actions/index.types'

export function ipAddressReducer(state = {
  loading: false,
  ipAddress: '',
  error:null
}, action) {
switch (action.type) {
  case type.GET_IP_ADDRESS:
    return {
      ...state,
      loading: true,
    }
  case type.GET_IP_ADDRESS_SUCCESS:
    return {
      ...state,
      error: null,
      ipAddress: action.payload,
      loading: false,
    }

  case type.GET_IP_ADDRESS_FAILURE:
    return {
      ...state,
      error: action?.message,
      loading: false,
    }
  default:
    return state
}
}