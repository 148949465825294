import React, { useEffect } from "react";
import { ReactComponent as CloseIcon } from "../../../assets/icons/closeIcon.svg";
import Button from "../../../components/Button/Button";
import { TextInputFM } from "../../../components/InputFields/TextInput";
import { DeleteIcon } from "../../../assets";
import { useDispatch } from "react-redux";
import { addUpdateSocietyEntryGateReq, deleteSocietyEntryGate, deleteVistorPurposeReq, getSocietyEntryGate } from "../../../redux/actions/GateManagementActions/VisitorAndVehicleInAndOutAction";
import { useSelector } from "react-redux";
import { FieldArray, Formik,Form } from "formik";
import { AddGateValidation } from "../../../validationSchema/gateManagementSchema/visitorAndVehicleInAndOutSchema";
import Loader from "../../../components/Loader/Loader";
import { CircularProgress } from "@mui/material";
import moment from "moment";

const AddGateModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch()
  const { loginData } = useSelector((state) => state.loginReducer);
  const {loadingModal, updateLoader, societyGateList, purposeList } = useSelector((state) => state.visitorAndVehicleInAndOutReducer);

  const handleDelete = (arrayHelpers, index, gate)=>{
    if(gate.gateId){
      dispatch(deleteSocietyEntryGate({
        gateId: gate.gateId, 
        onSuccessCallback: ()=>{
          arrayHelpers.remove(index); 
        }
      }))
    }else{
      arrayHelpers.remove(index); 
    }
  }
  
  const handleDeletePurpose=(purposeId) =>{
    dispatch(deleteVistorPurposeReq({purposeId, societyId: loginData?.default_community?.community_id}))
  }

  useEffect(()=>{
    dispatch(getSocietyEntryGate({societyId: loginData?.default_community?.community_id}))
  }, [])

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-60 z-20 ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="bg-white rounded-lg w-[420px] px-2 min-h-[550px] h-fit">
        <div className="flex justify-between p-2 gap-8 items-center -mb-2">
          <h2 className="p-2 text-lg font-semibold mb-2 items-center text-center w-[80%] flex justify-end">
            Add Gate
          </h2>
          <div className="w-1/2 flex justify-end">
            <button
              onClick={onClose}
              className="bg-[#EAEAEA] rounded-full p-2 items-center mb-2 w-9 h-9 "
            >
              <CloseIcon className="fill-current text-gray-600 hover:text-gray-800 ml-1 w-3 h-3 cursor-pointer" />
            </button>
          </div>
        </div>
        <div className="border-b border-[#CCCCCC]"></div>
        {
          loadingModal ?
          <Loader/> :
          <>
            <Formik
              enableReinitialize
              initialValues={{ 
                gates: societyGateList?.map(item => ({
                  gateId: item?.gate_id,
                  name: item?.entry_gate
                })) || [{
                  gateId: 0,
                  name: ''
                }]
              }}
              onSubmit={values =>
                dispatch(addUpdateSocietyEntryGateReq({...values, societyId: loginData?.default_community?.community_id, onSucessCallback: ()=>{dispatch(getSocietyEntryGate())}}))
              }
              validationSchema={AddGateValidation}
              >    
              {
                ({values, dirty})=>{
                  return (
                    <Form>
                      <div className="p-2 h-[180px] overflow-y-scroll">
                        <div className="text-sm mb-2">Gates</div>
                        <FieldArray
                          name="gates"
                          render={arrayHelpers => (
                            <>
                            {
                              values.gates?.map((gate, index)=>(
                                <div>
                                  <div
                                    className="flex justify-start gap-3"
                                    key={index}
                                  >
                                    <TextInputFM
                                      label=""
                                      placeholder="Enter Gate Number"
                                      className="w-56"
                                      name={`gates.${index}.name`}
                                    />
                                    <DeleteIcon
                                      className="fill-red-650 mt-3 cursor-pointer"
                                      onClick={()=>{handleDelete(arrayHelpers, index, gate)}}
                                    />
                                  </div>
                                </div>
                              ))
                            }
                              <button
                                type="button"
                                label="Add More"
                                onClick={() =>
                                  arrayHelpers.push({
                                    gateId: 0,
                                    name: '',
                                  })
                                }
                                className="text-red-650 font-semibold border-2 border-dashed border-red-650 w-24 h-8 p-2 bg-[#FDF6F6] flex justify-center rounded-md items-center text-xs"
                              >
                                Add More
                              </button>
                            </>
                          )}
                          ></FieldArray>
                        </div>
                        <div className="border-b"></div>
                        <div className="flex justify-center my-2">
                          <Button
                            label={
                              <span className="w-8 inline-block">
                                {
                                  updateLoader ?
                                    <CircularProgress sx={{color: "white"}} size={17}/>
                                    :
                                    'Save'
                                  }
                              </span>
                            }
                            className="h-8 font-semibold"
                            type={"submit"}
                            disabled={loadingModal || updateLoader || !dirty}
                          />
                        </div>
                      </Form>)
                    }}
                </Formik>
            <div className="m-2 h-56 overflow-y-scroll border rounded overflow-hidden text-sm">
              <table className=" w-full">
                <thead className="border-b py-2 sticky top-0 bg-white">
                  <tr>
                    <th className="text-[#AAAAAA]">SNo</th>
                    <th className="text-[#AAAAAA]">Purpose</th>
                    <th className="text-[#AAAAAA]">Date</th>
                    <th className="text-[#AAAAAA]">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    purposeList?.map((item, index)=>(
                    <tr key={index}>
                      <td>{(index+1)?.toString()?.padStart(2, '0')}</td>
                      <td className="text-left">{item.purpose}</td>
                      <td>{moment(item.date)?.format('DD-MM-YYYY')}</td>
                      <td>
                        {
                          item.society_id === loginData?.default_community?.community_id && 
                          <DeleteIcon
                            onClick={()=>{handleDeletePurpose(item.purpose_id)}}
                            className="fill-red-650 mt-3 ml-2 cursor-pointer"
                          />
                        }
                      </td>
                    </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </>
        }
      </div>
    </div>
  );
};

export default AddGateModal;
