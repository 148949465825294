import { call, put, takeLatest } from 'redux-saga/effects'
import * as types from '../../../actions/index.types'
import * as req from '../../../../axios/request/SetupRecordsReqs/SocietyFormsAndByeLawsReqs/SocietyFormsAndByeLawsReq'
import { getFirebaseURL } from '../../../../firebase/firebaseFunction';

function* getSocietyFormsAndByeLaws(action) {
    try {
        const result = yield call(req.getSocietyFormsAndByeLawsReq, action);
        if (result.statusCode === 200) {
            for(let item of result.data){
                item.file = yield getFirebaseURL(item?.file)
            }
            yield put({ type: types.GET_SOCIETY_FORMS_AND_BYE_LAWS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_SOCIETY_FORMS_AND_BYE_LAWS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

export default function* societyFormsAndByeLawsSaga() {
    yield takeLatest(types.GET_SOCIETY_FORMS_AND_BYE_LAWS, getSocietyFormsAndByeLaws);

}