import React from "react";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import Layout from "../../../Layout/Layout";
import { useNavigate } from "react-router-dom";

function ViewSentNotice() {
  const navigate = useNavigate();

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack
            title="View Sent Notice"
            onClick={() => {
              navigate(-1);
            }}
          >
          </ListHeaderBack>
        </div>
        <div className="p-5 px-9 gap-2 my-1">
          <div className="text-base flex justify-between">
            <span className="text-left">TelePhone No.: 9876-543210</span>
            <span className="text-right">Date: 11-04-2023</span>
          </div>
          <div className="mt-6">
            <p className="text-[24px] font-bold text-red-650 text-center">
              Treasure Town Society
            </p>
            <p className="text-center text-base font-semibold">
              Society Address
            </p>
          </div>
          <div className="border-[#222] border-b my-6" />
          <div className="text-lg font-bold text-center mt-7">
            Notice
          </div>
          <div className="mt-5 text-left text-[13px]">
            <p className="mb-3">Dear Sir/Ma'am</p>

            <p className="mb-3">
              With reference to your application dated —. This Society has no
              objection to transfer the flat in occupation with you too .
              Provided you will abide by the provision of Co-op. Act .and Rules,
              and the provision of State Government as well as the Provision of
              Society Bye Law.
            </p>

            <p className="font-semibold">Agenda</p>
            <p className="mb-6">
              - The said building has ground Plus Three storey without the
              facility of lift.
            </p>
          </div>
            <div className="text-right text-[13px]">
            <p className="">By Order Of The Managing Committee</p>
            <p className="font-semibold">Synapse</p>
            </div>
          <div className="text-right font-semibold text-[13px] mt-8">
            Honorary Secretary
          </div>

          <div className="text-[13px] text-left">
          <p className="font-semibold">Notes</p>
            <p className="mb-6">
              * The said building has ground Plus Three storey without the
              facility of lift.
            </p>

            <p className="font-semibold">Receiver</p>
            <p className="mb-6"></p>

            <p className="font-semibold">Member</p>
            <p className="mb-6">
            Vinesh Sandoria, Test User, Dheeraj Karda, Diapnshi J, Test joint, Himanshi Mehta
            </p>

            <p className="font-semibold">Chairman</p>
            <p className="mb-6">
            Vinesh Sandoria, Himanshi Mehta
            </p>

            <p className="font-semibold">Reminder</p>
            <p className="flex">
            <span className="font-semibold">Date: </span> 
            <span>11-04-2023</span>
            </p>
            <p>
            <span className="font-semibold">Time: </span> 
            <span>11-04-2023</span>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ViewSentNotice;
