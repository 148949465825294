import * as types from '../../index.types';

export function generateInvoice(data) {
    return {
        type: types.GENERATE_INVOICE,
        data,
    };
}

export function invoiceGeneratorList(data) {
    return {
        type: types.INVOICE_GENERATOR_LIST,
        data,
    };
}

export function getBillPeriod(data) {
    return {
        type: types.GET_BILL_PERIOD,
        data,
    };
}

export function getCreditNoteAdjustment(data) {
    return {
        type: types.GET_CREDIT_NOTE_ADJUSTMENT,
        data,
    };
}

export function getDebitNoteAdjustment(data) {
    return {
        type: types.GET_DEBIT_NOTE_ADJUSTMENT,
        data,
    };
}

export function downloadGenerateInvoiceExcelFormat(data) {
    return {
        type: types.DOWNLOAD_GENERATE_EXCEL_FORMAT,
        data,
    };
}

export function uploadGenerateInvoiceExcel(data) {
    return {
        type: types.UPLOAD_GENERATE_INVOICE_EXCEL,
        data,
    };
}

export function saveGenerateInvoiceExcel(data) {
    return {
        type: types.SAVE_GENERATE_INVOICE_EXCEL,
        data,
    };
}