import React, { useEffect, useRef } from "react";
import { TextInputFM } from "../../../components/InputFields/TextInput";
import { TextAreaFM } from "../../../components/InputFields/TextArea";
import { Formik, Form, ErrorMessage, FieldArray } from "formik";
import {
  MainVisitorTypeOptions,
  ProofTypeOptions,
  societyStaffVehicleTypeOptions,
} from "../../../consts/DropDownOptions";
import { DropdownFM2 } from "../../../components/Dropdown/dropdown2";
import Checkbox from "../../../components/Checkbox/Checkbox";
import { DeleteIcon } from "../../../assets";
import Button from "../../../components/Button/Button";
import ButtonG from "../../../components/Button/ButtonG";
import CaptureImage from "../../../components/CaptureImage/CaptureImage";
import { useDispatch } from "react-redux";
import { MainVisitorFormValidation } from "../../../validationSchema/gateManagementSchema/visitorAndVehicleInAndOutSchema";
import { useSelector } from "react-redux";
import {
  getFlatDetails,
  getWingsAndFlatDetails,
} from "../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import {
  adNewVisitor,
  clearVisitorData,
  getFlatMemberList,
  getVistorData,
  getVistorPurposeList,
  sendOTPToVisitor,
} from "../../../redux/actions/GateManagementActions/VisitorAndVehicleInAndOutAction";
import { notify } from "../../../utils/notification";
import {
  onlyPhoneKey,
  pasteOnlyNumberKey,
} from "../../../utils/helpers/universalFunctions";
import ImageComponent from "../../../components/ImageComponent/ImageComponent";
import { CircularProgress } from "@mui/material";
import countries from "../../../consts/countries";

const MainVisitorForm = ({ getData }) => {
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { purposeList, updateLoader, mobilPinCheckData, existingVisitorData } =
    useSelector((state) => state.visitorAndVehicleInAndOutReducer);
  const { ipAddress } = useSelector((state) => state.ipAddressReducer);
  const formRef = useRef();

  const handleSaveClick = (values, { resetForm, initialValues }) => {
    dispatch(
      adNewVisitor({
        ...values,
        phone: "+" + values?.mobileCountryCode + "-" + values?.mobileNo,
        ipAddress,
        societyId: loginData?.default_community?.community_id,
        onSuccessCallback: () => {
          resetForm();
          dispatch(clearVisitorData());
          getData();
        },
      })
    );
  };

  const handleDelete = (arrayHelpers, index, entry) => {
    arrayHelpers.remove(index);
  };

  const handleSendPin = (validPhoneNumber, phoneNumber) => {
    if (!phoneNumber) notify("warning", "Please enter contact number.");
    else if (!validPhoneNumber)
      dispatch(
        sendOTPToVisitor({ phoneNumber: phoneNumber?.replace("+", "") })
      );
    else notify("warning", "Please a valid contact number.");
  };

  const handleSendPIN = (setFieldValue, pin) => {
    if (pin !== mobilPinCheckData?.otp) notify("error", "Invalid OTP");
    else {
      notify("success", "PIN Valid");
      setFieldValue("mobilePINCheck", "Yes");
    }
  };

  const handleBlurOnContact = (error, number) => {
    if (!error) dispatch(getVistorData({ mobileNo: number?.replace("+", "") }));
  };

  useEffect(() => {
    const societyId = loginData?.default_community?.community_id;
    dispatch(getWingsAndFlatDetails({ societyId }));
    dispatch(getVistorPurposeList({ societyId }));
  }, []);

  useEffect(() => {
    let { values } = formRef?.current;

    formRef?.current?.setValues({
      photo: existingVisitorData?.profile_pic || "",
      useOld: !!existingVisitorData?.profile_pic || false,
      firstName: existingVisitorData?.first_name || "",
      lastName: existingVisitorData?.last_name || "",
      email: existingVisitorData?.email || "",

      mobileCountryCode: "91",
      countryISO: "IN",
      countryId: 1,
      mobileNo: existingVisitorData?.mobile_number || "",

      phone: values.phone,
      count: values.count,
      idProofType: values.idProofType,
      idProofNo: values.idProofNo,
      vehicleType: existingVisitorData?.vehicle_type || "",
      vehicleNumber: values.vehicleNumber,
      visitorType: existingVisitorData?.type || "",
      from: existingVisitorData?.vfrom || "",
      laptop: values.laptop,
      belongings: existingVisitorData?.belongings || "",
      whereToVisit: values.whereToVisit?.map((item) => ({
        wingId: item.phone,
        flatList: item.flatList,
        flatId: item.phone,
        memberId: item.phone,
        memberMobileNo: item.phone,
        memberList: item.memberList,
      })),
      purposeId: values.purposeId,
      purposeName: values.purposeName,
      pin: values.pin,
      mobilePINCheck: "No",
      addAsFrequentVisitor: values.addAsFrequentVisitor,
      sendSMS: values.sendSMS,
      comment: values.comment,
    });
  }, [existingVisitorData]);

  return (
    <>
      <Formik
        initialValues={{
          photo: existingVisitorData?.profile_pic || "",
          useOld: !!existingVisitorData?.profile_pic || false,
          firstName: existingVisitorData?.first_name || "",
          lastName: existingVisitorData?.last_name || "",
          email: existingVisitorData?.email || "",
          mobileCountryCode: "91",
          countryISO: "IN",
          countryId: 1,
          mobileNo: existingVisitorData?.mobile_number || "",
          phone: "",
          count: "",
          idProofType: "",
          idProofNo: "",
          vehicleType: existingVisitorData?.vehicle_type || "",
          vehicleNumber: "",
          visitorType: existingVisitorData?.type || "",
          from: existingVisitorData?.vfrom || "",
          laptop: "No",
          belongings: existingVisitorData?.belongings || "",
          whereToVisit: [
            {
              wingId: "",
              flatList: [],
              flatId: "",
              memberId: "",
              memberMobileNo: "",
              memberList: [],
            },
          ],
          purposeId: "",
          purposeName: "",
          pin: "",
          mobilePINCheck: "No",
          addAsFrequentVisitor: 0,
          sendSMS: 0,
          comment: "",
        }}
        onSubmit={handleSaveClick}
        validationSchema={MainVisitorFormValidation}
        innerRef={(f) => (formRef.current = f)}
      >
        {({ values, errors, resetForm, setFieldTouched, setFieldValue }) => {
          return (
            <Form>
              {
                <>
                  <div className="flex gap-4 p-2">
                    <div className="flex flex-row justify-between w-full gap-7">
                      <div className="flex flex-col w-1/2">
                        <TextInputFM
                          label="First Name"
                          placeholder="Enter First Name"
                          className="w-56"
                          name={`firstName`}
                          maxLength={30}
                        />
                        <TextInputFM
                          label="Last Name"
                          placeholder="Enter Last Name"
                          className="w-56"
                          name={`lastName`}
                          maxLength={30}
                        />
                        <TextInputFM
                          label="Email"
                          placeholder="Enter Email"
                          className="w-56"
                          name={`email`}
                        />
                        <div className="flex  justify-between text-sm">
                          <span className="mt-2">Country</span>
                          <DropdownFM2
                            options={
                              countries?.map((item) => ({
                                label: `${item?.name?.toUpperCase()} (${
                                  item?.phonecode
                                  })`,
                                value: item?.phonecode?.replace("+", ""),
                                iso: item.iso,
                                id: item?.id,
                              })) || []
                            }
                            width={"224px"}
                            className="text-xs"
                            placeholder={"Select Country"}
                            onSelect={(selectedItem) => {
                              setFieldValue(
                                `mobileCountryCode`,
                                selectedItem.value
                              );
                              setFieldValue(`countryId`, selectedItem.id);
                              setFieldValue(`countryISO`, selectedItem.iso);
                            }}
                            height="38px"
                            name={`mobileCountryCode`}
                            value={
                              values.mobileCountryCode && {
                                label: `${countries
                                  ?.find(
                                    (item) =>
                                      item.phonecode?.replace("+", "") ===
                                      values?.mobileCountryCode?.replace(
                                        "+",
                                        ""
                                      )
                                  )
                                  ?.name?.toUpperCase()} (+${
                                    values.mobileCountryCode
                                  })`,
                                value: values.mobileCountryCode,
                                iso: values.countryISO,
                                id: values.countryId,
                              }
                            }
                          />
                        </div>
                        <div className="flex justify-between mb-0.5">
                          <span className="text-sm mt-2">Contact Number</span>
                          <div>
                            <div
                              className={`w-56 text-sm border border-[#CCCCCC] rounded-md p-2 bg-[#F9FAFC] `}
                            >
                              <input
                                type="text"
                                placeholder="Enter Number"
                                className="bg-[#F9FAFC] outline-none border-none w-full placeholder-italic text-black not-italic text-xs font-semibold"
                                autoComplete="off"
                                maxLength={16}
                                value={values.mobileNo}
                                onChange={(e) => {
                                  setFieldValue(
                                    "phone",
                                    "+" +
                                    values?.mobileCountryCode +
                                    "-" +
                                    e.target.value
                                  );
                                  setFieldValue("mobileNo", e.target.value);
                                }}
                                onKeyPress={onlyPhoneKey}
                                onPaste={pasteOnlyNumberKey}
                                onBlur={(e) => {
                                  setFieldTouched("mobileNo", true);
                                  handleBlurOnContact(
                                    errors.mobileNo,
                                    values.mobileCountryCode +
                                    "-" +
                                    values.mobileNo
                                  );
                                  setFieldValue("mobileNo", e.target.value);
                                }}
                              />
                            </div>
                            <div
                              className={`text-red-500 text-xs italic h-4 text-wrap break-words`}
                            >
                              <ErrorMessage name={"mobileNo"} />
                            </div>
                          </div>
                        </div>

                        <div className="flex justify-between">
                          <span className="text-sm mt-2">Id Proof Type</span>
                          <DropdownFM2
                            options={ProofTypeOptions}
                            width={"224px"}
                            className="text-xs"
                            placeholder="Select ID Proof Type"
                            onSelect={(selectedItem) => {
                              setFieldValue(`idProofType`, selectedItem.value);
                            }}
                            height="38px"
                            name={`idProofType`}
                            value={
                              values.idProofType === ""
                                ? null
                                : {
                                  label: ProofTypeOptions?.find(
                                    (item) =>
                                      item?.value === values.idProofType
                                  )?.label,
                                  value: values.idProofType,
                                }
                            }
                          />
                        </div>
                        <div className="flex justify-between">
                          <span className="text-sm mt-2">Vehicle Type</span>
                          <DropdownFM2
                            options={societyStaffVehicleTypeOptions}
                            width={"224px"}
                            className="text-xs"
                            placeholder="Select Vehicle Type"
                            onSelect={(selectedItem) => {
                              setFieldValue(`vehicleType`, selectedItem.value);
                            }}
                            height="38px"
                            name={`vehicleType`}
                            value={{
                              label: societyStaffVehicleTypeOptions?.find(
                                (item) => item?.value === values.vehicleType
                              )?.label,
                              value: values.vehicleType,
                            }}
                          />
                        </div>
                        <div className="flex justify-between ">
                          <span className="text-sm mt-2">Type</span>
                          <DropdownFM2
                            options={MainVisitorTypeOptions}
                            width={"224px"}
                            className="text-xs"
                            placeholder="Select Type"
                            onSelect={(selectedItem) => {
                              setFieldValue(`visitorType`, selectedItem.value);
                            }}
                            height="38px"
                            name={`visitorType`}
                            value={
                              values.visitorType === ""
                                ? null
                                : {
                                  label: MainVisitorTypeOptions?.find(
                                    (item) =>
                                      item?.value === values.visitorType
                                  )?.label,
                                  value: values.visitorType,
                                }
                            }
                          />
                        </div>
                        <div className="flex justify-between text-sm mt-3">
                          <div>Laptop</div>
                          <div className="w-1/2">
                            <Checkbox
                              text={"Yes"}
                              className={"w-28"}
                              initialchecked={values.laptop === "Yes"}
                              onChange={(e) => {
                                setFieldValue(
                                  "laptop",
                                  e.target.checked ? "Yes" : "No"
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col w-1/2 ">
                        <div>
                          <div className="flex justify-between mb-1.5 h-[161px]">
                            <div>
                              {existingVisitorData?.profile_pic_url && (
                                <>
                                  <ImageComponent
                                    defaultUrl={
                                      existingVisitorData?.profile_pic_url
                                    }
                                    width={32}
                                    height={32}
                                  />
                                  <Checkbox
                                    text={"Use Old"}
                                    className={"text-xs mt-2"}
                                    initialchecked={values.useOld}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "photo",
                                        e.target.checked
                                          ? existingVisitorData?.profile_pic
                                          : ""
                                      );
                                      setFieldValue("useOld", e.target.checked);
                                    }}
                                  />
                                </>
                              )}
                            </div>
                            <CaptureImage
                              image={values.photo}
                              setImage={(url) => {
                                setFieldValue("photo", url);
                              }}
                            />
                          </div>
                        </div>
                        <TextInputFM
                          label="Count"
                          placeholder="Enter count"
                          className="w-56"
                          name="count"
                          type="number"
                          maxLength={3}
                        />
                        <TextInputFM
                          label="ID Proof Number"
                          placeholder="Enter Number"
                          className="w-56"
                          type="alphaNumber"
                          maxLength={12}
                          name="idProofNo"
                        />
                        <TextInputFM
                          label="Vehicle Number"
                          placeholder="Enter Number"
                          className="w-56"
                          name="vehicleNumber"
                          type="alphaNumber"
                          maxLength={10}
                        />
                        <TextInputFM
                          label="From"
                          placeholder="Enter"
                          className="w-56"
                          name="from"
                        />
                        <TextInputFM
                          label="Belongings"
                          placeholder="Enter "
                          className="w-56"
                          name="belongings"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="border-b border-[#CCC]"></div>
                  <div className="text-sm flex justify-between m-2">
                    <div className="w-1/4">Where To Visit</div>
                    <div className="w-3/4">
                      <FieldArray
                        name="whereToVisit"
                        render={(arrayHelpers) => (
                          <div>
                            <div>
                              {values.whereToVisit &&
                                values.whereToVisit.length > 0 &&
                                values.whereToVisit.map((entry, index) => (
                                  <AddDuplication
                                    key={index}
                                    index={index}
                                    onDelete={() => {
                                      handleDelete(arrayHelpers, index, entry);
                                    }}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                  />
                                ))}
                            </div>
                            <button
                              type="button"
                              onClick={() =>
                                arrayHelpers.push({
                                  wingId: "",
                                  flatList: [],
                                  flatId: "",
                                  memberList: [],
                                  memberId: "",
                                })
                              }
                              className="text-red-650 font-semibold border-2 border-dashed border-red-650 w-24 h-8 p-2 bg-[#FDF6F6] flex justify-center rounded-md items-center text-xs"
                            >
                              Add More
                            </button>
                          </div>
                        )}
                      ></FieldArray>
                    </div>
                  </div>
                  <div className="border-b border-[#CCC] mt-2"></div>
                  <div className="p-2 flex gap-6">
                    <div className="flex flex-col w-1/2">
                      <div className="flex justify-between  mt-2">
                        <span className="text-sm mt-2">Visit Purpose</span>
                        <DropdownFM2
                          options={
                            purposeList?.map((item) => ({
                              value: item?.purpose_id,
                              label: item?.purpose,
                            })) || []
                          }
                          width={"224px"}
                          className="text-xs"
                          placeholder={"Select Visit"}
                          onSelect={(selectedItem) => {
                            setFieldValue(`purposeId`, selectedItem.value);
                            setFieldValue(`purposeName`, selectedItem.label);
                          }}
                          height="36px"
                          name={`purposeId`}
                          {...(values.purposeId === "" && { value: null })}
                        />
                      </div>
                      <div>
                        <TextAreaFM
                          label="Comment"
                          placeholder="Enter Comment"
                          row="5"
                          className="w-56"
                          name="comment"
                        />
                      </div>
                      <div className="flex justify-end">
                        <ButtonG
                          label="Clear All"
                          type="button"
                          onClick={() => {
                            resetForm();
                            dispatch(clearVisitorData());
                            setFieldValue("profilePic", "");
                          }}
                          className="px-4 h-8"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col w-1/2">
                      <div className="w-full">
                        <div className="mt-4">
                          <Checkbox
                            text={"Add as Frequent Visitor"}
                            className={"text-sm"}
                            initialchecked={values.addAsFrequentVisitor === 1}
                            onChange={(e) => {
                              setFieldValue(
                                "addAsFrequentVisitor",
                                e.target.checked ? 1 : 0
                              );
                            }}
                          />
                        </div>

                        <div className="mt-5">
                          <Checkbox
                            text={"Send SMS to Member(s)"}
                            className={"text-sm"}
                            initialchecked={values.sendSMS === 1}
                            onChange={(e) => {
                              setFieldValue(
                                "sendSMS",
                                e.target.checked ? 1 : 0
                              );
                            }}
                          />
                        </div>

                        <div className="mt-4 gap-5 flex items-center">
                          <Checkbox
                            text={"Mobile Pin Check"}
                            className={"text-sm"}
                            initialchecked={values.mobilePINCheck === "Yes"}
                            onChange={undefined}
                          />
                          <span
                            className="text-red-650 underline cursor-pointer text-xs"
                            onClick={() => {
                              handleSendPin(errors.phone, values.phone);
                            }}
                          >
                            Send Pin
                          </span>
                          <TextInputFM
                            label=""
                            placeholder="Enter Pin"
                            className="w-24 -mb-4"
                            name="pin"
                            type="number"
                            maxLength={6}
                          />
                          <Button
                            type="button"
                            className={
                              values.pin?.length < 6 ? "opacity-50 h-9" : "h-9"
                            }
                            disabled={values.pin?.length < 6}
                            onClick={() => {
                              handleSendPIN(setFieldValue, values.pin);
                            }}
                            label="Check Pin"
                          />
                        </div>
                      </div>
                      <div className="mt-[42px]">
                        <Button
                          className="h-8"
                          label={
                            <span className="w-20 inline-block">
                              {updateLoader ? (
                                <CircularProgress
                                  sx={{ color: "white" }}
                                  size={17}
                                />
                              ) : (
                                "Check In Now"
                              )}
                            </span>
                          }
                        />
                      </div>
                    </div>
                  </div>
                </>
              }
            </Form>
          );
        }}
      </Formik>
      {/* </div> */}
    </>
  );
};

export const AddDuplication = ({ values, index, onDelete, setFieldValue }) => {
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { wingsData, flatsData } = useSelector((state) => state.wingsReducer);
  const { memberList } = useSelector(
    (state) => state.visitorAndVehicleInAndOutReducer
  );

  useEffect(() => {
    if (values.whereToVisit[index].wingId === flatsData?.wing?.wing_id)
      setFieldValue(
        `whereToVisit.${index}.flatList`,
        flatsData?.manage_flat_details
      );
  }, [flatsData]);

  useEffect(() => {
    if (values.whereToVisit[index].flatId === memberList?.flat_id)
      setFieldValue(`whereToVisit.${index}.memberList`, memberList?.flat_users);
  }, [memberList]);

  return (
    <div className="flex gap-3">
      <div className="flex gap-4 ">
        <DropdownFM2
          options={
            wingsData?.map((item) => ({
              value: item?.wing_id,
              label: item?.number,
            })) || []
          }
          width={"105px"}
          className="text-xs"
          placeholder={"Wing"}
          onSelect={(selectedItem) => {
            if (selectedItem.value !== values.whereToVisit[index].wingId) {
              setFieldValue(`whereToVisit.${index}.wingId`, selectedItem.value);
              setFieldValue(`whereToVisit.${index}.flatId`, "");
              setFieldValue(`whereToVisit.${index}.memberId`, "");
              dispatch(
                getFlatDetails({
                  societyId: loginData?.default_community?.community_id,
                  wingId: selectedItem?.value,
                })
              );
            }
          }}
          height="36px"
          name={`whereToVisit.${index}.wingId`}
          {...(!values.whereToVisit[index].wingId && { value: null })}
        />
        <DropdownFM2
          options={
            values.whereToVisit[index].wingId
              ? values.whereToVisit[index].flatList?.map((item) => ({
                value: item?.flat_id,
                label: item?.flat_no,
              }))
              : []
          }
          name={`whereToVisit.${index}.flatId`}
          width="105px"
          height="36px"
          placeholder="Flat/Unit"
          onSelect={(selectedItem) => {
            if (selectedItem.value !== values.whereToVisit[index].flatId) {
              setFieldValue(`whereToVisit.${index}.flatId`, selectedItem.value);
              setFieldValue(`whereToVisit.${index}.memberId`, "");
              dispatch(getFlatMemberList({ flatId: selectedItem?.value }));
            }
          }}
          {...(!values.whereToVisit[index].flatId && { value: null })}
        />
      </div>
      <DropdownFM2
        options={
          values.whereToVisit[index].flatId
            ? values.whereToVisit[index].memberList?.map((item) => ({
              value: item?.member_id,
              label: item?.name,
              memberMobileNo: `+${item?.mobile_country_code ? item?.mobile_country_code : "91"
                }-${item?.mobile_no}`,
            }))
            : []
        }
        name={`whereToVisit.${index}.memberId`}
        width="224px"
        height="36px"
        placeholder="Member Name"
        onSelect={(selectedItem) => {
          setFieldValue(`whereToVisit.${index}.memberId`, selectedItem.value);
          setFieldValue(
            `whereToVisit.${index}.memberMobileNo`,
            selectedItem.memberMobileNo
          );
        }}
        {...(!values.whereToVisit[index].memberId && { value: null })}
      />
      {values.whereToVisit?.length > 1 && (
        <DeleteIcon
          onClick={onDelete}
          className="fill-red-650 mt-3 cursor-pointer"
        />
      )}
    </div>
  );
};

export default MainVisitorForm;
