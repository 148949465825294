import React, { useState } from "react";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import { AppleStoreIcon, GooglePlayIcon, ZipgridIcon } from "../../../../../assets";
import IncermentDropdown from "../../../../../components/Dropdown/IncrementDropdown";
import { TextInput } from "../../../../../components/InputFields/TextInput";

const HeaderFooterSettings = ({
  setInvoiceSettingList,
  invoiceSettingList,
}) => {
  const [checked, setChecked] = useState(false);
  const [ticketPasses, setTicketPasses] = useState(0);

  const handleTicketPassesChange = (e) => {
    setTicketPasses(e.target.value);
  };

  const handleChecked = (event, setFieldValue) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      setFieldValue("isUrgent", "Yes");
    } else {
      setFieldValue("isUrgent", "No");
    }
  };
  console.log(invoiceSettingList)
  return (
    <div>
      <div className="mt-2">
        <table className="border-separate w-[65%]">
          <tbody>
            <tr className="items-center mb-4 text-sm">
              <td className="pb-4">Font Size (Print Invoice)</td>
              <td className="pb-4">
                <IncermentDropdown
                  id="invoiceFontSize"
                  type="number"
                  value={invoiceSettingList.printInvoiceFontSize}
                  className="w-[60px]"
                  onChange={(e)=>{
                    //invoiceSetting.printInvoiceFontSize = e.target.value
                    setInvoiceSettingList({...invoiceSettingList, printInvoiceFontSize: e.target.value });
                  }}
                />
                <span className="text-[#AAA] text-xs ml-2">(Recommended 18px to 30px)</span>
              </td>
            </tr>
            <tr className="items-center mb-4 text-sm">
              <td className="pb-4">Header Font Size(Society Name) </td>
              <td className="pb-4">
                <IncermentDropdown
                  id="headerFontSize"
                  type="number"
                  value={invoiceSettingList.headerInvoiceFontSize}
                  className="w-[60px]"
                  onChange={(e)=>{
                    //invoiceSetting.printInvoiceFontSize = e.target.value
                    setInvoiceSettingList({...invoiceSettingList, headerInvoiceFontSize: e.target.value });
                  }}
                />
                <span className="text-[#AAA] text-xs ml-2">(Recommended 18px to 30px)</span>
              </td>
            </tr>
            <tr className="items-center mb-4 text-sm">
              <td>Invoice Header</td>
              <td>
                <TextInput
                  value={invoiceSettingList.invheader}
                  className="w-56"
                  placeholder="Enter Header"
                  onChange={(e)=>{
                    //invoiceSetting.printInvoiceFontSize = e.target.value
                    setInvoiceSettingList({...invoiceSettingList, invheader: e.target.value });
                  }}
                />
              </td>
            </tr>
            <tr className="items-center text-sm">
              <td className="pb-4">Show Flat Address In the Invoice </td>
              <td className="pb-4">
                <Checkbox
                  text="Yes"
                  className="text-sm"
                  initialchecked={invoiceSettingList.showFlatAddress===1?true:false}
                  onChange={(e) => {
                    setInvoiceSettingList({...invoiceSettingList, showFlatAddress: e.target.checked ? 1 : 0 });
                  }}
                />
              </td>
            </tr>
            <tr className="items-center mb-4 text-sm">
              <td className="pb-4"> Show Unit As “Unit: Wing No. - Flat No.</td>
              <td className="pb-4">
                <Checkbox
                  text="Yes"
                  className="text-sm"
                  initialchecked={invoiceSettingList.unitFormate==="Yes"?true:false}
                  onChange={(e) => {
                    setInvoiceSettingList({...invoiceSettingList, unitFormate: e.target.checked ? "Yes" : "No" });
                  }}
                />
              </td>
            </tr>
            <tr className="items-center mb-4 text-sm">
              <td className="pb-4">Parking Number</td>
              <td className="pb-4">
                <Checkbox
                  text="Yes"
                  className="text-sm"
                  initialchecked={invoiceSettingList.showparkingno==="Yes"?true:false}
                  onChange={(e) => {
                    setInvoiceSettingList({...invoiceSettingList, showparkingno: e.target.checked ?"Yes" : "No" });
                  }}
                />
              </td>
            </tr>
            <tr className="items-center mb-4 text-sm">
              <td className="pb-4">Virtual Account Number</td>
              <td className="pb-4">
                <Checkbox
                  text="Yes"
                  className="text-sm"
                  initialchecked={invoiceSettingList.showvan==="Yes"?true:false}
                  onChange={(e) => {
                    setInvoiceSettingList({...invoiceSettingList, showvan: e.target.checked ?"Yes" : "No" });
                  }}
                />
              </td>
            </tr>
            <tr className="items-center mb-4 text-sm">
              <td className="pb-4">Show Tenant</td>
              <td className="pb-4">
                <Checkbox
                  text="Yes"
                  className="text-sm"
                  initialchecked={invoiceSettingList.showTenant==="Yes"?true:false}
                  onChange={(e) => {
                    setInvoiceSettingList({...invoiceSettingList, showTenant: e.target.checked ?"Yes" : "No"});
                  }}
                />
              </td>
            </tr>
            <tr className="items-center mb-4 text-sm">
              <td className="pb-4">Show Member Email ID</td>
              <td className="pb-4">
                <Checkbox
                  text="Yes"
                  className="text-sm"
                  initialchecked={invoiceSettingList.showemail==="Yes"?true:false}
                  onChange={(e) => {
                    setInvoiceSettingList({...invoiceSettingList, showemail: e.target.checked ? "Yes" : "No" });
                  }}
                />
              </td>
            </tr>
            <tr className="items-center mb-4 text-sm">
              <td className="pb-4">Show Member Phone Number</td>
              <td className="pb-4">
                <Checkbox
                  text="Yes"
                  className="text-sm"
                  initialchecked={invoiceSettingList.showphone==="Yes"?true:false}
                  onChange={(e) => {
                    setInvoiceSettingList({...invoiceSettingList, showphone: e.target.checked ? "Yes" : "No" });
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr className="fill-[#CCC]" />
      <div className="p-3 text-[#222222] text-sm">
        <div className="text-lg font-bold ms-10">
          Download ZIPGRID App to Avail Benefits of Smart Society...
        </div>
        <div className="flex">
          <div className="flex mt-4">
            <input type="radio" name="showInvoiceFooter" id="googlePlay" value="other" checked={invoiceSettingList.showInvoiceFooter==="other"} onChange={(e)=>{
              setInvoiceSettingList({...invoiceSettingList, showInvoiceFooter: "other" });
            }} className="mr-7" />
            <label htmlFor="googlePlay">
              <GooglePlayIcon />
            </label>
          </div>
          <div className="mt-4 ms-4">
            <label htmlFor="appleStore">
              <AppleStoreIcon />
            </label>
          </div>
        </div>

        <div className="flex mt-4">
          <div className="flex mt-4">
            <input type="radio" name="showInvoiceFooter" id="zipGrid"  checked={invoiceSettingList.showInvoiceFooter==="logo"} onChange={(e)=>{
              setInvoiceSettingList({...invoiceSettingList, showInvoiceFooter:"logo" });
            }} className="mr-7" />
            <label htmlFor="zipGrid">
              <ZipgridIcon />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderFooterSettings;
