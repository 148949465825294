import * as type from '../../actions/index.types'

export function loginReducer(state = {
  loading: false,
  reloginLoading: true,
  loginData: null,
  userWingsList: null,
  userFlatList: null,
  error:null
}, action) {

switch (action.type) {
  case type.LOGIN:
    console.log(action)
    return {
      ...state,
      loading: true,
      reloginLoading: action.data.autoLogin ? true : false,
      error: null,
    }
  case type.LOGIN_SUCCESS:{
    let uniqueWings = {}, uniqueFlats = {};
    if(action.payload?.user_type === 'Tenant' || action.payload?.user_type === 'User'){
        action.payload?.all_community_details?.find(item=>item.community_id === action.payload?.default_community?.community_id)?.blocks?.forEach(item => {
            uniqueWings[item.wing_id] = { number: item.block_name, wing_id: item.wing_id };
        });
        uniqueWings = Object.values(uniqueWings)
  
        action.payload?.all_community_details?.find(item=>item.community_id === action.payload?.default_community?.community_id)?.blocks?.forEach(item => {
            uniqueFlats[item.houseUniqueId] = { number: item.flat_no, flat_id: item.houseUniqueId, wing_id: item.wing_id  };
        });
        uniqueFlats = Object.values(uniqueFlats)
    }else{
      uniqueWings=null;
      uniqueFlats=null
    }

    return {
      ...state,
      error: null,
      loading: false,
      reloginLoading: false,
      loginData: action.payload,
      userWingsList: uniqueWings,
      userFlatList: uniqueFlats,
    }
  }
  case type.LOGIN_FAILURE :
    return {
      ...state,
      error: action?.message|| 'Somthing went wrong.',
      loading: false,
      reloginLoading: false
    }


    case type.RELOGIN:
      return {
        ...state,
        reloginLoading: true,
        error: null,
      }

    case type.RELOGIN_SUCCESS:{
      let uniqueWings = {}, uniqueFlats = {};
      if(action.payload?.user_type === 'Tenant' || action.payload?.user_type === 'User'){
          action.payload?.all_community_details?.find(item=>item.community_id === action.payload?.default_community?.community_id)?.blocks?.forEach(item => {
              uniqueWings[item.wing_id] = { number: item.block_name, wing_id: item.wing_id };
          });
          uniqueWings = Object.values(uniqueWings)
    
          action.payload?.all_community_details?.find(item=>item.community_id === action.payload?.default_community?.community_id)?.blocks?.forEach(item => {
              uniqueFlats[item.houseUniqueId] = { number: item.flat_no, flat_id: item.houseUniqueId, wing_id: item.wing_id  };
          });
          uniqueFlats = Object.values(uniqueFlats)
      }else{
        uniqueWings=null;
        uniqueFlats=null
      }

      return {
        ...state,
        loginData: action.payload,
        userWingsList: uniqueWings,
        userFlatList: uniqueFlats,
        error: null,
        reloginLoading: false,
      }
    }
  
    case type.RELOGIN_FAILURE:
      return {
        ...state,
        error: action?.message|| 'Somthing went wrong.',
        loading: false,
        reloginLoading: false
      }
    
    
      case type.LOGOUT_SUCCESS:
      return {
        ...state,
        error: null,
        loginData: null,
      }


    case type.UPDATE_SOCIETY_PROFILE_SUCCESS:
      return {
        ...state,
        loginData: {
          ...state.loginData,
          default_community: {
            ...state.loginData?.default_community,
            profile_photo_url: action.payload?.profileURL,
            community_name: action.payload?.societyName
          }
        }
      }
  default:
    return state
}
}