import React, { useEffect } from "react";
import { TextAreaFM } from "../../../../components/InputFields/TextArea";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import moment from "moment";
import { getFlatDetails, getWingsAndFlatDetails } from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import { DropdownFM2 } from "../../../../components/Dropdown/dropdown2";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage } from "formik";
import { validateDate } from "../../../../utils/helpers/universalFunctions";

const TaOtherDetails = ({values, setFieldValue}) => {
  const dispatch = useDispatch()
  const {wingsData, flatsData} = useSelector(state=>state.wingsReducer)
  const {loginData} = useSelector(state=>state.loginReducer)

  useEffect(()=>{
    const societyId= loginData?.default_community?.community_id
    dispatch(getWingsAndFlatDetails({societyId}))
  }, [])

  return (
    <div>
      <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg mt-3">
        <div className=" border-b border-[#CCC] rounded-tl-lg rounded-tr-lg pb-2 bg-[#FDF2F3]">
          <h1 className="text-base items-center p-2 -mb-2">Other Details</h1>
        </div>
        <div className="w-full gap-5 flex justify-between mt-2">
          <div className="w-[47%] p-2">
            <div className="flex flex-row justify-between">
              <span className="text-sm mt-2">Contract From Date</span>
              <div className="flex gap-3">
                <DatePickerComponentFM
                  className="w-56 h-9 ml-4 justify-between"
                  onDateChange={(selectedDate) => {
                    setFieldValue('fromDate', selectedDate)
                    if(validateDate(values.toDate) && moment(values.toDate).isBefore(new Date(selectedDate)))
                      setFieldValue(`toDate`, '')
                  }}
                  name='fromDate'
                  defaultValue={values.fromDate ? new Date(values.fromDate) : null}
                />
              </div>
            </div>
            <div className="flex flex-row justify-between">
              <span className="text-sm mt-2">Cargo Date</span>
              <div className="flex gap-3">
                <DatePickerComponentFM
                  className="w-56 h-9 ml-4 justify-between"
                  onDateChange={(selectedDate) => {
                    setFieldValue('cargoDate', selectedDate)
                  }}
                  name='cargoDate'
                  defaultValue={values.cargoDate ? new Date(values.cargoDate) : null}
                />
              </div>
            </div>

            <div>
              <TextAreaFM
                label="Description"
                placeholder="Enter Description"
                className="w-56 h-20"
                name='description'
              />
            </div>

            <div className="flex flex-row justify-between">
              <span className="text-sm mt-2">Select Wing</span>
              <div className="flex gap-3">
                <DropdownFM2
                  options={wingsData?.map((item)=>({
                    value: item?.wing_id,
                    label: item?.number
                  })) || []}
                  width={"224px"}
                  className="text-xs"
                  placeholder={"Select Wing"}
                  onSelect={(selectedItem)=>{
                    setFieldValue(`wingId`, selectedItem.value)
                    setFieldValue(`flatId`, '')
                    dispatch(getFlatDetails({societyId: loginData?.default_community?.community_id, wingId: selectedItem?.value}))
                  }}
                  height='38px'
                  name={`wingId`}
                  {...(values.wingId==='' && {value: null})}
                />
              </div>
            </div>
          </div>
          <div className="w-[47%] p-2">
            <div className="flex flex-row justify-between">
              <span className="text-sm mt-2">Contract to Date</span>
              <div className="flex gap-3 mr-4">
                <DatePickerComponentFM
                    className="w-56 h-9 ml-4 justify-between"
                    onDateChange={(selectedDate) => {
                      setFieldValue('toDate', selectedDate)
                    }}
                    name='toDate'
                    defaultValue={values.toDate ? new Date(values.toDate) : null}
                    {...(values.fromDate==='' && {value: null})}
                    {...(values.fromDate && {minDate: new Date(moment(values.fromDate).add(1, 'days'))})}
                    readOnly={!values.fromDate}
                  />
              </div>
            </div>
            <div className="flex flex-row justify-between mt-[154px]">
              <span className="text-sm mt-2">Select Flat</span>
              <div className="flex gap-3 mr-4">
                <DropdownFM2
                    options={values.wingId && flatsData?.manage_flat_details?.map((item)=>({
                    value: item?.flat_id,
                    label: item?.flat_no
                  })) || []}
                  width={"224px"}
                  className="text-xs"
                  placeholder={"Select Flat/Unit"}
                  onSelect={(selectedItem)=>{
                    setFieldValue(`flatId`, selectedItem.value)
                  }}
                  height='38px'
                  name={`flatId`}
                  {...(values.flatId==='' && {value: null})}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="p-2">
          <Checkbox 
            initialchecked={values.acceptTerms === 'Yes'}
            onChange={(e)=>{setFieldValue('acceptTerms', e.target.checked ? 'Yes' : '')}} />
          <span>
            I have read the society rules and Agree to Abide by the Rules.
          </span>
          <div className={`text-red-500 text-xs italic h-[16px]`}>
            <ErrorMessage name={'acceptTerms'}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaOtherDetails;
