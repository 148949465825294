import React, { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { EMPLOYEE_ENDPOINT } from "../../../../../Routing/routes";
import Layout from "../../../../Layout/Layout";
import ListHeaderBack from "../../../../../components/ListHeader/ListHeaderBack";
import ButtonG from "../../../../../components/Button/ButtonG";
import Button from "../../../../../components/Button/Button";
import { TextInputFM } from "../../../../../components/InputFields/TextInput";
import { TextAreaFM } from "../../../../../components/InputFields/TextArea";
import { Form, Formik } from "formik";
import { UpdateEmployeeValidation } from "../../../../../validationSchema/setupRecordsSchema/staffManagementSchema";
import { DropdownFM2 } from "../../../../../components/Dropdown/dropdown2";
import countries from "../../../../../consts/countries";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getEmployeeDetails,
  getEmployeeTypesList,
  getEmployeeUserNameList,
  updateEmployeeData,
} from "../../../../../redux/actions/SetupRecordsActions/StaffManagementActions/EmployeeManagementAction";
import Loader from "../../../../../components/Loader/Loader";
import { CircularProgress } from "@mui/material";

const EditEmployee = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const {
    updateLoader,
    employeeTypeList,
    loading,
    employeeDetails,
    employeeUserNameList,
  } = useSelector((state) => state.employeeManagementReducer);
  const { ipAddress } = useSelector((state) => state.ipAddressReducer);
  const { id, employeeDetailsId } = useParams();
  const formRef = useRef();

  const handleCancelClick = () => {
    navigate(EMPLOYEE_ENDPOINT);
  };

  const handleSaveClick = async (values, { resetForm, initialValues }) => {
    dispatch(
      updateEmployeeData({
        ...values,
        societyId: loginData?.default_community?.community_id,
        ipAddress,
        sessionId: loginData?.session_id,
        onSuccessCallback: () => {
          navigate(EMPLOYEE_ENDPOINT);
        },
      })
    );
  };

  useEffect(() => {
    dispatch(getEmployeeUserNameList());
    dispatch(getEmployeeDetails({ employeeId: id, employeeDetailsId }));
    dispatch(
      getEmployeeTypesList({
        societyId: loginData?.default_community?.community_id,
      })
    );
  }, []);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          innerRef={(f) => (formRef.current = f)}
          initialValues={{
            employeeId: employeeDetails?.emp_id,
            employeeDetailsId: employeeDetails?.emp_detail_id,
            employeeTypeId: employeeDetails?.emp_type_id || 0,
            fullName: employeeDetails?.emp_name || "",
            userName: employeeDetails?.username || "",
            mobileCountryCode: employeeDetails?.mobile_country_code || "",
            countryISO: countries?.find(
              (item) =>
                item.phonecode?.replace("+", "") ===
                employeeDetails?.mobile_country_code
            )?.iso,
            mobileNo: employeeDetails?.phone || "",
            email: employeeDetails?.email || "",
            address: employeeDetails?.address || "",
          }}
          enableReinitialize
          onSubmit={handleSaveClick}
          validationSchema={UpdateEmployeeValidation}
        >
          {({ values, errors, setFieldValue }) => {
            return (
              <Form>
                <div className="border-b border-[#CCC]">
                  <ListHeaderBack
                    title="Edit Employee Details"
                    onClick={handleCancelClick}
                  >
                    {/* Children content */}
                    <ButtonG
                      label="Reset"
                      onClick={() => {
                        formRef?.current?.resetForm();
                      }}
                      type="button"
                    />
                    <Button
                      label={
                        <span className="w-12 inline-block">
                          {updateLoader ? (
                            <CircularProgress
                              sx={{ color: "white" }}
                              size={17}
                            />
                          ) : (
                            "Update"
                          )}
                        </span>
                      }
                      type="submit"
                      className="px-4"
                    />
                  </ListHeaderBack>
                </div>
                {loading && id !== employeeDetails?.emp_id?.toString() ? (
                  <Loader />
                ) : (
                  <div className="flex justify-between gap-4 p-2">
                    <div className="flex flex-col w-1/2 pr-5">
                      <div className="flex justify-between mt-3">
                        <span className="text-sm mt-2">Employee Type</span>
                        <DropdownFM2
                          options={
                            employeeTypeList?.map((item) => ({
                              value: item?.emp_type_id,
                              label: item?.type,
                            })) || []
                          }
                          width={"208px"}
                          className="text-xs"
                          placeholder={"Select Employee Type"}
                          onSelect={(selectedItem) => {
                            setFieldValue(`employeeTypeId`, selectedItem.value);
                          }}
                          height="38px"
                          name={`employeeTypeId`}
                          value={{
                            value: values.emp_type_id,
                            label: employeeTypeList?.find(
                              (item) =>
                                item.emp_type_id === values.employeeTypeId
                            )?.type,
                          }}
                        />
                      </div>
                      <TextInputFM
                        label="Full Name"
                        placeholder="Enter Full Name"
                        className="w-52"
                        name="fullName"
                        maxLength={60}
                      />
                      <TextInputFM
                        label="User Name"
                        placeholder="Enter User Name"
                        className="w-52"
                        name="userName"
                        maxLength={30}
                        customError={
                          employeeUserNameList?.usernames
                            ?.split(",")
                            ?.includes(values.userName) &&
                          values.userName !== employeeDetails?.username
                            ? "Username already exists."
                            : ""
                        }
                      />
                      <div className="flex justify-between ">
                        <span className="text-sm mt-2">Country</span>
                        <DropdownFM2
                          options={
                            countries?.map((item) => ({
                              label: `${item?.name?.toUpperCase()} (${
                                item?.phonecode
                              })`,
                              value: item?.phonecode?.replace("+", ""),
                              iso: item.iso,
                              id: item?.id,
                            })) || []
                          }
                          width={"208px"}
                          className="text-xs"
                          placeholder={"Select Country"}
                          onSelect={(selectedItem) => {
                            setFieldValue(
                              `mobileCountryCode`,
                              selectedItem.value
                            );
                            setFieldValue(`countryISO`, selectedItem.iso);
                          }}
                          height="38px"
                          name={`mobileCountryCode`}
                          value={{
                            label: `${countries
                              ?.find(
                                (item) =>
                                  item.phonecode?.replace("+", "") ===
                                  values.mobileCountryCode
                              )
                              ?.name?.toUpperCase()} (+${
                              values.mobileCountryCode
                            })`,
                            value: values.mobileCountryCode,
                            iso: values.countryISO,
                          }}
                        />
                      </div>
                      <TextInputFM
                        label="Phone Number"
                        placeholder="Enter Phone Number"
                        className="w-52"
                        name="mobileNo"
                        type="number"
                        maxLength={12}
                      />
                      <TextInputFM
                        label="Email Address"
                        placeholder="Enter Email Address"
                        className="w-52"
                        name="email"
                      />
                      <TextAreaFM
                        label="Address"
                        placeholder="Enter Address"
                        className={"w-52"}
                        name="address"
                        maxLength={200}
                        row={4}
                      />
                    </div>
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    </Layout>
  );
};

export default EditEmployee;
