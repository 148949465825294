
import React, { useEffect,useState } from "react";
import Layout from "../../../Layout/Layout";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import Search from "../../../../components/SearchBox/Search";
import Button from "../../../../components/Button/Button";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import ManageCreditNoteTable from "./ManageCreditNoteTable";
import { ADD_CREDIT_NOTE_ENDPOINT, MANAGE_CREDIT_NOTE_ENDPOINT, UPLOAD_CREDIT_NOTE_ENDPOINT } from "../../../../Routing/routes";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getFlatDetails, getWingsAndFlatDetails } from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import { getManageCreditList } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageCreditNoteAction";
import { validateDate } from "../../../../utils/helpers/universalFunctions";
import moment from "moment";
import ButtonG from "../../../../components/Button/ButtonG";
import {
  getInvoiceTypeList,
} from "../../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";

const headCells = [
  { id: "sNo", label: "SNo.", sort: false, width: "40px" },
  { id: "invoiceNo", label: "Invoice No.", sort: true, width: "120px" },
  { id: "date", label: "Date", sort: true, width: "100px" },
  { id: "creditName", label: "Credit Name", sort: true, width: "230px" },
  { id: "amount", label: "Amount", sort: true, width: "120px" },
  { id: "remarks", label: "Remarks", sort: true, width: "120px" },
  { id: "status", label: "Status", sort: true, width: "100px" },
  {
    id: "action",
    label: "Action",
    sort: false,
  },
];

const ManageCreditNote = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {wingsData,flatsData} = useSelector(state=>state.wingsReducer)
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading, creditNoteList} = useSelector(state=>state.manageCreditNoteReducer)
  const { typeLists } = useSelector(
    (state) => state.invoiceSettingReducer
  );
  const [invoiceTypeList, setInvoiceTypeList] = useState([]);
  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleSearch = (text)=>{
    if(text && text !== searchParams.get("search")){
      searchParams.delete('page')
      searchParams.delete("search")
      navigate({
        pathname: `${MANAGE_CREDIT_NOTE_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["search", text?.trim()]])}`,
      })
    }
  }
  const handelSelectInvoiceType = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("typeid");
    navigate({
      pathname: `${MANAGE_CREDIT_NOTE_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["typeid", selectedItem.value],
      ])}`,
    });
  };
  const handelSelectWing = (selectedItem)=>{
    searchParams.delete('page')
    if(selectedItem.value?.toString() !== searchParams.get("wingId")){
      dispatch(getFlatDetails({societyId: loginData?.default_community?.community_id, wingId: selectedItem?.value}))

      searchParams.delete("wingId")
      searchParams.delete("flatId")
      navigate({
        pathname: `${MANAGE_CREDIT_NOTE_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["wingId", selectedItem.value]])}`,
      })
    }
  }

  const handelSelectFlat = (selectedItem)=>{
    searchParams.delete('page')
    if(selectedItem.value?.toString() !== searchParams.get("flatId")){
      searchParams.delete("flatId")
      navigate({
        pathname: `${MANAGE_CREDIT_NOTE_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["flatId", selectedItem.value]])}`,
      })
    }
  }


  const handleClear = ()=>{
    navigate(MANAGE_CREDIT_NOTE_ENDPOINT)
  }

  const getTableData =() =>{
    const data={
      societyId: loginData?.default_community?.community_id,
      page: searchParams.get("page") || 1,
      searchText: searchParams.get("search") || '',
      pageLimit: searchParams.get("pageLimit") || 10,
      wingId: searchParams.get("wingId") || '',
      flatId: searchParams.get("flatId") || '',
      InvoiceType: searchParams.get("typeid") || 1,
    }
    dispatch(getManageCreditList(data))
  }
  
  
  useEffect(()=>{
    let societyId= loginData?.default_community?.community_id
    dispatch(getWingsAndFlatDetails({societyId}))
    dispatch(getInvoiceTypeList({
      societyId: loginData?.default_community?.community_id,
    }))
    if(searchParams.get("wingId")){
      dispatch(getFlatDetails({societyId, wingId: searchParams.get("wingId")}))
    }

  }, [])
  
  useEffect(()=>{      
    getTableData()
  }, [searchParams])
  useEffect(() => {
    if (typeLists) {
      setInvoiceTypeList(typeLists)
    }
  }, [typeLists])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Credit Note">
            <div className="flex gap-3 text-sm">
              <div className="border-r border-[#CCC]"></div>
              <Button label="Upload Credit Excel" className="h-8" onClick={handleNavigation(UPLOAD_CREDIT_NOTE_ENDPOINT)} />
              <Button label="Add Credit Note" className="h-8 px-4" onClick={handleNavigation(ADD_CREDIT_NOTE_ENDPOINT)} />
            </div>
          </ListHeader>
        </div>

        <div className="flex flex-row justify-between gap-4 p-1 my-1">
          <Search 
            height="8" 
            onclick={handleSearch} 
            value={searchParams.get("search")} 
            placeholder="Search By Invoice No...."
          />
          <div className="flex items-center justify-between gap-3">
            {/* <div className="text-sm">Wing No.</div> */}
            <Dropdown2
              options={
                invoiceTypeList
                  ? invoiceTypeList?.map(
                    (item) => ({
                      label: item?.invoice_title,
                      value: item?.id,
                    })
                  )
                  : []
              }
              placeholder="Invoice type"
              className="text-[11px] ms-4"
              width="172px"
              height="32px"
              onSelect={(selectedItem) => {
                handelSelectInvoiceType(selectedItem);
              }}
              value={
                searchParams.get("typeid") && {
                  value: searchParams.get("typeid"),
                  label: invoiceTypeList ? invoiceTypeList?.find((item) => item?.id === parseInt(searchParams.get("typeid")))?.invoice_title : "",
                }
              }
            />
            <Dropdown2
              options={wingsData?.map((item)=>({
                value: item?.wing_id,
                label: item?.number
              })) || []}
              placeholder="Select Wing"
              className="text-[11px] ms-4"
              width="172px"
              height="32px"
              onSelect={(selectedItem) => {
                handelSelectWing(selectedItem)
              }}
              value={
                searchParams.get("wingId") && {
                  value: searchParams.get("wingId"),
                  label: wingsData?.find((item) => item.wing_id?.toString() === searchParams.get("wingId"))?.number
                }
              }
            />
              {/* <div className="text-sm">Flat No.</div> */}
              <Dropdown2
                options={searchParams.get('wingId') && flatsData?.manage_flat_details?.map((item)=>({
                  value: item?.flat_id,
                  label: item?.flat_no
                })) || []}
                placeholder="Select Flat"
                className="text-[11px] ms-4"
                width="172px"
                height="32px"
                onSelect={(selectedItem) => {
                  handelSelectFlat(selectedItem)
                }}
                value={
                  searchParams.get("flatId") && {
                    value: searchParams.get("flatId"),
                    label: flatsData?.manage_flat_details?.find((item) => item.flat_id?.toString() === searchParams.get("flatId"))?.flat_no
                  }
                }
              />
              <ButtonG label='Clear' className='h-8' onClick={handleClear} />
          </div>

        </div>
        <div>
          <ManageCreditNoteTable
            loading={loading}
            data={creditNoteList?.data?.map((item, index)=>({
              sNo: (((parseInt(creditNoteList?.paginate?.current)) * creditNoteList?.paginate?.limit + 1)+index)?.toString()?.padStart(2, "0"),
              creditNoteId: item?.id,
              jounralId: item?.journal_id,
              invoiceNo: item?.invoice_no,
              date: validateDate(item.date) ? moment(item.date)?.format('DD-MM-YYYY') : '-',
              creditName:  item.name || '-',
              remarks: item?.remarks || '-',
              status: item.pay_status || '-',
              amount: item?.amount ? parseFloat(item?.amount)?.toFixed(2) :  '0',
            }))}
            columns={headCells}
            checkbox={false}
            pagination={creditNoteList?.data?.length}
            totalCount={creditNoteList?.paginate?.total_count}
            totalPages={creditNoteList?.paginate?.total_page}
            start={(parseInt(creditNoteList?.paginate?.current)) * creditNoteList?.paginate?.limit + 1}
            end={(parseInt(creditNoteList?.paginate?.current)) * creditNoteList?.paginate?.limit + creditNoteList?.data?.length}
            currentPage={parseInt(creditNoteList?.paginate?.current)+1}
            rowsPerPage={searchParams.get("pageLimit") || 10}
            getTableData={getTableData}
          ></ManageCreditNoteTable>
          </div>
          {!creditNoteList?.data?.length && !loading && (
            <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
              No Records
            </div>
          )}
      </div>
    </Layout>
  );
};

export default ManageCreditNote;
