export const AuditData = [
    {
      label: "Member Details",
      key: 'occupant_audit_log',
      values: [
        { key: "Name", label: "Name", value: "" },
        { key: "Email ID", label: "Email ID", value: "" }
      ],
    },
    {
      label: "Flat Details",
      key: 'flat_audit_log',
      values: [
        { key: "flat no", label: "Flat Number", value: "" },
        { key: "floor no", label: "Floor Number", value: "" },
        { key: "area sq.ft", label: "Area", value: "" },
        { key: "van", label: "Virtual Account Number", value: "" },
      ],
    },
    {
      label: "Family Details",
      key: 'family_audit_log',
      values: [
        { key: "Name", label: "Name", value: "" },
        { key: "Date Of Birth", label: "Date Of Birth", value: "" },
        { key: "Anniversary Date", label: "Anniversary Date", value: "" },
        { key: "Email", label: "Email", value: "" },
        { key: "Contact No", label: "Contact No", value: "" },
        { key: "Profession", label: "Profession", value: "" },
        { key: "Email", label: "Email", value: "" },
        { key: "Relationship", label: "Relationship", value: "" },
      ],
    },
    {
      label: "Staff Details",
      key: 'staff_audit_log',
      values: [
        { key: "Name of Staff", label: "Name of Staff", value: "" },
        { key: "Type Of Staff", label: "Type Of Staff", value: "" },
        { key: "Issued on", label: "Issued on", value: "" },
        { key: "Valid Upto", label: "Valid Upto", value: "" },
        { key: "Address", label: "Address", value: "" },
        { key: "Duty Time", label: "Duty Time", value: "" },
        { key: "ID Card No", label: "ID Card No", value: "" },
        { key: "Emergency Contact", label: "Emergency Contact", value: "" },
        { key: "Blood Group", label: "Blood Group", value: "" },
        { key: "Date Of Birth", label: "Date Of Birth", value: "" },
        { key: "Driving License No", label: "Driving License No", value: "" },
      ],
    },
    {
      label: "Nominne",
      key: 'nominee_audit_log',
      values: [
        { key: "Nomination Serial No", label: "Nomination Serial No", value: "" },
        { key: "Nominee Name", label: "Nominee Name", value: "" },
        //{ key: "Address Of Nominee", label: "Address Of Nominee", value: "" },
        //{ key: "Relationship", label: "Relationship", value: "" },
        { key: "Share Of Nominee", label: "Share Of Nominee", value: "" },
        //{ key: "Date Of Birth", label: "Date Of Birth", value: "" },
        { key: "Date Of Nomination", label: "Date Of Nomination", value: "" },
        { key: "Date Of Meeting", label: "Date Of Meeting", value: "" },
      ],
    },
    {
      label: "Vehicle Details",
      key: 'vehicle_audit_log',
      values: [
        { key: "Parking Sticker No", label: "Parking Number", value: "" },
        { key: "Parking Type", label: "Parking Type", value: "" },
        { key: "Parking No", label: "Parking No", value: "" },
        { key: "Vehicle Type", label: "Vehical Type", value: "" },
        { key: "Vehicle Number", label: "Vehicle Number", value: "" },
        { key: "Vehicle Brand", label: "Vehicle Brand", value: "" },
        { key: "Vehicle Model", label: "Vehical Modal", value: "" },
        { key: "Year", label: "Year", value: "" },
      ],
    },
  ];
  

  export const MemberDetailstData = [
    {
      label: "Family Details",
      key: 'family_audit_log',
      values: [
        { label: "Name", key: "full_name", value: "" },
        { label: "Age", key: "age", value: "" },
        { label: "Gender", key: "gender", value: "" },
        { label: "Relationship", key: "relationship", value: "" },
        { label: "Contact No", key: "contact_no", value: "" },
        { label: "Profession", key: "profession", value: "" },
        { label: "Date Of Birth", key: "dob", value: "" },
        { label: "Anniversary Date", key: "anniversary_date", value: "" },
        { label: "Blood Group", key: "blood_group", value: "" },
        { label: "Other Details", key: "other_details", value: "" },
      ],
    },
    {
      label: "Staff Details",
      key: 'staff_audit_log',
      values: [
        { key: "full_name", label: "Name", value: "" },
        { key: "society_staff_type", label: "Type Of Staff", value: "" },
        { key: "staff_code", label: "Staff Code", value: "" },
        { key: "phone_number", label: "Contact", value: "" },
        { key: "staff_address", label: "Address", value: "" },
        { key: "gender", label: "Gender", value: "" },
        { key: "dob", label: "Date Of Birth", value: "" },
        { key: "staff_vehicle_number", label: "Vehicle Number", value: "" },
        { key: "rating", label: "Rating", value: "" },
      ],
    },
    {
      label: "Nominee Details",
      key: 'nominee_audit_log',
      values: [
        { key: "full_name", label: "Nominee Name", value: "" },
        { key: "address", label: "Address Of Nominee", value: "" },
        { key: "relationship", label: "Relationship", value: "" },
        { key: "share", label: "Share Of Nominee", value: "" },
        { key: "dob", label: "Date Of Birth", value: "" },
        { key: "date_of_nomination", label: "Date Of Nomination", value: "" },
      ],
    },
    {
      label: "Vehicle Details",
      key: 'vehicle_audit_log',
      values: [
        { key: "parking_lot", label: "Parking Sticker No.", value: "" },
        { key: "parking_type", label: "Parking Type", value: "" },
        { key: "parking_no", label: "Parking No", value: "" },
        { key: "location", label: "Location", value: "" },
        { key: "vehicle_type", label: "Vehical Type", value: "" },
        { key: "vehicle_no", label: "Vehicle Number", value: "" },
        { key: "vehicle_brand", label: "Vehicle Brand", value: "" },
        { key: "vehicle_model", label: "Vehical Modal", value: "" },
        { key: "vehicle_reg_year", label: "Year", value: "" },
      ],
    },
  ];
  