import * as types from '../../index.types';

export function getManageInvoiceCurrent(data) {
    return {
        type: types.GET_MANAGE_INVOICE_CURRENT,
        data,
    };
}

export function getManageInvoicePrevious(data) {
    return {
        type: types.GET_MANAGE_INVOICE_PREVIOUS,
        data,
    };
}

export function getManageInvoiceDraft(data) {
    return {
        type: types.GET_MANAGE_INVOICE_DRAFT,
        data,
    };
}

export function getManageInvoiceActionsList(data) {
    return {
        type: types.GET_MANAGE_INVOICE_ACTIONS_LIST,
        data,
    };
}

export function getAdjustmentPaymentDetails(data) {
    return {
        type: types.ADJUSTMENT_PAYMENT_DETAILS,
        data,
    };
}

export function getAdjustmentPaymentGetAmount(data) {
    return {
        type: types.ADJUSTMENT_PAYMENT_GET_AMOUNT,
        data,
    };
}


export function getAdjustmentPaymentSave(data) {
    return {
        type: types.ADJUSTMENT_PAYMENT_SAVE,
        data,
    };
}

export function downloadManageInvoicePaymentExcelSample(data) {
    return {
        type: types.DOWNLOAD_MANAGE_INVOICE_PAYMENT_EXCEL_SAMPLE,
        data,
    };
}

export function uploadManageInvoicePaymentExcel(data) {
    return {
        type: types.UPLOAD_MANAGE_INVOICE_PAYMENT_EXCEL,
        data,
    };
}

export function saveManageInvoicePaymentExcel(data) {
    return {
        type: types.SAVE_MANAGE_INVOICE_PAYMENT_EXCEL,
        data,
    };
}

export function getEditInvoiceDetails(data) {
    return {
        type: types.GET_EDIT_INVOICE_DETAILS,
        data,
    };
}
export function updateInvoiceDetails(data) {
    return {
        type: types.EDIT_INVOICE_DETAILS,
        data,
    };
}

export function deleteInvoice(data) {
    return {
        type: types.DELETE_INVOICE,
        data,
    };
}

export function rejectInvoicePayment(data) {
    return {
        type: types.REJECT_INVOICE,
        data,
    };
}
export function updateInvoiceRemarks(data) {
    return {
        type: types.UPDATE_INVOICE_REMARKS,
        data,
    };
}

export function getVerifyPostPaymentDetails(data) {
    return {
        type: types.VERIFY_POST_PAYMENT_DETAILS,
        data,
    };
}
export function verifyPostPaymentUpdate(data) {
    return {
        type: types.VERIFY_POST_PAYMENT_UPDATE,
        data,
    };
}
export function bulkVerifyPostPayment(data) {
    return {
        type: types.BULK_VERIFY_POST_PAYMENT_UPDATE,
        data,
    };
}
export function sendInvoiceReminderEmailSms(data) {
    return {
        type: types.SEND_INVOICE_REMINDER_EMAIL_SMS,
        data,
    };
}
export function sendResendReceiptEmailSms(data) {
    return {
        type: types.SEND_RESEND_RECEIPT_EMAIL_SMS,
        data,
    };
}
export function resendInvoiceEmailSms(data) {
    return {
        type: types.SEND_INVOICE_EMAIL_SMS,
        data,
    };
}

export function downloadInvoiceDraftExcel(data) {
    return {
        type: types.DOWNLOAD_INVOICE_DRAFT_EXCEL,
        data,
    };
}