import React, { useEffect, useState } from "react";
import Layout from "../../../Layout/Layout";
import RangeDatePicker from "../../../../components/DatePicker/RangeDatePicker";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import Search from "../../../../components/SearchBox/Search";
import Button from "../../../../components/Button/Button";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import ManageInvoicePreviousTable from "../../../../components/TableComponent/ManageInvoicePreviousTable";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  MANAGE_INVOICE_CURRENT_ENDPOINT,
  MANAGE_INVOICE_DRAFTS_ENDPOINT,
  MANAGE_INVOICE_PREVIOUS_ENDPOINT,
  GENERATE_INVOICE_ENDPOINT,
} from "../../../../Routing/routes";
import { getManageInvoicePrevious } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageInvoiceActions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  getFlatDetails,
  getWingsAndFlatDetails,
} from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import {
  getInvoiceTypeList,
} from "../../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";
import ButtonG from "../../../../components/Button/ButtonG";

const headingsInvoice = [
  { id: "sNo", label: "SNo.", sort: false, width: "40px" },
  { id: "invoiceNo", label: "Invoice No.", sort: true, width: "120px" },
  { id: "memberDetails", label: "Member Details", sort: true, width: "130px" },
  { id: "invoiceDate", label: "Invoice Date", sort: true, width: "120px" },
  {
    id: "generationDate",
    label: "Generation Date",
    sort: true,
    width: "130px",
  },
  { id: "amount", label: "Amount", sort: true, width: "150px" },
  { id: "status", label: "Status", sort: true, width: "90px" },
  { id: "remarks", label: "Remarks", sort: true, width: "90px" },
  {
    id: "action",
    label: "Action",
    sort: false,
  },
];

const ManageInvoicePrevious = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loginData } = useSelector((state) => state.loginReducer);
  const [invoiceTypeList, setInvoiceTypeList] = useState([]);
  const { manageInvoicePrevious, loading } = useSelector(
    (state) => state.manageInvoiceReducer
  );
  const { typeLists } = useSelector(
    (state) => state.invoiceSettingReducer
  );
  const { wingsData, flatsData } = useSelector((state) => state.wingsReducer);

  const handleNavigation = (path) => () => {
    navigate(path);
  };
  const handleSearch = (text) => {
    if (text && text !== searchParams.get("search")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("search");
      navigate({
        pathname: `${MANAGE_INVOICE_PREVIOUS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["search", text],
        ])}`,
      });
    }
  };
  const handelSelectInvoiceType = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("typeid");
    navigate({
      pathname: `${MANAGE_INVOICE_PREVIOUS_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["typeid", selectedItem.value],
      ])}`,
    });
  };
  const handelSelectStatus = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("status");
    navigate({
      pathname: `${MANAGE_INVOICE_PREVIOUS_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["status", selectedItem.value],
      ])}`,
    });
  };

  const handleSelectWing = (selectedItem) => {
    if (selectedItem.value?.toString() !== searchParams.get("wingId")) {
      dispatch(
        getFlatDetails({
          societyId: loginData?.default_community?.community_id,
          wingId: selectedItem?.value,
        })
      );
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("wingId");
      searchParams.delete("flatId");
      navigate({
        pathname: `${MANAGE_INVOICE_PREVIOUS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["wingId", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleSelectFlat = (selectedItem) => {
    if (selectedItem.value?.toString() !== searchParams.get("flatId")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("flatId");
      navigate({
        pathname: `${MANAGE_INVOICE_PREVIOUS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["flatId", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleStartDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !==
      searchParams.get("startDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("startDate");
      navigate({
        pathname: `${MANAGE_INVOICE_PREVIOUS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["startDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleEndDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !== searchParams.get("endDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("endDate");
      navigate({
        pathname: `${MANAGE_INVOICE_PREVIOUS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["endDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleClear = () => {
    navigate(MANAGE_INVOICE_PREVIOUS_ENDPOINT);
  };

  useEffect(() => {
    let societyId = loginData?.default_community?.community_id;
    dispatch(getWingsAndFlatDetails({ societyId }));
    dispatch(getInvoiceTypeList({
      societyId: loginData?.default_community?.community_id,
    }))
    if (searchParams.get("wingId")) {
      dispatch(
        getFlatDetails({ societyId, wingId: searchParams.get("wingId") })
      );
    }
  }, []);
  useEffect(() => {
    if (typeLists) {
      setInvoiceTypeList(typeLists)
    }
  }, [typeLists])
  useEffect(() => {
    dispatch(
      getManageInvoicePrevious({
        societyId: loginData?.default_community?.community_id,
        page: searchParams.get("page") || 1,
        pageLimit: searchParams.get("pageLimit") || 10,
        invoiceno: searchParams.get("invoiceno") || "",
        status: searchParams.get("status") || "",
        from_date: searchParams.get("startDate") || "",
        to_date: searchParams.get("endDate") || "",
        searchBy: searchParams.get("search") || "",
        wingId: searchParams.get("wingId") || "",
        flatId: searchParams.get("flatId") || "",
        InvoiceType: searchParams.get("typeid") || 1,
      })
    );
  }, [searchParams]);
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Invoice - Previous">
            <div className="flex gap-3 text-sm">
              <Button
                label="Current"
                className="h-8"
                onClick={handleNavigation(MANAGE_INVOICE_CURRENT_ENDPOINT)}
              />
              <Button
                label="Draft"
                className="h-8 px-4"
                onClick={handleNavigation(MANAGE_INVOICE_DRAFTS_ENDPOINT)}
              />
              <div className="border-r border-[#CCC]"></div>
              <Button className="" label="Generate Invoice" onClick={handleNavigation(GENERATE_INVOICE_ENDPOINT)} />
            </div>
          </ListHeader>
        </div>

        <div className="flex flex-row justify-between gap-4 p-1 my-1">
          <Search
            height="8"
            onclick={handleSearch}
            value={searchParams.get("search")}
            placeholder="Search..."
          />

          <Dropdown2
            options={
              manageInvoicePrevious?.data?.date_filters_list
                ? Object.entries(
                  manageInvoicePrevious?.data?.date_filters_list
                )?.map((item) => ({
                  label: item?.[1],
                  value: item?.[0],
                }))
                : []
            }
            placeholder="Search By Date"
            className="text-[11px] ms-4"
            width="152px"
            height="32px"
            onSelect={(selectedItem) => {
              handelSelectStatus(selectedItem);
            }}
            value={
              searchParams.get("status") && {
                value: searchParams.get("status"),
                label: manageInvoicePrevious?.data?.date_filters_list
                  ? Object.entries(
                    manageInvoicePrevious?.data?.date_filters_list
                  )?.find((item) => item[0] === searchParams.get("status"))[1]
                  : [],
              }
            }
          />
          <Dropdown2
            options={
              wingsData?.map((item) => ({
                value: item?.wing_id,
                label: item?.number,
              })) || []
            }
            placeholder="Select Wing"
            className="text-[11px] ms-4"
            width="152px"
            height="32px"
            onSelect={(selectedItem) => {
              handleSelectWing(selectedItem);
            }}
            value={
              searchParams.get("wingId") && {
                value: searchParams.get("wingId"),
                label: wingsData?.find(
                  (item) =>
                    item.wing_id?.toString() === searchParams.get("wingId")
                )?.number,
              }
            }
          />
          <Dropdown2
            options={
              (searchParams.get("wingId") &&
                flatsData?.manage_flat_details?.map((item) => ({
                  value: item?.flat_id,
                  label: item?.flat_no,
                }))) ||
              []
            }
            placeholder="Select Flat"
            className="text-[11px] ms-4"
            width="152px"
            height="32px"
            onSelect={(selectedItem) => {
              handleSelectFlat(selectedItem);
            }}
            value={
              searchParams.get("flatId") && {
                value: searchParams.get("flatId"),
                label: flatsData?.manage_flat_details?.find(
                  (item) =>
                    item.flat_id?.toString() === searchParams.get("flatId")
                )?.flat_no,
              }
            }
          />
          <RangeDatePicker
            className={"w-[180px] h-8"}
            defaultStartValue={
              searchParams.get("startDate")
                ? new Date(searchParams.get("startDate"))
                : ""
            }
            defaultEndValue={
              searchParams.get("endDate")
                ? new Date(searchParams.get("endDate"))
                : ""
            }
            onStartDateChange={handleStartDate}
            onEndDateChange={handleEndDate}
          />
          <ButtonG label="Clear" className="h-8" onClick={handleClear} />
        </div>
        <div className="flex flex-row justify-between gap-4 p-1 my-1">
          <Dropdown2
            options={
              invoiceTypeList
                ? invoiceTypeList?.map(
                  (item) => ({
                    label: item?.invoice_title,
                    value: item?.id,
                  })
                )
                : []
            }
            placeholder="Invoice type"
            className="text-[11px] ms-4"
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              handelSelectInvoiceType(selectedItem);
            }}
            value={
              searchParams.get("typeid") && {
                value: searchParams.get("typeid"),
                label: invoiceTypeList ? invoiceTypeList?.find((item) => item?.id === parseInt(searchParams.get("typeid")))?.invoice_title : "",
              }
            }
          />
        </div>
        <div>
          <ManageInvoicePreviousTable
            loading={loading}
            data={manageInvoicePrevious?.data?.invoice_list?.map(
              (item, index) => ({
                sNo:
                  manageInvoicePrevious?.data?.paginate?.current *
                  manageInvoicePrevious?.data?.paginate?.limit +
                  1 +
                  index,
                id: item.id,
                invoiceNo: item?.invoice_no,
                memberName: item?.member?.subscriber_name,
                wingNo: item?.flat?.wing?.number,
                flatNo: item.flat_no,
                invoiceDate: item.invoice_date
                  ? moment(new Date(item?.invoice_date))?.format("DD-MM-YYYY")
                  : "-",
                generationDate: item.date
                  ? moment(new Date(item?.date))?.format("DD-MM-YYYY")
                  : "-",
                amount: item.grandtotal,
                amountPaid: item.amount_paid,
                amountDue: item.dueAmount,
                status: item.status,
                payid: item.payments ? item.payments.map((payment) => payment.id) : []
              })
            )}
            columns={headingsInvoice}
            checkbox={true}
            pagination={manageInvoicePrevious?.data?.invoice_list?.length}
            totalCount={manageInvoicePrevious?.data?.paginate?.total_count}
            totalPages={manageInvoicePrevious?.data?.paginate?.total_page}
            start={
              manageInvoicePrevious?.data?.paginate?.current *
              manageInvoicePrevious?.data?.paginate?.limit +
              1
            }
            end={
              manageInvoicePrevious?.data?.paginate?.current *
              manageInvoicePrevious?.data?.paginate?.limit +
              manageInvoicePrevious?.data?.invoice_list?.length
            }
            currentPage={
              parseInt(manageInvoicePrevious?.data?.paginate?.current) + 1
            }
            rowsPerPage={searchParams.get("pageLimit") || 10}
          ></ManageInvoicePreviousTable>

          {!manageInvoicePrevious?.data?.invoice_list?.length && !loading ? (
            <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
              No Records
            </div>
          ) : <></>}
        </div>
      </div>
    </Layout>
  );
};

export default ManageInvoicePrevious;
