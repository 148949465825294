import * as types from '../../index.types';

export function getTitleList(data) {
    return {
        type: types.GET_TITLE_LIST,
        data
    };
}

export function getOccupantsDetailsList(data) {
    return {
        type: types.GET_OCCUPANTS_DETAILS_LIST,
        data
    };
}

export function downloadOccupantsDetailsList(data) {
    return {
        type: types.DOWNLOAD_OCCUPANTS_DETAILS_LIST,
        data
    };
}

export function sendEmailToOccupant(data) {
    return {
        type: types.SEND_EMAIL_TO_OCCUPANT,
        data
    };
}

export function changeOccupantStatus(data) {
    return {
        type: types.CHANGE_OCCUPANT_STATUS,
        data
    };
}

export function changeOccupantStatusInBulk(data) {
    return {
        type: types.CHANGE_OCCUPANT_STATUS_IN_BULK,
        data
    };
}

export function getMemberPrivacy(data) {
    return {
        type: types.GET_MEMBERS_PRIVACY,
        data
    };
}

export function updateMemberPrivacy(data) {
    return {
        type: types.UPDATE_MEMBERS_PRIVACY,
        data
    };
}

export function addOccupant(data) {
    return {
        type: types.ADD_OCCUPANTS,
        data
    };
}

export function deleteOccupants(data) {
    return {
        type: types.DELETE_OCCUPANT,
        data
    };
}

export function getOccupantsAuditLogs(data) {
    return {
        type: types.GET_OCCUPANTS_AUDIT_LOGS,
        data
    };
}

export function getExistingOccupants(data) {
    return {
        type: types.GET_EXISTING_OCCUPANTS,
        data
    };
}

export function addExistingOccupants(data) {
    return {
        type: types.ADD_EXISTING_OCCUPANTS,
        data
    };
}

export function getOccupantDetails(data) {
    return {
        type: types.GET_OCCUPANTS_DETAILS,
        data
    };
}

export function updateOccupantDetails(data) {
    return {
        type: types.UPDATE_OCCUPANTS_DETAILS ,
        data
    }
}

export function getArchiveOccupantsList(data) {
    return {
        type: types.GET_ARCHIVE_OCCUPANTS,
        data
    };
}

export function getMortgageDetails(data) {
    return {
        type: types.GET_MORTGAGE_DETAILS,
        data
    }
}

export function addMortgageDetails(data) {
    return {
        type: types.ADD_MORTGAGE_DETAILS,
        data
    }
}

export function addFamilyMember(data){
    return {
        type: types.ADD_FAMILY_MEMBER,
        data
    }
}

export function deleteFamilyMember(data) {
    return {
        type: types.DELETE_FAMILY_MEMBER,
        data
    };
}

export function getOccupantsStaffList(data) {
    return {
        type: types.GET_OCCUPANTS_STAFF_DETAILS,
        data,
    }
}

export function updateFamilyMember(data) {
    return {
        type: types.UPDATE_FAMILY_MEMBER,
        data,
    }
}

export function addFamilyMemberThroughExcel(data) {
    return {
        type: types.ADD_FAMILY_MEMBER_THROUGH_EXCEL,
        data,
    }
}

export function updateFamilyMemberThroughExcel(data) {
    return {
        type: types.UPDATE_FAMILY_MEMBER_THROUGH_EXCEL,
        data,
    }
}

export function downloadExcelSampleOfFamilyMember(data) {
    return {
        type: types.DOWNLOAD_FAMILY_MEMBER_EXCEL_SAMPLE,
        data,
    }
}

export function getFlatDocuments(data) {
    return {
        type: types.GET_FLAT_DOCUMENTS,
        data,
    }
}

export function updateFlatDocuments(data) {
    return {
        type: types.UPDATE_FLAT_DOCUMENTS,
        data,
    }
}

export function getNomineeList(data) {
    return {
        type: types.VIEW_NOMINEE_DETAILS,
        data,
    }
}

export function addUpdateNominee(data) {
    return {
        type: types.ADD_UPDATE_NOMINEE_DETAILS,
        data,
    }
}

export function deleteNominee(data) {
    return {
        type: types.DELETE_NOMINEE_DETAILS,
        data,
    }
}

export function getVehicleDetails(data) {
    return {
        type: types.GET_VEHICLE_DETAILS,
        data
    };
}

export function getParkingTypeList(data) {
    return {
        type: types.GET_PARKING_TYPE_LIST,
        data
    };
}

export function updateVehicleDetails(data) {
    return {
        type: types.UPDATE_VEHICLE_DETAILS,
        data
    };
}

export function deleteVehicleDetails(data) {
    return {
        type: types.DELETE_VEHICLE_DETAILS,
        data
    };
}