import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import "./Table.css";
import { DeleteIcon, EditIcon, SortIcon } from "../../assets";
import styled from "@emotion/styled";
import ItemsPerPage from "../ItemsPerPage/ItemsPerPage";
import Pagination from "../Pagination/Pagination";
import {
  stableSort,
  getComparator,
} from "../../utils/helpers/universalFunctions";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { EDIT_DOCUMENT_ENDPOINT } from "../../Routing/routes";
import DeleteModal from "../Modal/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { deleteDocuments } from "../../redux/actions/DeskActions/DocumentActions";
import { downloadFileFromURL } from "../../utils/helpers/downloadMedia";
import { getPermission } from "../../utils/helpers/getPermissions";
import * as permissions from '../../Routing/permissions'

function ActionComponent({ id, filename }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { rootFolderId, subFolderId } = useSelector(
    (state) => state.documentReducer
  );
  const { loginData } = useSelector((state) => state.loginReducer);

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleDelete = () => {
    let data = {
      fileId: id,
      onSuccessCallback: () => {
        setShowDeleteModal(false);
      },
    };
    dispatch(deleteDocuments(data));
  };
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {
        getPermission(loginData, [permissions.EDIT_DOCUMENTS_PERMISSION]) &&
        <Tooltip title="Edit" arrow>
          <EditIcon
            className="fill-[#555] hover:fill-red-650 me-3"
            onClick={() => {
              navigate(`${EDIT_DOCUMENT_ENDPOINT}/${id}`, {
                state: { rootFolderId, subFolderId, filename },
              });
            }}
          />
        </Tooltip>
      }
      {
        getPermission(loginData, [permissions.DELETE_DOCUMENTS_PERMISSION]) &&
        <Tooltip title="Delete" arrow>
          <DeleteIcon
            className="fill-[#555] hover:fill-red-650 me-3"
            onClick={() => handleDeleteClick()}
          />
        </Tooltip>
      }
      {/* Delete Confirmation Modal */}
      <DeleteModal
        isOpen={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onDelete={handleDelete}
      />
    </Box>
  );
}


  const TableRowStyled = styled(TableRow)`
    &:nth-of-type(odd) {
      background-color: #ffffff;
    }
    &:nth-of-type(even) {
      background-color: #f4f5f8;
    }
    &:nth-of-type(odd):hover {
      background-color: #ffffff;
    }
    &:nth-of-type(even):hover {
      background-color: #f4f5f8;
    }
  `;

  const DocumentsTable = ({
  children,
  data,
  columns,
  checkbox = true,
  pagination = true,
  setAnyChecked,
  //props are required if pagination is true
  totalCount,
  totalPages,
  start,
  end,
  currentPage,

  getTableData,
}) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);

  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(currentPage || 1);
  const [rowsPerPage, setRowsPerPage] = useState(
    !pagination ? data.length : 10
  );
  const { loginData } = useSelector((state) => state.loginReducer);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    const newSelected = event.target.checked ? data.map((item) => item.id) : [];
    setSelected(newSelected);
  };

  const handleClick = (event, id) => {
    const newSelected = selected.includes(id)
      ? selected.filter((selectedId) => selectedId !== id)
      : [...selected, id];
    setSelected(newSelected);
  };

  const handleChangePage = (newPage) => {
    if (page !== newPage) {
      setPage(newPage);
      getTableData(rowsPerPage, newPage);
    }
  };

  const handleChangeRowsPerPage = (value) => {
    if (rowsPerPage !== value) {
      getTableData(value, page);
      setRowsPerPage(value);
      setPage(0);
    }
  };

  const isSelected = (id) => selected.includes(id);

  const [checkedData, setCheckedData] = useState({});

  const checkedInputData = (key) => {
    if (checkedData.hasOwnProperty(key)) return checkedData[key];
    else return false;
  };

  const handleCheckedData = (key) => (isChecked) => {
    setCheckedData((prev) => ({ ...prev, [key]: isChecked }));
  };

  const handleDownload = async (filePath, fileName) => {
    downloadFileFromURL({ url: filePath, fileName });
  };

  return (
    <Box
      sx={{ width: "100%", height: "67%", fontSize: "13px" }}
      className="table-component"
    >
      <TableContainer
        className="border-t border-[#CCC]"
        sx={{ minHeight: "500px" }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {!!checkbox && (
                <TableCell
                  size="small"
                  sx={{ py: 1.5, pl: 1, pr: 2, zIndex: 0, width: 36 }}
                >
                  <Checkbox
                    size="small"
                    indeterminate={false}
                    checked={data.length > 0 && selected.length === data.length}
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all",
                    }}
                    sx={{
                      p: 0,
                      color: "#AAAAA",
                      "&.Mui-checked": {
                        color: "#CF001C",
                      },
                    }}
                  />
                </TableCell>
              )}
              {columns.map((column) => (
                <TableCell
                  size="small"
                  key={column.id}
                  align={column.numeric ? "right" : "left"}
                  sortDirection={orderBy === column.id ? order : false}
                  sx={{
                    pl: 0,
                    pr: 1,
                    fontWeight: 600,
                    zIndex: 0,
                    color: "#AAAAAA",
                    wordWrap: "break-word",
                    fontSize: "13px",
                    width: column.width || "fit-content",
                    minWidth: column.width || "fit-content",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={() => {
                      if (column.sort) {
                        handleRequestSort(column.id);
                      }
                    }}
                    IconComponent={SortIcon}
                    sx={{
                      ".MuiTableSortLabel-icon": {
                        opacity: "1 !important",
                      },
                      svg: {
                        flexShrink: 0,
                      },
                    }}
                    hideSortIcon={!column.sort}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!!data?.length &&
              stableSort(data, getComparator(order, orderBy)).map(
                (row, rowIndex) => {
                  const isRowSelected = isSelected(row.id);

                  return (
                    <TableRowStyled
                      key={row.id}
                      role="checkbox"
                      aria-checked={isRowSelected}
                      tabIndex={-1}
                      selected={isRowSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      {!!checkbox && (
                        <TableCell
                          size="small"
                          sx={{
                            borderBottom: "none",
                            fontSize: "13px",
                            py: 1.5,
                            pl: 1,
                            pr: 1,
                            zIndex: 0,
                          }}
                          onClick={(event) => handleClick(event, row.id)}
                        >
                          <Checkbox
                            // initialchecked={checkedInputData(row.id)}
                            onChange={(e) =>
                              handleCheckedData(row.id)(e.target.checked)
                            }
                            size="small"
                            color="primary"
                            checked={isRowSelected}
                            inputProps={{
                              "aria-labelledby": `generic-table-checkbox-${row.id}`,
                            }}
                            sx={{
                              p: 0,
                              color: "#AAAAA",
                              "&.Mui-checked": {
                                color: "#CF001C",
                              },
                            }}
                          />
                        </TableCell>
                      )}
                      <TableCell
                        size="small"
                        key="sNo"
                        align="left"
                        sx={{
                          borderBottom: "none",
                          fontSize: "13px",
                          py: 1.5,
                          pl: 0,
                          pr: 1,
                          wordBreak: "break-word",
                          width: "50px",
                        }}
                      >
                        {((page - 1) * rowsPerPage + rowIndex + 1)
                          ?.toString()
                          ?.padStart(2, "0")}
                      </TableCell>
                      <TableCell
                        size="small"
                        key="memberName"
                        align="left"
                        sx={{
                          borderBottom: "none",
                          fontSize: "13px",
                          py: 1.5,
                          pl: 0,
                          pr: 1,
                          wordBreak: "break-word",
                          width: "150px",
                        }}
                      >
                        {row.filename}
                      </TableCell>
                      <TableCell
                        size="small"
                        key="contactNo"
                        align="left"
                        sx={{
                          borderBottom: "none",
                          fontSize: "13px",
                          py: 1.5,
                          pl: 0,
                          pr: 1,
                          wordBreak: "break-word",
                          width: "100px",
                        }}
                      >
                        {
                          getPermission(loginData, [permissions.DOWNLOAD_DOCUMENTS_PERMISSION]) ?
                          <a
                            href={row.filePath}
                            target="_blank"
                            download={true}
                            rel="noreferrer"
                            className="text-blue-600 underline"
                            // onClick={() => handleDownload(row.filePath, row.filename)}
                          >
                            View
                          </a>
                          :
                          '-'
                        }
                      </TableCell>
                      <TableCell
                        size="small"
                        key="actions"
                        align="left"
                        sx={{
                          borderBottom: "none",
                          fontSize: "13px",
                          py: 1.5,
                          pl: 0,
                          pr: 1,
                          wordBreak: "break-word",
                          width: "130px",
                        }}
                      >
                        <ActionComponent id={row.id} filename={row.filename} />
                      </TableCell>
                    </TableRowStyled>
                  );
                }
              )}
          </TableBody>
        </Table>
      </TableContainer>

      {!!pagination && (
        <div className="flex flex-row justify-between mt-3 pt-1 border-t border-[#ccc] text-[14px]">
          <ItemsPerPage
            rowsPerPage={rowsPerPage}
            onChange={handleChangeRowsPerPage}
          />
          <Pagination
            totalCount={totalCount}
            totalPages={totalPages}
            start={start}
            end={end}
            currentPage={page}
            onChange={handleChangePage}
          />
        </div>
      )}
    </Box>
  );
};

DocumentsTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  defaultSortField: PropTypes.string,
  rowsPerPageOptions: PropTypes.array,
};

export default DocumentsTable;
