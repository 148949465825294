import { call, put, takeLatest } from 'redux-saga/effects'
import * as types from '../../actions/index.types'
import * as req from '../../../axios/request/ComplianceReqs/RegistersReqs'

function* getMemberListFormatI(action) {
    try {
        const result = yield call(req.getMemberListFormatIReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_MEMBER_LIST_FORMAT_I_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_MEMBER_LIST_FORMAT_I_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getMemberRegisterDetails(action) {
    try {
        const result = yield call(req.getMemberRegisterDetailsReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_MEMBER_REGISTER_DETAILS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_MEMBER_REGISTER_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getMemberListFormatJ(action) {
    try {
        const result = yield call(req.getMemberListFormatJReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_MEMBER_LIST_FORMAT_J_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_MEMBER_LIST_FORMAT_J_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getMemberShareRegisterList(action) {
    try {
        const result = yield call(req.getMemberShareRegisterListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_MEMBER_SHARE_REGISTER_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_MEMBER_SHARE_REGISTER_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getMemberMortgageRegisterList(action) {
    try {
        const result = yield call(req.getMemberMortgageRegisterListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_MEMBER_MORTGAGE_REGISTER_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_MEMBER_MORTGAGE_REGISTER_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getMemberMortgageRegisterDetails(action) {
    try {
        const result = yield call(req.getMemberMortgageRegisterDetailsReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_MEMBER_MORTGAGE_REGISTER_DETAILS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_MEMBER_MORTGAGE_REGISTER_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getNominationRegisterList(action) {
    try {
        const result = yield call(req.getNominationRegisterListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_NOMINATION_REGISTER_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_NOMINATION_REGISTER_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getPropertyRegisterDetails(action) {
    try {
        const result = yield call(req.getPropertyRegisterDetailsReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_PROPERTY_REGISTER_DATA_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_PROPERTY_REGISTER_DATA_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

export default function* registersSaga() {
    yield takeLatest(types.GET_MEMBER_LIST_FORMAT_I, getMemberListFormatI);
    yield takeLatest(types.GET_MEMBER_REGISTER_DETAILS, getMemberRegisterDetails);
    yield takeLatest(types.GET_MEMBER_LIST_FORMAT_J, getMemberListFormatJ);
    yield takeLatest(types.GET_MEMBER_SHARE_REGISTER_LIST, getMemberShareRegisterList);
    yield takeLatest(types.GET_MEMBER_MORTGAGE_REGISTER_LIST, getMemberMortgageRegisterList);
    yield takeLatest(types.GET_MEMBER_MORTGAGE_REGISTER_DETAILS, getMemberMortgageRegisterDetails);
    yield takeLatest(types.GET_NOMINATION_REGISTER_LIST, getNominationRegisterList);
    yield takeLatest(types.GET_PROPERTY_REGISTER_DATA, getPropertyRegisterDetails);
}