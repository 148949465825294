import React from "react";
import { ReactComponent as CloseIcon } from "../../../assets/icons/closeIcon.svg";
import { TextArea, TextAreaFM } from "../../../components/InputFields/TextArea";
import Button from "../../../components/Button/Button";
import { Form, Formik } from "formik";
import { CancellationValidation } from "../../../validationSchema/ClubSchema/ViewBookingSchema";
import { CircularProgress } from "@mui/material";

const BookingCancellationModal = ({ isOpen, onClose, onConfirm, loading}) => {

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-60 z-20 ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="bg-white rounded-lg w-[400px] px-2 min-h-[300px] h-fit">
        <div className="flex justify-between p-2 gap-8 items-center -mb-2">
          <h2 className="p-2 text-lg font-semibold mb-2 items-center text-center w-[180%] flex justify-end">
            Booking Cancellation
          </h2>
          <div className="w-1/2 flex justify-end">
            <button
              onClick={onClose}
              className="bg-[#EAEAEA] rounded-full p-2 items-center mb-2 w-9 h-9 "
            >
              <CloseIcon className="fill-current text-gray-600 hover:text-gray-800 ml-1 w-3 h-3 cursor-pointer" />
            </button>
          </div>
        </div>
        <div className="border-b border-[#CCCCCC]"></div>
        <Formik
        initialValues={{
          reason: ''
        }}
          validationSchema={CancellationValidation}
          onSubmit={(values, {resetForm})=>{onConfirm(values.reason)}}
        >
          {
            (values)=>(
              <Form>
                <div className="mt-2">
                    <div className="p-2">Please Give a Reason For Cancellation</div>
                    <div className="p-2">
                        <TextAreaFM name='reason' label="" placeholder="Enter Reason" row="5" className="w-[370px]" />
                    </div>
                </div>
                <div className="border-b border-[#CCC]"></div>
                <div className="flex justify-center mt-4">
                    <Button label={
                      <span className="w-12 inline-block">
                      {
                      loading ?
                        <CircularProgress sx={{color: "white"}} size={17}/>
                        :
                        <>Submit</>
                      }
                    </span>
                  } 
                  type='submit' />
                </div>
              </Form>
            )
          }
        </Formik>
      </div>
    </div>
  );
};

export default BookingCancellationModal;
