import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Layout from "../../../Layout/Layout";
import Button from "../../../../components/Button/Button";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import EditInvoiceDraftAccordion from "./EditInvoiceDraftAccordion";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getEditInvoiceDetails, updateInvoiceDetails } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageInvoiceActions";
import { MANAGE_INVOICE_DRAFTS_ENDPOINT,MANAGE_INVOICE_CURRENT_ENDPOINT } from "../../../../Routing/routes";
import editCalculation from "../../../../utils/helpers/invoiceCalculation"
import Loader from "../../../../components/Loader/Loader";
import { CircularProgress } from "@mui/material";

export const EditInvoiceDraftMulti = () => {
  const data = useLocation().state;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading,uploadLoader, editInvoiceDetails } = useSelector(
    (state) => state.manageInvoiceReducer
  );

  const [invoiceData, setInvoiceData] = useState();
  const [headData, setHeadData] = useState([]);
  const [postLoader, setPostLoader] = useState(false);
  const [updateLoader, setUpdateLoader] = useState(false);

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  useEffect(() => {
    dispatch(
      getEditInvoiceDetails({
        invIds: data.invid,
        societyId: loginData?.default_community?.community_id,
        is_draft: 1,
      })
    );
  }, []);

  const handlePost = () => {
    setPostLoader(true);
    dispatch(
      updateInvoiceDetails({
        invIds: data.invid,
        societyId: loginData?.default_community?.community_id,
        is_draft: 1,
        status: "generate",
        invoiceData,
        onSuccessCallback: () => {
          navigate(MANAGE_INVOICE_CURRENT_ENDPOINT);
        },
        onRequestEndCallback: () => {
          setPostLoader(false);
        },
      })
    );
  };
  const handleEdit = () => {
    setUpdateLoader(true)
    dispatch(
      updateInvoiceDetails({
        invIds: data.invid,
        societyId: loginData?.default_community?.community_id,
        is_draft: 1,
        status: "update",
        invoiceData,
        onSuccessCallback: () => {
          navigate(MANAGE_INVOICE_DRAFTS_ENDPOINT);
        },
        onRequestEndCallback: () => {
          setUpdateLoader(false)
        },
      })
    );
  };
  useEffect(() => {
    setInvoiceData(editInvoiceDetails);
  }, [editInvoiceDetails]);

  return (
    <Layout>
      <div className="flex flex-col w-full h-full gap-2">
        <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg p-2">
          <div className="border-b border-[#CCC]">
            <ListHeaderBack
              title="Edit Invoices"
              onClick={handleNavigation(-1)}
            >
              <div className="border-r border-[#CCC] w-full h-full"></div>
              <Button
                label={
                  <span className="inline-block w-12">
                    {updateLoader ? (
                      <CircularProgress
                        sx={{ color: "white" }}
                        size={17}
                      />
                    ) : ("Edit")}
                  </span>
                }
                disabled={loading || uploadLoader}
                className="h-8 px-4"
                onClick={handleEdit}
              />
              <Button label={
                  <span className="inline-block w-12">
                    {postLoader ? (
                      <CircularProgress
                        sx={{ color: "white" }}
                        size={17}
                      />
                    ) : ("Post")}
                  </span>
                } className="h-8 px-4" onClick={handlePost} disabled={loading || uploadLoader} />
            </ListHeaderBack>
          </div>
          <div>
          {loading ? <Loader /> :<EditInvoiceDraftAccordion
              setInvoiceData={setInvoiceData}
              invoiceData={invoiceData}
              headData={headData}
              editCalculation={editCalculation}
            />}
          </div>
        </div>
      </div>
    </Layout>
  );
};

const EditInvoiceDraft = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading,uploadLoader, editInvoiceDetails } = useSelector(
    (state) => state.manageInvoiceReducer
  );

  const [invoiceData, setInvoiceData] = useState();
  const [headData, setHeadData] = useState([]);
  const [postLoader, setPostLoader] = useState(false);
  const [updateLoader, setUpdateLoader] = useState(false);

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  useEffect(() => {
    dispatch(
      getEditInvoiceDetails({
        invIds: [id],
        societyId: loginData?.default_community?.community_id,
        is_draft: 1,
      })
    );
  }, []);

  const handlePost = () => {
    setPostLoader(true);
    dispatch(
      updateInvoiceDetails({
        invIds: [id],
        societyId: loginData?.default_community?.community_id,
        is_draft: 1,
        status: "generate",
        invoiceData,
        onSuccessCallback: () => {
          navigate(MANAGE_INVOICE_CURRENT_ENDPOINT);
        },
        onRequestEndCallback: () => {
          setPostLoader(false);
        },
      })
    );
  };
  const handleEdit = () => {
    setUpdateLoader(true)
    dispatch(
      updateInvoiceDetails({
        invIds: [id],
        societyId: loginData?.default_community?.community_id,
        is_draft: 1,
        status: "update",
        invoiceData,
        onSuccessCallback: () => {
          navigate(MANAGE_INVOICE_DRAFTS_ENDPOINT);
        },
        onRequestEndCallback: () => {
          setUpdateLoader(false)
        },
      })
    );
  };


  useEffect(() => {
    setInvoiceData(editInvoiceDetails);
  }, [editInvoiceDetails]);

  return (
    <Layout>
      <div className="flex flex-col w-full h-full gap-2">
        <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg p-2">
          <div className="border-b border-[#CCC]">
            <ListHeaderBack
              title="Edit Invoices"
              onClick={handleNavigation(-1)}
            >
              
              <Button
                label={
                  <span className="inline-block w-12">
                    {updateLoader ? (
                      <CircularProgress
                        sx={{ color: "white" }}
                        size={17}
                      />
                    ) : ("Edit")}
                  </span>
                }
                disabled={loading || uploadLoader}
                className="h-8 px-4"
                onClick={handleEdit}
              />
              <div className="border-r border-[#CCC] w-full h-full"></div>
              <Button label={
                <span className="inline-block w-12">
                  {postLoader ? (
                    <CircularProgress
                      sx={{ color: "white" }}
                      size={17}
                    />
                  ) : ("Post")}
                </span>
              } className="h-8 px-4" onClick={handlePost} disabled={loading || uploadLoader} />
            </ListHeaderBack>
          </div>
          <div>
          {loading ? <Loader /> :<EditInvoiceDraftAccordion
              editInvoiceDetails={editInvoiceDetails}
              setInvoiceData={setInvoiceData}
              invoiceData={invoiceData}
              headData={headData}
              editCalculation={editCalculation}
              //Calculation ={Calculation}
            />}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EditInvoiceDraft;
