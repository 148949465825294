import React, { useState } from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import Button from "../Button/Button";
import "./ArchiveTenantModal.css";
import MyCalendar from "../Calender/Calender";
import Dropdown2 from "../Dropdown/dropdown2";
import { CircularProgress } from "@mui/material";
import { monthOptions, yearOptionsForCalander } from "../../consts/DropDownOptions";
import moment from "moment";

function formatDateToDdMmYy(date) {
  // Get day, month, and year from the date object
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear().toString().slice(-2); // Get the last two digits of the year

  // Combine the components into the desired format
  const formattedDate = `${day}-${month}-${year}`;

  return formattedDate;
}

const ArchiveTenantModal = ({ isOpen, onClose, tenantName, loading, onSubmit, minDate }) => {
  // console.log("Initial selectedDate:", selectedDate);
  const [date, setDate] = useState(new Date());

  // console.log("Selected date after handleDateChange:", selectedDate);

  // const isSendButtonEnabled = !!selectedDate;
  const handleDateChange = (calDate)=>{

    const newDate = new Date(date);
    newDate.setDate(calDate.getDate());
    setDate(newDate);
  }

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-60 z-20 ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="bg-white rounded-lg w-[360px]">
        <div className="flex justify-end p-2 gap-10 items-center -mb-2">
          <h2 className="p-2 text-lg font-semibold mb-2 flex items-center justify-center">
            Archive: {tenantName}
          </h2>
          <button
            onClick={()=>{onClose(); setDate(new Date())}}
            className="bg-[#EAEAEA] rounded-full p-2.5 items-center mb-2"
          >
            <CloseIcon className="fill-current text-gray-600 hover:text-gray-800 w-4 h-3 cursor-pointer" />
          </button>
        </div>
        <div className="border-b border-[#CCCCCC]"></div>
        <div className="p-4">
          <div className="mt-4 flex justify-center font-semibold text-base">
            <p>Selected Leaving Date: {formatDateToDdMmYy(date)} </p>
          </div>

          <div className="flex justify-center gap-8 items-center mt-4 mb-4">
            {/* Year Dropdown */}
            <Dropdown2
              options={yearOptionsForCalander?.filter(item=> item.value>=minDate.getFullYear() && moment(minDate).isBefore(new Date(`${item.value}-12-31`)))}
              width="128px"
              height="36px"
              placeholder={date.getFullYear()}
              onSelect={(selectedItem) => {
                const newDate = new Date(date);
                newDate.setFullYear(parseInt(selectedItem.value));
                if(moment(minDate).isBefore(newDate)){
                  setDate(newDate);
                }
                else
                  setDate(minDate)
              }}
              value={{
                label: date.getFullYear(),
                value: date.getFullYear()
              }}
            />

            {/* Month Dropdown */}
            <Dropdown2
              options={monthOptions?.filter(item=> (date.getFullYear()!==minDate.getFullYear()) || ((parseInt(item.value)-1)>=minDate.getMonth() && minDate.getDate() !== moment(minDate).endOf('month')))}
              width="128px"
              height="36px"
              placeholder={date.toLocaleString("en-US", { month: "long" })}
              onSelect={(selectedItem) => {
                const newDate = new Date(date);
                
                if((parseInt(selectedItem.value)-1) === minDate.getMonth())
                  newDate.setDate(minDate.getDate());
                else
                  newDate.setDate(1)

                newDate.setMonth(parseInt(selectedItem.value)-1);
                setDate(newDate);
              }}
              value={{
                label: monthOptions?.find(item=> item.value === (date.getMonth() + 1)?.toString()?.padStart(2, "0"))?.label,
                value: (date.getMonth() + 1)?.toString()?.padStart(2, "0")
              }}
            />
          </div>

          <div>
            <MyCalendar selectedDate={date} handleDateChange={handleDateChange} minDate={minDate} />
          </div>

          <hr className="border-t border-[#CCC] my-4" />

          <div className="flex justify-center mt-5 gap-4">
            <Button
              label={
                <span className="w-8 inline-block">
                  {loading ? (
                    <CircularProgress sx={{ color: "white" }} size={17} />
                  ) : (
                    "Send"
                  )}
                </span>
              }
              type={"submit"}
              onClick={()=>{onSubmit(date)}}
              className={`py-2 px-5 font-semibold `}
              disabled={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArchiveTenantModal;
