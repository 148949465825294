import { CircularProgress } from '@mui/material'
import React from 'react'

function FullScreenLoader() {
  return (
    <div className='h-screen w-screen fixed top-0 left-0 bg-white z-50'>
        <div className="flex justify-center items-center h-full">
          <CircularProgress size={80} sx={{color:"#CF001C"}} /> 
        </div>
    </div>
  )
}

export default FullScreenLoader