import React, { useState } from "react";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import Layout from "../../Layout/Layout";
import { useNavigate } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function EditContentManagment() {
  const navigate = useNavigate();
//   const [subject, setSubject] = useState("");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  return (
    <Layout>
      <div className="content-container w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="header border-b border-[#CCC]">
          <ListHeaderBack
            title="Edit Content"
            onClick={() => {
              navigate(-1);
            }}
          />
        </div>
        <div className="content p-3">
          <div className="flex flex-col space-y-4">
            <Section label="Title" value="Reminder For Society Nomination" />
            <Section label="Description">
              <div className="editor-container border h-60 p-1">
                
                <Editor
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                  toolbar={{
                    options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history', 'colorPicker'],
                    inline: { inDropdown: false, options: ["bold", "italic", "underline"] },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: false, options: ["redo", "undo"] },
                    colorPicker: {
                      className: undefined,
                      component: undefined,
                      popupClassName: undefined,
                    },
                }}
                />
              </div>
            </Section>
          </div>
        </div>
      </div>
    </Layout>
  );
}

const Section = ({ label, value, children }) => (
  <div className="flex w-full">
    <div className="font-bold text-[#AAAAAA] mb-1 w-[15%]">{label}:</div>
    <div className="w-[85%]">{value || children}</div>
  </div>
);

export default EditContentManagment;
