import React, { useEffect, useState } from "react";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import { financialYears2 } from "../../../../consts/DropDownOptions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  downloadCSVGroupTrailBalance,
  downloadPDFGroupTrailBalance,
  getGroupTrailBalance,
} from "../../../../redux/actions/ReportActions/OldFinancialReportsActions";
import moment from "moment";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ACCOUNT_REPORTS_ENDPOINT } from "../../../../Routing/routes";
import GroupTrailBalanceTable from "./GroupTrailBalanceTable";
import { DownloadEx, UploadEx } from "../../../../assets";
import { DatePickerComponent } from "../../../../components/DatePicker/DatePicker";
import ButtonG from "../../../../components/Button/ButtonG";
import UploadDebitNoteExcelModal from "../../../../components/Modal/UploadDebitNoteExcelUpload";

export const headCells = [
  {
    id: "headOfAccount",
    label: "Head Of Account",
    width: "120px",
  },
  { id: "flat", label: "Flat", width: "70px" },
  { id: "openingBalance", label: "Opening Balance", width: "90px" },
  { id: "debit", label: "Debit", width: "90px" },
  { id: "credit", label: "Credit", width: "90px" },
  { id: "closingBalance", label: "Closing Balance", width: "90px" },
];

const GroupTrailBalance = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loginData } = useSelector((state) => state.loginReducer);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [uploadModalType, setUploadModalType] = useState("");

  const [selected, setSelected] = useState([]);
  const [file, setFile] = useState(null);

  const { loading, groupTrailBalance } = useSelector(
    (state) => state.oldFinancialReportsReducers
  );

  // const handleSearch = (text) => {
  //   if (text && text !== searchParams.get("search")) {
  //     searchParams.delete("page");
  //     searchParams.delete("search");
  //     navigate({
  //       pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
  //       search: `?${createSearchParams([
  //         ...searchParams.entries(),
  //         ["search", text?.trim()],
  //       ])}`,
  //     });
  //   }
  // };

  const handleOpenUploadModal = (value) => {
    setUploadModalType("value");
    setIsUploadModalOpen(true);
  };
  const handleCloseUploadModal = () => {
    setIsUploadModalOpen(false);
  };
  const onUpload = (uploadModalType) => {
    let data = {
      society_id: loginData?.default_community?.zipgrid_socsrno,
      email: searchParams.get("email") || "",
      fin_year: searchParams.get("year") || "",
      as_on_date: searchParams.get("as_on_date") || "",
      page: searchParams.get("page") || 1,
      limit: searchParams.get("pageLimit") || 10,
    };
    if (uploadModalType == "csv") {
      dispatch(downloadCSVGroupTrailBalance(data));
    } else if (uploadModalType == "pdf") {
      // file,
      // onSuccessCallback: () => {
      //   navigate(ACCOUNT_REPORTS_ENDPOINT);
      // },
      // onRequestEndCallback: () => {
      //   setFile(null);
      //   setIsUploadModalOpen(false);
      // },
      dispatch(downloadPDFGroupTrailBalance(data));
    }
  };

  const handleJoinDate = (selectedDate) => {
    if (
      selectedDate &&
      moment(selectedDate)?.format("YYYY-MM-DD") !==
        searchParams.get("as_on_date")
    ) {
      searchParams.delete("pageLimit");
      searchParams.delete("as_on_date");
      navigate({
        pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["as_on_date", moment(selectedDate)?.format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleSelectYear = (selectedItem) => {
    searchParams.delete("page");

    if (selectedItem.value?.toString() !== searchParams.get("year")) {
      searchParams.delete("year");
      navigate({
        pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["year", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleSendCSV = () => {
    dispatch(
      downloadCSVGroupTrailBalance({
        societyId: loginData?.default_community?.community_id,
        fin_year: searchParams.get("year"),
        as_on_date: searchParams.get("as_on_date"),
        page: searchParams.get("page") || 1,
        limit: searchParams.get("pageLimit") || 10,
        email: "",
      })
    );
  };

  const handleSendPDF = () => {
    dispatch(
      downloadPDFGroupTrailBalance({
        societyId: loginData?.default_community?.community_id,
        fin_year: searchParams.get("year"),
        as_on_date: searchParams.get("as_on_date"),
        page: searchParams.get("page") || 1,
        limit: searchParams.get("pageLimit") || 10,
        email: "",
      })
    );
  };

  const handleClear = () => {
    navigate({
      pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
      search: `?${createSearchParams([["tab", 0]])}`,
    });
  };

  useEffect(() => {
    dispatch(
      getGroupTrailBalance({
        society_id: loginData?.default_community?.zipgrid_socsrno,
        page: searchParams.get("page") || 1,
        limit: searchParams.get("pageLimit") || 10,
        fin_year: searchParams.get("year"),
        as_on_date: searchParams.get("as_on_date"),
      })
    );
  }, [searchParams]);

  return (
    <div className="mt-1">
      <div>
        <ListHeader leftContent="Group Trail Balance">
          {/* <Search /> */}
          {/* <span className="border-r border-[#CCC]"></span> */}
          <Dropdown2
            options={financialYears2}
            placeholder="Choose Financial Year"
            className=""
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              const valueParts = selectedItem.value.split("-");
              const selectedValue = `${valueParts[0]}${valueParts[1].slice(2)}`;
              handleSelectYear({ ...selectedItem, value: selectedValue });
            }}
            value={
              searchParams.get("year") && {
                value: searchParams.get("year"),
                label: financialYears2?.find((item) => {
                  const valueParts = item.value.split("-");
                  const selectedValue = `${valueParts[0]}${valueParts[1].slice(
                    2
                  )}`;
                  return selectedValue === searchParams.get("year");
                })?.label,
              }
            }
          />

          <DatePickerComponent
            className="w-48 h-8"
            onDateChange={handleJoinDate}
            defaultValue={
              searchParams.get("as_on_date")
                ? new Date(searchParams.get("as_on_date"))
                : ""
            }
          />
          <span className="border-r border-[#CCC]"></span>
          <button
            onClick={() => {
              onUpload("csv");
            }}
            className="flex text-red-650 border border-red-650 bg-white text-xs px-2 font-semibold items-center whitespace-nowrap rounded-lg"
          >
            <DownloadEx className="mr-2 fill-red-650" /> Download Excel
          </button>
          <button
            onClick={() => {
              onUpload("pdf");
            }}
            className="flex text-red-650 border border-red-650 bg-white text-xs px-2 font-semibold items-center whitespace-nowrap rounded-lg"
          >
            <DownloadEx className="mr-2 fill-red-650" /> Download PDF
          </button>
          <span className="border-r border-[#CCC]"></span>
          <ButtonG label="Clear" onClick={handleClear} />
        </ListHeader>
      </div>
      <div className="ml-1">
        <GroupTrailBalanceTable
          loading={loading}
          data={
            groupTrailBalance?.list?.map((item) => ({
              headOfAccount: item?.head_of_account,
              flat: item?.flat,
              openingBalance: item?.opening_balance,
              debit: item?.debit,
              credit: item?.credit,
              closingBalance: item?.closing_balance,
            })) || []
          }
          columns={headCells}
          checkbox={false}
          pagination={
            groupTrailBalance?.list?.length
              ? groupTrailBalance?.list?.length
              : 0
          }
          totalCount={groupTrailBalance?.total_count}
          totalPages={groupTrailBalance?.total_pages}
          start={
            (groupTrailBalance?.current_page - 1) *
              (parseInt(groupTrailBalance?.page_limit) || 10) +
            1
          }
          end={
            (groupTrailBalance?.current_page - 1) *
              (parseInt(groupTrailBalance?.page_limit) || 10) +
            groupTrailBalance?.list?.length
          }
          currentPage={parseInt(groupTrailBalance?.current_page)}
          rowsPerPage={searchParams.get("pageLimit") || 10}
        ></GroupTrailBalanceTable>
      </div>
      {!groupTrailBalance?.list?.length && !loading && (
        <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
          No Records
        </div>
      )}

      {isUploadModalOpen && (
        <UploadDebitNoteExcelModal
          isOpen={isUploadModalOpen}
          onClose={handleCloseUploadModal}
          buttons={[
            "Download Sample",
            // "Download CSV Sample",
            // "Download Format",
          ]}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          file={file}
          setFile={setFile}
          onUpload={onUpload}
          // downloadCSVSampleLink={handleDownloadSampleLink}
          // downloadSampleLink={handleDownloadExcel}
          // downloadFormatLink={handleDownloadFormatLink}
        />
      )}
    </div>
  );
};

export default GroupTrailBalance;
