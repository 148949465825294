import React, { useEffect } from "react";
import Layout from "../../Layout/Layout";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import Button from "../../../components/Button/Button";
import GenericTable from "../../../components/TableComponent/Table";
import { Box, Tooltip } from "@mui/material";
import { DeleteIcon } from "../../../assets";
import { useDispatch, useSelector } from "react-redux";
import { ADD_BANK_ACCOUNT_ENDPOINT } from "../../../Routing/routes";
import { deleteBankData, getBankNameList } from "../../../redux/actions/SetupRecordsActions/BuildingActions/BankDetailsAction";
import { useNavigate } from "react-router-dom";
import * as permissions from '../../../Routing/permissions'
import { getPermission } from "../../../utils/helpers/getPermissions";

const headCells = [
    { id: "sNo", label: "S No.", sort: false, width: '90px' },
    { id: "account", label: "Bank Name", sort: true,  },
    { id: "action", label: "Action", sort: false, width: '90px' },
  ];
  
  
  function ActionComponent ({id, status}) {
    const {ipAddress} = useSelector(state=>state.ipAddressReducer)
    const {loginData} = useSelector(state=>state.loginReducer)

    const dispatch = useDispatch()

    const handleDelete = ()=>{
      dispatch(deleteBankData({flag: 'bank_name', ipAddress, id, sessionId: loginData?.session_id, onSuccessCallback: ()=>{dispatch(getBankNameList())}}))
    }

    return(
      <Box sx={{ display: "flex", alignItems: "center"}}>
          {
            getPermission(loginData, [permissions.DELETE_BANK_ACCOUNT_PERMISSION])
            &&
            <>
              <Tooltip title="Delete">
                <DeleteIcon
                  className="fill-[#555] hover:fill-red-650 me-3  w-[14px]"
                  onClick={handleDelete}
                />
              </Tooltip>
            </>
          }
      </Box>
    )
  }

const CreateBankAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {loading, bankNameList} = useSelector(state=>state.bankNameReducer)
  const {loginData} = useSelector(state=>state.loginReducer)

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleAddBank = ()=>{
    navigate(ADD_BANK_ACCOUNT_ENDPOINT)
  }

  
  useEffect(()=>{
    dispatch(getBankNameList())
  }, [])
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 ">
        <ListHeaderBack
          onClick={handleNavigation(-1)}
          title="Create Bank Account"
        >
          {
            getPermission(loginData, [permissions.CREATE_BANK_ACCOUNT_PERMISSION])
            &&
            <>
              <span className="border-r border-[#CCCCCC]"></span>
              <div className="flex">
                <Button
                  label="Add Bank"
                  onClick={handleAddBank}
                  className="font-semibold h-8"
                />
              </div>
            </>
          }
        </ListHeaderBack>
        <div className="px-2">
          <GenericTable
              data={bankNameList?.map((item, index)=>({
                sNo: (index+1).toString()?.padStart(2, '0'),
                id: item?.bank_name_id,
                account: item.account
              }))}
              columns={headCells}
              checkbox={false}
              pagination={false}
              getTableData={undefined}
              loading={loading}
            >
              <ActionComponent />
            </GenericTable>
            {!bankNameList?.length && !loading && (
              <div className="text-sm text-[#AAAAAA] my-4 text-center">
                No Records
              </div>
            )}
        </div>
      </div>
    </Layout>
  );
};

export default CreateBankAccount;
