import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader/Loader";
import { printTenantsDetailsList } from "../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/TenantsInfoAction";
import moment from "moment";
import { validateDate } from "../../../../utils/helpers/universalFunctions";

export const headingsTenants = [
  { id: 'sNo', label: 'S No.', width: '45px' },
  { id: 'tenantName', label: 'Tenant Name', sort: true,width: '120px' },
  { id: 'wingNo', label: 'Wing No.', sort: true, width:'80px'  },
  { id: 'flatNo', label: 'Flat No.', sort: true, width:'80px' },
  { id: 'memberName', label: 'Owner Name', sort: true, width: '80px' },
  { id: 'rentDeedStartDate', label: 'Rent Deed Start Date', sort: true, width:'90px' },
  { id: 'rentDeedEndDate', label: 'Rent Deed End Date', sort: true, width:'90px'},
  { id: 'lastTimeActive', label: 'Last Activity', sort: true,width:'100px' },
  { id: 'status', label: 'Status', sort: true, width: '80px' },
];

const PrintTenantsList = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams()

  const { loginData } = useSelector((state) => state.loginReducer);
  const { printLoader, printTenantList } = useSelector(
    (state) => state.tenantInfoReducer
  );

  const getTableData =() =>{
    const data={
      societyId: loginData?.default_community?.community_id,
      page: searchParams.get("page") || 1,
      searchText: searchParams.get("search") || '',
      pageLimit: searchParams.get("pageLimit") || 10,
      status: searchParams.get("status") || '',
      wingId: searchParams.get("wingId") || '',
      flatId: searchParams.get("flatId") || '',
    }
    dispatch(printTenantsDetailsList(data));
  }
  
  useEffect(()=>{      
    getTableData()

    const afterprint = (e) => {
      e.preventDefault()
    };
    
    window.addEventListener("afterprint", afterprint);

    return () => {
      window.removeEventListener("afterprint", afterprint);
    }
  }, [])


  useEffect(() => {
    if(!printLoader && printTenantList){
      window.print()
    }
  }, [printLoader]);


  return printLoader ? (
    <Loader />
  ) : (
    <div className="m-1">
      <table className="table-auto w-full mx-auto border-collapse border border-black">
        <caption className="caption-top mb-10 mt-4">
          <div className="underline font-extrabold text-lg uppercase mb-3">{printTenantList?.society_title}</div>
          <div className="underline font-bold text-sm">(Reg No.: {printTenantList?.reg_no} &nbsp; Dated: {moment(new Date(printTenantList?.reg_date))?.format('DD-MM-YYYY')})</div>
          <div className="font-bold mb-5 text-sm">{printTenantList?.office_address} </div>
        </caption>
        <thead>
          <tr>
            {
              headingsTenants?.map((item)=>(
                <td className="text-sm p-1 font-semibold border border-black" key={item?.id}>
                  {item?.label}
                </td>
              ))
            }
          </tr>
        </thead>
        <tbody>
            {
              printTenantList?.tenant_list?.map((item, index)=>(
                <React.Fragment  key={index}>
                {
                  !!Object.keys(item)?.length &&
                  <tr className="text-[#222] text-sm">
                    <td className="p-1 border border-black align-top">
                      {(index+1)?.toString()?.padStart(2, 0)}
                    </td>
                    <td className="p-1 border border-black align-top capitalize">
                      {item?.first_name + ' ' + item?.middle_name + ' ' + item?.last_name || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.wing_no || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.flat_no || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.owner_name || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {validateDate(item?.rent_deed_start_date) ? moment(new Date(item?.rent_deed_start_date))?.format('DD-MM-YYYY') : '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {validateDate(item?.rent_deed_end_date) ? moment(new Date(item?.rent_deed_end_date))?.format('DD-MM-YYYY') : '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.last_activity_time ? moment(new Date(item?.last_activity_time))?.format('ddd, MMM DD,YYYY hh:mm A') : '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {moment(new Date(item?.rent_deed_end_date)).isBefore(new Date()) ? 'Expired' : (item?.status === 'A' ?  'Active': (item?.status === 'I' ? 'Inactive': 'Unapprove'))}
                    </td>
                    
                  </tr>
                }
                </React.Fragment>
              ))
            }
          
        </tbody>
      </table>
      {!printTenantList?.tenant_list?.length && !printLoader && (
          <div className="text-sm my-10 text-center italic">
            No Records
          </div>
        )}
    </div>
  );
};

export default PrintTenantsList;
