
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from 'redux-saga';
import rootSaga from "./sagas/rootSaga";
import rootReducer from './reducers/index'
import { composeWithDevTools } from '@redux-devtools/extension';

const sagaMiddleware = createSagaMiddleware();

const store = createStore (
        rootReducer,
        composeWithDevTools(applyMiddleware(sagaMiddleware))
    )

    
// running the sagas
sagaMiddleware.run(rootSaga);

export default store;

