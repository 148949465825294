import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { getMemberListFormatJ, getMembermemberListFormatJ } from '../../../redux/actions/ComplianceActions/RegistersAction'
import Loader from '../../../components/Loader/Loader'
import moment from 'moment'
import { validateDate } from '../../../utils/helpers/universalFunctions'
import { PrinterIcon } from '../../../assets'
import { useSearchParams } from 'react-router-dom'
import { classMemberOptions } from '../../../consts/DropDownOptions'

export const headingsTenants = [
    { id: 'sNo', label: 'S No.', width: '45px' },
    { id: 'date', label: 'Full Name Of Member', sort: true,width: '16%' },
    { id: 'folioNo', label: 'Flat No.', sort: true, width:'16%'  },
    { id: 'certificateNo', label: "'I' Folio.", sort: true, width:'16%' },
    { id: 'DistnoOfShare', label: 'Share Folio', sort: true, width: '16%' },
    { id: 'noOfShare', label: 'Nomination Folio', sort: true, width:'16%' },
    { id: 'valueOfShare', label: 'Class Of Member', sort: true, width:'16%'},
  ];
  
function PrintJForm() {
    const dispatch = useDispatch()
    const {loading, memberListFormatJ} = useSelector(state=>state.registersReducer)
    const {loginData} = useSelector(state=>state.loginReducer)
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(()=>{
        dispatch(getMemberListFormatJ({societyId: loginData?.default_community?.community_id, from: searchParams.get("from"), to: searchParams.get("yo")}))
    }, [])

    
  useEffect(() => {
    if(!loading && memberListFormatJ){
      window.print()
    }
  }, [loading]);

  return loading ? (
    <Loader />
  ) : (
    <div className="m-1 w-[900px] mx-auto">
      <table className="table-auto w-full mx-auto border-collapse border border-black">
        <caption className="caption-top mb-10 mt-4">
          <div className="underline font-extrabold text-lg uppercase mb-3">{loginData?.default_community?.community_name}</div>
          <div className="underline font-bold text-sm">(Reg No.: {loginData?.default_community?.regn_no} &nbsp; Dated: {moment(new Date(loginData?.default_community?.date_of_regn))?.format('DD-MM-YYYY')})</div>
          <div className="font-bold mb-5 text-sm">{loginData?.default_community?.office_address} </div>
          <div className="underline font-extrabold text-sm text-red-500 uppercase mt-3">FORM 'J'</div>
        </caption>
        <thead>
          <tr>
            {
              headingsTenants?.map((item)=>(
                <td className="text-sm p-1 font-semibold bg-[#DDDDDD] border border-black" key={item?.id}>
                  {item?.label}
                </td>
              ))
            }
          </tr>
        </thead>
        <tbody>
            {
              memberListFormatJ?.map((item, index)=>(
                <React.Fragment  key={index}>
                {
                  !!Object.keys(item)?.length &&
                  <tr className="text-[#222] text-sm">
                    <td className="p-1 border border-black align-top">
                      {(index+1)?.toString()?.padStart(2, 0)}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.occupant_names || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {`${item?.number} - ${item?.flat_no}` || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.sr_no_of_disti_held || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.no_of_share_held || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.nominee_folio || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {classMemberOptions?.find(e=> e.value === item.class_of_member)?.label || '-'}
                    </td>
                  </tr>
                }
                </React.Fragment>
              ))
            }
          
        </tbody>
      </table>
      {!memberListFormatJ?.length && !loading && (
          <div className="text-sm my-10 text-center italic">
            No Records
          </div>
        )}

        <div className="my-9 underline font-extrabold text-sm uppercase justify-center items-center cursor-pointer text-red-500 flex" onClick={()=>{window.print()}}> <PrinterIcon/>  &nbsp; PRINT</div>
    </div>
  );
}

export default PrintJForm