import React from 'react'
import Dropdown2 from "../../../../../components/Dropdown/dropdown2";
import DynamicTextFields from '../../../../../components/InputFields/DynamicFields'

const PaymentOrder = ({
  setInvoiceSettingList,
  invoiceSettingList
}) => {
  let option = [
    { value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }
  ]
  return (
    <div className="p-3 text-[#222222] text-sm">
      <div className="flex justify-between w-full">
        {/* <DynamicTextFields /> */}
        <table className="border-separate w-[65%]">
          <tbody>

            {
              Object.entries(invoiceSettingList.setoffOrder).map((item, index) => (
                <tr className="items-center mb-4 text-sm">
                  <td className="pb-4">{item[0]}</td>
                  <td className="pb-4">
                    <Dropdown2
                      options={item[1] ==="6" ? [{ value: "6", label: "6" },] :[
                        { value: "1", label: "1" },
                        { value: "2", label: "2" },
                        { value: "3", label: "3" },
                        { value: "4", label: "4" },
                        { value: "5", label: "5" },
                      ]}
                      value={{
                        label: item[1],
                        value: item[1],

                      }}
                      onSelect={(selectedItem) => {
                        let key = item[0];
                        var data = invoiceSettingList.setoffOrder;
                        console.log(data);
                        data[key] = selectedItem.value;
                        console.log(data);
                        setInvoiceSettingList({...invoiceSettingList, setoffOrder: data });
                      }}
                      placeholder="Select order"
                      width="100px"
                      height="36px"
                    />

                  </td>
                </tr>

              ))
            }
          </tbody>
        </table>
      </div>

    </div>
  )
}

export default PaymentOrder