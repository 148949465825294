import * as types from '../index.types';

export function getMemberListFormatI(data) {
    return {
        type: types.GET_MEMBER_LIST_FORMAT_I,
        data
    };
}

export function getMemberRegisterDetails(data) {
    return {
        type: types.GET_MEMBER_REGISTER_DETAILS,
        data
    };
}

export function getMemberListFormatJ(data) {
    return {
        type: types.GET_MEMBER_LIST_FORMAT_J,
        data
    };
}

export function getMemberShareRegisterList(data) {
    return {
        type: types.GET_MEMBER_SHARE_REGISTER_LIST,
        data
    };
}

export function getMemberMortgageRegisterList(data) {
    return {
        type: types.GET_MEMBER_MORTGAGE_REGISTER_LIST,
        data
    };
}

export function getMemberMortgageRegisterDetails(data) {
    return {
        type: types.GET_MEMBER_MORTGAGE_REGISTER_DETAILS,
        data
    };
}

export function getNominationRegisterList(data) {
    return {
        type: types.GET_NOMINATION_REGISTER_LIST,
        data
    };
}

export function getPropertyRegisterDetails(data) {
    return {
        type: types.GET_PROPERTY_REGISTER_DATA,
        data
    };
}