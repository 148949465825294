import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { TextInputFM } from "../../../../components/InputFields/TextInput";
import Layout from "../../../Layout/Layout";
import Button from "../../../../components/Button/Button";
import ButtonG from "../../../../components/Button/ButtonG";
import { TENANTS_INFO_ENDPOINT } from "../../../../Routing/routes";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import { TextAreaFM } from "../../../../components/InputFields/TextArea";
import { UploadButtonFM } from "../../../../components/Button/UploadButton";
import { useDispatch, useSelector } from "react-redux";
import { getTenantsDetails, updateTenantDetails } from "../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/TenantsInfoAction";
import Loader from "../../../../components/Loader/Loader";
import { Form, Formik } from "formik";
import { AddTenantValidation } from "../../../../validationSchema/setupRecordsSchema/occupants&TenantsSchema";
import { DropdownFM2 } from "../../../../components/Dropdown/dropdown2";
import { getFlatDetails, getWingsAndFlatDetails } from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import countries from "../../../../consts/countries";
import { validateDate } from "../../../../utils/helpers/universalFunctions";

const EditTenantDetails = () => {
  const navigate = useNavigate();
  const {id} = useParams()
  const dispatch = useDispatch()
  const {wingsData,flatsData} = useSelector(state=>state.wingsReducer)
  const {loginData} = useSelector(state=>state.loginReducer)
  const {ipAddress} = useSelector(state=>state.ipAddressReducer)
  const {loading, updateLoader, tenantDetails} = useSelector(state=>state.tenantInfoReducer)

  const handleCancelClick = () => {
    navigate(TENANTS_INFO_ENDPOINT);
  };

  const handleSaveClick = async(values, {resetForm}) => {
    dispatch(updateTenantDetails({
      ...values, 
      societyId: loginData?.default_community?.community_id, 
      sessionId: loginData?.session_id, 
      ipAddress
    }))
  };

  useEffect(()=>{
    dispatch(getTenantsDetails({tenantId: id}))
    const societyId= loginData?.default_community?.community_id
    dispatch(getWingsAndFlatDetails({societyId}))
  }, [])

  useEffect(()=>{
    if(tenantDetails?.wing_id){
      dispatch(getFlatDetails({societyId: loginData?.default_community?.community_id, wingId: tenantDetails?.wing_id}))
    }
  }, [tenantDetails])

  return (
    <Layout>
    <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
      <Formik
        initialValues={{
          tenantId: tenantDetails?.tenant_id,
          firstName: tenantDetails?.usr_fname || '',
          middleName: tenantDetails?.usr_mname || '',
          lastName: tenantDetails?.usr_lname || '',
          mobileCountryCode: tenantDetails?.mobile_country_code,
          countryISO: countries?.find(item=> item.phonecode?.replace('+','') === tenantDetails?.mobile_country_code)?.iso,
          countryId: countries?.find(item=> item.phonecode?.replace('+','') === tenantDetails?.mobile_country_code)?.id,
          mobileNo: tenantDetails?.contact_no|| '-',
          secondaryMobileNo: tenantDetails?.alternate_contact_no || '',
          primaryEmail: tenantDetails?.email ||  '',
          address: tenantDetails?.address ||  '',
          wingId: tenantDetails?.wing_id ||  '',
          flatId: tenantDetails?.flat_id ||  '',
          adharCardNo: tenantDetails?.uidcard_no || '',
          adharFile: tenantDetails?.aadhar_card || '',
          panCardNo: tenantDetails?.pancard_no || '',
          gstRegistrationNo: tenantDetails?.gst_registration_no || '' ,
          showInBill: tenantDetails?.gst_visible || 'No',
          rentDeedStartDate: tenantDetails?.start_date || '',
          rentDeedEndDate: tenantDetails?.end_date || '',
          rentAgreementFile: tenantDetails?.rent_agreement ||  ''
        }}
        enableReinitialize
        onSubmit={handleSaveClick}
        validationSchema={AddTenantValidation}
      >    
      {
        ({values, dirty, setFieldValue})=>{
          return (
            <Form>
              <div className="flex justify-between border-b border-[#CCC] pb-2">
                <h1 className="text-base font-semibold p-1">Edit Tenant Details</h1>
                <div className="flex gap-2">
                  <ButtonG label="Cancel" onClick={handleCancelClick} className="h-8" type='button'/>
                  <Button label={
                    <span className="w-12 inline-block">
                      {
                        updateLoader ?
                          <CircularProgress sx={{color: "white"}} size={17}/>
                          :
                          'Update'
                        }
                    </span>
                    } 
                    className="px-5 h-8" type='submit'
                    disabled={loading || updateLoader || !dirty}
                    />
                </div>
              </div>
              {
                loading && id !== tenantDetails?.tenant_id?.toString() ?
                <Loader/>
                :
                <div className="flex gap-4 p-2">
                  <div className="flex flex-row justify-between w-full gap-7">
                    <div className="flex flex-col w-1/2">
                      <TextInputFM
                        label="First Name"
                        placeholder="Enter First Name"
                        className="w-56"
                        name='firstName'
                        maxLength={20}
                      />
                      <TextInputFM
                        label="Last Name"
                        placeholder="Enter Last Name"
                        className="w-56"
                        name='lastName'
                        maxLength={20}
                      />
                      <div className="flex justify-between ">
                        <span className="text-sm mt-2">Country</span>
                        <DropdownFM2
                          options={ countries?.map((item)=>({
                            label: `${item?.name?.toUpperCase()} (${item?.phonecode})`,
                            value: item?.phonecode?.replace("+",""),
                            iso: item.iso,
                            id: item?.id
                          })) || []}
                          width={"224px"}
                          className="text-xs"
                          placeholder={"Select Country"}
                          onSelect={(selectedItem)=>{
                            setFieldValue(`mobileCountryCode`, selectedItem.value)
                            setFieldValue(`countryId`, selectedItem.id)
                            setFieldValue(`countryISO`, selectedItem.iso)
                          }}
                          height='38px'
                          name={`mobileCountryCode`}
                          value={{
                            label: `${countries?.find(item=> item.phonecode?.replace('+','') === tenantDetails?.mobile_country_code)?.name?.toUpperCase()} (+${values.mobileCountryCode})`,
                            value: values.mobileCountryCode,
                            iso: values.countryISO,
                            id: values.countryId
                          }}
                        />
                      </div>
                      <TextInputFM
                        label="Mobile Number"
                        placeholder="Enter Number"
                        className="w-56"
                        name='mobileNo'
                        type='number'
                        maxLength={15}
                      />
                      <TextInputFM
                        label="Primary Email ID"
                        placeholder="Enter Email"
                        className="w-56"
                        name='primaryEmail'
                      />
                      <div className="w-full">
                        <TextAreaFM
                          label="Address"
                          placeholder="Enter Address"
                          className={"w-56 h-20"}
                          name='address'
                          maxLength={200}
                        />
                      </div>
                      <div className="flex justify-between mb-2">
                        <span className="text-sm mt-2">Wing Number</span>
                        <DropdownFM2
                          options={wingsData?.map((item)=>({
                            value: item?.wing_id,
                            label: item?.number
                          })) || []}
                          width={"224px"}
                          className="text-xs"
                          placeholder={"Select Wing"}
                          onSelect={(selectedItem)=>{
                            setFieldValue(`wingId`, selectedItem.value)
                            setFieldValue('flatId', '')
                            dispatch(getFlatDetails({societyId: loginData?.default_community?.community_id, wingId: selectedItem?.value}))
                          }}
                          height='38px'
                          name={`wingId`}
                          value={{ 
                            value: values.wingId,
                            label: wingsData?.find(item=> item.wing_id === values.wingId)?.number
                          }}
                        />
                      </div>
                      <div className="flex flex-row justify-between mb-1">
                        <span className="text-sm mt-2">Rent Deed Start Date</span>
                        <div>
                          <DatePickerComponentFM
                            className="w-56 h-9 ml-4 justify-between"
                            onDateChange={(selectedDate) => {
                              setFieldValue('rentDeedStartDate', selectedDate)
                            }}
                            name='rentDeedStartDate'
                            defaultValue={validateDate(values.rentDeedStartDate) ? new Date(values.rentDeedStartDate) : null}
                            maxDate={new Date()}
                          />
                        </div>
                      </div>
                      <div className="flex flex-row justify-between mb-1">
                        <span className="text-sm mt-2">Rent Deed End Date</span>
                        <div>
                          <DatePickerComponentFM
                            className="w-56 h-9 ml-4 justify-between"
                            onDateChange={(selectedDate) => {
                              setFieldValue('rentDeedEndDate', selectedDate)
                            }}
                            name='rentDeedEndDate'
                            defaultValue={validateDate(values.rentDeedEndDate) ? new Date(values.rentDeedEndDate) : null}
                            {...(validateDate(values.rentDeedStartDate) && {minDate: new Date(moment(values.rentDeedStartDate).add(1, 'days'))})}
                            readOnly={!values.rentDeedStartDate}
                          />
                        </div>
                      </div>
                      <TextInputFM
                        label="Identity Proof Number"
                        placeholder="Enter"
                        className="w-56"
                        name='adharCardNo'
                        type='alphaNumber'
                        maxLength={12}
                      />
                      <TextInputFM
                        label="Pan Card"
                        placeholder="Enter Pan Number"
                        className="w-56"
                        name='panCardNo'
                        type='alphaNumber'
                        maxLength={10}
                      />
                    </div>
                    <div className="flex flex-col w-1/2">
                      <TextInputFM
                        label="Middle Name"
                        placeholder="Enter Middle Name"
                        className="w-56"
                        name='middleName'
                        maxLength={20}
                      />
                      <div className="mt-[110px]">
                        <TextInputFM
                          label="Secondary Mobile Number"
                          placeholder="Enter Mobile Number"
                          className="w-56"
                          name='secondaryMobileNo'
                          type='number'
                          maxLength={15}
                        />
                        </div>
                      <div>
                        <div className="flex justify-between mt-[155px]">
                          <span className="text-sm mt-2">Flat Number</span>
                          <DropdownFM2
                            options={values.wingId && flatsData?.manage_flat_details?.map((item)=>({
                              value: item?.flat_id,
                              label: item?.flat_no
                            })) || []}
                            width={"224px"}
                            className="text-xs"
                            placeholder={"Select Flat/Unit"}
                            onSelect={(selectedItem)=>{
                              setFieldValue(`flatId`, selectedItem.value)
                            }}
                            height='38px'
                            name={`flatId`}
                            value={values.wingId && {
                              value: values.flatId,
                              label: flatsData?.manage_flat_details?.find(item=> item.flat_id === values.flatId)?.flat_no
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex justify-between mt-2">
                          <span className="text-sm mt-2">Rent Agreement Upload</span>
                          <div className="w-1/2 -mr-1">
                            <UploadButtonFM showFileName={true} 
                              name={`rentAgreementFile`}
                              accept={"image/jpeg,image/gif,image/png,application/pdf,image/x-eps"}
                              onSelect={(e)=>setFieldValue(`rentAgreementFile`, e)} 
                              fileName={values?.rentAgreementFile?.name}
                              />
                          </div>
                        </div>
                      <div className="mb-2">
                        <div className="flex justify-between">
                          <span className="text-sm">Uploaded Rent Agreement</span>
                          <div className="w-1/2 -mr-1">
                            {tenantDetails?.rentAgreementURL ? <Link target="_blank" className="underline text-xs text-blue-500" to={tenantDetails?.rentAgreementURL}>View Uploaded Doc</Link> : '-'}
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-sm mt-2">Upload Identity Proof</span>
                        <div className="w-1/2 -mr-1">
                          <UploadButtonFM showFileName={true} 
                            name={`adharFile`}
                            accept={"image/jpeg,image/gif,image/png,application/pdf,image/x-eps"}
                            onSelect={(e)=>setFieldValue(`adharFile`, e)} 
                            fileName={values?.adharFile?.name}
                            />
                        </div>
                      </div>
                      <div className="mb-2">
                        <div className="flex justify-between">
                          <span className="text-sm">Uploaded Identity Proof</span>
                          <div className="w-1/2 -mr-1">
                            {tenantDetails?.aadharCardURL ? <Link target="_blank" className="underline text-xs text-blue-500" to={tenantDetails?.aadharCardURL}>View Uploaded Doc</Link> : '-'}
                          </div>
                        </div>
                      </div>
                      <div className="mt-3">
                        <TextInputFM
                          label="GST Registration Number"
                          placeholder="Enter GST Number"
                          className="w-56"
                          name='gstRegistrationNo'
                          maxLength={15}
                          type='alphaNumber'
                        />
                      </div>
                      <div className="flex justify-end ml-4 mt-2">
                        <div className="w-1/2 flex text-xs -mt-2">
                          <Checkbox 
                            text="Show in Bill"
                            initialchecked={values.showInBill === 'Yes' ? true: false}
                            onChange={(e)=> setFieldValue('showInBill', e.target.checked ? 'Yes' : 'No')}
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </Form>
        )}
      }
      </Formik>
    </div>
  </Layout>
  );
};

export default EditTenantDetails;
