import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ALL_DOCUMENTS_ENDPOINT } from "../../../Routing/routes";
import ListHeader from "../../../components/ListHeader/ListHeader";
import Layout from "../../Layout/Layout";
import Button from "../../../components/Button/Button";
import ButtonG from "../../../components/Button/ButtonG";
import { TextAreaFM } from "../../../components/InputFields/TextArea";
import { UploadButtonFM } from "../../../components/Button/UploadButton";
import Checkbox from "../../../components/Checkbox/Checkbox";
import { DropdownFM2 } from "../../../components/Dropdown/dropdown2";
import { Form, Formik } from "formik";
import { logaTicketValidation } from "../../../validationSchema/deskSchema/logaTicketSchema";
import { useDispatch, useSelector } from "react-redux";
import {
  addLogAComplaint,
  getComplaintNatureList,
  getComplaintTypeList,
  getFlatsNo,
  getWingsList,
} from "../../../redux/actions/DeskActions/LogaComplaintActions";
import { onBehalfOfOptions } from "../../../consts/DropDownOptions";
import { CircularProgress } from "@mui/material";
import {
  getComplaintCategoryDetailsList,
  getComplaintCategoryList,
} from "../../../redux/actions/DeskActions/ComplaintCategoryAction";
import { getPermission } from "../../../utils/helpers/getPermissions";
import * as permissions from '../../../Routing/permissions'
import { TextInputFM } from "../../../components/InputFields/TextInput";

const LogAComplaint = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const { loginData } = useSelector((state) => state.loginReducer);
  const dropdownData = useSelector((state) => state.logaComplaintReducer);
  const { wingsList, flatsList } = useSelector((state) => state.logaComplaintReducer);
  const { updateLoader } = useSelector((state) => state.logaComplaintReducer);
  const { complaintCategoryList, complaintCategoryDetailsList } = useSelector(
    (state) => state.complaintCategoryReducer
  );

  const handleChecked = (event, setFieldValue) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      setFieldValue("isUrgent", "Yes");
    } else {
      setFieldValue("isUrgent", "No");
    }
  };

  useEffect(() => {
    dispatch(getComplaintNatureList());
    dispatch(getComplaintCategoryList());
    const data = { societyId: loginData?.default_community?.community_id };
    dispatch(getComplaintTypeList(data));
    dispatch(getWingsList(data));
  }, []);

  const handleDownloadClick = () => {
    navigate(ALL_DOCUMENTS_ENDPOINT);
  };

  const handleCancelClick = () => {
    navigate(-1);
  };

  const handleSaveClick = async (values, { resetForm }) => {
    let sendRequest = {
      ...values,
      societyId: loginData?.default_community?.community_id,
      onSuccessCallback: () => {
        resetForm();
      },
    };
    dispatch(addLogAComplaint(sendRequest));
  };


  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 px-3">
        <Formik
          initialValues={{
            complaintNature: "",
            complaintType: "",
            category: "",
            subCategory: "",
            isUrgent: "No",
            subject: "",
            description: "",
            wingId: "",
            flatsNo: "",
            attachments: "",
            subscriber_id: "",
            isTenant: 0,
            tenantId: "",
          }}
          enableReinitialize
          validationSchema={logaTicketValidation}
          onSubmit={handleSaveClick}
        >
          {(formik) => {
            return (
              <Form>
                <div className="border-b border-[#CCC]">
                  <ListHeader leftContent="Log A Complaint">
                    {
                      getPermission(loginData, [permissions.VIEW_DOCUMENTS_PERMISSION]) &&
                      <Button
                        label="Download Forms"
                        onClick={handleDownloadClick}
                        className="px-4"
                        type={"button"}
                      />
                    }
                    <div className="mx-0.5 border-l-2"></div>
                    <ButtonG
                      label="Cancel"
                      onClick={handleCancelClick}
                      type={"button"}
                    />
                    <Button
                      label={
                        <span className="w-12 inline-block">
                          {updateLoader ? (
                            <CircularProgress
                              sx={{ color: "white" }}
                              size={17}
                            />
                          ) : (
                            "Save"
                          )}
                        </span>
                      }
                      className="px-3 h-8"
                      type="submit"
                    />
                  </ListHeader>
                </div>
                <div className="flex justify-between gap-4 mt-4">
                  <table className="border-separate">
                    <tr>
                      <td className="text-sm w-56 pt-2.5 align-top">
                        Ticket Nature{" "}
                      </td>
                      <td>
                        <DropdownFM2
                          options={dropdownData.complaintNatureList?.map(
                            (item) => ({
                              label: item.title,
                              value: item.title,
                            })
                          )}
                          placeholder="Select Ticket Nature"
                          className=""
                          width="208px"
                          height="36px"
                          onSelect={(selectedItem) => {
                            formik.setFieldValue(
                              "complaintNature",
                              selectedItem.value
                            );
                          }}
                          {...(formik.values.complaintNature === "" && {
                            value: null,
                          })}
                          name={"complaintNature"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-sm w-56 pt-2.5 align-top">
                        Ticket Type
                      </td>
                      <td>
                        <DropdownFM2
                          options={dropdownData.complaintType?.map((item) => ({
                            label: item.title,
                            value: item.title,
                          }))}
                          placeholder="Select Type"
                          className=""
                          width="208px"
                          height="36px"
                          onSelect={(selectedItem) => {
                            formik.setFieldValue(
                              "complaintType",
                              selectedItem.value
                            );
                          }}
                          {...(formik.values.complaintType === "" && {
                            value: null,
                          })}
                          name={"complaintType"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-sm w-56 pt-2.5 align-top">
                        Category
                      </td>
                      <td>
                        <DropdownFM2
                          options={complaintCategoryList?.map((item) => ({
                            label: item.title,
                            value: item.id,
                          }))}
                          placeholder="Select Category"
                          className=""
                          width="208px"
                          height="36px"
                          onSelect={(selectedItem) => {
                            formik.setFieldValue(
                              "category",
                              selectedItem.value
                            );
                            if (formik.values.category !== selectedItem.value) {
                              formik.setFieldValue("subCategory", "");
                              dispatch(
                                getComplaintCategoryDetailsList({
                                  categoryId: selectedItem.value,
                                })
                              );
                            }
                          }}
                          {...(formik.values.category === "" && {
                            value: null,
                          })}
                          name={"category"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-sm w-56 pt-2.5 align-top">
                        Sub Category
                      </td>
                      <td>
                        <DropdownFM2
                          options={complaintCategoryDetailsList?.[0]?.sub_category?.map(
                            (item) => ({
                              label: item.sub_title,
                              value: item.id,
                            })
                          )}
                          placeholder="Select SubCategory"
                          className=""
                          width="208px"
                          height="36px"
                          onSelect={(selectedItem) => {
                            formik.setFieldValue(
                              "subCategory",
                              selectedItem.value
                            );
                          }}
                          {...(formik.values.subCategory === "" && {
                            value: null,
                          })}
                          name={"subCategory"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-sm w-56 pt-2.5 align-top">
                        Is Urgent ?
                      </td>
                      <td>
                        <div className="w-52 mb-4">
                          <Checkbox
                            text="Yes"
                            className="text-sm"
                            initialchecked={checked}
                            onChange={(e) => {
                              handleChecked(e, formik.setFieldValue);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-sm w-56 pt-2.5 align-top">
                        Subject
                      </td>
                      <td>
                        <TextInputFM
                          placeholder="Enter Subject"
                          className="w-52 h-9"
                          name='subject'
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-sm w-56 pt-2.5 align-top">
                        Description
                      </td>
                      <td>
                        <TextAreaFM
                          placeholder="Enter Description"
                          className={"w-52"}
                          name="description"
                          row="5"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-sm w-56 pt-2.5 align-top">
                        Attachments
                      </td>
                      <td>
                        <div className="w-52 overflow-hidden mb-5">
                          <UploadButtonFM
                            showFileName={true}
                            name={`attachments`}
                            accept={
                              "image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                            }
                            onSelect={(e) =>
                              formik.setFieldValue(`attachments`, e)
                            }
                            fileName={formik.values?.attachments?.name}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-sm w-56 pt-2.5 align-top">
                        On Behalf Of
                      </td>
                      <td>
                        <DropdownFM2
                          options={onBehalfOfOptions}
                          width="208px"
                          height="36px"
                          name={"isTenant"}
                          placeholder="Select OnBehalf"
                          onSelect={(selectedItem) => {
                            if (selectedItem.value === 0) {
                              formik.setFieldValue(
                                "tenantId",
                                selectedItem.value
                              );
                            }
                            formik.setFieldValue(
                              "isTenant",
                              selectedItem.value
                            );
                            if (formik.values.wingId) {
                              let data = {
                                societyId:
                                  loginData?.default_community?.community_id,
                                wingId: formik.values.wingId,
                                userType: selectedItem.value ? 'Tenant' : 'Member'
                              };
                              dispatch(getFlatsNo(data));
                            }
                            formik.setFieldValue(
                              "flatsNo",
                              ''
                            );
                          }}
                          value={
                            formik.values.isTenant === '' ? null :
                              {
                                label: onBehalfOfOptions?.find(item => item.value === formik.values.isTenant)?.label,
                                value: formik.values.isTenant
                              }
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-sm w-56 pt-2.5 align-top">
                        Member/Tenant Details
                      </td>
                      <td>
                        <div className="flex items-center">
                          <div className="w-52 flex gap-4 mr-4">
                            <DropdownFM2
                              className={"me-4"}
                              options={wingsList?.map((item) => ({
                                label: item.number,
                                value: item.id,
                              }))}
                              width="96px"
                              height="36px"
                              placeholder="Wing"
                              name={"wingId"}
                              onSelect={(selectedItem) => {
                                formik.setFieldValue(
                                  "wingId",
                                  selectedItem.value
                                );
                                let data = {
                                  societyId:
                                    loginData?.default_community?.community_id,
                                  wingId: selectedItem.value,
                                  userType: formik.values.isTenant ? 'Tenant' : 'Member'
                                };
                                dispatch(getFlatsNo(data));
                              }}
                              {...(formik.values.wingId === "" && {
                                value: null,
                              })}
                            />
                            <DropdownFM2
                              options={flatsList?.map((item) => ({
                                label: item.flat_no,
                                value: item.house_unique_id,
                                subscriber_id: item.subscriber_id,
                                memberName: item.full_name,
                                tenant_id: item.tenantid,
                                tenantName: item.tenant_full_name
                              }))}
                              name={"flatsNo"}
                              width="96px"
                              height="36px"
                              placeholder="Flat/Unit"
                              onSelect={(selectedItem) => {
                                formik.setFieldValue(
                                  "flatsNo",
                                  selectedItem.value
                                );
                                formik.setFieldValue(
                                  "subscriber_id",
                                  selectedItem.subscriber_id
                                );
                                formik.setFieldValue(
                                  "tenantId",
                                  selectedItem.tenant_id
                                );
                                formik.setFieldValue(
                                  "memberName",
                                  selectedItem.memberName
                                );
                                formik.setFieldValue(
                                  "tenantName",
                                  selectedItem.tenantName
                                );
                              }}
                              {...(formik.values.flatsNo === "" && {
                                value: null,
                              })}
                            />
                          </div>
                          <div className="text-[#AAAAAA] font-bold text-xs -mt-3">
                            — {formik.values.isTenant ? formik.values.tenantName : formik.values.memberName}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Layout>
  );
};

export default LogAComplaint;
