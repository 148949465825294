// Country model:
// [
//    Country name,
//    Regions,
//    iso2 code,
//    International dial code,
//    Format (if available),
//    Order priority (if >1 country with same dial code),
//    Area codes (if >1 country with same dial code)
// ]
//
// Regions:
// ['america', 'europe', 'asia', 'oceania', 'africa']
//
// Sub-regions:
// ['north-america', 'south-america', 'central-america', 'carribean',
//  'eu-union', 'ex-ussr', 'ex-yugos', 'baltic', 'middle-east', 'north-africa']

const countries = [
    {"id":1,"iso":"IN","phonecode":"+91","name":"India"},
    {"id":2,"iso":"AL","phonecode":"+355","name":"Albania"},
    {"id":3,"iso":"DZ","phonecode":"+213","name":"Algeria"},
    {"id":4,"iso":"AS","phonecode":"+1684","name":"American Samoa"},
    {"id":5,"iso":"AD","phonecode":"+376","name":"Andorra"},
    {"id":6,"iso":"AO","phonecode":"+244","name":"Angola"},
    {"id":7,"iso":"AI","phonecode":"+1264","name":"Anguilla"},
    {"id":8,"iso":"AQ","phonecode":"+0","name":"Antarctica"},
    {"id":9,"iso":"AG","phonecode":"+1268","name":"Antigua and Barbuda"},
    {"id":10,"iso":"AR","phonecode":"+54","name":"Argentina"},
    {"id":11,"iso":"AM","phonecode":"+374","name":"Armenia"},
    {"id":12,"iso":"AW","phonecode":"+297","name":"Aruba"},
    {"id":13,"iso":"AU","phonecode":"+61","name":"Australia"},
    {"id":14,"iso":"AT","phonecode":"+43","name":"Austria"},
    {"id":15,"iso":"AZ","phonecode":"+994","name":"Azerbaijan"},
    {"id":16,"iso":"BS","phonecode":"+1242","name":"Bahamas"},
    {"id":17,"iso":"BH","phonecode":"+973","name":"Bahrain"},
    {"id":18,"iso":"BD","phonecode":"+880","name":"Bangladesh"},
    {"id":19,"iso":"BB","phonecode":"+1246","name":"Barbados"},
    {"id":20,"iso":"BY","phonecode":"+375","name":"Belarus"},
    {"id":21,"iso":"BE","phonecode":"+32","name":"Belgium"},
    {"id":22,"iso":"BZ","phonecode":"+501","name":"Belize"},
    {"id":23,"iso":"BJ","phonecode":"+229","name":"Benin"},
    {"id":24,"iso":"BM","phonecode":"+1441","name":"Bermuda"},
    {"id":25,"iso":"BT","phonecode":"+975","name":"Bhutan"},
    {"id":26,"iso":"BO","phonecode":"+591","name":"Bolivia"},
    {"id":27,"iso":"BA","phonecode":"+387","name":"Bosnia and Herzegovina"},
    {"id":28,"iso":"BW","phonecode":"+267","name":"Botswana"},
    {"id":29,"iso":"BV","phonecode":"+0","name":"Bouvet Island"},
    {"id":30,"iso":"BR","phonecode":"+55","name":"Brazil"},
    {"id":31,"iso":"IO","phonecode":"+246","name":"British Indian Ocean Territory"},
    {"id":32,"iso":"BN","phonecode":"+673","name":"Brunei"},
    {"id":33,"iso":"BG","phonecode":"+359","name":"Bulgaria"},
    {"id":34,"iso":"BF","phonecode":"+226","name":"Burkina Faso"},
    {"id":35,"iso":"BI","phonecode":"+257","name":"Burundi"},
    {"id":36,"iso":"KH","phonecode":"+855","name":"Cambodia"},
    {"id":37,"iso":"CM","phonecode":"+237","name":"Cameroon"},
    {"id":38,"iso":"CA","phonecode":"+1","name":"Canada"},
    {"id":39,"iso":"CV","phonecode":"+238","name":"Cape Verde"},
    {"id":40,"iso":"KY","phonecode":"+1345","name":"Cayman Islands"},
    {"id":41,"iso":"CF","phonecode":"+236","name":"Central African Republic"},
    {"id":42,"iso":"TD","phonecode":"+235","name":"Chad"},
    {"id":43,"iso":"CL","phonecode":"+56","name":"Chile"},
    {"id":44,"iso":"CN","phonecode":"+86","name":"China"},
    {"id":45,"iso":"CX","phonecode":"+61","name":"Christmas Island"},
    {"id":46,"iso":"CC","phonecode":"+672","name":"Cocos (Keeling) Islands"},
    {"id":47,"iso":"CO","phonecode":"+57","name":"Colombia"},
    {"id":48,"iso":"KM","phonecode":"+269","name":"Comoros"},
    {"id":49,"iso":"CG","phonecode":"+242","name":"Congo"},
    {"id":50,"iso":"CD","phonecode":"+242","name":"Congo (Democratic Republic of the)"},
    {"id":51,"iso":"CK","phonecode":"+682","name":"Cook Islands"},
    {"id":52,"iso":"CR","phonecode":"+506","name":"Costa Rica"},
    {"id":53,"iso":"CI","phonecode":"+225", "name": "Côte d'Ivoire"},
    {"id":54,"iso":"HR","phonecode":"+385", "name": "Croatia"},
    {"id":55,"iso":"CU","phonecode":"+53", "name": "Cuba"},
    {"id":56,"iso":"CY","phonecode":"+357", "name": "Cyprus"},
    {"id":57,"iso":"CZ","phonecode":"+420", "name": "Czech Republic"},
    {"id":58,"iso":"DK","phonecode":"+45", "name": "Denmark"},
    {"id":59,"iso":"DJ","phonecode":"+253", "name": "Djibouti"},
    {"id":60,"iso":"DM","phonecode":"+1767", "name": "Dominica"},
    {"id":61,"iso":"DO","phonecode":"+1809", "name": "Dominican Republic"},
    {"id":62,"iso":"EC","phonecode":"+593", "name": "Ecuador"},
    {"id":63,"iso":"EG","phonecode":"+20", "name": "Egypt"},
    {"id":64,"iso":"SV","phonecode":"+503", "name": "El Salvador"},
    {"id":65,"iso":"GQ","phonecode":"+240", "name": "Equatorial Guinea"},
    {"id":66,"iso":"ER","phonecode":"+291", "name": "Eritrea"},
    {"id":67,"iso":"EE","phonecode":"+372", "name": "Estonia"},
    {"id":68,"iso":"ET","phonecode":"+251", "name": "Ethiopia"},
    {"id":69,"iso":"FK","phonecode":"+500", "name": "Falkland Islands (Malvinas)"},
    {"id":70,"iso":"FO","phonecode":"+298", "name": "Faroe Islands"},
    {"id":71,"iso":"FJ","phonecode":"+679", "name": "Fiji"},
    {"id":72,"iso":"FI","phonecode":"+358", "name": "Finland"},
    {"id":73,"iso":"FR","phonecode":"+33", "name": "France"},
    {"id":74,"iso":"GF","phonecode":"+594", "name": "French Guiana"},
    {"id":75,"iso":"PF","phonecode":"+689", "name": "French Polynesia"},
    {"id":76,"iso":"TF","phonecode":"+0", "name": "French Southern Territories"},
    {"id":77,"iso":"GA","phonecode":"+241", "name": "Gabon"},
    {"id":78,"iso":"GM","phonecode":"+220", "name": "Gambia"},
    {"id":79,"iso":"GE","phonecode":"+995", "name": "Georgia"},
    {"id":80,"iso":"DE","phonecode":"+49", "name": "Germany"},
    {"id":81,"iso":"GH","phonecode":"+233", "name": "Ghana"},
    {"id":82,"iso":"GI","phonecode":"+350", "name": "Gibraltar"},
    {"id":83,"iso":"GR","phonecode":"+30", "name": "Greece"},
    {"id":84,"iso":"GL","phonecode":"+299", "name": "Greenland"},
    {"id":85,"iso":"GD","phonecode":"+1473", "name": "Grenada"},
    {"id":86,"iso":"GP","phonecode":"+590", "name": "Guadeloupe"},
    {"id":87,"iso":"GU","phonecode":"+1671", "name": "Guam"},
    {"id":88,"iso":"GT","phonecode":"+502", "name": "Guatemala"},
    {"id":89,"iso":"GN","phonecode":"+224", "name": "Guinea"},
    {"id":90,"iso":"GW","phonecode":"+245", "name": "Guinea-Bissau"},
    {"id":91,"iso":"GY","phonecode":"+592", "name": "Guyana"},
    {"id":92,"iso":"HT","phonecode":"+509", "name": "Haiti"},
    {"id":93,"iso":"HM","phonecode":"+0", "name": "Heard Island and McDonald Islands"},
    {"id":94,"iso":"VA","phonecode":"+39", "name": "Holy See (Vatican City State)"},
    {"id":95,"iso":"HN","phonecode":"+504", "name": "Honduras"},
    {"id":96,"iso":"HK","phonecode":"+852", "name": "Hong Kong"},
    {"id":97,"iso":"HU","phonecode":"+36", "name": "Hungary"},
    {"id":98,"iso":"IS","phonecode":"+354", "name": "Iceland"},
    {"id":99,"iso":"AF","phonecode":"+93", "name": "Afghanistan"},
    {"id":100,"iso":"ID","phonecode":"+62", "name": "Indonesia"},
    {"id":101,"iso":"IR","phonecode":"+98", "name": "Iran, Islamic Republic of"},
    {"id":102,"iso":"IQ","phonecode":"+964", "name": "Iraq"},
    {"id":103,"iso":"IE","phonecode":"+353", "name": "Ireland"},
    {"id":104,"iso":"IL","phonecode":"+972", "name": "Israel"},
    {"id":105,"iso":"IT","phonecode":"+39", "name": "Italy"},
    {"id":106,"iso":"JM","phonecode":"+1876", "name": "Jamaica"},
    {"id":107,"iso":"JP","phonecode":"+81", "name": "Japan"},
    {"id":108,"iso":"JO","phonecode":"+962", "name": "Jordan"},
    {"id":109,"iso":"KZ","phonecode":"+7", "name": "Kazakhstan"},
    {"id":110,"iso":"KE","phonecode":"+254", "name": "Kenya"},
    {"id":111,"iso":"KI","phonecode":"+686", "name": "Kiribati"},
    {"id":112,"iso":"KP","phonecode":"+850", "name": "Korea, Democratic People's Republic of"},
    {"id":113,"iso":"KR","phonecode":"+82", "name": "Korea, Republic of"},
    {"id":114,"iso":"KW","phonecode":"+965", "name": "Kuwait"},
    {"id":115,"iso":"KG","phonecode":"+996", "name": "Kyrgyzstan"},
    {"id":116,"iso":"LA","phonecode":"+856", "name": "Lao People's Democratic Republic"},
    {"id":117,"iso":"LV","phonecode":"+371", "name": "Latvia"},
    {"id":118,"iso":"LB","phonecode":"+961","name":"Lebanon"},
    {"id":119,"iso":"LS","phonecode":"+266","name":"Lesotho"},
    {"id":120,"iso":"LR","phonecode":"+231","name":"Liberia"},
    {"id":121,"iso":"LY","phonecode":"+218","name":"Libya"},
    {"id":122,"iso":"LI","phonecode":"+423","name":"Liechtenstein"},
    {"id":123,"iso":"LT","phonecode":"+370","name":"Lithuania"},
    {"id":124,"iso":"LU","phonecode":"+352","name":"Luxembourg"},
    {"id":125,"iso":"MO","phonecode":"+853","name":"Macao"},
    {"id":126,"iso":"MK","phonecode":"+389","name":"North Macedonia"},
    {"id":127,"iso":"MG","phonecode":"+261","name":"Madagascar"},
    {"id":128,"iso":"MW","phonecode":"+265","name":"Malawi"},
    {"id":129,"iso":"MY","phonecode":"+60","name":"Malaysia"},
    {"id":130,"iso":"MV","phonecode":"+960","name":"Maldives"},
    {"id":131,"iso":"ML","phonecode":"+223","name":"Mali"},
    {"id":132,"iso":"MT","phonecode":"+356","name":"Malta"},
    {"id":133,"iso":"MH","phonecode":"+692","name":"Marshall Islands"},
    {"id":134,"iso":"MQ","phonecode":"+596","name":"Martinique"},
    {"id":135,"iso":"MR","phonecode":"+222","name":"Mauritania"},
    {"id":136,"iso":"MU","phonecode":"+230","name":"Mauritius"},
    {"id":137,"iso":"YT","phonecode":"+269","name":"Mayotte"},
    {"id":138,"iso":"MX","phonecode":"+52","name":"Mexico"},
    {"id":139,"iso":"FM","phonecode":"+691","name":"Micronesia"},
    {"id":140,"iso":"MD","phonecode":"+373","name":"Moldova"},
    {"id":141,"iso":"MC","phonecode":"+377","name":"Monaco"},
    {"id":142,"iso":"MN","phonecode":"+976","name":"Mongolia"},
    {"id":143,"iso":"MS","phonecode":"+1664","name":"Montserrat"},
    {"id":144,"iso":"MA","phonecode":"+212","name":"Morocco"},
    {"id":145,"iso":"MZ","phonecode":"+258","name":"Mozambique"},
    {"id":146,"iso":"MM","phonecode":"+95","name":"Myanmar"},
    {"id":147,"iso":"NA","phonecode":"+264","name":"Namibia"},
    {"id":148,"iso":"NR","phonecode":"+674","name":"Nauru"},
    {"id":149,"iso":"NP","phonecode":"+977","name":"Nepal"},
    {"id":150,"iso":"NL","phonecode":"+31","name":"Netherlands"},
    {"id":151,"iso":"AN","phonecode":"+599", "name": "Netherlands Antilles"},
    {"id":152,"iso":"NC","phonecode":"+687", "name": "New Caledonia"},
    {"id":153,"iso":"NZ","phonecode":"+64", "name": "New Zealand"},
    {"id":154,"iso":"NI","phonecode":"+505", "name": "Nicaragua"},
    {"id":155,"iso":"NE","phonecode":"+227", "name": "Niger"},
    {"id":156,"iso":"NG","phonecode":"+234", "name": "Nigeria"},
    {"id":157,"iso":"NU","phonecode":"+683", "name": "Niue"},
    {"id":158,"iso":"NF","phonecode":"+672", "name": "Norfolk Island"},
    {"id":159,"iso":"MP","phonecode":"+1670", "name": "Northern Mariana Islands"},
    {"id":160,"iso":"NO","phonecode":"+47", "name": "Norway"},
    {"id":161,"iso":"OM","phonecode":"+968", "name": "Oman"},
    {"id":162,"iso":"PK","phonecode":"+92", "name": "Pakistan"},
    {"id":163,"iso":"PW","phonecode":"+680", "name": "Palau"},
    {"id":164,"iso":"PS","phonecode":"+970", "name": "Palestine"},
    {"id":165,"iso":"PA","phonecode":"+507", "name": "Panama"},
    {"id":166,"iso":"PG","phonecode":"+675", "name": "Papua New Guinea"},
    {"id":167,"iso":"PY","phonecode":"+595", "name": "Paraguay"},
    {"id":168,"iso":"PE","phonecode":"+51", "name": "Peru"},
    {"id":169,"iso":"PH","phonecode":"+63", "name": "Philippines"},
    {"id":170,"iso":"PN","phonecode":"+0", "name": "Pitcairn"},
    {"id":171,"iso":"PL","phonecode":"+48", "name": "Poland"},
    {"id":172,"iso":"PT","phonecode":"+351", "name": "Portugal"},
    {"id":173,"iso":"PR","phonecode":"+1787", "name": "Puerto Rico"},
    {"id":174,"iso":"QA","phonecode":"+974", "name": "Qatar"},
    {"id":175,"iso":"RE","phonecode":"+262", "name": "Réunion"},
    {"id":176,"iso":"RO","phonecode":"+40", "name": "Romania"},
    {"id":177,"iso":"RU","phonecode":"+70", "name": "Russia"},
    {"id":178,"iso":"RW","phonecode":"+250", "name": "Rwanda"},
    {"id":179,"iso":"SH","phonecode":"+290", "name": "Saint Helena"},
    {"id":180,"iso":"KN","phonecode":"+1869", "name": "Saint Kitts and Nevis"},
    {"id":181,"iso":"LC","phonecode":"+1758", "name": "Saint Lucia"},
    {"id":182,"iso":"PM","phonecode":"+508", "name": "Saint Pierre and Miquelon"},
    {"id":183,"iso":"VC","phonecode":"+1784", "name": "Saint Vincent and the Grenadines"},
    {"id":184,"iso":"WS","phonecode":"+684", "name": "Samoa"},
    {"id":185,"iso":"SM","phonecode":"+378", "name": "San Marino"},
    {"id":186,"iso":"ST","phonecode":"+239", "name": "Sao Tome and Principe"},
    {"id":187,"iso":"SA","phonecode":"+966", "name": "Saudi Arabia"},
    {"id":188,"iso":"SN","phonecode":"+221", "name": "Senegal"},
    {"id":189,"iso":"CS","phonecode":"+381", "name": "Serbia"},
    {"id":190,"iso":"SC","phonecode":"+248", "name": "Seychelles"},
    {"id":191,"iso":"SL","phonecode":"+232", "name": "Sierra Leone"},
    {"id":192,"iso":"SG","phonecode":"+65", "name": "Singapore"},
    {"id":193,"iso":"SK","phonecode":"+421", "name": "Slovakia"},
    {"id":194,"iso":"SI","phonecode":"+386", "name": "Slovenia"},
    {"id":195,"iso":"SB","phonecode":"+677", "name": "Solomon Islands"},
    {"id":196,"iso":"SO","phonecode":"+252", "name": "Somalia"},
    {"id":197,"iso":"ZA","phonecode":"+27", "name": "South Africa"},
    {"id":198,"iso":"GS","phonecode":"+0", "name": "South Georgia and the South Sandwich Islands"},
    {"id":199,"iso":"ES","phonecode":"+34", "name": "Spain"},
    {"id":200,"iso":"LK","phonecode":"+94", "name": "Sri Lanka"},
    {"id":201,"iso":"SD","phonecode":"+249", "name": "Sudan"},
    {"id":202,"iso":"SR","phonecode":"+597", "name": "Suriname"},
    {"id":203,"iso":"SJ","phonecode":"+47", "name": "Svalbard and Jan Mayen"},
    {"id":204,"iso":"SZ","phonecode":"+268", "name": "Swaziland"},
    {"id":205,"iso":"SE","phonecode":"+46", "name": "Sweden"},
    {"id":206,"iso":"CH","phonecode":"+41", "name": "Switzerland"},
    {"id":207,"iso":"SY","phonecode":"+963", "name": "Syria"},
    {"id":208,"iso":"TW","phonecode":"+886", "name": "Taiwan"},
    {"id":209,"iso":"TJ","phonecode":"+992", "name": "Tajikistan"},
    {"id":210,"iso":"TZ","phonecode":"+255", "name": "Tanzania"},
    {"id":211,"iso":"TH","phonecode":"+66", "name": "Thailand"},
    {"id":212,"iso":"TL","phonecode":"+670", "name": "Timor-Leste"},
    {"id":213,"iso":"TG","phonecode":"+228", "name": "Togo"},
    {"id":214,"iso":"TK","phonecode":"+690", "name": "Tokelau"},
    {"id":215,"iso":"TO","phonecode":"+676", "name": "Tonga"},
    {"id":216,"iso":"TT","phonecode":"+1868","name":"Trinidad and Tobago"},
    {"id":217,"iso":"TN","phonecode":"+216","name":"Tunisia"},
    {"id":218,"iso":"TR","phonecode":"+90","name":"Turkey"},
    {"id":219,"iso":"TM","phonecode":"+7370","name":"Turkmenistan"},
    {"id":220,"iso":"TC","phonecode":"+1649","name":"Turks and Caicos Islands"},
    {"id":221,"iso":"TV","phonecode":"+688","name":"Tuvalu"},
    {"id":222,"iso":"UG","phonecode":"+256","name":"Uganda"},
    {"id":223,"iso":"UA","phonecode":"+380","name":"Ukraine"},
    {"id":224,"iso":"AE","phonecode":"+971","name":"United Arab Emirates"},
    {"id":225,"iso":"GB","phonecode":"+44","name":"United Kingdom"},
    {"id":226,"iso":"US","phonecode":"+1","name":"United States"},
    {"id":227,"iso":"UM","phonecode":"+1","name":"United States Minor Outlying Islands"},
    {"id":228,"iso":"UY","phonecode":"+598","name":"Uruguay"},
    {"id":229,"iso":"UZ","phonecode":"+998","name":"Uzbekistan"},
    {"id":230,"iso":"VU","phonecode":"+678","name":"Vanuatu"},
    {"id":231,"iso":"VE","phonecode":"+58","name":"Venezuela"},
    {"id":232,"iso":"VN","phonecode":"+84","name":"Vietnam"},
    {"id":233,"iso":"VG","phonecode":"+1284","name":"Virgin Islands, British"},
    {"id":234,"iso":"VI","phonecode":"+1340","name":"Virgin Islands, U.S."},
    {"id":235,"iso":"WF","phonecode":"+681","name":"Wallis and Futuna"},
    {"id":236,"iso":"EH","phonecode":"+212","name":"Western Sahara"},
    {"id":237,"iso":"YE","phonecode":"+967","name":"Yemen"},
    {"id":238,"iso":"ZM","phonecode":"+260","name":"Zambia"},
    {"id":239,"iso":"ZW","phonecode":"+263","name":"Zimbabwe"}
  ]
  
    export default countries