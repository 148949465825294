import React, { useEffect } from "react";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Layout from "../../../Layout/Layout";
import Button from "../../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { ADD_OCCUPANT_ROLES_ENDPOINT } from "../../../../Routing/routes";
import ManageOccupantsRolesTable from "../../../../components/TableComponent/ManageOccupantsRolesTable";
import { useDispatch } from "react-redux";
import { getOccupantsRolesList } from "../../../../redux/actions/SetupRecordsActions/ManageRolesAndRightsActions/ManageRolesAndRightsAction";
import { useSelector } from "react-redux";

export const headCells = [
  { id: "id", label: "S No.", width: "120px" },
  { id: "role", label: "Role", sort: true, width: "400px" },
  { id: "status", label: "Status", sort: true, width: "300px" },
  { id: "action", label: "Action", width: "100px" },
];


const ManageOccupantRoles = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading, occupantsRoleList} = useSelector(state=>state.manageRoleAndRightsReducer)

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  useEffect(()=>{
    dispatch(getOccupantsRolesList({societyId: loginData?.default_community?.community_id}))
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
       
          <ListHeader leftContent="Manage Occupant Role">
            <div className="border-r border-[#CCC]"></div>
            <Button
            label="Add Occupant Role"
            onClick={handleNavigation(ADD_OCCUPANT_ROLES_ENDPOINT)}
          />
          </ListHeader>
        <div className="ml-1">
          <ManageOccupantsRolesTable
            loading={loading}
            columns={headCells}
            checkbox={true}
            pagination={false}
            data={occupantsRoleList?.map((item, index)=>({
              sNo: (index + 1)?.toString()?.padStart(2, "0"),
              role: item?.title || '-',
              status:item?.status === 'A' ? 'Active' : 'Inactive',
              occupantRoleId: item?.occupant_role_id
            }))}
          ></ManageOccupantsRolesTable>
        </div>
        {!occupantsRoleList?.length && !loading && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ManageOccupantRoles;
