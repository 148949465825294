import * as Yup from "yup";

  export const AddTimeSlotValidation = () => {
    return Yup.object({
      facilityId: Yup.string().required('*Required'),
      timeSlot: Yup.array().of(
        Yup.object().shape({
          type: Yup.string().required("*Required"),
          ticket: Yup.string().required("*Required"),
          startTime: Yup.string()
            .test('not-only-zeros', '*Required', value => {
              return /^(?:[01]\d|2[0-3]):[0-5]\d$/.test(value);
            })
            ,
          endTime: Yup.string()
          .test('not-only-zeros', '*Required', value => {
            return /^(?:[01]\d|2[0-3]):[0-5]\d$/.test(value);
          })
          ,
          frequency: Yup.string().required("*Required"),
          selectedDays:Yup.array().of(
            Yup.string()
            )
            .when(['frequency'], (frequency, schema) => {
              return frequency[0] === 'Selected' ?
              schema.required("**Select atleast 1 day")
              :
              schema
            }),
        }),
      ),
      timeSlotHourly: Yup.array().of(
        Yup.object().shape({
          type: Yup.string().required("*Required"),
          ticket: Yup.string().required("*Required"),
          lessThan: Yup.string().required("*Required"),
          hours: Yup.string().required("*Required"),
        }),
      )
    });
  };

  export const AddTimeSlotTypeValidation = () => {
    return Yup.object({
      type: Yup.string().required('*Required')
    });
  };
