import React from "react";
import { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import { DeleteIcon } from "../../../../assets";
import { FieldArray } from "formik";
import { DropdownFM2 } from "../../../../components/Dropdown/dropdown2";
import { societyStaffCurrentStatusptions } from "../../../../consts/DropDownOptions";
import { useSelector } from "react-redux";
import { validateDate } from "../../../../utils/helpers/universalFunctions";
import { TextInputFM } from "../../../../components/InputFields/TextInput";
import { DOMESTIC_STAFF_TYPE } from "../../../../consts/constant";
import { useParams } from "react-router-dom";

const EditStaffBoxForm = ({values, setFieldValue, type}) => {
  const {wingsFlatData} = useSelector(state=>state.staffPersonnelReducer)
  const { flatId } = useParams();
  const occupantDetail = useSelector((state) => state.occupantInfoReducer.occupantDetail);

  return (
    <div>
      {
        values.staffType==='Domestic' &&
        <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg mt-3">
          <div className=" border-b border-[#CCC] pb-2 bg-[#FDF2F3]">
            <h1 className="text-base items-center p-2 -mb-2 ml-1">{type===DOMESTIC_STAFF_TYPE ? 'Flat Details' : 'Add Flats' }</h1>
          </div>
          <div className=" p-2">
            <FieldArray
              name="flatData"
              render={arrayHelpers => (
                <div className="p-2">
                  {values.flatData && values.flatData.length > 0 && (
                    values.flatData.map((flat, index) => (
                      <>
                          {
                            ((type===DOMESTIC_STAFF_TYPE && values.flatData[index].flatId?.toString() === flatId ) || type!==DOMESTIC_STAFF_TYPE) &&
                            <div className="flex items-start flex-wrap gap-x-3" key={index}>
                                <div className="flex justify-between gap-x-3">
                                <span className="text-sm mt-2">Flat Number</span>
                                {
                                  type===DOMESTIC_STAFF_TYPE ?
                                  <div className="mt-2 text-sm text-[#AAAAAA] font-semibold">{occupantDetail?.flat_data?.find(item=> item.flat_id?.toString()===flatId)?.wing_no + ' - ' + occupantDetail?.flat_data?.find(item=> item.flat_id?.toString()===flatId)?.flat_no}</div>
                                  :
                                  <DropdownFM2
                                      options={wingsFlatData?.map((item)=>({
                                          value: item?.flat_id,
                                          label: item?.wing_flat
                                      })) || []}
                                      width={"176px"}
                                      className="text-xs"
                                      placeholder="Select Flat"
                                      onSelect={(selectedItem)=>{
                                          setFieldValue(`flatData.${index}.flatId`, selectedItem.value)
                                      }}
                                      height='36px'
                                      name={`flatData.${index}.flatId`}
                                      {...(values.flatData[index].flatId && {value: {
                                          label: wingsFlatData?.find(item=> item.flat_id === values.flatData[index].flatId)?.wing_flat,
                                          value: values.flatData[index].flatId
                                      }})}
                                      />
                                    }
                                </div>
                                <div className="flex justify-between">
                                    <span className="text-sm mt-2">Joining Date</span>
                                    <DatePickerComponentFM
                                        className="w-[176px] h-9 ml-4 justify-between"
                                        onDateChange={(selectedDate) => {
                                        setFieldValue(`flatData.${index}.joinDate`, selectedDate)
                                        }}
                                        name={`flatData.${index}.joinDate`}
                                        defaultValue={validateDate(values.flatData[index].joinDate) ? new Date(values.flatData[index].joinDate) : null}
                                    />
                                </div>
                                <div className="flex justify-between gap-x-3">
                                    <span className="text-sm mt-2">Current Status</span>
                                    <DropdownFM2
                                        options={societyStaffCurrentStatusptions}
                                        width={"176px"}
                                        className="text-xs"
                                        placeholder="Select Status"
                                        onSelect={(selectedItem)=>{
                                            setFieldValue(`flatData.${index}.status`, selectedItem.value)
                                        }}
                                        height='36px'
                                        name={`flatData.${index}.status`}
                                        {...(values.flatData[index].status && {value: {
                                            label: societyStaffCurrentStatusptions?.find(item=> item.value === values.flatData[index].status)?.label,
                                            value: values.flatData[index].status
                                        }})}                        
                                        />
                                </div>
                                {
                                    !values.flatData[index].staffFlatId &&
                                    <button
                                    className="fill-red-650 p-2"
                                    onClick={() => {arrayHelpers.remove(index)}}
                                    type="button"
                                    >
                                    <DeleteIcon />
                                    </button>
                                }
                                <div className="flex justify-between">
                                    <span className="text-sm mt-2">Resigning Date</span>
                                    <DatePickerComponentFM
                                        className="w-[176px] h-9 ml-4 justify-between"
                                        onDateChange={(selectedDate) => {
                                        setFieldValue(`flatData.${index}.dateofResign`, selectedDate)
                                        }}
                                        name={`flatData.${index}.dateofResign`}
                                        defaultValue={validateDate(values.flatData[index].dateofResign) ? new Date(values.flatData[index].dateofResign) : null}
                                    />
                                </div>
                                <div className="flex justify-between gap-x-3">
                                    <span className="text-sm mt-2">Resigning Reason</span>
                                    <TextInputFM
                                        label=""
                                        placeholder="Enter Reason"
                                        className={"w-[176px]"}
                                        name={`flatData.${index}.reason`}
                                        maxLength={300}
                                    />
                                </div>
                            </div>
                          }
                              
                          <div className=" border-b border-[#CCC] w-full mb-5"></div>
                      </>
                  ))
                )}
                {
                  type!==DOMESTIC_STAFF_TYPE &&
                  <div>
                    <button
                    type="button"
                      className="text-red-650 font-semibold border-2 border-dashed border-red-650 w-28 text-xs whitespace-nowrap h-9 p-2 mb-4 bg-[#FDF6F6] flex justify-center rounded-md"
                      onClick={() =>
                        arrayHelpers.push({
                            staffFlatId:0,
                            flatId: '',
                            joinDate: '',
                            status: '',
                            dateofResign: '',
                            reason: ''
                        })
                      }
                    >
                      Add More
                    </button>
                  </div>
                }
                </div>
              )}
              ></FieldArray>
          </div>
        </div>
      }
    </div>
  );
};

export default EditStaffBoxForm;
